import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Title, Button } from "rbx";
import { useMutation, useLazyQuery } from "@apollo/client";
import { useModal, useAuth } from "../../../../../context";
import {
  CREATE_STANDARD_CASE_HISTORY_NOTE_MUTATION,
  UPDATE_STANDARD_CASE_HISTORY_NOTE_MUTATION,
  DELETE_STANDARD_CASE_HISTORY_NOTE_MUTATION,
  LIST_STANDARD_CASE_HISTORY_NOTES_QUERY,
  SINGLE_STANDARD_CASE_HISTORY_NOTE_QUERY,
} from "../../../../../graphql";
import Confirmation from "../../../../../components/Confirmation";
import {
  customToast as toast,
  getDateEST,
  convertTimeZoneDataBase,
} from "../../../../../utils";
import StandardNoteForm from "../StandardNoteForm";

const INITIAL_STATE = {
  NoteDescription: "",
  NoteText: "",
  PublishOnWeb: false,
  Status: "Active",
};

class CustomCustomTypeError extends Error {}

const StandardNoteModal = ({ onComplete, noteId }) => {
  const { setModalOpen } = useModal();
  const { state: authState } = useAuth();
  const [inputs, setInputs] = useState(INITIAL_STATE);
  const [loading, setLoading] = useState(false);

  const [
    getStandardNote,
    { data: standardNoteData, loading: queryLoading },
  ] = useLazyQuery(SINGLE_STANDARD_CASE_HISTORY_NOTE_QUERY);
  const [createStandardNote] = useMutation(
    CREATE_STANDARD_CASE_HISTORY_NOTE_MUTATION
  );
  const [updateStandardNote] = useMutation(
    UPDATE_STANDARD_CASE_HISTORY_NOTE_MUTATION
  );
  // const [createAuditTrail] = useMutation(CREATE_AUDIT_TRAIL_MUTATION);
  const [deleteStandardNote] = useMutation(
    DELETE_STANDARD_CASE_HISTORY_NOTE_MUTATION
  );

  useEffect(() => {
    if (noteId) {
      getStandardNote({
        variables: {
          where: { NoteID: parseInt(noteId, 10) },
        },
      });
    }
  }, [noteId, getStandardNote]);

  useEffect(() => {
    const standardCaseHistoryNote =
      standardNoteData?.findUniqueStandardCaseHistoryNotes;
    if (standardCaseHistoryNote) {
      setInputs({
        NoteDescription: standardCaseHistoryNote.NoteDescription || "",
        NoteText: standardCaseHistoryNote.NoteText || "",
        PublishOnWeb: standardCaseHistoryNote.PublishOnWeb || false,
        Status: standardCaseHistoryNote.Status || "Active",
      });
    }
  }, [standardNoteData]);

  const handleChange = (name, value) => {
    setInputs((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      const date = convertTimeZoneDataBase(getDateEST());
      if (!noteId) {
        const dataToSend = { ...inputs };
        await createStandardNote({
          variables: {
            data: {
              ...dataToSend,
              UseridEdited: authState.user.userid,
              UseridAdded: authState.user.userid,
              DateEdited: date,
              DateAdded: date,
            },
          },
          refetchQueries: [
            {
              query: LIST_STANDARD_CASE_HISTORY_NOTES_QUERY,
            },
          ],
        });
        onComplete();
        toast.success("Standard Note created successfully.");
      } else {
        await updateStandardNote({
          variables: {
            data: {
              NoteDescription: { set: inputs.NoteDescription },
              NoteText: { set: inputs.NoteText },
              PublishOnWeb: { set: !!inputs.PublishOnWeb },
              Status: { set: inputs.Status },
              UseridEdited: { set: authState.user.userid },
              DateEdited: { set: date },
            },
            where: {
              NoteID: parseInt(noteId, 10),
            },
          },
          refetchQueries: [
            {
              query: LIST_STANDARD_CASE_HISTORY_NOTES_QUERY,
            },
            {
              query: SINGLE_STANDARD_CASE_HISTORY_NOTE_QUERY,
              variables: {
                where: { NoteID: parseInt(noteId, 10) },
              },
            },
          ],
        });
        onComplete();
        toast.success("Standard Note updated successfully.");
      }
      onComplete();
    } catch (err) {
      toast.error("Error saving Standard Note.");
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (e) => {
    e.preventDefault();

    const performDelete = async () => {
      try {
        setLoading(true);
        await deleteStandardNote({
          variables: {
            where: {
              NoteID: parseInt(noteId, 10),
            },
          },
          awaitRefetchQueries: true,
          refetchQueries: [
            {
              query: LIST_STANDARD_CASE_HISTORY_NOTES_QUERY,
            },
            {
              query: SINGLE_STANDARD_CASE_HISTORY_NOTE_QUERY,
              variables: {
                where: { NoteID: parseInt(noteId, 10) },
              },
            },
          ],
        });
        toast.success("Standard Note deleted successfully.");
        onComplete();
      } catch (err) {
        const message =
          err instanceof CustomCustomTypeError
            ? err.message
            : "Error trying to delete Standard Note.";
        toast.error(message);
      } finally {
        setLoading(false);
      }
    };
    setModalOpen(
      true,
      <Confirmation
        message="Are you sure you want to delete this Standard Note?"
        onCancel={() =>
          setModalOpen(
            true,
            <StandardNoteModal noteId={noteId} onComplete={onComplete} />
          )
        }
        onConfirm={performDelete}
      />
    );
  };

  const isDisabled = queryLoading || loading;

  return (
    <form id="case-type-form" onSubmit={handleSubmit}>
      <header className="page-head">
        <div className="page-head-start">
          <Title size={5}>
            {[noteId ? "Create" : "Edit", "Standard Note"].join(" ")}
          </Title>
        </div>
        <div className="page-head-end">
          <Button.Group hasAddons>
            <Button
              size="small"
              type="button"
              onClick={() => onComplete(false)}
            >
              <span>Cancel</span>
            </Button>
            <Button
              color="primary"
              disabled={isDisabled}
              form="case-type-form"
              size="small"
              state={loading ? "loading" : ""}
              type="submit"
            >
              <span>Submit</span>
            </Button>
          </Button.Group>
        </div>
      </header>
      <hr />
      <StandardNoteForm
        disabled={isDisabled}
        inputs={inputs}
        onChange={handleChange}
      />
      <hr />
      {noteId && (
        <Button
          color="danger"
          disabled={loading}
          size="small"
          type="button"
          onClick={handleDelete}
        >
          <span>Delete</span>
        </Button>
      )}
    </form>
  );
};

StandardNoteModal.propTypes = {
  onComplete: PropTypes.func,
  noteId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

StandardNoteModal.defaultProps = {
  onComplete: () => null,
  noteId: "",
};

export default StandardNoteModal;
