/* eslint-disable no-sequences */
/* eslint-disable no-return-assign */
/* eslint-disable no-bitwise */
import { toast } from "react-toastify";
import { isValid, set } from "date-fns";

import { DateTime } from "luxon";

const { REACT_APP_DOCUMENT_HOST } = process.env;

export const customToast = {
  success: (message) => {
    toast.success(message, { autoClose: 1000, pauseOnHover: false });
  },
  error: (message) => {
    toast.error(message, { autoClose: 4000 });
  },
  warning: (message) => {
    toast.error(message, { autoClose: 4000 });
  },
  erfsuccess: (message) => {
    toast.success(message, { autoClose: 4000 });
  },
};

export const debounce = (fn, ms = 0) => {
  let timeoutId;
  return function foo(...args) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn.apply(this, args), ms);
  };
};

export const throttle = (fn, wait) => {
  let inThrottle;
  let lastFn;
  let lastTime;
  return function throttledFunction(...args) {
    const context = this;

    if (!inThrottle) {
      fn.apply(context, args);
      lastTime = Date.now();
      inThrottle = true;
    } else {
      clearTimeout(lastFn);
      lastFn = setTimeout(() => {
        if (Date.now() - lastTime >= wait) {
          fn.apply(context, args);
          lastTime = Date.now();
        }
      }, Math.max(wait - (Date.now() - lastTime), 0));
    }
  };
};

export const getDateEST = () =>
  DateTime.now().setZone("America/New_York").toFormat("LL/dd/yyyy, HH:mm:ss");

export const formatPhoneNumber = (phoneNumber): [boolean, string] => {
  const cleaned =
    typeof phoneNumber === "string"
      ? phoneNumber.replace(/\D/g, "")
      : (phoneNumber && phoneNumber[1]?.replace(/\D/g, "")) || "";
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    const intlCode = match[1] ? "+1 " : "";
    return [
      true,
      [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join(""),
    ];
  }
  return [
    false,
    typeof phoneNumber === "string"
      ? phoneNumber
      : (phoneNumber && phoneNumber[1]) || "",
  ];
};

export const formatCurrency = (n) => {
  if (n) {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(n);
  }
  return "$0.00";
};

export const getFilterState = (key, defaults) =>
  window.sessionStorage.getItem(key)
    ? JSON.parse(window.sessionStorage.getItem(key))
    : { ...defaults };

export const saveFilterState = (key, filters = {}) =>
  window.sessionStorage.setItem(key, JSON.stringify(filters));

export const convertTimeZoneDataBase = (date) => {
  const localDate = new Date(date);
  localDate.setTime(
    localDate.getTime() - localDate.getTimezoneOffset() * 60 * 1000
  );
  return localDate;
};

export const convertTimeZone = (date) => {
  const localDate = new Date(date);
  localDate.setTime(
    localDate.getTime() + localDate.getTimezoneOffset() * 60 * 1000
  );
  return localDate;
};

export const capitalize = ([first = "", ...rest], lowerRest = false) =>
  first.toUpperCase() +
  (lowerRest ? rest.join("").toLowerCase() : rest.join(""));

export const round = (n, decimals = 0) =>
  Number(`${Math.round(`${n}e${decimals}`)}e-${decimals}`);

export const capitalizeEveryWord = (str) =>
  str.replace(/\b[a-z]/g, (char) => char.toUpperCase());

export const formatSSNumber = (SSN): [boolean, string] => {
  const cleaned =
    typeof SSN === "string"
      ? SSN.replace(/\D/g, "")
      : (SSN && SSN[1]?.replace(/\D/g, "")) || "";
  const match = cleaned.match(/^(\d{3})(\d{2})(\d{4})$/);
  if (match) {
    return [true, [match[1], "-", match[2], "-", match[3]].join("")];
  }
  return [false, typeof SSN === "string" ? SSN : (SSN && SSN[1]) || ""];
};

export const unmaskCurrency = (maskedValue = "") =>
  parseFloat(maskedValue.replace(/\D/g, "") || 0, 10) / 100;

export const runPromisesInSeries = (ps) =>
  ps.reduce((p, next) => p.then(next), Promise.resolve());

export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const getDaysDiffBetweenDates = (dateInitial, dateFinal) =>
  (dateFinal - dateInitial) / (1000 * 3600 * 24);

export const calculateAge = (dob) => {
  const dateOfBirth = dob instanceof Date ? dob : new Date(dob);
  if (!isValid(dateOfBirth)) return 0;
  return Math.floor(getDaysDiffBetweenDates(dateOfBirth, new Date()) / 365);
};

export const flattenObject = (obj, prefix = "") =>
  Object.keys(obj).reduce((acc, k) => {
    const pre = prefix.length ? `${prefix}.` : "";
    if (obj[k] !== null && typeof obj[k] === "object")
      Object.assign(acc, flattenObject(obj[k], pre + k));
    else acc[pre + k] = obj[k];
    return acc;
  }, {});

export const parseQueryResultWithColumns = (x, columns) => {
  const obj = flattenObject(x);
  return columns.reduce((acc, curr) => {
    if (
      obj[`${curr.accessor}`] === null ||
      typeof obj[`${curr.accessor}`] === "undefined" ||
      (typeof obj[`${curr.accessor}`] === "string" &&
        !obj[`${curr.accessor}`].length)
    ) {
      acc[curr.Header] = "";
    } else if (curr.Cell) {
      const result = curr.Cell({
        cell: { value: obj[`${curr.accessor}`], row: { original: x } },
      });

      acc[curr.Header] =
        // if Cell fn returns a react element
        result && typeof result.type === "function"
          ? result.props.value
          : result;
    } else if (
      typeof obj[`${curr.accessor}`] === "string" &&
      obj[`${curr.accessor}`].includes(",")
    ) {
      acc[curr.Header] = String(obj[`${curr.accessor}`]).replace(/"/gi, "''"); // .replace(/,/gi, " ");
    } else {
      acc[curr.Header] = obj[`${curr.accessor}`];
    }
    return acc;
  }, {});
};

export const download = (filename, type, content) => {
  const el = document.createElement("a");
  el.setAttribute(
    "href",
    `data:text/${type};charset=utf-8,${encodeURIComponent(content)}`
  );
  el.setAttribute("download", `${filename}`);
  el.style.display = "none";
  document.body.appendChild(el);
  el.click();
  document.body.removeChild(el);
};

export const generateCSV = (headers, data) =>
  `${headers.join(",")}\n${data
    .map((d) =>
      headers
        .map((h) =>
          h.toLowerCase().includes("zip")
            ? `"=""${d[h]}"""`
            : JSON.stringify(d[h])
        )
        .join(",")
    )
    .join("\n")}`;

export const downloadCSV = async (
  client,
  { query, where, orderByMulti },
  dataName,
  columns
) => {
  const { data } = await client.query({
    query,
    variables: {
      where,
      orderByMulti,
      first: null,
    },
    fetchPolicy: "network-only",
  });
  const items = data[dataName].Payload || data[dataName];
  const result =
    items && items.map((x) => parseQueryResultWithColumns(x, columns));
  if (result && result[0]) {
    const CSV = generateCSV(Object.keys(result[0]), result);
    return download(`${dataName}.csv`, "csv", CSV);
  }
  toast.error("No Data to Export");
  return null;
};

export const getCaseDocumentPath = (
  { casenbr, dateadded, sfilename },
  includeDocumentHost = true
) => {
  const date = dateadded instanceof Date ? dateadded : new Date(dateadded);

  return [
    includeDocumentHost && REACT_APP_DOCUMENT_HOST,
    "Documents",
    `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, "0")}`,
    casenbr,
    sfilename && encodeURIComponent(sfilename),
  ]
    .filter(Boolean)
    .join("/");
};

export const getDoctorDocumentPath = (filePath, includeDocumentHost = true) => {
  const result = filePath
    .replace("T:\\", "")
    .replace("\\\\isgfileserver\\IMECentric\\", "");

  return [includeDocumentHost && REACT_APP_DOCUMENT_HOST, "IMECentric", result]
    .filter(Boolean)
    .join(result.startsWith("/") ? "" : "/");
};

export const generateUUID = () =>
  ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );

export const buildDetailsWithoutCptCodeTable = (acctDetails = []) => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const COLUMNS = [
    {
      header: "Date",
      accessor: "date",
      fn: (cell) => {
        if (!cell) return "";
        const [d] = cell.split("T");
        const date = new Date(
          ...d.split("-").map((n, i) => parseInt(i === 1 ? n - 1 : n, 10))
        );
        return date.toLocaleDateString();
      },
    },
    { header: "", accessor: "", fn: () => "", style: "min-width: 75px;" },
    { header: "Description", accessor: "longdesc" },
    {
      header: "Unit Amt",
      accessor: "unit",
      fn: (cell) => (cell ? round(cell, 2).toFixed(2) : ""),
    },
    {
      header: "Ext Amt",
      accessor: "extendedamount",
      fn: (cell) => (cell ? formatter.format(cell) : ""),
    },
  ];

  const buildTable = (columns, data) => {
    const table = `
      <div class="details-without-cpt-code">
          <table>
          <thead>
              <tr>
                  ${columns
                    .map(
                      ({ header, style }) =>
                        `<th style="${style}">${header}</th>`
                    )
                    .join("")}
              </tr>
          </thead>
          <tbody>
          ${data
            .map(
              (row) => `<tr>
              ${columns
                .map(
                  (col) =>
                    `<td>${
                      typeof col.fn === "function"
                        ? col.fn(row[col.accessor])
                        : row[col.accessor]
                    }</td>`
                )
                .join("")}
          </tr>`
            )
            .join("")}
          </tbody>
      </table>
    </div>
    `;
    return table;
  };

  return buildTable(COLUMNS, [
    ...acctDetails,
    { date: "", longdesc: "", unit: "", extendedamount: "" },
    {
      date: "",
      longdesc: "Sub Total",
      unit: "",
      extendedamount: acctDetails.reduce((a, c) => a + c.extendedamount, 0),
    },
    {
      date: "",
      longdesc: "Total",
      unit: "",
      extendedamount: acctDetails.reduce((a, c) => a + c.extendedamount, 0),
    },
  ]);
};

export const parseBase64 = (str) => {
  try {
    return atob(str);
  } catch {
    return false;
  }
};

export const clampNumber = (num, a, b) =>
  Math.max(Math.min(num, Math.max(a, b)), Math.min(a, b));

export const getDaysInQueue = (date) => {
  try {
    const d = set(convertTimeZone(date), {
      hours: 0,
      minutes: 0,
      seconds: 0,
    });

    return Math.floor(getDaysDiffBetweenDates(d, new Date()));
  } catch {
    return 0;
  }
};

export const isObject = (item) =>
  item && typeof item === "object" && !Array.isArray(item);

export const deepMerge = (target, ...sources) => {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key])
          Object.assign(target, {
            [key]: {},
          });
        deepMerge(target[key], source[key]);
      } else {
        Object.assign(target, {
          [key]: source[key],
        });
      }
    }
  }

  return deepMerge(target, ...sources);
};

export const secureContextNavigatorCopyText = (text) => {
  try {
    // navigator clipboard api needs a secure context (https or localhost)
    if (navigator.clipboard && window.isSecureContext) {
      navigator.clipboard.writeText(text).then(
        () => {
          toast.success("Copied to clipboard!");
        },
        (err) => {
          throw new Error(err);
        }
      );
    } else {
      const textArea = document.createElement("textarea");
      textArea.value = text;

      // Avoid scrolling to bottom
      textArea.style.top = "0";
      textArea.style.left = "0";
      textArea.style.position = "fixed";

      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      const successful = document.execCommand("copy");
      document.body.removeChild(textArea);
      if (successful) {
        toast.success("Copied to clipboard!");
      } else {
        toast.error(
          "Something went wrong copying this text. Please try manually."
        );
      }
    }
  } catch {
    toast.error("Something went wrong copying this text. Please try manually.");
  }
};

export const openInNewTab = (url: string): void => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};

export const isWindows = () => {
  const { platform } = window.navigator;
  return platform.includes("Win");
};

export const isMacintosh = () => {
  const { platform } = window.navigator;
  return platform.includes("Mac");
};

export const isString = (val) => typeof val === "string";

export const equals = (a, b) => {
  if (a === b) return true;

  if (a instanceof Date && b instanceof Date)
    return a.getTime() === b.getTime();

  if (!a || !b || (typeof a !== "object" && typeof b !== "object"))
    return a === b;

  if (a.prototype !== b.prototype) return false;

  const keys = Object.keys(a);
  if (keys.length !== Object.keys(b).length) return false;

  return keys.every((k) => equals(a[k], b[k]));
};

export { default as isNull } from "./isNull";
export { default as isUndefined } from "./isUndefined";
export { default as isNil } from "./isNil";
export { default as convertInputToVariables } from "./convertInputToVariables";
export { default as omit } from "./omit";
export { default as addMinutesToDate } from "./addMinutesToDate";
export { default as initializeArrayWithRange } from "./initializeArrayWithRange";
export { default as prettyBytes } from "./prettyBytes";
export { default as groupBy } from "./groupBy";
export { default as calcShouldDisable } from "./calcShouldDisable";
export { default as dolValidations } from "./dolValidations";
export { default as activeProviderWithoutRecordType } from "./activeProviderWithoutRecordType";
