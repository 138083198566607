import { gql } from "@apollo/client";

export const CREATE_DOCTOR_LOCATION_MUTATION = gql`
  mutation CREATE_DOCTOR_LOCATION_MUTATION($data: DoctorLocationCreateInput!) {
    createDoctorLocation(data: $data) {
      doctorcode
      locationcode
    }
  }
`;

export const UPDATE_DOCTOR_LOCATION_MUTATION = gql`
  mutation UPDATE_DOCTOR_LOCATION_MUTATION(
    $data: DoctorLocationUpdateInput!
    $where: DoctorLocationWhereUniqueInput!
  ) {
    updateDoctorLocation(data: $data, where: $where) {
      doctorcode
      locationcode
    }
  }
`;
