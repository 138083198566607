
import React from 'react';
import PropTypes from "prop-types";
import { Dropdown, Input, Label } from "rbx";
import { v4 as uuidv4 } from "uuid";
import ClickCapture from '../../../../components/ClickCapture';

const AllstateQueryFirmField = ({ inputs, name, setShowDropdown, handleSearchChange, handleKeyDown, allstateNJAttorneys, selectAttorney, onChange, showDropdown, cursor }) => {
  const transformedLabel = name.replace(/([a-z])([A-Z])/g, "$1 $2");

  return (
    <React.Fragment>
      <Label>{transformedLabel}</Label>
      <ClickCapture>
        <Dropdown
          managed
          active={showDropdown}
          color="primary"
          style={{ width: "100%" }}
        >
          <Dropdown.Trigger style={{ flex: 1 }}>
            <Input
              autoComplete="new"
              name={name}
              size="small"
              tabIndex="0"
              value={inputs?.[`${name}`]}
              onChange={(e) => {
                onChange(e.target.name, e.target.value)
                handleSearchChange(e)
              }}
              onKeyDown={(e) => handleKeyDown(e, `${name}`)}
            />
          </Dropdown.Trigger>
          {showDropdown && allstateNJAttorneys?.length > 0 && (
            <Dropdown.Menu style={{ flex: 1 }}>
              {inputs?.[`${name}`] && (
                <Dropdown.Content>
                  {allstateNJAttorneys?.filter((attorney) => attorney?.[`${name}`].toLowerCase().startsWith(inputs?.[`${name}`].toLowerCase())).map((att, i) => (
                    <Dropdown.Item
                      active={cursor === i}
                      as="a"
                      key={uuidv4()}
                      onClick={() => selectAttorney(allstateNJAttorneys[allstateNJAttorneys.indexOf(att)])}
                    >
                      {[
                        `${[att.FirstName, att.LastName].filter(Boolean).join(" ")} - 
                                    ${att.Firm} - 
                                    ${[att.City, att.State].filter(Boolean).join(", ")
                        }`
                      ]}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Content>
              )}
            </Dropdown.Menu>
          )}
        </Dropdown>
      </ClickCapture>
    </React.Fragment>
  )
}

AllstateQueryFirmField.propTypes = {
  cursor: PropTypes.number,
  name: PropTypes.string.isRequired,
  setShowDropdown: PropTypes.func.isRequired,
  handleKeyDown: PropTypes.func.isRequired,
  handleSearchChange: PropTypes.func.isRequired,
  allstateNJAttorneys: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  showDropdown: PropTypes.bool.isRequired,
  inputs: PropTypes.object.isRequired,
  selectAttorney: PropTypes.func.isRequired
};

AllstateQueryFirmField.defaultProps = {
  cursor: 0
}

export default AllstateQueryFirmField;