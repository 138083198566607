import React, { useEffect, useState } from "react";
import {
  useParams,
  useLocation,
  Link,
  useHistory,
  Switch,
  Route,
} from "react-router-dom";
import { useLazyQuery, useMutation, useApolloClient } from "@apollo/client";
import { Tab, Title, Button } from "rbx";
import { useModal } from "../../../../context";
import { useAuth } from "../../../../context/AuthContext";
import {
  SINGLE_SERVICE_QUERY,
  LIST_SERVICE_QUERY,
  DELETE_SERVICE_MUTATION,
  LIST_CASES_QUERY,
  UPDATE_SERVICE_MUTATION,
  // CREATE_AUDIT_TRAIL_MUTATION,
} from "../../../../graphql";
import {
  customToast as toast,
  getDateEST,
  convertTimeZoneDataBase,
} from "../../../../utils";
import Confirmation from "../../../../components/Confirmation";
import ServiceQueuesPage from "./ServiceQueuesPage";
import ServiceMainPage from "./ServiceMainPage";

const INITIAL_STATE = {
  description: "",
  status: "Active",
  shortdesc: "",
  CalcFrom: "",
  DaysToCommitDate: "",
  PrintApptLetters: true,
};

const ServiceEditPage = () => {
  const { servicecode } = useParams();
  const location = useLocation();
  const history = useHistory();
  const { state: authState } = useAuth();
  const { setModalOpen } = useModal();
  const client = useApolloClient();
  const [inputs, setInputs] = useState(INITIAL_STATE);
  const [loading, setLoading] = useState(false);
  const [updated, setUpdated] = useState([]);

  const [getService, { data: serviceData }] = useLazyQuery(
    SINGLE_SERVICE_QUERY
  );
  const [deleteService] = useMutation(DELETE_SERVICE_MUTATION);
  const [updateService] = useMutation(UPDATE_SERVICE_MUTATION);
  // const [createAuditTrail] = useMutation(CREATE_AUDIT_TRAIL_MUTATION);

  useEffect(() => {
    if (servicecode) {
      getService({
        variables: {
          where: {
            servicecode: parseInt(servicecode, 10),
          },
        },
      });
    }
  }, [servicecode, getService]);

  useEffect(() => {
    if (serviceData?.service) {
      const {
        description,
        status,
        shortdesc,
        CalcFrom,
        DaysToCommitDate,
        PrintApptLetters,
      } = serviceData.service;
      const CalcFromFormatted = CalcFrom
        ? new Date(CalcFrom).toISOString().slice(0, 10)
        : "";
      setInputs((prev) => ({
        description,
        status,
        shortdesc,
        CalcFrom: CalcFromFormatted,
        DaysToCommitDate,
        PrintApptLetters,
      }));
    }
  }, [serviceData]);

  const handleDelete = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      const performDelete = async () => {
        await deleteService({
          variables: {
            where: {
              servicecode: parseInt(servicecode, 10),
            },
          },
          refetchQueries: [
            {
              query: LIST_SERVICE_QUERY,
              variables: {
                orderBy: [{ servicecode: "asc" }],
              },
              take: 25,
              skip: 0,
            },
          ],
        });
        toast.success("Service deleted successfully");
        history.push("/setup/services");
      };

      const { data: casesData } = await client.query({
        query: LIST_CASES_QUERY,
        variables: {
          where: {
            servicecode: { equals: parseInt(servicecode, 10) },
          },
          take: 1,
          skip: 0,
        },
      });
      if (casesData?.cases?.length > 0) {
        toast.error(
          "This service could not be removed because there's some Cases related"
        );
      } else {
        setModalOpen(
          true,
          <Confirmation
            message="Are you sure you want to delete this service?"
            onCancel={() => setModalOpen(false)}
            onConfirm={() => {
              setModalOpen(false);
              performDelete(parseInt(servicecode, 10));
            }}
          />
        );
      }
    } catch (err) {
      toast.error("Error trying to delete Service");
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (name, value) => {
    setInputs((prev) => ({
      ...prev,
      [name]: value,
    }));
    setUpdated((prev) => (prev.includes(name) ? prev : [...prev, name]));
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const date = convertTimeZoneDataBase(getDateEST());
      setLoading(true);
      await updateService({
        variables: {
          data: {
            shortdesc: { set: inputs.shortdesc },
            description: { set: inputs.description },
            status: { set: inputs.status },
            DaysToCommitDate: { set: parseInt(inputs.DaysToCommitDate, 10) },
            CalcFrom: { set: inputs.CalcFrom },
            useridedited: { set: authState.user.userid },
            PrintApptLetters: { set: inputs.PrintApptLetters },
            dateedited: { set: date },
          },
          where: {
            servicecode: parseInt(servicecode, 10),
          },
        },
        refetchQueries: [
          {
            query: SINGLE_SERVICE_QUERY,
            variables: {
              where: {
                servicecode: parseInt(servicecode, 10),
              },
            },
          },
        ],
      });
      // await createAuditTrail({
      //   variables: {
      //     data: {
      //       tablename: "tblService",
      //       entityid: servicecode,
      //       type: "Update",
      //       description: `Updated fields: ${updated.join(", ")}`,
      //       useridadded: authState.user.userid,
      //       dateadded: getDateEST(),
      //     },
      //   },
      // });
      setUpdated([]);
    } catch (err) {
      toast.error("Error saving Service");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <header className="page-head">
        <div className="page-head-start">
          <div>
            <Title size={4}>{serviceData?.service?.description || ""}</Title>
          </div>
        </div>
        <div className="page-head-end">
          <Button.Group hasAddons>
            <Button
              color="danger"
              size="small"
              type="button"
              onClick={handleDelete}
            >
              <span>Delete</span>
            </Button>
            <Button
              color="success"
              disabled={updated?.length === 0}
              form="service-edit-form"
              size="small"
              state={loading ? "loading" : ""}
              type="submit"
            >
              <span>Save</span>
            </Button>
          </Button.Group>
        </div>
      </header>
      <Tab.Group kind="boxed">
        <Tab
          active={location.pathname.includes("main")}
          as={Link}
          to={`/setup/services/${servicecode}/main`}
        >
          Main Information
        </Tab>
        <Tab
          active={location.pathname.includes("queues")}
          as={Link}
          to={`/setup/services/${servicecode}/queues`}
        >
          Queues
        </Tab>
      </Tab.Group>
      <Switch>
        <Route path="/setup/services/:casenbr/main">
          <ServiceMainPage
            inputs={inputs}
            onChange={handleChange}
            onSubmit={handleSubmit}
          />
        </Route>
        <Route path="/setup/services/:casenbr/queues">
          <ServiceQueuesPage servicecode={servicecode} />
        </Route>
      </Switch>
    </div>
  );
};
export default ServiceEditPage;
