import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useMutation, useApolloClient } from "@apollo/client";
import { Title, Fieldset, Button, Field, Control } from "rbx";
import { customToast as toast, getDateEST } from "../../../../utils";
import CompanyClientForm from "../CompanyClientForm";
import {
  CREATE_CLIENT_MUTATION,
  LIST_CLIENTS_QUERY,
  SINGLE_COMPANY_QUERY,
} from "../../../../graphql";
import { useAuth } from "../../../../context";

const DEFAULT_STATE = {
  firstname: "",
  lastname: "",
  addr1: "",
  addr2: "",
  city: "",
  state: "",
  zip: "",
  phone1: "",
  phone1ext: "",
  phone2: "",
  phone2ext: "",
  status: "Active",
  prefix: "",
  suffix: "",
  title: "",
  TypeCode: "",
  email: "",
  fax: "",

  // firstname: "James",
  // lastname: "Jarvis",
  // addr1: "53 Oak Hill Rd",
  // addr2: "",
  // city: "Harvard",
  // state: "MA",
  // zip: "01451",
  // phone1: "(508) 365-4281",
  // phone1ext: "",
  // phone2: "",
  // phone2ext: "",
  // status: "Active",
  // prefix: "Mr.",
  // suffix: "MD",
  // title: "Supreme Leader",
  // TypeCode: 10,
  // email: "jjarvis@gravoc.com",
};

const requiredKeys = [
  "firstname",
  "lastname",
  "addr1",
  "city",
  "state",
  "zip",
  "email",
  "phone1",
];

const convertInputToVariables = (variables, keys, adding = false) =>
  keys.reduce((acc, curr) => {
    if (variables[curr]) {
      acc[curr] = adding ? variables[curr] : { set: variables[curr] };
    }
    return acc;
  }, {});

const CompanyClientModal = ({ companycode, onComplete }) => {
  const apollo = useApolloClient();
  const { state: authState } = useAuth();
  const [createClient] = useMutation(CREATE_CLIENT_MUTATION);
  const [loading, setLoading] = useState(false);

  const [inputs, setInputs] = useState({
    ...DEFAULT_STATE,
  });

  const handleChange = (name, value) => {
    setInputs((prev) => ({ ...prev, [name]: value }));
  };

  const handleSave = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const date = getDateEST();
      await createClient({
        variables: {
          data: {
            company: {
              connect: {
                companycode: parseInt(companycode, 10),
              },
            },
            ...convertInputToVariables(
              inputs,
              Object.keys(DEFAULT_STATE),
              true
            ),
            dateadded: date,
            dateedited: date,
            useridadded: authState?.user?.userid,
            useridedited: authState?.user?.userid,
          },
        },
        refetchQueries: [
          {
            query: LIST_CLIENTS_QUERY,
            variables: {
              where: {
                companycode: {
                  equals: parseInt(companycode, 10),
                },
              },
              orderBy: [
                { lastname: "asc" },
                {
                  firstname: "asc",
                },
              ],
            },
          },
        ],
      });

      toast.success("Client created successfully.");
      onComplete();
    } catch (err) {
      toast.error("Error creating Client.");
    } finally {
      setLoading(false);
    }
  };

  const handleInheritFromCompany = async () => {
    if (companycode) {
      const { data: CompanyData } = await apollo.query({
        query: SINGLE_COMPANY_QUERY,
        variables: {
          where: {
            companycode: parseInt(companycode, 10),
          },
        },
      });
      const company = CompanyData?.company;
      if (company) {
        const { addr1, addr2, city, state, zip, phone } = company;
        setInputs((prev) => ({
          ...prev,
          addr1,
          addr2,
          city,
          state,
          zip,
          phone1: phone,
        }));
      }
    }
  };

  const isDisabled = useMemo(
    () => loading || requiredKeys.some((key) => !inputs[key]),
    [inputs, loading]
  );

  return (
    <form
      className="add-client-form"
      id="add-client-form"
      onSubmit={handleSave}
    >
      <header className="page-head">
        <div className="page-head-start">
          <Title size={5}>Create Client</Title>
        </div>
        <div className="page-head-end">
          <Button.Group hasAddons>
            <Button size="small" type="button" onClick={onComplete}>
              <span>Cancel</span>
            </Button>
            <Button
              color="primary"
              disabled={isDisabled}
              form="add-client-form"
              size="small"
              state={loading && "loading"}
              type="submit"
            >
              <span>Submit</span>
            </Button>
          </Button.Group>
        </div>
      </header>
      <hr />
      <Fieldset>
        <CompanyClientForm
          companycode={companycode}
          inputs={inputs}
          onChange={handleChange}
        />
      </Fieldset>
      <hr />
      <Field>
        <Control>
          <Button
            color="danger"
            size="small"
            type="button"
            onClick={handleInheritFromCompany}
          >
            Inherit from Company
          </Button>
        </Control>
      </Field>
      {/* <pre>
        <code>{JSON.stringify(inputs, null, 2)}</code>
      </pre> */}
    </form>
  );
};

CompanyClientModal.propTypes = {
  onComplete: PropTypes.func,
  companycode: PropTypes.string,
};

CompanyClientModal.defaultProps = {
  onComplete: (e) => e,
  companycode: null,
};

export default CompanyClientModal;
