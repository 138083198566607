import React from "react";
import PropTypes from "prop-types";
import { Control, Label, Input, Field } from "rbx";
import StateSelect from "../../../../../components/StateSelect";
import ZipInput from "../../../../../components/ZipInput";
import PhoneNumberInput from "../../../../../components/PhoneNumberInput";
import OtherPartyTypeSelect from "../../../../../components/OtherPartyTypeSelect";
import StatusSelect from "../../../../../components/StatusSelect";

const OtherPartyForm = ({ inputs, disabled, onChange, adding }) => (
  <div>
    <Field className="op-field">
      <Control expanded>
        <Label>Contact First Name</Label>
        <Input
          required
          autoComplete="new"
          disabled={disabled}
          maxLength={50}
          name="firstname"
          placeholder="First name"
          size="small"
          value={inputs.firstname}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
      <Control expanded>
        <Label>Contact Last Name</Label>
        <Input
          required
          autoComplete="new"
          disabled={disabled}
          maxLength={50}
          name="lastname"
          placeholder="Last name"
          size="small"
          value={inputs.lastname}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
      <Control expanded>
        <Label>Company</Label>
        <Input
          required
          autoComplete="new"
          disabled={disabled}
          maxLength={50}
          name="companyname"
          placeholder="Company"
          size="small"
          value={inputs.companyname}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
      <Control className="no-expanded">
        <StatusSelect
          disabled={disabled}
          label="Status"
          name="status"
          value={inputs.status}
          onChange={onChange}
        />
      </Control>
      <Control className="no-expanded">
        <OtherPartyTypeSelect
          required
          disabled={disabled}
          label="Type"
          name="OPSubType"
          size="small"
          value={inputs.OPSubType}
          onChange={(name, value) => onChange(name, value)}
        />
      </Control>
    </Field>
    <Field className="op-field">
      <Control expanded>
        <Label>Phone</Label>
        <PhoneNumberInput
          required
          disabled={disabled}
          name="phone"
          placeholder="Phone"
          value={inputs.phone}
          onChange={onChange}
        />
      </Control>
      <Control expanded>
        <Label>Address 1</Label>
        <Input
          required
          autoComplete="new"
          disabled={disabled}
          maxLength={50}
          name="addr1"
          placeholder="Address 1"
          size="small"
          value={inputs.addr1}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
      <Control expanded>
        <Label>City</Label>
        <Input
          required
          autoComplete="new"
          disabled={disabled}
          maxLength={50}
          name="city"
          placeholder="City"
          size="small"
          value={inputs.city}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
      <Control className="no-expanded" style={{ minWidth: "88px" }}>
        <StateSelect
          required
          disabled={disabled}
          id="state"
          label="State"
          name="state"
          size="small"
          value={inputs.state}
          onChange={onChange}
        />
      </Control>
      <Control className="no-expanded" style={{ minWidth: "201px" }}>
        <ZipInput
          required
          disabled={disabled}
          expanded={false}
          id="zip"
          name="zip"
          state={inputs.state}
          value={inputs.zip || ""}
          onChange={onChange}
        />
      </Control>
    </Field>
  </div>
);

OtherPartyForm.propTypes = {
  inputs: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  adding: PropTypes.bool,
};

OtherPartyForm.defaultProps = {
  disabled: false,
  adding: false,
};

export default OtherPartyForm;
