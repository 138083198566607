/* eslint-disable import/prefer-default-export */
import { gql } from "@apollo/client";

export const RATS_CREATE_MEDICAL_PROVIDER_MUTATION = gql`
  mutation CreateMedicalProvider($input: RatsMedicalProviderInput!) {
    createMedicalProvider(input: $input) {
      OK
      MPid
    }
  }
`;
