import { gql } from "@apollo/client";

export const CREATE_CASE_MUTATION = gql`
  mutation createCaseItem($data: CaseItemCreateInput!) {
    createCaseItem(data: $data) {
      casenbr
      ApptDate
      DoctorName
      status
      casetype
      priority
      commitdate
      doctorspecialty
      ApptDate
      Appttime
      IMECompany
      marketercode
      QARep
      SelectedAllstateProcessor
      schedulercode
      servicecode
      Jurisdiction
    }
  }
`;

export const UPDATE_CASE_MUTATION = gql`
  mutation updateCaseItem(
    $data: CaseItemUpdateInput!
    $where: CaseItemWhereUniqueInput!
  ) {
    updateCaseItem(data: $data, where: $where) {
      casenbr
      ApptDate
      DoctorName
      status
      casetype
      priority
      commitdate
      doctorspecialty
      ApptDate
      Appttime
      IMECompany
      marketercode
      QARep
      SelectedAllstateProcessor
      schedulercode
      servicecode
      Jurisdiction
      doctor {
        doctorcode
        firstname
        lastname
      }
      Status {
        statuscode
        statusdesc
        shortdesc
        type
        subtype
      }
    }
  }
`;

// CASE ISSUES
export const CREATE_CASE_ISSUE_MUTATION = gql`
  mutation createCaseIssue($data: CaseIssueCreateInput!) {
    createCaseIssue(data: $data) {
      casenbr
      issuecode
      dateadded
      useridadded
    }
  }
`;

export const UPDATE_CASE_ISSUE_MUTATION = gql`
  mutation updateCaseIssue(
    $data: CaseIssueUpdateInput!
    $where: CaseIssueWhereUniqueInput!
  ) {
    updateCaseIssue(data: $data, where: $where) {
      casenbr
      dateadded
      issuecode
      useridadded
    }
  }
`;

export const DELETE_CASE_ISSUE_MUTATION = gql`
  mutation deleteCaseIssue($where: CaseIssueWhereUniqueInput!) {
    deleteCaseIssue(where: $where) {
      casenbr
      issuecode
    }
  }
`;

// CASE PROBLEMS
export const CREATE_CASE_PROBLEM_MUTATION = gql`
  mutation createCaseProblem($data: CaseProblemCreateInput!) {
    createCaseProblem(data: $data) {
      casenbr
      dateadded
      problemcode
      useridadded
    }
  }
`;

export const UPDATE_CASE_PROBLEM_MUTATION = gql`
  mutation updateCaseProblem(
    $data: CaseProblemUpdateInput!
    $where: CaseProblemWhereUniqueInput!
  ) {
    updateCaseProblem(data: $data, where: $where) {
      casenbr
      dateadded
      problemcode
      useridadded
    }
  }
`;

export const DELETE_CASE_PROBLEM_MUTATION = gql`
  mutation deleteCaseProblem($where: CaseProblemWhereUniqueInput!) {
    deleteCaseProblem(where: $where) {
      casenbr
      problemcode
    }
  }
`;

export const DUPLICATE_CASE_ITEM_MUTATION = gql`
  mutation duplicateCaseItem(
    $data: CaseItemUpdateInput!
    $where: CaseItemWhereUniqueInput!
    $updateMasterCase: Boolean!
  ) {
    duplicateCaseItem(
      data: $data
      where: $where
      updateMasterCase: $updateMasterCase
    ) {
      casenbr
      dateadded
      issuecode
      useridadded
    }
  }
`;

export const ADD_EXAMINEE_SUB_CASE_MUTATION = gql`
  mutation AddExamineeSubCaseItem(
    $data: CaseItemUpdateInput!
    $where: CaseItemWhereUniqueInput!
  ) {
    addExamineeSubCaseItem(data: $data, where: $where) {
      casenbr
      dateadded
      issuecode
      useridadded
    }
  }
`;

export const BATCH_AWAITING_APPT_UPDATE_MUTATION = gql`
  mutation BATCH_AWAITING_APPT_UPDATE_MUTATION(
    $input: BatchAwaitingApptUpdateInput!
  ) {
    batchAwaitingApptUpdate(input: $input) {
      casenbr
      exceptions {
        DisplayMessage
        ExceptionDefID
        Message
        RequireComment
      }
    }
  }
`;

export const RESCHEDULE_CASE_MUTATION = gql`
  mutation RESCHEDULE_CASE_MUTATION($input: RescheduleInput!) {
    rescheduleCase(input: $input) {
      casenbr
      doctorcode
      status
      doctorlocation
      ApptDate
      Appttime
      doctorspecialty
      dateedited
      useridedited
      LastScheduledBy
      LastScheduledDate
    }
  }
`;

export const CANCEL_CASE_MUTATION = gql`
  mutation CANCEL_CASE_MUTATION($input: CancelCaseInput!) {
    cancelCase(input: $input) {
      casenbr
      doctorcode
      status
      doctorlocation
      ApptDate
      Appttime
      doctorspecialty
      dateedited
      useridedited
      LastScheduledBy
      LastScheduledDate
    }
  }
`;
