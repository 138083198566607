import React from "react";

import { Switch, Route } from "react-router-dom";

import CasePage from "./CasePage";
import CaseListPage from "./CaseListPage";
import CaseListAwaitingAppt from "./CaseListAwaitingAppt";
import CaseListAccountingPage from "./CaseListAccountingPage";
import CaseListInQA from "./CaseListInQA";

const CaseRouter = (props) => (
  <Switch>
    <Route path="/cases/in-qa">
      <CaseListInQA />
    </Route>
    <Route path="/cases/awaiting-appointment">
      <CaseListAwaitingAppt />
    </Route>
    <Route path="/cases/accounting">
      <CaseListAccountingPage />
    </Route>
    <Route path="/cases/:casenbr">
      <CasePage />
    </Route>
    <Route path="/cases">
      <CaseListPage />
    </Route>
  </Switch>
);

export default CaseRouter;
