import React from "react";
import { Generic, Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const COLUMNS = [
  {
    Header: "Specialty",
    id: "specialty:description",
    accessor: "specialty.description",
  },
];

export const generateColumns = (handleDelete) => [
  {
    Header: "",
    id: "delete",
    Cell: (cell) => (
      <Generic
        style={{ textAlign: "center" }}
        type="button"
        onClick={() => handleDelete(cell.row.original)}
      >
        <Icon color="danger">
          <FontAwesomeIcon icon="times" />
        </Icon>
      </Generic>
    ),
  },
  ...COLUMNS,
];
export default COLUMNS;
