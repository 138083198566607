import React, { useState } from "react";
import PropTypes from "prop-types";
import { Title, Fieldset, Button, Field } from "rbx";
import { useMutation } from "@apollo/client";

import RequestGeneratorEntryForm from "../RequestGeneratorEntryForm";

import DataTable from "../../../../components/DataTable";

import {
  RATS_REQUEST_GENERATOR_QUERY,
  RATS_CREATE_TEMP_REQUESTOR_MUTATION,
} from "../../../../graphql";

import { customToast as toast } from "../../../../utils";

import { useReferrer, useClientMaster } from "../../../../hooks";
import BusinessCard from "../BusinessCard";

const DEFAULT_STATE = {
  RGnameLast: "",
  RGnameFirst: "",
  RGcourtID: "",
  RGemail: "",
  RGemail2: "",
  COofficeName: "",
  COaddress1: "",
  COaddress2: "",
  COcity: "",
  COstate: "",
  COzip: "",
  COphone1: "",
  COfax: "",
};

const rgFields = [
  "RGnameLast",
  "RGnameFirst",
  "RGcourtID",
  "RGemail",
  "RGemail2",
];

const officeFields = [
  "COofficeName",
  "COaddress1",
  "COaddress2",
  "COcity",
  "COstate",
  "COzip",
  "COphone1",
  "COfax",
];

const requiredFields = [
  "RGnameLast",
  "RGnameFirst",
  "COofficeName",
  "COaddress1",
  "COcity",
  "COstate",
  "COzip",
];

const RequestGeneratorEntryModal = ({
  onComplete,
  title,
  mode,
  label,
  onRowClick,
  onTempRequestorAdd,
  shouldFilter,
}) => {
  const [inputs, setInputs] = useState({ ...DEFAULT_STATE });
  const [RGWhere, setRGWhere] = useState({ AND: [] });
  const [loading, setLoading] = useState(false);
  const { filterDetails } = useClientMaster();
  const applyFilter = filterDetails?.shouldFilterContacts && shouldFilter;

  const [createTempRequestor] = useMutation(
    RATS_CREATE_TEMP_REQUESTOR_MUTATION
  );

  const referrer = useReferrer();

  const handleChange = (name, value) => {
    setInputs((prev) => ({ ...prev, [name]: value }));
  };

  const handleReset = () => {
    setInputs({ ...DEFAULT_STATE });
    setRGWhere({ AND: [] });
  };

  const handleFilter = async () => {
    const where = { AND: [] };
    const clientoffice = {};
    for (const input in inputs) {
      if (
        Object.prototype.hasOwnProperty.call(inputs, input) &&
        inputs[input]
      ) {
        if (officeFields.some((field) => field === input)) {
          if (clientoffice.is) {
            clientoffice.is[input] = { contains: inputs[input] };
          } else {
            clientoffice.is = { [input]: { contains: inputs[input] } };
          }
        } else {
          where.AND.push({ [input]: { contains: inputs[input] } });
        }
      }
    }
    if (clientoffice.is) {
      where.AND.push({ clientoffice });
    }

    if (applyFilter && where.AND.length) {
      where.AND.push({
        clientoffice: {
          is: {
            COclientMasterID: {
              equals: filterDetails?.clientMasterID,
            },
          },
        },
      });
    }
    setRGWhere(where);
  };

  const handleAddRequestor = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);

      const input = {
        TRlastName: inputs.RGnameLast?.trim(),
        TRfirstName: inputs.RGnameFirst?.trim(),
        TRcourtID: inputs.RGcourtID,
        TRcompany: inputs.COofficeName?.trim(),
        TRaddress1: inputs.COaddress1?.trim(),
        TRaddress2: inputs.COaddress2,
        TRcity: inputs.COcity?.trim(),
        TRstate: inputs.COstate,
        TRzip: inputs.COzip,
        TRemail: inputs.RGemail,
        TRemail2: inputs.RGemail2,
        TRphone: inputs.COphone1,
        TRfax: inputs.COfax,
        TRclinkUserID: referrer?.Email || "Unknown",
      };

      const {
        data: { createTempRequestor: result },
      } = await createTempRequestor({ variables: { input } });

      if (result?.OK) {
        onTempRequestorAdd(result.TRid);
      } else {
        toast.error(
          "Something went wrong, please try again later or contact support"
        );
      }
    } catch (err) {
      toast.error(
        "Something went wrong, please try again later or contact support"
      );
    } finally {
      setLoading(false);
    }
  };

  const disabled =
    mode === "add" && requiredFields.some((field) => inputs[field] === "");

  const searchAndResetDisabled = !!(
    [...rgFields, ...officeFields].filter((field) => inputs[field] !== "")
      .length === 0
  );

  return (
    <React.Fragment>
      <form id="rg-form" onSubmit={handleAddRequestor}>
        <header className="page-head">
          <div className="page-head-start">
            <Title size={5}>{title}</Title>
          </div>
          <div className="page-head-end">
            <Button.Group hasAddons>
              <Button
                disabled={loading}
                size="small"
                type="button"
                onClick={onComplete}
              >
                <span>Cancel</span>
              </Button>
              {mode === "add" && (
                <Button
                  color="primary"
                  disabled={disabled || loading}
                  form="rg-form"
                  size="small"
                  state={loading ? "loading" : ""}
                  type="submit"
                >
                  <span>Submit</span>
                </Button>
              )}
            </Button.Group>
          </div>
        </header>
        <hr />
        <Field>
          <Fieldset>
            <RequestGeneratorEntryForm
              disabled={loading}
              inputs={inputs}
              label={label}
              mode={mode}
              onChange={handleChange}
              onFilter={handleFilter}
              onReset={handleReset}
            />
          </Fieldset>
        </Field>
      </form>
      {!!RGWhere.AND.length && (
        <React.Fragment>
          <hr />
          <DataTable
            aggregateKey="RGid"
            aggregateName="aggregateRequestgenerator"
            columns={[
              {
                Header: "Info",
                id: "businessCard",
                disableSortBy: true,
                Cell: (cell) => (
                  <BusinessCard
                    isInTable
                    data={cell.row.values}
                    disabled={!cell.row.values}
                    onSeeMoreClick={() => null}
                  />
                ),
              },
              {
                Header: "RG ID",
                id: "RGid",
                accessor: "RGid",
              },
              {
                Header: "First Name",
                id: "RGnameFirst",
                accessor: "RGnameFirst",
              },
              {
                Header: "Last Name",
                id: "RGnameLast",
                accessor: "RGnameLast",
              },
              {
                Header: "Company",
                id: "clientoffice:COofficeName",
                accessor: "clientoffice.COofficeName",
              },
              {
                Header: "Addr 1",
                id: "clientoffice:COaddress1",
                accessor: "clientoffice.COaddress1",
              },
              {
                Header: "Addr 2",
                id: "clientoffice:COaddress2",
                accessor: "clientoffice.COaddress2",
              },
              {
                Header: "State",
                id: "clientoffice:COstate",
                accessor: "clientoffice.COstate",
              },
              {
                Header: "City",
                id: "clientoffice:COcity",
                accessor: "clientoffice.COcity",
              },
              {
                Header: "Zip",
                id: "clientoffice:COzip",
                accessor: "clientoffice.COzip",
              },
              {
                Header: "Email",
                id: "RGemail",
                accessor: "RGemail",
              },
              {
                Header: "Phone #",
                id: "clientoffice:COphone1",
                accessor: "clientoffice.COphone1",
              },
            ]}
            loaderHeight="293px"
            name="requestgenerators"
            orderBy={[
              { id: "RGnameLast", desc: false },
              { id: "RGnameFirst", desc: false },
            ]}
            pageSize={10}
            pageSizeKey="RG_PAGE_SIZE"
            query={RATS_REQUEST_GENERATOR_QUERY}
            showPageSize={false}
            where={RGWhere}
            onCellClick={(cell, e) => {
              const {
                column: { id },
              } = cell;
              if (id === "businessCard") {
                e.stopPropagation();
              }
            }}
            onRowClick={onRowClick}
          />
        </React.Fragment>
      )}
      <hr />
      {mode === "search" && (
        <Field kind="group">
          <Button.Group>
            <Button
              color="danger"
              disabled={searchAndResetDisabled}
              size="small"
              onClick={handleReset}
            >
              Reset
            </Button>
            <Button
              color="primary"
              disabled={searchAndResetDisabled}
              size="small"
              onClick={handleFilter}
            >
              Search
            </Button>
          </Button.Group>
        </Field>
      )}
    </React.Fragment>
  );
};

RequestGeneratorEntryModal.propTypes = {
  onComplete: PropTypes.func,
  onRowClick: PropTypes.func,
  onTempRequestorAdd: PropTypes.func,
  title: PropTypes.string,
  mode: PropTypes.string,
  label: PropTypes.string,
  shouldFilter: PropTypes.bool,
};

RequestGeneratorEntryModal.defaultProps = {
  onComplete: (e) => e,
  onRowClick: () => null,
  onTempRequestorAdd: () => null,
  title: "",
  mode: "",
  label: "",
  shouldFilter: true,
};

export default RequestGeneratorEntryModal;
