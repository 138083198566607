import React from "react";
import PropTypes from "prop-types";
import { Field, Control, Label, Textarea } from "rbx";
import { useParams } from "react-router-dom";

import { CCAddressDisplay } from "../../../../components/CCAddress";
import CCAddressSelect from "../../../../components/CCAddressSelect";

import CaseExamineeContactList from "../../components/CaseExamineeContactList";

import CCAddressActionButtons from "../../components/CCAddressActionButtons";

const Attorney = ({ inputs, onChange, onSubmit }) => {
  const { casenbr: pcasenbr, workid } = useParams();
  const casenbr = pcasenbr || workid;
  return (
    <form
      className="case-attorney-page"
      id="edit-case-form"
      onSubmit={onSubmit}
    >
      <div className="case-attorney-container">
        <div className="case-attorney">
          <Field kind="group">
            <CCAddressSelect
              label="Plaintiff Attorney"
              name="plaintiffattorneycode"
              value={inputs.plaintiffattorneycode}
              onChange={onChange}
            />
            <Control>
              <Label>&nbsp;</Label>
              <CCAddressActionButtons
                cccode={inputs.plaintiffattorneycode}
                name="plaintiffattorneycode"
                onChange={onChange}
              />
            </Control>
          </Field>
          <Field>
            <CCAddressDisplay cccode={inputs.plaintiffattorneycode} />
          </Field>
        </div>
        <div className="case-attorney">
          <Field kind="group">
            <CCAddressSelect
              label="Defense Attorney"
              name="defenseattorneycode"
              value={inputs.defenseattorneycode}
              onChange={onChange}
            />
            <Control>
              <Label>&nbsp;</Label>
              <CCAddressActionButtons
                cccode={inputs.defenseattorneycode}
                name="defenseattorneycode"
                onChange={onChange}
              />
            </Control>
          </Field>
          <Field>
            <CCAddressDisplay cccode={inputs.defenseattorneycode} />
          </Field>
        </div>
      </div>
      <hr />
      <CaseExamineeContactList casenbr={casenbr} />
      <hr />

      <Field>
        <Control style={{ marginBottom: "1rem" }}>
          <Label>CC / Attorney Notes</Label>
          <Textarea
            name="AttorneyNote"
            placeholder="Note"
            size="small"
            value={inputs.AttorneyNote}
            onChange={onChange}
          />
        </Control>
      </Field>
    </form>
  );
};

Attorney.propTypes = {
  inputs: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default Attorney;
