import { gql } from "@apollo/client";

export const CREATE_SERVICE_MUTATION = gql`
  mutation CREATE_SERVICE_MUTATION($data: ServiceCreateInput!) {
    createService(data: $data) {
      servicecode
      description
    }
  }
`;

export const UPDATE_SERVICE_MUTATION = gql`
  mutation UPDATE_SERVICE_MUTATION(
    $data: ServiceUpdateInput!
    $where: ServiceWhereUniqueInput!
  ) {
    updateService(data: $data, where: $where) {
      servicecode
      description
    }
  }
`;

export const DELETE_SERVICE_MUTATION = gql`
  mutation DELETE_SERVICE_MUTATION($where: ServiceWhereUniqueInput!) {
    deleteService(where: $where) {
      servicecode
    }
  }
`;

export const DUPLICATE_SERVICE_MUTATION = gql`
  mutation DUPLICATE_SERVICE_MUTATION($input: ServiceWhereUniqueInput!) {
    duplicateService(input: $input) {
      servicecode
      description
    }
  }
`;

export const CREATE_SERVICE_CASE_TYPE_OFFICE_MUTATION = gql`
  mutation CREATE_CASE_TYPE_OFFICE_MUTATION(
    $data: CaseTypeServiceCreateInput!
  ) {
    createCaseTypeService(data: $data) {
      officecode
      casetype
      servicecode
    }
  }
`;

export const DELETE_SERVICE_CASE_TYPE_OFFICE_MUTATION = gql`
  mutation DELETE_SERVICE_CASE_TYPE_OFFICE_MUTATION(
    $where: CaseTypeServiceWhereUniqueInput!
  ) {
    deleteCaseTypeService(where: $where) {
      officecode
      casetype
      servicecode
    }
  }
`;

export const UPDATE_SERVICE_CASE_TYPE_MUTATION = gql`
  mutation UpdateCaseTypeService(
    $data: CaseTypeServiceUpdateInput!
    $where: CaseTypeServiceWhereUniqueInput!
  ) {
    updateCaseTypeService(data: $data, where: $where) {
      casetype
      servicecode
      officecode
    }
  }
`;
