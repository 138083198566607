import { gql } from "@apollo/client";

// eslint-disable-next-line
export const CREATE_APOLLO_CLAIM_MUTATION = gql`
  mutation CREATE_APOLLO_CLAIM_MUTATION($data: ApolloClaimsCreateInput!) {
    createApolloClaims(data: $data) {
      ClaimID
    }
  }
`;
