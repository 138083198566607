import { useEffect, useState } from "react";

import { useApolloClient } from "@apollo/client";
import useMasterSubCase from "./useMasterSubCase";
import { FIND_FIRST_CASE_ITEM_QUERY } from "../graphql";

export default function useHasAssociatedCases(casenbr) {
  const client = useApolloClient();
  const [hasAssociatedCases, setHasAssociatedCases] = useState(false);
  const { mastersubcase, mastercasenbr } = useMasterSubCase(casenbr);
  useEffect(() => {
    const checkIfCaseHasSubcases = async () => {
      const where = {};
      if (mastersubcase === "M") {
        where.mastersubcase = { equals: "S" };
        where.mastercasenbr = { equals: parseInt(casenbr, 10) };
      }
      if (mastersubcase === "S") {
        where.mastercasenbr = { equals: parseInt(mastercasenbr, 10) };
        where.casenbr = { not: { equals: parseInt(casenbr, 10) } };
      }

      if (mastercasenbr) {
        where.casenbr = { not: { equals: parseInt(casenbr, 10) } };
        where.mastercasenbr = { equals: parseInt(mastercasenbr, 10) };
      }

      if (Object.keys(where).length) {
        const {
          data: { findFirstCaseItem },
        } = await client.query({
          query: FIND_FIRST_CASE_ITEM_QUERY,
          variables: {
            where,
          },
        });
        setHasAssociatedCases(!!findFirstCaseItem);
      }
    };
    checkIfCaseHasSubcases();
  }, [mastersubcase, mastercasenbr, casenbr, client]);

  return hasAssociatedCases;
}
