import { gql } from "@apollo/client";

export const CREATE_QUEUE_MUTATION = gql`
  mutation CREATE_QUEUE_MUTATION($data: QueueCreateInput!) {
    createQueue(data: $data) {
      statuscode
      statusdesc
    }
  }
`;

export const UPDATE_QUEUE_MUTATION = gql`
  mutation UPDATE_QUEUE_MUTATION(
    $data: QueueUpdateInput!
    $where: QueueWhereUniqueInput!
  ) {
    updateQueue(data: $data, where: $where) {
      statuscode
      statusdesc
    }
  }
`;

export const DELETE_QUEUE_MUTATION = gql`
  mutation DELETE_QUEUE_MUTATION($where: QueueWhereUniqueInput!) {
    deleteQueue(where: $where) {
      statuscode
    }
  }
`;
