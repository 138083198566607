import { gql } from "@apollo/client";

export const ALL_USERS_QUERY = gql`
  query users(
    $take: Int
    $skip: Int
    $orderBy: [UserOrderByInput!]
    $where: UserWhereInput
  ) {
    users(take: $take, orderBy: $orderBy, skip: $skip, where: $where) {
      userid
      email
      lastname
      firstname
      dateadded
      dateedited
      useridadded
      useridedited
      usertype
      fldActive
    }
  }
`;

export const SINGLE_USER_QUERY = gql`
  query user($where: UserWhereUniqueInput!) {
    user(where: $where) {
      userid
      email
      lastname
      firstname
      usertype
    }
  }
`;

export const FIND_FIRST_USER_QUERY = gql`
  query FIND_FIRST_USER_QUERY($where: UserWhereInput!) {
    findFirstUser(where: $where) {
      userid
      email
      lastname
      firstname
      usertype
    }
  }
`;

export const LIST_USERS_QUERY = gql`
  query users(
    $take: Int
    $skip: Int
    $orderBy: [UserOrderByInput!]
    $where: UserWhereInput
  ) {
    users(take: $take, orderBy: $orderBy, skip: $skip, where: $where) {
      userid
      email
      lastname
      firstname
      usertype
      dateadded
      useridadded
      dateedited
      useridedited
    }
    aggregateUser(where: $where) {
      count {
        userid
      }
    }
  }
`;
