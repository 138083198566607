import React from "react";
import PropTypes from "prop-types";
import { Field, Control, Label, Input } from "rbx";

import BooleanInput from "../../../../components/BooleanInput";

const SentryForm = ({ handleChange, inputs, disabled }) => (
  <Field className="grid-override is-three" kind="group">
    <Control expanded>
      <Label>Claimant Letter</Label>
      <Input
        required
        autoComplete="new"
        disabled={disabled}
        maxLength={50}
        name="RRClaimantLetter"
        size="small"
        value={inputs.RRClaimantLetter}
        onChange={(e) => handleChange(e.target.name, e.target.value)}
      />
    </Control>
    <Control>
      <Label>Claim Number</Label>
      <Input
        disabled
        name="ClaimNumber"
        size="small"
        value={inputs.ClaimNumber}
      />
    </Control>
    <Control>
      <Label>Use CA WC regs?</Label>
      <BooleanInput
        disabled={disabled}
        name="RRUseCAWCRegs"
        value={inputs.RRUseCAWCRegs}
        onChange={(name, value) => handleChange(name, value)}
      />
    </Control>
  </Field>
);

SentryForm.propTypes = {
  handleChange: PropTypes.func.isRequired,
  inputs: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default SentryForm;
