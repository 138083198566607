import React, { useState } from "react";
import PropTypes from "prop-types";

import { useMutation, useApolloClient } from "@apollo/client";
import { Title, Button } from "rbx";
import { toast } from "react-toastify";
import {
  CREATE_CLIENT_DEFAULT_DOCUMENT_MUTATION,
  ALL_CLIENT_DEFAULT_DOCUMENTS_QUERY,
  SINGLE_CLIENT_DEFAULT_DOCUMENT_QUERY,
} from "../../../../graphql";
import { getDateEST } from "../../../../utils";

import ClientDefaultDocumentForm from "../ClientDefaultDocumentForm";
import { useAuth } from "../../../../context";

const initialState = {
  documentcode: "",
  documentqueue: "",
};

const ClientDefaultDocumentModal = ({ clientcode, onComplete }) => {
  const client = useApolloClient();
  const { state: authState } = useAuth();
  const [inputs, setInputs] = useState({ ...initialState });

  const [createClientDefDocument] = useMutation(
    CREATE_CLIENT_DEFAULT_DOCUMENT_MUTATION
  );

  const handleSave = async (e) => {
    try {
      e.preventDefault();
      const date = getDateEST();
      const {
        data: { clientDefDocument },
      } = await client.query({
        query: SINGLE_CLIENT_DEFAULT_DOCUMENT_QUERY,
        variables: {
          where: {
            clientcode_documentcode_documentqueue: {
              clientcode: parseInt(clientcode, 10),
              documentcode: inputs.documentcode,
              documentqueue: inputs.documentqueue,
            },
          },
        },
        fetchPolicy: "network-only",
      });
      if (!clientDefDocument) {
        await createClientDefDocument({
          variables: {
            data: {
              clientcode: parseInt(clientcode, 10),
              documentcode: inputs.documentcode,
              documentqueue: inputs.documentqueue,
              dateadded: date,
              dateedited: date,
              useridadded: authState?.user?.userid,
              useridedited: authState.user.userid,
              order: inputs.order ? parseInt(inputs.order, 10) : null,
            },
          },
          refetchQueries: [
            {
              query: ALL_CLIENT_DEFAULT_DOCUMENTS_QUERY,
              variables: {
                where: { clientcode: { equals: parseInt(clientcode, 10) } },
              },
            },
          ],
        });
        toast.success(`Document created successfully.`);
        onComplete(true);
      } else {
        toast.warning(`This default document already exists.`);
      }
    } catch (err) {
      toast.error(`Error creating document.`);
    }
  };
  const handleChange = (name, value) => {
    setInputs((prev) => ({ ...prev, [name]: value }));
  };

  const isDisabled = !inputs.documentcode || !inputs.documentqueue;
  return (
    <form id="client-default-document-form" onSubmit={handleSave}>
      <header className="page-head">
        <div className="page-head-start">
          <Title size={5}>Create Client Default Document</Title>
        </div>
        <div className="page-head-end">
          <Button.Group hasAddons>
            <Button
              size="small"
              type="button"
              onClick={() => onComplete(false)}
            >
              <span>Cancel</span>
            </Button>
            <Button
              color="primary"
              disabled={isDisabled}
              form="client-default-document-form"
              size="small"
              type="submit"
            >
              <span>Submit</span>
            </Button>
          </Button.Group>
        </div>
      </header>
      <hr />
      <ClientDefaultDocumentForm inputs={inputs} onChange={handleChange} />
      <hr />
      {/* <pre>
        <code>{JSON.stringify(inputs, null, 2)}</code>
      </pre> */}
    </form>
  );
};

ClientDefaultDocumentModal.propTypes = {
  clientcode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onComplete: PropTypes.func,
};

ClientDefaultDocumentModal.defaultProps = {
  clientcode: "",
  onComplete: () => null,
};
export default ClientDefaultDocumentModal;
