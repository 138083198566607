import { gql } from "@apollo/client";

// eslint-disable-next-line
export const LIST_ALLSTATE_NJ_ATTORNEYS = gql`
  query FindManyAllstateNJAttorneys {
    findManyAllstateNJAttorneys {
      FirstName
      LastName
      Firm
      Email
      Address
      City
      State
      Zip
      Phone
    }
  }
`;