import React, { useState, useEffect, useRef } from "react";
import { Title, Field, Control, Button, Icon, Input } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useParams } from "react-router-dom";
import { useModal } from "../../../../context";
import { LIST_QUEUES_QUERY } from "../../../../graphql";
import { debounce } from "../../../../utils";
import DataTable from "../../../../components/DataTable";

import { QueueModal } from "../../components";
import COLUMNS from "./columns";

const DEFAULT_FILTER_STATE = {
  OR: [
    {
      statusdesc: { contains: "" },
    },
    {
      type: { contains: "" },
    },
    {
      shortdesc: { contains: "" },
    },
  ],
};

const QueuesList = () => {
  const { statuscode } = useParams();
  const { setModalOpen } = useModal();
  const [where, setWhere] = useState(DEFAULT_FILTER_STATE);
  const [searchValue, setSearchValue] = useState("");
  const isModalOpen = useRef(false);

  useEffect(() => {
    if (statuscode && !isModalOpen.current) {
      isModalOpen.current = true;
      setModalOpen(
        true,
        <QueueModal
          statuscode={parseInt(statuscode, 10)}
          onComplete={() => setModalOpen(false)}
        />
      );
    }
  }, [setModalOpen, statuscode, where]);

  const handleRowClick = (row) => {
    setModalOpen(
      true,
      <QueueModal
        statuscode={row.statuscode}
        onComplete={() => setModalOpen(false)}
      />
    );
  };
  const handleSearchChange = debounce((value) => {
    if (value) {
      setWhere({
        OR: [
          {
            type: { contains: value },
          },
          {
            statusdesc: { contains: value },
          },
          {
            shortdesc: { contains: value },
          },
        ],
      });
    } else {
      setWhere({
        OR: [
          {
            type: { contains: "" },
          },
          {
            statusdesc: { contains: "" },
          },
          {
            shortdesc: { contains: "" },
          },
        ],
      });
    }
  }, 500);

  return (
    <div>
      <div className="section-header">
        <Title style={{ marginRight: 15 }}>Queues</Title>
        <Field kind="group" style={{ paddingTop: "0.3rem" }}>
          <Control>
            <Input
              autoComplete="off"
              id="search-input"
              placeholder="Search..."
              size="small"
              value={searchValue}
              onChange={({ target: { value } }) => {
                setSearchValue(value);
                handleSearchChange(value);
              }}
            />
          </Control>
          <Control>
            <Button
              color="primary"
              size="small"
              onClick={() => {
                setModalOpen(
                  true,
                  <QueueModal adding onComplete={() => setModalOpen(false)} />
                );
              }}
            >
              <Icon>
                <FontAwesomeIcon icon="plus" />
              </Icon>
              <span>Add</span>
            </Button>
          </Control>
        </Field>
      </div>
      <DataTable
        aggregateKey="statuscode"
        aggregateName="aggregateQueue"
        columns={COLUMNS}
        name="queues"
        orderBy={[{ id: "statusdesc", desc: false }]}
        query={LIST_QUEUES_QUERY}
        where={where}
        onRowClick={handleRowClick}
      />
    </div>
  );
};

QueuesList.propTypes = {};

export default QueuesList;
