import React from "react";
import PropTypes from "prop-types";

import { Control, Input, Label } from "rbx";

import { debounce } from "../../utils";

const TimeInput = ({
  value,
  name,
  label,
  size,
  onChange,
  disabled,
  getValue,
  setValue,
  required,
}) => {
  const handleChange = debounce((e) => {
    onChange(e.target.name, setValue(e.target.value));
  }, 1000);

  return (
    <Control expanded>
      <Label>{label}</Label>
      <Input
        defaultValue={getValue(value)}
        disabled={disabled}
        key={getValue(value)}
        name={name}
        required={required}
        size={size}
        type="time"
        onChange={handleChange}
      />
    </Control>
  );
};

TimeInput.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.number,
  ]),
  name: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  getValue: PropTypes.func,
  setValue: PropTypes.func,
  required: PropTypes.bool,
};

TimeInput.defaultProps = {
  value: "",
  name: "",
  label: "Date",
  size: "small",
  onChange: () => null,
  disabled: false,
  getValue: (x) => x,
  setValue: (x) => x,
  required: false,
};

export default TimeInput;
