/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from "react";
import PropTypes from "prop-types";

import { useTable, useSortBy } from "react-table";

import { Table as RbxTable } from "rbx";

import "../DataTable/DataTable.scss";

const Table = ({ columns, data, onCellClick, initialSortBy, onChangeSort }) => {
  const tableState = {
    pageIndex: 0,
    sortBy: initialSortBy,
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { sortBy },
  } = useTable(
    {
      columns,
      data,
      initialState: { ...tableState },
    },
    useSortBy
  );

  const renderTableHeaderSort = (column) => {
    if (column.isSorted) {
      return column.isSortedDesc ? " ▼" : " ▲";
    }
    return "";
  };

  useEffect(() => {
    onChangeSort(sortBy);
  }, [onChangeSort, sortBy]);

  return (
    <div className="data-table-container">
      <div className="table-container">
        <RbxTable {...getTableProps()} bordered fullwidth hoverable narrow>
          <RbxTable.Head>
            {headerGroups.map((headerGroup) => (
              <RbxTable.Row {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <RbxTable.Heading
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    style={
                      typeof column.maxWidth === "string"
                        ? { width: column.maxWidth }
                        : { maxWidth: column.maxWidth || 400 }
                    }
                  >
                    {column.render("Header")}
                    <span>{renderTableHeaderSort(column)}</span>
                  </RbxTable.Heading>
                ))}
              </RbxTable.Row>
            ))}
          </RbxTable.Head>
          <RbxTable.Body {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <RbxTable.Row {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <RbxTable.Cell
                      {...cell.getCellProps()}
                      onClick={() => onCellClick(cell)}
                    >
                      {cell.render("Cell")}
                    </RbxTable.Cell>
                  ))}
                </RbxTable.Row>
              );
            })}
          </RbxTable.Body>
        </RbxTable>
      </div>
    </div>
  );
};

Table.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  onCellClick: PropTypes.func,
  initialSortBy: PropTypes.array,
  onChangeSort: PropTypes.func,
};

Table.defaultProps = {
  columns: [],
  data: [],
  onCellClick: () => null,
  initialSortBy: [],
  onChangeSort: () => null,
};
export default Table;
