import { gql } from "@apollo/client";

export const CREATE_APOLLO_NOTE_TYPE_MUTATION = gql`
  mutation CREATE_NOTE_TYPE_MUTATION($data: NoteTypesCreateInput!) {
    createNoteTypes(data: $data) {
      NoteTypeID
      NoteType
    }
  }
`;

export const UPDATE_APOLLO_NOTE_TYPE_MUTATION = gql`
  mutation UPDATE_NOTE_TYPE_MUTATION(
    $data: NoteTypesUpdateInput!
    $where: NoteTypesWhereUniqueInput!
  ) {
    updateNoteTypes(data: $data, where: $where) {
      NoteTypeID
      NoteType
    }
  }
`;

export const DELETE_APOLLO_NOTE_TYPE_MUTATION = gql`
  mutation DELETE_NOTE_TYPE_MUTATION($where: NoteTypesWhereUniqueInput!) {
    deleteNoteTypes(where: $where) {
      NoteTypeID
    }
  }
`;
