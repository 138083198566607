import React from "react";
import PropTypes from "prop-types";

import ExamineeForm from "../../components/ExamineeForm";

const Profile = ({ inputs, disabled, onChange }) => (
  <ExamineeForm disabled={disabled} inputs={inputs} onChange={onChange} />
);

Profile.propTypes = {
  inputs: PropTypes.object,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

Profile.defaultProps = {
  inputs: {},
  disabled: false,
  onChange: () => null,
};

export default Profile;
