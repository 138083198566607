import { gql } from "@apollo/client";

export const CREATE_EXAMINEE_MUTATION = gql`
  mutation createExaminee($data: ExamineeCreateInput!) {
    createExaminee(data: $data) {
      firstname
      lastname
      note
      chartnbr
      addr1
      addr2
      city
      state
      DOB
      SSN
    }
  }
`;

export const UPDATE_EXAMINEE_MUTATION = gql`
  mutation updateExaminee(
    $data: ExamineeUpdateInput!
    $where: ExamineeWhereUniqueInput!
  ) {
    updateExaminee(data: $data, where: $where) {
      firstname
      lastname
      note
      chartnbr
      addr1
      addr2
      city
      state
      DOB
      SSN
    }
  }
`;
