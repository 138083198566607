import React from "react";
import { useParams } from "react-router-dom";

import { useQuery } from "@apollo/client";
import { LIST_APOLLO_SERVICES_QUERY } from "../../../../graphql";
import ServiceList from "./ServiceList";

import CasePage from "../../../Case/CasePage";

const ServicesListPage = () => {
  const { claimcode, serviceid, workid } = useParams();

  const { data } = useQuery(LIST_APOLLO_SERVICES_QUERY, {
    variables: {
      where: {
        ClaimID: {
          equals: parseInt(claimcode, 10),
        },
        OR: [
          {
            ParentServiceID: { equals: null },
          },
          {
            ParentServiceID: { equals: 0 },
          },
        ],
      },
      orderBy: [{ ServiceID: "desc" }],
    },
  });

  const services = Array.isArray(data?.findManyServices)
    ? data.findManyServices
    : [];

  return (
    <div>
      <div className="service-list-wrapper">
        <ServiceList services={services} />
      </div>
      <hr />
      <div className="service-work-area">
        {serviceid && workid && <CasePage />}
      </div>
    </div>
  );
};

ServicesListPage.propTypes = {};

export default ServicesListPage;
