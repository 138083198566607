import React, { useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { Title, Field, Control, Button, Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import { useApolloClient } from "@apollo/client";
import {
  LIST_CASE_CLIENT_QUERY,
  FIND_FIRST_APOLLO_SERVICE_QUERY,
} from "../../../../graphql";
import DataTable from "../../../../components/DataTable";
import CaseClientFilters from "../../components/CaseClientFilters";
import { debounce } from "../../../../utils";

import COLUMNS from "./columns";

const Cases = ({ client }) => {
  const apolloClient = useApolloClient();
  const [where, setWhere] = useState({
    clientcode: { equals: parseInt(client, 10) },
  });
  const [showFilters, setShowFilters] = useState(false);
  const history = useHistory();
  const handleRowClick = async (casenbr) => {
    try {
      const {
        data: { findFirstServices },
      } = await apolloClient.query({
        query: FIND_FIRST_APOLLO_SERVICE_QUERY,
        variables: {
          where: {
            WorkID: { equals: parseInt(casenbr, 10) },
          },
        },
      });
      const {
        ClaimID,
        ServiceType: { ServiceType },
        ServiceID,
        WorkID,
      } = findFirstServices;
      history.push(
        `/claims/${ClaimID}/services/${ServiceID}/${ServiceType}/${WorkID}/profile`
      );
    } catch (err) {
      toast.error("Unable to determine service.");
      history.push(`/cases/${casenbr}/profile`);
    }
  };

  const handleReset = () => {
    setWhere({ clientcode: { equals: parseInt(client, 10) } });
  };
  const handleChange = debounce((name, value) => {
    if (!value) {
      setWhere((prev) =>
        Object.keys(prev).reduce((acc, curr) => {
          if (curr !== name) acc[curr] = prev[curr];
          return acc;
        }, {})
      );
    } else {
      setWhere((prev) => ({ ...prev, [name]: value }));
    }
  }, 500);

  return (
    <div>
      <Field kind="group">
        <Control style={{ marginTop: "-4px" }}>
          <Button
            color="warning"
            size="small"
            onClick={() => setShowFilters((prev) => !prev)}
          >
            <Icon>
              <FontAwesomeIcon icon="filter" />
            </Icon>
          </Button>
        </Control>
        <Title subtitle>Cases</Title>
      </Field>
      {showFilters && (
        <CaseClientFilters
          filters={where}
          onChange={handleChange}
          onReset={handleReset}
        />
      )}
      <DataTable
        aggregateKey="casenbr"
        aggregateName="aggregateCase"
        columns={COLUMNS}
        name="cases"
        orderBy={[{ id: "casenbr", desc: true }]}
        query={LIST_CASE_CLIENT_QUERY}
        where={where}
        onRowClick={({ casenbr }) => handleRowClick(casenbr)}
      />
    </div>
  );
};

Cases.propTypes = {
  client: PropTypes.string,
};

Cases.defaultProps = {
  client: "",
};

export default Cases;
