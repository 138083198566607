import { gql } from "@apollo/client";

export const RATS_ALL_MEDICAL_PROVIDERS_QUERY = gql`
  query Medicalproviders(
    $where: MedicalproviderWhereInput
    $orderBy: [MedicalproviderOrderByInput!]
    $take: Int
    $skip: Int
  ) {
    medicalproviders(
      where: $where
      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      MPid
      MPnameFirst
      MPnameLast
      MPgroupName
      MPphone1
      MPfax
      MPEmailAddress
      MPaddress1
      MPaddress2
      MPcity
      MPstate
      MPzip

      providerLink {
        PLid
        PLmedicalProviderID
      }
    }
    aggregateMedicalprovider: aggregateMedicalprovider(where: $where) {
      count {
        MPid
      }
    }
  }
`;

export const RATS_SINGLE_MEDICAL_PROVIDER_QUERY = gql`
  query Medicalprovider($where: MedicalproviderWhereUniqueInput!) {
    medicalprovider(where: $where) {
      MPid
      MPnameLast
      MPnameFirst
      MPgroupName
      MPaddress1
      MPaddress2
      MPcity
      MPstate
      MPzip
      MPphone1
      MPfax
    }
  }
`;

export const RATS_FIND_FIRST_MEDICAL_PROVIDER_QUERY = gql`
  query FindFirstMedicalprovider($where: MedicalproviderWhereInput) {
    findFirstMedicalprovider(where: $where) {
      MPid
      MPnameLast
      MPnameFirst
    }
  }
`;
