import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Control, Field, Input, Box, Label, Dropdown } from "rbx";
import { useLazyQuery } from "@apollo/client";
import { format } from "date-fns";
import { convertTimeZone, debounce } from "../../../../utils";

import CaseTypeSelect from "../../../../components/CaseTypeSelect";
import JurisdictionSelect from "../../../../components/JurisdictionSelect";

import ReferralStyledSubtitle from "../ReferralStyledSubtitle";
import { useReferrer } from "../../../../hooks";
import { ALL_ZIPS_QUERY, LIST_CE_CLAIMS_QUERY } from "../../../../graphql";

import { KEY_CODES } from "../../../../constants";
import ClickCapture from "../../../../components/ClickCapture";

const { UP, DOWN, ENTER } = KEY_CODES;

const ClaimInformationForm = ({
  inputs,
  onChange,
  setInputs,
  teamConnectID,
  isLowesCase,
  isGeicoMarltonOffice,
  mode,
  disabled,
  DOLRequired,
}) => {
  const isRecordsMode = /^records$/gi.test(inputs?.product);
  const claimantLastName = inputs?.ClaimantLastName;

  const referrer = useReferrer();
  const inputClaimnbrRef = useRef(null);
  const inputVenueRef = useRef(null);
  const [cursor, setCursor] = useState(null);
  // const [value, setLocalValue] = useState("");
  const showClaimnbrDropdown = useRef();
  const showVenueDropdown = useRef();

  const [getClaims, { data: ClaimsData }] = useLazyQuery(LIST_CE_CLAIMS_QUERY);
  const [getZipCodes, { data: ZipCodesData, loading: zipCodesLoading }] =
    useLazyQuery(ALL_ZIPS_QUERY);

  const setShowClaimnbrDropdown = (boolean) => {
    showClaimnbrDropdown.current = boolean;
  };

  const setShowVenueDropdown = (boolean) => {
    showVenueDropdown.current = boolean;
  };

  const getClaimVariables = useCallback(
    (search) => {
      const where = {
        ClaimNumber: { startsWith: search },
      };
      if (referrer?.CompanyName) {
        where.CompanyName = { equals: referrer.CompanyName };
      }
      if (claimantLastName) {
        where.ClaimantLastName = { startsWith: claimantLastName };
      }
      return {
        where,
        take: 12,
        orderBy: [
          { ClaimNumber: "asc" },
          { ClaimantLastName: "asc" },
          { ClaimantFirstName: "asc" },
        ],
      };
    },
    [referrer, claimantLastName]
  );

  useEffect(() => {
    const el = inputClaimnbrRef.current;

    const handleFocus = (e) => {
      const {
        target: { value: newValue },
      } = e;
      if (referrer && (inputs?.ClaimantLastName || newValue.length >= 1)) {
        setShowClaimnbrDropdown(true);
        getClaims({
          variables: getClaimVariables(newValue),
        });
      }
    };

    el?.addEventListener("focus", handleFocus);
    el?.addEventListener("click", handleFocus);

    return () => {
      el?.removeEventListener("focus", handleFocus);
      el?.removeEventListener("click", handleFocus);
    };
  }, [
    inputClaimnbrRef,
    referrer,
    inputs?.ClaimantLastName,
    getClaimVariables,
    getClaims,
  ]);

  useEffect(() => {
    const el = inputVenueRef.current;

    const handleFocus = (e) => {
      const {
        target: { value: newValue },
      } = e;
      if (inputs?.Jurisdiction || newValue.length >= 1) {
        setShowVenueDropdown(true);
      }
    };

    el?.addEventListener("focus", handleFocus);
    el?.addEventListener("click", handleFocus);

    return () => {
      el?.removeEventListener("focus", handleFocus);
      el?.removeEventListener("click", handleFocus);
    };
  }, [inputVenueRef, inputs?.Jurisdiction]);

  const handleSearchClaimnbrChange = debounce((e) => {
    e.preventDefault();
    e.stopPropagation();
    const {
      target: { value: newValue },
    } = e;
    if (referrer && (claimantLastName || newValue.length >= 1)) {
      setShowClaimnbrDropdown(true);
      getClaims({
        variables: getClaimVariables(newValue),
      });
    } else {
      setShowClaimnbrDropdown(false);
    }
  }, 500);

  const handleSearchVenueChange = debounce((e) => {
    e.preventDefault();
    e.stopPropagation();
    const {
      target: { value: newValue },
    } = e;

    const sanitizedValue = newValue?.trim(); // Remove leading/trailing spaces
    const hasComma = newValue?.includes(",");

    const where = {
      sState: { equals: inputs.Jurisdiction },
      OR: [],
    };

    if (hasComma) {
      // If a comma is present, prioritize City and County
      const [sanitizedCityValue, sanitizedCountyValue] =
        sanitizedValue.split(",");

      if (sanitizedCityValue) {
        where.OR.push({ sCity: { contains: sanitizedCityValue?.trim() } });
      }

      if (sanitizedCountyValue) {
        where.OR.push({
          sCountyName: { contains: sanitizedCountyValue?.trim() },
        });
      }
    } else {
      // If no comma, search for both City and County
      where.OR = [
        { sCity: { contains: sanitizedValue } },
        { sCountyName: { contains: sanitizedValue } },
      ];
    }

    getZipCodes({
      variables: {
        where,
        distinct: "sCity",
      },
    });

    if (newValue.length >= 1) {
      setShowVenueDropdown(true);
    } else {
      setShowVenueDropdown(false);
    }
  }, 500);

  const selectClaim = (claim) => {
    if (!claim) return;
    setInputs((prev) => ({
      ...prev,
      ClaimNumber: claim.ClaimNumber || "",
      CentralEligibilityID: claim.CentralEligibilityID || "",
      ClaimantFirstName: claim.ClaimantFirstName || "",
      ClaimantLastName: claim.ClaimantLastName || "",
      ClaimantAddress1: claim.Address1 || "",
      ClaimantAddress2: claim.Address2 || "",
      ClaimantPhone: claim.Phone1 || "",
      ClaimantCellPhone: claim.Phone2 || "",
      ClaimantCity: claim.City || "",
      ClaimantState: claim.State || "",
      ClaimantZip: claim.Zip5?.trim() || "",
      ClaimantGender: claim.Sex || "",
      ClaimantDateOfBirth: format(
        convertTimeZone(claim.DateOfBirth),
        "yyyy-MM-dd"
      ),
      DateOfLoss: format(convertTimeZone(claim.DateOfLoss), "yyyy-MM-dd"),
    }));
    setShowClaimnbrDropdown(false);
    setCursor(null);
  };

  const selectVenue = (venueObj) => {
    if (!venueObj) return;

    setInputs((prev) => ({
      ...prev,
      Venue: [venueObj.sCity, venueObj.sCountyName].join(", "),
    }));
    setShowVenueDropdown(false);
    setCursor(null);
  };

  const handleClaimnbrKeyDown = (e) => {
    const { keyCode } = e;
    if ([UP, DOWN, ENTER]?.includes(e.keyCode)) {
      e.preventDefault();
      e.stopPropagation();
    }
    if (keyCode === UP) {
      if (typeof cursor === "number" && cursor !== 0) {
        setCursor((prev) => prev - 1);
      }
    } else if (keyCode === DOWN) {
      if (typeof cursor !== "number") {
        setCursor(0);
      } else if (cursor < ClaimsData?.findManyClaims?.length - 1) {
        setCursor((prev) => prev + 1);
      }
    } else if (keyCode === ENTER) {
      const claim = ClaimsData?.findManyClaims?.[cursor];
      if (claim) {
        selectClaim(claim);
        setCursor(null);
      }
    }
  };

  const handleVenueKeyDown = (e) => {
    const { keyCode } = e;
    if ([UP, DOWN, ENTER]?.includes(e.keyCode)) {
      e.preventDefault();
      e.stopPropagation();
    }
    if (keyCode === UP) {
      if (typeof cursor === "number" && cursor !== 0) {
        setCursor((prev) => prev - 1);
      }
    } else if (keyCode === DOWN) {
      if (typeof cursor !== "number") {
        setCursor(0);
      } else if (cursor < ZipCodesData?.zipCodes?.length - 1) {
        setCursor((prev) => prev + 1);
      }
    } else if (keyCode === ENTER) {
      const venueObj = ZipCodesData?.zipCodes?.[cursor];
      if (venueObj) {
        selectVenue(venueObj);
        setCursor(null);
      }
    }
  };

  const isClaimnbrActive =
    ClaimsData &&
    Array.isArray(ClaimsData.findManyClaims) &&
    !!ClaimsData.findManyClaims.length;

  const isVenueActive = ZipCodesData && Array.isArray(ZipCodesData.zipCodes);

  return (
    <Box className={/^records$/gi.test(inputs?.product) ? "col-span" : ""}>
      <ReferralStyledSubtitle>Claim Information</ReferralStyledSubtitle>
      <Field className="grid-override" kind="group">
        <CaseTypeSelect
          expanded
          disabled={
            (disabled || isRecordsMode) && /^records$/gi.test(inputs?.product)
          }
          label="Type of Case"
          name="CaseType"
          required={/^medical management$/gi.test(inputs?.product)}
          value={inputs.CaseType || ""}
          onChange={onChange}
        />

        {/* Claim Number Input */}
        <Control expanded>
          <Label
            className={
              /^records$/gi.test(inputs?.product) &&
              isLowesCase &&
              inputs?.ClaimNumber.length < 15
                ? "erf-claim-number--validation"
                : ""
            }
            style={{ position: "relative" }}
          >
            Claim Number
          </Label>
          <ClickCapture onOutsideClick={() => setShowClaimnbrDropdown(false)}>
            <Dropdown
              managed
              active={
                referrer && isClaimnbrActive && showClaimnbrDropdown.current
              }
              color="primary"
              style={{ width: "100%" }}
            >
              <Dropdown.Trigger style={{ flex: 1 }}>
                <Input
                  required
                  autoComplete="new"
                  disabled={disabled}
                  name="ClaimNumber"
                  pattern={
                    isLowesCase && /^records$/gi.test(inputs?.product)
                      ? ".{15,}"
                      : undefined
                  }
                  ref={inputClaimnbrRef}
                  size="small"
                  tabIndex="0"
                  value={inputs?.ClaimNumber}
                  onChange={(e) => {
                    onChange(e.target.name, e.target.value);
                    handleSearchClaimnbrChange(e);
                  }}
                  onKeyDown={handleClaimnbrKeyDown}
                />
              </Dropdown.Trigger>
              {showClaimnbrDropdown.current &&
                ClaimsData?.findManyClaims?.length > 0 && (
                  <Dropdown.Menu style={{ flex: 1 }}>
                    {isClaimnbrActive && inputs?.ClaimNumber && (
                      <Dropdown.Content>
                        {ClaimsData.findManyClaims.map((claim, i) => (
                          <Dropdown.Item
                            active={cursor === i}
                            as="a"
                            key={claim.CentralEligibilityID}
                            onClick={() => selectClaim(claim)}
                          >
                            {[
                              claim.ClaimNumber,
                              [claim.ClaimantLastName, claim.ClaimantFirstName]
                                .filter(Boolean)
                                .join(", "),
                            ]
                              .filter(Boolean)
                              .join(" - ")}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Content>
                    )}
                  </Dropdown.Menu>
                )}
            </Dropdown>
          </ClickCapture>
        </Control>
        {/* Claim Number Input */}
      </Field>
      <Field
        className={
          !/^records$/gi.test(inputs?.product)
            ? "grid-override is-three-last-double"
            : "grid-override"
        }
        kind="group"
      >
        <Control expanded>
          <Label>Insured</Label>
          <Input
            disabled={disabled || /^records$/gi.test(inputs?.product)}
            name="Insured"
            required={!/^records$/gi.test(inputs?.product)}
            size="small"
            value={inputs.Insured}
            onChange={(e) => onChange(e.target.name, e.target.value)}
          />
        </Control>
        <Control expanded>
          <Label>Date Of Loss/Injury</Label>
          <Input
            disabled={disabled}
            name="DateOfLoss"
            required={DOLRequired}
            size="small"
            type="date"
            value={inputs.DateOfLoss}
            onChange={(e) => onChange(e.target.name, e.target.value)}
          />
        </Control>
        {!/^records$/gi.test(inputs?.product) ? (
          <Control expanded>
            <Label>
              {isGeicoMarltonOffice
                ? "Legal File Number / Teams Connect Number"
                : "Legal File Number"}
            </Label>
            <Input
              disabled={disabled}
              name="FileNumber"
              required={!/^records$/gi.test(inputs?.product) && teamConnectID}
              size="small"
              type="text"
              value={inputs?.FileNumber}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </Control>
        ) : (
          ""
        )}
      </Field>
      <Field className="grid-override" kind="group">
        <JurisdictionSelect
          expanded
          disabled={disabled || /^records$/gi.test(inputs?.product)}
          label="Jurisdiction/State"
          name="Jurisdiction"
          value={inputs.Jurisdiction}
          onChange={onChange}
        />

        <Control expanded>
          <Label>Venue/County</Label>
          <ClickCapture onOutsideClick={() => setShowVenueDropdown(false)}>
            <Dropdown
              managed
              active={isVenueActive && showVenueDropdown.current}
              color="primary"
              style={{ width: "100%" }}
            >
              <Dropdown.Trigger style={{ flex: 1 }}>
                <Input
                  autoComplete="new"
                  disabled={disabled || /^records$/gi.test(inputs?.product)}
                  name="Venue"
                  ref={inputVenueRef}
                  required={/^medical management$/gi.test(inputs?.product)}
                  size="small"
                  value={inputs.Venue}
                  onChange={(e) => {
                    onChange(e.target.name, e.target.value);
                    handleSearchVenueChange(e);
                  }}
                  onKeyDown={handleVenueKeyDown}
                />
              </Dropdown.Trigger>
              {showVenueDropdown.current &&
                ZipCodesData?.zipCodes?.length > 0 && (
                  <Dropdown.Menu style={{ flex: 1 }}>
                    {isVenueActive &&
                      inputs?.Jurisdiction &&
                      !zipCodesLoading && (
                        <Dropdown.Content>
                          {ZipCodesData?.zipCodes.map((venueObj, i) => (
                            <Dropdown.Item
                              active={cursor === i}
                              as="a"
                              key={venueObj.kIndex}
                              onClick={() => selectVenue(venueObj)}
                            >
                              {[venueObj.sCity, venueObj.sCountyName].join(
                                ", "
                              )}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Content>
                      )}
                  </Dropdown.Menu>
                )}
            </Dropdown>
          </ClickCapture>
        </Control>
      </Field>
      {/^medical management$/gi.test(inputs?.product) && (
        <React.Fragment>
          <Field className="grid-override" kind="group">
            <Control expanded>
              <Label>
                First Name
                <em>(Attorney/Secretary)</em>
              </Label>
              <Input
                disabled={disabled || /^records$/gi.test(inputs?.product)}
                name="AttSecFname"
                required={
                  /^medical management$/gi.test(inputs?.product) &&
                  isGeicoMarltonOffice
                }
                size="small"
                value={inputs.AttSecFname}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
            </Control>
            <Control expanded>
              <Label>
                Last Name
                <em>(Attorney/Secretary)</em>
              </Label>
              <Input
                disabled={disabled || /^records$/gi.test(inputs?.product)}
                name="AttSecLname"
                required={
                  /^medical management$/gi.test(inputs?.product) &&
                  isGeicoMarltonOffice
                }
                size="small"
                value={inputs.AttSecLname}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
            </Control>
          </Field>
          <Field className="grid-override" kind="group">
            <Control expanded>
              <Label>
                First Name
                <em>(Claim Rep)</em>
              </Label>
              <Input
                disabled={disabled || /^records$/gi.test(inputs?.product)}
                name="GeicoClaimRepFname"
                required={
                  /^medical management$/gi.test(inputs?.product) &&
                  isGeicoMarltonOffice
                }
                size="small"
                value={inputs.GeicoClaimRepFname}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
            </Control>
            <Control expanded>
              <Label>
                Last Name
                <em>(Claim Rep)</em>
              </Label>
              <Input
                disabled={disabled || /^records$/gi.test(inputs?.product)}
                name="GeicoClaimRepLname"
                required={
                  /^medical management$/gi.test(inputs?.product) &&
                  isGeicoMarltonOffice
                }
                size="small"
                value={inputs.GeicoClaimRepLname}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
            </Control>
          </Field>
        </React.Fragment>
      )}
    </Box>
  );
};

ClaimInformationForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  inputs: PropTypes.object.isRequired,
  setInputs: PropTypes.func.isRequired,
  mode: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  DOLRequired: PropTypes.bool.isRequired,
  teamConnectID: PropTypes.string,
  isGeicoMarltonOffice: PropTypes.bool.isRequired,
  isLowesCase: PropTypes.bool,
};

ClaimInformationForm.defaultProps = {
  teamConnectID: null,
  isLowesCase: false,
};

export default ClaimInformationForm;
