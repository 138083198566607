import React from "react";
import PropTypes from "prop-types";
import { Field, Control, Input, Label } from "rbx";
import StatusSelect from "../../../../../components/StatusSelect";
import NativeSelect from "../../../../../components/NativeSelect";

const OfficeForm = ({ inputs, onChange }) => (
  <div>
    <Field kind="group">
      <Control expanded>
        <Label>Short Description</Label>
        <Input
          autoComplete="new"
          maxLength={15}
          name="shortdesc"
          size="small"
          value={inputs.shortdesc}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
      <Control expanded>
        <Label>Description</Label>
        <Input
          autoComplete="new"
          name="description"
          size="small"
          value={inputs.description}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
      <Control>
        <NativeSelect
          label="Class"
          name="class"
          options={[
            {
              name: "class",
              value: "Surveillance",
              label: "Surveillance",
            },
            {
              name: "class",
              value: "IME",
              label: "IME",
            },
          ]}
          size="small"
          value={inputs.class}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
      <Control>
        <StatusSelect
          label="Status"
          name="status"
          value={inputs.status}
          onChange={onChange}
        />
      </Control>
    </Field>
  </div>
);

OfficeForm.propTypes = {
  inputs: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default OfficeForm;
