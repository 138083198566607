import { useQuery } from "@apollo/client";
import { FIND_FIRST_CLIENT_QUERY } from "../graphql";
import { useQueryParams } from ".";

const useClientCodeClient = () => {
  const [clientCode] = useQueryParams("clientcode", "", true);

  const { data } = useQuery(FIND_FIRST_CLIENT_QUERY, {
    variables: {
      where: { clientcode: { equals: parseInt(clientCode, 10) } },
    },
    fetchPolicy: "network-only",
    skip: !clientCode,
  });

  return { data };
};

export default useClientCodeClient;
