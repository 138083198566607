import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { Field, Box, Button, Icon } from "rbx";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import styled from "styled-components";
import { generateColumns } from "./columns";
import Table from "../../../../components/DataTable/Table";
import { useModal } from "../../../../context";

import ReferralStyledSubtitle from "../ReferralStyledSubtitle";

import {
  generateUUID,
  parseBase64,
  customToast as toast,
} from "../../../../utils";

import NotesModal from "./NotesModal";
import { useQueryParams } from "../../../../hooks";

import StyledFormSectionHeader from "../StyledFormSectionHeader";

const StyledTableContainer = styled.div`
  margin-top: ${(props) => (props.showMarginTop ? "15px" : "0px")};
`;

const Notes = ({ handleChange, notes, disabled }) => {
  const { setModalOpen } = useModal();
  const [email] = useQueryParams("email");

  const handleRemoveNote = useCallback(
    (id, fromDB) => {
      if (!disabled && !fromDB) {
        const currNotes = [...notes];
        const idx = currNotes.findIndex((n) => n.id === id);
        if (idx > -1) {
          currNotes.splice(idx, 1);
        }
        handleChange(currNotes);
      } else {
        toast.warning(
          "Notes can not be deleted once the request has been submitted"
        );
      }
    },
    [handleChange, notes, disabled]
  );

  const handleAddNote = () => {
    setModalOpen(
      true,
      <NotesModal
        onComplete={(success, value) => {
          if (success && value) {
            const _email = parseBase64(email);
            handleChange([
              ...notes,
              {
                id: generateUUID(),
                note: value,
                user: _email || "Unknown",
              },
            ]);
          }
          setModalOpen(false);
        }}
      />
    );
  };

  const COLUMNS = useMemo(
    () => generateColumns({ handleDelete: handleRemoveNote }),
    [handleRemoveNote]
  );

  return (
    <Box className="col-span">
      <StyledFormSectionHeader>
        <Field>
          <ReferralStyledSubtitle>Notes</ReferralStyledSubtitle>
        </Field>
        <Field>
          <Button
            color="primary"
            size="small"
            type="button"
            onClick={handleAddNote}
          >
            <Icon>
              <FontAwesomeIcon icon="plus" />
            </Icon>
            <span>Add Note</span>
          </Button>
        </Field>
      </StyledFormSectionHeader>
      <StyledTableContainer showMarginTop={!!notes.length}>
        <Table columns={COLUMNS} data={notes} displayEmptyMessage={false} />
      </StyledTableContainer>
    </Box>
  );
};

Notes.propTypes = {
  handleChange: PropTypes.func,
  notes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      note: PropTypes.string,
      user: PropTypes.string,
    })
  ),
  disabled: PropTypes.bool,
};

Notes.defaultProps = {
  handleChange: () => {},
  notes: [],
  disabled: false,
};

export default Notes;
