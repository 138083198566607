import React from "react";
import PropTypes from "prop-types";
import { Field, Control, Column, Input, Label, Textarea } from "rbx";
import BooleanSelect from "../../../components/BooleanSelect";
import BooleanInput from "../../../components/BooleanInput";

const UserDefinedFields = ({ inputs, onChange, disabled, onSubmit }) => (
  <form id="add-company-form" onSubmit={onSubmit}>
    <Column.Group>
      <Column desktop={{ size: 6 }} mobile={{ size: 12 }}>
        <Column.Group>
          <Column size={4}>
            <BooleanSelect
              disabled={disabled}
              label="Supplement"
              name="usdvarchar1"
              value={inputs.usdvarchar1}
              onChange={onChange}
            />
          </Column>
          <Column size={4}>
            <BooleanSelect
              disabled={disabled}
              label="Corporate"
              name="usdvarchar2"
              value={inputs.usdvarchar2}
              onChange={onChange}
            />
          </Column>
          <Column size={4}>
            <Field>
              <Control>
                <Label>Email Referral</Label>
                <BooleanInput
                  disabled={disabled}
                  name="usdint2"
                  size="small"
                  type="text"
                  value={inputs.usdint2}
                  onChange={onChange}
                />
              </Control>
            </Field>
          </Column>
        </Column.Group>
        <Column.Group>
          <Column size={4}>
            <Control>
              <Label>Prospect Date</Label>
              <Input
                disabled={disabled}
                name="usddate1"
                size="small"
                type="date"
                value={inputs.usddate1}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
            </Control>
          </Column>
          <Column size={4}>
            <Control>
              <Label>Followup Date</Label>
              <Input
                disabled={disabled}
                name="usddate2"
                size="small"
                type="date"
                value={inputs.usddate2}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
            </Control>
          </Column>
          <Column size={4}>
            <Control>
              <Label>Pinnacle</Label>
              <BooleanInput
                disabled={disabled}
                name="usdint1"
                size="small"
                type="text"
                value={inputs.usdint1}
                onChange={onChange}
              />
            </Control>
          </Column>
        </Column.Group>
      </Column>
      <Column desktop={{ size: 6 }} mobile={{ size: 12 }}>
        <Column.Group>
          <Column size={6}>
            <Control style={{ marginBottom: "1rem" }}>
              <Label>Notes</Label>
              <Textarea
                disabled={disabled}
                name="usdtext1"
                size="small"
                value={inputs.usdtext1}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
            </Control>
          </Column>
          <Column size={6}>
            <Control>
              <Label>Company Type</Label>
              <Textarea
                disabled={disabled}
                name="usdtext2"
                size="small"
                value={inputs.usdtext2}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
            </Control>
          </Column>
        </Column.Group>
      </Column>
    </Column.Group>
  </form>
);

UserDefinedFields.propTypes = {
  inputs: PropTypes.object,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  onSubmit: PropTypes.func,
};

UserDefinedFields.defaultProps = {
  inputs: {},
  onChange: (e) => e,
  disabled: false,
  onSubmit: (e) => e,
};

export default UserDefinedFields;
