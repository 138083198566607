import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Title } from "rbx";
import { useModal } from "../../context/ModalContext";

import releaseNotesTextFile from "./ReleaseNotes.txt";

const ReleaseNotesModal = ({ onCancel }) => {
  const { setModalOpen } = useModal();
  const [releaseNotes, setReleaseNotes] = useState("");

  const handleCancel = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setModalOpen(false, "");
    if (onCancel && typeof onCancel === "function") {
      onCancel();
    }
  };

  useEffect(() => {
    fetch(releaseNotesTextFile)
      .then((t) => t.text())
      .then((text) => {
        setReleaseNotes(text);
      });
  });

  return (
    <form id="release-notes-form">
      <header className="page-head">
        <div className="page-head-start">
          <Title size={5}>Release Notes</Title>
        </div>
        <div className="page-head-end">
          <Button.Group hasAddons>
            <Button
              color="success"
              role="button"
              size="small"
              style={{ zIndex: 9999 }}
              type="button"
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </Button.Group>
        </div>
      </header>
      <hr />
      <pre>{releaseNotes}</pre>
    </form>
  );
};

ReleaseNotesModal.propTypes = {
  onCancel: PropTypes.func,
};

ReleaseNotesModal.defaultProps = {
  onCancel: () => null,
};

export default ReleaseNotesModal;
