import React, { useState } from "react";
import PropTypes from "prop-types";
import { Title, Fieldset, Button, Field } from "rbx";
import { useMutation } from "@apollo/client";
import { CustomERFError } from "../../ReferralPage";

import MedicalProviderEntryForm from "../MedicalProviderEntryForm";

import DataTable from "../../../../components/DataTable";

import {
  RATS_ALL_MEDICAL_PROVIDERS_QUERY,
  RATS_CREATE_MEDICAL_PROVIDER_MUTATION,
} from "../../../../graphql";

import { customToast as toast } from "../../../../utils";

import { useReferrer } from "../../../../hooks";
import BusinessCard from "../BusinessCard";

const DEFAULT_STATE = {
  MPnameFirst: "",
  MPnameLast: "",
  MPgroupName: "",
  MPphone1: "",
  MPfax: "",
  MPEmailAddress: "",
  MPaddress1: "",
  MPaddress2: "",
  MPcity: "",
  MPstate: "",
  MPzip: "",
  // MpproviderTypeID: "TRPTOTH", // commented out so that results and aggregation mirror CLINK
};

const fields = [
  "MPnameFirst",
  "MPnameLast",
  "MPgroupName",
  "MPphone1",
  "MPfax",
  "MPEmailAddress",
  "MPaddress1",
  "MPaddress2",
  "MPcity",
  "MPstate",
  "MPzip",
];

const MedicalProviderEntryModal = ({
  onComplete,
  title,
  mode,
  onRowClick,
  onMedicalProviderAdd,
}) => {
  const [inputs, setInputs] = useState({ ...DEFAULT_STATE });
  const [MPWhere, setMPWhere] = useState({ AND: [] });
  const [loading, setLoading] = useState(false);

  const [createMedicalProvider] = useMutation(
    RATS_CREATE_MEDICAL_PROVIDER_MUTATION
  );

  const referrer = useReferrer();

  const handleChange = (name, value) => {
    setInputs((prev) => ({ ...prev, [name]: value }));
  };

  const handleReset = () => {
    setInputs({ ...DEFAULT_STATE });
    setMPWhere({ AND: [] });
  };

  const handleFilter = async () => {
    const where = { AND: [] };
    for (const input in inputs) {
      if (
        Object.prototype.hasOwnProperty.call(inputs, input) &&
        inputs[input]
      ) {
        where.AND.push({ [input]: { contains: inputs[input] } });
      }
    }

    if (where.AND.length) {
      setMPWhere(where);
    }
  };

  const handleAddMedicalProvider = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);

      if (!inputs?.MPcity || inputs?.MPcity?.length < 2) {
        throw new CustomERFError("Please enter a valid city");
      }

      const input = {
        MPnameFirst: inputs.MPnameFirst,
        MPnameLast: inputs.MPnameLast,
        MPgroupName: inputs.MPgroupName,
        MPphone1: inputs.MPphone1,
        MPfax: inputs.MPfax,
        MPaddress1: inputs.MPaddress1,
        MPaddress2: inputs.MPaddress2,
        MPcity: inputs.MPcity,
        MPstate: inputs.MPstate,
        MPzip: inputs.MPzip,
        MPenteredBy: referrer?.ClientLogin || "Unknown",
        MpproviderTypeID: inputs.MpproviderTypeID,
      };

      const {
        data: { createMedicalProvider: result },
      } = await createMedicalProvider({ variables: { input } });

      if (result?.OK) {
        onMedicalProviderAdd(result.MPid);
      } else {
        toast.error(
          "Something went wrong, please try again later or contact support"
        );
      }
    } catch (err) {
      if (err instanceof CustomERFError) {
        toast.error(err.message);
      } else {
        toast.error(
          "Something went wrong, please try again later or contact support"
        );
      }
    } finally {
      setLoading(false);
    }
  };

  const disabled =
    mode === "add" &&
    (!inputs.MPnameLast ||
      !inputs.MPaddress1 ||
      !inputs.MPcity ||
      !inputs.MPstate);

  const searchAndResetDisabled = !!(
    fields.filter((field) => inputs[field] !== "").length === 0
  );

  return (
    <React.Fragment>
      <form id="rg-form" onSubmit={handleAddMedicalProvider}>
        <header className="page-head">
          <div className="page-head-start">
            <Title size={5}>{title}</Title>
          </div>
          <div className="page-head-end">
            <Button.Group hasAddons>
              <Button
                disabled={loading}
                size="small"
                type="button"
                onClick={onComplete}
              >
                <span>Cancel</span>
              </Button>
              {mode === "add" && (
                <Button
                  color="primary"
                  disabled={disabled || loading}
                  form="rg-form"
                  size="small"
                  state={loading ? "loading" : ""}
                  type="submit"
                >
                  <span>Submit</span>
                </Button>
              )}
            </Button.Group>
          </div>
        </header>
        <hr />
        <Field>
          <Fieldset>
            <MedicalProviderEntryForm
              disabled={loading}
              inputs={inputs}
              mode={mode}
              onChange={handleChange}
            />
          </Fieldset>
        </Field>
      </form>
      {!!MPWhere.AND.length && (
        <React.Fragment>
          <hr />
          <DataTable
            aggregateKey="MPid"
            aggregateName="aggregateMedicalprovider"
            columns={[
              {
                Header: "Info",
                id: "businessCard",
                disableSortBy: true,
                Cell: (cell) => (
                  <BusinessCard
                    isInTable
                    data={cell.row.values}
                    disabled={!cell.row.values}
                    onSeeMoreClick={() => null}
                  />
                ),
              },
              {
                Header: "Assoc. Providers",
                id: "providerLinkCount",
                disableSortBy: true,
                accessor: (row) => row.providerLink.length,
                Cell: (cell) => {
                  const associatedProvidersCount = cell.value;
                  return associatedProvidersCount;
                },
              },
              {
                Header: "MP ID",
                id: "MPid",
                accessor: "MPid",
              },
              {
                Header: "First Name",
                id: "MPnameFirst",
                accessor: "MPnameFirst",
              },
              {
                Header: "Last Name / Facility Name",
                id: "MPnameLast",
                accessor: "MPnameLast",
              },
              {
                Header: "Group Name",
                id: "MPgroupName",
                accessor: "MPgroupName",
              },
              {
                Header: "Addr 1",
                id: "MPaddress1",
                accessor: "MPaddress1",
              },
              {
                Header: "Addr 2",
                id: "MPaddress2",
                accessor: "MPaddress2",
              },
              {
                Header: "City",
                id: "MPcity",
                accessor: "MPcity",
              },
              {
                Header: "Zip",
                id: "MPzip",
                accessor: "MPzip",
              },
              {
                Header: "State",
                id: "MPstate",
                accessor: "MPstate",
              },
              {
                Header: "Phone #",
                id: "MPphone1",
                accessor: "MPphone1",
              },
              {
                Header: "Email",
                id: "MPEmailAddress",
                accessor: "MPEmailAddress",
              },
            ]}
            fetchPolicy="network-only"
            loaderHeight="293px"
            name="medicalproviders"
            orderBy={[
              { id: "MPnameLast", desc: false },
              { id: "MPnameFirst", desc: false },
            ]}
            pageSize={10}
            pageSizeKey="RG_PAGE_SIZE"
            query={RATS_ALL_MEDICAL_PROVIDERS_QUERY}
            showPageSize={false}
            where={MPWhere}
            onCellClick={(cell, e) => {
              const {
                column: { id },
              } = cell;
              if (id === "businessCard") {
                e.stopPropagation();
              }
            }}
            onRowClick={onRowClick}
          />
        </React.Fragment>
      )}
      <hr />
      {mode === "search" && (
        <Field kind="group">
          <Button.Group>
            <Button
              color="danger"
              disabled={searchAndResetDisabled}
              size="small"
              onClick={handleReset}
            >
              Reset
            </Button>
            <Button
              color="primary"
              disabled={searchAndResetDisabled}
              size="small"
              onClick={handleFilter}
            >
              Search
            </Button>
          </Button.Group>
        </Field>
      )}
    </React.Fragment>
  );
};

MedicalProviderEntryModal.propTypes = {
  onComplete: PropTypes.func,
  onRowClick: PropTypes.func,
  onMedicalProviderAdd: PropTypes.func,
  title: PropTypes.string,
  mode: PropTypes.string,
};

MedicalProviderEntryModal.defaultProps = {
  onComplete: (e) => e,
  onRowClick: () => null,
  onMedicalProviderAdd: () => null,
  title: "",
  mode: "",
};

export default MedicalProviderEntryModal;
