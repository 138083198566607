import { gql } from "@apollo/client";

// eslint-disable-next-line import/prefer-default-export
export const LOGIN_MUTATION = gql`
  mutation login($input: AuthInput!) {
    login(input: $input) {
      user {
        userid
        email
        resetPassword
        usertype
      }
      token
    }
  }
`;

export const FORGOT_PASSWORD_MUTATION = gql`
  mutation requestPasswordReset($input: ForgotPasswordInput!) {
    requestPasswordReset(input: $input)
  }
`;

export const COMPLETE_PASSWORD_RESET_MUTATION = gql`
  mutation completePasswordReset($input: CompletePasswordInput!) {
    completePasswordReset(input: $input)
  }
`;
