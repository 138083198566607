import { gql } from "@apollo/client";

export const LIST_DRS_DO_NOT_USE_QUERY = gql`
  query drDoNotUses(
    $where: DrDoNotUseWhereInput
    $orderBy: [DrDoNotUseOrderByInput!]
    $take: Int
    $skip: Int
  ) {
    drDoNotUses(take: $take, orderBy: $orderBy, skip: $skip, where: $where) {
      code
      doctorcode
      type
      dateadded
      useridadded
      doctor {
        doctorcode
        firstname
        lastname
      }
    }
  }
`;

export const LIST_DR_DO_NOT_USES_QUERY = gql`
  query drDoNotUses(
    $where: DrDoNotUseWhereInput
    $orderBy: [DrDoNotUseOrderByInput!]
    $take: Int
    $skip: Int
  ) {
    drDoNotUses(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
      code
      doctorcode
      type
      dateadded
      useridadded
      doctor {
        doctorcode
        firstname
        lastname
        status
      }
    }
    aggregateDrDoNotUse(where: $where) {
      count {
        _all
      }
    }
  }
`;

export const ALL_DR_DO_NOT_USE_BY_CASE_QUERY = gql`
  query doctorNotToUseByCase($where: CaseItemWhereUniqueInput!) {
    doctorNotToUseByCase(where: $where) {
      code
      type
      doctorcode
    }
  }
`;

export const SINGLE_DR_DO_NOT_USE_QUERY = gql`
  query drDoNotUse($where: DrDoNotUseWhereUniqueInput!) {
    drDoNotUse(where: $where) {
      code
      dateadded
      doctorcode
      type
      useridadded
    }
  }
`;
