import React from "react";
import PropTypes from "prop-types";

// components
import { Field, Control, Input, Label, Column } from "rbx";

// internal-components

import StateSelect from "../../../../components/StateSelect";
import CompanyGroupSelect from "../../../../components/CompanyGroupSelect";
import StatusSelect from "../../../../components/StatusSelect";
import PhoneNumberInput from "../../../../components/PhoneNumberInput";
import ZipInput from "../../../../components/ZipInput";

const CompanyForm = ({
  inputs,
  companyCode,
  disabled,
  onChange,
  showCompanyGroup,
  onSubmit,
}) => (
  <form
    id="add-company-form"
    style={{ marginBottom: "1.5rem" }}
    onSubmit={onSubmit}
  >
    <Column.Group>
      <Column size={6}>
        <Field>
          <Control>
            <Label size="xs">Internal Company Name</Label>
            <Input
              required
              autoComplete="new"
              disabled={disabled}
              maxLength={70}
              name="intname"
              placeholder="Internal Co. Name"
              size="small"
              value={inputs.intname || ""}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </Control>
        </Field>
        <Field>
          <Control>
            <Label size="xs">Address 1</Label>
            <Input
              required
              autoComplete="new"
              disabled={disabled}
              maxLength={50}
              name="addr1"
              placeholder="Address 1"
              size="small"
              value={inputs.addr1 || ""}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </Control>
        </Field>
        <Field kind="group">
          <Control expanded>
            <Label size="xs">City</Label>
            <Input
              required
              autoComplete="new"
              disabled={disabled}
              maxLength={50}
              name="city"
              placeholder="City"
              size="small"
              value={inputs.city || ""}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </Control>
          <Control>
            <StateSelect
              required
              disabled={disabled}
              id="Statecode"
              label="State"
              name="state"
              size="small"
              value={inputs.state}
              onChange={onChange}
            />
          </Control>
          <ZipInput
            disabled={disabled}
            state={inputs.state}
            value={inputs.zip || ""}
            onChange={onChange}
          />
        </Field>
      </Column>
      <Column size={6}>
        <Field>
          <Control>
            <Label size="xs">External Company Name</Label>
            <Input
              required
              autoComplete="new"
              disabled={disabled}
              maxLength={70}
              name="extname"
              placeholder="External Co. Name"
              size="small"
              value={inputs.extname || ""}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </Control>
        </Field>
        <Field>
          <Control>
            <Label size="xs">Address 2</Label>
            <Input
              autoComplete="new"
              disabled={disabled}
              maxLength={50}
              name="addr2"
              placeholder="Address 2"
              size="small"
              value={inputs.addr2 || ""}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </Control>
        </Field>
        <Field kind="group">
          <Control expanded>
            <Label size="xs">Phone</Label>
            <PhoneNumberInput
              required
              disabled={disabled}
              name="phone"
              value={inputs.phone}
              onChange={onChange}
            />
          </Control>
          {showCompanyGroup && (
            <Control expanded>
              <CompanyGroupSelect
                disabled={disabled}
                name="CompanyGroupID"
                size="small"
                value={inputs.CompanyGroupID || ""}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
            </Control>
          )}
          <Control expanded>
            <StatusSelect
              required
              showBlank
              disabled={disabled}
              id="status"
              label="Status"
              name="status"
              size="small"
              value={inputs.status}
              onChange={onChange}
            />
          </Control>
        </Field>
      </Column>
    </Column.Group>
  </form>
);
CompanyForm.propTypes = {
  inputs: PropTypes.object.isRequired,
  companyCode: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  showCompanyGroup: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

CompanyForm.defaultProps = {
  disabled: false,
  showCompanyGroup: true,
};

export default CompanyForm;
