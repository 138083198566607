import React from "react";
import PropTypes from "prop-types";
import { Field, Box } from "rbx";
import ProblemMultiSelect from "../../../../components/ProblemMultiSelect";
import ReferralStyledSubtitle from "../ReferralStyledSubtitle";

const PhysicalProblems = ({ inputs, problems, onChange, isGeicoInvestigationsRequirement }) => (
  <Box>
    <ReferralStyledSubtitle>Physical Problems</ReferralStyledSubtitle>
    <Field>
      <ProblemMultiSelect
        fullWidth
        inputs={inputs}
        label="Please Select Problems as Appropriate"
        name="PhysicalProblems"
        problemsState={problems}
        required={isGeicoInvestigationsRequirement}
        value={problems}
        onChange={(e) => onChange("PhysicalProblems", e)}
      />
    </Field>
  </Box>
);
PhysicalProblems.propTypes = {
  inputs: PropTypes.object.isRequired,
  problems: PropTypes.array,
  isGeicoInvestigationsRequirement: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
};

PhysicalProblems.defaultProps = {
  problems: [],
  onChange: () => null,
};

export default PhysicalProblems;
