import React from "react";
import PropTypes from "prop-types";
import { Button, Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useModal } from "../../../../context";
import RatsEmployerEntryModal from "../RatsEmployerEntryModal";
import RatsEmployerInfoModal from "../RatsEmployerInfoModal";
import BusinessCard from "../BusinessCard";

const RatsEmployerActionButtons = ({
  label,
  disabled,
  onRowClick,
  onRatsEmployerAdd,
  selectedAddress,
  addressInputs,
  onClearSelectionClick,
}) => {
  const { setModalOpen } = useModal();

  const handleRowClick = (row) => {
    onRowClick(row);
    setModalOpen(false, "");
  };

  const handleRatsEmployerAdd = (employer) => {
    onRatsEmployerAdd(employer);
    setModalOpen(false, "");
  };

  const handleAddClick = () => {
    setModalOpen(
      true,
      <RatsEmployerEntryModal
        label={label}
        mode="add"
        title={`Add ${label}`}
        onComplete={() => {
          setModalOpen(false, "");
        }}
        onRatsEmployerAdd={handleRatsEmployerAdd}
      />
    );
  };

  const handleSearchClick = () => {
    setModalOpen(
      true,
      <RatsEmployerEntryModal
        label={label}
        mode="search"
        title={`Search ${label}`}
        onComplete={() => {
          setModalOpen(false, "");
        }}
        onRowClick={handleRowClick}
      />
    );
  };

  const handleSeeMoreClick = () => {
    setModalOpen(
      true,
      <RatsEmployerInfoModal
        inputs={addressInputs}
        title={label}
        onComplete={() => {
          setModalOpen(false, "");
        }}
      />
    );
  };

  return (
    <Button.Group hasAddons>
      <BusinessCard
        data={selectedAddress}
        disabled={!selectedAddress.length}
        onSeeMoreClick={handleSeeMoreClick}
      />
      <Button
        className="request-generator-action-buttons--delete"
        color="danger"
        disabled={disabled || !selectedAddress.length}
        size="small"
        title="Remove"
        type="button"
        onClick={onClearSelectionClick}
      >
        <Icon>
          <FontAwesomeIcon icon="times" />
        </Icon>
      </Button>
      <Button
        className="request-generator-action-buttons--search"
        color="warning"
        disabled={disabled}
        size="small"
        title="Search"
        type="button"
        onClick={handleSearchClick}
      >
        <Icon>
          <FontAwesomeIcon icon="filter" />
        </Icon>
      </Button>
      <Button
        className="request-generator-action-buttons--add"
        color="success"
        disabled={disabled}
        size="small"
        title="Add"
        type="button"
        onClick={handleAddClick}
      >
        <Icon>
          <FontAwesomeIcon icon="plus" />
        </Icon>
      </Button>
    </Button.Group>
  );
};

RatsEmployerActionButtons.propTypes = {
  onRowClick: PropTypes.func,
  onRatsEmployerAdd: PropTypes.func,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  selectedAddress: PropTypes.array,
  addressInputs: PropTypes.object,
  onClearSelectionClick: PropTypes.func,
};

RatsEmployerActionButtons.defaultProps = {
  onRowClick: () => null,
  onRatsEmployerAdd: () => null,
  label: "",
  disabled: false,
  selectedAddress: [],
  addressInputs: {},
  onClearSelectionClick: () => null,
};

export default RatsEmployerActionButtons;
