import { gql } from "@apollo/client";

export const CREATE_AUDIT_TRAIL_MUTATION = gql`
  mutation CREATE_AUDIT_TRAIL_MUTATION($data: AuditTrailCreateInput!) {
    createAuditTrail(data: $data) {
      audittrailid
      tablename
      entityid
      type
      description
      useridadded
      dateadded
    }
  }
`;

export const UPDATE_AUDIT_TRAIL_MUTATION = gql`
  mutation UPDATE_AUDIT_TRAIL_MUTATION(
    $data: AuditTrailUpdateInput!
    $where: AuditTrailWhereUniqueInput!
  ) {
    updateAuditTrail(data: $data, where: $where) {
      audittrailid
      tablename
      entityid
      type
      description
      useridadded
      dateadded
    }
  }
`;
