import React from "react";
import PropTypes from "prop-types";
import { Field, Control, Input, Label } from "rbx";

const CaseDocumentForm = ({ disabled, inputs, onChange }) => (
  <div>
    <Field kind="group">
      <Control expanded>
        <Label>Name</Label>
        <Input
          autoComplete="new"
          disabled={disabled}
          name="sfilename"
          size="small"
          type="text"
          value={inputs.sfilename}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
      <Control expanded>
        <Label>Description</Label>
        <Input
          autoComplete="new"
          disabled={disabled}
          name="description"
          size="small"
          type="text"
          value={inputs.description}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
    </Field>
  </div>
);

CaseDocumentForm.propTypes = {
  disabled: PropTypes.bool,
  inputs: PropTypes.object,
  onChange: PropTypes.func,
};
CaseDocumentForm.defaultProps = {
  disabled: false,
  inputs: {},
  onChange: () => null,
};
export default CaseDocumentForm;
