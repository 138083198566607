/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
import { Control, Progress, Generic, Icon } from "rbx";
import CircleLoader from "react-spinners/CircleLoader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { customToast as toast } from "../../../../utils";

import Table from "../../../../components/DataTable/Table";

import "./FileUploader.scss";

const TEN_GB = 1000000000 * 10;

const DropZoneFileUploader = ({
  name,
  onChange,
  multiple,
  loading,
  uploadPercentage,
  disabled,
  uploadedFiles,
  filesToUpload,
  onRemoveFile,
}) => {
  const options = { month: '2-digit', day: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true, timeZone: 'UTC' };
  const optionsWhenAttached = { month: '2-digit', day: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true };
  
  const onDropAccepted = useCallback(
    async (acceptedFiles) => {
      onChange(name, acceptedFiles);
    },
    [name, onChange]
  );

  const onDropRejected = (files) => {
    if (files.length) {
      toast.error(files[0].errors[0].message);
    } else {
      toast.error(
        "This file is too large to be uploaded. The current limit is 1 GB. Please contact your administrator if the issue persists."
      );
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDropAccepted,
    onDropRejected,
    multiple,
    maxSize: TEN_GB,
  });

  useEffect(() => {
    const handlePaste = (e) => {
      const files = e.clipboardData?.files;
      if (files.length) {
        onDropAccepted([...files]);
      }
    };
    window.addEventListener("paste", handlePaste);
    return () => window.removeEventListener("paste", handlePaste);
  }, [onDropAccepted]);

  return (
    <Control>
      {loading ? (
        <div className="drop-zone-loader">
          <CircleLoader loading size={75} />
          <h1>Uploading...</h1>
          <div className="progress-container">
            <Progress color="primary" value={uploadPercentage} />
            <p>
              {typeof uploadPercentage === "string"
                ? uploadPercentage.split(".")[0]
                : parseInt(uploadPercentage, 2)}
              %
            </p>
          </div>
        </div>
      ) : (
        <div>
          <div {...getRootProps()} className="drap-drop-zone">
            <input {...getInputProps()} disabled={disabled} />
            {isDragActive ? (
              <p>Drop the file here ...</p>
            ) : (
              <p id="upload-files-text">
                Paste files into the window, drag and drop files here, or click
                to select files
              </p>
            )}
          </div>
          {(filesToUpload.length > 0 || uploadedFiles.length > 0) && (
            <React.Fragment>
              <hr />
              {!!filesToUpload?.length && (
                <React.Fragment>
                  <div>
                    In order for us to receive your documents, you MUST SUBMIT
                    your request after you upload them. Otherwise, these
                    documents will be missed. Thank you.
                  </div>
                  <hr />
                </React.Fragment>
              )}
              <div
                style={{ maxHeight: 400, minHeight: 100, overflowY: "auto" }}
              >
                <Table
                  columns={[
                    {
                      Header: "",
                      id: "Delete",
                      maxWidth: "1%",
                      Cell: (cell) =>
                        !cell?.row?.original?.submitted ? (
                          <Generic
                            disabled={disabled}
                            style={{ textAlign: "center" }}
                            type="button"
                            onClick={() => !disabled && onRemoveFile(cell?.row)}
                          >
                            <Icon color="danger">
                              <FontAwesomeIcon icon="times" />
                            </Icon>
                          </Generic>
                        ) : (
                          ""
                        ),
                    },
                    {
                      Header: "File Name",
                      id: "path",
                      accessor: "path",
                      maxWidth: "25%"
                    },
                    {
                      Header: "Attached",
                      id: "dateadded",
                      accessor: "dateadded",
                      disableSortBy: true,
                      maxWidth: "12.5%",
                      Cell: ({ cell }) => (cell?.value ? new Date(cell.value).toLocaleDateString("en-US", options) : new Date().toLocaleDateString("en-US", optionsWhenAttached))
                    },
                    // {
                    //   Header: "Uploaded",
                    //   id: "uploaded",
                    //   accessor: "uploaded",
                    //   disableSortBy: true,
                    //   maxWidth: "12.5%",
                    //   Cell: ({ cell }) => (cell?.value ? `Yes ${cell.value}` : "No"),
                    // },
                    {
                      Header: "Submitted",
                      id: "submitted",
                      accessor: "submitted",
                      disableSortBy: true,
                      maxWidth: "12.5%",
                      Cell: ({ cell }) => (cell?.value ? "Yes" : "No"),
                    },
                    // {
                    //   Header: "Date Added",
                    //   id: "dateadded",
                    //   accessor: "dateadded",
                    //   disableSortBy: true,
                    //   maxWidth: "12.5%",
                    //   Cell: ({ cell }) => (cell?.value ? new Date(cell.value).toLocaleDateString("en-US") : "N/A - Not uploaded"),
                    // },
                    // {
                    //   Header: "Download",
                    //   id: "download",
                    //   disableSortBy: true,
                    //   maxWidth: "12.5%",
                    //   Cell: (cell) =>
                    //     cell?.row?.original?.uploaded ? (
                    //       <Generic
                    //         as="a"
                    //         href={cell?.row?.original?.link}
                    //         rel="noopener noreferrer"
                    //         style={{ textAlign: "center", display: "block" }}
                    //         target="_blank"
                    //         title="Download"
                    //         type="button"
                    //         onClick={(e) => e.stopPropagation()}
                    //       >
                    //         <Icon color="link">
                    //           <FontAwesomeIcon icon="download" />
                    //         </Icon>
                    //       </Generic>
                    //     ) : (
                    //       ""
                    //     ),
                    // },
                  ]}
                  data={[...uploadedFiles, ...filesToUpload]}
                />
              </div>
            </React.Fragment>
          )}
        </div>
      )}
    </Control>
  );
};

DropZoneFileUploader.propTypes = {
  name: PropTypes.string,
  multiple: PropTypes.bool,
  onChange: PropTypes.func,
  loading: PropTypes.bool,
  uploadPercentage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  uploadedFiles: PropTypes.array,
  filesToUpload: PropTypes.array,
  onRemoveFile: PropTypes.func,
};

DropZoneFileUploader.defaultProps = {
  name: "",
  multiple: false,
  onChange: () => null,
  loading: false,
  uploadPercentage: 0,
  disabled: false,
  uploadedFiles: [],
  filesToUpload: [],
  onRemoveFile: () => null,
};

export default DropZoneFileUploader;
