import React from "react";
import PropTypes from "prop-types";
import { Column, Field, Control, Label, Input } from "rbx";

import DocumentSelect from "../../../../components/DocumentSelect";
import QueueSelect from "../../../../components/QueueSelect";
import DocumentTypeSelect from "../../../../components/DocumentTypeSelect";

const CaseDefaultDocumentForm = ({ inputs, onChange }) => (
  <Column.Group>
    <Column size={2}>
      <DocumentTypeSelect
        fullWidth
        label="Type"
        name="documentType"
        size="small"
        value={inputs.documentType}
        onChange={onChange}
      />
    </Column>
    <Column size={4}>
      <DocumentSelect
        fullWidth
        documentType={inputs.documentType}
        label="Document"
        name="documentcode"
        size="small"
        value={inputs.documentcode}
        onChange={onChange}
      />
    </Column>
    <Column size={4}>
      <QueueSelect
        label="Where to generate?"
        name="documentqueue"
        size="small"
        type="text"
        value={inputs.documentqueue}
        onChange={onChange}
      />
    </Column>
    <Column size={2}>
      <Field>
        <Control>
          <Label>Order</Label>
          <Input
            autoComplete="new"
            name="order"
            size="small"
            type="number"
            value={inputs.order}
            onChange={(e) => onChange(e.target.name, e.target.value)}
          />
        </Control>
      </Field>
    </Column>
  </Column.Group>
);

CaseDefaultDocumentForm.propTypes = {
  inputs: PropTypes.object,
  onChange: PropTypes.func,
};
CaseDefaultDocumentForm.defaultProps = {
  inputs: {},
  onChange: () => null,
};
export default CaseDefaultDocumentForm;
