import React, { useState } from "react";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/client";
import { Button, Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useModal, useAuth } from "../../context";
import Confirmation from "../Confirmation";
import {
  UPDATE_CASE_MUTATION,
  SINGLE_CASE_QUERY,
  CREATE_CASE_HISTORY_MUTATION,
  ALL_CASE_HISTORY_QUERY,
} from "../../graphql";
import {
  customToast as toast,
  getDateEST,
  convertTimeZoneDataBase,
} from "../../utils";

const ToggleQueueButton = ({ casenbr, disabled }) => {
  const { setModalOpen } = useModal();
  const { state: authState } = useAuth();

  const [loading, setLoading] = useState(false);

  const [updateCase] = useMutation(UPDATE_CASE_MUTATION);
  const [createCaseHistory] = useMutation(CREATE_CASE_HISTORY_MUTATION);

  const onToggleQueue = () => {
    setModalOpen(
      true,
      <Confirmation
        message="Are you sure you want to toggle the queue?"
        onCancel={() => setModalOpen(false)}
        onConfirm={performToggleQueue}
      />
    );
  };

  const performToggleQueue = async () => {
    try {
      setModalOpen(false);
      setLoading(true);
      const date = convertTimeZoneDataBase(getDateEST());
      const {
        data: { updateCaseItem },
      } = await updateCase({
        variables: {
          where: {
            casenbr: parseInt(casenbr, 10),
          },
          data: {
            laststatuschg: { set: date },
          },
        },
        refetchQueries: [
          {
            query: SINGLE_CASE_QUERY,
            variables: {
              where: { casenbr: parseInt(casenbr, 10) },
            },
          },
        ],
      });
      if (updateCaseItem) {
        await createCaseHistory({
          variables: {
            data: {
              casenbr: parseInt(casenbr, 10),
              eventdesc: `Status Chg New Status - ${
                updateCaseItem?.Status?.shortdesc || ""
              }`,
              otherinfo: [
                updateCaseItem?.Status?.statusdesc &&
                  `Old - ${updateCaseItem.Status.statusdesc}`,
                updateCaseItem?.Status?.statusdesc &&
                  `New - ${updateCaseItem.Status.statusdesc}`,
              ]
                .filter(Boolean)
                .join(" "),
              eventdate: date,
              userid: authState?.user?.userid,
              useridedited: authState?.user?.userid,
              dateedited: date,
              dateadded: date,
              type: "StatChg",
              status: parseInt(updateCaseItem.status, 10),
            },
          },
          refetchQueries: [
            {
              query: ALL_CASE_HISTORY_QUERY,
              variables: {
                where: { casenbr: { equals: parseInt(casenbr, 10) } },
              },
            },
          ],
        });
        toast.success("Queue toggled successfully.");
      }
    } catch (err) {
      toast.error("Error toggling queue.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Button
      color="success"
      disabled={disabled || loading}
      size="small"
      state={loading ? "loading" : ""}
      style={{ alignSelf: "flex-end" }}
      title="Toggle Queue"
      type="button"
      onClick={onToggleQueue}
    >
      <Icon>
        <FontAwesomeIcon icon="angle-double-left" />
      </Icon>
    </Button>
  );
};

ToggleQueueButton.propTypes = {
  casenbr: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  disabled: PropTypes.bool,
};

ToggleQueueButton.defaultProps = {
  disabled: false,
};

export default ToggleQueueButton;
