/* eslint-disable import/prefer-default-export */
import { gql } from "@apollo/client";

export const CLAIM_CLAIMANT_LOOKUP_QUERY = gql`
  query CLAIM_CLAIMANT_LOOKUP_QUERY($where: ClaimClaimantLookupWhereInput!) {
    claimClaimantLookup(where: $where) {
      Result {
        CentralEligibilityID
        CompanyName
        ExternalClaimID
        ClaimNumber
        SOJ
        ExternalPatientID
        ClaimantIPNumber
        ClaimantFirstName
        ClaimantMiddleName
        ClaimantLastName
        Phone1
        Phone2
        DateOfLoss
        DateOfBirth
        Address1
        Address2
        State
        City
        DateOfBirth
        Sex
        HCP
        EligibilityStatus
        Zip5
        SSN
        InsuredParty
        Venue
        Jurisdiction
        Email
        SpecialPhysical
      }
      Errors
    }
  }
`;
