import React, { useState } from "react";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/client";
import {
  Control,
  Label,
  Input,
  Title,
  Column,
  Button,
  Select as RbxSelect,
  Field,
} from "rbx";
import { customToast as toast, getDateEST } from "../../../utils";
import {
  CREATE_CASE_OTHER_PARTY_MUTATION,
  LIST_CASE_OTHER_PARTY_QUERY,
  UPDATE_CASE_OTHER_PARTY_MUTATION,
} from "../../../graphql";
import { useAuth } from "../../../context/AuthContext";
import UserSelect from "../../../components/UserSelect";
import OtherPartySelect from "../../../components/OtherPartySelect";

const INITIAL_STATE = {
  OPCode: "",
  description: "",
  duedate: "",
  status: "",
  useridresponsible: "",
};

const CaseOtherParty = ({ handleCancel, casenbr, data }) => {
  const { state: authState } = useAuth();
  const [createOtherParty] = useMutation(CREATE_CASE_OTHER_PARTY_MUTATION);
  const [updateOtherParty] = useMutation(UPDATE_CASE_OTHER_PARTY_MUTATION);

  const [inputs, setInputs] = useState(
    data
      ? {
          ...data,
          duedate: new Date(data.duedate).toISOString().slice(0, 10),
        }
      : INITIAL_STATE
  );

  const onChange = (e, value) => {
    setInputs((prev) => ({
      ...prev,
      [e.target ? e.target.name : e]: e.target ? e.target.value : value,
    }));
  };

  const handleSave = async (e) => {
    e.preventDefault();
    try {
      const date = getDateEST();
      if (data !== null) {
        await updateOtherParty({
          variables: {
            data: {
              otherParty: {
                connect: { doctorcode: parseInt(inputs.OPCode, 10) },
              },
              description: { set: inputs.description },
              status: { set: inputs.status },
              dateedited: { set: date },
              duedate: {
                set: new Date(new Date(inputs.duedate).setUTCHours(13, 0, 0)),
              },
              useridedited: { set: authState?.user?.userid },
              useridresponsible: { set: inputs.useridresponsible },
            },
            where: {
              casenbr_OPCode: {
                casenbr,
                OPCode: data.OPCode,
              },
            },
          },
          refetchQueries: [
            {
              query: LIST_CASE_OTHER_PARTY_QUERY,
              variables: {
                where: {
                  casenbr: { equals: casenbr },
                },
                orderBy: [{ useridresponsible: "asc" }],
              },
              fetchPolicy: "cache-first",
            },
          ],
        });
        toast.success("Service updated successfully.");
      } else {
        await createOtherParty({
          variables: {
            data: {
              casenbr,
              otherParty: {
                connect: {
                  doctorcode: parseInt(inputs.OPCode, 10),
                },
              },
              description: inputs.description,
              status: inputs.status,
              dateadded: date,
              dateedited: date,
              useridadded: authState?.user?.userid,
              useridedited: authState?.user?.userid,
              useridresponsible: inputs.useridresponsible,
              duedate: new Date(new Date(inputs.duedate).setUTCHours(13, 0, 0)),
            },
          },
          refetchQueries: [
            {
              query: LIST_CASE_OTHER_PARTY_QUERY,
              variables: {
                where: {
                  casenbr: { equals: casenbr },
                },
                orderBy: [{ useridresponsible: "asc" }],
              },
              fetchPolicy: "cache-first",
            },
          ],
        });
        toast.success("Service created successfully.");
      }
      handleCancel();
    } catch (err) {
      toast.error("Please check if this Service already exists on Case.");
    }
  };

  const isDisabled = !data && Object.keys(inputs).some((key) => !inputs[key]);

  return (
    <form id="add-other-party-form" onSubmit={handleSave}>
      <header className="page-head">
        <div className="page-head-start">
          <Title size={5}>
            {`${data !== null ? "Edit" : "Create"} Case Other Service`}
          </Title>
        </div>
        <div className="page-head-end">
          <Button.Group hasAddons>
            <Button size="small" type="button" onClick={handleCancel}>
              <span>Cancel</span>
            </Button>
            <Button
              color="primary"
              disabled={isDisabled}
              form="add-other-party-form"
              size="small"
              type="submit"
            >
              <span>Submit</span>
            </Button>
          </Button.Group>
        </div>
      </header>
      <hr />
      <Column.Group multiline>
        <Column size={6}>
          <Field>
            <OtherPartySelect
              required
              label="Other Party"
              name="OPCode"
              value={inputs.OPCode}
              onChange={onChange}
            />
          </Field>
          <Field>
            <Control expanded>
              <Label>Due Date</Label>
              <Input
                autoComplete="new"
                name="duedate"
                size="small"
                type="date"
                value={inputs.duedate}
                onChange={onChange}
              />
            </Control>
          </Field>
        </Column>
        <Column size={6}>
          <Field>
            <Control expanded>
              <Label>Description</Label>
              <Input
                autoComplete="new"
                name="description"
                size="small"
                type="text"
                value={inputs.description}
                onChange={onChange}
              />
            </Control>
          </Field>
          <Field kind="group">
            <Control expanded>
              <Label>Status</Label>
              <RbxSelect.Container fullwidth size="small">
                <RbxSelect
                  name="status"
                  value={inputs.status}
                  onChange={onChange}
                >
                  <RbxSelect.Option value="" />
                  <RbxSelect.Option value="Canceled">Canceled</RbxSelect.Option>
                  <RbxSelect.Option value="Complete">Complete</RbxSelect.Option>
                  <RbxSelect.Option value="Open">Open</RbxSelect.Option>
                  <RbxSelect.Option value="Pre-Approved">
                    Pre-Approved
                  </RbxSelect.Option>
                  <RbxSelect.Option value="Rescheduled">
                    Rescheduled
                  </RbxSelect.Option>
                  <RbxSelect.Option value="Scheduled">
                    Scheduled
                  </RbxSelect.Option>
                </RbxSelect>
              </RbxSelect.Container>
            </Control>
            <UserSelect
              canSelectInactive
              label="Responsibility"
              name="useridresponsible"
              value={inputs.useridresponsible}
              // 01-18-2022 -- Users have indicated that ALL users should be available in this dropdown. Including terminated employees.
              // where={{
              //   fldActive: { equals: 1 },
              //   OR: [
              //     { usertype: { equals: "SC" } },
              //     { usertype: { equals: "TM" } },
              //     { usertype: { equals: "MK" } },
              //     { usertype: { equals: "*" } },
              //   ],
              // }}
              onChange={onChange}
            />
          </Field>
        </Column>
      </Column.Group>
      <hr />
    </form>
  );
};

CaseOtherParty.propTypes = {
  handleCancel: PropTypes.func,
  casenbr: PropTypes.number.isRequired,
  data: PropTypes.object,
};

CaseOtherParty.defaultProps = {
  handleCancel: (e) => e,
  data: null,
};

export default CaseOtherParty;
