import React from "react";
import PropTypes from "prop-types";

import { Field, Control, Label, Input } from "rbx";

import StatusSelect from "../../../../components/StatusSelect";
import CompleteSelect from "../../../../components/CompleteSelect";
import BusinessLineSelect from "../../../../components/BusinessLineSelect";
import DocumentFormatSelect from "../../../../components/DocumentFormatSelect";

const DocumentForm = ({ inputs, onChange, disabled }) => (
  <div className="document-form">
    <Field className="grid-override" kind="group">
      <Control expanded>
        <Label>Document</Label>
        <Input
          required
          autoComplete="new"
          disabled={disabled}
          maxLength="15"
          name="document"
          size="small"
          type="text"
          value={inputs.document}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
      <Control expanded>
        <Label>Name</Label>
        <Input
          required
          autoComplete="new"
          disabled={disabled}
          maxLength="10"
          name="documentname"
          size="small"
          type="text"
          value={inputs.documentname}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
    </Field>
    <Field className="grid-override" kind="group">
      <Control expanded>
        <Label>Description</Label>
        <Input
          required
          autoComplete="new"
          disabled={disabled}
          maxLength="50"
          name="description"
          size="small"
          type="text"
          value={inputs.description}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
      <DocumentFormatSelect
        fullWidth
        disabled={disabled}
        id="outputFormat"
        label="Output Format"
        name="outputFormat"
        size="small"
        value={inputs.outputFormat || ""}
        onChange={onChange}
      />
    </Field>
    <Field className="grid-override" kind="group">
      <Control expanded>
        <Label>Type</Label>
        <Input
          required
          autoComplete="new"
          disabled={disabled}
          maxLength="10"
          name="type"
          placeholder="Invoice, Voucher, Letter..."
          size="small"
          type="text"
          value={inputs.type}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
      <BusinessLineSelect
        showBlank
        disabled={disabled}
        label="Line of Business"
        name="lob"
        setValue={(x) => (x ? parseInt(x, 10) : "")}
        size="small"
        value={inputs.lob}
        onChange={onChange}
      />
    </Field>
    <Field className="grid-override" kind="group">
      <CompleteSelect
        disabled={disabled}
        label="Complete"
        name="complete"
        size="small"
        value={inputs.complete || ""}
        onChange={onChange}
      />
      <StatusSelect
        disabled={disabled}
        label="Status"
        name="status"
        size="small"
        value={inputs.status || ""}
        onChange={onChange}
      />
    </Field>
  </div>
);

DocumentForm.propTypes = {
  inputs: PropTypes.object,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

DocumentForm.defaultProps = {
  inputs: {},
  onChange: () => null,
  disabled: false,
};
export default DocumentForm;
