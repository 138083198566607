import React from "react";
import { startOfDay, endOfDay, isValid } from "date-fns";
import PropTypes from "prop-types";
import { Box, Field, Control, Button, Label } from "rbx";
import FilterInput from "../../../../components/FilterInput";
import DateInput from "../../../../components/DateInput";
import ApolloNoteTypeSelect from "../../../../components/ApolloNoteTypeSelect";

const ApolloNoteFilters = ({ filters, onChange, onReset }) => (
  <Box>
    <div className="list-filters">
      <Field kind="group">
        <DateInput
          getValue={(x) => {
            const date = x?.gte;
            return date ? date.substr(0, 10) : "";
          }}
          id="start-date"
          label="Start Created Date"
          name="CreatedDate"
          setValue={(x) => {
            if (
              x &&
              isValid(new Date(x.split("-").map((y) => parseInt(y, 10))))
            ) {
              return {
                lte:
                  filters.CreatedDate?.lte ||
                  endOfDay(
                    new Date(x.split("-").map((y) => parseInt(y, 10)))
                  ).toISOString(),
                gte: startOfDay(
                  new Date(x.split("-").map((y) => parseInt(y, 10)))
                ).toISOString(),
              };
            }
            return "";
          }}
          value={filters.CreatedDate?.gte || ""}
          onChange={onChange}
        />
        <DateInput
          getValue={(x) => {
            const date = x?.lte;
            return date ? date.substr(0, 10) : "";
          }}
          id="end-date"
          label="End Created Date"
          name="CreatedDate"
          setValue={(x) => {
            if (
              x &&
              isValid(new Date(x.split("-").map((y) => parseInt(y, 10))))
            ) {
              return {
                gte:
                  filters.CreatedDate?.gte ||
                  startOfDay(
                    new Date(x.split("-").map((y) => parseInt(y, 10)))
                  ).toISOString(),
                lte: endOfDay(
                  new Date(x.split("-").map((y) => parseInt(y, 10)))
                ).toISOString(),
              };
            }
            return "";
          }}
          value={filters.CreatedDate?.lte || ""}
          onChange={onChange}
        />
      </Field>
      <Field>
        <Control>
          <ApolloNoteTypeSelect
            getValue={(x) => x?.equals || ""}
            label="Note Type"
            name="NoteTypeID"
            setValue={(x) => (x ? { equals: x } : "")}
            value={filters.NoteTypeID}
            onChange={onChange}
          />
        </Control>
      </Field>
      <Field>
        <FilterInput
          getValue={(x) => x?.contains || ""}
          label="Note Description"
          maxLength={50}
          name="NoteText"
          placeholder="Note Description"
          setValue={(x) => (x ? { contains: x } : "")}
          size="small"
          value={filters.NoteText || ""}
          onChange={onChange}
        />
      </Field>
      <Field>
        <Control>
          <Label>&nbsp;</Label>
          <Button color="danger" size="small" onClick={onReset}>
            Reset
          </Button>
        </Control>
      </Field>
    </div>
  </Box>
);

ApolloNoteFilters.propTypes = {
  filters: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
};

export default ApolloNoteFilters;
