import React from "react";
import PropTypes from "prop-types";
import { Field, Control, Label, Input } from "rbx";
import DocumentSelect from "../../../../components/DocumentSelect";
import QueueSelect from "../../../../components/QueueSelect";
import DocumentTypeSelect from "../../../../components/DocumentTypeSelect";

const CompanyDefaultDocumentForm = ({ inputs, onChange }) => (
  <Field className="grid-override is-four-complete" kind="group">
    <DocumentTypeSelect
      label="Type"
      name="documentType"
      size="small"
      value={inputs.documentType}
      onChange={onChange}
    />
    <DocumentSelect
      fullWidth
      accessor="document"
      label="Document"
      name="documentcode"
      size="small"
      value={inputs.documentcode}
      onChange={onChange}
    />
    <QueueSelect
      label="Where to generate?"
      name="documentqueue"
      size="small"
      type="text"
      value={inputs.documentqueue}
      onChange={onChange}
    />

    <Control expanded>
      <Label>Order</Label>
      <Input
        autoComplete="new"
        name="order"
        size="small"
        type="number"
        value={inputs.order}
        onChange={(e) => onChange(e.target.name, e.target.value)}
      />
    </Control>
  </Field>
);

CompanyDefaultDocumentForm.propTypes = {
  inputs: PropTypes.object,
  onChange: PropTypes.func,
};
CompanyDefaultDocumentForm.defaultProps = {
  inputs: {},
  onChange: () => null,
};
export default CompanyDefaultDocumentForm;
