import { Control, Field, Label, Radio, Button } from "rbx";
import PropTypes from "prop-types";

import React from "react";
import { useQuery } from "@apollo/client";
import { PROVIDER_RUSH_STATUS_OPTIONS } from "../../../../graphql";

// maybe not needed, but allows loading of previous records if the rush status wasn't updated in the db
const getRushOptionFallback = (id, providerRushStatus) => {
  if (providerRushStatus === id) return true;
  if (providerRushStatus === "Fast" || providerRushStatus === "Rush") {
    if (id === "TRRT5DY") return true;
  }
  if (providerRushStatus === "No" && id === "TRRTNOR") return true;
  return false;
};

const ProviderRushStatusOptions = ({
  provider,
  index,
  disabled,
  handleArrChange,
}) => {
  const { data, loading, refetch, error } = useQuery(
    PROVIDER_RUSH_STATUS_OPTIONS,
    {
      variables: {
        where: {
          AND: [
            {
              IsActive: {
                equals: true,
              },
              TRentityAbbrev: {
                equals: "RT",
              },
            },
          ],
        },
        orderBy: [
          {
            TRvalue: "asc",
          },
        ],
      },
      fetchPolicy: "network-only",
    }
  );

  if (loading) return <div>Loading Rush Options...</div>;

  if (error)
    return (
      <div>
        <p>Rush options failed to load</p>
        <Button color="info" size="small" onClick={() => refetch()}>
          Try Again
        </Button>
      </div>
    );

  const radioButton = (id, description) => (
    <Field className="radioWrapper">
      <Control>
        <Label>
          <Radio
            checked={getRushOptionFallback(id, provider.RushStatus)}
            disabled={disabled && provider.fromDB}
            name={`${index}-RushStatus`}
            value={id}
            onChange={(e) =>
              handleArrChange(index, "RushStatus", e.target.value)
            }
          />
          &nbsp;
          <span className="radioItem">{description}</span>
        </Label>
      </Control>
    </Field>
  );

  return (
    <div>
      <h2 className="sectionTitle">Rush Status</h2>
      {data.typereferences.map((rushOption) =>
        radioButton(rushOption.TRid, rushOption.TRdescription)
      )}
    </div>
  );
};

ProviderRushStatusOptions.propTypes = {
  provider: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  disabled: PropTypes.bool.isRequired,
  handleArrChange: PropTypes.func.isRequired,
};

export default ProviderRushStatusOptions;
