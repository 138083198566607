import { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";

import useQueryParams from "./useQueryParams";
import useReferrer from "./useReferrer";

import { SINGLE_RECORD_REQUEST } from "../graphql";

import { parseBase64 } from "../utils";

export default function useRRID() {
  const [state, setState] = useState(null);
  const [rrId] = useQueryParams("rrId");
  const [CLid] = useQueryParams("clid");

  const [getRecordRequest, { data: RecordRequestData }] = useLazyQuery(
    SINGLE_RECORD_REQUEST
  );

  const referrer = useReferrer();

  useEffect(() => {
    const _rrId = parseBase64(rrId);
    const _CLid = parseBase64(CLid);

    if (_rrId && (referrer?.ClientID || referrer?.PersonID)) {
      getRecordRequest({
        variables: {
          where: {
            RRid: parseInt(_rrId, 10),
            CustomerLinkID: parseInt(_CLid, 10),
            PersonID: referrer?.PersonID,
          },
        },
      });
    }
  }, [rrId, referrer?.ClientID, getRecordRequest, CLid, referrer?.PersonID]);

  const recordRequest = RecordRequestData?.recordRequest;

  useEffect(() => {
    if (recordRequest) {
      setState(recordRequest);
    }
  }, [setState, recordRequest]);

  return state;
}
