import { gql } from "@apollo/client";

export const LIST_OTHER_PARTY_TYPES_QUERY = gql`
  query LIST_OTHER_PARTY_TYPES_QUERY(
    $take: Int
    $orderBy: [OtherPartyTypeOrderByInput!]
    $skip: Int
    $where: OtherPartyTypeWhereInput
  ) {
    otherPartyTypes(
      take: $take
      orderBy: $orderBy
      skip: $skip
      where: $where
    ) {
      type
      description
      dateadded
      useridadded
      dateedited
      useridedited
    }
    aggregateOtherPartyType(where: $where) {
      count {
        type
      }
    }
  }
`;

export const ALL_OTHER_PARTY_TYPES_QUERY = gql`
  query ALL_OTHER_PARTY_TYPES_QUERY(
    $take: Int
    $orderBy: [OtherPartyTypeOrderByInput!]
    $skip: Int
    $where: OtherPartyTypeWhereInput
  ) {
    otherPartyTypes(
      take: $take
      orderBy: $orderBy
      skip: $skip
      where: $where
    ) {
      type
      description
      dateadded
      useridadded
      dateedited
      useridedited
    }
  }
`;

export const SINGLE_OTHER_PARTY_TYPE_QUERY = gql`
  query SINGLE_OTHER_PARTY_TYPE_QUERY($where: OtherPartyTypeWhereUniqueInput!) {
    otherPartyType(where: $where) {
      type
      description
      dateadded
      useridadded
      dateedited
      useridedited
    }
  }
`;
