import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Title, Button } from "rbx";
import { useApolloClient, useMutation, useLazyQuery } from "@apollo/client";
import DoctorSpecialtyForm from "../DoctorSpecialtyForm";
import { useModal, useAuth } from "../../../../../context";
import {
  FIND_FIRST_DOCTOR_SPECIALTY_QUERY,
  CREATE_SPECIALTY_MUTATION,
  UPDATE_SPECIALTY_MUTATION,
  // CREATE_AUDIT_TRAIL_MUTATION,
  DELETE_SPECIALTY_MUTATION,
  ALL_SPECIALTIES_QUERY,
  SINGLE_SPECIALTY_QUERY,
} from "../../../../../graphql";
import {
  convertTimeZoneDataBase,
  customToast as toast,
  getDateEST,
} from "../../../../../utils";
import Confirmation from "../../../../../components/Confirmation";

const INITIAL_STATE = {
  specialtycode: "",
  description: "",
};

class CustomDoctorSpecialtyError extends Error {}

const DoctorSpecialtyModal = ({ onComplete, specialtycode }) => {
  const client = useApolloClient();
  const { setModalOpen } = useModal();
  const { state: authState } = useAuth();
  const [inputs, setInputs] = useState(INITIAL_STATE);
  const [updated, setUpdated] = useState([]);
  const [loading, setLoading] = useState(false);
  const [
    getSpecialty,
    { data: specialtyData, loading: queryLoading },
  ] = useLazyQuery(SINGLE_SPECIALTY_QUERY);
  const [createSpecialty] = useMutation(CREATE_SPECIALTY_MUTATION);
  const [updateSpecialty] = useMutation(UPDATE_SPECIALTY_MUTATION);
  // const [createAuditTrail] = useMutation(CREATE_AUDIT_TRAIL_MUTATION);
  const [deleteSpecialty] = useMutation(DELETE_SPECIALTY_MUTATION);

  useEffect(() => {
    if (specialtycode) {
      getSpecialty({
        variables: {
          where: {
            specialtycode,
          },
        },
      });
    }
  }, [specialtycode, getSpecialty]);

  useEffect(() => {
    if (specialtyData?.specialty) {
      setInputs({
        specialtycode: specialtyData.specialty.specialtycode || "",
        description: specialtyData.specialty.description || "",
      });
    }
  }, [specialtyData]);

  const handleChange = (name, value) => {
    setInputs((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (specialtycode) {
      setUpdated((prev) => (prev.includes(name) ? prev : [...prev, name]));
    }
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const date = convertTimeZoneDataBase(getDateEST());
      setLoading(true);
      if (!specialtycode) {
        await createSpecialty({
          variables: {
            data: {
              ...inputs,
              useridedited: authState.user.userid,
              dateedited: date,
              useridadded: authState.user.userid,
              dateadded: date,
            },
          },
          refetchQueries: [
            {
              query: ALL_SPECIALTIES_QUERY,
            },
          ],
        });
        // await createAuditTrail({
        //   variables: {
        //     data: {
        //       tablename: "tblSpecialty",
        //       entityid: createSpecialtyData.createSpecialty.specialtycode,
        //       type: "New",
        //       description: "New specialty created",
        //       useridadded: authState.user.userid,
        //       dateadded: getDateEST(),
        //     },
        //   },
        //   refetchQueries: [
        //     {
        //       query: SINGLE_SPECIALTY_QUERY,
        //       variables: {
        //         where: {
        //           specialtycode: createSpecialtyData.createSpecialty.specialtycode,
        //         },
        //       },
        //     },
        //   ]
        // });
        toast.success("Specialty created successfully.");
      } else {
        await updateSpecialty({
          variables: {
            data: {
              specialtycode: { set: inputs.specialtycode },
              description: { set: inputs.description },
              useridedited: { set: authState.user.userid },
              dateedited: { set: date },
            },
            where: {
              specialtycode,
            },
          },
          refetchQueries: [
            {
              query: ALL_SPECIALTIES_QUERY,
            },
            {
              query: SINGLE_SPECIALTY_QUERY,
              variables: {
                where: {
                  specialtycode,
                },
              },
            },
          ],
        });
        // await createAuditTrail({
        //   variables: {
        //     data: {
        //       tablename: "tblSpecialty",
        //       entityid: specialtycode,
        //       type: "Update",
        //       description: `Updated fields: ${updated.join(", ")}`,
        //       useridadded: authState.user.userid,
        //       dateadded: getDateEST(),
        //     },
        //   },
        // });
        toast.success("Specialty updated successfully.");
      }
      onComplete();
    } catch (err) {
      if (err.message.includes("Unique constraint")) {
        toast.error("This code specialty already exists");
      } else {
        toast.error("Error saving specialty");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (e) => {
    e.preventDefault();

    const performDelete = async () => {
      try {
        setLoading(true);
        const { data: DoctorSpecialtyData } = await client.query({
          query: FIND_FIRST_DOCTOR_SPECIALTY_QUERY,
          variables: {
            where: {
              specialtycode: { equals: specialtycode },
            },
          },
        });
        if (DoctorSpecialtyData?.findFirstDoctorSpecialty) {
          throw new CustomDoctorSpecialtyError(
            "This specialty is associated with a doctor and cannot be removed."
          );
        }
        await deleteSpecialty({
          variables: {
            where: {
              specialtycode,
            },
          },
          refetchQueries: [
            {
              query: ALL_SPECIALTIES_QUERY,
            },
          ],
        });
        toast.success("Specialty deleted successfully");
        onComplete();
      } catch (err) {
        if (err instanceof CustomDoctorSpecialtyError) {
          toast.error(err.message);
        } else {
          toast.error("Error trying to delete Specialty");
        }
      } finally {
        setLoading(false);
      }
    };
    setModalOpen(
      true,
      <Confirmation
        message="Are you sure you want to delete this Specialty?"
        onCancel={() =>
          setModalOpen(
            true,
            <DoctorSpecialtyModal
              specialtycode={specialtycode}
              onComplete={onComplete}
            />
          )
        }
        onConfirm={performDelete}
      />
    );
  };

  const isDisabled =
    !inputs?.specialtycode ||
    (specialtycode && !updated?.length) ||
    queryLoading ||
    loading;

  return (
    <form id="doctor-specialty-form" onSubmit={handleSubmit}>
      <header className="page-head">
        <div className="page-head-start">
          <Title size={5}>
            {[!specialtycode ? "Create" : "Edit", "Doctor Specialty"].join(" ")}
          </Title>
        </div>
        <div className="page-head-end">
          <Button.Group hasAddons>
            <Button
              size="small"
              type="button"
              onClick={() => onComplete(false)}
            >
              <span>Cancel</span>
            </Button>
            <Button
              color="primary"
              disabled={isDisabled}
              form="doctor-specialty-form"
              size="small"
              state={loading ? "loading" : ""}
              type="submit"
            >
              <span>Submit</span>
            </Button>
          </Button.Group>
        </div>
      </header>
      <hr />
      <DoctorSpecialtyForm inputs={inputs} onChange={handleChange} />
      <hr />
      {specialtycode && (
        <Button
          color="danger"
          size="small"
          type="button"
          onClick={handleDelete}
        >
          <span>Delete</span>
        </Button>
      )}
    </form>
  );
};

DoctorSpecialtyModal.propTypes = {
  onComplete: PropTypes.func,
  specialtycode: PropTypes.string,
};

DoctorSpecialtyModal.defaultProps = {
  onComplete: () => null,
  specialtycode: null,
};

export default DoctorSpecialtyModal;
