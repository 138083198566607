import React, { useState } from "react";
import PropTypes from "prop-types";
import { Title, Button } from "rbx";
import { useMutation } from "@apollo/client";

import { useModal, useAuth } from "../../../../context";
import {
  LIST_APOLLO_NOTES_QUERY,
  CREATE_NOTE_MUTATION,
} from "../../../../graphql";
import {
  customToast as toast,
  getDateEST,
  convertTimeZoneDataBase,
} from "../../../../utils";
import ClaimNoteCreateForm from "../ClaimNoteCreateForm";

const INITIAL_STATE = {
  NoteText: "",
  NoteTypeID: 0,
};

const ClaimNoteCreateModal = ({ onComplete, ClaimID }) => {
  const { setModalOpen } = useModal();

  const { state: authState } = useAuth();

  const [inputs, setInputs] = useState(INITIAL_STATE);
  const [loading, setLoading] = useState(false);

  const [createNote] = useMutation(CREATE_NOTE_MUTATION);

  const handleChange = (name, value) => {
    setInputs((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);

      const { NoteText, NoteTypeID } = inputs;

      const date = convertTimeZoneDataBase(getDateEST());
      await createNote({
        variables: {
          data: {
            ClaimID,
            NoteText,
            CreatedDate: date,
            CreatedBy: authState?.user?.userid,
            Archive: false,
            Type: {
              connect: {
                NoteTypeID,
              },
            },
          },
        },
        refetchQueries: [
          {
            query: LIST_APOLLO_NOTES_QUERY,
            variables: {
              where: {
                ClaimID: {
                  equals: parseInt(ClaimID, 10),
                },
              },
              orderBy: [{ NoteID: "asc" }],
              skip: 0,
              take: 25,
            },
          },
        ],
      });
      setModalOpen(false);
      toast.success("Note created successfully.");
      onComplete();
    } catch (err) {
      toast.error("Error creating Note");
    } finally {
      setLoading(false);
    }
  };

  const isDisabled = !inputs.NoteText.length || !inputs.NoteTypeID > 0;

  return (
    <form id="claim-note-form" onSubmit={handleSubmit}>
      <header className="page-head">
        <div className="page-head-start">
          <Title size={5}>Create Note</Title>
        </div>
        <div className="page-head-end">
          <Button.Group hasAddons>
            <Button
              size="small"
              type="button"
              onClick={() => onComplete(false)}
            >
              <span>Cancel</span>
            </Button>
            <Button
              color="primary"
              disabled={isDisabled}
              form="claim-note-form"
              size="small"
              state={loading ? "loading" : ""}
              type="submit"
            >
              <span>Submit</span>
            </Button>
          </Button.Group>
        </div>
      </header>
      <hr />
      <ClaimNoteCreateForm inputs={inputs} onChange={handleChange} />
      <hr />
    </form>
  );
};

ClaimNoteCreateModal.propTypes = {
  onComplete: PropTypes.func,
  ClaimID: PropTypes.number,
};

ClaimNoteCreateModal.defaultProps = {
  onComplete: () => null,
  ClaimID: null,
};

export default ClaimNoteCreateModal;
