import { format } from "date-fns";
import { capitalize, convertTimeZone } from "../../../../../utils";

export const DEFAULT_COLUMNS = [
  {
    id: "location:location",
    Header: "Location",
    accessor: "location.location",
  },
  {
    id: "location:city",
    Header: "City",
    accessor: "location.city",
  },
  {
    id: "location:state",
    Header: "State",
    accessor: "location.state",
  },
  {
    id: "phone",
    Header: "Phone",
    accessor: "location.Phone",
    disableSortBy: true,
  },
  {
    id: "status",
    Header: "status",
    accessor: "status",
    Cell: ({ cell }) => capitalize(cell?.value || "--"),
  },
  {
    Header: "Added",
    id: "dateadded",
    accessor: "dateadded",
    Cell: ({ cell }) =>
      cell?.value
        ? format(convertTimeZone(cell.value), "MM/dd/yyyy hh:mm a")
        : "--",
  },
  {
    Header: "By",
    id: "useridadded",
    accessor: "useridadded",
  },
  {
    Header: "Edited",
    id: "dateedited",
    accessor: "dateedited",
    Cell: ({ cell }) =>
      cell?.value
        ? format(convertTimeZone(cell.value), "MM/dd/yyyy hh:mm a")
        : "--",
  },
  {
    Header: "By",
    id: "useridedited",
    accessor: "useridedited",
  },
];

export default DEFAULT_COLUMNS;
