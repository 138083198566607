import React, { useState } from "react";
import PropTypes from "prop-types";
import { Field, Control, Label, Title, Input, Button } from "rbx";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";

import AlphaInput from "../../../../components/AlphaInput";
import COLUMNS from "./columns";
import { getDateEST } from "../../../../utils";
import DataTable from "../../../../components/DataTable";
import ZipInput from "../../../../components/ZipInput";
import StateSelect from "../../../../components/StateSelect";
import PrefixSelect from "../../../../components/PrefixSelect";

import {
  ALL_COURT_QUERY,
  CREATE_COURT_MUTATION,
} from "../../../../graphql/court";

const INITIAL_STATE = {
  CourtCounty: "",
  CourtType: "",
  CourtAddress: "",
  CourtAddress2: "",
  CourtCity: "",
  CourtState: "",
  CourtZip: "",
  CourtclerkLname: "",
  CourtclerkFname: "",
  CourtclerkTitle: "",
  CourtID: "",
};

const CourtyEntryForm = ({
  inputs,
  mode,
  label,
  disabled,
  onClose,
  onSelect,
}) => {
  const [state, setState] = useState(inputs);
  const [where, setWhere] = useState({ AND: [] });
  const [createCourt, { loading }] = useMutation(CREATE_COURT_MUTATION);

  const createSearchVariables = () => {
    const variables = {};

    for (const key in state) {
      if (state[key] && key !== "county")
        variables[key] = { contains: state[key] }; // zip field adds county, exclude it
    }

    setWhere({ AND: [variables] });
  };

  const createSubmitVariables = () => {
    const variables = {};

    for (const key in state) {
      if (state[key] && key !== "county") variables[key] = state[key]?.trim(); // zip field adds county, exclude it and trim extra whitespace
    }

    return variables;
  };

  const handleChange = (name, value) => {
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const handleReset = () => {
    setState(INITIAL_STATE);
    setWhere({ AND: [] });
  };

  const handleSubmit = async () => {
    const date = getDateEST();
    // get curent user

    await createCourt({
      variables: {
        data: {
          ...createSubmitVariables(),
          CourtEnteredDate: date,
          CourtEnteredBy: "ERF",
        },
      },
      onCompleted: (data) => {
        toast.success("Court Address added successfully");
        onSelect(data.createCourt);
      },
      onError: (error) => {
        toast.error("There was an error. Please try again.");
      },
    });
  };

  const requiredFields = [
    "CourtCounty",
    "CourtType",
    "CourtAddress",
    "CourtCity",
    "CourtState",
    "CourtZip",
  ];

  const title = mode.charAt(0).toUpperCase() + mode.slice(1);
  const adjustedLabel = label.replace(/\s*\([^)]*\)/, "")?.trim();

  const disableButton = () => {
    if (mode === "search") {
      return !Object.values(state).some((value) => value);
    }
    if (mode === "add") {
      for (const key in state) {
        if (requiredFields?.includes(key) && !state[key]) return true;
      }
    }
    return false;
  };

  const searchButtons = (
    <Field kind="group">
      <Button.Group>
        <Button
          color="danger"
          disabled={disableButton()}
          size="small"
          onClick={handleReset}
        >
          Reset
        </Button>
        <Button
          color="primary"
          disabled={disableButton()}
          size="small"
          onClick={createSearchVariables}
        >
          Search
        </Button>
      </Button.Group>
    </Field>
  );

  const submitButton = (
    <Button
      color="primary"
      disabled={disableButton() || loading}
      form="rg-form"
      size="small"
      state={loading ? "loading" : ""}
      type="submit"
      onClick={handleSubmit}
    >
      Submit
    </Button>
  );

  const header = (
    <header className="page-head">
      <div className="page-head-start">
        <Title size={5}>{`${title} ${adjustedLabel}`}</Title>
      </div>
      <div className="page-head-end">
        <Button.Group hasAddons>
          <Button size="small" type="button" onClick={onClose}>
            <span>Close</span>
          </Button>
          {mode === "add" && submitButton}
        </Button.Group>
      </div>
    </header>
  );

  const dataTable = (
    <React.Fragment>
      <hr />
      <DataTable
        aggregateKey="CourtID"
        aggregateName="aggregateCourt"
        columns={COLUMNS}
        loaderHeight="293px"
        name="courts"
        orderBy={[{ id: "CourtID", desc: false }]}
        pageSize={10}
        query={ALL_COURT_QUERY}
        showPageSize={false}
        where={where}
        onCellClick={(cell, e) => {
          const {
            column: { id },
          } = cell;
          if (id === "businessCard") {
            e.stopPropagation();
          }
        }}
        onRowClick={onSelect}
      />
    </React.Fragment>
  );

  return (
    <React.Fragment>
      {header}

      <Field id="court-entry-form">
        <Field style={{ marginBottom: "1.25rem" }}>
          <Title subtitle size={6} style={{ marginBottom: "0.75rem" }}>
            Clerk Information
          </Title>
          <Field id="court-entry-form-section" kind="group">
            <Control expanded>
              <Label>Title</Label>
              <PrefixSelect
                disabled={disabled || mode === "view"}
                name="CourtclerkTitle"
                size="small"
                value={state.CourtclerkTitle}
                onChange={handleChange}
              />
            </Control>
            <Control expanded>
              <Label>First Name</Label>
              <AlphaInput
                disabled={disabled || mode === "view"}
                name="CourtclerkFname"
                placeholder="First Name"
                size="small"
                value={state.CourtclerkFname}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
              />
            </Control>
            <Control expanded>
              <Label>Last Name</Label>
              <Input // input type requested to allow space at end of last name in case suffix needed
                disabled={disabled || mode === "view"}
                name="CourtclerkLname"
                placeholder="Last Name"
                size="small"
                value={state.CourtclerkLname}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
              />
            </Control>
            <Control expanded>
              <Label>Court ID</Label>
              <Input
                disabled
                name="CourtID"
                placeholder="Court ID"
                size="small"
                value={state.CourtID}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
              />
            </Control>
          </Field>
        </Field>

        <Field id="court-entry-form-section">
          <Title subtitle size={6} style={{ marginBottom: "0.75rem" }}>
            Court Information
          </Title>
          <Field
            id="court-entry-form-section"
            kind="group"
            style={{ width: "100%" }}
          >
            <Control expanded>
              <Label>County</Label>
              <Input
                disabled={disabled || mode === "view"}
                name="CourtCounty"
                placeholder="County"
                required={mode === "add"}
                size="small"
                value={state.CourtCounty}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
              />
            </Control>
            <Control expanded>
              <Label>Type</Label>
              <Input
                disabled={disabled || mode === "view"}
                name="CourtType"
                placeholder="Type"
                required={mode === "add"}
                size="small"
                value={state.CourtType}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
              />
            </Control>
          </Field>

          <Field id="court-entry-form-section" kind="group">
            <Control expanded>
              <Label>Address 1</Label>
              <Input
                disabled={disabled || mode === "view"}
                name="CourtAddress"
                placeholder="Address"
                required={mode === "add"}
                size="small"
                value={state.CourtAddress}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
              />
            </Control>
            <Control expanded>
              <Label>Address 2</Label>
              <Input
                disabled={disabled || mode === "view"}
                name="CourtAddress2"
                placeholder="Address 2"
                size="small"
                value={state.CourtAddress2}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
              />
            </Control>
            <Control expanded>
              <Label>City</Label>
              <Input
                disabled={disabled || mode === "view"}
                name="CourtCity"
                placeholder="City"
                required={mode === "add"}
                size="small"
                value={state.CourtCity}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
              />
            </Control>
            <Control expanded>
              <StateSelect
                disabled={disabled || mode === "view"}
                id="state"
                label="State"
                name="CourtState"
                required={mode === "add"}
                size="small"
                value={state.CourtState}
                onChange={handleChange}
              />
            </Control>
            <Control expanded>
              <ZipInput
                disabled={disabled || mode === "view"}
                label="Zip"
                name="CourtZip"
                placeholder="Zip"
                required={mode === "add"}
                size="small"
                value={state.CourtZip}
                onChange={handleChange}
              />
            </Control>
          </Field>
        </Field>
      </Field>

      {!!where.AND.length && dataTable}

      <hr />

      {mode === "search" && searchButtons}
    </React.Fragment>
  );
};

CourtyEntryForm.propTypes = {
  inputs: PropTypes.object,
  mode: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSelect: PropTypes.func,
};

CourtyEntryForm.defaultProps = {
  mode: "search",
  label: "",
  disabled: false,
  inputs: INITIAL_STATE,
  onSelect: () => null,
};

export default CourtyEntryForm;
