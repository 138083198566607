import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";

import { useMutation, useLazyQuery, useQuery } from "@apollo/client";
import { Title, Button, Field, Control } from "rbx";
import { toast } from "react-toastify";
import {
  LIST_DOCTOR_DOCUMENTS_QUERY,
  SINGLE_DOCTOR_DOCUMENT_QUERY,
  CREATE_DOCTOR_DOCUMENT_MUTATION,
  UPDATE_DOCTOR_DOCUMENT_MUTATION,
  SINGLE_DOCTOR_QUERY,
} from "../../../../graphql";

import DoctorSelect from "../../../../components/DoctorSelect";

import InvestigatorDocumentForm from "../InvestigatorDocumentForm";
import { useAuth } from "../../../../context";
import { getDateEST } from "../../../../utils";

const initialState = {
  type: "",
  description: "",
  expiredate: "",
  pathfilename: "",
  order: "",
};

const convertInputToVariables = (variables, keys, adding = false) =>
  keys.reduce((acc, curr) => {
    if (variables[curr]) {
      acc[curr] = adding ? variables[curr] : { set: variables[curr] };
    }
    return acc;
  }, {});

const InvestigatorDocumentModal = ({ doctorcode, recid, onComplete }) => {
  const { state: authState } = useAuth();
  const [inputs, setInputs] = useState({ ...initialState });

  const { data: DoctorData } = useQuery(SINGLE_DOCTOR_QUERY, {
    variables: { where: { doctorcode: parseInt(doctorcode, 10) } },
  });

  const [getDoctorDocument, { data: getDoctorDocumentData }] = useLazyQuery(
    SINGLE_DOCTOR_DOCUMENT_QUERY
  );

  const [createDoctorDocument] = useMutation(CREATE_DOCTOR_DOCUMENT_MUTATION);
  const [updateDoctorDocument] = useMutation(UPDATE_DOCTOR_DOCUMENT_MUTATION);

  useEffect(() => {
    if (recid && doctorcode) {
      getDoctorDocument({
        variables: {
          where: {
            doctorcode_recid: {
              doctorcode: parseInt(doctorcode, 10),
              recid: parseInt(recid, 10),
            },
          },
        },
      });
    }
  }, [doctorcode, getDoctorDocument, recid]);

  useEffect(() => {
    const doctorDocument = getDoctorDocumentData?.doctorDocument;
    if (doctorDocument) {
      setInputs(
        Object.keys(initialState).reduce(
          (acc, curr) => ({ ...acc, [curr]: doctorDocument[curr] || "" }),
          {}
        )
      );
    }
  }, [getDoctorDocumentData]);

  const handleSave = async (e) => {
    try {
      e.preventDefault();
      const refetchQueries = [
        {
          query: LIST_DOCTOR_DOCUMENTS_QUERY,
          variables: {
            where: { doctorcode: { equals: parseInt(doctorcode, 10) } },
          },
        },
      ];

      const date = getDateEST();

      if (recid) {
        await updateDoctorDocument({
          variables: {
            data: {
              ...convertInputToVariables(
                inputs,
                Object.keys(initialState),
                false
              ),
              expiredate: inputs.expiredate
                ? { set: new Date(inputs.expiredate) }
                : null,
              DateEdited: { set: date },
              order: inputs.order && { set: parseInt(inputs.order, 10) },
              UserIDEdited: { set: authState?.user?.userid },
            },
            where: {
              doctorcode_recid: {
                doctorcode: parseInt(doctorcode, 10),
                recid: parseInt(recid, 10),
              },
            },
          },
          refetchQueries: [
            ...refetchQueries,
            {
              query: SINGLE_DOCTOR_DOCUMENT_QUERY,
              variables: {
                where: {
                  doctorcode_recid: {
                    recid: parseInt(recid, 10),
                    doctorcode: parseInt(doctorcode, 10),
                  },
                },
              },
            },
          ],
        });
      } else {
        await createDoctorDocument({
          variables: {
            data: {
              ...convertInputToVariables(
                inputs,
                Object.keys(initialState),
                true
              ),
              doctorcode: parseInt(doctorcode, 10),
              dateadded: date,
              expiredate: inputs.expiredate || null,
              useridadded: authState?.user?.userid,
              DateEdited: date,
              order: inputs.order ? parseInt(inputs.order, 10) : null,
              UserIDEdited: authState?.user?.userid,
            },
          },
          refetchQueries,
        });
      }
      toast.success(
        `Investigator Document ${recid ? "updat" : "creat"}ing successfully.`
      );
      onComplete(true);
    } catch (err) {
      toast.error(
        `Error ${recid ? "updat" : "creat"}ing investigator document.`
      );
    }
  };

  const handleChange = (name, value) => {
    setInputs((prev) => ({ ...prev, [name]: value }));
  };

  const dirPath = useMemo(() => {
    const doctor = DoctorData?.doctor;
    if (doctor) {
      return `/Dr Credentials/${doctor.state}/${doctor.prefix} ${
        doctor.lastname
      }, ${doctor.firstname} Credentials ${new Date().getFullYear()}`;
    }

    return "";
  }, [DoctorData]);
  return (
    <form id="doctor-location-form" onSubmit={handleSave}>
      <header className="page-head">
        <div className="page-head-start">
          <Title size={5}>Investigator Document</Title>
        </div>
        <div className="page-head-end">
          <Button.Group hasAddons>
            <Button
              size="small"
              type="button"
              onClick={() => onComplete(false)}
            >
              <span>Cancel</span>
            </Button>
            <Button
              color="primary"
              form="doctor-location-form"
              size="small"
              type="submit"
            >
              <span>Submit</span>
            </Button>
          </Button.Group>
        </div>
      </header>
      <hr />
      <Field kind="group">
        <Control expanded>
          <DoctorSelect disabled label="Investigator" value={doctorcode} />
        </Control>
      </Field>
      <InvestigatorDocumentForm
        dirPath={dirPath}
        inputs={inputs}
        onChange={handleChange}
      />
      <hr />
      {/* <pre>
        <code>{JSON.stringify(inputs, null, 2)}</code>
      </pre> */}
    </form>
  );
};

InvestigatorDocumentModal.propTypes = {
  doctorcode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  recid: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onComplete: PropTypes.func,
};

InvestigatorDocumentModal.defaultProps = {
  doctorcode: "",
  recid: "",
  onComplete: () => null,
};
export default InvestigatorDocumentModal;
