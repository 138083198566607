import React from "react";
import PropTypes from "prop-types";

// components
import { Switch, Route } from "react-router-dom";
import { Tab } from "rbx";

// internal components
import Locations from "../../routes/Locations";
import Profile from "../../routes/Profile";
import General from "../../routes/General";
import Cases from "../../routes/Cases";
import Notes from "../../routes/Notes";
import Qualifications from "../../routes/Qualitifications";
import Documents from "../../routes/Documents";
import UserDefinedFields from "../../routes/UserDefinedFields";
import Statistics from "../../routes/Statistics";
import Exceptions from "../../routes/Exceptions";

const DoctorTabs = ({
  doctorcode,
  tab,
  history,
  onChange,
  inputs,
  disabled,
}) => (
  <React.Fragment key="company-tabs">
    <Tab.Group kind="boxed">
      <Tab
        active={tab === "profile"}
        onClick={() => history.push(`/investigators/${doctorcode}/profile`)}
      >
        Profile
      </Tab>
      <Tab
        active={tab === "locations"}
        onClick={() => history.push(`/investigators/${doctorcode}/locations`)}
      >
        Locations
      </Tab>
      <Tab
        active={tab === "general"}
        onClick={() => history.push(`/investigators/${doctorcode}/general`)}
      >
        General
      </Tab>
      <Tab
        active={tab === "cases"}
        onClick={() => history.push(`/investigators/${doctorcode}/cases`)}
      >
        Cases
      </Tab>
      <Tab
        active={tab === "notes"}
        onClick={() => history.push(`/investigators/${doctorcode}/notes`)}
      >
        Notes
      </Tab>
      <Tab
        active={tab === "qualifications"}
        onClick={() =>
          history.push(`/investigators/${doctorcode}/qualifications`)
        }
      >
        Qualifications
      </Tab>
      <Tab
        active={tab === "documents"}
        onClick={() => history.push(`/investigators/${doctorcode}/documents`)}
      >
        Documents
      </Tab>
      <Tab
        active={tab === "user-defined"}
        onClick={() =>
          history.push(`/investigators/${doctorcode}/user-defined`)
        }
      >
        User Defined Fields
      </Tab>
      <Tab
        active={tab === "exceptions"}
        onClick={() => history.push(`/investigators/${doctorcode}/exceptions`)}
      >
        exceptions
      </Tab>
      <Tab
        active={tab === "statistics"}
        onClick={() => history.push(`/investigators/${doctorcode}/statistics`)}
      >
        Statistics
      </Tab>
    </Tab.Group>
    <Switch>
      <Route path="/investigators/:doctorcode/profile">
        <Profile disabled={disabled} inputs={inputs} onChange={onChange} />
      </Route>
      <Route path="/investigators/:doctorcode/locations">
        <Locations disabled={disabled} inputs={inputs} onChange={onChange} />
      </Route>
      <Route path="/investigators/:doctorcode/general">
        <General disabled={disabled} inputs={inputs} onChange={onChange} />
      </Route>
      <Route path="/investigators/:doctorcode/cases">
        <Cases disabled={disabled} inputs={inputs} onChange={onChange} />
      </Route>
      <Route path="/investigators/:doctorcode/notes">
        <Notes disabled={disabled} inputs={inputs} onChange={onChange} />
      </Route>
      <Route path="/investigators/:doctorcode/qualifications">
        <Qualifications
          disabled={disabled}
          inputs={inputs}
          onChange={onChange}
        />
      </Route>
      <Route path="/investigators/:doctorcode/documents">
        <Documents disabled={disabled} inputs={inputs} onChange={onChange} />
      </Route>
      <Route path="/investigators/:doctorcode/user-defined">
        <UserDefinedFields
          disabled={disabled}
          inputs={inputs}
          onChange={onChange}
        />
      </Route>
      <Route path="/investigators/:doctorcode/exceptions">
        <Exceptions doctorcode={doctorcode} />
      </Route>
      <Route path="/investigators/:doctorcode/statistics">
        <Statistics />
      </Route>
    </Switch>
  </React.Fragment>
);

DoctorTabs.propTypes = {
  doctorcode: PropTypes.string.isRequired,
  tab: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  inputs: PropTypes.object.isRequired,
};

DoctorTabs.defaultProps = {};

export default DoctorTabs;
