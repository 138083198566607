/* eslint-disable react/prop-types */
import React from "react";
import { format } from "date-fns";
import { Icon, Generic } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { convertTimeZone } from "../../../../utils";

export const generateColumns = ({ duplicateCaseItem, addSubCaseItem }) => [
  {
    maxWidth: "1%",
    Header: "",
    id: "duplicate",
    Cell: ({ cell }) => (
      <Generic
        style={{ textAlign: "center" }}
        title="Duplicate Case"
        type="button"
        onClick={(e) => {
          e.stopPropagation();
          duplicateCaseItem(cell.row.original);
        }}
      >
        <Icon color="inherit">
          <FontAwesomeIcon icon="copy" />
        </Icon>
      </Generic>
    ),
  },
  {
    Header: "Case",
    id: "casenbr",
    accessor: "casenbr",
  },
  {
    Header: "Case Type",
    id: "casetype",
    accessor: "casetype",
    Cell: ({ cell }) => cell?.value || "--",
  },
  {
    Header: "Appt Date",
    id: "Appttime",
    accessor: "Appttime",
    Cell: ({ cell }) =>
      cell?.value
        ? format(convertTimeZone(cell.value), "MM/dd/yyyy hh:mm a")
        : "--",
  },
  {
    Header: "Doctor",
    id: "doctorName",
    accessor: "doctorName",
    Cell: ({ cell }) => cell?.value || "--",
  },
  {
    Header: "Location",
    id: "location",
    accessor: "location",
    Cell: ({ cell }) => cell?.value || "--",
  },
  {
    Header: "Specialty",
    id: "specialty",
    accessor: "specialty",
    Cell: ({ cell }) => cell?.value || "--",
  },
  {
    Header: "Status",
    id: "status",
    accessor: "status",
    Cell: ({ cell }) => cell?.value || "--",
  },
  {
    Header: "Client",
    id: "clientName",
    accessor: "clientName",
    Cell: ({ cell }) => cell?.value || "--",
  },
  {
    Header: "Service",
    id: "service",
    accessor: "service",
    Cell: ({ cell }) => cell?.value || "--",
  },
  {
    maxWidth: "1%",
    Header: "",
    id: "Subcase",
    Cell: ({ cell }) => (
      <Generic
        size="small"
        title="Add Sub Case"
        type="button"
        onClick={(e) => {
          e.stopPropagation();
          addSubCaseItem(cell.row.original);
        }}
      >
        <Icon>
          <FontAwesomeIcon icon="plus" />
        </Icon>
      </Generic>
    ),
  },
];

export default generateColumns;
