import React from "react";
import PropTypes from "prop-types";
import { Control, Label, Select } from "rbx";
import { useQuery } from "@apollo/client";

import { LIST_SPECIALTIES_QUERY } from "../../graphql";

const SpecialtySelect = ({
  value,
  name,
  label,
  size,
  onChange,
  disabled,
  required,
  getValue,
  setValue,
}) => {
  const { data: specialtyData } = useQuery(LIST_SPECIALTIES_QUERY, {
    variables: {
      where: {
        NOT: [
          {
            description: {
              startsWith: "Si ", // KEEP SPACE. Removes old IS stuff from list
            },
          },
          {
            description: {
              startsWith: "SIU", // Removes old IS stuff from list
            },
          },
        ],
      },
      orderBy: {
        description: "asc",
      },
    },
  });

  const indicateRequired =
    required && (!value || value === "" || value === "0" || value === 0);

  const specialties = specialtyData?.specialties;

  const handleChange = (e) => {
    onChange(e.target.name, setValue(e.target.value));
  };

  return (
    <Control expanded>
      <Label>{label}</Label>
      <Select.Container
        fullwidth
        size={size}
        state={Array.isArray(specialties) ? "" : "loading"}
      >
        <Select
          disabled={disabled}
          name={name}
          style={{
            border: indicateRequired ? "1px #e63362 solid" : "",
            borderRadius: "4px",
            boxShadow: indicateRequired
              ? "0 0 0 0.125em rgba(230, 51, 98, 0.25)"
              : "",
          }}
          value={getValue(value)}
          onChange={handleChange}
        >
          <Select.Option value="" />
          {Array.isArray(specialties) &&
            specialties.map((specialty) => (
              <Select.Option
                key={specialty.specialtycode}
                value={specialty.specialtycode}
              >
                {specialty.description}
              </Select.Option>
            ))}
        </Select>
      </Select.Container>
    </Control>
  );
};

SpecialtySelect.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.number,
  ]),
  name: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  getValue: PropTypes.func,
  setValue: PropTypes.func,
};

SpecialtySelect.defaultProps = {
  value: "",
  name: "",
  label: "Specialty",
  size: "small",
  onChange: () => null,
  disabled: false,
  required: false,
  getValue: (x) => x,
  setValue: (x) => x,
};

export default SpecialtySelect;
