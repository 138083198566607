import { gql } from "@apollo/client";

export const CREATE_SPECIALTY_MUTATION = gql`
  mutation CREATE_SPECIALTY_MUTATION($data: SpecialtyCreateInput!) {
    createSpecialty(data: $data) {
      specialtycode
      description
    }
  }
`;

export const UPDATE_SPECIALTY_MUTATION = gql`
  mutation UPDATE_SPECIALTY_MUTATION(
    $data: SpecialtyUpdateInput!
    $where: SpecialtyWhereUniqueInput!
  ) {
    updateSpecialty(data: $data, where: $where) {
      specialtycode
      description
    }
  }
`;

export const DELETE_SPECIALTY_MUTATION = gql`
  mutation DELETE_SPECIALTY_MUTATION($where: SpecialtyWhereUniqueInput!) {
    deleteSpecialty(where: $where) {
      specialtycode
    }
  }
`;