import React, { useState, useMemo } from "react";
import { Field, Control, Button, Icon, Input } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";

import Confirmation from "../../../../components/Confirmation";
import { useModal } from "../../../../context";
import DataTable from "../../../../components/DataTable";
import {
  LIST_APOLLO_NOTES_QUERY,
  DELETE_APOLLO_NOTE_MUTATION,
} from "../../../../graphql";
import { ClaimNoteCreateModal } from "../../components";
import { debounce, customToast as toast } from "../../../../utils";
import { generateColumns } from "./columns";

const NotesListPage = () => {
  const { claimcode } = useParams();

  const DEFAULT_FILTER_STATE = {
    AND: [
      {
        ClaimID: {
          equals: parseInt(claimcode, 10),
        },
      },
    ],
    OR: [
      {
        NoteText: {
          contains: "",
        },
      },
    ],
  };

  const [where, setWhere] = useState(DEFAULT_FILTER_STATE);

  const { setModalOpen } = useModal();

  const [deleteNote] = useMutation(DELETE_APOLLO_NOTE_MUTATION);

  const COLUMNS = useMemo(() => {
    const handleDelete = (note) => {
      const performDelete = async () => {
        try {
          setModalOpen(false, "");

          await deleteNote({
            variables: {
              where: {
                NoteID: parseInt(note.NoteID, 10),
              },
            },
            refetchQueries: [
              {
                query: LIST_APOLLO_NOTES_QUERY,
                variables: {
                  where: DEFAULT_FILTER_STATE,
                },
                fetchPolicy: "cache-first",
              },
            ],
          });
          toast.success("Note deleted successfully.");
        } catch (err) {
          toast.error(`Error deleting Note.`);
        }
      };
      setModalOpen(
        true,
        <Confirmation
          message="Are you sure you want to delete this note?"
          onCancel={() => setModalOpen(false, "")}
          onConfirm={performDelete}
        />
      );
    };
    return generateColumns(handleDelete);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [claimcode, deleteNote, setModalOpen]);

  return (
    <div>
      <div className="page-head">
        <div className="page-head-start">
          <Field kind="group">
            <Control>
              <Input
                autoComplete="off"
                id="search-input"
                placeholder="Search..."
                size="small"
                onChange={debounce(({ target: { value } }) => {
                  if (value) {
                    setWhere({
                      AND: [
                        {
                          ClaimID: {
                            equals: parseInt(claimcode, 10),
                          },
                        },
                      ],
                      OR: [
                        {
                          NoteText: {
                            contains: value,
                          },
                        },
                      ],
                    });
                  } else {
                    setWhere(DEFAULT_FILTER_STATE);
                  }
                }, 500)}
              />
            </Control>
            <Control>
              <Button
                color="primary"
                size="small"
                onClick={() => {
                  setModalOpen(
                    true,
                    <ClaimNoteCreateModal
                      ClaimID={parseInt(claimcode, 10)}
                      onComplete={() => setModalOpen(false)}
                    />
                  );
                }}
              >
                <Icon>
                  <FontAwesomeIcon icon="plus" />
                </Icon>
                <span>Add</span>
              </Button>
            </Control>
          </Field>
        </div>
      </div>
      <DataTable
        aggregateKey="NoteID"
        aggregateName="aggregateNotes"
        columns={COLUMNS}
        name="findManyNotes"
        orderBy={[{ id: "CreatedDate", desc: true }]}
        query={LIST_APOLLO_NOTES_QUERY}
        where={where}
      />
    </div>
  );
};

NotesListPage.propTypes = {};

export default NotesListPage;
