const DEFAULT_COLUMNS = [
  {
    Header: "Claim",
    id: "claimnbr",
    accessor: "claimnbr",
  },
  {
    Header: "Doctor",
    id: "doctor:lastname",
    accessor: "doctor.fullname",
  },
  {
    Header: "Specialty",
    id: "doctorspecialty",
    accessor: "doctorspecialty",
  },
  {
    Header: "Status",
    id: "status",
    accessor: "Status.statusdesc",
  },
  {
    Header: "Case Type",
    id: "casetype",
    accessor: "caseType.description",
  },
  {
    Header: "Service",
    id: "service:description",
    accessor: "service.description",
  },
];

export default DEFAULT_COLUMNS;
