/* eslint-disable import/prefer-default-export */
import { gql } from "@apollo/client";

export const LIST_EXAMINEE_CASES_QUERY = gql`
  query LIST_EXAMINEE_CASES_QUERY(
    $where: ExamineeCaseWhereInput
    $orderBy: [ExamineeCaseOrderByInput!]
    $take: Int
    $skip: Int
  ) {
    examineeCases(where: $where, orderBy: $orderBy, skip: $skip, take: $take) {
      casenbr
      casetype
      Appttime
      doctorName
      location
      specialty
      status
      clientName
      service
    }
    aggregateExamineeCase(where: $where) {
      count {
        _all
      }
    }
  }
`;
