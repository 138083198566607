import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Title, Button } from "rbx";
import { useMutation, useLazyQuery } from "@apollo/client";

import {
  CREATE_CASE_DOCUMENT_MUTATION,
  LIST_CASE_DOCUMENT_QUERY,
  SINGLE_CASE_DOCUMENT_QUERY,
} from "../../../../graphql";

import { customToast as toast, getDateEST } from "../../../../utils";
import { useAuth } from "../../../../context/AuthContext";

import CaseDocumentReportForm from "../CaseDocumentReportForm";

const CaseDocumentReportModal = ({ onComplete, casenbr }) => {
  const { state: authState } = useAuth();
  const [inputs, setInputs] = useState({
    description: "",
    seqno: "",
    reporttype: "Report",
  });
  const [loading, setLoading] = useState(false);
  const [getCaseDocument, { data: CaseDocumentData }] = useLazyQuery(
    SINGLE_CASE_DOCUMENT_QUERY
  );

  useEffect(() => {
    if (inputs.seqno) {
      getCaseDocument({
        variables: { where: { seqno: parseInt(inputs.seqno, 10) } },
      });
    }
  }, [getCaseDocument, inputs.seqno]);

  useEffect(() => {
    const caseDocument = CaseDocumentData?.caseDocument;
    if (caseDocument) {
      setInputs((prev) => ({ ...prev, description: caseDocument.description }));
    }
  }, [CaseDocumentData?.caseDocument]);

  const [createCaseDocument] = useMutation(CREATE_CASE_DOCUMENT_MUTATION);

  const handleChange = (name, value) => {
    setInputs((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (CaseDocumentData?.caseDocument) {
        await createCaseDocument({
          variables: {
            data: {
              reporttype: inputs.reporttype,
              type: "Report",
              description: inputs.description,
              sfilename: CaseDocumentData.caseDocument.sfilename,
              document: CaseDocumentData.caseDocument.document,
              CaseItem: {
                connect: {
                  casenbr: parseInt(casenbr, 10),
                },
              },
              dateadded: getDateEST(),
              useridadded: authState?.user?.userid,
              useridedited: authState?.user?.userid,
              dateedited: getDateEST(),
            },
          },
          refetchQueries: [
            {
              query: LIST_CASE_DOCUMENT_QUERY,
              variables: {
                where: {
                  casenbr: { equals: parseInt(casenbr, 10) },
                  type: { equals: "Report" },
                  // OR: [
                  //   { reporttype: { equals: "Report" } },
                  //   { reporttype: { equals: "Addendum" } },
                  //   { reporttype: { equals: "File Review" } },
                  // ],
                },
              },
            },
          ],
        });
      }
      toast.success(`Case Reports attached successfully`);
      onComplete();
    } catch (err) {
      toast.error(`Error attaching case report.`);
    } finally {
      setLoading(false);
    }
  };

  const isDisabled = Object.keys(inputs).some((key) => !inputs[key]);

  return (
    <form id="add-case-report-form" onSubmit={handleSubmit}>
      <header className="page-head">
        <div className="page-head-start">
          <Title size={5}>Attach External Report</Title>
        </div>
        <div className="page-head-end">
          <Button.Group hasAddons>
            <Button
              size="small"
              type="button"
              onClick={() => onComplete(false)}
            >
              <span>Cancel</span>
            </Button>
            <Button
              color="primary"
              disabled={isDisabled || loading}
              form="add-case-report-form"
              size="small"
              state={loading ? "loading" : ""}
              type="submit"
            >
              <span>Submit</span>
            </Button>
          </Button.Group>
        </div>
      </header>
      <hr />
      <CaseDocumentReportForm
        casenbr={casenbr}
        inputs={inputs}
        onChange={handleChange}
      />
      <hr />
    </form>
  );
};

CaseDocumentReportModal.propTypes = {
  onComplete: PropTypes.func.isRequired,
  casenbr: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default CaseDocumentReportModal;
