export const validateTRNWSCTTRCCSCT = (value, complete) => {
  if (complete) {
    const re = /^[A-Za-z0-9]{8}/;
    if (re.test(value)) {
      return { OK: true };
    }
    return { OK: false };
  }
  const re = /^[A-Za-z0-9]+$/;
  if ((value === "" || re.test(value)) && value.length <= 8) {
    return { OK: true, value };
  }
  return { OK: false };
};

export const validateTRNWNWTRCCNW = (value, ClaimNumber, complete) => {
  if (complete) {
    const re = /^[0-9]{6}-[a-zA-Z]{2}/;
    if (re.test(value)) {
      return { OK: true };
    }
    return { OK: false };
  }
  const re1 = /^[0-9]+$/;
  const re2 = /^[A-Za-z]+$/;
  if (value === "") {
    return { OK: true, value };
  }
  if (value.length <= 5 && re1.test(value)) {
    return { OK: true, value };
  }
  if (value.length === 6 && re1.test(value)) {
    if (ClaimNumber[6] !== "-") {
      const v = value.concat("-");
      return { OK: true, value: v };
    }
    return { OK: true, value };
  }
  if (
    value.length > 6 &&
    value.length <= 9 &&
    re1.test(value.substr(0, 6)) &&
    (value.substr(7) === "" || re2.test(value.substr(7)))
  ) {
    return { OK: true, value };
  }
  return { OK: false };
};

export const validateTRNWHARTRCCNWHAR = (value, ClaimNumber, complete) => {
  if (complete) {
    const re = /^(N|n)[0-9]{1}-[0-9]{6}/;
    if (re.test(value)) {
      return { OK: true };
    }
    return { OK: false };
  }
  const re1 = /^[0-9]+$/;
  if (value === "") {
    return { OK: true, value };
  }
  if (value.length === 1 && (value === "n" || value === "N")) {
    return { OK: true, value };
  }
  if (
    value.length === 2 &&
    (value.substr(0, 1) === "n" || value.substr(0, 1) === "N") &&
    re1.test(value.substr(1))
  ) {
    if (ClaimNumber[2] !== "-") {
      const v = value.concat("-");
      return { OK: true, value: v };
    }
    return { OK: true, value };
  }
  if (value.length === 3 && value[2] === "-") {
    return { OK: true, value };
  }
  if (
    value.length > 2 &&
    value.length <= 9 &&
    (value.substr(0, 1) === "n" || value.substr(0, 1) === "N") &&
    re1.test(value.substr(1, 1)) &&
    re1.test(value.substr(3))
  ) {
    return { OK: true, value };
  }
  return { OK: false };
};

export const validateTRNWNWPCTRCCNWPC = (value, complete) => {
  if (complete) {
    const re = /^CLM000[0-9]{5}/;
    const re2 = /^CLM-000[0-9]{5}/;
    const re3 = /^CLM[0-9]{5}/;
    if (re.test(value) || re2.test(value) || re3.test(value)) {
      return { OK: true };
    }
    return { OK: false };
  }
  const re = /^[0-9]+$/;
  const re2 = /^[Cc]+$/;
  if (value === "") {
    return { OK: true, value };
  }
  if (value.length < 3 && re2.test(value.substr(0, 1))) {
    return { OK: true, value: "CLM" };
  }
  if (value.length === 4 && value[3] === "-") {
    return { OK: true, value };
  }
  if (
    value.length < 13 &&
    value.substr(0, 3) === "CLM" &&
    (value.substr(4) === "" || re.test(value.substr(4)))
  ) {
    return { OK: true, value };
  }

  return { OK: false };
};

export const validateTRNWOTHTRCCNWOTH = (value, complete) => {
  if (complete) {
    const re = /^NW[0-9]{6}(c|C)/;
    if (re.test(value)) {
      return { OK: true };
    }
    return { OK: false };
  }
  if (value === "") {
    return { OK: true, value };
  }
  const re = /^[nN]+$/;
  const re2 = /^[0-9]+$/;
  if (value.length < 2 && re.test(value.substr(0, 1))) {
    return { OK: true, value: "NW" };
  }
  if (
    value.length < 9 &&
    value.substr(0, 2) === "NW" &&
    (!value[2] || re2.test(value.substr(2, 6)))
  ) {
    return { OK: true, value };
  }
  if (
    value.length === 9 &&
    value.substr(0, 2) === "NW" &&
    re2.test(value.substr(2, 6)) &&
    (value[8] === "C" || value[8] === "c")
  ) {
    return { OK: true, value };
  }
  return { OK: false };
};
