import React, { useState, useEffect } from "react";
import { Title, Field, Control, Button, Icon, Input } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";

import { useAuth } from "../../../../context/AuthContext";
import { useModal } from "../../../../context";
import DataTable from "../../../../components/DataTable";
import {
  LIST_USERS_QUERY,
  CREATE_USER_MUTATION,
  UPDATE_USER_MUTATION,
  DELETE_USER_MUTATION,
} from "../../../../graphql";
import { UserModal } from "../../components/Users";
import {
  debounce,
  customToast as toast,
  getDateEST,
  convertTimeZoneDataBase,
} from "../../../../utils";
import Loader from "../../../../components/Loader";

import COLUMNS from "./columns";

const DEFAULT_FILTER_STATE = {
  OR: [
    {
      userid: { contains: "" },
    },
    {
      firstname: { contains: "" },
    },
    {
      lastname: { contains: "" },
    },
  ],
};

const UsersListPage = () => {
  const { state: authState } = useAuth();
  const history = useHistory();

  const [where, setWhere] = useState(DEFAULT_FILTER_STATE);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const { setModalOpen } = useModal();

  const [createUser] = useMutation(CREATE_USER_MUTATION);
  const [updateUser] = useMutation(UPDATE_USER_MUTATION);
  const [deleteUser] = useMutation(DELETE_USER_MUTATION);
  // const [createAuditTrail] = useMutation(CREATE_AUDIT_TRAIL_MUTATION);

  useEffect(() => {
    const listener = history.listen(() => {
      const route = history.location.pathname;
      if (route === "/setup/user-groups" || route === "/setup/user-functions") {
        return;
      }

      if (
        route.length > 12 &&
        route !== "/setup/user-groups" &&
        route.split("/").includes("users")
      ) {
        const userid = route.slice(13, route.length);

        setModalOpen(
          true,
          <UserModal
            values={{ userid }}
            onComplete={(
              adding,
              originalUserType,
              inputs,
              previousCode,
              updated
            ) => {
              setModalOpen(false);
              if (inputs) {
                handleSave(
                  adding,
                  originalUserType,
                  inputs,
                  previousCode,
                  updated
                );
              } else {
                setModalOpen(false);
              }
            }}
            onDelete={handleDelete}
          />
        );
      } else {
        setModalOpen(false);
      }
    });
    return () => {
      listener();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  const handleDelete = async (userid, usertype) => {
    try {
      setLoading(true);
      await deleteUser({
        variables: {
          where: {
            userid_usertype: {
              userid,
              usertype,
            },
          },
        },
        refetchQueries: [
          {
            query: LIST_USERS_QUERY,
          },
        ],
      });
      toast.success("User deleted successfully");
    } catch (err) {
      toast.error("Error trying to delete User");
    } finally {
      setLoading(false);
    }
  };

  const handleRowClick = (row) => {
    const values = {
      userid: row.userid || "",
      firstname: row.firstname || "",
      lastname: row.lastname || "",
      email: row.email || "",
      usertype: row.usertype || "",
    };
    setModalOpen(
      true,
      <UserModal
        values={values}
        onComplete={(
          adding,
          originalUserType,
          inputs,
          previousCode,
          updated
        ) => {
          setModalOpen(false);
          if (inputs) {
            handleSave(adding, originalUserType, inputs, previousCode, updated);
          } else {
            setModalOpen(false);
          }
        }}
        onDelete={handleDelete}
      />
    );
  };

  const handleSave = async (
    adding,
    originalUserType,
    inputs,
    previousCode,
    updated = []
  ) => {
    try {
      setLoading(true);
      const date = convertTimeZoneDataBase(getDateEST());
      if (adding) {
        await createUser({
          variables: {
            data: {
              ...inputs,
              useridedited: authState.user.userid,
              dateedited: date,
              useridadded: authState.user.userid,
              dateadded: date,
              isPasswordUpdated: false,
              resetPassword: true,
            },
          },
          refetchQueries: [
            {
              query: LIST_USERS_QUERY,
            },
          ],
        });

        setModalOpen(false);
        toast.success("User created successfully.");
      } else {
        await updateUser({
          variables: {
            data: {
              firstname: { set: inputs.firstname },
              lastname: { set: inputs.lastname },
              usertype: { set: inputs.usertype },
              email: { set: inputs.email },
              useridedited: { set: authState.user.userid },
              dateedited: { set: date },
            },
            where: {
              userid_usertype: {
                userid: previousCode,
                usertype: originalUserType,
              },
            },
          },
          refetchQueries: [
            {
              query: LIST_USERS_QUERY,
            },
          ],
        });

        setModalOpen(false);
        toast.success("User updated successfully.");
      }
    } catch (err) {
      if (err.message.includes("Unique constraint")) {
        toast.error("This User already exists");
      } else {
        toast.error("Error saving User");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleSearchChange = debounce(({ target: { value } }) => {
    if (value) {
      setSearchValue(value);
      setWhere({
        OR: [
          {
            userid: { contains: value },
          },
          {
            firstname: { contains: value },
          },
          {
            lastname: { contains: value },
          },
        ],
      });
    } else {
      setSearchValue("");
      setWhere({
        OR: [
          {
            userid: { contains: "" },
          },
          {
            firstname: { contains: "" },
          },
          {
            lastname: { contains: "" },
          },
        ],
      });
    }
  }, 500);

  if (loading) return <Loader />;

  return (
    <div>
      <div className="section-header">
        <Title style={{ marginRight: 15 }}>Users</Title>
        <Field kind="group" style={{ paddingTop: "0.3rem" }}>
          <Control>
            <Input
              autoComplete="off"
              id="search-input"
              placeholder="Search..."
              size="small"
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value);
                handleSearchChange(e);
              }}
            />
          </Control>

          <Control>
            <Button
              color="primary"
              size="small"
              onClick={() => {
                setModalOpen(
                  true,
                  <UserModal
                    adding
                    onComplete={(adding, originalUserType, inputs, updated) => {
                      if (inputs) {
                        handleSave(adding, originalUserType, inputs, updated);
                      } else {
                        setModalOpen(false);
                      }
                    }}
                  />
                );
              }}
            >
              <Icon>
                <FontAwesomeIcon icon="plus" />
              </Icon>
              <span>Add</span>
            </Button>
          </Control>
        </Field>
      </div>

      <DataTable
        aggregateKey="userid"
        aggregateName="aggregateUser"
        columns={COLUMNS}
        fetchPolicy="network-only"
        name="users"
        orderBy={[{ id: "userid", desc: false }]}
        query={LIST_USERS_QUERY}
        where={where}
        onRowClick={handleRowClick}
      />
    </div>
  );
};

UsersListPage.propTypes = {};

export default UsersListPage;
