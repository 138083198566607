import React from "react";
import PropTypes from "prop-types";
import { Tab } from "rbx";
import { Link, useLocation } from "react-router-dom";

const ExamineeTabs = ({ chartnbr }) => {
  const location = useLocation();

  return (
    <Tab.Group kind="boxed">
      <Tab
        active={location.pathname.includes("profile")}
        as={Link}
        to={`/examinees/${chartnbr}/profile`}
      >
        Profile
      </Tab>
      {chartnbr !== "new" && (
        <Tab
          active={location.pathname.includes("cases")}
          as={Link}
          to={`/examinees/${chartnbr}/cases`}
        >
          Cases
        </Tab>
      )}
    </Tab.Group>
  );
};

ExamineeTabs.propTypes = {
  chartnbr: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
};

export default ExamineeTabs;
