import { gql } from "@apollo/client";

export const LIST_DEGREES_QUERY = gql`
  query LIST_DEGREES_QUERY(
    $where: DegreeWhereInput
    $orderBy: [DegreeOrderByInput!]
    $take: Int
    $skip: Int
  ) {
    degrees(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
      degreecode
      description
    }
  }
`;

export const ALL_DEGREES_QUERY = gql`
  query ALL_DEGREES_QUERY(
    $where: DegreeWhereInput
    $orderBy: [DegreeOrderByInput!]
    $take: Int
    $skip: Int
  ) {
    degrees(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
      degreecode
      description
    }
    aggregateDegree(where: $where) {
      count {
        _all
      }
    }
  }
`;

export const SINGLE_DEGREE_QUERY = gql`
  query SINGLE_DEGREE_QUERY($where: DegreeWhereUniqueInput!) {
    degree(where: $where) {
      degreecode
      description
    }
  }
`;

