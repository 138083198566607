import { gql } from "@apollo/client";

export const LIST_DOCTOR_LOCATIONS_QUERY = gql`
  query LIST_DOCTOR_LOCATIONS_QUERY(
    $where: DoctorLocationWhereInput
    $orderBy: [DoctorLocationOrderByInput!]
    $take: Int
    $skip: Int
  ) {
    doctorLocations(
      where: $where
      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      locationcode
      location {
        locationcode
        location
        city
        state

        Phone
      }
      doctorcode
      status
      useridadded
      useridedited
      dateadded
      dateedited
    }
    aggregateDoctorLocation(where: $where) {
      count {
        doctorcode
      }
    }
  }
`;

export const ALL_DOCTOR_LOCATIONS_QUERY = gql`
  query ALL_DOCTOR_LOCATIONS_QUERY(
    $where: DoctorLocationWhereInput
    $orderBy: [DoctorLocationOrderByInput!]
    $take: Int
    $skip: Int
  ) {
    doctorLocations(
      where: $where
      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      locationcode
      location {
        locationcode
        location
        city
        state
        Phone
        county
        zip
      }
      doctorcode
      doctor {
        doctorcode
        firstname
        lastname
        credentials
        notes
        DoctorSpecialty {
          doctorcode
          specialtycode
        }
      }
      status
      useridadded
      useridedited
      dateadded
      dateedited
    }
  }
`;

export const SINGLE_DOCTOR_LOCATION_QUERY = gql`
  query SINGLE_DOCTOR_LOCATION_QUERY($where: DoctorLocationWhereUniqueInput!) {
    doctorLocation(where: $where) {
      locationcode
      location {
        locationcode
        location
        city
        state
        Phone
      }
      doctorcode
      status
      useridadded
      useridedited
      dateadded
      dateedited
    }
  }
`;
