import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import { useMutation, useApolloClient } from "@apollo/client";
import { Title, Button } from "rbx";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import DocumentForm from "../DocumentForm";
import { useAuth } from "../../../../context";
import { getDateEST, convertInputToVariables } from "../../../../utils";

import {
  CREATE_DOCUMENT_MUTATION,
  ALL_DOCUMENTS_QUERY,
  FIND_FIRST_DOCUMENT_QUERY,
} from "../../../../graphql";

const INITIAL_STATE = {
  document: "",
  documentname: "",
  description: "",
  type: "",
  outputFormat: "PDF",
  complete: "Incomplete",
  status: "Inactive",
  lob: "",
};

const AddDocumentModal = ({ onComplete }) => {
  const { state: authState } = useAuth();
  const history = useHistory();
  const client = useApolloClient();

  const [inputs, setInputs] = useState({ ...INITIAL_STATE });
  const [createDocument] = useMutation(CREATE_DOCUMENT_MUTATION);
  const [loading, setLoading] = useState(false);

  const handleSave = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      const date = getDateEST();
      const {
        data: { findFirstDocument },
      } = await client.query({
        query: FIND_FIRST_DOCUMENT_QUERY,
        variables: {
          where: { document: { equals: inputs.document } },
        },
        fetchPolicy: "network-only",
      });

      if (!findFirstDocument) {
        const data = {
          ...convertInputToVariables(inputs, Object.keys(INITIAL_STATE), true, [
            "lob",
          ]),
          dateadded: date,
          dateedited: date,
          useridadded: authState.user.userid,
          useridedited: authState.user.userid,
        };

        if (inputs.lob) {
          data.businessLine = {
            connect: {
              businesslineid: inputs.lob,
            },
          };
        }

        const { data: CreateDocumentData } = await createDocument({
          variables: {
            data,
          },
          refetchQueries: [
            {
              query: ALL_DOCUMENTS_QUERY,
              variables: {
                orderBy: [{ document: "asc" }],
              },
            },
          ],
        });

        history.push(
          `/documents/${CreateDocumentData.createDocument.SeqNo}/profile`
        );
        toast.success(`Document created.`);
        onComplete();
      } else {
        toast.warn("A document with this identifier already exists.");
      }
    } catch (err) {
      toast.error(`Error creating Document`);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (name, value) => {
    setInputs((prev) => ({ ...prev, [name]: value }));
  };

  const isDisabled = useMemo(
    () =>
      Object.keys(inputs)
        .filter((input) => input !== "lob")
        .some((key) => typeof inputs[key] === "string" && !inputs[key].length),
    [inputs]
  );

  return (
    <form id="document-create-form" onSubmit={handleSave}>
      <header className="page-head">
        <div className="page-head-start">
          <Title size={5}>Create Document</Title>
        </div>
        <div className="page-head-end">
          <Button.Group hasAddons>
            <Button
              disabled={loading}
              size="small"
              type="button"
              onClick={() => onComplete(false)}
            >
              <span>Cancel</span>
            </Button>
            <Button
              color="primary"
              disabled={isDisabled || loading}
              form="document-create-form"
              size="small"
              state={loading ? "loading" : ""}
              type="submit"
            >
              <span>Submit</span>
            </Button>
          </Button.Group>
        </div>
      </header>
      <hr />
      <DocumentForm
        disabled={loading}
        inputs={inputs}
        showCompanyGroup={false}
        onChange={handleChange}
      />
      <hr />
      {/* <pre>
        <code>{JSON.stringify(inputs, null, 2)}</code>
      </pre> */}
    </form>
  );
};

AddDocumentModal.propTypes = {
  onComplete: PropTypes.func,
};

AddDocumentModal.defaultProps = {
  onComplete: () => null,
};

export default AddDocumentModal;
