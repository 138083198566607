import React from "react";

import PropTypes from "prop-types";

import { Field, Control, Input, Label } from "rbx";

const DoctorSpecialtyForm = ({ inputs, onChange }) => (
  <div>
    <Field kind="group">
      <Control expanded>
        <Label>Code</Label>
        <Input
          autoComplete="new"
          name="specialtycode"
          size="small"
          value={inputs.specialtycode}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
      <Control expanded>
        <Label>Description</Label>
        <Input
          autoComplete="new"
          name="description"
          size="small"
          value={inputs.description}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
    </Field>
  </div>
);

DoctorSpecialtyForm.propTypes = {
  inputs: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default DoctorSpecialtyForm;
