import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Control, Label, Select } from "rbx";
import { useLazyQuery } from "@apollo/client";

import { LIST_STATE_QUERY } from "../../graphql/clients";

const additionalUSAAOptions = [
  { StateName: "American Samoa", Statecode: "AS" },
  { StateName: "Federated States of Micronesia", Statecode: "FM" },
  { StateName: "Guam", Statecode: "GU" },
  { StateName: "Marshall Islands", Statecode: "MH" },
  { StateName: "Northern Mariana Islands", Statecode: "MP" },
  { StateName: "Palau", Statecode: "PW" },
];

const StateSelect = ({
  value,
  name,
  label,
  size,
  onChange,
  onBlur,
  disabled,
  required,
  getValue,
  setValue,
  expanded,
  displayFullLabel,
  extendedOptionsUSAA,
}) => {
  const [states, setStates] = useState(null);
  const [getStates, { data: getStatesData }] = useLazyQuery(LIST_STATE_QUERY);
  const mountedRef = useRef(true);

  useEffect(() => {
    getStates({
      variables: { orderBy: { StateName: "asc" } },
    });
  }, [getStates]);

  useEffect(() => {
    if (mountedRef.current) {
      setStates(getStatesData?.states);

      if (extendedOptionsUSAA && getStatesData?.states) {
        const withUSAAOptions = [
          ...getStatesData?.states,
          ...additionalUSAAOptions,
        ];
        withUSAAOptions.sort((a, b) => a.StateName.localeCompare(b.StateName));
        setStates(withUSAAOptions);
      }
    }
  }, [getStatesData, mountedRef, extendedOptionsUSAA]);

  const indicateRequired =
    required && (!value || value === "" || value === "0" || value === 0);

  const handleChange = (e) => {
    onChange(e.target.name, setValue(e.target.value));
  };

  const handleBlur = (e) => {
    onBlur(e.target.name, setValue(e.target.value));
  };

  return (
    <Control expanded={expanded}>
      <Label>{label}</Label>
      <Select.Container
        fullwidth
        size={size}
        state={Array.isArray(states) ? "" : "loading"}
      >
        <Select
          disabled={disabled}
          name={name}
          required={required}
          style={{
            border: indicateRequired ? "1px #e63362 solid" : "",
            boxShadow: indicateRequired
              ? "0 0 0 0.125em rgba(230, 51, 98, 0.25)"
              : "",
          }}
          value={getValue(value)}
          onBlur={handleBlur}
          onChange={handleChange}
        >
          <Select.Option value="" />
          {Array.isArray(states) &&
            states.map((state) => (
              <Select.Option key={state.Statecode} value={state.Statecode}>
                {state.Statecode}
                {displayFullLabel && ` - ${state.StateName}`}
              </Select.Option>
            ))}
        </Select>
      </Select.Container>
    </Control>
  );
};

StateSelect.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.number,
  ]),
  name: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  required: PropTypes.bool,
  getValue: PropTypes.func,
  setValue: PropTypes.func,
  onBlur: PropTypes.func,
  expanded: PropTypes.bool,
  displayFullLabel: PropTypes.bool,
  extendedOptionsUSAA: PropTypes.bool,
};

StateSelect.defaultProps = {
  value: "",
  name: "",
  label: "State",
  size: "small",
  onChange: () => null,
  disabled: false,
  required: false,
  getValue: (x) => x,
  setValue: (x) => x,
  onBlur: () => null,
  expanded: false,
  displayFullLabel: false,
  extendedOptionsUSAA: false,
};

export default StateSelect;
