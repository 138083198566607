import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Button } from "rbx";

import styled from "styled-components";

const StyledButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: -13px;
  margin-top: 0.5rem;
`;

const StyledParagraph = styled.p`
  color: #2a4734;
`;
const CustomToastWithLink = ({ showAction, message, actionLabel, to }) => (
  <div>
    <StyledParagraph>{message}</StyledParagraph>
    {showAction && (
      <StyledButtonWrapper>
        <Button fullwidth as={Link} color="primary" size="small" to={to}>
          {actionLabel}
        </Button>
      </StyledButtonWrapper>
    )}
  </div>
);

CustomToastWithLink.propTypes = {
  showAction: PropTypes.bool,
  message: PropTypes.string.isRequired,
  actionLabel: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

CustomToastWithLink.defaultProps = {
  showAction: true,
};
export default CustomToastWithLink;
