import React from "react";
import PropTypes from "prop-types";

import CaseParametersForm from "../components/CaseParametersForm";

const CaseParameters = ({ inputs, onChange, disabled, onSubmit, hasSubcases }) => (
  <CaseParametersForm
    disabled={disabled}
    hasSubcases={hasSubcases}
    inputs={inputs}
    onChange={onChange}
    onSubmit={onSubmit}
  />
);

CaseParameters.propTypes = {
  inputs: PropTypes.object,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  hasSubcases: PropTypes.bool,
};

CaseParameters.defaultProps = {
  inputs: {},
  onChange: (e) => e,
  disabled: false,
  hasSubcases: false,
};

export default CaseParameters;
