/* eslint-disable import/prefer-default-export */
import { gql } from "@apollo/client";

export const LIST_RATS_BUSINESS_UNIT = gql`
  query Businessunits(
    $where: BusinessunitWhereInput
    $orderBy: [BusinessunitOrderByInput!]
  ) {
    businessunits(where: $where, orderBy: $orderBy) {
      PK_BUid
      BUdescription
      BUrefCode
      BUisRetired
      BUisNW
    }
  }
`;
