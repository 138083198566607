import React, { useState, useContext, useRef } from "react";
import PropTypes from "prop-types";
import { useApolloClient } from "@apollo/client";

import { Field, Control, Button, Textarea, Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from "../../context/AuthContext";
import { ModalContext } from "../../context/ModalContext";
import Confirmation from "../Confirmation";
import { customToast as toast } from "../../utils";
import { SPELL_CHECK_QUERY } from "../../graphql";

const NotesInput = ({
  name,
  value: initialValue,
  onChange,
  disabled,
  saveNotes,
  contentEditable,
  onFormatClick,
}) => {
  const notesRef = useRef(null);
  const { setModalOpen } = useContext(ModalContext);
  const { state: authState } = useAuth();
  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(false);

  const client = useApolloClient();

  const saveData = async () => {
    const date = new Date().toLocaleString("en-US", {
      timeZone: "America/New_York",
      hour12: true,
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    });
    onChange(
      name,
      `${authState.user.userid} ${date.replace(",", "")}\r\n${value}\r\n\r\n${
        initialValue || ""
      }\r\n`
    );
    setValue("");
    setModalOpen(false);
  };

  const validateGrammar = async (input) => {
    const {
      data: { spellCheck },
    } = await client.query({
      query: SPELL_CHECK_QUERY,
      variables: {
        input,
      },
    });
    return spellCheck;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const spellCheck = await validateGrammar(value);
      if (spellCheck) {
        saveData();
      } else {
        setModalOpen(
          true,
          <Confirmation
            message="Are you sure you want to save this note with spelling errors?"
            onCancel={() => setModalOpen(false)}
            onConfirm={saveData}
          />
        );
      }
    } catch (err) {
      toast.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (output) => {
    setValue(output);
  };

  const handleContentEditableBlur = async (e, checkSpelling = true) => {
    const text = new DOMParser().parseFromString(
      e.target.value.replace(/<br(\s)?(\/)?>/, "\r\n"),
      "text/html"
    ).body.textContent;

    const formattedText = text.replace(/(?:\r|\n|\r\n)/g, "\r\n");

    const spellCheck = checkSpelling
      ? await validateGrammar(formattedText)
      : true;

    if (spellCheck) {
      onChange(name, formattedText);
    } else {
      setModalOpen(
        true,
        <Confirmation
          message="Are you sure you want to save this note with spelling errors?"
          onCancel={() => setModalOpen(false)}
          onConfirm={() => {
            onChange(name, formattedText);
            setModalOpen(false);
          }}
        />
      );
    }
  };

  return (
    <div className="notes-input">
      {contentEditable && (
        <form>
          <Field>
            <Control expanded>
              <Textarea
                size="small"
                value={value}
                onChange={(e) => handleChange(e.target.value)}
              />
            </Control>
          </Field>
          <Field>
            <Control>
              <Button
                color="primary"
                disabled={!value || disabled || loading}
                size="small"
                state={loading ? "loading" : ""}
                onClick={handleSubmit}
              >
                <Icon>
                  <FontAwesomeIcon icon="save" />
                </Icon>
                <span>Save Note</span>
              </Button>
            </Control>
          </Field>
        </form>
      )}

      <Textarea
        defaultValue={initialValue}
        key={initialValue}
        readOnly={!contentEditable}
        ref={notesRef}
        size="small"
        style={{
          marginTop: 16,
          paddingTop: 16,
          paddingBottom: 16,
          paddingLeft: 8,
          paddingRight: 8,
          whiteSpace: "pre-line",
          // border: "none",s
          minHeight: 500,
          // fontSize: "0.75rem",
        }}
        onBlur={handleContentEditableBlur}
      />

      <Control style={{ textAlign: "end", marginTop: 10 }}>
        <Button
          color="primary"
          size="small"
          type="button"
          onClick={(e) =>
            handleContentEditableBlur({ target: notesRef?.current }, false)
          }
        >
          <Icon>
            <FontAwesomeIcon icon="paragraph" />
          </Icon>
          <span>Format</span>
        </Button>
      </Control>
    </div>
  );
};

NotesInput.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  saveNotes: PropTypes.func,
  disabled: PropTypes.bool,
  contentEditable: PropTypes.bool,
  onFormatClick: PropTypes.func,
};

NotesInput.defaultProps = {
  name: "",
  value: "",
  onChange: () => null,
  saveNotes: () => null,
  disabled: false,
  contentEditable: false,
  onFormatClick: () => null,
};
export default NotesInput;
