import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Title, Button } from "rbx";
import { useMutation, useLazyQuery, useApolloClient } from "@apollo/client";

import { useAuth } from "../../../../../../context";
import {
  LIST_BUSINESS_LINES_QUERY,
  SINGLE_BUSINESS_LINE_QUERY,
  CREATE_BUSINESS_LINE_MUTATION,
  UPDATE_BUSINESS_LINE_MUTATION,
  DELETE_BUSINESS_LINE_MUTATION,
  FIND_FIRST_SERVICE_QUERY,
} from "../../../../../../graphql";
import {
  convertTimeZoneDataBase,
  customToast as toast,
  getDateEST,
} from "../../../../../../utils";
import BusinessLineForm from "../BusinessLineForm";

const INITIAL_STATE = {
  name: "",
  description: "",
  dontVoucher: false,
};

class CustomBusinessLineError extends Error {}

const BusinessLineModal = ({ onComplete, businesslineid }) => {
  const { state: authState } = useAuth();
  const client = useApolloClient();

  const [inputs, setInputs] = useState(INITIAL_STATE);

  const [
    getBusinessLine,
    { data: BusinessLineData, loading: BusinessLineLoading },
  ] = useLazyQuery(SINGLE_BUSINESS_LINE_QUERY);

  const [
    createBusinessLine,
    { loading: CreateBusinessLineLoading },
  ] = useMutation(CREATE_BUSINESS_LINE_MUTATION);
  const [
    updateBusinessLine,
    { loading: UpdateBusinessLineLoading },
  ] = useMutation(UPDATE_BUSINESS_LINE_MUTATION);
  const [
    deleteBusinessLine,
    { loading: DeleteBusinessLineLoading },
  ] = useMutation(DELETE_BUSINESS_LINE_MUTATION);

  useEffect(() => {
    if (businesslineid) {
      getBusinessLine({
        variables: {
          where: {
            businesslineid: parseInt(businesslineid, 10),
          },
        },
      });
    }
  }, [businesslineid, getBusinessLine]);

  useEffect(() => {
    const businessLine = BusinessLineData?.businessLine;
    if (businessLine) {
      setInputs({
        name: businessLine.name || "",
        description: businessLine.description || "",
        dontVoucher: !!businessLine.dontVoucher,
      });
    }
  }, [BusinessLineData]);

  const handleChange = (name, value) => {
    setInputs((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();

      const date = convertTimeZoneDataBase(getDateEST());

      if (!businesslineid) {
        await createBusinessLine({
          variables: {
            data: {
              ...inputs,
              useridedited: authState.user.userid,
              dateedited: date,
              useridadded: authState.user.userid,
              dateadded: date,
            },
          },
          refetchQueries: [
            {
              query: LIST_BUSINESS_LINES_QUERY,
            },
          ],
        });

        toast.success("Line of Business created successfully.");
      } else {
        await updateBusinessLine({
          variables: {
            data: {
              name: { set: inputs.name },
              description: { set: inputs.description },
              dontVoucher: { set: inputs.dontVoucher },
            },
            where: {
              businesslineid,
            },
          },
          refetchQueries: [
            {
              query: LIST_BUSINESS_LINES_QUERY,
            },
            {
              query: SINGLE_BUSINESS_LINE_QUERY,
              variables: {
                where: {
                  businesslineid,
                },
              },
            },
          ],
        });
        toast.success("Line of Business updated successfully.");
      }
      onComplete();
    } catch (err) {
      toast.error("Error saving Line of Business");
    }
  };

  const handleDelete = async () => {
    try {
      const {
        data: { findFirstService },
      } = await client.query({
        query: FIND_FIRST_SERVICE_QUERY,
        variables: {
          where: { LOB: { equals: parseInt(businesslineid, 10) } },
        },
      });

      if (findFirstService)
        throw new CustomBusinessLineError(
          "This Line of Business is being used and cannot be deleted."
        );

      await deleteBusinessLine({
        variables: { where: { businesslineid } },
        refetchQueries: [
          {
            query: LIST_BUSINESS_LINES_QUERY,
          },
        ],
      });
      toast.success("Line of Business deleted successfully.");
      onComplete();
    } catch (err) {
      const message =
        err instanceof CustomBusinessLineError
          ? err.message
          : "Error deleting Line of Business";
      toast.error(message);
    }
  };

  const isLoading =
    BusinessLineLoading ||
    CreateBusinessLineLoading ||
    UpdateBusinessLineLoading ||
    DeleteBusinessLineLoading;

  const isDisabled = !inputs.description || !inputs.name || isLoading;

  return (
    <form id="business-line-form" onSubmit={handleSubmit}>
      <header className="page-head">
        <div className="page-head-start">
          <Title size={5}>
            {[businesslineid ? "Edit" : "Create", "Line of Business"].join(" ")}
          </Title>
        </div>
        <div className="page-head-end">
          <Button.Group hasAddons>
            <Button
              disabled={isLoading}
              size="small"
              type="button"
              onClick={() => onComplete()}
            >
              <span>Cancel</span>
            </Button>
            <Button
              color="primary"
              disabled={isDisabled}
              form="business-line-form"
              size="small"
              state={isLoading ? "loading" : ""}
              type="submit"
            >
              <span>Submit</span>
            </Button>
          </Button.Group>
        </div>
      </header>
      <hr />
      <BusinessLineForm
        disabled={isLoading}
        inputs={inputs}
        onChange={handleChange}
      />
      <hr />
      {businesslineid && (
        <Button
          color="danger"
          size="small"
          type="button"
          onClick={handleDelete}
        >
          <span>Delete</span>
        </Button>
      )}
    </form>
  );
};

BusinessLineModal.propTypes = {
  onComplete: PropTypes.func,
  businesslineid: PropTypes.number,
};

BusinessLineModal.defaultProps = {
  onComplete: () => null,
  businesslineid: null,
};

export default BusinessLineModal;
