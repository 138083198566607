import React from "react";
import PropTypes from "prop-types";

import { Box, Field, Control, Button, Label } from "rbx";

import CaseTypeSelect from "../../../../../components/CaseTypeSelect";
import OfficeSelect from "../../../../../components/OfficeSelect";

const CaseTypeDocumentFilters = ({ filters, onChange, onReset }) => (
  <Box>
    <div className="list-filters">
      <Field>
        <OfficeSelect
          label="Office"
          name="officecode"
          size="small"
          value={filters.officecode?.equals || ""}
          where={{ status: { equals: "Active" } }}
          onChange={onChange}
        />
      </Field>
      <Field>
        <CaseTypeSelect
          name="casetypecode"
          showBlankOptionLabel="All"
          value={filters.casetypecode?.equals || ""}
          onChange={onChange}
        />
      </Field>
      <Field kind="group">
        <Control>
          <Label>&nbsp;</Label>
          <Button color="danger" size="small" onClick={onReset}>
            Reset
          </Button>
        </Control>
      </Field>
    </div>
  </Box>
);

CaseTypeDocumentFilters.propTypes = {
  filters: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
};

export default CaseTypeDocumentFilters;
