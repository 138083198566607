import { gql } from "@apollo/client";

export const CREATE_DEGREE_MUTATION = gql`
  mutation CREATE_DEGREE_MUTATION($data: DegreeCreateInput!) {
    createDegree(data: $data) {
      degreecode
      description
    }
  }
`;

export const UPDATE_DEGREE_MUTATION = gql`
  mutation UPDATE_DEGREE_MUTATION(
    $data: DegreeUpdateInput!
    $where: DegreeWhereUniqueInput!
  ) {
    updateDegree(data: $data, where: $where) {
      degreecode
      description
    }
  }
`;

export const DELETE_DEGREE_MUTATION = gql`
  mutation DELETE_DEGREE_MUTATION($where: DegreeWhereUniqueInput!) {
    deleteDegree(where: $where) {
      degreecode
    }
  }
`;