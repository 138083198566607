import React, { useState } from "react";
import PropTypes from "prop-types";
import { useApolloClient } from "@apollo/client";
import { Label, Title, Button, Content, Field } from "rbx";

import { customToast as toast } from "../../../../../utils";
import {
  CREATE_USER_SECURITY_MUTATION,
  ALL_USER_SECURITIES_QUERY,
} from "../../../../../graphql";
import UserGroupMultiSelect from "../../../../../components/UserGroupMultiSelect";
import OfficeMultiSelect from "../../../../../components/OfficeMultiSelect";

class CustomUserSecurityFormError extends Error {
  constructor(errors) {
    super();
    this.errors = errors;
  }
}
const UserSecurityForm = ({ disabled, onComplete, userid }) => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);

  const client = useApolloClient();

  const [inputs, setInputs] = useState({
    offices: [],
    groups: [],
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      setErrors([]);
      const errorStack = [];

      await Promise.all(
        inputs.offices.map((office) =>
          Promise.all(
            inputs.groups.map(async (group) => {
              try {
                await client.mutate({
                  mutation: CREATE_USER_SECURITY_MUTATION,
                  variables: {
                    data: {
                      userid,
                      office: {
                        connect: {
                          officecode: office.value,
                        },
                      },
                      groupcode: group.value,
                    },
                  },
                });
              } catch (err) {
                const message = "Unknown issue. Please contact support";
                if (!err.message.includes("Unique constraint")) {
                  errorStack.push({
                    office: office.label,
                    group: group.label,
                    message,
                  });
                }
              }
            })
          )
        )
      );
      await client.reFetchObservableQueries({
        include: [
          {
            query: ALL_USER_SECURITIES_QUERY,
            variables: {
              where: {
                userid: { equals: userid },
              },
            },
          },
        ],
      });
      if (errorStack.length) {
        throw new CustomUserSecurityFormError(errorStack);
      }
      toast.success("All user securities created successfully.");
      onComplete();
    } catch (err) {
      if (err instanceof CustomUserSecurityFormError) {
        setErrors(err.errors);
      }
      toast.error(
        "Whoops! Something went wrong creating these user securities. Please see output below or contact support."
      );
    } finally {
      setLoading(false);
    }
  };

  const isDisabled =
    loading ||
    (Array.isArray(inputs.groups) && !inputs.groups.length) ||
    (Array.isArray(inputs.offices) && !inputs.offices.length);

  const handleChange = (name, value) => {
    setInputs((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <form id="add-user-security-form" onSubmit={handleSubmit}>
      <header className="page-head">
        <div className="page-head-start">
          <Title size={5}>Add User Security</Title>
        </div>
        <div className="page-head-end">
          <Button.Group hasAddons>
            <Button
              disabled={loading}
              size="small"
              type="button"
              onClick={onComplete}
            >
              <span>Cancel</span>
            </Button>
            <Button
              color="primary"
              disabled={isDisabled}
              form="add-user-security-form"
              size="small"
              state={loading ? "loading" : ""}
              type="submit"
            >
              <span>Submit</span>
            </Button>
          </Button.Group>
        </div>
      </header>
      <hr />
      <Field>
        <OfficeMultiSelect
          allowSelectAll
          required
          disabled={disabled || loading}
          label="Office"
          name="offices"
          noValueLabel=""
          placeholder="Start typing..."
          size="small"
          value={inputs.offices}
          onChange={handleChange}
        />
      </Field>
      <Field>
        <Label>User Securities</Label>
        <UserGroupMultiSelect
          fullWidth
          required
          disabled={disabled || loading}
          name="groups"
          value={inputs.groups}
          onChange={handleChange}
        />
      </Field>
      <hr />
      {!!errors.length && (
        <Content size="small" textColor="danger">
          <ul>
            {errors.map((err) => (
              <li>{`${err.office} / ${err.group} - ${err.message}`}</li>
            ))}
          </ul>
        </Content>
      )}
    </form>
  );
};

UserSecurityForm.propTypes = {
  disabled: PropTypes.bool,
  onComplete: PropTypes.func,
  userid: PropTypes.string.isRequired,
};

UserSecurityForm.defaultProps = {
  onComplete: (e) => e,
  disabled: false,
};

export default UserSecurityForm;
