import React from "react";
import PropTypes from "prop-types";
import { Field, Control, Input, Label } from "rbx";
import { isValid } from "date-fns";

import DateInput from "../../../../components/DateInput";
import DocumentTypeSelect from "../../../../components/DocumentTypeSelect";
import FileUploader from "../../../../components/FileUploader";

const InvestigatorDocumentForm = ({ inputs, onChange, dirPath }) => (
  <div className="doctor-document-form">
    <Field>
      <Control>
        <Label>Description</Label>
        <Input
          autoComplete="new"
          name="description"
          size="small"
          type="text"
          value={inputs.description}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
    </Field>
    <DocumentTypeSelect
      label="Type"
      name="type"
      size="small"
      value={inputs.type}
      onChange={onChange}
    />
    <Field>
      <FileUploader
        dirPath={dirPath}
        name="pathfilename"
        value={inputs.pathfilename}
        onChange={onChange}
      />
    </Field>
    <Field>
      <DateInput
        getValue={(x) => (x ? x.substr(0, 10) : "")}
        label="Expiration Date"
        name="expiredate"
        setValue={(x) => {
          const date = new Date(x.split("-").map((y) => parseInt(y, 10)));
          if (x && isValid(date)) {
            return date.toISOString();
          }
          return "";
        }}
        size="small"
        value={inputs.expiredate}
        onChange={onChange}
      />
    </Field>
    <Field>
      <Control>
        <Label>Order</Label>
        <Input
          autoComplete="new"
          name="order"
          size="small"
          type="number"
          value={inputs.order}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
    </Field>
  </div>
);

InvestigatorDocumentForm.propTypes = {
  inputs: PropTypes.object,
  dirPath: PropTypes.string,
  onChange: PropTypes.func,
};
InvestigatorDocumentForm.defaultProps = {
  inputs: {},
  dirPath: "",
  onChange: () => null,
};
export default InvestigatorDocumentForm;
