import { gql } from "@apollo/client";

// eslint-disable-next-line import/prefer-default-export
export const ALL_EXAMINEE_CCS_QUERY = gql`
  query ALL_EXAMINEE_CCS_QUERY(
    $where: ExamineeCCWhereInput
    $orderBy: [ExamineeCCOrderByInput!]
    $take: Int
    $skip: Int
  ) {
    examineeCCS(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
      cccode
      chartnbr
      ccAddress {
        cccode
        company
        prefix
        firstname
        lastname
        address1
        address2
        city
        state
        zip
        phone
        email
        useridadded
        dateadded
        useridedited
        dateedited
      }
    }
  }
`;
