/* eslint-disable import/prefer-default-export */
import { gql } from "@apollo/client";

export const LIST_ISSUES_QUERY = gql`
  query issues(
    $take: Int
    $orderBy: [IssueOrderByInput!]
    $skip: Int
    $where: IssueWhereInput
  ) {
    issues(take: $take, orderBy: $orderBy, skip: $skip, where: $where) {
      PublishOnWeb
      WebID
      WebSynchDate
      dateadded
      dateedited
      description
      instruction
      issuecode
      status
      useridadded
      useridedited
    }
    aggregateIssue: aggregateIssue(where: $where) {
      count {
        issuecode
      }
    }
  }
`;

export const SINGLE_ISSUE_QUERY = gql`
  query issue($where: IssueWhereUniqueInput!) {
    issue(where: $where) {
      PublishOnWeb
      WebID
      WebSynchDate
      dateadded
      dateedited
      description
      instruction
      issuecode
      status
      useridadded
      useridedited
    }
  }
`;
