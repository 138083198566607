/* eslint-disable import/prefer-default-export */
import { gql } from "@apollo/client";

export const CREATE_USER_GROUP_FUNCTION_MUTATION = gql`
  mutation createUserGroupFunction($data: UserGroupFunctionCreateInput!) {
    createUserGroupFunction(data: $data) {
      usergroupfunctionid
    }
  }
`;

export const UPDATE_USER_GROUP_FUNCTION_MUTATION = gql`
  mutation updateUserGroupFunction(
    $data: UserGroupFunctionUpdateInput!
    $where: UserGroupFunctionWhereUniqueInput!
  ) {
    updateUserGroupFunction(data: $data, where: $where) {
      usergroupfunctionid
    }
  }
`;

export const DELETE_USER_GROUP_FUNCTION_MUTATION = gql`
  mutation deleteUserGroupFunction($where: UserGroupFunctionWhereUniqueInput!) {
    deleteUserGroupFunction(where: $where) {
      usergroupfunctionid
    }
  }
`;