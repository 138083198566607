import { gql } from "@apollo/client";

export const CREATE_DOCTOR_DOCUMENT_MUTATION = gql`
  mutation createDoctorDocument($data: DoctorDocumentCreateInput!) {
    createDoctorDocument(data: $data) {
      doctorcode
      recid
    }
  }
`;

export const UPDATE_DOCTOR_DOCUMENT_MUTATION = gql`
  mutation updateDoctorDocument(
    $data: DoctorDocumentUpdateInput!
    $where: DoctorDocumentWhereUniqueInput!
  ) {
    updateDoctorDocument(data: $data, where: $where) {
      doctorcode
      recid
    }
  }
`;

export const DELETE_DOCTOR_DOCUMENT_MUTATION = gql`
  mutation deleteDoctorDocument($where: DoctorDocumentWhereUniqueInput!) {
    deleteDoctorDocument(where: $where) {
      doctorcode
      recid
    }
  }
`;
