import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Field, Button, Control, Title } from "rbx";
import { useApolloClient, useMutation } from "@apollo/client";
import {
  SINGLE_CASE_QUERY,
  UPDATE_CASE_MUTATION,
  LIST_ASSOCIATED_CASES_QUERY,
} from "../../../../graphql";
import { customToast as toast } from "../../../../utils";
import { useMasterSubCase } from "../../../../hooks";
import CaseSelectWithDemographics from "../../../../components/CaseSelectWithDemographics";
import BooleanInput from "../../../../components/BooleanInput";

class CustomSubCaseError extends Error {}

const SubCaseModal = ({ casenbr, onComplete }) => {
  const client = useApolloClient();
  const [subcasenbr, setSubcasenbr] = useState("");
  const [inputs, setInputs] = useState({
    matchClaim: true,
    matchExaminee: false,
  });

  const {
    mastercasenbr,
    claimnbr,
    chartnbr,
    // mastersubcase
  } = useMasterSubCase(casenbr);

  const [loading, setLoading] = useState(false);
  const [updateCase] = useMutation(UPDATE_CASE_MUTATION);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);

      const { data: GetCaseItemData } = await client.query({
        query: SINGLE_CASE_QUERY,
        variables: {
          where: {
            casenbr: parseInt(subcasenbr, 10),
          },
        },
      });

      const masterCaseNumber = mastercasenbr || casenbr;

      const caseItem = GetCaseItemData?.caseItem;

      if (parseInt(subcasenbr, 10) === parseInt(masterCaseNumber, 10)) {
        throw new CustomSubCaseError(`You can't associate a case with itself.`);
      }
      if (caseItem?.mastersubcase === "M") {
        throw new CustomSubCaseError(
          `This case is a master case and cannot be made a subcase.`
        );
      }
      if (caseItem?.mastercasenbr) {
        throw new CustomSubCaseError(
          `This case is already associated with another case.`
        );
      }
      await Promise.all([
        updateCase({
          variables: {
            where: {
              casenbr: parseInt(masterCaseNumber, 10),
            },
            data: {
              mastercasenbr: { set: parseInt(masterCaseNumber, 10) },
              mastersubcase: { set: "M" },
            },
          },
          refetchQueries: [
            {
              query: SINGLE_CASE_QUERY,
              variables: { where: { casenbr: parseInt(masterCaseNumber, 10) } },
            },
          ],
        }),
        updateCase({
          variables: {
            where: {
              casenbr: parseInt(subcasenbr, 10),
            },
            data: {
              mastersubcase: { set: "S" },
              mastercasenbr: { set: parseInt(masterCaseNumber, 10) },
            },
          },
          refetchQueries: [{ query: LIST_ASSOCIATED_CASES_QUERY }],
        }),
      ]);

      toast.success(`Subcase added successfully.`);
      onComplete();
    } catch (err) {
      const message =
        err instanceof CustomSubCaseError
          ? err.message
          : "Error adding subcase.";
      toast.error(message);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (name, value) => {
    setInputs((prev) => ({ ...prev, [name]: value }));
  };

  const where = useMemo(() => {
    const result = {
      casenbr: { not: { equals: parseInt(casenbr, 10) } },
      OR: [
        {
          mastercasenbr: { not: { equals: parseInt(casenbr, 10) } },
        },
        {
          mastercasenbr: { equals: null },
        },
      ],
    };
    if (inputs.matchClaim) {
      result.claimnbr = { equals: claimnbr };
    }
    if (inputs.matchExaminee) {
      result.chartnbr = { equals: chartnbr };
    }
    return result;
  }, [chartnbr, claimnbr, casenbr, inputs.matchClaim, inputs.matchExaminee]);

  return (
    <form id="subcase-form" onSubmit={handleSubmit}>
      <header className="page-head">
        <div className="page-head-start">
          <Title size={5}>Add Subcase</Title>
        </div>
        <div className="page-head-end">
          <Button.Group hasAddons>
            <Button
              size="small"
              type="button"
              onClick={() => onComplete(false)}
            >
              <span>Cancel</span>
            </Button>
            <Button
              color="primary"
              disabled={loading || !subcasenbr}
              form="subcase-form"
              size="small"
              state={loading ? "loading" : ""}
              type="submit"
            >
              <span>Submit</span>
            </Button>
          </Button.Group>
        </div>
      </header>
      <hr />
      <Field kind="group">
        <Control>
          <BooleanInput
            label="Match Claim"
            name="matchClaim"
            value={inputs.matchClaim}
            onChange={handleChange}
          />
        </Control>
        <Control>
          <BooleanInput
            label="Match Examinee"
            name="matchExaminee"
            value={inputs.matchExaminee}
            onChange={handleChange}
          />
        </Control>
      </Field>
      <Field>
        <CaseSelectWithDemographics
          label="Case"
          value={subcasenbr}
          where={where}
          onChange={(name, value) => setSubcasenbr(value)}
        />
      </Field>
      <hr />
    </form>
  );
};

SubCaseModal.propTypes = {
  casenbr: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onComplete: PropTypes.func,
};
SubCaseModal.defaultProps = {
  onComplete: () => {},
  casenbr: "",
};

export default SubCaseModal;
