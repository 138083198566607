import React from "react";
import PropTypes from "prop-types";

import { Box, Field, Control, Button, Label } from "rbx";
import { endOfDay, startOfDay, isValid } from "date-fns";

import QueueSelect from "../../../../components/QueueSelect";
import CaseTypeSelect from "../../../../components/CaseTypeSelect";
import ExamineeSelect from "../../../../components/ExamineeSelect";
import FilterInput from "../../../../components/FilterInput";
import DoctorSelect from "../../../../components/DoctorSelect";
import ServiceSelect from "../../../../components/ServiceSelect";

import DateInput from "../../../../components/DateInput";

const ClientCaseListFilters = ({ filters, onChange, onReset }) => (
  <Box>
    <div className="list-filters">
      <FilterInput
        getValue={(x) => x?.equals || ""}
        label="Case #"
        name="casenbr"
        placeholder="Case #"
        setValue={(x) => (x ? { equals: parseInt(x, 10) } : "")}
        size="small"
        style={{ width: 90 }}
        value={filters.casenbr || ""}
        onChange={onChange}
      />
      <Field>
        <DateInput
          getValue={(x) => {
            const date = x?.gte || x?.lte;
            return date ? date.substr(0, 10) : "";
          }}
          label="Appt. Date"
          name="Appttime"
          setValue={(x) => {
            if (
              x &&
              isValid(new Date(x.split("-").map((y) => parseInt(y, 10))))
            ) {
              return {
                gte: startOfDay(
                  new Date(x.split("-").map((y) => parseInt(y, 10)))
                ).toISOString(),
                lte: endOfDay(
                  new Date(x.split("-").map((y) => parseInt(y, 10)))
                ).toISOString(),
              };
            }
            return "";
          }}
          value={filters.Appttime}
          onChange={onChange}
        />
      </Field>
      <Field>
        <QueueSelect
          getValue={(x) => x?.equals || ""}
          label="Status"
          name="status"
          setValue={(x) => (x ? { equals: x } : "")}
          value={filters.status}
          onChange={onChange}
        />
      </Field>
      <Field>
        <CaseTypeSelect
          getValue={(x) => x?.equals || ""}
          label="Case Type"
          name="casetype"
          setValue={(x) => (x ? { equals: x } : "")}
          value={filters.casetype}
          onChange={onChange}
        />
      </Field>
      <Field>
        <Control>
          <DoctorSelect
            getValue={(x) => x?.equals || ""}
            label="Doctor"
            name="doctorcode"
            setValue={(x) => (x ? { equals: x } : "")}
            size="small"
            value={filters.doctorcode}
            onChange={onChange}
          />
        </Control>
      </Field>
      <Field>
        <ExamineeSelect
          getValue={(x) => x?.equals || ""}
          label="Examinee"
          name="chartnbr"
          setValue={(x) => (x ? { equals: x } : "")}
          value={filters.chartnbr}
          onChange={onChange}
        />
      </Field>
      <Field>
        <ServiceSelect
          getValue={(x) => x?.equals || ""}
          label="Service"
          name="servicecode"
          setValue={(x) => (x ? { equals: parseInt(x, 10) } : "")}
          size="small"
          value={filters.servicecode}
          onChange={onChange}
        />
      </Field>
      <Field>
        <Control>
          <Label>&nbsp;</Label>
          <Button color="danger" size="small" onClick={onReset}>
            Reset
          </Button>
        </Control>
      </Field>
    </div>
    {/* <hr />
    <pre>
      <code>{JSON.stringify(filters, null, 2)}</code>
    </pre> */}
  </Box>
);

ClientCaseListFilters.propTypes = {
  filters: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
};

export default ClientCaseListFilters;
