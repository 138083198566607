import React, { useState } from "react";
import PropTypes from "prop-types";
import { useMutation, useApolloClient } from "@apollo/client";
import { Control, Label, Title, Fieldset, Button } from "rbx";

import {
  customToast as toast,
  getDateEST,
  convertTimeZoneDataBase,
} from "../../../../../utils";
import {
  CREATE_USER_GROUP_FUNCTION_MUTATION,
  LIST_USER_GROUP_FUNCTIONS_QUERY,
} from "../../../../../graphql";
import { useAuth } from "../../../../../context/AuthContext";
import UserFunctionSelect from "../../../../../components/UserFunctionSelect";
import UserFunctionMultiSelect from "../../../../../components/UserFunctionMultiSelect";

const UserFunctionForm = ({ handleCancel, entityid, functioncode }) => {
  const { state: authState } = useAuth();

  const [createUserGroupFunction] = useMutation(
    CREATE_USER_GROUP_FUNCTION_MUTATION
  );

  const client = useApolloClient();

  const [inputs, setInputs] = useState({
    functioncode: functioncode || "",
    functioncodes: [],
  });

  const onChange = (e, value) => {
    setInputs((prev) => ({
      ...prev,
      [e.target ? e.target.name : e]: e.target ? e.target.value : value,
    }));
  };

  const handleSave = async (e) => {
    e.preventDefault();
    try {
      const refetchQueries = [
        {
          query: LIST_USER_GROUP_FUNCTIONS_QUERY,
          variables: {
            where: {
              entityid: { equals: entityid },
            },
            orderBy: [{ functioncode: "asc" }],
          },
        },
      ];
      if (functioncode === null) {
        const { functioncodes } = inputs;
        const date = convertTimeZoneDataBase(getDateEST());
        await functioncodes.forEach(async (func) => {
          const {
            data: { userGroupFunctions },
          } = await client.query({
            query: LIST_USER_GROUP_FUNCTIONS_QUERY,
            variables: {
              take: 1,
              where: {
                entitytype: { equals: "User" },
                entityid: { equals: entityid },
                functioncode: { equals: func.value },
              },
            },
            fetchPolicy: "network-only",
          });
          if (userGroupFunctions && !userGroupFunctions.length) {
            await createUserGroupFunction({
              variables: {
                data: {
                  entitytype: "User",
                  entityid,
                  UserFunction: {
                    connect: {
                      functioncode: func.value,
                    },
                  },
                  dateadded: date,
                  useridadded: authState?.user?.userid,
                  dateedited: date,
                  useridedited: authState?.user?.userid,
                },
              },
              refetchQueries,
            });
          }
        });
        toast.success("User Function created successfully.");
      }
      handleCancel();
    } catch (err) {
      toast.error(
        "Error: Please, check if this User Function already exists on User."
      );
    }
  };

  const isDisabled = !inputs.functioncodes.length;

  return (
    <form id="add-issue-form" style={{ height: 200 }} onSubmit={handleSave}>
      <header className="page-head">
        <div className="page-head-start">
          <Title size={5}>Add User Function</Title>
        </div>
        <div className="page-head-end">
          <Button.Group hasAddons>
            <Button size="small" type="button" onClick={handleCancel}>
              <span>Cancel</span>
            </Button>
            <Button
              color="primary"
              disabled={isDisabled}
              form="add-issue-form"
              size="small"
              type="submit"
            >
              <span>Submit</span>
            </Button>
          </Button.Group>
        </div>
      </header>
      <hr />
      <Fieldset>
        <Control>
          <Label>{functioncode ? "User Function" : "User Functions"}</Label>
          {functioncode ? (
            <UserFunctionSelect
              fullWidth
              required
              name="functioncode"
              value={inputs.functioncode}
              onChange={onChange}
            />
          ) : (
            <UserFunctionMultiSelect
              fullWidth
              required
              name="functioncodes"
              value={inputs.functioncodes}
              onChange={(value) => onChange("functioncodes", value)}
            />
          )}
        </Control>
      </Fieldset>
      <hr />
    </form>
  );
};

UserFunctionForm.propTypes = {
  handleCancel: PropTypes.func,
  entityid: PropTypes.string.isRequired,
  functioncode: PropTypes.string,
};

UserFunctionForm.defaultProps = {
  handleCancel: (e) => e,
  functioncode: null,
};

export default UserFunctionForm;
