import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Title, Button } from "rbx";
import { useMutation, useLazyQuery, useApolloClient } from "@apollo/client";
import { useModal } from "../../../../../context";
import {
  LIST_APOLLO_NOTE_TYPES_QUERY,
  CREATE_APOLLO_NOTE_TYPE_MUTATION,
  UPDATE_APOLLO_NOTE_TYPE_MUTATION,
  DELETE_APOLLO_NOTE_TYPE_MUTATION,
  SINGLE_APOLLO_NOTE_TYPE_QUERY,
  LIST_APOLLO_NOTES_QUERY,
} from "../../../../../graphql";
import Confirmation from "../../../../../components/Confirmation";
import { customToast as toast } from "../../../../../utils";
import NoteTypeForm from "../NoteTypeForm";

const INITIAL_STATE = {
  NoteType: "",
};

const NoteTypeModal = ({ onComplete, NoteTypeID, where }) => {
  const { setModalOpen } = useModal();
  const client = useApolloClient();
  const [inputs, setInputs] = useState(INITIAL_STATE);
  const [loading, setLoading] = useState(false);
  const [updated, setUpdated] = useState([]);

  const [
    getNoteType,
    { data: noteTypeData, loading: queryLoading },
  ] = useLazyQuery(SINGLE_APOLLO_NOTE_TYPE_QUERY);
  const [createNoteType] = useMutation(CREATE_APOLLO_NOTE_TYPE_MUTATION);
  const [updateNoteType] = useMutation(UPDATE_APOLLO_NOTE_TYPE_MUTATION);
  const [deleteNoteType] = useMutation(DELETE_APOLLO_NOTE_TYPE_MUTATION);

  useEffect(() => {
    if (NoteTypeID) {
      getNoteType({
        variables: {
          where: { NoteTypeID },
        },
      });
    }
  }, [NoteTypeID, getNoteType]);

  useEffect(() => {
    if (noteTypeData?.findUniqueNoteTypes) {
      setInputs({
        NoteType: noteTypeData.findUniqueNoteTypes.NoteType || "",
      });
    }
  }, [noteTypeData]);

  const handleChange = (name, value) => {
    setInputs((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (NoteTypeID) {
      setUpdated((prev) => (prev.includes(name) ? prev : [...prev, name]));
    }
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);

      if (!NoteTypeID) {
        await createNoteType({
          variables: {
            data: inputs,
          },
          refetchQueries: [
            {
              query: LIST_APOLLO_NOTE_TYPES_QUERY,
              variables: {
                where,
                orderBy: [{ NoteType: "asc" }],
                skip: 0,
                take: 25,
              },
            },
          ],
        });
        setModalOpen(false);
        toast.success("Note Type created successfully.");
      } else {
        await updateNoteType({
          variables: {
            data: {
              NoteType: {
                set: inputs.NoteType,
              },
            },
            where: {
              NoteTypeID,
            },
          },
          refetchQueries: [
            {
              query: LIST_APOLLO_NOTE_TYPES_QUERY,
              variables: {
                where,
                orderBy: [{ NoteType: "asc" }],
                skip: 0,
                take: 25,
              },
            },
            {
              query: SINGLE_APOLLO_NOTE_TYPE_QUERY,
              variables: {
                where: { NoteTypeID },
              },
            },
          ],
        });
        setModalOpen(false);
        toast.success("Note Type updated successfully.");
      }
      onComplete();
    } catch (err) {
      toast.error("Error saving Note Type");
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    const { data: notesData } = await client.query({
      query: LIST_APOLLO_NOTES_QUERY,
      variables: {
        where: {
          NoteTypeID: { equals: NoteTypeID },
        },
        take: 1,
        skip: 0,
      },
    });

    if (notesData?.findManyNotes.length) {
      toast.error(
        "This Note Type could not be removed because there are some notes related"
      );
    } else {
      const performDelete = async () => {
        try {
          setLoading(true);
          await deleteNoteType({
            variables: {
              where: {
                NoteTypeID,
              },
            },
            refetchQueries: [
              {
                query: LIST_APOLLO_NOTE_TYPES_QUERY,
                variables: {
                  where,
                  orderBy: [{ NoteType: "asc" }],
                  take: 25,
                  skip: 0,
                },
              },
              {
                query: SINGLE_APOLLO_NOTE_TYPE_QUERY,
                variables: {
                  where: { NoteTypeID },
                },
              },
            ],
          });
          toast.success("Note Type deleted successfully");
          onComplete();
        } catch (err) {
          toast.error("Error trying to delete Note Type");
        } finally {
          setLoading(false);
        }
      };
      setModalOpen(
        true,
        <Confirmation
          message="Are you sure you want to delete this Note Type?"
          onCancel={() => setModalOpen(false)}
          onConfirm={performDelete}
        />
      );
    }
  };

  const isDisabled =
    !inputs.NoteType || (NoteTypeID && !updated.length) || queryLoading;

  return (
    <form id="claim-note-form" onSubmit={handleSubmit}>
      <header className="page-head">
        <div className="page-head-start">
          <Title size={5}>
            {`${!NoteTypeID ? "Create" : "Edit"}`}
            &nbsp;Note Type
          </Title>
        </div>
        <div className="page-head-end">
          <Button.Group hasAddons>
            <Button
              size="small"
              type="button"
              onClick={() => onComplete(false)}
            >
              <span>Cancel</span>
            </Button>
            <Button
              color="primary"
              disabled={isDisabled}
              form="claim-note-form"
              size="small"
              state={loading ? "loading" : ""}
              type="submit"
            >
              <span>Submit</span>
            </Button>
          </Button.Group>
        </div>
      </header>
      <hr />
      <NoteTypeForm inputs={inputs} onChange={handleChange} />
      <hr />
      {NoteTypeID && (
        <Button
          color="danger"
          disabled={loading}
          size="small"
          type="button"
          onClick={handleDelete}
        >
          <span>Delete</span>
        </Button>
      )}
    </form>
  );
};

NoteTypeModal.propTypes = {
  onComplete: PropTypes.func,
  NoteTypeID: PropTypes.number,
  where: PropTypes.object,
};

NoteTypeModal.defaultProps = {
  onComplete: () => null,
  NoteTypeID: null,
  where: {},
};

export default NoteTypeModal;
