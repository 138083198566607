import React from "react";
import PropTypes from "prop-types";
import { Control, Column, Input, Label, Textarea } from "rbx";
import BooleanInput from "../../../components/BooleanInput";
import BooleanSelect from "../../../components/BooleanSelect";

const UserDefinedFields = ({ client, onChange, disabled, onSubmit }) => (
  <form id="edit-client-form" onSubmit={onSubmit}>
    <Column.Group>
      <Column desktop={{ size: 6 }} mobile={{ size: 12 }}>
        <Column.Group>
          <Column size={6}>
            <BooleanSelect
              disabled={disabled}
              label="Constant Contact"
              name="usdvarchar1"
              value={client.usdvarchar1}
              onChange={onChange}
            />
          </Column>
          <Column size={6}>
            <BooleanSelect
              disabled={disabled}
              label="Supplement"
              name="usdvarchar2"
              value={client.usdvarchar2}
              onChange={onChange}
            />
          </Column>
        </Column.Group>
        <Column.Group>
          <Column size={4}>
            <Control>
              <Label>Follow Up Date</Label>
              <Input
                disabled={disabled}
                name="usddate1"
                size="small"
                type="date"
                value={client.usddate1}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
            </Control>
          </Column>
          <Column size={4}>
            <Control>
              <Label>Work From Home</Label>
              <BooleanInput
                disabled={disabled}
                name="usdint1"
                size="small"
                type="text"
                value={client.usdint1}
                onChange={onChange}
              />
            </Control>
          </Column>
          <Column size={4}>
            <Control>
              <Label>TPA Adjuster</Label>
              <BooleanInput
                disabled={disabled}
                name="usdint2"
                size="small"
                type="text"
                value={client.usdint2}
                onChange={onChange}
              />
            </Control>
          </Column>
        </Column.Group>
      </Column>
      <Column desktop={{ size: 6 }} mobile={{ size: 12 }} tablet={{ size: 12 }}>
        <Control style={{ marginBottom: "1rem" }}>
          <Label>Follow Notes</Label>
          <Textarea
            disabled={disabled}
            name="usdtext1"
            size="small"
            value={client.usdtext1}
            onChange={(e) => onChange(e.target.name, e.target.value)}
          />
        </Control>
        <Control>
          <Label>Update Notes</Label>
          <Textarea
            disabled={disabled}
            name="usdtext2"
            size="small"
            value={client.usdtext2}
            onChange={(e) => onChange(e.target.name, e.target.value)}
          />
        </Control>
      </Column>
    </Column.Group>
  </form>
);

UserDefinedFields.propTypes = {
  client: PropTypes.object,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  onSubmit: PropTypes.func,
};

UserDefinedFields.defaultProps = {
  client: {},
  onChange: (e) => e,
  disabled: false,
  onSubmit: (e) => e,
};

export default UserDefinedFields;
