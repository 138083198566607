import { useState, useEffect } from "react";
import { usePortalPerson, useLsiClient } from ".";

const useClientMaster = () => {
  const { portalPerson } = usePortalPerson();
  const { lsiClient } = useLsiClient();
  const [filterDetails, setFilterDetails] = useState({
    clientMasterID: null,
    shouldFilterContacts: false,
  });

  useEffect(() => {
    if (lsiClient && lsiClient?.shouldFilterContacts) {
      setFilterDetails({
        clientMasterID: lsiClient?.ClientMasterID,
        shouldFilterContacts: true,
      });
    }
    if (portalPerson && portalPerson?.shouldFilterContacts) {
      setFilterDetails({
        clientMasterID: portalPerson.ClientMasterID,
        shouldFilterContacts: true,
      });
    }
  }, [portalPerson, lsiClient]);

  return { filterDetails };
};

export default useClientMaster;
