import React from "react";
import PropTypes from "prop-types";
import { Field, Control, Label, Input, Textarea } from "rbx";

const CaseEmailDocumentForm = ({ inputs, onChange, disabled }) => (
  <div className="case-email-document-form">
    <Field>
      <Control>
        <Label>To</Label>
        <Input
          required
          autoComplete="new"
          disabled={disabled}
          name="to"
          size="small"
          type="email"
          value={inputs.to}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
    </Field>
    <Field>
      <Control>
        <Label>From</Label>
        <Input
          required
          autoComplete="new"
          disabled={disabled}
          name="from"
          size="small"
          type="email"
          value={inputs.from}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
    </Field>
    <Field className="span-2">
      <Control>
        <Label>Subject</Label>
        <Input
          required
          autoComplete="new"
          disabled={disabled}
          name="subject"
          size="small"
          type="text"
          value={inputs.subject}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
    </Field>
    <Field className="span-2">
      <Control>
        <Label>Body</Label>
        <Textarea
          required
          disabled={disabled}
          name="text"
          rows="3"
          size="small"
          value={inputs.text}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
    </Field>
  </div>
);

CaseEmailDocumentForm.propTypes = {
  inputs: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

CaseEmailDocumentForm.defaultProps = {
  disabled: false,
};
export default CaseEmailDocumentForm;
