import React, { useState } from "react";

import PropTypes from "prop-types";

import { Field, Box, Control, Label, Input } from "rbx";
import { debounce } from "../../../../utils";

import PrefixSelect from "../../../../components/PrefixSelect";
import PhoneNumberInput from "../../../../components/PhoneNumberInput";
import StateSelect from "../../../../components/StateSelect";
import ZipInput from "../../../../components/ZipInput";

import ReferralStyledSubtitle from "../ReferralStyledSubtitle";

import { KEY_CODES } from "../../../../constants";
import AllstateQueryFNField from "../AllstateQueryField/AllstateQueryFNField";
import AllstateQueryLNField from "../AllstateQueryField/AllstateQueryLNField";
import AllstateQueryFirmField from "../AllstateQueryField/AllstateQueryFirmField";

const { UP, DOWN, ENTER } = KEY_CODES;

const DefenseAttorneyForm = ({
  onChange,
  inputs,
  required,
  isAllstateOffice,
  allstateNJAttorneys,
  setDefenseAttorney,
  title,
}) => {
  const [showFNDropdown, setShowFNDropdown] = useState(false);
  const [showLNDropdown, setShowLNDropdown] = useState(false);
  const [showFirmDropdown, setShowFirmDropdown] = useState(false);
  const [cursor, setCursor] = useState(null);

  const selectAttorney = (att) => {
    setDefenseAttorney((prev) => ({
      FirstName: att.FirstName || "",
      LastName: att.LastName || "",
      Firm: att.Firm || "",
      Email: att.Email || "",
      Address1: att.Address || "",
      City: att.City || "",
      State: att.State || "",
      ZipCode: att.Zip || "",
      Phone: att.Phone || "",
    }));
    setShowFNDropdown(false);
    setShowLNDropdown(false);
    setShowFirmDropdown(false);
  };

  const handleKeyDown = (e, name) => {
    const { keyCode } = e;
    if ([UP, DOWN, ENTER]?.includes(e.keyCode)) {
      e.preventDefault();
      e.stopPropagation();
    }
    if (keyCode === UP) {
      if (typeof cursor === "number" && cursor !== 0) {
        setCursor((prev) => prev - 1);
      }
    } else if (keyCode === DOWN) {
      if (typeof cursor !== "number") {
        setCursor(0);
      } else if (cursor < allstateNJAttorneys.length - 1) {
        setCursor((prev) => prev + 1);
      }
    } else if (keyCode === ENTER) {
      const att = allstateNJAttorneys.filter((attorney) =>
        attorney?.[`${name}`]
          .toLowerCase()
          .startsWith(inputs?.[`${name}`].toLowerCase())
      )?.[cursor];
      if (att) {
        selectAttorney(allstateNJAttorneys[allstateNJAttorneys.indexOf(att)]);
        setCursor(null);
      }
    }
  };

  const handleSearchFNChange = debounce((e) => {
    e.preventDefault();
    e.stopPropagation();
    const {
      target: { value: newValue },
    } = e;
    if (inputs.FirstName || newValue.length >= 1) {
      setShowFNDropdown(true);
    } else {
      setShowFNDropdown(false);
    }
  }, 500);

  const handleSearchLNChange = debounce((e) => {
    e.preventDefault();
    e.stopPropagation();
    const {
      target: { value: newValue },
    } = e;
    if (inputs.LastName || newValue.length >= 1) {
      setShowLNDropdown(true);
    } else {
      setShowLNDropdown(false);
    }
  }, 500);

  const handleSearchFirmChange = debounce((e) => {
    e.preventDefault();
    e.stopPropagation();
    const {
      target: { value: newValue },
    } = e;
    if (inputs.Firm || newValue.length >= 1) {
      setShowFirmDropdown(true);
    } else {
      setShowFirmDropdown(false);
    }
  }, 500);

  return (
    <Box>
      <ReferralStyledSubtitle>{title}</ReferralStyledSubtitle>
      <Field className="grid-override is-three" kind="group">
        <PrefixSelect
          fullWidth
          id="Prefix"
          label="Prefix"
          name="Prefix"
          size="small"
          value={inputs.Prefix}
          onChange={onChange}
        />
        <Control expanded>
          {isAllstateOffice ? (
            <AllstateQueryFNField
              allstateNJAttorneys={allstateNJAttorneys}
              cursor={cursor}
              handleKeyDown={handleKeyDown}
              handleSearchChange={handleSearchFNChange}
              inputs={inputs}
              name="FirstName"
              selectAttorney={selectAttorney}
              setShowDropdown={setShowFNDropdown}
              showDropdown={showFNDropdown}
              onChange={onChange}
            />
          ) : (
            <React.Fragment>
              <Label>First Name</Label>
              <Input
                name="FirstName"
                size="small"
                value={inputs.FirstName}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
            </React.Fragment>
          )}
        </Control>
        <Control expanded>
          {isAllstateOffice ? (
            <AllstateQueryLNField
              allstateNJAttorneys={allstateNJAttorneys}
              cursor={cursor}
              handleKeyDown={handleKeyDown}
              handleSearchChange={handleSearchLNChange}
              inputs={inputs}
              name="LastName"
              selectAttorney={selectAttorney}
              setShowDropdown={setShowLNDropdown}
              showDropdown={showLNDropdown}
              onChange={onChange}
            />
          ) : (
            <React.Fragment>
              <Label>Last Name</Label>
              <Input
                name="LastName"
                size="small"
                value={inputs.LastName}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
            </React.Fragment>
          )}
        </Control>
      </Field>
      <Field>
        <Control expanded>
          {isAllstateOffice ? (
            <AllstateQueryFirmField
              allstateNJAttorneys={allstateNJAttorneys}
              cursor={cursor}
              handleKeyDown={handleKeyDown}
              handleSearchChange={handleSearchFirmChange}
              inputs={inputs}
              name="Firm"
              selectAttorney={selectAttorney}
              setShowDropdown={setShowFirmDropdown}
              showDropdown={showFirmDropdown}
              onChange={onChange}
            />
          ) : (
            <React.Fragment>
              <Label>Firm</Label>
              <Input
                name="Firm"
                size="small"
                value={inputs.Firm}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
            </React.Fragment>
          )}
        </Control>
      </Field>
      <Field kind="group">
        <Control expanded>
          <Label>Address 1</Label>
          <Input
            name="Address1"
            size="small"
            value={inputs.Address1}
            onChange={(e) => onChange(e.target.name, e.target.value)}
          />
        </Control>
        <Control expanded>
          <Label>Address 2</Label>
          <Input
            name="Address2"
            size="small"
            value={inputs.Address2}
            onChange={(e) => onChange(e.target.name, e.target.value)}
          />
        </Control>
      </Field>
      <Field className="grid-override is-three" kind="group">
        <Control expanded>
          <Label>City</Label>
          <Input
            name="City"
            size="small"
            value={inputs.City}
            onChange={(e) => onChange(e.target.name, e.target.value)}
          />
        </Control>
        <Control expanded>
          <StateSelect
            displayFullLabel
            id="State"
            label="State"
            name="State"
            size="small"
            value={inputs.State}
            onChange={onChange}
          />
        </Control>
        <Control expanded>
          <ZipInput
            id="ZipCode"
            name="ZipCode"
            state={inputs.State}
            value={inputs.ZipCode || ""}
            onChange={onChange}
          />
        </Control>
      </Field>
      <Field kind="group">
        <Control expanded>
          <Label>Phone</Label>
          <PhoneNumberInput
            name="Phone"
            value={inputs.Phone}
            onChange={onChange}
          />
        </Control>
        <Control>
          <Label>Extension</Label>
          <Input
            name="Extension"
            size="small"
            value={inputs.Extension}
            onChange={(e) => onChange(e.target.name, e.target.value)}
          />
        </Control>
      </Field>
      <Field kind="group">
        <Control expanded>
          <Label>Fax</Label>
          <PhoneNumberInput name="Fax" value={inputs.Fax} onChange={onChange} />
        </Control>
        <Control expanded>
          <Label>Email</Label>
          <Input
            name="Email"
            size="small"
            value={inputs.Email}
            onChange={(e) => onChange(e.target.name, e.target.value)}
          />
        </Control>
      </Field>
    </Box>
  );
};

DefenseAttorneyForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  setDefenseAttorney: PropTypes.func.isRequired,
  inputs: PropTypes.object.isRequired,
  required: PropTypes.bool.isRequired,
  isAllstateOffice: PropTypes.bool.isRequired,
  allstateNJAttorneys: PropTypes.array,
  title: PropTypes.string,
};

DefenseAttorneyForm.defaultProps = {
  allstateNJAttorneys: [],
  title: "Defense Attorney",
};

export default DefenseAttorneyForm;
