const LIST_FIELDS = [
  { name: "#issues", type: "String", entity: "issue" },
  { name: "/issues", type: "String", entity: "issue" },
  { name: "PublishOnWeb", type: "Boolean", entity: "issue" },
  { name: "WebID", type: "Int", entity: "issue" },
  { name: "WebSynchDate", type: "DateTime", entity: "issue" },
  { name: "dateadded", type: "DateTime", entity: "issue" },
  { name: "dateedited", type: "DateTime", entity: "issue" },
  { name: "description", type: "String", entity: "issue" },
  { name: "instruction", type: "String", entity: "issue" },
  { name: "issuecode", type: "Int", entity: "issue" },
  { name: "status", type: "String", entity: "issue" },
  { name: "useridadded", type: "String", entity: "issue" },
  { name: "useridedited", type: "String", entity: "issue" },
];

export default LIST_FIELDS;
