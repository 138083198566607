import { gql } from "@apollo/client";

export const ALL_APOLLO_DOCUMENT_TYPES_QUERY = gql`
  query findManyDocumentTypes(
    $take: Int = 100
    $orderBy: [DocumentTypesOrderByInput!]
    $skip: Int
    $where: DocumentTypesWhereInput
  ) {
    findManyDocumentTypes(take: $take, orderBy: $orderBy, skip: $skip, where: $where) {
      DocumentTypeID
      DocumentType
    }
  }
`;

export const LIST_APOLLO_DOCUMENT_TYPES_QUERY = gql`
  query findManyDocumentTypes(
    $take: Int = 100
    $orderBy: [DocumentTypesOrderByInput!]
    $skip: Int
    $where: DocumentTypesWhereInput
  ) {
    findManyDocumentTypes(take: $take, orderBy: $orderBy, skip: $skip, where: $where) {
      DocumentTypeID
      DocumentType
    }
    aggregateDocumentTypes(where: $where) {
      count {
        DocumentTypeID
      }
    }
  }
`;

export const SINGLE_APOLLO_DOCUMENT_TYPE_QUERY = gql`
  query findUniqueDocumentTypes($where: DocumentTypesWhereUniqueInput!) {
    findUniqueDocumentTypes(where: $where) {
      DocumentTypeID
      DocumentType
    }
  }
`;
