import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Control, Label, Select } from "rbx";
import { useLazyQuery } from "@apollo/client";

import { LIST_STATE_QUERY } from "../../graphql/clients";

const JurisdictionSelect = ({
  value,
  name,
  label,
  size,
  onChange,
  disabled,
  required,
  getValue,
  setValue,
  expanded,
}) => {
  const [states, setStates] = useState(null);
  const [getStates, { data: getStatesData }] = useLazyQuery(LIST_STATE_QUERY);

  useEffect(() => {
    getStates({
      variables: { orderBy: { StateName: "asc" } },
    });
  }, [getStates]);

  useEffect(() => {
    setStates(getStatesData?.states);
  }, [getStatesData]);

  const indicateRequired =
    required && (!value || value === "" || value === "0" || value === 0);

  const handleChange = (e) => {
    onChange(e.target.name, setValue(e.target.value));
  };
  return (
    <Control expanded={expanded}>
      <Label>{label}</Label>
      <Select.Container
        fullwidth
        size={size}
        state={Array.isArray(states) ? "" : "loading"}
      >
        <Select
          disabled={disabled}
          name={name}
          required={required}
          style={{
            border: indicateRequired ? "1px #e63362 solid" : "",
            boxShadow: indicateRequired
              ? "0 0 0 0.125em rgba(230, 51, 98, 0.25)"
              : "",
          }}
          value={getValue(value)}
          onChange={handleChange}
        >
          <Select.Option value="" />
          {Array.isArray(states) &&
            states.map((state) => (
              <Select.Option
                key={`jurisdiction-${state.Statecode}`}
                value={state.Statecode}
              >
                {state.Statecode}
                &nbsp;-&nbsp;
                {state.StateName}
              </Select.Option>
            ))}
        </Select>
      </Select.Container>
    </Control>
  );
};

JurisdictionSelect.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.number,
  ]),
  name: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  getValue: PropTypes.func,
  setValue: PropTypes.func,
  expanded: PropTypes.bool,
};

JurisdictionSelect.defaultProps = {
  value: "",
  name: "",
  label: "State",
  size: "small",
  onChange: () => null,
  disabled: false,
  required: false,
  getValue: (x) => x,
  setValue: (x) => x,
  expanded: false,
};

export default JurisdictionSelect;
