import { useState, useEffect } from "react";

import { useAuth } from "../context/AuthContext";

const usePermissions = (permissions = []) => {
  const { state: authState } = useAuth();
  const [isPermitted, setIsPermitted] = useState(false);

  useEffect(() => {
    const userSecurities = authState?.user?.userSecurities;
    if (Array.isArray(userSecurities)) {
      setIsPermitted(
        permissions.some((perm) =>
          userSecurities.some((us) => us.groupcode === perm)
        )
      );
    }
  }, [authState, permissions]);

  return isPermitted;
};

export default usePermissions;
