import React, { useContext } from "react";
import PropTypes from "prop-types";

import { Content, Button, Control, Field } from "rbx";

import { ModalContext } from "../../../../context/ModalContext";

const ConfirmationModal = ({ message, negative, onCancel, actions }) => {
  const { setModalOpen } = useContext(ModalContext);

  const handleCancel = () => {
    setModalOpen(false, "");
    if (onCancel && typeof onCancel === "function") {
      onCancel();
    }
  };

  return (
    <div>
      <Content size="medium" textAlign="centered">
        <p>{message}</p>
      </Content>

      <Field
        kind="group"
        style={{
          textAlign: "center",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Control>
          <Button
            fullwidth
            color="danger"
            size="small"
            style={{ width: 75 }}
            onClick={handleCancel}
          >
            {negative}
          </Button>
        </Control>
        <Field kind="group">
          {actions.length &&
            actions.map((action) => (
              <Control expanded key={action.id}>
                <Button
                  fullwidth
                  color={action.color}
                  size="small"
                  style={{ width: 75 }}
                  onClick={() => {
                    setModalOpen(false, "");
                    action.onClick();
                  }}
                >
                  {action.text}
                </Button>
              </Control>
            ))}
        </Field>
      </Field>
    </div>
  );
};

ConfirmationModal.propTypes = {
  message: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  onCancel: PropTypes.func,
  negative: PropTypes.string,
  actions: PropTypes.array,
};

ConfirmationModal.defaultProps = {
  onCancel: () => null,
  negative: "Cancel",
  actions: [],
};
export default ConfirmationModal;
