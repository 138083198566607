import React, { useState, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { Title } from "rbx";
import { useQuery } from "@apollo/client";

import Table from "./Table";
import "../DataTable/DataTable.scss";
import { groupBy } from "../../utils";

const GroupByDataTable = ({
  name,
  columns,
  query,
  where,
  orderBy,
  onCellClick,
  fetchPolicy,
  groupByFn,
  sortFn,
  hideCellFn,
}) => {
  const [orderByMulti, setOrderByMulti] = useState(orderBy);

  const handleChangeSort = useCallback(async (sortBy) => {
    setOrderByMulti(sortBy);
  }, []);

  const newOrderBy = orderBy.map((x) => {
    if (x.id.includes(":")) {
      const [lastKey, ...sorts] = x.id.split(":").reverse();
      return sorts.reduce((acc, curr) => ({ [curr]: { ...acc } }), {
        [lastKey]: x.desc ? "desc" : "asc",
      });
    }
    if (x.id.includes(".")) {
      const sorts = x.id.split(".");
      return { [sorts[0]]: x.desc ? "desc" : "asc" };
    }
    return { [x.id]: x.desc ? "desc" : "asc" };
  });

  const { data } = useQuery(query, {
    variables: {
      where,
      orderBy: newOrderBy,
    },
    fetchPolicy,
  });

  const result = useMemo(() => {
    if (!Array.isArray(data?.[name])) return [];

    const grouped = groupBy(data?.[name], groupByFn);

    return Object.keys(grouped)
      .reduce((acc, curr) => {
        const arr = grouped[curr];

        return [
          ...acc,
          ...arr.map((x, i) => {
            const hide = hideCellFn(x, i);
            return {
              ...Object.keys(x).reduce(
                (a, c) => ({
                  ...a,
                  [c]: x[c],
                }),
                []
              ),
              hide,
            };
          }),
        ];
      }, [])
      .sort(sortFn);
  }, [data, groupByFn, hideCellFn, name, sortFn]);

  if (!Array.isArray(data?.[name])) {
    return <h2>Loading...</h2>;
  }

  if (Array.isArray(data?.[name]) && data?.[name]?.length === 0) {
    return (
      <Title subtitle style={{ color: "gray" }}>
        Nothing here...
      </Title>
    );
  }

  return (
    <Table
      columns={columns}
      data={result}
      initialSortBy={orderByMulti}
      onCellClick={onCellClick}
      onChangeSort={handleChangeSort}
    />
  );
};

GroupByDataTable.propTypes = {
  name: PropTypes.string.isRequired,
  columns: PropTypes.array.isRequired,
  query: PropTypes.object.isRequired,
  where: PropTypes.object,
  orderBy: PropTypes.array,
  onCellClick: PropTypes.func,
  fetchPolicy: PropTypes.string,
  groupByFn: PropTypes.func,
  hideCellFn: PropTypes.func,
  sortFn: PropTypes.func,
};

GroupByDataTable.defaultProps = {
  onCellClick: () => null,
  where: {},
  orderBy: [],
  fetchPolicy: "cache-and-network",
  groupByFn: (x) => x,
  hideCellFn: (x) => x,
  sortFn: (a, b) => {
    if (a > b) return 1;
    if (b > a) return -1;
    return 0;
  },
};

export default GroupByDataTable;
