import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import {
  RATS_SINGLE_REQUEST_GENERATOR_QUERY,
  FIND_FIRST_CLIENT_QUERY,
} from "../graphql";
import FIND_PERSON_BY_ID_QUERY from "../graphql/portalPerson/portalPerson.queries";
import useQueryParams from "./useQueryParams";

const usePortalPerson = () => {
  const [personIDstring] = useQueryParams("PersonID", "", true);
  const personID = parseInt(personIDstring, 10);
  const [portalPerson, setPortalPerson] = useState(null);
  const DEFAULT_CLIENT_CODE = 19705;

  const { data: foundPerson } = useQuery(FIND_PERSON_BY_ID_QUERY, {
    variables: {
      where: { PersonID: personID },
    },
    fetchPolicy: "network-only",
    skip: !personID,
  });

  const { data: ratsData } = useQuery(RATS_SINGLE_REQUEST_GENERATOR_QUERY, {
    variables: {
      where: { RGid: foundPerson?.person?.RGID },
    },
    fetchPolicy: "network-only",
    skip: !foundPerson?.person?.RGID,
  });

  const { data: tblClientData } = useQuery(FIND_FIRST_CLIENT_QUERY, {
    variables: {
      where: {
        clientcode: {
          equals: foundPerson?.person?.ClientCode || DEFAULT_CLIENT_CODE,
        },
      },
    },
    fetchPolicy: "network-only",
    skip: !foundPerson,
  });

  useEffect(() => {
    if (foundPerson) {
      setPortalPerson({
        FirstName: foundPerson?.person?.FirstName,
        LastName: foundPerson?.person?.LastName,
        CompanyName:
          ratsData?.requestgenerator?.clientoffice?.COofficeName ||
          tblClientData?.findFirstClient?.company?.extname,
        City: foundPerson?.person?.City,
        State: foundPerson?.person?.StateCode,
        Phone: foundPerson?.person?.Phone,
        Email: foundPerson?.person?.EmailAddress,
        CLnwOption: foundPerson?.person?.IsNationwide,
        CLbsOption: foundPerson?.person?.IsBroadspire,
        ClientLogin: foundPerson?.person?.EmailAddress,
        ClientID: null,
        CLUSAARequired: foundPerson?.person?.IsUSAA,
        CLSentryOption: foundPerson?.person?.IsSentry,
        IsUSAALegal: foundPerson?.person?.IsUSAALegal,
        clientcode: foundPerson?.person?.ClientCode || DEFAULT_CLIENT_CODE,
        marketercode: tblClientData?.findFirstClient?.marketercode,
        PersonID: foundPerson?.person?.PersonID,
        RGID: foundPerson?.person?.RGID,
        ClientMasterID:
          ratsData?.requestgenerator?.clientoffice?.COclientMasterID,
        shouldFilterContacts:
          ratsData?.requestgenerator?.clientoffice?.clientmaster
            ?.shouldFilterContacts,
      });
    }
  }, [
    foundPerson,
    foundPerson?.person?.City,
    foundPerson?.person?.ClientCode,
    foundPerson?.person?.EmailAddress,
    foundPerson?.person?.FirstName,
    foundPerson?.person?.IsBroadspire,
    foundPerson?.person?.IsNationwide,
    foundPerson?.person?.IsSentry,
    foundPerson?.person?.IsUSAA,
    foundPerson?.person?.IsUSAALegal,
    foundPerson?.person?.LastName,
    foundPerson?.person?.PersonID,
    foundPerson?.person?.Phone,
    foundPerson?.person?.StateCode,
    ratsData,
    ratsData?.requestgenerator?.clientoffice?.COofficeName,
    tblClientData?.findFirstClient?.company?.extname,
    tblClientData?.findFirstClient?.marketercode,
  ]);
  return { portalPerson };
};

export default usePortalPerson;
