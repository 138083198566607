import React from "react";
import PropTypes from "prop-types";
import { Column, Field, Control, Label, Input } from "rbx";
import { format } from "date-fns";
import StateSelect from "../../../../components/StateSelect";

import PhoneNumberInput from "../../../../components/PhoneNumberInput";
import DateInput from "../../../../components/DateInput";
import SSNumberInput from "../../../../components/SSNumberInput";
import BooleanInput from "../../../../components/BooleanInput";
import GenderSelect from "../../../../components/GenderSelect";

const ClaimForm = ({ inputs, onChange, disabled, onSubmit, formId }) => (
  <form id={formId} onSubmit={onSubmit}>
    <Column.Group>
      <Column>
        <Field kind="group">
          <Control expanded>
            <Label>Client Name</Label>
            <Input
              required
              autoComplete="new"
              disabled={disabled}
              maxLength={50}
              name="CompanyName"
              size="small"
              type="text"
              value={inputs.CompanyName}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </Control>
          <Control expanded>
            <Label>Claim Number</Label>
            <Input
              required
              autoComplete="new"
              disabled={disabled}
              maxLength={50}
              name="ClaimNumber"
              size="small"
              type="text"
              value={inputs.ClaimNumber}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </Control>
          <StateSelect
            disabled={disabled}
            label="SOJ"
            name="SOJ"
            size="small"
            value={inputs.SOJ}
            onChange={onChange}
          />
        </Field>
        <Field kind="group">
          <Control expanded>
            <Label>First Name</Label>
            <Input
              required
              autoComplete="new"
              disabled={disabled}
              maxLength={50}
              name="ClaimantFirstName"
              size="small"
              type="text"
              value={inputs.ClaimantFirstName}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </Control>
          <Control expanded>
            <Label>Middle Name</Label>
            <Input
              autoComplete="new"
              disabled={disabled}
              maxLength={50}
              name="ClaimantMiddleName"
              size="small"
              type="text"
              value={inputs.ClaimantMiddleName}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </Control>
          <Control expanded>
            <Label>Last Name</Label>
            <Input
              required
              autoComplete="new"
              disabled={disabled}
              maxLength={50}
              name="ClaimantLastName"
              size="small"
              type="text"
              value={inputs.ClaimantLastName}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </Control>
        </Field>
        <Field kind="group">
          <DateInput
            disabled={disabled}
            label="Date of Loss"
            name="DateOfLoss"
            value={inputs.DateOfLoss}
            onChange={onChange}
          />
          <DateInput
            disabled={disabled}
            label="Date of Birth"
            max={format(new Date(), "yyyy-MM-dd")}
            name="DateOfBirth"
            value={inputs.DateOfBirth}
            onChange={onChange}
          />
          <Control>
            <Label>IP Number</Label>
            <Input
              required
              autoComplete="new"
              disabled={disabled}
              maxLength={5}
              name="ClaimantIPNumber"
              size="small"
              style={{ maxWidth: "100px" }}
              type="text"
              value={inputs.ClaimantIPNumber}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </Control>
        </Field>
        <Field kind="group">
          <Control expanded>
            <Label>Address 1</Label>
            <Input
              required
              autoComplete="new"
              disabled={disabled}
              maxLength={50}
              name="Address1"
              size="small"
              type="text"
              value={inputs.Address1}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </Control>
          <Control expanded>
            <Label>Address 2</Label>
            <Input
              autoComplete="new"
              disabled={disabled}
              maxLength={50}
              name="Address2"
              size="small"
              type="text"
              value={inputs.Address2}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </Control>
        </Field>
      </Column>
      <Column>
        <Field kind="group">
          <Control expanded>
            <Label>City</Label>
            <Input
              required
              autoComplete="new"
              disabled={disabled}
              maxLength={50}
              name="City"
              size="small"
              type="text"
              value={inputs.City}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </Control>
          <StateSelect
            required
            disabled={disabled}
            label="State"
            name="State"
            size="small"
            value={inputs.State}
            onChange={onChange}
          />
          <Control expanded>
            <Label>Zip 5</Label>
            <Input
              required
              disabled={disabled}
              maxLength="5"
              name="Zip5"
              size="small"
              value={inputs.Zip5}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </Control>
          <Control expanded>
            <Label>Zip 4</Label>
            <Input
              disabled={disabled}
              label="Zip 4"
              maxLength="4"
              name="Zip4"
              size="small"
              value={inputs.Zip4}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </Control>
        </Field>
        <Field kind="group">
          <Control expanded>
            <Label>Phone 1</Label>
            <PhoneNumberInput
              disabled={disabled}
              name="Phone1"
              value={inputs.Phone1}
              onChange={onChange}
            />
          </Control>
          <Control expanded>
            <Label>Phone 2</Label>
            <PhoneNumberInput
              disabled={disabled}
              name="Phone2"
              value={inputs.Phone2}
              onChange={onChange}
            />
          </Control>
        </Field>
        <Field kind="group">
          <Control expanded>
            <Label>Social Security</Label>
            <SSNumberInput
              disabled={disabled}
              maxLength={11}
              name="SSN"
              size="small"
              value={inputs.SSN}
              onChange={onChange}
            />
          </Control>
          <GenderSelect
            disabled={disabled}
            label="Sex"
            name="Sex"
            value={inputs.Sex}
            onChange={onChange}
          />
        </Field>
        <Field kind="group">
          <Control expanded>
            <Label>Coverage Type</Label>
            <Input
              autoComplete="new"
              disabled={disabled}
              maxLength={50}
              name="CoverageType"
              size="small"
              type="text"
              value={inputs.CoverageType}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </Control>
          <Control expanded>
            <Label>Eligibility Status</Label>
            <Input
              autoComplete="new"
              disabled={disabled}
              maxLength={50}
              name="EligibilityStatus"
              size="small"
              type="text"
              value={inputs.EligibilityStatus}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </Control>
          <Control>
            <Label>HCP</Label>
            <BooleanInput
              disabled={disabled}
              name="HCP"
              value={inputs.HCP}
              onChange={onChange}
            />
          </Control>
        </Field>
      </Column>
    </Column.Group>
  </form>
);
ClaimForm.propTypes = {
  inputs: PropTypes.object,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  onSubmit: PropTypes.func,
  formId: PropTypes.string,
};

ClaimForm.defaultProps = {
  inputs: {},
  onChange: (e) => e,
  disabled: false,
  onSubmit: (e) => e,
  formId: "claim-form",
};
export default ClaimForm;
