import React from "react";

import PropTypes from "prop-types";

import { Control, Label, Select } from "rbx";

import { useQuery } from "@apollo/client";
import { ALL_SERVICES_CASE_TYPES_SELECT_QUERY } from "../../graphql";

const ServiceCaseTypeSelect = ({
  value,
  name,
  label,
  size,
  onChange,
  disabled,
  caseType,
  required,
  showBlank,
  officeCode,
}) => {
  const { data: ServicesData, loading: ServicesLoading } = useQuery(
    ALL_SERVICES_CASE_TYPES_SELECT_QUERY,
    {
      variables:
        caseType && officeCode
          ? {
              orderBy: { Service: { description: "asc" } },
              where: {
                casetype: {
                  equals: parseInt(caseType, 10),
                },
                officecode: {
                  equals: parseInt(officeCode, 10),
                },
              },
            }
          : { take: 1 },
      fetchPolicy: "cache-first",
    }
  );

  const handleChange = (e) => {
    onChange(e.target.name, e.target.value || "");
  };

  const services =
    caseType && officeCode && Array.isArray(ServicesData?.caseTypeServices)
      ? ServicesData.caseTypeServices.map((caseTypeService) => ({
          ...caseTypeService.Service,
        }))
      : [];

  return (
    <Control>
      <Label>{label}</Label>
      <Select.Container
        fullwidth
        size={size}
        state={ServicesLoading ? "loading" : ""}
      >
        <Select
          disabled={disabled}
          name={name}
          required={required}
          value={value}
          onChange={handleChange}
        >
          {showBlank && <Select.Option value="" />}
          {Array.isArray(services) &&
            services.map((service) => (
              <Select.Option
                key={service.servicecode}
                value={service.servicecode}
              >
                {service?.description}
              </Select.Option>
            ))}
        </Select>
      </Select.Container>
    </Control>
  );
};

ServiceCaseTypeSelect.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  caseType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  required: PropTypes.bool,
  showBlank: PropTypes.bool,
  officeCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ServiceCaseTypeSelect.defaultProps = {
  value: "",
  name: "",
  label: "Service",
  size: "small",
  onChange: () => null,
  disabled: false,
  required: false,
  caseType: null,
  showBlank: true,
  officeCode: null,
};

export default ServiceCaseTypeSelect;
