import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useLazyQuery, useMutation, useApolloClient } from "@apollo/client";
import { Button, Input, Field, Title, Control, Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  debounce,
  convertTimeZoneDataBase,
  customToast as toast,
  getDateEST,
} from "../../../../utils";
import {
  LIST_EXCEPTION_DEFINITION_QUERY,
  FIND_FIRST_EXCEPTION_DEFINITION_QUERY,
  SINGLE_EXCEPTION_LIST_QUERY,
  UPDATE_EXCEPTION_LIST_MUTATION,
  DELETE_EXCEPTION_LIST_MUTATION,
  LIST_EXCEPTION_LIST_QUERY,
} from "../../../../graphql";
import DataTable from "../../../../components/DataTable";
import COLUMNS from "./columns";
import { ExceptionForm } from "../../components";
import Loader from "../../../../components/Loader";

import { useAuth, useModal } from "../../../../context";
import ExceptionModal from "../../../../components/ExceptionDefinition/ExceptionModal";
import Confirmation from "../../../../components/Confirmation";

const INITIAL_STATE = {
  Description: "",
  Status: "Active",
};

class CustomExceptionError extends Error {}

const ExceptionPage = () => {
  const history = useHistory();
  const { ExceptionID } = useParams();
  const { setModalOpen } = useModal();
  const client = useApolloClient();
  const { state: authState } = useAuth();
  const [inputs, setInputs] = useState(INITIAL_STATE);

  const [where, setWhere] = useState({
    Description: { contains: "" },
  });

  useEffect(() => {
    if (ExceptionID) {
      setWhere((prev) => ({
        ...prev,
        ExceptionID: { equals: parseInt(ExceptionID, 10) },
      }));
    }
  }, [ExceptionID]);

  const [updateException, { loading: updateExceptionLoading }] = useMutation(
    UPDATE_EXCEPTION_LIST_MUTATION
  );
  const [deleteException] = useMutation(DELETE_EXCEPTION_LIST_MUTATION);

  const [
    getException,
    { data: exceptionData, loading: queryLoading },
  ] = useLazyQuery(SINGLE_EXCEPTION_LIST_QUERY);

  useEffect(() => {
    if (ExceptionID) {
      getException({
        variables: {
          where: {
            ExceptionID: parseInt(ExceptionID, 10),
          },
        },
      });
    }
  }, [ExceptionID, getException]);

  useEffect(() => {
    if (exceptionData?.exceptionList) {
      setInputs({
        Description: exceptionData.exceptionList.Description || "",
        Status: exceptionData.exceptionList.Status || "Active",
      });
    }
  }, [exceptionData]);

  const handleChange = (name, value) => {
    setInputs((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const date = convertTimeZoneDataBase(getDateEST());
      await updateException({
        variables: {
          data: {
            Description: { set: inputs.Description },
            Status: { set: inputs.Status },
            UserIDEdited: { set: authState.user.userid },
            DateEdited: { set: date },
          },
          where: {
            ExceptionID: parseInt(ExceptionID, 10),
          },
        },
        refetchQueries: [
          {
            query: SINGLE_EXCEPTION_LIST_QUERY,
            variables: {
              where: {
                ExceptionID: parseInt(ExceptionID, 10),
              },
            },
          },
        ],
      });
    } catch (err) {
      toast.error("Error on saving Exception");
    }
  };

  const handleRowClick = (row) => {
    setModalOpen(
      true,
      <ExceptionModal
        canDelete
        Entity={row.Entity}
        ExceptionID={ExceptionID}
        entityDisabled={false}
        exception={row}
        where={{ ExceptionDefID: { equals: parseInt(ExceptionID, 10) } }}
        onComplete={() => setModalOpen(false)}
      />
    );
  };

  const handleDelete = async (e) => {
    e.preventDefault();

    const performDelete = async () => {
      try {
        const {
          data: { findFirstExceptionDefinition },
        } = await client.query({
          query: FIND_FIRST_EXCEPTION_DEFINITION_QUERY,
          variables: {
            where: {
              ExceptionID: { equals: parseInt(ExceptionID, 10) },
            },
          },
        });
        if (findFirstExceptionDefinition) {
          throw new CustomExceptionError(
            "This exception cannot be deleted as there are still exception definitions related."
          );
        }
        await deleteException({
          variables: {
            where: {
              ExceptionID: parseInt(ExceptionID, 10),
            },
          },
          refetchQueries: [
            {
              query: LIST_EXCEPTION_LIST_QUERY,
            },
          ],
        });
        toast.success("Exception deleted successfully");
        history.push("/setup/exceptions");
      } catch (err) {
        const message =
          err instanceof CustomExceptionError
            ? err.message
            : "Error trying to delete exception";
        toast.error(message);
      } finally {
        setModalOpen(false);
      }
    };
    setModalOpen(
      true,
      <Confirmation
        message="Are you sure you want to delete this exception?"
        onConfirm={performDelete}
      />
    );
  };

  if (queryLoading) return <Loader />;

  return (
    <div>
      <header className="page-head">
        <div className="page-head-start">
          <Title>Edit Exception List</Title>
        </div>
        <div className="page-head-end">
          <Button.Group hasAddons>
            <Button color="danger" size="small" onClick={handleDelete}>
              <span>Delete</span>
            </Button>
            <Button
              color="primary"
              disabled={updateExceptionLoading}
              size="small"
              state={updateExceptionLoading ? "loading" : ""}
              onClick={handleSubmit}
            >
              <span>Submit</span>
            </Button>
          </Button.Group>
        </div>
      </header>
      <hr />
      <ExceptionForm inputs={inputs} onChange={handleChange} />
      <hr />
      <div className="page-head">
        <div className="page-head-start">
          <Title subtitle>Exception Definitions</Title>
        </div>
        <div className="page-head-end">
          <Field kind="group">
            <Control>
              <Input
                autoComplete="off"
                id="search-input"
                placeholder="Search..."
                size="small"
                onChange={debounce(({ target: { value } }) => {
                  if (value) {
                    setWhere((prev) => ({
                      ...prev,
                      Description: { contains: value },
                    }));
                  } else {
                    setWhere((prev) => ({
                      ...prev,
                      Description: { contains: "" },
                    }));
                  }
                }, 500)}
              />
            </Control>
            <Control>
              <Button
                color="primary"
                size="small"
                onClick={() => {
                  setModalOpen(
                    true,
                    <ExceptionModal
                      ExceptionID={ExceptionID}
                      entityDisabled={false}
                      where={{
                        ExceptionID: { equals: parseInt(ExceptionID, 10) },
                      }}
                      onComplete={() => setModalOpen(false)}
                    />
                  );
                }}
              >
                <Icon>
                  <FontAwesomeIcon icon="plus" />
                </Icon>
                <span>Add</span>
              </Button>
            </Control>
          </Field>
        </div>
      </div>
      <DataTable
        aggregateKey="ExceptionDefID"
        aggregateName="aggregateExceptionDefinition"
        columns={COLUMNS}
        name="exceptionDefinitions"
        orderBy={[{ id: "Description", desc: false }]}
        query={LIST_EXCEPTION_DEFINITION_QUERY}
        where={where}
        onRowClick={handleRowClick}
      />
    </div>
  );
};
export default ExceptionPage;
