import { gql } from "@apollo/client";

export const SUBMIT_REFERRAL_MUTATION = gql`
  mutation SUBMIT_REFERRAL_MUTATION(
    $input: ReferralInput!
    $output: ClaimClaimantOutputInput
  ) {
    submitReferral(input: $input, output: $output) {
      OK
      DocumentPaths
      Errors
      ReferenceNumbers
    }
  }
`;

export const SUBMIT_RECORDS_REFERRAL_MUTATION = gql`
  mutation SUBMIT_RECORDS_REFERRAL_MUTATION(
    $input: RecordsReferralInput!
    $output: ClaimClaimantOutputInput
  ) {
    submitRecordsReferral(input: $input, output: $output) {
      OK
      DocumentPaths
      Errors
      RRid
    }
  }
`;

export const SEND_REFERRAL_RECEIPT_MUTATION = gql`
  mutation SEND_REFERRAL_RECEIPT_MUTATION(
    $input: RecordsReferralInput!
    $output: ClaimClaimantOutputInput
  ) {
    sendRecordsReferralReceipt(input: $input, output: $output) {
      OK
      DocumentPaths
      Errors
    }
  }
`;

export const CREATE_RECORD_UPLOAD_MUTATION = gql`
  mutation CreateRecorduploads($data: RecorduploadsCreateInput!) {
    createRecorduploads(data: $data) {
      RUid
    }
  }
`;

export const UPDATE_RECORD_REQUEST_MUTATION = gql`
  mutation UpdateRecordrequest(
    $where: RecordrequestWhereUniqueInput!
    $data: RecordrequestUpdateInput!
  ) {
    updateRecordrequest(where: $where, data: $data) {
      RRid
    }
  }
`;

export const UPDATE_RECORD_PROVIDER_MUTATION = gql`
  mutation UpdateRecordprovider(
    $data: RecordproviderUpdateInput!
    $where: RecordproviderWhereUniqueInput!
  ) {
    updateRecordprovider(data: $data, where: $where) {
      RPid
    }
  }
`;

export const UPDATE_SUBMITTED_RECORDS_REFERRAL_MUTATION = gql`
  mutation UPDATE_SUBMITTED_RECORDS_REFERRAL_MUTATION(
    $input: RecordsReferralInput!
    $output: ClaimClaimantOutputInput
  ) {
    updateSubmittedRecordsReferral(input: $input, output: $output) {
      OK
      DocumentPaths
      Errors
    }
  }
`;

export const UPDATE_NON_SUBMITTED_RECORDS_REFERRAL_MUTATION = gql`
  mutation UPDATE_SUBMITTED_RECORDS_REFERRAL_MUTATION(
    $input: RecordsReferralInput!
    $output: ClaimClaimantOutputInput
  ) {
    updateNonSubmittedRecordsReferral(input: $input, output: $output) {
      OK
      DocumentPaths
      Errors
    }
  }
`;

export const CAPTURE_REFERRAL_ERROR_MUTATION = gql`
  mutation CaptureReferralError($input: ReferralErrorInput!) {
    captureReferralError(input: $input) {
      Type
      Code
      Message
      OtherInfo
    }
  }
`;
