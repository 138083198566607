import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Title, Button } from "rbx";
import { useApolloClient, useMutation, useLazyQuery } from "@apollo/client";
import ProductForm from "../ProductForm";
import { useModal, useAuth } from "../../../../../context";
import {
  customToast as toast,
  getDateEST,
  convertTimeZoneDataBase,
} from "../../../../../utils";
import {
  CREATE_PRODUCT_MUTATION,
  UPDATE_PRODUCT_MUTATION,
  DELETE_PRODUCT_MUTATION,
  // CREATE_AUDIT_TRAIL_MUTATION,
  LIST_PRODUCTS_QUERY,
  SINGLE_PRODUCT_QUERY,
  FIND_FIRST_DOCTOR_QUERY,
  FIND_FIRST_ACCT_DETAIL_QUERY,
} from "../../../../../graphql";
import Confirmation from "../../../../../components/Confirmation";

const INITIAL_STATE = {
  description: "",
  longdesc: "",
  status: "Active",
  INglacct: "",
  VOglacct: "",
};

const ProductModal = ({ onComplete, prodcode }) => {
  const client = useApolloClient();
  const { setModalOpen } = useModal();
  const { state: authState } = useAuth();
  const [inputs, setInputs] = useState(INITIAL_STATE);
  const [updated, setUpdated] = useState([]);
  const [loading, setLoading] = useState(false);

  const [
    getProduct,
    { data: productData, loading: queryLoading },
  ] = useLazyQuery(SINGLE_PRODUCT_QUERY);
  const [createProduct] = useMutation(CREATE_PRODUCT_MUTATION);
  const [updateProduct] = useMutation(UPDATE_PRODUCT_MUTATION);
  const [deleteProduct] = useMutation(DELETE_PRODUCT_MUTATION);
  // const [createAuditTrail] = useMutation(CREATE_AUDIT_TRAIL_MUTATION);

  useEffect(() => {
    if (prodcode) {
      getProduct({
        variables: {
          where: {
            prodcode,
          },
        },
      });
    }
  }, [getProduct, prodcode]);

  useEffect(() => {
    if (productData?.product) {
      setInputs({
        description: productData.product.description || "",
        longdesc: productData.product.longdesc || "",
        status: productData.product.status || "Active",
        INglacct: productData.product.INglacct || "",
        VOglacct: productData.product.VOglacct || "",
      });
    }
  }, [productData]);

  const handleChange = (name, value) => {
    setInputs((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (prodcode) {
      setUpdated((prev) => (prev.includes(name) ? prev : [...prev, name]));
    }
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      const date = convertTimeZoneDataBase(getDateEST());
      if (!prodcode) {
        await createProduct({
          variables: {
            data: {
              ...inputs,
              useridedited: authState.user.userid,
              dateedited: date,
              useridadded: authState.user.userid,
              dateadded: date,
            },
          },
          refetchQueries: [
            {
              query: LIST_PRODUCTS_QUERY,
            },
          ],
        });

        // await createAuditTrail({
        //   variables: {
        //     data: {
        //       tablename: "tblProduct",
        //       entityid: createProductData.createProduct.prodcode.toString(),
        //       type: "New",
        //       description: "New Product created",
        //       useridadded: authState.user.userid,
        //       dateadded: date,
        //     },
        //   },
        //   refetchQueries: [
        //     {
        //       query: SINGLE_PRODUCT_QUERY,
        //       variables: {
        //         where: { prodcode: createProductData.createProduct.prodcode },
        //       },
        //     },
        //   ],
        // });
        toast.success("Product created successfully.");
      } else {
        await updateProduct({
          variables: {
            data: {
              description: { set: inputs.description },
              longdesc: { set: inputs.longdesc },
              status: { set: inputs.status },
              INglacct: { set: inputs.INglacct },
              VOglacct: { set: inputs.VOglacct },
              useridedited: { set: authState.user.userid },
              dateedited: { set: date },
            },
            where: {
              prodcode,
            },
          },
          refetchQueries: [
            {
              query: LIST_PRODUCTS_QUERY,
            },
            {
              query: SINGLE_PRODUCT_QUERY,
              variables: {
                where: { prodcode },
              },
            },
          ],
        });

        // await createAuditTrail({
        //   variables: {
        //     data: {
        //       tablename: "tblProduct",
        //       entityid: prodcode.toString(),
        //       type: "Update",
        //       description: `Updated fields: ${updated.join(", ")}`,
        //       useridadded: authState.user.userid,
        //       dateadded: date,
        //     },
        //   },
        // });
        toast.success("Product updated successfully.");
      }
      onComplete();
    } catch (err) {
      toast.error("Error saving Product");
    } finally {
      setLoading(false);
    }
  };

  const checkDoctors = async () => {
    const { data: doctorsData } = await client.query({
      query: FIND_FIRST_DOCTOR_QUERY,
      variables: {
        where: {
          prodcode: { equals: parseInt(prodcode, 10) },
        },
      },
    });
    return doctorsData;
  };

  const checkAcctDetails = async () => {
    const { data: acctDetailsData } = await client.query({
      query: FIND_FIRST_ACCT_DETAIL_QUERY,
      variables: {
        where: {
          prodcode: { equals: parseInt(prodcode, 10) },
        },
      },
    });
    return acctDetailsData;
  };

  const handleDelete = async (e) => {
    e.preventDefault();

    const doctorsData = await checkDoctors();
    if (doctorsData?.findFirstDoctor) {
      toast.error(
        "This product could not be removed because there are some doctors related"
      );
      return;
    }

    const acctDetailsData = await checkAcctDetails();
    if (acctDetailsData?.findFirstAcctDetail) {
      toast.error(
        "This product could not be removed because there are some acct details related"
      );
      return;
    }

    const performDelete = async () => {
      try {
        setLoading(true);
        await deleteProduct({
          variables: {
            where: {
              prodcode,
            },
          },
          refetchQueries: [
            {
              query: LIST_PRODUCTS_QUERY,
            },
            {
              query: SINGLE_PRODUCT_QUERY,
              variables: {
                where: { prodcode },
              },
            },
          ],
        });
        toast.success("Product deleted successfully");
        onComplete();
      } catch (err) {
        toast.error("Error trying to delete Product");
      } finally {
        setLoading(false);
      }
    };
    setModalOpen(
      true,
      <Confirmation
        message="Are you sure you want to delete this Product?"
        onCancel={() =>
          setModalOpen(
            true,
            <ProductModal prodcode={prodcode} onComplete={onComplete} />
          )
        }
        onConfirm={performDelete}
      />
    );
  };

  const isDisabled =
    !inputs.description ||
    (prodcode && !updated?.length) ||
    queryLoading ||
    loading;

  return (
    <form id="product-form" onSubmit={handleSubmit}>
      <header className="page-head">
        <div className="page-head-start">
          <Title size={5}>
            {`${!prodcode ? "Create" : "Edit"}`}
            &nbsp;Product
          </Title>
        </div>
        <div className="page-head-end">
          <Button.Group hasAddons>
            <Button
              size="small"
              type="button"
              onClick={() => onComplete(false)}
            >
              <span>Cancel</span>
            </Button>

            <Button
              color="primary"
              disabled={isDisabled}
              form="product-form"
              size="small"
              state={loading ? "loading" : ""}
              type="submit"
            >
              <span>Submit</span>
            </Button>
          </Button.Group>
        </div>
      </header>
      <hr />
      <ProductForm inputs={inputs} onChange={handleChange} />
      <hr />
      {prodcode && (
        <Button
          color="danger"
          size="small"
          type="button"
          onClick={handleDelete}
        >
          <span>Delete</span>
        </Button>
      )}
    </form>
  );
};

ProductModal.propTypes = {
  onComplete: PropTypes.func,
  prodcode: PropTypes.number,
};

ProductModal.defaultProps = {
  onComplete: () => null,
  prodcode: null,
};

export default ProductModal;
