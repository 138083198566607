import React from "react";
import PropTypes from "prop-types";
import { Control, Label, Select } from "rbx";

const MaritalStatusSelect = ({
  label,
  size,
  name,
  onChange,
  disabled,
  value,
  required,
  id,
  fullWidth,
  getValue,
  setValue,
}) => {
  const handleChange = (e) => {
    onChange(e.target.name, setValue(e.target.value));
  };

  return (
    <Control expanded>
      {label && <Label>{label}</Label>}

      <Select.Container fullwidth size={size}>
        <Select
          disabled={disabled}
          id={id}
          name={name}
          required={required}
          value={getValue(value)}
          onChange={handleChange}
        >
          <Select.Option value="" />
          <Select.Option value="Married">Married</Select.Option>
          <Select.Option value="Single">Single</Select.Option>
          <Select.Option value="Divorced">Divorced</Select.Option>
        </Select>
      </Select.Container>
    </Control>
  );
};

MaritalStatusSelect.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  id: PropTypes.string,
  fullWidth: PropTypes.bool,
  getValue: PropTypes.func,
  setValue: PropTypes.func,
};

MaritalStatusSelect.defaultProps = {
  size: "small",
  fullWidth: false,
  disabled: false,
  value: "",
  name: "",
  label: null,
  required: false,
  id: null,
  getValue: (x) => x,
  setValue: (x) => x,
  onChange: () => null,
};

export default MaritalStatusSelect;
