/* eslint-disable react/prop-types */
import React, { useMemo } from "react";
import { Field, Control, Button } from "rbx";
import { format } from "date-fns";
import { convertTimeZone, getDaysInQueue } from "../../../utils";
import { TableCell } from "../../../components/DataTable";
import "../Case.scss";

const DEFAULT_COLUMNS = [
  {
    Header: "Case",
    id: "casenbr",
    accessor: "casenbr",
    Cell: ({ cell, row }) => {
      const isNormalPriority = row?.original?.priority?.includes("Normal");
      return (
        <span style={{ color: isNormalPriority ? "#363636" : "red" }}>
          {cell.value}
        </span>
      );
    },
  },
  {
    Header: "Priority",
    id: "priority",
    accessor: "priority",
    Cell: ({ cell }) => {
      if (cell.value) {
        return (
          <span
            style={{ color: cell.value.includes("Normal") ? "#363636" : "red" }}
          >
            {cell.value}
          </span>
        );
      }
      return "";
    },
  },
  {
    Header: "IQ",
    id: "laststatuschg",
    accessor: "laststatuschg",
    Cell: ({ cell }) => (
      <TableCell.Number
        value={cell?.value ? getDaysInQueue(cell.value) : "--"}
      />
    ),
  },
  {
    Header: "Appt. Time",
    id: "Appttime",
    accessor: "Appttime",
    Cell: ({ cell }) =>
      cell?.value
        ? format(convertTimeZone(cell.value), "MM/dd/yyyy hh:mm")
        : "--",
  },
  {
    Header: "Company",
    id: "client:company:intname",
    accessor: "client.company.intname",
  },
  {
    Header: "Examinee",
    id: "examinee:lastname",
    accessor: "examinee",
    Cell: ({ cell }) =>
      cell.value ? `${cell.value.lastname}, ${cell.value.firstname}` : "--",
  },
  {
    Header: "Doctor",
    id: "DoctorName",
    accessor: "DoctorName",
  },
  {
    Header: "Case Type",
    id: "casetype",
    accessor: "caseType.description",
  },
  {
    Header: "Service",
    id: "service:description",
    accessor: "service.description",
  },
  {
    Header: "Scheduler",
    id: "schedulercode",
    accessor: "schedulercode",
  },
];

export const generateCaseAwaitingApptColumns = ({
  selected,
  onSelect,
  onSelectAll,
  onUpdate,
}) => [
  {
    id: "selection",
    accessor: "casenbr",
    defaultCanSort: false,
    disableSortBy: true,
    Header: ({ data }) => {
      const allSelected = useMemo(
        () =>
          data.every((caseItem) =>
            selected.some((s) => s.casenbr === caseItem.casenbr)
          ),
        [data]
      );
      const someSelected = useMemo(
        () =>
          data.some((caseItem) =>
            selected.some((s) => s.casenbr === caseItem.casenbr)
          ),
        [data]
      );

      return (
        <TableCell.Checkbox
          checked={allSelected}
          indeterminate={!allSelected && someSelected}
          onChange={() =>
            onSelectAll(
              allSelected,
              data.map((caseItem) => caseItem.casenbr)
            )
          }
          onClick={(e) => e.stopPropagation()}
        />
      );
    },
    Cell: ({ cell }) => (
      <TableCell.Checkbox
        checked={selected.some((s) => s.casenbr === cell?.value)}
        onChange={() => onSelect(cell?.value)}
        onClick={(e) => e.stopPropagation()}
      />
    ),
  },
  ...DEFAULT_COLUMNS,
  {
    Header: "Action",
    id: "Action",
    Cell: ({ row }) => (
      <Field kind="addons">
        <Control>
          <Button
            className="toggle-button"
            color="primary"
            outlined={
              !selected.some(
                (s) => s.casenbr === row.original.casenbr && s.type === "show"
              )
            }
            size="small"
            style={{ height: 20 }}
            type="button"
            onClick={(e) => {
              e.stopPropagation();
              onUpdate(row.original.casenbr, "show");
            }}
          >
            <span>Show</span>
          </Button>
        </Control>
        <Control>
          <Button
            className="toggle-button"
            color="primary"
            outlined={
              !selected.some(
                (s) => s.casenbr === row.original.casenbr && s.type === "noshow"
              )
            }
            size="small"
            style={{ height: 20 }}
            type="button"
            onClick={(e) => {
              e.stopPropagation();
              onUpdate(row.original.casenbr, "noshow");
            }}
          >
            <span>No Show</span>
          </Button>
        </Control>
      </Field>
    ),
  },
];
export default generateCaseAwaitingApptColumns;
