import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";

import { AsyncPaginate } from "react-select-async-paginate";

import { Label, Control, Field } from "rbx";
import { useApolloClient, useLazyQuery } from "@apollo/client";
import { ALL_COURT_QUERY, SINGLE_COURT_QUERY } from "../../../../graphql/court";

import CourtSelectActionButtons from "../CourtSelectActionButtons";

const QUERY_LIMIT = 50;

const CourtSelect = ({
  name,
  value: initialValue,
  label,
  onChange,
  disabled,
  getValue,
  setValue,
  required,
}) => {
  const client = useApolloClient();

  const [getCourt, { data: singleCourtData }] = useLazyQuery(SINGLE_COURT_QUERY)

  const [selected, setSelected] = useState(null);
  const [defaultOptions, setDefaultOptions] = useState([]);

  const getLabel = useCallback(
    (court) =>
      `${court.CourtCounty || ""} ${court.CourtType || ""} - ${court.CourtAddress || ""} ${court.CourtCity || ""} ${court.CourtState || ""}`
    , []
  );

  const promiseOptions = async (inputValue, { length }) => {
    // const where = {
    //   company: { startsWith: inputValue },
    // };

    const { data: allCourtData } = await client.query({
      query: ALL_COURT_QUERY,
      variables: {
        take: QUERY_LIMIT,
        orderBy: { "CourtID": "asc" },
        skip: length,
      }
    })

    const selectOptions = Array.isArray(allCourtData?.courts)
      ? allCourtData?.courts.map((court) => ({
        value: court.CourtID,
        label: getLabel(court),
      }))
      : [];

    setDefaultOptions(selectOptions);

    return {
      options: selectOptions,
      hasMore: selectOptions.length === QUERY_LIMIT,
    };
  };

  const handleChange = (courtData) => {
    onChange(name, setValue(courtData?.CourtID || courtData?.value || "")); // send courtID value for DB
  };

  const handleFormClear = () => {
    onChange(name, setValue(""));
    setSelected(null)
  }

  useEffect(() => {
    if (initialValue) {
      getCourt({
        variables: {
          where: { CourtID: parseInt(getValue(initialValue), 10) },
        },
      });
    } else {
      setSelected(null);
    }
  }, [initialValue, getValue, getCourt]);

  useEffect(() => {
    const court = singleCourtData?.court;
    if (court) {
      setSelected({
        value: court.CourtID,
        label: getLabel(court),
        CourtclerkFname: court.CourtclerkFname,
        CourtclerkLname: court.CourtclerkLname,
        CourtclerkTitle: court.CourtclerkTitle,
        CourtAddress: court.CourtAddress,
        CourtAddress2: court.CourtAddress2,
        CourtCity: court.city,
        CourtCounty: court.CourtCounty,
        CourtState: court.CourtState,
        CourtZip: court.CourtZip,
        CourtType: court.CourtType,
        CourtID: court.CourtID
      });
    }
  }, [singleCourtData?.court, getLabel]);

  useEffect(() => {
    if (selected) {
      const currOptions = [...defaultOptions];
      if (!currOptions.some((opt) => opt.value === selected.value)) {
        setDefaultOptions((prev) => [selected, ...prev]);
      }
    }
  }, [selected, defaultOptions]);

  const indicateRequired =
    required &&
    !disabled &&
    (!initialValue ||
      initialValue === "" ||
      initialValue === "0" ||
      initialValue === 0);

  return (
    <Field kind="group">
      <Control expanded>
        <Label>{label}</Label>
        <AsyncPaginate
          cacheOptions
          debounceTimeout={300}
          defaultOptions={defaultOptions} // this is provided so that the fetch is not made every time the dropdown is opened!
          isDisabled={disabled}
          loadOptions={promiseOptions} // this is provided to async call the options
          menuPortalTarget={document.body}
          placeholder="Start typing..."
          required={required}
          styles={{
            menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
            indicatorsContainer: (provided) => ({
              ...provided,
              height: "inherit",
            }),
            indicatorSeparator: (provided) => ({
              ...provided,
              display: "none",
            }),
            container: (provided) => ({
              fontSize: 13,
            }),
            singleValue: (provided) => ({
              ...provided,
              top: "38%",
            }),
            control: (provided) => ({
              ...provided,
              minHeight: 31,
              height: 31,
              width: "100%",
              minWidth: 180,
              border: indicateRequired ? "1px #e63362 solid" : "",
              boxShadow: indicateRequired
                ? "0 0 0 0.125em rgba(230, 51, 98, 0.25)"
                : "",
              "&:hover": {
                borderColor: "hsl(0, 0%, 90%)",
                borderRadius: 4,
                cursor: disabled ? "not-allowed" : "pointer",
              },
            }),
            placeholder: (provided) => ({ ...provided, top: "38%" }),
            menu: (provided) => ({
              ...provided,
              fontSize: 12,
              zIndex: 999,
            }),
          }}
          value={selected}
          onChange={handleChange}
        />
      </Control>
      <Control>
        <Label>&nbsp;</Label>
        <CourtSelectActionButtons
          disabled={disabled}
          label={label}
          selectedAddress={selected}
          onClearSelectionClick={handleFormClear}
          onSelect={(courtData) => handleChange(courtData)}
        />
      </Control>
    </Field>
  );
};

CourtSelect.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  getValue: PropTypes.func,
  setValue: PropTypes.func,
  required: PropTypes.bool,
};

CourtSelect.defaultProps = {
  name: "",
  label: "Court Address",
  value: "",
  onChange: () => null,
  disabled: false,
  getValue: (x) => x,
  setValue: (x) => x,
  required: false,
};

export default CourtSelect;
