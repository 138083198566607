import React, { useState, useEffect } from "react";
import { Title, Tab, Button } from "rbx";
import { useLazyQuery, useMutation, useApolloClient } from "@apollo/client";
import { Link, useParams, useLocation, Route, Switch } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import { usePermissions } from "../../hooks";
import {
  customToast as toast,
  getDateEST,
  convertInputToVariables,
} from "../../utils";
import Defaults from "./routes/Defaults";
import Cases from "./routes/Cases";
import UserDefinedFields from "./routes/UserDefinedFields";
import Notes from "./routes/Notes";
import BillingInformation from "./routes/BillingInformation";
import Exceptions from "./routes/Exceptions";
import DrsNotToUse from "./routes/DrsNotToUse";
import WebPortal from "./routes/WebPortal";
import Statistics from "./routes/Statistics";
import Profile from "./routes/Profile";

import {
  SINGLE_CLIENT_QUERY,
  UPDATE_CLIENT_MUTATION,
  LIST_CLIENTS_QUERY,
  SYNC_WEB_USER_INFO_MUTATION,
  LIST_WEB_USER_QUERY,
} from "../../graphql";

import Loader from "../../components/Loader";
import IconLink from "../../components/IconLink";

import "./Client.scss";

const INITIAL_STATE = {
  companycode: "",
  firstname: "",
  lastname: "",
  addr1: "",
  addr2: "",
  city: "",
  state: "",
  zip: "",
  phone1: "",
  phone1ext: "",
  phone2: "",
  fax: "",
  phone2ext: "",
  status: "Active",
  prefix: "",
  suffix: "",
  title: "",
  TypeCode: "",
  email: "",
  marketercode: "",
  casetype: "",
  priority: "Normal",
  DefOfficeCode: "",
  QARep: "",
  documentemail: false,
  documentfax: false,
  documentmail: false,
  reportphone: false,
  CertifiedMail: false,
  photoRqd: false,
  PublishOnWeb: false,
  DocumentPublish: false,
  ProcessorFirstName: "",
  ProcessorLastName: "",
  ProcessorPhone: "",
  ProcessorPhoneExt: "",
  ProcessorFax: "",
  ProcessorEmail: "",
  usdvarchar1: "",
  usdvarchar2: "",
  usddate1: "",
  usdint1: "",
  usdint2: "",
  usdtext1: "",
  usdtext2: "",
  notes: "",
  billattn: "",
  billaddr1: "",
  billaddr2: "",
  billcity: "",
  billstate: "",
  billzip: "",
  billfax: "",
  clientnbrold: "",
  WebUserName: "",
  Webpassword: "",
  doNotInherit: false,
};

const ClientPage = () => {
  const { state: authState } = useAuth();
  const { clientcode } = useParams();
  const aClient = useApolloClient();
  const [inputs, setInputs] = useState(INITIAL_STATE);
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [getClientData, resultClient] = useLazyQuery(SINGLE_CLIENT_QUERY);
  const [updateClient] = useMutation(UPDATE_CLIENT_MUTATION);
  const [syncWebUserInfo] = useMutation(SYNC_WEB_USER_INFO_MUTATION);

  const hasPermission = usePermissions([
    "AllAdminFunc",
    "Accounting",
    "Cust Service",
    "QC",
  ]);

  const location = useLocation();

  useEffect(() => {
    if (clientcode && clientcode !== "add") {
      getClientData({
        variables: {
          where: { clientcode: parseInt(clientcode, 10) },
        },
        fetchPolicy: "cache-first",
      });
    }
  }, [clientcode, getClientData]);

  useEffect(() => {
    if (resultClient?.data && !resultClient?.loading) {
      const {
        data: { client },
      } = resultClient;
      // This is to prevent warning on changing state values from defined to undefined
      const clearClient = Object.fromEntries(
        Object.entries(client).filter(([_, v]) => v !== null)
      );
      setInputs((prev) => ({
        ...prev,
        ...clearClient,
        state: client.state,
        TypeCode: client.TypeCode || "",
        marketercode: client.marketercode || "",
        casetype: client.casetype || "",
        QARep: client.QARep || "",
        prefix: client.prefix || "",
        priority: client.priority || "",
        DefOfficeCode: client.DefOfficeCode || "",
        company: client.companycode || "",
        usddate1: client.usddate1
          ? new Date(client.usddate1).toISOString().slice(0, 10)
          : "",
        WebUserName: client.WebUser?.UserID || "",
        Webpassword: client.WebUser?.Password || "",
      }));
    }
  }, [resultClient]);

  const handleChange = (name, value) => {
    setInputs((prev) => ({ ...prev, [name]: value }));
  };

  const handleSave = async (e) => {
    if (e) e.preventDefault();
    try {
      setLoading(true);
      if (location.pathname.includes("defaults") && !inputs.marketercode) {
        throw new Error("Marketer required");
      }
      const date = getDateEST();

      const data = {
        ...convertInputToVariables(inputs, Object.keys(INITIAL_STATE), false, [
          "companycode",
          "TypeCode",
          "DefOfficeCode",
        ]),
      };

      if (inputs.companycode) {
        data.company = {
          connect: { companycode: parseInt(inputs.companycode, 10) },
        };
      } else if (resultClient?.data?.client?.companycode) {
        data.company = {
          disconnect: true,
        };
      }
      await updateClient({
        variables: {
          where: {
            clientcode: parseInt(clientcode, 10),
          },
          data: {
            ...data,
            TypeCode: inputs.TypeCode
              ? { set: parseInt(inputs.TypeCode, 10) }
              : null,
            DefOfficeCode: inputs.DefOfficeCode
              ? { set: parseInt(inputs.DefOfficeCode, 10) }
              : null,
            usdint1: { set: inputs.usdint1 ? 1 : 0 },
            usdint2: { set: inputs.usdint2 ? 1 : 0 },
            dateedited: { set: date },
            useridedited: { set: authState?.user?.userid },
          },
        },
        refetchQueries: [
          {
            query: SINGLE_CLIENT_QUERY,
            variables: {
              where: { clientcode: parseInt(clientcode, 10) },
            },
          },
          {
            query: LIST_CLIENTS_QUERY,
            variables: {
              orderBy: [{ lastname: "asc" }, { firstname: "asc" }],
            },
            fetchPolicy: "network-only",
          },
        ],
      });
      toast.success("Client updated successfully.");
    } catch (err) {
      toast.error("Error updating Client.");
    } finally {
      setLoading(false);
    }
  };

  const handleWebPortalSave = async (e) => {
    if (e) e.preventDefault();
    const originalClientValues = resultClient?.data?.client;

    if (
      inputs.PublishOnWeb !== originalClientValues.PublishOnWeb ||
      inputs.WebUserName !== originalClientValues?.WebUser?.UserID ||
      inputs.Webpassword !== originalClientValues?.WebUser?.Password
    ) {
      try {
        setLoading(true);
        if (!inputs.email) {
          throw new Error("Email is required");
        }

        if (inputs.PublishOnWeb) {
          const { data: webUserData } = await aClient.query({
            query: LIST_WEB_USER_QUERY,
            variables: {
              where: {
                UserID: { equals: inputs.WebUserName },
                WebUserID: {
                  not: { equals: originalClientValues?.WebUser?.WebUserID },
                },
              },
              take: 1,
            },
          });
          if (
            Array.isArray(webUserData?.webUsers) &&
            webUserData?.webUsers.length
          ) {
            throw new Error("The Web User Id already exists");
          }
        }

        const date = getDateEST();

        await updateClient({
          variables: {
            where: {
              clientcode: parseInt(clientcode, 10),
            },
            data: {
              PublishOnWeb: { set: inputs.PublishOnWeb },
            },
          },
        });

        await syncWebUserInfo({
          variables: {
            data: {
              DateAdded: date,
              UseridAdded: authState?.user?.userid,
              UserID: inputs.WebUserName,
              Password: inputs.Webpassword,
              IMECentricCode: parseInt(clientcode, 10),
            },
            where: {
              clientcode: parseInt(clientcode, 10),
            },
          },
          refetchQueries: [
            {
              query: SINGLE_CLIENT_QUERY,
              variables: {
                where: { clientcode: parseInt(clientcode, 10) },
              },
            },
          ],
        });
      } catch (err) {
        toast.error(err.message);
      } finally {
        setLoading(false);
      }
    }
  };

  if (resultClient?.loading || loading) return <Loader />;
  return (
    <div className="client-page">
      <header className="page-head">
        <div className="page-head-start">
          <Title>{`${inputs.firstname} ${inputs.lastname}`}</Title>
          <div>
            <IconLink
              href={`tel:${inputs?.phone1}`}
              icon="phone"
              label={inputs?.phone1}
            />
          </div>
          <div>
            <IconLink
              href={`mailto:${inputs?.email}`}
              icon="at"
              label={inputs?.email}
            />
          </div>
        </div>
        {hasPermission && (
          <div className="page-head-end">
            <Button.Group hasAddons>
              {clientcode !== "add" && (
                <Button
                  color="primary"
                  size="small"
                  type="button"
                  onClick={() => setEditMode(!editMode)}
                >
                  <span>Edit</span>
                </Button>
              )}
              <Button
                color="success"
                disabled={!editMode}
                form="edit-client-form"
                size="small"
                type="submit"
              >
                <span>Save</span>
              </Button>
            </Button.Group>
          </div>
        )}
      </header>
      <Tab.Group kind="boxed">
        <Tab
          active={location.pathname.includes("profile")}
          as={Link}
          to={`/clients/${clientcode}/profile`}
        >
          Profile
        </Tab>
        <Tab
          active={location.pathname.includes("defaults")}
          as={Link}
          to={`/clients/${clientcode}/defaults`}
        >
          Defaults
        </Tab>
        <Tab
          active={location.pathname.includes("cases")}
          as={Link}
          to={`/clients/${clientcode}/cases`}
        >
          Cases
        </Tab>
        <Tab
          active={location.pathname.includes("user-defined")}
          as={Link}
          to={`/clients/${clientcode}/user-defined`}
        >
          User-Defined Fields
        </Tab>
        <Tab
          active={location.pathname.includes("notes")}
          as={Link}
          to={`/clients/${clientcode}/notes`}
        >
          Notes
        </Tab>
        <Tab
          active={location.pathname.includes("billing-information")}
          as={Link}
          to={`/clients/${clientcode}/billing-information`}
        >
          Billing Information
        </Tab>
        <Tab
          active={location.pathname.includes("drs-not-to-use")}
          as={Link}
          to={`/clients/${clientcode}/drs-not-to-use`}
        >
          Drs Not to Use
        </Tab>
        <Tab
          active={location.pathname.includes("web-portal")}
          as={Link}
          to={`/clients/${clientcode}/web-portal`}
        >
          Web Portal
        </Tab>
        <Tab
          active={location.pathname.includes("exceptions")}
          as={Link}
          to={`/clients/${clientcode}/exceptions`}
        >
          Exceptions
        </Tab>
        <Tab
          active={location.pathname.includes("statistics")}
          as={Link}
          to={`/clients/${clientcode}/statistics`}
        >
          Statistics
        </Tab>
      </Tab.Group>
      <Switch>
        <Switch path="/clients/:clientcode/profile">
          <Profile
            client={inputs}
            disabled={!editMode}
            onChange={handleChange}
            onSubmit={handleSave}
          />
        </Switch>
        <Route path="/clients/:clientcode/defaults">
          <Defaults
            client={inputs}
            disabled={!editMode}
            onChange={handleChange}
            onSubmit={handleSave}
          />
        </Route>
        <Route path="/clients/:clientcode/cases">
          <Cases client={clientcode} />
        </Route>
        <Route path="/clients/:clientcode/user-defined">
          <UserDefinedFields
            client={inputs}
            disabled={!editMode}
            onChange={handleChange}
            onSubmit={handleSave}
          />
        </Route>
        <Route path="/clients/:clientcode/notes">
          <Notes
            client={inputs}
            disabled={!editMode}
            inputs={inputs}
            onChange={handleChange}
          />
        </Route>
        <Route path="/clients/:clientcode/billing-information">
          <BillingInformation
            client={inputs}
            disabled={!editMode}
            setInputs={setInputs}
            onChange={handleChange}
            onSubmit={handleSave}
          />
        </Route>
        <Route path="/clients/:clientcode/exceptions">
          <Exceptions clientcode={clientcode} />
        </Route>
        <Route path="/clients/:clientcode/drs-not-to-use">
          <DrsNotToUse />
        </Route>
        <Route path="/clients/:clientcode/web-portal">
          <WebPortal
            client={inputs}
            disabled={!editMode}
            handleSave={handleWebPortalSave}
            onChange={handleChange}
          />
        </Route>
        <Route path="/clients/:clientcode/statistics">
          <Statistics client={inputs} />
        </Route>
      </Switch>
      {/* <pre>
        <code>{JSON.stringify(inputs, null, 2)}</code>
      </pre> */}
    </div>
  );
};

ClientPage.propTypes = {};

export default ClientPage;
