import React from "react";
import PropTypes from "prop-types";

import { Box, Field, Control, Button, Label } from "rbx";
import StateSelect from "../../../../components/StateSelect";
import FilterInput from "../../../../components/FilterInput";
import BooleanInput from "../../../../components/BooleanInput";

const ClaimListFilters = ({ filters, onChange, onFilter }) => (
  <Box>
    <div className="list-filters claims">
      <Field>
        <FilterInput
          getValue={(x) => x?.equals || ""}
          label="Claim Number"
          name="ClaimNumber"
          placeholder="Claim Number"
          setValue={(x) => (x ? { equals: x } : "")}
          value={filters.ClaimNumber || ""}
          onChange={onChange}
        />
      </Field>
      <Field>
        <FilterInput
          getValue={(x) => x?.equals || ""}
          label="Company Name"
          name="CompanyName"
          placeholder="Company Name"
          setValue={(x) => (x ? { equals: x } : "")}
          value={filters.CompanyName || ""}
          onChange={onChange}
        />
      </Field>
      <Field>
        <FilterInput
          getValue={(x) => x?.equals || ""}
          label="Claimant First Name"
          name="ClaimantFirstName"
          placeholder="Claimant First Name"
          setValue={(x) => (x ? { equals: x } : "")}
          value={filters.ClaimantFirstName || ""}
          onChange={onChange}
        />
      </Field>
      <Field>
        <FilterInput
          getValue={(x) => x?.equals || ""}
          label="Claimant Last Name"
          name="ClaimantLastName"
          placeholder="Claimant Last Name"
          setValue={(x) => (x ? { equals: x } : "")}
          value={filters.ClaimantLastName || ""}
          onChange={onChange}
        />
      </Field>
      <Field>
        <FilterInput
          getValue={(x) => x?.equals || ""}
          label="City"
          name="City"
          placeholder="City"
          setValue={(x) => (x ? { equals: x } : "")}
          value={filters.City || ""}
          onChange={onChange}
        />
      </Field>
      <Field>
        <StateSelect
          getValue={(x) => x?.equals || ""}
          label="State"
          name="State"
          setValue={(x) => (x ? { equals: x } : "")}
          size="small"
          value={filters.State}
          onChange={onChange}
        />
      </Field>
      <Field>
        <Label>HCP</Label>
        <BooleanInput
          getValue={(x) => x?.equals || ""}
          name="HCP"
          setValue={(x) => (x ? { equals: x } : "")}
          value={filters.HCP}
          onChange={(name, value) => onChange(name, value)}
        />
      </Field>
      <Field>
        <Control>
          <Label>&nbsp;</Label>
          <Button color="primary" size="small" type="button" onClick={onFilter}>
            Filter
          </Button>
        </Control>
      </Field>
    </div>
  </Box>
);

ClaimListFilters.propTypes = {
  filters: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onFilter: PropTypes.func.isRequired,
};

export default ClaimListFilters;
