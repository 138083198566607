import React from "react";
import PropTypes from "prop-types";
import { Column, Control } from "rbx";

import { useParams } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import {
  UPDATE_CLIENT_MUTATION,
  SINGLE_CLIENT_QUERY,
  LIST_CLIENTS_QUERY,
} from "../../../graphql";
import { usePermissions } from "../../../hooks";
import NotesInput from "../../../components/NotesInput";

const Notes = ({ inputs, onChange, disabled, handleSave }) => {
  const isAdmin = usePermissions(["AllAdminFunc"]);
  const { clientcode } = useParams();
  const [updateClient] = useMutation(UPDATE_CLIENT_MUTATION);

  const handleSaveNote = async (note) => {
    updateClient({
      variables: {
        data: {
          notes: { set: note },
        },
        where: {
          clientcode: parseInt(clientcode, 10),
        },
      },
      refetchQueries: [
        {
          query: SINGLE_CLIENT_QUERY,
          variables: {
            where: { clientcode: parseInt(clientcode, 10) },
          },
        },
        {
          query: LIST_CLIENTS_QUERY,
          variables: {
            orderBy: [{ lastname: "asc" }, { firstname: "asc" }],
          },
          fetchPolicy: "network-only",
        },
      ],
    });
  };

  const handleChange = async (name, value) => {
    try {
      handleSaveNote(value);
      onChange(name, value);
      toast.success(`Note saved successfully.`);
    } catch (err) {
      toast.error(`Error saving note.`);
    }
  };

  return (
    <Column.Group>
      <Column>
        <Control style={{ marginBottom: "1rem" }}>
          <NotesInput
            contentEditable={isAdmin}
            disabled={disabled}
            name="notes"
            saveNotes={handleSave}
            size="small"
            value={inputs.notes}
            onChange={handleChange}
          />
        </Control>
      </Column>
    </Column.Group>
  );
};

Notes.propTypes = {
  inputs: PropTypes.object,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  handleSave: PropTypes.func,
};

Notes.defaultProps = {
  inputs: {},
  onChange: (e) => e,
  handleSave: () => null,
  disabled: false,
};

export default Notes;
