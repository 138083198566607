import { useState } from "react";

const useConditionalRecordTypes = (
  disabled,
  provider,
  handleArrChange,
  index
) => {
  const disableCheck = ["B", "DR", "F", "M", "M-MH", "M-NT"];

  const [conditionCodes, setConditionCodes] = useState({
    g: false,
    others: {
      B: false,
      DR: false,
      F: false,
      M: false,
      "M-HM": false,
      "M-NT": false,
    },
  });

  const handleConditionCodeUpdates = (recordTypeCode) => {
    const isG = recordTypeCode === "G";
    const isOthers = disableCheck.includes(recordTypeCode);

    if (isG) setConditionCodes((prev) => ({ ...prev, g: !prev.g }));
    if (isOthers)
      setConditionCodes((prev) => ({
        ...prev,
        others: {
          ...prev.others,
          [recordTypeCode]: !prev.others[recordTypeCode],
        },
      }));
  };

  const handleChange = (recordTypeID, recordTypeCode) => {
    handleConditionCodeUpdates(recordTypeCode);
    const valueExists = provider?.RecordType?.find(
      (each) => each === recordTypeID
    );

    if (valueExists) {
      const updatedTypesArray = provider?.RecordType?.filter(
        (each) => each !== recordTypeID
      );
      return handleArrChange(index, "RecordType", updatedTypesArray);
    }

    const newArray = [...provider?.RecordType, recordTypeID];
    return handleArrChange(index, "RecordType", newArray);
  };

  const shouldDisable = (recordTypeCode) => {
    if (disabled && provider?.fromDB) return true;

    const isG = recordTypeCode === "G";
    const isOthers = disableCheck.includes(recordTypeCode);

    if (isG && Object.values(conditionCodes.others).some((e) => e === true))
      return true;
    if (isOthers && conditionCodes.g) return true;

    return false;
  };

  const getIsSelected = (recordTypeId) =>
    provider?.RecordType?.find((each) => each === recordTypeId);

  const getLabel = (recordTypeName, isActive) => {
    if (recordTypeName.trim() !== "Other") return recordTypeName;

    return isActive
      ? "Other (Please Specify Below In Special Instructions)"
      : recordTypeName;
  };

  return { shouldDisable, handleChange, getIsSelected, getLabel };
};

export default useConditionalRecordTypes;
