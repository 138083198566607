import React, { useState } from "react";
import PropTypes from "prop-types";
import { Title, Fieldset, Button, Field } from "rbx";
import { useMutation } from "@apollo/client";

import RatsEmployerEntryForm from "../RatsEmployerEntryForm";

import DataTable from "../../../../components/DataTable";

import {
  RATS_EMPLOYER_QUERY,
  RATS_CREATE_EMPLOYER_MUTATION,
} from "../../../../graphql";

import {
  convertTimeZoneDataBase,
  customToast as toast,
  getDateEST,
} from "../../../../utils";

import { useReferrer } from "../../../../hooks";
import BusinessCard from "../BusinessCard";

const DEFAULT_STATE = {
  EMtaxidFEIN: "",
  EMname: "",
  EMaddress1: "",
  EMaddress2: "",
  EMcity: "",
  EMstate: "",
  EMzip: "",
  EMphone: "",
};

const fields = [
  "EMtaxidFEIN",
  "EMname",
  "EMaddress1",
  "EMaddress2",
  "EMcity",
  "EMstate",
  "EMzip",
  "EMphone",
];

const RatsEmployerEntryModal = ({
  onComplete,
  title,
  mode,
  label,
  onRowClick,
  onRatsEmployerAdd,
}) => {
  const [inputs, setInputs] = useState({ ...DEFAULT_STATE });
  const [EMWhere, setEMWhere] = useState({ AND: [] });
  const [loading, setLoading] = useState(false);

  const [createRatsEmployer] = useMutation(RATS_CREATE_EMPLOYER_MUTATION);

  const referrer = useReferrer();

  const handleChange = (name, value) => {
    setInputs((prev) => ({ ...prev, [name]: value }));
  };

  const handleReset = () => {
    setInputs({ ...DEFAULT_STATE });
    setEMWhere({ AND: [] });
  };

  const handleFilter = async () => {
    const where = { AND: [] };
    for (const input in inputs) {
      if (
        Object.prototype.hasOwnProperty.call(inputs, input) &&
        inputs[input]
      ) {
        where.AND.push({ [input]: { startsWith: inputs[input] } });
      }
    }

    if (where.AND.length) {
      setEMWhere(where);
    }
  };

  const handleAddRatsEmployer = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);

      const data = {
        EMtaxidFEIN: inputs.EMtaxidFEIN,
        EMname: inputs.EMname,
        EMaddress1: inputs.EMaddress1,
        EMaddress2: inputs.EMaddress2,
        EMcity: inputs.EMcity,
        EMstate: inputs.EMstate,
        EMzip: inputs.EMzip,
        EMphone: inputs.EMphone,
        EMenteredBy: referrer?.ClientLogin,
        EMenteredDate: convertTimeZoneDataBase(getDateEST()),
      };

      const {
        data: { createEmployer },
      } = await createRatsEmployer({ variables: { data } });

      onRatsEmployerAdd(createEmployer.PK_EMid);
    } catch (err) {
      toast.error(
        "Something went wrong, please try again later or contact support"
      );
    } finally {
      setLoading(false);
    }
  };

  const disabled =
    mode === "add" && (inputs.EMname === "" || inputs.EMaddress1 === "");

  const searchAndResetDisabled = !!(
    fields.filter((field) => inputs[field] !== "").length === 0
  );

  return (
    <React.Fragment>
      <form id="rg-form" onSubmit={handleAddRatsEmployer}>
        <header className="page-head">
          <div className="page-head-start">
            <Title size={5}>{title}</Title>
          </div>
          <div className="page-head-end">
            <Button.Group hasAddons>
              <Button
                disabled={loading}
                size="small"
                type="button"
                onClick={onComplete}
              >
                <span>Cancel</span>
              </Button>
              {mode === "add" && (
                <Button
                  color="primary"
                  disabled={disabled || loading}
                  form="rg-form"
                  size="small"
                  state={loading ? "loading" : ""}
                  type="submit"
                >
                  <span>Submit</span>
                </Button>
              )}
            </Button.Group>
          </div>
        </header>
        <hr />
        <Field>
          <Fieldset>
            <RatsEmployerEntryForm
              disabled={loading}
              inputs={inputs}
              label={label}
              mode={mode}
              onChange={handleChange}
              onFilter={handleFilter}
              onReset={handleReset}
            />
          </Fieldset>
        </Field>
      </form>
      {!!EMWhere.AND.length && (
        <React.Fragment>
          <hr />
          <DataTable
            aggregateKey="PK_EMid"
            aggregateName="aggregateEmployer"
            columns={[
              {
                Header: "Info",
                id: "businessCard",
                disableSortBy: true,
                Cell: (cell) => (
                  <BusinessCard
                    isInTable
                    data={cell.row.values}
                    disabled={!cell.row.values}
                    onSeeMoreClick={() => null}
                  />
                )
              },
              {
                Header: "FEIN Number",
                id: "EMtaxidFEIN",
                accessor: "EMtaxidFEIN",
              },
              {
                Header: "Name",
                id: "EMname",
                accessor: "EMname",
              },
              {
                Header: "Phone #",
                id: "EMphone",
                accessor: "EMphone",
              },
              {
                Header: "Address 1",
                id: "EMaddress1",
                accessor: "EMaddress1",
              },
              {
                Header: "Address 2",
                id: "EMaddress2",
                accessor: "EMaddress2",
              },
              {
                Header: "City",
                id: "EMcity",
                accessor: "EMcity",
              },
              {
                Header: "State",
                id: "EMstate",
                accessor: "EMstate",
              },
              {
                Header: "Zip",
                id: "EMzip",
                accessor: "EMzip",
              },
            ]}
            loaderHeight="293px"
            name="employers"
            orderBy={[{ id: "EMname", desc: false }]}
            pageSize={10}
            pageSizeKey="RG_PAGE_SIZE"
            query={RATS_EMPLOYER_QUERY}
            showPageSize={false}
            where={EMWhere}
            onCellClick={(cell, e) => {
              const { column: { id } } = cell;
              if (id === 'businessCard') {
                e.stopPropagation();
              }
            }}
            onRowClick={onRowClick}
          />
        </React.Fragment>
      )}
      <hr />
      {mode === "search" && (
        <Field kind="group">
          <Button.Group>
            <Button
              color="danger"
              disabled={searchAndResetDisabled}
              size="small"
              onClick={handleReset}
            >
              Reset
            </Button>
            <Button
              color="primary"
              disabled={searchAndResetDisabled}
              size="small"
              onClick={handleFilter}
            >
              Search
            </Button>
          </Button.Group>
        </Field>
      )}
    </React.Fragment>
  );
};

RatsEmployerEntryModal.propTypes = {
  onComplete: PropTypes.func,
  onRowClick: PropTypes.func,
  onRatsEmployerAdd: PropTypes.func,
  title: PropTypes.string,
  mode: PropTypes.string,
  label: PropTypes.string,
};

RatsEmployerEntryModal.defaultProps = {
  onComplete: (e) => e,
  onRowClick: () => null,
  onRatsEmployerAdd: () => null,
  title: "",
  mode: "",
  label: "",
};

export default RatsEmployerEntryModal;
