import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Title, Button, Field, Control } from "rbx";
import { useApolloClient, useMutation, useLazyQuery } from "@apollo/client";

import ProviderTypeForm from "../ProviderTypeForm";
import { useModal, useAuth } from "../../../../../context";
import {
  FIND_FIRST_DOCTOR_QUERY,
  CREATE_PROVIDER_TYPE_MUTATION,
  UPDATE_PROVIDER_TYPE_MUTATION,
  // CREATE_AUDIT_TRAIL_MUTATION,
  DELETE_PROVIDER_TYPE_MUTATION,
  LIST_PROVIDER_TYPES_QUERY,
  SINGLE_PROVIDER_TYPE_QUERY,
} from "../../../../../graphql";
import {
  customToast as toast,
  getDateEST,
  convertTimeZoneDataBase,
} from "../../../../../utils";
import Confirmation from "../../../../../components/Confirmation";

const INITIAL_STATE = {
  description: "",
};

const ProviderTypeModal = ({ onComplete, ProvTypeCode }) => {
  const client = useApolloClient();
  const { setModalOpen } = useModal();
  const { state: authState } = useAuth();
  const [inputs, setInputs] = useState(INITIAL_STATE);
  const [updated, setUpdated] = useState([]);
  const [loading, setLoading] = useState(false);

  const [
    getProviderType,
    { data: providerTypeData, loading: queryLoading },
  ] = useLazyQuery(SINGLE_PROVIDER_TYPE_QUERY);
  const [createProviderType] = useMutation(CREATE_PROVIDER_TYPE_MUTATION);
  const [updateProviderType] = useMutation(UPDATE_PROVIDER_TYPE_MUTATION);
  // const [createAuditTrail] = useMutation(CREATE_AUDIT_TRAIL_MUTATION);
  const [deleteProviderType] = useMutation(DELETE_PROVIDER_TYPE_MUTATION);

  useEffect(() => {
    if (ProvTypeCode) {
      getProviderType({
        variables: {
          where: {
            ProvTypeCode,
          },
        },
      });
    }
  }, [ProvTypeCode, getProviderType]);

  useEffect(() => {
    if (providerTypeData?.providerType) {
      setInputs({
        description: providerTypeData.providerType.description || "",
      });
    }
  }, [providerTypeData]);

  const handleChange = (name, value) => {
    setInputs((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (ProvTypeCode) {
      setUpdated((prev) => (prev.includes(name) ? prev : [...prev, name]));
    }
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      const date = convertTimeZoneDataBase(getDateEST());
      if (!ProvTypeCode) {
        await createProviderType({
          variables: {
            data: {
              ...inputs,
              useridedited: authState.user.userid,
              dateedited: date,
              useridadded: authState.user.userid,
              dateadded: date,
            },
          },
          refetchQueries: [
            {
              query: LIST_PROVIDER_TYPES_QUERY,
            },
          ],
        });
        // await createAuditTrail({
        //   variables: {
        //     data: {
        //       tablename: "tblProviderType",
        //       entityid: String(
        //         createProviderTypeData.createProviderType.ProvTypeCode
        //       ),
        //       type: "New",
        //       description: "New provider type created",
        //       useridadded: authState.user.userid,
        //       dateadded: getDateEST(),
        //     },
        //   },
        //   refetchQueries: [
        //     {
        //       query: SINGLE_PROVIDER_TYPE_QUERY,
        //       variables: {
        //         where: {
        //           ProvTypeCode:
        //             createProviderTypeData.createProviderType.ProvTypeCode,
        //         },
        //       },
        //     },
        //   ],
        // });
        setModalOpen(false);
        toast.success("Problem created successfully.");
      } else {
        await updateProviderType({
          variables: {
            data: {
              description: { set: inputs.description },
              useridedited: { set: authState.user.userid },
              dateedited: { set: date },
            },
            where: {
              ProvTypeCode,
            },
          },
          refetchQueries: [
            {
              query: LIST_PROVIDER_TYPES_QUERY,
            },
            {
              query: SINGLE_PROVIDER_TYPE_QUERY,
              variables: {
                where: {
                  ProvTypeCode,
                },
              },
            },
          ],
        });
        // await createAuditTrail({
        //   variables: {
        //     data: {
        //       tablename: "tblProviderType",
        //       entityid: String(ProvTypeCode),
        //       type: "Update",
        //       description: `Updated fields: ${updated.join(", ")}`,
        //       useridadded: authState.user.userid,
        //       dateadded: getDateEST(),
        //     },
        //   },
        // });
        setModalOpen(false);
        toast.success("Provider Type updated successfully.");
      }
    } catch (err) {
      if (err.message.includes("Unique constraint")) {
        toast.error("This code provider type already exists");
      } else {
        toast.error("Error saving Provider Type");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    const { data: doctorData } = await client.query({
      query: FIND_FIRST_DOCTOR_QUERY,
      variables: {
        where: {
          ProvTypeCode: { equals: ProvTypeCode },
        },
      },
    });
    if (doctorData?.findFirstDoctor) {
      toast.error(
        "This Provider Type can't be removed because there's some Doctors related"
      );
    } else {
      const performDelete = async () => {
        try {
          setLoading(true);
          await deleteProviderType({
            variables: {
              where: {
                ProvTypeCode,
              },
            },
            refetchQueries: [
              {
                query: LIST_PROVIDER_TYPES_QUERY,
                options: {
                  fetchPolicy: "network-only",
                },
              },
              {
                query: SINGLE_PROVIDER_TYPE_QUERY,
                variables: {
                  where: {
                    ProvTypeCode,
                  },
                },
              },
            ],
          });
          toast.success("Provider Type deleted successfully");
          onComplete();
        } catch (err) {
          toast.error("Error trying to delete Provider Type");
        } finally {
          setLoading(false);
        }
      };
      setModalOpen(
        true,
        <Confirmation
          message="Are you sure you want to delete this Provider Type?"
          onCancel={() =>
            setModalOpen(
              true,
              <ProviderTypeModal
                ProvTypeCode={ProvTypeCode}
                onComplete={onComplete}
              />
            )
          }
          onConfirm={performDelete}
        />
      );
    }
  };

  const isDisabled =
    !inputs.description ||
    (ProvTypeCode && !updated?.length) ||
    queryLoading ||
    loading;

  return (
    <form id="provider-type-form" onSubmit={handleSubmit}>
      <header className="page-head">
        <div className="page-head-start">
          <Title size={5}>
            {`${!ProvTypeCode ? "Create" : "Edit"}`}
            &nbsp;Provider Type
          </Title>
        </div>
        <div className="page-head-end">
          <Button.Group hasAddons>
            <Button
              size="small"
              type="button"
              onClick={() => onComplete(false)}
            >
              <span>Cancel</span>
            </Button>

            <Button
              color="primary"
              disabled={isDisabled}
              form="provider-type-form"
              size="small"
              state={loading ? "loading" : ""}
              type="submit"
            >
              <span>Submit</span>
            </Button>
          </Button.Group>
        </div>
      </header>
      <hr />
      <ProviderTypeForm inputs={inputs} onChange={handleChange} />
      <hr />
      {ProvTypeCode && (
        <Field>
          <Control>
            <Button
              color="danger"
              size="small"
              type="button"
              onClick={handleDelete}
            >
              <span>Delete</span>
            </Button>
          </Control>
        </Field>
      )}
    </form>
  );
};

ProviderTypeModal.propTypes = {
  onComplete: PropTypes.func,
  ProvTypeCode: PropTypes.number,
};

ProviderTypeModal.defaultProps = {
  onComplete: () => null,
  ProvTypeCode: null,
};

export default ProviderTypeModal;
