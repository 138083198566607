import React from "react";
// import PropTypes from "prop-types";
import { useParams, useHistory } from "react-router-dom";

import { LIST_DOCTOR_CASES_QUERY } from "../../../../graphql";
import DataTable from "../../../../components/DataTable";
import COLUMNS from "./columns";

const Cases = (props) => {
  const history = useHistory();

  const { doctorcode } = useParams();

  const handleRowClick = ({ casenbr }) => {
    history.push(`/cases/${casenbr}/profile`);
  };

  return (
    <div className="investigator-cases-page">
      <DataTable
        aggregateKey="casenbr"
        aggregateName="aggregateCase"
        columns={COLUMNS}
        name="cases"
        orderBy={[{ id: "Appttime", desc: true }]}
        query={LIST_DOCTOR_CASES_QUERY}
        where={{ doctorcode: { equals: parseInt(doctorcode, 10) } }}
        onRowClick={handleRowClick}
      />
    </div>
  );
};

Cases.propTypes = {};

export default Cases;
