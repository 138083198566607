import { format } from "date-fns";
import { convertTimeZone } from "../../../../utils";

const COLUMNS = [
  {
    Header: "Office",
    id: "office",
    accessor: "office",
  },
  {
    Header: "Case Type",
    id: "casetype",
    accessor: "casetype",
  },
  {
    Header: "Service",
    id: "service",
    accessor: "service",
  },
  {
    Header: "Added",
    id: "dateadded",
    accessor: "dateadded",
    Cell: ({ cell }) =>
      cell?.value
        ? format(convertTimeZone(cell.value), "MM/dd/yyyy hh:mm a")
        : "",
  },
  {
    Header: "By",
    id: "useridadded",
    accessor: "useridadded",
  },
  {
    Header: "Edited",
    id: "dateedited",
    accessor: "dateedited",
    Cell: ({ cell }) =>
      cell?.value
        ? format(convertTimeZone(cell.value), "MM/dd/yyyy hh:mm a")
        : "",
  },
  {
    Header: "By",
    id: "useridedited",
    accessor: "useridedited",
  },
];

export default COLUMNS;
