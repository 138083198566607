import React, { useState, useContext, useMemo, useCallback } from "react";
import { Field, Control, Input, Button, Title, Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../../context/AuthContext";
import { ModalContext } from "../../../context/ModalContext";
import DataTable from "../../../components/DataTable";

import { CaseListFilters, CaseMarkAwaitingApptModal } from "../components";
import generateColumns from "./columns";

import {
  debounce,
  getFilterState,
  saveFilterState,
  customToast as toast,
  getDateEST,
  convertTimeZoneDataBase,
  openInNewTab,
  isWindows,
  isMacintosh,
} from "../../../utils";

import {
  LIST_CASE_CLIENT_QUERY,
  BATCH_AWAITING_APPT_UPDATE_MUTATION,
} from "../../../graphql";

const INITIAL_FILTER_STATE = {
  status: { equals: 1 },
};

const CaseListAwaitingAppt = (props) => {
  const history = useHistory();
  const { state: authState } = useAuth();
  const { setModalOpen } = useContext(ModalContext);
  const [where, setWhere] = useState(
    getFilterState("CASE_LIST_AWAITING_APPT_FILTERS", INITIAL_FILTER_STATE)
  );
  const [showFilters, setShowFilters] = useState(false);

  const [batchAwaitingApptUpdate] = useMutation(
    BATCH_AWAITING_APPT_UPDATE_MUTATION
  );

  const handleReset = () => {
    saveFilterState("CASE_LIST_AWAITING_APPT_FILTERS", INITIAL_FILTER_STATE);
    setWhere(INITIAL_FILTER_STATE);
  };

  const handleChange = (name, value) => {
    if (!value) {
      setWhere((prev) =>
        Object.keys(prev).reduce((acc, curr) => {
          if (curr !== name) acc[curr] = prev[curr];
          return acc;
        }, {})
      );
    } else {
      setWhere((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleUpdateCase = useCallback(
    async (casenbr, action) => {
      try {
        const date = convertTimeZoneDataBase(getDateEST());

        await batchAwaitingApptUpdate({
          variables: {
            input: {
              selected: [casenbr],
              userid: authState?.user?.userid,
              date,
              action,
            },
          },
          refetchQueries: [
            {
              query: LIST_CASE_CLIENT_QUERY,
              variables: {
                where,
              },
            },
          ],
          awaitRefetchQueries: true,
        });

        toast.success("Case updated successfully!");
        setModalOpen(false);
      } catch (err) {
        toast.error("Error updating case. Please contact support.");
      }
    },

    [batchAwaitingApptUpdate, authState?.user?.userid, where, setModalOpen]
  );
  const [selected, setSelected] = useState([]);

  const handleUpdateCaseWithConfirmation = useCallback((casenbr, action) => {
    setSelected((prev) => {
      const curr = [...prev];
      const idx = curr.findIndex((item) => item.casenbr === casenbr);
      if (idx > -1 && curr[idx].type === action) {
        curr.splice(idx, 1);
      } else if (idx > -1 && curr[idx].type !== action) {
        curr.splice(idx, 1, { casenbr, type: action });
      } else {
        curr.push({ casenbr, type: action });
      }
      return curr;
    });
  }, []);

  const handleSelect = useCallback((casenbr) => {
    setSelected((prev) => {
      const curr = [...prev];
      const idx = curr.findIndex((item) => item.casenbr === casenbr);
      if (idx > -1) {
        curr.splice(idx, 1);
      } else {
        curr.push({ casenbr, type: "show" });
      }
      return curr;
    });
  }, []);

  const handleSelectAll = useCallback((allSelected, casenbrs) => {
    setSelected((prev) => {
      const curr = [...prev];
      casenbrs.forEach((casenbr) => {
        const idx = curr.findIndex((item) => item.casenbr === casenbr);
        if (allSelected) {
          if (idx > -1) {
            curr.splice(idx, 1);
          }
        } else if (idx < 0) {
          curr.push({ casenbr, type: "show" });
        }
      });
      return curr;
    });
  }, []);

  const CASE_CLIENT_COLUMNS = useMemo(
    () =>
      generateColumns({
        selected,
        onSelect: handleSelect,
        onSelectAll: handleSelectAll,
        onUpdate: handleUpdateCaseWithConfirmation,
      }),
    [handleUpdateCaseWithConfirmation, handleSelect, handleSelectAll, selected]
  );

  const handleCellClick = ({ column, row }, event) => {
    if (column.id !== "selection") {
      const { casenbr } = row.original;

      const newPath = `/cases/${casenbr}/profile`;
      // simulate normal browser behavior
      if (
        (isMacintosh() && event?.metaKey) ||
        (isWindows() && event?.ctrlKey)
      ) {
        return openInNewTab(newPath);
      }
      return history.push(`/cases/${casenbr}/profile`);
    }
    return undefined;
  };

  const handleBatchUpdate = () => {
    setModalOpen(
      true,
      <CaseMarkAwaitingApptModal
        handleUpdateCase={handleUpdateCase}
        selected={selected}
        onCancel={() => setModalOpen(false)}
        onComplete={() => {
          setSelected([]);
          setModalOpen(false, "");
        }}
      />
    );
  };

  const handleClearSelected = () => {
    setSelected([]);
  };

  return (
    <div>
      <div className="section-header" style={{ width: "100%" }}>
        <Title style={{ marginRight: 15 }}>Awaiting Appointment</Title>
        <Field kind="group" style={{ paddingTop: "0.3rem" }}>
          <Control>
            <Input
              autoComplete="new"
              placeholder="Find by Case #"
              size="small"
              onChange={debounce(({ target: { value } }) => {
                setWhere((prev) =>
                  value
                    ? { ...prev, casenbr: { equals: parseInt(value, 10) } }
                    : Object.keys(prev)
                        .filter((x) => x !== "casenbr")
                        .reduce((a, c) => ({ ...a, [c]: prev[c] }), {})
                );
              }, 500)}
            />
          </Control>
          <Control>
            <Button
              color="warning"
              size="small"
              onClick={() => setShowFilters((prev) => !prev)}
            >
              <Icon>
                <FontAwesomeIcon icon="filter" />
              </Icon>
              <span>Filters</span>
            </Button>
          </Control>
        </Field>
        {!!selected.length && (
          <Field
            className="align-right"
            kind="group"
            style={{ paddingTop: "0.3rem" }}
          >
            <Control>
              <Button
                color="info"
                disabled={!selected.length}
                size="small"
                onClick={handleClearSelected}
              >
                <Icon>
                  <FontAwesomeIcon icon="times" />
                </Icon>
                <span>Clear Selected</span>
              </Button>
            </Control>

            <Control>
              <Button
                color="primary"
                disabled={!selected.length}
                size="small"
                onClick={handleBatchUpdate}
              >
                <Icon>
                  <FontAwesomeIcon icon="exchange-alt" />
                </Icon>
                <span>{`Update ${selected.length} Cases`}</span>
              </Button>
            </Control>
          </Field>
        )}
      </div>
      {showFilters && (
        <CaseListFilters
          disableStatusFilter
          filters={where}
          show={showFilters}
          onChange={handleChange}
          onReset={handleReset}
        />
      )}
      <DataTable
        aggregateKey="casenbr"
        aggregateName="aggregateCase"
        columns={CASE_CLIENT_COLUMNS}
        name="cases"
        orderBy={[{ id: "casenbr", desc: true }]}
        query={LIST_CASE_CLIENT_QUERY}
        where={where}
        onCellClick={handleCellClick}
      />
    </div>
  );
};

CaseListAwaitingAppt.propTypes = {};

export default CaseListAwaitingAppt;
