import React from "react";
import PropTypes from "prop-types";
import { Field, Control, Label, Textarea, Box } from "rbx";
import ServiceMultiSelect from "../../../../components/ServiceMultiSelect";
import SpecialtySelect from "../../../../components/SpecialtySelect";
import ReferralStyledSubtitle from "../ReferralStyledSubtitle";

const ServicesRequested = ({ product, inputs, onChange }) => {
  if (!product) return null;

  const isInvestigations = product === "Investigations";
  const isMedicalManagement = product === "Medical Management";

  return (
    <Box>
      <ReferralStyledSubtitle>Services Requested</ReferralStyledSubtitle>
      <Field>
        {isInvestigations && (
          <ServiceMultiSelect
            fullWidth
            required
            label="Investigative Services"
            name="ServicesRequested"
            value={inputs.ServicesRequested}
            where={{
              PublishOnWeb: {
                equals: true,
              },
              status: {
                equals: "Active",
              },
              LOB: {
                equals: 1,
              },
            }}
            onChange={(e) => onChange("ServicesRequested", e)}
          />
        )}
        {isMedicalManagement && (
          <ServiceMultiSelect
            fullWidth
            required
            label="Medical Management"
            name="ServicesRequested"
            value={inputs.ServicesRequested}
            where={{
              PublishOnWeb: {
                equals: true,
              },
              status: {
                equals: "Active",
              },
              LOB: {
                equals: 2,
              },
            }}
            onChange={(e) => onChange("ServicesRequested", e)}
          />
        )}
        {isMedicalManagement && (
          <React.Fragment>
            <Field style={{ marginTop: ".75rem" }}>
              <SpecialtySelect
                fullWidth
                label="Requested Specialty"
                name="RequestedSpecialty"
                value={inputs.RequestedSpecialty}
                onChange={(name, value) => onChange(name, value)}
              />
            </Field>
            {/* {(isGeicoMarltonOffice && /^medical management$/gi.test(inputs?.product)) && (
              <Field kind="group" style={{ alignItems: "flex-end" }}>
                <DoctorSelect
                  showOnlyActives
                  label="Requested Doctor"
                  name="doctorcode"
                  required={/^medical management$/gi.test(inputs?.product) && isGeicoMarltonOffice && !doctorNotFound}
                  value={inputs.doctorcode}
                  onChange={onChange}
                />
                <BooleanInput
                  label="Doctor Not Found"
                  value={doctorNotFound}
                  onChange={() => setDoctorNotFound(!doctorNotFound)}
                />
              </Field>
            )} */}
          </React.Fragment>
        )}
      </Field>
      <Field kind="group">
        <Control expanded>
          <Label>
            {isMedicalManagement
              ? "Doctor Specific Instructions"
              : "Case Specific Instructions"}
          </Label>
          <Textarea
            name="ServiceInstructions"
            size="small"
            value={inputs.ServiceInstructions}
            onChange={(e) => onChange("ServiceInstructions", e.target.value)}
          />
        </Control>
      </Field>
    </Box>
  );
};
ServicesRequested.propTypes = {
  product: PropTypes.string,
  inputs: PropTypes.object,
  onChange: PropTypes.func,
};

ServicesRequested.defaultProps = {
  product: "",
  inputs: {
    InvestigativeServices: [],
    MedicalManagement: [],
    MedicareComplicance: [],
  },
  onChange: () => null,
};

export default ServicesRequested;
