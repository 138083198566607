import React, { useState } from "react";
import PropTypes from "prop-types";
import { Dropdown, Input } from "rbx";
import { useClientCodeClient, useGetJohnTropList } from "../../../../hooks";
import ClickCapture from "../../../../components/ClickCapture";
import allstateDefaultEmails from "./allstateDefaultEmails";

const AllstateAdjusterEmailSelect = ({ onChange, inputs, name, filterBy }) => {
  const [open, setOpen] = useState(false);
  const emails = name === "Email" ? allstateDefaultEmails : [];

  const { data } = useClientCodeClient();

  const { mappedFilteredList } = useGetJohnTropList(
    data?.findFirstClient?.company?.intname,
    filterBy,
    inputs?.[name]
  );

  const handleContactClick = (c) => {
    onChange("Email", c?.email);
    onChange("FirstName", c?.firstname);
    onChange("LastName", c?.lastname);
    onChange("Phone", c?.phone1);
    onChange("Company", c?.extname);
    onChange("Office", c?.intname);
  };

  const nameList = (key) =>
    mappedFilteredList[key].map((c) => (
      <Dropdown.Item key={c?.clientcode} onClick={() => handleContactClick(c)}>
        {`${c.firstname} ${c.lastname} - ${c.email}`}
      </Dropdown.Item>
    ));

  const johnTropItems = Object.keys(mappedFilteredList).map((key, index) => (
    <React.Fragment>
      {index > 0 ? <Dropdown.Divider /> : null}
      <Dropdown.Item disabled key={key}>
        <b>{key}</b>
      </Dropdown.Item>
      {nameList(key)}
    </React.Fragment>
  ));

  const allstateEmails = emails.map((e) => (
    <Dropdown.Item key={e} onClick={() => handleContactClick(e)}>
      {e.email}
    </Dropdown.Item>
  ));

  return (
    <ClickCapture>
      <Dropdown managed active={open} color="primary" style={{ width: "100%" }}>
        <Dropdown.Trigger style={{ flex: 1 }}>
          <Input
            autoComplete="new"
            name={name}
            size="small"
            tabIndex="0"
            value={inputs?.[name]}
            onBlur={() => {
              setTimeout(() => setOpen(false), 100);
            }}
            onChange={(e) => {
              onChange(e.target.name, e.target.value);
            }}
            onFocus={() => setOpen(true)}
          />
        </Dropdown.Trigger>
        {open && (
          <Dropdown.Menu style={{ flex: 1 }}>
            <Dropdown.Content>
              {emails?.length ? allstateEmails : null}
              {Object.keys(mappedFilteredList)?.length ? johnTropItems : null}
            </Dropdown.Content>
          </Dropdown.Menu>
        )}
      </Dropdown>
    </ClickCapture>
  );
};

AllstateAdjusterEmailSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  inputs: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  filterBy: PropTypes.string.isRequired,
};

export default AllstateAdjusterEmailSelect;
