import React from "react";
import PropTypes from "prop-types";

// components
import { Field, Control, Input, Label, Column } from "rbx";

// internal-components
import PhoneNumberInput from "../PhoneNumberInput";
import PrefixSelect from "../PrefixSelect";
import StateSelect from "../StateSelect";
import ZipInput from "../ZipInput";
import CCAddressSelect from "../CCAddressSelect";

const CCAddressForm = ({
  cccode,
  inputs,
  disabled,
  onChange,
  allowCCAddressSelect,
}) => (
  <div className="doctor-form">
    <Column.Group>
      {(cccode || allowCCAddressSelect) && (
        <Column size={6}>
          <CCAddressSelect
            disabled={!allowCCAddressSelect}
            label="Search for existing CC"
            name="cccode"
            value={cccode}
            onChange={onChange}
          />
        </Column>
      )}
      <Column size={6}>
        {/* PREFIX / NAME / CREDENTIALS */}
        <Field kind="group">
          <Control>
            <PrefixSelect
              disabled={disabled}
              id="prefix"
              label="Prefix"
              name="prefix"
              size="small"
              value={inputs.prefix}
              onChange={onChange}
            />
          </Control>
          <Control expanded>
            <Label>First Name</Label>
            <Input
              autoComplete="new"
              disabled={disabled}
              maxLength={50}
              name="firstname"
              placeholder="Firstname"
              size="small"
              value={inputs.firstname}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </Control>
          <Control expanded>
            <Label>Last Name</Label>
            <Input
              autoComplete="new"
              disabled={disabled}
              maxLength={50}
              name="lastname"
              placeholder="Lastname"
              size="small"
              value={inputs.lastname}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </Control>
        </Field>
      </Column>
    </Column.Group>
    <Column.Group>
      <Column>
        <Field>
          <Control expanded>
            <Label>Company Name</Label>
            <Input
              autoComplete="new"
              disabled={disabled}
              maxLength={70}
              name="company"
              placeholder="Company"
              size="small"
              value={inputs.company}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </Control>
        </Field>
        {/* ADDRESS 1 / ADDRESS 2 */}
        <Field kind="group">
          <Control expanded>
            <Label>Address 1</Label>
            <Input
              autoComplete="new"
              disabled={disabled}
              maxLength={50}
              name="address1"
              placeholder="Address 1"
              size="small"
              value={inputs.address1}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </Control>
          <Control expanded>
            <Label>Address 2</Label>
            <Input
              autoComplete="new"
              disabled={disabled}
              maxLength={50}
              name="address2"
              placeholder="Address 2"
              size="small"
              value={inputs.address2}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </Control>
        </Field>
        {/* CITY / STATE / ZIP / COUNTRY */}
        <Field kind="group">
          <Control expanded>
            <Label>City</Label>
            <Input
              autoComplete="new"
              disabled={disabled}
              maxLength={50}
              name="city"
              placeholder="City"
              size="small"
              value={inputs.city}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </Control>
          <Control>
            <StateSelect
              disabled={disabled}
              id="state"
              label="State"
              name="state"
              size="small"
              value={inputs.state}
              onChange={onChange}
            />
          </Control>
          <ZipInput
            disabled={disabled}
            id="zip"
            name="zip"
            state={inputs.state}
            value={inputs.zip || ""}
            onChange={onChange}
          />
        </Field>
      </Column>
      <Column>
        <Field kind="group">
          <Control expanded>
            <Label>Phone</Label>
            <PhoneNumberInput
              disabled={disabled}
              name="phone"
              placeholder="Phone"
              value={inputs.phone}
              onChange={onChange}
            />
          </Control>
          <Control>
            <Label>Ext</Label>
            <Input
              autoComplete="new"
              disabled={disabled}
              maxLength={14}
              name="phoneextension"
              placeholder="Ext"
              size="small"
              value={inputs.phoneextension}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </Control>
        </Field>
        <Field kind="group">
          <Control expanded>
            <Label>Fax</Label>
            <PhoneNumberInput
              disabled={disabled}
              name="fax"
              placeholder="Fax"
              value={inputs.fax}
              onChange={onChange}
            />
          </Control>
        </Field>
        <Field kind="group">
          <Control expanded>
            <Label>Email</Label>
            <Input
              autoComplete="new"
              disabled={disabled}
              maxLength={70}
              name="email"
              placeholder="Email"
              size="small"
              type="email"
              value={inputs.email}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </Control>
        </Field>
      </Column>
    </Column.Group>
  </div>
);
CCAddressForm.propTypes = {
  inputs: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  cccode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  allowCCAddressSelect: PropTypes.bool,
};

CCAddressForm.defaultProps = {
  disabled: false,
  cccode: "",
  allowCCAddressSelect: false,
};

export default CCAddressForm;
