import { gql } from "@apollo/client";

export const CHECK_AUTH_QUERY = gql`
  query checkAuth {
    checkAuth {
      userid
      resetPassword
      usertype
    }
  }
`;

export const GET_USER_SECURITIES = gql`
  query userSecurities(
    $where: UserSecurityWhereInput
    $distinct: [UserSecurityScalarFieldEnum!]
  ) {
    userSecurities(where: $where, distinct: $distinct) {
      groupcode
    }
  }
`;

export const ALL_USER_GROUP_FUNCTIONS_QUERY = gql`
  query userFunctions($where: UserGroupFunctionWhereInput) {
    userGroupFunctions(where: $where) {
      usergroupfunctionid
      entitytype
      entityid
      functioncode
    }
  }
`;
