import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Title, Button } from "rbx";
import { useApolloClient, useMutation, useLazyQuery } from "@apollo/client";
import { useModal, useAuth } from "../../../../../context";
import {
  FIND_FIRST_CASE_ITEM_QUERY,
  CREATE_CASE_TYPE_MUTATION,
  UPDATE_CASE_TYPE_MUTATION,
  // CREATE_AUDIT_TRAIL_MUTATION,
  DELETE_CASE_TYPE_MUTATION,
  LIST_CASE_TYPES_QUERY,
  SINGLE_CASE_TYPE_QUERY,
} from "../../../../../graphql";
import Confirmation from "../../../../../components/Confirmation";
import {
  customToast as toast,
  getDateEST,
  convertTimeZoneDataBase,
} from "../../../../../utils";
import CaseTypeForm from "../CaseTypeForm";

const INITIAL_STATE = {
  description: "",
  status: "Active",
  ShortDesc: "",
  officecode: "",
};

class CustomCustomTypeError extends Error {}

const CaseTypeModal = ({ onComplete, code }) => {
  const client = useApolloClient();
  const { setModalOpen } = useModal();
  const { state: authState } = useAuth();
  const [inputs, setInputs] = useState(INITIAL_STATE);
  const [loading, setLoading] = useState(false);
  const [updated, setUpdated] = useState([]);

  const [
    getCaseType,
    { data: caseTypeData, loading: queryLoading },
  ] = useLazyQuery(SINGLE_CASE_TYPE_QUERY);
  const [createCaseType] = useMutation(CREATE_CASE_TYPE_MUTATION);
  const [updateCaseType] = useMutation(UPDATE_CASE_TYPE_MUTATION);
  // const [createAuditTrail] = useMutation(CREATE_AUDIT_TRAIL_MUTATION);
  const [deleteCaseType] = useMutation(DELETE_CASE_TYPE_MUTATION);

  useEffect(() => {
    if (code) {
      getCaseType({
        variables: {
          where: { code },
        },
      });
    }
  }, [code, getCaseType]);

  useEffect(() => {
    if (caseTypeData?.caseType) {
      setInputs({
        description: caseTypeData.caseType.description || "",
        status: caseTypeData.caseType.status || "Active",
        ShortDesc: caseTypeData.caseType.ShortDesc || "",
        officecode: caseTypeData.caseType.officecode || "",
      });
    }
  }, [caseTypeData]);

  const handleChange = (name, value) => {
    setInputs((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (code) {
      setUpdated((prev) => (prev.includes(name) ? prev : [...prev, name]));
    }
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      const date = convertTimeZoneDataBase(getDateEST());
      if (!code) {
        const dataToSend = { ...inputs };
        if (dataToSend.officecode) {
          dataToSend.Office = {
            connect: {
              officecode: parseInt(inputs.officecode, 10),
            },
          };
        }
        delete dataToSend.officecode;
        await createCaseType({
          variables: {
            data: {
              ...dataToSend,
              useridedited: authState.user.userid,
              dateedited: date,
              useridadded: authState.user.userid,
              dateadded: date,
            },
          },
          refetchQueries: [
            {
              query: LIST_CASE_TYPES_QUERY,
            },
          ],
        });
        // await createAuditTrail({
        //   variables: {
        //     data: {
        //       tablename: "tblCaseType",
        //       entityid: createCaseTypeData.createCaseType.code.toString(),
        //       type: "New",
        //       description: "New Case Type created",
        //       useridadded: authState.user.userid,
        //       dateadded: getDateEST(),
        //     },
        //   },
        //   refetchQueries: [
        //     {
        //       query: SINGLE_CASE_TYPE_QUERY,
        //       variables: {
        //         where: { code: createCaseTypeData.createCaseType.code },
        //       },
        //     },
        //   ],
        // });
        setModalOpen(false);
        toast.success("Case Type created successfully.");
      } else {
        const dataToSend = {};
        if (inputs.officecode) {
          dataToSend.Office = {
            connect: {
              officecode: parseInt(inputs.officecode, 10),
            },
          };
        }
        await updateCaseType({
          variables: {
            data: {
              ...dataToSend,
              ShortDesc: { set: inputs.ShortDesc },
              description: { set: inputs.description },
              useridedited: { set: authState.user.userid },
              dateedited: { set: date },
            },
            where: {
              code,
            },
          },
          refetchQueries: [
            {
              query: LIST_CASE_TYPES_QUERY,
            },
            {
              query: SINGLE_CASE_TYPE_QUERY,
              variables: {
                where: { code },
              },
            },
          ],
        });
        // await createAuditTrail({
        //   variables: {
        //     data: {
        //       tablename: "tblCaseType",
        //       entityid: code.toString(),
        //       type: "Update",
        //       description: `Updated fields: ${updated.join(", ")}`,
        //       useridadded: authState.user.userid,
        //       dateadded: getDateEST(),
        //     },
        //   },
        // });
        setModalOpen(false);
        toast.success("Case Type updated successfully.");
      }
      onComplete();
    } catch (err) {
      toast.error("Error saving Case Type");
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (e) => {
    e.preventDefault();

    const performDelete = async () => {
      try {
        setLoading(true);
        const { data: CaseItemData } = await client.query({
          query: FIND_FIRST_CASE_ITEM_QUERY,
          variables: {
            where: {
              casetype: { equals: parseInt(code, 10) },
            },
          },
          fetchPolicy: "network-only",
        });
        if (CaseItemData?.findFirstCaseItem) {
          throw new CustomCustomTypeError(
            "This Case Type is associated with a Case and cannot be removed."
          );
        }
        await deleteCaseType({
          variables: {
            where: {
              code,
            },
          },
          refetchQueries: [
            {
              query: LIST_CASE_TYPES_QUERY,
            },
            {
              query: SINGLE_CASE_TYPE_QUERY,
              variables: {
                where: { code },
              },
            },
          ],
        });
        toast.success("Case Type deleted successfully");
        onComplete();
      } catch (err) {
        const message =
          err instanceof CustomCustomTypeError
            ? err.message
            : "Error trying to delete Case Type";
        toast.error(message);
      } finally {
        setLoading(false);
      }
    };
    setModalOpen(
      true,
      <Confirmation
        message="Are you sure you want to delete this Case Type?"
        onCancel={() =>
          setModalOpen(
            true,
            <CaseTypeModal code={code} onComplete={onComplete} />
          )
        }
        onConfirm={performDelete}
      />
    );
  };

  const isDisabled =
    !inputs.ShortDesc || (code && !updated.length) || queryLoading || loading;

  return (
    <form id="case-type-form" onSubmit={handleSubmit}>
      <header className="page-head">
        <div className="page-head-start">
          <Title size={5}>
            {`${!code ? "Create" : "Edit"}`}
            &nbsp;Case Type
          </Title>
        </div>
        <div className="page-head-end">
          <Button.Group hasAddons>
            <Button
              size="small"
              type="button"
              onClick={() => onComplete(false)}
            >
              <span>Cancel</span>
            </Button>
            <Button
              color="primary"
              disabled={isDisabled}
              form="case-type-form"
              size="small"
              state={loading ? "loading" : ""}
              type="submit"
            >
              <span>Submit</span>
            </Button>
          </Button.Group>
        </div>
      </header>
      <hr />
      <CaseTypeForm inputs={inputs} onChange={handleChange} />
      <hr />
      {code && (
        <Button
          color="danger"
          disabled={loading}
          size="small"
          type="button"
          onClick={handleDelete}
        >
          <span>Delete</span>
        </Button>
      )}
    </form>
  );
};

CaseTypeModal.propTypes = {
  onComplete: PropTypes.func,
  code: PropTypes.number,
};

CaseTypeModal.defaultProps = {
  onComplete: () => null,
  code: null,
};

export default CaseTypeModal;
