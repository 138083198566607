import { format } from "date-fns";

import { convertTimeZone } from "../../../../utils";

const mapUserType = {
  "*": "All",
  MK: "Marketer",
  QA: "QA Rep",
  SC: "Scheduler",
  TM: "Terminated",
};

const COLUMNS = [
  {
    Header: "User ID",
    id: "userid",
    accessor: "userid",
  },
  {
    Header: "First Name",
    id: "firstname",
    accessor: "firstname",
  },
  {
    Header: "Last Name",
    id: "lastname",
    accessor: "lastname",
  },
  {
    Header: "Email",
    id: "email",
    accessor: "email",
  },
  {
    Header: "User Type",
    id: "usertype",
    accessor: "usertype",
    Cell: ({ cell }) => (cell?.value ? mapUserType[cell.value] || "--" : "--"),
  },
  {
    Header: "Added",
    id: "dateadded",
    accessor: "dateadded",
    Cell: ({ cell }) =>
      cell?.value
        ? format(convertTimeZone(cell.value), "MM/dd/yyyy hh:mm a")
        : "",
  },
  {
    Header: "By",
    id: "useridadded",
    accessor: "useridadded",
    Cell: ({ cell }) => (cell?.value ? cell.value : ""),
  },
  {
    Header: "Edited",
    id: "dateedited",
    accessor: "dateedited",
    Cell: ({ cell }) =>
      cell?.value
        ? format(convertTimeZone(cell.value), "MM/dd/yyyy hh:mm a")
        : "",
  },
  {
    Header: "By",
    id: "useridedited",
    accessor: "useridedited",
    Cell: ({ cell }) => (cell?.value ? cell.value : ""),
  },
];

export default COLUMNS;
