import { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import useQueryParams from "./useQueryParams";
import usePortalPerson from "./usePortalPerson";

import {
  FIND_FIRST_CLIENT_QUERY,
  FIND_FIRST_LSI_CLIENT_QUERY,
} from "../graphql";

import { parseBase64 } from "../utils";

export default function useReferrer(product) {
  const [state, setState] = useState(null);
  // const [email] = useQueryParams("email");
  const [referrer] = useQueryParams("referrer");
  const [clientCodeFromUrl] = useQueryParams("clientcode");
  const [CLidFromUrl] = useQueryParams("clid");
  const DEFAULT_CLIENT_CODE = 19705; // WebReferral  (Default)
  const { portalPerson } = usePortalPerson();

  const [getClient, { data: ClientData }] = useLazyQuery(
    FIND_FIRST_CLIENT_QUERY
  );

  const [getLsiClient, { data: LsiClientData }] = useLazyQuery(
    FIND_FIRST_LSI_CLIENT_QUERY
  );

  const lsiClient = LsiClientData?.findFirstLsiClient;
  const _clientcode = parseInt(parseBase64(clientCodeFromUrl), 10); // convert from URL params and parse as number
  const _CLid = parseInt(parseBase64(CLidFromUrl), 10); // convert from URL params and parse as number

  useEffect(() => {
    const _referrer = parseBase64(referrer);

    if (/^myisg|myclient$/gi.test(_referrer) && _clientcode) {
      getClient({
        variables: {
          where: {
            clientcode: { equals: _clientcode },
          },
        },
        fetchPolicy: "network-only",
      });
    }

    if ((/^records$/gi.test(product) || /^clink$/gi.test(_referrer)) && _CLid) {
      getLsiClient({
        variables: {
          where: {
            CLid: { equals: _CLid },
          },
        },
        fetchPolicy: "network-only",
      });
    }
  }, [_CLid, _clientcode, getClient, getLsiClient, referrer, product]);

  const client = ClientData?.findFirstClient;

  useEffect(() => {
    const _referrer = parseBase64(referrer);
    if (
      lsiClient &&
      !lsiClient?.CLemail &&
      /^clink$/gi.test(_referrer) &&
      (_clientcode || _CLid)
    ) {
      getClient({
        variables: {
          where: {
            clientcode: {
              equals: 19705,
            },
          },
        },
        fetchPolicy: "network-only",
      });
    }
  }, [referrer, lsiClient, getClient, _clientcode, _CLid, product]);

  useEffect(() => {
    const _referrer = parseBase64(referrer);
    if (
      !/^clink$/gi.test(_referrer) &&
      client &&
      client.clientcode !== DEFAULT_CLIENT_CODE &&
      /^medical management|investigations$/gi.test(product)
    ) {
      setState({
        FirstName: client.firstname,
        LastName: client.lastname,
        CompanyName: client.company?.intname,
        City: client.city,
        State: client.state,
        Phone: client.phone1,
        Email: client.email,
        marketercode: client.marketercode,
        clientcode:
          typeof _clientcode === "number" && !isNaN(_clientcode)
            ? _clientcode
            : client.clientcode,
      });
    } else if (!/^clink$/gi.test(_referrer) && client) {
      setState({
        marketercode: client.marketercode,
        clientcode:
          typeof _clientcode === "number" && !isNaN(_clientcode)
            ? _clientcode
            : client.clientcode,
      });
    } else if (
      (/^records$/gi.test(product) || /^clink$/gi.test(_referrer)) &&
      lsiClient
    ) {
      setState({
        FirstName: lsiClient.CLfname,
        LastName: lsiClient.CLlname,
        CompanyName: lsiClient.CLcompany,
        City: lsiClient.CLcity,
        State: lsiClient.CLstate,
        Phone: lsiClient.CLphone,
        Email: lsiClient.CLemail,
        CLnwOption: lsiClient.CLnwOption,
        CLbsOption: lsiClient.CLbsOption,
        ClientLogin: lsiClient.CLlogin,
        ClientID: lsiClient.CLid,
        CLUSAARequired: lsiClient.CLUSAARequired,
        CLSentryOption: lsiClient.CLSentryOption,
        clientcode: parseInt(_CLid, 10),
      });
    }
  }, [client, _clientcode, _CLid, lsiClient, referrer, product]);

  useEffect(() => {
    if (portalPerson) {
      setState({
        FirstName: portalPerson?.FirstName,
        LastName: portalPerson?.LastName,
        CompanyName: portalPerson?.CompanyName, // TODO make sure this will be okay
        City: portalPerson?.City,
        State: portalPerson?.StateCode,
        Phone: portalPerson?.Phone,
        Email: portalPerson?.EmailAddress,
        CLnwOption: portalPerson?.IsNationwide,
        CLbsOption: portalPerson?.IsBroadspire,
        ClientLogin: portalPerson?.EmailAddress,
        ClientID: null,
        CLUSAARequired: portalPerson?.IsUSAA,
        CLSentryOption: portalPerson?.IsSentry,
        IsUSAALegal: portalPerson?.IsUSAALegal,
        clientcode: portalPerson?.ClientCode,
        marketercode: portalPerson?.marketercode,
        PersonID: portalPerson?.PersonID,
      });
    }
  }, [portalPerson]);

  return state;
}
