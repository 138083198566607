import React from "react";
import PropTypes from "prop-types";
import { Control, Field, Box, Button, Icon, Label, Input } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ReferralStyledSubtitle from "../ReferralStyledSubtitle";
import StyledFormSectionHeader from "../StyledFormSectionHeader";
import RequestGenerator from "../RequestGenerator";

const CounselForm = ({
  handleArrChange,
  inputs,
  handleAddCounsel,
  handleDeleteCounsel,
  disabled,
}) => {
  let displayIndex = 0;
  return (
    <React.Fragment>
      {!inputs.Counsels.filter((counsel) => !counsel.deleted).length && (
        <Box className="col-span">
          <StyledFormSectionHeader>
            <Field>
              <ReferralStyledSubtitle>
                Additional Counsel
              </ReferralStyledSubtitle>
            </Field>
            <Field>
              <Control>
                <Button
                  color="primary"
                  size="small"
                  type="button"
                  onClick={handleAddCounsel}
                >
                  <Icon>
                    <FontAwesomeIcon icon="plus" />
                  </Icon>
                  <span>Add Counsel</span>
                </Button>
              </Control>
            </Field>
          </StyledFormSectionHeader>
        </Box>
      )}
      {inputs.Counsels.map((counsel, index, arr) => {
        if (!counsel.deleted) {
          displayIndex += 1;
        }
        return (
          !counsel.deleted && (
            <Box className="col-span" key={counsel.CounselID}>
              <ReferralStyledSubtitle>
                {`Additional Counsel ${displayIndex}`}
              </ReferralStyledSubtitle>
              <Field className="grid-override" id="additional-counsel-form" kind="group">
                <RequestGenerator
                  required
                  disabled={!!(disabled && counsel.fromDB)}
                  handleChange={(name, value) =>
                    handleArrChange(index, name, value)
                  }
                  inputs={inputs.Counsels[index]}
                  label="Additional Counsel"
                  type="AdditionalCounsel"
                />
                <Control expanded>
                  <Label>Representing</Label>
                  <Input
                    required
                    disabled={disabled && counsel.fromDB}
                    name="Representing"
                    size="small"
                    value={inputs.Counsels[index].Representing}
                    onChange={(e) =>
                      handleArrChange(index, e.target.name, e.target.value)
                    }
                  />
                </Control>
              </Field>
              <StyledFormSectionHeader id="remove-add-counsel-buttons">
                <Field>
                  <Control>
                    <Button
                      color="danger"
                      disabled={disabled && counsel.fromDB}
                      size="small"
                      type="button"
                      onClick={() => handleDeleteCounsel(index)}
                    >
                      <span>Remove</span>
                    </Button>
                  </Control>
                </Field>
                {index >= arr.filter((c) => !c.deleted).length - 1 && (
                  <Field>
                    <Control>
                      <Button
                        color="primary"
                        size="small"
                        type="button"
                        onClick={handleAddCounsel}
                      >
                        <Icon>
                          <FontAwesomeIcon icon="plus" />
                        </Icon>
                        <span>Add Counsel</span>
                      </Button>
                    </Control>
                  </Field>
                )}
              </StyledFormSectionHeader>
            </Box>
          )
        );
      })}
    </React.Fragment>
  );
};

CounselForm.propTypes = {
  handleArrChange: PropTypes.func.isRequired,
  inputs: PropTypes.object.isRequired,
  handleAddCounsel: PropTypes.func.isRequired,
  handleDeleteCounsel: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default CounselForm;
