import { gql } from "@apollo/client";

// eslint-disable-next-line import/prefer-default-export
export const LIST_FEE_HEADER_QUERY = gql`
  query feeHeaders(
    $take: Int
    $orderBy: [FeeHeaderOrderByInput!]
    $skip: Int
    $where: FeeHeaderWhereInput
  ) {
    feeHeaders(take: $take, orderBy: $orderBy, skip: $skip, where: $where) {
      feecode
      feedesc
    }
  }
`;

export const SINGLE_FEE_HEADER_QUERY = gql`
  query feeHeader(
    $where: FeeHeaderWhereUniqueInput!
  ) {
    feeHeader(where: $where) {
      feecode
      feedesc
    }
  }
`;
