import React from "react";
import PropTypes from "prop-types";
import { Button, Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useModal } from "../../../../context";
import { CCAddressModal } from "../../../../components/CCAddress";

const CCAddressActionButtons = ({ name, cccode, onChange }) => {
  const { setModalOpen } = useModal();

  const handleAddClick = () => {
    setModalOpen(
      true,
      <CCAddressModal
        onComplete={(ccAddressCode) => {
          setModalOpen(false, "");
          if (ccAddressCode) {
            onChange(name, ccAddressCode);
          }
        }}
      />,
    );
  };

  const handleEditClick = () => {
    setModalOpen(
      true,
      <CCAddressModal
        cccode={cccode}
        onComplete={() => {
          setModalOpen(false, "");
        }}
      />,
    );
  };

  const handleDeleteClick = () => {
    onChange(name, "");
  };
  return (
    <Button.Group hasAddons>
      <Button
        color="success"
        size="small"
        type="button"
        onClick={handleAddClick}
      >
        <Icon>
          <FontAwesomeIcon icon="plus" />
        </Icon>
      </Button>
      <Button
        color="primary"
        disabled={!cccode}
        size="small"
        type="button"
        onClick={handleEditClick}
      >
        <Icon>
          <FontAwesomeIcon icon="edit" />
        </Icon>
      </Button>
      <Button
        color="danger"
        disabled={!cccode}
        size="small"
        type="button"
        onClick={handleDeleteClick}
      >
        <Icon>
          <FontAwesomeIcon icon="times" />
        </Icon>
      </Button>
    </Button.Group>
  );
};

CCAddressActionButtons.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  cccode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

CCAddressActionButtons.defaultProps = {
  cccode: "",
};

export default CCAddressActionButtons;
