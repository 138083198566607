import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";

import { AsyncPaginate } from "react-select-async-paginate";

import { Label, Control } from "rbx";
import { useApolloClient, useLazyQuery } from "@apollo/client";
import { ALL_DOCTORS_QUERY, SINGLE_DOCTOR_QUERY } from "../../graphql/doctor";

const QUERY_LIMIT = 50;

const DoctorSelect = ({
  showLabel,
  name,
  value: initialValue,
  label,
  onChange,
  disabled,
  required,
  getValue,
  setValue,
  showOnlyActives,
  where: initialWhere,
}) => {
  const client = useApolloClient();

  const [getDoctor, { data: getDoctorData }] = useLazyQuery(
    SINGLE_DOCTOR_QUERY
  );

  const [value, setLocalValue] = useState(null);
  const [defaultOptions, setDefaultOptions] = useState([]);

  const getLabel = useCallback(
    (doctor) =>
      `${doctor.lastname}, ${doctor.firstname}  ${doctor.middleinitial || ""
      } - ${doctor.city ? `${doctor.city}, ` : ""}${doctor.state || ""}${doctor.ImportSpecialty ? ` - ${doctor.ImportSpecialty}` : ""
      } - ${doctor.usdint1 === 1 ? "I" : "D"}`,
    []
  );

  const promiseOptions = async (inputValue, { length }) => {
    const [lastname, firstname] = inputValue
      ?.split(/,/)
      .map((x) => x.trim())
      .filter(Boolean);

    const where = {
      ...initialWhere,
      lastname: { startsWith: lastname },
      firstname: { startsWith: firstname },
    };

    if (showOnlyActives) {
      where.status = { equals: "Active" };
    }
    if (label !== "Investigator") {
      where.OR = [
        {
          usdint1: { equals: 2 },
        },
        {
          usdint1: { equals: null },
        },
      ];
    } else {
      where.usdint1 = { equals: 1 };
    }

    const { data: doctorsData } = await client.query({
      query: ALL_DOCTORS_QUERY,
      variables: {
        take: QUERY_LIMIT,
        where,
        orderBy: [
          { lastname: "asc" },
          { firstname: "asc" },
          { middleinitial: "asc" },
        ],
        skip: length,
      },
      fetchPolicy: "network-only",
    });
    const doctors = doctorsData?.doctors;

    const result = Array.isArray(doctors)
      ? doctors.map((doctor) => ({
        value: doctor.doctorcode,
        label: getLabel(doctor),
      }))
      : [];
    setDefaultOptions(result);
    return { options: result, hasMore: result.length === QUERY_LIMIT };
  };

  const handleChange = ({ value: newValue }) => {
    onChange(name, setValue(newValue));
  };

  useEffect(() => {
    if (initialValue) {
      getDoctor({
        variables: {
          where: { doctorcode: parseInt(getValue(initialValue), 10) },
        },
      });
    } else {
      setLocalValue(null);
    }
  }, [initialValue, getDoctor, getValue]);

  useEffect(() => {
    const doctor = getDoctorData?.doctor;
    if (doctor && initialValue) {
      setLocalValue({
        value: doctor.doctorcode,
        label: getLabel(doctor),
      });
    }
  }, [getDoctorData, getLabel, initialValue]);

  useEffect(() => {
    if (value) {
      const currOptions = [...defaultOptions];
      if (!currOptions.some((opt) => opt.value === value.value)) {
        setDefaultOptions((prev) => [value, ...prev]);
      }
    }
  }, [value, defaultOptions]);

  const indicateRequired =
    required && (!value || value === "" || value === "0" || value === 0);

  return (
    <Control expanded>
      {showLabel && <Label>{label}</Label>}
      <AsyncPaginate
        cacheOptions
        debounceTimeout={300}
        defaultOptions={defaultOptions}
        isDisabled={disabled}
        loadOptions={promiseOptions}
        menuPlacement="auto"
        menuPortalTarget={document.body}
        placeholder="Enter Doctor Last Name..."
        styles={{
          menuPortal: (provided) => ({
            ...provided,
            zIndex: 9999,
          }),
          indicatorsContainer: (provided) => ({
            ...provided,
            height: "inherit",
          }),
          indicatorSeparator: (provided) => ({
            ...provided,
            display: "none",
          }),
          container: (provided) => ({
            fontSize: 13,
          }),
          singleValue: (provided) => ({
            ...provided,
            top: "38%",
          }),
          control: (provided) => ({
            ...provided,
            minHeight: 30,
            height: 30,
            width: "100%",
            minWidth: 180,
            border: indicateRequired ? "1px #e63362 solid" : "",
            borderRadius: "4px",
            boxShadow: indicateRequired
              ? "0 0 0 0.125em rgba(230, 51, 98, 0.25)"
              : "",
            "&:hover": {
              borderColor: "hsl(0, 0%, 90%)",
              borderRadius: 4,
              cursor: disabled ? "not-allowed" : "pointer",
            },
          }),
          placeholder: (provided) => ({ ...provided, top: "38%" }),
          menu: (provided) => ({
            ...provided,
            fontSize: 12,
            zIndex: 999,
          }),
        }}
        value={value}
        onChange={handleChange}
      />
    </Control>
  );
};

DoctorSelect.propTypes = {
  showLabel: PropTypes.bool,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  getValue: PropTypes.func,
  setValue: PropTypes.func,
  showOnlyActives: PropTypes.bool,
  where: PropTypes.object,
};

DoctorSelect.defaultProps = {
  where: {},
  name: "",
  label: "",
  value: "",
  onChange: () => null,
  showLabel: true,
  disabled: false,
  required: false,
  getValue: (x) => x,
  setValue: (x) => x,
  showOnlyActives: false,
};

export default DoctorSelect;
