import { gql } from "@apollo/client";

export const DELETE_CLIENT_DEFAULT_DOCUMENT_MUTATION = gql`
  mutation DELETE_CLIENT_DEFAULT_DOCUMENT_MUTATION(
    $where: ClientDefDocumentWhereUniqueInput!
  ) {
    deleteClientDefDocument(where: $where) {
      clientcode
      documentqueue
      documentcode
    }
  }
`;

export const CREATE_CLIENT_DEFAULT_DOCUMENT_MUTATION = gql`
  mutation CREATE_CLIENT_DEFAULT_DOCUMENT_MUTATION(
    $data: ClientDefDocumentCreateInput!
  ) {
    createClientDefDocument(data: $data) {
      documentcode
      documentqueue
      clientcode
    }
  }
`;
