import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/client";
import { Button, Icon, Title } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ALL_CLIENT_DEFAULT_DOCUMENTS_QUERY,
  DELETE_CLIENT_DEFAULT_DOCUMENT_MUTATION,
} from "../../../../graphql";
import { customToast as toast } from "../../../../utils";
import { useModal } from "../../../../context";
import FlatDataTable from "../../../../components/FlatDataTable";

import { generateColumns } from "./columns";
import Confirmation from "../../../../components/Confirmation";
import ClientDefaultDocumentModal from "../ClientDefaultDocumentModal";

const ClientDefaultDocuments = ({ clientcode }) => {
  const { setModalOpen } = useModal();
  const [deleteClientDefaultDocument] = useMutation(
    DELETE_CLIENT_DEFAULT_DOCUMENT_MUTATION
  );

  const COLUMNS = useMemo(() => {
    const handleDelete = (clientDefDocument) => {
      const performDelete = async () => {
        try {
          setModalOpen(false, "");
          await deleteClientDefaultDocument({
            variables: {
              where: {
                clientcode_documentcode_documentqueue: {
                  clientcode: clientDefDocument.clientcode,
                  documentcode: clientDefDocument.documentcode,
                  documentqueue: clientDefDocument.documentqueue,
                },
              },
            },
            refetchQueries: [
              {
                query: ALL_CLIENT_DEFAULT_DOCUMENTS_QUERY,
                variables: {
                  where: { clientcode: { equals: parseInt(clientcode, 10) } },
                },
              },
            ],
          });
          toast.success("Document deleted successfully.");
        } catch (err) {
          toast.error(`Error deleting document.`);
        }
      };
      setModalOpen(
        true,
        <Confirmation
          message="Are you sure you want to delete this document?"
          onCancel={() => setModalOpen(false, "")}
          onConfirm={performDelete}
        />
      );
    };
    return generateColumns(handleDelete);
  }, [clientcode, deleteClientDefaultDocument, setModalOpen]);

  const handleAddClick = () => {
    setModalOpen(
      true,
      <ClientDefaultDocumentModal
        clientcode={clientcode}
        onComplete={() => {
          setModalOpen(false, "");
        }}
      />
    );
  };
  return (
    <div className="client-default-documents-page">
      <header className="page-head">
        <div className="page-head-start">
          <Button
            aria-label="Add Default Document"
            color="primary"
            size="small"
            type="button"
            onClick={handleAddClick}
          >
            <Icon>
              <FontAwesomeIcon icon="plus" />
            </Icon>
            <span>Add</span>
          </Button>
          <Title size={6}>Default Documents</Title>
        </div>
        <div className="page-head-end" />
      </header>
      <FlatDataTable
        columns={COLUMNS}
        fetchPolicy="cache-and-network"
        name="clientDefDocuments"
        orderBy={[
          { id: "order", desc: false },
          { id: "documentcode", desc: false },
        ]}
        query={ALL_CLIENT_DEFAULT_DOCUMENTS_QUERY}
        where={{ clientcode: { equals: parseInt(clientcode, 10) } }}
      />
    </div>
  );
};

ClientDefaultDocuments.propTypes = {
  clientcode: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
};

export default ClientDefaultDocuments;
