import { gql } from "@apollo/client";

export const LIST_DOCTORS_QUERY = gql`
  query doctors(
    $take: Int
    $orderBy: [DoctorOrderByInput!]
    $skip: Int
    $where: DoctorWhereInput
  ) {
    doctors(take: $take, orderBy: $orderBy, skip: $skip, where: $where) {
      doctorcode
      firstname
      lastname
      licensenbr
      credentials
      notes
      city
      state
      zip
      county
      DoctorSpecialty {
        doctorcode
        specialtycode
      }
      SSNTaxID
      status
    }
    aggregateDoctor(where: $where) {
      count {
        doctorcode
      }
    }
  }
`;

export const SINGLE_DOCTOR_QUERY = gql`
  query doctor($where: DoctorWhereUniqueInput!) {
    doctor(where: $where) {
      # PROFILE
      doctorcode
      lastname
      firstname
      middleinitial
      prefix
      addr1
      addr2
      city
      state
      county
      zip
      phone
      phoneExt
      cellphone
      companyname
      notes
      faxNbr
      emailAddr
      credentials
      # GENERAL
      SSNTaxID
      licensenbr
      UPIN
      NPINbr
      WCNbr
      UnRegNbr
      status
      schedulepriority
      ProvTypeCode
      prepaid
      doctoremail
      medicalrecordemail
      # QUALIFICATIONS
      qualifications
      # USER DEFINED FIELDS
      usdvarchar1
      usdvarchar2
      usdvarchar3
      usdtext1
      usdtext2
      usddate1
      usddate2
      usddate3
      usddate4
      usdint1
      usdint2
      # STATISTICS
      dateadded
      dateedited
      useridadded
      useridedited
    }
  }
`;

export const ALL_DOCTORS_QUERY = gql`
  query doctors(
    $where: DoctorWhereInput
    $orderBy: [DoctorOrderByInput!]
    $take: Int
    $skip: Int
  ) {
    doctors(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
      doctorcode
      firstname
      lastname
      city
      state
      prefix
      status
      middleinitial
      ImportSpecialty
      companyname
      OPType
      OPSubType
      SSNTaxID
    }
  }
`;

export const LIST_OTHER_PARTIES_QUERY = gql`
  query LIST_OTHER_PARTIES_QUERY(
    $take: Int
    $orderBy: [DoctorOrderByInput!]
    $skip: Int
    $where: DoctorWhereInput
  ) {
    doctors(take: $take, orderBy: $orderBy, skip: $skip, where: $where) {
      doctorcode
      OPSubType
      firstname
      lastname
      status
      companyname
      addr1
      city
      state
      zip
      phone
      notes
      dateadded
      dateedited
      useridedited
      useridadded
      OtherPartyType {
        type
        description
      }
    }
    aggregateDoctor(where: $where) {
      count {
        doctorcode
      }
    }
  }
`;

export const SINGLE_OTHER_PARTY_QUERY = gql`
  query SINGLE_OTHER_PARTY_QUERY($where: DoctorWhereUniqueInput!) {
    doctor(where: $where) {
      doctorcode
      OPSubType
      firstname
      lastname
      status
      companyname
      addr1
      city
      state
      zip
      phone
      notes
      dateadded
      dateedited
      useridedited
      useridadded
    }
  }
`;

export const FIND_FIRST_OTHER_PARTY_QUERY = gql`
  query FIND_FIRST_OTHER_PARTY_QUERY($where: DoctorWhereInput!) {
    findFirstOtherParty: findFirstDoctor(where: $where) {
      doctorcode
    }
  }
`;

export const FIND_FIRST_DOCTOR_QUERY = gql`
  query FIND_FIRST_DOCTOR_QUERY($where: DoctorWhereInput!) {
    findFirstDoctor: findFirstDoctor(where: $where) {
      doctorcode
    }
  }
`;

export const CUSTOM_LIST_DOCTORS_QUERY = gql`
  query doctorItems(
    $take: Float
    $orderBy: [CustomDoctorOrderByInput!]
    $skip: Float
    $where: CustomDoctorWhereInput
  ) {
    doctorItems(take: $take, orderBy: $orderBy, skip: $skip, where: $where) {
      doctorcode
      firstname
      lastname
      licensenbr
      credentials
      notes
      city
      state
      zip
      county
      proximity
      doctorSpecialties
      SSNTaxID
      status
    }
    aggregateDoctorItems(where: $where) {
      count {
        doctorcode
      }
    }
  }
`;

export const ALL_DOCTOR_SCHEDULE_QUERY = gql`
  query ALL_DOCTOR_SCHEDULE_QUERY(
    $where: DoctorScheduleWhereInput
    $orderBy: [DoctorScheduleOrderByInput!]
    $take: Int
    $skip: Int
  ) {
    doctorSchedules(
      take: $take
      orderBy: $orderBy
      skip: $skip
      where: $where
    ) {
      schedcode
      locationcode
      starttime
      duration
      status
      casenbr1
      casenbr1desc
      casenbr2
      casenbr2desc
      CaseNbr3
      CaseNbr3Desc
      dateadded
      useridadded
      dateedited
    }
  }
`;

export const SINGLE_DOCTOR_SCHEDULE_QUERY = gql`
  query SINGLE_DOCTOR_SCHEDULE_QUERY($where: DoctorScheduleWhereUniqueInput!) {
    doctorSchedule(where: $where) {
      schedcode
      locationcode
      starttime
      duration
      status
      casenbr1
      casenbr1desc
      casenbr2
      casenbr2desc
      CaseNbr3
      CaseNbr3Desc
      dateadded
      useridadded
      dateedited
    }
  }
`;
