const DEFAULT_COLUMNS = [
  {
    Header: "External Name",
    id: "extname",
    accessor: "extname",
  },
  {
    Header: "Internal Name",
    id: "intname",
    accessor: "intname",
  },
  {
    Header: "Address 1",
    id: "addr1",
    accessor: "addr1",
  },
  {
    Header: "Address 2",
    id: "addr2",
    accessor: "addr2",
  },
  {
    Header: "City",
    id: "city",
    accessor: "city",
  },
  {
    Header: "State",
    id: "state",
    accessor: "state",
  },
  {
    Header: "Zip",
    id: "zip",
    accessor: "zip",
  },
  {
    Header: "Group",
    accessor: "companyToCompanyGroup.CompanyGroup.CompanyGroupName",
    id: "companyToCompanyGroup",
    disableSortBy: true,
  },
  {
    Header: "Status",
    accessor: "status",
    id: "status",
  },
  // {
  //   Header: "Code",
  //   id: "companycode",
  //   accessor: "companycode",
  // },
];

export default DEFAULT_COLUMNS;
