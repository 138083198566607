import React from "react";
import PropTypes from "prop-types";

import ReferrerInformationForm from "../ReferrerInformationForm";
import ClaimInformationForm from "../ClaimInformationForm";
import ClaimantInformationForm from "../ClaimantInformationForm";

import ProductSelectionForm from "../ProductSelectionForm";
import ServicesRequested from "../ServicesRequested";

const ReferralFormBase = ({
  onChange,
  inputs,
  mode,
  product,
  reload,
  setInputs,
  teamConnectID,
  isLowesCase,
  isGeicoInvestigationsRequirement,
  isGeicoMarltonOffice,
  disabled,
  DOLRequired,
}) => (
  <React.Fragment>
    {!/^records$/gi.test(inputs?.product) ? (
      <ReferrerInformationForm
        disabled={disabled}
        inputs={inputs}
        mode={mode}
        setInputs={setInputs}
        onChange={onChange}
      />
    ) : (
      ""
    )}

    <ClaimInformationForm
      DOLRequired={DOLRequired}
      disabled={disabled}
      inputs={inputs}
      isGeicoMarltonOffice={isGeicoMarltonOffice}
      isLowesCase={isLowesCase}
      mode={mode}
      setInputs={setInputs}
      teamConnectID={teamConnectID}
      onChange={onChange}
    />

    <ClaimantInformationForm
      disabled={disabled}
      inputs={inputs}
      isGeicoInvestigationsRequirement={isGeicoInvestigationsRequirement}
      isGeicoMarltonOffice={isGeicoMarltonOffice}
      mode={mode}
      setInputs={setInputs}
      onChange={onChange}
    />

    <ProductSelectionForm
      inputs={inputs}
      mode={mode}
      reload={reload}
      onChange={onChange}
    />

    {!/^records$/gi.test(inputs?.product) ? (
      <ServicesRequested
        inputs={inputs}
        isGeicoMarltonOffice={isGeicoMarltonOffice}
        product={product}
        onChange={onChange}
      />
    ) : (
      ""
    )}
  </React.Fragment>
);

ReferralFormBase.propTypes = {
  onChange: PropTypes.func,
  inputs: PropTypes.object,
  mode: PropTypes.string,
  product: PropTypes.string,
  reload: PropTypes.bool,
  setInputs: PropTypes.func.isRequired,
  teamConnectID: PropTypes.string,
  isLowesCase: PropTypes.bool,
  disabled: PropTypes.bool,
  DOLRequired: PropTypes.bool,
  isGeicoInvestigationsRequirement: PropTypes.bool.isRequired,
  isGeicoMarltonOffice: PropTypes.bool.isRequired,
};
ReferralFormBase.defaultProps = {
  onChange: () => {},
  inputs: {},
  mode: "",
  product: "",
  reload: false,
  teamConnectID: null,
  isLowesCase: false,
  disabled: false,
  DOLRequired: false,
};
export default ReferralFormBase;
