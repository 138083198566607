import React from "react";

import PropTypes from "prop-types";

import { Control, Label, Select } from "rbx";

import { useQuery } from "@apollo/client";
import { ALL_BUSINESS_LINES_QUERY } from "../../graphql";

const BusinessLineSelect = ({
  value,
  name,
  label,
  size,
  onChange,
  disabled,
  getValue,
  setValue,
  required,
  showAll,
  showBlank,
  where,
}) => {
  const { data: BusinessLineData, refetch } = useQuery(
    ALL_BUSINESS_LINES_QUERY,
    {
      variables: {
        orderBy: { name: "asc" },
        where,
      },
    }
  );

  const businessLines = BusinessLineData?.businessLines;

  const handleChange = (e) => {
    onChange(e.target.name, setValue(e.target.value || ""));
  };

  return (
    <Control>
      <Label>{label}</Label>
      <Select.Container
        fullwidth
        size={size}
        state={Array.isArray(businessLines) ? "" : "loading"}
      >
        <Select
          disabled={disabled}
          name={name}
          required={required}
          value={getValue(value)}
          onChange={handleChange}
          onFocus={() => {
            refetch();
          }}
        >
          {showBlank && <Select.Option value="" />}
          {showAll && <Select.Option value="">All</Select.Option>}
          {Array.isArray(businessLines) &&
            businessLines.map((businessLine) => (
              <Select.Option
                key={`businessline-${businessLine.businesslineid}`}
                value={businessLine.businesslineid}
              >
                {[businessLine.name, businessLine.description]
                  .filter(Boolean)
                  .join(" - ")}
              </Select.Option>
            ))}
        </Select>
      </Select.Container>
    </Control>
  );
};

BusinessLineSelect.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
    PropTypes.object,
  ]),
  name: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  getValue: PropTypes.func,
  setValue: PropTypes.func,
  showAll: PropTypes.bool,
  showBlank: PropTypes.bool,
  where: PropTypes.object,
};

BusinessLineSelect.defaultProps = {
  showAll: false,
  value: "",
  name: "",
  label: "Business Line",
  size: "small",
  onChange: () => null,
  disabled: false,
  required: false,
  getValue: (x) => x,
  setValue: (x) => x,
  showBlank: false,
  where: {},
};

export default BusinessLineSelect;
