/* eslint-disable import/prefer-default-export */
import { gql } from "@apollo/client";

export const CALL_LINK_SERVICE = gql`
  mutation LinkService($input: LinkServiceInput!) {
    linkService(input: $input) {
      ids
      message
    }
  }
`;
