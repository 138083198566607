import "./Referral.scss";

import React from "react";

import { Switch, Route } from "react-router-dom";

import ReferralPage from "./ReferralPage";

const ReferralRouter = (props) => (
  <Switch>
    <Route path="/referral">
      <ReferralPage />
    </Route>
  </Switch>
);

export default ReferralRouter;
