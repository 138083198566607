import React, { useMemo } from "react";
import PropTypes from "prop-types";

import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { Field, Control, Input, Label, Textarea } from "rbx";
import { SINGLE_CASE_QUERY } from "../../../../graphql";

import Schedule from "./Schedule";
import Scheduled from "./Scheduled";
import BooleanInput from "../../../../components/BooleanInput";

const Scheduling = ({ onChangeCase, inputsCase, disabled, onSubmit }) => {
  const { casenbr: pcasenbr, workid } = useParams();
  const casenbr = pcasenbr || workid;

  const { data: CaseItemData } = useQuery(SINGLE_CASE_QUERY, {
    variables: { where: { casenbr: parseInt(casenbr, 10) } },
  });

  const isScheduled = useMemo(() => {
    const caseItem = CaseItemData?.caseItem;
    return (
      caseItem?.doctor &&
      caseItem?.doctorLocation &&
      caseItem?.location &&
      caseItem?.ApptDate &&
      caseItem?.Appttime
    );
  }, [CaseItemData?.caseItem]);

  return (
    <React.Fragment>
      <form id="edit-case-form" onSubmit={onSubmit}>
        <Field>
          <Control>
            <Label>Requested Doctor</Label>
            <Input
              autoComplete="new"
              name="requesteddoc"
              size="small"
              type="text"
              value={inputsCase.requesteddoc || ""}
              onChange={(e) => onChangeCase(e.target.name, e.target.value)}
            />
          </Control>
        </Field>
        <Field>
          <Control>
            <BooleanInput
              disabled={disabled}
              label="This doctor was requested by the client AND the requested doctor performed the service."
              name="DoctorWasRequestedByClient"
              value={inputsCase.DoctorWasRequestedByClient}
              onChange={onChangeCase}
            />
          </Control>
        </Field>
        <Field style={{ gridColumn: "1 / 5" }}>
          <Control>
            <Label>Scheduling Notes</Label>
            <Textarea
              name="schedulenotes"
              rows="6"
              size="small"
              value={inputsCase.schedulenotes}
              onChange={(e) => onChangeCase(e.target.name, e.target.value)}
            />
          </Control>
        </Field>
      </form>
      <hr />
      {isScheduled ? (
        <Scheduled
          disabled={disabled}
          inputsCase={inputsCase}
          onChangeCase={onChangeCase}
          onSubmit={onSubmit}
        />
      ) : (
        <Schedule
          inputsCase={inputsCase}
          onChangeCase={onChangeCase}
          onSubmit={onSubmit}
        />
      )}
    </React.Fragment>
  );
};
Scheduling.propTypes = {
  onChangeCase: PropTypes.func.isRequired,
  inputsCase: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
};
Scheduling.defaultProps = {
  disabled: false,
};
export default Scheduling;
