import React from "react";
import PropTypes from "prop-types";
import { useApolloClient } from "@apollo/client";
import { Column, Control, Label, Input, Field } from "rbx";
import { useModal } from "../../../context";
import ClientSelect from "../../../components/ClientSelect";
import PrioritySelect from "../../../components/PrioritySelect";
import UserSelect from "../../../components/UserSelect";
import JurisdictionSelect from "../../../components/JurisdictionSelect";
import CaseSpecialSelects from "../../../components/CaseSpecialSelects";
import { Company } from "../../../graphql";
import Confirmation from "../../../components/Confirmation";

const CaseForm = ({
  inputs,
  onChange,
  codeStates,
  disabled = false,
  onSubmit,
  setInputs,
  casenbr,
}) => {
  const client = useApolloClient();
  const { setModalOpen } = useModal();

  const handleChange = async (name, clientcode, cCode) => {
    // query to find if there is a company group id
    const { data: companyData } = await client.query({
      query: Company,
      variables: {
        where: {
          companycode: cCode,
        },
      },
    });
    if (
      inputs?.company?.companyToCompanyGroup?.CompanyGroupID === 5010 &&
      companyData?.company?.companyToCompanyGroup?.CompanyGroupID !== 5010
    ) {
      setModalOpen(
        true,
        <Confirmation
          affirmative="Confirm"
          message="Changing the client from Allstate will remove the Allstate Processor. Proceed?"
          negative="Cancel"
          onCancel={() => setModalOpen(false)}
          onConfirm={() => handleAllstateChange(name, clientcode)}
        />
      );
    } else {
      onChange(name, clientcode);
    }
  };

  const handleAllstateChange = async (name, clientcode) => {
    // update state with input and remove processor
    const allstateProcessor = false;
    onChange(name, clientcode, null, allstateProcessor);

    // close modal
    setModalOpen(false);
  };

  return (
    <form id="edit-case-form" onSubmit={onSubmit}>
      <Column.Group>
        <Column size={12}>
          <div className="case-special-selectors">
            <Field style={{ gridColumn: "1/3" }}>
              <Control expanded>
                <Label>Case Number</Label>
                <Input
                  disabled
                  autoComplete="new"
                  name="casenbr"
                  size="small"
                  type="text"
                  value={inputs.casenbr}
                />
              </Control>
            </Field>
            <Field style={{ gridColumn: "3/5" }}>
              <Control expanded>
                <ClientSelect
                  required
                  showLabel
                  showLink
                  disabled={disabled}
                  label="Client"
                  name="client"
                  value={inputs.client}
                  where={{ status: { equals: "Active" } }}
                  onChange={handleChange}
                />
              </Control>
            </Field>
            <Field style={{ gridColumn: "5/7" }}>
              <Control expanded>
                <PrioritySelect
                  disabled={disabled}
                  name="priority"
                  noValueLabel=""
                  value={inputs.priority}
                  onChange={onChange}
                />
              </Control>
            </Field>
            <Field style={{ gridColumn: "7/9" }}>
              <Control expanded>
                <Label>Commit Date</Label>
                <Input
                  disabled={disabled}
                  max="9999-12-31"
                  name="commitdate"
                  size="small"
                  type="date"
                  value={inputs.commitdate}
                  onChange={onChange}
                />
              </Control>
            </Field>
          </div>

          <div className="case-special-selectors">
            <CaseSpecialSelects
              inline
              casenbr={casenbr}
              disabled={disabled}
              inputs={inputs}
              setInputs={setInputs}
            />
          </div>

          <div className="case-special-selectors">
            <Field style={{ gridColumn: "1/3" }}>
              <Control expanded>
                <JurisdictionSelect
                  disabled={disabled}
                  id="Statecode"
                  label="Jurisdiction"
                  name="Jurisdiction"
                  value={inputs.Jurisdiction}
                  onChange={onChange}
                />
              </Control>
            </Field>
            <Field style={{ gridColumn: "3/5" }}>
              <UserSelect
                fullWidth
                disabled={disabled}
                label="Marketer"
                name="marketercode"
                value={inputs.marketercode}
                where={{
                  OR: [
                    { usertype: { equals: "MK" } },
                    { usertype: { equals: "*" } },
                  ],
                }}
                onChange={onChange}
              />
            </Field>

            <Field style={{ gridColumn: "5/7" }}>
              <Control expanded>
                <UserSelect
                  fullWidth
                  required
                  disabled={disabled}
                  label="Scheduler"
                  name="schedulercode"
                  value={inputs.schedulercode}
                  where={{
                    OR: [
                      { usertype: { equals: "SC" } },
                      { usertype: { equals: "*" } },
                    ],
                  }}
                  onChange={onChange}
                />
              </Control>
            </Field>

            <Field style={{ gridColumn: "7/9" }}>
              <Control expanded>
                <UserSelect
                  fullWidth
                  disabled={disabled}
                  label="QA Rep"
                  name="QARep"
                  value={inputs.QARep}
                  where={{
                    OR: [
                      { usertype: { equals: "QA" } },
                      { usertype: { equals: "*" } },
                    ],
                  }}
                  onChange={onChange}
                />
              </Control>
            </Field>
            {inputs?.company?.companyToCompanyGroup?.CompanyGroupID ===
              5010 && (
              <Field style={{ gridColumn: "7/9" }}>
                <Control expanded>
                  <UserSelect
                    fullWidth
                    required
                    disabled={disabled}
                    label="Processor"
                    name="SelectedAllstateProcessor"
                    value={inputs.SelectedAllstateProcessor}
                    where={{
                      OR: [{ AllstateProcessor: { equals: 1 } }],
                    }}
                    onChange={onChange}
                  />
                </Control>
              </Field>
            )}
          </div>
        </Column>
      </Column.Group>
    </form>
  );
};
CaseForm.propTypes = {
  inputs: PropTypes.object,
  onChange: PropTypes.func,
  codeStates: PropTypes.object,
  disabled: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  setInputs: PropTypes.func.isRequired,
  casenbr: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

CaseForm.defaultProps = {
  inputs: {},
  onChange: (e) => e,
  codeStates: {},
  disabled: false,
  casenbr: null,
};
export default CaseForm;
