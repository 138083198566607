import { gql } from "@apollo/client";

export const CREATE_OFFICE_MUTATION = gql`
  mutation CREATE_OFFICE_MUTATION($data: OfficeCreateInput!) {
    createOffice(data: $data) {
      officecode
      description
      shortdesc
      status
    }
  }
`;

export const UPDATE_OFFICE_MUTATION = gql`
  mutation UPDATE_OFFICE_MUTATION(
    $data: OfficeUpdateInput!
    $where: OfficeWhereUniqueInput!
  ) {
    updateOffice(data: $data, where: $where) {
      officecode
      description
      shortdesc
      status
    }
  }
`;

export const DELETE_OFFICE_MUTATION = gql`
  mutation DELETE_OFFICE_MUTATION($where: OfficeWhereUniqueInput!) {
    deleteOffice(where: $where) {
      officecode
    }
  }
`;