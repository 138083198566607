import { gql } from "@apollo/client";

export const LIST_AUDIT_TRAILS_QUERY = gql`
  query LIST_AUDIT_TRAILS_QUERY(
    $take: Int
    $orderBy: [AuditTrailOrderByInput!]
    $skip: Int
    $where: AuditTrailWhereInput
  ) {
    auditTrails(take: $take, orderBy: $orderBy, skip: $skip, where: $where) {
      audittrailid
      tablename
      entityid
      type
      description
      useridadded
      dateadded
    }
    aggregateAuditTrail(where: $where) {
      count {
        audittrailid
      }
    }
  }
`;

export const SINGLE_AUDIT_TRAIL_QUERY = gql`
  query SINGLE_AUDIT_TRAIL_QUERY($where: AuditTrailWhereUniqueInput!) {
    auditTrail(where: $where) {
      audittrailid
      tablename
      entityid
      type
      description
      useridadded
      dateadded
    }
  }
`;

export const ALL_AUDIT_TRAILS_QUERY = gql`
  query ALL_AUDIT_TRAILS_QUERY(
    $where: AuditTrailWhereInput
    $orderBy: [AuditTrailOrderByInput!]
    $take: Int
  ) {
    auditTrails(where: $where, orderBy: $orderBy, take: $take) {
      audittrailid
      tablename
      entityid
      type
      description
      useridadded
      dateadded
    }
  }
`;
