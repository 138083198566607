const LOCATION_FIELDS = [
  { name: "Country", type: "String", entity: "location" },
  { name: "Phone", type: "String", entity: "location" },
  { name: "addr1", type: "String", entity: "location" },
  { name: "addr2", type: "String", entity: "location" },
  { name: "city", type: "String", entity: "location" },
  { name: "contactfirst", type: "String", entity: "location" },
  { name: "contactlast", type: "String", entity: "location" },
  { name: "contactprefix", type: "String", entity: "location" },
  { name: "county", type: "String", entity: "location" },
  { name: "dateadded", type: "DateTime", entity: "location" },
  { name: "dateedited", type: "DateTime", entity: "location" },
  { name: "drletter", type: "Boolean", entity: "location" },
  { name: "email", type: "String", entity: "location" },
  { name: "fax", type: "String", entity: "location" },
  { name: "faxdrschedule", type: "Boolean", entity: "location" },
  { name: "insidedr", type: "Boolean", entity: "location" },
  { name: "location", type: "String", entity: "location" },
  { name: "locationcode", type: "Int", entity: "location" },
  { name: "medrcdletter", type: "Boolean", entity: "location" },
  { name: "notes", type: "String", entity: "location" },
  { name: "state", type: "String", entity: "location" },
  { name: "status", type: "String", entity: "location" },
  { name: "useridadded", type: "String", entity: "location" },
  { name: "useridedited", type: "String", entity: "location" },
  { name: "zip", type: "String", entity: "location" },
];

export default LOCATION_FIELDS;
