import { gql } from "@apollo/client";

export const ALL_ZIPS_QUERY = gql`
  query zipCodes(
    $take: Int
    $skip: Int
    $orderBy: [ZipCodeOrderByInput!]
    $where: ZipCodeWhereInput
    $distinct: [ZipCodeScalarFieldEnum!]
  ) {
    zipCodes(take: $take, orderBy: $orderBy, skip: $skip, where: $where, distinct: $distinct) {
      kIndex
      sCity
      sState
      sZip
      fLatitude
      fLongitude
      sCountyName
    }
  }
`;

export const ALL_ZIP_CODES_BY_PROXIMITY_QUERY = gql`
  query zipCodesByProximity($input: ZipCodesByProximityInput!) {
    zipCodesByProximity(input: $input) {
      kIndex
      sZip
      proximity
    }
  }
`;

export const FIND_FIRST_ZIP_QUERY = gql`
  query findFirstZipCode($where: ZipCodeWhereInput) {
    findFirstZipCode(where: $where) {
      kIndex
      sState
      sZip
      fLatitude
      fLongitude
      sCountyName
    }
  }
`;
