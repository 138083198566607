import React from "react";
import PropTypes from "prop-types";
import { ServiceForm } from "../../components";

const ServiceMainPage = ({ inputs, onChange, onSubmit }) => (
  <form id="service-edit-form" onSubmit={onSubmit}>
    <ServiceForm inputs={inputs} onChange={onChange} />
  </form>
);

ServiceMainPage.propTypes = {
  inputs: PropTypes.object,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
};

ServiceMainPage.defaultProps = {
  inputs: {},
  onChange: () => null,
  onSubmit: () => null,
};

export default ServiceMainPage;
