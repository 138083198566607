import { gql } from "@apollo/client";

export const CREATE_COURT_MUTATION = gql`
  mutation CREATE_COURT_MUTATION($data: CourtCreateInput!) {
    createCourt(data: $data) {
      CourtID
    }
  }
`;

export const UPDATE_COURT_MUTATION = gql`
  mutation UPDATE_COURT_MUTATION(
    $data: CourtUpdateInput!
    $where: CourtWhereUniqueInput!
  ) {
    updateCourt(data: $data, where: $where) {
      CourtID
    }
  }
`;
