import React from "react";
import PropTypes from "prop-types";

import { Box, Field, Control, Button, Label } from "rbx";
import { endOfDay, startOfDay, isValid } from "date-fns";

import QueueSelect from "../../../../components/QueueSelect";
import CaseTypeSelect from "../../../../components/CaseTypeSelect";
import OfficeSelect from "../../../../components/OfficeSelect";
import UserSelect from "../../../../components/UserSelect";
import ServiceSelect from "../../../../components/ServiceSelect";
import DoctorSelect from "../../../../components/DoctorSelect";
import FilterInput from "../../../../components/FilterInput";
import ExamineeSelect from "../../../../components/ExamineeSelect";

import DateInput from "../../../../components/DateInput";

const CaseListFilters = ({
  filters,
  onChange,
  onReset,
  disableStatusFilter,
  onFilter,
}) => (
  <Box>
    <div className="list-filters">
      <Field>
        <DateInput
          getValue={(x) => {
            const date = x?.gte || x?.lte;
            return date ? date.substr(0, 10) : "";
          }}
          label="Date Added"
          name="dateadded"
          setValue={(x) => {
            if (
              x &&
              isValid(new Date(x.split("-").map((y) => parseInt(y, 10))))
            ) {
              return {
                gte: startOfDay(
                  new Date(x.split("-").map((y) => parseInt(y, 10)))
                ).toISOString(),
                lte: endOfDay(
                  new Date(x.split("-").map((y) => parseInt(y, 10)))
                ).toISOString(),
              };
            }
            return "";
          }}
          value={filters.dateadded}
          onChange={onChange}
        />
      </Field>

      <Field>
        <CaseTypeSelect
          getValue={(x) => x?.equals || ""}
          label="Case Type"
          name="casetype"
          setValue={(x) => (x ? { equals: x } : "")}
          value={filters.casetype}
          onChange={onChange}
        />
      </Field>
      <Field>
        <ServiceSelect
          showAll
          getValue={(x) => x?.equals || ""}
          label="Service"
          name="servicecode"
          setValue={(x) => (x ? { equals: parseInt(x, 10) } : "")}
          value={filters.servicecode}
          onChange={onChange}
        />
      </Field>
      <Field>
        <QueueSelect
          showAll
          disabled={disableStatusFilter}
          getValue={(x) => x?.equals || ""}
          label="Status"
          name="status"
          setValue={(x) => (x ? { equals: x } : "")}
          value={filters.status}
          onChange={onChange}
        />
      </Field>
      <Field>
        <OfficeSelect
          getValue={(x) => x?.equals || ""}
          label="Office"
          name="officecode"
          setValue={(x) => (x ? { equals: x } : "")}
          value={filters.officecode}
          onChange={onChange}
        />
      </Field>
      <Field>
        <UserSelect
          getValue={(x) => x?.equals || ""}
          label="Marketer"
          name="marketercode"
          setValue={(x) => (x ? { equals: x } : "")}
          value={filters.marketercode}
          where={{
            fldActive: { equals: 1 },
            OR: [{ usertype: { equals: "MK" } }, { usertype: { equals: "*" } }],
          }}
          onChange={onChange}
        />
      </Field>
      <Field>
        <UserSelect
          getValue={(x) => x?.equals || ""}
          label="Scheduler"
          name="schedulercode"
          setValue={(x) => (x ? { equals: x } : "")}
          value={filters.schedulercode}
          where={{
            fldActive: { equals: 1 },
            OR: [{ usertype: { equals: "SC" } }, { usertype: { equals: "*" } }],
          }}
          onChange={onChange}
        />
      </Field>
      <Field>
        <Control>
          <DoctorSelect
            getValue={(x) => x?.equals || ""}
            label="Doctor"
            name="doctorcode"
            setValue={(x) => (x ? { equals: x } : "")}
            value={filters.doctorcode}
            onChange={onChange}
          />
        </Control>
      </Field>
      <Field>
        <UserSelect
          getValue={(x) => x?.equals || ""}
          label="QA Rep"
          name="QARep"
          setValue={(x) => (x ? { equals: x } : "")}
          value={filters.QARep}
          where={{
            fldActive: { equals: 1 },
            OR: [{ usertype: { equals: "QA" } }, { usertype: { equals: "*" } }],
          }}
          onChange={onChange}
        />
      </Field>
      <Field>
        <FilterInput
          getValue={(x) => (x && x.equals ? x.equals : "")}
          label="Claim"
          name="claimnbr"
          placeholder="Claim"
          setValue={(x) => (x ? { equals: x } : "")}
          value={filters.claimnbr || ""}
          onChange={onChange}
        />
      </Field>
      <Field>
        <FilterInput
          getValue={(x) => x?.equals || ""}
          label="Internal Case"
          name="sinternalcasenbr"
          placeholder="Internal Case"
          setValue={(x) => (x ? { equals: x } : "")}
          value={filters.sinternalcasenbr || ""}
          onChange={onChange}
        />
      </Field>
      {/* <Field>
        <FilterInput
          getValue={(x) => {
            if (!x) return "";
            const firstname = x?.is?.firstname?.startsWith;
            const lastname = x?.is?.lastname?.startsWith;
            return [lastname, firstname].filter(Boolean).join(", ");
          }}
          label="Claimant Name"
          name="examinee"
          placeholder="Smith, John"
          setValue={(x) => {
            if (!x) return "";
            const [lastname, firstname] = x.split(",").map((str) => str.trim());
            const obj = {};
            if (lastname) {
              obj.is = { lastname: { startsWith: lastname } };
            }
            if (firstname) {
              obj.is = obj.is
                ? { ...obj.is, firstname: { startsWith: firstname } }
                : { firstname: { startsWith: firstname } };
            }
            return obj;
          }}
          value={filters.examinee || ""}
          onChange={(name, value) => onChange(name, value)}
        />
      </Field> */}
      <Field>
        <ExamineeSelect
          getValue={(x) => x?.equals || ""}
          label="Claimant / Examinee Name"
          name="chartnbr"
          setValue={(x) => (x ? { equals: parseInt(x, 10) } : "")}
          value={filters.chartnbr}
          onChange={onChange}
        />
      </Field>
      <Field>
        <FilterInput
          getValue={(x) => {
            if (x?.is?.documenttype?.equals === "IN") {
              return x?.is?.documentnbr?.equals;
            }
            return "";
          }}
          label="Invoice Number"
          maxLength={10}
          name="AcctHeader"
          placeholder="Invoice Number"
          setValue={(x) =>
            x && !isNaN(parseInt(x, 10))
              ? {
                  is: {
                    documentnbr: { equals: parseInt(x, 10) },
                    documenttype: { equals: "IN" },
                  },
                }
              : ""
          }
          value={
            filters.AcctHeader?.is?.documenttype?.equals === "IN"
              ? filters.AcctHeader
              : ""
          }
          onChange={onChange}
        />
      </Field>

      <Field>
        <FilterInput
          getValue={(x) => {
            if (x?.is?.documenttype?.equals === "VO") {
              return x?.is?.documentnbr?.equals;
            }
            return "";
          }}
          label="Voucher Number"
          maxLength={10}
          name="AcctHeader"
          placeholder="Voucher Number"
          setValue={(x) =>
            x && !isNaN(parseInt(x, 10))
              ? {
                  is: {
                    documentnbr: { equals: parseInt(x, 10) },
                    documenttype: { equals: "VO" },
                  },
                }
              : ""
          }
          value={
            filters.AcctHeader?.is?.documenttype?.equals === "VO"
              ? filters.AcctHeader
              : ""
          }
          onChange={onChange}
        />
      </Field>
      <Field>
        <DateInput
          getValue={(x) => {
            const date = x?.gte;
            return date ? date.substr(0, 10) : "";
          }}
          label="Appt. Start Date"
          name="Appttime"
          setValue={(x) => {
            if (
              x &&
              isValid(new Date(x.split("-").map((y) => parseInt(y, 10))))
            ) {
              const where = {
                gte: startOfDay(
                  new Date(x.split("-").map((y) => parseInt(y, 10)))
                ).toISOString(),
              };
              if (filters?.Appttime?.lte) {
                where.lte = filters?.Appttime?.lte;
              }
              return where;
            }
            return "";
          }}
          value={filters.Appttime}
          onChange={onChange}
        />
      </Field>
      <Field>
        <DateInput
          getValue={(x) => {
            const date = x?.lte;
            if (date) {
              const localDate = new Date(date);
              const offset = localDate.getTimezoneOffset() * 60 * 1000;
              const localTime = new Date(localDate - offset).toISOString();
              return localTime.substr(0, 10);
            }
            return "";
          }}
          label="Appt. End Date"
          name="Appttime"
          setValue={(x) => {
            if (
              x &&
              isValid(new Date(x.split("-").map((y) => parseInt(y, 10))))
            ) {
              const where = {
                lte: endOfDay(
                  new Date(x.split("-").map((y) => parseInt(y, 10)))
                ).toISOString(),
              };
              if (filters?.Appttime?.gte) {
                where.gte = filters.Appttime.gte;
              }
              return where;
            }
            return "";
          }}
          value={filters.Appttime}
          onChange={onChange}
        />
      </Field>
      <Field kind="group">
        <Control>
          <Label>&nbsp;</Label>
          <Button color="danger" size="small" onClick={onReset}>
            Reset
          </Button>
        </Control>
        <Control>
          <Label>&nbsp;</Label>
          <Button color="primary" size="small" onClick={onFilter}>
            Filter
          </Button>
        </Control>
      </Field>
    </div>
    {/* <hr />
    <pre>
      <code>{JSON.stringify(filters, null, 2)}</code>
    </pre> */}
  </Box>
);

CaseListFilters.propTypes = {
  filters: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  onFilter: PropTypes.func,
  disableStatusFilter: PropTypes.bool,
};

CaseListFilters.defaultProps = {
  disableStatusFilter: false,
  onFilter: () => {},
};

export default CaseListFilters;
