import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const NoDataResults = () => (
  <div className="no-data-container">
    <div className="icon-container">
      <FontAwesomeIcon icon="search" />
    </div>
    <span>Please Enter Some Search Criteria</span>
  </div>
);

export default NoDataResults;
