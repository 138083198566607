import React from "react";
import PropTypes from "prop-types";
import { Field, Control, Input, Label } from "rbx";

import UserTypeSelect from "../../../../../components/UserTypeSelect";

const UserForm = ({ inputs, onChange, userid }) => (
  <div>
    <Field kind="group">
      <Control expanded>
        <Label>User ID</Label>
        <Input
          autoComplete="new"
          disabled={!!userid.length}
          maxLength="30"
          name="userid"
          size="small"
          value={userid.length ? userid : inputs.userid}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
      <Control expanded>
        <Label>First Name</Label>
        <Input
          autoComplete="new"
          maxLength="30"
          name="firstname"
          size="small"
          value={inputs.firstname}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
      <Control expanded>
        <Label>Last Name</Label>
        <Input
          autoComplete="new"
          maxLength="30"
          name="lastname"
          size="small"
          value={inputs.lastname}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
    </Field>
    <Field kind="group">
      <Control expanded>
        <Label>Email</Label>
        <Input
          autoComplete="new"
          maxLength="30"
          name="email"
          size="small"
          value={inputs.email}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
      <UserTypeSelect
        label="User Type"
        name="usertype"
        value={inputs.usertype}
        onChange={(name, value) => onChange(name, value)}
      />
    </Field>
  </div>
);

UserForm.propTypes = {
  inputs: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  userid: PropTypes.string,
};

UserForm.defaultProps = {
  userid: "",
};

export default UserForm;
