import React from "react";
import PropTypes from "prop-types";
import { Field, Control, Label, Button } from "rbx";

import OfficeSelect from "../../components/OfficeSelect";
import UserSelect from "../../components/UserSelect";
import LocationSelect from "../../components/LocationSelect";
import CompanySelect from "../../components/CompanySelect";
import DoctorSelect from "../../components/DoctorSelect";
import ExamineeSelect from "../../components/ExamineeSelect";

const CaseTrackerFilters = ({ where, onChange, onReset }) => (
  <React.Fragment>
    <Field>
      <OfficeSelect
        getValue={(x) => x?.equals || ""}
        name="officecode"
        setValue={(x) => (x ? { equals: parseInt(x, 10) } : "")}
        value={where.officecode}
        onChange={onChange}
      />
    </Field>
    <Field>
      <UserSelect
        getValue={(x) => x?.equals || ""}
        label="Marketer"
        name="marketercode"
        setValue={(x) => (x ? { equals: x } : "")}
        value={where.marketercode}
        where={{
          fldActive: { equals: 1 },
          OR: [{ usertype: { equals: "MK" } }, { usertype: { equals: "*" } }],
        }}
        onChange={onChange}
      />
    </Field>
    <Field>
      <UserSelect
        getValue={(x) => x?.equals || ""}
        label="QA Rep"
        name="QARep"
        setValue={(x) => (x ? { equals: x } : "")}
        value={where.QARep}
        where={{
          fldActive: { equals: 1 },
          OR: [{ usertype: { equals: "QA" } }, { usertype: { equals: "*" } }],
        }}
        onChange={onChange}
      />
    </Field>
    <Field>
      <UserSelect
        getValue={(x) => x?.equals || ""}
        label="Scheduler"
        name="schedulercode"
        setValue={(x) => (x ? { equals: x } : "")}
        value={where.schedulercode}
        where={{
          fldActive: { equals: 1 },
          OR: [{ usertype: { equals: "SC" } }, { usertype: { equals: "*" } }],
        }}
        onChange={onChange}
      />
    </Field>

    <Field>
      <LocationSelect
        getValue={(x) => x?.equals || ""}
        label="Location"
        name="doctorlocation"
        setValue={(x) => (x ? { equals: parseInt(x, 10) } : "")}
        value={where.doctorlocation}
        onChange={onChange}
      />
    </Field>
    <Field>
      <CompanySelect
        fullWidth
        getValue={(x) => x?.is?.companycode?.equals || ""}
        label="Company"
        name="client"
        setValue={(x) =>
          x ? { is: { companycode: { equals: parseInt(x, 10) } } } : ""
        }
        value={where.client}
        onChange={onChange}
      />
    </Field>
    <Field>
      <DoctorSelect
        getValue={(x) => x?.equals || ""}
        label="Doctor"
        name="doctorcode"
        setValue={(x) => (x ? { equals: parseInt(x, 10) } : "")}
        value={where.doctorcode}
        onChange={onChange}
      />
    </Field>
    <Field>
      <ExamineeSelect
        getValue={(x) => x?.equals || ""}
        label="Examinee"
        name="chartnbr"
        setValue={(x) => (x ? { equals: parseInt(x, 10) } : "")}
        value={where.chartnbr}
        onChange={onChange}
      />
    </Field>
    <Field>
      <Control>
        <Label>&nbsp;</Label>
        <Button color="primary" size="small" onClick={onReset}>
          Reset
        </Button>
      </Control>
    </Field>
  </React.Fragment>
);

CaseTrackerFilters.propTypes = {
  where: PropTypes.object,
  onChange: PropTypes.func,
  onReset: PropTypes.func,
};

CaseTrackerFilters.defaultProps = {
  where: {},
  onChange: () => null,
  onReset: () => null,
};
export default CaseTrackerFilters;
