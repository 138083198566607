import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Control, Label, Select } from "rbx";
import { useLazyQuery } from "@apollo/client";

import { LIST_CLIENT_TYPE_QUERY } from "../../graphql/clients";

const ClientTypeSelect = ({
  value,
  name,
  label,
  size,
  onChange,
  disabled,
  getValue,
  setValue,
}) => {
  const [clientTypes, setClientTypes] = useState(null);
  const [getClientType, { data: getClientTypeData }] = useLazyQuery(
    LIST_CLIENT_TYPE_QUERY
  );

  useEffect(() => {
    getClientType({
      variables: { orderBy: { Description: "asc" } },
    });
  }, [getClientType]);

  useEffect(() => {
    setClientTypes(getClientTypeData?.clientTypes);
  }, [getClientTypeData]);

  const handleChange = (e) => {
    onChange(
      e.target.name,
      setValue(e.target.value ? parseInt(e.target.value, 10) : "")
    );
  };

  return (
    <Control>
      <Label>{label}</Label>
      <Select.Container
        fullwidth
        size={size}
        state={Array.isArray(clientTypes) ? "" : "loading"}
      >
        <Select
          disabled={disabled}
          name={name}
          value={getValue(value)}
          onChange={handleChange}
        >
          <Select.Option value="" />
          {Array.isArray(clientTypes) &&
            clientTypes.map((clientType) => (
              <Select.Option
                key={clientType.typecode}
                value={clientType.typecode}
              >
                {clientType.Description}
              </Select.Option>
            ))}
        </Select>
      </Select.Container>
    </Control>
  );
};

ClientTypeSelect.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.number,
  ]),
  name: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  getValue: PropTypes.func,
  setValue: PropTypes.func,
};

ClientTypeSelect.defaultProps = {
  value: "",
  name: "",
  label: "Client Type",
  size: "small",
  onChange: () => null,
  disabled: false,
  getValue: (x) => x,
  setValue: (x) => x,
};

export default ClientTypeSelect;
