import React from "react";
import PropTypes from "prop-types";
import { Field, Control, Input, Label, Help } from "rbx";

const NoteTypeForm = ({ inputs, onChange }) => (
  <div>
    <Field>
      <Control>
        <Label>Note Type</Label>
        <Input
          autoComplete="new"
          maxLength={100}
          name="NoteType"
          size="small"
          value={inputs.NoteType}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
        <Help color="danger">Note Type must be 100 characters or less</Help>
      </Control>
    </Field>
  </div>
);

NoteTypeForm.propTypes = {
  inputs: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default NoteTypeForm;
