import React, { useMemo, useContext } from "react";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/client";
import { Column, Title, Icon, Button } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { ModalContext } from "../../../context/ModalContext";
import FlatDataTable from "../../../components/FlatDataTable";
import Confirmation from "../../../components/Confirmation";
import { customToast as toast } from "../../../utils";
import CaseIssue from "./CaseIssue";
import CaseProblem from "./CaseProblem";
import CaseOtherParty from "./CaseOtherParty";
import {
  LIST_CASE_ISSUES_QUERY,
  LIST_CASE_PROBLEMS_QUERY,
  LIST_CASE_OTHER_PARTY_QUERY,
  DELETE_CASE_ISSUE_MUTATION,
  DELETE_CASE_PROBLEM_MUTATION,
  DELETE_CASE_OTHER_PARTY_MUTATION,
} from "../../../graphql";
import {
  COLUMNS_ISSUES as generateIssuesColumns,
  COLUMNS_PROBLEMS as generateProblemsColumns,
  COLUMNS_OTHER_PARTY as generateOtherPartyColumns,
} from "./columns";

const CaseParametersTables = ({ inputs, disabled }) => {
  const { setModalOpen } = useContext(ModalContext);
  const [deleteCaseIssue] = useMutation(DELETE_CASE_ISSUE_MUTATION);
  const [deleteCaseProblem] = useMutation(DELETE_CASE_PROBLEM_MUTATION);
  const [deleteCaseOtherService] = useMutation(
    DELETE_CASE_OTHER_PARTY_MUTATION
  );

  const COLUMNS_ISSUES = useMemo(() => {
    const handleDeleteIssue = (issue) => {
      const performDelete = async () => {
        try {
          await deleteCaseIssue({
            variables: {
              where: {
                casenbr_issuecode: {
                  casenbr: issue.casenbr,
                  issuecode: issue.issuecode,
                },
              },
            },
            refetchQueries: [
              {
                query: LIST_CASE_ISSUES_QUERY,
                variables: {
                  where: {
                    casenbr: { equals: inputs.casenbr },
                  },
                  orderBy: [{ issuecode: "asc" }],
                },
                fetchPolicy: "cache-first",
              },
            ],
          });
          toast.success("Case Issue deleted successfully");
          setModalOpen(false);
        } catch (err) {
          toast.error("Error on delete Case Issue");
        }
      };
      setModalOpen(
        true,
        <Confirmation
          message="Are you sure you want to delete this issue?"
          onCancel={() => setModalOpen(false)}
          onConfirm={performDelete}
        />
      );
    };
    return generateIssuesColumns(handleDeleteIssue, disabled);
  }, [setModalOpen, deleteCaseIssue, inputs?.casenbr, disabled]);

  const COLUMNS_PROBLEMS = useMemo(() => {
    const handleDeleteProblem = (problem) => {
      const performDelete = async () => {
        try {
          await deleteCaseProblem({
            variables: {
              where: {
                casenbr_problemcode: {
                  casenbr: problem.casenbr,
                  problemcode: problem.problemcode,
                },
              },
            },
            refetchQueries: [
              {
                query: LIST_CASE_PROBLEMS_QUERY,
                variables: {
                  where: {
                    casenbr: { equals: inputs.casenbr },
                  },
                  orderBy: [{ problemcode: "asc" }],
                },
                fetchPolicy: "cache-first",
              },
            ],
          });
          toast.success("Case Problem deleted successfully");
          setModalOpen(false);
        } catch (err) {
          toast.error("Error on delete Case Problem");
        }
      };
      setModalOpen(
        true,
        <Confirmation
          message="Are you sure you want to delete this problem?"
          onCancel={() => setModalOpen(false)}
          onConfirm={performDelete}
        />
      );
    };
    return generateProblemsColumns(handleDeleteProblem, disabled);
  }, [setModalOpen, deleteCaseProblem, inputs?.casenbr, disabled]);

  const COLUMNS_OTHER_PARTY = useMemo(() => {
    const handleDeleteOtherParty = (otherParty) => {
      const performDelete = async () => {
        try {
          await deleteCaseOtherService({
            variables: {
              where: {
                casenbr_OPCode: {
                  casenbr: otherParty.casenbr,
                  OPCode: otherParty.OPCode,
                },
              },
            },
            refetchQueries: [
              {
                query: LIST_CASE_OTHER_PARTY_QUERY,
                variables: {
                  where: {
                    casenbr: { equals: inputs.casenbr },
                  },
                  orderBy: [{ useridresponsible: "asc" }],
                },
                fetchPolicy: "cache-first",
              },
            ],
          });
          toast.success("Case Other Service deleted successfully");
          setModalOpen(false);
        } catch (err) {
          toast.error("Error on delete Case Other Service");
        }
      };
      setModalOpen(
        true,
        <Confirmation
          message="Are you sure you want to delete this service?"
          onCancel={() => setModalOpen(false)}
          onConfirm={performDelete}
        />
      );
    };
    return generateOtherPartyColumns(handleDeleteOtherParty, disabled);
  }, [setModalOpen, deleteCaseOtherService, inputs?.casenbr, disabled]);

  return (
    <Column.Group multiline>
      <Column size={4}>
        <div className="page-head">
          <div className="page-head-start">
            <Button
              color="success"
              size="small"
              type="button"
              onClick={() => {
                setModalOpen(
                  true,
                  <CaseIssue
                    casenbr={inputs.casenbr}
                    handleCancel={() => setModalOpen(false)}
                  />
                );
              }}
            >
              <Icon>
                <FontAwesomeIcon icon="plus" />
              </Icon>
              <span>Add</span>
            </Button>
            <Title size={6}>Issues</Title>
          </div>
        </div>
        <div style={{ maxHeight: 400, minHeight: 100, overflowY: "auto" }}>
          <FlatDataTable
            columns={COLUMNS_ISSUES}
            name="caseIssues"
            orderBy={[{ id: "issuecode", desc: false }]}
            query={LIST_CASE_ISSUES_QUERY}
            where={{ casenbr: { equals: inputs.casenbr } }}
            onCellClick={(cell) => {
              if (cell.column.id !== "Delete") {
                setModalOpen(
                  true,
                  <CaseIssue
                    casenbr={inputs.casenbr}
                    handleCancel={() => setModalOpen(false)}
                    issuecode={cell.row.original.issuecode}
                  />
                );
              }
            }}
          />
        </div>
      </Column>
      <Column size={4}>
        <div className="page-head">
          <div className="page-head-start">
            <Button
              color="success"
              size="small"
              type="button"
              onClick={() => {
                setModalOpen(
                  true,
                  <CaseProblem
                    casenbr={inputs.casenbr}
                    handleCancel={() => setModalOpen(false)}
                  />
                );
              }}
            >
              <Icon>
                <FontAwesomeIcon icon="plus" />
              </Icon>
              <span>Add</span>
            </Button>
            <Title size={6}>Problems</Title>
          </div>
        </div>
        <div style={{ maxHeight: 400, minHeight: 100, overflowY: "auto" }}>
          <FlatDataTable
            columns={COLUMNS_PROBLEMS}
            name="caseProblems"
            orderBy={[{ id: "problemcode", desc: false }]}
            query={LIST_CASE_PROBLEMS_QUERY}
            where={{ casenbr: { equals: inputs.casenbr } }}
            onCellClick={(cell) => {
              if (cell.column.id !== "Delete") {
                setModalOpen(
                  true,
                  <CaseProblem
                    casenbr={inputs.casenbr}
                    handleCancel={() => setModalOpen(false)}
                    problemcode={cell.row.original.problemcode}
                  />
                );
              }
            }}
          />
        </div>
      </Column>
      <Column size={4}>
        <div className="page-head">
          <div className="page-head-start">
            <Button
              color="success"
              size="small"
              type="button"
              onClick={() => {
                setModalOpen(
                  true,
                  <CaseOtherParty
                    casenbr={inputs.casenbr}
                    handleCancel={() => setModalOpen(false)}
                  />
                );
              }}
            >
              <Icon>
                <FontAwesomeIcon icon="plus" />
              </Icon>
              <span>Add</span>
            </Button>
            <Title size={6}>Other Services</Title>
          </div>
        </div>
        <div style={{ maxHeight: 400, minHeight: 100, overflowY: "auto" }}>
          <FlatDataTable
            columns={COLUMNS_OTHER_PARTY}
            name="caseOtherParties"
            orderBy={[{ id: "useridresponsible", desc: false }]}
            query={LIST_CASE_OTHER_PARTY_QUERY}
            where={{ casenbr: { equals: inputs.casenbr } }}
            onCellClick={(cell) => {
              if (cell.column.id !== "Delete") {
                setModalOpen(
                  true,
                  <CaseOtherParty
                    casenbr={inputs.casenbr}
                    data={cell.row.original}
                    handleCancel={() => setModalOpen(false)}
                  />
                );
              }
            }}
          />
        </div>
      </Column>
    </Column.Group>
  );
};

CaseParametersTables.propTypes = {
  inputs: PropTypes.object,
  disabled: PropTypes.bool,
};

CaseParametersTables.defaultProps = {
  inputs: {},
  disabled: false,
};

export default CaseParametersTables;
