import React, { useEffect, useState, useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import { useLazyQuery, useMutation } from "@apollo/client";
import { Button, Table, Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DraggableTable from "../../../../../../components/DraggableTable";
import {
  ALL_SERVICE_QUEUES_QUERY,
  UPDATE_SERVICE_QUEUE_MUTATION,
  DELETE_STATUS_SERVICE_MUTATION,
} from "../../../../../../graphql";
import { generateColumns } from "./columns";
import { ServiceQueueModal } from "../../../../components";
import Confirmation from "../../../../../../components/Confirmation";
import Loader from "../../../../../../components/Loader";
import {
  getDateEST,
  convertTimeZoneDataBase,
  customToast as toast,
} from "../../../../../../utils";
import { useModal, useAuth } from "../../../../../../context";

const StatusDraggableList = ({ addStatus, serviceCode }) => {
  const { setModalOpen } = useModal();
  const [loading, setLoading] = useState(false);
  const [
    getServiceQueues,
    { data: serviceCodeData, loading: loadingGetServices },
  ] = useLazyQuery(ALL_SERVICE_QUEUES_QUERY);

  const [deleteStatusService] = useMutation(DELETE_STATUS_SERVICE_MUTATION);
  const [updateServiceQueue] = useMutation(UPDATE_SERVICE_QUEUE_MUTATION);
  const { state: authState } = useAuth();
  const [serviceQueues, setServiceQueues] = useState([]);

  const handleDragEnd = async (result) => {
    try {
      setLoading(true);
      const { source, destination } = result;
      if (source && destination) {
        const date = convertTimeZoneDataBase(getDateEST());
        const newItems = [...serviceQueues];
        newItems.move(source.index, destination.index);
        setServiceQueues(
          newItems.map((item, index) => ({
            ...item,
            content: {
              ...item.content,
              nextstatus: newItems[index + 1]
                ? newItems[index + 1].content.statuscode
                : "0",
              NextStatus: newItems[index + 1]
                ? {
                    statuscode: newItems[index + 1].content.queue?.statuscode,
                    statusdesc: newItems[index + 1].content.queue?.statusdesc,
                  }
                : null,
              queueorder: index + 1,
            },
          }))
        );
        const itemsToSave = newItems.map((item, index) => ({
          oldqueueorder: parseInt(item.content.queueorder, 10),
          nextstatus: newItems[index + 1]
            ? newItems[index + 1].content.statuscode
            : "0",
          servicecode: parseInt(item.content.servicecode, 10),
          queueorder: index + 1,
          statuscode: item.content.statuscode,
        }));
        await Promise.all(
          itemsToSave.map(async (serviceQueue) => {
            await updateServiceQueue({
              variables: {
                where: {
                  servicecode_queueorder_statuscode: {
                    servicecode: serviceQueue.servicecode,
                    queueorder: serviceQueue.oldqueueorder,
                    statuscode: serviceQueue.statuscode,
                  },
                },
                data: {
                  queueorder: { set: serviceQueue.queueorder },
                  nextstatus: { set: serviceQueue.nextstatus },
                  dateedited: { set: date },
                  useridedited: { set: authState?.user?.userid },
                },
              },
            });
          })
        );
      }
    } catch (err) {
      toast.error("Error saving Status.");
    } finally {
      setLoading(false);
    }
  };

  const handleRowClick = (row, index) => {
    let lastItemContent = null;
    if (index > 0) {
      lastItemContent = serviceQueues[index - 1].content;
    }
    setModalOpen(
      true,
      <ServiceQueueModal
        inputs={row.content}
        lastItem={lastItemContent}
        servicecode={serviceCode}
        onComplete={() => setModalOpen(false)}
      />
    );
  };

  useEffect(() => {
    if (serviceCode) {
      getServiceQueues({
        variables: {
          orderBy: [{ queueorder: "asc" }, { nextstatus: "asc" }],
          where: {
            servicecode: {
              equals: parseInt(serviceCode, 10),
            },
          },
        },
      });
    } else {
      setServiceQueues([]);
    }
  }, [getServiceQueues, serviceCode]);

  useEffect(() => {
    const sqs = serviceCodeData?.serviceQueues;
    if (Array.isArray(sqs)) {
      setServiceQueues(
        sqs.map((queue) => ({
          id: `${queue.servicecode}_${queue.statuscode}_${queue.queueorder}`,
          content: queue,
        }))
      );
    }
  }, [serviceCodeData?.serviceQueues]);

  const removeStatus = useCallback(
    async (status = false, position) => {
      try {
        setLoading(true);
        setModalOpen(false, "");
        await deleteStatusService({
          variables: {
            where: {
              servicecode_queueorder_statuscode: {
                servicecode: parseInt(serviceCode, 10),
                statuscode: status?.statuscode,
                queueorder: parseInt(status.queueorder, 10),
              },
            },
          },
        });
        const date = convertTimeZoneDataBase(getDateEST());
        const newItems = [...serviceQueues];
        newItems.splice(position, 1);
        setServiceQueues(
          newItems.map((item, index) => ({
            ...item,
            content: {
              ...item.content,
              nextstatus: newItems[index + 1]
                ? newItems[index + 1].content.statuscode
                : "0",
              NextStatus: newItems[index + 1]
                ? {
                    statuscode: newItems[index + 1].content.queue?.statuscode,
                    statusdesc: newItems[index + 1].content.queue?.statusdesc,
                  }
                : null,
              queueorder: index + 1,
            },
          }))
        );
        const itemsToSave = newItems.map((item, index) => ({
          oldqueueorder: parseInt(item.content.queueorder, 10),
          nextstatus: newItems[index + 1]
            ? newItems[index + 1].content.statuscode
            : "0",
          servicecode: parseInt(item.content.servicecode, 10),
          queueorder: index + 1,
          statuscode: item.content.statuscode,
        }));
        await Promise.all(
          itemsToSave.map(async (serviceQueue) => {
            await updateServiceQueue({
              variables: {
                where: {
                  servicecode_queueorder_statuscode: {
                    servicecode: serviceQueue.servicecode,
                    queueorder: serviceQueue.oldqueueorder,
                    statuscode: serviceQueue.statuscode,
                  },
                },
                data: {
                  queueorder: { set: serviceQueue.queueorder },
                  nextstatus: { set: serviceQueue.nextstatus },
                  dateedited: { set: date },
                  useridedited: { set: authState?.user?.userid },
                },
              },
              refetchQueries: [
                {
                  query: ALL_SERVICE_QUEUES_QUERY,
                  variables: {
                    orderBy: { queueorder: "asc" },
                    where: {
                      servicecode: {
                        equals: parseInt(serviceCode, 10),
                      },
                    },
                  },
                },
              ],
            });
          })
        );
        toast.success("Status to Service deleted successfully.");
      } catch (err) {
        toast.error("Error deleting Status.");
      } finally {
        setLoading(false);
      }
    },
    [
      authState?.user?.userid,
      deleteStatusService,
      serviceCode,
      serviceQueues,
      setModalOpen,
      updateServiceQueue,
    ]
  );

  const COLUMNS = useMemo(() => {
    const handleDelete = (item, position) => {
      setModalOpen(
        true,
        <Confirmation
          message="Are you sure you want to delete this Status?"
          onCancel={() => setModalOpen(false, "")}
          onConfirm={() => removeStatus(item.content, position)}
        />
      );
    };
    return generateColumns(handleDelete);
  }, [removeStatus, setModalOpen]);

  const handleAddQueue = (e) => {
    e.preventDefault();
    const { content: lastItemContent } = serviceQueues.length
      ? serviceQueues[serviceQueues.length - 1]
      : {};
    setModalOpen(
      true,
      <ServiceQueueModal
        lastItem={lastItemContent}
        queueorder={lastItemContent ? lastItemContent.queueorder + 1 : 1}
        servicecode={serviceCode}
        onComplete={() => setModalOpen(false)}
      />
    );
  };

  return (
    <div>
      <header className="page-head">
        <div className="page-head-start">
          <Button
            color="primary"
            disabled={!serviceCode || loadingGetServices || loading}
            size="small"
            state={loading ? "loading" : ""}
            type="button"
            onClick={handleAddQueue}
          >
            {!loading && (
              <Icon>
                <FontAwesomeIcon icon="plus" />
              </Icon>
            )}
            &nbsp; Add New Queue
          </Button>
        </div>
      </header>
      <div className="data-table-container" style={{ marginBottom: 0 }}>
        <Table bordered fullwidth hoverable narrow>
          <Table.Head>
            <Table.Row>
              {COLUMNS.map(({ Header, width, id }) => (
                <Table.Heading key={id} style={{ width: width || "20%" }}>
                  {Header}
                </Table.Heading>
              ))}
            </Table.Row>
          </Table.Head>
        </Table>
      </div>
      {!loadingGetServices ? (
        <DraggableTable
          columns={COLUMNS}
          items={serviceQueues}
          loading={loading}
          name="serviceQueues"
          title="Queues"
          onDragEnd={handleDragEnd}
          onRowClick={handleRowClick}
        />
      ) : (
        <div style={{ height: "100vh" }}>
          <Loader />
        </div>
      )}
    </div>
  );
};

StatusDraggableList.propTypes = {
  addStatus: PropTypes.func,
  serviceCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

StatusDraggableList.defaultProps = {
  serviceCode: "",
  addStatus: () => null,
};

export default StatusDraggableList;
