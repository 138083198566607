import React, { useState } from "react";
import PropTypes from "prop-types";
import { Title, Button } from "rbx";
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";

import OtherPartyForm from "../OtherPartyForm";
import { useAuth } from "../../../../../context";
import {
  customToast as toast,
  getDateEST,
  convertTimeZoneDataBase,
} from "../../../../../utils";
import {
  CREATE_DOCTOR_MUTATION,
  LIST_OTHER_PARTIES_QUERY,
} from "../../../../../graphql";

const INITIAL_STATE = {
  OPSubType: "",
  firstname: "",
  lastname: "",
  status: "Active",
  companyname: "",
  addr1: "",
  city: "",
  state: "",
  zip: "",
  phone: "",
};

const OtherPartyModal = ({ onComplete }) => {
  const history = useHistory();

  const [inputs, setInputs] = useState(INITIAL_STATE);
  const { state: authState } = useAuth();
  const [createOtherParty, { loading: CreateOtherPartyLoading }] = useMutation(
    CREATE_DOCTOR_MUTATION
  );

  const handleChange = (name, value) => {
    setInputs((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const date = convertTimeZoneDataBase(getDateEST());
      const { data: newOtherParty } = await createOtherParty({
        variables: {
          data: {
            OtherPartyType: {
              connect: {
                type: inputs.OPSubType,
              },
            },
            firstname: inputs.firstname,
            lastname: inputs.lastname,
            status: inputs.status,
            companyname: inputs.companyname,
            addr1: inputs.addr1,
            city: inputs.city,
            state: inputs.state,
            zip: inputs.zip,
            phone: inputs.phone,
            notes: inputs.notes,
            OPType: "OP",
            useridedited: authState.user.userid,
            dateedited: date,
            useridadded: authState.user.userid,
            dateadded: date,
          },
          refetchQueries: [
            {
              query: LIST_OTHER_PARTIES_QUERY,
              variables: {
                where: {
                  OPType: {
                    equals: "OP",
                  },
                },
              },
            },
          ],
        },
      });
      toast.success("Other Party created successfully");
      if (newOtherParty?.createDoctor) {
        history.push(
          `/setup/other-party/${newOtherParty.createDoctor.doctorcode}/profile`
        );
      }
      onComplete();
    } catch (err) {
      toast.error("Error creating Other Party");
    }
  };

  const isDisabled =
    Object.keys(inputs)
      .map(
        (key) =>
          /${firstname|lastname|companyname|addr1|city|state|zip|phone|OPSubType}/.test(
            key
          ) && !inputs[key]
      )
      .includes(true) || CreateOtherPartyLoading;

  return (
    <form id="other-party-form" onSubmit={handleSubmit}>
      <header className="page-head">
        <div className="page-head-start">
          <Title size={5}>Create Other Party</Title>
        </div>
        <div className="page-head-end">
          <Button.Group hasAddons>
            <Button
              disabled={CreateOtherPartyLoading}
              size="small"
              type="button"
              onClick={() => onComplete(false)}
            >
              <span>Cancel</span>
            </Button>
            <Button
              color="primary"
              disabled={isDisabled}
              form="other-party-form"
              size="small"
              state={CreateOtherPartyLoading ? "loading" : ""}
              type="submit"
            >
              <span>Submit</span>
            </Button>
          </Button.Group>
        </div>
      </header>
      <hr />
      <OtherPartyForm
        disabled={CreateOtherPartyLoading}
        inputs={inputs}
        onChange={handleChange}
      />
      <hr />
    </form>
  );
};

OtherPartyModal.propTypes = {
  onComplete: PropTypes.func,
};

OtherPartyModal.defaultProps = {
  onComplete: () => null,
};

export default OtherPartyModal;
