import React from "react";

import { Switch, Route } from "react-router-dom";

import DocumentListPage from "./DocumentListPage";
import DocumentPage from "./DocumentPage";

const DocumentRouter = () => (
  <Switch>
    <Route path="/documents/:id">
      <DocumentPage />
    </Route>
    <Route path="/documents">
      <DocumentListPage />
    </Route>
  </Switch>
);

export default DocumentRouter;
