import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Title, Button } from "rbx";
import { useApolloClient, useMutation, useLazyQuery } from "@apollo/client";
import { useModal } from "../../../../../context";
import {
  LIST_DOCTORS_QUERY,
  CREATE_DEGREE_MUTATION,
  UPDATE_DEGREE_MUTATION,
  // CREATE_AUDIT_TRAIL_MUTATION,
  DELETE_DEGREE_MUTATION,
  ALL_DEGREES_QUERY,
  SINGLE_DEGREE_QUERY,
} from "../../../../../graphql";
import { customToast as toast } from "../../../../../utils";
import Confirmation from "../../../../../components/Confirmation";
import DoctorDegreeForm from "../DoctorDegreeForm";

const INITIAL_STATE = {
  degreecode: "",
  description: "",
};

const DoctorDegreeModal = ({ onComplete, degreecode, where }) => {
  const client = useApolloClient();
  const { setModalOpen } = useModal();
  // const { state: authState } = useAuth();
  const [inputs, setInputs] = useState(INITIAL_STATE);
  const [updated, setUpdated] = useState([]);
  const [loading, setLoading] = useState(false);
  const [getDegree, { data: degreeData, loading: queryLoading }] = useLazyQuery(
    SINGLE_DEGREE_QUERY
  );
  const [createDoctorDegree] = useMutation(CREATE_DEGREE_MUTATION);
  const [updateDoctorDegree] = useMutation(UPDATE_DEGREE_MUTATION);
  // const [createAuditTrail] = useMutation(CREATE_AUDIT_TRAIL_MUTATION);
  const [deleteDegree] = useMutation(DELETE_DEGREE_MUTATION);

  useEffect(() => {
    if (degreecode) {
      getDegree({
        variables: {
          where: {
            degreecode,
          },
        },
      });
    }
  }, [degreecode, getDegree]);

  useEffect(() => {
    if (degreeData?.degree) {
      setInputs({
        degreecode: degreeData.degree.degreecode || "",
        description: degreeData.degree.description || "",
      });
    }
  }, [degreeData]);

  const handleChange = (name, value) => {
    setInputs((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (degreecode) {
      setUpdated((prev) => (prev.includes(name) ? prev : [...prev, name]));
    }
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      if (!degreecode) {
        await createDoctorDegree({
          variables: {
            data: inputs,
          },
          refetchQueries: [
            {
              query: ALL_DEGREES_QUERY,
              variables: {
                where,
                orderBy: [{ degreecode: "asc" }],
                skip: 0,
                take: 25,
              },
            },
          ],
        });
        // await createAuditTrail({
        //   variables: {
        //     data: {
        //       tablename: "tblDegree",
        //       entityid: createDegreeData.createDegree.degreecode,
        //       type: "New",
        //       description: "New degree created",
        //       useridadded: authState.user.userid,
        //       dateadded: getDateEST(),
        //     },
        //   },
        //   refetchQueries: [
        //     {
        //       query: SINGLE_DEGREE_QUERY,
        //       variables: {
        //         where: {
        //           degreecode: createDegreeData.createDegree.degreecode,
        //         },
        //       },
        //     },
        //   ],
        // });
        toast.success("Degree created successfully.");
      } else {
        await updateDoctorDegree({
          variables: {
            data: {
              degreecode: { set: inputs.degreecode },
              description: { set: inputs.description },
            },
            where: {
              degreecode,
            },
          },
          refetchQueries: [
            {
              query: ALL_DEGREES_QUERY,
              variables: {
                where,
                orderBy: [{ degreecode: "asc" }],
                skip: 0,
                take: 25,
              },
            },
            {
              query: SINGLE_DEGREE_QUERY,
              variables: {
                where: {
                  degreecode,
                },
              },
            },
          ],
        });
        // await createAuditTrail({
        //   variables: {
        //     data: {
        //       tablename: "tblDegree",
        //       entityid: degreecode,
        //       type: "Update",
        //       description: `Updated fields: ${updated.join(", ")}`,
        //       useridadded: authState.user.userid,
        //       dateadded: getDateEST(),
        //     },
        //   },
        // });
        toast.success("Degree updated successfully.");
      }
      onComplete();
    } catch (err) {
      if (err.message.includes("Unique constraint")) {
        toast.error("This code degree already exists");
      } else {
        toast.error("Error saving degree");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    const { data: doctorsData } = await client.query({
      query: LIST_DOCTORS_QUERY,
      variables: {
        where: {
          credentials: { equals: degreecode },
        },
        take: 1,
        skip: 0,
      },
    });
    if (doctorsData?.doctors?.length > 0) {
      toast.error(
        "This Degree can't be removed because there's some Doctors related"
      );
    } else {
      const performDelete = async () => {
        try {
          setLoading(true);
          await deleteDegree({
            variables: {
              where: {
                degreecode,
              },
            },
            refetchQueries: [
              {
                query: ALL_DEGREES_QUERY,
                variables: {
                  where,
                  orderBy: [{ degreecode: "asc" }],
                  take: 25,
                  skip: 0,
                },
              },
              {
                query: SINGLE_DEGREE_QUERY,
                variables: {
                  where: {
                    degreecode,
                  },
                },
              },
            ],
          });
          toast.success("Degree deleted successfully");
          onComplete();
        } catch (err) {
          toast.error("Error trying to delete Degree");
        } finally {
          setLoading(false);
        }
      };
      setModalOpen(
        true,
        <Confirmation
          message="Are you sure you want to delete this Degree?"
          onCancel={() => setModalOpen(false)}
          onConfirm={performDelete}
        />
      );
    }
  };

  const isDisabled =
    !inputs.degreecode ||
    (degreecode && !updated.length) ||
    queryLoading ||
    loading;

  return (
    <form id="doctor-degree-form" onSubmit={handleSubmit}>
      <header className="page-head">
        <div className="page-head-start">
          <Title size={5}>
            {`${!degreecode ? "Create" : "Edit"}`}
            &nbsp;Doctor Degree
          </Title>
        </div>
        <div className="page-head-end">
          <Button.Group hasAddons>
            <Button
              size="small"
              type="button"
              onClick={() => onComplete(false)}
            >
              <span>Cancel</span>
            </Button>
            <Button
              color="primary"
              disabled={isDisabled}
              form="doctor-degree-form"
              size="small"
              state={loading ? "loading" : ""}
              type="submit"
            >
              <span>Submit</span>
            </Button>
          </Button.Group>
        </div>
      </header>
      <hr />
      <DoctorDegreeForm inputs={inputs} onChange={handleChange} />
      <hr />
      {degreecode && (
        <Button
          color="danger"
          size="small"
          type="button"
          onClick={handleDelete}
        >
          <span>Delete</span>
        </Button>
      )}
    </form>
  );
};
DoctorDegreeModal.propTypes = {
  onComplete: PropTypes.func,
  degreecode: PropTypes.string,
  where: PropTypes.object,
};
DoctorDegreeModal.defaultProps = {
  onComplete: () => null,
  degreecode: null,
  where: {},
};
export default DoctorDegreeModal;
