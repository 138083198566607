/* eslint-disable import/prefer-default-export */
import { gql } from "@apollo/client";

export const LIST_PROBLEMS_QUERY = gql`
  query problems(
    $take: Int
    $orderBy: [ProblemOrderByInput!]
    $skip: Int
    $where: ProblemWhereInput
  ) {
    problems(take: $take, orderBy: $orderBy, skip: $skip, where: $where) {
      PublishOnWeb
      WebSynchDate
      dateadded
      dateedited
      description
      problemcode
      status
      useridadded
      useridedited
    }
    aggregateProblem: aggregateProblem(where: $where) {
      count {
        problemcode
      }
    }
  }
`;

export const SINGLE_PROBLEM_QUERY = gql`
  query problem($where: ProblemWhereUniqueInput!) {
    problem(where: $where) {
      PublishOnWeb
      WebSynchDate
      dateadded
      dateedited
      description
      problemcode
      status
      useridadded
      useridedited
    }
  }
`;
