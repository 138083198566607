import { gql } from "@apollo/client";

export const CREATE_COMPANY_MUTATION = gql`
  mutation createCompany($data: CompanyCreateInput!) {
    createCompany(data: $data) {
      companycode
    }
  }
`;

export const UPDATE_COMPANY_MUTATION = gql`
  mutation updateCompany(
    $data: CompanyUpdateInput!
    $where: CompanyWhereUniqueInput!
  ) {
    updateCompany(data: $data, where: $where) {
      companycode
    }
  }
`;

export const DELETE_COMPANY_MUTATION = gql`
  mutation deleteCompany($where: CompanyWhereUniqueInput!) {
    deleteCompany(where: $where) {
      companycode
    }
  }
`;

export const CREATE_COMPANY_TO_GROUP_MUTATION = gql`
  mutation createCompanyToCompanyGroup(
    $data: CompanyToCompanyGroupCreateInput!
  ) {
    createCompanyToCompanyGroup(data: $data) {
      CompanyGroupID
    }
  }
`;

export const UPDATE_COMPANY_TO_GROUP_MUTATION = gql`
  mutation updateCompanyToCompanyGroup(
    $data: CompanyToCompanyGroupUpdateInput!
    $where: CompanyToCompanyGroupWhereUniqueInput!
  ) {
    updateCompanyToCompanyGroup(data: $data, where: $where) {
      CompanyGroupID
    }
  }
`;

export const DELETE_COMPANY_TO_COMPANY_GROUP_MUTATION = gql`
  mutation deleteCompanyToCompanyGroup(
    $where: CompanyToCompanyGroupWhereUniqueInput!
  ) {
    deleteCompanyToCompanyGroup(where: $where) {
      id
    }
  }
`;
