import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { AsyncPaginate } from "react-select-async-paginate";

import { Label, Control } from "rbx";
import { useApolloClient, useLazyQuery } from "@apollo/client";
import {
  ALL_LOCATIONS_QUERY,
  SINGLE_LOCATION_QUERY,
} from "../../graphql/location";

const QUERY_LIMIT = 50;

const LocationSelect = ({
  name,
  value: initialValue,
  label,
  onChange,
  getValue,
  setValue,
}) => {
  const client = useApolloClient();

  const [getLocation, { data: getLocationData }] = useLazyQuery(
    SINGLE_LOCATION_QUERY
  );

  const [value, setInternalValue] = useState(null);
  const [defaultOptions, setDefaultOptions] = useState([]);

  const promiseOptions = async (inputValue, { length }) => {
    const { data: locationsData } = await client.query({
      query: ALL_LOCATIONS_QUERY,
      variables: {
        take: QUERY_LIMIT,
        where: { location: { startsWith: inputValue } },
        orderBy: { location: "asc" },
        skip: length,
      },
    });
    const locations = locationsData?.locations;

    const result = Array.isArray(locations)
      ? locations.map((location) => ({
          value: location.locationcode,
          label: location.location,
        }))
      : [];

    setDefaultOptions(result);

    return { options: result, hasMore: result.length === QUERY_LIMIT };
  };

  const handleChange = ({ value: newValue }) => {
    onChange(name, setValue(newValue));
  };

  useEffect(() => {
    if (initialValue) {
      getLocation({
        variables: {
          where: { locationcode: parseInt(getValue(initialValue), 10) },
        },
      });
    } else {
      setInternalValue(null);
    }
  }, [initialValue, getLocation, getValue]);

  useEffect(() => {
    const location = getLocationData?.location;
    if (location && initialValue) {
      setInternalValue({
        value: location.locationcode,
        label: location.location,
      });
    }
  }, [getLocationData, initialValue]);

  useEffect(() => {
    if (value) {
      const currOptions = [...defaultOptions];
      if (!currOptions.some((opt) => opt.value === value.value)) {
        setDefaultOptions((prev) => [value, ...prev]);
      }
    }
  }, [value, defaultOptions]);

  return (
    <Control expanded>
      <Label>{label}</Label>
      <AsyncPaginate
        cacheOptions
        debounceTimeout={300}
        defaultOptions={defaultOptions}
        loadOptions={promiseOptions}
        placeholder="Start typing..."
        styles={{
          indicatorsContainer: (provided) => ({
            ...provided,
            height: "inherit",
          }),
          indicatorSeparator: (provided) => ({
            ...provided,
            display: "none",
          }),
          container: (provided) => ({
            fontSize: 13,
          }),
          singleValue: (provided) => ({
            ...provided,
            top: "38%",
          }),
          control: (provided) => ({
            ...provided,
            minHeight: 31,
            height: 31,
            width: "100%",
            minWidth: 180,
            "&:hover": {
              borderColor: "hsl(0, 0%, 90%)",
              borderRadius: 4,
              cursor: "pointer",
            },
          }),
          placeholder: (provided) => ({ ...provided, top: "38%" }),
          menu: (provided) => ({
            ...provided,
            fontSize: 12,
            zIndex: 999,
          }),
        }}
        value={value}
        onChange={handleChange}
      />
    </Control>
  );
};

LocationSelect.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  getValue: PropTypes.func,
  setValue: PropTypes.func,
};

LocationSelect.defaultProps = {
  name: "",
  label: "",
  value: "",
  onChange: () => null,
  getValue: (x) => x,
  setValue: (x) => x,
};

export default LocationSelect;
