import React from "react";
import { Generic, Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { format } from "date-fns";

import { convertTimeZone } from "../../../../utils";
import CaseDocumentDownloadLink from "../../components/CaseDocumentDownloadLink";

export const DEFAULT_COLUMNS = [
  {
    id: "description",
    Header: "Description",
    accessor: "description",
  },
  {
    id: "reporttype",
    Header: "Type",
    accessor: "reporttype",
  },
  {
    id: "sfilename",
    Header: "Filename",
    accessor: "sfilename",
    Cell: ({ cell }) => {
      const val = cell?.value;
      if (val) {
        if (/\\/.test(val)) {
          return cell.value.slice(cell.value.lastIndexOf("\\") + 1);
        }
        return cell.value.slice(cell.value.lastIndexOf("/") + 1);
      }
      return "--";
    },
  },
  {
    id: "dateadded",
    Header: "Added",
    accessor: "dateadded",
    Cell: ({ cell }) =>
      cell?.value ? format(convertTimeZone(cell.value), "MM/dd/yyyy") : "--",
  },
  {
    id: "useridadded",
    Header: "By",
    accessor: "useridadded",
  },
];

export const generateColumns = (handleDelete, handlePublish) => [
  {
    Header: "",
    id: "delete",
    Cell: (cell) => (
      <Generic
        style={{ textAlign: "center" }}
        title="Delete"
        type="button"
        onClick={(e) => {
          e.stopPropagation();
          handleDelete(cell.row.original);
        }}
      >
        <Icon color="danger">
          <FontAwesomeIcon icon="times" />
        </Icon>
      </Generic>
    ),
  },
  ...DEFAULT_COLUMNS,
  {
    id: "PublishOnWeb",
    Header: "Web",
    accessor: "PublishOnWeb",
    Cell: (cell) => (
      <Generic
        style={{ textAlign: "center" }}
        textColor={cell?.value ? "success" : "danger"}
        title="Publish On Web"
        type="button"
        onClick={(e) => {
          e.stopPropagation();
          handlePublish(cell.row.original);
        }}
      >
        <Icon color="inherit">
          <FontAwesomeIcon icon={cell.value ? "eye" : "eye-slash"} />
        </Icon>
      </Generic>
    ),
  },
  {
    Header: "",
    id: "download",
    Cell: (cell) => (
      <CaseDocumentDownloadLink caseDocument={cell.row.original} />
    ),
  },
];

export default DEFAULT_COLUMNS;
