import { useState, useEffect } from "react";

const useIsUSAA = (lsiClientData) => {
  const [isUSAA, setIsUSAA] = useState({ legal: false, claims: false });

  useEffect(() => {
    const checkUSAA = () => {
      if (lsiClientData?.findFirstLsiClient?.CLUSAARequired) {
        setIsUSAA((prev) => ({ ...prev, claims: true }));
      }

      if (lsiClientData?.findFirstLsiClient?.CLUSAALegalRequired) {
        setIsUSAA((prev) => ({ ...prev, legal: true }));
      }
    };
    checkUSAA();
  }, [lsiClientData]);

  return { isUSAA };
};

export default useIsUSAA;
