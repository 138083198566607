import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { AsyncPaginate } from "react-select-async-paginate";
import { Label, Control, Input } from "rbx";
import { useApolloClient, useLazyQuery } from "@apollo/client";

import { LIST_USER_FUNCTIONS_QUERY, SINGLE_USER_FUNCTION_QUERY } from "../../graphql";

const UserFunctionSelect = ({
  name,
  value: initialValue,
  label,
  onChange,
  fullWidth,
  disabled,
  readOnly,
  required,
}) => {
  const client = useApolloClient();

  const [getUserFunction, { data: getUserFunctionData }] = useLazyQuery(SINGLE_USER_FUNCTION_QUERY);
  const [value, setValue] = useState(null);
  const [defaultOptions, setDefaultOptions] = useState([]);

  const promiseOptions = async (inputValue, { length }) => {
    const { data: userFunctionsData } = await client.query({
      query: LIST_USER_FUNCTIONS_QUERY,
      variables: {
        take: 100,
        where: {
          functiondesc: { contains: inputValue },
        },
        orderBy: { functiondesc: "asc" },
        skip: length,
      },
    });
    const userFunctions = userFunctionsData?.userFunctions;

    const result = Array.isArray(userFunctions)
      ? userFunctions.map((userFunction) => ({
          value: userFunction.functioncode,
          label: userFunction.functiondesc,
        }))
      : [];
    setDefaultOptions(result);

    return {
      options: result,
      hasMore: true,
    };
  };

  const handleChange = ({ value: newValue }) => {
    onChange(name, String(newValue));
  };

  useEffect(() => {
    if (initialValue) {
      getUserFunction({
        variables: {
          where: { functioncode: parseInt(initialValue, 10) },
        },
      });
    } else {
      setValue(null);
    }
  }, [initialValue, getUserFunction]);

  useEffect(() => {
    const userFunction = getUserFunctionData?.userFunction;
    if (userFunction) {
      setValue({ value: userFunction.functioncode, label: userFunction.functiondesc });
    }
  }, [getUserFunctionData]);

  useEffect(() => {
    if (value) {
      const currOptions = [...defaultOptions];
      if (!currOptions.some((opt) => opt.value === value.value)) {
        setDefaultOptions((prev) => [value, ...prev]);
      }
    }
  }, [value, defaultOptions]);

  const indicateRequired =
    required && (!value || value === "" || value === "0" || value === 0);

  return (
    <Control expanded>
      <Label>{label}</Label>
      {readOnly ? (
        <Input
          readOnly
          autoComplete="new"
          size="small"
          type="text"
          value={value?.label || ""}
        />
      ) : (
        <AsyncPaginate
          cacheOptions
          defaultOptions={defaultOptions}
          isDisabled={disabled}
          isSearchable={!readOnly}
          loadOptions={promiseOptions}
          menuIsOpen={readOnly ? false : undefined}
          menuPlacement="auto"
          menuPortalTarget={document.body}
          placeholder="Start typing..."
          styles={{
            menuPortal: (provided) => ({
              ...provided,
              zIndex: 9999,
            }),
            indicatorsContainer: (provided) => ({
              ...provided,
              height: "inherit",
            }),
            indicatorSeparator: (provided) => ({
              ...provided,
              display: "none",
            }),
            container: (provided) => ({
              fontSize: 13,
            }),
            singleValue: (provided) => ({
              ...provided,
              top: "38%",
            }),
            control: (provided) => ({
              ...provided,
              minHeight: 31,
              height: 31,
              width: fullWidth ? "100%" : 180,
              minWidth: 180,
              border: indicateRequired ? "1px #e63362 solid" : "",
              borderRadius: "4px",
              boxShadow: indicateRequired
                ? "0 0 0 0.125em rgba(230, 51, 98, 0.25)"
                : "",
              "&:hover": {
                borderColor: "hsl(0, 0%, 90%)",
                borderRadius: 4,
                cursor: disabled ? "not-allowed" : "pointer",
              },
            }),
            placeholder: (provided) => ({ ...provided, top: "38%" }),
            menu: (provided) => ({
              ...provided,
              zIndex: 999,
            }),
          }}
          value={value}
          onChange={handleChange}
        />
      )}
    </Control>
  );
};

UserFunctionSelect.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
};

UserFunctionSelect.defaultProps = {
  name: "",
  label: "",
  value: "",
  readOnly: false,
  onChange: () => null,
  fullWidth: false,
  disabled: false,
  required: false,
};

export default UserFunctionSelect;
