import { gql } from "@apollo/client";

export const LIST_CE_CLAIMS_QUERY = gql`
  query LIST_CE_CLAIMS_QUERY(
    $where: ClaimsWhereInput
    $orderBy: [ClaimsOrderByInput!]
    $take: Int
    $skip: Int
    $distinct: [ClaimsScalarFieldEnum!]
  ) {
    findManyClaims(
      where: $where
      orderBy: $orderBy
      take: $take
      skip: $skip
      distinct: $distinct
    ) {
      CentralEligibilityID
      CompanyName
      ExternalClaimID
      ClaimNumber
      SOJ
      ExternalPatientID
      ClaimantIPNumber
      ClaimantFirstName
      ClaimantMiddleName
      ClaimantLastName
      Phone1
      Phone2
      DateOfLoss
      DateOfBirth
      Address1
      Address2
      State
      City
      DateOfBirth
      Sex
      HCP
      SSN
      Zip5
      EligibilityStatus
    }
    aggregateClaims(where: $where) {
      count {
        _all
      }
    }
  }
`;

export const SINGLE_CE_CLAIM_QUERY_BY_APOLLO_CLAIM_ID = gql`
  query SINGLE_CE_CLAIM_QUERY_BY_APOLLO_CLAIM_ID(
    $where: CustomCentralEligibilityClaimWhereUniqueInput!
  ) {
    findCentralEligibilityClaimByApolloClaimId(where: $where) {
      CentralEligibilityID
      CompanyName
      ExternalClaimID
      ClaimNumber
      DateOfLoss
      ClaimantFirstName
      ClaimantMiddleName
      ClaimantLastName
      DateOfBirth
      Address1
      Address2
      City
      State
      Zip5
      Zip4
      Phone1
      Phone2
      SSN
      Sex
      CoverageType
      EligibilityStatus
      HCP
    }
  }
`;

export const SINGLE_CE_CLAIM_QUERY = gql`
  query SINGLE_CE_CLAIM_QUERY($where: ClaimsWhereUniqueInput!) {
    findUniqueClaims(where: $where) {
      CentralEligibilityID
      CompanyName
      ExternalClaimID
      ClaimNumber
      DateOfLoss
      DateOfBirth
      Address1
      Address2
      City
      State
      Zip5
      Zip4
      Phone1
      Phone2
      SSN
      Sex
      ClaimantFirstName
      ClaimantMiddleName
      ClaimantLastName
      CoverageType
      EligibilityStatus
      HCP
    }
  }
`;

export const FIND_FIRST_CLAIM_QUERY = gql`
  query FIND_FIRST_CLAIM_QUERY($where: ClaimsWhereInput!) {
    findFirstClaims(where: $where) {
      CentralEligibilityID
      CompanyName
      ExternalClaimID
      ClaimNumber
      DateOfLoss
      DateOfBirth
      Address1
      Address2
      City
      State
      Zip5
      Zip4
      Phone1
      Phone2
      SSN
      Sex
      CoverageType
      EligibilityStatus
      HCP
    }
  }
`;
