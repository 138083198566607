import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Control, Label, Select } from "rbx";
import { useLazyQuery } from "@apollo/client";

import { ALL_USERS_QUERY } from "../../graphql/user";

function UserSelect({
  value,
  name,
  label,
  size,
  where,
  disabled,
  onChange,
  getValue,
  setValue,
  required,
  canSelectInactive,
}) {
  const [users, setUsers] = useState(null);
  const [getUsers, { data: getUsersData }] = useLazyQuery(ALL_USERS_QUERY);
  useEffect(() => {
    getUsers({
      variables: {
        orderBy: [{ lastname: "asc" }, { firstname: "asc" }],
        where,
      },
    });
  }, [getUsers, where]);

  useEffect(() => {
    setUsers(getUsersData?.users);
  }, [getUsersData]);

  const handleChange = (e) => {
    onChange(e.target.name, setValue(e.target.value));
  };

  return (
    <Control expanded>
      <Label>{label}</Label>
      <Select.Container
        fullwidth
        size={size}
        state={Array.isArray(users) ? "" : "loading"}
      >
        <Select
          disabled={disabled}
          name={name}
          required={required}
          value={getValue(value)}
          onChange={handleChange}
        >
          <Select.Option>Select a user</Select.Option>
          {Array.isArray(users) &&
            users.map((user) => (
              <Select.Option
                disabled={!canSelectInactive && !user.fldActive}
                key={`user-${user.userid}-${user.usertype}`}
                value={user.userid}
              >
                {`${user.lastname}, ${user.firstname}`}
              </Select.Option>
            ))}
        </Select>
      </Select.Container>
    </Control>
  );
}

UserSelect.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
  name: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.string,
  where: PropTypes.shape({
    usertype: PropTypes.shape({
      equals: PropTypes.string,
    }),
  }),
  disabled: PropTypes.bool,
  onChange: PropTypes.func,

  getValue: PropTypes.func,
  setValue: PropTypes.func,
  required: PropTypes.bool,
  canSelectInactive: PropTypes.bool,
};

UserSelect.defaultProps = {
  value: "",
  name: "",
  label: "",
  size: "small",
  where: {},
  disabled: false,
  onChange: () => null,
  getValue: (x) => x,
  setValue: (x) => x,
  required: false,
  canSelectInactive: false,
};

export default UserSelect;
