import React from "react";
import PropTypes from "prop-types";
import { Field, Label, Control, Input } from "rbx";
import BooleanInput from "../../../../components/BooleanInput";

const CasePrintDocumentForm = ({ inputs, showLabels, onChange }) => (
  <Field className="grid-override print-documents-grid" kind="group">
    <Control>
      {showLabels && <Label>Print</Label>}
      <BooleanInput
        name="printDocument"
        value={inputs.printDocument}
        onChange={() => onChange("printDocument", !inputs.printDocument)}
      />
    </Control>
    <Control>
      {showLabels && <Label>Description</Label>}
      <Input
        readOnly
        size="small"
        style={{ paddingLeft: "0" }}
        tabIndex="-1"
        value={inputs.description}
      />
    </Control>
    <Control>
      {showLabels && <Label>Filename</Label>}
      <Input
        readOnly
        size="small"
        style={{ paddingLeft: "0" }}
        tabIndex="-1"
        value={inputs.sfilename}
      />
    </Control>
  </Field>
);
CasePrintDocumentForm.propTypes = {
  inputs: PropTypes.object.isRequired,
  showLabels: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default CasePrintDocumentForm;
