import React from "react";
import PropTypes from "prop-types";
import { Control, Label, Select } from "rbx";
import { useQuery } from "@apollo/client";

import { LIST_COMPANY_GROUPS_QUERY } from "../../graphql";

const CompanyGroupSelect = ({
  value,
  name,
  label,
  size,
  onChange,
  disabled,
}) => {
  const { data: companyGroupsData } = useQuery(LIST_COMPANY_GROUPS_QUERY, {
    variables: { orderBy: { CompanyGroupName: "asc" } },
  });

  const companyGroups = companyGroupsData?.companyGroups;

  return (
    <Control expanded>
      <Label>{label}</Label>
      <Select.Container
        fullwidth
        size={size}
        state={Array.isArray(companyGroups) ? "" : "loading"}
      >
        <Select
          disabled={disabled}
          name={name}
          value={value}
          onChange={onChange}
        >
          <Select.Option value="" />
          {Array.isArray(companyGroups) &&
            companyGroups.map((group) => (
              <Select.Option
                key={group.CompanyGroupID}
                value={group.CompanyGroupID}
              >
                {group.CompanyGroupName}
              </Select.Option>
            ))}
        </Select>
      </Select.Container>
    </Control>
  );
};

CompanyGroupSelect.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

CompanyGroupSelect.defaultProps = {
  value: "",
  name: "",
  label: "Company Group",
  size: "small",
  onChange: () => null,
  disabled: false,
};

export default CompanyGroupSelect;
