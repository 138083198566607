import React from "react";

import PropTypes from "prop-types";

import { Control, Label, Select } from "rbx";

import { useQuery } from "@apollo/client";
import { ALL_CASE_TYPES_OFFICES_SELECT_QUERY } from "../../graphql";

const CaseTypeOfficeSelect = ({
  value,
  name,
  label,
  size,
  onChange,
  disabled,
  officeCode,
  required,
  showBlank,
}) => {
  const { data, loading } = useQuery(ALL_CASE_TYPES_OFFICES_SELECT_QUERY, {
    variables: officeCode
      ? {
          orderBy: { CaseType: { description: "asc" } },
          where: {
            officecode: { equals: parseInt(officeCode, 10) },
          },
        }
      : { take: 1 },
    fetchPolicy: "cache-first",
  });

  const handleChange = (e) => {
    onChange(e.target.name, e.target.value ? e.target.value : "");
  };

  const caseTypeOffices =
    officeCode && data && data.caseTypeOffices
      ? data.caseTypeOffices.map((cto) => ({ ...cto.CaseType }))
      : [];

  return (
    <Control>
      <Label>{label}</Label>
      <Select.Container fullwidth size={size} state={loading ? "loading" : ""}>
        <Select
          disabled={disabled}
          name={name}
          required={required}
          value={value}
          onChange={handleChange}
        >
          {showBlank && <Select.Option value="" />}
          {caseTypeOffices.map((caseType) => (
            <Select.Option key={caseType.code} value={caseType.code}>
              {caseType?.description}
            </Select.Option>
          ))}
        </Select>
      </Select.Container>
    </Control>
  );
};

CaseTypeOfficeSelect.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  officeCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  required: PropTypes.bool,
  showBlank: PropTypes.bool,
};

CaseTypeOfficeSelect.defaultProps = {
  value: "",
  name: "",
  label: "Case Type",
  size: "small",
  onChange: () => null,
  disabled: false,
  required: false,
  showBlank: true,
  officeCode: null,
};

export default CaseTypeOfficeSelect;
