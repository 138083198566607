import React from "react";
import PropTypes from "prop-types";
import { Column, Control } from "rbx";
import { useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  UPDATE_COMPANY_MUTATION,
  SINGLE_COMPANY_QUERY,
} from "../../../graphql";
import NotesInput from "../../../components/NotesInput";
import { usePermissions } from "../../../hooks";

const Notes = ({ inputs, onChange, disabled, handleSave }) => {
  const { companycode } = useParams();
  const isAdmin = usePermissions(["AllAdminFunc"]);
  const [updateCompany] = useMutation(UPDATE_COMPANY_MUTATION);

  const handleSaveNote = async (note) => {
    updateCompany({
      variables: {
        data: {
          notes: { set: note },
        },
        where: {
          companycode: parseInt(companycode, 10),
        },
      },
      refetchQueries: [
        {
          query: SINGLE_COMPANY_QUERY,
          variables: {
            where: { companycode: parseInt(companycode, 10) },
          },
        },
      ],
    });
  };

  const handleChange = async (name, value) => {
    try {
      await handleSaveNote(value);
      onChange(name, value);
      toast.success(`Note saved successfully.`);
    } catch (err) {
      toast.error(`Error saving note.`);
    }
  };

  return (
    <Column.Group>
      <Column>
        <Control style={{ marginBottom: "1rem" }}>
          <NotesInput
            contentEditable={isAdmin}
            disabled={disabled}
            name="notes"
            saveNotes={handleSaveNote}
            size="small"
            value={inputs.notes}
            onChange={handleChange}
          />
        </Control>
      </Column>
    </Column.Group>
  );
};

Notes.propTypes = {
  inputs: PropTypes.object,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  handleSave: PropTypes.func,
};

Notes.defaultProps = {
  inputs: {},
  onChange: (e) => e,
  handleSave: () => null,
  disabled: false,
};

export default Notes;
