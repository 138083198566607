import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Title, Button } from "rbx";
import { useMutation, useLazyQuery, useApolloClient } from "@apollo/client";
import { useModal } from "../../../../../context";
import {
  LIST_APOLLO_DOCUMENT_TYPES_QUERY,
  CREATE_APOLLO_DOCUMENT_TYPE_MUTATION,
  UPDATE_APOLLO_DOCUMENT_TYPE_MUTATION,
  DELETE_APOLLO_DOCUMENT_TYPE_MUTATION,
  SINGLE_APOLLO_DOCUMENT_TYPE_QUERY,
  FIND_FIRST_APOLLO_DOCUMENT_QUERY,
} from "../../../../../graphql";
import Confirmation from "../../../../../components/Confirmation";
import { customToast as toast } from "../../../../../utils";
import DocumentTypeForm from "../DocumentTypeForm";

const INITIAL_STATE = {
  DocumentType: "",
};

const DocumentTypeModal = ({ onComplete, DocumentTypeID, where }) => {
  const { setModalOpen } = useModal();
  const client = useApolloClient();
  const [inputs, setInputs] = useState(INITIAL_STATE);
  const [loading, setLoading] = useState(false);
  const [updated, setUpdated] = useState([]);

  const [
    getDocumentType,
    { data: documentTypeData, loading: queryLoading },
  ] = useLazyQuery(SINGLE_APOLLO_DOCUMENT_TYPE_QUERY);
  const [createDocumentType] = useMutation(
    CREATE_APOLLO_DOCUMENT_TYPE_MUTATION
  );
  const [updateDocumentType] = useMutation(
    UPDATE_APOLLO_DOCUMENT_TYPE_MUTATION
  );
  const [deleteDocumentType] = useMutation(
    DELETE_APOLLO_DOCUMENT_TYPE_MUTATION
  );

  useEffect(() => {
    if (DocumentTypeID) {
      getDocumentType({
        variables: {
          where: { DocumentTypeID },
        },
      });
    }
  }, [DocumentTypeID, getDocumentType]);

  useEffect(() => {
    if (documentTypeData?.findUniqueDocumentTypes) {
      setInputs({
        DocumentType:
          documentTypeData.findUniqueDocumentTypes.DocumentType || "",
      });
    }
  }, [documentTypeData]);

  const handleChange = (name, value) => {
    setInputs((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (DocumentTypeID) {
      setUpdated((prev) => (prev.includes(name) ? prev : [...prev, name]));
    }
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);

      if (!DocumentTypeID) {
        await createDocumentType({
          variables: {
            data: inputs,
          },
          refetchQueries: [
            {
              query: LIST_APOLLO_DOCUMENT_TYPES_QUERY,
              variables: {
                where,
                orderBy: [{ DocumentType: "asc" }],
                skip: 0,
                take: 25,
              },
            },
          ],
        });
        setModalOpen(false);
        toast.success("Document Type created successfully.");
      } else {
        await updateDocumentType({
          variables: {
            data: {
              DocumentType: {
                set: inputs.DocumentType,
              },
            },
            where: {
              DocumentTypeID,
            },
          },
          refetchQueries: [
            {
              query: LIST_APOLLO_DOCUMENT_TYPES_QUERY,
              variables: {
                where,
                orderBy: [{ DocumentType: "asc" }],
                skip: 0,
                take: 25,
              },
            },
            {
              query: SINGLE_APOLLO_DOCUMENT_TYPE_QUERY,
              variables: {
                where: { DocumentTypeID },
              },
            },
          ],
        });
        setModalOpen(false);
        toast.success("Document Type updated successfully.");
      }
      onComplete();
    } catch (err) {
      toast.error("Error saving Document Type");
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    const { data: documentsData } = await client.query({
      query: FIND_FIRST_APOLLO_DOCUMENT_QUERY,
      variables: {
        where: {
          DocumentTypeID: { equals: DocumentTypeID },
        },
        take: 1,
        skip: 0,
      },
    });

    if (documentsData?.findFirstDocuments) {
      toast.error(
        "This Document Type could not be removed because there's some Cases related"
      );
    } else {
      const performDelete = async () => {
        try {
          setLoading(true);
          await deleteDocumentType({
            variables: {
              where: {
                DocumentTypeID,
              },
            },
            refetchQueries: [
              {
                query: LIST_APOLLO_DOCUMENT_TYPES_QUERY,
                variables: {
                  where,
                  orderBy: [{ DocumentType: "asc" }],
                  take: 25,
                  skip: 0,
                },
              },
              {
                query: SINGLE_APOLLO_DOCUMENT_TYPE_QUERY,
                variables: {
                  where: { DocumentTypeID },
                },
              },
            ],
          });
          toast.success("Document Type deleted successfully");
          onComplete();
        } catch (err) {
          toast.error("Error trying to delete Document Type");
        } finally {
          setLoading(false);
        }
      };
      setModalOpen(
        true,
        <Confirmation
          message="Are you sure you want to delete this Document Type?"
          onCancel={() => setModalOpen(false)}
          onConfirm={performDelete}
        />
      );
    }
  };

  const isDisabled =
    !inputs.DocumentType || (DocumentTypeID && !updated.length) || queryLoading;

  return (
    <form id="document-type-form" onSubmit={handleSubmit}>
      <header className="page-head">
        <div className="page-head-start">
          <Title size={5}>
            {`${!DocumentTypeID ? "Create" : "Edit"}`}
            &nbsp;Document Type
          </Title>
        </div>
        <div className="page-head-end">
          <Button.Group hasAddons>
            <Button
              size="small"
              type="button"
              onClick={() => onComplete(false)}
            >
              <span>Cancel</span>
            </Button>
            <Button
              color="primary"
              disabled={isDisabled}
              form="document-type-form"
              size="small"
              state={loading ? "loading" : ""}
              type="submit"
            >
              <span>Submit</span>
            </Button>
          </Button.Group>
        </div>
      </header>
      <hr />
      <DocumentTypeForm inputs={inputs} onChange={handleChange} />
      <hr />
      {DocumentTypeID && (
        <Button
          color="danger"
          disabled={loading}
          size="small"
          type="button"
          onClick={handleDelete}
        >
          <span>Delete</span>
        </Button>
      )}
    </form>
  );
};

DocumentTypeModal.propTypes = {
  onComplete: PropTypes.func,
  DocumentTypeID: PropTypes.number,
  where: PropTypes.object,
};

DocumentTypeModal.defaultProps = {
  onComplete: () => null,
  DocumentTypeID: null,
  where: {},
};

export default DocumentTypeModal;
