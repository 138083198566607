import React, { useState } from "react";
import { Title, Field, Control, Button, Icon, Input } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useModal } from "../../../../context";
import DataTable from "../../../../components/DataTable";
import { LIST_STANDARD_CASE_HISTORY_NOTES_QUERY } from "../../../../graphql";
import { StandardNoteModal } from "../../components";
import { debounce } from "../../../../utils";
import COLUMNS from "./columns";

const DEFAULT_FILTER_STATE = {
  OR: [
    {
      NoteDescription: { contains: "" },
    },
  ],
};

const StandardNotesListPage = () => {
  const [where, setWhere] = useState(DEFAULT_FILTER_STATE);
  const [searchValue, setSearchValue] = useState("");

  const { setModalOpen } = useModal();

  const handleRowClick = ({ NoteID }) => {
    setModalOpen(
      true,
      <StandardNoteModal
        noteId={NoteID}
        onComplete={() => setModalOpen(false)}
      />
    );
  };

  const handleSearchChange = debounce(({ target: { value } }) => {
    if (value) {
      setWhere({
        OR: [
          {
            NoteDescription: { contains: value },
          },
        ],
      });
    } else {
      setWhere({
        OR: [
          {
            NoteDescription: { contains: "" },
          },
        ],
      });
    }
  }, 500);

  return (
    <div>
      <div className="section-header">
        <Title style={{ marginRight: 15 }}>Standard Notes</Title>
        <Field kind="group">
          <Control>
            <Input
              autoComplete="off"
              id="search-input"
              placeholder="Search..."
              size="small"
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value);
                handleSearchChange(e);
              }}
            />
          </Control>

          <Control>
            <Button
              color="primary"
              size="small"
              onClick={() => {
                setModalOpen(
                  true,
                  <StandardNoteModal
                    onComplete={() => {
                      setModalOpen(false);
                    }}
                  />
                );
              }}
            >
              <Icon>
                <FontAwesomeIcon icon="plus" />
              </Icon>

              <span>Add</span>
            </Button>
          </Control>
        </Field>
      </div>

      <DataTable
        aggregateKey="NoteID"
        aggregateName="aggregateStandardCaseHistoryNotes"
        columns={COLUMNS}
        name="findManyStandardCaseHistoryNotes"
        orderBy={[{ id: "NoteDescription", desc: false }]}
        query={LIST_STANDARD_CASE_HISTORY_NOTES_QUERY}
        where={where}
        onRowClick={handleRowClick}
      />
    </div>
  );
};

StandardNotesListPage.propTypes = {};

export default StandardNotesListPage;
