import { format } from "date-fns";
import { convertTimeZone } from "../../../../utils";

const DEFAULT_COLUMNS = [
  {
    id: "casenbr",
    Header: "Case Number",
    accessor: "casenbr",
  },
  {
    id: "Appttime",
    Header: "Appt Time",
    accessor: "Appttime",
    Cell: ({ cell }) =>
      cell?.value ? format(convertTimeZone(cell.value), "hh:mm a") : "--",
  },
  {
    id: "description",
    Header: "Description",
    Cell: ({ row }) => {
      const { examinee, client } = row.original;
      const result = [
        examinee?.lastname,
        examinee?.firstname,
        "/",
        client?.company?.intname,
      ]
        .filter(Boolean)
        .join(" ");
      return result;
    },
  },
];
export default DEFAULT_COLUMNS;
