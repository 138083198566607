import { useState, useEffect } from "react";

import { useQuery } from "@apollo/client";

import { SINGLE_CASE_QUERY } from "../graphql";

const useCaseDoctorLabel = (casenbr) => {
  const [state, setState] = useState("");
  const { data } = useQuery(SINGLE_CASE_QUERY, {
    variables: { where: { casenbr: parseInt(casenbr, 10) } },
  });

  const lob = data?.caseItem?.service?.LOB;

  useEffect(() => {
    const label = lob === 1 ? "Investigator" : "Doctor";
    setState(label);
  }, [lob]);

  return state;
};

export default useCaseDoctorLabel;
