import React from "react";
import PropTypes from "prop-types";
import { useParams, useHistory } from "react-router-dom";
import { Button, Field, Control, Icon, Label } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useModal } from "../../../../context";

import Confirmation from "../../../../components/Confirmation";
import RescheduleCaseModal from "../../components/RescheduleCaseModal";
import { getDaysDiffBetweenDates, convertTimeZone } from "../../../../utils";

const Scheduled = ({ inputsCase, disabled, onChangeCase, onSubmit }) => {
  const { casenbr: pcasenbr, workid } = useParams();
  const casenbr = pcasenbr || workid;
  const { setModalOpen } = useModal();
  const history = useHistory();
 
  const handleReschedule = async () => {
    const performReschedule = async () => {
      const { Appttime } = inputsCase;
      const daysDiff = getDaysDiffBetweenDates(
        new Date(),
        convertTimeZone(Appttime)
      );
      const isLateCancel = daysDiff < 1;

      if (isLateCancel) {
        setModalOpen(
          true,
          <Confirmation
            message="This case is being rescheduled inside of your standard late cancellation period. Should it be treated as a late cancel for accounting purposes?"
            onCancel={() =>
              setModalOpen(
                true,
                <RescheduleCaseModal
                  casenbr={casenbr}
                  onComplete={() => {
                    setModalOpen(false, "");
                  }}
                />
              )
            }
            onConfirm={() =>
              setModalOpen(
                true,
                <RescheduleCaseModal
                  isLateCancel
                  casenbr={casenbr}
                  onComplete={() => {
                    setModalOpen(false, "");
                  }}
                />
              )
            }
          />
        );
      } else {
        setModalOpen(
          true,
          <RescheduleCaseModal
            casenbr={casenbr}
            onComplete={() => {
              setModalOpen(false, "");
            }}
          />
        );
      }
    };
    setModalOpen(
      true,
      <Confirmation
        message="Are you sure you wish to reschedule this case?"
        onConfirm={performReschedule}
      />
    );
  };

  const handlePrintLetters = async () => {
    history.push({
      pathname: `/cases/${casenbr}/documents`,
      state: { triggerGenerateModal: true },
    });
  };

  return (
    <Field>
      <Control>
        <Label>
          Case:&nbsp;
          {casenbr}
          &nbsp;has already been scheduled. Please click reschedule or print
          letters to continue.
        </Label>
        <Field kind="group">
          <Control>
            <Button color="info" size="small" onClick={handlePrintLetters}>
              <Icon>
                <FontAwesomeIcon icon="print" />
              </Icon>
              <span>Print Letters</span>
            </Button>
          </Control>
          <Control>
            <Button color="danger" size="small" onClick={handleReschedule}>
              <Icon>
                <FontAwesomeIcon icon={["fa", "calendar-times"]} />
              </Icon>
              <span>Reschedule</span>
            </Button>
          </Control>
        </Field>
      </Control>
    </Field>
  );
};

Scheduled.propTypes = {
  disabled: PropTypes.bool,
  inputsCase: PropTypes.object,
  onChangeCase: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
};

Scheduled.defaultProps = {
  disabled: false,
  inputsCase: { schedulenotes: "" },
  onChangeCase: () => null,
};

export default Scheduled;
