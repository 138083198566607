const LIST_FIELDS = [
  { name: "#problems", type: "String", entity: "problem" },
  { name: "/problems", type: "String", entity: "problem" },
  { name: "problemcode", type: "Int", entity: "problem" },
  { name: "problemcode", type: "Int", entity: "problem" },
  { name: "description", type: "String", entity: "problem" },
  { name: "status", type: "String", entity: "problem" },
  { name: "dateadded", type: "DateTime", entity: "problem" },
  { name: "dateedited", type: "DateTime", entity: "problem" },
  { name: "PublishOnWeb", type: "Boolean", entity: "problem" },
  { name: "WebSynchDate", type: "String", entity: "problem" },
  { name: "useridadded", type: "String", entity: "problem" },
  { name: "useridedited", type: "String", entity: "problem" },
];

export default LIST_FIELDS;
