import React from "react";
import PropTypes from "prop-types";

import { Help } from "rbx";

const Copyright = ({ color, align }) => (
  <Help align={align} color={color}>
    IMEC © 
    {' '}
    {new Date().getFullYear()}
    {' '}
    GraVoc Associates, Inc.
  </Help>
);

Copyright.propTypes = {
  color: PropTypes.string,
  align: PropTypes.string,
};

Copyright.defaultProps = {
  color: "white",
  align: "center",
};

export default Copyright;
