import React from "react";

import { Title } from "rbx";
import "./BucketsPage.scss";

import BucketsForm from "./components/BucketsForm";

const BucketsPage = () => (
  <div>
    <div className="section-header">
      <Title style={{ marginRight: 15 }}>
        Manage Case Type, Office, Service, and Queue Relations
      </Title>
      <span />
    </div>
    <BucketsForm />
  </div>
);

BucketsPage.propTypes = {};

export default BucketsPage;
