import React from "react";
import PropTypes from "prop-types";
import { Control, Label, Select } from "rbx";
import { useQuery } from "@apollo/client";

import { ALL_OFFICES_QUERY } from "../../graphql/office";

const OfficeSelect = ({
  value,
  name,
  label,
  size,
  onChange,
  disabled,
  required,
  getValue,
  setValue,
  noValueLabel,
  where,
}) => {
  const { data: officeData } = useQuery(ALL_OFFICES_QUERY, {
    variables: { where, orderBy: { description: "asc" } },
  });

  const offices = officeData?.offices;
  const indicateRequired =
    required && (!value || value === "" || value === "0" || value === 0);

  const handleChange = (e) => {
    onChange(
      e.target.name,
      setValue(e.target.value ? parseInt(e.target.value, 10) : "")
    );
  };

  return (
    <Control expanded>
      <Label>{label}</Label>
      <Select.Container
        fullwidth
        size={size}
        state={Array.isArray(offices) ? "" : "loading"}
      >
        <Select
          disabled={disabled}
          name={name}
          required={required}
          style={{
            border: indicateRequired ? "1px #e63362 solid" : "",
            borderRadius: "4px",
            boxShadow: indicateRequired
              ? "0 0 0 0.125em rgba(230, 51, 98, 0.25)"
              : "",
          }}
          value={getValue(value)}
          onChange={handleChange}
        >
          <Select.Option value="">{noValueLabel}</Select.Option>
          {Array.isArray(offices) &&
            offices.map((office) => (
              <Select.Option
                key={`office-${office.officecode}`}
                value={office.officecode}
              >
                {office.description}
              </Select.Option>
            ))}
        </Select>
      </Select.Container>
    </Control>
  );
};

OfficeSelect.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.number,
  ]),
  name: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  getValue: PropTypes.func,
  setValue: PropTypes.func,
  noValueLabel: PropTypes.string,
  where: PropTypes.object,
};

OfficeSelect.defaultProps = {
  value: "",
  name: "",
  label: "Office",
  size: "small",
  onChange: () => null,
  disabled: false,
  required: false,
  getValue: (x) => x,
  setValue: (x) => x,
  noValueLabel: "All",
  where: {},
};

export default OfficeSelect;
