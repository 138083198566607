import { gql } from "@apollo/client";

export const ALL_SERVICE_QUERY = gql`
  query services($orderBy: [ServiceOrderByInput!], $where: ServiceWhereInput) {
    services(orderBy: $orderBy, where: $where) {
      CalcFrom
      dateadded
      dateedited
      DaysToCommitDate
      description
      ESendInsured
      ExcludeOnDash
      FinanceDescription
      HideExamDateInfo
      IsReview
      IsSIU
      IsSurv
      LOB
      officecode
      PhysicalAppt
      ProjRev
      PublishOnWeb
      servicecode
      ServiceType
      ServShortName
      shortdesc
      ShowAsPendingSupp
      ShowLegalTabOnCase
      status
      useridadded
      useridedited
      WebID
      WebSynchDate
      PrintApptLetters
    }
  }
`;

export const LIST_SERVICE_QUERY = gql`
  query services(
    $orderBy: [ServiceOrderByInput!]
    $where: ServiceWhereInput
    $take: Int
    $skip: Int
  ) {
    services(orderBy: $orderBy, where: $where, take: $take, skip: $skip) {
      CalcFrom
      dateadded
      dateedited
      DaysToCommitDate
      description
      ESendInsured
      ExcludeOnDash
      FinanceDescription
      HideExamDateInfo
      IsReview
      IsSIU
      IsSurv
      LOB
      officecode
      PhysicalAppt
      ProjRev
      PublishOnWeb
      servicecode
      ServiceType
      ServShortName
      shortdesc
      ShowAsPendingSupp
      ShowLegalTabOnCase
      status
      useridadded
      useridedited
      WebID
      WebSynchDate
      PrintApptLetters
    }
    aggregateService {
      count {
        servicecode
      }
    }
  }
`;

export const SINGLE_SERVICE_QUERY = gql`
  query service($where: ServiceWhereUniqueInput!) {
    service(where: $where) {
      CalcFrom
      dateadded
      dateedited
      DaysToCommitDate
      description
      ESendInsured
      ExcludeOnDash
      FinanceDescription
      HideExamDateInfo
      IsReview
      IsSIU
      IsSurv
      LOB
      officecode
      PhysicalAppt
      ProjRev
      PublishOnWeb
      servicecode
      ServiceType
      ServShortName
      shortdesc
      ShowAsPendingSupp
      ShowLegalTabOnCase
      status
      useridadded
      useridedited
      WebID
      WebSynchDate
      PrintApptLetters
    }
  }
`;

export const LIST_SERVICE_QUEUES_QUERY = gql`
  query serviceQueues(
    $take: Int
    $orderBy: [ServiceQueueOrderByInput!]
    $skip: Int
    $where: ServiceQueueWhereInput
    $distinct: [ServiceQueueScalarFieldEnum!]
  ) {
    serviceQueues(
      take: $take
      orderBy: $orderBy
      skip: $skip
      where: $where
      distinct: $distinct
    ) {
      createinvoice
      createvoucher
      dateadded
      dateedited
      nextstatus
      officecode
      queueorder
      servicecode
      statuscode
      statusdesc
      useridadded
      useridedited
    }
    aggregateServiceQueue {
      count {
        queueorder
        servicecode
        statuscode
      }
    }
  }
`;

export const ALL_SERVICES_CASE_TYPES_SELECT_QUERY = gql`
  query caseTypeServices(
    $take: Int
    $orderBy: [CaseTypeServiceOrderByInput!]
    $skip: Int
    $where: CaseTypeServiceWhereInput
    $distinct: [CaseTypeServiceScalarFieldEnum!]
  ) {
    caseTypeServices(
      take: $take
      orderBy: $orderBy
      skip: $skip
      where: $where
      distinct: $distinct
    ) {
      casetype
      servicecode
      Service {
        servicecode
        description
      }
    }
  }
`;

export const LIST_SERVICE_TYPE_DOCUMENTS_QUERY = gql`
  query CaseTypeServices(
    $take: Int
    $skip: Int
    $orderBy: [CaseTypeServiceOrderByInput!]
    $where: CaseTypeServiceWhereInput
  ) {
    caseTypeServices(
      orderBy: $orderBy
      where: $where
      take: $take
      skip: $skip
    ) {
      casetype
      useridadded
      dateadded
      useridedited
      dateedited
      Service {
        servicecode
        description
      }
      Office {
        officecode
        shortdesc
      }
      CaseType {
        code
        description
      }
    }
    aggregateCaseTypeService(where: $where) {
      count {
        _all
      }
    }
  }
`;

export const FIND_FIRST_SERVICE_QUERY = gql`
  query FIND_FIRST_SERVICE_QUERY($where: ServiceWhereInput) {
    findFirstService(where: $where) {
      servicecode
    }
  }
`;

export const SINGLE_CASE_TYPE_DOCUMENT_QUERY = gql`
  query CaseTypeService($where: CaseTypeServiceWhereUniqueInput!) {
    caseTypeService(where: $where) {
      casetype
      servicecode
      examineeaddrreqd
      examineeSSNreqd
      attorneyreqd
      useridadded
      dateadded
      useridedited
      dateedited
      DOIRqd
      ClaimNbrRqd
      officecode
      JurisdictionRqd
      EmployerRqd
      TreatingPhysicianRqd
    }
  }
`;

export const LIST_CASE_TYPE_SERVICE_VIEW_QUERY = gql`
  query CaseTypeServiceViews(
    $take: Int
    $where: CaseTypeServiceViewWhereInput
    $orderBy: [CaseTypeServiceViewOrderByInput!]
    $skip: Int
  ) {
    caseTypeServiceViews(
      take: $take
      where: $where
      orderBy: $orderBy
      skip: $skip
    ) {
      casetypecode
      officecode
      servicecode
      office
      casetype
      service
      useridadded
      dateadded
      useridedited
      dateedited
    }
    aggregateCaseTypeServiceView(where: $where) {
      count {
        _all
      }
    }
  }
`;
