const document = {
  name: "documents",
  label: "Documents",
  icon: "file",
  active: (location) => {
    const [, p] = location.pathname.split("/");
    return /documents/.test(p);
  },
  to: "/documents",
  permissions: ["AllAdminFunc"],
};

export default document;
