import React from "react";

import PropTypes from "prop-types";

import { Field } from "rbx";

import OfficeSelect from "../OfficeSelect";
import ServiceQueuesSelect from "../ServiceQueuesSelect";
import CaseTypeOfficeSelect from "../CaseTypeOfficeSelect";
import ServiceCaseTypeSelect from "../ServiceCaseTypeSelect";

const CaseSpecialSelects = ({ inputs, setInputs, disabled, inline }) => {
  const onChange = (name, value) => {
    if (name && ["officeCode"].includes(name)) {
      setInputs((prev) => ({
        ...prev,
        statusCode: "",
        serviceCode: "",
        caseTypeCode: "",
        [name]: value,
      }));
    } else if (name && ["caseTypeCode"].includes(name)) {
      setInputs((prev) => ({
        ...prev,
        statusCode: "",
        serviceCode: "",
        [name]: value,
      }));
    } else if (name && ["serviceCode"].includes(name)) {
      setInputs((prev) => ({
        ...prev,
        statusCode: "",
        [name]: value,
      }));
    } else {
      setInputs((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  return (
    <React.Fragment>
      <Field style={{ gridColumn: inline ? "1/3" : "1 / 4" }}>
        <OfficeSelect
          required
          disabled={disabled}
          label="Office"
          name="officeCode"
          noValueLabel=""
          size="small"
          value={inputs.officeCode}
          onChange={onChange}
        />
      </Field>
      <Field style={{ gridColumn: inline ? "3/ 5" : "4 / 7" }}>
        <CaseTypeOfficeSelect
          required
          disabled={disabled}
          name="caseTypeCode"
          officeCode={inputs.officeCode}
          value={inputs.caseTypeCode}
          onChange={onChange}
        />
      </Field>
      <Field style={{ gridColumn: inline ? "5 /7" : "1 / 4" }}>
        <ServiceCaseTypeSelect
          required
          caseType={inputs.caseTypeCode}
          disabled={disabled}
          name="serviceCode"
          officeCode={inputs.officeCode}
          value={inputs.serviceCode}
          onChange={onChange}
        />
      </Field>
      <Field style={{ gridColumn: inline ? "7 /9" : "4 / 7" }}>
        <div style={{ display: "flex", gap: "0.5rem" }}>
          <div style={{ flexGrow: "1" }}>
            <ServiceQueuesSelect
              fullWidth
              required
              disabled={disabled}
              label="Status"
              name="statusCode"
              serviceCode={inputs.serviceCode}
              value={inputs.statusCode}
              onChange={onChange}
            />
          </div>
        </div>
      </Field>
    </React.Fragment>
  );
};

CaseSpecialSelects.propTypes = {
  inputs: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.number,
    PropTypes.array,
  ]),
  setInputs: PropTypes.func,
  disabled: PropTypes.bool,
  inline: PropTypes.bool,
};

CaseSpecialSelects.defaultProps = {
  inputs: [],
  setInputs: () => null,
  disabled: false,
  inline: false,
};

export default CaseSpecialSelects;
