import React, { useState, useEffect, useRef } from "react";
import { Title, Field, Control, Button, Icon, Input } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useParams } from "react-router-dom";
import { useModal } from "../../../../context";
import DataTable from "../../../../components/DataTable";
import { LIST_PROVIDER_TYPES_QUERY } from "../../../../graphql";
import { ProviderTypeModal } from "../../components/ProviderTypes";
import { debounce } from "../../../../utils";
import COLUMNS from "./columns";

const DEFAULT_FILTER_STATE = {
  OR: [
    {
      description: { contains: "" },
    },
  ],
};

const ProviderTypesListPage = () => {
  const { ProvTypeCode } = useParams();
  const [where, setWhere] = useState(DEFAULT_FILTER_STATE);
  const [searchValue, setSearchValue] = useState("");
  const isModalOpen = useRef(false);

  const { setModalOpen } = useModal();

  useEffect(() => {
    if (ProvTypeCode && !isModalOpen.current) {
      isModalOpen.current = true;
      setModalOpen(
        true,
        <ProviderTypeModal
          ProvTypeCode={parseInt(ProvTypeCode, 10)}
          onComplete={(adding, inputs, previousCode, updated) =>
            setModalOpen(false)
          }
        />
      );
    }
  }, [ProvTypeCode, setModalOpen, where]);

  const handleRowClick = (row) => {
    setModalOpen(
      true,
      <ProviderTypeModal
        ProvTypeCode={row.ProvTypeCode}
        onComplete={(adding, inputs, previousCode, updated) =>
          setModalOpen(false)
        }
      />
    );
  };

  const handleSearchChange = debounce(({ target: { value } }) => {
    if (value) {
      setSearchValue(value);
      setWhere({
        OR: [
          {
            description: { contains: value },
          },
        ],
      });
    } else {
      setSearchValue("");
      setWhere({
        OR: [
          {
            description: { contains: "" },
          },
        ],
      });
    }
  }, 500);

  return (
    <div>
      <div className="section-header">
        <Title style={{ marginRight: 15 }}>Provider Types</Title>
        <Field kind="group" style={{ paddingTop: "0.3rem" }}>
          <Control>
            <Input
              autoComplete="off"
              id="search-input"
              placeholder="Search..."
              size="small"
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value);
                handleSearchChange(e);
              }}
            />
          </Control>

          <Control>
            <Button
              color="primary"
              size="small"
              onClick={() => {
                setModalOpen(
                  true,
                  <ProviderTypeModal
                    onComplete={(adding, inputs, updated) =>
                      setModalOpen(false)
                    }
                  />
                );
              }}
            >
              <Icon>
                <FontAwesomeIcon icon="plus" />
              </Icon>
              <span>Add</span>
            </Button>
          </Control>
        </Field>
      </div>

      <DataTable
        aggregateKey="ProvTypeCode"
        aggregateName="aggregateProviderType"
        columns={COLUMNS}
        fetchPolicy="network-only"
        name="providerTypes"
        orderBy={[{ id: "description", desc: false }]}
        query={LIST_PROVIDER_TYPES_QUERY}
        where={where}
        onRowClick={handleRowClick}
      />
    </div>
  );
};

ProviderTypesListPage.propTypes = {};

export default ProviderTypesListPage;
