import { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { SINGLE_CASE_QUERY } from "../graphql";

export default function useMasterSubCase(casenbr) {
  const [state, setState] = useState({
    mastersubcase: "",
    mastercasenbr: "",
    claimnbr: "",
    chartnbr: "",
  });
  const [getCaseItem, { data: GetCaseItemData }] = useLazyQuery(
    SINGLE_CASE_QUERY
  );

  useEffect(() => {
    if (casenbr) {
      getCaseItem({ variables: { where: { casenbr: parseInt(casenbr, 10) } } });
    }
  }, [getCaseItem, casenbr]);

  const caseItem = GetCaseItemData?.caseItem;
  useEffect(() => {
    if (caseItem) {
      const mastersubcase = caseItem?.mastersubcase;
      const mastercasenbr = caseItem?.mastercasenbr;
      const claimnbr = caseItem?.claimnbr;
      const chartnbr = caseItem?.chartnbr;
      setState({ mastersubcase, mastercasenbr, claimnbr, chartnbr });
    }
  }, [caseItem]);

  return state;
}
