import React from "react";
import PropTypes from "prop-types";
import { Control, Label, Select } from "rbx";
import { useQuery } from "@apollo/client";

import { ALL_STANDARD_CASE_HISTORY_NOTES_QUERY } from "../../graphql";

const StandardNoteSelect = ({
  value,
  name,
  label,
  size,
  onChange,
  disabled,
  required,
  getValue,
  setValue,
}) => {
  const { data: standardNoteData } = useQuery(
    ALL_STANDARD_CASE_HISTORY_NOTES_QUERY,
    {
      variables: {
        orderBy: { NoteDescription: "asc" },
        where: { Status: { equals: "Active" } },
      },
    }
  );
  const indicateRequired =
    required && (!value || value === "" || value === "0" || value === 0);

  const standardNotes = standardNoteData?.findManyStandardCaseHistoryNotes;

  const handleChange = (e) => {
    onChange(e.target.name, setValue(e.target.value));
  };

  return (
    <Control expanded>
      <Label>{label}</Label>
      <Select.Container
        fullwidth
        size={size}
        state={Array.isArray(standardNotes) ? "" : "loading"}
      >
        <Select
          disabled={disabled}
          name={name}
          style={{
            border: indicateRequired ? "1px #e63362 solid" : "",
            borderRadius: "4px",
            boxShadow: indicateRequired
              ? "0 0 0 0.125em rgba(230, 51, 98, 0.25)"
              : "",
          }}
          value={getValue(value)}
          onChange={handleChange}
        >
          <Select.Option value="" />
          {Array.isArray(standardNotes) &&
            standardNotes.map((standardNote) => (
              <Select.Option
                key={standardNote.NoteID}
                value={standardNote.NoteID}
              >
                {standardNote.NoteDescription}
              </Select.Option>
            ))}
        </Select>
      </Select.Container>
    </Control>
  );
};

StandardNoteSelect.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.number,
  ]),
  name: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  getValue: PropTypes.func,
  setValue: PropTypes.func,
};

StandardNoteSelect.defaultProps = {
  value: "",
  name: "",
  label: "Standard Note",
  size: "small",
  onChange: () => null,
  disabled: false,
  required: false,
  getValue: (x) => x,
  setValue: (x) => x,
};

export default StandardNoteSelect;
