import React from "react";
import PropTypes from "prop-types";
import { useApolloClient } from "@apollo/client";

import { SINGLE_CC_ADDRESS_QUERY } from "../../../graphql";
import ExamineeForm from "../components/ExamineeForm";

const Examinee = ({
  chartnbr,
  inputs,
  setInputs,
  onChange,
  disabled,
  onSubmit,
  initialState,
  onProfileChange,
  initialExamineeState,
  physician,
  resetExamineeInputs,
  plaintiffattorneycode,
  defenseattorneycode,
  casenbr,
  claimnbr,
}) => {
  const client = useApolloClient();
  const titleTag = () => {
    if (plaintiffattorneycode !== "" && defenseattorneycode === "") {
      return "Plaintiff Attorney Exists On Case";
    }
    if (plaintiffattorneycode === "" && defenseattorneycode !== "") {
      return "Defense Attorney Exists On Case";
    }
    if (plaintiffattorneycode !== "" && defenseattorneycode !== "") {
      return "Defense and Plaintiff Attorneys Exist On Case";
    }
    return "";
  };

  const handleTreatingChanged = async (name, value) => {
    onProfileChange("TreatingPhysician", value);
    const {
      data: { cCAddress },
    } = await client.query({
      query: SINGLE_CC_ADDRESS_QUERY,
      variables: { where: { cccode: parseInt(value, 10) } },
    });
    if (cCAddress) {
      setInputs((prev) => ({
        ...prev,
        treatingphysician: `${cCAddress.firstname || ""} ${
          cCAddress.lastname || ""
        }`,
        TreatingPhysicianAddr1: cCAddress.address1 || "",
        TreatingPhysicianAddr2: cCAddress.address2 || "",
        TreatingPhysicianCity: cCAddress.city || "",
        TreatingPhysicianState: cCAddress.state || "",
        TreatingPhysicianZip: cCAddress.zip || "",
        TreatingPhysicianPhone: cCAddress.phone || "",
        TreatingPhysicianPhoneExt: cCAddress.phoneextension || "",
        TreatingPhysicianEmail: cCAddress.email || "",
        TreatingPhysicianFax: cCAddress.fax || "",
      }));
    }
  };

  return (
    <ExamineeForm
      casenbr={casenbr}
      chartnbr={chartnbr}
      claimnbr={claimnbr}
      disabled={disabled}
      handleTreatingChanged={handleTreatingChanged}
      initialExamineeState={initialExamineeState}
      inputs={inputs}
      resetExamineeInputs={resetExamineeInputs}
      titleTag={titleTag()}
      treating={physician}
      onChange={onChange}
      onSubmit={onSubmit}
    />
  );
};

Examinee.propTypes = {
  chartnbr: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  inputs: PropTypes.object,
  setInputs: PropTypes.func,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  initialState: PropTypes.object.isRequired,
  onProfileChange: PropTypes.func,
  initialExamineeState: PropTypes.object.isRequired,
  physician: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  resetExamineeInputs: PropTypes.func,
  plaintiffattorneycode: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  defenseattorneycode: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  casenbr: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  claimnbr: PropTypes.string,
};

Examinee.defaultProps = {
  chartnbr: "",
  inputs: {},
  setInputs: (e) => e,
  onChange: (e) => e,
  disabled: false,
  onProfileChange: (e) => e,
  physician: "",
  resetExamineeInputs: (e) => e,
  plaintiffattorneycode: "",
  defenseattorneycode: "",
  casenbr: "",
  claimnbr: "",
};

export default Examinee;
