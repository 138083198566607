import React, { useState, useRef, useLayoutEffect, useEffect } from "react";
import PropTypes from "prop-types";
import { Icon, Button, Dropdown } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ClickCapture from "../ClickCapture";

export default function DropdownButton({ options }) {
  const ref = useRef(null);
  const [open, setOpen] = useState(false);
  const [coords, setCoords] = useState({ top: "-50%", left: "-50%" });

  const handlePositionDropdown = () => {
    const element = ref.current;
    if (element) {
      const result = element.getBoundingClientRect();
      setCoords({ top: result.bottom, left: result.left });
    }
  };

  useLayoutEffect(() => {
    handlePositionDropdown(ref.current);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", () => setOpen(false));
    return () => window.removeEventListener("scroll", () => setOpen(false));
  }, []);

  const handleOpen = () => {
    handlePositionDropdown();
    setOpen((prev) => !prev);
  };
  return (
    <ClickCapture onOutsideClick={() => setOpen(false)}>
      <Dropdown managed active={open} ref={ref}>
        <Dropdown.Trigger>
          <Button
            size="small"
            style={{ height: 20, width: 20 }}
            title="Actions"
            onClick={handleOpen}
          >
            <Icon size="small">
              <FontAwesomeIcon icon="ellipsis-h" />
            </Icon>
          </Button>
        </Dropdown.Trigger>
        <Dropdown.Menu style={{ position: "fixed", ...coords }}>
          <Dropdown.Content>
            {options.map(({ title, onClick }) => (
              <Dropdown.Item
                key={title}
                style={{ fontSize: 12.8 }}
                onClick={onClick}
              >
                {title}
              </Dropdown.Item>
            ))}
          </Dropdown.Content>
        </Dropdown.Menu>
      </Dropdown>
    </ClickCapture>
  );
}

DropdownButton.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      onClick: PropTypes.func,
    })
  ),
};
DropdownButton.defaultProps = {
  options: [],
};
