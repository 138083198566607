import { gql } from "@apollo/client";

export const LIST_PROVIDER_TYPES_QUERY = gql`
  query providerTypes(
    $where: ProviderTypeWhereInput
    $orderBy: [ProviderTypeOrderByInput!]
    $take: Int
    $skip: Int
  ) {
    providerTypes(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
      ProvTypeCode
      description
      dateadded
      dateedited
      useridadded
      useridedited
    }
    aggregateProviderType: aggregateProviderType(where: $where) {
      count {
        ProvTypeCode
      }
    }
  }
`;

export const SINGLE_PROVIDER_TYPE_QUERY = gql`
  query SINGLE_PROVIDER_TYPE_QUERY($where: ProviderTypeWhereUniqueInput!) {
    providerType(where: $where) {
      ProvTypeCode
      description
    }
  }

`