import React from "react";
import PropTypes from "prop-types";
import { format } from "date-fns";
import {
  Input,
  Label,
  Field,
  Control,
  Select as RbxSelect,
  Textarea,
} from "rbx";
import { DateTime } from "luxon";
import StateSelect from "../../../../components/StateSelect";
import PhoneNumberInput from "../../../../components/PhoneNumberInput";
import ZipInput from "../../../../components/ZipInput";
import PrefixSelect from "../../../../components/PrefixSelect";
import SSNumberInput from "../../../../components/SSNumberInput";
import { calculateAge } from "../../../../utils";

const ExamineeForm = ({ inputs, onChange, disabled }) => (
  <div className="examinee-form case-examinee-form-section-personal-info">
    <Field className="span-2">
      <PrefixSelect
        disabled={disabled}
        id="prefix"
        label="Prefix"
        name="prefix"
        size="small"
        value={inputs.prefix}
        onChange={onChange}
      />
    </Field>
    <Field className="span-4">
      <Control expanded>
        <Label>First Name</Label>
        <Input
          required
          autoComplete="new"
          disabled={disabled}
          maxLength={50}
          name="firstname"
          size="small"
          value={inputs.firstname}
          onChange={onChange}
        />
      </Control>
    </Field>
    <Field className="span-2">
      <Control expanded>
        <Label>MI</Label>
        <Input
          autoComplete="new"
          disabled={disabled}
          maxLength={1}
          name="middleinitial"
          size="small"
          value={inputs.middleinitial}
          onChange={onChange}
        />
      </Control>
    </Field>
    <Field className="span-4">
      <Control expanded>
        <Label>Last Name</Label>
        <Input
          required
          autoComplete="new"
          disabled={disabled}
          maxLength={50}
          name="lastname"
          size="small"
          value={inputs.lastname}
          onChange={onChange}
        />
      </Control>
    </Field>
    <Field className="span-4">
      <Control expanded>
        <Label>
          {`Birth Date (Age ${calculateAge(new Date(inputs.DOB))})`}
        </Label>
        <Input
          defaultValue={
            inputs.DOB
              ? DateTime.fromISO(inputs.DOB)
                  .setZone("utc")
                  .toFormat("yyyy-MM-dd")
              : ""
          }
          disabled={disabled}
          key={inputs.DOB}
          max={format(new Date(), "yyyy-MM-dd")}
          name="DOB"
          size="small"
          type="date"
          onBlur={onChange}
        />
      </Control>
    </Field>

    <Field className="span-2">
      <Control expanded>
        <Label>Sex</Label>
        <RbxSelect.Container fullwidth size="small">
          <RbxSelect
            disabled={disabled}
            name="sex"
            value={inputs.sex}
            onChange={onChange}
          >
            <RbxSelect.Option value="" />
            <RbxSelect.Option value="Female">Female</RbxSelect.Option>
            <RbxSelect.Option value="Male">Male</RbxSelect.Option>
          </RbxSelect>
        </RbxSelect.Container>
      </Control>
    </Field>
    <Field className="span-3">
      <Control expanded>
        <Label>Phone</Label>
        <PhoneNumberInput
          disabled={disabled}
          name="phone1"
          value={inputs.phone1}
          onChange={onChange}
        />
      </Control>
    </Field>
    <Field className="span-3">
      <Control expanded>
        <Label>Cell Phone</Label>
        <PhoneNumberInput
          disabled={disabled}
          name="phone2"
          value={inputs.phone2}
          onChange={onChange}
        />
      </Control>
    </Field>
    <Field className="span-4">
      <Control expanded>
        <Label>Email</Label>
        <Input
          autoComplete="new"
          disabled={disabled}
          maxLength={50}
          name="email"
          size="small"
          value={inputs.email}
          onChange={onChange}
        />
      </Control>
    </Field>
    <Field className="span-4">
      <Control expanded>
        <Label>Social Security</Label>
        <SSNumberInput
          disabled={disabled}
          maxLength={11}
          name="SSN"
          size="small"
          value={inputs.SSN}
          onChange={onChange}
        />
      </Control>
    </Field>
    <Field className="span-8">
      <Control expanded>
        <Label>Address 1</Label>
        <Input
          required
          autoComplete="new"
          disabled={disabled}
          maxLength={50}
          name="addr1"
          size="small"
          value={inputs.addr1}
          onChange={onChange}
        />
      </Control>
    </Field>
    <Field className="span-8">
      <Control expanded>
        <Label>Address 2</Label>
        <Input
          autoComplete="new"
          disabled={disabled}
          maxLength={50}
          name="addr2"
          size="small"
          value={inputs.addr2}
          onChange={onChange}
        />
      </Control>
    </Field>
    <Field className="span-4">
      <Control expanded>
        <Label>City</Label>
        <Input
          required
          autoComplete="new"
          disabled={disabled}
          maxLength={50}
          name="city"
          size="small"
          type="text"
          value={inputs.city}
          onChange={onChange}
        />
      </Control>
    </Field>
    <Field className="span-4">
      <StateSelect
        required
        disabled={disabled}
        label="State"
        name="state"
        size="small"
        value={inputs.state}
        onChange={onChange}
      />
    </Field>
    <Field className="span-4">
      <ZipInput
        disabled={disabled}
        state={inputs.state}
        value={inputs.zip || ""}
        onChange={onChange}
      />
    </Field>
    <Field className="span-4">
      <Control expanded>
        <Label>County</Label>
        <Input
          autoComplete="new"
          disabled={disabled}
          maxLength={50}
          name="county"
          size="small"
          value={inputs.county}
          onChange={onChange}
        />
      </Control>
    </Field>
    <Field className="span-16">
      <Control>
        <Label>Chart Notes</Label>
        <Textarea
          disabled={disabled}
          name="note"
          size="small"
          value={inputs.note}
          onChange={onChange}
        />
      </Control>
    </Field>
  </div>
);

ExamineeForm.propTypes = {
  inputs: PropTypes.object,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

ExamineeForm.defaultProps = {
  inputs: {},
  onChange: (e) => e,
  disabled: false,
};

export default ExamineeForm;
