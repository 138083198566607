import React, { useState, useMemo, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { Title, Field, Control, Button, Icon, Heading } from "rbx";

import { useQuery, useApolloClient, useLazyQuery } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { endOfDay, format, startOfDay } from "date-fns";
import { LIST_CASE_TRACKER_ITEMS_QUERY } from "../../graphql/caseTracker";
import { AGGREGATE_CASE_QUERY } from "../../graphql/case";
import {
  AGGREGATE_CASE_OTHER_PARTY,
  LIST_CASE_OTHER_PARTIES,
} from "../../graphql/caseOtherParty";

import { DEFAULT_COLUMNS } from "./columns";
import Table from "../../components/DataTable/Table";
import BooleanInput from "../../components/BooleanInput";

import Loader from "../../components/Loader";
import CaseTrackerFilters from "./CaseTrackerFilters";

import { saveFilterState, getFilterState } from "../../utils";
import { useLocalStorage } from "../../hooks";

const CaseTrackerPage = (props) => {
  const client = useApolloClient();

  const [where, setWhere] = useState(
    getFilterState("CASE_TRACKER_FILTERS", {})
  );
  const history = useHistory();
  const [showEmpty] = useState(false);
  const [date] = useState(format(new Date(), "yyyy-MM-dd"));
  const [refreshEveryMinute, setRefreshEveryMinute] = useLocalStorage(
    "REFRESH_EVERY_MINUTE",
    false
  );

  const endOfSelectedDay = useMemo(
    () => endOfDay(new Date(date.split("-").map((x) => parseInt(x, 10)))),
    [date]
  );
  const startOfSelectedDay = useMemo(
    () => startOfDay(new Date(date.split("-").map((x) => parseInt(x, 10)))),
    [date]
  );

  const [getCaseTrackerData, { data: caseTrackerData, loading }] = useLazyQuery(
    LIST_CASE_TRACKER_ITEMS_QUERY,
    {
      variables: {
        where: {
          ...where,
          dateadded: { lt: endOfSelectedDay },
        },
      },
      fetchPolicy: "cache-and-network",
    }
  );

  useEffect(() => {
    getCaseTrackerData();
  }, [getCaseTrackerData]);

  const {
    data: newCasesTodayData,
    //  loading: newCasesTodayLoading
  } = useQuery(AGGREGATE_CASE_QUERY, {
    variables: {
      where: {
        dateadded: {
          gte: startOfSelectedDay,
          lte: endOfSelectedDay,
        },
      },
    },
    fetchPolicy: "cache-and-network",
  });

  const {
    data: appointmentsTodayData,
    // loading: appointmentsTodayLoading,
  } = useQuery(AGGREGATE_CASE_QUERY, {
    variables: {
      where: {
        Appttime: {
          gte: startOfSelectedDay,
          lte: endOfSelectedDay,
        },
      },
      fetchPolicy: "cache-and-network",
    },
  });

  const {
    data: specialServiceAppointmentsTodayData,
    // loading: specialServiceAppointmentsTodayLoading,
  } = useQuery(AGGREGATE_CASE_OTHER_PARTY, {
    variables: {
      where: {
        duedate: {
          gte: startOfSelectedDay,
          lte: endOfSelectedDay,
        },
      },
    },
    fetchPolicy: "cache-and-network",
  });

  const data = showEmpty
    ? caseTrackerData?.caseTrackerItems
    : caseTrackerData?.caseTrackerItems?.filter(
      (item) => item.caseCount || item.rushCount
    );

  useEffect(() => {
    saveFilterState("CASE_TRACKER_FILTERS", where);
  }, [where]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (refreshEveryMinute) {
        getCaseTrackerData();
      }
    }, 60e3);

    return () => clearInterval(interval);
  }, [getCaseTrackerData, refreshEveryMinute]);

  const handleChange = (name, value) => {
    setWhere((prev) =>
      value
        ? { ...prev, [name]: value }
        : Object.keys(prev).reduce((acc, curr) => {
          if (curr !== name) acc[curr] = prev[curr];
          return acc;
        }, {})
    );
  };

  const handleReset = () => {
    saveFilterState("CASE_TRACKER_FILTERS", {});
    setWhere({});
  };

  // const handleDateChange = (e) => {
  //   setDate(e.target.value);
  // };

  // const handleEmptyChange = () => {
  //   setShowEmpty((prev) => !prev);
  // };

  const handleNewCasesTodayClick = () => {
    saveFilterState("CASE_LIST_FILTERS", {
      dateadded: {
        gte: startOfSelectedDay,
        lte: endOfSelectedDay,
      },
    });
    history.push(`/cases`);
  };

  const handleAppointmentsTodayClick = () => {
    saveFilterState("CASE_LIST_FILTERS", {
      Appttime: {
        gte: startOfSelectedDay,
        lte: endOfSelectedDay,
      },
    });
    history.push(`/cases`);
  };

  const handleSpecialServicesTodayClick = async () => {
    const {
      data: { caseOtherParties },
    } = await client.query({
      query: LIST_CASE_OTHER_PARTIES,
      variables: {
        where: {
          duedate: {
            gte: startOfSelectedDay,
            lte: endOfSelectedDay,
          },
        },
      },
      fetchPolicy: 'network-only'
    });
    saveFilterState("CASE_LIST_FILTERS", {
      casenbr: { in: caseOtherParties.map((x) => x.casenbr) },
    });
    history.push(`/cases`);
  };

  const handleRowClick = ({ statuscode }) => {
    if (statuscode === 1) {
      saveFilterState("CASE_LIST_AWAITING_APPT_FILTERS", {
        ...where,
        status: { equals: statuscode },
      });
      history.push("/cases/awaiting-appointment");
    } else if (statuscode === 5) {
      saveFilterState("CASE_LIST_ACCOUNTING_APPT_FILTERS", {
        ...where,
        status: { equals: statuscode },
      });
      history.push("/cases/accounting");
    } else if (statuscode === 13) {
      sessionStorage.setItem(
        "CASE_LIST_IN_QA_FILTERS",
        JSON.stringify({ ...where, status: { equals: statuscode } })
      );
      history.push("/cases/in-qa");
    } else {
      saveFilterState("CASE_LIST_FILTERS", {
        status: { equals: statuscode },
        ...where,
        dateadded: { lt: endOfSelectedDay },
      });
      history.push(`/cases`);
    }
  };

  const handleRefreshClick = () => {
    getCaseTrackerData();
  };

  const handleRefreshEveryMinute = () => {
    setRefreshEveryMinute(!refreshEveryMinute);
  };

  return (
    <div className="case-tracker-page">
      {/* <header className="page-head">
        <div className="page-head-start">
          <Title>Case Tracker</Title>
        </div>
        <div className="page-head-end">
          <Field kind="group">
            <Control size="small">
              <Label>&nbsp;</Label>
              <Label>
                <span>Show Empty</span>
                &nbsp;
                <Checkbox checked={showEmpty} onChange={handleEmptyChange} />
              </Label>
            </Control>
            <Control size="small">
              <Label>Date</Label>
              <Input
                defaultValue={date}
                size="small"
                type="date"
                onBlur={handleDateChange}
              />
            </Control>
            <Control>
              <Label>&nbsp;</Label>
              <Button color="primary" size="small">
                <Icon>
                  <FontAwesomeIcon icon="sync-alt" />
                </Icon>
                <span>Refresh</span>
              </Button>
            </Control>
          </Field>
        </div>
      </header> */}

      <div className="case-tracker-container">
        <div className="case-tracker-filters">
          <Field>
            <Control>
              <Title size={5}>Filters</Title>
            </Control>
          </Field>
          <CaseTrackerFilters
            where={where}
            onChange={handleChange}
            onReset={handleReset}
          />
        </div>
        <div className="case-tracker-table">
          <Field kind="group">
            <Control expanded>
              <Title size={5}>Case Tracker</Title>
            </Control>
            <Field kind="group">
              <BooleanInput
                label="Refresh Every Minute"
                name="refreshEveryMinute"
                value={refreshEveryMinute}
                onChange={handleRefreshEveryMinute}
              />

              <Control>
                <Button
                  color="primary"
                  disabled={loading}
                  size="small"
                  state={loading ? "loading" : ""}
                  onClick={handleRefreshClick}
                >
                  <Icon>
                    <FontAwesomeIcon icon="sync" />
                  </Icon>
                  <span>Refresh</span>
                </Button>
              </Control>
            </Field>
          </Field>
          {loading && !Array.isArray(data) && <Loader />}
          {!loading && !Array.isArray(data) && (
            <Title subtitle style={{ color: "gray" }}>
              Nothing here...
            </Title>
          )}
          {Array.isArray(data) && (
            <Table
              columns={DEFAULT_COLUMNS}
              data={data}
              onRowClick={handleRowClick}
            />
          )}
        </div>
        <div className="case-tracker-metrics">
          <div className="case-tracker-metric">
            <Heading style={{ fontSize: 18 }}>New Cases Today</Heading>
            <Title as="a" size={1} onClick={handleNewCasesTodayClick}>
              {newCasesTodayData?.aggregateCase?.count?.casenbr || 0}
            </Title>
          </div>
          <div className="case-tracker-metric">
            <Heading style={{ fontSize: 18 }}>Appointments Today</Heading>
            <Title as="a" size={1} onClick={handleAppointmentsTodayClick}>
              {appointmentsTodayData?.aggregateCase?.count?.casenbr || 0}
            </Title>
          </div>
          <div className="case-tracker-metric">
            <Heading style={{ fontSize: 18 }}>Special Services Today</Heading>
            <Title as="a" size={1} onClick={handleSpecialServicesTodayClick}>
              {specialServiceAppointmentsTodayData?.aggregateCaseOtherParty
                ?.count?.casenbr || 0}
            </Title>
          </div>
        </div>
      </div>
      {/* <pre>
        <code>{JSON.stringify(data, null, 2)}</code>
      </pre> */}
    </div>
  );
};

CaseTrackerPage.propTypes = {};

export default CaseTrackerPage;
