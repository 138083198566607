import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

import { Icon } from "rbx";
import "./IconLink.scss";

const IconLink = ({ href, label, icon, to }) => {
  if (label) {
    if (href) {
      return (
        <a className="icon-link" href={href}>
          <Icon>
            <FontAwesomeIcon icon={icon} />
          </Icon>
          <span>{label}</span>
        </a>
      );
    }
    if (to) {
      return (
        <Link className="icon-link" to={to}>
          <Icon>
            <FontAwesomeIcon icon={icon} />
          </Icon>
          <span>{label}</span>
        </Link>
      );
    }
  }
  return null;
};

IconLink.propTypes = {
  to: PropTypes.string,
  href: PropTypes.string,
  label: PropTypes.string,
  icon: PropTypes.string,
};

IconLink.defaultProps = {
  to: "",
  href: "",
  label: "",
  icon: "",
};
export default IconLink;
