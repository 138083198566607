import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";

import { Control, Input, Label } from "rbx";

import { debounce } from "../../utils";

const FilterInput = ({
  value,
  name,
  label,
  size,
  onChange,
  onBlur,
  disabled,
  getValue,
  setValue,
  placeholder,
  type,
}) => {
  const [innerValue, setInnerValue] = useState(getValue(value));

  useEffect(() => {
    setInnerValue(getValue(value));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleChange = useMemo(
    () =>
      debounce((val) => {
        onChange(name, setValue(val));
      }, 1000),
    [name, onChange, setValue]
  );

  const handleInnerChange = (e) => {
    setInnerValue(e.target.value);
    handleChange(e.target.value);
  };

  const handleBlur = (e) => {
    onBlur(e.target.name, e.target.value);
  };

  return (
    <Control expanded>
      <Label>{label}</Label>
      <Input
        autoComplete="new"
        disabled={disabled}
        name={name}
        placeholder={placeholder}
        size={size}
        type={type}
        value={innerValue}
        onBlur={handleBlur}
        onChange={handleInnerChange}
      />
    </Control>
  );
};

FilterInput.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.number,
  ]),
  name: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  getValue: PropTypes.func,
  setValue: PropTypes.func,
  placeholder: PropTypes.string,
  onBlur: PropTypes.func,
  type: PropTypes.string,
};

FilterInput.defaultProps = {
  value: "",
  name: "",
  placeholder: "",
  label: "",
  size: "small",
  onChange: () => {},
  disabled: false,
  getValue: (x) => x,
  setValue: (x) => x,
  onBlur: () => {},
  type: "text",
};

export default FilterInput;
