import { gql } from "@apollo/client";

export const FIND_FIRST_WEB_USER_QUERY = gql`
  query FIND_FIRST_WEB_USER_QUERY($where: WebUserWhereInput!) {
    findFirstWebUser(where: $where) {
      WebUserID
      UserID
      IMECentricCode
      UserType
    }
  }
`;

export const SINGLE_WEB_USER_QUERY = gql`
  query SINGLE_WEB_USER_QUERY($where: WebUserWhereUniqueInput!) {
    webUser(where: $where) {
      WebUserID
      UserID
      Password
      LastLoginDate
    }
  }
`;

export const FIND_MANY_WEB_USERS_QUERY = gql`
  query FIND_MANY_WEB_USERS_QUERY(
    $where: WebUserWhereInput!
    $take: Int
    $limit: Int
    $orderBy: WebUserOrderByInput
  ) {
    findManyWebUsers(
      where: $where
      take: $take
      limit: $limit
      orderBy: $orderBy
    ) {
      WebUserID
      UserID
      IMECentricCode
      UserType
    }
  }
`;

export const LIST_WEB_USER_QUERY = gql`
  query LIST_WEB_USER_QUERY(
    $where: WebUserWhereInput
    $orderBy: [WebUserOrderByInput!]
    $take: Int
    $skip: Int
  ) {
    webUsers(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
      WebUserID
      UserID
      Password
      LastLoginDate
    }
  }
`;
