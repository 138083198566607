import React, { useState } from "react";
import { Title, Field, Control, Button, Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useModal } from "../../../../context";
import DataTable from "../../../../components/DataTable";
import { ALL_USERS_QUERY } from "../../../../graphql";
import ProcessorsModal from "../../components/Processors/ProcessorsModal";
import COLUMNS from "./columns";

const DEFAULT_FILTER_STATE = {
  userid: { contains: "" },
  firstname: { contains: "" },
  lastname: { contains: "" },
  AllstateProcessor: { equals: 1 },
};

const ProcessorsPage = () => {
  const [
    where,
    // setWhere
  ] = useState(DEFAULT_FILTER_STATE);
  // const [searchValue, setSearchValue] = useState("");
  // const isModalOpen = useRef(false);
  const { setModalOpen } = useModal();

  const handleRowClick = (row) => {
    setModalOpen(
      true,
      <ProcessorsModal
        rowClick
        rowData={row}
        where={{
          userid: { equals: row.userid },
        }}
        onComplete={() => setModalOpen(false)}
      />
    );
  };

  // const handleSearchChange = debounce(({ target: { value } }) => {
  //   if (value) {
  //     setSearchValue(value);
  //     // setWhere({
  //     //   OR: [
  //     //     {
  //     //       description: { contains: value },
  //     //     },
  //     //   ],
  //     // });
  //   } else {
  //     setSearchValue("");
  //     // setWhere({
  //     //   where: [
  //     //     {
  //     //       userid: { contains: "" },
  //     //       firstname: { contains: ""},
  //     //       lastname: { contains: ""},
  //     //       AllstateProcessor: {equals: 1}
  //     //     },
  //     //   ],
  //     // });
  //   }
  // }, 500);

  return (
    <div>
      <div className="section-header">
        <Title style={{ marginRight: 15 }}>Processors</Title>
        <Field kind="group" style={{ paddingTop: "0.3rem" }}>
          {/* <Control>
            <Input
              autoComplete="off"
              id="search-input"
              placeholder="Search..."
              size="small"
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value);
                handleSearchChange(e);
              }}
            />
          </Control> */}

          <Control>
            <Button
              color="primary"
              size="small"
              onClick={() => {
                setModalOpen(
                  true,
                  <ProcessorsModal
                    where={where}
                    onComplete={() => setModalOpen(false)}
                  />
                );
              }}
            >
              <Icon>
                <FontAwesomeIcon icon="plus" />
              </Icon>
              <span>Add</span>
            </Button>
          </Control>
        </Field>
      </div>
      <DataTable
        columns={COLUMNS}
        fetchPolicy="network-only"
        name="users"
        orderBy={[{ id: "firstname", desc: false }]}
        query={ALL_USERS_QUERY}
        where={where}
        onRowClick={handleRowClick}
      />
    </div>
  );
};

ProcessorsPage.propTypes = {};

export default ProcessorsPage;
