import React from "react";

import PropTypes from "prop-types";

import { Control, Label, Select } from "rbx";

import { useQuery } from "@apollo/client";
import { ALL_CASE_TYPES_SELECT_QUERY } from "../../graphql";
import caseTypesToExclude from "./caseTypesToExclude";

const CaseTypeSelect = ({
  value,
  name,
  label,
  size,
  onChange,
  disabled,
  getValue,
  setValue,
  required,
  expanded,
  where,
  showBlank,
  showBlankOptionLabel,
}) => {
  const { data: caseTypeData } = useQuery(ALL_CASE_TYPES_SELECT_QUERY, {
    variables: {
      orderBy: { description: "asc" },
      where,
    },
  });

  const caseTypes = caseTypeData?.caseTypes;

  const handleChange = (e) => {
    onChange(
      e.target.name,
      setValue(e.target.value ? parseInt(e.target.value, 10) : "")
    );
  };

  return (
    <Control expanded={expanded}>
      <Label>{label}</Label>
      <Select.Container
        fullwidth
        size={size}
        state={Array.isArray(caseTypes) ? "" : "loading"}
      >
        <Select
          disabled={disabled}
          name={name}
          required={required}
          value={getValue(value)}
          onChange={handleChange}
        >
          {showBlank && (
            <Select.Option value="">{showBlankOptionLabel}</Select.Option>
          )}
          {Array.isArray(caseTypes) &&
            caseTypes.map(
              (type) =>
                !caseTypesToExclude[type.code] && (
                  <Select.Option
                    key={`casetype-${type.code}`}
                    value={type.code}
                  >
                    {type.description}
                  </Select.Option>
                )
            )}
        </Select>
      </Select.Container>
    </Control>
  );
};

CaseTypeSelect.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.number,
    PropTypes.array,
  ]),
  name: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  getValue: PropTypes.func,
  setValue: PropTypes.func,
  required: PropTypes.bool,
  expanded: PropTypes.bool,
  where: PropTypes.object,
  showBlank: PropTypes.bool,
  showBlankOptionLabel: PropTypes.string,
};

CaseTypeSelect.defaultProps = {
  value: "",
  name: "",
  label: "Case Type",
  size: "small",
  where: {},
  onChange: () => null,
  disabled: false,
  getValue: (x) => x,
  setValue: (x) => x,
  required: false,
  expanded: false,
  showBlank: true,
  showBlankOptionLabel: "",
};

export default CaseTypeSelect;
