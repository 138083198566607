import { gql } from "@apollo/client";

// eslint-disable-next-line import/prefer-default-export
export const CREATE_APOLLO_SERVICE_MUTATION = gql`
  mutation CREATE_APOLLO_SERVICE_MUTATION($data: ServicesCreateInput!) {
    createServices(data: $data) {
      ServiceID
    }
  }
`;
