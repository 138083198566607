import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";

import { differenceInCalendarDays } from "date-fns";
import ServiceQueuesSelect from "../../../../components/ServiceQueuesSelect";

import {
  getDaysDiffBetweenDates,
  convertTimeZoneDataBase,
  convertTimeZone,
  getDateEST,
} from "../../../../utils";

import {
  LIST_CASE_HISTORY_QUERY,
  UPDATE_CASE_MUTATION,
  CREATE_CASE_HISTORY_MUTATION,
  SINGLE_CASE_QUERY,
  SINGLE_QUEUE_QUERY,
  LIST_CASES_QUERY,
} from "../../../../graphql";

import { useAuth, useModal } from "../../../../context";
import CaseCancellationModal from "../CaseCancellationModal";

const CaseStatusSelect = ({
  casenbr,
  serviceCode,
  statusCode,
  caseTypeCode,
  officeCode,
  executeExceptions,
}) => {
  const { state: authState } = useAuth();

  const client = useApolloClient();

  const { setModalOpen } = useModal();

  const { data: CaseItemData } = useQuery(SINGLE_CASE_QUERY, {
    variables: { where: { casenbr: parseInt(casenbr, 10) } },
  });

  const [updateCase] = useMutation(UPDATE_CASE_MUTATION);

  const [createCaseHistory] = useMutation(CREATE_CASE_HISTORY_MUTATION);

  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("");

  useEffect(() => {
    setStatus(statusCode);
  }, [statusCode, CaseItemData?.caseItem?.status]);

  const handleChange = async (name, newStatusCode) => {
    try {
      const originalStatusCode = status || CaseItemData?.caseItem?.status;
      const date = convertTimeZoneDataBase(getDateEST());

      setLoading(true);
      setStatus(newStatusCode);

      const { data: OriginalQueueData } = await client.query({
        query: SINGLE_QUEUE_QUERY,
        variables: {
          where: { statuscode: parseInt(originalStatusCode, 10) },
        },
      });

      const { data: NewQueueData } = await client.query({
        query: SINGLE_QUEUE_QUERY,
        variables: {
          where: { statuscode: parseInt(newStatusCode, 10) },
        },
      });

      const performUpdate = async () => {
        const duration = CaseItemData?.caseItem?.laststatuschg
          ? differenceInCalendarDays(
              date,
              convertTimeZone(new Date(CaseItemData.caseItem.laststatuschg))
            )
          : null;

        await Promise.all([
          updateCase({
            variables: {
              where: { casenbr: parseInt(casenbr, 10) },
              data: {
                Status: {
                  connect: { statuscode: parseInt(newStatusCode, 10) },
                },
                office: { connect: { officecode: parseInt(officeCode, 10) } },
                service: {
                  connect: { servicecode: parseInt(serviceCode, 10) },
                },
                caseType: { connect: { code: parseInt(caseTypeCode, 10) } },
                laststatuschg: { set: date },
                dateedited: { set: date },
                useridedited: { set: authState?.user?.userid },
              },
            },
            refetchQueries: [
              {
                query: SINGLE_CASE_QUERY,
                variables: { where: { casenbr: parseInt(casenbr, 10) } },
              },
              {
                query: LIST_CASES_QUERY,
              },
            ],
          }),
          createCaseHistory({
            variables: {
              data: {
                casenbr: parseInt(casenbr, 10),
                eventdate: date,
                eventdesc: `Status Chg New Status - ${
                  NewQueueData.queue.shortdesc || ""
                }`,
                otherinfo: [
                  OriginalQueueData?.queue?.statusdesc &&
                    `Old - ${OriginalQueueData.queue.statusdesc}`,
                  NewQueueData?.queue?.statusdesc &&
                    `New - ${NewQueueData.queue.statusdesc}`,
                ]
                  .filter(Boolean)
                  .join(" "),
                userid: authState?.user?.userid,
                dateadded: date,
                useridedited: authState?.user?.userid,
                dateedited: date,
                duration,
                type: "StatChg",
                status: parseInt(newStatusCode, 10),
              },
            },
            refetchQueries: [
              {
                query: LIST_CASE_HISTORY_QUERY,
                variables: {
                  where: { casenbr: { equals: parseInt(casenbr, 10) } },
                  orderBy: [{ eventdate: "desc" }],
                },
              },
            ],
          }),
          executeExceptions(
            OriginalQueueData?.queue?.statuscode,
            newStatusCode
          ),
        ]);
      };

      if (NewQueueData?.queue?.statusdesc === "Cancelled") {
        const Appttime = CaseItemData?.caseItem?.Appttime;

        const daysDiff = Appttime
          ? getDaysDiffBetweenDates(new Date(), convertTimeZone(Appttime))
          : Number.MAX_SAFE_INTEGER;

        const isLateCancel = daysDiff < 1;

        setModalOpen(
          true,
          <CaseCancellationModal
            casenbr={casenbr}
            isLateCancel={isLateCancel}
            onComplete={async (shouldUpdate) => {
              setModalOpen(false, "");
              if (shouldUpdate) {
                await performUpdate();
                toast.success(`Case status updated successfully.`);
              }
            }}
          />
        );
      } else {
        await performUpdate();
        toast.success(`Case status updated successfully.`);
      }
    } catch (err) {
      toast.error(`Error updating case status.`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ServiceQueuesSelect
      fullWidth
      disabled={loading || !serviceCode || !caseTypeCode || !officeCode}
      loading={loading}
      name="status"
      serviceCode={serviceCode}
      showLabel={false}
      size="small"
      value={status}
      onChange={handleChange}
    />
  );
};

CaseStatusSelect.propTypes = {
  casenbr: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  serviceCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  statusCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  caseTypeCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  officeCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  executeExceptions: PropTypes.func,
};

CaseStatusSelect.defaultProps = {
  serviceCode: "",
  statusCode: "",
  caseTypeCode: "",
  officeCode: "",
  executeExceptions: () => {},
};
export default CaseStatusSelect;
