import React from "react";
import PropTypes from "prop-types";

import { Field, Control, Label, Title, Input } from "rbx";

import AlphaInput from "../../../../components/AlphaInput";
import PhoneNumberInput from "../../../../components/PhoneNumberInput";
import ZipInput from "../../../../components/ZipInput";
import StateSelect from "../../../../components/StateSelect"; // state dropdown change from text area

const RequestGeneratorEntryForm = ({
  inputs,
  onChange,
  mode,
  label,
  disabled,
}) => (
  <Field id="request-generator-entry-form">
    <Field style={{ marginBottom: "1.25rem" }}>
      <Title subtitle size={6} style={{ marginBottom: "0.75rem" }}>
        {label}
      </Title>
      <Field id="request-generator-entry-form-section" kind="group">
        <Control expanded>
          <Label>First Name</Label>
          <AlphaInput
            allowSpaces
            disabled={disabled}
            name="RGnameFirst"
            placeholder="First Name"
            required={mode === "add"}
            size="small"
            value={inputs.RGnameFirst}
            onChange={(e) => onChange(e.target.name, e.target.value)}
          />
        </Control>
        <Control expanded>
          <Label>Last Name</Label>
          <AlphaInput
            allowSpaces
            disabled={disabled}
            name="RGnameLast"
            placeholder="Last Name"
            required={mode === "add"}
            size="small"
            value={inputs.RGnameLast}
            onChange={(e) => onChange(e.target.name, e.target.value)}
          />
        </Control>
        <Control expanded>
          <Label>Court ID</Label>
          <Input
            disabled={disabled}
            name="RGcourtID"
            placeholder="Court ID"
            size="small"
            value={inputs.RGcourtID}
            onChange={(e) => onChange(e.target.name, e.target.value)}
          />
        </Control>
        <Control expanded>
          <Label>Primary Email</Label>
          <Input
            disabled={disabled}
            name="RGemail"
            pattern="^[\w]{1,}[\w.+-]{0,}@[\w-]{2,}([.][a-zA-Z]{2,}|[.][\w-]{2,}[.][a-zA-Z]{2,})$" // Explanation of the regex: https://stackoverflow.com/questions/5601647/html5-email-input-pattern-attribute
            placeholder="example@domain.com"
            size="small"
            type="email"
            value={inputs.RGemail}
            onChange={(e) => onChange(e.target.name, e.target.value)}
          />
        </Control>
        <Control expanded>
          <Label>Secondary Email</Label>
          <Input
            disabled={disabled}
            name="RGemail2"
            pattern="^[\w]{1,}[\w.+-]{0,}@[\w-]{2,}([.][a-zA-Z]{2,}|[.][\w-]{2,}[.][a-zA-Z]{2,})$"
            placeholder="example@domain.con"
            size="small"
            type="email"
            value={inputs.RGemail2}
            onChange={(e) => onChange(e.target.name, e.target.value)}
          />
        </Control>
      </Field>
    </Field>

    <Field id="request-generator-entry-form-section">
      <Title subtitle size={6} style={{ marginBottom: "0.75rem" }}>
        Client Office
      </Title>
      <Field
        id="request-generator-entry-form-section"
        kind="group"
        style={{ width: "100%" }}
      >
        <Control expanded>
          <Label>Company</Label>
          <Input
            disabled={disabled}
            name="COofficeName"
            placeholder="Company"
            required={mode === "add"}
            size="small"
            value={inputs.COofficeName}
            onChange={(e) => onChange(e.target.name, e.target.value)}
          />
        </Control>
        <Control expanded>
          <Label>Phone</Label>
          <PhoneNumberInput
            disabled={disabled}
            name="COphone1"
            placeholder="Phone"
            size="small"
            value={inputs.COphone1}
            onChange={onChange}
          />
        </Control>
        <Control expanded>
          <Label>Fax</Label>
          <PhoneNumberInput
            disabled={disabled}
            name="COfax"
            placeholder="Fax"
            size="small"
            value={inputs.COfax}
            onChange={onChange}
          />
        </Control>
      </Field>

      <Field id="request-generator-entry-form-section" kind="group">
        <Control expanded>
          <Label>Address 1</Label>
          <Input
            disabled={disabled}
            name="COaddress1"
            placeholder="Address 1"
            required={mode === "add"}
            size="small"
            value={inputs.COaddress1}
            onChange={(e) => onChange(e.target.name, e.target.value)}
          />
        </Control>
        <Control expanded>
          <Label>Address 2</Label>
          <Input
            disabled={disabled}
            name="COaddress2"
            placeholder="Address 2"
            size="small"
            value={inputs.COaddress2}
            onChange={(e) => onChange(e.target.name, e.target.value)}
          />
        </Control>
        <Control expanded>
          <Label>City</Label>
          <Input
            disabled={disabled}
            name="COcity"
            placeholder="City"
            required={mode === "add"}
            size="small"
            value={inputs.COcity}
            onChange={(e) => onChange(e.target.name, e.target.value)}
          />
        </Control>
        <Control expanded>
          <StateSelect
            disabled={disabled}
            id="state"
            label="State"
            name="COstate"
            required={mode === "add"}
            size="small"
            value={inputs.COstate}
            onChange={onChange}
          />
        </Control>
        <Control expanded>
          <ZipInput
            disabled={disabled}
            label="Zip"
            name="COzip"
            placeholder="Zip"
            required={mode === "add"}
            size="small"
            value={inputs.COzip}
            onChange={onChange}
          />
        </Control>
      </Field>
    </Field>
  </Field>
);

RequestGeneratorEntryForm.propTypes = {
  inputs: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  mode: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
};

RequestGeneratorEntryForm.defaultProps = {
  mode: "search",
  label: "",
  disabled: false,
};

export default RequestGeneratorEntryForm;
