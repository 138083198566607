import { gql } from "@apollo/client";

export const LIST_SPECIALTIES_QUERY = gql`
  query LIST_SPECIALTIES_QUERY(
    $where: SpecialtyWhereInput
    $orderBy: [SpecialtyOrderByInput!]
    $take: Int
    $skip: Int
  ) {
    specialties(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
      specialtycode
      description
    }
  }
`;

export const ALL_SPECIALTIES_QUERY = gql`
  query ALL_SPECIALITIES_QUERY(
    $where: SpecialtyWhereInput
    $orderBy: [SpecialtyOrderByInput!]
    $take: Int
    $skip: Int
  ) {
    specialties(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
      specialtycode
      description
      dateadded
      useridadded
      dateedited
      useridedited
      PublishOnWeb
      WebSynchDate
      LOB
    }
    aggregateSpecialty(where: $where) {
      count {
        _all
      }
    }
  }
`;

export const SINGLE_SPECIALTY_QUERY = gql`
  query SINGLE_SPECIALTY_QUERY($where: SpecialtyWhereUniqueInput!) {
    specialty(where: $where) {
      specialtycode
      description
      dateadded
      useridadded
      dateedited
      useridedited
      PublishOnWeb
    }
  }
`;
