import React from "react";
import PropTypes from "prop-types";
import { Column, Field, Control, Label, Input } from "rbx";
import CompanySelect from "../../../../components/CompanySelect";
import ClientTypeSelect from "../../../../components/ClientTypeSelect";
import StateSelect from "../../../../components/StateSelect";

import PhoneNumberInput from "../../../../components/PhoneNumberInput";
import ZipInput from "../../../../components/ZipInput";
import PrefixSelect from "../../../../components/PrefixSelect";
import StatusSelect from "../../../../components/StatusSelect";
import UserSelect from "../../../../components/UserSelect";
import CaseTypeSelect from "../../../../components/CaseTypeSelect";
import PrioritySelect from "../../../../components/PrioritySelect";

const AddClientForm = ({
  inputs,
  onChange,
  codeStates,
  disabled,
  adding,
  onSubmit,
  formId,
}) => (
  <form id={formId} onSubmit={onSubmit}>
    <Column.Group>
      <Column>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gridColumnGap: "0.75rem",
          }}
        >
          <Field>
            <Control expanded>
              <CompanySelect
                fullWidth
                required
                disabled={!adding}
                label="Company"
                name="companycode"
                value={inputs.companycode}
                onChange={onChange}
              />
            </Control>
          </Field>
          <Field>
            <ClientTypeSelect
              disabled={disabled}
              label="Client Type"
              name="TypeCode"
              size="small"
              value={inputs.TypeCode}
              onChange={onChange}
            />
          </Field>
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "0.5fr 1fr 1fr 0.5fr",
            gridColumnGap: "0.75rem",
          }}
        >
          <Field>
            <PrefixSelect
              disabled={disabled}
              label="Prefix"
              name="prefix"
              value={inputs.prefix}
              onChange={onChange}
            />
          </Field>
          <Field>
            <Control>
              <Label>First Name</Label>
              <Input
                required
                autoComplete="new"
                disabled={disabled}
                maxLength={50}
                name="firstname"
                size="small"
                type="text"
                value={inputs.firstname}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
            </Control>
          </Field>
          <Field style={{ gridColumn: "3 / 5" }}>
            <Control expanded>
              <Label>Last Name</Label>
              <Input
                required
                autoComplete="new"
                disabled={disabled}
                maxLength={50}
                name="lastname"
                size="small"
                type="text"
                value={inputs.lastname}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
            </Control>
          </Field>
        </div>
        <Field kind="group">
          <Control expanded>
            <Label>Address 1</Label>
            <Input
              required
              autoComplete="new"
              disabled={disabled}
              maxLength={50}
              name="addr1"
              size="small"
              type="text"
              value={inputs.addr1}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </Control>
          <Control expanded>
            <Label>Address 2</Label>
            <Input
              autoComplete="new"
              disabled={disabled}
              maxLength={50}
              name="addr2"
              size="small"
              type="text"
              value={inputs.addr2}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </Control>
        </Field>

        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr",
            gridColumnGap: "0.75rem",
          }}
        >
          <Field>
            <Control>
              <Label>City</Label>
              <Input
                required
                autoComplete="new"
                disabled={disabled}
                maxLength={50}
                name="city"
                size="small"
                type="text"
                value={inputs.city}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
            </Control>
          </Field>
          <Field>
            <StateSelect
              required
              disabled={disabled}
              label="State"
              name="state"
              size="small"
              value={inputs.state}
              onChange={onChange}
            />
          </Field>
          <Field>
            <ZipInput
              required
              disabled={disabled}
              state={inputs.state}
              value={inputs.zip || ""}
              onChange={onChange}
            />
          </Field>
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gridColumnGap: "0.75rem",
          }}
        >
          <Field>
            <UserSelect
              required
              disabled={disabled}
              label="Marketer"
              name="marketercode"
              value={inputs.marketercode || ""}
              where={{
                fldActive: { equals: 1 },
                OR: [
                  { usertype: { equals: "MK" } },
                  { usertype: { equals: "*" } },
                ],
              }}
              onChange={onChange}
            />
          </Field>
          <Field>
            <CaseTypeSelect
              disabled={disabled}
              label="Case Type"
              name="casetype"
              setValue={(x) => (x ? String(x) : "")}
              size="small"
              value={inputs.casetype || ""}
              onChange={onChange}
            />
          </Field>
        </div>
      </Column>
      <Column>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gridColumnGap: "0.75rem",
          }}
        >
          <Field>
            <Control expanded>
              <Label>Title</Label>
              <Input
                autoComplete="new"
                disabled={disabled}
                maxLength={50}
                name="title"
                size="small"
                type="text"
                value={inputs.title}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
            </Control>
          </Field>
          <Field>
            <Control>
              <StatusSelect
                disabled={disabled}
                label="Status"
                name="status"
                value={inputs.status}
                onChange={onChange}
              />
            </Control>
          </Field>
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gridColumnGap: "0.75rem",
          }}
        >
          <Field>
            <Control>
              <Label>Email</Label>
              <Input
                required
                autoComplete="new"
                disabled={disabled}
                maxLength={70}
                name="email"
                size="small"
                type="email"
                value={inputs.email}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
            </Control>
          </Field>
          <Field>
            <Control>
              <Label>Fax</Label>
              <PhoneNumberInput
                disabled={disabled}
                name="fax"
                value={inputs.fax}
                onChange={onChange}
              />
            </Control>
          </Field>
        </div>
        <Field kind="group" style={{ gridColumn: "1 / 3" }}>
          <Control expanded>
            <Label>Phone 1</Label>
            <PhoneNumberInput
              required
              disabled={disabled}
              name="phone1"
              value={inputs.phone1}
              onChange={onChange}
            />
          </Control>
          <Control>
            <Label>Ext</Label>
            <Input
              autoComplete="new"
              disabled={disabled}
              maxLength={50}
              name="phone1ext"
              size="small"
              type="text"
              value={inputs.phone1ext}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </Control>
        </Field>
        <Field kind="group" style={{ gridColumn: "3 / 5" }}>
          <Control expanded>
            <Label>Phone 2</Label>
            <PhoneNumberInput
              disabled={disabled}
              name="phone2"
              value={inputs.phone2}
              onChange={onChange}
            />
          </Control>
          <Control>
            <Label>Ext</Label>
            <Input
              autoComplete="new"
              disabled={disabled}
              maxLength={14}
              name="phone2ext"
              size="small"
              type="text"
              value={inputs.phone2ext}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </Control>
        </Field>
        <Field kind="group" style={{ gridColumn: "1 / 3" }}>
          <UserSelect
            disabled={disabled}
            label="QA Rep"
            name="QARep"
            value={inputs.QARep || ""}
            where={{
              fldActive: { equals: 1 },
              OR: [
                { usertype: { equals: "QA" } },
                { usertype: { equals: "*" } },
              ],
            }}
            onChange={onChange}
          />
          <PrioritySelect
            disabled={disabled}
            label="Priority"
            name="priority"
            noValueLabel=""
            showBlank={false}
            value={inputs.priority || ""}
            onChange={onChange}
          />
        </Field>
      </Column>
    </Column.Group>
  </form>
);
AddClientForm.propTypes = {
  inputs: PropTypes.object,
  onChange: PropTypes.func,
  codeStates: PropTypes.object,
  disabled: PropTypes.bool,
  adding: PropTypes.bool,
  onSubmit: PropTypes.func,
  formId: PropTypes.string,
};

AddClientForm.defaultProps = {
  inputs: {},
  onChange: (e) => e,
  codeStates: {},
  disabled: false,
  adding: false,
  onSubmit: (e) => e,
  formId: "edit-client-form",
};
export default AddClientForm;
