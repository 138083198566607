import React from "react";
import PropTypes from "prop-types";
import { Field, Control, Label, Input } from "rbx";

import CaseDocumentSelect from "../../../../components/CaseDocumentSelect";
import ReportTypeSelect from "../../../../components/ReportTypeSelect";

const CaseDocumentReportForm = ({ casenbr, inputs, onChange }) => (
  <div className="case-attach-external-report-form">
    <Field>
      <Control expanded>
        <Label>Description</Label>
        <Input
          autoComplete="new"
          name="description"
          size="small"
          type="text"
          value={inputs.description}
          onChange={({ target: { name, value } }) => onChange(name, value)}
        />
      </Control>
    </Field>
    <Field>
      <ReportTypeSelect
        name="reporttype"
        value={inputs.reporttype}
        onChange={onChange}
      />
    </Field>
    <Field>
      <CaseDocumentSelect
        casenbr={casenbr}
        name="seqno"
        value={inputs.seqno}
        onChange={onChange}
      />
    </Field>
  </div>
);

CaseDocumentReportForm.propTypes = {
  casenbr: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  inputs: PropTypes.shape({
    description: PropTypes.string,
    seqno: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    reporttype: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.object,
    ]),
  }),
  onChange: PropTypes.func.isRequired,
};

CaseDocumentReportForm.defaultProps = {
  inputs: { description: "", seqno: "" },
};
export default CaseDocumentReportForm;
