import React from "react";
import PropTypes from "prop-types";
import { Control, Label, Select } from "rbx";

const SCHEDULE_PRIORITIES = Array.from({ length: 5 }).map((_, i) => ({
  value: i + 1,
  label: i + 1,
}));

const SchedulePrioritySelect = ({
  label,
  size,
  name,
  onChange,
  disabled,
  value,
  required,
  id,
  fullWidth,
  getValue,
  setValue,
  showBlank,
}) => {
  const handleChange = (e) => {
    onChange(e.target.name, setValue(e.target.value));
  };
  return (
    <Control
      expanded
      size={size}
      style={{
        border: required && !value ? "1px #e63362 solid" : "",
        borderRadius: "4px",
        boxShadow:
          required && !value ? "0 0 0 0.125em rgba(230, 51, 98, 0.25)" : "",
      }}
    >
      {label && <Label>{label}</Label>}
      <Select.Container fullwidth size={size}>
        <Select
          disabled={disabled}
          id={id}
          name={name}
          required={required}
          value={getValue(value)}
          onChange={handleChange}
        >
          {showBlank && <Select.Option value="" />}
          {SCHEDULE_PRIORITIES.map((item) => (
            <Select.Option key={item.value} value={item.value}>
              {item.label}
            </Select.Option>
          ))}
        </Select>
      </Select.Container>
    </Control>
  );
};
SchedulePrioritySelect.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.number,
  ]),
  name: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  id: PropTypes.string,
  fullWidth: PropTypes.bool,
  getValue: PropTypes.func,
  setValue: PropTypes.func,
  showBlank: PropTypes.bool,
};

SchedulePrioritySelect.defaultProps = {
  size: "small",
  fullWidth: false,
  disabled: false,
  value: "",
  name: "",
  label: null,
  required: false,
  id: null,
  getValue: (x) => x,
  setValue: (x) => x,
  onChange: () => null,
  showBlank: false,
};

export default SchedulePrioritySelect;
