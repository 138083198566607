/* eslint-disable react/prop-types */
import React from "react";
import { Generic, Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { format } from "date-fns";
import { capitalize, convertTimeZone } from "../../../../utils";

export const DEFAULT_COLUMNS = [
  {
    id: "location:location",
    Header: "Location",
    accessor: "location.location",
    maxWidth: "35%",
  },
  {
    id: "location:city",
    Header: "City",
    accessor: "location.city",
  },
  {
    id: "location:state",
    Header: "State",
    accessor: "location.state",
  },
  {
    id: "phone",
    Header: "Phone",
    accessor: "location.Phone",
    disableSortBy: true,
  },
  {
    id: "status",
    Header: "status",
    accessor: "status",
    Cell: ({ cell }) => capitalize(cell?.value || "--"),
  },
  {
    Header: "Added",
    id: "dateadded",
    accessor: "dateadded",
    Cell: ({ cell }) =>
      cell?.value
        ? format(convertTimeZone(cell.value), "MM/dd/yyyy hh:mm a")
        : "--",
    maxWidth: "5%",
  },
  {
    Header: "By",
    id: "useridadded",
    accessor: "useridadded",
    maxWidth: "5%",
  },
  {
    Header: "Edited",
    id: "dateedited",
    accessor: "dateedited",
    Cell: ({ cell }) =>
      cell?.value
        ? format(convertTimeZone(cell.value), "MM/dd/yyyy hh:mm a")
        : "--",
    maxWidth: "5%",
  },
  {
    Header: "By",
    id: "useridedited",
    accessor: "useridedited",
    maxWidth: "5%",
  },
];

export const generateColumns = ({
  handleDailyApptListClick,
  handleDoctorBlockTimeClick,
}) => [
  {
    Header: "",
    id: "daily-appt-list",
    accessor: "locationcode",
    Cell: ({ cell }) => (
      <Generic
        style={{ textAlign: "center" }}
        title="Daily Appt List"
        type="button"
        onClick={(e) => {
          e.stopPropagation();
          handleDailyApptListClick(cell?.value);
        }}
      >
        <Icon color="info" size="small">
          <FontAwesomeIcon icon="clock" />
        </Icon>
      </Generic>
    ),
  },
  {
    Header: "",
    id: "block-time",
    accessor: "locationcode",
    Cell: ({ cell }) => (
      <Generic
        style={{ textAlign: "center" }}
        title="Block Time"
        type="button"
        onClick={(e) => {
          e.stopPropagation();
          handleDoctorBlockTimeClick(cell?.value);
        }}
      >
        <Icon color="info" size="small">
          <FontAwesomeIcon icon="calendar" />
        </Icon>
      </Generic>
    ),
  },
  ...DEFAULT_COLUMNS,
];

export default DEFAULT_COLUMNS;
