import { gql } from "@apollo/client";

export const CREATE_CASE_HISTORY_MUTATION = gql`
  mutation createCaseHistory($data: CaseHistoryCreateInput!) {
    createCaseHistory(data: $data) {
      casenbr
      duration
      eventdate
      eventdesc
      id
      status
    }
  }
`;

export const UPDATE_CASE_HISTORY_MUTATION = gql`
  mutation updateCaseHistory(
    $data: CaseHistoryUpdateInput!
    $where: CaseHistoryWhereUniqueInput!
  ) {
    updateCaseHistory(data: $data, where: $where) {
      PublishOnWeb
      PublishedTo
      WebGUID
      WebSynchDate
      casenbr
      dateadded
      dateedited
      duration
      eventdate
      eventdesc
      id
      otherinfo
      status
      type
      userid
      useridedited
    }
  }
`;

export const DELETE_CASE_HISTORY_MUTATION = gql`
  mutation deleteCaseHistory($where: CaseHistoryWhereUniqueInput!) {
    deleteCaseHistory(where: $where) {
      casenbr
      id
    }
  }
`;

