import React, { useState, useEffect, useCallback, useMemo } from "react";
import PropTypes from "prop-types";

import { Field, Control, Title } from "rbx";

import { useLazyQuery } from "@apollo/client";

import Table from "./Table";
import Pagination from "./Pagination";

import { useLocalStorage } from "../../hooks";
import { equals } from "../../utils";

import Loader from "../Loader";
import TableError from "./TableError";

const DataTable = ({
  aggregateName,
  aggregateKey,
  name,
  columns,
  query,
  where,
  orderBy,
  onRowClick,
  fetchPolicy,
  onCellClick,
  getRowProps,
  showPageSize,
  pageSizeKey,
  pageSize,
  loaderHeight,
}) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [orderByMulti, setOrderByMulti] = useState(orderBy);
  const [innerWhere, setInnerWhere] = useState(where);

  const [runQuery, { data, loading, error: queryError }] = useLazyQuery(query, {
    fetchPolicy,
  });

  const [defaultPageSize, setDefaultPageSize] = useLocalStorage(
    pageSizeKey,
    pageSize
  );

  const innerOrderBy = useMemo(
    () =>
      orderByMulti.map((x) => {
        if (x.id.includes(":")) {
          const [lastKey, ...sorts] = x.id.split(":").reverse();
          return sorts.reduce((acc, curr) => ({ [curr]: { ...acc } }), {
            [lastKey]: x.desc ? "desc" : "asc",
          });
        }
        if (x.id.includes(".")) {
          const sorts = x.id.split(".");
          return { [sorts[0]]: x.desc ? "desc" : "asc" };
        }
        return { [x.id]: x.desc ? "desc" : "asc" };
      }),
    [orderByMulti]
  );

  useEffect(() => {
    setCurrentPage(0);
  }, [innerWhere, defaultPageSize]);

  useEffect(() => {
    const isEqual = equals(where, innerWhere);
    if (!isEqual) {
      setInnerWhere(where);
    }
  }, [where, innerWhere]);

  useEffect(() => {
    runQuery({
      variables: {
        where: innerWhere,
        orderBy: innerOrderBy,
        take: defaultPageSize,
        skip: currentPage * defaultPageSize,
      },
    });
  }, [
    currentPage,
    defaultPageSize,
    fetchPolicy,
    innerOrderBy,
    innerWhere,
    runQuery,
  ]);

  const reload = () => {
    window.location.reload();
  };

  const handleChangeSort = useCallback(async (sortBy) => {
    setOrderByMulti(sortBy);
  }, []);

  const recordCount = data?.[aggregateName]?.count
    ? data[aggregateName].count[aggregateKey]
    : 0;

  const pageCount = data?.[aggregateName]?.count
    ? Math.ceil(recordCount / defaultPageSize)
    : "1";

  const isEmptyResultSet =
    !data?.[name] ||
    (Array.isArray(data?.[name]) && data?.[name]?.length === 0);

  return (
    <React.Fragment>
      {!data && <Loader height={loaderHeight} />}

      {queryError && !data && <TableError onTryAgain={reload} />}

      {!queryError && !isEmptyResultSet && (
        <React.Fragment>
          <Table
            manualSortBy
            columns={columns}
            data={data?.[name]}
            getRowProps={getRowProps}
            initialSortBy={orderByMulti}
            onCellClick={onCellClick}
            onChangeSort={handleChangeSort}
            onRowClick={onRowClick}
          />
          <Field align="right" kind="group">
            <Control>
              <Pagination
                canNextPage={currentPage + 1 < pageCount}
                canPreviousPage={currentPage + 1 > 1}
                page={currentPage + 1}
                pageSize={defaultPageSize}
                pages={pageCount}
                recordCount={recordCount}
                setPageSize={setDefaultPageSize}
                showPageSize={showPageSize}
                onClickNext={() => {
                  setCurrentPage((prev) => prev + 1);
                }}
                onClickPrev={() => {
                  setCurrentPage((prev) => prev - 1);
                }}
              />
            </Control>
          </Field>
        </React.Fragment>
      )}

      {!queryError && !loading && isEmptyResultSet && (
        <Title subtitle style={{ color: "gray" }}>
          Nothing here...
        </Title>
      )}
    </React.Fragment>
  );
};

DataTable.propTypes = {
  aggregateName: PropTypes.string.isRequired,
  aggregateKey: PropTypes.string,
  name: PropTypes.string.isRequired,
  columns: PropTypes.array.isRequired,
  query: PropTypes.object.isRequired,
  where: PropTypes.object,
  orderBy: PropTypes.array,
  onRowClick: PropTypes.func,
  fetchPolicy: PropTypes.string,
  onCellClick: PropTypes.func,
  getRowProps: PropTypes.func,
  showPageSize: PropTypes.bool,
  pageSizeKey: PropTypes.string,
  pageSize: PropTypes.number,
  loaderHeight: PropTypes.string,
};

DataTable.defaultProps = {
  onRowClick: () => null,
  where: {},
  orderBy: [],
  fetchPolicy: "cache-and-network",
  aggregateKey: "_all",
  onCellClick: () => null,
  getRowProps: () => { },
  showPageSize: true,
  pageSizeKey: "DEFAULT_PAGE_SIZE",
  pageSize: 25,
  loaderHeight: "80vh",
};
export default DataTable;
