import React from "react";
import PropTypes from "prop-types";

import CircleLoader from "react-spinners/CircleLoader";
import "./Loader.scss";

const Loader = ({ size, message, height }) => (
  <div className="sweet-loading" style={{ height }}>
    <CircleLoader loading size={size} />
    <h1>{message}</h1>
  </div>
);

Loader.propTypes = {
  size: PropTypes.number,
  message: PropTypes.string,
  height: PropTypes.string,
};

Loader.defaultProps = {
  size: 150,
  message: "Loading...",
  height: "80vh",
};

export default Loader;
