import { Button } from "rbx";
import React from "react";
import PropTypes from "prop-types";

const RetryFetch = ({ handleRetry }) => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      gap: "10px",
    }}
  >
    <p>There was an error fetching these options.</p>
    <Button
      color="info"
      size="medium"
      onClick={(e) => {
        e.preventDefault();
        handleRetry();
      }}
    >
      Try Again
    </Button>
  </div>
);

RetryFetch.propTypes = {
  handleRetry: PropTypes.func.isRequired,
};

export default RetryFetch;
