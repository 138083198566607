import { gql } from "@apollo/client";

export const CREATE_APOLLO_DOCUMENT_TYPE_MUTATION = gql`
  mutation CREATE_DOCUMENT_TYPE_MUTATION($data: DocumentTypesCreateInput!) {
    createDocumentTypes(data: $data) {
      DocumentTypeID
      DocumentType
    }
  }
`;

export const UPDATE_APOLLO_DOCUMENT_TYPE_MUTATION = gql`
  mutation UPDATE_DOCUMENT_TYPE_MUTATION(
    $data: DocumentTypesUpdateInput!
    $where: DocumentTypesWhereUniqueInput!
  ) {
    updateDocumentTypes(data: $data, where: $where) {
      DocumentTypeID
      DocumentType
    }
  }
`;

export const DELETE_APOLLO_DOCUMENT_TYPE_MUTATION = gql`
  mutation DELETE_DOCUMENT_TYPE_MUTATION($where: DocumentTypesWhereUniqueInput!) {
    deleteDocumentTypes(where: $where) {
      DocumentTypeID
    }
  }
`;
