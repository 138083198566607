import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Button, Control, Field, Title } from "rbx";

const StyledErrorFallbackWrapper = styled.div`
  display: flex;
  height: 80vh;
  text-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;
const StyledErrorFallbackContainer = styled.div`
  width: 95%;
  max-width: 400px;
  text-align: center;
`;

const StyledErrorFallbackField = styled(Field).attrs({ kind: "group" })`
  display: flex;
  width: 80%;
  margin: 1rem auto;
  .control {
    flex: 1;
  }
`;

const TableError = ({ onTryAgain }) => (
  <StyledErrorFallbackWrapper>
    <StyledErrorFallbackContainer>
      <Title>Something went wrong!</Title>
      <Title subtitle>Please try again.</Title>
      <StyledErrorFallbackField>
        <Control expanded>
          <Button fullwidth color="info" size="small" onClick={onTryAgain}>
            Contact Support
          </Button>
        </Control>
        <Control expanded>
          <Button fullwidth color="primary" size="small" onClick={onTryAgain}>
            Try Again
          </Button>
        </Control>
      </StyledErrorFallbackField>
    </StyledErrorFallbackContainer>
  </StyledErrorFallbackWrapper>
);

TableError.propTypes = {
  onTryAgain: PropTypes.func,
};

TableError.defaultProps = {
  onTryAgain: () => {},
};

export default TableError;
