import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useApolloClient, useMutation } from "@apollo/client";
import { Title, Button } from "rbx";
import { customToast as toast, getDateEST } from "../../utils";
import { useLocalStorage } from "../../hooks";
import ExceptionForm from "./ExceptionForm";
import {
  CREATE_EXCEPTION_MUTATION,
  LIST_EXCEPTION_DEFINITION_QUERY,
  UPDATE_EXCEPTION_MUTATION,
  DELETE_EXCEPTION_MUTATION,
  SINGLE_EXCEPTION_LIST_QUERY,
} from "../../graphql/exception";
import { useAuth, useModal } from "../../context";
import Confirmation from "../Confirmation";

const DEFAULT_STATE = {
  Description: "",
  Entity: "CL",
  ExceptionID: "1",
  Status: "Active",
  CaseTypeCode: "",
  ServiceCode: "",
  StatusCode: "",
  StatusCodeValue: "1",
  DisplayMessage: false,
  RequireComment: false,
  Message: "",
  EmailMessage: false,
  EditEmail: false,
  EmailScheduler: false,
  EmailQA: false,
  EmailOther: "",
  EmailSubject: "",
  EmailText: "",
  GenerateDocument: false,
  Document1: "",
  Document2: "",
  IMECentricCode: "",
};

class CustomExceptionError extends Error {}

const REQUIRED_KEYS = ["Description", "Entity", "StatusCode"];

const ExceptionModal = ({
  onComplete,
  IMECentricCode,
  exception,
  Entity,
  ExceptionID,
  canDelete,
  entityDisabled,
}) => {
  const client = useApolloClient();
  const { setModalOpen } = useModal();
  const { state: authState } = useAuth();
  const [inputs, setInputs] = useState(
    exception?.ExceptionDefID
      ? {
          ...exception,
          Entity,
          ExceptionID: exception.ExceptionID,
          Status: exception.Status,
          ServiceCode: exception.ServiceCode,
          CaseTypeCode: exception.CaseTypeCode,
          StatusCode: exception.StatusCode,
          StatusCodeValue: exception.StatusCodeValue,
          Document1: exception.Document1,
          Document2: exception.Document2,
        }
      : {
          ...DEFAULT_STATE,
          IMECentricCode,
          Entity,
          ExceptionID: parseInt(ExceptionID, 10),
        }
  );
  const [loading, setLoading] = useState(false);
  const [adding] = useState(!exception?.ExceptionDefID);
  const [defaultPageSize] = useLocalStorage(`DEFAULT_PAGE_SIZE`, 25);
  const [deleteException] = useMutation(DELETE_EXCEPTION_MUTATION);

  const handleChange = (name, value) => {
    setInputs((prev) => ({ ...prev, [name]: value }));
  };

  const getClearedObjectToAdd = (object) => {
    const obj = Object.keys(DEFAULT_STATE).reduce((acc, curr) => {
      if (object[curr] !== null) {
        return {
          ...acc,
          [curr]:
            typeof object[curr] === "object"
              ? object[curr].value
              : object[curr],
        };
      }
      return acc;
    }, {});
    const clearedObject = Object.fromEntries(
      Object.entries(obj).filter(([_, v]) => v !== "")
    );
    const date = getDateEST();

    return {
      data: {
        ...clearedObject,
        ExceptionID: parseInt(clearedObject.ExceptionID, 10),
        CaseTypeCode: clearedObject.CaseTypeCode
          ? parseInt(clearedObject.CaseTypeCode, 10)
          : -1,
        ServiceCode: clearedObject.ServiceCode
          ? parseInt(clearedObject.ServiceCode, 10)
          : -1,
        IMECentricCode:
          clearedObject.Entity === "CS"
            ? -1
            : parseInt(clearedObject.IMECentricCode, 10),
        StatusCode: clearedObject.StatusCode
          ? parseInt(clearedObject.StatusCode, 10)
          : -1,
        StatusCodeValue: clearedObject.StatusCodeValue
          ? parseInt(clearedObject.StatusCodeValue, 10)
          : -1,
        DateEdited: date,
        DateAdded: date,
        UserIDAdded: authState?.user?.userid,
        UserIDEdited: authState?.user?.userid,
      },
    };
  };

  const getClearedObjectToEdit = () => {
    const obj = Object.keys(DEFAULT_STATE).reduce((acc, curr) => {
      if (inputs[curr] !== null) {
        return {
          ...acc,
          [curr]: {
            set:
              typeof inputs[curr] === "object"
                ? inputs[curr].value
                : inputs[curr],
          },
        };
      }
      return acc;
    }, {});
    const clearedObject = Object.fromEntries(
      Object.entries(obj).filter(([_, v]) => v.set !== "")
    );
    const date = getDateEST();
    return {
      data: {
        ...clearedObject,
        IMECentricCode:
          clearedObject.Entity?.set === "CS"
            ? { set: -1 }
            : { set: parseInt(clearedObject.IMECentricCode.set, 10) },
        ExceptionID: clearedObject.ExceptionID?.set
          ? { set: parseInt(clearedObject.ExceptionID.set, 10) }
          : { set: -1 },
        CaseTypeCode: clearedObject.CaseTypeCode?.set
          ? { set: parseInt(clearedObject.CaseTypeCode.set, 10) }
          : { set: -1 },
        ServiceCode: clearedObject.ServiceCode?.set
          ? { set: parseInt(clearedObject.ServiceCode.set, 10) }
          : { set: -1 },
        StatusCode: clearedObject.StatusCode?.set
          ? { set: parseInt(clearedObject.StatusCode.set, 10) }
          : { set: -1 },
        StatusCodeValue: clearedObject.StatusCodeValue?.set
          ? { set: parseInt(clearedObject.StatusCodeValue.set, 10) }
          : { set: 1 },
        Document1: { set: clearedObject.Document1?.set || "" },
        Document2: { set: clearedObject.Document2?.set || "" },
        DateEdited: { set: date },
        UserIDEdited: { set: authState?.user?.userid },
      },
      where: {
        ExceptionDefID: exception.ExceptionDefID,
      },
    };
  };

  const createException = async (variables) => {
    const refetchWhere = {
      IMECentricCode: {
        equals: parseInt(IMECentricCode || variables.data.IMECentricCode, 10),
      },
    };
    if (Entity) {
      refetchWhere.Entity = { equals: Entity };
    }
    await client.mutate({
      mutation: CREATE_EXCEPTION_MUTATION,
      variables,
      refetchQueries: [
        {
          query: LIST_EXCEPTION_DEFINITION_QUERY,
          variables: {
            where: refetchWhere,
            take: defaultPageSize,
            orderBy: [{ Description: "asc" }],
          },
        },
      ],
    });
  };

  const getEntityName = (entity) => {
    let entityToReturn = "";
    switch (entity) {
      case "CL":
        entityToReturn = "Client";
        break;
      case "CO":
        entityToReturn = "Company";
        break;
      case "DR":
        entityToReturn = "Doctor";
        break;
      default:
        break;
    }
    return entityToReturn;
  };

  const handleSave = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      if (adding) {
        if (inputs.Entity !== "CS" && !inputs.IMECentricCode) {
          throw new CustomExceptionError(
            `Please select a ${getEntityName(inputs.Entity)}.`
          );
        }
        if (inputs.StatusCode) {
          const itemToCreate = getClearedObjectToAdd(inputs);
          await createException(itemToCreate);
          toast.success("Exception created successfully.");
        } else {
          throw new CustomExceptionError("Inputs required.");
        }
      } else {
        const objectToSend = getClearedObjectToEdit();

        if (!objectToSend.data.ExceptionID) {
          throw new CustomExceptionError("Please select an exception option.");
        }

        if (!objectToSend.data.IMECentricCode) {
          throw new CustomExceptionError(
            `Please select a ${getEntityName(objectToSend.data.Entity)}.`
          );
        }

        const refetchWhere = {
          IMECentricCode: {
            equals: parseInt(
              IMECentricCode || objectToSend.data.IMECentricCode.set,
              10
            ),
          },
        };
        if (Entity) {
          refetchWhere.Entity = { equals: Entity };
        }

        await client.mutate({
          mutation: UPDATE_EXCEPTION_MUTATION,
          variables: objectToSend,
          refetchQueries: [
            {
              query: LIST_EXCEPTION_DEFINITION_QUERY,
              variables: {
                where: refetchWhere,
                take: defaultPageSize,
                orderBy: [{ Description: "asc" }],
              },
            },
          ],
        });
        toast.success("Exception edited successfully.");
      }
      onComplete({ IMECentricCode });
    } catch (err) {
      if (err instanceof CustomExceptionError) {
        toast.error(err.message);
      } else {
        toast.error("Error creating Exception.");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = (e) => {
    e.preventDefault();

    const performDelete = async () => {
      try {
        await deleteException({
          variables: {
            where: {
              ExceptionDefID: parseInt(exception.ExceptionDefID, 10),
            },
          },
          refetchQueries: [
            {
              query: SINGLE_EXCEPTION_LIST_QUERY,
              variables: {
                where: {
                  ExceptionID: parseInt(ExceptionID, 10),
                },
              },
            },
          ],
        });
        toast.success("Exception deleted successfully");
        onComplete();
      } catch (err) {
        toast.error("Error trying to delete exception");
      }
    };

    setModalOpen(
      true,
      <Confirmation
        message="Are you sure you want to delete this exception?"
        onCancel={() => setModalOpen(false)}
        onConfirm={performDelete}
      />
    );
  };

  const isDisabled = useMemo(() => REQUIRED_KEYS.some((key) => !inputs[key]), [
    inputs,
  ]);

  return (
    <form id="add-exception-form" onSubmit={handleSave}>
      <header className="page-head">
        <div className="page-head-start">
          <Title size={5}>
            {exception?.ExceptionDefID ? "Edit Exception" : "Create Exception"}
          </Title>
        </div>
        <div className="page-head-end">
          <Button.Group hasAddons>
            <Button size="small" type="button" onClick={onComplete}>
              <span>Cancel</span>
            </Button>
            <Button
              color="primary"
              disabled={isDisabled || loading}
              form="add-exception-form"
              size="small"
              state={loading ? "loading" : ""}
              type="submit"
            >
              <span>Submit</span>
            </Button>
          </Button.Group>
        </div>
      </header>
      <hr />
      <ExceptionForm
        entityDisabled={entityDisabled}
        inputs={inputs}
        onChange={handleChange}
      />
      <hr />
      {canDelete && (
        <Button
          color="danger"
          size="small"
          type="button"
          onClick={handleDelete}
        >
          <span>Delete</span>
        </Button>
      )}
    </form>
  );
};

ExceptionModal.propTypes = {
  onComplete: PropTypes.func,
  IMECentricCode: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  exception: PropTypes.object,
  Entity: PropTypes.string,
  ExceptionID: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  canDelete: PropTypes.bool,
  entityDisabled: PropTypes.bool,
};

ExceptionModal.defaultProps = {
  onComplete: (e) => e,
  IMECentricCode: "",
  exception: {},
  Entity: null,
  ExceptionID: "1",
  canDelete: false,
  entityDisabled: false,
};

export default ExceptionModal;
