import React from "react";
import PropTypes from "prop-types";
import { Box, Field, Control, Input, Label } from "rbx";
import JurisdictionSelect from "../../../../components/JurisdictionSelect";
import ReferralStyledSubtitle from "../ReferralStyledSubtitle";

const VehicleInformation = ({ inputs, onChange }) => (
  <Box>
    <ReferralStyledSubtitle>Vehicle</ReferralStyledSubtitle>
    <Field className="grid-override is-three" kind="group">
      <Control expanded>
        <Label>Year</Label>
        <Input
          name="year"
          size="small"
          value={inputs.year}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
      <Control expanded>
        <Label>Make</Label>
        <Input
          name="make"
          size="small"
          value={inputs.make}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
      <Control expanded>
        <Label>Plate #</Label>
        <Input
          name="plateNbr"
          size="small"
          value={inputs.plateNbr}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
    </Field>
    <Field className="grid-override is-three" kind="group">
      <Control expanded>
        <Label>Color</Label>
        <Input
          name="color"
          size="small"
          value={inputs.color}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
      <Control expanded>
        <Label>Model</Label>
        <Input
          name="model"
          size="small"
          value={inputs.model}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
      <JurisdictionSelect
        label="State"
        name="state"
        size="small"
        value={inputs.state}
        onChange={onChange}
      />
    </Field>
  </Box>
);
VehicleInformation.propTypes = {
  inputs: PropTypes.object,
  onChange: PropTypes.func,
};
VehicleInformation.defaultProps = {
  inputs: {},
  onChange: () => null,
};

export default VehicleInformation;
