import React from "react";
import PropTypes from "prop-types";
import { Field, Control, Input, Label } from "rbx";
import { isValid } from "date-fns";

import DateInput from "../../../../components/DateInput";
import DocumentTypeSelect from "../../../../components/DocumentTypeSelect";
import FileUploader from "../../../../components/FileUploader";

const DoctorDocumentForm = ({ disabled, inputs, onChange, dirPath }) => (
  <div>
    <Field className="grid-override" kind="group">
      <Control expanded>
        <Label>Description</Label>
        <Input
          autoComplete="new"
          disabled={disabled}
          name="description"
          size="small"
          type="text"
          value={inputs.description}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
      <Field className="grid-override" kind="group">
        <DocumentTypeSelect
          disabled={disabled}
          label="Type"
          name="type"
          size="small"
          value={inputs.type}
          onChange={onChange}
        />

        <DateInput
          disabled={disabled}
          getValue={(x) => (x ? x.substr(0, 10) : "")}
          label="Expiration Date"
          name="expiredate"
          setValue={(x) => {
            const date = new Date(x.split("-").map((y) => parseInt(y, 10)));
            if (x && isValid(date)) {
              return date.toISOString();
            }
            return "";
          }}
          size="small"
          value={inputs.expiredate}
          onChange={onChange}
        />
      </Field>
    </Field>
    <Field>
      <FileUploader
        dirPath={dirPath}
        name="pathfilename"
        value={inputs.pathfilename}
        onChange={onChange}
      />
    </Field>
  </div>
);

DoctorDocumentForm.propTypes = {
  inputs: PropTypes.object,
  dirPath: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};
DoctorDocumentForm.defaultProps = {
  inputs: {},
  dirPath: "",
  onChange: () => {},
  disabled: false,
};
export default DoctorDocumentForm;
