import React, { useContext } from "react";
import PropTypes from "prop-types";

import { Content, Button, Control, Field } from "rbx";

import { ModalContext } from "../../context/ModalContext";

const Confirmation = ({
  message,
  affirmative,
  negative,
  showAffirmative,
  onConfirm,
  onCancel,
}) => {
  const { setModalOpen } = useContext(ModalContext);

  const handleCancel = () => {
    setModalOpen(false, "");
    if (onCancel && typeof onCancel === "function") {
      onCancel();
    }
  };

  return (
    <div>
      <Content size="medium" textAlign="centered">
        <p>{message}</p>
      </Content>

      <Field
        kind="group"
        style={{ textAlign: "center", width: 200, margin: "0 auto" }}
      >
        {showAffirmative && (
          <Control expanded>
            <Button fullwidth color="success" size="small" onClick={onConfirm}>
              {affirmative}
            </Button>
          </Control>
        )}
        <Control expanded>
          <Button fullwidth color="danger" size="small" onClick={handleCancel}>
            {negative}
          </Button>
        </Control>
      </Field>
    </div>
  );
};

Confirmation.propTypes = {
  message: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  affirmative: PropTypes.string,
  negative: PropTypes.string,
  showAffirmative: PropTypes.bool,
};

Confirmation.defaultProps = {
  onCancel: () => null,
  affirmative: "Yes",
  negative: "No",
  showAffirmative: true,
};
export default Confirmation;
