import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { useApolloClient } from "@apollo/client";

import { toast } from "react-toastify";
import {
  LIST_CASE_COMPANY_QUERY,
  FIND_FIRST_APOLLO_SERVICE_QUERY,
} from "../../../graphql";

import DataTable from "../../../components/DataTable";
import { CASE_COMPANY_COLUMNS } from "../../Case/columns";

const Cases = ({ company }) => {
  const history = useHistory();
  const client = useApolloClient();

  const handleRowClick = async (casenbr) => {
    try {
      const {
        data: { findFirstServices },
      } = await client.query({
        query: FIND_FIRST_APOLLO_SERVICE_QUERY,
        variables: {
          where: {
            WorkID: { equals: parseInt(casenbr, 10) },
          },
        },
      });
      const {
        ClaimID,
        ServiceType: { ServiceType },
        ServiceID,
        WorkID,
      } = findFirstServices;
      history.push(
        `/claims/${ClaimID}/services/${ServiceID}/${ServiceType}/${WorkID}/profile`
      );
    } catch (err) {
      toast.error("Unable to determine service.");
      history.push(`/cases/${casenbr}/profile`);
    }
  };

  return (
    <div>
      <DataTable
        aggregateKey="casenbr"
        aggregateName="aggregateCase"
        columns={CASE_COMPANY_COLUMNS}
        name="cases"
        orderBy={[{ id: "casenbr", desc: true }]}
        query={LIST_CASE_COMPANY_QUERY}
        where={{
          client: { is: { companycode: { equals: parseInt(company, 10) } } },
        }}
        onRowClick={({ casenbr }) => handleRowClick(casenbr)}
      />
    </div>
  );
};

Cases.propTypes = {
  company: PropTypes.string,
};

Cases.defaultProps = {
  company: "",
};

export default Cases;
