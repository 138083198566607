import React from "react";
import PropTypes from "prop-types";

import { Box, Field, Control, Button, Label } from "rbx";

import StateSelect from "../../../../components/StateSelect";
import FilterInput from "../../../../components/FilterInput";
import StatusSelect from "../../../../components/StatusSelect";
import UserSelect from "../../../../components/UserSelect";

const CompanyListFilters = ({ filters, onChange, onReset }) => (
  <Box>
    <div className="list-filters">
      <Field>
        <Control>
          <Label size="xs">Internal Name</Label>
          <FilterInput
            getValue={(x) => x?.contains || ""}
            maxLength={50}
            name="intname"
            placeholder="Internal Name"
            setValue={(x) => (x ? { contains: x } : "")}
            size="small"
            value={filters.intname || ""}
            onChange={onChange}
          />
        </Control>
      </Field>
      <Field>
        <Control>
          <Label size="xs">Address 1</Label>
          <FilterInput
            getValue={(x) => x?.contains || ""}
            maxLength={50}
            name="addr1"
            placeholder="Internal Name"
            setValue={(x) => (x ? { contains: x } : "")}
            size="small"
            value={filters.addr1 || ""}
            onChange={onChange}
          />
        </Control>
      </Field>
      <Field kind="group">
        <Control expanded>
          <Label size="xs">City</Label>
          <FilterInput
            getValue={(x) => x?.contains || ""}
            maxLength={50}
            name="city"
            placeholder="City"
            setValue={(x) => (x ? { contains: x } : "")}
            size="small"
            value={filters.city || ""}
            onChange={onChange}
          />
        </Control>
      </Field>
      <Field>
        <Control>
          <StateSelect
            getValue={(x) => x?.equals || ""}
            id="Statecode"
            label="State"
            name="state"
            setValue={(x) => (x ? { equals: x } : "")}
            size="small"
            value={filters.state}
            onChange={onChange}
          />
        </Control>
      </Field>
      <Field>
        <Control>
          <Label size="xs">PC/Zip</Label>
          <FilterInput
            getValue={(x) => x?.contains || ""}
            maxLength={10}
            name="zip"
            placeholder="PC/Zip"
            setValue={(x) => (x ? { contains: x } : "")}
            size="small"
            style={{ width: 90 }}
            value={filters.zip || ""}
            onChange={onChange}
          />
        </Control>
      </Field>
      <Field>
        <Control>
          <StatusSelect
            showBlank
            getValue={(x) => x?.equals || ""}
            id="status"
            label="Status"
            name="status"
            setValue={(x) => (x ? { equals: x } : "")}
            size="small"
            value={filters.status}
            onChange={onChange}
          />
        </Control>
      </Field>
      <Field>
        <UserSelect
          getValue={(x) => x?.equals || ""}
          label="Marketer"
          name="marketercode"
          setValue={(x) => (x ? { equals: x } : "")}
          value={filters.marketercode}
          where={{
            fldActive: { equals: 1 },
            OR: [{ usertype: { equals: "MK" } }, { usertype: { equals: "*" } }],
          }}
          onChange={onChange}
        />
      </Field>
      <Field>
        <Control>
          <Label>&nbsp;</Label>
          <Button color="danger" size="small" onClick={onReset}>
            Reset
          </Button>
        </Control>
      </Field>
    </div>
  </Box>
);

CompanyListFilters.propTypes = {
  filters: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
};

export default CompanyListFilters;
