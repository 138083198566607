import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Title, Button } from "rbx";
import { useApolloClient, useMutation } from "@apollo/client";
import { useAuth } from "../../../../../context";
import AddProcessorsForm from "../AddProcessorsForm";
import {
  ALL_USERS_QUERY,
  FIND_FIRST_USER_QUERY,
  UPDATE_USER_MUTATION,
} from "../../../../../graphql";
import {
  customToast as toast,
  getDateEST,
  convertTimeZoneDataBase,
} from "../../../../../utils";

const INITIAL_STATE = {
  user: "",
  status: "Active",
  userid: "",
};

const ProcessorsModal = ({ onComplete, where, rowClick, rowData }) => {
  const { state: authState } = useAuth();
  const client = useApolloClient();
  const [inputs, setInputs] = useState({ ...INITIAL_STATE });
  const [loading, setLoading] = useState(false);

  const [updateUserToAllstateProcessor] = useMutation(UPDATE_USER_MUTATION);

  useEffect(() => {
    if (rowClick) {
      handleChange("user", rowData.userid);
    }
  }, [rowClick, rowData.userid]);

  const handleChange = (name, value) => {
    setInputs((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const userid = String(inputs.user);
    const date = convertTimeZoneDataBase(getDateEST());

    try {
      setLoading(true);

      const { data: userInfo } = await client.query({
        query: FIND_FIRST_USER_QUERY,
        variables: {
          where: {
            userid: { equals: userid },
          },
        },
      });

      await updateUserToAllstateProcessor({
        variables: {
          where: {
            userid_usertype: {
              userid,
              usertype: userInfo.findFirstUser.usertype,
            },
          },
          data: {
            AllstateProcessor: {
              set: inputs.status === "Active" ? 1 : 0,
            },
            useridedited: { set: authState?.user?.userid },
            dateedited: { set: date },
          },
        },
        refetchQueries: [
          {
            query: ALL_USERS_QUERY,
          },
        ],
      });
      onComplete();
    } catch (err) {
      if (err.message.includes("Unique constraint")) {
        toast.error("This user already exists as an Allstate Processor");
      } else {
        toast.error("Error saving user as Allstate Processor");
      }
    } finally {
      setLoading(false);
    }
  };

  // let isDisabled =
  //   !inputs.user ||
  //   loading;

  // isDisabled = rowClick

  return (
    <form id="processor-form" onSubmit={handleSubmit}>
      <header className="page-head">
        <div className="page-head-start">
          <Title size={5}>Add Processors</Title>
        </div>
        <div className="page-head-end">
          <Button.Group hasAddons>
            <Button
              size="small"
              type="button"
              onClick={() => onComplete(false)}
            >
              <span>Cancel</span>
            </Button>

            <Button
              color="primary"
              // disabled={isDisabled}
              form="processor-form"
              size="small"
              state={loading ? "loading" : ""}
              type="submit"
            >
              <span>Submit</span>
            </Button>
          </Button.Group>
        </div>
      </header>
      <hr />
      <AddProcessorsForm
        inputs={inputs}
        rowClick={rowClick}
        where={where}
        onChange={handleChange}
      />
      <hr />
    </form>
  );
};

ProcessorsModal.propTypes = {
  onComplete: PropTypes.func,
  rowClick: PropTypes.bool,
  rowData: PropTypes.object,
  where: PropTypes.object,
};

ProcessorsModal.defaultProps = {
  onComplete: () => null,
  rowClick: false,
  rowData: {},
  where: {},
};

export default ProcessorsModal;
