import { useApolloClient } from "@apollo/client";
import { useEffect, useState, useCallback } from "react";

import { GET_CASE_VIRTUAL_DOCUMENT_PATH } from "../graphql";

const { REACT_APP_DOCUMENT_HOST } = process.env;

export default function useCaseDocumentPath(casenbr) {
  const client = useApolloClient();
  const [path, setPath] = useState("");

  useEffect(() => {
    (async () => {
      if (casenbr) {
        const {
          data: { getDocVirtualPathByCase },
        } = await client.query({
          query: GET_CASE_VIRTUAL_DOCUMENT_PATH,
          variables: { where: { casenbr: parseInt(casenbr, 10) } },
        });
        setPath(getDocVirtualPathByCase);
      }
    })();
  }, [casenbr, client]);

  return useCallback(
    (filename, includeDocumentHost = true) =>
      path
        ? [
            includeDocumentHost && REACT_APP_DOCUMENT_HOST,
            path,
            filename && encodeURIComponent(filename),
          ]
            .filter(Boolean)
            .join("/")
        : "",
    [path]
  );
}
