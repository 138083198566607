import React from "react";
import PropTypes from "prop-types";
import { Field, Control, Input, Label, Textarea, Help } from "rbx";
import StatusSelect from "../../../../../components/StatusSelect";
import BooleanInput from "../../../../../components/BooleanInput";

const CaseTypeForm = ({ inputs, onChange, disabled }) => (
  <div>
    <Field kind="group">
      <Control expanded>
        <Label>Note Description</Label>
        <Input
          autoComplete="new"
          disabled={disabled}
          maxLength={50}
          name="NoteDescription"
          size="small"
          value={inputs.NoteDescription}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
      <StatusSelect
        fullWidth
        disabled={disabled}
        label="Status"
        name="Status"
        options={[
          {
            id: "Active",
            value: "Active",
            label: "Active",
          },
          {
            id: "Inactive",
            value: "Inactive",
            label: "Inactive",
          },
        ]}
        size="small"
        value={inputs.Status}
        onChange={onChange}
      />
      <Control expanded>
        <Label>Publish On Web</Label>
        <BooleanInput
          disabled={disabled}
          name="PublishOnWeb"
          value={inputs.PublishOnWeb}
          onChange={onChange}
        />
      </Control>
    </Field>
    <Field kind="group">
      <Control expanded>
        <Label>Note Text</Label>
        <Textarea
          disabled={disabled}
          maxLength={1000}
          name="NoteText"
          rows="6"
          size="small"
          value={inputs.NoteText}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
        <Help color="primary">
          {`${inputs.NoteText.length} / ${1000} Characters`}
        </Help>
      </Control>
    </Field>
  </div>
);

CaseTypeForm.propTypes = {
  inputs: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default CaseTypeForm;
