import React from "react";
import PropTypes from "prop-types";
import { Column, Control, Label, Input, Textarea, Field } from "rbx";
import "./ExceptionForm.scss";
import DocumentSelect from "../DocumentSelect";
import ServiceSelect from "../ServiceSelect";
import StatusSelect from "../StatusSelect";
import CaseTypeSelect from "../CaseTypeSelect";
import BooleanInput from "../BooleanInput";
import ClientSelect from "../ClientSelect";
import CompanySelect from "../CompanySelect";
import DoctorSelect from "../DoctorSelect";
import EntitySelect from "../EntitySelect";
import WhenSelect from "../WhenSelect";
import QueueSelect from "../QueueSelect";

import "../../pages/Client/Client.scss";

const SelectedEntitySelect = ({ entityType, value, onChange, disabled }) => {
  switch (entityType) {
    case "CL":
      return (
        <ClientSelect
          required
          disabled={disabled}
          label="Client"
          name="IMECentricCode"
          value={value}
          onChange={onChange}
        />
      );
    case "CO":
      return (
        <CompanySelect
          required
          disabled={disabled}
          label="Company"
          name="IMECentricCode"
          value={value}
          onChange={onChange}
        />
      );
    case "DR":
      return (
        <DoctorSelect
          required
          disabled={disabled}
          label="Doctor"
          name="IMECentricCode"
          value={value}
          onChange={onChange}
        />
      );
    default:
      return null;
  }
};

SelectedEntitySelect.propTypes = {
  entityType: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

const ExceptionForm = ({
  inputs,
  onChange,
  codeStates,
  disabled = false,
  entityDisabled,
}) => (
  <div className="exception-form">
    <div className="exception-form-inputs">
      <Field>
        <Control>
          <Label>Description</Label>
          <Input
            required
            autoComplete="new"
            disabled={disabled}
            name="Description"
            size="small"
            type="text"
            value={inputs.Description}
            onChange={(e) => onChange(e.target.name, e.target.value)}
          />
        </Control>
      </Field>
      <Field>
        <EntitySelect
          required
          disabled={entityDisabled}
          id="Entity"
          label="Entity"
          name="Entity"
          size="small"
          value={inputs.Entity}
          onChange={onChange}
        />
      </Field>
      <Field>
        <SelectedEntitySelect
          disabled={entityDisabled}
          entityType={inputs.Entity}
          value={inputs.IMECentricCode}
          onChange={onChange}
        />
      </Field>
      <Field>
        <StatusSelect
          disabled={disabled}
          fullWidth={false}
          id="Status"
          label="Status"
          name="Status"
          size="small"
          value={inputs.Status}
          onChange={onChange}
        />
      </Field>
      <Field>
        <CaseTypeSelect
          showBlank
          disabled={disabled}
          label="Case Type"
          name="CaseTypeCode"
          showBlankOptionLabel="All"
          size="small"
          value={inputs.CaseTypeCode}
          onChange={(e, value) => onChange("CaseTypeCode", value)}
        />
      </Field>
      <Field>
        <ServiceSelect
          showAllOption
          disabled={disabled}
          label="Service"
          name="ServiceCode"
          showBlank={false}
          size="small"
          value={inputs.ServiceCode}
          onChange={(e, value) => onChange("ServiceCode", value)}
        />
      </Field>
      <Field>
        <QueueSelect
          required
          disabled={disabled}
          label="Queue"
          name="StatusCode"
          value={inputs.StatusCode}
          onChange={(e, value) => onChange("StatusCode", value)}
        />
      </Field>
      <Field>
        <WhenSelect
          required
          id="StatusCodeValue"
          label="When"
          name="StatusCodeValue"
          options={[
            {
              value: "1",
              label: "Enter Status",
            },
            {
              value: "2",
              label: "Leave Status",
            },
          ]}
          value={inputs.StatusCodeValue}
          onChange={onChange}
        />
      </Field>
    </div>
    {/* <hr /> */}
    <Column.Group>
      <Column size={12}>
        <p style={{ paddingTop: 15, paddingBottom: 15 }}>
          What action would you like to perform when this exception occurs?
        </p>
        <Label style={{ textAlign: "center", background: "#eae8e8" }}>
          Display a message to the user?
        </Label>
        <div
          className="grid-3"
          style={{ gridTemplateColumns: "0.5fr 0.5fr 2fr" }}
        >
          <Control>
            <Label>Display Message</Label>
            <BooleanInput
              name="DisplayMessage"
              value={inputs.DisplayMessage}
              onChange={onChange}
            />
          </Control>
          {inputs.DisplayMessage && (
            <React.Fragment key="message">
              <Control>
                <Label>Require Comments</Label>
                <BooleanInput
                  name="RequireComment"
                  value={inputs.RequireComment}
                  onChange={onChange}
                />
              </Control>
              <Control>
                <Label>Message</Label>
                <Textarea
                  disabled={disabled}
                  name="Message"
                  rows={2}
                  size="small"
                  value={inputs.Message}
                  onChange={(e) => onChange(e.target.name, e.target.value)}
                />
              </Control>
            </React.Fragment>
          )}
        </div>
      </Column>
    </Column.Group>
    {/* <hr /> */}
    <Column.Group>
      <Column size={12}>
        <Label style={{ textAlign: "center", background: "#eae8e8" }}>
          Send an Email to the User?
        </Label>
        <Column.Group>
          <Column desktop={{ size: 4 }}>
            <div className="grid-2">
              <Control>
                <Label>Email Message</Label>
                <BooleanInput
                  name="EmailMessage"
                  value={inputs.EmailMessage}
                  onChange={onChange}
                />
              </Control>
              {inputs.EmailMessage && (
                <Control>
                  <Label>Edit Email</Label>
                  <BooleanInput
                    name="EditEmail"
                    value={inputs.EditEmail}
                    onChange={onChange}
                  />
                </Control>
              )}
            </div>
            <div className="grid-2">
              {inputs.EmailMessage && (
                <Control>
                  <Label>Email Scheduler</Label>
                  <BooleanInput
                    name="EmailScheduler"
                    value={inputs.EmailScheduler}
                    onChange={onChange}
                  />
                </Control>
              )}
              {inputs.EmailMessage && (
                <Control>
                  <Label>Email QA Rep</Label>
                  <BooleanInput
                    name="EmailQA"
                    value={inputs.EmailQA}
                    onChange={onChange}
                  />
                </Control>
              )}
            </div>
          </Column>
          {inputs.EmailMessage && (
            <Column desktop={{ size: 8 }}>
              <Field kind="group">
                <Control expanded>
                  <Label>Email Other</Label>
                  <Input
                    autoComplete="new"
                    disabled={disabled}
                    name="EmailOther"
                    size="small"
                    type="email"
                    value={inputs.EmailOther}
                    onChange={(e) => onChange(e.target.name, e.target.value)}
                  />
                </Control>
                <Control expanded>
                  <Label>Email Subject</Label>
                  <Input
                    autoComplete="new"
                    disabled={disabled}
                    name="EmailSubject"
                    size="small"
                    type="text"
                    value={inputs.EmailSubject}
                    onChange={(e) => onChange(e.target.name, e.target.value)}
                  />
                </Control>
              </Field>
              <Field>
                <Control>
                  <Label>Email Text</Label>
                  <Textarea
                    disabled={disabled}
                    name="EmailText"
                    rows={2}
                    size="small"
                    value={inputs.EmailText}
                    onChange={(e) => onChange(e.target.name, e.target.value)}
                  />
                </Control>
              </Field>
            </Column>
          )}
        </Column.Group>
      </Column>
    </Column.Group>
    {/* <hr /> */}
    <Column.Group>
      <Column size={12}>
        <Label style={{ textAlign: "center", background: "#eae8e8" }}>
          Generate a Document?
        </Label>
        <div className="grid-3">
          <Control>
            <Label>Generate Document</Label>
            <BooleanInput
              name="GenerateDocument"
              value={inputs.GenerateDocument}
              onChange={(name, value) => {
                if (!value) {
                  onChange("Document1", "");
                  onChange("Document2", "");
                }
                onChange(name, value);
              }}
            />
          </Control>
          {inputs.GenerateDocument && (
            <React.Fragment>
              <DocumentSelect
                disabled={disabled}
                label="Document 1"
                name="Document1"
                value={inputs.Document1}
                onChange={onChange}
              />
              <DocumentSelect
                disabled={disabled}
                label="Document 2"
                name="Document2"
                value={inputs.Document2}
                onChange={onChange}
              />
            </React.Fragment>
          )}
        </div>
      </Column>
    </Column.Group>
  </div>
);
ExceptionForm.propTypes = {
  inputs: PropTypes.object,
  onChange: PropTypes.func,
  codeStates: PropTypes.object,
  disabled: PropTypes.bool,
  entityDisabled: PropTypes.bool,
};

ExceptionForm.defaultProps = {
  inputs: {},
  onChange: (e) => e,
  codeStates: {},
  disabled: false,
  entityDisabled: false,
};
export default ExceptionForm;
