import React from "react";
import PropTypes from "prop-types";
import { Control, Label, Select } from "rbx";
import { useQuery } from "@apollo/client";

import { LIST_DEGREES_QUERY } from "../../graphql/degree";

const StateSelect = ({
  value,
  name,
  label,
  size,
  onChange,
  disabled,
  required,
  getValue,
  setValue,
}) => {
  const { data: degreeData } = useQuery(LIST_DEGREES_QUERY, {
    variables: { orderBy: { degreecode: "asc" } },
  });
  const indicateRequired =
    required && (!value || value === "" || value === "0" || value === 0);

  const degrees = degreeData?.degrees;

  const handleChange = (e) => {
    onChange(e.target.name, setValue(e.target.value));
  };
  return (
    <Control expanded>
      <Label>{label}</Label>
      <Select.Container
        fullwidth
        size={size}
        state={Array.isArray(degrees) ? "" : "loading"}
      >
        <Select
          disabled={disabled}
          name={name}
          style={{
            border: indicateRequired ? "1px #e63362 solid" : "",
            borderRadius: "4px",
            boxShadow: indicateRequired
              ? "0 0 0 0.125em rgba(230, 51, 98, 0.25)"
              : "",
          }}
          value={getValue(value)}
          onChange={handleChange}
        >
          <Select.Option value="" />
          {Array.isArray(degrees) &&
            degrees.map((degree) => (
              <Select.Option key={degree.degreecode} value={degree.degreecode}>
                {degree.degreecode}
              </Select.Option>
            ))}
        </Select>
      </Select.Container>
    </Control>
  );
};

StateSelect.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.number,
  ]),
  name: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  getValue: PropTypes.func,
  setValue: PropTypes.func,
};

StateSelect.defaultProps = {
  value: "",
  name: "",
  label: "Degree",
  size: "small",
  onChange: () => null,
  disabled: false,
  required: false,
  getValue: (x) => x,
  setValue: (x) => x,
};

export default StateSelect;
