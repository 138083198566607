import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";

import { useMutation, useQuery } from "@apollo/client";
import { Title, Generic, Button } from "rbx";

import { useAuth } from "../../../../context";

import { getDateEST, convertTimeZoneDataBase } from "../../../../utils";

import { SINGLE_CASE_QUERY, CANCEL_CASE_MUTATION } from "../../../../graphql";
import CaseCancellationForm from "../CaseCancellationForm";

const initialState = {
  reason: "",
};

const CaseCancellationModal = ({ casenbr, isLateCancel, onComplete }) => {
  const { state: authState } = useAuth();

  const { data: CaseItemData } = useQuery(SINGLE_CASE_QUERY, {
    variables: { where: { casenbr: parseInt(casenbr, 10) } },
  });

  const [cancelCase, { loading: CancelCaseLoading }] = useMutation(
    CANCEL_CASE_MUTATION
  );

  const [inputs, setInputs] = useState({
    ...initialState,
  });

  const handleSave = async (e) => {
    e.preventDefault();
    const date = convertTimeZoneDataBase(getDateEST());
    await cancelCase({
      variables: {
        input: {
          casenbr: parseInt(casenbr, 10),
          reason: inputs.reason,
          isLateCancel,
          userid: authState?.user?.userid,
          date,
        },
      },
    });
    onComplete(true);
  };

  const handleChange = (name, value) => {
    setInputs((prev) => ({ ...prev, [name]: value }));
  };

  const isDisabled = useMemo(
    () =>
      Object.keys(inputs).some((key) => !inputs[key].length) ||
      !CaseItemData?.caseItem,
    [CaseItemData?.caseItem, inputs]
  );

  return (
    <form id="cancel-case-form" onSubmit={handleSave}>
      <header className="page-head">
        <div className="page-head-start">
          <Title size={5}>Cancel Case</Title>
        </div>
        <div className="page-head-end">
          <Button.Group hasAddons>
            <Button
              disabled={CancelCaseLoading}
              size="small"
              type="button"
              onClick={() => onComplete(false)}
            >
              <span>Cancel</span>
            </Button>
            <Button
              color="primary"
              disabled={isDisabled || CancelCaseLoading}
              form="cancel-case-form"
              size="small"
              state={CancelCaseLoading ? "loading" : ""}
              type="submit"
            >
              <span>Submit</span>
            </Button>
          </Button.Group>
        </div>
      </header>
      <hr />
      <CaseCancellationForm
        disabled={CancelCaseLoading}
        inputs={inputs}
        onChange={handleChange}
      />
      <hr />
      <Generic textColor="danger" textSize={6}>
        {isLateCancel
          ? "This cancellation is a late cancellation and may be subject to a cancellation fee."
          : "This cancellation will not be subject to late cancellation fees."}
      </Generic>
    </form>
  );
};

CaseCancellationModal.propTypes = {
  casenbr: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isLateCancel: PropTypes.bool,
  onComplete: PropTypes.func,
};

CaseCancellationModal.defaultProps = {
  casenbr: "",
  isLateCancel: false,
  onComplete: () => null,
};
export default CaseCancellationModal;
