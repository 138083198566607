import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import StyledLabel from "../../styles/StyledLabel";

const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 3px;
`;

const StyledCheckbox = styled.div`
  display: block;
  width: 1.5em;
  height: 1.5em;
  cursor: ${(props) =>
    props.disabled || props.readOnly ? "not-allowed" : "pointer"};
  background: ${(props) =>
    props.checked ? props.theme.secondary : props.theme.offWhite};
  border-radius: 2px;
  transition: all 150ms;
  position: relative;
  margin: 8px 5px 0px 0px;
  background: ${(props) => props.checked ? "#2a4734" : "#b1afaf"};
  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 2px ${(props) => props.theme.lightgrey};
  }
  ${Icon} {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 1em;
    visibility: ${(props) => (props.checked ? "visible" : "hidden")};
  }
`;
const CheckboxContainer = styled(StyledLabel)`
  cursor: ${(props) =>
    props.disabled || props.readOnly ? "not-allowed" : "pointer"};
  font-size: ${(props) => (props.size === "small" ? 0.75 : 1)}rem;
  opacity: ${(props) => (props.disabled || props.readOnly ? "0.5" : "1")};
  ${StyledCheckbox} {
    margin-top: ${(props) => (props.label ? "0.25rem" : "0px")};
  }
  display: inline-flex;
`;

const Checkbox = ({
  name,
  size,
  checked,
  onChange,
  readOnly,
  disabled,
  label,
}) => (
  <CheckboxContainer
    className="checkbox-container"
    disabled={disabled}
    label={label}
    readOnly={readOnly}
    size={size}
  >
    <HiddenCheckbox
      checked={checked}
      name={name}
      onChange={(e) => (!readOnly && !disabled ? onChange(e) : null)}
    />
    <StyledCheckbox checked={checked} disabled={disabled} readOnly={readOnly}>
      <Icon viewBox="0 0 24 24">
        <polyline points="20 6 9 17 4 12" />
      </Icon>
    </StyledCheckbox>
    <span style={{ paddingTop: "0.3rem", fontSize: "0.8rem" }}>{label}</span>
  </CheckboxContainer>
);

Checkbox.propTypes = {
  name: PropTypes.string,
  size: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
};

Checkbox.defaultProps = {
  onChange: (e) => e,
  name: "",
  size: "",
  readOnly: false,
  disabled: false,
  label: "",
};

export default Checkbox;
