const DEFAULT_COLUMNS = [
  {
    Header: "Last Name",
    id: "lastname",
    accessor: "lastname",
  },
  {
    Header: "First Name",
    id: "firstname",
    accessor: "firstname",
  },
  {
    Header: "Address",
    id: "addr1",
    accessor: "addr1",
  },
  {
    Header: "City",
    id: "city",
    accessor: "city",
  },
  {
    Header: "State",
    id: "state",
    accessor: "state",
  },
  {
    Header: "Phone",
    id: "phone1",
    accessor: "phone1",
  },
];

export default DEFAULT_COLUMNS;
