import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { format } from "date-fns";
import { convertTimeZone } from "../utils";
import { useQueryParams } from ".";
import { GET_CASE_FOR_PORTAL2 } from "../graphql";

const isMMorIS = (prevRef) => {
  if (prevRef.startsWith("MM") || prevRef.startsWith("IS")) return true;
  return false;
};

const useGetNonRecordsCase = () => {
  const [state, setState] = useState(null);

  const [previousReferral] = useQueryParams("ISGNumber", "", true);

  const casenbr = isMMorIS(previousReferral)
    ? parseInt(previousReferral.slice(2), 10)
    : null;

  const { data } = useQuery(GET_CASE_FOR_PORTAL2, {
    variables: {
      where: { casenbr },
    },
    fetchPolicy: "network-only",
    skip: !casenbr,
  });

  useEffect(() => {
    if (data) {
      setState({
        // Claim
        carrier: data?.caseItem?.carrier,
        CaseType: data?.caseItem?.casetype,
        ClaimNumber: data?.caseItem?.claimnbr,
        Insured: data?.caseItem?.examinee?.insured,
        DateOfLoss: data?.caseItem?.dateofinjury
          ? format(convertTimeZone(data?.caseItem?.dateofinjury), "yyyy-MM-dd")
          : "",
        Jurisdiction: data?.caseItem?.Jurisdiction,

        // Claimant
        ClaimantFirstName: data?.caseItem?.examinee?.firstname,
        ClaimantLastName: data?.caseItem?.examinee?.lastname,
        ClaimantAddress1: data?.caseItem?.examinee?.addr1,
        ClaimantAddress2: data?.caseItem?.examinee?.addr2,
        ClaimantCity: data?.caseItem?.examinee?.city,
        ClaimantState: data?.caseItem?.examinee?.state,
        ClaimantZip: data?.caseItem?.examinee?.zip,
        ClaimantEmail: data?.caseItem?.examinee?.email,
        ClaimantPhone: data?.caseItem?.examinee?.phone1,
        ClaimantCellPhone: data?.caseItem?.examinee?.phone2,
        ClaimantDateOfBirth: data?.caseItem?.examinee?.DOB
          ? format(convertTimeZone(data?.caseItem?.examinee?.DOB), "yyyy-MM-dd")
          : "",
        ClaimantGender: data?.caseItem?.examinee?.sex,
        ClaimantSSN: data?.caseItem?.examinee?.SSN,
        ClaimantSpecialPhysical: data?.caseItem?.examinee?.note,
      });
    }
  }, [data]);

  return state;
};

export default useGetNonRecordsCase;
