import { differenceInCalendarYears, isValid, isFuture } from "date-fns";
import { convertTimeZone } from ".";

const dolValidations = (stringDate, maxYearsAllowed = 10) => {
  const today = new Date();

  const dolStringAsDate = convertTimeZone(
    new Date(stringDate.split("-").map((x) => parseInt(x, 10)))
  );

  const numberOfYearsDifferent = differenceInCalendarYears(
    today,
    dolStringAsDate
  );
  const isWithinAllowableTimeframe = numberOfYearsDifferent <= maxYearsAllowed;

  if (!isValid(dolStringAsDate)) return { error: true, message: "is invalid" };

  if (isFuture(dolStringAsDate))
    return { error: true, message: "is a future date" };
  if (!isWithinAllowableTimeframe) {
    return {
      error: true,
      message: `is older than maximum allowable ${maxYearsAllowed} years`,
    };
  }

  return { error: false, message: "n/a" };
};

export default dolValidations;
