import { gql } from "@apollo/client";

export const ALL_SERVICE_QUEUES_QUERY = gql`
  query ALL_SERVICE_QUEUES_QUERY(
    $where: ServiceQueueWhereInput
    $orderBy: [ServiceQueueOrderByInput!]
    $take: Int
    $skip: Int
  ) {
    serviceQueues(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
      servicecode
      queueorder
      statuscode
      statusdesc
      createvoucher
      createinvoice
      queue {
        statuscode
        statusdesc
      }
      NextStatus {
        statuscode
        statusdesc
      }
      nextstatus
    }
  }
`;

export const FIND_FIRST_SERVICE_QUEUE_QUERY = gql`
  query FIND_FIRST_SERVICE_QUEUE_QUERY($where: ServiceQueueWhereInput) {
    findFirstServiceQueue(where: $where) {
      servicecode
      queueorder
      statuscode
      statusdesc
      nextstatus
    }
  }
`;
