import { gql } from "@apollo/client";

export const RATS_EMPLOYER_QUERY = gql`
  query Employers(
    $where: EmployerWhereInput
    $orderBy: [EmployerOrderByInput!]
    $take: Int
    $skip: Int
  ) {
    employers(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
      PK_EMid
      EMtaxidFEIN
      EMname
      EMaddress1
      EMaddress2
      EMcity
      EMstate
      EMzip
      EMphone
    }
    aggregateEmployer: aggregateEmployer(where: $where) {
      count {
        PK_EMid
      }
    }
  }
`;

export const RATS_SINGLE_EMPLOYER_QUERY = gql`
  query Employer($where: EmployerWhereUniqueInput!) {
    employer(where: $where) {
      PK_EMid
      EMtaxidFEIN
      EMname
      EMaddress1
      EMaddress2
      EMcity
      EMstate
      EMzip
      EMphone
    }
  }
`;
