import React, { useState } from "react";
import PropTypes from "prop-types";

import { useMutation } from "@apollo/client";
import { Title, Button } from "rbx";
import { toast } from "react-toastify";
import {
  CREATE_CASE_DEF_DOCUMENT_MUTATION,
  LIST_CASE_DEF_DOCUMENT_QUERY,
} from "../../../../graphql";

import CaseDefaultDocumentForm from "../CaseDefaultDocumentForm";
import { useAuth } from "../../../../context";
import { getDateEST } from "../../../../utils";

const initialState = {
  documentcode: "",
  documentqueue: "",
};

const CaseDefaultDocumentModal = ({ casenbr, onComplete }) => {
  const { state: authState } = useAuth();
  const [inputs, setInputs] = useState({ ...initialState });

  const [createCaseDefDocument] = useMutation(
    CREATE_CASE_DEF_DOCUMENT_MUTATION
  );

  const handleSave = async (e) => {
    try {
      e.preventDefault();
      const date = getDateEST();
      await createCaseDefDocument({
        variables: {
          data: {
            casenbr: parseInt(casenbr, 10),
            dateadded: date,
            dateedited: date,
            documentcode: inputs.documentcode,
            documentqueue: parseInt(inputs.documentqueue, 10),
            useridadded: authState?.user?.userid,
            useridedited: authState?.user?.userid,
            order: inputs.order ? parseInt(inputs.order, 10) : null,
          },
        },
        refetchQueries: [
          {
            query: LIST_CASE_DEF_DOCUMENT_QUERY,
            variables: {
              where: { casenbr: { equals: parseInt(casenbr, 10) } },
            },
          },
        ],
      });
      toast.success(`Document created successfully.`);
      onComplete(true);
    } catch (err) {
      toast.error(`Error creating document.`);
    }
  };
  const handleChange = (name, value) => {
    setInputs((prev) => ({ ...prev, [name]: value }));
  };

  const isDisabled = !inputs.documentcode || !inputs.documentqueue;
  return (
    <form id="case-default-document-form" onSubmit={handleSave}>
      <header className="page-head">
        <div className="page-head-start">
          <Title size={5}>Create Case Default Document</Title>
        </div>
        <div className="page-head-end">
          <Button.Group hasAddons>
            <Button
              size="small"
              type="button"
              onClick={() => onComplete(false)}
            >
              <span>Cancel</span>
            </Button>
            <Button
              color="primary"
              disabled={isDisabled}
              form="case-default-document-form"
              size="small"
              type="submit"
            >
              <span>Submit</span>
            </Button>
          </Button.Group>
        </div>
      </header>
      <hr />
      <CaseDefaultDocumentForm inputs={inputs} onChange={handleChange} />
      <hr />
    </form>
  );
};

CaseDefaultDocumentModal.propTypes = {
  casenbr: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onComplete: PropTypes.func,
};

CaseDefaultDocumentModal.defaultProps = {
  casenbr: "",
  onComplete: () => null,
};
export default CaseDefaultDocumentModal;
