import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import { Input, Control, Button, Icon, Field } from "rbx";
import { useMutation } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  LIST_EXCEPTION_DEFINITION_QUERY,
  DELETE_EXCEPTION_MUTATION,
} from "../../../../graphql/exception";
import { debounce, customToast as toast } from "../../../../utils";
import { useModal } from "../../../../context";
import DataTable from "../../../../components/DataTable";
import Confirmation from "../../../../components/Confirmation";
import ExceptionModal from "../../../../components/ExceptionDefinition/ExceptionModal";
import { generateColumns } from "./columns";

const Exceptions = ({ company }) => {
  const { setModalOpen } = useModal();
  const [deleteException] = useMutation(DELETE_EXCEPTION_MUTATION);
  const [where, setWhere] = useState({
    IMECentricCode: { equals: parseInt(company, 10) },
    Entity: { equals: "CO" },
  });

  const addException = () => {
    setModalOpen(
      true,
      <ExceptionModal
        entityDisabled
        Entity="CO"
        IMECentricCode={company}
        onComplete={() => setModalOpen(false, "")}
      />
    );
  };

  const editException = (exception) => {
    setModalOpen(
      true,
      <ExceptionModal
        entityDisabled
        Entity="CO"
        IMECentricCode={company}
        exception={exception}
        onComplete={() => setModalOpen(false, "")}
      />
    );
  };

  const COLUMNS = useMemo(() => {
    const handleDelete = (exception) => {
      const performDelete = async () => {
        try {
          setModalOpen(false, "");
          await deleteException({
            variables: {
              where: {
                ExceptionDefID: parseInt(exception.ExceptionDefID, 10),
              },
            },
            refetchQueries: [
              {
                query: LIST_EXCEPTION_DEFINITION_QUERY,
                variables: {
                  where,
                  orderBy: [{ DateAdded: "desc" }, { Status: "desc" }],
                },
              },
            ],
          });
          toast.success("Exception deleted successfully.");
        } catch (err) {
          toast.error(`Error deleting exception.`);
        }
      };
      setModalOpen(
        true,
        <Confirmation
          message="Are you sure you want to delete this exception?"
          onCancel={() => setModalOpen(false, "")}
          onConfirm={performDelete}
        />
      );
    };
    return generateColumns(handleDelete);
  }, [deleteException, setModalOpen, where]);

  return (
    <div>
      <div className="section-header">
        <Field kind="group">
          <Control>
            <Button color="primary" size="small" onClick={addException}>
              <Icon>
                <FontAwesomeIcon icon="plus" />
              </Icon>
              <span>Add</span>
            </Button>
          </Control>
          <Control>
            <Input
              autoComplete="new"
              placeholder="Search..."
              size="small"
              onChange={debounce(({ target: { value } }) => {
                setWhere({ ...where, Description: { startsWith: value } });
              }, 500)}
            />
          </Control>
        </Field>
      </div>
      <DataTable
        aggregateKey="ExceptionDefID"
        aggregateName="aggregateExceptionDefinition"
        columns={COLUMNS}
        fetchPolicy="cache-and-network"
        name="exceptionDefinitions"
        orderBy={[
          { id: "Status", desc: false },
          { id: "Description", desc: false },
        ]}
        query={LIST_EXCEPTION_DEFINITION_QUERY}
        where={where}
        onRowClick={(exception) => editException(exception)}
      />
    </div>
  );
};

Exceptions.propTypes = {
  company: PropTypes.string,
};

Exceptions.defaultProps = {
  company: "",
};

export default Exceptions;
