import React from "react";
import PropTypes from "prop-types";
import { Column, Field, Control, Label, Input, Textarea } from "rbx";
import BooleanInput from "../../../components/BooleanInput";
import CurrencyInput from "../../../components/CurrencyInput";
import UserSelect from "../../../components/UserSelect";

const UserFieldsForm = ({ disabled, inputs, onChange, onSubmit }) => (
  <form id="edit-case-form" onSubmit={onSubmit}>
    <Column.Group multiline>
      <Column size={6}>
        <Field className="grid-override is-four-complete" kind="group">
          <Control expanded>
            <Label>Impact Rating</Label>
            <Input
              autoComplete="new"
              disabled={disabled}
              name="usdvarchar1"
              placeholder="Impact Rating"
              size="small"
              value={inputs.usdvarchar1}
              onChange={onChange}
            />
          </Control>
          <Control expanded>
            <Label>Geico Outcome</Label>
            <Input
              autoComplete="new"
              disabled={disabled}
              name="usdvarchar2"
              placeholder="Geico Outcome"
              size="small"
              type="text"
              value={inputs.usdvarchar2}
              onChange={onChange}
            />
          </Control>
          <Control expanded>
            <UserSelect
              fullWidth
              disabled={disabled}
              label="Ask Credit"
              name="SalesCreditUserID"
              value={inputs.SalesCreditUserID}
              where={{
                fldActive: { equals: 1 },
                OR: [
                  { SurvCaseManager: { equals: 1 } },
                  { ConversionTeam: { equals: 1 } },
                  { SIUCaseManager: { equals: 1 } },
                ],
              }}
              onChange={onChange}
            />
          </Control>
          <Control expanded>
            <Label>&nbsp;</Label>
            <BooleanInput
              disabled={disabled}
              label="Incomplete Referral"
              name="bComplete"
              value={inputs.bComplete}
              onChange={onChange}
            />
          </Control>
        </Field>
        <Field className="grid-override is-four-complete" kind="group">
          <Control expanded>
            <Label>Total Hours</Label>
            <Input
              autoComplete="new"
              disabled={disabled}
              name="usdint1"
              placeholder="Total Hours"
              size="small"
              type="number"
              value={inputs.usdint1}
              onChange={onChange}
            />
          </Control>
          <Control expanded>
            <Label>Hours Used</Label>
            <Input
              autoComplete="new"
              disabled={disabled}
              max="999.99"
              name="usdint2"
              placeholder="Hours Used"
              size="small"
              type="number"
              value={inputs.usdint2}
              onChange={onChange}
            />
          </Control>
          <CurrencyInput
            disabled={disabled}
            label="Miles"
            name="usdmoney1"
            placeholder="Miles"
            size="small"
            type="number"
            value={inputs.usdmoney1}
            onChange={onChange}
          />

          <CurrencyInput
            disabled={disabled}
            label="Rate of Pay"
            name="usdmoney2"
            placeholder="Rate of Pay"
            size="small"
            type="number"
            value={inputs.usdmoney2}
            onChange={onChange}
          />
        </Field>
      </Column>
      <Column size={6}>
        <Field kind="group">
          <Control expanded>
            <Label>IME/Med Appt Date</Label>
            <Input
              disabled={disabled}
              name="usddate1"
              size="small"
              type="date"
              value={inputs.usddate1}
              onChange={onChange}
            />
          </Control>
          <Control expanded>
            <Label>Mandatory Work Date</Label>
            <Input
              disabled={disabled}
              name="usddate2"
              size="small"
              type="date"
              value={inputs.usddate2}
              onChange={onChange}
            />
          </Control>
          <Control expanded>
            <Label>Pre-Edit Date</Label>
            <Input
              disabled={disabled}
              name="usddate3"
              size="small"
              type="date"
              value={inputs.usddate3}
              onChange={onChange}
            />
          </Control>
          <Control expanded>
            <Label>Closed Date</Label>
            <Input
              disabled={disabled}
              name="usddate4"
              size="small"
              type="date"
              value={inputs.usddate4}
              onChange={onChange}
            />
          </Control>
        </Field>
        <Field kind="group">
          <Control expanded>
            <Label>IME MM MC Additional</Label>
            <Textarea
              disabled={disabled}
              name="usdtext1"
              placeholder="IME MM MC Additional"
              size="small"
              type="text"
              value={inputs.usdtext1}
              onChange={onChange}
            />
          </Control>
          <Control expanded>
            <Label>Impact/Sypnosis</Label>
            <Textarea
              disabled={disabled}
              name="usdtext2"
              placeholder="Impact/Sypnosis"
              size="small"
              type="text"
              value={inputs.usdtext2}
              onChange={onChange}
            />
          </Control>
        </Field>
      </Column>
    </Column.Group>
  </form>
);

UserFieldsForm.propTypes = {
  disabled: PropTypes.bool,
  inputs: PropTypes.object,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
};

UserFieldsForm.defaultProps = {
  disabled: false,
  inputs: {},
  onChange: () => null,
  onSubmit: () => null,
};

export default UserFieldsForm;
