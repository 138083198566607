import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import PropTypes from "prop-types";
import { Title, Button } from "rbx";
import { useMutation, useApolloClient } from "@apollo/client";

import {
  LIST_APOLLO_SERVICES_QUERY,
  CREATE_APOLLO_SERVICE_MUTATION,
  CREATE_CASE_MUTATION,
  SIMPLE_QUEUES_QUERY,
  LIST_CASE_CLIENT_QUERY,
  CREATE_CASE_HISTORY_MUTATION,
  LIST_CASE_HISTORY_QUERY,
  SINGLE_CLIENT_QUERY,
  SINGLE_COMPANY_QUERY,
} from "../../../../graphql";
import {
  customToast as toast,
  getDateEST,
  convertTimeZoneDataBase,
  convertTimeZone,
} from "../../../../utils";
import ClaimServiceForm from "../ClaimServiceForm";
import { useAuth } from "../../../../context/AuthContext";

const INITIAL_STATE = {
  // Case information
  clientcode: "", // client
  IMECompany: "", // company
  officecode: "", // office
  priority: "Normal    ", // priority
  commitdate: format(new Date(), "yyyy-MM-dd"), // Commit Date
  caseType: "", // case type
  servicecode: "", // service code
  Jurisdiction: "", // jurisdiction
  marketercode: "",
  QARep: "",
  schedulercode: "",
  Status: 3,
  // Apollo service information
  ServiceID: "",
  ExternalServiceID: "",
  ParentServiceID: "",
  ServiceTypeID: "",
  ClaimID: "",
  WorkID: "",
  Archive: 0,
};

const ClaimServiceModal = ({ onComplete, ClaimID, ParentServiceID }) => {
  const client = useApolloClient();
  const { state: authState } = useAuth();
  const [inputs, setInputs] = useState(INITIAL_STATE);
  const [loading, setLoading] = useState(false);

  const [createService] = useMutation(CREATE_APOLLO_SERVICE_MUTATION);
  const [createCase] = useMutation(CREATE_CASE_MUTATION);
  const [createCaseHistory] = useMutation(CREATE_CASE_HISTORY_MUTATION);

  // company should change with client
  useEffect(() => {
    const fetchClient = async (clientcode) => {
      const { data: clientData } = await client.query({
        query: SINGLE_CLIENT_QUERY,
        variables: { where: { clientcode: parseInt(clientcode, 10) } },
      });

      if (clientData?.client) {
        setInputs((prev) => ({
          ...prev,
          marketercode: clientData.client.marketercode || "",
          IMECompany: clientData.client.companycode || "",
        }));
      }
    };
    if (inputs.clientcode) {
      fetchClient(inputs.clientcode);
    }
  }, [client, authState, inputs.clientcode]);

  // qa rep should change with comppany
  useEffect(() => {
    const fetchCompany = async (companycode) => {
      const { data: companyData } = await client.query({
        query: SINGLE_COMPANY_QUERY,
        variables: { where: { companycode: parseInt(companycode, 10) } },
      });

      if (companyData?.company) {
        setInputs((prev) => ({
          ...prev,
          QARep: companyData.company.QARep || "",
        }));
      }
    };

    if (inputs.IMECompany) {
      fetchCompany(inputs.IMECompany);
    }
  }, [client, inputs.IMECompany]);

  const handleChange = (name, value) => {
    setInputs((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);

      const date = convertTimeZoneDataBase(getDateEST());
      const { clientcode, ServiceTypeID, Archive } = inputs;
      const objectToCreate = {
        IMECompany: inputs.IMECompany.toString(),
        client: {
          connect: {
            clientcode: parseInt(clientcode, 10),
          },
        },
        caseType: inputs.caseType
          ? {
              connect: {
                code: parseInt(inputs.caseType, 10),
              },
            }
          : null,
        office: inputs.officecode
          ? {
              connect: {
                officecode: parseInt(inputs.officecode, 10),
              },
            }
          : null,
        service: inputs.servicecode
          ? {
              connect: {
                servicecode: parseInt(inputs.servicecode, 10),
              },
            }
          : null,
        Status: inputs.Status
          ? {
              connect: {
                statuscode: parseInt(inputs.Status, 10),
              },
            }
          : null,
        marketercode: inputs.marketercode,
        schedulercode: inputs.schedulercode,
        QARep: inputs.QARep,
        commitdate: inputs.commitdate,
        priority: inputs.priority,
        Jurisdiction: inputs.Jurisdiction,
      };
      // Clear Object
      const cleanObject = Object.fromEntries(
        Object.entries(objectToCreate).filter(([_, v]) => v)
      );
      const { data: queueData } = await client.query({
        query: SIMPLE_QUEUES_QUERY,
        variables: {
          where: { statuscode: { equals: parseInt(inputs.Status, 10) } },
          first: 1,
        },
        fetchPolicy: "network-only",
      });
      const { data: createCaseData } = await createCase({
        variables: {
          data: {
            ...cleanObject,
            WebNotifyEmail: authState?.user?.email,
            dateadded: date,
            dateedited: date,
            DateReceived: date,
            useridadded: authState?.user?.userid,
            useridedited: authState?.user?.userid,
            CaseCompletedDate:
              queueData?.queues[0]?.statusdesc === "Complete" ? date : null,
          },
        },
        refetchQueries: [
          {
            query: LIST_CASE_CLIENT_QUERY,
          },
        ],
      });
      await createCaseHistory({
        variables: {
          data: {
            casenbr: createCaseData.createCaseItem.casenbr,
            eventdate: date,
            eventdesc: "Case Created",
            PublishOnWeb: false,
            otherinfo: `Case created from Apollo at ${format(
              convertTimeZone(date),
              "MM/dd/yyyy hh:mm a"
            )} by ${authState?.user?.userid}`,
            userid: authState?.user?.userid,
            useridedited: authState?.user?.userid,
            dateedited: getDateEST(),
            status: parseInt(inputs.Status, 10),
            dateadded: getDateEST(),
          },
        },
        refetchQueries: [
          {
            query: LIST_CASE_HISTORY_QUERY,
            variables: {
              where: {
                casenbr: { equals: createCaseData.createCaseItem.casenbr },
              },
              orderBy: [{ eventdate: "desc" }],
            },
            fetchPolicy: "cache-first",
          },
        ],
      });
      await createService({
        variables: {
          data: {
            ClientCode: parseInt(clientcode, 10),
            ExternalServiceID: inputs.servicecode,
            ParentService: ParentServiceID
              ? { connect: { ServiceID: ParentServiceID } }
              : {},
            ServiceType: { connect: { ServiceTypeID } },
            WorkID: createCaseData.createCaseItem.casenbr,
            CreatedDate: date,
            Archive: !!Archive,
            ClaimID: parseInt(ClaimID, 10),
          },
        },
        refetchQueries: [
          {
            query: LIST_APOLLO_SERVICES_QUERY,
            variables: {
              where: {
                ClaimID: {
                  equals: parseInt(ClaimID, 10),
                },
                OR: [
                  {
                    ParentServiceID: { equals: null },
                  },
                  {
                    ParentServiceID: { equals: 0 },
                  },
                ],
              },
              orderBy: [{ ServiceID: "desc" }],
            },
          },
        ],
      });
      toast.success("Service created successfully.");
      onComplete();
    } catch (err) {
      toast.error("Error creating Service");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <header className="page-head">
        <div className="page-head-start">
          <Title size={5}>
            {ParentServiceID ? "Create Child Service" : "Create Service"}
          </Title>
        </div>
        <div className="page-head-end">
          <Button.Group hasAddons>
            <Button
              disabled={loading}
              size="small"
              type="button"
              onClick={() => onComplete(false)}
            >
              <span>Cancel</span>
            </Button>
            <Button
              color="primary"
              disabled={
                loading ||
                !inputs.clientcode ||
                !inputs.IMECompany ||
                !inputs.officecode ||
                !inputs.caseType ||
                !inputs.Jurisdiction ||
                !inputs.ServiceTypeID
              }
              form="claim-service-form"
              size="small"
              state={loading ? "loading" : ""}
              type="submit"
            >
              <span>Submit</span>
            </Button>
          </Button.Group>
        </div>
      </header>
      <hr />
      <ClaimServiceForm
        disabled={loading}
        inputs={inputs}
        onChange={handleChange}
        onSubmit={handleSubmit}
      />
      <hr />
    </div>
  );
};

ClaimServiceModal.propTypes = {
  onComplete: PropTypes.func,
  ClaimID: PropTypes.number,
  ParentServiceID: PropTypes.string,
};

ClaimServiceModal.defaultProps = {
  onComplete: () => null,
  ParentServiceID: 0,
  ClaimID: null,
};

export default ClaimServiceModal;
