const allstateDefaultEmails = [
  {
    email: "claims@claims.allstate.com",
    firstname: "",
    lastname: "",
    phone1: "",
    extname: "Allstate",
    intname: "",
  },
  {
    email: "claims@ngic.com",
    firstname: "",
    lastname: "",
    phone1: "",
    extname: "National General",
    intname: "",
  },
];

export default allstateDefaultEmails;
