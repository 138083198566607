const company = {
  name: "company",
  label: "Companies",
  icon: "building",

  active: (location) => /companies/.test(location.pathname),
  to: "/companies",
};

export default company;
