import React, { useState, useReducer } from "react";
import PropTypes from "prop-types";

import { Title, Button } from "rbx";
import axios from "axios";
import { useMutation, useApolloClient } from "@apollo/client";
import FileServerForm from "../CaseLinkDocumentForm";
import { useCaseDocumentPath } from "../../../../hooks";
import { customToast as toast, getDateEST } from "../../../../utils";
import { useAuth } from "../../../../context";
import {
  CREATE_CASE_DOCUMENT_MUTATION,
  LIST_CASE_DOCUMENT_QUERY,
  FIND_FIRST_CASE_DOCUMENT_QUERY,
} from "../../../../graphql";

const initialState = {
  name: "",
  description: "",
};
const { REACT_APP_DOCUMENT_HOST } = process.env;

function reducer(state, action) {
  switch (action.type) {
    case "found":
      return {
        canSubmit: true,
        errorMessage: "",
        successMessage: `File found in ${action.documentPath}.`,
      };
    case "not_found":
      return {
        canSubmit: false,
        errorMessage: `This file cannot be found in ${action.documentPath}.`,
        successMessage: "",
      };
    case "exists":
      return {
        canSubmit: false,
        errorMessage: "A case document linked to this file already exists.",
        successMessage: "",
      };
    case "reset":
      return {
        canSubmit: false,
        errorMessage: "",
        successMessage: "",
      };
    default:
      throw new Error();
  }
}
const CaseLinkDocumentModal = ({ casenbr, onComplete }) => {
  const client = useApolloClient();
  const { state: authState } = useAuth();

  const [state, dispatch] = useReducer(reducer, {
    canSubmit: false,
    successMessage: "",
    errorMessage: "",
  });

  const getCaseVirtualDocumentPath = useCaseDocumentPath(casenbr);
  const [
    createCaseDocument,
    { loading: CreateCaseDocumentLoading },
  ] = useMutation(CREATE_CASE_DOCUMENT_MUTATION);

  const [inputs, setInputs] = useState({
    ...initialState,
  });

  const handleSave = async (e) => {
    e.preventDefault();

    await createCaseDocument({
      variables: {
        data: {
          dateadded: getDateEST(),
          useridadded: authState?.user?.userid,
          document: "external",
          description: inputs.description || "",
          sfilename: inputs.sfilename || "",
          CaseItem: {
            connect: {
              casenbr: parseInt(casenbr, 10),
            },
          },
        },
      },
      refetchQueries: [
        {
          query: LIST_CASE_DOCUMENT_QUERY,
          variables: {
            where: { casenbr: { equals: parseInt(casenbr, 10) } },
          },
        },
      ],
    });
    toast.success("Document created successfully");
    onComplete();
  };

  const handleChange = (name, value) => {
    setInputs((prev) => ({ ...prev, [name]: value }));
  };

  const handleBlur = async (value) => {
    try {
      if (value) {
        const documentPath = getCaseVirtualDocumentPath("", false);

        const { data: caseDocumentData } = await client.query({
          query: FIND_FIRST_CASE_DOCUMENT_QUERY,
          variables: {
            where: {
              sfilename: { equals: value },
              casenbr: { equals: parseInt(casenbr, 10) },
            },
          },
          fetchPolicy: "network-only",
        });
        if (caseDocumentData?.findFirstCaseDocument) {
          dispatch({ type: "exists", documentPath });
        } else {
          // ensure path
          const { data: documentExists } = await axios.get(
            `${REACT_APP_DOCUMENT_HOST}/exists?path=${encodeURIComponent(
              `${documentPath}/${value}`
            )}`
          );
          if (!documentExists) {
            dispatch({ type: "not_found", documentPath });
          } else {
            dispatch({ type: "found", documentPath });
          }
        }
      } else {
        dispatch({ type: "reset" });
      }
    } catch (err) {
      toast.error(err.message);
    }
  };

  const isDisabled =
    !inputs.sfilename || !inputs.description || CreateCaseDocumentLoading;

  return (
    <form id="link-case-document-form" onSubmit={handleSave}>
      <header className="page-head">
        <div className="page-head-start">
          <Title size={5}>Link Existing Document</Title>
        </div>
        <div className="page-head-end">
          <Button.Group hasAddons>
            <Button
              disabled={CreateCaseDocumentLoading}
              size="small"
              type="button"
              onClick={() => onComplete(false)}
            >
              <span>Cancel</span>
            </Button>
            <Button
              color="primary"
              disabled={isDisabled || !state.canSubmit}
              form="link-case-document-form"
              size="small"
              state={CreateCaseDocumentLoading ? "loading" : ""}
              type="submit"
            >
              <span>Submit</span>
            </Button>
          </Button.Group>
        </div>
      </header>
      <hr />
      <FileServerForm
        canSubmit={state.canSubmit}
        errorMessage={state.errorMessage}
        inputs={inputs}
        successMessage={state.successMessage}
        onBlur={handleBlur}
        onChange={handleChange}
      />
      <hr />
    </form>
  );
};

CaseLinkDocumentModal.propTypes = {
  casenbr: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onComplete: PropTypes.func,
};

CaseLinkDocumentModal.defaultProps = {
  casenbr: "",
  onComplete: () => null,
};
export default CaseLinkDocumentModal;
