import React from "react";
import PropTypes from "prop-types";
import { Control, Field, Box, Label, Radio } from "rbx";

import ReferralStyledSubtitle from "../ReferralStyledSubtitle";

const SubServiceForm = ({ handleChange, inputs, disabled, isGeicoOffice }) => (
  <Box className="col-span">
    <ReferralStyledSubtitle>Service Selection</ReferralStyledSubtitle>
    <Field className="radioWrapper">
      <Control>
        <Label>
          <Radio
            checked={inputs.SubService === "RR"}
            disabled={disabled}
            name="SubService"
            value="RR"
            onChange={(e) => handleChange(e.target.name, e.target.value)}
          />
          &nbsp;
          <span className="radioItem">Record Retrieval</span>
        </Label>
      </Control>
    </Field>
    <Field className="radioWrapper">
      <Control>
        <Label disabled={isGeicoOffice}>
          <Radio
            checked={inputs.SubService === "IQ"}
            disabled={disabled || isGeicoOffice}
            name="SubService"
            value="IQ"
            onChange={(e) => handleChange(e.target.name, e.target.value)}
          />
          &nbsp;
          <span className="radioItem" style={{color: isGeicoOffice ? '#888' : ''}}>Record IQ Summary</span>
        </Label>
      </Control>
    </Field>
    <Field className="radioWrapper">
      <Control>
        <Label disabled={isGeicoOffice}>
          <Radio
            checked={inputs.SubService === "RRIQ"}
            disabled={disabled || isGeicoOffice}
            name="SubService"
            value="RRIQ"
            onChange={(e) => handleChange(e.target.name, e.target.value)}
          />
          &nbsp;
          <span className="radioItem" style={{color: isGeicoOffice ? '#888' : ''}}>
            Record IQ Summary and Record Retrieval
          </span>
        </Label>
      </Control>
    </Field>
    <Field>
      <i style={{ fontSize: "0.75rem" }}>
        You can always add summaries and retrieval orders to this case at a
        future date
      </i>
    </Field>
  </Box>
);

SubServiceForm.propTypes = {
  handleChange: PropTypes.func.isRequired,
  inputs: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  isGeicoOffice: PropTypes.bool.isRequired,
};

export default SubServiceForm;
