const COLUMNS = [
  {
    Header: "Claim Number",
    accessor: "ClaimNumber",
    id: "ClaimNumber",
    sortType: "basic",
  },
  {
    Header: "Company Name",
    accessor: "CompanyName",
    id: "CompanyName",
    sortType: "basic",
  },
  {
    Header: "First Name",
    accessor: "ClaimantFirstName",
    id: "ClaimantFirstName",
    sortType: "basic",
  },
  {
    Header: "Last Name",
    accessor: "ClaimantLastName",
    id: "ClaimantLastName",
    sortType: "basic",
  },
  {
    Header: "Middle Name",
    accessor: "ClaimantMiddleName",
    id: "ClaimantMiddleName",
    maxWidth: "10%",
    sortType: "basic",
  },
  {
    Header: "City",
    accessor: "City",
    id: "City",
    sortType: "basic",
  },
  {
    Header: "State",
    accessor: "State",
    id: "State",
    maxWidth: "5%",
    sortType: "basic",
  },
  {
    Header: "HCP",
    accessor: "HCP",
    id: "HCP",
    maxWidth: "5%",
    disableSortBy: true,
    Cell: ({ cell }) => (cell.value ? "Yes" : "No"),
    sortType: "basic",
  },
];
export default COLUMNS;
