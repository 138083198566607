import React, { useState } from "react";
import PropTypes from "prop-types";
import { useApolloClient, useMutation } from "@apollo/client";
import { Title, Button } from "rbx";
import ServiceQueueForm from "../ServiceQueueForm";
import {
  customToast as toast,
  getDateEST,
  convertTimeZoneDataBase,
} from "../../../../../utils";
import {
  SINGLE_QUEUE_QUERY,
  CREATE_SERVICE_QUEUE_MUTATION,
  ALL_SERVICE_QUEUES_QUERY,
  UPDATE_SERVICE_QUEUE_MUTATION,
  // CREATE_AUDIT_TRAIL_MUTATION,
} from "../../../../../graphql";
import { useAuth } from "../../../../../context";

const INITIAL_STATE = {
  queueorder: "",
  statuscode: "",
  statusdesc: "",
  createvoucher: false,
  createinvoice: false,
};

const ServiceQueueModal = ({
  servicecode,
  queueorder,
  onComplete,
  lastItem,
  inputs: initialInputs,
}) => {
  const client = useApolloClient();
  const { state: authState } = useAuth();
  const [createServiceQueue] = useMutation(CREATE_SERVICE_QUEUE_MUTATION);
  const [updateServiceQueue] = useMutation(UPDATE_SERVICE_QUEUE_MUTATION);
  // const [createAuditTrail] = useMutation(CREATE_AUDIT_TRAIL_MUTATION);
  const [loading, setLoading] = useState(false);
  const [inputs, setInputs] = useState(
    initialInputs ? { ...initialInputs } : { ...INITIAL_STATE, queueorder }
  );
  const [updated, setUpdated] = useState([]);

  const handleChange = (name, value) => {
    setInputs((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (initialInputs) {
      setUpdated((prev) => (prev.includes(name) ? prev : [...prev, name]));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const { data: queueData } = await client.query({
        query: SINGLE_QUEUE_QUERY,
        variables: {
          where: {
            statuscode: parseInt(inputs.statuscode, 10),
          },
        },
      });
      const date = convertTimeZoneDataBase(getDateEST());
      if (lastItem) {
        await updateServiceQueue({
          variables: {
            where: {
              servicecode_queueorder_statuscode: {
                servicecode: lastItem.servicecode,
                queueorder: lastItem.queueorder,
                statuscode: lastItem.statuscode,
              },
            },
            data: {
              nextstatus: { set: inputs.statuscode.toString() },
              dateedited: { set: date },
              useridedited: { set: authState?.user?.userid },
            },
          },
        });
      }
      if (initialInputs) {
        await updateServiceQueue({
          variables: {
            where: {
              servicecode_queueorder_statuscode: {
                servicecode: initialInputs.servicecode,
                queueorder: initialInputs.queueorder,
                statuscode: initialInputs.statuscode,
              },
            },
            data: {
              statuscode: { set: inputs.statuscode.toString() },
              statusdesc: { set: queueData?.queue?.statusdesc },
              createinvoice: { set: inputs.createinvoice },
              createvoucher: { set: inputs.createvoucher },
              dateedited: { set: date },
              useridedited: { set: authState?.user?.userid },
            },
          },
          refetchQueries: [
            {
              query: ALL_SERVICE_QUEUES_QUERY,
              variables: {
                where: {
                  servicecode: { equals: parseInt(servicecode, 10) },
                },
                orderBy: [{ queueorder: "asc" }, { nextstatus: "asc" }],
              },
            },
          ],
        });
        // await createAuditTrail({
        //   variables: {
        //     data: {
        //       tablename: "tblServiceQueue",
        //       entityid: `${initialInputs.servicecode}_${initialInputs.queueorder}_${initialInputs.statuscode}`,
        //       type: "Update",
        //       description: `Updated fields: ${updated.join(", ")}`,
        //       useridadded: authState.user.userid,
        //       dateadded: date,
        //     },
        //   },
        // });
      } else {
        await createServiceQueue({
          variables: {
            data: {
              servicecode: parseInt(servicecode, 10),
              queueorder: parseInt(queueorder, 10),
              statuscode: inputs.statuscode.toString(),
              statusdesc: queueData?.queue?.statusdesc,
              nextstatus: "0",
              dateadded: date,
              dateedited: date,
              createinvoice: inputs.createinvoice,
              createvoucher: inputs.createvoucher,
              useridadded: authState?.user?.userid,
              useridedited: authState?.user?.userid,
            },
          },
          refetchQueries: [
            {
              query: ALL_SERVICE_QUEUES_QUERY,
              variables: {
                where: {
                  servicecode: { equals: parseInt(servicecode, 10) },
                },
                orderBy: [{ queueorder: "asc" }, { nextstatus: "asc" }],
              },
            },
          ],
        });
        // const { createServiceQueue: newServiceQueue } = createServiceQueueData;
        // await createAuditTrail({
        //   variables: {
        //     data: {
        //       tablename: "tblServiceQueue",
        //       entityid: `${newServiceQueue.servicecode}_${newServiceQueue.queueorder}_${newServiceQueue.statuscode}`,
        //       type: "New",
        //       description: "New Service Queue created",
        //       useridadded: authState.user.userid,
        //       dateadded: date,
        //     },
        //   },
        // });
      }
      onComplete();
    } catch (err) {
      toast.error("Error saving this Service Queue");
    } finally {
      setLoading(false);
    }
  };

  const isDisabled =
    !inputs.statuscode || (initialInputs && !updated?.length) || loading;

  return (
    <form id="service-queue-form" onSubmit={handleSubmit}>
      <header className="page-head">
        <div className="page-head-start">
          <Title size={5}>
            {!initialInputs ? "Create Service Queue" : "Edit Service Queue"}
          </Title>
        </div>
        <div className="page-head-end">
          <Button.Group hasAddons>
            <Button
              size="small"
              type="button"
              onClick={() => onComplete(false)}
            >
              <span>Cancel</span>
            </Button>
            <Button
              color="primary"
              disabled={isDisabled}
              form="service-queue-form"
              size="small"
              state={loading ? "loading" : ""}
              type="submit"
            >
              <span>Submit</span>
            </Button>
          </Button.Group>
        </div>
      </header>
      <hr />
      <ServiceQueueForm
        disabled={loading}
        inputs={inputs}
        onChange={handleChange}
      />
      <hr />
    </form>
  );
};

ServiceQueueModal.propTypes = {
  onComplete: PropTypes.func,
  servicecode: PropTypes.string.isRequired,
  queueorder: PropTypes.number,
  lastItem: PropTypes.object,
  inputs: PropTypes.object,
};

ServiceQueueModal.defaultProps = {
  onComplete: () => null,
  lastItem: null,
  inputs: null,
  queueorder: null,
};

export default ServiceQueueModal;
