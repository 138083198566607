import React, { useState, useEffect, useRef } from "react";
import { Title, Field, Control, Button, Icon, Input } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useParams } from "react-router-dom";
import { useModal } from "../../../../context";
import DataTable from "../../../../components/DataTable";
import { LIST_APOLLO_DOCUMENT_TYPES_QUERY } from "../../../../graphql";
import { DocumentTypeModal } from "../../components";
import { debounce } from "../../../../utils";
import COLUMNS from "./columns";

const DEFAULT_FILTER_STATE = {
  OR: [
    {
      DocumentType: { contains: "" },
    },
  ],
};

const CaseTypesListPage = () => {
  const { type } = useParams();
  const [where, setWhere] = useState(DEFAULT_FILTER_STATE);
  const [searchValue, setSearchValue] = useState("");
  const isModalOpen = useRef(false);

  const { setModalOpen } = useModal();

  useEffect(() => {
    if (type && !isModalOpen.current) {
      isModalOpen.current = true;
      setModalOpen(
        true,
        <DocumentTypeModal
          DocumentTypeID={parseInt(type, 10)}
          where={where}
          onComplete={() => setModalOpen(false)}
        />
      );
    }
  }, [type, setModalOpen, where]);

  const handleRowClick = (row) => {
    setModalOpen(
      true,
      <DocumentTypeModal
        DocumentTypeID={parseInt(row.DocumentTypeID, 10)}
        where={where}
        onComplete={() => setModalOpen(false)}
      />
    );
  };

  const handleSearchChange = debounce(({ target: { value } }) => {
    if (value) {
      setWhere({
        OR: [
          {
            DocumentType: { contains: value },
          },
        ],
      });
    } else {
      setWhere({
        OR: [
          {
            DocumentType: { contains: "" },
          },
        ],
      });
    }
  }, 500);

  return (
    <div>
      <div className="section-header">
        <Title style={{ marginRight: 15 }}>Document Types</Title>
        <Field kind="group">
          <Control>
            <Input
              autoComplete="off"
              id="search-input"
              placeholder="Search..."
              size="small"
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value);
                handleSearchChange(e);
              }}
            />
          </Control>

          <Control>
            <Button
              color="primary"
              size="small"
              onClick={() => {
                setModalOpen(
                  true,
                  <DocumentTypeModal
                    where={where}
                    onComplete={() => setModalOpen(false)}
                  />
                );
              }}
            >
              <Icon>
                <FontAwesomeIcon icon="plus" />
              </Icon>

              <span>Add</span>
            </Button>
          </Control>
        </Field>
      </div>

      <DataTable
        aggregateKey="DocumentTypeID"
        aggregateName="aggregateDocumentTypes"
        columns={COLUMNS}
        name="findManyDocumentTypes"
        orderBy={[{ id: "DocumentType", desc: false }]}
        query={LIST_APOLLO_DOCUMENT_TYPES_QUERY}
        where={where}
        onRowClick={handleRowClick}
      />
    </div>
  );
};

CaseTypesListPage.propTypes = {};

export default CaseTypesListPage;
