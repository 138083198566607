const CLIENT_FIELDS = [
  { name: "ARKey", type: "String", entity: "client" },
  { name: "CertifiedMail", type: "Boolean", entity: "client" },
  { name: "Country", type: "String", entity: "client" },
  { name: "DefOfficeCode", type: "Int", entity: "client" },
  { name: "DocumentPublish", type: "Boolean", entity: "client" },
  { name: "ProcessorEmail", type: "String", entity: "client" },
  { name: "ProcessorFax", type: "String", entity: "client" },
  { name: "ProcessorFirstName", type: "String", entity: "client" },
  { name: "ProcessorLastName", type: "String", entity: "client" },
  { name: "ProcessorPhone", type: "String", entity: "client" },
  { name: "ProcessorPhoneExt", type: "String", entity: "client" },
  { name: "PublishOnWeb", type: "Boolean", entity: "client" },
  { name: "QARep", type: "String", entity: "client" },
  { name: "TypeCode", type: "Int", entity: "client" },
  { name: "WebGUID", type: "String", entity: "client" },
  { name: "WebUserID", type: "Int", entity: "client" },
  { name: "WebUserName", type: "String", entity: "client" },
  { name: "Webpassword", type: "String", entity: "client" },
  { name: "addr1", type: "String", entity: "client" },
  { name: "addr2", type: "String", entity: "client" },
  { name: "billaddr1", type: "String", entity: "client" },
  { name: "billaddr2", type: "String", entity: "client" },
  { name: "billattn", type: "String", entity: "client" },
  { name: "billcity", type: "String", entity: "client" },
  { name: "billfax", type: "String", entity: "client" },
  { name: "billstate", type: "String", entity: "client" },
  { name: "billzip", type: "String", entity: "client" },
  { name: "casetype", type: "String", entity: "client" },
  { name: "city", type: "String", entity: "client" },
  { name: "clientcode", type: "Int", entity: "client" },
  { name: "clientnbrold", type: "String", entity: "client" },
  { name: "company", type: "Company", entity: "client" },
  { name: "companycode", type: "Int", entity: "client" },
  { name: "dateadded", type: "DateTime", entity: "client" },
  { name: "dateedited", type: "DateTime", entity: "client" },
  { name: "doNotInherit", type: "Boolean", entity: "client" },
  { name: "documentemail", type: "Boolean", entity: "client" },
  { name: "documentfax", type: "Boolean", entity: "client" },
  { name: "documentmail", type: "Boolean", entity: "client" },
  { name: "email", type: "String", entity: "client" },
  { name: "fax", type: "String", entity: "client" },
  { name: "firstname", type: "String", entity: "client" },
  { name: "lastappt", type: "DateTime", entity: "client" },
  { name: "lastname", type: "String", entity: "client" },
  { name: "marketercode", type: "String", entity: "client" },
  { name: "notes", type: "String", entity: "client" },
  { name: "phone1", type: "String", entity: "client" },
  { name: "phone1ext", type: "String", entity: "client" },
  { name: "phone2", type: "String", entity: "client" },
  { name: "phone2ext", type: "String", entity: "client" },
  { name: "photoRqd", type: "Boolean", entity: "client" },
  { name: "prefix", type: "String", entity: "client" },
  { name: "priority", type: "String", entity: "client" },
  { name: "reportphone", type: "Boolean", entity: "client" },
  { name: "state", type: "String", entity: "client" },
  { name: "status", type: "String", entity: "client" },
  { name: "suffix", type: "String", entity: "client" },
  { name: "title", type: "String", entity: "client" },
  { name: "usddate1", type: "DateTime", entity: "client" },
  { name: "usddate2", type: "DateTime", entity: "client" },
  { name: "usdint1", type: "Int", entity: "client" },
  { name: "usdint2", type: "Int", entity: "client" },
  { name: "usdmoney1", type: "Float", entity: "client" },
  { name: "usdmoney2", type: "Float", entity: "client" },
  { name: "usdtext1", type: "String", entity: "client" },
  { name: "usdtext2", type: "String", entity: "client" },
  { name: "usdvarchar1", type: "String", entity: "client" },
  { name: "usdvarchar2", type: "String", entity: "client" },
  { name: "useridadded", type: "String", entity: "client" },
  { name: "useridedited", type: "String", entity: "client" },
  { name: "zip", type: "String", entity: "client" },
];

export default CLIENT_FIELDS;
