import React from "react";
import PropTypes from "prop-types";
import { Field, Control, Input, Label } from "rbx";
import StatusSelect from "../../../../../components/StatusSelect";

const ProductForm = ({ inputs, onChange }) => (
  <div>
    <Field multiline kind="group">
      <Control expanded>
        <Label>Name</Label>
        <Input
          autoComplete="new"
          name="description"
          size="small"
          value={inputs.description}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
      <Control expanded>
        <Label>Description</Label>
        <Input
          autoComplete="new"
          name="longdesc"
          size="small"
          value={inputs.longdesc}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
      <Control expanded>
        <Label>Revenue IN GL Account</Label>
        <Input
          autoComplete="new"
          name="INglacct"
          size="small"
          value={inputs.INglacct}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
      <Control expanded>
        <Label>Expense VO GL Account</Label>
        <Input
          autoComplete="new"
          name="VOglacct"
          size="small"
          value={inputs.VOglacct}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
      <Control>
        <StatusSelect
          label="Status"
          name="status"
          value={inputs.status}
          onChange={onChange}
        />
      </Control>
    </Field>
  </div>
);

ProductForm.propTypes = {
  inputs: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ProductForm;
