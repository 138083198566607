import React from "react";

import { Footer, Content } from "rbx";

import VersionTag from "../VersionTag";

const ApolloFooter = () => (
  <Footer className="apollo-footer">
    <Content size="small" textAlign="centered">
      <VersionTag />
    </Content>
  </Footer>
);

export default ApolloFooter;
