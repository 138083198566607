import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Control, Field, Label, Input, Icon, Help, Title } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useModal } from "../../context/ModalContext";

const UpdatePasswordModal = ({ onConfirm, onCancel }) => {
  const { setModalOpen } = useModal();
  const [inputs, setInputs] = useState({
    Password: "",
    ConfirmPassword: "",
  });

  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleCancel = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setModalOpen(false, "");
    if (onCancel && typeof onCancel === "function") {
      onCancel();
    }
  };

  const onChange = (name, value) => {
    setInputs((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setLoading(true);
    onConfirm(inputs.Password, inputs.ConfirmPassword);
  };

  const toggleShowPassword = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowPassword(!showPassword);
  };

  const showHelp =
    inputs.Password !== "" &&
    inputs.ConfirmPassword !== "" &&
    inputs.Password !== inputs.ConfirmPassword;

  return (
    <form id="update-password-form" onSubmit={handleSave}>
      <header className="page-head">
        <div className="page-head-start">
          <Title size={5}>Update Password</Title>
        </div>
        <div className="page-head-end">
          <Button.Group hasAddons>
            <Button
              color="success"
              role="button"
              size="small"
              style={{ zIndex: 9999 }}
              type="button"
              onClick={handleCancel}
            >
              Cancel
            </Button>

            <Button
              color="primary"
              disabled={
                !inputs.Password ||
                !inputs.ConfirmPassword ||
                inputs.Password !== inputs.ConfirmPassword ||
                loading
              }
              role="button"
              size="small"
              state={loading ? "loading" : ""}
              type="submit"
            >
              Save
            </Button>
          </Button.Group>
        </div>
      </header>
      <hr />
      <Field kind="group">
        <Control expanded size="small">
          <Label size="small">Password</Label>
          <Field kind="addons">
            <Control expanded>
              <Input
                required
                maxLength={250}
                name="Password"
                placeholder="New Password"
                size="small"
                type={showPassword ? "text" : "password"}
                value={inputs.Password}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
            </Control>
            <Control>
              <Button
                className="input"
                color="info"
                size="small"
                tabIndex="-1"
                onClick={toggleShowPassword}
              >
                <Icon>
                  <FontAwesomeIcon icon={showPassword ? "eye" : "eye-slash"} />
                </Icon>
              </Button>
            </Control>
          </Field>
          <Help color="danger">
            {showHelp ? (
              <span>Passwords don&quot;t match</span>
            ) : (
              <span>&nbsp;</span>
            )}
          </Help>
        </Control>

        <Control expanded size="small">
          <Label size="small">Confirm Password</Label>
          <Field kind="addons">
            <Control expanded>
              <Input
                required
                maxLength={250}
                name="ConfirmPassword"
                placeholder="Confirm Password"
                size="small"
                type={showPassword ? "text" : "password"}
                value={inputs.ConfirmPassword}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
            </Control>
            <Control>
              <Button
                className="input"
                color="info"
                size="small"
                tabIndex="-1"
                onClick={toggleShowPassword}
              >
                <Icon>
                  <FontAwesomeIcon icon={showPassword ? "eye" : "eye-slash"} />
                </Icon>
              </Button>
            </Control>
          </Field>
          <Help color="danger">
            {showHelp ? (
              <span>Passwords don&quot;t match</span>
            ) : (
              <span>&nbsp;</span>
            )}
          </Help>
        </Control>
      </Field>
      <hr />
    </form>
  );
};

UpdatePasswordModal.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
};
UpdatePasswordModal.defaultProps = {
  onCancel: () => null,
};
export default UpdatePasswordModal;
