import React from "react";
import PropTypes from "prop-types";
import { Field, Input, Label, Control, Title } from "rbx";
import CaseTypeSelect from "../../../../components/CaseTypeSelect";
import PhoneNumberInput from "../../../../components/PhoneNumberInput";
import UserSelect from "../../../../components/UserSelect";
import PrioritySelect from "../../../../components/PrioritySelect";

import BooleanInput from "../../../../components/BooleanInput";

const ClientDefaultForm = ({ client, onChange, disabled, onSubmit }) => (
  <form id="edit-client-form" onSubmit={onSubmit}>
    <div className="client-defaults">
      <div>
        <Title subtitle style={{ textAlign: "center" }}>
          Defaults
        </Title>
        <div className="grid-2">
          <Field>
            <UserSelect
              required
              disabled={disabled}
              label="Marketer"
              name="marketercode"
              value={client.marketercode || ""}
              where={{
                fldActive: { equals: 1 },
                OR: [
                  { usertype: { equals: "MK" } },
                  { usertype: { equals: "*" } },
                ],
              }}
              onChange={onChange}
            />
          </Field>
          <Field>
            <CaseTypeSelect
              disabled={disabled}
              label="Case Type"
              name="casetype"
              setValue={(x) => (x ? String(x) : "")}
              size="small"
              value={client.casetype || ""}
              onChange={onChange}
            />
          </Field>
        </div>
        <div className="grid-2">
          <Field>
            <UserSelect
              disabled={disabled}
              label="QA Rep"
              name="QARep"
              value={client.QARep || ""}
              where={{
                fldActive: { equals: 1 },
                OR: [
                  { usertype: { equals: "QA" } },
                  { usertype: { equals: "*" } },
                ],
              }}
              onChange={onChange}
            />
          </Field>
          <Field>
            <PrioritySelect
              showBlank
              disabled={disabled}
              label="Priority"
              name="priority"
              noValueLabel=""
              value={client.priority || ""}
              onChange={onChange}
            />
          </Field>
        </div>
        <Field>
          <Control>
            <Label>Examinee Photo Required</Label>
            <BooleanInput
              disabled={disabled}
              name="photoRqd"
              value={client.photoRqd}
              onChange={onChange}
            />
          </Control>
        </Field>
      </div>
      <div>
        <Title subtitle style={{ textAlign: "center" }}>
          Client Document Delivery
        </Title>
        <div className="client-document-delivery-checks">
          <Field className="grid-override is-three is-centered" kind="group">
            <Control>
              <Label>Email</Label>
              <BooleanInput
                disabled={disabled}
                name="documentemail"
                value={client.documentemail}
                onChange={onChange}
              />
            </Control>
            <Control>
              <Label>Fax</Label>
              <BooleanInput
                disabled={disabled}
                name="documentfax"
                value={client.documentfax}
                onChange={onChange}
              />
            </Control>
            <Control>
              <Label>Mail</Label>
              <BooleanInput
                disabled={disabled}
                name="documentmail"
                value={client.documentmail}
                onChange={onChange}
              />
            </Control>
          </Field>

          <Field className="grid-override is-three is-centered" kind="group">
            <Control>
              <Label>Verbal Report</Label>
              <BooleanInput
                disabled={disabled}
                name="reportphone"
                value={client.reportphone}
                onChange={onChange}
              />
            </Control>
            <Control>
              <Label>Certified Mail</Label>
              <BooleanInput
                disabled={disabled}
                name="CertifiedMail"
                value={client.CertifiedMail}
                onChange={onChange}
              />
            </Control>
            <Control>
              <Label>Web</Label>
              <BooleanInput
                disabled={disabled}
                name="DocumentPublish"
                value={client.DocumentPublish}
                onChange={onChange}
              />
            </Control>
          </Field>
        </div>
      </div>
      <div>
        <Title subtitle style={{ textAlign: "center" }}>
          Processor/Assistant
        </Title>
        <Field kind="group">
          <Control expanded>
            <Label>First Name</Label>
            <Input
              autoComplete="new"
              disabled={disabled}
              maxLength={30}
              name="ProcessorFirstName"
              size="small"
              value={client.ProcessorFirstName || ""}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </Control>
          <Control expanded>
            <Label>Last Name</Label>
            <Input
              autoComplete="new"
              disabled={disabled}
              maxLength={50}
              name="ProcessorLastName"
              size="small"
              value={client.ProcessorLastName || ""}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </Control>
        </Field>
        <Field className="grid-override" kind="group">
          <Control expanded>
            <Label>Phone</Label>
            <PhoneNumberInput
              disabled={disabled}
              name="ProcessorPhone"
              value={client.ProcessorPhone || ""}
              onChange={onChange}
            />
          </Control>
          <Control expanded>
            <Label>Ext</Label>
            <Input
              autoComplete="new"
              disabled={disabled}
              maxLength={10}
              name="ProcessorPhoneExt"
              size="small"
              type="text"
              value={client.ProcessorPhoneExt || ""}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </Control>
        </Field>
        <Field className="grid-override" kind="group">
          <Control expanded>
            <Label>Fax</Label>
            <PhoneNumberInput
              disabled={disabled}
              name="ProcessorFax"
              value={client.ProcessorFax || ""}
              onChange={onChange}
            />
          </Control>
          <Control>
            <Label>Email</Label>
            <Input
              autoComplete="new"
              disabled={disabled}
              maxLength={200}
              name="ProcessorEmail"
              size="small"
              type="email"
              value={client.ProcessorEmail || ""}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </Control>
        </Field>
      </div>
    </div>
  </form>
);

ClientDefaultForm.propTypes = {
  client: PropTypes.object,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  onSubmit: PropTypes.func,
};

ClientDefaultForm.defaultProps = {
  client: {},
  onChange: (e) => e,
  disabled: false,
  onSubmit: (e) => e,
};

export default ClientDefaultForm;
