import React from "react";
import PropTypes from "prop-types";
import { useQuery, useMutation } from "@apollo/client";
import { Field, Control, Button, Help, Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import {
  FIND_FIRST_CLIENT_QUERY,
  DELETE_COMPANY_MUTATION,
  FIND_FIRST_CASE_ITEM_QUERY,
} from "../../../../graphql";

import { useModal } from "../../../../context";

import Confirmation from "../../../../components/Confirmation";

const CompanyDeleteButton = ({ companycode }) => {
  const { setModalOpen } = useModal();
  const history = useHistory();
  const [deleteCompany] = useMutation(DELETE_COMPANY_MUTATION);
  const { data: FindFirstClientData } = useQuery(FIND_FIRST_CLIENT_QUERY, {
    variables: {
      where: { companycode: { equals: parseInt(companycode, 10) } },
    },
    fetchPolicy: "cache-and-network",
  });
  const { data: FindFirstCaseItemData } = useQuery(FIND_FIRST_CASE_ITEM_QUERY, {
    variables: {
      where: { IMECompany: { equals: companycode } },
    },
    fetchPolicy: "cache-and-network",
  });

  const isDisabled =
    !FindFirstClientData ||
    !FindFirstCaseItemData ||
    FindFirstClientData?.findFirstClient ||
    FindFirstCaseItemData?.findFirstCaseItem;

  const handleDeleteCompany = () => {
    const performDelete = async () => {
      try {
        await deleteCompany({
          variables: {
            where: {
              companycode: parseInt(companycode, 10),
            },
          },
        });
        toast.success(`Company deleted successfully.`);
        setModalOpen(false, "");
        history.push(`/companies`);
      } catch (err) {
        toast.error(`Error deleting company.`);
      }
    };
    setModalOpen(
      true,
      <Confirmation
        message="Are you sure you want to delete this company?"
        onConfirm={performDelete}
      />,
    );
  };
  return (
    <div>
      <Field>
        <Control>
          <Button
            color="danger"
            disabled={isDisabled}
            size="small"
            onClick={handleDeleteCompany}
          >
            <Icon>
              <FontAwesomeIcon icon="times" />
            </Icon>
            <span>Delete</span>
          </Button>
          {FindFirstClientData?.findFirstClient && (
            <Help>
              This company has clients associated with it and cannot be deleted.
            </Help>
          )}
          {FindFirstCaseItemData?.findFirstCaseItem && (
            <Help>
              This company has cases associated with it and cannot be deleted.
            </Help>
          )}
        </Control>
      </Field>
    </div>
  );
};

CompanyDeleteButton.propTypes = {
  companycode: PropTypes.string.isRequired,
};

export default CompanyDeleteButton;
