import React from "react";
import PropTypes from "prop-types";
import { Field } from "rbx";
import { isValid } from "date-fns";

import DateInput from "../../../../components/DateInput";
import TimeInput from "../../../../components/TimeInput";

import DrsDoNotUseDoctorSelect from "../../../../components/DrsDoNotUseDoctorSelect";
import DoctorLocationSelect from "../../../../components/DoctorLocationSelect";
import DoctorSpecialtySelect from "../../../../components/DoctorSpecialtySelect";

const ScheduleCaseForm = ({
  inputs,
  onChange,
  disabled,
  disableDoctorSelect,
  doctorLabel,
  casenbr,
}) => (
  <div className="schedule-case-form">
    <Field style={{ gridColumn: "1 / 4" }}>
      <DrsDoNotUseDoctorSelect
        required
        showOnlyActives
        casenbr={casenbr}
        disabled={disableDoctorSelect || disabled}
        label={doctorLabel}
        name="doctorcode"
        value={inputs.doctorcode}
        onChange={onChange}
      />
    </Field>
    <Field style={{ gridColumn: "4 / 7" }}>
      <DoctorLocationSelect
        required
        showOnlyActives
        disabled={disableDoctorSelect || disabled}
        doctorcode={inputs.doctorcode}
        label={`${doctorLabel} Location`}
        name="locationcode"
        value={inputs.locationcode}
        onChange={onChange}
      />
    </Field>
    <Field style={{ gridColumn: "1 / 3" }}>
      <DoctorSpecialtySelect
        required
        disabled={disabled}
        doctorcode={inputs.doctorcode}
        label={`${doctorLabel} Specialty`}
        name="doctorspecialty"
        value={inputs.doctorspecialty}
        onChange={onChange}
      />
    </Field>
    <Field style={{ gridColumn: "3 / 5" }}>
      <DateInput
        required
        disabled={disabled}
        getValue={(x) => (x ? x.substr(0, 10) : "")}
        label="Appt. Date"
        name="ApptDate"
        setValue={(x) => {
          const date = new Date(x.split("-").map((y) => parseInt(y, 10)));
          if (x && isValid(date)) {
            return date.toISOString();
          }
          return "";
        }}
        size="small"
        value={inputs.ApptDate}
        onChange={onChange}
      />
    </Field>
    <Field style={{ gridColumn: "5 / 7" }}>
      <TimeInput
        required
        disabled={disabled}
        getValue={(x) => x?.substr(11, 5) || ""}
        label="Appt. Time"
        name="Appttime"
        setValue={(x) => {
          if (x) {
            const [hr, min] = x.split(":").map((y) => parseInt(y, 10));
            const date = inputs?.ApptDate
              ? new Date(
                  inputs?.ApptDate.split("-").map((y) => parseInt(y, 10))
                )
              : new Date();
            date.setUTCHours(hr, min, 0);
            if (isValid(date)) {
              return date.toISOString();
            }
          }
          return "";
        }}
        size="small"
        value={inputs.Appttime}
        onChange={onChange}
      />
    </Field>
    {/* <Field style={{ gridColumn: "1 / 5" }}>
      <Control>
        <Label>Scheduling Notes</Label>
        <Textarea
          name="schedulenotes"
          rows="3"
          size="small"
          value={inputs.schedulenotes}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
    </Field> */}
    {/* <pre style={{ gridColumn: "1 / 3" }}>
      <code>{JSON.stringify(inputs, null, 2)}</code>
    </pre> */}
  </div>
);

ScheduleCaseForm.propTypes = {
  casenbr: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  inputs: PropTypes.object,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  doctorLabel: PropTypes.string,
  disableDoctorSelect: PropTypes.bool,
};
ScheduleCaseForm.defaultProps = {
  inputs: {},
  casenbr: "",
  onChange: () => null,
  disabled: true,
  disableDoctorSelect: true,
  doctorLabel: "Doctor",
};
export default ScheduleCaseForm;
