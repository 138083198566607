/* eslint-disable react/prop-types */
import React from "react";
import { format } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Generic, Icon } from "rbx";

const COLUMNS = [
  {
    Header: "Note Text",
    accessor: "NoteText",
    id: "NoteText",
    Cell: ({ cell }) => <pre>{cell?.value || ""}</pre>,
    maxWidth: "60%",
    disableSortBy: true,
  },
  {
    Header: "Note Type",
    accessor: "Type.NoteType",
    id: "Type:NoteType",
    maxWidth: "20%",
  },
  {
    Header: "Created Date",
    accessor: "CreatedDate",
    id: "CreatedDate",
    Cell: ({ cell }) =>
      cell?.value ? format(new Date(cell.value), "MM/dd/yyyy hh:mm") : "--",
    maxWidth: "10%",
  },
  {
    Header: "Created By",
    accessor: "CreatedBy",
    id: "CreatedBy",
    maxWidth: "10%",
  },
];

export const generateColumns = (handleDelete) => [
  {
    Header: "",
    id: "delete",
    Cell: (cell) => (
      <Generic
        style={{ textAlign: "center" }}
        type="button"
        onClick={(e) => {
          e.stopPropagation();
          handleDelete(cell.row.original);
        }}
      >
        <Icon color="danger" size="small">
          <FontAwesomeIcon icon="times" />
        </Icon>
      </Generic>
    ),
  },
  ...COLUMNS,
];

export default COLUMNS;
