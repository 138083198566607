/* eslint-disable import/prefer-default-export */
import { gql } from "@apollo/client";

export const CREATE_USER_MUTATION = gql`
  mutation createUser($data: UserCreateInput!) {
    createUser(data: $data) {
      userid
      email
      lastname
      firstname
      usertype
      dateadded
      useridadded
      dateedited
      useridedited
    }
  }
`;

export const UPDATE_USER_MUTATION = gql`
  mutation updateUser(
    $data: UserUpdateInput!
    $where: UserWhereUniqueInput!
  ) {
    updateUser(data: $data, where: $where) {
      userid
      email
      lastname
      firstname
      usertype
      dateadded
      useridadded
      dateedited
      useridedited
    }
  }
`;

export const DELETE_USER_MUTATION = gql`
  mutation deleteUser($where: UserWhereUniqueInput!) {
    deleteUser(where: $where) {
      userid
    }
  }
`;

export const UPDATE_PASSWORD_MUTATION = gql`
  mutation UPDATE_PASSWORD_MUTATION(
    $data: UpdatePasswordInput!
    $where: UserWhereUniqueInput!
  ) {
    updatePassword(data: $data, where: $where)
  }
`;
