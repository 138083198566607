import React from "react";
import PropTypes from "prop-types";
import { Control, Field, Label, Textarea } from "rbx";
import { AccountingThirdPartyForm } from "./components";

const Accounting = ({ inputs, onChange, disabled, onSubmit }) => (
  <form id="edit-case-form" onSubmit={onSubmit}>
    <div className="case-accounting-page">
      <Field>
        <AccountingThirdPartyForm
          disabled={disabled}
          inputs={inputs}
          onChange={onChange}
        />
      </Field>
      <Field>
        <Control>
          <Label>Billing Note</Label>
          <Textarea
            disabled={disabled}
            name="BillingNote"
            size="small"
            value={inputs.BillingNote}
            onChange={(e) => onChange(e.target.name, e.target.value)}
          />
        </Control>
      </Field>
    </div>
  </form>
);

Accounting.propTypes = {
  inputs: PropTypes.object,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
};

Accounting.defaultProps = {
  inputs: {},
  onChange: (e) => e,
  disabled: false,
};

export default Accounting;
