import { gql } from "@apollo/client";

export const LIST_RATS_CLIENT_OFFICE = gql`
  query Clientoffices(
    $where: ClientofficeWhereInput
    $orderBy: [ClientofficeOrderByInput!]
  ) {
    clientoffices(where: $where, orderBy: $orderBy) {
      COid
      COofficeName
    }
  }
`;

export const SINGLE_RATS_CLIENT_OFFICE = gql`
  query Clientoffice($where: ClientofficeWhereUniqueInput!) {
    clientoffice(where: $where) {
      COofficeName
      COaddress1
      COaddress2
      COcity
      COstate
      COzip
      COphone1
      COclientMasterID
    }
  }
`;
