import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getFilterState, saveFilterState } from "../../../../utils";
import { useModal } from "../../../../context";
import { LIST_APOLLO_NOTES_QUERY } from "../../../../graphql";
import DataTable from "../../../../components/DataTable";
import { COLUMNS_APOLLO_NOTES } from "../../columns";
import ApolloNoteModal from "../../components/ApolloNoteModal";
import ApolloNoteFilters from "../../components/ApolloNoteFilters";

const ApolloNotes = ({ casenbr }) => {
  const { setModalOpen } = useModal();
  const [showFilters, setShowFilters] = useState(false);
  const [where, setWhere] = useState(
    getFilterState("APOLLO_NOTES_FILTERS", {})
  );

  const handleChange = (name, value) => {
    if (!value) {
      setWhere((prev) =>
        Object.keys(prev).reduce((acc, curr) => {
          if (curr !== name) acc[curr] = prev[curr];
          return acc;
        }, {})
      );
    } else {
      setWhere((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleReset = () => {
    saveFilterState("APOLLO_NOTES_FILTERS", {});
    setWhere({});
    if (document.getElementById("start-date")) {
      document.getElementById("start-date").value = "";
    }
    if (document.getElementById("end-date")) {
      document.getElementById("end-date").value = "";
    }
  };

  return (
    <div>
      <header className="page-head">
        <div className="page-head-start">
          <Button.Group>
            <Button
              color="primary"
              size="small"
              onClick={() =>
                setModalOpen(
                  true,
                  <ApolloNoteModal
                    casenbr={parseInt(casenbr, 10)}
                    onComplete={() => setModalOpen(false, "")}
                  />
                )
              }
            >
              <Icon>
                <FontAwesomeIcon icon="plus" />
              </Icon>
            </Button>
            <Button
              color="warning"
              size="small"
              onClick={() => setShowFilters((prev) => !prev)}
            >
              <Icon>
                <FontAwesomeIcon icon="filter" />
              </Icon>
            </Button>
          </Button.Group>
        </div>
      </header>
      {showFilters && (
        <ApolloNoteFilters
          filters={where}
          onChange={handleChange}
          onReset={handleReset}
        />
      )}
      <DataTable
        aggregateKey="NoteID"
        aggregateName="aggregateNotes"
        columns={COLUMNS_APOLLO_NOTES}
        fetchPolicy="cache-and-network"
        name="findManyNotes"
        orderBy={[{ id: "CreatedDate", desc: true }]}
        query={LIST_APOLLO_NOTES_QUERY}
        where={{
          ...where,
          Service: {
            is: {
              WorkID: {
                equals: parseInt(casenbr, 10),
              },
            },
          },
        }}
        onRowClick={(row) =>
          setModalOpen(
            true,
            <ApolloNoteModal
              casenbr={parseInt(casenbr, 10)}
              inputs={row}
              onComplete={() => setModalOpen(false, "")}
            />
          )
        }
      />
    </div>
  );
};

ApolloNotes.propTypes = {
  casenbr: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ApolloNotes.defaultProps = {
  casenbr: "",
};

export default ApolloNotes;
