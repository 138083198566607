/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Header = styled.h1`
    display: flex;
    position: fixed;
    left: 50%;
    transform: translate(-50%, -50%);
    flex-direction: column;
    align-items: center;
    justify-content: center;
    justify-self: center;
    text-align: center;
    border: 0.12rem solid #2a4734;
    background-color: #cde0d3;
    border-radius: 0.3rem;
    letter-spacing: -0.02rem;
    font-size: 0.95rem;
    width: 60rem;
    max-width: 100rem;
    margin: 0 auto;
    margin-bottom: 1rem;
    padding-top: 2rem;
    line-break: strict;
    gap: 0;
    padding: 0.4rem 4rem;
    z-index: 100;
    opacity: 90%;
`

const InformationHeader = ({ mode }) => {
  const isRecordsMode = /^records$/gi.test(mode);

  return (
    <Header id="information-header">
      <div>
        <span style={{ fontWeight: 'bold' }}>NOTE: </span>
        Grayed-out input fields indicate that they don't apply to your referral type. No action is required.
      </div>
      <span><em>Fields marked red are required{isRecordsMode ? " unless saving. However, Records must include at least one chosen provider whether saving or submitting." : "."}</em></span>
      {/* {isRecordsMode &&
        <span>Records MUST have at least one provider with selection when saving or submitting.</span>
      } */}
    </Header>
  )
}

InformationHeader.propTypes = {
  mode: PropTypes.string
}

InformationHeader.defaultProps = {
  mode: ""
}

export default InformationHeader;
