import { gql } from "@apollo/client";

export const CREATE_STANDARD_CASE_HISTORY_NOTE_MUTATION = gql`
  mutation CREATE_STANDARD_CASE_HISTORY_NOTE_MUTATION(
    $data: StandardCaseHistoryNotesCreateInput!
  ) {
    createStandardCaseHistoryNotes(data: $data) {
      NoteID
    }
  }
`;

export const UPDATE_STANDARD_CASE_HISTORY_NOTE_MUTATION = gql`
  mutation UPDATE_STANDARD_CASE_HISTORY_NOTE_MUTATION(
    $data: StandardCaseHistoryNotesUpdateInput!
    $where: StandardCaseHistoryNotesWhereUniqueInput!
  ) {
    updateStandardCaseHistoryNotes(data: $data, where: $where) {
      NoteID
    }
  }
`;

export const DELETE_STANDARD_CASE_HISTORY_NOTE_MUTATION = gql`
  mutation DELETE_STANDARD_CASE_HISTORY_NOTE_MUTATION(
    $where: StandardCaseHistoryNotesWhereUniqueInput!
  ) {
    deleteStandardCaseHistoryNotes(where: $where) {
      NoteID
    }
  }
`;
