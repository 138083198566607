import React from "react";
import PropTypes from "prop-types";

import { Field, Control, Label, Input } from "rbx";

import RatsTypeReferenceSelect from "../../../../components/RatsTypeReferenceSelect";
import StateSelect from "../../../../components/StateSelect";

const MedicalProviderEntryForm = ({
  inputs,
  onChange,
  mode,
  label,
  disabled,
}) => (
  <Field>
    <Field style={{ marginBottom: "1.25rem" }}>
      <Field id="medical-provider-entry-form" kind="group">
        <Control expanded>
          <Label>First Name</Label>
          <Input
            disabled={disabled}
            name="MPnameFirst"
            placeholder="First Name"
            size="small"
            value={inputs.MPnameFirst}
            onChange={(e) => onChange(e.target.name, e.target.value)}
          />
        </Control>
        <Control expanded>
          <Label>Last Name / Facility Name</Label>
          <Input
            disabled={disabled}
            name="MPnameLast"
            placeholder="Last Name / Facility Name"
            required={mode === "add"}
            size="small"
            value={inputs.MPnameLast}
            onChange={(e) => onChange(e.target.name, e.target.value)}
          />
        </Control>
        <Control expanded>
          <Label>Group Name</Label>
          <Input
            disabled={disabled}
            name="MPgroupName"
            placeholder="Group Name"
            size="small"
            value={inputs.MPgroupName}
            onChange={(e) => onChange(e.target.name, e.target.value)}
          />
        </Control>
        <Control expanded>
          <Label>Phone</Label>
          <Input
            disabled={disabled}
            name="MPphone1"
            placeholder="Phone"
            size="small"
            value={inputs.MPphone1}
            onChange={(e) => onChange(e.target.name, e.target.value)}
          />
        </Control>
        <Control expanded>
          <Label>Fax</Label>
          <Input
            disabled={disabled}
            name="MPfax"
            placeholder="Fax"
            size="small"
            value={inputs.MPfax}
            onChange={(e) => onChange(e.target.name, e.target.value)}
          />
        </Control>
      </Field>
    </Field>

    <Field>
      <Field id="medical-provider-location-info-form" kind="group">
        <Control expanded>
          <Label>Address 1</Label>
          <Input
            disabled={disabled}
            name="MPaddress1"
            placeholder="Address 1"
            required={mode === "add"}
            size="small"
            value={inputs.MPaddress1}
            onChange={(e) => onChange(e.target.name, e.target.value)}
          />
        </Control>
        <Control expanded>
          <Label>Address 2</Label>
          <Input
            disabled={disabled}
            name="MPaddress2"
            placeholder="Address 2"
            size="small"
            value={inputs.MPaddress2}
            onChange={(e) => onChange(e.target.name, e.target.value)}
          />
        </Control>
        <Control expanded>
          <Label>City</Label>
          <Input
            disabled={disabled}
            name="MPcity"
            placeholder="City"
            required={mode === "add"}
            size="small"
            value={inputs.MPcity}
            onChange={(e) => onChange(e.target.name, e.target.value)}
          />
        </Control>
        <Control expanded>
          <StateSelect
            disabled={disabled}
            id="state"
            label="State"
            name="MPstate"
            required={mode === "add"}
            size="small"
            value={inputs.MPstate}
            onChange={onChange}
          />
        </Control>
        {/* <Control expanded>
          <Label>State</Label>
          <Input
            disabled={disabled}
            name="MPstate"
            placeholder="State"
            size="small"
            value={inputs.MPstate}
            onChange={(e) => onChange(e.target.name, e.target.value)}
          />
        </Control> */}
        <Control expanded>
          <Label>Zip</Label>
          <Input
            disabled={disabled}
            name="MPzip"
            placeholder="Zip"
            size="small"
            value={inputs.MPzip}
            onChange={(e) => onChange(e.target.name, e.target.value)}
          />
        </Control>
        <Control>
          <Label>Email</Label>
          <Input
            disabled={disabled}
            name="MPEmailAddress"
            placeholder="Email"
            size="small"
            value={inputs.MPEmailAddress}
            onChange={(e) => onChange(e.target.name, e.target.value)}
          />
        </Control>
        {/* </Field>
      <Field kind="group"> */}
      </Field>
    </Field>

    {mode === "add" && (
      <Field>
        <RatsTypeReferenceSelect
          disabled={disabled}
          label="Provider Type"
          name="MpproviderTypeID"
          value={inputs.MpproviderTypeID}
          where={{
            TRentityAbbrev: {
              equals: "PT",
            },
          }}
          onChange={(name, value) => onChange(name, value)}
        />
      </Field>
    )}
  </Field>
);

MedicalProviderEntryForm.propTypes = {
  inputs: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  mode: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
};

MedicalProviderEntryForm.defaultProps = {
  mode: "search",
  label: "",
  disabled: false,
};

export default MedicalProviderEntryForm;
