import React from "react";
import PropTypes from "prop-types";

import { Link } from "react-router-dom";
import { Button, Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CaseStatusTag = ({ casenbr }) => (
  <Button
    as={Link}
    color="warning"
    size="small"
    to={`/cases/${casenbr}/profile`}
  >
    <Icon>
      <FontAwesomeIcon icon="link" />
    </Icon>
    <span>{["Master Case:", casenbr].filter(Boolean).join(" ")}</span>
  </Button>
);

CaseStatusTag.propTypes = {
  casenbr: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default CaseStatusTag;
