/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";

import { useTable, useSortBy } from "react-table";

import { Table as RbxTable, Title } from "rbx";

import "./DataTable.scss";

const Table = ({
  columns,
  data,
  onRowClick,
  initialSortBy,
  onChangeSort,
  onCellClick,
  displayEmptyMessage,
  getRowProps,
  manualSortBy,
}) => {
  const tableState = {
    pageIndex: 0,
    sortBy: initialSortBy,
  };

  const sortTypes = useMemo(
    () => ({
      string: (rowA, rowB, columnId, desc) => {
        const aVal = rowA.original[columnId];
        const bVal = rowB.original[columnId];
        return aVal.localeCompare(bVal);
      },
      number: (rowA, rowB, columnId, desc) => {
        const aVal = rowA.original[columnId];
        const bVal = rowB.original[columnId];
        if (aVal > bVal) return 1;
        if (bVal > aVal) return -1;
        return 0;
      },
    }),
    []
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { sortBy },
  } = useTable(
    {
      columns,
      data,
      initialState: { ...tableState },
      sortTypes,
      manualSortBy,
    },
    useSortBy
  );

  const renderTableHeaderSort = (column) => {
    if (column.isSorted) {
      return column.isSortedDesc ? " ▼" : " ▲";
    }
    return "";
  };

  useEffect(() => {
    onChangeSort(sortBy);
  }, [onChangeSort, sortBy]);

  if (!data || (Array.isArray(data) && data.length === 0)) {
    return (
      displayEmptyMessage && (
        <Title subtitle style={{ color: "gray" }}>
          Nothing here...
        </Title>
      )
    );
  }

  const handleRowClick = (...args) => {
    const sel = window.getSelection().toString().length;
    if (sel) return;
    onRowClick(...args);
  };
  const handleCellClick = (...args) => {
    const sel = window.getSelection().toString().length;
    if (sel) return;
    onCellClick(...args);
  };

  return (
    <div className="data-table-container">
      <div className="table-container">
        <RbxTable {...getTableProps()} bordered fullwidth hoverable narrow>
          <RbxTable.Head>
            {headerGroups.map((headerGroup) => (
              <RbxTable.Row {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <RbxTable.Heading
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className={
                      ["Rush"].includes(column.render("Header"))
                        ? "right-align"
                        : ""
                    }
                    style={
                      typeof column.maxWidth === "string"
                        ? { width: column.maxWidth }
                        : { maxWidth: column.maxWidth || 400 }
                    }
                  >
                    {column.render("Header")}
                    <span>{renderTableHeaderSort(column)}</span>
                  </RbxTable.Heading>
                ))}
              </RbxTable.Row>
            ))}
          </RbxTable.Head>
          <RbxTable.Body {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <RbxTable.Row
                  {...row.getRowProps(getRowProps(row))}
                  onClick={(e) => handleRowClick(row.original, e)}
                >
                  {row.cells.map((cell) => (
                    <RbxTable.Cell
                      {...cell.getCellProps()}
                      onClick={(e) => handleCellClick(cell, e)}
                    >
                      {cell.render("Cell")}
                    </RbxTable.Cell>
                  ))}
                </RbxTable.Row>
              );
            })}
          </RbxTable.Body>
        </RbxTable>
      </div>
    </div>
  );
};

Table.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  onRowClick: PropTypes.func,
  initialSortBy: PropTypes.array,
  onChangeSort: PropTypes.func,
  onCellClick: PropTypes.func,
  displayEmptyMessage: PropTypes.bool,
  getRowProps: PropTypes.func,
  manualSortBy: PropTypes.bool,
};

Table.defaultProps = {
  columns: [],
  data: [],
  initialSortBy: [],
  onRowClick: () => null,
  onChangeSort: () => null,
  onCellClick: () => null,
  displayEmptyMessage: true,
  getRowProps: () => {},
  manualSortBy: false,
};
export default Table;
