/* eslint-disable react/no-unescaped-entities */
import React from "react";
import PropTypes from "prop-types";
import { Control, Field, Box, Radio, Label } from "rbx";

import BooleanInput from "../../../../components/BooleanInput";
import ReferralStyledSubtitle from "../ReferralStyledSubtitle";

const ClaimQuestionsForExaminingPhysician = ({ handleChange, inputs }) => (
  <Box className="col-span">
    <ReferralStyledSubtitle>
      Claim Questions For Examining Physician
    </ReferralStyledSubtitle>
    <div className="checkbox-wrapper">
      <Field>
        <Control>
          <BooleanInput
            label="I will be submitting my own cover letter for this referral"
            name="IWillBeSubmittingMyOwnCoverLetterForThisReferral"
            value={inputs.IWillBeSubmittingMyOwnCoverLetterForThisReferral}
            onChange={handleChange}
          />
        </Control>
        <Control>
          <BooleanInput
            label="History, Diagnosis, Prognosis"
            name="HistoryDiagnosisPrognosis"
            value={inputs.HistoryDiagnosisPrognosis}
            onChange={handleChange}
          />
        </Control>
        <Control>
          <BooleanInput
            label="Treatment Reasonable &amp; Necessary"
            name="TreatmentReasonableAndNecessary"
            value={inputs.TreatmentReasonableAndNecessary}
            onChange={handleChange}
          />
        </Control>
        <Control>
          <BooleanInput
            label="Present Work Status"
            name="PresentWorkStatus"
            value={inputs.PresentWorkStatus}
            onChange={handleChange}
          />
        </Control>
        <Control>
          <BooleanInput
            label="Physical Capacity Form / FCE"
            name="PhysicalCapacityFormFCE"
            value={inputs.PhysicalCapacityFormFCE}
            onChange={handleChange}
          />
        </Control>
        <Control>
          <BooleanInput
            label="Work Restrictions (Duration of Restrictions) / Light or Full Duty"
            name="WorkRestrictions"
            value={inputs.WorkRestrictions}
            onChange={handleChange}
          />
        </Control>
        <Control>
          <BooleanInput
            label="Length of Disability"
            name="LengthOfDisability"
            value={inputs.LengthOfDisability}
            onChange={handleChange}
          />
        </Control>
        <Control>
          <BooleanInput
            label="Causal Relationship"
            name="CausalRelationship"
            value={inputs.CausalRelationship}
            onChange={handleChange}
          />
        </Control>
        <Control>
          <BooleanInput
            label="Degree of Disability"
            name="DegreeOfDisability"
            value={inputs.DegreeOfDisability}
            onChange={handleChange}
          />
        </Control>
        <Control>
          <BooleanInput
            label="Permanent and Stationary Status (California)"
            name="PermanentAndStationaryStatus"
            value={inputs.PermanentAndStationaryStatus}
            onChange={handleChange}
          />
        </Control>
        <Control>
          <BooleanInput
            label="Apportionment"
            name="Apportionment"
            value={inputs.Apportionment}
            onChange={handleChange}
          />
        </Control>
        <Control>
          <BooleanInput
            label="M&S Statement"
            name="MAndSStatement"
            value={inputs.MAndSStatement}
            onChange={handleChange}
          />
        </Control>
        <Control>
          <BooleanInput
            label="Pre-existing Conditions"
            name="PreexistingConditions"
            value={inputs.PreexistingConditions}
            onChange={handleChange}
          />
        </Control>
        <Control>
          <BooleanInput
            label="Radiologist to Read"
            name="RadiologistToRead"
            value={inputs.RadiologistToRead}
            onChange={handleChange}
          />
        </Control>
        <Control>
          <BooleanInput
            label="End Result"
            name="EndResult"
            value={inputs.EndResult}
            onChange={handleChange}
          />
        </Control>
        <Control>
          <BooleanInput
            label="Has the examinee reached MMI? (Requires physicals affidavit of
            Recovery)"
            name="HasTheExamineeReachedMMI"
            value={inputs.HasTheExamineeReachedMMI}
            onChange={handleChange}
          />
        </Control>
        <Control>
          <BooleanInput
            label="Maximum Medical Improvement"
            name="MaximumMedicalImprovement"
            value={inputs.MaximumMedicalImprovement}
            onChange={handleChange}
          />
        </Control>
        <Control>
          <BooleanInput
            label="Permanent Scarring"
            name="PermanentScarring"
            value={inputs.PermanentScarring}
            onChange={handleChange}
          />
        </Control>
        <Control>
          <BooleanInput
            label="AMA Impairment Rating"
            name="AMAImpairmentRating"
            value={inputs.AMAImpairmentRating}
            onChange={handleChange}
          />
        </Control>
        {inputs.AMAImpairmentRating && (
          <Field kind="group">
            <Control>
              <Label>Edition</Label>
              <Label>
                <Radio
                  defaultChecked
                  name="AMAImpairmentRatingLevel"
                  value="3"
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                />
                &nbsp;
                <span className="radioItem">3</span>
              </Label>
              <Label>
                <Radio
                  name="AMAImpairmentRatingLevel"
                  value="4"
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                />
                &nbsp;
                <span className="radioItem">4</span>
              </Label>
              <Label>
                <Radio
                  name="AMAImpairmentRatingLevel"
                  value="5"
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                />
                &nbsp;
                <span className="radioItem">5</span>
              </Label>
              <Label>
                <Radio
                  name="AMAImpairmentRatingLevel"
                  value="6"
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                />
                &nbsp;
                <span className="radioItem">6</span>
              </Label>
            </Control>
          </Field>
        )}
        <Control>
          <BooleanInput
            label="Scheduled Loss Of Use"
            name="ScheduledLossOfUse"
            value={inputs.ScheduledLossOfUse}
            onChange={handleChange}
          />
        </Control>
        <Control>
          <BooleanInput
            label="Need For Treatment"
            name="NeedForTreatment"
            value={inputs.NeedForTreatment}
            onChange={handleChange}
          />
        </Control>
        <Control>
          <BooleanInput
            label="Permanency (New York Worker's Comp)"
            name="Permanency"
            value={inputs.Permanency}
            onChange={handleChange}
          />
        </Control>
        <Control>
          <BooleanInput
            label="Need For Surgery"
            name="NeedForSurgery"
            value={inputs.NeedForSurgery}
            onChange={handleChange}
          />
        </Control>
        <Control>
          <BooleanInput
            label="Transportation"
            name="Transportation"
            value={inputs.Transportation}
            onChange={handleChange}
          />
        </Control>
        <Control>
          <BooleanInput
            label="Attendant Care"
            name="AttendantCare"
            value={inputs.AttendantCare}
            onChange={handleChange}
          />
        </Control>
        <Control>
          <BooleanInput
            label="Essential Services"
            name="EssentialServices"
            value={inputs.EssentialServices}
            onChange={handleChange}
          />
        </Control>
        <Control>
          <BooleanInput
            label="Durable Medical Equipment"
            name="DurableMedicalEquipment"
            value={inputs.DurableMedicalEquipment}
            onChange={handleChange}
          />
        </Control>
        <Control>
          <BooleanInput
            label="Prescriptions"
            name="Prescriptions"
            value={inputs.Prescriptions}
            onChange={handleChange}
          />
        </Control>
      </Field>
    </div>
  </Box>
);

ClaimQuestionsForExaminingPhysician.propTypes = {
  handleChange: PropTypes.func.isRequired,
  inputs: PropTypes.object.isRequired,
};

export default ClaimQuestionsForExaminingPhysician;
