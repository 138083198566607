import { gql } from "@apollo/client";

export const ALL_COURT_QUERY = gql`
query ALL_COURT_QUERY($where: CourtWhereInput, $orderBy: [CourtOrderByInput!], $take: Int, $skip: Int) {
  courts(orderBy: $orderBy, take: $take, skip: $skip, where: $where) {
    CourtID
    CourtCounty
    CourtType
    CourtAddress
    CourtAddress2
    CourtCity
    CourtState
    CourtZip
    CourtclerkLname
    CourtclerkFname
    CourtclerkTitle
  }
  aggregateCourt(where: $where) {
    count {
      CourtID
    }
  }
}
`;

export const SINGLE_COURT_QUERY = gql`
query SINGLE_COURT_QUERY($where: CourtWhereUniqueInput!) {
  court(where: $where) {
    CourtID
    CourtCounty
    CourtType
    CourtAddress
    CourtAddress2
    CourtCity
    CourtState
    CourtZip
    CourtclerkLname
    CourtclerkFname
    CourtclerkTitle
  }
}
`;
