import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { Field, Control, Input } from "rbx";

const EditableCell = ({ name, value: initialValue, type, onChange }) => {
  const [value, setValue] = useState("");
  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handleBlur = () => {
    onChange(name, value);
  };
  return (
    <Field>
      <Control>
        <Input
          autoComplete="new"
          name={name}
          size="small"
          type={type}
          value={value}
          onBlur={handleBlur}
          onChange={handleChange}
        />
      </Control>
    </Field>
  );
};

EditableCell.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func,
  type: PropTypes.string,
};

EditableCell.defaultProps = {
  value: 0,
  onChange: () => null,
  type: "text",
};

export default EditableCell;
