import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import { Title, Button } from "rbx";
import { useMutation, useApolloClient } from "@apollo/client";
import { useCaseDocumentPath } from "../../../../hooks";
import {
  CREATE_CASE_DOCUMENT_MUTATION,
  LIST_CASE_DOCUMENT_QUERY,
  ALL_CASE_DOCUMENTS_QUERY,
} from "../../../../graphql";
import { customToast as toast, getDateEST } from "../../../../utils";
import { useAuth } from "../../../../context/AuthContext";
import Loader from "../../../../components/Loader";

import CaseAttachExternalReportForm from "../CaseAttachExternalReportForm";

class CaseItemError extends Error {}

const INITIAL_STATE = {
  type: "Report",
  description: "",
  document: "external",
  PublishOnWeb: false,
  sfilename: "",
  reporttype: "Report",
  files: [],
};

const CaseAttachExternalReportModal = ({ onComplete, casenbr }) => {
  const { state: authState } = useAuth();
  const [inputs, setInputs] = useState(INITIAL_STATE);
  const [loading, setLoading] = useState(false);
  const client = useApolloClient();

  const [createCaseDocument] = useMutation(CREATE_CASE_DOCUMENT_MUTATION);

  const getCaseVirtualDocumentPath = useCaseDocumentPath(casenbr);

  const handleChange = (name, value, id) => {
    const names = name.split(".");
    if (names.length === 1) {
      setInputs((prev) => ({
        ...prev,
        [name]: value,
      }));
    } else {
      setInputs((prev) => ({
        ...prev,
        files: prev.files.map((file) =>
          file.id === id ? { ...file, [names[1]]: value } : file
        ),
      }));
    }
  };

  const uploadPath = useMemo(() => getCaseVirtualDocumentPath("", false), [
    getCaseVirtualDocumentPath,
  ]);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);

      const { data: CaseDocumentsData } = await client.query({
        query: ALL_CASE_DOCUMENTS_QUERY,
        variables: {
          where: {
            casenbr: {
              equals: parseInt(casenbr, 10),
            },
            OR: inputs.files.map(({ file }) => ({
              sfilename: { equals: file },
            })),
          },
        },
        fetchPolicy: "network-only",
      });

      const duplicatedDocuments = CaseDocumentsData?.caseDocuments?.map(
        (caseDocument) => caseDocument.sfilename
      );

      if (Array.isArray(duplicatedDocuments) && duplicatedDocuments.length) {
        const formatter = new Intl.ListFormat("en", {
          style: "long",
          type: "conjunction",
        });

        throw new CaseItemError(
          `${formatter.format(duplicatedDocuments)} already exist${
            duplicatedDocuments.length > 1 ? "" : "s"
          }.`
        );
      }

      await Promise.all(
        inputs.files.map(async (file, i) => {
          await createCaseDocument({
            variables: {
              data: {
                CaseItem: {
                  connect: {
                    casenbr: parseInt(casenbr, 10),
                  },
                },
                document: inputs.document,
                reporttype: inputs.reporttype,
                description: file.description,
                type: inputs.type,
                sfilename: file.file,
                dateadded: getDateEST(),
                useridadded: authState?.user?.userid,
              },
            },
            refetchQueries: [
              {
                query: LIST_CASE_DOCUMENT_QUERY,
                variables: {
                  where: { casenbr: { equals: parseInt(casenbr, 10) } },
                },
              },
            ],
          });
        })
      );
      toast.success("Case Report attached successfully");
      onComplete();
    } catch (err) {
      const message =
        err instanceof CaseItemError
          ? err.message
          : "Error attaching Case Report.";
      toast.error(message);
    } finally {
      setLoading(false);
    }
  };

  const isDisabled = () =>
    inputs.files.length === 0 ||
    inputs.files.length > 5 ||
    inputs.files.some((file) => file.description === "");

  return (
    <form id="add-case-report-form" onSubmit={handleSubmit}>
      <header className="page-head">
        <div className="page-head-start">
          <Title size={5}>Attach External Report</Title>
        </div>
        <div className="page-head-end">
          <Button.Group hasAddons>
            <Button
              size="small"
              type="button"
              onClick={() => onComplete(false)}
            >
              <span>Cancel</span>
            </Button>
            <Button
              color="primary"
              disabled={isDisabled()}
              form="add-case-report-form"
              size="small"
              type="submit"
            >
              <span>Submit</span>
            </Button>
          </Button.Group>
        </div>
      </header>
      <hr />
      {!loading ? (
        <CaseAttachExternalReportForm
          inputs={inputs}
          uploadPath={uploadPath}
          onChange={handleChange}
        />
      ) : (
        <Loader />
      )}
      <hr />
    </form>
  );
};

CaseAttachExternalReportModal.propTypes = {
  onComplete: PropTypes.func.isRequired,
  casenbr: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default CaseAttachExternalReportModal;
