import React from "react";
import PropTypes from "prop-types";
import { Button, Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useModal } from "../../../../context";
import InsuranceCarrierEntryModal from "../InsuranceCarrierEntryModal";

import BusinessCard from "../BusinessCard";

const InsuranceCarrierActionButtons = ({
  label,
  disabled,
  onInsuranceCarrierAdd,
  onClearSelectionClick,
  value,
  selected,
  setSelected,
}) => {
  const { setModalOpen } = useModal();

  const handleInsuranceCarrierAdd = (insuranceCarrier) => {
    onInsuranceCarrierAdd(insuranceCarrier);
    setModalOpen(false, "");
  };

  const handleAddClick = () => {
    setModalOpen(
      true,
      <InsuranceCarrierEntryModal
        label={label}
        mode="add"
        title={`Add ${label}`}
        onComplete={() => {
          setModalOpen(false, "");
        }}
        onInsuranceCarrierAdd={handleInsuranceCarrierAdd}
      />
    );
  };

  const handleSeeMoreClick = () => {
    setModalOpen(
      true,
      <InsuranceCarrierEntryModal
        label={label}
        mode="view"
        selected={selected}
        title={`View ${label}`}
        onComplete={() => {
          setModalOpen(false, "");
        }}
      />
    );
  };

  const handleSearchClick = () => {
    setModalOpen(
      true,
      <InsuranceCarrierEntryModal
        label={label}
        mode="search"
        setSelected={setSelected}
        title={`Search ${label}s`}
        onComplete={() => {
          setModalOpen(false, "");
        }}
        onRowSelect={handleInsuranceCarrierAdd}
      />
    );
  };

  return (
    <Button.Group hasAddons>
      <BusinessCard
        data={[selected?.label]}
        disabled={!selected}
        style={{ position: "relative" }}
        onSeeMoreClick={handleSeeMoreClick}
      />
      <Button
        className="request-generator-action-buttons--delete"
        color="danger"
        disabled={disabled || !value}
        size="small"
        title="Remove"
        type="button"
        onClick={onClearSelectionClick}
      >
        <Icon>
          <FontAwesomeIcon icon="times" />
        </Icon>
      </Button>
      <Button
        color="warning"
        disabled={disabled}
        size="small"
        title="Search"
        type="button"
        onClick={handleSearchClick}
      >
        <Icon>
          <FontAwesomeIcon icon="filter" />
        </Icon>
      </Button>
      <Button
        className="request-generator-action-buttons--add"
        color="success"
        disabled={disabled}
        size="small"
        title="Add"
        type="button"
        onClick={handleAddClick}
      >
        <Icon>
          <FontAwesomeIcon icon="plus" />
        </Icon>
      </Button>
    </Button.Group>
  );
};

InsuranceCarrierActionButtons.propTypes = {
  onInsuranceCarrierAdd: PropTypes.func,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  onClearSelectionClick: PropTypes.func,
  value: PropTypes.string,
  selected: PropTypes.object.isRequired,
  setSelected: PropTypes.func.isRequired,
};

InsuranceCarrierActionButtons.defaultProps = {
  onInsuranceCarrierAdd: () => null,
  label: "",
  disabled: false,
  onClearSelectionClick: () => null,
  value: "",
};

export default InsuranceCarrierActionButtons;
