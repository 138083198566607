// eslint-disable-next-line max-classes-per-file
import React, { useCallback, useEffect, useState } from "react";
import { Container, Field, Control, Button, Box } from "rbx";
import { useHistory } from "react-router-dom";

import { format, isValid } from "date-fns";
import axios from "axios";
import {
  useApolloClient,
  useLazyQuery,
  useMutation,
  useQuery,
} from "@apollo/client";
import checkProvReqMethod from "./validations/checkProvReqMethod";

import { useModal } from "../../context";
import Confirmation from "../../components/Confirmation";
import {
  convertTimeZoneDataBase,
  convertTimeZone,
  generateUUID,
  customToast as toast,
  omit,
  getDateEST,
  parseBase64,
  dolValidations,
  activeProviderWithoutRecordType,
} from "../../utils";

import useLinkService from "../../hooks/useLinkService";

import {
  useQueryParams,
  useClaim,
  useRRID,
  useWindowUnloadConfirmation,
  usePortalPerson,
  useIsUSAA,
  usePortalRefresh,
  useLsiClient,
  useGetWorkRequest,
  useGetNonRecordsCase,
} from "../../hooks";

import ReferralStyledSubtitle from "./components/ReferralStyledSubtitle";
import FileUploader from "./components/FileUploader";

import {
  SUBMIT_REFERRAL_MUTATION,
  SUBMIT_RECORDS_REFERRAL_MUTATION,
  UPDATE_SUBMITTED_RECORDS_REFERRAL_MUTATION,
  UPDATE_NON_SUBMITTED_RECORDS_REFERRAL_MUTATION,
  SINGLE_RECORD_REQUEST,
  ATTACH_CASE_DOCUMENT_MUTATION,
  CREATE_RECORD_UPLOAD_MUTATION,
  RATS_FIND_FIRST_MEDICAL_PROVIDER_QUERY,
  UPDATE_RECORD_REQUEST_MUTATION,
  FIND_FIRST_USER_QUERY,
  FIND_FIRST_LSI_CLIENT_QUERY,
  FIND_FIRST_CLIENT_QUERY,
  SINGLE_COMPANY_QUERY,
  CAPTURE_REFERRAL_ERROR_MUTATION,
  CLIENT_OFFICES_QUERY,
  RATS_REQUEST_FOR_CLIENT_OFFICE_QUERY,
  SEND_REFERRAL_RECEIPT_MUTATION,
  LIST_ALLSTATE_NJ_ATTORNEYS,
  SINGLE_DOCTOR_QUERY,
  UPDATE_RECORD_PROVIDER_MUTATION,
  LIST_ALLSTATE_COMPANY_GROUP_ITEMS_QUERY,
  LIST_GEICO_COMPANY_GROUP_ITEMS_QUERY,
} from "../../graphql";

import {
  ReferralHeader,
  ReferralFooter,
  ReferralFormBase,
  ClientContactInformationForm,
  BillingInformationForm,
  RecordRetrievalRequestForServiceForm,
  PhysicalProblems,
  AdditionalInformation,
  PlaintiffAttorneyForm,
  SurveillanceActivityCheck,
  InvestigativeServiceInstructions,
  MedicalServiceInstructions,
  SpecialServices,
  ClaimQuestionsForExaminingPhysician,
  Employer,
  TreatingPhysician,
  InsuredInformation,
  VehicleInformation,
  DefenseAttorneyForm,
  SubpoenaInformationForm,
  Notes,
  // DebugQueryParams,
  SubServiceForm,
  CounselForm,
} from "./components";

import {
  INITIAL_STATE,
  INITIAL_ADDITIONAL_INFORMATION_STATE,
  INITIAL_PROVIDER_MODEL,
  INITIAL_TREATING_PHYSICIAN_STATE,
  INITIAL_ADJUSTER_INFORMATION_STATE,
  INITIAL_EMPLOYER_STATE,
  INITIAL_CLAIM_QUESTIONS_STATE,
  INITIAL_SURVEILLANCE_ACTIVITY_CHECK_STATE,
  INITIAL_INVESTIGATIVE_SERVICE_INSTRUCTIONS_STATE,
  INITIAL_DEFENSE_ATTORNEY_STATE,
  INITIAL_VEHICLE_INFORMATION_STATE,
  INITIAL_INSURED_INFORMATION_STATE,
  INITIAL_PLAINTIFF_ATTORNEY_STATE,
  INITIAL_SUBPOENA_INFO,
  INITIAL_NOTES_STATE,
} from "./state";

import {
  validateTRNWSCTTRCCSCT,
  validateTRNWNWTRCCNW,
  validateTRNWHARTRCCNWHAR,
  validateTRNWNWPCTRCCNWPC,
  validateTRNWOTHTRCCNWOTH,
} from "./components/ClaimNumberRecordsInput/helpers";
import AdjusterForm from "./components/AdjusterForm/AdjusterForm";
import SaveAndSubmitButton from "./components/SaveAndSubmitButton";
import RetainClaimAndClaimaint from "./components/RetainClaimAndClaimaint";

// Referral variables types
import {
  CREATE_NEW_REFERRAL_ON_SAVE,
  SUBMIT,
  SAVE,
  RECORDS,
  MEDICAL_MANAGEMENT,
  INVESTIGATIONS,
} from "./constants/variableTypes";

const { REACT_APP_DOCUMENT_HOST } = process.env;

class CustomFileUploaderError extends Error {}
export class CustomERFError extends Error {}

const ReferralPage = () => {
  const client = useApolloClient();
  const history = useHistory();
  const DEFAULT_CLIENT_CODE = 19705;
  const { setModalOpen } = useModal();
  const { portalPerson } = usePortalPerson();
  const { lsiClient: lsiClientHook } = useLsiClient();
  const { addToLinkServiceQueue } = useLinkService();
  const { portalRefresh } = usePortalRefresh();

  // ERROR CAPTURING
  const {
    Type: { ERF_UPLOAD, ERF_SUBMISSION, ERF_INPUT },
    Code: { INVALID_INPUT_CODE, FILE_UPLOAD_FAIL, SUBMISSION_FAIL_CODE },
    Message: {
      INPUT_ERROR_MESSAGE,
      SUBMISSION_ERROR_MESSAGE,
      UPLOAD_ERROR_MESSAGE,
    },
    OtherInfo: {
      INPUT_OTHERINFO_MESSAGE,
      SUBMISSION_OTHERINFO_MESSAGE,
      UPLOAD_OTHERINFO_MESSAGE,
    },
  } = {
    Type: {
      ERF_INPUT: "ERF_INPUT",
      ERF_SUBMISSION: "ERF_SUBMISSION",
      ERF_UPLOAD: "ERF_UPLOAD",
    },
    Code: {
      FILE_UPLOAD_FAIL: "FILE_UPLOAD_FAIL",
      INVALID_INPUT_CODE: "INVALID_INPUT_CODE",
      SUBMISSION_FAIL_CODE: "SUBMISSION_FAIL_CODE",
    },
    Message: {
      INPUT_ERROR_MESSAGE: "Invalid input.",
      SUBMISSION_ERROR_MESSAGE: "Submission fail.",
      UPLOAD_ERROR_MESSAGE: "File(s) failed to upload.",
    },
    OtherInfo: {
      INPUT_OTHERINFO_MESSAGE: (msg) => msg,
      SUBMISSION_OTHERINFO_MESSAGE: (msg) => msg,
      UPLOAD_OTHERINFO_MESSAGE: (msg) => msg,
    },
  };

  const [getUser, { data: getUserData }] = useLazyQuery(FIND_FIRST_USER_QUERY);
  const [getCompanyCode, { data: getCompanyCodeData }] = useLazyQuery(
    FIND_FIRST_CLIENT_QUERY
  );
  const [getTeamConnectID, { data: teamConnectIDData }] =
    useLazyQuery(SINGLE_COMPANY_QUERY);
  const [getMasterClientID, { data: clientOfficesData }] =
    useLazyQuery(CLIENT_OFFICES_QUERY);
  const [getDoctor, { data: getDoctorData }] =
    useLazyQuery(SINGLE_DOCTOR_QUERY);
  const companyCode = getCompanyCodeData?.findFirstClient?.company?.companycode;

  const [getClientOfficeCode, { data: clientOfficeData }] = useLazyQuery(
    RATS_REQUEST_FOR_CLIENT_OFFICE_QUERY
  );

  const [getClient, { data: ClientData }] = useLazyQuery(
    FIND_FIRST_CLIENT_QUERY
  );

  const [getLsiClient, { data: LsiClientData }] = useLazyQuery(
    FIND_FIRST_LSI_CLIENT_QUERY
  );

  // test
  const [submitReferral, { loading: SubmitReferralLoading }] = useMutation(
    SUBMIT_REFERRAL_MUTATION
  );

  const [submitRecordsReferral, { loading: SubmitRecordsReferralLoading }] =
    useMutation(SUBMIT_RECORDS_REFERRAL_MUTATION);

  const [sendReferralReceipt, { loading: SendReferralReceiptLoading }] =
    useMutation(SEND_REFERRAL_RECEIPT_MUTATION);

  const [updateRecordRequest] = useMutation(UPDATE_RECORD_REQUEST_MUTATION);
  const [updateRecordProvider] = useMutation(UPDATE_RECORD_PROVIDER_MUTATION);

  const [captureReferralError] = useMutation(CAPTURE_REFERRAL_ERROR_MUTATION);

  const [updateSubmittedReferral, { loading: UpdateSubmittedReferralLoading }] =
    useMutation(UPDATE_SUBMITTED_RECORDS_REFERRAL_MUTATION);

  const [
    updateNonSubmittedReferral,
    { loading: UpdateNonSubmittedReferralLoading },
  ] = useMutation(UPDATE_NON_SUBMITTED_RECORDS_REFERRAL_MUTATION);

  const [attachCaseDocument] = useMutation(ATTACH_CASE_DOCUMENT_MUTATION);
  const [createRecordUpload] = useMutation(CREATE_RECORD_UPLOAD_MUTATION);

  const [mode] = useQueryParams("mode", "", true);
  const [ref] = useQueryParams("referrer", "", true);
  const [sourceId] = useQueryParams("sourceId", "", true);
  const [claimId] = useQueryParams("claimId", "", true);
  const [claimantId] = useQueryParams("claimantId", "", true);
  const [referrerParam] = useQueryParams("referrer");
  const [marketerUserIDParam] = useQueryParams("marketeruserid");
  const [clientCodeFromUrl] = useQueryParams("clientcode");
  const [CLidFromUrl] = useQueryParams("clid");
  const [email] = useQueryParams("email");
  const _clientcode = parseInt(parseBase64(clientCodeFromUrl), 10); // convert from URL params and parse as number
  const _CLid = parseInt(parseBase64(CLidFromUrl), 10); // convert from URL params and parse as number
  const _email = parseBase64(email); // convert from URL params
  const [previousReferral] = useQueryParams("ISGNumber", "", true);

  const [isUpdatingSubmittedReferral, setIsUpdatingSubmittedReferral] =
    useState(false);

  // Indicates if the records referral form has already been submitted
  const [isRecordsReferralSubmitted, setIsRecordsReferralSubmitted] =
    useState(false);

  // Referrer, Claim, Claimant, Services, Product
  const [inputs, setInputs] = useState({ ...INITIAL_STATE });

  // New Provider ID, used when a user selects "Obtain Updated Records" button. See handleObtainUpdatedRecords()
  const [newObtainedProvider, setNewProvider] = useState({
    ID: null,
    new: false,
  });

  // Physical Problems
  const [problems, setProblems] = useState([]);

  // Geico Marlton Offices -- Specific to RR Attorney dropdown, impacts the logic rules of some ERF functionality. Such as special required fields.
  const [isGeicoMarltonOffice, setIsGeicoMarltonOffice] = useState(false);

  // Geico Marlton Office -- doctorFound
  // const [doctorNotFound, setDoctorNotFound] = useState(false); // default must be false for Requested Doctor to be required & validated

  // Allstate Offices
  const [isAllstateOffice, setIsAllstateOffice] = useState(false);

  // ALL Geico offices
  const [isGeicoOffice, setIsGeicoOffice] = useState(false);

  // Geico Investigations Required Fields
  const isGeicoInvestigationsRequirement =
    /^investigations$/gi.test(inputs?.product) && isGeicoOffice;

  // Geico Claim Length Requirement
  const geicoClaimLength = 16;

  // Additional Information
  const [additionalInformation, setAdditionalInformation] = useState({
    ...INITIAL_ADDITIONAL_INFORMATION_STATE,
  });

  // Investigations - Surveillance / Activity Check
  const [surveillanceActivityCheck, setSurveillanceActivityCheck] = useState({
    ...INITIAL_SURVEILLANCE_ACTIVITY_CHECK_STATE,
  });

  // Investigations - Investigative Service Instructions
  const [
    investigativeServiceInstructions,
    setInvestigativeServiceInstructions,
  ] = useState({ ...INITIAL_INVESTIGATIVE_SERVICE_INSTRUCTIONS_STATE });

  // For the footer dropdown state
  const [retainClaimClaimantInformation, setRetainClaimClaimantInformation] =
    useState({ claim: false, claimant: false });

  const [subpoenaInfo, setSubpoenaInfo] = useState(INITIAL_SUBPOENA_INFO);

  const [uploadingFiles, setUploadingFiles] = useState(false);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [files, setFiles] = useState([]);

  // specific fields required in subpoena info if a provider has non party selected
  const checkProviderNonPartyRequest = inputs?.Providers?.some(
    (provider) => !provider.deleted && provider?.RequestMethod === 3
  );

  const { data: clientData } = useQuery(FIND_FIRST_CLIENT_QUERY, {
    variables: {
      where: { clientcode: { equals: _clientcode || DEFAULT_CLIENT_CODE } },
    },
    fetchPolicy: "network-only",
  });
  const { data: lsiClientData } = useQuery(FIND_FIRST_LSI_CLIENT_QUERY, {
    variables: {
      where: { CLid: { equals: _CLid } },
    },
    fetchPolicy: "network-only",
  });
  const { data: defaultClientData } = useQuery(FIND_FIRST_CLIENT_QUERY, {
    variables: {
      where: { clientcode: { equals: DEFAULT_CLIENT_CODE } },
    },
    fetchPolicy: "network-only",
  });

  // Allstate
  const { data: allstateCompanyGroupItems } = useQuery(
    LIST_ALLSTATE_COMPANY_GROUP_ITEMS_QUERY,
    { fetchPolicy: "cache-and-network" }
  );

  // Allstate
  const { data: allstateNJAttorneys } = useQuery(LIST_ALLSTATE_NJ_ATTORNEYS, {
    fetchPolicy: "cache-and-network",
  });

  // Geico
  const { data: geicoCompanyGroupItems } = useQuery(
    LIST_GEICO_COMPANY_GROUP_ITEMS_QUERY,
    { fetchPolicy: "cache-and-network" }
  );

  // USAA Employee Check
  // const [isUSAA, setIsUSAA] = useState(false);
  // const [isCLUSAARequired, setIsCLUSAARequired] = useState(false);
  // const [isUSAALegal, setIsUSAALegal] = useState(false);

  // useEffect(() => {
  //   const checkUSAA = () => {
  //     const lsiClientCompanyName = lsiClientData?.findFirstLsiClient?.CLcompany;
  //     const portalPersonCompanyName = portalPerson?.CompanyName;
  //     if (
  //       (lsiClientCompanyName && lsiClientCompanyName.includes("USAA")) ||
  //       (portalPersonCompanyName && portalPersonCompanyName.includes("USAA"))
  //     )
  //       setIsUSAA(true);
  //   };
  //   const checkCLUSAARequired = () => {
  //     if (
  //       lsiClientData?.findFirstLsiClient?.CLUSAARequired ||
  //       portalPerson?.CLUSAARequired
  //     )
  //       setIsCLUSAARequired(true);
  //   };
  //   // const checkCLUSAALegal = () => {
  //   //   if (
  //   //     lsiClientData?.findFirstLsiClient?.CLUSAALegalRequired ||
  //   //     foundUser?.IsUSAALegal
  //   //   )
  //   //     setIsUSAALegal(true);
  //   // };
  //   checkUSAA();
  //   checkCLUSAARequired();
  //   // checkCLUSAALegal();
  //   // TODO update USAA logic once the DOL cleanup is in
  // });isUSAA

  const { isUSAA } = useIsUSAA(lsiClientData);

  // Handles getting the company code data when in MM or IS modes and a clientcode exists in state.
  // This is needed to execute the next useEffect below
  useEffect(() => {
    const isMMorISMode = /^medical management|investigations$/gi.test(
      inputs?.product
    );
    if (isMMorISMode && (_clientcode || portalPerson)) {
      const clientcode = _clientcode || portalPerson?.clientcode;
      getCompanyCode({
        variables: {
          where: {
            clientcode: { equals: clientcode },
          },
        },
      });
    }
  }, [_clientcode, inputs?.product, getCompanyCode, portalPerson]);

  // Handles fetching a TeamConnectID if one exists based on the company code.
  // We will use the existence of this TeamConnectID to determine if Legal File Number is a required field or not.
  useEffect(() => {
    const isMMorISMode = /^medical management|investigations$/gi.test(
      inputs?.product
    );
    if (isMMorISMode && companyCode) {
      getTeamConnectID({
        variables: {
          where: {
            companycode: parseInt(companyCode, 10),
          },
        },
        fetchPolicy: "network-only",
      });
    }
  }, [companyCode, getTeamConnectID, inputs?.product]);

  useEffect(() => {
    if (mode) {
      setInputs((prev) => ({ ...prev, product: mode }));
    }
  }, [mode]);

  const resetServicesRequested = () => {
    setInputs((prev) => ({
      ...prev,
      ServicesRequested: [],
      ServiceInstructions: "",
    }));
  };
  // Reset Services Requested On Product Change
  useEffect(resetServicesRequested, [inputs?.product]);

  // MARKETER CODE: Sets the marketercode in state
  useEffect(() => {
    const isMarketerCodePresent = Boolean(inputs?.marketercode);

    if (isMarketerCodePresent) {
      getUser({
        variables: {
          where: {
            userid: { equals: inputs?.marketercode },
          },
        },
        fetchPolicy: "network-only",
      });
    }
  }, [inputs?.marketercode, getUser]);

  // MARKETER EMAIL: Sets the marketeremail in state
  useEffect(() => {
    const marketerEmail = getUserData?.findFirstUser?.email;

    if (marketerEmail) {
      setInputs((prev) => ({
        ...prev,
        marketeremail: marketerEmail,
      }));
    }
  }, [getUserData?.findFirstUser?.email, setInputs]);

  const [treatingPhysician, setTreatingPhysician] = useState(
    INITIAL_TREATING_PHYSICIAN_STATE
  );

  const [adjusterInformation, setAdjusterInformation] = useState(
    INITIAL_ADJUSTER_INFORMATION_STATE
  );

  const [employer, setEmployer] = useState(INITIAL_EMPLOYER_STATE);

  const [claimQuestions, setClaimQuestions] = useState(
    INITIAL_CLAIM_QUESTIONS_STATE
  );

  const [defenseAttorney, setDefenseAttorney] = useState(
    INITIAL_DEFENSE_ATTORNEY_STATE
  );

  const [coDefendant, setCoDefendant] = useState(
    INITIAL_DEFENSE_ATTORNEY_STATE
  );

  const [vehicleInformation, setVehicleInformation] = useState(
    INITIAL_VEHICLE_INFORMATION_STATE
  );

  const [insuredInformation, setInsuredInformation] = useState(
    INITIAL_INSURED_INFORMATION_STATE
  );

  const [plaintiffAttorney, setPlaintiffAttorney] = useState(
    INITIAL_PLAINTIFF_ATTORNEY_STATE
  );

  const [notes, setNotes] = useState(INITIAL_NOTES_STATE);

  useEffect(() => {
    if (previousReferral) {
      addToLinkServiceQueue(previousReferral, true, previousReferral);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previousReferral]);

  useEffect(() => {
    // Handle client data
    if (
      /^medical management|investigations$/gi.test(inputs?.product) &&
      clientData?.findFirstClient?.email &&
      !portalPerson
    ) {
      setInputs((prev) => ({
        ...prev,
        FirstName: clientData?.findFirstClient?.firstname || "",
        LastName: clientData?.findFirstClient?.lastname || "",
        CompanyName: clientData?.findFirstClient?.company?.extname || "",
        City: clientData?.findFirstClient?.city || "",
        State: clientData?.findFirstClient?.state || "",
        Phone: clientData?.findFirstClient?.phone1 || "",
        Email: clientData?.findFirstClient?.email || "",
        clientcode: _clientcode || DEFAULT_CLIENT_CODE,
      }));
    } else if (
      lsiClientData?.findFirstLsiClient?.CLemail &&
      /^records$/gi.test(inputs?.product) &&
      !portalPerson
    ) {
      setInputs((prev) => ({
        ...prev,
        FirstName: lsiClientData?.findFirstLsiClient?.CLfname || "",
        LastName: lsiClientData?.findFirstLsiClient?.CLlname || "",
        CompanyName: lsiClientData?.findFirstLsiClient?.CLcompany || "",
        City: lsiClientData?.findFirstLsiClient?.CLcity || "",
        State: lsiClientData?.findFirstLsiClient?.CLstate || "",
        Phone: lsiClientData?.findFirstLsiClient?.CLphone || "",
        Email: lsiClientData?.findFirstLsiClient?.CLemail || "",
        clientcode: _CLid || DEFAULT_CLIENT_CODE,
      }));
    } else if (portalPerson) {
      setInputs((prev) => ({
        ...prev,
        FirstName: portalPerson?.FirstName || "",
        LastName: portalPerson?.LastName || "",
        CompanyName: portalPerson?.CompanyName || "",
        City: portalPerson?.City || "",
        State: portalPerson?.State || "",
        Phone: portalPerson?.Phone || "",
        Email: portalPerson?.Email || "",
        clientcode: portalPerson?.clientcode || DEFAULT_CLIENT_CODE,
        marketercode: !/^records$/gi.test(inputs?.product)
          ? portalPerson?.marketercode
          : null,
        PersonID: portalPerson?.PersonID,
      }));
    } else if (history?.location?.search?.length > 0) {
      setInputs((prev) => ({
        ...prev,
        FirstName: defaultClientData?.findFirstClient?.firstname || "",
        LastName: defaultClientData?.findFirstClient?.lastname || "",
        CompanyName: defaultClientData?.findFirstClient?.company?.extname || "",
        City: defaultClientData?.findFirstClient?.city || "",
        State: defaultClientData?.findFirstClient?.state || "",
        Phone: defaultClientData?.findFirstClient?.phone1 || "",
        Email: defaultClientData?.findFirstClient?.email || "",
        clientcode: _clientcode || DEFAULT_CLIENT_CODE,
      }));
    }
    // eslint-disable-next-line
  }, [inputs?.product, clientData, lsiClientData, portalPerson]);

  // Handles checking Defense Attorney and Adjuster when a TeamConnectID is found for user filling out the referral form
  useEffect(() => {
    if (teamConnectIDData?.company?.TeamConnectID) {
      setAdditionalInformation((prev) => ({
        ...prev,
        DefenseAttorney: true,
        Adjuster: true,
      }));
    }
  }, [teamConnectIDData]);

  const handleChangeNotes = (value) => {
    setNotes(value);
  };

  // Handle scrolling to new provider when "Obtain Updated Records" is clicked. See handleObtainUpdatedRecords() above.
  useEffect(() => {
    if (newObtainedProvider?.new && newObtainedProvider?.ID) {
      const provider = document.getElementById(`${newObtainedProvider.ID}`);
      if (provider) {
        const { offsetTop } = provider;
        window.scrollTo({
          top: offsetTop,
          behavior: "smooth",
        });
      }
    }
  }, [newObtainedProvider]);

  const handleChange = (name, value) => {
    setInputs((prev) => ({ ...prev, [name]: value }));

    if (name === "BillingContact") {
      getMasterClientID({
        variables: {
          where: { requestgenerator: { some: { RGid: { equals: value } } } },
        },
        fetchPolicy: "network-only",
      });
    }
  };

  const handleTreatingPhysiciansChange = (name, value) => {
    setTreatingPhysician((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleAdjusterInfoChange = (name, value) => {
    setAdjusterInformation((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleBillingSameAsRequestorChange = (name, value) => {
    setInputs((prev) => ({
      ...prev,
      BillingContact: value ? inputs.RequestorOrAttorney : "",
    }));
  };

  const handleAdditionalInformationChange = (name, value) => {
    setAdditionalInformation((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSurveillanceActivityChange = (name, value) => {
    const surveillanceExists = inputs.ServicesRequested.some(
      (service) => service.value === "11"
    );

    if (name === "SurveillanceAmount" && value && !surveillanceExists) {
      setInputs((prev) => ({
        ...prev,
        ServicesRequested: [
          ...inputs.ServicesRequested,
          {
            label: "Surveillance",
            name,
            value: "11",
          },
        ],
      }));
    }

    setSurveillanceActivityCheck((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleClaimQuestionsChange = (name, value) => {
    setClaimQuestions((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleInvestigativeServiceChange = (name, value) => {
    setInvestigativeServiceInstructions((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handlePlaintiffAttorneyChange = (name, value) => {
    setPlaintiffAttorney((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSetEmployer = (name, value) => {
    setEmployer((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleArrChange = async (index, name, value) => {
    const newObj = {
      ...inputs.Providers[index],
      updated: true,
      [name]: value,
    };

    const { Providers } = inputs;
    Providers[index] = newObj;

    setInputs((prev) => ({
      ...prev,
      Providers,
    }));
  };

  const handleCounselChange = (index, name, value) => {
    const newObj = {
      ...inputs.Counsels[index],
      updated: true,
      [name]: value,
    };

    const { Counsels } = inputs;
    Counsels[index] = newObj;

    setInputs((prev) => ({
      ...prev,
      Counsels,
    }));
  };

  const handleAddCounsel = () => {
    setInputs((prev) => ({
      ...prev,
      Counsels: [
        ...prev.Counsels,
        {
          CounselID: generateUUID(),
          Representing: "",
        },
      ],
    }));
  };

  const handleDeleteCounsel = (index) => {
    setInputs((prev) => {
      const newCounsels = [...prev.Counsels];
      newCounsels.splice(index, 1, {
        ...prev.Counsels[index],
        deleted: true,
      });
      return {
        ...prev,
        Counsels: newCounsels,
      };
    });
  };

  const handleAddProvider = () => {
    const { Providers } = inputs;
    Providers.push({
      ...INITIAL_PROVIDER_MODEL,
      ProviderID: generateUUID(),
    });

    setInputs((prev) => ({
      ...prev,
      Providers,
    }));
  };

  // const handleDeleteProvider = (index) => {
  //   setInputs((prev) => ({
  //     ...prev,
  //     Providers: [...inputs.Providers.filter((provider) => provider !== inputs.Providers[index])],
  //   }));
  // };

  const handleDeleteProvider = async (index) => {
    const performDelete = async () => {
      try {
        setInputs((prev) => {
          const updatedProviders = [...prev.Providers];
          updatedProviders[index] = {
            ...updatedProviders[index],
            deleted: true,
          };

          return {
            ...prev,
            Providers: updatedProviders,
          };
        });
        setModalOpen(false);
        toast.erfsuccess("Provider deleted successfully");
      } catch (err) {
        toast.error("Error trying to delete provider");
      }
    };

    setModalOpen(
      true,
      <Confirmation
        message="Are you sure you want to delete this provider?"
        onCancel={() => setModalOpen(false)}
        onConfirm={performDelete}
      />
    );
  };

  const handleSubpoenaInputChange = (name, value) => {
    setSubpoenaInfo((prev) => ({ ...prev, [name]: value }));
  };

  const handleInsuredInformationChange = (name, value) => {
    setInsuredInformation((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleVehicleInformationChange = (name, value) => {
    setVehicleInformation((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleDefenseAttorneyChange = (name, value) => {
    setDefenseAttorney((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleCoDefendantChange = (name, value) => {
    setCoDefendant((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // ---  HANDLE REFERRER --- //
  const [referrer, setReferrer] = useState(null);

  useEffect(() => {
    // This useEffect handles getting the right client or LSI client based on the product of the form
    if (!portalPerson && /^records$/gi.test(inputs?.product)) {
      getLsiClient({
        variables: {
          where: {
            CLid: { equals: _CLid },
          },
        },
        fetchPolicy: "network-only",
      });
    }

    if (
      !portalPerson &&
      /^medical management|investigations$/gi.test(inputs?.product)
    ) {
      getClient({
        variables: {
          where: {
            clientcode: { equals: _clientcode },
          },
        },
        fetchPolicy: "network-only",
      });
    }
  }, [
    inputs?.product,
    _CLid,
    _clientcode,
    getClient,
    getLsiClient,
    portalPerson,
  ]);

  useEffect(() => {
    // This useEffect handles setting the referrer based on the returned lazy queries from the useEffect above
    const lsiClient = LsiClientData?.findFirstLsiClient;
    const currentClient = ClientData?.findFirstClient;

    if (
      /^medical management|investigations$/gi.test(inputs?.product) &&
      currentClient &&
      !portalPerson
    ) {
      setReferrer({
        FirstName: currentClient.firstname,
        LastName: currentClient.lastname,
        CompanyName: currentClient.company?.intname,
        City: currentClient.city,
        State: currentClient.state,
        Phone: currentClient.phone1,
        Email: currentClient.email,
        marketercode: currentClient.marketercode,
        clientcode:
          typeof _clientcode === "number" && !isNaN(_clientcode)
            ? _clientcode
            : currentClient.clientcode,
      });
    }

    if (/^records$/gi.test(inputs?.product) && lsiClient && !portalPerson) {
      setReferrer({
        FirstName: lsiClient.CLfname,
        LastName: lsiClient.CLlname,
        CompanyName: lsiClient.CLcompany,
        City: lsiClient.CLcity,
        State: lsiClient.CLstate,
        Phone: lsiClient.CLphone,
        Email: lsiClient.CLemail,
        CLnwOption: lsiClient.CLnwOption,
        CLbsOption: lsiClient.CLbsOption,
        ClientLogin: lsiClient.CLlogin,
        ClientID: lsiClient.CLid,
        CLUSAARequired: lsiClient.CLUSAARequired,
        CLSentryOption: lsiClient.CLSentryOption,
        clientcode: parseInt(_CLid, 10),
      });
    }

    if (portalPerson) {
      setReferrer({
        FirstName: portalPerson?.FirstName,
        LastName: portalPerson?.LastName,
        CompanyName: portalPerson?.CompanyName,
        City: portalPerson?.City,
        State: portalPerson?.State,
        Phone: portalPerson?.Phone,
        Email: portalPerson?.Email,
        CLnwOption: portalPerson?.CLnwOption,
        CLbsOption: portalPerson?.CLbsOption,
        ClientLogin: portalPerson?.ClientLogin,
        ClientID: portalPerson?.ClientID,
        CLUSAARequired: portalPerson?.CLUSAARequired,
        CLSentryOption: portalPerson?.CLSentryOption,
        clientcode: portalPerson?.clientcode,
        marketercode: !/^records$/gi.test(inputs?.product)
          ? portalPerson?.marketercode
          : null,
      });
    }
  }, [
    inputs?.product,
    setReferrer,
    LsiClientData,
    ClientData,
    _CLid,
    _clientcode,
    portalPerson,
  ]);

  // const referrer = useReferrer(inputs?.product)
  // Handle Referrer
  // const [referrer, setReferrer] = useState();
  // useEffect(() => {
  //   const newReferrer = useReferrer(inputs?.product)

  //   if (newReferrer) {
  //     setReferrer(() => newReferrer)
  //   }
  // }, [inputs?.product])

  const [claim, claimErrors] = useClaim();

  const rrId = useRRID();
  const [
    usedChangeProductFromPreviousRRid,
    setUsedChangeProductFromPreviousRRid,
  ] = useState(false);

  const IsBillingSameAsRequestor = useCallback(() => {
    if (rrId?.RequestorOrAttorney || rrId?.BillingContact) {
      return rrId.RequestorOrAttorney === rrId.BillingContact;
    }
    return false;
  }, [rrId]);

  const DOLRequired =
    !/^records$/gi.test(inputs?.product) ||
    inputs.Providers.some((provider) => provider.RequestDates === "FromDOL") ||
    isUSAA.claims ||
    isUSAA.legal ||
    inputs?.InsuranceCarrier?.includes("USAA");

  const { mainData, subpoenaData } = useGetWorkRequest();

  useEffect(() => {
    if (mainData) {
      setInputs((prev) => ({
        ...prev,
        ...mainData,
      }));
      if (/^records$/gi.test(inputs?.product)) {
        setSubpoenaInfo((prev) => ({
          ...prev,
          ...subpoenaData,
        }));
      }
    }
  }, [inputs?.product, mainData, subpoenaData]);

  const nonRecordsData = useGetNonRecordsCase();

  useEffect(() => {
    if (nonRecordsData) {
      setInputs((prev) => ({
        ...prev,
        ...nonRecordsData,
      }));
    }
  }, [inputs?.product, nonRecordsData]);

  useEffect(() => {
    if (
      rrId &&
      !/^medical management|investigations$/gi.test(inputs?.product)
    ) {
      setIsRecordsReferralSubmitted(rrId.Submitted);

      const handleProviderRetrievePresent = (provider) => {
        if (provider.RetrieveEndDate === "Present") {
          return true;
        }

        return provider.RetrievePresent;
      };

      setInputs((prev) => ({
        ...prev,
        ClaimantLastName: rrId.ClaimantLastName,
        ClaimantFirstName: rrId.ClaimantFirstName,
        ClaimantSSN: rrId.ClaimantSSN,
        ClaimantDateOfBirth: rrId.ClaimantDateOfBirth
          ? format(convertTimeZone(rrId.ClaimantDateOfBirth), "yyyy-MM-dd")
          : "",
        ClaimantAddress1: rrId.ClaimantAddress1,
        ClaimantAddress2: rrId.ClaimantAddress2,
        ClaimantCity: rrId.ClaimantCity,
        ClaimantState: rrId.ClaimantState,
        ClaimantZip: rrId.ClaimantZip,
        ClaimNumber: rrId.ClaimNumber,
        DateOfLoss: rrId.DateOfLoss
          ? format(convertTimeZone(rrId.DateOfLoss), "yyyy-MM-dd")
          : "",

        RequestorOrAttorney: rrId.RequestorOrAttorney,
        RepresentedParty: rrId.RepresentedParty,
        FileNumber: rrId.FileNumber,
        ParalegalOrContact: rrId.ParalegalOrContact,
        AdditionalContact: rrId.AdditionalContact,
        GlobalInstructions: rrId.GlobalInstructions,
        DeadlineDate: rrId.DeadlineDate
          ? format(convertTimeZone(rrId.DeadlineDate), "yyyy-MM-dd")
          : "",
        IsBillingSameAsRequestor: IsBillingSameAsRequestor(),
        BillingContact: rrId.BillingContact,
        InsuranceCarrier: rrId.InsuranceCarrier,

        NWCarrier: rrId.NWCarrier,
        ClaimSystem: rrId.ClaimSystem,
        BusinessUnit: rrId.BusinessUnit,
        LossState: rrId.LossState,
        Subrogation: rrId.Subrogation,
        AllegedInjuries: rrId.AllegedInjuries,
        ServiceCenter: rrId.ServiceCenter,
        BroadspireClient: rrId.BroadspireClient,
        BroadspireCarrier: rrId.BroadspireCarrier,
        RRClaimantLetter: rrId.RRClaimantLetter,
        RRUseCAWCRegs: rrId.RRUseCAWCRegs,
        RRclaimOffice_us: rrId.RRclaimOffice_us,
        RRmemberNumber_us: rrId.RRmemberNumber_us,
        RRalisNumber_us: rrId.RRalisNumber_us,
        RRclaimsLossEmail_us: rrId.RRclaimsLossEmail_us,
        RRlossReportNumber_us: rrId.RRlossReportNumber_us,
        RRLegalOffice_us: rrId.RRLegalOffice_us,

        // Phone: rrId.Phone,
        CaseType: rrId.CaseType,
        Insured: rrId.Insured,
        Jurisdiction: rrId.Jurisdiction,
        Venue: rrId.Venue,
        ClaimantEmail: rrId.ClaimantEmail,
        ClaimantPhone: rrId.ClaimantPhone,
        ClaimantCellPhone: rrId.ClaimantCellPhone,
        ClaimantGender: rrId.ClaimantGender,
        ClaimantMaritalStatus: rrId.ClaimantMaritalStatus,
        ClaimantSpecialPhysical: rrId.ClaimantSpecialPhysical,

        Providers:
          rrId.Providers && rrId.Providers.length
            ? rrId.Providers.map((provider) => {
                const cleanedProvider = omit({ ...provider }, ["__typename"]);
                cleanedProvider.RetrievePresent =
                  handleProviderRetrievePresent(provider);
                return {
                  ...cleanedProvider,
                  fromDB: true,
                };
              })
            : [],

        Counsels:
          rrId.Counsels && rrId.Counsels.length
            ? rrId.Counsels.map((counsel) => {
                const cleanedCounsel = omit({ ...counsel }, ["__typename"]);
                return { ...cleanedCounsel, fromDB: true };
              })
            : [],

        SubService: rrId.SubService,

        Uploads: rrId.Uploads || [],
      }));

      setSubpoenaInfo({
        ContactName: rrId.ContactName,
        Caption: rrId.Caption,
        County: rrId.County,
        DocketNumber: rrId.DocketNumber,
        Court: rrId.Court,
        CourtAddress: rrId.CourtAddress,
        Branch: rrId.Branch,
        Division: rrId.Division,
        CalendarNumber: rrId.CalendarNumber,
        ClaimType: rrId.ClaimType,
        WCJudge: rrId.WCJudge,
        WCInsuranceCarrier: rrId.WCInsuranceCarrier,
        WCEmployer: rrId.WCEmployer,
        WCStateFileNumber: rrId.WCStateFileNumber,
        WCStateFileYear: rrId.WCStateFileYear,
        WCRFANumber: rrId.WCRFANumber,
        WCCarrierCode: rrId.WCCarrierCode,

        SubpoenaState: rrId.REState,
        CourtFee: rrId.RECourtFee,
        SheriffFee: rrId.RESheriffFee,
        SecretaryOfStateFee: rrId.RESecretaryOfStateFee,
      });

      if (rrId.Notes && rrId.Notes.length) {
        setNotes([
          ...rrId.Notes.map((n) => ({
            id: n.NoteID.toString(),
            note: n.Note,
            user: referrer?.Email || "Unknown",
            fromDB: true,
          })),
        ]);
      }
    }
  }, [
    rrId,
    referrer?.Email,
    setIsRecordsReferralSubmitted,
    inputs?.product,
    IsBillingSameAsRequestor,
  ]);

  useEffect(() => {
    if (claim) {
      setInputs((prev) => ({ ...prev, ...claim }));
    }
  }, [claim]);

  useEffect(() => {
    if (claimErrors.length) {
      claimErrors.forEach((error) => toast.error(error));
    }
  }, [claimErrors]);

  useEffect(() => {
    // This is where state gets populated by referrer upon landing on any referral form type that has one in the URL params
    if (referrer) {
      setInputs((prev) => ({ ...prev, ...referrer }));
    }
  }, [referrer]);

  useEffect(() => {
    const GEICO_MARLTON_OFFICES = ["CO64263", "CO69789", "CO62239", "CO65848"];

    async function fetchData() {
      if (inputs?.Email) {
        getClientOfficeCode({
          variables: {
            where: {
              AND: [
                {
                  OR: [
                    { RGclientOfficeID: { equals: "CO64263" } },
                    { RGclientOfficeID: { equals: "CO69789" } },
                    { RGclientOfficeID: { equals: "CO62239" } },
                    { RGclientOfficeID: { equals: "CO65848" } },
                  ],
                },
                { RGemail: { equals: inputs.Email } },
              ],
            },
          },
          fetchPolicy: "network-only",
        });

        if (
          GEICO_MARLTON_OFFICES?.includes(
            clientOfficeData?.requestgenerators[0]?.RGclientOfficeID
          )
        ) {
          setIsGeicoMarltonOffice(true);
        } else {
          setIsGeicoMarltonOffice(false);
        }
      }
    }

    fetchData();
  }, [inputs?.Email, getClientOfficeCode, clientOfficeData]);

  useEffect(() => {
    const companyGroupData =
      allstateCompanyGroupItems?.allstateCompanyGroupItems;
    if (inputs?.Email?.length && companyGroupData?.length) {
      const allstateUser = companyGroupData.filter((user) =>
        inputs?.Email.length ? inputs?.Email === user.email : null
      );

      if (allstateUser[0]?.email === inputs?.Email) {
        setIsAllstateOffice(true);
      } else {
        setIsAllstateOffice(false);
      }
    }
  }, [inputs?.Email, allstateCompanyGroupItems]);

  useEffect(() => {
    if (
      portalPerson?.ClientMasterID === "CM2080" ||
      lsiClientHook?.ClientMasterID === "CM2080"
    ) {
      setIsGeicoOffice(true);
    }

    if (!portalPerson && !lsiClientHook) {
      const companyGroupData = geicoCompanyGroupItems?.geicoCompanyGroupItems;
      if (inputs?.Email?.length && companyGroupData?.length) {
        const geicoUser = companyGroupData.filter((user) =>
          inputs?.Email.length
            ? inputs?.Email?.toLowerCase() === user?.email?.toLowerCase()
            : null
        );

        if (
          geicoUser[0]?.email?.toLowerCase() === inputs?.Email?.toLowerCase()
        ) {
          setIsGeicoOffice(true);
        } else {
          setIsGeicoOffice(false);
        }
      }
    }
  }, [
    inputs?.Email,
    geicoCompanyGroupItems,
    portalPerson?.ClientMasterID,
    lsiClientHook?.ClientMasterID,
    portalPerson,
    lsiClientHook,
  ]);

  useEffect(() => {
    if (isAllstateOffice) {
      // Auto-check the Adjuster for Allstate Users
      setAdditionalInformation((prev) => ({
        ...prev,
        Adjuster: true,
      }));
      // Set default Adjuster information for Allstate Users
      setAdjusterInformation((prev) => ({
        ...prev,
        Email: "claims@claims.allstate.com",
        Company: "Allstate",
      }));
    }
  }, [isAllstateOffice]);

  const canSaveRecordsReferralForm = () => {
    // Claimant validations
    if (!inputs.ClaimantLastName) {
      captureReferralError({
        variables: {
          input: {
            Type: ERF_INPUT,
            Code: INVALID_INPUT_CODE,
            Message: INPUT_ERROR_MESSAGE,
            OtherInfo: INPUT_OTHERINFO_MESSAGE(
              "VALIDATION ERROR ON: Last Name required for Save"
            ),
          },
        },
      });
      return {
        OK: false,
        message: "Claimant Last Name is required before Saving",
      };
    }
    // Record Providers
    if (!inputs?.Providers?.filter((p) => !p.deleted).length) {
      captureReferralError({
        variables: {
          input: {
            Type: ERF_INPUT,
            Code: INVALID_INPUT_CODE,
            Message: INPUT_ERROR_MESSAGE,
            OtherInfo: INPUT_OTHERINFO_MESSAGE(
              "VALIDATION ERROR ON: Provider -- Require at least ONE."
            ),
          },
        },
      });
      return {
        OK: false,
        message: "You must add at least one record provider",
      };
    }
    if (inputs.Providers.some((p) => !p.deleted && p.Provider === "")) {
      captureReferralError({
        variables: {
          input: {
            Type: ERF_INPUT,
            Code: INVALID_INPUT_CODE,
            Message: INPUT_ERROR_MESSAGE,
            OtherInfo: INPUT_OTHERINFO_MESSAGE("VALIDATION ERROR ON: Provider"),
          },
        },
      });
      return {
        OK: false,
        message: "Provider is a required field",
      };
    }

    if (
      inputs.Providers.some(
        (p) =>
          !p.deleted &&
          p.RequestDates === "dateRange" &&
          (!p.RetrieveStartDate || (!p.RetrieveEndDate && !p.RetrievePresent))
      )
    ) {
      captureReferralError({
        variables: {
          input: {
            Type: ERF_INPUT,
            Code: INVALID_INPUT_CODE,
            Message: INPUT_ERROR_MESSAGE,
            OtherInfo: INPUT_OTHERINFO_MESSAGE(
              "VALIDATION ERROR ON: Providers => You have selected to retrieve specific dates. Please enter a start and end date for your request."
            ),
          },
        },
      });
      return {
        OK: false,
        message:
          "You have selected to retrieve specific dates. Please enter a start and end date for your request.",
      };
    }

    if (
      inputs.SubService === "RR" &&
      inputs.Providers.some(
        (prov) =>
          !prov.deleted &&
          prov?.RecordType?.includes(284) &&
          !prov.SpecialInstructions
      )
    ) {
      captureReferralError({
        variables: {
          input: {
            Type: ERF_INPUT,
            Code: INVALID_INPUT_CODE,
            Message: INPUT_ERROR_MESSAGE,
            OtherInfo: INPUT_OTHERINFO_MESSAGE(
              "VALIDATION ERROR ON: Special Instructions (required)"
            ),
          },
        },
      });
      return {
        OK: false,
        message: "Special Instructions is a required field",
      };
    }

    if (activeProviderWithoutRecordType(inputs)) {
      captureReferralError({
        variables: {
          input: {
            Type: ERF_INPUT,
            Code: INVALID_INPUT_CODE,
            Message: INPUT_ERROR_MESSAGE,
            OtherInfo: INPUT_OTHERINFO_MESSAGE(
              "VALIDATION ERROR ON: Record Types => You must select at least one record type for each record provider."
            ),
          },
        },
      });
      return {
        OK: false,
        message:
          "You must select at least one record type for each record provider",
      };
    }

    return {
      OK: true,
    };
  };

  const canSubmitRecordsReferralForm = () => {
    if (DOLRequired || inputs.DateOfLoss) {
      const result = dolValidations(inputs.DateOfLoss);

      if (result.error) {
        captureReferralError({
          variables: {
            input: {
              Type: ERF_INPUT,
              Code: INVALID_INPUT_CODE,
              Message: INPUT_ERROR_MESSAGE,
              OtherInfo: INPUT_OTHERINFO_MESSAGE(
                "VALIDATION ERROR: Date of Loss/Injury"
              ),
            },
          },
        });
        return {
          OK: false,
          message: `'Date of Loss/Injury' ${result.message}. Please clear your selection and try again.`,
        };
      }
    }

    // Claimant validations
    if (
      !inputs.ClaimantFirstName ||
      !inputs.ClaimantLastName ||
      !inputs.ClaimantSSN
    ) {
      captureReferralError({
        variables: {
          input: {
            Type: ERF_INPUT,
            Code: INVALID_INPUT_CODE,
            Message: INPUT_ERROR_MESSAGE,
            OtherInfo: INPUT_OTHERINFO_MESSAGE(
              "VALIDATION ERROR ON: First Name, Last Name and/or Social Security Number"
            ),
          },
        },
      });
      return {
        OK: false,
        message:
          "The following claimant information is required: First Name, Last Name and Social Security Number",
      };
    }

    // Claim validations
    if (!inputs.ClaimNumber) {
      captureReferralError({
        variables: {
          input: {
            Type: ERF_INPUT,
            Code: INVALID_INPUT_CODE,
            Message: INPUT_ERROR_MESSAGE,
            OtherInfo: INPUT_OTHERINFO_MESSAGE(
              "VALIDATION ERROR ON: Claim Number"
            ),
          },
        },
      });
      return {
        OK: false,
        message: "The following claim information is required: Claim Number",
      };
    }

    // Geico Claim Number Length Requirement
    if (isGeicoOffice && inputs.ClaimNumber.length !== geicoClaimLength) {
      captureReferralError({
        variables: {
          input: {
            Type: ERF_INPUT,
            Code: INVALID_INPUT_CODE,
            Message: INPUT_ERROR_MESSAGE,
            OtherInfo: INPUT_OTHERINFO_MESSAGE(
              "VALIDATION ERROR ON: Claim Number - Geico length requirement"
            ),
          },
        },
      });
      return {
        OK: false,
        message: `Claim number is required to be ${geicoClaimLength} characters for Geico`,
      };
    }

    const clientOffice = clientOfficesData?.findFirstClientoffice;
    if (
      clientOffice?.COclientMasterID === "CM3279" &&
      inputs.ClaimNumber.length < 15
    ) {
      captureReferralError({
        variables: {
          input: {
            Type: ERF_INPUT,
            Code: INVALID_INPUT_CODE,
            Message: INPUT_ERROR_MESSAGE,
            OtherInfo: INPUT_OTHERINFO_MESSAGE(
              "VALIDATION ERROR ON: Claim Number -- 15 character minimum required for Lowes cases."
            ),
          },
        },
      });
      return {
        OK: false,
        message: `There is a 15-character minimum on the Claim Number for Lowes cases. Only ${inputs.ClaimNumber.length} characters have been entered.`,
      };
    }

    // Requestor or Attorney
    if (!inputs.RequestorOrAttorney) {
      captureReferralError({
        variables: {
          input: {
            Type: ERF_INPUT,
            Code: INVALID_INPUT_CODE,
            Message: INPUT_ERROR_MESSAGE,
            OtherInfo: INPUT_OTHERINFO_MESSAGE(
              "VALIDATION ERROR ON: Requestor or Attorney"
            ),
          },
        },
      });
      return {
        OK: false,
        message: "Requestor or Attorney is a required field",
      };
    }

    if (!inputs.FileNumber && !isRecordsReferralSubmitted) {
      captureReferralError({
        variables: {
          input: {
            Type: ERF_INPUT,
            Code: INVALID_INPUT_CODE,
            Message: INPUT_ERROR_MESSAGE,
            OtherInfo: INPUT_OTHERINFO_MESSAGE(
              "VALIDATION ERROR ON: File # -- Value is Required!"
            ),
          },
        },
      });
      return {
        OK: false,
        message: "File #/Matter ID is a required field",
      };
    }

    // Insurance Carrier
    if (!inputs.InsuranceCarrier) {
      captureReferralError({
        variables: {
          input: {
            Type: ERF_INPUT,
            Code: INVALID_INPUT_CODE,
            Message: INPUT_ERROR_MESSAGE,
            OtherInfo: INPUT_OTHERINFO_MESSAGE(
              "VALIDATION ERROR ON: Insurance Carrier"
            ),
          },
        },
      });
      return {
        OK: false,
        message: "Insurance Carrier is a required field",
      };
    }

    // Broadspire Carrier
    if (
      inputs.InsuranceCarrier === "Broadspire" &&
      (!inputs.ServiceCenter ||
        !inputs.BroadspireClient ||
        !inputs.BroadspireCarrier)
    ) {
      captureReferralError({
        variables: {
          input: {
            Type: ERF_INPUT,
            Code: INVALID_INPUT_CODE,
            Message: INPUT_ERROR_MESSAGE,
            OtherInfo: INPUT_OTHERINFO_MESSAGE(
              "VALIDATION ERROR ON: Service Center, Client and/or Carrier"
            ),
          },
        },
      });
      return {
        OK: false,
        message: "Service Center, Client and Carrier are required",
      };
    }

    // Nationwide Carrier
    if (
      inputs.InsuranceCarrier === "Nationwide" &&
      (!inputs.NWCarrier ||
        !inputs.ClaimSystem ||
        !inputs.BusinessUnit ||
        !inputs.LossState ||
        !inputs.AllegedInjuries)
    ) {
      captureReferralError({
        variables: {
          input: {
            Type: ERF_INPUT,
            Code: INVALID_INPUT_CODE,
            Message: INPUT_ERROR_MESSAGE,
            OtherInfo: INPUT_OTHERINFO_MESSAGE(
              "VALIDATION ERROR ON: Nationwide Carrier, Claim System, Line of Business, Loss State and/or Alleged Injuries"
            ),
          },
        },
      });
      return {
        OK: false,
        message:
          "Nationwide Carrier, Claim System, Line of Business, Loss State and Alleged Injuries are required",
      };
    }

    // Nationwide Claim Number
    if (inputs.InsuranceCarrier === "Nationwide") {
      // NWCarrier = Scottsdale/Western Heritage & ClaimSystem = E&S Claim Center
      if (inputs.NWCarrier === "TRNWSCT" && inputs.ClaimSystem === "TRCCSCT") {
        const { OK } = validateTRNWSCTTRCCSCT(inputs.ClaimNumber, true);
        if (!OK) {
          captureReferralError({
            variables: {
              input: {
                Type: ERF_INPUT,
                Code: INVALID_INPUT_CODE,
                Message: INPUT_ERROR_MESSAGE,
                OtherInfo: INPUT_OTHERINFO_MESSAGE(
                  "VALIDATION ERROR ON: (Nationwide) Insurance Carrier => Claim Number (Wrong format)"
                ),
              },
            },
          });
          return {
            OK: false,
            message: "Wrong Claim Number format",
          };
        }
      }
      // NWCarrier = Nationwide (NW/AG/Alied/Titan) & ClaimSystem = Claim Center
      if (inputs.NWCarrier === "TRNWNW" && inputs.ClaimSystem === "TRCCNW") {
        const { OK } = validateTRNWNWTRCCNW(
          inputs.ClaimNumber,
          inputs.ClaimNumber,
          true
        );
        if (!OK) {
          captureReferralError({
            variables: {
              input: {
                Type: ERF_INPUT,
                Code: INVALID_INPUT_CODE,
                Message: INPUT_ERROR_MESSAGE,
                OtherInfo: INPUT_OTHERINFO_MESSAGE(
                  "VALIDATION ERROR ON: (Nationwide) Insurance Carrier => Claim Number (Wrong format)"
                ),
              },
            },
          });
          return {
            OK: false,
            message: "Wrong Claim Number format",
          };
        }
      }
      // NWCarrier = Harleysville & ClaimSystem = Harleysville
      if (
        inputs.NWCarrier === "TRNWHAR" &&
        inputs.ClaimSystem === "TRCCNWHAR"
      ) {
        const { OK } = validateTRNWHARTRCCNWHAR(
          inputs.ClaimNumber,
          inputs.ClaimNumber,
          true
        );
        if (!OK) {
          captureReferralError({
            variables: {
              input: {
                Type: ERF_INPUT,
                Code: INVALID_INPUT_CODE,
                Message: INPUT_ERROR_MESSAGE,
                OtherInfo: INPUT_OTHERINFO_MESSAGE(
                  "VALIDATION ERROR ON: (Nationwide) Insurance Carrier (Harleysville & ClaimSystem) => Claim Number (Wrong format)"
                ),
              },
            },
          });
          return {
            OK: false,
            message: "Wrong Claim Number format",
          };
        }
      }
      // NWCarrier = NWPC & ClaimSystem = E&S Cliam Center
      if (
        inputs.NWCarrier === "TRNWNWPC" &&
        inputs.ClaimSystem === "TRCCNWPC"
      ) {
        const { OK } = validateTRNWNWPCTRCCNWPC(inputs.ClaimNumber, true);
        if (!OK) {
          captureReferralError({
            variables: {
              input: {
                Type: ERF_INPUT,
                Code: INVALID_INPUT_CODE,
                Message: INPUT_ERROR_MESSAGE,
                OtherInfo: INPUT_OTHERINFO_MESSAGE(
                  "VALIDATION ERROR ON: (Nationwide) Insurance Carrier (Harleysville & ClaimSystem) => Claim Number (Wrong format)"
                ),
              },
            },
          });
          return {
            OK: false,
            message: "Wrong Claim Number format",
          };
        }
      }
      // NWCarrier = Other (K&K/Hagerty) & ClaimSystem = Claim Center
      if (
        inputs.NWCarrier === "TRNWOTH" &&
        inputs.ClaimSystem === "TRCCNWOTH"
      ) {
        const { OK } = validateTRNWOTHTRCCNWOTH(inputs.ClaimNumber, true);
        if (!OK) {
          captureReferralError({
            variables: {
              input: {
                Type: ERF_INPUT,
                Code: INVALID_INPUT_CODE,
                Message: INPUT_ERROR_MESSAGE,
                OtherInfo: INPUT_OTHERINFO_MESSAGE(
                  "VALIDATION ERROR ON: (Nationwide) Insurance Carrier  (Other (K&K/Hagerty) & ClaimSystem) => Claim Number (Wrong format)"
                ),
              },
            },
          });
          return {
            OK: false,
            message: "Wrong Claim Number format",
          };
        }
      }
    }

    // Sentry Carrier
    if (inputs.InsuranceCarrier === "Sentry" && !inputs.RRClaimantLetter) {
      captureReferralError({
        variables: {
          input: {
            Type: ERF_INPUT,
            Code: INVALID_INPUT_CODE,
            Message: INPUT_ERROR_MESSAGE,
            OtherInfo: INPUT_OTHERINFO_MESSAGE(
              "VALIDATION ERROR ON: Claimant Letter (Sentry Insurance Carrier)"
            ),
          },
        },
      });
      return {
        OK: false,
        message: "Claimant Letter is required",
      };
    }

    // USAA Carrier
    if (inputs.InsuranceCarrier?.includes("USAA")) {
      const emailRegex = /^[\w-]+(?:\.[\w-]+)*@(?:[\w-]+\.)+[a-zA-Z]{2,}$/;
      const missingFields = [];
      if (!inputs.DateOfLoss) missingFields.push("Date Of Loss");

      if (inputs.InsuranceCarrier === "USAA Claims") {
        if (!inputs.RRclaimOffice_us) missingFields.push("USAA Claim Office");
        if (!inputs.RRmemberNumber_us) missingFields.push("USAA Member Number");
        if (!inputs.RRlossReportNumber_us)
          missingFields.push("USAA Loss Report Number");
        if (!inputs.RRclaimsLossEmail_us)
          missingFields.push("USAA Claims Loss Email");
        if (!emailRegex.test(inputs.RRclaimsLossEmail_us))
          return {
            OK: false,
            message: `USAA Claims Loss Email must be a valid email address`,
          };
      }

      if (
        inputs.InsuranceCarrier === "USAA Legal" &&
        !inputs.RRLegalOffice_us
      ) {
        missingFields.push("USAA Legal Office");
      }

      if (missingFields.length > 0) {
        const finalErrorMessage = missingFields.join(", ");
        captureReferralError({
          variables: {
            input: {
              Type: ERF_INPUT,
              Code: INVALID_INPUT_CODE,
              Message: INPUT_ERROR_MESSAGE,
              OtherInfo: INPUT_OTHERINFO_MESSAGE(
                `VALIDATION ERROR ON: ${finalErrorMessage} missing for USAA`
              ),
            },
          },
        });
        return {
          OK: false,
          message: `${finalErrorMessage} ${
            missingFields.length > 1 ? "are" : "is"
          } required`,
        };
      }
    }

    // Billing Contact
    if (!inputs.BillingContact) {
      captureReferralError({
        variables: {
          input: {
            Type: ERF_INPUT,
            Code: INVALID_INPUT_CODE,
            Message: INPUT_ERROR_MESSAGE,
            OtherInfo: INPUT_OTHERINFO_MESSAGE(
              "VALIDATION ERROR ON: Billing Contact"
            ),
          },
        },
      });
      return {
        OK: false,
        message: "Billing Contact is a required field",
      };
    }

    // Record Providers
    if (!inputs.Providers.filter((p) => !p.deleted).length) {
      captureReferralError({
        variables: {
          input: {
            Type: ERF_INPUT,
            Code: INVALID_INPUT_CODE,
            Message: INPUT_ERROR_MESSAGE,
            OtherInfo: INPUT_OTHERINFO_MESSAGE(
              "VALIDATION ERROR ON: Provider -- Require at least ONE."
            ),
          },
        },
      });
      return {
        OK: false,
        message: "You must add at least one record provider",
      };
    }

    if (inputs.Providers.some((p) => !p.deleted && p.Provider === "")) {
      captureReferralError({
        variables: {
          input: {
            Type: ERF_INPUT,
            Code: INVALID_INPUT_CODE,
            Message: INPUT_ERROR_MESSAGE,
            OtherInfo: INPUT_OTHERINFO_MESSAGE("VALIDATION ERROR ON: Provider"),
          },
        },
      });
      return {
        OK: false,
        message: "Provider is a required field",
      };
    }

    if (
      inputs.Providers.some(
        (p) =>
          !p.deleted &&
          p.RequestDates === "dateRange" &&
          (!p.RetrieveStartDate || (!p.RetrieveEndDate && !p.RetrievePresent))
      )
    ) {
      captureReferralError({
        variables: {
          input: {
            Type: ERF_INPUT,
            Code: INVALID_INPUT_CODE,
            Message: INPUT_ERROR_MESSAGE,
            OtherInfo: INPUT_OTHERINFO_MESSAGE(
              "VALIDATION ERROR ON: Providers => You have selected to retrieve specific dates. Please enter a start and end date for your request."
            ),
          },
        },
      });
      return {
        OK: false,
        message:
          "You have selected to retrieve specific dates. Please enter a start and end date for your request.",
      };
    }

    if (
      inputs.DateOfLoss === "" &&
      inputs.Providers.some((p) => !p.deleted && p.RequestDates === "FromDOL")
    ) {
      captureReferralError({
        variables: {
          input: {
            Type: ERF_INPUT,
            Code: INVALID_INPUT_CODE,
            Message: INPUT_ERROR_MESSAGE,
            OtherInfo: INPUT_OTHERINFO_MESSAGE(
              "VALIDATION ERROR ON: Providers => DOL to Present selected but no DOL provided."
            ),
          },
        },
      });
      return {
        OK: false,
        message:
          "Requested Dates option selected is Date of Loss To Present. Please enter a Date Of Loss.",
      };
    }

    if (
      inputs.SubService === "RR" &&
      inputs.Providers.some(
        (prov) =>
          !prov.deleted &&
          prov?.RecordType?.includes(284) &&
          !prov.SpecialInstructions
      )
    ) {
      captureReferralError({
        variables: {
          input: {
            Type: ERF_INPUT,
            Code: INVALID_INPUT_CODE,
            Message: INPUT_ERROR_MESSAGE,
            OtherInfo: INPUT_OTHERINFO_MESSAGE(
              "VALIDATION ERROR ON: Special Instructions (required)"
            ),
          },
        },
      });
      return {
        OK: false,
        message: "Special Instructions is a required field",
      };
    }

    if (activeProviderWithoutRecordType(inputs)) {
      captureReferralError({
        variables: {
          input: {
            Type: ERF_INPUT,
            Code: INVALID_INPUT_CODE,
            Message: INPUT_ERROR_MESSAGE,
            OtherInfo: INPUT_OTHERINFO_MESSAGE(
              "VALIDATION ERROR ON: Record Types => You must select at least one record type for each record provider."
            ),
          },
        },
      });
      return {
        OK: false,
        message:
          "You must select at least one record type for each record provider",
      };
    }

    // Represented Party
    if (
      inputs.Providers.some(
        (prov) => !prov.deleted && prov.RequestMethod === 2
      ) &&
      !inputs.RepresentedParty
    ) {
      captureReferralError({
        variables: {
          input: {
            Type: ERF_INPUT,
            Code: INVALID_INPUT_CODE,
            Message: INPUT_ERROR_MESSAGE,
            OtherInfo: INPUT_OTHERINFO_MESSAGE(
              "VALIDATION ERROR ON: Represented Party (required)"
            ),
          },
        },
      });
      return {
        OK: false,
        message: "Represented Party is a required field",
      };
    }

    // Contact Name
    if (
      !rrId &&
      !subpoenaInfo.ContactName &&
      inputs?.Providers?.some((prov) => prov.RequestMethod === 1)
    ) {
      // Request Method: ISG Retrieve Authorization from opposing counsel
      captureReferralError({
        variables: {
          input: {
            Type: ERF_INPUT,
            Code: INVALID_INPUT_CODE,
            Message: INPUT_ERROR_MESSAGE,
            OtherInfo: INPUT_OTHERINFO_MESSAGE(
              "VALIDATION ERROR ON: Contact Name (required)"
            ),
          },
        },
      });
      return {
        OK: false,
        message: "Contact Name is a required field",
      };
    }

    // fields required if at least one Provider has Non Party Request selected
    if (checkProviderNonPartyRequest) {
      const { error, message, missingCount } = checkProvReqMethod(subpoenaInfo);

      if (error) {
        captureReferralError({
          variables: {
            input: {
              Type: ERF_INPUT,
              Code: INVALID_INPUT_CODE,
              Message: INPUT_ERROR_MESSAGE,
              OtherInfo: INPUT_OTHERINFO_MESSAGE(
                `VALIDATION ERROR ON: Provider Request Method = Non Party. ${message} missing`
              ),
            },
          },
        });
        return {
          OK: false,
          message: `A Provider Request Method is 'Non Party Request.' ${message} ${
            missingCount >= 2 ? "are" : "is"
          } required`,
        };
      }
    }

    // Claim Type
    if (!subpoenaInfo.ClaimType) {
      captureReferralError({
        variables: {
          input: {
            Type: ERF_INPUT,
            Code: INVALID_INPUT_CODE,
            Message: INPUT_ERROR_MESSAGE,
            OtherInfo: INPUT_OTHERINFO_MESSAGE(
              "VALIDATION ERROR ON: Claim Type (required)"
            ),
          },
        },
      });
      return {
        OK: false,
        message: "Claim Type is a required field",
      };
    }

    // Authorization / Supoena Information
    if (
      inputs.Providers.some((prov) => !prov.deleted && prov.RequestMethod === 2)
    ) {
      const { error, message, missingCount } = checkProvReqMethod(subpoenaInfo);

      if (error) {
        captureReferralError({
          variables: {
            input: {
              Type: ERF_INPUT,
              Code: INVALID_INPUT_CODE,
              Message: INPUT_ERROR_MESSAGE,
              OtherInfo: INPUT_OTHERINFO_MESSAGE(
                `VALIDATION ERROR ON: Subpoena for records: ${message} missing`
              ),
            },
          },
        });
        return {
          OK: false,
          message: `A Provider Request Method is 'Subpoena For Records.' ${message} ${
            missingCount >= 2 ? "are" : "is"
          } required`,
        };
      }
    }

    // Counsels
    if (inputs.Counsels.length) {
      if (
        inputs.Counsels.some(
          (counsel) => !counsel.deleted && !counsel.AdditionalCounsel
        )
      ) {
        captureReferralError({
          variables: {
            input: {
              Type: ERF_INPUT,
              Code: INVALID_INPUT_CODE,
              Message: INPUT_ERROR_MESSAGE,
              OtherInfo: INPUT_OTHERINFO_MESSAGE(
                "VALIDATION ERROR ON: Additional Counsel (required)"
              ),
            },
          },
        });
        return {
          OK: false,
          message: "Additional Counsel is a required field",
        };
      }

      if (
        inputs.Counsels.some(
          (counsel) => !counsel.deleted && !counsel.Representing
        )
      ) {
        captureReferralError({
          variables: {
            input: {
              Type: ERF_INPUT,
              Code: INVALID_INPUT_CODE,
              Message: INPUT_ERROR_MESSAGE,
              OtherInfo: INPUT_OTHERINFO_MESSAGE(
                "VALIDATION ERROR ON: (Counsels) Representing (required)"
              ),
            },
          },
        });
        return {
          OK: false,
          message: "Representing is a required field",
        };
      }
    }

    // clientcode check
    if (!inputs?.clientcode) {
      captureReferralError({
        variables: {
          input: {
            Type: ERF_SUBMISSION,
            Code: SUBMISSION_FAIL_CODE,
            Message: SUBMISSION_ERROR_MESSAGE,
            OtherInfo: SUBMISSION_OTHERINFO_MESSAGE(
              "No client code found. ERR CODE: clientcode NULL"
            ),
          },
        },
      });
      return {
        OK: false,
        message:
          "Please contact your administrator for assistance. ERR CODE: clientcode NULL",
      };
    }

    return { OK: true };
  };

  const canSubmitReferralForm = () => {
    const dolValidationResult = dolValidations(inputs.DateOfLoss);
    if (dolValidationResult.error) {
      captureReferralError({
        variables: {
          input: {
            Type: ERF_INPUT,
            Code: INVALID_INPUT_CODE,
            Message: INPUT_ERROR_MESSAGE,
            OtherInfo: INPUT_OTHERINFO_MESSAGE(
              "VALIDATION ERROR ON: Date of Loss/Injury"
            ),
          },
        },
      });
      return {
        OK: false,
        message: `'Date of Loss/Injury' ${dolValidationResult.message}. Please clear your selection and try again.`,
      };
    }

    // First check product:
    if (!inputs.product) {
      captureReferralError({
        variables: {
          input: {
            Type: ERF_INPUT,
            Code: INVALID_INPUT_CODE,
            Message: INPUT_ERROR_MESSAGE,
            OtherInfo: INPUT_OTHERINFO_MESSAGE(
              "VALIDATION ERROR ON: Product - no product selected"
            ),
          },
        },
      });
      return {
        OK: false,
        message:
          "A product selection is required before attempting to submit your referral.",
      };
    }

    // Referrer validations
    if (
      !inputs.FirstName ||
      !inputs.LastName ||
      !inputs.CompanyName ||
      !inputs.Email
    ) {
      const instructions = [];
      if (!inputs.FirstName) instructions.push("First Name");
      if (!inputs.LastName) instructions.push("Last Name");
      if (!inputs.CompanyName) instructions.push("Company Name");
      if (!inputs.Email) instructions.push("Email");

      const finalMessage = instructions.join(", ");
      captureReferralError({
        variables: {
          input: {
            Type: ERF_INPUT,
            Code: INVALID_INPUT_CODE,
            Message: INPUT_ERROR_MESSAGE,
            OtherInfo: INPUT_OTHERINFO_MESSAGE(
              `VALIDATION ERROR ON: Referrer - ${finalMessage} missing `
            ),
          },
        },
      });
      return {
        OK: false,
        message: `The following referrer information is required: ${finalMessage}`,
      };
    }

    // Claim validations
    const teamConnectIDExists = teamConnectIDData?.company?.TeamConnectID;

    if (/^medical management|investigations$/gi.test(inputs?.product)) {
      if (/^investigations$/gi.test(inputs?.product)) {
        if (
          !inputs.ClaimNumber ||
          !inputs.DateOfLoss ||
          !inputs.Insured ||
          (companyCode && teamConnectIDExists && !inputs?.FileNumber)
        ) {
          const instructions = [];
          if (!inputs.ClaimNumber) instructions.push("Claim Number");
          if (!inputs.DateOfLoss) instructions.push("Date of Loss");
          if (!inputs.Insured) instructions.push("Insured");
          if (companyCode && teamConnectIDExists && !inputs?.FileNumber)
            instructions.push("Legal File Number");

          const finalMessage = instructions.join(", ");

          captureReferralError({
            variables: {
              input: {
                Type: ERF_INPUT,
                Code: INVALID_INPUT_CODE,
                Message: INPUT_ERROR_MESSAGE,
                OtherInfo: INPUT_OTHERINFO_MESSAGE(
                  `VALIDATION ERROR ON: Claim Validations - ${finalMessage} missing`
                ),
              },
            },
          });
          return {
            OK: false,
            message: `The following claim information is required: ${finalMessage}`,
          };
        }

        // 11 is option "Surveillance" under Services Requested
        if (
          inputs.ServicesRequested.some((service) => service.value === "11") &&
          !surveillanceActivityCheck.SurveillanceAmount
        ) {
          captureReferralError({
            variables: {
              input: {
                Type: ERF_INPUT,
                Code: INVALID_INPUT_CODE,
                Message: INPUT_ERROR_MESSAGE,
                OtherInfo: INPUT_OTHERINFO_MESSAGE(
                  "VALIDATION ERROR ON: Surveillance Amount -- User chose 'Surveillance' under Services Requested and did not choose a Surveillance Amount."
                ),
              },
            },
          });

          return {
            OK: false,
            message:
              "You must select a Surveillance Amount when Surveillance is listed under Services Requested.",
          };
        }
      }

      if (/^medical management$/gi.test(inputs?.product) && !inputs?.CaseType) {
        captureReferralError({
          variables: {
            input: {
              Type: ERF_INPUT,
              Code: INVALID_INPUT_CODE,
              Message: INPUT_ERROR_MESSAGE,
              OtherInfo: INPUT_OTHERINFO_MESSAGE(
                "VALIDATION ERROR ON: Type of Case - not provided."
              ),
            },
          },
        });
        return {
          OK: false,
          message: "Type of Case is a required field.",
        };
      }

      if (
        /^medical management$/gi.test(inputs?.product) &&
        isGeicoMarltonOffice
      ) {
        if (
          !inputs.ClaimNumber ||
          !inputs.DateOfLoss ||
          !inputs.Insured ||
          !inputs.Venue ||
          !inputs.AttSecFname ||
          !inputs.AttSecLname ||
          !inputs.GeicoClaimRepFname ||
          !inputs.GeicoClaimRepLname ||
          (companyCode && teamConnectIDExists && !inputs?.FileNumber)
        ) {
          const instructions = [];
          const requiredFieldsMap = {
            ClaimNumber: "Claim Number",
            DateOfLoss: "Date of Loss",
            Insured: "Insured",
            Venue: "Venue",
            AttSecFname: "Attorney/Secretary First Name",
            AttSecLname: "Attorney/Secretary Last Name",
            GeicoClaimRepFname: "Geico Claim Rep First Name",
            GeicoClaimRepLname: "Geico Claim Rep Last Name",
          };
          for (const field in requiredFieldsMap) {
            if (!inputs[field]) instructions.push(requiredFieldsMap[field]);
          }
          if (companyCode && teamConnectIDExists && !inputs?.FileNumber)
            instructions.push("Legal File Number");
          const finalMessage = instructions.join(", ");

          captureReferralError({
            variables: {
              input: {
                Type: ERF_INPUT,
                Code: INVALID_INPUT_CODE,
                Message: INPUT_ERROR_MESSAGE,
                OtherInfo: INPUT_OTHERINFO_MESSAGE(
                  `VALIDATION ERROR ON: Claim Validations, GeicoMarlton - ${finalMessage} missing`
                ),
              },
            },
          });
          return {
            OK: false,
            message: `The following claim information is required: ${finalMessage}`,
          };
        }
      }
    }

    if (/^medical management$/gi.test(inputs?.product) && !inputs?.DateOfLoss) {
      return {
        OK: false,
        message:
          "Invalid format on 'Date Of Loss/Injury'. Must be: MM/DD/YYYY. Please correct this date before submitting.",
      };
    }

    if (
      /^medical management$/gi.test(inputs?.product) &&
      (!inputs?.DateOfLoss ||
        !inputs?.Venue ||
        !inputs?.ClaimNumber ||
        !inputs?.Insured)
    ) {
      const instructions = [];
      if (!inputs?.DateOfLoss) instructions.push("Date Of Loss");
      if (!inputs?.Venue) instructions.push("Venue");
      if (!inputs?.ClaimNumber) instructions.push("Claim Number");
      if (!inputs?.Insured) instructions.push("Insured");
      const finalMessage = instructions.join(", ");
      captureReferralError({
        variables: {
          input: {
            Type: ERF_INPUT,
            Code: INVALID_INPUT_CODE,
            Message: INPUT_ERROR_MESSAGE,
            OtherInfo: INPUT_OTHERINFO_MESSAGE(
              `VALIDATION ERROR ON: Claim Info, MM - ${finalMessage} missing`
            ),
          },
        },
      });
      return {
        OK: false,
        message: `The following claim information is required: ${finalMessage}.`,
      };
    }

    // Geico Claim Number Length Requirement
    if (isGeicoOffice && inputs.ClaimNumber.length !== geicoClaimLength) {
      captureReferralError({
        variables: {
          input: {
            Type: ERF_INPUT,
            Code: INVALID_INPUT_CODE,
            Message: INPUT_ERROR_MESSAGE,
            OtherInfo: INPUT_OTHERINFO_MESSAGE(
              "VALIDATION ERROR ON: Claim Number - Geico length requirement"
            ),
          },
        },
      });
      return {
        OK: false,
        message: `Claim number is required to be ${geicoClaimLength} characters for Geico`,
      };
    }

    // Claimant validations
    if (/^medical management|investigations$/gi.test(inputs?.product)) {
      if (
        /^investigations$/gi.test(inputs?.product) &&
        (!inputs.ClaimantFirstName || !inputs.ClaimantLastName)
      ) {
        captureReferralError({
          variables: {
            input: {
              Type: ERF_INPUT,
              Code: INVALID_INPUT_CODE,
              Message: INPUT_ERROR_MESSAGE,
              OtherInfo: INPUT_OTHERINFO_MESSAGE(
                "VALIDATION ERROR ON: Claimant - First or Last Name missing"
              ),
            },
          },
        });
        return {
          OK: false,
          message:
            "The following claimant information is required: First Name, Last Name",
        };
      }
      if (
        /^medical management$/gi.test(inputs?.product) &&
        (!inputs.ClaimantFirstName ||
          !inputs.ClaimantLastName ||
          (!inputs.ClaimantDateOfBirth && isGeicoMarltonOffice))
      ) {
        const instructions = [];
        if (!inputs?.ClaimantFirstName)
          instructions.push("Claimant First Name");
        if (!inputs?.ClaimantLastName) instructions.push("Claimant Last Name");
        if (!inputs.ClaimantDateOfBirth && isGeicoMarltonOffice)
          instructions.push("Date of Birth");
        const finalMessage = instructions.join(", ");
        captureReferralError({
          variables: {
            input: {
              Type: ERF_INPUT,
              Code: INVALID_INPUT_CODE,
              Message: INPUT_ERROR_MESSAGE,
              OtherInfo: INPUT_OTHERINFO_MESSAGE(
                `VALIDATION ERROR ON: Claimant - ${finalMessage}`
              ),
            },
          },
        });
        return {
          OK: false,
          message: `The following claimant information is required: First Name, Last Name, ${
            isGeicoMarltonOffice ? "and Date Of Birth" : ""
          }`,
        };
      }
    }

    // This section specifically for Defense Attorney and Adjuster Information forms when a TeamConnectID is found.
    if (teamConnectIDExists) {
      if (!defenseAttorney.FirstName || !defenseAttorney.LastName) {
        captureReferralError({
          variables: {
            input: {
              Type: ERF_INPUT,
              Code: INVALID_INPUT_CODE,
              Message: INPUT_ERROR_MESSAGE,
              OtherInfo: INPUT_OTHERINFO_MESSAGE(
                "VALIDATION ERROR ON: First Name, Last Name on Defense Attorney. NOTE: This happens when a TeamConnectID is found and a user does not fill in the Defense Attorney First Name and/or Last Name."
              ),
            },
          },
        });
        return {
          OK: false,
          message:
            "The following information for Defense Attorney is required: First Name and Last Name",
        };
      }
      if (!adjusterInformation.FirstName || !adjusterInformation.LastName) {
        captureReferralError({
          variables: {
            input: {
              Type: ERF_INPUT,
              Code: INVALID_INPUT_CODE,
              Message: INPUT_ERROR_MESSAGE,
              OtherInfo: INPUT_OTHERINFO_MESSAGE(
                "VALIDATION ERROR ON: First Name, Last Name on Adjuster Information. NOTE: This happens when a TeamConnectID is found and a user does not fill in the Adjuster Information First Name and/or Last Name."
              ),
            },
          },
        });
        return {
          OK: false,
          message:
            "The following information for Adjuster Information is required: First Name and Last Name",
        };
      }
    }

    // if (
    //   /^medical management$/gi.test(inputs?.product) &&
    //   isGeicoMarltonOffice &&
    //   !doctorNotFound
    // ) {
    //   if (!inputs.doctorcode) {
    //     captureReferralError({
    //       variables: {
    //         input: {
    //           Type: ERF_INPUT,
    //           Code: INVALID_INPUT_CODE,
    //           Message: INPUT_ERROR_MESSAGE,
    //           OtherInfo: INPUT_OTHERINFO_MESSAGE(
    //             `VALIDATION ERROR ON: No Doctor Code provided - GeicoMarlton`
    //           ),
    //         },
    //       },
    //     });
    //     return {
    //       OK: false,
    //       message: "Please select a Requested Doctor under Services Requested.",
    //     };
    //   }
    // }

    // Service validations
    if (/^medical management|investigations$/gi.test(inputs.product)) {
      if (inputs.ServicesRequested.length === 0) {
        captureReferralError({
          variables: {
            input: {
              Type: ERF_INPUT,
              Code: INVALID_INPUT_CODE,
              Message: INPUT_ERROR_MESSAGE,
              OtherInfo: INPUT_OTHERINFO_MESSAGE(
                "VALIDATION ERROR ON: Services Requested - no option selected."
              ),
            },
          },
        });
        return {
          OK: false,
          message: "Please select at least one Service",
        };
      }
    }

    // Geico Investigations Carrier Required Fields -- ToDo, find a good way to provide better feedback
    if (
      isGeicoInvestigationsRequirement &&
      (!inputs.ClaimantDateOfBirth ||
        !inputs.ClaimantAddress1 ||
        !inputs.ClaimantCity ||
        !inputs.ClaimantState ||
        !inputs.ClaimantZip ||
        !problems.length ||
        (additionalInformation.Adjuster && !adjusterInformation.Phone))
    ) {
      const instructions = [];
      const reqInputFields = {
        ClaimantDateOfBirth: "Claimant Date of Birth",
        ClaimantAddress1: "Claimant Address",
        ClaimantCity: "Claimant City",
        ClaimantState: "Claimant State",
        ClaimantZip: "Claimant Zip",
      };

      for (const field in inputs) {
        if (reqInputFields[field] && !inputs[field])
          instructions.push(reqInputFields[field]);
      }

      if (!problems.length) instructions.push("Physical Problems");

      if (additionalInformation.Adjuster && !adjusterInformation.Phone)
        instructions.push("Adjuster Phone");

      const finalMessage = instructions.join(", ");

      captureReferralError({
        variables: {
          input: {
            Type: ERF_INPUT,
            Code: INVALID_INPUT_CODE,
            Message: INPUT_ERROR_MESSAGE,
            OtherInfo: INPUT_OTHERINFO_MESSAGE(
              `VALIDATION ERROR ON: Geico Investigations, ${finalMessage}`
            ),
          },
        },
      });
      return {
        OK: false,
        message: `Geico Investigations: Please fill in ${finalMessage}`,
      };
    }

    return { OK: true };
  };

  let refMethod = "web";
  if (referrerParam) {
    refMethod = parseBase64(referrerParam).toLowerCase();
  }

  const getRefMethodNumber = (method) => {
    if (method === "myclient") return 73;
    if (method === "myisg") return 74;
    if (method === "myisg2.0") return 76;
    return 35;
  };

  const marketeruserid = marketerUserIDParam;

  const handleSubmitReferral = async (
    isCreatingNewReferralOnSave,
    isSavingClaimInfo
  ) => {
    try {
      const opDate = convertTimeZoneDataBase(getDateEST());

      const checkForm = canSubmitReferralForm();

      if (checkForm.OK) {
        // const handleDiscoveryEndDate = () => {
        //   const discoveryEndDate = inputs?.DiscoveryEndDate || investigativeServiceInstructions?.DiscoveryEndDate;
        //   if (discoveryEndDate) {
        //     return convertTimeZoneDataBase(discoveryEndDate);
        //   }
        //   return '';
        // };

        // const handleDateExamNeededBy = () => {
        //   const dateExamNeededBy = inputs?.DateExamNeededBy;
        //   if (dateExamNeededBy) {
        //     return convertTimeZoneDataBase(dateExamNeededBy);
        //   }
        //   return '';
        // };

        const handleDiscoveryEndDate = () => {
          const discoveryEndDate =
            inputs?.DiscoveryEndDate ||
            investigativeServiceInstructions?.DiscoveryEndDate;
          if (discoveryEndDate) {
            return convertTimeZone(
              new Date(discoveryEndDate.split("-").map((x) => parseInt(x, 10)))
            );
          }
          return "";
        };

        const handleDateExamNeededBy = () => {
          const dateExamNeededBy = inputs?.DateExamNeededBy;
          if (dateExamNeededBy) {
            return convertTimeZone(
              new Date(dateExamNeededBy.split("-").map((x) => parseInt(x, 10)))
            );
          }
          return "";
        };

        if (rrId) {
          inputs.Providers.map(async (provider) => {
            if (provider.fromDB && !provider.DateSubmitted) {
              await updateRecordProvider({
                variables: {
                  data: {
                    RRsubmitteddate: { set: opDate },
                  },
                  where: {
                    RPid: parseInt(provider.ProdviderID, 10),
                  },
                },
              });
            }
          });
        }

        const {
          data: { submitReferral: result },
        } = await submitReferral({
          variables: {
            sourceId,
            input: {
              marketeremail: inputs?.marketeremail,
              clientcode: inputs?.clientcode || DEFAULT_CLIENT_CODE,
              doctorcode: inputs?.doctorcode || null,
              product: inputs?.product,
              LegalFileNumber: inputs?.FileNumber,
              // Referrer
              FirstName: inputs.FirstName,
              LastName: inputs.LastName,
              CompanyName: inputs.CompanyName,
              City: inputs.City,
              State: inputs.State,
              Phone: inputs.Phone,
              Email: inputs.Email,
              marketeruserid: marketeruserid || "WebClient",

              // Claim
              CaseType: inputs.CaseType || null,
              ClaimNumber: inputs.ClaimNumber,
              Insured: inputs.Insured,
              DateOfLoss: inputs?.DateOfLoss
                ? convertTimeZone(
                    new Date(
                      inputs.DateOfLoss.split("-").map((x) => parseInt(x, 10))
                    )
                  )
                : "",
              Jurisdiction: inputs.Jurisdiction,
              Venue: inputs.Venue,

              // Claimant
              ClaimantFirstName: inputs.ClaimantFirstName,
              ClaimantLastName: inputs.ClaimantLastName,
              ClaimantAddress1: inputs.ClaimantAddress1,
              ClaimantAddress2: inputs.ClaimantAddress2,
              ClaimantCity: inputs.ClaimantCity,
              ClaimantState: inputs.ClaimantState,
              ClaimantZip: inputs.ClaimantZip,
              ClaimantEmail: inputs.ClaimantEmail,
              ClaimantPhone: inputs.ClaimantPhone,
              ClaimantCellPhone: inputs.ClaimantCellPhone,
              ClaimantDateOfBirth: inputs.ClaimantDateOfBirth
                ? convertTimeZone(
                    new Date(
                      inputs.ClaimantDateOfBirth.split("-").map((x) =>
                        parseInt(x, 10)
                      )
                    )
                  )
                : "",
              ClaimantGender: inputs.ClaimantGender,
              ClaimantMaritalStatus: inputs.ClaimantMaritalStatus,
              ClaimantSSN: inputs.ClaimantSSN,
              ClaimantSpecialPhysical: inputs.ClaimantSpecialPhysical,

              // Case
              RequestedSpecialty: inputs?.RequestedSpecialty || "",
              ServicesRequested: inputs.ServicesRequested.map(({ value }) =>
                parseInt(value, 10)
              ),
              ServiceInstructions: inputs.ServiceInstructions,
              Problems: problems.map(({ value }) => parseInt(value, 10)),
              Subrogation: additionalInformation.PartOfSubrogation,
              SurveillanceHours: parseInt(
                surveillanceActivityCheck.OtherAmount ||
                  surveillanceActivityCheck.SurveillanceAmount,
                10
              ),
              referralmethod: getRefMethodNumber(refMethod),
              DateReportNeeded:
                investigativeServiceInstructions.DateReportNeeded
                  ? convertTimeZoneDataBase(
                      investigativeServiceInstructions.DateReportNeeded
                    )
                  : "",
              DiscoveryEndDate: handleDiscoveryEndDate() || "",
              DateExamNeededBy: handleDateExamNeededBy() || "",
              AppointmentDate: surveillanceActivityCheck.AppointmentDate,

              // Notes
              Notes: createNonRecordsNotes(),
              NotesPDF: createNonRecordsNotesForPDF(),
              UsdText1: createUsdText1(),
            },
            output: {
              sourceId,
              claimId,
              claimantId,
            },
          },
        });

        if (result.OK) {
          await addToLinkServiceQueue(
            result.ReferenceNumbers,
            retainClaimClaimantInformation,
            "NonRecords"
          );
          portalRefresh(
            inputs.product === MEDICAL_MANAGEMENT ? "MM" : "IS",
            result.ReferenceNumbers
          );
          if (result.Errors.length) {
            result.Errors.forEach((err) => {
              captureReferralError({
                variables: {
                  input: {
                    Type: ERF_SUBMISSION,
                    Code: SUBMISSION_FAIL_CODE,
                    Message: SUBMISSION_ERROR_MESSAGE,
                    OtherInfo: INPUT_OTHERINFO_MESSAGE(
                      `SUBMISSION ERROR: Details => ${err}`
                    ),
                  },
                },
              });
              toast.error(err);
            });
          }
          if (files.length) {
            await uploadFiles(result.DocumentPaths, "nonrecords");
          }
          if (!isCreatingNewReferralOnSave) {
            history.push("/confirmation?method=submit");
          } else if (isCreatingNewReferralOnSave && !isSavingClaimInfo) {
            // Has to be set this way since those two inputs are nested deeper in their own components
            setInputs((prev) => ({
              ...prev,
              ClaimantFirstName: "",
              ClaimantLastName: "",
              ClaimantEmail: "",
              ClaimantAddress1: "",
              ClaimantAddress2: "",
              ClaimantPhone: "",
              ClaimantCellPhone: "",
              ClaimantCity: "",
              ClaimantState: "",
              ClaimantZip: "",
              ClaimantDateOfBirth: "",
              ClaimantGender: "",
              ClaimantMaritalStatus: "",
              ClaimantSSN: "",
              ClaimantSpecialPhysical: "",
            }));
            document.querySelector(`input[name="ClaimNumber"]`).value = "";
            document.querySelector(`input[name="ClaimantLastName"]`).value = "";
            document.querySelector(`input[name="DateOfLoss"]`).value = "";
          }
          toast.erfsuccess("Case successfully submitted");
          if (window.parent) {
            window.parent.postMessage("submit", "*");
          }

          // reset MM instructions
          setInputs((prev) => ({
            ...prev,
            MedicalManagementSpecialInstructions: "",
          }));
        } else {
          captureReferralError({
            variables: {
              input: {
                Type: ERF_SUBMISSION,
                Code: SUBMISSION_FAIL_CODE,
                Message: SUBMISSION_ERROR_MESSAGE,
                OtherInfo: INPUT_OTHERINFO_MESSAGE(
                  `SUBMISSION ERROR: Details: Unknown... Occurred on submitReferral() function`
                ),
              },
            },
          });
        }
      } else {
        toast.warning(checkForm.message);
        captureReferralError({
          variables: {
            input: {
              Type: ERF_INPUT,
              Code: INVALID_INPUT_CODE,
              Message: INPUT_ERROR_MESSAGE,
              OtherInfo: INPUT_OTHERINFO_MESSAGE(
                `LOCATION: handleSubmitReferral() => canSubmitReferralForm() -- VALIDATION ERRORS: ${checkForm.message}`
              ),
            },
          },
        });
      }
    } catch (err) {
      captureReferralError({
        variables: {
          input: {
            Type: ERF_SUBMISSION,
            Code: SUBMISSION_FAIL_CODE,
            Message: SUBMISSION_ERROR_MESSAGE,
            OtherInfo: INPUT_OTHERINFO_MESSAGE(
              `SUBMISSION ERROR try/catch BLOCK: => ${err}`
            ),
          },
        },
      });
      toast.error(
        `There was an error submitting your referral. Details: ${err}`
      );
    }
  };

  const uploadFiles = async (paths, formmode, method) => {
    setUploadingFiles(true);
    const opDate = convertTimeZoneDataBase(getDateEST());
    await Promise.all(
      files.map(async (file, i) => {
        try {
          const formData = new FormData();
          formData.append("file", file);

          await Promise.all(
            paths.map(async (dirPath) => {
              await axios.post(
                `${REACT_APP_DOCUMENT_HOST}/${dirPath}`,
                formData,
                {
                  headers: { "Content-Type": "multipart/form-data" },
                  onUploadProgress: (progress) => {
                    const { total, loaded } = progress;
                    const totalSizeInMB = total / 1000000;
                    const loadedSizeInMB = loaded / 1000000;
                    const currentUploadPercentage =
                      (loadedSizeInMB / totalSizeInMB) * 100;
                    setUploadPercentage(currentUploadPercentage.toFixed(2));
                  },
                }
              );

              if (formmode === "nonrecords") {
                await attachCaseDocument({
                  variables: {
                    data: {
                      casenbr: parseInt(
                        dirPath.substr(dirPath.lastIndexOf("/") + 1),
                        10
                      ),
                      document: "external",
                      reporttype: "Document",
                      description: "Referral Form Attachment",
                      type: "Letter",
                      sfilename: file.name,
                      dateadded: convertTimeZoneDataBase(getDateEST()),
                      useridadded: "Web User",
                      documenttype: 2,
                    },
                  },
                });
              } else if (formmode === "records") {
                await createRecordUpload({
                  variables: {
                    data: {
                      RUrecordrequestID: parseInt(
                        dirPath.substr(dirPath.lastIndexOf("/") + 1),
                        10
                      ),
                      RUpath: `medrec/MR Files/WEST-PITTSBURGH/UploadedAuths/${dirPath.substr(
                        dirPath.lastIndexOf("/") + 1
                      )}`,
                      RUuploadedDate: opDate,
                      RUworkrequestID: "",
                      file_name: file.name,
                    },
                  },
                });
                if (method === "submit" || isRecordsReferralSubmitted) {
                  await updateRecordRequest({
                    variables: {
                      data: {
                        RRsubmitteddate: {
                          set: opDate,
                        },
                        RRmodifiedDate: {
                          set: opDate,
                        },
                      },
                      where: {
                        RRid: parseInt(
                          dirPath.substr(dirPath.lastIndexOf("/") + 1),
                          10
                        ),
                      },
                    },
                  });
                }
              }
            })
          );
        } catch (err) {
          const message =
            err instanceof CustomFileUploaderError
              ? err.message
              : "There was an error uploading this file. Please try again.";
          toast.error(message);
          captureReferralError({
            variables: {
              input: {
                Type: ERF_UPLOAD,
                Code: FILE_UPLOAD_FAIL,
                Message: UPLOAD_ERROR_MESSAGE,
                OtherInfo: UPLOAD_OTHERINFO_MESSAGE(
                  `ERROR DETAILS: uploadFiles func => ${err}`
                ),
              },
            },
          });
        }
      })
    );
    setFiles([]);
    setUploadingFiles(false);
  };

  const createUsdText1 = () => {
    if (/^medical management$/gi.test(inputs.product)) {
      return createMedicalManagementUsdText1();
    }
    if (/^investigations$/gi.test(inputs.product)) {
      return createInvestigationsUsdText1();
    }
    return "";
  };

  const createClaimQuestionsString = () =>
    [
      claimQuestions.IWillBeSubmittingMyOwnCoverLetterForThisReferral &&
        "I will be submitting my own letter for this referral : Yes",
      claimQuestions.HistoryDiagnosisPrognosis &&
        "History, Diagnosis, Prognosis: Yes",
      claimQuestions.TreatmentReasonableAndNecessary &&
        "Treatment Reasonable & Necessary: Yes",
      claimQuestions.PresentWorkStatus && "Present Work Status: Yes",
      claimQuestions.PhysicalCapacityFormFCE &&
        "Physical Capacity Form / FCE: Yes",
      claimQuestions.WorkRestrictions && "Work Restrictions: Yes",
      claimQuestions.LengthOfDisability && "Length of Disability: Yes",
      claimQuestions.CausalRelationship && "Casual Relationship: Yes",
      claimQuestions.DegreeOfDisability && "Dregree of Disability: Yes",
      claimQuestions.PermanentAndStationaryStatus &&
        "Permanent and Stationary Status (California): Yes",
      claimQuestions.Apportionment && "Apportionment: Yes",
      claimQuestions.MAndSStatement && "M&S Statement: Yes",
      claimQuestions.PreexistingConditions && "Pre-existing Conditions: Yes",
      claimQuestions.RadiologistToRead && "Radiologist to Read: Yes",
      claimQuestions.EndResult && "End Result: Yes",
      claimQuestions.HasTheExamineeReachedMMI &&
        "Has theexaminee reached MMI: Yes",
      claimQuestions.MaximumMedicalImprovement &&
        "Maximun Medical Improvement: Yes",
      claimQuestions.PermanentScarring && "Permanent Scarring: Yes",
      claimQuestions.AMAImpairmentRating && "AMA Impairment Rating: Yes",
      claimQuestions.ScheduledLossOfUse && "Schedule Loss of Use: Yes",
      claimQuestions.NeedForTreatment && "Need for Treatment: Yes",
      claimQuestions.Permanency && "Permanency (New York Worker's Comp): Yes",
      claimQuestions.NeedForSurgery && "Need for Surgery: Yes",
      claimQuestions.Transportation && "Transportation: Yes",
      claimQuestions.AttendantCare && "Attendant Care: Yes",
      claimQuestions.EssentialServices && "Essential Services: Yes",
      claimQuestions.DurableMedicalEquipment &&
        "Durable Medical Equipment: Yes",
      claimQuestions.Prescriptions && "Prescriptions: Yes",
    ]
      .filter(Boolean)
      .join("\n");

  const createInvestigationsUsdText1 = (forPDF = false) => {
    if (forPDF) {
      return `\nUpcoming Appointment:\nTransportation Provided: ${
        surveillanceActivityCheck.TransportationProvided ? "Yes" : "NO"
      }\n${
        surveillanceActivityCheck.Type
          ? `Appointment Type: ${surveillanceActivityCheck.Type}\n`
          : ""
      }${
        surveillanceActivityCheck.AppointmentDate &&
        surveillanceActivityCheck.AppointmentTime
          ? `Appointment Time: ${surveillanceActivityCheck.AppointmentDate} ${surveillanceActivityCheck.AppointmentTime}\n`
          : ""
      }Physician: ${
        surveillanceActivityCheck.PhysicianName
      }\nPhysician Address: ${surveillanceActivityCheck.Address} ${
        surveillanceActivityCheck.City
      } ${surveillanceActivityCheck.State} ${surveillanceActivityCheck.Zip}
      `;
    }

    return `***Investigation Services Information***\nUpcoming Appointment:\nTransportation Provided: ${
      surveillanceActivityCheck.TransportationProvided ? "Yes" : "NO"
    }\n${
      surveillanceActivityCheck.Type
        ? `Appointment Type: ${surveillanceActivityCheck.Type}\n`
        : ""
    }${
      surveillanceActivityCheck.AppointmentDate &&
      surveillanceActivityCheck.AppointmentTime
        ? `Appointment Time: ${surveillanceActivityCheck.AppointmentDate} ${surveillanceActivityCheck.AppointmentTime}\n`
        : ""
    }Physician: ${
      surveillanceActivityCheck.PhysicianName
    }\nPhysician Address: ${surveillanceActivityCheck.Address} ${
      surveillanceActivityCheck.City
    } ${surveillanceActivityCheck.State} ${surveillanceActivityCheck.Zip}`;
  };

  const createMedicalManagementUsdText1 = (forPDF = false) => {
    if (forPDF) {
      return `\nSpecial Instructions: ${
        inputs.MedicalManagementSpecialInstructions
      }${inputs.NextDayReport ? "Next Day Report: Yes\n" : ""}${
        inputs.Reevaluation ? "Re-Evaluation: Yes\n" : ""
      }${inputs.Litigated ? "Litigated: Yes\n" : ""}${
        inputs.BoardDirected ? "Board Directed: Yes\n" : ""
      }${
        inputs.ClaimantVideographerAttending
          ? "Claimant Videographer Attending: Yes\n"
          : ""
      }${
        inputs.NYBoardNumber ? `NY Board Number: ${inputs.NYBoardNumber}\n` : ""
      }${
        inputs.NYDistrictOffice
          ? `NY District Office: ${inputs.NYDistrictOffice}\n`
          : ""
      }${
        inputs.RequestedSpecialty
          ? `Requested Specialty: ${inputs.RequestedSpecialty}\n`
          : ""
      }${inputs.TaxiService ? "Taxi Service: Yes\n" : ""}${
        inputs.InterpreterService ? "Interpreter Service: Yes\n" : ""
      }${
        inputs.InterpreterLanguage
          ? `Language: ${inputs.InterpreterLanguage}\n`
          : ""
      }${
        inputs.CertifiedMail ? "Certified Mail: Yes\n" : ""
      }${createClaimQuestionsString()}`;
    }

    return `***Medical Management Instructions***\nSpecial Instructions: ${
      inputs.MedicalManagementSpecialInstructions
    }\n${inputs.NextDayReport ? "Next Day Report: Yes" : ""}\n${
      inputs.Reevaluation ? "Re-Evaluation: Yes" : ""
    }${inputs.Litigated ? "Litigated: Yes" : ""}\n${
      inputs.BoardDirected ? "Board Directed: Yes" : ""
    }\n${
      inputs.ClaimantVideographerAttending
        ? "Claimant Videographer Attending: Yes"
        : ""
    }\n${
      inputs.NYBoardNumber ? `NY Board Number: ${inputs.NYBoardNumber}` : ""
    }\n${
      inputs.NYDistrictOffice
        ? `NY District Office: ${inputs.NYDistrictOffice}`
        : ""
    }\n${
      inputs.RequestedSpecialty
        ? `Requested Specialty: ${inputs.RequestedSpecialty}`
        : ""
    }\n${inputs.TaxiService ? "Taxi Service: Yes" : ""}${
      inputs.InterpreterService ? "Interpreter Service: Yes" : ""
    }\n${
      inputs.InterpreterLanguage
        ? `Language: ${inputs.InterpreterLanguage}`
        : ""
    }\n${
      inputs.CertifiedMail ? "Certified Mail: Yes" : ""
    }\n${createClaimQuestionsString()}`;
  };

  const createNonRecordsNotes = () => {
    const DEFAULT_WEBREF_CLIENTCODE = 19705;
    return `${
      inputs?.clientcode === DEFAULT_WEBREF_CLIENTCODE
        ? `Referrer Name: ${inputs.FirstName} ${inputs.LastName}\nReferrer Company: ${inputs.CompanyName}\nReferrer Contact: ${inputs.Phone} Email: ${inputs.Email}\n`
        : ""
    }${
      /^investigations$/gi.test(inputs.product)
        ? `Case Specific Instructions: ${inputs.ServiceInstructions}\n`
        : ``
    }${
      surveillanceActivityCheck.SurveillanceAmount
        ? `Other Surveillance Amount: ${surveillanceActivityCheck.SurveillanceAmount}\n`
        : ""
    }Physical Characteristics: ${
      inputs.ClaimantSpecialPhysical
    }\nSurveillance Instructions: ${
      surveillanceActivityCheck.SurveillanceInstructions
    }${
      inputs.ClaimantMaritalStatus
        ? `\nMarital Status: ${inputs.ClaimantMaritalStatus}`
        : ""
    }\nSoft Tissue Claim: ${
      investigativeServiceInstructions.SoftTissueClaim ? "Yes" : "No"
    }\nCase referred to SIU?: ${
      investigativeServiceInstructions.ReferralSentToSIUDept ? "Yes" : "No"
    }\nEmail Report: ${
      investigativeServiceInstructions.ReceiveReportViaEmail ? "Yes" : "No"
    }\nRUSH Report?: ${
      investigativeServiceInstructions.RushReferral ? "Yes" : "No"
    }\nPlaintiff Attorney Info: ${
      additionalInformation.PlaintiffAttorney ? getPlaintiffAttorneyInfo() : ""
    }\nDefense Attorney Info: ${
      additionalInformation.DefenseAttorney
        ? getDefenseAttorneyInfo(defenseAttorney)
        : ""
    }\nCo-Defendant Info: ${
      additionalInformation.CoDefendant
        ? getDefenseAttorneyInfo(coDefendant)
        : ""
    }\nAdjuster Info: ${
      additionalInformation.Adjuster ? getAdjusterInfo() : ""
    }\nTreating Physician Info: ${
      additionalInformation.TreatingPhysician ? getTreatingPhysicianInfo() : ""
    }\nDoctor Name: ${inputs?.DoctorFirstName || ""} ${
      inputs?.DoctorFirstName ? "," : ""
    } ${inputs?.DoctorLastName || ""}\nVehicle Info: ${
      additionalInformation.Vehicle ? getVehicleInfo() : ""
    }\nEmployer Info: ${
      additionalInformation.Employer ? getEmployerInfo() : ""
    }\nInsured Info: ${
      additionalInformation.Insured ? getInsuredInfo() : ""
    }\nExaminee Cell: ${inputs.ClaimantCellPhone || ""}\nVenue: ${
      inputs.Venue || ""
    }\nAttorney/Secretary: ${getClaimAttySecInfo() || "N/A"}\nClaim Rep: ${
      getClaimGeicoRepInfo() || "N/A"
    }\n\n${createUsdText1()}
    `;
  };

  const createNonRecordsNotesForPDF = () => {
    if (/^medical management$/gi.test(inputs.product)) {
      return {
        PhysicalCharacteristics: inputs.ClaimantSpecialPhysical,
        SurveillanceInstructions:
          surveillanceActivityCheck.SurveillanceInstructions,
        SoftTissueClaim: investigativeServiceInstructions.SoftTissueClaim
          ? "Yes"
          : "No",
        CaseReferredToSIO:
          investigativeServiceInstructions.ReferralSentToSIUDept ? "Yes" : "No",
        NoEmailReport: investigativeServiceInstructions.ReceiveReportViaEmail
          ? "Yes"
          : "No",
        RUSHReport: investigativeServiceInstructions.RushReferral
          ? "Yes"
          : "No",
        PlaintiffAttorneyInfo: additionalInformation.PlaintiffAttorney
          ? getPlaintiffAttorneyInfo()
          : "",
        DefenseAttorneyInfo: additionalInformation.DefenseAttorney
          ? getDefenseAttorneyInfo(defenseAttorney)
          : "",
        CoDefendantInfo: additionalInformation.CoDefendant
          ? getDefenseAttorneyInfo(coDefendant)
          : "",
        AdjusterInfo: additionalInformation.Adjuster ? getAdjusterInfo() : "",
        TreatingPhysicianInfo: additionalInformation.TreatingPhysician
          ? getTreatingPhysicianInfo()
          : "",
        DoctorName: `${inputs?.DoctorFirstName || ""}${
          inputs?.DoctorFirstName ? "," : ""
        }${inputs?.DoctorLastName || ""}`,
        VehicleInfo: additionalInformation.Vehicle ? getVehicleInfo() : "",
        EmployerInfo: additionalInformation.Employer ? getEmployerInfo() : "",
        InsuredInfo: additionalInformation.Insured ? getInsuredInfo() : "",
        ExamineeCell: inputs.ClaimantCellPhone || "",
        Venue: inputs.Venue || "",
        AttorneySecretary: getClaimAttySecInfo() || "N/A",
        GeicoClaimRep: getClaimGeicoRepInfo() || "N/A",
        MedicalManagementInstructions: {
          DiscoveryEndDate: inputs.DiscoveryEndDate
            ? convertTimeZone(
                new Date(
                  inputs.DiscoveryEndDate.split("-").map((x) => parseInt(x, 10))
                )
              )
            : null,
          DateExamNeededBy: inputs.DateExamNeededBy
            ? convertTimeZone(
                new Date(
                  inputs.DateExamNeededBy.split("-").map((x) => parseInt(x, 10))
                )
              )
            : null,
          SpecialInstructions: inputs.MedicalManagementSpecialInstructions,
          ServiceInstructions: inputs.ServiceInstructions,
          NextDayReport: inputs.NextDayReport ? "Yes" : "",
          Reevaluation: inputs.Reevaluation ? "Yes" : "",
          BoardDirected: inputs.BoardDirected ? "Yes" : "",
          ClaimantVideographerAttending: inputs.ClaimantVideographerAttending
            ? "Yes"
            : "",
          Litigated: inputs.Litigated ? "Yes" : "",
          NYBoardNumber: inputs.NYBoardNumber ? `${inputs.NYBoardNumber}` : "",
          NYDistrictOffice: inputs.NYDistrictOffice
            ? `${inputs.NYDistrictOffice}`
            : "",
          RequestedSpecialty: inputs.RequestedSpecialty
            ? `${inputs.RequestedSpecialty}`
            : "",
          TaxiService: inputs.TaxiService ? "Yes" : "",
          InterpreterLanguage: inputs.InterpreterLanguage
            ? `${inputs.InterpreterLanguage}`
            : "",
          CertifiedMail: inputs.CertifiedMail ? "Yes" : "",
          ClaimQuestions: {
            IWillBeSubmittingMyOwnCoverLetterForThisReferral:
              claimQuestions.IWillBeSubmittingMyOwnCoverLetterForThisReferral
                ? "Yes"
                : "",
            HistoryDiagnosisPrognosis: claimQuestions.HistoryDiagnosisPrognosis
              ? "Yes"
              : "",
            TreatmentReasonableAndNecessary:
              claimQuestions.TreatmentReasonableAndNecessary ? "Yes" : "",
            PresentWorkStatus: claimQuestions.PresentWorkStatus ? "Yes" : "",
            PhysicalCapacityFormFCE: claimQuestions.PhysicalCapacityFormFCE
              ? "Yes"
              : "",
            WorkRestrictions: claimQuestions.WorkRestrictions ? "Yes" : "",
            LengthOfDisability: claimQuestions.LengthOfDisability ? "Yes" : "",
            CausalRelationship: claimQuestions.CausalRelationship ? "Yes" : "",
            DegreeOfDisability: claimQuestions.DegreeOfDisability ? "Yes" : "",
            PermanentAndStationaryStatus:
              claimQuestions.PermanentAndStationaryStatus ? "Yes" : "",
            Apportionment: claimQuestions.Apportionment ? "Yes" : "",
            MAndSStatement: claimQuestions.MAndSStatement ? "Yes" : "",
            PreexistingConditions: claimQuestions.PreexistingConditions
              ? "Yes"
              : "",
            RadiologistToRead: claimQuestions.RadiologistToRead ? "Yes" : "",
            EndResult: claimQuestions.EndResult ? "Yes" : "",
            HasTheExamineeReachedMMI: claimQuestions.HasTheExamineeReachedMMI
              ? "Yes"
              : "",
            MaximumMedicalImprovement: claimQuestions.MaximumMedicalImprovement
              ? "Yes"
              : "",
            PermanentScarring: claimQuestions.PermanentScarring ? "Yes" : "",
            AMAImpairmentRating: claimQuestions.AMAImpairmentRating
              ? "Yes"
              : "",
            ScheduledLossOfUse: claimQuestions.ScheduledLossOfUse ? "Yes" : "",
            NeedForTreatment: claimQuestions.NeedForTreatment ? "Yes" : "",
            Permanency: claimQuestions.Permanency ? "Yes" : "",
            NeedForSurgery: claimQuestions.NeedForSurgery ? "Yes" : "",
            Transportation: claimQuestions.Transportation ? "Yes" : "",
            AttendantCare: claimQuestions.AttendantCare ? "Yes" : "",
            EssentialServices: claimQuestions.EssentialServices ? "Yes" : "",
            DurableMedicalEquipment: claimQuestions.DurableMedicalEquipment
              ? "Yes"
              : "",
            Prescriptions: claimQuestions.Prescriptions ? "Yes" : "",
          },
        },
      };
    }

    if (/^investigations$/gi.test(inputs.product)) {
      return {
        PhysicalCharacteristics: inputs.ClaimantSpecialPhysical,
        SurveillanceInstructions:
          surveillanceActivityCheck.SurveillanceInstructions,
        SoftTissueClaim: investigativeServiceInstructions.SoftTissueClaim
          ? "Yes"
          : "No",
        CaseReferredToSIO:
          investigativeServiceInstructions.ReferralSentToSIUDept ? "Yes" : "No",
        NoEmailReport: investigativeServiceInstructions.ReceiveReportViaEmail
          ? "Yes"
          : "No",
        RUSHReport: investigativeServiceInstructions.RushReferral
          ? "Yes"
          : "No",
        PlaintiffAttorneyInfo: additionalInformation.PlaintiffAttorney
          ? getPlaintiffAttorneyInfo()
          : "",
        DefenseAttorneyInfo: additionalInformation.DefenseAttorney
          ? getDefenseAttorneyInfo(defenseAttorney)
          : "",
        CoDefendantInfo: additionalInformation.CoDefendant
          ? getDefenseAttorneyInfo(coDefendant)
          : "",
        AdjusterInfo: additionalInformation.Adjuster ? getAdjusterInfo() : "",
        TreatingPhysicianInfo: additionalInformation.TreatingPhysician
          ? getTreatingPhysicianInfo()
          : "",
        DoctorName: `${inputs?.DoctorFirstName || ""}${
          inputs?.DoctorFirstName ? "," : ""
        }${inputs?.DoctorLastName || ""}`,
        VehicleInfo: additionalInformation.Vehicle ? getVehicleInfo() : "",
        EmployerInfo: additionalInformation.Employer ? getEmployerInfo() : "",
        InsuredInfo: additionalInformation.Insured ? getInsuredInfo() : "",
        ExamineeCell: inputs.ClaimantCellPhone || "",
        Venue: inputs.Venue || "",
        AttorneySecretary: getClaimAttySecInfo() || "N/A",
        GeicoClaimRep: getClaimGeicoRepInfo() || "N/A",
        InvestigativeServicesInformation: {
          ServiceInstructions: inputs.ServiceInstructions,
          // eslint-disable-next-line no-nested-ternary
          SurveillanceAmount: surveillanceActivityCheck.SurveillanceAmount
            ? surveillanceActivityCheck.SurveillanceAmount
            : surveillanceActivityCheck.OtherAmount
            ? surveillanceActivityCheck.OtherAmount
            : "",
          TransportationProvided:
            surveillanceActivityCheck.TransportationProvided ? "Yes" : "No",
          AppointmentType: surveillanceActivityCheck.Type
            ? surveillanceActivityCheck.Type
            : "",
          AppointmentTime:
            surveillanceActivityCheck.AppointmentDate &&
            surveillanceActivityCheck.AppointmentTime
              ? `${surveillanceActivityCheck.AppointmentDate} ${surveillanceActivityCheck.AppointmentTime}`
              : "",
          Physician: surveillanceActivityCheck.PhysicianName
            ? surveillanceActivityCheck.PhysicianName
            : "",
          PhysicianAddress:
            `${surveillanceActivityCheck.Address} ${surveillanceActivityCheck.City} ${surveillanceActivityCheck.State} ${surveillanceActivityCheck.Zip}` ||
            "",
        },
      };
    }

    return {};
  };

  const getPlaintiffAttorneyInfo = () => {
    const valuesToInclude = [
      plaintiffAttorney.FirstName,
      plaintiffAttorney.LastName,
      plaintiffAttorney.Firm,
      plaintiffAttorney.Address1,
      plaintiffAttorney.Address2,
      plaintiffAttorney.City,
      plaintiffAttorney.State,
      plaintiffAttorney.ZipCode,
      plaintiffAttorney.Phone,
      plaintiffAttorney.Extension,
      plaintiffAttorney.Email,
    ];

    const nonEmptyValues = valuesToInclude.filter((value) => value);
    return nonEmptyValues.join(", ");
  };

  const getDefenseAttorneyInfo = (stateObj) => {
    const valuesToInclude = [
      stateObj.FirstName,
      stateObj.LastName,
      stateObj.Firm,
      stateObj.Address1,
      stateObj.Address2,
      stateObj.City,
      stateObj.State,
      stateObj.ZipCode,
      stateObj.Phone,
      stateObj.Extension,
      stateObj.Email,
    ];

    const nonEmptyValues = valuesToInclude.filter((value) => value);
    return nonEmptyValues.join(", ");
  };

  const getTreatingPhysicianInfo = () => {
    const valuesToInclude = [
      treatingPhysician.FirstName,
      treatingPhysician.LastName,
      treatingPhysician.Address1,
      treatingPhysician.Address2,
      treatingPhysician.City,
      treatingPhysician.State,
      treatingPhysician.Zip,
      treatingPhysician.Phone,
      treatingPhysician.PhoneExtension,
    ];

    const nonEmptyValues = valuesToInclude.filter((value) => value);
    return nonEmptyValues.join(", ");
  };

  const getAdjusterInfo = () => {
    const valuesToInclude = [
      adjusterInformation.FirstName,
      adjusterInformation.LastName,
      adjusterInformation.Phone,
      adjusterInformation.Email,
      adjusterInformation.Company,
      adjusterInformation.Office,
    ];

    const nonEmptyValues = valuesToInclude.filter((value) => value);
    return nonEmptyValues.join(", ");
  };

  const getClaimAttySecInfo = () => {
    const valuesToInclude = [inputs.AttSecFname, inputs.AttSecLname];

    const nonEmptyValues = valuesToInclude.filter((value) => value);
    return nonEmptyValues.join(", ");
  };

  const getClaimGeicoRepInfo = () => {
    const valuesToInclude = [
      inputs.GeicoClaimRepFname,
      inputs.GeicoClaimRepLname,
    ];

    const nonEmptyValues = valuesToInclude.filter((value) => value);
    return nonEmptyValues.join(", ");
  };

  const getVehicleInfo = () => {
    const valuesToInclude = [
      vehicleInformation.year,
      vehicleInformation.make,
      vehicleInformation.plateNbr,
      vehicleInformation.color,
      vehicleInformation.model,
      vehicleInformation.state,
    ];

    const nonEmptyValues = valuesToInclude.filter((value) => value);
    return nonEmptyValues.join(", ");
  };

  const getEmployerInfo = () => {
    const valuesToInclude = [
      employer.ContactFirstName,
      employer.ContactLastName,
      employer.Address1,
      employer.Address2,
      employer.Zip,
      employer.City,
      employer.State,
      employer.Phone,
      employer.PhoneExt,
      employer.Email,
    ];

    const nonEmptyValues = valuesToInclude.filter((value) => value);
    return nonEmptyValues.join(", ");
  };

  const getInsuredInfo = () => {
    const valuesToInclude = [
      insuredInformation.address1,
      insuredInformation.address2,
      insuredInformation.city,
      insuredInformation.state,
      insuredInformation.zip,
    ];

    const nonEmptyValues = valuesToInclude.filter((value) => value);
    return nonEmptyValues.join(", ");
  };

  const handleSubmitRecordsReferral = async (
    method,
    isCreatingNewReferralOnSave,
    saveClaimInfo
  ) => {
    const checkForm = canSubmitRecordsReferralForm();
    const checkSaveForm = canSaveRecordsReferralForm();

    if (method === SUBMIT && checkForm.OK) {
      await performSubmitRecordsReferral(
        method,
        isCreatingNewReferralOnSave,
        saveClaimInfo
      );
    } else if (method === SAVE && checkSaveForm.OK) {
      await performSubmitRecordsReferral(
        method,
        isCreatingNewReferralOnSave,
        saveClaimInfo
      );
    } else {
      toast.warning(
        /^save$/gi.test(method) ? checkSaveForm.message : checkForm.message
      );
      captureReferralError({
        variables: {
          input: {
            Type: ERF_INPUT,
            Code: INVALID_INPUT_CODE,
            Message: INPUT_ERROR_MESSAGE,
            OtherInfo: INPUT_OTHERINFO_MESSAGE(
              `LOCATION: handleSubmitRecordsReferral() => canSubmitRecordsReferralForm() -- VALIDATION ERRORS: ${
                /^save$/gi.test(method)
                  ? checkSaveForm.message
                  : checkForm.message
              }`
            ),
          },
        },
      });
    }
  };

  const buildRequestInput = (method) => ({
    marketeremail: inputs?.marketeremail,
    clientcode: parseInt(inputs?.clientcode, 10),
    product: inputs?.product,
    // Referrer
    FirstName: inputs.FirstName,
    LastName: inputs.LastName,
    CompanyName: inputs.CompanyName,
    City: inputs.City,
    State: inputs.State,
    Phone: inputs.Phone,
    Email: inputs.Email,

    // Claim
    CaseType: inputs.CaseType || null,
    ClaimNumber: inputs.ClaimNumber,
    Insured: inputs.Insured,
    DateOfLoss: inputs.DateOfLoss
      ? convertTimeZone(
          new Date(inputs.DateOfLoss.split("-").map((x) => parseInt(x, 10)))
        )
      : null,
    Jurisdiction: inputs.Jurisdiction,
    Venue: inputs.Venue,

    // Claimant
    ClaimantFirstName: inputs.ClaimantFirstName,
    ClaimantLastName: inputs.ClaimantLastName,
    ClaimantAddress1: inputs.ClaimantAddress1,
    ClaimantAddress2: inputs.ClaimantAddress2,
    ClaimantCity: inputs.ClaimantCity,
    ClaimantState: inputs.ClaimantState,
    ClaimantZip: inputs.ClaimantZip,
    ClaimantEmail: inputs.ClaimantEmail,
    ClaimantPhone: inputs.ClaimantPhone,
    ClaimantCellPhone: inputs.ClaimantCellPhone,
    ClaimantDateOfBirth: inputs.ClaimantDateOfBirth
      ? convertTimeZone(
          new Date(
            inputs.ClaimantDateOfBirth.split("-").map((x) => parseInt(x, 10))
          )
        )
      : null,
    ClaimantGender: inputs.ClaimantGender,
    ClaimantMaritalStatus: inputs.ClaimantMaritalStatus,
    ClaimantSSN: inputs.ClaimantSSN,
    ClaimantSpecialPhysical: inputs.ClaimantSpecialPhysical,

    // Client / Contact Information
    RequestorOrAttorney: inputs.RequestorOrAttorney,
    RepresentedParty: inputs.RepresentedParty,
    FileNumber: inputs.FileNumber,
    ParalegalOrContact: inputs.ParalegalOrContact,
    AdditionalContact: inputs.AdditionalContact,
    GlobalInstructions: inputs.GlobalInstructions,
    DeadlineDate: isValid(
      new Date(inputs.DeadlineDate.split("-").map((x) => parseInt(x, 10)))
    )
      ? inputs.DeadlineDate
      : null,

    // Billing
    IsBillingSameAsRequestor: inputs.IsBillingSameAsRequestor,
    BillingContact: inputs.BillingContact,
    InsuranceCarrier: inputs.InsuranceCarrier,

    // NW Carrier
    NWCarrier: inputs.NWCarrier,
    ClaimSystem: inputs.ClaimSystem,
    BusinessUnit: inputs.BusinessUnit,
    LossState: inputs.LossState,
    Subrogation: inputs.Subrogation,
    AllegedInjuries: inputs.AllegedInjuries,

    // Broadspire Carrier
    ServiceCenter: inputs.ServiceCenter,
    BroadspireClient: inputs.BroadspireClient,
    BroadspireCarrier: inputs.BroadspireCarrier,

    // Sentry Carrier
    RRClaimantLetter: inputs.RRClaimantLetter,
    RRUseCAWCRegs: inputs.RRUseCAWCRegs,

    // USAA Carrier
    RRclaimOffice_us: inputs.RRclaimOffice_us,
    RRmemberNumber_us: inputs.RRmemberNumber_us,
    RRalisNumber_us: inputs.RRalisNumber_us,
    RRclaimsLossEmail_us: inputs.RRclaimsLossEmail_us,
    RRlossReportNumber_us: inputs.RRlossReportNumber_us,
    RRLegalOffice_us: inputs.RRLegalOffice_us,

    CustomerLinkID: inputs?.ClientID || null,
    CustomerLogin: inputs.ClientLogin,
    PersonID: portalPerson?.PersonID || undefined,

    // Notes
    Notes: notes.map(({ note, fromDB }) => ({ note, fromDB })),

    // Providers
    Providers: inputs.Providers,

    // Counsels
    Counsels: inputs.Counsels,

    // Authorization / Subpoena Information
    ContactName: subpoenaInfo.ContactName,
    ClaimType: subpoenaInfo.ClaimType,
    Caption: subpoenaInfo.Caption,
    County: subpoenaInfo.County,
    DocketNumber: subpoenaInfo.DocketNumber,
    Court: subpoenaInfo.Court,
    CourtAddress: subpoenaInfo.CourtAddress || null,
    Branch: subpoenaInfo.Branch,
    Division: subpoenaInfo.Division,
    CalendarNumber: subpoenaInfo.CalendarNumber,

    WCJudge: subpoenaInfo.WCJudge,
    WCInsuranceCarrier: subpoenaInfo.WCInsuranceCarrier
      ? subpoenaInfo.WCInsuranceCarrier.toString()
      : null,
    WCEmployer: subpoenaInfo.WCEmployer
      ? subpoenaInfo.WCEmployer.toString()
      : null,
    WCStateFileNumber: subpoenaInfo.WCStateFileNumber,
    WCStateFileYear: subpoenaInfo.WCStateFileYear,
    WCRFANumber: subpoenaInfo.WCRFANumber,
    WCCarrierCode: subpoenaInfo.WCCarrierCode,

    SubpoenaState: subpoenaInfo.SubpoenaState,
    CourtFee: subpoenaInfo.CourtFee,
    SheriffFee: subpoenaInfo.SheriffFee,
    SecretaryOfStateFee: subpoenaInfo.SecretaryOfStateFee,

    // SubService
    SubService: inputs.SubService,

    FileSubmitted: !!files.length,

    // Capture who submits this RR referral
    SubmitterEmailAddress: portalPerson ? portalPerson.Email : _email,

    // submit or save
    Method: method,
  });

  const performSubmitRecordsReferral = async (
    method,
    isCreatingNewReferralOnSave
  ) => {
    try {
      if (rrId && !usedChangeProductFromPreviousRRid) {
        if (files.length) {
          await uploadFiles([`erf/${rrId.RRid}`], "records", method);
          await client.query({
            query: SINGLE_RECORD_REQUEST,
            variables: {
              where: {
                RRid: parseInt(rrId.RRid, 10),
                CustomerLinkID: parseInt(referrer.ClientID, 10),
                PersonID: parseInt(referrer?.PersonID, 10),
              },
            },
            fetchPolicy: "network-only",
          });
        }
        if (isRecordsReferralSubmitted) {
          setIsUpdatingSubmittedReferral(true);

          const providersMarkSubmit = (providers) => {
            const date = convertTimeZone(getDateEST());

            const submittedProviders = providers.map((prov) => {
              if (prov.fromDB && !prov.DateSubmitted) {
                return {
                  ...prov,
                  DateSubmitted: date.toISOString(),
                };
              }
              return { ...prov };
            });

            return submittedProviders;
          };

          const {
            data: { updateSubmittedRecordsReferral: result },
          } = await updateSubmittedReferral({
            variables: {
              input: {
                product: inputs.product,
                RRid: rrId.RRid,
                CustomerLogin: inputs.ClientLogin,
                CustomerLinkID: inputs?.ClientID || null,
                PersonID: inputs?.PersonID || undefined,
                ClaimNumber: inputs.ClaimNumber,
                ClaimantFirstName: inputs.ClaimantFirstName,
                ClaimantLastName: inputs.ClaimantLastName,
                Email: inputs.Email,
                Notes: notes.map(({ note, fromDB }) => ({ note, fromDB })),
                Providers: providersMarkSubmit(inputs.Providers),
                Counsels: inputs.Counsels,

                // Authorization / Subpoena Information
                ContactName: subpoenaInfo.ContactName,
                ClaimType: subpoenaInfo.ClaimType,
                Caption: subpoenaInfo.Caption,
                County: subpoenaInfo.County,
                DocketNumber: subpoenaInfo.DocketNumber,
                Court: subpoenaInfo.Court,
                CourtAddress: subpoenaInfo.CourtAddress || null,
                Branch: subpoenaInfo.Branch,
                Division: subpoenaInfo.Division,
                CalendarNumber: subpoenaInfo.CalendarNumber,

                WCJudge: subpoenaInfo.WCJudge,
                WCInsuranceCarrier: subpoenaInfo.WCInsuranceCarrier
                  ? subpoenaInfo.WCInsuranceCarrier.toString()
                  : null,
                WCEmployer: subpoenaInfo.WCEmployer
                  ? subpoenaInfo.WCEmployer.toString()
                  : null,
                WCStateFileNumber: subpoenaInfo.WCStateFileNumber,
                WCStateFileYear: subpoenaInfo.WCStateFileYear,
                WCRFANumber: subpoenaInfo.WCRFANumber,
                WCCarrierCode: subpoenaInfo.WCCarrierCode,

                SubpoenaState: subpoenaInfo.SubpoenaState,
                CourtFee: subpoenaInfo.CourtFee,
                SheriffFee: subpoenaInfo.SheriffFee,
                SecretaryOfStateFee: subpoenaInfo.SecretaryOfStateFee,

                FileSubmitted: !!files.length,

                DateOfLoss: inputs.DateOfLoss
                  ? convertTimeZone(
                      new Date(
                        inputs.DateOfLoss.split("-").map((x) => parseInt(x, 10))
                      )
                    )
                  : null,
              },
              output: {
                sourceId,
                claimId,
                claimantId,
              },
            },
            refetchQueries: [
              {
                query: SINGLE_RECORD_REQUEST,
                variables: {
                  where: {
                    RRid: parseInt(rrId?.RRid, 10),
                    CustomerLinkID: parseInt(referrer.ClientID, 10),
                    PersonID: parseInt(referrer?.PersonID, 10),
                  },
                },
              },
            ],
          });
          if (result.OK) {
            await addToLinkServiceQueue(
              rrId?.RRid,
              retainClaimClaimantInformation,
              "Records"
            );
            portalRefresh("RR", rrId?.RRid);
            if (result.Errors.length) {
              result.Errors.forEach((err) => {
                toast.error(err);
              });
              captureReferralError({
                variables: {
                  input: {
                    Type: ERF_SUBMISSION,
                    Code: SUBMISSION_FAIL_CODE,
                    Message: SUBMISSION_ERROR_MESSAGE,
                    OtherInfo: SUBMISSION_OTHERINFO_MESSAGE(
                      `ERROR DETAILS: upadateSubmittedReferral() func => ERRORS: ${result.Errors}`
                    ),
                  },
                },
              });
            }

            toast.erfsuccess("Case successfully updated");

            if (!isCreatingNewReferralOnSave) {
              history.push("/confirmation?method=submit");
            }
          } else {
            toast.error(
              "Something went wrong, please try again later or contact support"
            );
            captureReferralError({
              variables: {
                input: {
                  Type: ERF_SUBMISSION,
                  Code: SUBMISSION_FAIL_CODE,
                  Message: SUBMISSION_ERROR_MESSAGE,
                  OtherInfo: SUBMISSION_OTHERINFO_MESSAGE(
                    "LOCATION: performSubmitRecordsReferral() -- POSSIBLE ISSUES: error on uploadFiles() func, or error on updateSubmittedReferral() func."
                  ),
                },
              },
            });
          }
          setIsRecordsReferralSubmitted(false);
        }
        if (!isRecordsReferralSubmitted) {
          const {
            data: { updateNonSubmittedRecordsReferral: result },
          } = await updateNonSubmittedReferral({
            variables: {
              sourceId,
              input: {
                ...buildRequestInput(method),
                RRid: parseInt(rrId.RRid, 10),
                FilesToDelete: inputs.Uploads.filter((f) => f.deleted).map(
                  ({ id }) => id
                ),
              },
              output: {
                sourceId,
                claimId,
                claimantId,
              },
            },
            refetchQueries: [
              {
                query: SINGLE_RECORD_REQUEST,
                variables: {
                  where: {
                    RRid: parseInt(rrId.RRid, 10),
                    CustomerLinkID: parseInt(referrer.ClientID, 10),
                    PersonID: parseInt(referrer?.PersonID, 10),
                  },
                },
              },
            ],
          });
          if (result.OK) {
            await addToLinkServiceQueue(
              rrId.RRid,
              retainClaimClaimantInformation,
              "Records"
            );
            portalRefresh("RR", rrId.RRid);
            if (result.Errors.length) {
              result.Errors.forEach((err) => toast.error(err));
            }
            if (files.length) {
              await client.query({
                query: SINGLE_RECORD_REQUEST,
                variables: {
                  where: {
                    RRid: parseInt(rrId.RRid, 10),
                    CustomerLinkID: parseInt(referrer.ClientID, 10),
                    PersonID: parseInt(referrer?.PersonID, 10),
                  },
                },
                fetchPolicy: "network-only",
              });
            }
            if (method === SUBMIT) {
              const RRid =
                result?.DocumentPaths[0]?.split("/")[1] || rrId?.RRid;

              await sendReferralReceipt({
                variables: {
                  sourceId,
                  input: {
                    ...buildRequestInput(method),
                    RRid: parseInt(RRid, 10),
                  },
                  output: {
                    sourceId,
                    claimId,
                    claimantId,
                  },
                },
              });
            }
            toast.erfsuccess(
              `Referral has been successfully ${
                method === SAVE ? "saved!" : "submitted!"
              }`
            );
            if (window.parent) {
              window.parent.postMessage("close", "*");
            }
            if (!isCreatingNewReferralOnSave) {
              history.push(
                method === SAVE
                  ? `/confirmation?method=save`
                  : "/confirmation?method=submit"
              );
            }
          } else {
            toast.error(
              "Something went wrong, please try again later or contact support"
            );
            captureReferralError({
              variables: {
                input: {
                  Type: ERF_SUBMISSION,
                  Code: SUBMISSION_FAIL_CODE,
                  Message: SUBMISSION_ERROR_MESSAGE,
                  OtherInfo: SUBMISSION_OTHERINFO_MESSAGE(
                    "FIELD: Date of Loss/Injury"
                  ),
                },
              },
            });
          }
        }
        setIsUpdatingSubmittedReferral(false);
      } else {
        const {
          data: { submitRecordsReferral: result },
        } = await submitRecordsReferral({
          variables: {
            sourceId,
            input: buildRequestInput(method),
            output: {
              sourceId,
              claimId,
              claimantId,
            },
          },
        });
        if (result.OK) {
          await addToLinkServiceQueue(
            result.RRid,
            retainClaimClaimantInformation,
            "Records"
          );
          portalRefresh("RR", result.RRid);
          if (result.Errors.length) {
            result.Errors.forEach((err) => toast.error(err));
            await captureReferralError({
              variables: {
                input: {
                  Type: ERF_SUBMISSION,
                  Code: SUBMISSION_FAIL_CODE,
                  Message: SUBMISSION_ERROR_MESSAGE,
                  OtherInfo: SUBMISSION_OTHERINFO_MESSAGE(
                    `LOCATION: submittedRecordsReferral() -- ERRORS: ${result.Errors}`
                  ),
                },
              },
            });
          }
          if (files.length) {
            await uploadFiles(result.DocumentPaths, "records", method);
          }

          const RRid = result?.DocumentPaths[0]?.split("/")[1];

          if (method !== SAVE) {
            await sendReferralReceipt({
              variables: {
                sourceId,
                input: {
                  ...buildRequestInput(method),
                  RRid: parseInt(RRid, 10),
                },
                output: {
                  sourceId,
                  claimId,
                  claimantId,
                },
              },
            });
          }

          if (!isCreatingNewReferralOnSave && method === SUBMIT) {
            history.push("/confirmation?method=submit");
          }
          if (!isCreatingNewReferralOnSave && method === SAVE) {
            history.push("/confirmation?method=save");
          }
          if (method === SUBMIT) {
            toast.erfsuccess(
              "Case created, and your referral has been submitted successfully!"
            );
          }
          if (method === SAVE) {
            toast.erfsuccess("Your referral has been successfully saved!");
          }
          if (window.parent) {
            window.parent.postMessage("submit", "*");
          }
        } else {
          toast.error(
            "Something went wrong, please try again later or contact support"
          );
          captureReferralError({
            variables: {
              input: {
                Type: ERF_SUBMISSION,
                Code: SUBMISSION_FAIL_CODE,
                Message: SUBMISSION_ERROR_MESSAGE,
                OtherInfo: SUBMISSION_OTHERINFO_MESSAGE(
                  "LOCATION: updateNonSubmittedReferral(), under submitRecordsReferral() -- POSSIBLE ISSUES: error on uploadFiles() func"
                ),
              },
            },
          });
        }
      }
    } catch (err) {
      if (err instanceof CustomERFError) {
        toast.error(err.message);
      } else {
        toast.error(
          `Something went wrong, please try again later or contact support`
        );
      }
      captureReferralError({
        variables: {
          input: {
            Type: ERF_SUBMISSION,
            Code: SUBMISSION_FAIL_CODE,
            Message: SUBMISSION_ERROR_MESSAGE,
            OtherInfo: SUBMISSION_OTHERINFO_MESSAGE(
              `LOCATION: updateNonSubmittedReferral(), catch block -- ERRORS: ${err})`
            ),
          },
        },
      });
    }
  };

  const handleSubmit = (method) => {
    const isSubmit = method === SUBMIT;
    const checkForm = canSubmitReferralForm();
    const checkSaveForm = canSaveRecordsReferralForm();

    if ((isSubmit && checkForm.OK) || (!isSubmit && checkSaveForm.OK)) {
      const isRecordsProduct = /^records$/gi.test(inputs?.product);
      const submitMethod = isSubmit ? SUBMIT : SAVE;

      if (isRecordsProduct) {
        handleSubmitRecordsReferral(
          submitMethod,
          CREATE_NEW_REFERRAL_ON_SAVE,
          retainClaimClaimantInformation.claim ||
            retainClaimClaimantInformation.claimant
        );
      }
    } else {
      const errorMessage = isSubmit ? checkForm.message : checkSaveForm.message;

      toast.warning(errorMessage);

      captureReferralError({
        variables: {
          input: {
            Type: ERF_INPUT,
            Code: INVALID_INPUT_CODE,
            Message: INPUT_ERROR_MESSAGE,
            OtherInfo: INPUT_OTHERINFO_MESSAGE(
              `LOCATION: handleSubmitRecordsReferral() => canSubmitRecordsReferralForm() -- VALIDATION ERRORS: ${errorMessage}`
            ),
          },
        },
      });
    }
  };

  const handleRemoveFile = (row) => {
    if (row.original.uploaded) {
      const uploads = [...inputs.Uploads];
      const newItem = { ...uploads[row.index] };
      newItem.deleted = true;
      uploads.splice(row.index, 1, newItem);
      setInputs((prevState) => ({
        ...prevState,
        Uploads: uploads,
      }));
    } else {
      const index = row.index - inputs.Uploads.filter((f) => !f.deleted).length;
      const f = [...files];
      f.splice(index, 1);
      setFiles(f);
    }
  };

  const handleSubServiceChange = async (name, value) => {
    handleChange(name, value);
    if (value === "IQ" || value === "RRIQ") {
      await handleAddRecordIQSummaryRequest();
    }
    if (value === "RR") {
      // Removes the automatically inserted RecordIQ provider when "Record Retrieval" is checked.
      setInputs((prev) => ({
        ...prev,
        Providers: [
          ...inputs?.Providers.filter(
            (provider) => !provider.SpecialInstructions?.includes("RecordIQ")
          ),
        ],
      }));
    }
  };

  const handleAddRecordIQSummaryRequest = async () => {
    const { data: ratsMedicalProviderData } = await client.query({
      query: RATS_FIND_FIRST_MEDICAL_PROVIDER_QUERY,
      variables: {
        where: { MPnameLast: { equals: "RecordIQ Summary Request" } },
      },
    });

    if (
      ratsMedicalProviderData?.findFirstMedicalprovider &&
      !inputs.Providers.some(
        (provider) =>
          !provider.deleted &&
          provider.Provider ===
            ratsMedicalProviderData.findFirstMedicalprovider.MPid
      )
    ) {
      const newProvider = {
        ...INITIAL_PROVIDER_MODEL,
        ProviderID: generateUUID(),
        updated: true,
        Provider: ratsMedicalProviderData.findFirstMedicalprovider.MPid,
        SpecialInstructions: "Records attached for RecordIQ",
      };

      setInputs((prev) => ({
        ...prev,
        Providers: [
          ...inputs.Providers,
          {
            ...newProvider,
            RecordType: [284],
          },
        ],
      }));
    }
  };

  useWindowUnloadConfirmation();

  const handleProductChange = (type) => {
    try {
      const modeUrl = "?mode=".concat(btoa(`${type}`));
      const refUrl = "&referrer=".concat(btoa(ref));
      const emailUrl = "&email=".concat(btoa(inputs?.Email));

      const addressTemplate = mode
        ? `/referral${mode ? modeUrl : ""}${ref ? refUrl : ""}${
            inputs?.Email ? emailUrl : ""
          }${
            _clientcode
              ? "&clientcode=".concat(btoa(_clientcode))
              : "&clientcode=".concat(btoa(0))
          }${_CLid ? "&clid=".concat(btoa(_CLid)) : "&clid=".concat(btoa(0))}`
        : "/referral";

      const portalPersonAddress = `/referral${modeUrl}${refUrl}${emailUrl}&PersonID=${btoa(
        portalPerson?.PersonID
      )}`;

      handleChange("product", type);
      setIsRecordsReferralSubmitted(false);
      setAdditionalInformation({ ...INITIAL_ADDITIONAL_INFORMATION_STATE }); // reset Additional Information checkboxes
      setPlaintiffAttorney({ ...INITIAL_PLAINTIFF_ATTORNEY_STATE }); // reset Plaintiff Attorney
      setDefenseAttorney({ ...INITIAL_DEFENSE_ATTORNEY_STATE }); // reset Defense Attorney
      setCoDefendant({ ...INITIAL_DEFENSE_ATTORNEY_STATE }); // reset Co-Defendant
      setInsuredInformation({ ...INITIAL_INSURED_INFORMATION_STATE }); // reset Insured
      setVehicleInformation({ ...INITIAL_VEHICLE_INFORMATION_STATE }); // reset Vehicle
      setEmployer({ ...INITIAL_EMPLOYER_STATE }); // reset Employer
      setTreatingPhysician({ ...INITIAL_TREATING_PHYSICIAN_STATE }); // reset Treating Physician
      setAdjusterInformation({ ...INITIAL_ADJUSTER_INFORMATION_STATE }); // reset Adjuster
      setClaimQuestions({ ...INITIAL_CLAIM_QUESTIONS_STATE });
      setSurveillanceActivityCheck({
        ...INITIAL_SURVEILLANCE_ACTIVITY_CHECK_STATE,
      });
      // setSubpoenaInfo({ ...INITIAL_SUBPOENA_INFO }); keeping subpoena info per Krisy when claim is retained
      setProblems([]);
      setInvestigativeServiceInstructions({
        ...INITIAL_INVESTIGATIVE_SERVICE_INSTRUCTIONS_STATE,
      });
      setNotes(INITIAL_NOTES_STATE);
      setFiles([]);

      if (type === RECORDS) {
        setUsedChangeProductFromPreviousRRid(true);
        setInputs((prev) => ({
          ...prev,
          SubService: "RR",
        }));
      }

      setRetainClaimClaimantInformation({ claim: false, claimant: false });
      history.push(portalPerson ? portalPersonAddress : addressTemplate);
      toast.erfsuccess(`Referral type successfully changed to ${type}!`);
      window.scrollTo(0, 0);
    } catch (err) {
      toast.error(`ERR: @ handleProductChange() => ${err}`);
    }
  };

  // Handles getting the doctor data when 'Requested Doctor' is updated.
  useEffect(() => {
    const doctorSelected = inputs?.doctorcode;
    if (doctorSelected) {
      getDoctor({
        variables: {
          where: {
            doctorcode: inputs?.doctorcode,
          },
        },
        fetchPolicy: "network-only",
      });
    }
  }, [inputs?.doctorcode, getDoctor]);

  // Doctor Info: Sets the Doctor's first and last name in state
  useEffect(() => {
    const firstname = getDoctorData?.doctor?.firstname;
    const lastname = getDoctorData?.doctor?.lastname;

    if (firstname || lastname) {
      setInputs((prev) => ({
        ...prev,
        DoctorFirstName: firstname,
        DoctorLastName: lastname,
      }));
    }
  }, [
    getDoctorData?.doctor?.firstname,
    getDoctorData?.doctor?.lastname,
    setInputs,
  ]);

  const handleReferralSaveFromDropdown = (type, method) => {
    const returnObject = {
      OK: "",
      message: "",
    };

    try {
      // const checkForm = canSubmitReferralForm();
      const handleFormCheck = () =>
        method === SUBMIT
          ? canSubmitRecordsReferralForm()
          : canSaveRecordsReferralForm();
      const checkForm = /^records$/gi.test(inputs?.product)
        ? handleFormCheck()
        : canSubmitReferralForm();

      if (checkForm.OK) {
        if (/^records$/gi.test(inputs?.product)) {
          handleSubmitRecordsReferral(
            method,
            CREATE_NEW_REFERRAL_ON_SAVE,
            retainClaimClaimantInformation.claim ||
              retainClaimClaimantInformation.claimant
          );
        } else {
          handleSubmitReferral(
            CREATE_NEW_REFERRAL_ON_SAVE,
            retainClaimClaimantInformation.claim ||
              retainClaimClaimantInformation.claimant
          );
        }

        const handleRetainAddress = (address) => {
          if (
            /^records$/gi.test(inputs?.product) &&
            /^medical management$/gi.test(type)
          ) {
            return "";
          }
          return address;
        };

        const generateClientcode = (retainingClaimClaimantInfo) => {
          if (retainingClaimClaimantInfo) {
            if (/^records$/i.test(inputs?.product) && _CLid) {
              return _CLid;
            }

            if (_clientcode) {
              return _clientcode;
            }

            if (!_clientcode && !_CLid) {
              return DEFAULT_CLIENT_CODE;
            }
          }

          return DEFAULT_CLIENT_CODE;
        };

        const newInputs = {
          // reset inputs
          RequestorOrAttorney:
            retainClaimClaimantInformation.claim && type === RECORDS
              ? inputs.RequestorOrAttorney
              : "",
          Providers: [],
          Counsels: [],
          AdditionalContact: "",
          BillingContact:
            retainClaimClaimantInformation.claim && type === RECORDS
              ? inputs.BillingContact
              : "",
          ParalegalOrContact: "",
          RepresentedParty: "",
          GlobalInstructions: "",
          FileNumber: retainClaimClaimantInformation.claim
            ? inputs?.FileNumber
            : "",
          DeadlineDate: "",
          RequestedSpecialty:
            type === "Medical Management" ? inputs?.RequestedSpecialty : "",
          // InsuranceCarrier: "",
          ServiceCenter: "",
          BroadspireCarrier: "",
          BroadspireClient: "",
          IsBillingSameAsRequestor:
            retainClaimClaimantInformation.claim && type === RECORDS
              ? inputs.IsBillingSameAsRequestor
              : false,
          clientcode: generateClientcode(retainClaimClaimantInformation.claim),
          product: type,

          // Claim Info
          CaseType:
            retainClaimClaimantInformation.claim &&
            (type === MEDICAL_MANAGEMENT || type === INVESTIGATIONS)
              ? inputs?.CaseType
              : "",
          ClaimNumber: retainClaimClaimantInformation.claim
            ? inputs?.ClaimNumber
            : "",
          Insured:
            retainClaimClaimantInformation.claim &&
            (type === MEDICAL_MANAGEMENT || type === INVESTIGATIONS)
              ? inputs?.Insured
              : "",
          DateOfLoss: retainClaimClaimantInformation.claim
            ? inputs?.DateOfLoss
            : "",
          Jurisdiction:
            retainClaimClaimantInformation.claim &&
            (type === MEDICAL_MANAGEMENT || type === INVESTIGATIONS)
              ? inputs?.Jurisdiction
              : "",
          Venue:
            retainClaimClaimantInformation.claim &&
            (type === MEDICAL_MANAGEMENT || type === INVESTIGATIONS)
              ? inputs?.Venue
              : "",
          CentralEligabilityID: retainClaimClaimantInformation.claim
            ? inputs?.CentralEligabilityID // not sure what this is doing here. Doesn't look like CentralEl exists or is sent anywhere?
            : "",
          carrier:
            retainClaimClaimantInformation.claim &&
            (type === MEDICAL_MANAGEMENT || type === INVESTIGATIONS)
              ? inputs?.carrier
              : "",

          // Claimant Info
          ClaimantFirstName: retainClaimClaimantInformation.claimant
            ? inputs?.ClaimantFirstName
            : "",
          ClaimantLastName: retainClaimClaimantInformation.claimant
            ? inputs?.ClaimantLastName
            : "",
          ClaimantEmail: retainClaimClaimantInformation.claimant
            ? inputs?.ClaimantEmail
            : "",
          ClaimantAddress1: retainClaimClaimantInformation.claimant
            ? handleRetainAddress(inputs?.ClaimantAddress1)
            : "",
          ClaimantAddress2: retainClaimClaimantInformation.claimant
            ? handleRetainAddress(inputs?.ClaimantAddress2)
            : "",
          ClaimantPhone: retainClaimClaimantInformation.claimant
            ? inputs?.ClaimantPhone
            : "",
          ClaimantCellPhone: retainClaimClaimantInformation.claimant
            ? inputs?.ClaimantCellPhone
            : "",
          ClaimantCity: retainClaimClaimantInformation.claimant
            ? inputs?.ClaimantCity
            : "",
          ClaimantState: retainClaimClaimantInformation.claimant
            ? inputs?.ClaimantState
            : "",
          ClaimantZip: retainClaimClaimantInformation.claimant
            ? inputs?.ClaimantZip
            : "",
          ClaimantDateOfBirth: retainClaimClaimantInformation.claimant
            ? inputs?.ClaimantDateOfBirth
            : "",
          ClaimantGender: retainClaimClaimantInformation.claimant
            ? inputs?.ClaimantGender
            : "",
          ClaimantMaritalStatus: retainClaimClaimantInformation.claimant
            ? inputs?.ClaimantMaritalStatus
            : "",
          ClaimantSSN: retainClaimClaimantInformation.claimant
            ? inputs?.ClaimantSSN
            : "",
          ClaimantSpecialPhysical: retainClaimClaimantInformation.claimant
            ? inputs?.ClaimantSpecialPhysical
            : "",
          Uploads: [],
          SpecialInstructions: "",
          MedicalServiceInstructions: "",

          // reset Services Requested
          ServicesRequested: [],
          ServiceInstructions: "",

          // reset Medical Service Instructions
          DateExamNeededBy: "",
          DiscoveryEndDate: "",
          NextDayReport: false,
          Reevaluation: false,
          Litigated: false,
          BoardDirected: false,
          ClaimantVideographerAttending: false,
          NYBoardNumber: "",
          NYDistrictOffice: "",

          // reset special services
          TaxiService: false,
          InterpreterService: false,
          InterpreterLanguage: "",
          CertifiedMail: false,
          MedicalManagementSpecialInstructions: "",
        };

        if (referrer && type === RECORDS) {
          newInputs.FirstName = referrer?.FirstName;
          newInputs.LastName = referrer?.LastName;
          newInputs.CompanyName = referrer?.CompanyName;
          newInputs.City = referrer?.City;
          newInputs.State = referrer?.State;
          newInputs.Phone = referrer?.Phone;
          newInputs.Email = referrer?.Email;
        }

        setInputs((prev) => ({ ...prev, ...newInputs }));

        setNotes([]); // reset notes

        setProblems([]); // reset problems

        setSubpoenaInfo((prev) => ({
          // reset supoena inputs
          ...prev,
          ContactName:
            retainClaimClaimantInformation.claim && type === RECORDS
              ? prev.ContactName
              : "",
          ClaimType:
            retainClaimClaimantInformation.claim && type === RECORDS
              ? prev.ClaimType
              : "",
          Caption:
            retainClaimClaimantInformation.claim && type === RECORDS
              ? prev.Caption
              : "",
          County:
            retainClaimClaimantInformation.claim && type === RECORDS
              ? prev.County
              : "",
          DocketNumber:
            retainClaimClaimantInformation.claim && type === RECORDS
              ? prev.DocketNumber
              : "",
          Court:
            retainClaimClaimantInformation.claim && type === RECORDS
              ? prev.Court
              : "",
          CourtAddress:
            retainClaimClaimantInformation.claim && type === RECORDS
              ? prev.CourtAddress
              : "",
          Branch:
            retainClaimClaimantInformation.claim && type === RECORDS
              ? prev.Branch
              : "",
          Division:
            retainClaimClaimantInformation.claim && type === RECORDS
              ? prev.Division
              : "",
          CalendarNumber:
            retainClaimClaimantInformation.claim && type === RECORDS
              ? prev.CalendarNumber
              : "",
          WCJudge:
            retainClaimClaimantInformation.claim && type === RECORDS
              ? prev.WCJudge
              : "",
          WCInsuranceCarrier:
            retainClaimClaimantInformation.claim && type === RECORDS
              ? prev.WCInsuranceCarrier
              : "",
          WCEmployer:
            retainClaimClaimantInformation.claim && type === RECORDS
              ? prev.WCEmployer
              : "",
          WCStateFileNumber:
            retainClaimClaimantInformation.claim && type === RECORDS
              ? prev.WCStateFileNumber
              : "",
          WCStateFileYear:
            retainClaimClaimantInformation.claim && type === RECORDS
              ? prev.WCStateFileYear
              : "",
          WCRFANumber:
            retainClaimClaimantInformation.claim && type === RECORDS
              ? prev.WCRFANumber
              : "",
          WCCarrierCode:
            retainClaimClaimantInformation.claim && type === RECORDS
              ? prev.WCCarrierCode
              : "",
          SubpoenaState:
            retainClaimClaimantInformation.claim && type === RECORDS
              ? prev.SubpoenaState
              : "",
          CourtFee:
            retainClaimClaimantInformation.claim && type === RECORDS
              ? prev.CourtFee
              : 0,
          SheriffFee:
            retainClaimClaimantInformation.claim && type === RECORDS
              ? prev.SheriffFee
              : 0,
          SecretaryOfStateFee:
            retainClaimClaimantInformation.claim && type === RECORDS
              ? prev.SecretaryOfStateFee
              : 0,
        }));

        returnObject.OK = true;
        returnObject.message = "Referral successfully submitted!";
      } else {
        toast.warning(checkForm.message);
      }
    } catch (err) {
      toast.error(`ERROR: ${err.message}`);
      captureReferralError({
        variables: {
          input: {
            Type: ERF_SUBMISSION,
            Code: SUBMISSION_FAIL_CODE,
            Message: SUBMISSION_ERROR_MESSAGE,
            OtherInfo: SUBMISSION_OTHERINFO_MESSAGE(
              `LOCATION: handleReferralSaveFromDropdown(), catch block -- ERRORS: ${err})`
            ),
          },
        },
      });

      returnObject.OK = false;
      returnObject.message = "Referral submission fail!";
    }

    return returnObject;
  };

  const handleObtainUpdatedRecords = (providerID) => {
    const currentProvider = inputs?.Providers.filter(
      (provider) => provider.ProviderID === providerID
    )[0];
    const updatedProvider = {
      ...currentProvider,
      ProviderID: generateUUID(),
      SpecialInstructions: "Updated Materials being Requested",
      RetrieveStartDate: "",
      RetrieveEndDate: "",
      OriginalDateEntered: "",
      DateSubmitted: "",
    };
    delete updatedProvider.fromDB;
    setInputs((prev) => ({
      ...prev,
      Providers: [...inputs.Providers, updatedProvider],
    }));
    setNewProvider((prev) => ({
      ...prev,
      new: true,
      ID: updatedProvider.ProviderID,
    }));
  };

  return (
    <div className="referral-page">
      <ReferralHeader mode={inputs?.product} />
      <main className="main-container" style={{ left: 0, paddingBottom: 300 }}>
        <section className="section-container">
          <Container>
            <form
              autoComplete="off"
              id="referral-form"
              style={{
                marginTop: /^records$/gi.test(inputs?.product)
                  ? "7rem"
                  : "6rem",
              }}
              onSubmit={handleSubmit}
            >
              <ReferralFormBase
                DOLRequired={DOLRequired}
                disabled={
                  (/^records$/gi.test(inputs?.product) &&
                    isRecordsReferralSubmitted) ||
                  SubmitRecordsReferralLoading ||
                  SendReferralReceiptLoading ||
                  UpdateSubmittedReferralLoading ||
                  UpdateNonSubmittedReferralLoading ||
                  uploadingFiles
                }
                inputs={inputs}
                isGeicoInvestigationsRequirement={
                  isGeicoInvestigationsRequirement
                }
                isGeicoMarltonOffice={isGeicoMarltonOffice}
                isLowesCase={
                  clientOfficesData?.findFirstClientoffice?.COclientMasterID ===
                  "CM3279"
                }
                mode={mode}
                product={inputs.product}
                setInputs={setInputs}
                teamConnectID={teamConnectIDData?.company?.TeamConnectID}
                onChange={handleChange}
              />

              {/^records$/gi.test(inputs.product) ? (
                <SubServiceForm
                  disabled={
                    (/^records$/gi.test(mode) && isRecordsReferralSubmitted) ||
                    SubmitRecordsReferralLoading ||
                    SendReferralReceiptLoading ||
                    UpdateSubmittedReferralLoading ||
                    UpdateNonSubmittedReferralLoading
                  }
                  handleChange={handleSubServiceChange}
                  inputs={inputs}
                  isGeicoOffice={isGeicoOffice}
                />
              ) : (
                ""
              )}

              {/^medical management|investigations/gi.test(inputs.product) && (
                <React.Fragment>
                  <PhysicalProblems
                    inputs={inputs}
                    isGeicoInvestigationsRequirement={
                      isGeicoInvestigationsRequirement
                    }
                    problems={problems}
                    onChange={(_, value) => setProblems(value)}
                  />

                  {inputs.product === "Medical Management" && (
                    <React.Fragment>
                      <MedicalServiceInstructions
                        inputs={inputs}
                        onChange={handleChange}
                      />

                      <SpecialServices
                        inputs={inputs}
                        onChange={handleChange}
                      />

                      <AdditionalInformation
                        inputs={additionalInformation}
                        teamConnectID={
                          teamConnectIDData?.company?.TeamConnectID
                        }
                        onChange={handleAdditionalInformationChange}
                      />

                      {!!additionalInformation.PlaintiffAttorney && (
                        <PlaintiffAttorneyForm
                          allstateNJAttorneys={
                            allstateNJAttorneys?.findManyAllstateNJAttorneys
                          }
                          inputs={plaintiffAttorney}
                          isAllstateOffice={isAllstateOffice}
                          setPlaintiffAttorney={setPlaintiffAttorney}
                          onChange={handlePlaintiffAttorneyChange}
                        />
                      )}

                      {!!additionalInformation.DefenseAttorney && (
                        <DefenseAttorneyForm
                          allstateNJAttorneys={
                            allstateNJAttorneys?.findManyAllstateNJAttorneys
                          }
                          inputs={defenseAttorney}
                          isAllstateOffice={isAllstateOffice}
                          required={
                            teamConnectIDData?.company?.TeamConnectID &&
                            additionalInformation.Adjuster
                          }
                          setDefenseAttorney={setDefenseAttorney}
                          onChange={handleDefenseAttorneyChange}
                        />
                      )}

                      {/* co-defendant form captures all the same info as DefenseAttorney */}
                      {!!additionalInformation.CoDefendant && (
                        <DefenseAttorneyForm
                          allstateNJAttorneys={
                            allstateNJAttorneys?.findManyAllstateNJAttorneys
                          }
                          inputs={coDefendant}
                          isAllstateOffice={isAllstateOffice}
                          setDefenseAttorney={setCoDefendant}
                          title={"Co-Defendant"}
                          onChange={handleCoDefendantChange}
                        />
                      )}

                      {!!additionalInformation.Insured && (
                        <InsuredInformation
                          inputs={insuredInformation}
                          onChange={handleInsuredInformationChange}
                        />
                      )}

                      {!!additionalInformation.Vehicle && (
                        <VehicleInformation
                          inputs={vehicleInformation}
                          onChange={handleVehicleInformationChange}
                        />
                      )}

                      {!!additionalInformation.Employer && (
                        <Employer
                          inputs={employer}
                          onChange={handleSetEmployer}
                        />
                      )}

                      {!!additionalInformation.TreatingPhysician && (
                        <TreatingPhysician
                          inputs={treatingPhysician}
                          onChange={handleTreatingPhysiciansChange}
                        />
                      )}

                      {!!additionalInformation.Adjuster && (
                        <AdjusterForm
                          inputs={adjusterInformation}
                          isAllstateOffice={isAllstateOffice}
                          isGeicoInvestigationsRequirement={
                            isGeicoInvestigationsRequirement
                          }
                          required={
                            teamConnectIDData?.company?.TeamConnectID &&
                            additionalInformation.Adjuster
                          }
                          onChange={handleAdjusterInfoChange}
                        />
                      )}

                      <ClaimQuestionsForExaminingPhysician
                        handleChange={handleClaimQuestionsChange}
                        inputs={claimQuestions}
                      />
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}

              {/^investigations$/gi.test(inputs.product) && (
                <React.Fragment>
                  <AdditionalInformation
                    inputs={additionalInformation}
                    onChange={handleAdditionalInformationChange}
                  />

                  {!!additionalInformation.PlaintiffAttorney && (
                    <PlaintiffAttorneyForm
                      inputs={plaintiffAttorney}
                      onChange={handlePlaintiffAttorneyChange}
                    />
                  )}

                  {!!additionalInformation.DefenseAttorney && (
                    <DefenseAttorneyForm
                      inputs={defenseAttorney}
                      required={
                        teamConnectIDData?.company?.TeamConnectID &&
                        additionalInformation.Adjuster
                      }
                      onChange={handleDefenseAttorneyChange}
                    />
                  )}

                  {/* co-defendant form captures all the same info as Defense Attorney */}
                  {!!additionalInformation.CoDefendant && (
                    <DefenseAttorneyForm
                      inputs={coDefendant}
                      title={"Co-Defendant"}
                      onChange={handleCoDefendantChange}
                    />
                  )}

                  {!!additionalInformation.Insured && (
                    <InsuredInformation
                      inputs={insuredInformation}
                      onChange={handleInsuredInformationChange}
                    />
                  )}

                  {!!additionalInformation.Vehicle && (
                    <VehicleInformation
                      inputs={vehicleInformation}
                      onChange={handleVehicleInformationChange}
                    />
                  )}

                  {!!additionalInformation.Employer && (
                    <Employer inputs={employer} onChange={handleSetEmployer} />
                  )}

                  {!!additionalInformation.TreatingPhysician && (
                    <TreatingPhysician
                      inputs={treatingPhysician}
                      onChange={handleTreatingPhysiciansChange}
                    />
                  )}

                  {!!additionalInformation.Adjuster && (
                    <AdjusterForm
                      inputs={adjusterInformation}
                      isAllstateOffice={isAllstateOffice}
                      isGeicoInvestigationsRequirement={
                        isGeicoInvestigationsRequirement
                      }
                      required={
                        teamConnectIDData?.company?.TeamConnectID &&
                        additionalInformation.Adjuster
                      }
                      onChange={handleAdjusterInfoChange}
                    />
                  )}
                  <SurveillanceActivityCheck
                    inputs={surveillanceActivityCheck}
                    surveillancePicked={inputs.ServicesRequested.some(
                      (service) => service.value === "11"
                    )}
                    onChange={handleSurveillanceActivityChange}
                  />
                  <InvestigativeServiceInstructions
                    inputs={investigativeServiceInstructions}
                    onChange={handleInvestigativeServiceChange}
                  />
                </React.Fragment>
              )}

              {/^records$/gi.test(inputs?.product) && (
                <React.Fragment>
                  <ClientContactInformationForm
                    disabled={
                      (/^records$/gi.test(mode) &&
                        isRecordsReferralSubmitted) ||
                      SubmitRecordsReferralLoading ||
                      SendReferralReceiptLoading ||
                      UpdateSubmittedReferralLoading ||
                      UpdateNonSubmittedReferralLoading
                    }
                    handleChange={handleChange}
                    inputs={inputs}
                    rpRequired={inputs.Providers.some(
                      (prov) => !prov.deleted && prov.RequestMethod === 2
                    )} // Request Method = Subpoena for records
                  />

                  <BillingInformationForm
                    disabled={
                      (/^records$/gi.test(mode) &&
                        isRecordsReferralSubmitted) ||
                      SubmitRecordsReferralLoading ||
                      SendReferralReceiptLoading ||
                      UpdateSubmittedReferralLoading ||
                      UpdateNonSubmittedReferralLoading
                    }
                    handleBillingSameAsRequestorChange={
                      handleBillingSameAsRequestorChange
                    }
                    handleChange={handleChange}
                    inputs={inputs}
                    isUSAA={isUSAA}
                  />

                  <Notes
                    disabled={
                      (/^records$/gi.test(mode) &&
                        isRecordsReferralSubmitted) ||
                      SubmitRecordsReferralLoading ||
                      SendReferralReceiptLoading ||
                      UpdateSubmittedReferralLoading ||
                      UpdateNonSubmittedReferralLoading
                    }
                    handleChange={handleChangeNotes}
                    notes={notes}
                  />

                  <RecordRetrievalRequestForServiceForm
                    disabled={
                      (/^records$/gi.test(mode) &&
                        isRecordsReferralSubmitted) ||
                      SubmitRecordsReferralLoading ||
                      SendReferralReceiptLoading ||
                      UpdateSubmittedReferralLoading ||
                      UpdateNonSubmittedReferralLoading
                    }
                    handleAddProvider={handleAddProvider}
                    handleArrChange={handleArrChange}
                    handleDeleteProvider={handleDeleteProvider}
                    handleDolChange={handleChange}
                    handleObtainUpdatedRecords={handleObtainUpdatedRecords}
                    inputs={inputs}
                    isSubmittedState={isRecordsReferralSubmitted}
                    rrIdDbInfo={rrId}
                  />

                  <SubpoenaInformationForm
                    disabled={
                      isRecordsReferralSubmitted ||
                      SubmitRecordsReferralLoading ||
                      SendReferralReceiptLoading ||
                      UpdateSubmittedReferralLoading ||
                      UpdateNonSubmittedReferralLoading
                    }
                    handleChange={handleSubpoenaInputChange}
                    inputs={subpoenaInfo}
                    nonPartyRequestActive={checkProviderNonPartyRequest}
                    requestMethod1={inputs?.Providers?.some(
                      (prov) => prov.RequestMethod === 1
                    )} // Request Method = ISG Retrieve Authorization from opposing counsel
                    required={inputs.Providers.some(
                      (prov) => !prov.deleted && prov.RequestMethod === 2
                    )} // Request Method = Subpoena for records
                  />

                  <CounselForm
                    disabled={
                      (/^records$/gi.test(mode) &&
                        isRecordsReferralSubmitted) ||
                      SubmitRecordsReferralLoading ||
                      SendReferralReceiptLoading ||
                      UpdateSubmittedReferralLoading ||
                      UpdateNonSubmittedReferralLoading
                    }
                    handleAddCounsel={handleAddCounsel}
                    handleArrChange={handleCounselChange}
                    handleDeleteCounsel={handleDeleteCounsel}
                    inputs={inputs}
                  />
                </React.Fragment>
              )}

              {/^records|investigations|medical management$/gi.test(
                inputs.product
              ) && (
                <Box className="col-span">
                  <ReferralStyledSubtitle>Upload Files</ReferralStyledSubtitle>
                  <Field className="span-2">
                    <FileUploader
                      multiple
                      filesToUpload={files}
                      loading={uploadingFiles}
                      name="files"
                      uploadPercentage={uploadPercentage}
                      uploadedFiles={inputs.Uploads.filter((f) => !f.deleted)}
                      onChange={(name, f) =>
                        setFiles((prev) => [...prev, ...f])
                      }
                      onRemoveFile={handleRemoveFile}
                    />
                  </Field>
                </Box>
              )}
            </form>
          </Container>
        </section>
        {/* <pre>{JSON.stringify(inputs, null, 2)}</pre> */}
      </main>
      {/* <DebugQueryParams /> */}
      <ReferralFooter>
        <Field kind="group">
          {claimId && claimantId && sourceId && (
            <Control>
              <Button
                color="default"
                disabled={
                  SubmitRecordsReferralLoading ||
                  SendReferralReceiptLoading ||
                  UpdateSubmittedReferralLoading ||
                  UpdateNonSubmittedReferralLoading ||
                  uploadingFiles
                }
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  if (window.parent) {
                    window.parent.postMessage("close", "*");
                  }
                }}
              >
                Cancel
              </Button>
            </Control>
          )}
          {/^records$/gi.test(inputs?.product) &&
            !isRecordsReferralSubmitted && (
              <SaveAndSubmitButton
                actionType={SAVE}
                disabled={
                  SubmitReferralLoading ||
                  SubmitRecordsReferralLoading ||
                  UpdateNonSubmittedReferralLoading ||
                  uploadingFiles
                }
                handleProductChange={handleProductChange}
                handleReferralSaveFromDropdown={handleReferralSaveFromDropdown}
                handleSubmitRecordsReferral={handleSubmitRecordsReferral}
                handleSubmitReferral={handleSubmitReferral}
                inputs={inputs}
                lsiClientEmail={lsiClientData?.findFirstLsiClient?.CLemail}
                state={
                  SubmitReferralLoading ||
                  SubmitRecordsReferralLoading ||
                  UpdateNonSubmittedReferralLoading
                }
              >
                <RetainClaimAndClaimaint
                  handleChange={setRetainClaimClaimantInformation}
                  value={retainClaimClaimantInformation}
                />
              </SaveAndSubmitButton>
            )}
          <SaveAndSubmitButton
            actionType={SUBMIT}
            disabled={
              SubmitReferralLoading ||
              SubmitRecordsReferralLoading ||
              SendReferralReceiptLoading ||
              UpdateNonSubmittedReferralLoading ||
              uploadingFiles
            }
            handleProductChange={handleProductChange}
            handleReferralSaveFromDropdown={handleReferralSaveFromDropdown}
            handleSubmitRecordsReferral={handleSubmitRecordsReferral}
            handleSubmitReferral={handleSubmitReferral}
            inputs={inputs}
            lsiClientEmail={lsiClientData?.findFirstLsiClient?.CLemail}
            state={
              SubmitReferralLoading ||
              SubmitRecordsReferralLoading ||
              UpdateNonSubmittedReferralLoading ||
              isUpdatingSubmittedReferral
            }
          >
            <RetainClaimAndClaimaint
              handleChange={setRetainClaimClaimantInformation}
              value={retainClaimClaimantInformation}
            />
          </SaveAndSubmitButton>
        </Field>
      </ReferralFooter>
    </div>
  );
};

ReferralPage.propTypes = {};

export default ReferralPage;
