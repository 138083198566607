import { gql } from "@apollo/client";

export const LIST_CASE_HISTORY_QUERY = gql`
  query caseHistories(
    $where: CaseHistoryWhereInput
    $orderBy: [CaseHistoryOrderByInput!]
    $take: Int
    $skip: Int
  ) {
    caseHistories(take: $take, orderBy: $orderBy, skip: $skip, where: $where) {
      PublishOnWeb
      PublishedTo
      WebGUID
      WebSynchDate
      casenbr
      dateadded
      dateedited
      duration
      eventdate
      eventdesc
      id
      otherinfo
      status
      type
      userid
      useridedited
    }
    aggregateCaseHistory(where: $where) {
      count {
        id
        casenbr
      }
    }
  }
`;

export const ALL_CASE_HISTORY_QUERY = gql`
  query caseHistories(
    $where: CaseHistoryWhereInput
    $orderBy: [CaseHistoryOrderByInput!]
    $take: Int
    $skip: Int
  ) {
    caseHistories(take: $take, orderBy: $orderBy, skip: $skip, where: $where) {
      PublishOnWeb
      PublishedTo
      WebGUID
      WebSynchDate
      casenbr
      dateadded
      dateedited
      duration
      eventdate
      eventdesc
      id
      otherinfo
      status
      type
      userid
      useridedited
      IQ
    }
  }
`;

export const SINGLE_CASE_HISTORY_QUERY = gql`
  query caseHistory($where: CaseHistoryWhereUniqueInput!) {
    caseHistory(where: $where) {
      PublishOnWeb
      PublishedTo
      WebGUID
      WebSynchDate
      casenbr
      dateadded
      dateedited
      duration
      eventdate
      eventdesc
      id
      otherinfo
      status
      type
      userid
      useridedited
    }
  }
`;

export const FIND_FIRST_CASE_HISTORY_QUERY = gql`
  query caseHistory(
    $where: CaseHistoryWhereInput!
    $orderBy: [CaseHistoryOrderByInput!]
    $take: Int
    $skip: Int
  ) {
    findFirstCaseHistory(
      take: $take
      orderBy: $orderBy
      skip: $skip
      where: $where
    ) {
      PublishOnWeb
      PublishedTo
      WebGUID
      WebSynchDate
      casenbr
      dateadded
      dateedited
      duration
      eventdate
      eventdesc
      id
      otherinfo
      status
      type
      userid
      useridedited
    }
  }
`;
