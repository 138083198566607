import React from "react";
import PropTypes from "prop-types";
import { DocumentForm } from "../../components";

const Profile = ({ inputs, onChange, disabled }) => (
  <DocumentForm disabled={disabled} inputs={inputs} onChange={onChange} />
);

Profile.propTypes = {
  inputs: PropTypes.object,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

Profile.defaultProps = {
  inputs: {},
  disabled: false,
  onChange: (e) => e,
};

export default Profile;
