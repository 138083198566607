import { gql } from "@apollo/client";

export const ALL_CC_ADDRESSES_QUERY = gql`
  query ALL_CC_ADDRESSES_QUERY(
    $where: CCAddressWhereInput
    $orderBy: [CCAddressOrderByInput!]
    $take: Int
    $skip: Int
  ) {
    cCAddresses(where: $where, orderBy: $orderBy, skip: $skip, take: $take) {
      cccode
      prefix
      firstname
      lastname
      company
      address1
      address2
      city
      state
      zip
      phone
      phoneextension
      fax
      email
    }
    aggregateCCAddress: aggregateCCAddress(where: $where) {
      count {
        cccode
      }
    }
  }
`;

export const SINGLE_CC_ADDRESS_QUERY = gql`
  query SINGLE_CC_ADDRESS_QUERY($where: CCAddressWhereUniqueInput!) {
    cCAddress(where: $where) {
      cccode
      prefix
      firstname
      lastname
      company
      address1
      address2
      city
      state
      zip
      phone
      phoneextension
      fax
      email
    }
  }
`;
