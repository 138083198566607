import React, { useState } from "react";
import { Field, Control, Input, Button, Title, Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";

import DataTable from "../../components/DataTable";

import { CaseListFilters } from "./components";

import { CASE_ACCOUNTING_COLUMNS } from "./columns";

import { debounce, getFilterState, saveFilterState } from "../../utils";

import { LIST_CASE_ACCOUNTING_QUERY } from "../../graphql";

const INITIAL_FILTER_STATE = {
  status: { equals: 5 },
};

const CaseListAccountingPage = (props) => {
  const history = useHistory();
  const [where, setWhere] = useState(
    getFilterState("CASE_LIST_ACCOUNTING_APPT_FILTERS", INITIAL_FILTER_STATE)
  );

  const [showFilters, setShowFilters] = useState(false);

  const handleReset = () => {
    saveFilterState("CASE_LIST_ACCOUNTING_APPT_FILTERS", INITIAL_FILTER_STATE);

    setWhere(INITIAL_FILTER_STATE);
  };

  const handleChange = (name, value) => {
    if (!value) {
      setWhere((prev) =>
        Object.keys(prev).reduce((acc, curr) => {
          if (curr !== name) acc[curr] = prev[curr];

          return acc;
        }, {})
      );
    } else {
      setWhere((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleRowClick = ({ casenbr }) => {
    history.push(`/cases/${casenbr}/profile`);
  };

  return (
    <div>
      <div className="section-header">
        <Title style={{ marginRight: 15 }}>Accounting</Title>

        <Field kind="group" style={{ paddingTop: "0.3rem" }}>
          <Control>
            <Input
              autoComplete="new"
              placeholder="Find by Case #"
              size="small"
              onChange={debounce(({ target: { value } }) => {
                setWhere((prev) =>
                  value
                    ? { ...prev, casenbr: { equals: parseInt(value, 10) } }
                    : Object.keys(prev)

                        .filter((x) => x !== "casenbr")

                        .reduce((a, c) => ({ ...a, [c]: prev[c] }), {})
                );
              }, 500)}
            />
          </Control>

          <Control>
            <Button
              color="warning"
              size="small"
              onClick={() => setShowFilters((prev) => !prev)}
            >
              <Icon>
                <FontAwesomeIcon icon="filter" />
              </Icon>

              <span>Filters</span>
            </Button>
          </Control>
        </Field>
      </div>

      {showFilters && (
        <CaseListFilters
          disableStatusFilter
          filters={where}
          show={showFilters}
          onChange={handleChange}
          onReset={handleReset}
        />
      )}

      <DataTable
        aggregateKey="casenbr"
        aggregateName="aggregateCase"
        columns={CASE_ACCOUNTING_COLUMNS}
        name="cases"
        orderBy={[{ id: "casenbr", desc: true }]}
        query={LIST_CASE_ACCOUNTING_QUERY}
        where={where}
        onRowClick={handleRowClick}
      />
    </div>
  );
};

CaseListAccountingPage.propTypes = {};

export default CaseListAccountingPage;
