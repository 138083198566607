import React from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/client";
import { Label } from "rbx";
import { ALL_CASE_DOCUMENTS_QUERY } from "../../../../graphql";

import Table from "../../../../components/DataTable/Table";

import { generateColumns, transformDocumentObjectToKey } from "./columns";

const CaseEmailDocumentAttachments = ({
  casenbr,
  attachments,
  onChange,
  disabled,
}) => {
  const { data: GetCaseDocumentsData } = useQuery(ALL_CASE_DOCUMENTS_QUERY, {
    variables: {
      where: {
        casenbr: { equals: parseInt(casenbr, 10) },
        OR: [
          {
            reporttype: { not: { equals: "Report" } },
          },
          {
            reporttype: { equals: null },
          },
        ],
      },
    },
    fetchPolicy: "cache-and-network",
  });

  const documents = Array.isArray(GetCaseDocumentsData?.caseDocuments)
    ? GetCaseDocumentsData.caseDocuments
    : [];

  const handleSelect = (documentKey) => {
    const currAttachments = [...attachments];
    const idx = currAttachments.indexOf(documentKey);

    if (idx > -1) {
      onChange([
        ...currAttachments.slice(0, idx),
        ...currAttachments.slice(idx + 1),
      ]);
    } else {
      onChange([...attachments, documentKey]);
    }
  };

  const allSelected = documents.every((doc) =>
    attachments.includes(transformDocumentObjectToKey(doc))
  );

  const handleSelectAll = () => {
    onChange(allSelected ? [] : documents.map(transformDocumentObjectToKey));
  };

  const COLUMNS = generateColumns({
    onSelect: handleSelect,
    onSelectAll: handleSelectAll,
    allSelected,
    selected: attachments,
    disabled,
  });

  const handleRowClick = (doc) => {
    handleSelect(transformDocumentObjectToKey(doc));
  };

  return (
    <div className="case-email-attachment-list">
      <Label>Documents</Label>
      <Table columns={COLUMNS} data={documents} onRowClick={handleRowClick} />
    </div>
  );
};

CaseEmailDocumentAttachments.propTypes = {
  casenbr: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  attachments: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};
CaseEmailDocumentAttachments.defaultProps = {
  disabled: false,
};

export default CaseEmailDocumentAttachments;
