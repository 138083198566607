import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Field, Control, Input, Label, Icon, Button } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import GenericSelect from "../../../../components/GenericSelect";

import {
  hours,
  minutes as initialMinutes,
  duration,
  meridiem,
} from "./options";

const DoctorScheduleInputs = ({ inputs, onChange, onReset, disabled }) => {
  const minutes = useMemo(() => {
    if (inputs.duration === "30")
      return initialMinutes.filter((x) => x.value === "00" || x.value === "30");
    if (inputs.duration === "60")
      return initialMinutes.filter((x) => x.value === "00");
    return initialMinutes;
  }, [inputs.duration]);

  return (
    <Field kind="group" style={{ marginBottom: "1.5rem" }}>
      <GenericSelect
        disabled={disabled}
        label="Intervals"
        name="duration"
        options={duration}
        value={inputs.duration}
        onChange={onChange}
      />
      <Control>
        <Label>Date</Label>
        <Input
          required
          autoComplete="new"
          disabled={disabled}
          name="date"
          size="small"
          type="date"
          value={inputs.date}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
      <Control>
        <Field kind="addons">
          <Control>
            <GenericSelect
              disabled={disabled}
              label="From"
              name="startHours"
              options={hours}
              value={inputs.startHours}
              onChange={onChange}
            />
          </Control>
          <Control>
            <GenericSelect
              disabled={disabled}
              label="&nbsp;"
              name="startMinutes"
              options={minutes}
              value={inputs.startMinutes}
              onChange={onChange}
            />
          </Control>
          <Control>
            <GenericSelect
              disabled={disabled}
              label="&nbsp;"
              name="startMeridiem"
              options={meridiem}
              value={inputs.startMeridiem}
              onChange={onChange}
            />
          </Control>
        </Field>
      </Control>
      <Control>
        <Field kind="addons">
          <Control>
            <GenericSelect
              disabled={disabled}
              label="To"
              name="endHours"
              options={hours}
              value={inputs.endHours}
              onChange={onChange}
            />
          </Control>
          <Control>
            <GenericSelect
              disabled={disabled}
              label="&nbsp;"
              name="endMinutes"
              options={minutes}
              value={inputs.endMinutes}
              onChange={onChange}
            />
          </Control>
          <Control>
            <GenericSelect
              disabled={disabled}
              label="&nbsp;"
              name="endMeridiem"
              options={meridiem}
              value={inputs.endMeridiem}
              onChange={onChange}
            />
          </Control>
        </Field>
      </Control>
      <Control>
        <GenericSelect
          disabled={disabled}
          label="Status"
          name="status"
          options={[
            {
              value: "Open",
              label: "Open",
              id: 1,
            },
            {
              value: "Off",
              label: "Off",
              id: 2,
            },
            {
              value: "Hold",
              label: "Hold",
              id: 3,
            },
            {
              value: "Scheduled",
              label: "Scheduled",
              id: 4,
            },
          ]}
          value={inputs.status}
          onChange={onChange}
        />
      </Control>

      <Control style={{ flex: 1, textAlign: "right" }}>
        <Label>&nbsp;</Label>
        <Button
          static
          color="default"
          size="small"
          style={{
            backgroundColor: "transparent",
            boxShadow: "unset",
            border: "none",
            color: "#363636",
          }}
        >
          <Icon>
            <FontAwesomeIcon color="#2a473471" icon="square" />
          </Icon>
          <span>New</span>
        </Button>
      </Control>
      <Control style={{ flex: 1, textAlign: "right" }}>
        <Label>&nbsp;</Label>
        <Button color="warning" size="small" type="button" onClick={onReset}>
          <Icon>
            <FontAwesomeIcon icon="redo" />
          </Icon>
          <span>Reset</span>
        </Button>
      </Control>
    </Field>
  );
};

DoctorScheduleInputs.propTypes = {
  onChange: PropTypes.func.isRequired,
  inputs: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  onReset: PropTypes.func.isRequired,
};

export default DoctorScheduleInputs;
