import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { AsyncPaginate } from "react-select-async-paginate";

import { Label, Control } from "rbx";
import { useApolloClient, useLazyQuery } from "@apollo/client";
import { LIST_CLIENTS_QUERY, SINGLE_CLIENT_QUERY } from "../../graphql/clients";

const QUERY_LIMIT = 50;

const ClientSelect = ({
  showLabel,
  name,
  value: initialValue,
  label,
  onChange,
  disabled,
  required,
  showLink,
  where: initialWhere,
}) => {
  const apolloClient = useApolloClient();

  const [getClient, { data: getClientData }] = useLazyQuery(
    SINGLE_CLIENT_QUERY
  );

  const [value, setValue] = useState(null);
  const [defaultOptions, setDefaultOptions] = useState([]);
  const indicateRequired =
    required && (!value || value === "" || value === "0" || value === 0);

  const getLabel = (client) =>
    [
      [client.lastname, client.firstname].filter(Boolean).join(", "),
      client?.company?.intname,
    ]
      .filter(Boolean)
      .join(" - ");

  const promiseOptions = async (inputValue, { length }) => {
    const [lastname, firstname] = inputValue
      .split(/,/)
      .map((x) => x.trim())
      .filter(Boolean);

    const where = {
      ...initialWhere,
      lastname: { startsWith: lastname },
      firstname: { startsWith: firstname },
    };
    const { data: clientsData } = await apolloClient.query({
      query: LIST_CLIENTS_QUERY,
      variables: {
        take: QUERY_LIMIT,
        where,
        orderBy: [{ lastname: "asc" }, { firstname: "asc" }],
        skip: length,
      },
    });
    const clients = clientsData?.clients;

    const result = Array.isArray(clients)
      ? clients.map((client) => ({
          value: client.clientcode,
          label: getLabel(client),
          companycode: client.companycode,
          IMECompanyLabel: client.companyLabel,
        }))
      : [];
    setDefaultOptions(result);
    return {
      options: result,
      hasMore: result.length === QUERY_LIMIT,
    };
  };

  const handleChange = ({ value: newValue, companycode }) => {
    onChange(name, newValue, companycode);
  };

  useEffect(() => {
    if (initialValue) {
      getClient({
        variables: {
          where: { clientcode: parseInt(initialValue, 10) },
        },
      });
    } else {
      setValue(null);
    }
  }, [initialValue, getClient]);

  useEffect(() => {
    const client = getClientData?.client;
    if (client) {
      setValue({
        value: client.clientcode,
        label: getLabel(client),
        companycode: client.companycode,
      });
    }
  }, [getClientData]);

  useEffect(() => {
    if (value) {
      const currOptions = [...defaultOptions];
      if (!currOptions.some((opt) => opt.value === value.value)) {
        setDefaultOptions((prev) => [value, ...prev]);
      }
    }
  }, [value, defaultOptions]);

  return (
    <Control expanded>
      {showLabel && showLink && (
        <Label
          as={Link}
          className="label-link"
          tabIndex="-1"
          to={value?.value ? `/clients/${value.value}/profile` : "/clients"}
        >
          {label}
        </Label>
      )}
      {showLabel && !showLink && <Label>{label}</Label>}
      <AsyncPaginate
        cacheOptions
        defaultOptions={defaultOptions}
        isDisabled={disabled}
        loadOptions={promiseOptions}
        placeholder="Start typing..."
        styles={{
          indicatorsContainer: (provided) => ({
            ...provided,
            height: "inherit",
          }),
          indicatorSeparator: (provided) => ({
            ...provided,
            display: "none",
          }),
          container: (provided) => ({
            fontSize: 13,
          }),
          singleValue: (provided) => ({
            ...provided,
            top: "38%",
          }),
          control: (provided) => ({
            ...provided,
            minHeight: 31,
            height: 31,
            width: "100%",
            minWidth: 140,
            border: indicateRequired ? "1px #e63362 solid" : "",
            borderRadius: "4px",
            boxShadow: indicateRequired
              ? "0 0 0 0.125em rgba(230, 51, 98, 0.25)"
              : "",
            "&:hover": {
              borderColor: "hsl(0, 0%, 90%)",
              borderRadius: 4,
              cursor: disabled ? "not-allowed" : "pointer",
            },
          }),
          placeholder: (provided) => ({ ...provided, top: "38%" }),
          menu: (provided) => ({
            ...provided,
            fontSize: 12,
            zIndex: 999,
          }),
        }}
        value={value}
        onChange={(e) => handleChange(e)}
      />
    </Control>
  );
};

ClientSelect.propTypes = {
  showLabel: PropTypes.bool,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  where: PropTypes.object,
  showLink: PropTypes.bool,
};

ClientSelect.defaultProps = {
  showLink: false,
  name: "",
  label: "",
  value: "",
  onChange: () => null,
  showLabel: true,
  disabled: false,
  required: false,
  where: {},
};

export default ClientSelect;
