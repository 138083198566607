/* eslint-disable import/prefer-default-export */
import { gql } from "@apollo/client";

export const CREATE_CASE_OTHER_PARTY_MUTATION = gql`
  mutation createCaseOtherParty($data: CaseOtherPartyCreateInput!) {
    createCaseOtherParty(data: $data) {
      OPCode
      Type
      casenbr
    }
  }
`;

export const UPDATE_CASE_OTHER_PARTY_MUTATION = gql`
  mutation updateCaseOtherParty(
    $data: CaseOtherPartyUpdateInput!
    $where: CaseOtherPartyWhereUniqueInput!
  ) {
    updateCaseOtherParty(data: $data, where: $where) {
      OPCode
      Type
      casenbr
    }
  }
`;

export const DELETE_CASE_OTHER_PARTY_MUTATION = gql`
  mutation deleteCaseOtherParty(
    $where: CaseOtherPartyWhereUniqueInput!
  ) {
    deleteCaseOtherParty(where: $where) {
      casenbr
      OPCode
    }
  }
`;