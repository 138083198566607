import { gql } from "@apollo/client";

export const CREATE_CASE_TYPE_MUTATION = gql`
  mutation CREATE_CASE_TYPE_MUTATION($data: CaseTypeCreateInput!) {
    createCaseType(data: $data) {
      code
      description
    }
  }
`;

export const UPDATE_CASE_TYPE_MUTATION = gql`
  mutation UPDATE_CASE_TYPE_MUTATION(
    $data: CaseTypeUpdateInput!
    $where: CaseTypeWhereUniqueInput!
  ) {
    updateCaseType(data: $data, where: $where) {
      code
      description
    }
  }
`;

export const DELETE_CASE_TYPE_MUTATION = gql`
  mutation DELETE_CASE_TYPE_MUTATION($where: CaseTypeWhereUniqueInput!) {
    deleteCaseType(where: $where) {
      code
    }
  }
`;

export const CREATE_CASE_TYPE_OFFICE_MUTATION = gql`
  mutation CREATE_CASE_TYPE_OFFICE_MUTATION($data: CaseTypeOfficeCreateInput!) {
    createCaseTypeOffice(data: $data) {
      officecode
      casetype
    }
  }
`;

export const DELETE_CASE_TYPE_OFFICE_MUTATION = gql`
  mutation DELETE_CASE_TYPE_OFFICE_MUTATION(
    $where: CaseTypeOfficeWhereUniqueInput!
  ) {
    deleteCaseTypeOffice(where: $where) {
      casetype
    }
  }
`;
