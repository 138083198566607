import React, { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { Control, Label } from "rbx";
import { useApolloClient, useLazyQuery } from "@apollo/client";
import { AsyncPaginate } from "react-select-async-paginate";

import {
  RATS_SINGLE_MEDICAL_PROVIDER_QUERY,
  RATS_ALL_MEDICAL_PROVIDERS_QUERY,
} from "../../graphql";

const QUERY_LIMIT = 10;

const DEFAULT_STATE = {
  MPnameLast: "",
  MPnameFirst: "",
  MPgroupName: "",
  MPaddress1: "",
  MPaddress2: "",
  MPcity: "",
  MPstate: "",
  MPzip: "",
  MPphone1: "",
  MPfax: "",
};

const RatsMedicalProviderSelect = ({
  name,
  value: initialValue,
  label,
  onChange,
  disabled,
  getValue,
  setValue,
  required,
  onSelectedAddressChange,
  onAddressInputsChange,
}) => {
  const client = useApolloClient();

  const [getMedicalProvider, { data: medicalProviderData }] = useLazyQuery(
    RATS_SINGLE_MEDICAL_PROVIDER_QUERY
  );

  const [selected, setSelected] = useState(null);
  const [defaultOptions, setDefaultOptions] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState([]);
  const [inputs, setInputs] = useState({ ...DEFAULT_STATE });

  useEffect(() => {
    if (typeof onSelectedAddressChange === "function") {
      onSelectedAddressChange(selectedAddress);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAddress]);

  useEffect(() => {
    if (typeof onAddressInputsChange === "function") {
      onAddressInputsChange(inputs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputs]);

  const getLabel = useCallback(
    (mp) =>
      `${
        mp.MPnameFirst || mp.MPnameLast
          ? `${mp.MPnameFirst} ${mp.MPnameLast}`
          : "N/A"
      } - ${mp.MPaddress1 || "N/A"}`,
    []
  );

  const promiseOptions = async (inputValue, { length }) => {
    const where = {
      OR: [
        { MPnameFirst: { contains: inputValue } },
        { MPnameLast: { contains: inputValue } },
      ],
    };

    // If no user input, promise nothing, return nothing
    if (!inputValue) {
      return {
        options: [],
        hasMore: 0,
      };
    }

    const { data: ratsMedicalProvidersData } = await client.query({
      query: RATS_ALL_MEDICAL_PROVIDERS_QUERY,
      variables: {
        take: QUERY_LIMIT,
        where,
        orderBy: [{ MPnameFirst: "asc" }, { MPnameLast: "asc" }],
        skip: length,
      },
      fetchPolicy: "network-only",
    });

    const medicalProviders = ratsMedicalProvidersData?.medicalproviders;

    const result = Array.isArray(medicalProviders)
      ? medicalProviders.map((mp) => ({
          value: mp.MPid,
          label: getLabel(mp),
        }))
      : [];
    setDefaultOptions(result);

    return {
      options: result,
      hasMore: result.length === QUERY_LIMIT,
    };
  };

  const handleChange = ({ value: newValue }) => {
    onChange(name, setValue(newValue || ""));
  };

  useEffect(() => {
    if (initialValue) {
      getMedicalProvider({
        variables: {
          where: { MPid: getValue(initialValue) },
        },
      });
    } else {
      setSelected(null);
      setSelectedAddress([]);
      setInputs({ ...DEFAULT_STATE });
    }
  }, [initialValue, getValue, getMedicalProvider]);

  useEffect(() => {
    const medicalProvider = medicalProviderData?.medicalprovider;
    if (medicalProvider) {
      setSelected({
        value: medicalProvider.MPid,
        label: getLabel(medicalProvider),
      });

      const address = [
        `${medicalProvider.MPnameLast}, ${medicalProvider.MPnameFirst}`,
        medicalProvider.MPgroupName,
        medicalProvider.MPaddress1,
        medicalProvider.MPaddress2,
        `${medicalProvider.MPcity}, ${medicalProvider.MPstate} ${medicalProvider.MPzip}`,
        medicalProvider.MPphone1,
      ];

      setSelectedAddress(address);

      setInputs((prev) => ({
        ...prev,
        MPnameLast: medicalProvider.MPnameLast,
        MPnameFirst: medicalProvider.MPnameFirst,
        MPgroupName: medicalProvider.MPgroupName,
        MPaddress1: medicalProvider.MPaddress1,
        MPaddress2: medicalProvider.MPaddress2,
        MPcity: medicalProvider.MPcity,
        MPstate: medicalProvider.MPstate,
        MPzip: medicalProvider.MPzip,
        MPphone1: medicalProvider.MPphone1,
        MPfax: medicalProvider.MPfax,
      }));
    }
  }, [medicalProviderData?.medicalprovider, getLabel]);

  useEffect(() => {
    if (selected) {
      const currOptions = [...defaultOptions];
      if (!currOptions.some((opt) => opt.value === selected.value)) {
        setDefaultOptions((prev) => [selected, ...prev]);
      }
    }
  }, [selected, defaultOptions]);

  const indicateRequired =
    required &&
    !disabled &&
    (!initialValue ||
      initialValue === "" ||
      initialValue === "0" ||
      initialValue === 0);

  return (
    <Control expanded style={{ flexGrow: 1, marginRight: "0.313rem" }}>
      <Label>{label}</Label>
      <AsyncPaginate
        cacheOptions
        debounceTimeout={500}
        defaultOptions={defaultOptions}
        isDisabled={disabled}
        loadOptions={promiseOptions}
        menuPortalTarget={document.body}
        placeholder="Start typing..."
        required={required}
        styles={{
          menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
          indicatorsContainer: (provided) => ({
            ...provided,
            height: "inherit",
          }),
          indicatorSeparator: (provided) => ({
            ...provided,
            display: "none",
          }),
          container: (provided) => ({
            fontSize: 13,
          }),
          singleValue: (provided) => ({
            ...provided,
            top: "38%",
          }),
          control: (provided) => ({
            ...provided,
            minHeight: 31,
            height: 31,
            width: "100%",
            minWidth: 180,
            border: indicateRequired ? "1px #e63362 solid" : "",
            boxShadow: indicateRequired
              ? "0 0 0 0.125em rgba(230, 51, 98, 0.25)"
              : "",
            "&:hover": {
              borderColor: "hsl(0, 0%, 90%)",
              borderRadius: 4,
              cursor: disabled ? "not-allowed" : "pointer",
            },
          }),
          placeholder: (provided) => ({ ...provided, top: "38%" }),
          menu: (provided) => ({
            ...provided,
            fontSize: 12,
            zIndex: 999,
          }),
        }}
        value={selected}
        onChange={handleChange}
      />
    </Control>
  );
};

RatsMedicalProviderSelect.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  getValue: PropTypes.func,
  setValue: PropTypes.func,
  required: PropTypes.bool,
  onSelectedAddressChange: PropTypes.func,
  onAddressInputsChange: PropTypes.func,
};

RatsMedicalProviderSelect.defaultProps = {
  name: "",
  label: "",
  value: "",
  onChange: () => null,
  disabled: false,
  getValue: (x) => x,
  setValue: (x) => x,
  required: false,
  onSelectedAddressChange: () => null,
  onAddressInputsChange: () => null,
};

export default RatsMedicalProviderSelect;
