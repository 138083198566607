import { useQuery } from "@apollo/client";
import { ALLSTATE_JOHN_TROP_PEOPLE } from "../graphql";

const useGetJohnTropList = (companyIntName = "", filterBy, input) => {
  const isJohnTropCompany = companyIntName.includes("John Trop");

  const { data } = useQuery(ALLSTATE_JOHN_TROP_PEOPLE, {
    skip: !isJohnTropCompany,
  });

  const filteredTroplist =
    data?.allstateJohnTropSelection?.length && input
      ? data?.allstateJohnTropSelection.filter((e) =>
          e?.[filterBy]?.toLowerCase().includes(input.toLowerCase())
        )
      : [];

  const makeMap = (contactList) => {
    const hashMap = {};
    contactList.forEach((c) => {
      if (!hashMap[c?.intname]) hashMap[c?.intname] = [];
      hashMap[c?.intname].push(c);
    });
    return hashMap;
  };

  const mappedFilteredList = makeMap(filteredTroplist);

  return { mappedFilteredList };
};

export default useGetJohnTropList;
