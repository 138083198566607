/* eslint-disable func-names */
import React from "react";
import PropTypes from "prop-types";
import { Button, Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useApolloClient } from "@apollo/client";
import { format, addMinutes } from "date-fns";

import jsPDF from "jspdf";
import "jspdf-autotable";

import { ALL_CASE_HISTORY_QUERY, SINGLE_CASE_QUERY } from "../../graphql";
import { customToast as toast, convertTimeZone } from "../../utils";

const CaseExportButton = ({ casenbr }) => {
  const client = useApolloClient();

  const formatDate = (date) =>
    format(addMinutes(date, date.getTimezoneOffset()), "MM/dd/yyyy hh:mm a");

  const handleExportData = async () => {
    try {
      const { data: caseHistoryData } = await client.query({
        query: ALL_CASE_HISTORY_QUERY,
        variables: {
          where: {
            casenbr: {
              equals: parseInt(casenbr, 10),
            },
          },
          orderBy: [
            {
              eventdate: "desc",
            },
            {
              id: "asc",
            },
          ],
        },
      });

      if (
        caseHistoryData?.caseHistories &&
        Array.isArray(caseHistoryData?.caseHistories)
      ) {
        if (!caseHistoryData?.caseHistories.length) {
          toast.error("No case history data found.");
          return;
        }

        const { data: CaseData } = await client.query({
          query: SINGLE_CASE_QUERY,
          variables: {
            where: {
              casenbr: parseInt(casenbr, 10),
            },
          },
        });

        const caseItem = CaseData?.caseItem;

        const exportData = {
          tableHeaders: [
            "Event Date",
            "Description",
            "Other Info",
            "IQ",
            "Web",
            "By",
          ],
          header: [
            {
              name: "Case",
              value: casenbr,
              y: 40,
              x: 50,
            },
            {
              name: "Client",
              value: [caseItem?.client?.firstname, caseItem?.client?.lastname]
                .filter(Boolean)
                .join(" "),
              y: 320,
              x: 50,
            },
            {
              name: "Company",
              value: caseItem?.client?.company?.intname || "",
              y: 40,
              x: 65,
            },
            {
              name: "Appt",
              value: caseItem?.Appttime
                ? formatDate(new Date(caseItem?.Appttime))
                : "",
              y: 320,
              x: 65,
            },
            {
              name: "Investigator",
              value: [
                caseItem?.doctor?.firstname,
                caseItem?.doctor?.lastname,
                caseItem?.doctor?.credentials,
              ]
                .filter(Boolean)
                .join(" "),
              y: 40,
              x: 80,
            },
            {
              name: "Specialty",
              value: caseItem?.doctorspecialty || "",
              y: 320,
              x: 80,
            },
            {
              name: "Location",
              value: caseItem?.location?.location || "",
              y: 40,
              x: 95,
            },
            {
              name: "Examinee",
              value: [
                caseItem?.examinee?.firstname,
                caseItem?.examinee?.lastname,
              ]
                .filter(Boolean)
                .join(" "),
              y: 320,
              x: 95,
            },
            {
              name: "Scheduler",
              value: [
                caseItem?.scheduler?.firstname,
                caseItem?.scheduler?.lastname,
              ]
                .filter(Boolean)
                .join(" "),
              y: 40,
              x: 110,
            },
            {
              name: "QA Rep",
              value: [
                caseItem?.QAReporter?.firstname,
                caseItem?.QAReporter?.lastname,
              ]
                .filter(Boolean)
                .join(" "),
              y: 320,
              x: 110,
            },
            {
              name: "Case Type",
              value: caseItem?.caseType?.description || "",
              y: 40,
              x: 125,
            },
            {
              name: "Service",
              value: caseItem?.service?.description,
              y: 320,
              x: 125,
            },
          ],
        };

        const exportTableData = caseHistoryData?.caseHistories.map(
          (caseHistory) => [
            caseHistory?.eventdate
              ? format(
                  convertTimeZone(caseHistory.eventdate),
                  "MM/dd/yyyy hh:mm a"
                )
              : "--",
            caseHistory?.eventdesc || "--",
            caseHistory?.otherinfo || "--",
            caseHistory?.IQ || 0,
            caseHistory?.PublishOnWeb ? "Yes" : "No",
            caseHistory?.userid || "--",
          ]
        );

        const unit = "pt";
        const size = "A4";
        const orientation = "portrait";
        const marginLeft = 210;
        // eslint-disable-next-line new-cap
        const doc = new jsPDF(orientation, unit, size);
        doc.setFontSize(20);
        doc.setTextColor("#2a4734");
        const title = "Case History Report";
        doc.setTextColor("#000");
        const headers = [exportData.tableHeaders];
        doc.text(title, marginLeft, 25);
        doc.setFontSize(10);
        exportData.header.forEach((element) => {
          doc.text(element.y, element.x, `${element.name}: ${element.value}`);
        });
        doc.autoTable({
          startY: 145,
          head: headers,
          body: exportTableData,
          // eslint-disable-next-line object-shorthand
          didParseCell: function (table) {
            if (table.section === "head") {
              // eslint-disable-next-line no-param-reassign
              table.cell.styles.fillColor = "#2a4734";
            }
          },
        });
        doc.save(`caseHistoryFor${casenbr}.pdf`);
      } else {
        throw new Error();
      }
    } catch (err) {
      toast.error("Error preparing case history data.");
    }
  };

  return (
    <Button
      color="primary"
      size="small"
      title="Export Case History"
      onClick={handleExportData}
    >
      <Icon>
        <FontAwesomeIcon icon="print" />
      </Icon>
    </Button>
  );
};

CaseExportButton.propTypes = {
  casenbr: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default CaseExportButton;
