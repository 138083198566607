import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { Title, Input, Button, Icon, Field, Label, Control } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useParams } from "react-router-dom";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import axios from "axios";
import { useCaseDocumentPath } from "../../../../hooks";
import { generateColumns } from "./columns";

import {
  SINGLE_CLIENT_QUERY,
  LIST_CASE_DOCUMENT_QUERY,
  CREATE_CASE_HISTORY_MUTATION,
  UPDATE_CASE_MUTATION,
  UPDATE_CASE_DOCUMENT_MUTATION,
  DELETE_CASE_DOCUMENT_MUTATION,
  SINGLE_CASE_QUERY,
  LIST_CASE_HISTORY_QUERY,
} from "../../../../graphql";
import {
  getDateEST,
  convertTimeZoneDataBase,
  customToast as toast,
  download,
} from "../../../../utils";

import { useModal, useAuth } from "../../../../context";

import DataTable from "../../../../components/DataTable";

import Confirmation from "../../../../components/Confirmation";
import ReportStatusSelect from "../../../../components/ReportStatusSelect";

import CaseAttachExternalReportModal from "../../components/CaseAttachExternalReportModal";
import CaseDocumentReportModal from "../../components/CaseDocumentReportModal";
import CaseReportModal from "../../components/CaseReportModal";

const MAX_REPORTS = 999;

const {
  REACT_APP_FILE_SERVER_ADDRESS,
  REACT_APP_DOCUMENT_HOST,
  REACT_APP_CLOUD_FILE_SERVER_ADDRESS,
} = process.env;

class CustomCaseReportError extends Error {}
const Reports = ({ inputs, onChange, onSubmit, disabled }) => {
  const { state: authState } = useAuth();
  const { casenbr: pcasenbr, workid } = useParams();
  const casenbr = pcasenbr || workid;
  const { setModalOpen } = useModal();

  const where = useMemo(
    () => ({
      casenbr: { equals: parseInt(casenbr, 10) },
      type: { equals: "Report" },
      // OR: [
      //   { reporttype: { equals: "Report" } },
      //   { reporttype: { equals: "Addendum" } },
      //   { reporttype: { equals: "File Review" } },
      // ],
    }),
    [casenbr]
  );

  const [loading, setLoading] = useState(false);
  const [createCaseHistory] = useMutation(CREATE_CASE_HISTORY_MUTATION);
  const [updateCase] = useMutation(UPDATE_CASE_MUTATION);
  const [updateCaseDocument] = useMutation(UPDATE_CASE_DOCUMENT_MUTATION);
  const [deleteCaseDocument] = useMutation(DELETE_CASE_DOCUMENT_MUTATION);

  const { data: CaseDocumentReportsData } = useQuery(LIST_CASE_DOCUMENT_QUERY, {
    variables: { where },
  });

  const [getClient, { data: getClientData }] = useLazyQuery(
    SINGLE_CLIENT_QUERY
  );

  const isAddDisabled = useMemo(() => {
    const reportCount =
      typeof CaseDocumentReportsData?.aggregateCaseDocument?.count?._all ===
      "number"
        ? CaseDocumentReportsData?.aggregateCaseDocument?.count?._all
        : MAX_REPORTS;
    return reportCount >= MAX_REPORTS;
  }, [CaseDocumentReportsData?.aggregateCaseDocument?.count?._all]);

  useEffect(() => {
    if (inputs?.client) {
      getClient({
        variables: {
          where: { clientcode: parseInt(inputs.client, 10) },
        },
      });
    }
  }, [getClient, inputs?.client]);

  const handleReportStatusChange = async (newValue) => {
    try {
      setLoading(true);
      const date = convertTimeZoneDataBase(getDateEST());
      await updateCase({
        variables: {
          where: { casenbr: parseInt(casenbr, 10) },
          data: {
            rptstatus: { set: newValue },
            FinalizedRptDate: { set: newValue === "Final" ? date : null },
          },
        },
        refetchQueries: [
          {
            query: SINGLE_CASE_QUERY,
            variables: { where: { casenbr: parseInt(casenbr, 10) } },
          },
        ],
      });
      await createCaseHistory({
        variables: {
          data: {
            casenbr: parseInt(casenbr, 10),
            eventdate: date,
            eventdesc: `Report Status Change`,
            otherinfo:
              newValue === "Final" ? "Finalized Rpt" : "Unfinalized Rpt",
            userid: authState?.user?.userid,
            dateadded: date,
            useridedited: authState?.user?.userid,
            dateedited: date,
            type: "Report",
          },
        },
        refetchQueries: [
          {
            query: LIST_CASE_HISTORY_QUERY,
            variables: {
              where: { casenbr: { equals: parseInt(casenbr, 10) } },
            },
            fetchPolicy: "network-only",
          },
        ],
      });
      toast.success(`Report status updated successfully.`);
    } catch (err) {
      toast.error(`Error updating report status.`);
    } finally {
      setLoading(false);
    }
  };

  const handleAddClick = () => {
    setModalOpen(
      true,
      <CaseDocumentReportModal
        casenbr={casenbr}
        onComplete={() => setModalOpen(false, "")}
      />
    );
  };

  const COLUMNS = useMemo(() => {
    const handleDelete = (caseDocument) => {
      const performDelete = async () => {
        try {
          setLoading(true);
          setModalOpen(false, "");
          await deleteCaseDocument({
            variables: {
              where: {
                seqno: parseInt(caseDocument.seqno, 10),
              },
            },
            refetchQueries: [
              {
                query: LIST_CASE_DOCUMENT_QUERY,
                variables: {
                  where,
                },
              },
            ],
          });
          toast.success("Case Report deleted successfully.");
        } catch (err) {
          toast.error(`Error deleting Case Report.`);
        } finally {
          setLoading(false);
        }
      };
      setModalOpen(
        true,
        <Confirmation
          message="Are you sure you want to delete this report?"
          onCancel={() => setModalOpen(false, "")}
          onConfirm={performDelete}
        />
      );
    };

    const handlePublish = async (caseDocument) => {
      const performPublish = async () => {
        try {
          setLoading(true);
          await updateCaseDocument({
            variables: {
              data: {
                PublishOnWeb: { set: !caseDocument.PublishOnWeb },
              },
              where: {
                seqno: parseInt(caseDocument.seqno, 10),
              },
            },
            refetchQueries: [
              {
                query: LIST_CASE_DOCUMENT_QUERY,
                variables: { where },
                fetchPolicy: "network-only",
              },
            ],
          });
          toast.success("Case Document published successfully.");
        } catch (err) {
          toast.error(`Error updating document Publish On Web status.`);
        } finally {
          setModalOpen(false, "");
          setLoading(false);
        }
      };
      setModalOpen(
        true,
        <Confirmation
          message={`Are you sure you want to ${
            caseDocument.PublishOnWeb
              ? "unpublish this case document from web?"
              : "publish this case document to web?"
          }`}
          onCancel={() => setModalOpen(false, "")}
          onConfirm={performPublish}
        />
      );
    };
    return generateColumns(handleDelete, handlePublish);
  }, [deleteCaseDocument, setModalOpen, updateCaseDocument, where]);

  const handleFinalizeClick = (unfinalize = false) => () => {
    setModalOpen(
      true,
      <Confirmation
        message={`Are you sure you want to ${
          unfinalize ? "un" : ""
        }finalize this report?`}
        onCancel={() => setModalOpen(false, "")}
        onConfirm={() => {
          handleReportStatusChange(unfinalize ? "" : "Final");
          setModalOpen(false, "");
        }}
      />
    );
  };

  const getCaseVirtualDocumentPath = useCaseDocumentPath(casenbr);

  const handleOpenDocumentFolder = async () => {
    try {
      const documentPath = getCaseVirtualDocumentPath("", false);

      // ensure path
      await axios.get(
        `${REACT_APP_DOCUMENT_HOST}/verify?path=${encodeURIComponent(
          documentPath
        )}`
      );

      const isCloudStored = /ISGCloudStorage/.test(documentPath);

      const fileServerAddress = isCloudStored
        ? REACT_APP_CLOUD_FILE_SERVER_ADDRESS
        : REACT_APP_FILE_SERVER_ADDRESS;

      const directoryPath = isCloudStored
        ? documentPath.replace("ISGCloudStorage/", "")
        : documentPath;

      const text = `[InternetShortcut]\nURL=file://${fileServerAddress}/${directoryPath}`;
      download(`${casenbr}.url`, "plain", text);
    } catch (err) {
      const message =
        err instanceof CustomCaseReportError
          ? err.message
          : "There was an error performing this operation. Please contact your administrator.";
      toast.error(message);
    }
  };

  const handleAttachExternalClick = () => {
    setModalOpen(
      true,
      <CaseAttachExternalReportModal
        casenbr={casenbr}
        onComplete={() => {
          setModalOpen(false, "");
        }}
      />
    );
  };

  const handleRowClick = ({ seqno }) => {
    setModalOpen(
      true,
      <CaseReportModal
        casenbr={casenbr}
        seqno={seqno}
        onComplete={() => setModalOpen(false, "")}
      />
    );
  };

  return (
    <form id="edit-case-form" onSubmit={onSubmit}>
      <div className="page-head reports-head">
        <div className="page-head-start">
          <Button
            color="primary"
            disabled={isAddDisabled}
            size="small"
            style={{ marginRight: "0.5rem" }}
            type="button"
            onClick={handleAddClick}
          >
            <Icon>
              <FontAwesomeIcon icon="plus" />
            </Icon>
            <span>Add</span>
          </Button>
          <Button
            color="primary"
            size="small"
            style={{ marginRight: "0.5rem" }}
            type="button"
            onClick={handleOpenDocumentFolder}
          >
            <Icon>
              <FontAwesomeIcon icon="folder-open" />
            </Icon>
            <span>Browse</span>
          </Button>
          <Button
            color="primary"
            size="small"
            type="button"
            onClick={handleAttachExternalClick}
          >
            <Icon>
              <FontAwesomeIcon icon="plus" />
            </Icon>
            <span>Attach</span>
          </Button>
          <Title size={6}>Case Reports</Title>
        </div>
        <div className="page-head-end">
          <Field horizontal style={{ marginBottom: 0 }}>
            <Field.Label size="small">
              <Label style={{ whitespace: "nowrap" }}>Client</Label>
            </Field.Label>
            <Field.Body>
              <Control>
                <Input
                  readOnly
                  size="small"
                  value={[
                    getClientData?.client?.lastname,
                    getClientData?.client?.firstname,
                  ]
                    .filter(Boolean)
                    .join(", ")}
                />
              </Control>
            </Field.Body>
          </Field>
          <Field horizontal style={{ marginBottom: 0, marginRight: "0.75rem" }}>
            <Field.Label size="small">
              <Label style={{ whiteSpace: "nowrap" }}>Report Status</Label>
            </Field.Label>
            <Field.Body>
              <Field>
                <ReportStatusSelect
                  disabled={loading || disabled}
                  name="rptstatus"
                  noValueLabel=""
                  value={inputs.rptstatus}
                  onChange={(name, value) =>
                    onChange({
                      target: {
                        name,
                        value,
                      },
                    })
                  }
                />
              </Field>
            </Field.Body>
          </Field>
          <Field>
            <Control>
              {inputs.rptstatus === "Final" ? (
                <Button
                  color="warning"
                  disabled={loading}
                  size="small"
                  type="button"
                  onClick={handleFinalizeClick(true)}
                >
                  <Icon>
                    <FontAwesomeIcon icon="undo" />
                  </Icon>
                  <span>Unfinalize</span>
                </Button>
              ) : (
                <Button
                  color="danger"
                  disabled={loading}
                  size="small"
                  type="button"
                  onClick={handleFinalizeClick(false)}
                >
                  <Icon>
                    <FontAwesomeIcon icon="check-double" />
                  </Icon>
                  <span>Finalize</span>
                </Button>
              )}
            </Control>
          </Field>
        </div>
      </div>
      <DataTable
        aggregateKey="_all"
        aggregateName="aggregateCaseDocument"
        columns={COLUMNS}
        fetchPolicy="cache-and-network"
        name="caseDocuments"
        orderBy={[{ id: "casenbr", desc: true }]}
        query={LIST_CASE_DOCUMENT_QUERY}
        where={where}
        onRowClick={handleRowClick}
      />
    </form>
  );
};

Reports.propTypes = {
  inputs: PropTypes.object,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  disabled: PropTypes.bool,
};

Reports.defaultProps = {
  inputs: {},
  onChange: () => null,
  onSubmit: () => null,
  disabled: false,
};
export default Reports;
