import React, { useState } from "react";
import PropTypes from "prop-types";

import { useMutation } from "@apollo/client";
import { Title, Button } from "rbx";

import { customToast as toast, getDateEST } from "../../../../utils";
import {
  CREATE_DR_DO_NOT_USE_MUTATION,
  LIST_DR_DO_NOT_USES_QUERY,
} from "../../../../graphql";

import ClientDrDoNotUseForm from "../ClientDrDoNotUseForm";
import { useAuth } from "../../../../context";

const initialState = {
  doctorcode: "",
};

const ClientDrDoNotUseModal = ({ clientcode, onComplete }) => {
  const { state: authState } = useAuth();
  const [inputs, setInputs] = useState({ ...initialState });

  const [createDrDoNotUse, { loading: CreateDrDoNotUseLoading }] = useMutation(
    CREATE_DR_DO_NOT_USE_MUTATION
  );

  const handleSave = async (e) => {
    try {
      e.preventDefault();

      await createDrDoNotUse({
        variables: {
          data: {
            doctor: {
              connect: { doctorcode: parseInt(inputs.doctorcode, 10) },
            },
            code: parseInt(clientcode, 10),
            type: "CL",
            dateadded: getDateEST(),
            useridadded: authState?.user?.userid,
          },
        },
        refetchQueries: [
          {
            query: LIST_DR_DO_NOT_USES_QUERY,
            variables: {
              where: { code: { equals: parseInt(clientcode, 10) } },
            },
          },
        ],
      });
      toast.success(`Dr Do Not Use created successfully.`);
      onComplete(true);
    } catch (err) {
      toast.error(`Error creating doctor document.`);
    }
  };
  const handleChange = (name, value) => {
    setInputs((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <form id="doctor-location-form" onSubmit={handleSave}>
      <header className="page-head">
        <div className="page-head-start">
          <Title size={5}>Create Doctor Do Not Use</Title>
        </div>
        <div className="page-head-end">
          <Button.Group hasAddons>
            <Button
              size="small"
              type="button"
              onClick={() => onComplete(false)}
            >
              <span>Cancel</span>
            </Button>
            <Button
              color="primary"
              disabled={!inputs.doctorcode || CreateDrDoNotUseLoading}
              form="doctor-location-form"
              size="small"
              state={CreateDrDoNotUseLoading ? "loading" : ""}
              type="submit"
            >
              <span>Submit</span>
            </Button>
          </Button.Group>
        </div>
      </header>
      <hr />
      <ClientDrDoNotUseForm
        disabled={CreateDrDoNotUseLoading}
        inputs={inputs}
        onChange={handleChange}
      />
      <hr />
      {/* <pre>
        <code>{JSON.stringify(inputs, null, 2)}</code>
      </pre> */}
    </form>
  );
};

ClientDrDoNotUseModal.propTypes = {
  clientcode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onComplete: PropTypes.func,
};

ClientDrDoNotUseModal.defaultProps = {
  clientcode: "",
  onComplete: () => null,
};
export default ClientDrDoNotUseModal;
