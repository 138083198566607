import React from "react";
import { format } from "date-fns";
import PropTypes from "prop-types";
import {
  Column,
  Input,
  Label,
  Field,
  Control,
  Textarea,
  Icon,
  Button,
} from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useModal } from "../../../context";
import SpecialtySelect from "../../../components/SpecialtySelect";
import InsightCodeSelect from "../../../components/InsightCodeSelect";
import CaseParametersTables from "./CaseParametersTables";
import DegreeSelect from "../../../components/DegreeSelect";
import MedsStatusSelect from "../../../components/MedsStatusSelect";
import SubCaseModal from "./SubCaseModal";
import CaseLinkToMasterModal from "./CaseLinkToMasterModal";
import CaseClaimNumberCheckModal from "./CaseClaimNumberCheckModal";

const CaseParametersForm = ({
  inputs,
  onChange,
  disabled,
  onSubmit,
  hasSubcases,
}) => {
  const { setModalOpen } = useModal();

  const handleAddSubcase = (e) => {
    e.preventDefault();
    setModalOpen(
      true,
      <SubCaseModal
        casenbr={inputs.casenbr}
        onComplete={() => setModalOpen(false)}
      />
    );
  };
  const handleLinkCaseToMaster = (e) => {
    e.preventDefault();
    setModalOpen(
      true,
      <CaseLinkToMasterModal
        casenbr={inputs.casenbr}
        onComplete={() => setModalOpen(false)}
      />
    );
  };

  return (
    <form id="edit-case-form" onSubmit={onSubmit}>
      <Column.Group>
        <Column size={6}>
          <Field kind="group">
            <Control expanded>
              <Label>External Due Date</Label>
              <Input
                disabled={disabled}
                name="ExternalDueDate"
                size="small"
                type="date"
                value={inputs.ExternalDueDate}
                onChange={onChange}
              />
            </Control>
            <Control expanded>
              <Label>Internal Due Date</Label>
              <Input
                disabled={disabled}
                name="InternalDueDate"
                size="small"
                type="date"
                value={inputs.InternalDueDate}
                onChange={onChange}
              />
            </Control>
          </Field>
          <Field kind="group">
            <Control>
              <Label>Date of Injury</Label>
              <Input
                required
                disabled={disabled}
                max={format(new Date(), "yyyy-MM-dd")}
                name="dateofinjury"
                size="small"
                type="date"
                value={inputs.dateofinjury}
                onChange={onChange}
              />
            </Control>
            <Control expanded>
              <Field kind="group">
                <Control expanded>
                  <Label>Claim Nbr</Label>
                  <Input
                    required
                    autoComplete="new"
                    disabled={disabled}
                    maxLength={50}
                    name="claimnbr"
                    size="small"
                    type="text"
                    value={inputs.claimnbr}
                    onChange={onChange}
                  />
                </Control>
                <Control>
                  <Label>Claim Check</Label>
                  <Button
                    color="success"
                    disabled={disabled || !inputs.claimnbr}
                    size="small"
                    type="button"
                    onClick={() => {
                      setModalOpen(
                        true,
                        <CaseClaimNumberCheckModal
                          claimnbr={inputs.claimnbr}
                          onComplete={() => setModalOpen(false, "")}
                        />
                      );
                    }}
                  >
                    <Icon size="large">
                      <FontAwesomeIcon icon="external-link-alt" />
                    </Icon>
                  </Button>
                </Control>
              </Field>
            </Control>
          </Field>
          <Field kind="group">
            <SpecialtySelect
              required
              disabled={disabled}
              label="Request Specialty"
              name="sreqspecialty"
              value={inputs.sreqspecialty}
              onChange={onChange}
            />
            <DegreeSelect
              disabled={disabled}
              label="Request Degree"
              name="sreqdegree"
              value={inputs.sreqdegree}
              onChange={onChange}
            />
          </Field>
          <Field kind="group">
            <Control expanded>
              <Label>ICD-9 Code 1</Label>
              <Input
                autoComplete="new"
                disabled={disabled}
                maxLength={70}
                name="icd9code"
                size="small"
                type="text"
                value={inputs.icd9code}
                onChange={onChange}
              />
            </Control>
            <Control expanded>
              <Label>ICD-9 Code 2</Label>
              <Input
                autoComplete="new"
                disabled={disabled}
                maxLength={70}
                name="ICD9Code2"
                size="small"
                type="text"
                value={inputs.ICD9Code2}
                onChange={onChange}
              />
            </Control>
          </Field>
        </Column>
        <Column size={6}>
          <Field kind="group">
            <Control expanded>
              <Label>Date / Time Received</Label>
              <Input
                autoComplete="new"
                disabled={disabled}
                name="DateReceived"
                size="small"
                type="datetime-local"
                value={inputs.DateReceived}
                onChange={onChange}
              />
            </Control>
            <Control>
              <Label>Called In By</Label>
              <Input
                autoComplete="new"
                disabled={disabled}
                maxLength={50}
                name="calledinby"
                size="small"
                type="text"
                value={inputs.calledinby}
                onChange={onChange}
              />
            </Control>
          </Field>
          <Field kind="group">
            <Control expanded>
              <Label>WCB Nbr</Label>
              <Input
                autoComplete="new"
                disabled={disabled}
                maxLength={50}
                name="WCBNbr"
                size="small"
                type="text"
                value={inputs.WCBNbr}
                onChange={onChange}
              />
            </Control>
            <Control expanded>
              <Label>Hearing Date</Label>
              <Input
                disabled={disabled}
                name="HearingDate"
                size="small"
                type="date"
                value={inputs.HearingDate}
                onChange={onChange}
              />
            </Control>
            <Control expanded>
              <MedsStatusSelect
                showBlank
                disabled={disabled}
                name="reccode"
                value={inputs.reccode}
                onChange={onChange}
              />
            </Control>
            <Control expanded>
              <Label>Meds Received</Label>
              <Input
                disabled={disabled}
                name="datemedsrecd"
                size="small"
                type="date"
                value={inputs.datemedsrecd}
                onChange={onChange}
              />
            </Control>
          </Field>
          <Field kind="group">
            <Control expanded>
              <Label>Int. Case Nbr</Label>
              <Input
                autoComplete="new"
                disabled={disabled}
                maxLength={255}
                name="sinternalcasenbr"
                size="small"
                type="text"
                value={inputs.sinternalcasenbr}
                onChange={onChange}
              />
            </Control>
            <Control expanded>
              <Label>Cert. Mail Nbr</Label>
              <Input
                autoComplete="new"
                disabled={disabled}
                maxLength={50}
                name="CertMailNbr"
                size="small"
                type="text"
                value={inputs.CertMailNbr}
                onChange={onChange}
              />
            </Control>
          </Field>
          <Field kind="group">
            <Control expanded>
              <Label>ICD-9 Code 3</Label>
              <Input
                autoComplete="new"
                disabled={disabled}
                maxLength={70}
                name="ICD9Code3"
                size="small"
                type="text"
                value={inputs.ICD9Code3}
                onChange={onChange}
              />
            </Control>
            <Control expanded>
              <Label>ICD-9 Code 4</Label>
              <Input
                autoComplete="new"
                disabled={disabled}
                maxLength={70}
                name="ICD9Code4"
                size="small"
                type="text"
                value={inputs.ICD9Code4}
                onChange={onChange}
              />
            </Control>
          </Field>
        </Column>
      </Column.Group>
      <hr />
      {inputs.casenbr && (
        <CaseParametersTables disabled={disabled} inputs={inputs} />
      )}
      <hr />
      <Column.Group>
        <Column size={6}>
          <Field>
            <Control style={{ marginBottom: "1rem" }}>
              <Label>Dr. Special Instructions</Label>
              <Textarea
                disabled={disabled}
                name="specialinstructions"
                size="small"
                value={inputs.specialinstructions}
                onChange={onChange}
              />
            </Control>
          </Field>
          <Field>
            <Control>
              <Label>Notes</Label>
              <Textarea
                disabled={disabled}
                name="notes"
                size="small"
                value={inputs.notes}
                onChange={onChange}
              />
            </Control>
          </Field>
        </Column>
        <Column size={6}>
          <Field className="grid-override" kind="group">
            <InsightCodeSelect
              disabled={disabled}
              label="Referral Type"
              name="referraltype"
              size="small"
              value={inputs.referraltype}
              where={{ Type: { equals: "RT" } }}
              onChange={onChange}
            />
            <InsightCodeSelect
              disabled={disabled}
              label="Referral Source"
              name="referralmethod"
              size="small"
              value={inputs.referralmethod}
              where={{ Type: { equals: "RS" } }}
              onChange={onChange}
            />
          </Field>
          <Field className="grid-override" kind="group">
            <InsightCodeSelect
              disabled={disabled}
              label="IME Co"
              name="IMECompany"
              size="small"
              value={inputs.IMECompany}
              where={{ Type: { equals: "CO" } }}
              onChange={onChange}
            />
            <Control expanded>
              <Label>IME/MD Name</Label>
              <Input
                autoComplete="new"
                disabled={disabled}
                maxLength={50}
                name="IMEMDName"
                size="small"
                type="text"
                value={inputs.IMEMDName}
                onChange={onChange}
              />
            </Control>
          </Field>
          <Field kind="group">
            <Control>
              <Label>&nbsp;</Label>
              <Button color="primary" size="small" onClick={handleAddSubcase}>
                <Icon>
                  <FontAwesomeIcon icon="plus" />
                </Icon>
                <span>Add Subcase</span>
              </Button>
            </Control>
            <Control>
              <Label>&nbsp;</Label>
              <Button
                color="primary"
                size="small"
                onClick={handleLinkCaseToMaster}
              >
                <Icon>
                  <FontAwesomeIcon icon="link" />
                </Icon>
                <span>Link Case To Master</span>
              </Button>
            </Control>
          </Field>
        </Column>
      </Column.Group>
    </form>
  );
};

CaseParametersForm.propTypes = {
  inputs: PropTypes.object,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  hasSubcases: PropTypes.bool,
};

CaseParametersForm.defaultProps = {
  inputs: {},
  onChange: (e) => e,
  disabled: false,
  hasSubcases: false,
};

export default CaseParametersForm;
