import React, { useState, useEffect } from "react";

import { Title, Button } from "rbx";

import { useParams, useHistory } from "react-router-dom";
import { useLazyQuery, useMutation } from "@apollo/client";

// componets
import {
  customToast,
  formatPhoneNumber,
  getDateEST,
  formatSSNumber,
} from "../../utils";
import Loader from "../../components/Loader";
import InvestigatorTabs from "./components/InvestigatorTabs";
import IconLink from "../../components/IconLink";

import { useAuth } from "../../context/AuthContext";
import { usePermissions } from "../../hooks";

// graphql
import {
  SINGLE_DOCTOR_QUERY,
  UPDATE_DOCTOR_MUTATION,
  CREATE_DOCTOR_MUTATION,
} from "../../graphql";

const initialState = {
  // PROFILE
  lastname: "",
  firstname: "",
  middleinitial: "",
  addr1: "",
  addr2: "",
  city: "",
  companyname: "",
  state: "",
  credentials: "",
  prefix: "",
  county: "",
  zip: "",
  phone: "",
  phoneExt: "",
  cellphone: "",
  faxNbr: "",
  emailAddr: "",
  status: "",
  // GENERAL
  SSNTaxID: "",
  licensenbr: "",
  UPIN: "",
  NPINbr: "",
  WCNbr: "",
  UnRegNbr: "",
  schedulepriority: "",
  ProvTypeCode: "",
  prepaid: false,
  doctoremail: "",
  medicalrecordemail: "",
  // NOTES
  notes: "",
  // QUALIFICATIONS
  qualifications: "",
  // USER DEFINED FIELDS
  usdvarchar1: "", // open field
  usdvarchar2: "", // web password
  usdvarchar3: "", // first looker
  usdtext1: "", // open field
  usdtext2: "", // open field
  usddate1: "", // desktop cred date
  usddate2: "", // cred request date
  usddate3: "", // full credential date
  usddate4: "", // recredential date
  usdint1: "", // type
  usdint2: "", // open field
};

const convertInputToVariables = (variables, keys, adding = false) =>
  keys.reduce((acc, curr) => {
    if (variables[curr]) {
      acc[curr] = adding ? variables[curr] : { set: variables[curr] };
    } else {
      acc[curr] = adding ? null : { set: null };
    }
    return acc;
  }, {});

class CustomDoctorError extends Error {}

const InvestigatorPage = (props) => {
  const { state: authState } = useAuth();
  const { doctorcode, tab } = useParams();
  const history = useHistory();
  const [disabled, setEditMode] = useState(true);
  const [inputs, setInputs] = useState(initialState);
  const [loading, setLoading] = useState(false);

  const hasPermission = usePermissions([
    "AllAdminFunc",
    "Accounting",
    "Cust Service",
    "QC",
  ]);

  const [getDoctor, { data: getDoctorData }] = useLazyQuery(
    SINGLE_DOCTOR_QUERY
  );

  const [createDoctor] = useMutation(CREATE_DOCTOR_MUTATION);
  const [updateDoctor] = useMutation(UPDATE_DOCTOR_MUTATION);

  useEffect(() => {
    if (doctorcode && doctorcode !== "add") {
      getDoctor({
        variables: {
          where: { doctorcode: parseInt(doctorcode, 10) },
        },
      });
    }
  }, [doctorcode, getDoctor]);

  useEffect(() => {
    const doctor = getDoctorData?.doctor;
    if (doctor) {
      setInputs((prev) => ({
        ...prev,
        ...Object.keys(doctor).reduce((acc, curr) => ({
          ...acc,
          [curr]: doctor[curr] || "",
        })),
      }));
    }
  }, [getDoctorData?.doctor]);

  const onChange = (name, value) => {
    setInputs((prev) => ({ ...prev, [name]: value }));
  };

  const handleSave = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const [isValid] = formatPhoneNumber(inputs.phone);
      const copyInitialState = { ...initialState };
      if (tab === "general") {
        if (inputs?.SSNTaxID) {
          const [isSSNValid] = formatSSNumber(inputs.SSNTaxID);
          if (!isSSNValid) {
            throw new CustomDoctorError(
              "SSN / Tax ID must be exactly 9 characters."
            );
          }
        }
      } else {
        delete copyInitialState.SSNTaxID;
      }
      if (isValid) {
        const methods =
          doctorcode === "add"
            ? {
                doctor: "createDoctor",
              }
            : {
                doctor: "updateDoctor",
              };

        const dateedited = getDateEST();
        const { data: doctorData } = await { createDoctor, updateDoctor }[
          methods.doctor
        ]({
          variables: {
            data: {
              ...convertInputToVariables(
                inputs,
                Object.keys(copyInitialState),
                doctorcode === "add"
              ),
              usdint1: inputs.usdint1
                ? { set: parseInt(inputs.usdint1, 10) }
                : "",
              dateedited:
                doctorcode === "add" ? dateedited : { set: dateedited },
              useridedited:
                doctorcode === "add"
                  ? authState?.user?.userid
                  : { set: authState?.user?.userid },
            },
            where: { doctorcode: parseInt(doctorcode, 10) },
          },
          refetchQueries: [
            {
              query: SINGLE_DOCTOR_QUERY,
              variables: {
                where: { doctorcode: parseInt(doctorcode, 10) },
              },
            },
          ],
        });

        const { doctorcode: resultDoctorCode } = doctorData[methods.doctor];

        customToast.success(
          `Investigator ${
            doctorcode !== "add" ? "updated" : "created"
          } successfully`
        );
        if (doctorcode === "add") {
          history.push(`/investigators/${resultDoctorCode}/locations`);
        }
      } else {
        customToast.warning("Invalid phone format.");
      }
    } catch (err) {
      if (err instanceof CustomDoctorError) {
        customToast.error(err.message);
      } else {
        customToast.warning("Error saving Investigator.");
      }
    } finally {
      setLoading(false);
    }
  };

  if (loading || (doctorcode !== "add" && !getDoctorData)) return <Loader />;

  return (
    <React.Fragment>
      <form
        className="add-company-form"
        id="add-company-form"
        key="edit-company-page"
        onSubmit={handleSave}
      >
        <header className="page-head">
          <div className="page-head-start">
            <Title>
              {doctorcode === "add"
                ? "Add New Investigator"
                : `${inputs?.prefix} ${inputs?.firstname} ${inputs?.middleinitial} ${inputs?.lastname} ${inputs?.credentials}`}
            </Title>
            <div style={{ marginLeft: "2rem" }}>
              <IconLink
                href={`tel:${inputs?.phone}`}
                icon="phone"
                label={inputs?.phone}
              />
            </div>
            <div style={{ marginLeft: "2rem" }}>
              <IconLink
                href={`mailto:${inputs?.emailAddr}`}
                icon="at"
                label={inputs?.emailAddr}
              />
            </div>
          </div>
          {hasPermission && (
            <div className="page-head-end">
              <Button.Group hasAddons style={{ justifyContent: "flex-end" }}>
                {doctorcode !== "add" && (
                  <Button
                    color="primary"
                    size="small"
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setEditMode(!disabled);
                    }}
                  >
                    Edit
                  </Button>
                )}
                <Button
                  color="success"
                  disabled={disabled}
                  size="small"
                  type="submit"
                >
                  Save
                </Button>
              </Button.Group>
            </div>
          )}
        </header>
      </form>
      {doctorcode !== "add" && (
        <InvestigatorTabs
          disabled={disabled}
          doctorcode={doctorcode}
          history={history}
          inputs={inputs}
          tab={tab}
          onChange={onChange}
        />
      )}
    </React.Fragment>
  );
};

InvestigatorPage.propTypes = {};

export default InvestigatorPage;
