const COLUMNS = [
  {
    Header: "Function Code",
    id: "functioncode",
    accessor: "functioncode",
  },
  {
    Header: "Function Description",
    id: "functiondesc",
    accessor: "functiondesc",
  },
];

export default COLUMNS;
