import React from "react";
import { Control, Button, Dropdown } from "rbx";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { usePortalPerson } from "../../../../hooks";
import {
  MEDICAL_MANAGEMENT,
  INVESTIGATIONS,
  RECORDS,
  SAVE,
} from "../../constants/variableTypes";

const SaveAndSubmitButton = ({
  handleSubmitRecordsReferral,
  handleSubmitReferral,
  handleProductChange,
  handleReferralSaveFromDropdown,
  disabled,
  state,
  inputs,
  actionType,
  lsiClientEmail,
  children,
}) => {
  const { portalPerson } = usePortalPerson();
  const showRecordsDropdownOptions =
    (!/^$/gi.test(lsiClientEmail) && inputs?.Email === lsiClientEmail) ||
    portalPerson?.RGID;

  const createDropDownItem = (name) => (
    <Dropdown.Item
      onClick={() => {
        const createReferralSuccess = handleReferralSaveFromDropdown(
          name,
          actionType
        );
        if (createReferralSuccess.OK) {
          handleProductChange(name);
        }
      }}
    >
      {actionType === SAVE ? "Save & Create new" : "Submit & Create new"}
      <span style={{ margin: "0 0.2rem", fontWeight: "bold" }}>{name}</span>
      referral
    </Dropdown.Item>
  );

  return (
    <Control>
      <Dropdown up style={{ marginTop: "1rem" }}>
        <Button.Group hasAddons>
          <Button
            color="primary"
            disabled={disabled}
            state={state && "loading"}
            style={{ marginBottom: "0" }}
            onClick={() =>
              /^records$/gi.test(inputs?.product)
                ? handleSubmitRecordsReferral(actionType, false, false)
                : handleSubmitReferral(false, false)
            } // default, submits (arg1) without creating a new referral (arg2) and without retaining claim/claimant info (arg3)
          >
            <span>{actionType === SAVE ? "Save" : "Submit"}</span>
          </Button>
          <Dropdown.Trigger>
            <Button color="white" style={{ marginBottom: "0" }}>
              <FontAwesomeIcon icon={faAngleUp} />
            </Button>
          </Dropdown.Trigger>
        </Button.Group>
        <Dropdown.Menu id="dropdown-save">
          <Dropdown.Content style={{ height: "auto" }}>
            {createDropDownItem(MEDICAL_MANAGEMENT)}
            {createDropDownItem(INVESTIGATIONS)}
            {showRecordsDropdownOptions ? createDropDownItem(RECORDS) : ""}

            {children}
          </Dropdown.Content>
        </Dropdown.Menu>
      </Dropdown>
    </Control>
  );
};

SaveAndSubmitButton.propTypes = {
  handleSubmitRecordsReferral: PropTypes.func.isRequired,
  handleSubmitReferral: PropTypes.func.isRequired,
  handleProductChange: PropTypes.func.isRequired,
  handleReferralSaveFromDropdown: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  state: PropTypes.bool.isRequired,
  inputs: PropTypes.object.isRequired,
  actionType: PropTypes.oneOf(["save, submit"]).isRequired,
  lsiClientEmail: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default SaveAndSubmitButton;
