import { gql } from "@apollo/client";

export const CREATE_EXCEPTION_MUTATION = gql`
  mutation createExceptionDefinition($data: ExceptionDefinitionCreateInput!) {
    createExceptionDefinition(data: $data) {
      CaseTypeCode
      DateAdded
      DateEdited
      Description
      DisplayMessage
      Document1
      Document2
      EditEmail
      EmailMessage
      EmailOther
      EmailQA
      EmailScheduler
      EmailSubject
      EmailText
      Entity
      ExceptionDefID
      ExceptionID
      GenerateDocument
      IMECentricCode
      Message
      RequireComment
      ServiceCode
      Status
      StatusCode
      StatusCodeValue
      UserIDAdded
      UserIDEdited
    }
  }
`;

export const UPDATE_EXCEPTION_MUTATION = gql`
  mutation updateExceptionDefinition(
    $data: ExceptionDefinitionUpdateInput!
    $where: ExceptionDefinitionWhereUniqueInput!
  ) {
    updateExceptionDefinition(data: $data, where: $where) {
      CaseTypeCode
      DateAdded
      DateEdited
      Description
      DisplayMessage
      Document1
      Document2
      EditEmail
      EmailMessage
      EmailOther
      EmailQA
      EmailScheduler
      EmailSubject
      EmailText
      Entity
      ExceptionDefID
      ExceptionID
      GenerateDocument
      IMECentricCode
      Message
      RequireComment
      ServiceCode
      Status
      StatusCode
      StatusCodeValue
      UserIDAdded
      UserIDEdited
    }
  }
`;

export const DELETE_EXCEPTION_MUTATION = gql`
  mutation deleteExceptionDefinition(
    $where: ExceptionDefinitionWhereUniqueInput!
  ) {
    deleteExceptionDefinition(where: $where) {
      ExceptionID
      ExceptionDefID
      IMECentricCode
      Entity
    }
  }
`;

export const CREATE_EXCEPTION_LIST_MUTATION = gql`
  mutation CREATE_EXCEPTION_LIST_MUTATION($data: ExceptionListCreateInput!) {
    createExceptionList(data: $data) {
      Description
      ExceptionID
    }
  }
`;

export const UPDATE_EXCEPTION_LIST_MUTATION = gql`
  mutation UPDATE_EXCEPTION_LIST_MUTATION(
    $data: ExceptionListUpdateInput!
    $where: ExceptionListWhereUniqueInput!
  ) {
    updateExceptionList(data: $data, where: $where) {
      Description
      ExceptionID
    }
  }
`;

export const DELETE_EXCEPTION_LIST_MUTATION = gql`
  mutation DELETE_EXCEPTION_LIST_MUTATION(
    $where: ExceptionListWhereUniqueInput!
  ) {
    deleteExceptionList(where: $where) {
      ExceptionID
    }
  }
`;
