import React from "react";
import PropTypes from "prop-types";
import OtherPartyForm from "../OtherPartyForm";

const MainInfo = ({ onChange, inputs, disabled }) => (
  <div>
    <OtherPartyForm disabled={disabled} inputs={inputs} onChange={onChange} />
  </div>
);
MainInfo.propTypes = {
  inputs: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};
MainInfo.defaultProps = {
  disabled: false,
};
export default MainInfo;
