import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";

import { Title, Button, Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLazyQuery, useMutation, useApolloClient } from "@apollo/client";

import { useParams, useHistory, useLocation } from "react-router-dom";

import axios from "axios";
import CaseGenerateDocumentModal from "../../components/CaseGenerateDocumentModal";
import CaseAttachExternalDocumentModal from "../../components/CaseAttachExternalDocumentModal";
import CaseEmailDocumentModal from "../../components/CaseEmailDocumentModal";
import CasePrintDocumentModal from "../../components/CasePrintDocumentModal";
import CaseDocumentModal from "../../components/CaseDocumentModal";
import CaseLinkDocumentModal from "../../components/CaseLinkDocumentModal";

import { useModal } from "../../../../context";
import {
  customToast as toast,
  getDateEST,
  convertTimeZoneDataBase,
  download,
} from "../../../../utils";

import { generateColumns } from "./columns";
import {
  SINGLE_CASE_QUERY,
  SINGLE_CLIENT_QUERY,
  LIST_CASE_DOCUMENT_QUERY,
  UPDATE_CASE_DOCUMENT_MUTATION,
  DELETE_CASE_DOCUMENT_MUTATION,
  CREATE_CASE_HISTORY_MUTATION,
  ALL_CASE_HISTORY_QUERY,
} from "../../../../graphql";

import DataTable from "../../../../components/DataTable";
import { useAuth } from "../../../../context/AuthContext";

import Confirmation from "../../../../components/Confirmation";
import { useCaseDocumentPath, useCaseStatusChange } from "../../../../hooks";

const {
  REACT_APP_FILE_SERVER_ADDRESS,
  REACT_APP_DOCUMENT_HOST,
  REACT_APP_CLOUD_FILE_SERVER_ADDRESS,
} = process.env;

class CustomCaseDocumentError extends Error {}

const CaseDocuments = ({ inputs }) => {
  const client = useApolloClient();
  const { casenbr: pcasenbr, workid } = useParams();
  const casenbr = pcasenbr || workid;
  const { setModalOpen } = useModal();
  const { state: authState } = useAuth();
  const [updateCaseDocument] = useMutation(UPDATE_CASE_DOCUMENT_MUTATION);
  const [deleteCaseDocument] = useMutation(DELETE_CASE_DOCUMENT_MUTATION);
  const [createCaseHistory] = useMutation(CREATE_CASE_HISTORY_MUTATION);
  const history = useHistory();
  const getCaseVirtualDocumentPath = useCaseDocumentPath(casenbr);
  const location = useLocation();
  const executeCaseStatusChange = useCaseStatusChange(casenbr);
  const [getClient, { data: GetClientData }] = useLazyQuery(
    SINGLE_CLIENT_QUERY
  );

  useEffect(() => {
    const cl = inputs?.client;
    if (cl) {
      getClient({
        variables: { where: { clientcode: parseInt(inputs.client, 10) } },
      });
    }
  }, [inputs?.client, getClient]);

  useEffect(() => {
    if (location?.state?.triggerGenerateModal) {
      setModalOpen(
        true,
        <CaseGenerateDocumentModal
          casenbr={casenbr}
          onComplete={() => {
            setModalOpen(false, "");
            if (location?.state?.printApptLtrs) {
              executeCaseStatusChange(2);
            }
          }}
        />
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.state?.triggerGenerateModal]);

  const where = useMemo(
    () => ({
      casenbr: { equals: parseInt(casenbr, 10) },
      type: { not: { equals: "Report" } },
      // OR: [
      //   {
      //     reporttype: { not: { equals: "Report" } },
      //   },
      //   {
      //     reporttype: { equals: null },
      //   },
      // ],
    }),
    [casenbr]
  );

  const goToUpdateStatus = () => {
    setModalOpen(false, "");
    history.push(`/cases/${casenbr}/profile`);
  };

  const handleGenerateDocumentClick = async () => {
    const { data: CaseItemData } = await client.query({
      query: SINGLE_CASE_QUERY,
      variables: { where: { casenbr: parseInt(casenbr, 10) } },
      fetchPolicy: "cache-only",
    });

    if (CaseItemData?.caseItem?.status) {
      setModalOpen(
        true,
        <CaseGenerateDocumentModal
          casenbr={casenbr}
          onComplete={() => {
            setModalOpen(false, "");
          }}
        />
      );
    } else {
      setModalOpen(
        true,
        <Confirmation
          message={
            // eslint-disable-next-line react/jsx-wrap-multilines
            <div>
              Please update the case status before generating a document.
              <br />
              Would you like to update the case status now?
            </div>
          }
          onCancel={() => setModalOpen(false, "")}
          onConfirm={goToUpdateStatus}
        />
      );
    }
  };

  const handleRowClick = ({ seqno }) => {
    setModalOpen(
      true,
      <CaseDocumentModal
        casenbr={casenbr}
        seqno={seqno}
        onComplete={() => setModalOpen(false, "")}
      />
    );
  };

  const handlePrintDocumentClick = () => {
    setModalOpen(
      true,
      <CasePrintDocumentModal
        casenbr={casenbr}
        onComplete={() => {
          setModalOpen(false, "");
        }}
      />
    );
  };

  const handleAttachExternalClick = () => {
    setModalOpen(
      true,
      <CaseAttachExternalDocumentModal
        casenbr={casenbr}
        onComplete={() => {
          setModalOpen(false, "");
        }}
      />
    );
  };

  const COLUMNS = useMemo(() => {
    const handleDelete = (caseDocument) => {
      const performDelete = async () => {
        try {
          setModalOpen(false, "");
          await deleteCaseDocument({
            variables: {
              where: {
                seqno: parseInt(caseDocument.seqno, 10),
              },
            },
            refetchQueries: [
              {
                query: LIST_CASE_DOCUMENT_QUERY,
                variables: {
                  where,
                },
              },
            ],
          });
          toast.success("Case Document deleted successfully.");
        } catch (err) {
          toast.error(`Error deleting Case Document.`);
        } finally {
          setModalOpen(false, "");
        }
      };
      setModalOpen(
        true,
        <Confirmation
          message="Are you sure you want to delete this Case Document?"
          onCancel={() => setModalOpen(false, "")}
          onConfirm={performDelete}
        />
      );
    };

    const handlePublish = async (caseDocument) => {
      const performPublish = async () => {
        try {
          await updateCaseDocument({
            variables: {
              data: {
                PublishOnWeb: { set: !caseDocument.PublishOnWeb },
              },
              where: {
                seqno: parseInt(caseDocument.seqno, 10),
              },
            },
            refetchQueries: [
              {
                query: LIST_CASE_DOCUMENT_QUERY,
                variables: { where },
              },
            ],
          });
          if (!caseDocument.PublishOnWeb) {
            const date = convertTimeZoneDataBase(getDateEST());
            createCaseHistory({
              variables: {
                data: {
                  casenbr: parseInt(casenbr, 10),
                  otherinfo: "",
                  eventdate: date,
                  eventdesc: "Published to Web",
                  userid: authState?.user?.userid,
                  dateadded: date,
                  useridedited: authState?.user?.userid,
                  dateedited: date,
                  type: "Document",
                },
              },
              refetchQueries: [
                {
                  query: ALL_CASE_HISTORY_QUERY,
                  variables: {
                    where: { casenbr: { equals: parseInt(casenbr, 10) } },
                    orderBy: [{ eventdate: "desc" }],
                  },
                },
              ],
            });
          }
          toast.success("Case Document published successfully.");
        } catch (err) {
          toast.error(`Error updating document Publish On Web status.`);
        } finally {
          setModalOpen(false, "");
        }
      };
      setModalOpen(
        true,
        <Confirmation
          message={`Are you sure you want to ${
            caseDocument.PublishOnWeb
              ? "unpublish this case document from web?"
              : "publish this case document to web?"
          }`}
          onCancel={() => setModalOpen(false, "")}
          onConfirm={performPublish}
        />
      );
    };

    const openDocument = async (caseDocument) => {
      try {
        const documentPath = getCaseVirtualDocumentPath(
          caseDocument.sfilename,
          false
        );

        download(
          `${casenbr}-${caseDocument.sfilename}.url`,
          "plain",
          `[InternetShortcut]\nURL=file://${REACT_APP_FILE_SERVER_ADDRESS}/${documentPath}`
        );
      } catch (err) {
        toast.error(`Error opening file.`);
      }
    };
    return generateColumns(handleDelete, handlePublish, openDocument);
  }, [
    authState?.user?.userid,
    casenbr,
    createCaseHistory,
    deleteCaseDocument,
    getCaseVirtualDocumentPath,
    setModalOpen,
    updateCaseDocument,
    where,
  ]);

  const handleOpenDocumentFolder = async () => {
    try {
      const documentPath = getCaseVirtualDocumentPath("", false);

      // ensure path
      await axios.get(
        `${REACT_APP_DOCUMENT_HOST}/verify?path=${encodeURIComponent(
          documentPath
        )}`
      );

      const isCloudStored = /ISGCloudStorage/.test(documentPath);

      const fileServerAddress = isCloudStored
        ? REACT_APP_CLOUD_FILE_SERVER_ADDRESS
        : REACT_APP_FILE_SERVER_ADDRESS;

      const directoryPath = isCloudStored
        ? documentPath.replace("ISGCloudStorage/", "")
        : documentPath;

      const text = `[InternetShortcut]\nURL=file://${fileServerAddress}/${directoryPath}`;
      download(`${casenbr}.url`, "plain", text);
    } catch (err) {
      const message =
        err instanceof CustomCaseDocumentError
          ? err.message
          : "There was an error performing this operation. Please contact your administrator.";
      toast.error(message);
    }
  };

  const handleOpenLinkDocumentModal = () => {
    setModalOpen(
      true,
      <CaseLinkDocumentModal
        casenbr={casenbr}
        onComplete={() => setModalOpen(false)}
      />
    );
  };

  const handleEmailDocumentClick = () => {
    setModalOpen(
      true,
      <CaseEmailDocumentModal
        casenbr={casenbr}
        onComplete={() => {
          setModalOpen(false, "");
        }}
      />
    );
  };
  return (
    <div>
      <div className="page-head">
        <div className="page-head-start">
          <Title size={6}>Case Documents List</Title>
        </div>
        <div className="page-head-end">
          <p style={{ fontSize: "0.8rem", margin: "0 2rem" }}>
            <b>Client</b>
            &nbsp;&nbsp;&nbsp;
            <span>
              {GetClientData?.client?.lastname &&
              GetClientData?.client.firstname
                ? `${GetClientData.client.lastname}, ${GetClientData.client.firstname}`
                : "--"}
            </span>
          </p>
          <Button.Group>
            <Button
              color="primary"
              size="small"
              title="Link Document"
              onClick={handleOpenLinkDocumentModal}
            >
              <Icon>
                <FontAwesomeIcon icon="link" />
              </Icon>
              <span>Link</span>
            </Button>
            <Button
              color="primary"
              size="small"
              onClick={handleOpenDocumentFolder}
            >
              <Icon>
                <FontAwesomeIcon icon="binoculars" />
              </Icon>
              <span>Directory</span>
            </Button>
            <Button
              color="primary"
              size="small"
              onClick={handlePrintDocumentClick}
            >
              <Icon>
                <FontAwesomeIcon icon="print" />
              </Icon>
              <span>Print</span>
            </Button>
            <Button
              color="primary"
              size="small"
              onClick={handleEmailDocumentClick}
            >
              <Icon>
                <FontAwesomeIcon icon="mail-bulk" />
              </Icon>
              <span>Email</span>
            </Button>
            <Button
              color="primary"
              size="small"
              onClick={handleGenerateDocumentClick}
            >
              <Icon>
                <FontAwesomeIcon icon="plus" />
              </Icon>
              <span>Generate</span>
            </Button>
            <Button
              color="primary"
              size="small"
              onClick={handleAttachExternalClick}
            >
              <Icon>
                <FontAwesomeIcon icon="plus" />
              </Icon>
              <span>Attach</span>
            </Button>
          </Button.Group>
        </div>
      </div>
      <DataTable
        aggregateKey="_all"
        aggregateName="aggregateCaseDocument"
        columns={COLUMNS}
        fetchPolicy="cache-and-network"
        name="caseDocuments"
        orderBy={[{ id: "dateadded", desc: true }]}
        query={LIST_CASE_DOCUMENT_QUERY}
        where={where}
        onRowClick={handleRowClick}
      />
    </div>
  );
};

CaseDocuments.propTypes = {
  inputs: PropTypes.object,
};
CaseDocuments.defaultProps = {
  inputs: {},
};
export default CaseDocuments;
