import React from "react";
import PropTypes from "prop-types";
import { Field, Control, Label, Input } from "rbx";

import ApolloDocumentTypeSelect from "../../../../components/ApolloDocumentTypeSelect";
import ApolloDocumentLocationSelect from "../../../../components/ApolloDocumentLocationSelect";
import DropZoneFileUploader from "../../../../components/DropZoneFileUploader";
import "./ClaimDocumentCreateForm.scss";

const ClaimDocumentCreateForm = ({ inputs, onChange, disabled }) => (
  <div className="claim-document-form">
    {inputs &&
      inputs.files &&
      inputs.files.map((file) => (
        <React.Fragment key={file.id}>
          <Field>
            <ApolloDocumentTypeSelect
              required
              disabled={disabled}
              label="Document Type"
              name="type"
              size="small"
              value={file.type}
              onChange={(name, value) => {
                onChange(name, value, file.id)
              }}
            />
          </Field>
          <Field>
            <Control expanded>
              <Label>Document Description</Label>
              <Input
                required  
                autoComplete="new"
                disabled={disabled}
                name="description"
                size="small"
                type="text"
                value={file.description}
                onChange={(e) => onChange(e.target.name, e.target.value, file.id)}
              />
            </Control>
          </Field>
          <Field>
            <ApolloDocumentLocationSelect
              required
              disabled={disabled}
              label="Location"
              name="location"
              size="small"
              value={file.location}
              onChange={(name, value) => onChange(name, value, file.id)}
            />
          </Field>
          <Field>
            <Control>
              <Label>File</Label>
              <Input
                disabled
                readOnly
                required
                autoComplete="new"
                name="file"
                size="small"
                type="text"
                value={file.file}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
            </Control>
          </Field>
        </React.Fragment>
    ))}
    <Field className="span-2">
      <DropZoneFileUploader
        multiple
        name="files"
        value={inputs.files}
        onChange={onChange}
      />
    </Field>
  </div>
);

ClaimDocumentCreateForm.propTypes = {
  inputs: PropTypes.object,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

ClaimDocumentCreateForm.defaultProps = {
  inputs: {},
  onChange: () => null,
  disabled: false,
};

export default ClaimDocumentCreateForm;
