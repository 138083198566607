import React from "react";

import PropTypes from "prop-types";

import { Control, Label, Select } from "rbx";

import { useQuery } from "@apollo/client";
import { ALL_SERVICE_QUEUES_QUERY } from "../../graphql";

const ServiceQueuesSelect = ({
  value,
  name,
  label,
  size,
  onChange,
  disabled,
  serviceCode,
  required,
  showBlank,
  showLabel,
}) => {
  const { data, loading } = useQuery(ALL_SERVICE_QUEUES_QUERY, {
    variables: serviceCode
      ? {
          orderBy: { queueorder: "asc" },
          where: {
            servicecode: {
              equals: parseInt(serviceCode, 10),
            },
          },
        }
      : { take: 1 },
    fetchPolicy: "cache-first",
  });

  const handleChange = (e) => {
    onChange(e.target.name, e.target.value ? e.target.value : "");
  };
  const serviceQueues = (serviceCode && data?.serviceQueues) || [];

  return (
    <Control expanded className="service-queue-select">
      {showLabel && <Label>{label}</Label>}
      <Select.Container fullwidth size={size} state={loading ? "loading" : ""}>
        <Select
          disabled={disabled}
          name={name}
          required={required}
          value={value}
          onChange={handleChange}
        >
          {showBlank && <Select.Option value="" />}
          {Array.isArray(serviceQueues) &&
            serviceQueues.map((serviceQueue) => (
              <Select.Option
                key={`servicequeue-${serviceQueue.statuscode}-${serviceQueue.queueorder}`}
                value={serviceQueue.statuscode}
              >
                {serviceQueue?.queue?.statusdesc}
              </Select.Option>
            ))}
        </Select>
      </Select.Container>
    </Control>
  );
};

ServiceQueuesSelect.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  serviceCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  required: PropTypes.bool,
  showBlank: PropTypes.bool,
  showLabel: PropTypes.bool,
};

ServiceQueuesSelect.defaultProps = {
  value: "",
  name: "",
  label: "Service Status",
  size: "small",
  onChange: () => null,
  disabled: false,
  required: false,
  showBlank: true,
  showLabel: true,
  serviceCode: null,
};

export default ServiceQueuesSelect;
