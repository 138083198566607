import { gql } from "@apollo/client";

export const COMPANY_QUERY = gql`
  query company($where: CompanyWhereUniqueInput!) {
    company(where: $where) {
      companycode
      marketercode
      priority
      QARep
      photoRqd
      feecode
      extname
      intname
      addr1
      addr2
      city
      status
      state
      zip
      phone
      notes
      usdvarchar1
      usdvarchar2
      usddate1
      usddate2
      usdtext1
      usdtext2
      usdint1
      usdint2
      usdmoney1
      usdmoney2
      dateadded
      useridadded
      dateedited
      useridedited
    }
  }
`;

export const LIST_COMPANY_QUERY = gql`
  query companies(
    $take: Int = 20
    $orderBy: [CompanyOrderByInput!]
    $skip: Int
    $where: CompanyWhereInput
  ) {
    companies(take: $take, orderBy: $orderBy, skip: $skip, where: $where) {
      companycode
      extname
      intname
      addr1
      addr2
      city
      state
      zip
      notes
      status
      companyToCompanyGroup {
        id
        CompanyGroup {
          CompanyGroupID
          CompanyGroupName
        }
      }
    }
    aggregateCompany(where: $where) {
      count {
        companycode
      }
    }
  }
`;

export const LIST_ALLSTATE_COMPANY_GROUP_ITEMS_QUERY = gql`
  query AllstateCompanyGroupItems {
    allstateCompanyGroupItems {
      firstname
      lastname
      email
      CompanyGroupID
    }
  }
`;

export const ALLSTATE_JOHN_TROP_PEOPLE = gql`
  query AllstateJohnTropSelection {
    allstateJohnTropSelection {
      companycode
      clientcode
      lastname
      firstname
      phone1
      intname
      extname
      email
    }
  }
`;

export const LIST_GEICO_COMPANY_GROUP_ITEMS_QUERY = gql`
  query GeicoCompanyGroupItems {
    geicoCompanyGroupItems {
      firstname
      lastname
      email
      CompanyGroupID
    }
  }
`;

export const FIND_FIRST_COMPANY_GROUP_QUERY = gql`
  query findFirstCompanyGroup($where: CompanyGroupWhereInput, $take: Int = 1) {
    findFirstCompanyGroup(where: $where, take: $take) {
      CompanyGroupID
      CompanyGroupName
    }
  }
`;
export const LIST_COMPANY_GROUPS_QUERY = gql`
  query companyGroups(
    $orderBy: [CompanyGroupOrderByInput!]
    $distinct: [CompanyGroupScalarFieldEnum!]
    $cursor: CompanyGroupWhereUniqueInput
    $where: CompanyGroupWhereInput
  ) {
    companyGroups(
      orderBy: $orderBy
      where: $where
      distinct: $distinct
      cursor: $cursor
    ) {
      CompanyGroupID
      CompanyGroupName
    }
  }
`;

export const SINGLE_COMPANY_QUERY = gql`
  query SINGLE_COMPANY_QUERY($where: CompanyWhereUniqueInput!) {
    company(where: $where) {
      companycode
      extname
      intname
      addr1
      addr2
      city
      state
      zip
      phone
      QARep
      priority
      marketercode
      TeamConnectID
    }
  }
`;

export const ALL_COMPANIES_QUERY = gql`
  query companies(
    $where: CompanyWhereInput
    $orderBy: [CompanyOrderByInput!]
    $take: Int
    $distinct: [CompanyScalarFieldEnum!]
    $skip: Int
  ) {
    companies(
      where: $where
      orderBy: $orderBy
      take: $take
      distinct: $distinct
      skip: $skip
    ) {
      companycode
      intname
    }
  }
`;

export const ALL_COMPANY_DEFAULT_DOCUMENTS_QUERY = gql`
  query companyDefDocuments(
    $take: Int
    $orderBy: [CompanyDefDocumentOrderByInput!]
    $skip: Int
    $where: CompanyDefDocumentWhereInput
  ) {
    companyDefDocuments(
      take: $take
      orderBy: $orderBy
      skip: $skip
      where: $where
    ) {
      companycode
      documentcode
      documentqueue
      dateadded
      useridadded
      dateedited
      useridedited
      order
      queue {
        statuscode
        statusdesc
      }
      document {
        document
        documentname
        description
        type
      }
    }
  }
`;
