import React from "react";
import PropTypes from "prop-types";

// components
import { Field, Title, Control, Input, Label, Column } from "rbx";

// internal-components
import PhoneNumberInput from "../../../../components/PhoneNumberInput";

import PrefixSelect from "../../../../components/PrefixSelect";
import StateSelect from "../../../../components/StateSelect";
import DegreeSelect from "../../../../components/DegreeSelect";
import StatusSelect from "../../../../components/StatusSelect";
import ZipInput from "../../../../components/ZipInput";
import SpecialtySelect from "../../../../components/SpecialtySelect";
import BooleanInput from "../../../../components/BooleanInput";

const DoctorForm = ({
  inputs,
  disabled,
  onChange,
  adding,
  specialty,
  handleSpecialtyChange,
}) => (
  <div className="doctor-form">
    <Column.Group multiline>
      <Column size={12}>
        {/* PREFIX / NAME / CREDENTIALS */}
        <Field kind="group">
          <Control>
            <PrefixSelect
              disabled={disabled}
              id="prefix"
              label="Prefix"
              name="prefix"
              size="small"
              value={inputs.prefix}
              onChange={onChange}
            />
          </Control>
          <Control expanded>
            <Label>First Name</Label>
            <Input
              required
              autoComplete="new"
              disabled={disabled}
              maxLength={50}
              name="firstname"
              placeholder="Firstname"
              size="small"
              value={inputs.firstname}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </Control>
          <Control style={{ width: 60 }}>
            <Label>MI</Label>
            <Input
              autoComplete="new"
              disabled={disabled}
              maxLength={2}
              name="middleinitial"
              placeholder="Middle"
              size="small"
              value={inputs.middleinitial}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </Control>
          <Control expanded>
            <Label>Last Name</Label>
            <Input
              required
              autoComplete="new"
              disabled={disabled}
              maxLength={50}
              name="lastname"
              placeholder="Lastname"
              size="small"
              value={inputs.lastname}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </Control>

          <Control>
            <DegreeSelect
              disabled={disabled}
              id="credentials"
              label="Degree"
              name="credentials"
              size="small"
              value={inputs.credentials}
              onChange={onChange}
            />
          </Control>
          <StatusSelect
            disabled={disabled}
            label="Status"
            name="status"
            value={inputs.status}
            onChange={onChange}
          />
        </Field>
      </Column>
      {adding && (
        <Column size={6}>
          <Field kind="group">
            <SpecialtySelect
              label="Specialty"
              name="specialtycode"
              value={specialty}
              onChange={handleSpecialtyChange}
            />
            <Control>
              <Label>&nbsp;</Label>
              <BooleanInput
                label="Require Prepayment?"
                name="prepaid"
                value={inputs.prepaid}
                onChange={onChange}
              />
            </Control>
          </Field>
        </Column>
      )}
    </Column.Group>
    <hr />
    <Title size={5}>Correspondence Information</Title>
    <Column.Group>
      <Column size={6}>
        <Field>
          <Control expanded>
            <Label>Practice Name</Label>
            <Input
              required
              autoComplete="new"
              disabled={disabled}
              maxLength={70}
              name="companyname"
              placeholder="Practice Name"
              size="small"
              value={inputs.companyname}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </Control>
        </Field>
        {/* ADDRESS 1 / ADDRESS 2 */}
        <Field kind="group">
          <Control expanded>
            <Label>Address 1</Label>
            <Input
              required
              autoComplete="new"
              disabled={disabled}
              maxLength={50}
              name="addr1"
              placeholder="Address 1"
              size="small"
              value={inputs.addr1}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </Control>
          <Control expanded>
            <Label>Address 2</Label>
            <Input
              autoComplete="new"
              disabled={disabled}
              maxLength={50}
              name="addr2"
              placeholder="Address 2"
              size="small"
              value={inputs.addr2}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </Control>
        </Field>
        {/* CITY / STATE / ZIP / COUNTRY */}

        <Field kind="group">
          <Control expanded>
            <Label>City</Label>
            <Input
              required
              autoComplete="new"
              disabled={disabled}
              maxLength={50}
              name="city"
              placeholder="City"
              size="small"
              value={inputs.city}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </Control>
          <Control>
            <StateSelect
              required
              disabled={disabled}
              id="state"
              label="State"
              name="state"
              size="small"
              value={inputs.state}
              onChange={onChange}
            />
          </Control>
          <ZipInput
            required
            disabled={disabled}
            id="zip"
            name="zip"
            state={inputs.state}
            value={inputs.zip || ""}
            onChange={onChange}
          />
          <Control expanded>
            <Label>County</Label>
            <Input
              autoComplete="new"
              disabled={disabled}
              name="county"
              placeholder="County"
              size="small"
              value={inputs.county}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </Control>
        </Field>
      </Column>
      <Column size={6}>
        <Field kind="group">
          <Control expanded>
            <Label>Phone</Label>
            <PhoneNumberInput
              required
              disabled={disabled}
              name="phone"
              placeholder="Phone"
              value={inputs.phone}
              onChange={onChange}
            />
          </Control>
          <Control expanded>
            <Label>Ext</Label>
            <Input
              autoComplete="new"
              disabled={disabled}
              maxLength={14}
              name="phoneExt"
              placeholder="Ext"
              size="small"
              value={inputs.phoneExt}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </Control>
        </Field>
        <Field kind="group">
          <Control expanded>
            <Label>Cell</Label>
            <PhoneNumberInput
              disabled={disabled}
              name="cellphone"
              placeholder="Cell Phone"
              value={inputs.cellphone}
              onChange={onChange}
            />
          </Control>
          <Control expanded>
            <Label>Fax</Label>
            <PhoneNumberInput
              disabled={disabled}
              name="faxNbr"
              placeholder="Fax"
              value={inputs.faxNbr}
              onChange={onChange}
            />
          </Control>
        </Field>
        <Field kind="group">
          <Control expanded>
            <Label>Doctor Email</Label>
            <Input
              required
              autoComplete="new"
              disabled={disabled}
              maxLength={70}
              name="emailAddr"
              placeholder="Email"
              size="small"
              type="email"
              value={inputs.emailAddr}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </Control>
          <Control expanded>
            <Label>Billing Email</Label>
            <Input
              autoComplete="new"
              disabled={disabled}
              maxLength={70}
              name="doctoremail"
              placeholder="Email"
              size="small"
              type="email"
              value={inputs.doctoremail}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </Control>
          <Control expanded>
            <Label>Med Record Email</Label>
            <Input
              autoComplete="new"
              disabled={disabled}
              maxLength={70}
              name="medicalrecordemail"
              placeholder="Email"
              size="small"
              type="email"
              value={inputs.medicalrecordemail}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </Control>
        </Field>
      </Column>
    </Column.Group>
  </div>
);
DoctorForm.propTypes = {
  inputs: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  adding: PropTypes.bool,
  specialty: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handleSpecialtyChange: PropTypes.func,
};

DoctorForm.defaultProps = {
  disabled: false,
  adding: false,
  specialty: "",
  handleSpecialtyChange: () => null,
};

export default DoctorForm;
