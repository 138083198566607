import React, { useState, useEffect, useRef, useContext } from "react";

import { useHistory } from "react-router-dom";
import { Title, Field, Control, Input, Button, Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DataTable from "../../components/DataTable";
import { ExamineeListFilters, ExamineeModal } from "./components";
import Confirmation from "../../components/Confirmation";

import COLUMNS from "./columns";

import { ModalContext } from "../../context/ModalContext";
import { debounce, getFilterState, saveFilterState } from "../../utils";

import { LIST_EXAMINEE_QUERY } from "../../graphql/examinee";

const INITIAL_FILTER_STATE = {};

const ListExamineePage = (props) => {
  const history = useHistory();
  const nameInputRef = useRef();
  const { setModalOpen } = useContext(ModalContext);
  const [where, setWhere] = useState(
    getFilterState("EXAMINEE_LIST_FILTERS", { ...INITIAL_FILTER_STATE })
  );
  const [showFilters, setShowFilters] = useState(false);

  const handleReset = () => {
    saveFilterState("EXAMINEE_LIST_FILTERS", { ...INITIAL_FILTER_STATE });
    setWhere({ ...INITIAL_FILTER_STATE });
    nameInputRef.current.value = "";
    if (document.getElementById("search-input")) {
      document.getElementById("search-input").value = "";
    }
  };

  const handleChange = (name, value) => {
    setWhere((prev) =>
      value
        ? { ...prev, [name]: value }
        : Object.keys(prev).reduce((acc, curr) => {
          if (curr !== name) acc[curr] = prev[curr];
          return acc;
        }, {})
    );
  };

  useEffect(() => {
    saveFilterState("EXAMINEE_LIST_FILTERS", where);
  }, [where]);

  const handleRowClick = (row) => {
    history.push(`/examinees/${row.chartnbr}/profile`);
  };

  const handleAddClick = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    setModalOpen(
      true,
      <ExamineeModal
        onComplete={(chartnbr) => {
          setModalOpen(false, "");
          if (chartnbr) {
            setModalOpen(
              true,
              <Confirmation
                message="Examinee created successfully. Would you like to open it now?"
                onConfirm={() => {
                  setModalOpen(false);
                  history.push(`/examinees/${chartnbr}/profile`);
                }}
              />
            );
          }
        }}
      />
    );
  };

  const getInputValue = () =>
    [where?.lastname?.startsWith, where?.firstname?.startsWith]
      .filter(Boolean)
      .join(", ");

  return (
    <div>
      <div className="section-header">
        <Title style={{ marginRight: 15 }}>Examinees</Title>
        <Field kind="group" style={{ paddingTop: "0.3rem" }}>
          <Control>
            <Input
              autoComplete="new"
              defaultValue={getInputValue()}
              placeholder="Search by Name"
              ref={nameInputRef}
              size="small"
              onChange={debounce(({ target: { value } }) => {
                const [lastname, firstname] = value
                  .split(/,/)
                  .map((x) => x.trim())
                  .filter(Boolean);

                setWhere((prev) =>
                  value
                    ? {
                      ...prev,
                      lastname: { startsWith: lastname },
                      firstname: { startsWith: firstname },
                    }
                    : Object.keys(prev)
                      .filter((x) => x !== "firstname" && x !== "lastname")
                      .reduce((a, c) => ({ ...a, [c]: prev[c] }), {})
                );
              }, 500)}
            />
          </Control>
          <Control>
            <Button
              color="warning"
              size="small"
              onClick={() => setShowFilters((prev) => !prev)}
            >
              <Icon>
                <FontAwesomeIcon icon="filter" />
              </Icon>
              <span>Filters</span>
            </Button>
          </Control>
          <Control>
            <Button color="primary" size="small" onClick={handleAddClick}>
              <Icon>
                <FontAwesomeIcon icon="plus" />
              </Icon>
              <span>Add</span>
            </Button>
          </Control>
        </Field>
      </div>
      {showFilters && (
        <ExamineeListFilters
          filters={where}
          show={showFilters}
          onChange={handleChange}
          onReset={handleReset}
        />
      )}
      <DataTable
        aggregateKey="chartnbr"
        aggregateName="aggregateExaminee"
        columns={COLUMNS}
        name="examinees"
        orderBy={[
          { id: "lastname", desc: false },
          { id: "firstname", desc: false },
        ]}
        query={LIST_EXAMINEE_QUERY}
        where={where}
        onRowClick={handleRowClick}
      />
      {/* <pre>
        <code>{JSON.stringify(data, null, 2)}</code>
      </pre> */}
    </div>
  );
};

ListExamineePage.propTypes = {};

export default ListExamineePage;
