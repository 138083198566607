import React from "react";

import { Box, Container, Icon, Title } from "rbx";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ReferralHeader from "../ReferralHeader";

import { useQueryParams } from "../../../../hooks";

const ConfirmationScreen = () => {
  const [method] = useQueryParams("method", "", false);

  const message =
    method === "save"
      ? "Your referral has been saved."
      : "Your submission has been received.";

  return (
    <div>
      <ReferralHeader />
      <Container className="confirmation-container">
        <Box className="confirmation-box">
          <Icon color="primary" size="large">
            <FontAwesomeIcon icon="check-circle" size="4x" />
          </Icon>
          <Title>Thank you!</Title>
          <Title subtitle>{message}</Title>
        </Box>
      </Container>
    </div>
  );
};
export default ConfirmationScreen;
