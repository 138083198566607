import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { AsyncPaginate } from "react-select-async-paginate";
import { useApolloClient, useLazyQuery } from "@apollo/client";
import { Control, Label, Input } from "rbx";
import { LIST_USERS_QUERY } from "../../graphql/user";

const UserSelectAsync = ({
  showLabel,
  name,
  value: initialValue,
  label,
  onChange,
  disabled,
  required,
  getValue,
  setValue,
  where: initialWhere,
  readOnly,
}) => {
  const client = useApolloClient();
  const [value, setLocalValue] = useState(null);
  const [getUser, { data: getUserData }] = useLazyQuery(LIST_USERS_QUERY);

  useEffect(() => {
    if (initialValue) {
      getUser({
        variables: {
          where: {
            ...initialWhere,
            userid: { equals: getValue(initialValue) },
          },
        },
      });
    } else {
      setLocalValue(null);
    }
  }, [initialValue, getUser, getValue, initialWhere]);

  useEffect(() => {
    const user = getUserData?.users[0];
    if (user) {
      setLocalValue({
        value: user.userid,
        label: `${user.lastname}, ${user.firstname}`,
      });
    }
  }, [getUserData]);

  const promiseOptions = async (search, loadedOptions) => {
    const [lastname, firstname] = search.split(/\s/);

    const where = {
      lastname: { startsWith: lastname },
      firstname: { startsWith: firstname },
      fldActive: { equals: 1 },
    };

    const { data: usersData } = await client.query({
      query: LIST_USERS_QUERY,
      variables: {
        take: 25,
        skip: loadedOptions.length,
        where: { ...where, ...initialWhere },
        orderBy: [{ lastname: "asc" }, { firstname: "asc" }],
      },
      fetchPolicy: "network-only",
    });
    const users = usersData?.users;

    const result = Array.isArray(users)
      ? users.map((user) => ({
          value: user.userid,
          label: `${user.lastname}, ${user.firstname}`,
        }))
      : [];

    return {
      options: result,
      hasMore: loadedOptions.length < usersData.aggregateUser.count.userid,
    };
  };

  const handleChange = ({ value: newValue }) => {
    onChange(name, setValue(newValue));
  };

  const indicateRequired =
    required && (!value || value === "" || value === "0" || value === 0);

  return (
    <Control expanded>
      {showLabel && <Label size="small">{label}</Label>}
      {readOnly ? (
        <Input
          readOnly
          autoComplete="new"
          size="small"
          type="text"
          value={value?.label || ""}
        />
      ) : (
        <AsyncPaginate
          isDisabled={disabled}
          loadOptions={promiseOptions}
          menuPlacement="auto"
          menuPortalTarget={document.body}
          placeholder=""
          styles={{
            menuPortal: (provided) => ({
              ...provided,
              zIndex: 9999,
            }),
            indicatorsContainer: (provided) => ({
              ...provided,
              height: "inherit",
            }),
            indicatorSeparator: (provided) => ({
              ...provided,
              display: "none",
            }),
            container: (provided) => ({
              ...provided,
              fontSize: 13,
            }),
            singleValue: (provided) => ({
              ...provided,
              top: "38%",
            }),
            control: (provided) => ({
              ...provided,
              minHeight: 30,
              height: 30,
              width: "100%",
              minWidth: 180,
              border: indicateRequired ? "1px #e63362 solid" : "",
              borderRadius: "4px",
              boxShadow: indicateRequired
                ? "0 0 0 0.125em rgba(230, 51, 98, 0.25)"
                : "",
              "&:hover": {
                borderColor: "hsl(0, 0%, 90%)",
                borderRadius: 4,
                cursor: disabled ? "not-allowed" : "pointer",
              },
            }),
            placeholder: (provided) => ({ ...provided, top: "38%" }),
            menu: (provided) => ({
              ...provided,
              fontSize: 12,
              zIndex: 999,
            }),
          }}
          value={value}
          onChange={handleChange}
        />
      )}
    </Control>
  );
};

UserSelectAsync.propTypes = {
  showLabel: PropTypes.bool,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  getValue: PropTypes.func,
  setValue: PropTypes.func,
  where: PropTypes.shape({
    usertype: PropTypes.shape({
      equals: PropTypes.string,
    }),
  }),
  readOnly: PropTypes.bool,
};

UserSelectAsync.defaultProps = {
  name: "",
  label: "",
  value: "",
  onChange: () => null,
  showLabel: true,
  disabled: false,
  required: false,
  getValue: (x) => x,
  setValue: (x) => x,
  where: {},
  readOnly: false,
};

export default UserSelectAsync;
