import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Control, Field, Input, Box, Label } from "rbx";
import { useHistory } from "react-router-dom";

import PhoneNumberInput from "../../../../components/PhoneNumberInput";
import StateSelect from "../../../../components/StateSelect";
import ReferralStyledSubtitle from "../ReferralStyledSubtitle";
import { useReferrer } from "../../../../hooks";

const ReferrerInformationForm = ({ onChange, inputs, mode, disabled }) => {
  const [emailValue, setEmailValue] = useState("");
  const referrer = useReferrer();
  const history = useHistory();

  useEffect(() => {
    if (inputs?.Email) {
      setEmailValue(inputs?.Email);
    }
  }, [inputs?.Email]);

  return (
    <Box>
      <ReferralStyledSubtitle>Referrer Information</ReferralStyledSubtitle>
      <Field kind="group">
        <Control expanded>
          <Label>First Name</Label>
          <Input
            disabled={
              disabled || referrer?.Email || history?.location?.search?.length
            }
            name="FirstName"
            required={
              !/^records$/gi.test(mode) || inputs?.product !== "Records"
            }
            size="small"
            value={inputs.FirstName}
            onChange={(e) => onChange(e.target.name, e.target.value)}
          />
        </Control>
        <Control expanded>
          <Label>Last Name</Label>
          <Input
            disabled={
              disabled || referrer?.Email || history?.location?.search?.length
            }
            name="LastName"
            required={
              !/^records$/gi.test(mode) || inputs?.product !== "Records"
            }
            size="small"
            value={inputs.LastName}
            onChange={(e) => onChange(e.target.name, e.target.value)}
          />
        </Control>
        <Control expanded>
          <Label>Company Name</Label>
          <Input
            disabled={
              disabled || referrer?.Email || history?.location?.search?.length
            }
            name="CompanyName"
            required={
              !/^records$/gi.test(mode) || inputs?.product !== "Records"
            }
            size="small"
            value={inputs.CompanyName}
            onChange={(e) => onChange(e.target.name, e.target.value)}
          />
        </Control>
      </Field>
      <Field className="grid-override" kind="group">
        <Control expanded>
          <Label>City</Label>
          <Input
            disabled={
              disabled || referrer?.Email || history?.location?.search?.length
            }
            name="City"
            size="small"
            value={inputs.City}
            onChange={(e) => onChange(e.target.name, e.target.value)}
          />
        </Control>
        <StateSelect
          displayFullLabel
          disabled={
            disabled || referrer?.Email || history?.location?.search?.length
          }
          label="State"
          name="State"
          size="small"
          value={inputs.State}
          onChange={onChange}
        />
      </Field>
      <Field kind="group">
        <Control expanded>
          <Label>Phone</Label>
          <PhoneNumberInput
            disabled={
              disabled || referrer?.Email || history?.location?.search?.length
            }
            name="Phone"
            required={
              !/^records$/gi.test(mode) || inputs?.product !== "Records"
            }
            size="small"
            value={inputs.Phone}
            onChange={onChange}
          />
        </Control>
        <Control expanded>
          <Label>Email</Label>
          <Input
            disabled={
              disabled || referrer?.Email || history?.location?.search?.length
            }
            name="Email"
            required={
              !/^records$/gi.test(mode) || inputs?.product !== "Records"
            }
            size="small"
            value={emailValue}
            onBlur={(e) => {
              if (emailValue) {
                onChange(e.target.name, emailValue);
              }
            }}
            onChange={(e) => {
              setEmailValue(e.target.value);
            }}
          />
        </Control>
      </Field>
    </Box>
  );
};

ReferrerInformationForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  inputs: PropTypes.object.isRequired,
  mode: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default ReferrerInformationForm;
