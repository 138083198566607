import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Title, Button } from "rbx";
import { useApolloClient, useMutation, useLazyQuery } from "@apollo/client";
import { useModal, useAuth } from "../../../../../context";
import {
  LIST_EXCEPTION_LIST_QUERY,
  CREATE_EXCEPTION_LIST_MUTATION,
  UPDATE_EXCEPTION_LIST_MUTATION,
  DELETE_EXCEPTION_LIST_MUTATION,
  SINGLE_EXCEPTION_LIST_QUERY,
  LIST_EXCEPTION_DEFINITION_QUERY,
} from "../../../../../graphql";
import Confirmation from "../../../../../components/Confirmation";
import {
  convertTimeZoneDataBase,
  customToast as toast,
  getDateEST,
} from "../../../../../utils";
import ExceptionForm from "../ExceptionForm";

const INITIAL_STATE = {
  Description: "",
  Status: "Active",
};

const ExceptionModal = ({ onComplete, ExceptionID, where }) => {
  const client = useApolloClient();
  const { setModalOpen } = useModal();
  const { state: authState } = useAuth();
  const [inputs, setInputs] = useState(INITIAL_STATE);
  const [updated, setUpdated] = useState([]);
  const [loading, setLoading] = useState(false);

  const [
    getException,
    { data: exceptionData, loading: queryLoading },
  ] = useLazyQuery(SINGLE_EXCEPTION_LIST_QUERY);
  const [createException] = useMutation(CREATE_EXCEPTION_LIST_MUTATION);
  const [updateException] = useMutation(UPDATE_EXCEPTION_LIST_MUTATION);
  const [deleteException] = useMutation(DELETE_EXCEPTION_LIST_MUTATION);

  useEffect(() => {
    if (ExceptionID) {
      getException({
        variables: {
          where: {
            ExceptionID: parseInt(ExceptionID, 10),
          },
        },
      });
    }
  }, [ExceptionID, getException]);

  useEffect(() => {
    if (exceptionData?.exceptionList) {
      setInputs({
        Description: exceptionData.exceptionList.Description || "",
        Status: exceptionData.exceptionList.Status || "Active",
      });
    }
  }, [exceptionData]);

  const handleChange = (name, value) => {
    setInputs((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (ExceptionID) {
      setUpdated((prev) => (prev.includes(name) ? prev : [...prev, name]));
    }
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);

      const date = convertTimeZoneDataBase(getDateEST());

      if (!ExceptionID) {
        await createException({
          variables: {
            data: {
              ...inputs,
              UserIDEdited: authState.user.userid,
              DateEdited: date,
              UserIDAdded: authState.user.userid,
              DateAdded: date,
            },
          },
          refetchQueries: [
            {
              query: LIST_EXCEPTION_LIST_QUERY,
            },
          ],
        });
        toast.success("Specialty created successfully.");
      } else {
        await updateException({
          variables: {
            data: {
              Description: { set: inputs.Description },
              Status: { set: inputs.Status },
              UserIDEdited: { set: authState.user.userid },
              DateEdited: { set: date },
            },
            where: {
              ExceptionID,
            },
          },
          refetchQueries: [
            {
              query: LIST_EXCEPTION_LIST_QUERY,
            },
            {
              query: SINGLE_EXCEPTION_LIST_QUERY,
              variables: {
                where: {
                  ExceptionID,
                },
              },
            },
          ],
        });
        toast.success("Exception updated successfully.");
      }
      onComplete();
    } catch (err) {
      toast.error("Error saving Exception");
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    const { data: exceptionsData } = await client.query({
      query: LIST_EXCEPTION_DEFINITION_QUERY,
      variables: {
        where: {
          ExceptionID: { equals: parseInt(ExceptionID, 10) },
        },
        take: 1,
        skip: 0,
      },
    });
    if (exceptionsData?.exceptionDefinitions?.length > 0) {
      toast.error(
        "This exception could not be removed because there's some information related"
      );
    } else {
      const performDelete = async () => {
        try {
          setLoading(true);
          await deleteException({
            variables: {
              where: {
                ExceptionID,
              },
            },
            refetchQueries: [
              {
                query: LIST_EXCEPTION_LIST_QUERY,
                variables: {
                  where,
                  orderBy: [{ Description: "asc" }],
                },
                take: 25,
                skip: 0,
              },
              {
                query: SINGLE_EXCEPTION_LIST_QUERY,
                variables: {
                  where: {
                    ExceptionID,
                  },
                },
              },
            ],
          });
          toast.success("Exception deleted successfully");
          onComplete();
        } catch (err) {
          toast.error("Error trying to delete exception");
        } finally {
          setLoading(false);
        }
      };
      setModalOpen(
        true,
        <Confirmation
          message="Are you sure you want to delete this exception?"
          onCancel={() => setModalOpen(false)}
          onConfirm={performDelete}
        />
      );
    }
  };

  const isDisabled =
    !inputs.Description ||
    (ExceptionID && !updated.length) ||
    queryLoading ||
    loading;

  return (
    <form id="exception-form" onSubmit={handleSubmit}>
      <header className="page-head">
        <div className="page-head-start">
          <Title size={5}>
            {`${!ExceptionID ? "Create" : "Edit"}`}
            &nbsp;Exception
          </Title>
        </div>
        <div className="page-head-end">
          <Button.Group hasAddons>
            <Button size="small" type="button" onClick={() => onComplete()}>
              <span>Cancel</span>
            </Button>
            <Button
              color="primary"
              disabled={isDisabled}
              form="exception-form"
              size="small"
              state={loading ? "loading" : ""}
              type="submit"
            >
              <span>Submit</span>
            </Button>
          </Button.Group>
        </div>
      </header>
      <hr />
      <ExceptionForm inputs={inputs} onChange={handleChange} />
      <hr />
      {ExceptionID && (
        <Button
          color="danger"
          size="small"
          type="button"
          onClick={handleDelete}
        >
          <span>Delete</span>
        </Button>
      )}
    </form>
  );
};

ExceptionModal.propTypes = {
  onComplete: PropTypes.func,
  ExceptionID: PropTypes.number,
  where: PropTypes.object,
};

ExceptionModal.defaultProps = {
  onComplete: () => null,
  ExceptionID: null,
  where: {},
};

export default ExceptionModal;
