import React from "react";
import PropTypes from "prop-types";
import { Field, Control, Label, Input, Help } from "rbx";

const CaseLinkDocumentForm = ({
  canSubmit,
  errorMessage,
  successMessage,
  inputs,
  onBlur,
  onChange,
}) => {
  const getInputColor = () => {
    if (canSubmit) return "success";
    if (errorMessage) return "danger";
    return "";
  };

  return (
    <Field className="grid-override" kind="group">
      <Control expanded>
        <Label>Filename </Label>
        <Input
          required
          color={getInputColor()}
          name="sfilename"
          placeholder="e.g. Referral.pdf, DoctorCover.docx"
          size="small"
          value={inputs.sfilename}
          onBlur={(e) => onBlur(e.target.value)}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
        <Help color={errorMessage ? "danger" : "success"}>
          {!canSubmit && errorMessage ? (
            <span>{errorMessage}</span>
          ) : (
            <span>{successMessage}</span>
          )}
          <span>&nbsp;</span>
        </Help>
      </Control>
      <Control expanded>
        <Label>Description</Label>
        <Input
          required
          name="description"
          size="small"
          value={inputs.description}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
    </Field>
  );
};

CaseLinkDocumentForm.propTypes = {
  canSubmit: PropTypes.bool,
  errorMessage: PropTypes.string,
  successMessage: PropTypes.string,
  inputs: PropTypes.object,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};
CaseLinkDocumentForm.defaultProps = {
  canSubmit: false,
  errorMessage: "",
  successMessage: "",
  inputs: {},
  onChange: () => null,
  onBlur: () => null,
};
export default CaseLinkDocumentForm;
