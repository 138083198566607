import React, { useState } from "react";
import PropTypes from "prop-types";
import { Control, Field, Label } from "rbx";

import RatsRequestGeneratorSelect from "../../../../components/RatsRequestGeneratorSelect";

import RequestGeneratorActionButtons from "../RequestGeneratorActionButtons";

const RequestGenerator = ({
  handleChange,
  inputs,
  disabled,
  required,
  label,
  type,
  shouldFilter,
}) => {
  const [selectedAddress, setSelectedAddress] = useState([]);
  const [addressInputs, setAddressInputs] = useState({});

  return (
    <Field kind="group">
      <div style={{ flexGrow: 1 }}>
        <RatsRequestGeneratorSelect
          disabled={disabled}
          label={label}
          name={type}
          required={required}
          shouldFilter={shouldFilter}
          value={inputs[type]}
          onAddressInputsChange={(ai) => setAddressInputs(ai)}
          onChange={(name, value) => handleChange(name, value)}
          onSelectedAddressChange={(sa) => setSelectedAddress(sa)}
        />
      </div>
      <Control>
        <Label>&nbsp;</Label>
        <RequestGeneratorActionButtons
          addressInputs={addressInputs}
          disabled={disabled}
          label={label}
          selectedAddress={selectedAddress}
          shouldFilter={shouldFilter}
          onClearSelectionClick={() => handleChange(type, "")}
          onRowClick={(row) => handleChange(type, row.RGid)}
          onTempRequestorAdd={(tempRequestor) =>
            handleChange(type, tempRequestor)
          }
        />
      </Control>
    </Field>
  );
};

RequestGenerator.propTypes = {
  handleChange: PropTypes.func.isRequired,
  inputs: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  required: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  shouldFilter: PropTypes.bool,
};

RequestGenerator.defaultProps = {
  shouldFilter: true,
};

export default RequestGenerator;
