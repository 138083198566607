import React, { useMemo } from "react";
import PropTypes from "prop-types";

import { Button, Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import { useMutation, useApolloClient } from "@apollo/client";

import { useMasterSubCase } from "../../../../hooks";
import { useModal } from "../../../../context";
import { customToast as toast } from "../../../../utils";

import {
  LIST_ASSOCIATED_CASES_QUERY,
  UPDATE_CASE_MUTATION,
  SINGLE_CASE_QUERY,
  FIND_FIRST_CASE_ITEM_QUERY,
} from "../../../../graphql";
import { ASSOCIATED_CASE_COLUMNS } from "../../columns";

import DataTable from "../../../../components/DataTable";
import Confirmation from "../../../../components/Confirmation";

import { SubCaseModal, MasterCase } from "../../components";

class CustomAssociatedCasesError extends Error {}

const AssociatedCases = ({ casenbr, chartnbr, claimnbr }) => {
  const history = useHistory();
  const { setModalOpen } = useModal();
  const client = useApolloClient();

  const { mastersubcase, mastercasenbr } = useMasterSubCase(casenbr);
  const [updateCase] = useMutation(UPDATE_CASE_MUTATION);

  const handleCellClick = (cell, event) => {
    if (cell.column.id !== "actions") {
      history.push(`/cases/${cell.row.original.casenbr}/profile`);
    }
    return null;
  };

  const handleAddSubcase = (e) => {
    e.preventDefault();
    setModalOpen(
      true,
      <SubCaseModal casenbr={casenbr} onComplete={() => setModalOpen(false)} />
    );
  };

  const where = useMemo(() => {
    if (mastersubcase === "M") {
      return {
        mastersubcase: { equals: "S" },
        mastercasenbr: { equals: parseInt(casenbr, 10) },
      };
    }
    if (mastersubcase === "S") {
      return {
        mastercasenbr: { equals: parseInt(mastercasenbr, 10) },
        casenbr: { not: { equals: parseInt(casenbr, 10) } },
      };
    }
    if (mastercasenbr) {
      return {
        casenbr: { not: { equals: parseInt(casenbr, 10) } },
        mastercasenbr: { equals: parseInt(mastercasenbr, 10) },
      };
    }
    return {};
  }, [mastersubcase, mastercasenbr, casenbr]);

  const markAsMasterOrSub = (rowItem) => {
    if (
      Number(rowItem.chartnbr) === Number(chartnbr) &&
      claimnbr === rowItem.claimnbr
    ) {
      setModalOpen(
        true,
        <Confirmation
          message={`Would you like to to mark case ${rowItem.casenbr} as a sub case of case ${casenbr}?`}
          onCancel={() => setModalOpen(false, "")}
          onConfirm={async () => {
            try {
              const {
                data: { findFirstCaseItem },
              } = await client.query({
                query: FIND_FIRST_CASE_ITEM_QUERY,
                variables: {
                  where: {
                    AND: [
                      where,
                      { casenbr: { not: { equals: rowItem.casenbr } } },
                    ],
                  },
                },
              });
              if (findFirstCaseItem) {
                if (rowItem.mastersubcase === "M") {
                  throw new CustomAssociatedCasesError(
                    "The selected case is already a master case and has other subcases associated with it."
                  );
                } else if (rowItem.mastersubcase === "S") {
                  throw new CustomAssociatedCasesError(
                    "The selected case is already associated with a different master case."
                  );
                } else {
                  throw new CustomAssociatedCasesError(
                    "Whoops! You've encountered an uknown error. Please contact support."
                  );
                }
              }
              await updateCase({
                variables: {
                  where: { casenbr: parseInt(casenbr, 10) },
                  data: {
                    mastersubcase: { set: "M" },
                    mastercasenbr: { set: parseInt(casenbr, 10) },
                  },
                },
              });
              await updateCase({
                variables: {
                  where: { casenbr: parseInt(rowItem.casenbr, 10) },
                  data: {
                    mastersubcase: { set: "S" },
                    mastercasenbr: { set: parseInt(casenbr, 10) },
                  },
                },
                refetchQueries: [
                  {
                    query: LIST_ASSOCIATED_CASES_QUERY,
                    variables: { where },
                  },
                  {
                    query: SINGLE_CASE_QUERY,
                    variables: { where: { casenbr: parseInt(casenbr, 10) } },
                  },
                ],
              });
              setModalOpen(false, "");
              toast.success("Case marked as subcase successfully");
            } catch (err) {
              const message =
                err instanceof CustomAssociatedCasesError
                  ? err.message
                  : "There was an error marking this case as a subcase. Please contact support.";
              toast.error(message);
            }
          }}
        />
      );
    } else {
      toast.error(
        "The claim and claimant demographic info need to match in order to mark as a subcase."
      );
    }
  };

  const showResults = !!Object.keys(where).length;

  return (
    <div>
      <div className="page-head">
        <div className="page-head-start">
          <Button
            color="primary"
            disabled={mastersubcase !== "M"}
            size="small"
            title="Add Subcase"
            onClick={handleAddSubcase}
          >
            <Icon>
              <FontAwesomeIcon icon="plus" />
            </Icon>
            <span>Add Subcase</span>
          </Button>
        </div>
        <div className="page-head-end">
          {!!mastercasenbr && <MasterCase casenbr={mastercasenbr} />}
        </div>
      </div>

      {showResults && (
        <DataTable
          aggregateKey="casenbr"
          aggregateName="aggregateCase"
          columns={ASSOCIATED_CASE_COLUMNS(markAsMasterOrSub)}
          fetchPolicy="cache-and-network"
          name="cases"
          orderBy={[{ id: "dateadded", desc: true }]}
          query={LIST_ASSOCIATED_CASES_QUERY}
          where={where}
          onCellClick={handleCellClick}
        />
      )}
    </div>
  );
};

AssociatedCases.propTypes = {
  casenbr: PropTypes.string.isRequired,
  chartnbr: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  claimnbr: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

AssociatedCases.defaultProps = {
  chartnbr: null,
  claimnbr: null,
};

export default AssociatedCases;
