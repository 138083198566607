import React from "react";
import PropTypes from "prop-types";

import { Link } from "react-router-dom";

const LinkCell = ({ to, value }) => (
  <Link to={to} onClick={(e) => e.stopPropagation()}>
    {value}
  </Link>
);

LinkCell.propTypes = {
  to: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default LinkCell;
