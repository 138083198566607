import React from "react";
import PropTypes from "prop-types";
import { Column, Box, Label, Field, Title } from "rbx";
import BooleanInput from "../../../components/BooleanInput";
import CaseDefaultDocuments from "../components/CaseDefaultDocuments";

const DocumentDefaults = ({
  inputs,
  onChange,
  disabled,
  casenbr,
  onSubmit,
}) => (
  <React.Fragment>
    <Box>
      <Title subtitle>Default Document Distribution</Title>
      <form id="edit-case-form" onSubmit={onSubmit}>
        <Column.Group multiline>
          <Column desktop={{ size: 4 }} mobile={{ size: 12 }}>
            <Label>E-Mail</Label>
            <Field kind="group">
              <BooleanInput
                disabled={disabled}
                label="Client"
                name="emailclient"
                value={inputs.emailclient}
                onChange={onChange}
              />
              <BooleanInput
                disabled={disabled}
                label="Doctor"
                name="emaildoctor"
                value={inputs.emaildoctor}
                onChange={onChange}
              />
              <BooleanInput
                disabled={disabled}
                label="P-Attny"
                name="emailPattny"
                value={inputs.emailPattny}
                onChange={onChange}
              />
            </Field>
          </Column>
          <Column
            desktop={{ size: 4 }}
            mobile={{ size: 12 }}
            style={{ paddingLeft: 20 }}
          >
            <Label>Fax</Label>
            <Field kind="group">
              <BooleanInput
                disabled={disabled}
                label="Client"
                name="faxclient"
                value={inputs.faxclient}
                onChange={onChange}
              />
              <BooleanInput
                disabled={disabled}
                label="Doctor"
                name="faxdoctor"
                value={inputs.faxdoctor}
                onChange={onChange}
              />
              <BooleanInput
                disabled={disabled}
                label="P-Attny"
                name="faxPattny"
                value={inputs.faxPattny}
                onChange={onChange}
              />
            </Field>
          </Column>
          <Column
            desktop={{ size: 4 }}
            mobile={{ size: 12 }}
            style={{ paddingLeft: 20 }}
          >
            <Label>Other</Label>
            <Field kind="group">
              <BooleanInput
                disabled={disabled}
                label="Verbal Report"
                name="reportverbal"
                value={inputs.reportverbal}
                onChange={onChange}
              />
              <BooleanInput
                disabled={disabled}
                label="Hand Deliver"
                name="bhanddelivery"
                value={inputs.bhanddelivery}
                onChange={onChange}
              />
              <BooleanInput
                disabled={disabled}
                label="Certified Mail"
                name="CertifiedMail"
                value={inputs.CertifiedMail}
                onChange={onChange}
              />
            </Field>
          </Column>
        </Column.Group>
      </form>
    </Box>
    <CaseDefaultDocuments casenbr={casenbr} />
  </React.Fragment>
);

DocumentDefaults.propTypes = {
  inputs: PropTypes.object,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  casenbr: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
};

DocumentDefaults.defaultProps = {
  inputs: {},
  onChange: (e) => e,
  disabled: false,
  casenbr: "",
};

export default DocumentDefaults;
