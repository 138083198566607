import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useMutation, useLazyQuery } from "@apollo/client";
import { Field, Control, Button, Help, Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import {
  DELETE_DOCTOR_MUTATION,
  DELETE_MANY_DOCTOR_SPECIALTIES_MUTATION,
  FIND_FIRST_CASE_ITEM_QUERY,
  FIND_FIRST_CASE_OTHER_PARTY,
} from "../../../../graphql";

import { useModal } from "../../../../context";

import Confirmation from "../../../../components/Confirmation";

const DoctorDeleteButton = ({
  doctorcode,
  navigateTo,
  confirmationText,
  successText,
  errorText,
  helpText,
  source,
}) => {
  const { setModalOpen } = useModal();
  const history = useHistory();
  const [deleteDoctor] = useMutation(DELETE_DOCTOR_MUTATION);
  const [deleteManyDoctorSpecialties] = useMutation(
    DELETE_MANY_DOCTOR_SPECIALTIES_MUTATION
  );

  const [findFirstCaseItem, { data: FindFirstCaseItemData }] = useLazyQuery(
    FIND_FIRST_CASE_ITEM_QUERY
  );

  const [
    findFirstCaseOtherParty,
    { data: FindFirstCaseOtherParty },
  ] = useLazyQuery(FIND_FIRST_CASE_OTHER_PARTY);

  useEffect(() => {
    if (source === "doctor") {
      findFirstCaseItem({
        variables: {
          where: { doctorcode: { equals: parseInt(doctorcode, 10) } },
        },
      });
    } else {
      findFirstCaseOtherParty({
        variables: {
          where: {
            otherParty: {
              is: {
                doctorcode: {
                  equals: parseInt(doctorcode, 10),
                },
              },
            },
          },
        },
      });
    }
  }, [source, doctorcode, findFirstCaseItem, findFirstCaseOtherParty]);

  const isDisabled =
    (source === "doctor" &&
      (!FindFirstCaseItemData || FindFirstCaseItemData?.findFirstCaseItem)) ||
    (source === "otherparty" &&
      (!FindFirstCaseOtherParty ||
        FindFirstCaseOtherParty?.findFirstCaseOtherParty));

  const handleDeleteDoctor = () => {
    const performDelete = async () => {
      try {
        await deleteManyDoctorSpecialties({
          variables: {
            where: {
              doctorcode: { equals: parseInt(doctorcode, 10) },
            },
          },
        });
        await deleteDoctor({
          variables: {
            where: {
              doctorcode: parseInt(doctorcode, 10),
            },
          },
        });
        toast.success(successText);
        setModalOpen(false, "");
        history.push(`/${navigateTo}`);
      } catch (err) {
        toast.error(errorText);
      }
    };
    setModalOpen(
      true,
      <Confirmation message={confirmationText} onConfirm={performDelete} />
    );
  };
  return (
    <div>
      <Field>
        <Control>
          <Button
            color="danger"
            disabled={isDisabled}
            size="small"
            onClick={handleDeleteDoctor}
          >
            <Icon>
              <FontAwesomeIcon icon="times" />
            </Icon>
            <span>Delete</span>
          </Button>
          {FindFirstCaseItemData?.findFirstCaseItem && <Help>{helpText}</Help>}
        </Control>
      </Field>
    </div>
  );
};

DoctorDeleteButton.propTypes = {
  doctorcode: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  navigateTo: PropTypes.string,
  confirmationText: PropTypes.string,
  successText: PropTypes.string,
  errorText: PropTypes.string,
  helpText: PropTypes.string,
  source: PropTypes.string,
};

DoctorDeleteButton.defaultProps = {
  navigateTo: "doctors",
  confirmationText: "Are you sure you want to delete this doctor?",
  successText: "Doctor deleted successfully.",
  errorText: "Error deleting doctor.",
  helpText: "This doctor has cases associated with it and cannot be deleted.",
  source: "doctor",
};

export default DoctorDeleteButton;
