import React from "react";
import PropTypes from "prop-types";
import { Box, Field, Control, Button, Label } from "rbx";

import { endOfDay, startOfDay, isValid } from "date-fns";

import { clampNumber } from "../../../../utils";

import FilterInput from "../../../../components/FilterInput";
import DegreeSelect from "../../../../components/DegreeSelect";
import StateSelect from "../../../../components/StateSelect";
import StatusSelect from "../../../../components/StatusSelect";
import SpecialtySelect from "../../../../components/SpecialtySelect";
import ZipInput from "../../../../components/ZipInput";
import BooleanInput from "../../../../components/BooleanInput";
import DateInput from "../../../../components/DateInput";

const [MIN_PROXIMITY, MAX_PROXIMITY] = [1, 5000];

const InvestigatorListFilters = ({
  filters,
  onChange,
  onBlur,
  loading,
  isDisabled,
  onReset,
}) => (
  <Box>
    <form className="list-filters" id="investigator-list-filters">
      <Field>
        <Control>
          <Label>Last Name</Label>
          <FilterInput
            getValue={(x) => (x && x.startsWith ? x.startsWith : "")}
            name="lastname"
            setValue={(x) => (x ? { startsWith: x } : "")}
            size="small"
            value={filters.lastname}
            onChange={onChange}
          />
        </Control>
      </Field>
      <Field>
        <SpecialtySelect
          getValue={(x) => x?.contains || ""}
          label="Specialty"
          name="doctorSpecialties"
          setValue={(x) => (x ? { contains: x } : "")}
          value={filters.doctorSpecialties}
          onChange={onChange}
        />
      </Field>
      <Field>
        <DateInput
          getValue={(x) => {
            const date = x?.some?.ApptDate?.gte || x?.some?.ApptDate?.lte;
            return date ? date.substr(0, 10) : "";
          }}
          label="Appt. Date"
          name="CaseItem"
          setValue={(x) => {
            if (
              x &&
              isValid(new Date(x.split("-").map((y) => parseInt(y, 10))))
            ) {
              return {
                some: {
                  ApptDate: {
                    gte: startOfDay(
                      new Date(x.split("-").map((y) => parseInt(y, 10)))
                    ).toISOString(),
                    lte: endOfDay(
                      new Date(x.split("-").map((y) => parseInt(y, 10)))
                    ).toISOString(),
                  },
                },
              };
            }
            return "";
          }}
          value={filters.CaseItem}
          onChange={onChange}
        />
      </Field>
      <Field>
        <StatusSelect
          showBlank
          getValue={(x) => x?.equals || ""}
          label="Status"
          name="status"
          setValue={(x) => (x ? { equals: x } : "")}
          size="small"
          value={filters.status}
          onChange={onChange}
        />
      </Field>
      <Field>
        <DegreeSelect
          getValue={(x) => x?.equals || ""}
          label="Credentials"
          name="credentials"
          setValue={(x) => (x ? { equals: x } : "")}
          size="small"
          value={filters.credentials}
          onChange={onChange}
        />
      </Field>
      <Field>
        <Control>
          <Label>City</Label>
          <FilterInput
            autoComplete="new"
            getValue={(x) => x?.equals || ""}
            name="city"
            required={filters.useProximity}
            setValue={(x) => (x ? { equals: x } : "")}
            size="small"
            type="text"
            value={filters.city || ""}
            onBlur={onBlur}
            onChange={onChange}
          />
        </Control>
      </Field>
      <Field>
        <StateSelect
          getValue={(x) => x?.equals || ""}
          label="State"
          name="state"
          required={filters.useProximity}
          setValue={(x) => (x ? { equals: x } : "")}
          value={filters.state}
          onBlur={(name, value) => onBlur(name, value?.equals || "")}
          onChange={onChange}
        />
      </Field>
      <Field>
        <ZipInput
          getValue={(x) => x?.equals || ""}
          name="zip"
          required={filters.useProximity}
          setCounty={(x) => (x ? { equals: x } : "")}
          setValue={(x) => (x ? { equals: x } : "")}
          value={filters.zip || ""}
          onChange={onChange}
        />
      </Field>
      <Field>
        <Control>
          <Label>County</Label>
          <FilterInput
            autoComplete="new"
            getValue={(x) => x?.equals || ""}
            name="county"
            required={filters.useProximity}
            setValue={(x) => (x ? { equals: x } : "")}
            size="small"
            type="text"
            value={filters.county || ""}
            onChange={onChange}
          />
        </Control>
      </Field>
      <Field kind="group">
        <Control expanded>
          <Label>Proximity</Label>
          <FilterInput
            autoComplete="new"
            getValue={(x) => (x && x.equals ? x.equals : "")}
            name="proximity"
            required={filters.useProximity}
            setValue={(x) => {
              const newValue = clampNumber(
                parseInt(x, 10),
                MIN_PROXIMITY,
                MAX_PROXIMITY
              );
              if (newValue) {
                return { equals: newValue };
              }
              return "";
            }}
            size="small"
            type="number"
            value={filters.proximity}
            onChange={(name, value) => {
              onChange("proximity", value);
              onChange("useProximity", !!value);
            }}
            onWheel={(e) => e.preventDefault()}
          />
        </Control>
        <Control>
          <Label>Use Proximity</Label>
          <BooleanInput
            name="useProximity"
            value={filters.useProximity}
            onChange={(name, value) => {
              const checked = !!value;
              onChange("useProximity", checked);
              if (!checked) {
                onChange("proximity", "");
              }
            }}
          />
        </Control>
      </Field>
      <Field>
        <Control>
          <Label>SSN / Tax ID</Label>
          <FilterInput
            getValue={(x) => (x && x.startsWith ? x.startsWith : "")}
            name="SSNTaxID"
            setValue={(x) => (x ? { startsWith: x } : "")}
            size="small"
            type="text"
            value={filters.SSNTaxID}
            onChange={onChange}
          />
        </Control>
      </Field>
      <Field>
        <Control>
          <Label>&nbsp;</Label>
          <Button color="danger" size="small" type="button" onClick={onReset}>
            Reset
          </Button>
        </Control>
      </Field>
    </form>
    {/* <hr /> */}
    {/* <pre>
      <code>{JSON.stringify(filters, null, 2)}</code>
    </pre> */}
  </Box>
);

InvestigatorListFilters.propTypes = {
  filters: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  isDisabled: PropTypes.bool.isRequired,
};

InvestigatorListFilters.defaultProps = {
  loading: false,
};
export default InvestigatorListFilters;
