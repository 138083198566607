import { gql } from "@apollo/client";

// eslint-disable-next-line import/prefer-default-export
export const WORKREQUEST_QUERY = gql`
  query Workrequest($where: WorkrequestWhereUniqueInput!) {
    workrequest(where: $where) {
      WRattorneyFileID
      WRdateOfAccident
      WRinsuredName
      WRfileContactID
      WRbillToID
      WRclaimType
      WRcarrier
      WRclaimState
      requestsubject {
        RSnameLast
        RSnameFirst
        RSdateOfBirth
        RSaddress1
        RSaddress2
        RScity
        RSstate
        RSzip
        RSphone1
        RSphone2
        RSnote
        RSssnFull
      }
    }
  }
`;
