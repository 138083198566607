import { gql } from "@apollo/client";

export const CREATE_PRODUCT_MUTATION = gql`
  mutation CREATE_PRODUCT_MUTATION($data: ProductCreateInput!) {
    createProduct(data: $data) {
      prodcode
      description
      longdesc
    }
  }
`;

export const UPDATE_PRODUCT_MUTATION = gql`
  mutation UPDATE_PRODUCT_MUTATION(
    $data: ProductUpdateInput!
    $where: ProductWhereUniqueInput!
  ) {
    updateProduct(data: $data, where: $where) {
      prodcode
      description
      longdesc
    }
  }
`;

export const DELETE_PRODUCT_MUTATION = gql`
  mutation DELETE_PRODUCT_MUTATION($where: ProductWhereUniqueInput!) {
    deleteProduct(where: $where) {
      prodcode
    }
  }
`;
