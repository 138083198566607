import React, { useState } from "react";

import { Field, Control, Button, Icon, Column, Label, Box } from "rbx";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useMutation } from "@apollo/client";
import { useModal } from "../../../../../../context";
import {
  DELETE_CASE_TYPE_OFFICE_MUTATION,
  ALL_CASE_TYPES_OFFICES_SELECT_QUERY,
  DELETE_SERVICE_CASE_TYPE_OFFICE_MUTATION,
  ALL_SERVICES_CASE_TYPES_SELECT_QUERY,
} from "../../../../../../graphql";
import { customToast as toast } from "../../../../../../utils";
import Confirmation from "../../../../../../components/Confirmation";
import OfficeSelect from "../../../../../../components/OfficeSelect";
import CaseTypeOfficeSelect from "../../../../../../components/CaseTypeOfficeSelect";
import ServiceCaseTypeSelect from "../../../../../../components/ServiceCaseTypeSelect";
import AddCaseTypeToOfficeModal from "../AddCaseTypeToOfficeModal";
import AddServiceCaseTypeOfficeModal from "../AddServiceCaseTypeOfficeModal";
import AddStatusToServiceModal from "../AddStatusToServiceModal";
import StatusDraggableList from "../StatusDraggableList";

const SpecialSelectsForm = () => {
  const [inputs, setInputs] = useState({
    officeCode: "",
    caseTypeCode: "",
    serviceCode: "",
    statusCode: "",
  });
  const { setModalOpen } = useModal();
  const [
    deleteCaseTypeOffice,
    { loading: DeleteCaseTypeOfficeLoading },
  ] = useMutation(DELETE_CASE_TYPE_OFFICE_MUTATION);

  const [
    deleteServiceCaseTypeOffice,
    { loading: DeleteServiceCaseTypeOfficeLoading },
  ] = useMutation(DELETE_SERVICE_CASE_TYPE_OFFICE_MUTATION);

  const onChange = (name, value) => {
    setInputs((prev) => {
      const next = { ...prev, [name]: value };
      if (/officeCode/.test(name)) {
        return {
          ...next,
          statusCode: "",
          serviceCode: "",
          caseTypeCode: "",
        };
      }
      if (/caseTypeCode/.test(name)) {
        return {
          ...next,
          statusCode: "",
          serviceCode: "",
        };
      }
      if (/serviceCode/.test(name)) {
        return {
          ...next,
          statusCode: "",
        };
      }
      return next;
    });
  };

  const handleAddCaseTypeOffice = () => {
    setModalOpen(
      true,
      <AddCaseTypeToOfficeModal
        officeCode={inputs.officeCode}
        onCancel={() => setModalOpen(false, "")}
        onComplete={(caseTypeCode) => {
          setInputs((prev) => ({ ...prev, caseTypeCode }));
          setModalOpen(false, "");
        }}
      />
    );
  };

  const handleDeleteCaseTypeOffice = () => {
    const performDelete = async () => {
      try {
        await deleteCaseTypeOffice({
          variables: {
            where: {
              casetype_officecode: {
                casetype: parseInt(inputs.caseTypeCode, 10),
                officecode: parseInt(inputs.officeCode, 10),
              },
            },
          },
          refetchQueries: [
            {
              query: ALL_CASE_TYPES_OFFICES_SELECT_QUERY,
              variables: {
                orderBy: { CaseType: { description: "asc" } },
                where: {
                  officecode: { equals: parseInt(inputs.officeCode, 10) },
                },
              },
            },
          ],
        });

        toast.success("Case Type to Office deleted successfully.");
        setInputs((prev) => ({
          ...prev,
          caseTypeCode: "",
          statusCode: "",
          serviceCode: "",
        }));
        setModalOpen(false, "");
      } catch (err) {
        toast.error(
          "Something went wrong deleting this Case Type to Office relation."
        );
      }
    };
    setModalOpen(
      true,
      <Confirmation
        message="Are you sure you want to delete this relation between case type and office?"
        onConfirm={performDelete}
      />
    );
  };

  const handleAddServiceCaseTypeOffice = () => {
    setModalOpen(
      true,
      <AddServiceCaseTypeOfficeModal
        caseTypeCode={inputs.caseTypeCode}
        officeCode={inputs.officeCode}
        onCancel={() => setModalOpen(false, "")}
        onComplete={(serviceCode) => {
          setInputs((prev) => ({ ...prev, serviceCode }));
          setModalOpen(false, "");
        }}
      />
    );
  };

  const handleDeleteServiceCaseTypeOffice = () => {
    const performDelete = async () => {
      try {
        await deleteServiceCaseTypeOffice({
          variables: {
            where: {
              casetype_servicecode_officecode: {
                casetype: parseInt(inputs.caseTypeCode, 10),
                officecode: parseInt(inputs.officeCode, 10),
                servicecode: parseInt(inputs.serviceCode, 10),
              },
            },
          },
          refetchQueries: [
            {
              query: ALL_SERVICES_CASE_TYPES_SELECT_QUERY,
              variables: {
                orderBy: { Service: { description: "asc" } },
                where: {
                  casetype: {
                    equals: parseInt(inputs.caseTypeCode, 10),
                  },
                  officecode: {
                    equals: parseInt(inputs.officeCode, 10),
                  },
                },
              },
            },
          ],
        });
        toast.success("Service to Case Type and Office deleted successfully.");
        setInputs((prev) => ({ ...prev, serviceCode: "", statusCode: "" }));
        setModalOpen(false, "");
      } catch (err) {
        toast.error(
          "Something went wrong deleting Service to Case Type and Office."
        );
      }
    };
    setModalOpen(
      true,
      <Confirmation
        message="Are you sure you want to delete this relation between service, case type and office?"
        onConfirm={performDelete}
      />
    );
  };

  const handleAddStatusToService = () => {
    setModalOpen(
      true,
      <AddStatusToServiceModal
        caseTypeCode={inputs.caseTypeCode}
        officeCode={inputs.officeCode}
        serviceCode={inputs.serviceCode}
        onCancel={() => setModalOpen(false, "")}
        onComplete={(statusCode) => {
          setInputs((prev) => ({ ...prev, statusCode }));
          setModalOpen(false, "");
        }}
      />
    );
  };

  const handleReset = () => {
    setInputs({
      officeCode: "",
      caseTypeCode: "",
      serviceCode: "",
      statusCode: "",
    });
  };
  return (
    <Column.Group>
      <Column size={4}>
        <Box>
          <Field kind="group">
            <Control expanded>
              <OfficeSelect
                name="officeCode"
                noValueLabel=""
                value={inputs.officeCode}
                onChange={onChange}
              />
            </Control>
          </Field>
          {inputs.officeCode && (
            <Field kind="group">
              <Control expanded>
                <CaseTypeOfficeSelect
                  disabled={!inputs.officeCode}
                  name="caseTypeCode"
                  noValueLabel=""
                  officeCode={inputs.officeCode}
                  value={inputs.caseTypeCode}
                  onChange={onChange}
                />
              </Control>
              <Control>
                <Label>&nbsp;</Label>
                <Button.Group hasAddons>
                  <Button
                    color="success"
                    disabled={!inputs.officeCode}
                    size="small"
                    type="button"
                    onClick={handleAddCaseTypeOffice}
                  >
                    <Icon>
                      <FontAwesomeIcon icon="plus" />
                    </Icon>
                  </Button>
                  <Button
                    color="danger"
                    disabled={
                      !inputs.caseTypeCode || DeleteCaseTypeOfficeLoading
                    }
                    size="small"
                    state={DeleteCaseTypeOfficeLoading ? "loading" : ""}
                    type="button"
                    onClick={handleDeleteCaseTypeOffice}
                  >
                    <Icon>
                      <FontAwesomeIcon icon="times" />
                    </Icon>
                  </Button>
                </Button.Group>
              </Control>
            </Field>
          )}
          {inputs.officeCode && inputs.caseTypeCode && (
            <Field kind="group">
              <Control expanded>
                <ServiceCaseTypeSelect
                  caseType={inputs.caseTypeCode}
                  disabled={!inputs.caseTypeCode}
                  name="serviceCode"
                  officeCode={inputs.officeCode}
                  value={inputs.serviceCode}
                  onChange={onChange}
                />
              </Control>
              <Control>
                <Label>&nbsp;</Label>
                <Button.Group hasAddons>
                  <Button
                    color="success"
                    disabled={!inputs.caseTypeCode}
                    size="small"
                    type="button"
                    onClick={handleAddServiceCaseTypeOffice}
                  >
                    <Icon>
                      <FontAwesomeIcon icon="plus" />
                    </Icon>
                  </Button>
                  <Button
                    color="danger"
                    disabled={
                      !inputs.serviceCode || DeleteServiceCaseTypeOfficeLoading
                    }
                    size="small"
                    state={DeleteServiceCaseTypeOfficeLoading ? "loading" : ""}
                    type="button"
                    onClick={handleDeleteServiceCaseTypeOffice}
                  >
                    <Icon>
                      <FontAwesomeIcon icon="times" />
                    </Icon>
                  </Button>
                </Button.Group>
              </Control>
            </Field>
          )}
          <hr />
          <Button
            color="primary"
            size="small"
            type="button"
            onClick={handleReset}
          >
            Reset
          </Button>
        </Box>
      </Column>
      <Column size={8}>
        <Box>
          <StatusDraggableList
            addStatus={handleAddStatusToService}
            serviceCode={inputs.serviceCode}
          />
        </Box>
      </Column>
    </Column.Group>
  );
};

SpecialSelectsForm.propTypes = {};

export default SpecialSelectsForm;
