import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { Title, Button, Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { toast } from "react-toastify";
import {
  LIST_DR_DO_NOT_USES_QUERY,
  DELETE_DR_DO_NOT_USE_MUTATION,
} from "../../../../graphql";

import Confirmation from "../../../../components/Confirmation";
import DataTable from "../../../../components/DataTable";
import CompanyDrDoNotUseModal from "../../components/CompanyDrDoNotUseModal";

import { generateColumns } from "./columns";

import { useModal } from "../../../../context";

const DrsNotToUse = () => {
  const { setModalOpen } = useModal();
  const { companycode } = useParams();
  const [deleteDrDoNotUse] = useMutation(DELETE_DR_DO_NOT_USE_MUTATION);

  const COLUMNS = useMemo(() => {
    const handleDelete = (drDoNotUse) => {
      const performDelete = async () => {
        try {
          setModalOpen(false, "");
          await deleteDrDoNotUse({
            variables: {
              where: {
                code_type_doctorcode: {
                  code: drDoNotUse.code,
                  type: drDoNotUse.type,
                  doctorcode: drDoNotUse.doctorcode,
                },
              },
            },
            refetchQueries: [
              {
                query: LIST_DR_DO_NOT_USES_QUERY,
                variables: {
                  where: {
                    code: { equals: parseInt(companycode, 10) },
                    type: { equals: "CO" },
                  },
                },
              },
            ],
          });
          toast.success("Dr Do Not Use deleted successfully.");
        } catch (err) {
          toast.error(`Error deleting Doctor Document.`);
        }
      };
      setModalOpen(
        true,
        <Confirmation
          message="Are you sure you want to delete this Dr Do Not Use?"
          onCancel={() => setModalOpen(false, "")}
          onConfirm={performDelete}
        />
      );
    };
    return generateColumns(handleDelete);
  }, [companycode, deleteDrDoNotUse, setModalOpen]);

  const handleAddClick = () => {
    setModalOpen(
      true,
      <CompanyDrDoNotUseModal
        companycode={companycode}
        onComplete={() => {
          setModalOpen(false, "");
        }}
      />
    );
  };

  return (
    <div className="client-drs-do-not-use-page">
      <header className="page-head">
        <div className="page-head-start">
          <Button
            aria-label="Add Default Document"
            color="primary"
            size="small"
            onClick={handleAddClick}
          >
            <Icon>
              <FontAwesomeIcon icon="plus" />
            </Icon>
            <span>Add</span>
          </Button>
          <Title subtitle size={6}>
            Select doctors this company does not want used on their cases.
          </Title>
        </div>
      </header>
      <DataTable
        aggregateKey="_all"
        aggregateName="aggregateDrDoNotUse"
        columns={COLUMNS}
        fetchPolicy="cache-and-network"
        name="drDoNotUses"
        query={LIST_DR_DO_NOT_USES_QUERY}
        where={{
          code: { equals: parseInt(companycode, 10) },
          type: { equals: "CO" },
        }}
      />
    </div>
  );
};

DrsNotToUse.propTypes = {};

export default DrsNotToUse;
