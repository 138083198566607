import React, { useState } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Box, Icon } from "rbx";

import ReferralStyledSubtitle from "../ReferralStyledSubtitle";

const Collapsible = ({ collapsed, children, label, footer }) => {
  const [isCollapsed, setIsCollapsed] = useState(collapsed);

  return (
    <Box className="collapse-box">
      <Button
        className="collapse-button"
        type="button"
        onClick={() => setIsCollapsed(!isCollapsed)}
      >
        <div className="button-content-container">
          <ReferralStyledSubtitle style={{ width: "100%", lineHeight: 0.95 }}>{label}</ReferralStyledSubtitle>
          <Icon>
            <FontAwesomeIcon
              icon={`${isCollapsed ? "chevron-down" : "chevron-up"}`}
            />
          </Icon>
        </div>
      </Button>
      <div
        aria-expanded={isCollapsed}
        className={`collapse-content ${isCollapsed ? "collapsed" : "expanded"}`}
      >
        {children}
      </div>
      <hr />
      {footer}
    </Box>
  );
};

Collapsible.propTypes = {
  collapsed: PropTypes.bool,
  children: PropTypes.object,
  label: PropTypes.string,
  footer: PropTypes.object,
};

Collapsible.defaultProps = {
  collapsed: false,
  children: null,
  label: "",
  footer: null,
};

export default Collapsible;
