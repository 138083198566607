import { gql } from "@apollo/client";

export const SINGLE_APOLLO_CLAIM_QUERY = gql`
  query SINGLE_APOLLO_CLAIM_QUERY($where: ApolloClaimsWhereUniqueInput!) {
    findUniqueApolloClaims(where: $where) {
      ClaimID
      CentralEligibilityID
      CreatedDate
      Archive
    }
  }
`;

export const FIND_FIRST_APOLLO_CLAIM_QUERY = gql`
  query FIND_FIRST_APOLLO_CLAIM_QUERY($where: ApolloClaimsWhereInput) {
    findFirstApolloClaims(where: $where) {
      ClaimID
      CentralEligibilityID
      CreatedDate
      Archive
    }
  }
`;
