import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";

import { Field, Control, Title } from "rbx";

import ScheduleTableWithSubComponents from "./ScheduleTableWithSubComponents";
import Pagination from "./SchedulePagination";
import columns from "./columns";

const ScheduleTable = ({ onRowClick, data }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [dataToRender, setDataToRender] = useState(data.slice(0, 25));

  const renderRowSubComponent = useCallback(
    ({ row }) => (
      <pre
        style={{
          padding: "1rem",
          fontSize: "12px",
          color: "RoyalBlue",
        }}
      >
        <code>{row.original.doctor.notes}</code>
      </pre>
    ),
    []
  );

  const defaultPageSize = 25;

  const recordCount = data.length;

  const pageCount = data.length
    ? Math.ceil(recordCount / defaultPageSize)
    : "1";

  useEffect(() => {
    setCurrentPage(0);
  }, [data]);

  useEffect(() => {
    if (currentPage === 0) {
      setDataToRender(data.slice(0, 25));
    } else {
      setDataToRender(data.slice(currentPage * 25, currentPage * 25 + 25));
    }
  }, [currentPage, data]);

  if (Array.isArray(data) && !data.length) {
    return (
      <Title subtitle style={{ color: "gray" }}>
        Nothing here...
      </Title>
    );
  }

  return (
    <React.Fragment>
      <ScheduleTableWithSubComponents
        columns={columns}
        data={dataToRender}
        renderRowSubComponent={renderRowSubComponent}
        onRowClick={onRowClick}
      />
      <Field align="right" kind="group">
        <Control>
          <Pagination
            canNextPage={currentPage + 1 < pageCount}
            canPreviousPage={currentPage + 1 > 1}
            page={currentPage + 1}
            pageSize={defaultPageSize}
            pages={pageCount}
            recordCount={recordCount}
            onClickNext={() => {
              setCurrentPage((prev) => prev + 1);
            }}
            onClickPrev={() => {
              setCurrentPage((prev) => prev - 1);
            }}
          />
        </Control>
      </Field>
    </React.Fragment>
  );
};

ScheduleTable.propTypes = {
  onRowClick: PropTypes.func,
  data: PropTypes.array,
};

ScheduleTable.defaultProps = {
  onRowClick: () => {},
  data: [],
};
export default ScheduleTable;
