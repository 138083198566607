const setup = {
  name: "setup",
  label: "Setup",
  icon: "cogs",
  active: (location) => {
    const [, p] = location.pathname.split("/");
    return /setup/.test(p);
  },
  permissions: ["AllAdminFunc"],
  links: [
    {
      name: "Business Lines",
      to: "/setup/business-lines",
    },
    {
      name: "Case Type Documents",
      to: "/setup/case-type-documents",
    },
    {
      name: "Doctors",
      links: [
        {
          name: "Degrees",
          to: "/setup/doctor-degrees",
        },
        {
          name: "Specialties",
          to: "/setup/doctor-specialties",
        },
      ],
    },
    {
      name: "Documents",
      links: [
        {
          name: "Locations",
          to: "/setup/document-locations",
        },
        {
          name: "Types",
          to: "/setup/document-types",
        },
      ],
    },
    {
      name: "Exceptions",
      to: "/setup/exceptions",
    },
    {
      name: "Issues",
      to: "/setup/issues",
    },
    {
      name: "Lists",
      links: [
        {
          name: "Buckets",
          to: "/setup/buckets",
        },
        {
          name: "Case Types",
          to: "/setup/case-types",
        },
        {
          name: "Offices",
          to: "/setup/offices",
        },
        {
          name: "Queues",
          to: "/setup/queues",
        },
        {
          name: "Services",
          to: "/setup/services",
        },
      ],
    },
    {
      name: "Notes",
      links: [
        {
          name: "Types",
          to: "/setup/note-types",
        },
      ],
    },
    {
      name: "Other Parties",
      links: [
        {
          name: "Other Parties",
          to: "/setup/other-party",
        },
        {
          name: "Types",
          to: "/setup/other-party-types",
        },
      ],
    },

    {
      name: "Problems",
      to: "/setup/problems",
    },

    {
      name: "Products",
      to: "/setup/products",
    },

    {
      name: "Provider Types",
      to: "/setup/provider-types",
    },

    {
      name: "Report Status Codes",
      to: "/setup/report-status-codes",
    },
    {
      name: "Standard Notes",
      to: "/setup/standard-notes",
    },
    {
      name: "Users",
      links: [
        {
          name: "Users",
          to: "/setup/users",
        },
        {
          name: "User Groups",
          to: "/setup/user-groups",
        },
        {
          name: "User Functions",
          to: "/setup/user-functions",
        },
        {
          name: "Processors",
          to: "/setup/processors",
        },
      ],
    },
  ],
};

export default setup;
