/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import {
  Control,
  Field,
  Input,
  Box,
  Label,
  Textarea,
  Radio,
  Button,
  Icon,
  Help,
} from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import BooleanInput from "../../../../components/BooleanInput";
import ReferralStyledSubtitle from "../ReferralStyledSubtitle";
import MedicalProvider from "../MedicalProvider";
import ProviderRushStatusOptions from "../ProviderRushStatusOptions/ProviderRushStatusOptions";

import StyledFormSectionHeader from "../StyledFormSectionHeader";
import Collapsible from "../Collapsible";
import RecordProviderRecordTypes from "./RecordProviderRecordTypes";

const NOTE_LENGTH_LIMIT = 3500;

const RecordRetrievalRequestForServiceForm = ({
  handleArrChange,
  inputs,
  handleAddProvider,
  handleDeleteProvider,
  handleObtainUpdatedRecords,
  disabled,
  handleDolChange,
}) => {
  let displayIndex = 0;
  return (
    <React.Fragment>
      {!inputs.Providers.filter((prov) => !prov.deleted)?.length && (
        <Box className="col-span">
          <StyledFormSectionHeader>
            <Field>
              <ReferralStyledSubtitle style={{ width: "100%" }}>
                Record Retrieval Request For Service
              </ReferralStyledSubtitle>
            </Field>
            <Field>
              <Control>
                <Button
                  color="primary"
                  size="small"
                  type="button"
                  onClick={handleAddProvider}
                >
                  <Icon>
                    <FontAwesomeIcon icon="plus" />
                  </Icon>
                  <span>Add Provider</span>
                </Button>
              </Control>
            </Field>
          </StyledFormSectionHeader>
        </Box>
      )}
      {inputs.Providers.map((prov, index, arr) => {
        if (!prov.deleted) {
          displayIndex += 1;
        }

        // UNECESARY CHECK, ADD PROVIDER BUTTON WAS MISSING
        // AFTER DELETING 1ST PROVIDER
        // const arrWithRemoveDeleted = arr.filter((a) => !a.deleted);

        const isNoteTooLong =
          prov?.SpecialInstructions?.length >= NOTE_LENGTH_LIMIT;

        const footer = (
          <StyledFormSectionHeader>
            <Field>
              <Control>
                <Button
                  color="danger"
                  disabled={disabled && prov.fromDB}
                  size="small"
                  type="button"
                  onClick={() => handleDeleteProvider(index)}
                >
                  <span>Remove</span>
                </Button>
              </Control>
            </Field>
            <Field>
              <Control>
                <Button
                  color="primary"
                  size="small"
                  type="button"
                  onClick={handleAddProvider}
                >
                  <Icon>
                    <FontAwesomeIcon icon="plus" />
                  </Icon>
                  <span>Add Provider</span>
                </Button>
              </Control>
            </Field>
            {/* UNECESSARY CHECK */}
            {/* {index === arrWithRemoveDeleted.length - 1 && (
              <Field>
                <Control>
                  <Button
                    color="primary"
                    size="small"
                    type="button"
                    onClick={handleAddProvider}
                  >
                    <Icon>
                      <FontAwesomeIcon icon="plus" />
                    </Icon>
                    <span>Add Provider</span>
                  </Button>
                </Control>
              </Field>
            )} */}
          </StyledFormSectionHeader>
        );

        const formatDate = (date) => {
          function convertToNonMilitaryTime(hour) {
            if (hour === 0) {
              return 12; // Special case: 0 should be converted to 12 AM
            }

            if (hour > 12) {
              return hour - 12; // Subtract 12 from hours greater than 12 to get the non-military equivalent
            }
            return hour; // For hours less than or equal to 12, no change needed
          }

          const utcTime = new Date(date);

          const year = utcTime.getUTCFullYear();
          const month = utcTime.getUTCMonth() + 1;
          const day = utcTime.getUTCDate();
          const hours = convertToNonMilitaryTime(utcTime.getUTCHours());
          const minutes =
            utcTime.getUTCMinutes() < 10
              ? `0${utcTime.getUTCMinutes()}`
              : utcTime.getUTCMinutes();
          const AMPM = utcTime.getUTCHours() >= 12 ? "PM" : "AM";
          const timeZoneArray = utcTime
            .toString()
            .match(/\(([A-Za-z\s].*)\)/)[1]
            .split(" "); // Eastern Daylight Time = ["Eastern", "Daylight", "Time"]

          const timeZoneAbbreviated = `${timeZoneArray[0][0]}${timeZoneArray[1][0]}${timeZoneArray[2][0]}`;

          const formattedDate = `${month}/${day}/${year}, ${hours}:${minutes}${AMPM} ${timeZoneAbbreviated}`;

          return formattedDate;
        };

        return (
          !prov.deleted && (
            <div
              className="col-span"
              id={prov.ProviderID}
              key={prov.ProviderID}
            >
              <Collapsible
                footer={footer}
                label={
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <React.Fragment>
                    <span>
                      Record Retrieval Request For Service
                      {` ${displayIndex}`}
                    </span>
                    <br />
                    <span
                      id="rr-date-enter-submit-info"
                      style={{
                        display: "flex",
                        fontSize: "0.8rem",
                        gap: "0.8rem",
                      }}
                    >
                      <React.Fragment>
                        <div>
                          <strong>Date Entered:</strong>
                          {prov.OriginalDateEntered ? (
                            <em>{`${formatDate(prov.OriginalDateEntered)}`}</em>
                          ) : (
                            <em>{` N/A`}</em>
                          )}
                        </div>
                      </React.Fragment>
                      <React.Fragment>
                        <div>
                          <strong>Date Submitted:</strong>
                          {prov.DateSubmitted ? (
                            <em>{`${formatDate(prov.DateSubmitted)}`}</em>
                          ) : (
                            <em>{` N/A`}</em>
                          )}
                        </div>
                      </React.Fragment>
                    </span>
                  </React.Fragment>
                }
              >
                <div>
                  <h2
                    className="sectionTitle"
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    Record Provider
                    {disabled && prov.fromDB && (
                      <Button
                        color="warning"
                        size="small"
                        type="button"
                        onClick={() =>
                          handleObtainUpdatedRecords(prov.ProviderID)
                        }
                      >
                        <strong>Obtain Updated Records</strong>
                      </Button>
                    )}
                  </h2>
                  <MedicalProvider
                    disabled={disabled}
                    handleArrChange={handleArrChange}
                    index={index}
                    prov={prov}
                  />
                  <h2 className="sectionTitle">Record Type</h2>
                  <RecordProviderRecordTypes
                    disabled={disabled}
                    handleArrChange={handleArrChange}
                    index={index}
                    provider={prov}
                  />

                  <h2 className="sectionTitle">Request Dates</h2>
                  <Field className="radioWrapper">
                    <Control>
                      <Label>
                        <Radio
                          checked={prov.RequestDates === "allRecs"}
                          disabled={disabled && prov.fromDB}
                          name={`${index}-RequestDates`}
                          value="allRecs"
                          onChange={(e) =>
                            handleArrChange(
                              index,
                              "RequestDates",
                              e.target.value
                            )
                          }
                        />
                        &nbsp;
                        <span className="radioItem">
                          Date Of Birth To Present
                        </span>
                      </Label>
                    </Control>
                  </Field>
                  <Field className="radioWrapper">
                    <Control>
                      <Label>
                        <Radio
                          checked={prov.RequestDates === "FromDOL"}
                          disabled={disabled && prov.fromDB}
                          name={`${index}-RequestDates`}
                          value="FromDOL"
                          onChange={(e) =>
                            handleArrChange(
                              index,
                              "RequestDates",
                              e.target.value
                            )
                          }
                        />
                        &nbsp;
                        <span className="radioItem">
                          Date Of Loss To Present
                        </span>
                      </Label>
                    </Control>
                  </Field>
                  {prov.RequestDates === "FromDOL" && (
                    <Field kind="group">
                      <Control>
                        <Label>Date Of Loss/Injury</Label>
                        <Input
                          required
                          disabled={disabled}
                          name="DateOfLoss"
                          size="small"
                          type="date"
                          value={inputs.DateOfLoss}
                          onChange={(e) =>
                            handleDolChange(e.target.name, e.target.value)
                          }
                        />
                      </Control>
                    </Field>
                  )}
                  <Field className="radioWrapper">
                    <Control>
                      <Label>
                        <Radio
                          checked={prov.RequestDates === "dateRange"}
                          disabled={disabled && prov.fromDB}
                          name={`${index}-RequestDates`}
                          value="dateRange"
                          onChange={(e) =>
                            handleArrChange(
                              index,
                              "RequestDates",
                              e.target.value
                            )
                          }
                        />
                        &nbsp;
                        <span className="radioItem">
                          Retrieve Specific Dates
                        </span>
                      </Label>
                    </Control>
                  </Field>
                  {prov.RequestDates === "dateRange" ? (
                    <Field kind="group">
                      <Control>
                        <Label>Start Date</Label>
                        <Input
                          required
                          disabled={disabled && prov.fromDB}
                          name="RetrieveStartDate"
                          size="small"
                          type="date"
                          value={prov.RetrieveStartDate}
                          onChange={(e) =>
                            handleArrChange(
                              index,
                              e.target.name,
                              e.target.value
                            )
                          }
                        />
                      </Control>
                      <Control>
                        <Label>End Date</Label>
                        <Input
                          required
                          disabled={
                            prov.RetrievePresent || (disabled && prov.fromDB)
                          }
                          name="RetrieveEndDate"
                          size="small"
                          type="date"
                          value={prov.RetrieveEndDate}
                          onChange={(e) =>
                            handleArrChange(
                              index,
                              e.target.name,
                              e.target.value
                            )
                          }
                        />
                      </Control>
                      <div className="present-checkbox">
                        <Control>
                          <BooleanInput
                            disabled={disabled && prov.fromDB}
                            label="Present"
                            name="RetrievePresent"
                            value={prov.RetrievePresent}
                            onChange={(name, value) => {
                              handleArrChange(index, name, value);
                            }}
                          />
                        </Control>
                      </div>
                    </Field>
                  ) : null}
                  <h2 className="sectionTitle">Request Method</h2>
                  <Field className="radioWrapper">
                    <Control>
                      <Label>
                        <Radio
                          checked={prov.RequestMethod === 0}
                          disabled={disabled && prov.fromDB}
                          name={`${index}-RequestMethod`}
                          value={0}
                          onChange={(e) =>
                            handleArrChange(
                              index,
                              "RequestMethod",
                              parseInt(e.target.value, 10)
                            )
                          }
                        />
                        &nbsp;
                        <span className="radioItem">
                          Authorization Will Be Submitted
                        </span>
                      </Label>
                    </Control>
                  </Field>
                  <Field className="radioWrapper">
                    <Control>
                      <Label>
                        <Radio
                          checked={prov.RequestMethod === 1}
                          disabled={disabled && prov.fromDB}
                          name={`${index}-RequestMethod`}
                          value={1}
                          onChange={(e) =>
                            handleArrChange(
                              index,
                              "RequestMethod",
                              parseInt(e.target.value, 10)
                            )
                          }
                        />
                        &nbsp;
                        <span className="radioItem">
                          ISG Retrieve Authorization from opposing counsel
                        </span>
                      </Label>
                    </Control>
                  </Field>
                  <Field className="radioWrapper">
                    <Control>
                      <Label>
                        <Radio
                          checked={prov.RequestMethod === 2}
                          disabled={disabled && prov.fromDB}
                          name={`${index}-RequestMethod`}
                          value={2}
                          onChange={(e) =>
                            handleArrChange(
                              index,
                              "RequestMethod",
                              parseInt(e.target.value, 10)
                            )
                          }
                        />
                        &nbsp;
                        <span className="radioItem">Subpoena For Records</span>
                      </Label>
                    </Control>
                  </Field>
                  <Field className="radioWrapper">
                    <Control>
                      <Label>
                        <Radio
                          checked={prov.RequestMethod === 3}
                          disabled={disabled && prov.fromDB}
                          name={`${index}-RequestMethod`}
                          value={3}
                          onChange={(e) =>
                            handleArrChange(
                              index,
                              "RequestMethod",
                              parseInt(e.target.value, 10)
                            )
                          }
                        />
                        &nbsp;
                        <span className="radioItem">
                          Non Party Request For Production Of Documents (GA
                          Only)
                        </span>
                      </Label>
                    </Control>
                  </Field>
                  <Field className="radioWrapper">
                    <Control>
                      <Label>
                        <Radio
                          checked={prov.RequestMethod === 4}
                          disabled={disabled && prov.fromDB}
                          name={`${index}-RequestMethod`}
                          value={4}
                          onChange={(e) =>
                            handleArrChange(
                              index,
                              "RequestMethod",
                              parseInt(e.target.value, 10)
                            )
                          }
                        />
                        &nbsp;
                        <span className="radioItem">
                          No Auth/Use Workers Comp Specified Language
                        </span>
                      </Label>
                    </Control>
                  </Field>
                  <ProviderRushStatusOptions
                    disabled={disabled}
                    handleArrChange={handleArrChange}
                    index={index}
                    provider={prov}
                  />
                  <h2 className="sectionTitle">Special Instructions</h2>
                  <Field>
                    <Textarea
                      disabled={disabled && prov.fromDB}
                      maxLength={NOTE_LENGTH_LIMIT}
                      name="SpecialInstructions"
                      required={prov?.RecordType?.includes(284)}
                      size="small"
                      value={prov.SpecialInstructions}
                      onChange={(e) =>
                        handleArrChange(index, e.target.name, e.target.value)
                      }
                    />
                    <Help color={isNoteTooLong ? "danger" : "primary"}>
                      {`${prov?.SpecialInstructions?.length} / ${NOTE_LENGTH_LIMIT} Characters`}
                    </Help>
                  </Field>
                </div>
              </Collapsible>
            </div>
          )
        );
      })}
    </React.Fragment>
  );
};

RecordRetrievalRequestForServiceForm.propTypes = {
  handleArrChange: PropTypes.func.isRequired,
  inputs: PropTypes.object.isRequired,
  handleAddProvider: PropTypes.func.isRequired,
  handleDeleteProvider: PropTypes.func.isRequired,
  handleObtainUpdatedRecords: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  handleDolChange: PropTypes.func.isRequired,
};

export default RecordRetrievalRequestForServiceForm;
