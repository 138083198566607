const DOCTOR_LOCATION_FIELDS = [
  {
    name: "#doctorLocations",
    type: "String",
    entity: "doctorLocation",
  },
  {
    name: "/doctorLocations",
    type: "String",
    entity: "doctorLocation",
  },
  { name: "notes", type: "String", entity: "doctorLocation" },
];

export default DOCTOR_LOCATION_FIELDS;
