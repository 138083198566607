import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Title, Button } from "rbx";
import { useMutation, useLazyQuery } from "@apollo/client";
import ServiceSelect from "../../../../../../components/ServiceSelect";
import QueueSelect from "../../../../../../components/QueueSelect";
import { useAuth } from "../../../../../../context";
import {
  customToast as toast,
  getDateEST,
  convertTimeZoneDataBase,
} from "../../../../../../utils";
import {
  CREATE_SERVICE_QUEUE_MUTATION,
  ALL_SERVICE_QUEUES_QUERY,
} from "../../../../../../graphql";

const INITIAL_STATE = {
  serviceCode: "",
  statusCode: "",
  officeCode: "",
  caseTypeCode: "",
};

const AddStatusToServiceModal = ({ onComplete, serviceCode, onCancel }) => {
  const [
    getServiceQueues,
    { data: serviceCodeData, loading: loadingCaseTypes },
  ] = useLazyQuery(ALL_SERVICE_QUEUES_QUERY);
  const [queues, setQueues] = useState([]);

  const [inputs, setInputs] = useState({ ...INITIAL_STATE, serviceCode });
  const { state: authState } = useAuth();
  const [loading, setLoading] = useState(false);
  const [createServiceQueue] = useMutation(CREATE_SERVICE_QUEUE_MUTATION);

  useEffect(() => {
    if (serviceCode) {
      getServiceQueues({
        variables: {
          orderBy: [{ queueorder: "asc" }, { nextstatus: "asc" }],
          where: {
            servicecode: { equals: parseInt(serviceCode, 10) },
          },
        },
      });
    } else {
      setQueues([]);
    }
  }, [getServiceQueues, serviceCode]);

  useEffect(() => {
    const sqs = serviceCodeData?.serviceQueues;
    if (Array.isArray(sqs)) {
      setQueues(sqs);
    }
  }, [serviceCodeData?.serviceQueues]);

  const handleChange = (name, value) => {
    setInputs((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const date = convertTimeZoneDataBase(getDateEST());
      setLoading(true);
      await createServiceQueue({
        variables: {
          data: {
            servicecode: parseInt(inputs.serviceCode, 10),
            statuscode: inputs.statusCode.toString(),
            useridadded: authState.user.userid,
            dateadded: date,
            nextstatus: "0",
            dateedited: date,
            queueorder:
              Array.isArray(queues) && queues.length
                ? Math.max(...queues.map((qu) => qu.queueorder)) + 1
                : 1,
            useridedited: authState.user.userid,
          },
        },
        refetchQueries: [
          {
            query: ALL_SERVICE_QUEUES_QUERY,
            variables: {
              orderBy: { queueorder: "asc" },
              where: {
                servicecode: {
                  equals: parseInt(inputs.serviceCode, 10),
                },
              },
            },
          },
        ],
      });
      toast.success("Status to Service created successfully.");
      onComplete(inputs.statusCode);
    } catch (err) {
      toast.error("Error saving Status to Service ");
    } finally {
      setLoading(false);
    }
  };

  const isDisabled = !inputs.statusCode || loading || loadingCaseTypes;

  return (
    <form id="service-form" onSubmit={handleSubmit}>
      <header className="page-head">
        <div className="page-head-start">
          <Title size={5}>Link Status to Service</Title>
        </div>
        <div className="page-head-end">
          <Button.Group hasAddons>
            <Button size="small" type="button" onClick={onCancel}>
              <span>Cancel</span>
            </Button>
            <Button
              color="primary"
              disabled={isDisabled}
              form="service-form"
              size="small"
              state={loading ? "loading" : ""}
              type="submit"
            >
              <span>Submit</span>
            </Button>
          </Button.Group>
        </div>
      </header>
      <hr />
      <div className="service-status-form">
        <ServiceSelect
          disabled
          name="serviceCode"
          value={inputs.serviceCode}
          onChange={handleChange}
        />
        <QueueSelect
          name="statusCode"
          value={inputs.statusCode}
          where={
            Array.isArray(queues) &&
            queues.length > 0 && {
              statuscode: {
                notIn: queues.map((sq) => parseInt(sq.statuscode, 10)),
              },
            }
          }
          onChange={handleChange}
        />
      </div>
      <hr />
    </form>
  );
};

AddStatusToServiceModal.propTypes = {
  onComplete: PropTypes.func.isRequired,
  serviceCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default AddStatusToServiceModal;
