import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { useQuery, useMutation } from "@apollo/client";
import { Title, Button } from "rbx";
import { toast } from "react-toastify";

import CaseDocumentForm from "../CaseDocumentForm";
import {
  UPDATE_CASE_DOCUMENT_MUTATION,
  LIST_CASE_DOCUMENT_QUERY,
  SINGLE_CASE_DOCUMENT_QUERY,
} from "../../../../graphql";

import { useCaseDocumentPath } from "../../../../hooks";

const { REACT_APP_DOCUMENT_HOST } = process.env;

const initialState = {
  description: "",
  sfilename: "",
};

const convertInputToVariables = (variables, keys, adding = false) =>
  keys.reduce((acc, curr) => {
    if (variables[curr]) {
      acc[curr] = adding ? variables[curr] : { set: variables[curr] };
    }
    return acc;
  }, {});

const CaseDocumentModal = ({ seqno, casenbr, onComplete }) => {
  const getCaseVirtualDocumentPath = useCaseDocumentPath(casenbr);
  const [inputs, setInputs] = useState({ ...initialState });
  const [loading, setLoading] = useState(false);

  const [
    updateCaseDocument,
    { loading: UpdateCaseDocumentLoading },
  ] = useMutation(UPDATE_CASE_DOCUMENT_MUTATION);

  const { data: CaseDocumentData } = useQuery(SINGLE_CASE_DOCUMENT_QUERY, {
    variables: { where: { seqno: parseInt(seqno, 10) } },
  });

  useEffect(() => {
    if (CaseDocumentData?.caseDocument) {
      setInputs({
        description: CaseDocumentData?.caseDocument.description,
        sfilename: CaseDocumentData?.caseDocument.sfilename.replace(
          /\..+$/,
          ""
        ),
      });
    }
  }, [CaseDocumentData]);

  const handleSave = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      const extension = CaseDocumentData.caseDocument.sfilename
        .split(".")
        .pop();
      const newSfilename = `${inputs.sfilename}.${extension}`;
      if (CaseDocumentData.caseDocument.sfilename !== newSfilename) {
        const computedOldPath = getCaseVirtualDocumentPath(
          CaseDocumentData.caseDocument.sfilename,
          false
        );
        const computedNewPath = getCaseVirtualDocumentPath(newSfilename, false);

        const oldPath = encodeURIComponent(computedOldPath);
        const newPath = encodeURIComponent(computedNewPath);

        await axios.put(
          `${REACT_APP_DOCUMENT_HOST}?oldPath=${oldPath}&newPath=${newPath}`
        );
      }

      await updateCaseDocument({
        variables: {
          data: {
            ...convertInputToVariables(
              inputs,
              Object.keys(initialState),
              false
            ),
            sfilename: {
              set: newSfilename,
            },
          },
          where: {
            seqno: parseInt(seqno, 10),
          },
        },
        refetchQueries: [
          {
            query: LIST_CASE_DOCUMENT_QUERY,
            variables: {
              where: {
                casenbr: {
                  equals: parseInt(casenbr, 10),
                },
              },
            },
          },
        ],
      });
      toast.success("Case Document updated successfully.");
      onComplete(true);
    } catch (err) {
      toast.error("Error updating case document.");
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (name, value) => {
    setInputs((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <form id="case-document-form" onSubmit={handleSave}>
      <header className="page-head">
        <div className="page-head-start">
          <Title size={5}>
            {[seqno ? "Edit" : "Create", "Case Document"]
              .filter(Boolean)
              .join(" ")}
          </Title>
        </div>
        <div className="page-head-end">
          <Button.Group hasAddons>
            <Button
              size="small"
              type="button"
              onClick={() => onComplete(false)}
            >
              <span>Cancel</span>
            </Button>
            <Button
              color="primary"
              disabled={UpdateCaseDocumentLoading}
              form="case-document-form"
              size="small"
              state={UpdateCaseDocumentLoading ? "loading" : ""}
              type="submit"
            >
              <span>Submit</span>
            </Button>
          </Button.Group>
        </div>
      </header>
      <hr />
      <CaseDocumentForm
        disabled={loading}
        inputs={inputs}
        onChange={handleChange}
      />
      <hr />
    </form>
  );
};

CaseDocumentModal.propTypes = {
  seqno: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  casenbr: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onComplete: PropTypes.func,
};

CaseDocumentModal.defaultProps = {
  seqno: "",
  casenbr: "",
  onComplete: () => null,
};
export default CaseDocumentModal;
