import { gql } from "@apollo/client";

export const SINGLE_USER_FUNCTION_QUERY = gql`
  query userFunction($where: UserFunctionpWhereUniqueInput!) {
    userFunctions(where: $where) {
      functioncode
      functiondesc
    }
  }
`;

export const LIST_USER_FUNCTIONS_QUERY = gql`
  query userFunctions(
    $take: Int
    $skip: Int
    $orderBy: [UserFunctionOrderByInput!]
    $where: UserFunctionWhereInput
  ) {
    userFunctions(take: $take, orderBy: $orderBy, skip: $skip, where: $where) {
      functioncode
      functiondesc
    }
    aggregateUserFunction(where: $where) {
      count {
        functioncode
      }
    }
  }
`;