import { gql } from "@apollo/client";

export const LIST_CLIENTS_QUERY = gql`
  query clients(
    $take: Int = 20
    $orderBy: [ClientOrderByInput!]
    $skip: Int
    $where: ClientWhereInput
  ) {
    clients(take: $take, orderBy: $orderBy, skip: $skip, where: $where) {
      clientcode
      lastname
      firstname
      addr1
      addr2
      city
      state
      lastappt
      phone1
      status
      companycode
      company {
        companycode
        intname
      }
    }
    aggregateClient(where: $where) {
      count {
        clientcode
      }
    }
  }
`;

export const LIST_CLIENT_TYPE_QUERY = gql`
  query clientTypes(
    $take: Int = 50
    $orderBy: [ClientTypeOrderByInput!]
    $skip: Int
    $where: ClientTypeWhereInput
  ) {
    clientTypes(take: $take, orderBy: $orderBy, skip: $skip, where: $where) {
      dateadded
      dateedited
      Description
      typecode
      useridadded
      useridedited
    }
  }
`;

export const LIST_STATE_QUERY = gql`
  query states(
    $take: Int = 100
    $orderBy: [StateOrderByInput!]
    $skip: Int
    $where: StateWhereInput
  ) {
    states(take: $take, orderBy: $orderBy, skip: $skip, where: $where) {
      dateedited
      Statecode
      StateName
      useridedited
    }
  }
`;

export const SINGLE_CLIENT_QUERY = gql`
  query client($where: ClientWhereUniqueInput!) {
    client(where: $where) {
      addr1
      addr2
      ARKey
      billaddr1
      billaddr2
      billattn
      billcity
      billfax
      billstate
      billzip
      casetype
      CertifiedMail
      city
      clientcode
      clientnbrold
      companycode
      Country
      dateadded
      dateedited
      DefOfficeCode
      documentemail
      documentfax
      documentmail
      DocumentPublish
      email
      fax
      firstname
      lastappt
      lastname
      marketercode
      notes
      phone1
      phone1ext
      phone2
      phone2ext
      photoRqd
      prefix
      priority
      ProcessorEmail
      ProcessorFax
      ProcessorFirstName
      ProcessorLastName
      ProcessorPhone
      ProcessorPhoneExt
      PublishOnWeb
      QARep
      reportphone
      state
      status
      suffix
      title
      TypeCode
      usddate1
      usddate2
      usdint1
      usdint2
      usdmoney1
      usdmoney2
      usdtext1
      usdtext2
      usdvarchar1
      usdvarchar2
      useridadded
      useridedited
      WebGUID
      Webpassword
      WebUserID
      WebUserName
      zip
      doNotInherit
      company {
        companycode
        intname
        companyToCompanyGroup {
          CompanyGroupID
        }
      }
      WebUser {
        WebUserID
        UserID
        Password
        LastLoginDate
      }
    }
  }
`;

export const FIND_FIRST_CLIENT_QUERY = gql`
  query FIND_FIRST_CLIENT_QUERY($where: ClientWhereInput) {
    findFirstClient(where: $where) {
      clientcode
      lastname
      firstname
      city
      state
      phone1
      # DEFAULTS
      marketercode
      QARep
      email
      priority
      company {
        intname
        extname
        companycode
        marketercode
        QARep
        priority
      }
    }
  }
`;

// NOTE:  We no longer will be looking up tblClient through tblWebUser existing. We now directly access tblClient.
//       The query above "FIND_FIRST_CLIENT_QUERY" now accomplishes gathering the information we need. Please see AP-591 in Jira.

// export const SINGLE_REFERRER_CLIENT_QUERY = gql`
//   query SINGLE_REFERRER_CLIENT_QUERY($where: ClientWhereUniqueInput!) {
//     client(where: $where) {
//       clientcode
//       firstname
//       lastname
//       city
//       state
//       phone1
//       email
//       companycode
//       marketercode
//       company {
//         companycode
//         intname
//         extname
//       }
//     }
//   }
// `;

export const Company = gql`
  query Company($where: CompanyWhereUniqueInput!) {
    company(where: $where) {
      companycode
      companyToCompanyGroup {
        CompanyGroupID
      }
    }
  }
`;

export const CLIENT_OFFICES_QUERY = gql`
  query FindFirstClientoffice($where: ClientofficeWhereInput) {
    findFirstClientoffice(where: $where) {
      COclientMasterID
    }
  }
`;
