import React from "react";
import { format } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Generic, Icon } from "rbx";
import { convertTimeZone } from "../../../../utils";

const COLUMNS = [
  {
    Header: "Description",
    id: "Description",
    accessor: "Description",
  },
  {
    Header: "Status",
    id: "Status",
    accessor: "Status",
  },
  {
    Header: "Date Added",
    id: "DateAdded",
    accessor: "DateAdded",
    Cell: ({ cell }) =>
      cell?.value
        ? format(convertTimeZone(cell.value), "MM/dd/yyyy hh:mm a")
        : "--",
  },
  {
    Header: "Added By",
    id: "UserIDAdded",
    accessor: "UserIDAdded",
  },
  {
    Header: "Date Edited",
    id: "DateEdited",
    accessor: "DateEdited",
    Cell: ({ cell }) =>
      cell?.value
        ? format(convertTimeZone(cell.value), "MM/dd/yyyy hh:mm a")
        : "--",
  },
  {
    Header: "Edited By",
    id: "UserIDEdited",
    accessor: "UserIDEdited",
  },
];

export const generateColumns = (handleDelete) => [
  {
    Header: "",
    id: "delete",
    Cell: (cell) => (
      <Generic
        style={{ textAlign: "center" }}
        type="button"
        onClick={(e) => {
          e.stopPropagation();
          handleDelete(cell.row.original);
        }}
      >
        <Icon color="danger" size="small">
          <FontAwesomeIcon icon="times" />
        </Icon>
      </Generic>
    ),
  },
  ...COLUMNS,
];

export default COLUMNS;
