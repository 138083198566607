import { gql } from "@apollo/client";

export const CREATE_APOLLO_DOCUMENT_LOCATION_MUTATION = gql`
  mutation CREATE_APOLLO_DOCUMENT_LOCATION_MUTATION(
    $data: DocumentLocationsCreateInput!
  ) {
    createDocumentLocations(data: $data) {
      DocumentLocationID
      DocumentLocation
    }
  }
`;

export const UPDATE_APOLLO_DOCUMENT_LOCATION_MUTATION = gql`
  mutation UPDATE_APOLLO_DOCUMENT_LOCATION_MUTATION(
    $data: DocumentLocationsUpdateInput!
    $where: DocumentLocationsWhereUniqueInput!
  ) {
    updateDocumentLocations(data: $data, where: $where) {
      DocumentLocationID
      DocumentLocation
    }
  }
`;

export const DELETE_APOLLO_DOCUMENT_LOCATION_MUTATION = gql`
  mutation DELETE_APOLLO_DOCUMENT_LOCATION_MUTATION(
    $where: DocumentLocationsWhereUniqueInput!
  ) {
    deleteDocumentLocations(where: $where) {
      DocumentLocationID
      DocumentLocation
    }
  }
`;
