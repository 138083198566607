import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Control, Label, Select } from "rbx";

const PRODUCTS = [
  {
    value: "",
    label: "",
  },
  {
    value: "Records",
    label: "Records",
  },
  {
    value: "Investigations",
    label: "Investigations",
  },
  {
    value: "Medical Management",
    label: "Medical Management",
  },
];

const ProductSelect = ({
  label,
  size,
  name,
  onChange,
  disabled,
  value,
  required,
  id,
  fullWidth,
  getValue,
  setValue,
  inputs,
  isRecords,
}) => {
  const handleChange = (e) => {
    onChange(e.target.name, e.target.value);
  };

  const [productSelection, setProductSelection] = useState([...PRODUCTS]);

  useEffect(() => {
    const recordsOnly = PRODUCTS.filter(product => product.value === "Records");
    const noRecords = PRODUCTS.filter(product => product.value !== "Records")
    if (/^records$/gi.test(inputs?.product)) {
      setProductSelection((prev) => recordsOnly)
    } else if (!/^records$/gi.test(inputs?.product)) {
      setProductSelection((prev) => noRecords)
    }
  }, [inputs?.product]);

  return (
    <Control expanded size={size}>
      {label && <Label>{label}</Label>}
      <Select.Container fullwidth size={size}>
        <Select
          disabled={disabled || inputs?.product}
          id={id}
          name={name}
          required={required}
          value={inputs?.product}
          onChange={handleChange}
        >
          {productSelection.map((item) => (
            <Select.Option key={item.value} value={item.value}>
              {item.label}
            </Select.Option>
          ))}
        </Select>
      </Select.Container>
    </Control>
  );
};
ProductSelect.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  id: PropTypes.string,
  fullWidth: PropTypes.bool,
  getValue: PropTypes.func,
  setValue: PropTypes.func,
  inputs: PropTypes.object.isRequired,
  isRecords: PropTypes.bool,
};

ProductSelect.defaultProps = {
  size: "small",
  fullWidth: false,
  disabled: false,
  value: "",
  name: "",
  label: null,
  required: false,
  id: null,
  isRecords: false,
  getValue: (x) => x,
  setValue: (x) => x,
  onChange: () => null,
};

export default ProductSelect;
