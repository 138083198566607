import React from "react";
import PropTypes from "prop-types";
import { Field, Control, Textarea, Label } from "rbx";

const CaseCancellationForm = ({ inputs, disabled, onChange }) => (
  <div className="reschedule-case-form">
    <Field style={{ gridColumn: "1 / 3" }}>
      <Control>
        <Label>Enter a reason for cancellation</Label>
        <Textarea
          disabled={disabled}
          maxLength="900"
          name="reason"
          rows="3"
          size="small"
          value={inputs.reason}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
    </Field>
  </div>
);

CaseCancellationForm.propTypes = {
  inputs: PropTypes.object,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};
CaseCancellationForm.defaultProps = {
  inputs: {},
  onChange: () => {},
  disabled: false,
};
export default CaseCancellationForm;
