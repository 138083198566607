import React from "react";
import PropTypes from "prop-types";
import { Control, Label, Select } from "rbx";

const ReportStatuses = [
  {
    value: "Draft",
    label: "Draft",
  },
  {
    value: "Final",
    label: "Final",
  },
  {
    value: "Waiting for Signature",
    label: "Waiting for Signature",
  },
  {
    value: "Medical Records",
    label: "Medical Records",
  },
  {
    value: "Quick Sheet",
    label: "Quick Sheet",
  },
  {
    value: "Audit / Review",
    label: "Audit / Review",
  },
  {
    value: "Final need Fee Audit",
    label: "Final need Fee Audit",
  },
];

const ReportStatusSelect = ({
  label,
  size,
  name,
  onChange,
  disabled,
  value,
  required,
  id,
  fullWidth,
  getValue,
  setValue,
  noValueLabel,
  disabledOptions,
}) => {
  const handleChange = (e) => {
    onChange(e.target.name, setValue(e.target.value));
  };

  return (
    <Control expanded size={size}>
      {label && <Label>{label}</Label>}
      <Select.Container fullwidth size={size}>
        <Select
          disabled={disabled}
          id={id}
          name={name}
          required={required}
          value={getValue(value)}
          onChange={handleChange}
        >
          <Select.Option value="">{noValueLabel}</Select.Option>
          {ReportStatuses.map((item) => (
            <Select.Option
              disabled={disabledOptions.includes(item.value)}
              key={item.value}
              value={item.value}
            >
              {item.label}
            </Select.Option>
          ))}
        </Select>
      </Select.Container>
    </Control>
  );
};
ReportStatusSelect.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.number,
  ]),
  name: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  id: PropTypes.string,
  fullWidth: PropTypes.bool,
  getValue: PropTypes.func,
  setValue: PropTypes.func,
  noValueLabel: PropTypes.string,
  disabledOptions: PropTypes.arrayOf(PropTypes.string),
};

ReportStatusSelect.defaultProps = {
  size: "small",
  fullWidth: false,
  disabled: false,
  value: "",
  name: "",
  label: null,
  required: false,
  id: null,
  getValue: (x) => x,
  setValue: (x) => x,
  onChange: () => null,
  noValueLabel: "",
  disabledOptions: [],
};

export default ReportStatusSelect;
