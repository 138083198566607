import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { format } from "date-fns";
import { useLazyQuery } from "@apollo/client";
import { SINGLE_CLIENT_QUERY } from "../../../../graphql";

import Table from "../../../../components/DataTable/Table";

const COLUMNS = [
  {
    Header: "User Type",
    id: "usertype",
    accessor: "usertype",
  },
  {
    Header: "Name",
    id: "name",
    accessor: "name",
  },
  {
    Header: "publish",
    id: "publish",
    accessor: "publish",
    Cell: ({ cell }) => (cell?.value ? "Yes" : "No"),
  },
  {
    Header: "Last Edited",
    id: "dateedited",
    accessor: "dateedited",
    Cell: ({ cell }) =>
      cell?.value ? format(new Date(cell.value), "MM/dd/yyyy") : "--",
  },
  {
    Header: "By",
    id: "useridedited",
    accessor: "useridedited",
  },
];
const WebPortal = ({ inputs }) => {
  const [client, setClient] = useState({
    usertype: "Client",
    name: "",
    publish: false,
    dateedited: new Date(),
    useridedited: "",
  });

  const [getClient, { data: getClientData }] = useLazyQuery(
    SINGLE_CLIENT_QUERY,
  );

  useEffect(() => {
    if (inputs.client) {
      getClient({
        variables: { where: { clientcode: parseInt(inputs.client, 10) } },
      });
    }
  }, [getClient, inputs.client]);

  useEffect(() => {
    const clientData = getClientData?.client;
    if (clientData) {
      setClient((prev) => ({
        ...prev,
        name:
          clientData.firstname && clientData.lastname
            ? `${clientData.firstname} ${clientData.lastname}`
            : "",
        publish: clientData.DocumentPublish,
        dateedited: clientData.dateedited,
        useridedited: clientData.useridedited,
      }));
    }
  }, [getClientData]);

  return (
    <div className="case-web-portal">
      <Table columns={COLUMNS} data={[client]} />
    </div>
  );
};

WebPortal.propTypes = {
  inputs: PropTypes.object,
};

WebPortal.defaultProps = {
  inputs: {},
};

export default WebPortal;
