import React from "react";
import PropTypes from "prop-types";

import { Input } from "rbx";

const AlphaInput = ({
  name,
  value,
  onChange,
  disabled,
  required,
  placeholder,
  size,
  allowSpaces,
}) => {
  const handleChange = (e) => {
    if (allowSpaces) {
      onChange(e);
    } else {
      const re = /^[A-Za-z-,']+$/;
      if (e.target.value === "" || re.test(e.target.value)) {
        onChange(e);
      }
    }
  };

  return (
    <Input
      disabled={disabled}
      name={name}
      placeholder={placeholder}
      required={required}
      size={size}
      type="text"
      value={value}
      onChange={handleChange}
    />
  );
};

AlphaInput.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  size: PropTypes.string,
  allowSpaces: PropTypes.bool,
};

AlphaInput.defaultProps = {
  name: "",
  placeholder: "",
  value: "",
  disabled: false,
  required: false,
  onChange: () => null,
  size: "small",
  allowSpaces: false,
};

export default AlphaInput;
