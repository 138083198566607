import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Title, Button } from "rbx";
import { useMutation, useLazyQuery, useApolloClient } from "@apollo/client";
import OfficeForm from "../OfficeForm";
import { useModal, useAuth } from "../../../../../context";
import {
  convertTimeZoneDataBase,
  customToast as toast,
  getDateEST,
} from "../../../../../utils";
import {
  CREATE_OFFICE_MUTATION,
  UPDATE_OFFICE_MUTATION,
  DELETE_OFFICE_MUTATION,
  // CREATE_AUDIT_TRAIL_MUTATION,
  LIST_OFFICES_QUERY,
  SINGLE_OFFICE_QUERY,
  FIND_FIRST_CASE_ITEM_QUERY,
} from "../../../../../graphql";
import Confirmation from "../../../../../components/Confirmation";

const INITIAL_STATE = {
  description: "",
  shortdesc: "",
  status: "Active",
  class: "",
};

class CustomOfficeError extends Error {}

const OfficeModal = ({ onComplete, officecode, where }) => {
  const client = useApolloClient();
  const { setModalOpen } = useModal();
  const { state: authState } = useAuth();
  const [inputs, setInputs] = useState(INITIAL_STATE);
  const [updated, setUpdated] = useState([]);
  const [loading, setLoading] = useState(false);

  const [getOffice, { data: officeData, loading: queryLoading }] = useLazyQuery(
    SINGLE_OFFICE_QUERY
  );
  const [createOffice] = useMutation(CREATE_OFFICE_MUTATION);
  const [updateOffice] = useMutation(UPDATE_OFFICE_MUTATION);
  const [deleteOffice] = useMutation(DELETE_OFFICE_MUTATION);
  // const [createAuditTrail] = useMutation(CREATE_AUDIT_TRAIL_MUTATION);

  useEffect(() => {
    if (officecode) {
      getOffice({
        variables: {
          where: { officecode },
        },
      });
    }
  }, [officecode, getOffice]);

  useEffect(() => {
    if (officeData?.office) {
      setInputs({
        description: officeData.office.description || "",
        shortdesc: officeData.office.shortdesc || "",
        status: officeData.office.status || "",
        class: officeData.office.class || "",
      });
    }
  }, [officeData]);

  const handleChange = (name, value) => {
    setInputs((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (officecode) {
      setUpdated((prev) => (prev.includes(name) ? prev : [...prev, name]));
    }
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      const date = convertTimeZoneDataBase(getDateEST());
      if (!officecode) {
        await createOffice({
          variables: {
            data: {
              ...inputs,
              useridedited: authState.user.userid,
              dateedited: date,
              useridadded: authState.user.userid,
              dateadded: date,
            },
          },
          refetchQueries: [
            {
              query: LIST_OFFICES_QUERY,
            },
          ],
        });

        // await createAuditTrail({
        //   variables: {
        //     data: {
        //       tablename: "tblOffice",
        //       entityid: createOfficeData.createOffice.officecode.toString(),
        //       type: "New",
        //       description: "New Office created",
        //       useridadded: authState.user.userid,
        //       dateadded: date,
        //     },
        //   },
        //   refetchQueries: [
        //     {
        //       query: SINGLE_OFFICE_QUERY,
        //       variables: {
        //         where: {
        //           officecode: createOfficeData.createOffice.officecode,
        //         },
        //       },
        //     },
        //   ],
        // });
        toast.success("Office created successfully.");
      } else {
        await updateOffice({
          variables: {
            data: {
              description: { set: inputs.description },
              shortdesc: { set: inputs.shortdesc },
              class: { set: inputs.class },
              status: { set: inputs.status },
              useridedited: { set: authState.user.userid },
              dateedited: { set: date },
            },
            where: {
              officecode,
            },
          },
          refetchQueries: [
            {
              query: LIST_OFFICES_QUERY,
            },
            {
              query: SINGLE_OFFICE_QUERY,
              variables: {
                where: {
                  officecode,
                },
              },
            },
          ],
        });

        // await createAuditTrail({
        //   variables: {
        //     data: {
        //       tablename: "tblOffice",
        //       entityid: officecode.toString(),
        //       type: "Update",
        //       description: `Updated fields: ${updated.join(", ")}`,
        //       useridadded: authState.user.userid,
        //       dateadded: date,
        //     },
        //   },
        // });
        toast.success("Office updated successfully.");
      }
      onComplete();
    } catch (err) {
      toast.error("Error saving Office");
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (e) => {
    e.preventDefault();

    const performDelete = async () => {
      try {
        setLoading(true);
        const { data: CaseItemData } = await client.query({
          query: FIND_FIRST_CASE_ITEM_QUERY,
          variables: {
            where: { officecode: { equals: parseInt(officecode, 10) } },
          },
          fetchPolicy: "network-only",
        });

        if (CaseItemData?.findFirstCaseItem) {
          throw new CustomOfficeError(
            "This office is associated with a case and cannot be removed."
          );
        }
        await deleteOffice({
          variables: {
            where: {
              officecode,
            },
          },
          refetchQueries: [
            {
              query: LIST_OFFICES_QUERY,
              variables: {
                where,
                orderBy: [{ officecode: "asc" }],
              },
              take: 25,
              skip: 0,
            },
            {
              query: SINGLE_OFFICE_QUERY,
              variables: {
                where: {
                  officecode,
                },
              },
            },
          ],
        });
        toast.success("Office deleted successfully");
        onComplete();
      } catch (err) {
        if (err instanceof CustomOfficeError) {
          toast.error(err.message);
        } else {
          toast.error("Error trying to delete Office");
        }
      } finally {
        setLoading(false);
      }
    };

    setModalOpen(
      true,
      <Confirmation
        message="Are you sure you want to delete this Office?"
        onCancel={() =>
          setModalOpen(
            true,
            <OfficeModal
              officecode={officecode}
              where={where}
              onComplete={onComplete}
            />
          )
        }
        onConfirm={performDelete}
      />
    );
  };

  const isDisabled =
    !inputs.description ||
    (officecode && !updated?.length) ||
    queryLoading ||
    loading;

  return (
    <form id="office-form" onSubmit={handleSubmit}>
      <header className="page-head">
        <div className="page-head-start">
          <Title size={5}>
            {`${!officecode ? "Create" : "Edit"}`}
            &nbsp;Office
          </Title>
        </div>
        <div className="page-head-end">
          <Button.Group hasAddons>
            <Button
              size="small"
              type="button"
              onClick={() => onComplete(false)}
            >
              <span>Cancel</span>
            </Button>
            <Button
              color="primary"
              disabled={isDisabled}
              form="office-form"
              size="small"
              state={loading ? "loading" : ""}
              type="submit"
            >
              <span>Submit</span>
            </Button>
          </Button.Group>
        </div>
      </header>
      <hr />
      <OfficeForm inputs={inputs} onChange={handleChange} />
      <hr />
      {officecode && (
        <Button
          color="danger"
          size="small"
          type="button"
          onClick={handleDelete}
        >
          <span>Delete</span>
        </Button>
      )}
    </form>
  );
};

OfficeModal.propTypes = {
  onComplete: PropTypes.func,
  officecode: PropTypes.number,
  where: PropTypes.object,
};

OfficeModal.defaultProps = {
  onComplete: () => null,
  officecode: null,
  where: {},
};

export default OfficeModal;
