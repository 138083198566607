import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/client";
import { Title, Button } from "rbx";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import CompanyForm from "../CompanyForm";
import { useAuth } from "../../../../context";
import { getDateEST, isString } from "../../../../utils";

import {
  CREATE_COMPANY_MUTATION,
  LIST_COMPANY_QUERY,
} from "../../../../graphql";

const INITIAL_STATE = {
  status: "Active",
  extname: "",
  intname: "",
  addr1: "",
  addr2: "",
  city: "",
  state: "",
  zip: "",
  phone: "",

  // status: "Active",
  // extname: "2nd I F",
  // intname: "2nd Insurance Fund",
  // addr1: "53 Oak Hill Rd",
  // addr2: "",
  // city: "Harvard",
  // state: "MA",
  // zip: "01451",
  // phone: "(508) 265-4271",
};

const convertInputToVariables = (variables, keys, adding = false) =>
  keys.reduce((acc, curr) => {
    if (variables[curr]) {
      acc[curr] = adding ? variables[curr] : { set: variables[curr] };
    }
    return acc;
  }, {});

const CompanyCreateModal = ({ onComplete }) => {
  const { state: authState } = useAuth();
  const history = useHistory();
  const [inputs, setInputs] = useState({ ...INITIAL_STATE });
  const [createCompany] = useMutation(CREATE_COMPANY_MUTATION);

  const handleSave = async (e) => {
    try {
      e.preventDefault();
      const date = getDateEST();
      const { data: CreateCompanyData } = await createCompany({
        variables: {
          data: {
            ...convertInputToVariables(
              inputs,
              Object.keys(INITIAL_STATE),
              true
            ),
            dateadded: date,
            dateedited: date,
            useridadded: authState.user.userid,
            useridedited: authState.user.userid,
          },
        },
        refetchQueries: [
          {
            query: LIST_COMPANY_QUERY,
            variables: {
              orderBy: [{ intname: "asc" }],
            },
          },
        ],
      });
      history.push(
        `/companies/${CreateCompanyData.createCompany.companycode}/profile`
      );
      toast.success(`Company created.`);
      onComplete();
    } catch (err) {
      toast.error(`Error creating company`);
    }
  };

  const handleChange = (name, value) => {
    setInputs((prev) => ({
      ...prev,
      // if the value is a string, we trim any leading spaces
      // if the value is not a string we set the value without trimming it bc it can be a number
      [name]: isString(value) ? value.trimStart() : value,
    }));
  };

  const isDisabled = useMemo(
    () =>
      Object.keys(inputs)
        .filter((x) => x !== "addr2")
        .some((key) => !inputs[key].length),
    [inputs]
  );
  return (
    <form id="company-create-form" onSubmit={handleSave}>
      <header className="page-head">
        <div className="page-head-start">
          <Title size={5}>Create Company</Title>
        </div>
        <div className="page-head-end">
          <Button.Group hasAddons>
            <Button
              size="small"
              type="button"
              onClick={() => onComplete(false)}
            >
              <span>Cancel</span>
            </Button>
            <Button
              color="primary"
              disabled={isDisabled}
              form="company-create-form"
              size="small"
              type="submit"
            >
              <span>Submit</span>
            </Button>
          </Button.Group>
        </div>
      </header>
      <hr />
      <CompanyForm
        inputs={inputs}
        showCompanyGroup={false}
        onChange={handleChange}
      />
      <hr />
      {/* <pre>
        <code>{JSON.stringify(inputs, null, 2)}</code>
      </pre> */}
    </form>
  );
};

CompanyCreateModal.propTypes = {
  onComplete: PropTypes.func,
};

CompanyCreateModal.defaultProps = {
  onComplete: () => null,
};

export default CompanyCreateModal;
