import { useState, useEffect } from "react";

import { useAuth } from "../context/AuthContext";

export default function useFunctions(functions = []) {
  const { state: authState } = useAuth();
  const [isPermitted, setIsPermitted] = useState(false);

  useEffect(() => {
    const userFunctions = authState?.user?.userGroupFunctions || [];
    if (Array.isArray(userFunctions)) {
      setIsPermitted(
        functions.some((perm) =>
          userFunctions.some((us) => us.functioncode === perm)
        )
      );
    }
  }, [authState, functions]);

  return isPermitted;
}
