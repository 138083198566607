import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { useMutation, useLazyQuery } from "@apollo/client";
import { Title, Button, Field, Control, Label } from "rbx";
import { toast } from "react-toastify";
import {
  ALL_DOCTOR_LOCATIONS_QUERY,
  SINGLE_LOCATION_QUERY,
  SINGLE_DOCTOR_LOCATION_QUERY,
  CREATE_DOCTOR_LOCATION_MUTATION,
  UPDATE_DOCTOR_LOCATION_MUTATION,
} from "../../../../graphql";

import DoctorSelect from "../../../../components/DoctorSelect";
import StatusSelect from "../../../../components/StatusSelect";
import { capitalize, getDateEST } from "../../../../utils";

import DoctorLocationForm from "../DoctorLocationForm";
import DoctorLocationInheritFromDoctorButton from "../DoctorLocationInheritFromDoctorButton";
import { useLocalStorage } from "../../../../hooks";
import { useAuth } from "../../../../context";

const initialState = {
  location: "",
  addr1: "",
  addr2: "",
  city: "",
  state: "",
  zip: "",
  county: "",
  Phone: "",
  fax: "",
  email: "",
  contactprefix: "",
  contactfirst: "",
  contactlast: "",
  status: "Active",
  notes: "",
  // location: "James",
  // addr1: "51 Yale St",
  // addr2: "13123213",
  // city: "Medford",
  // state: "MA",
  // zip: "02155",
  // county: "123123",
  // Phone: "123123",
  // fax: "12312312",
  // email: "james@jarvis.com",
  // contactprefix: "Dr.",
  // contactfirst: "James",
  // contactlast: "Jarvis",
  // notes: "SPAGHETTI",
};

const convertInputToVariables = (variables, keys, adding = false) =>
  keys.reduce((acc, curr) => {
    if (variables[curr]) {
      acc[curr] = adding ? variables[curr] : { set: variables[curr] };
    }
    return acc;
  }, {});

const DoctorLocationModal = ({
  doctorcode,
  locationcode,
  onComplete,
  refetchWhere,
}) => {
  const { state: authState } = useAuth();
  const [defaultPageSize] = useLocalStorage("DEFAULT_PAGE_SIZE", 18);
  const [inputs, setInputs] = useState({ ...initialState });

  const [doctorLocationStatus, setDoctorLocationStatus] = useState("Active");

  const [getDoctorLocation, { data: getDoctorLocationData }] = useLazyQuery(
    SINGLE_DOCTOR_LOCATION_QUERY,
  );

  const [getLocation, { data: getLocationData }] = useLazyQuery(
    SINGLE_LOCATION_QUERY,
  );

  const [createDoctorLocation] = useMutation(CREATE_DOCTOR_LOCATION_MUTATION);
  const [updateDoctorLocation] = useMutation(UPDATE_DOCTOR_LOCATION_MUTATION);

  useEffect(() => {
    if (locationcode) {
      getLocation({
        variables: { where: { locationcode: parseInt(locationcode, 10) } },
      });
    }
  }, [locationcode, getLocation]);

  useEffect(() => {
    if (locationcode && doctorcode) {
      getDoctorLocation({
        variables: {
          where: {
            locationcode_doctorcode: {
              locationcode: parseInt(locationcode, 10),
              doctorcode: parseInt(doctorcode, 10),
            },
          },
        },
      });
    }
  }, [doctorcode, getDoctorLocation, locationcode]);

  useEffect(() => {
    const location = getLocationData?.location;
    if (location) {
      setInputs(
        Object.keys(initialState).reduce(
          (acc, curr) => ({ ...acc, [curr]: location[curr] }),
          {},
        ),
      );
    }
  }, [getLocationData]);

  useEffect(() => {
    const doctorLocation = getDoctorLocationData?.doctorLocation;
    if (doctorLocation) {
      setDoctorLocationStatus(doctorLocation.status);
    }
  }, [getDoctorLocationData]);

  const handleSave = async (e) => {
    try {
      e.preventDefault();
      const refetchQueries = [
        {
          query: ALL_DOCTOR_LOCATIONS_QUERY,
          variables: {
            where: refetchWhere,
            orderBy: [],
            skip: 0,
            take: defaultPageSize,
          },
        },
      ];

      const date = getDateEST();

      if (locationcode) {
        await updateDoctorLocation({
          variables: {
            data: {
              status: { set: doctorLocationStatus },
              location: {
                update: convertInputToVariables(
                  inputs,
                  Object.keys(initialState),
                  false,
                ),
              },
              useridedited: { set: authState?.user?.userid },
              dateedited: { set: date },
            },
            where: {
              locationcode_doctorcode: {
                locationcode: parseInt(locationcode, 10),
                doctorcode: parseInt(doctorcode, 10),
              },
            },
          },
          refetchQueries: [
            ...refetchQueries,
            {
              query: SINGLE_DOCTOR_LOCATION_QUERY,
              variables: {
                where: {
                  locationcode_doctorcode: {
                    locationcode: parseInt(locationcode, 10),
                    doctorcode: parseInt(doctorcode, 10),
                  },
                },
              },
            },
            {
              query: SINGLE_LOCATION_QUERY,
              variables: {
                where: {
                  locationcode: parseInt(locationcode, 10),
                },
              },
            },
          ],
        });
      } else {
        await createDoctorLocation({
          variables: {
            data: {
              status: doctorLocationStatus,
              doctor: {
                connect: {
                  doctorcode: parseInt(doctorcode, 10),
                },
              },
              location: {
                create: convertInputToVariables(
                  inputs,
                  Object.keys(initialState),
                  true,
                ),
              },
              useridadded: authState?.user?.userid,
              dateadded: date,
              useridedited: authState?.user?.userid,
              dateedited: date,
            },
          },
          refetchQueries,
        });
      }
      toast.success(
        `Doctor Location ${locationcode ? "updat" : "creat"}ed successfully.`,
      );
      onComplete(true);
    } catch (err) {
      toast.error(
        `Error ${locationcode ? "updat" : "creat"}ing doctor location.`,
      );
    }
  };
  const handleChange = (name, value) => {
    setInputs((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <form id="doctor-location-form" onSubmit={handleSave}>
      <header className="page-head">
        <div className="page-head-start">
          <Title size={5}>Doctor Location</Title>
        </div>
        <div className="page-head-end">
          <Button.Group hasAddons>
            <Button
              size="small"
              type="button"
              onClick={() => onComplete(false)}
            >
              <span>Cancel</span>
            </Button>
            <Button
              color="primary"
              form="doctor-location-form"
              size="small"
              type="submit"
            >
              <span>Submit</span>
            </Button>
          </Button.Group>
        </div>
      </header>
      <hr />
      <Field kind="group">
        <Control expanded>
          <DoctorSelect disabled label="Doctor" value={doctorcode} />
        </Control>
        <Control expanded>
          <StatusSelect
            getValue={(x) => capitalize(x)}
            label="Doctor Location Status"
            size="small"
            value={doctorLocationStatus}
            onChange={(name, value) => setDoctorLocationStatus(value)}
          />
        </Control>
        <Control>
          <Label>&nbsp;</Label>
          <DoctorLocationInheritFromDoctorButton
            doctorcode={doctorcode}
            setInputs={setInputs}
          />
        </Control>
      </Field>

      <DoctorLocationForm inputs={inputs} onChange={handleChange} />
      <hr />
      {/* <pre>
        <code>{JSON.stringify(inputs, null, 2)}</code>
      </pre> */}
    </form>
  );
};

DoctorLocationModal.propTypes = {
  doctorcode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  locationcode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onComplete: PropTypes.func,
  refetchWhere: PropTypes.object,
};

DoctorLocationModal.defaultProps = {
  doctorcode: "",
  locationcode: "",
  onComplete: () => null,
  refetchWhere: {},
};
export default DoctorLocationModal;
