const caseTypesToExclude = {
  58: "Auto Bodily - Sedgwick SIU",
  48: "Auto Bodily Injury - PL",
  66: "Auto Bodily Injury - Sedgwick SIU",
  59: "Auto Bodily Injury - SIU",
  52: "Bodily Injury- PL SIU DG",
  31: "Family Medical Leave Act (FMLA)",
  60: "General Liability - PL",
  57: "General Liability - Sedgwick SIU",
  61: "General Liability - SIU",
  70: "Homeowners - SIU",
  38: "Mass PIP",
  40: "Med Pay",
  36: "New Jersey Auto",
  26: "New York NF/PIP",
  62: "Property - PL",
  54: "Property - PL SIU DG",
  63: "Property - SIU",
  65: "Workers Compensation - PL",
  55: "Workers Compensation - Sedgwick SIU",
  64: "Workers Compensation - SIU",
  68: "Automation Testing",
};

export default caseTypesToExclude;
