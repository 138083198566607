import React from "react";
import PropTypes from "prop-types";
import { Column, Control } from "rbx";

import { useParams } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { UPDATE_DOCTOR_MUTATION } from "../../../../graphql";

import { usePermissions } from "../../../../hooks";
import NotesInput from "../../../../components/NotesInput";

const Qualifications = ({ inputs, onChange, disabled, handleSave }) => {
  const isAdmin = usePermissions(["AllAdminFunc"]);
  const { doctorcode } = useParams();

  const [updateDoctor] = useMutation(UPDATE_DOCTOR_MUTATION);

  const handleSaveNote = async (qualifcations) => {
    updateDoctor({
      variables: {
        data: {
          qualifications: { set: qualifcations },
        },
        where: {
          doctorcode: parseInt(doctorcode, 10),
        },
      },
    });
  };

  const handleChange = async (name, value) => {
    try {
      await handleSaveNote(value);
      onChange(name, value);
      toast.success(`Qualifcations saved successfully.`);
    } catch (err) {
      toast.error(`Error saving qualifications.`);
    }
  };
  return (
    <Column.Group>
      <Column
        desktop={{ size: 12 }}
        mobile={{ size: 12 }}
        tablet={{ size: 12 }}
      >
        <Control style={{ marginBottom: "1rem" }}>
          <NotesInput
            contentEditable={isAdmin}
            disabled={disabled}
            name="qualifications"
            saveNotes={handleSaveNote}
            size="small"
            value={inputs.qualifications}
            onChange={handleChange}
          />
        </Control>
      </Column>
    </Column.Group>
  );
};

Qualifications.propTypes = {
  inputs: PropTypes.object,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  handleSave: PropTypes.func,
};

Qualifications.defaultProps = {
  inputs: {},
  onChange: (e) => e,
  handleSave: () => null,
  disabled: false,
};

export default Qualifications;
