import React, { useState, useRef, useEffect } from "react";
import { Field, Control, Button, Icon, Input } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import { useApolloClient } from "@apollo/client";
import { toast } from "react-toastify";
import DataTable from "../../components/DataTable";
import {
  LIST_CE_CLAIMS_QUERY,
  FIND_FIRST_APOLLO_CLAIM_QUERY,
  CREATE_APOLLO_CLAIM_MUTATION,
} from "../../graphql";
import { saveFilterState } from "../../utils";
import COLUMNS from "./columns";
import { ClaimListFilters, ClaimModal, NoDataResults } from "./components";
import { useLocalStorage, useQueryParams } from "../../hooks";
import { useModal } from "../../context";

const FILTER_STATE_KEYS = [
  "ClaimNumber",
  "CompanyName",
  "ClaimantFirstName",
  "ClaimantLastName",
];

const ClaimListPage = () => {
  const history = useHistory();
  const searchInput = useRef(null);
  const client = useApolloClient();

  const [where, setWhere] = useLocalStorage(`CLAIM_WHERE_SEARCH`, {});
  const [tempWhere, setTempWhere] = useState({});
  const [showFilters, setShowFilters] = useState(false);
  const [displayData, setDisplayData] = useState(
    !!window.sessionStorage.getItem("displayData")
  );

  const [search, setSearch] = useQueryParams("search", "");

  const { setModalOpen } = useModal();

  const handleRowClick = async (row) => {
    try {
      const {
        data: { findFirstApolloClaims: claim },
      } = await client.query({
        query: FIND_FIRST_APOLLO_CLAIM_QUERY,
        variables: {
          where: {
            CentralEligibilityID: {
              equals: parseInt(row.CentralEligibilityID, 10),
            },
          },
        },
      });
      if (claim) {
        history.push(`/claims/${claim.ClaimID}/services`);
      } else {
        const {
          data: { createApolloClaims },
        } = await client.mutate({
          mutation: CREATE_APOLLO_CLAIM_MUTATION,
          variables: {
            data: {
              CentralEligibilityID: parseInt(row.CentralEligibilityID, 10),
              CreatedDate: new Date(),
              Archive: false,
            },
          },
        });
        history.push(`/claims/${createApolloClaims.ClaimID}/services`);
      }
    } catch (err) {
      toast.error("There was an issue processing this request.");
    }
  };

  const handleSearchChange = ({ target: { value } }) => {
    setSearch(value || "");
  };

  const handleReset = () => {
    saveFilterState("CLAIM_WHERE_SEARCH", {});
    setWhere("");
    setTempWhere({});
    setDisplayData(false);
    setSearch("");
    if (searchInput.current) {
      searchInput.current.value = "";
    }
  };

  const handleFiltersChange = (name, value) => {
    if (!value) {
      setTempWhere((prev) =>
        Object.keys(prev).reduce((acc, curr) => {
          if (curr !== name) acc[curr] = prev[curr];
          return acc;
        }, {})
      );
    } else {
      setTempWhere((prev) => ({ ...prev, [name]: value }));
    }
    setTempWhere((prev) => {
      saveFilterState("CLAIM_WHERE_SEARCH", prev);
      return prev;
    });
  };

  const handleFilter = (e) => {
    e.preventDefault();
    const whereToSend = { ...tempWhere };
    const quotes = search.match(/(["'])(?:(?=(\\?))\2.)*?\1/gi);
    if (Array.isArray(quotes)) {
      const result = quotes.map((x) => x.replace(/"|'/g, ""));
      whereToSend.OR = result.reduce(
        (acc, curr) => [
          ...acc,
          ...FILTER_STATE_KEYS.map((key) => ({
            [key]: { equals: curr },
          })),
        ],
        []
      );
    } else if (/,/.test(search)) {
      const [lastName, firstName] = search.split(",").map((x) => x.trim());
      whereToSend.ClaimantFirstName = { equals: firstName };
      whereToSend.ClaimantLastName = { equals: lastName };
    } else {
      const values = search ? search.split(/\s/g) : null;
      const orFilter = values
        ? values.reduce(
            (acc, curr) => [
              ...acc,
              ...FILTER_STATE_KEYS.map((key) => ({
                [key]: { startsWith: curr },
              })),
            ],
            []
          )
        : null;
      if (orFilter) {
        whereToSend.OR = orFilter;
      }
    }

    setWhere(whereToSend);
    setDisplayData(true);
  };

  // if we extract a query from the url
  useEffect(() => {
    if (search) {
      setDisplayData(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div className="page-head">
        <form className="page-head-start" onSubmit={handleFilter}>
          {/* <Title>Central Eligibility Search</Title> */}
          <Field kind="group" style={{ width: "90%" }}>
            <Control expanded>
              <Input
                autoFocus
                autoComplete="off"
                defaultValue={search}
                id="search-input"
                placeholder="Search..."
                ref={searchInput}
                size="small"
                onChange={handleSearchChange}
              />
            </Control>
            <Control>
              <Button size="small" type="submit">
                <Icon>
                  <FontAwesomeIcon icon="search" />
                </Icon>
              </Button>
            </Control>
            <Control>
              <Button
                color="danger"
                size="small"
                type="button"
                onClick={handleReset}
              >
                Reset
              </Button>
            </Control>
          </Field>
        </form>
        <div className="page-head-end">
          <div className="section-header">
            <Field kind="group">
              <Control>
                <Button
                  color="warning"
                  size="small"
                  type="button"
                  onClick={() => setShowFilters((prev) => !prev)}
                >
                  <Icon>
                    <FontAwesomeIcon icon="filter" />
                  </Icon>
                  <span>Filters</span>
                </Button>
              </Control>
              <Button
                color="primary"
                size="small"
                onClick={() =>
                  setModalOpen(
                    true,
                    <ClaimModal
                      openDuplicateClaim={handleRowClick}
                      onComplete={() => {
                        setModalOpen(false, "");
                      }}
                    />
                  )
                }
              >
                <Icon>
                  <FontAwesomeIcon icon="plus" />
                </Icon>
                <span>Add</span>
              </Button>
            </Field>
          </div>
        </div>
      </div>

      {showFilters && (
        <ClaimListFilters
          filters={tempWhere}
          show={showFilters}
          onChange={handleFiltersChange}
          onFilter={handleFilter}
        />
      )}
      {displayData ? (
        <DataTable
          aggregateKey="_all"
          aggregateName="aggregateClaims"
          columns={COLUMNS}
          name="findManyClaims"
          orderBy={[
            { id: "CompanyName", desc: false },
            { id: "ClaimantFirstName", desc: false },
            { id: "ClaimantLastName", desc: false },
          ]}
          query={LIST_CE_CLAIMS_QUERY}
          where={where}
          onRowClick={handleRowClick}
        />
      ) : (
        <NoDataResults />
      )}
    </div>
  );
};

export default ClaimListPage;
