import { gql } from "@apollo/client";

const RECORD_PROVIDER_RECORD_TYPES_QUERY = gql`
  query Query(
    $where: Dropdown_RecordTypeWhereInput
    $orderBy: [Dropdown_RecordTypeOrderByInput!]
  ) {
    dropdown_RecordTypes(where: $where, orderBy: $orderBy) {
      RecordTypeID
      RecordTypeCode
      RecordTypeName
    }
  }
`;

export default RECORD_PROVIDER_RECORD_TYPES_QUERY;
