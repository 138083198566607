import React from "react";
import { Generic, Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { format } from "date-fns";
import { convertTimeZone } from "../../../../utils";

const DEFAULT_COLUMNS = [
  {
    Header: "Doctor",
    id: "doctor:lastname",
    accessor: "doctor",
    Cell: ({ cell }) =>
      cell?.value ? `${cell.value.lastname}, ${cell.value.firstname}` : "--",
  },
  {
    Header: "Added",
    id: "dateadded",
    accessor: "dateadded",
    Cell: ({ cell }) =>
      cell?.value
        ? format(convertTimeZone(cell.value), "MM/dd/yyyy hh:mm a")
        : "--",
  },
  {
    Header: "By",
    id: "useridadded",
    accessor: "useridadded",
  },
];

export const generateColumns = (handleDelete) => [
  {
    Header: "",
    id: "delete",
    Cell: (cell) => (
      <Generic
        style={{ textAlign: "center" }}
        type="button"
        onClick={(e) => {
          e.stopPropagation();
          handleDelete(cell.row.original);
        }}
      >
        <Icon color="danger" size="small">
          <FontAwesomeIcon icon="times" />
        </Icon>
      </Generic>
    ),
  },
  ...DEFAULT_COLUMNS,
];

export default DEFAULT_COLUMNS;
