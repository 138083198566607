/* eslint-disable react/prop-types */
/* eslint-disable-next-line react/prop-types */
import { format } from "date-fns";
import { convertTimeZone } from "../../../../utils";

const COLUMNS = [
  {
    Header: "Code",
    id: "officecode",
    accessor: "officecode",
  },
  {
    Header: "Short Description",
    id: "shortdesc",
    accessor: "shortdesc",
  },
  {
    Header: "Description",
    id: "description",
    accessor: "description",
  },
  {
    Header: "Status",
    id: "status",
    accessor: "status",
  },
  {
    Header: "Class",
    id: "class",
    accessor: "class",
  },
  {
    Header: "Added",
    id: "dateadded",
    accessor: "dateadded",
    Cell: ({ cell }) =>
      cell?.value
        ? format(convertTimeZone(cell.value), "MM/dd/yyyy hh:mm a")
        : "",
  },
  {
    Header: "By",
    id: "useridadded",
    accessor: "useridadded",
  },
  {
    Header: "Edited",
    id: "dateedited",
    accessor: "dateedited",
    Cell: ({ cell }) =>
      cell?.value
        ? format(convertTimeZone(cell.value), "MM/dd/yyyy hh:mm a")
        : "",
  },
  {
    Header: "By",
    id: "useridedited",
    accessor: "useridedited",
  },
];

export default COLUMNS;
