/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Table } from "rbx";

const getBackgroundColor = (
  isDragging,
  isGroupedOver,
  authorColors = { soft: "#FFFFFF" }
) => {
  if (isDragging) {
    return authorColors.soft;
  }

  if (isGroupedOver) {
    return "#EBECF0";
  }

  return "#FFFFFF";
};

const getBorderColor = (isDragging, authorColors = { hard: "lightgray" }) =>
  isDragging ? authorColors.hard : "transparent";

const Container = styled.a`
  border: 2px solid transparent;
  border-color: ${(props) => getBorderColor(props.isDragging, props.colors)};
  background-color: ${(props) =>
    getBackgroundColor(props.isDragging, props.isGroupedOver, props.colors)};
  box-shadow: ${({ isDragging }) =>
    isDragging ? `2px 2px 1px #A5ADBA` : "none"};
  padding: 0px;
  min-height: 26px;
  user-select: none;
  /* anchor overrides */
  color: #091e42;

  &:hover,
  &:active {
    color: #091e42;
    text-decoration: none;
  }

  &:focus {
    outline: none;
    border-color: ${(props) => props?.colors?.hard || "#2684FF"};
    box-shadow: none;
  }

  /* flexbox */
  display: flex;
  height: 100%;
  cursor: grab;
`;

const DraggableTableItem = ({
  item,
  isDragging,
  isGroupedOver,
  provided,
  name,
  columns,
  onRowClick,
  index,
  loading,
}) => (
  <Container
    colors={item?.author?.colors}
    href={item?.author?.url}
    isDragging={isDragging}
    isGroupedOver={isGroupedOver}
    name={name}
    ref={provided.innerRef}
    {...provided.draggableProps}
    {...provided.dragHandleProps}
  >
    <div
      className="data-table-container"
      style={{ marginBottom: 0, width: "100%", height: 26 }}
    >
      <Table fullwidth hoverable narrow>
        <Table.Body>
          <Table.Row onClick={() => onRowClick(item, index)}>
            {columns.map(({ accessor, width, id, Cell }) => {
              const [first, second] = accessor.split(".");
              return !Cell ? (
                <Table.Cell
                  className={loading ? "disabled-row" : ""}
                  key={id}
                  style={{ width: width || "20%" }}
                >
                  {second
                    ? item?.content?.[first]?.[second] || ""
                    : item?.content[accessor] || ""}
                </Table.Cell>
              ) : (
                <Table.Cell
                  className={loading ? "disabled-row" : ""}
                  key={id}
                  style={{ width: width || "20%" }}
                >
                  {Cell(item, index)}
                </Table.Cell>
              );
            })}
          </Table.Row>
        </Table.Body>
      </Table>
    </div>
  </Container>
);

DraggableTableItem.propTypes = {
  item: PropTypes.object,
  isDragging: PropTypes.bool,
  isGroupedOver: PropTypes.bool,
  provided: PropTypes.object,
  name: PropTypes.string.isRequired,
  columns: PropTypes.array.isRequired,
  onRowClick: PropTypes.func,
  index: PropTypes.number,
  loading: PropTypes.bool,
};

DraggableTableItem.defaultProps = {
  item: {},
  isDragging: false,
  isGroupedOver: false,
  provided: {},
  loading: false,
  onRowClick: () => null,
  index: null,
};

export default DraggableTableItem;
