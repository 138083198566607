import React from "react";
import PropTypes from "prop-types";
import { Label } from "rbx";

import DoctorBlockTimeListItem from "../DoctorBlockTimeListItem";

const DoctorBlockTimeList = ({
  blocks,
  disabled,
  onChange,
  onRemoveBlock,
  readOnly,
  isScheduling,
  onScheduleBlock,
}) => (
  <div>
    <div className="schedule-header schedule-grid">
      <Label>&nbsp;</Label>
      <Label>Time</Label>
      <Label>Duration</Label>
      <Label>Case 1</Label>
      <Label>Description 1</Label>
      <Label>Case 2</Label>
      <Label>Description 2</Label>
      <Label>Case 3</Label>
      <Label>Description 3</Label>
      <Label>Status</Label>
    </div>
    <div className="schedule-container">
      {blocks.map((item) => (
        <DoctorBlockTimeListItem
          block={item}
          disabled={disabled}
          isScheduling={isScheduling}
          key={[
            item.schedcode,
            item.casenbr1,
            item.casenbr2,
            item.CaseNbr3,
            item.new && "new",
          ]
            .filter(Boolean)
            .join("_")}
          readOnly={readOnly}
          onChange={onChange}
          onRemoveBlock={onRemoveBlock}
          onScheduleBlock={onScheduleBlock}
        />
      ))}
    </div>
  </div>
);

DoctorBlockTimeList.propTypes = {
  onChange: PropTypes.func,
  blocks: PropTypes.array.isRequired,
  disabled: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool,
  onRemoveBlock: PropTypes.func,
  onScheduleBlock: PropTypes.func,
  isScheduling: PropTypes.bool,
};

DoctorBlockTimeList.defaultProps = {
  isScheduling: false,
  readOnly: false,
  onScheduleBlock: () => {},
  onRemoveBlock: () => {},
  onChange: () => {},
};

export default DoctorBlockTimeList;
