import { gql } from "@apollo/client";

export const LIST_BUSINESS_LINES_QUERY = gql`
  query businessLines(
    $orderBy: [BusinessLineOrderByInput!]
    $where: BusinessLineWhereInput
    $take: Int
    $skip: Int
  ) {
    businessLines(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
      businesslineid
      name
      description
      useridadded
      useridedited
      dateadded
      dateedited
      dontVoucher
    }
    aggregateBusinessLine: aggregateBusinessLine(where: $where) {
      count {
        businesslineid
      }
    }
  }
`;

export const ALL_BUSINESS_LINES_QUERY = gql`
  query businessLines(
    $orderBy: [BusinessLineOrderByInput!]
    $where: BusinessLineWhereInput
    $take: Int
    $skip: Int
  ) {
    businessLines(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
      businesslineid
      name
      description
      useridadded
      useridedited
      dateadded
      dateedited
      dontVoucher
    }
  }
`;

export const SINGLE_BUSINESS_LINE_QUERY = gql`
  query businessLine($where: BusinessLineWhereUniqueInput!) {
    businessLine(where: $where) {
      businesslineid
      name
      description
      useridadded
      useridedited
      dateadded
      dateedited
      dontVoucher
    }
  }
`;

export const FIND_FIRST_BUSINESS_LINE_QUERY = gql`
  query FIND_FIRST_BUSINESS_LINE_QUERY($where: BusinessLineWhereInput!) {
    findFirstBusinessLine(where: $where) {
      businesslineid
      name
      description
      useridadded
      useridedited
      dateadded
      dateedited
      dontVoucher
    }
  }
`;
