import React from "react";

import { Switch, Route } from "react-router-dom";

import DoctorPage from "./DoctorPage";
import DoctorListPage from "./DoctorListPage";

const DoctorRouter = (props) => (
  <Switch>
    <Route path="/doctors/:doctorcode/:tab">
      <DoctorPage />
    </Route>
    <Route path="/doctors">
      <DoctorListPage />
    </Route>
  </Switch>
);

export default DoctorRouter;
