import React from "react";
import PropTypes from "prop-types";
import { Field, Box, Control, Label, Input } from "rbx";
import BooleanInput from "../../../../components/BooleanInput";
import ReferralStyledSubtitle from "../ReferralStyledSubtitle";

const InvestigativeServiceInstructions = ({ inputs, onChange }) => (
  <Box>
    <ReferralStyledSubtitle>
      Investigative Service Instructions
    </ReferralStyledSubtitle>
    <Field kind="group">
      <Control expanded>
        <Label>Report Needed By</Label>
        <Input
          name="DateReportNeeded"
          size="small"
          type="date"
          value={inputs.DateReportNeeded}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
      <Control expanded>
        <Label>Discovery End Date</Label>
        <Input
          name="DiscoveryEndDate"
          size="small"
          type="date"
          value={inputs.DiscoveryEndDate}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
    </Field>
    <Field>
      <Control expanded>
        <BooleanInput
          hideLabelTop
          label="Receive Report via Email"
          name="ReceiveReportViaEmail"
          value={inputs.ReceiveReportViaEmail}
          onChange={(name, value) => onChange(name, value)}
        />
      </Control>
      <Control expanded>
        <BooleanInput
          label="Rush Referral"
          name="RushReferral"
          value={inputs.RushReferral}
          onChange={(name, value) => onChange(name, value)}
        />
      </Control>
      <Control expanded>
        <BooleanInput
          label="Soft Tissue Claim"
          name="SoftTissueClaim"
          value={inputs.SoftTissueClaim}
          onChange={(name, value) => onChange(name, value)}
        />
      </Control>
      <Control expanded>
        <BooleanInput
          label="Referral sent to SIU Dept"
          name="ReferralSentToSIUDept"
          value={inputs.ReferralSentToSIUDept}
          onChange={(name, value) => onChange(name, value)}
        />
      </Control>
    </Field>
  </Box>
);
InvestigativeServiceInstructions.propTypes = {
  inputs: PropTypes.object,
  onChange: PropTypes.func,
};

InvestigativeServiceInstructions.defaultProps = {
  inputs: {},
  onChange: () => null,
};

export default InvestigativeServiceInstructions;
