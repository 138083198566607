import React from "react";
import PropTypes from "prop-types";
import { useApolloClient } from "@apollo/client";

import { Control, Label, Input } from "rbx";

import { ALL_ZIPS_QUERY } from "../../graphql";

const ZipInput = ({
  name,
  required,
  label,
  value,
  onChange,
  disabled,
  saveNotes,
  contentEditable,
  expanded,
  getValue,
  setValue,
  setCounty,
  placeholder,
}) => {
  const client = useApolloClient();

  const handleBlur = async ({ target: { value: zip } }) => {
    if (zip) {
      const { data: ZipsData } = await client.query({
        query: ALL_ZIPS_QUERY,
        variables: { where: { sZip: { equals: zip } }, take: 1 },
      });
      const zips = ZipsData?.zipCodes;
      if (zips && zips[0]) {
        onChange("county", setCounty(zips[0].sCountyName));
      }
    }
  };

  const handleChange = (e) => {
    const re = /^[0-9]+$/; // Only accept numbers 
    const newValue = e.target.value;
    if (newValue === "" || re.test(newValue)) {
      onChange(e.target.name, setValue(newValue));
    }
  };

  return (
    <Control expanded={expanded}>
      <Label>{label}</Label>
      <Input
        autoComplete="new"
        disabled={disabled}
        maxLength={5}
        name={name}
        placeholder={placeholder}
        required={required}
        size="small"
        type="text"
        value={getValue(value)}
        onBlur={handleBlur}
        onChange={handleChange}
      />
    </Control>
  );
};

ZipInput.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func,
  saveNotes: PropTypes.func,
  disabled: PropTypes.bool,
  contentEditable: PropTypes.bool,
  label: PropTypes.string,
  required: PropTypes.bool,
  expanded: PropTypes.bool,
  getValue: PropTypes.func,
  setValue: PropTypes.func,
  setCounty: PropTypes.func,
  placeholder: PropTypes.string,
};

ZipInput.defaultProps = {
  name: "zip",
  label: "PC/Zip",
  value: "",
  onChange: () => null,
  saveNotes: () => null,
  required: false,
  disabled: false,
  contentEditable: false,
  expanded: true,
  getValue: (x) => x,
  setValue: (x) => x,
  setCounty: (x) => x,
  placeholder: "",
};
export default ZipInput;
