import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { useMutation, useQuery, useApolloClient } from "@apollo/client";
import { Title, Button } from "rbx";
import { format } from "date-fns";
import { useAuth, useModal } from "../../../../context";
import ScheduleCaseForm from "../ScheduleCaseForm";
import Confirmation from "../../../../components/Confirmation";
import { useCaseDoctorLabel, useCaseStatusChange } from "../../../../hooks";

import {
  // FIND_FIRST_SERVICE_QUEUE_QUERY,
  SINGLE_DOCTOR_QUERY,
  SINGLE_CASE_QUERY,
  UPDATE_CASE_MUTATION,
  CREATE_CASE_HISTORY_MUTATION,
  ALL_CASE_HISTORY_QUERY,
  SINGLE_LOCATION_QUERY,
  UPDATE_DOCTOR_SCHEDULE_MUTATION,
  SINGLE_DOCTOR_SCHEDULE_QUERY,
  FIND_FIRST_BUSINESS_LINE_QUERY,
} from "../../../../graphql";
import {
  getDateEST,
  convertTimeZoneDataBase,
  customToast as toast,
  convertInputToVariables,
} from "../../../../utils";

const initialState = {
  ApptDate: "",
  Appttime: "",
  doctorspecialty: "",
};

const ScheduleCaseModal = ({
  casenbr,
  doctorcode,
  locationcode,
  schedcode,
  onComplete,
  prefillDoctor,
  doctorspecialty,
  appttime: initialApptTime,
}) => {
  const client = useApolloClient();
  const { state: authState } = useAuth();
  const [loading, setLoading] = useState(false);
  const { setModalOpen } = useModal();
  const history = useHistory();

  const [updateCaseItem] = useMutation(UPDATE_CASE_MUTATION);
  const [createCaseHistory] = useMutation(CREATE_CASE_HISTORY_MUTATION);
  const [updateDoctorSchedule] = useMutation(UPDATE_DOCTOR_SCHEDULE_MUTATION);

  const [inputs, setInputs] = useState({
    ...initialState,
    doctorcode,
    locationcode,
    doctorspecialty,
  });

  useEffect(() => {
    if (initialApptTime) {
      const dateStr = convertTimeZoneDataBase(initialApptTime).toISOString();
      setInputs((prev) => ({ ...prev, ApptDate: dateStr, Appttime: dateStr }));
    }
  }, [initialApptTime]);

  const { data: CaseItemData } = useQuery(SINGLE_CASE_QUERY, {
    variables: { where: { casenbr: parseInt(casenbr, 10) } },
  });

  const executeCaseStatusChange = useCaseStatusChange(casenbr);

  const handleDocumentPrinting = () => {
    history.push({
      pathname: `/cases/${casenbr}/documents`,
      state: {
        triggerGenerateModal: true,
        printApptLtrs: CaseItemData?.caseItem?.service?.PrintApptLetters,
      },
    });
  };

  const handleSave = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);

      const date = convertTimeZoneDataBase(getDateEST());
      const { data: DoctorData } = await client.query({
        query: SINGLE_DOCTOR_QUERY,
        variables: { where: { doctorcode: parseInt(inputs.doctorcode, 10) } },
      });

      const doctor = DoctorData?.doctor;
      const doctorName = doctor ? `${doctor.firstname} ${doctor.lastname}` : "";
      const apptDate = new Date(inputs.ApptDate);

      apptDate.setHours(0, 0, 0, 0);

      const [hours, minutes] = [
        new Date(inputs.Appttime).getUTCHours(),
        new Date(inputs.Appttime).getUTCMinutes(),
      ];

      const apptTime = new Date(inputs.ApptDate);
      apptTime.setHours(hours, minutes);

      await updateCaseItem({
        variables: {
          where: {
            casenbr: parseInt(casenbr, 10),
          },
          data: {
            ...convertInputToVariables(
              inputs,
              Object.keys(initialState),
              false
            ),
            doctor: {
              connect: {
                doctorcode: parseInt(inputs.doctorcode, 10),
              },
            },

            DoctorName: { set: doctorName },
            doctorLocation: {
              connect: {
                locationcode_doctorcode: {
                  locationcode: parseInt(inputs.locationcode, 10),
                  doctorcode: parseInt(inputs.doctorcode, 10),
                },
              },
            },
            location: {
              connect: {
                locationcode: parseInt(inputs.locationcode, 10),
              },
            },
            // Status: { connect: { statuscode: parseInt(nextStatus, 10) } },
            LastScheduledBy: { set: authState?.user?.userid },
            LastScheduledDate: { set: date },
            laststatuschg: { set: date },
            dateedited: { set: date },
            useridedited: { set: authState?.user?.userid },
            ApptDate: { set: convertTimeZoneDataBase(apptDate) },
            Appttime: { set: convertTimeZoneDataBase(apptTime) },
            schedcode: { set: schedcode || null },
          },
        },
        refetchQueries: [
          {
            query: SINGLE_CASE_QUERY,
            variables: { where: { casenbr: parseInt(casenbr, 10) } },
          },
        ],
      });

      const { data: locationData } = await client.query({
        query: SINGLE_LOCATION_QUERY,
        variables: {
          where: { locationcode: parseInt(inputs.locationcode, 10) },
        },
      });

      const locationName = locationData?.location?.location || "";

      createCaseHistory({
        variables: {
          data: {
            casenbr: parseInt(casenbr, 10),
            otherinfo: `Scheduled ${format(
              apptTime,
              "MM/dd/yyyy hh:mm a"
            )} ${doctorName} at ${locationName}`,
            eventdate: date,
            eventdesc: "Scheduled",
            userid: authState?.user?.userid,
            dateadded: date,
            useridedited: authState?.user?.userid,
            dateedited: date,
            type: "Scheduled",
          },
        },
        refetchQueries: [
          {
            query: ALL_CASE_HISTORY_QUERY,
            variables: {
              where: { casenbr: { equals: parseInt(casenbr, 10) } },
              orderBy: [{ eventdate: "desc" }],
            },
          },
        ],
      });

      if (schedcode) {
        const { data: DoctorScheduleData } = await client.query({
          query: SINGLE_DOCTOR_SCHEDULE_QUERY,
          variables: {
            where: {
              schedcode_locationcode: {
                schedcode: parseInt(schedcode, 10),
                locationcode: parseInt(locationcode, 10),
              },
            },
          },
        });
        const doctorSchedule = DoctorScheduleData?.doctorSchedule;

        if (doctorSchedule) {
          const casenbrKeys = ["casenbr1", "casenbr2", "CaseNbr3"];
          const descKeys = ["casenbr1desc", "casenbr2desc", "CaseNbr3Desc"];

          const casenbrKey = casenbrKeys.find((key) => !doctorSchedule[key]);
          const descKey = descKeys[casenbrKeys.indexOf(casenbrKey)];

          await updateDoctorSchedule({
            variables: {
              where: {
                schedcode_locationcode: {
                  schedcode: parseInt(schedcode, 10),
                  locationcode: parseInt(locationcode, 10),
                },
              },
              data: {
                [casenbrKey]: { set: parseInt(casenbr, 10) },
                [descKey]: {
                  set: [
                    CaseItemData?.caseItem?.examinee?.lastname,
                    CaseItemData?.caseItem?.examinee?.firstname,
                  ]
                    .filter(Boolean)
                    .join(", "),
                },
              },
            },
          });
        }
      }

      onComplete();
      toast.success("Case scheduled successfully.");

      const { data: FindFirstBusinessLineData } = await client.query({
        query: FIND_FIRST_BUSINESS_LINE_QUERY,
        variables: {
          where: {
            name: { equals: "IS" },
          },
        },
      });

      if (
        CaseItemData?.caseItem?.service?.LOB !==
          FindFirstBusinessLineData?.findFirstBusinessLine?.businesslineid &&
        CaseItemData?.caseItem?.service?.PrintApptLetters
      ) {
        setModalOpen(
          true,
          <Confirmation
            message="Case scheduled successfully. Would you like to print the appointment letters now?"
            onCancel={() => {
              setModalOpen(false);
              executeCaseStatusChange(2);
            }}
            onConfirm={() => {
              setModalOpen(false);
              handleDocumentPrinting();
            }}
          />
        );
      } else if (
        CaseItemData?.caseItem?.service?.LOB !==
          FindFirstBusinessLineData?.findFirstBusinessLine?.businesslineid &&
        !CaseItemData?.caseItem?.service?.PrintApptLetters
      ) {
        executeCaseStatusChange(79);
      }
    } catch (err) {
      toast.error("Error scheduling case.");
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (name, value) => {
    setInputs((prev) => ({ ...prev, [name]: value }));
  };

  const isDisabled = useMemo(
    () => Object.keys(inputs).some((key) => !inputs[key]),
    [inputs]
  );

  const doctorLabel = useCaseDoctorLabel(casenbr);

  return (
    <form id="schedule-case-form" onSubmit={handleSave}>
      <header className="page-head">
        <div className="page-head-start">
          <Title size={5}>Schedule Case</Title>
        </div>
        <div className="page-head-end">
          <Button.Group hasAddons>
            <Button
              disabled={loading}
              size="small"
              type="button"
              onClick={() => onComplete(false)}
            >
              <span>Cancel</span>
            </Button>
            <Button
              color="primary"
              disabled={isDisabled || loading}
              form="schedule-case-form"
              size="small"
              state={loading ? "loading" : ""}
              type="submit"
            >
              <span>Submit</span>
            </Button>
          </Button.Group>
        </div>
      </header>
      <hr />
      <ScheduleCaseForm
        casenbr={casenbr}
        disableDoctorSelect={prefillDoctor}
        disabled={loading}
        doctorLabel={doctorLabel}
        inputs={inputs}
        onChange={handleChange}
      />
      <hr />
    </form>
  );
};

ScheduleCaseModal.propTypes = {
  casenbr: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  doctorcode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  locationcode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  schedcode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onComplete: PropTypes.func,
  prefillDoctor: PropTypes.bool,
  doctorspecialty: PropTypes.string,
  appttime: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
};

ScheduleCaseModal.defaultProps = {
  casenbr: "",
  doctorcode: "",
  locationcode: "",
  onComplete: () => null,
  prefillDoctor: true,
  doctorspecialty: "",
  appttime: "",
  schedcode: "",
};
export default ScheduleCaseModal;
