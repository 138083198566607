const usePortalRefresh = () => {
  const targetWindow = window?.opener;

  const portalRefresh = (type, ids) => {
    if (targetWindow) {
      const finalIdString = Array.isArray(ids) ? ids.join(",") : ids;
      targetWindow.postMessage(`${type}:${finalIdString}`, "*");
    }
  };

  return { portalRefresh };
};

export default usePortalRefresh;
