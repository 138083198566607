import React, { Fragment } from "react";

import PropTypes from "prop-types";

import { Control, Label, Select } from "rbx";

import { useQuery } from "@apollo/client";
import { ALL_APOLLO_DOCUMENT_TYPES_QUERY } from "../../graphql";

const ApolloDocumentTypeSelect = ({
  value,
  name,
  label,
  size,
  onChange,
  disabled,
  getValue,
  setValue,
  required,
  showLabel,
}) => {
  const { data: documentTypes } = useQuery(ALL_APOLLO_DOCUMENT_TYPES_QUERY, {
    variables: { orderBy: { DocumentType: "asc" } },
  });

  const types = documentTypes?.findManyDocumentTypes;

  const handleChange = (e) => {
    onChange(
      e.target.name,
      setValue(e.target.value ? parseInt(e.target.value, 10) : "")
    );
  };

  return (
    <Control expanded>
      <Fragment>
        {showLabel && <Label>{label}</Label>}
        <Select.Container
          fullwidth
          size={size}
          state={Array.isArray(types) ? "" : "loading"}
        >
          <Select
            disabled={disabled}
            name={name}
            required={required}
            value={getValue(value)}
            onChange={handleChange}
          >
            <Select.Option value="" />
            {Array.isArray(types) &&
              types.map((type) => (
                <Select.Option
                  key={type.DocumentTypeID}
                  value={type.DocumentTypeID}
                >
                  {type.DocumentType}
                </Select.Option>
              ))}
          </Select>
        </Select.Container>
      </Fragment>
    </Control>
  );
};

ApolloDocumentTypeSelect.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.number,
    PropTypes.array,
  ]),
  name: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  getValue: PropTypes.func,
  setValue: PropTypes.func,
  required: PropTypes.bool,
  showLabel: PropTypes.bool,
};

ApolloDocumentTypeSelect.defaultProps = {
  value: "",
  name: "",
  label: "Document Type",
  size: "small",
  onChange: () => null,
  disabled: false,
  getValue: (x) => x,
  setValue: (x) => x,
  required: false,
  showLabel: true,
};

export default ApolloDocumentTypeSelect;
