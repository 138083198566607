/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { Title, Button } from "rbx";
import { useMutation } from "@apollo/client";

import { useModal, useAuth } from "../../../../context";
import {
  LIST_APOLLO_DOCUMENTS_QUERY,
  CREATE_APOLLO_DOCUMENT_MUTATION,
} from "../../../../graphql";
import { customToast as toast, getDateEST } from "../../../../utils";
import ClaimDocumentCreateForm from "../ClaimDocumentCreateForm";

const INITIAL_STATE = {
  files: [],
};

const ClaimDocumentCreateModal = ({ onComplete, ClaimID }) => {
  const { setModalOpen } = useModal();

  const { state: authState } = useAuth();

  const [inputs, setInputs] = useState(INITIAL_STATE);
  const [loading, setLoading] = useState(false);

  const [createDocument] = useMutation(CREATE_APOLLO_DOCUMENT_MUTATION);

  const handleChange = (name, value, id) => {
    if (name === "files") {
      setInputs((prev) => ({
        ...prev,
        [name]: value,
      }));
    } else {
      setInputs((prev) => ({
        ...prev,
        files: prev.files.map((file) =>
          file.id === id ? { ...file, [name]: value } : file
        ),
      }));
    }
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      await Promise.all(
        inputs.files.map(async (file, i) => {
          await createDocument({
            variables: {
              data: {
                ClaimID: parseInt(ClaimID, 10),
                DocumentLocationID: file.location,
                DocumentTypeID: file.type,
                DocumentDescription: file.description,
                DocumentName: file.file,
                CreatedDate: getDateEST(),
                CreatedBy: authState?.user?.userid,
                PublishOnWeb: false,
                Archive: false,
              },
            },
            refetchQueries: [
              {
                query: LIST_APOLLO_DOCUMENTS_QUERY,
                variables: {
                  where: { 
                    ClaimID: { 
                      equals: parseInt(ClaimID, 10),
                    }, 
                  },
                },
              },
            ],
          });
        })
      );
      toast.success(`Document attached successfully`);
      onComplete();
    } catch (err) {
      toast.error(`Error attaching Document`);
    } finally {
      setLoading(false);
    }
  };

  const isDisabled = !inputs.files.length 
    || inputs.files.some((file) => !file?.description?.length
      || !file?.type > 0 
      || !file?.location > 0
    );

  return (
    <form id="claim-document-form" onSubmit={handleSubmit}>
      <header className="page-head">
        <div className="page-head-start">
          <Title size={5}>Attach Document</Title>
        </div>
        <div className="page-head-end">
          <Button.Group hasAddons>
            <Button
              size="small"
              type="button"
              onClick={() => onComplete(false)}
            >
              <span>Cancel</span>
            </Button>
            <Button
              color="primary"
              disabled={isDisabled}
              form="claim-document-form"
              size="small"
              state={loading ? "loading" : ""}
              type="submit"
            >
              <span>Submit</span>
            </Button>
          </Button.Group>
        </div>
      </header>
      <hr />
      <ClaimDocumentCreateForm inputs={inputs} onChange={handleChange} />
      <hr />
    </form>
  );
};

ClaimDocumentCreateModal.propTypes = {
  onComplete: PropTypes.func,
  ClaimID: PropTypes.number,
};

ClaimDocumentCreateModal.defaultProps = {
  onComplete: () => null,
  ClaimID: null,
};

export default ClaimDocumentCreateModal;

// ToDo: Missing doc field 