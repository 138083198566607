import React from "react";

import { Switch, Route } from "react-router-dom";

import CompanyListPage from "./CompanyListPage";
import CompanyPage from "./CompanyPage";

const CompanyRouter = (props) => (
  <Switch>
    <Route path="/companies/:companyCode/:tab">
      <CompanyPage />
    </Route>
    <Route path="/companies">
      <CompanyListPage />
    </Route>
  </Switch>
);

export default CompanyRouter;
