import { useApolloClient } from "@apollo/client";
import { useCallback } from "react";
import { SAVE_CONFLICT_QUERY } from "../graphql";

export default function useSaveConflict({
  casenbr,
  lastuseridedited,
  lastdateedited,
}) {
  const client = useApolloClient();

  const checkForSaveConflict = useCallback(async () => {
    const {
      data: { caseItem },
    } = await client.query({
      query: SAVE_CONFLICT_QUERY,
      variables: { where: { casenbr: parseInt(casenbr, 10) } },
      fetchPolicy: "no-cache",
    });

    if (
      caseItem?.useridedited &&
      caseItem?.dateedited &&
      (caseItem.useridedited !== lastuseridedited ||
        caseItem.dateedited !== lastdateedited)
    ) {
      return {
        conflict: true,
        useridedited: caseItem?.useridedited,
        dateedited: caseItem?.dateedited,
      };
    }
    return {
      conflict: false,
      useridedited: lastuseridedited,
      dateedited: lastdateedited,
    };
  }, [casenbr, client, lastdateedited, lastuseridedited]);

  return checkForSaveConflict;
}
