import { gql } from "@apollo/client";

export const LIST_DOCUMENT_QUERY = gql`
  query documents(
    $take: Int
    $orderBy: [DocumentOrderByInput!]
    $skip: Int
    $where: DocumentWhereInput
    $distinct: [DocumentScalarFieldEnum!]
  ) {
    documents(
      take: $take
      orderBy: $orderBy
      skip: $skip
      where: $where
      distinct: $distinct
    ) {
      document
      description
      type
      sfilename
      documentname
      SeqNo
      status
      complete
      lob
      useridadded
      useridedited
      dateadded
      dateedited
      businessLine {
        businesslineid
        name
        description
      }
      outputFormat
    }
  }
`;

export const SINGLE_DOCUMENT_QUERY = gql`
  query document($where: DocumentWhereUniqueInput!) {
    document(where: $where) {
      document
      description
      type
      sfilename
      template
      SeqNo
      documentname
      useridadded
      useridedited
      dateadded
      dateedited
      status
      complete
      lob
      outputFormat
    }
  }
`;

export const FIND_FIRST_DOCUMENT_QUERY = gql`
  query findFirstDocument($where: DocumentWhereInput!) {
    findFirstDocument(where: $where) {
      document
      description
      type
      sfilename
      template
      SeqNo
      documentname
      useridadded
      useridedited
      dateadded
      dateedited
      status
      complete
      lob
      outputFormat
    }
  }
`;

export const ALL_DOCUMENTS_QUERY = gql`
  query documents(
    $take: Int
    $orderBy: [DocumentOrderByInput!]
    $skip: Int
    $where: DocumentWhereInput
    $distinct: [DocumentScalarFieldEnum!]
  ) {
    documents(
      take: $take
      orderBy: $orderBy
      skip: $skip
      where: $where
      distinct: $distinct
    ) {
      SeqNo
      document
      description
      type
      sfilename
      documentname
      useridadded
      useridedited
      dateadded
      dateedited
      status
      complete
      lob
      businessLine {
        businesslineid
        name
        description
      }
      outputFormat
    }
    aggregateDocument(where: $where) {
      count {
        _all
      }
    }
  }
`;

export const LIST_COMPANY_DEF_DOCUMENT_QUERY = gql`
  query companyDefDocuments(
    $take: Int
    $orderBy: [CompanyDefDocumentOrderByInput!]
    $skip: Int
    $where: CompanyDefDocumentWhereInput
  ) {
    companyDefDocuments(
      take: $take
      orderBy: $orderBy
      skip: $skip
      where: $where
    ) {
      companycode
      documentcode
      documentqueue
      dateadded
      useridadded
      dateedited
      useridedited
      order
      queue {
        statuscode
        statusdesc
      }
      document {
        document
        documentname
        description
        type
      }
    }
  }
`;

export const LIST_CASE_DEF_DOCUMENT_QUERY = gql`
  query caseDefDocuments(
    $take: Int
    $orderBy: [CaseDefDocumentOrderByInput!]
    $skip: Int
    $where: CaseDefDocumentWhereInput
  ) {
    caseDefDocuments(
      take: $take
      orderBy: $orderBy
      skip: $skip
      where: $where
    ) {
      casenbr
      documentcode
      documentqueue
      dateadded
      useridadded
      dateedited
      useridedited
      order
      queue {
        statuscode
        statusdesc
      }
      document {
        document
        documentname
        description
      }
    }
  }
`;

export const SINGLE_CASE_DOCUMENT_QUERY = gql`
  query caseDocument($where: CaseDocumentWhereUniqueInput!) {
    caseDocument(where: $where) {
      casenbr
      document
      type
      reporttype
      description
      sfilename
      PublishOnWeb
      seqno
    }
  }
`;

export const SINGLE_COMPANY_DEFAULT_DOCUMENT_QUERY = gql`
  query SINGLE_COMPANY_DEFAULT_DOCUMENT_QUERY(
    $where: CompanyDefDocumentWhereUniqueInput!
  ) {
    companyDefDocument(where: $where) {
      companycode
      documentcode
      documentqueue
    }
  }
`;

export const ALL_DOCUMENT_LOCATIONS_QUERY = gql`
  query documents(
    $take: Int
    $orderBy: [DocumentOrderByInput!]
    $skip: Int
    $where: DocumentWhereInput
    $distinct: [DocumentScalarFieldEnum!]
  ) {
    documents(
      take: $take
      orderBy: $orderBy
      skip: $skip
      where: $where
      distinct: $distinct
    ) {
      SeqNo
      document
      description
      type
      sfilename
      TemplateFormat
      documentname
      useridadded
      useridedited
      dateadded
      dateedited
    }
    aggregateDocument(where: $where) {
      count {
        _all
      }
    }
  }
`;

export const FIND_FIRST_CASE_DOCUMENT_QUERY = gql`
  query FIND_FIRST_CASE_DOCUMENT_QUERY($where: CaseDocumentWhereInput!) {
    findFirstCaseDocument(where: $where) {
      seqno
    }
  }
`;

export const LIST_CASE_DOCUMENT_QUERY = gql`
  query caseDocuments(
    $take: Int
    $orderBy: [CaseDocumentOrderByInput!]
    $skip: Int
    $where: CaseDocumentWhereInput
  ) {
    caseDocuments(take: $take, orderBy: $orderBy, skip: $skip, where: $where) {
      casenbr
      document
      type
      reporttype
      description
      sfilename
      dateadded
      useridadded
      PublishOnWeb
      WebSynchDate
      dateedited
      useridedited
      seqno
      PublishedTo
      apolloDocument {
        documentType {
          DocumentType
        }
        documentLocation {
          DocumentLocation
        }
      }
      CaseItem {
        casenbr
        dateadded
      }
    }
    aggregateCaseDocument(where: $where) {
      count {
        _all
      }
    }
  }
`;

export const ALL_CASE_DOCUMENTS_QUERY = gql`
  query ALL_CASE_DOCUMENTS_QUERY(
    $take: Int
    $orderBy: [CaseDocumentOrderByInput!]
    $skip: Int
    $where: CaseDocumentWhereInput
  ) {
    caseDocuments(take: $take, orderBy: $orderBy, skip: $skip, where: $where) {
      casenbr
      document
      type
      reporttype
      description
      sfilename
      dateadded
      useridadded
      PublishOnWeb
      WebSynchDate
      dateedited
      useridedited
      seqno
      PublishedTo
      CaseItem {
        casenbr
        dateadded
      }
    }
  }
`;
