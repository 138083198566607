/* eslint-disable import/prefer-default-export */
/* eslint-disable react/prop-types */
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Generic, Icon } from "rbx";

export const COLUMNS_USER_FUNCTIONS = (handleDelete, disabled) => [
  {
    Header: "",
    id: "Delete",
    Cell: ({ row }) => (
      <Generic
        disabled={disabled}
        style={{ textAlign: "center" }}
        type="button"
        onClick={() => !disabled && handleDelete(row.original)}
      >
        <Icon color="danger">
          <FontAwesomeIcon icon="times" />
        </Icon>
      </Generic>
    ),
  },
  {
    Header: "User Function",
    id: "functioncode",
    accessor: "UserFunction.functiondesc",
  },
];
