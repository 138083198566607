const DOCTOR_SPECIALTY_FIELDS = [
  {
    name: "#doctorSpecialties",
    type: "String",
    entity: "doctorSpecialty",
  },
  {
    name: "/doctorSpecialties",
    type: "String",
    entity: "doctorSpecialty",
  },
  { name: "specialtycode", type: "String", entity: "doctorSpecialty" },
  { name: "description", type: "String", entity: "doctorSpecialty" },
  { name: "dateadded", type: "DateTime", entity: "doctorSpecialty" },
  { name: "useridadded", type: "String", entity: "doctorSpecialty" },
  { name: "dateedited", type: "DateTime", entity: "doctorSpecialty" },
  { name: "useridedited", type: "String", entity: "doctorSpecialty" },
  { name: "PublishOnWeb", type: "Boolean", entity: "doctorSpecialty" },
  { name: "WebSynchDate", type: "DateTime", entity: "doctorSpecialty" },
  { name: "LOB", type: "Int", entity: "doctorSpecialty" },
];

export default DOCTOR_SPECIALTY_FIELDS;
