import { gql } from "@apollo/client";

// eslint-disable-next-line import/prefer-default-export
export const CORE_SERVICE_FRAGMENT = gql`
  fragment CoreServiceFragment on Services {
    ServiceID
    ClaimID
    ExternalServiceID
    ServiceTypeID
    ClientCode
    WorkID
    CreatedDate
    ParentServiceID
    Archive
    ClientName
    ServiceType {
      ServiceTypeID
      ServiceType
    }
  }
`;
