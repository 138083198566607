import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";

import { AsyncPaginate } from "react-select-async-paginate";

import { Label, Control } from "rbx";
import { useApolloClient, useLazyQuery } from "@apollo/client";

import { ALL_CASES_QUERY, SINGLE_CASE_QUERY } from "../../graphql";

const QUERY_LIMIT = 50;

const CaseSelect = ({
  showLabel,
  name,
  value: initialValue,
  label,
  onChange,
  disabled,
  required,
  getValue,
  setValue,
  showOnlyActives,
  where,
}) => {
  const client = useApolloClient();

  const [getCase, { data: getCaseData }] = useLazyQuery(SINGLE_CASE_QUERY);

  const [selected, setSelected] = useState(null);
  const [defaultOptions, setDefaultOptions] = useState([]);

  const getLabel = useCallback(
    (caseItem) =>
      [caseItem.casenbr, caseItem.Status?.statusdesc]
        .filter(Boolean)
        .join(" - "),
    []
  );
  const promiseOptions = async (inputValue, { length }) => {
    const { data: casesData } = await client.query({
      query: ALL_CASES_QUERY,
      variables: {
        take: QUERY_LIMIT,
        where: inputValue
          ? { ...where, casenbr: { equals: parseInt(inputValue, 10) } }
          : where,
        orderBy: [{ casenbr: "asc" }],
        skip: length,
      },
      fetchPolicy: "network-only",
    });
    const cases = casesData?.cases;

    const result = Array.isArray(cases)
      ? cases.map((caseItem) => ({
          value: caseItem.casenbr,
          label: getLabel(caseItem),
        }))
      : [];

    setDefaultOptions(result);
    return { options: result, hasMore: result.length === QUERY_LIMIT };
  };

  const handleChange = ({ value: newValue }) => {
    onChange(name, setValue(newValue));
  };

  useEffect(() => {
    if (initialValue) {
      getCase({
        variables: {
          where: { casenbr: parseInt(getValue(initialValue), 10) },
        },
      });
    } else {
      setSelected(null);
    }
  }, [initialValue, getCase, getValue]);

  useEffect(() => {
    const caseItem = getCaseData?.caseItem;
    if (caseItem) {
      setSelected({
        value: caseItem.casenbr,
        label: getLabel(caseItem),
      });
    }
  }, [getCaseData, getLabel]);

  useEffect(() => {
    if (selected) {
      const currOptions = [...defaultOptions];
      if (!currOptions.some((opt) => opt.value === selected.value)) {
        setDefaultOptions((prev) => [selected, ...prev]);
      }
    }
  }, [selected, defaultOptions]);

  const indicateRequired =
    required &&
    (!selected || selected === "" || selected === "0" || selected === 0);

  return (
    <Control expanded>
      {showLabel && <Label size="small">{label}</Label>}
      <AsyncPaginate
        cacheOptions
        debounceTimeout={300}
        defaultOptions={defaultOptions}
        isDisabled={disabled}
        loadOptions={promiseOptions}
        menuPlacement="auto"
        menuPortalTarget={document.body}
        placeholder="Start typing..."
        styles={{
          menuPortal: (provided) => ({
            ...provided,
            zIndex: 9999,
          }),
          indicatorsContainer: (provided) => ({
            ...provided,
            height: "inherit",
          }),
          indicatorSeparator: (provided) => ({
            ...provided,
            display: "none",
          }),
          container: (provided) => ({
            fontSize: 13,
          }),
          singleValue: (provided) => ({
            ...provided,
            top: "38%",
          }),
          control: (provided) => ({
            ...provided,
            minHeight: 30,
            height: 30,
            width: "100%",
            minWidth: 180,
            border: indicateRequired ? "1px #e63362 solid" : "",
            borderRadius: "4px",
            boxShadow: indicateRequired
              ? "0 0 0 0.125em rgba(230, 51, 98, 0.25)"
              : "",
            "&:hover": {
              borderColor: "hsl(0, 0%, 90%)",
              borderRadius: 4,
              cursor: disabled ? "not-allowed" : "pointer",
            },
          }),
          placeholder: (provided) => ({ ...provided, top: "38%" }),
          menu: (provided) => ({
            ...provided,
            fontSize: 12,
            zIndex: 999,
          }),
        }}
        value={selected}
        onChange={handleChange}
      />
    </Control>
  );
};

CaseSelect.propTypes = {
  showLabel: PropTypes.bool,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  getValue: PropTypes.func,
  setValue: PropTypes.func,
  showOnlyActives: PropTypes.bool,
  where: PropTypes.object,
};

CaseSelect.defaultProps = {
  name: "",
  label: "",
  value: "",
  onChange: () => null,
  showLabel: true,
  disabled: false,
  required: false,
  getValue: (x) => x,
  setValue: (x) => x,
  showOnlyActives: false,
  where: {},
};

export default CaseSelect;
