import { gql } from "@apollo/client";

// eslint-disable-next-line import/prefer-default-export
export const LIST_DOCTOR_DOCUMENTS_QUERY = gql`
  query doctorDocuments(
    $where: DoctorDocumentWhereInput
    $orderBy: [DoctorDocumentOrderByInput!]
    $take: Int
    $skip: Int
  ) {
    doctorDocuments(
      where: $where
      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      doctorcode
      recid
      type
      description
      expiredate
      pathfilename
      DateEdited
      UserIDEdited
      order
    }
    aggregateDoctorDocument(where: $where) {
      count {
        _all
      }
    }
  }
`;

export const SINGLE_DOCTOR_DOCUMENT_QUERY = gql`
  query doctorDocument($where: DoctorDocumentWhereUniqueInput!) {
    doctorDocument(where: $where) {
      doctorcode
      recid
      type
      description
      expiredate
      pathfilename
      DateEdited
      order
      UserIDEdited
    }
  }
`;
