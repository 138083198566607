import React from "react";
import PropTypes from "prop-types";
import { Control, Label, Select } from "rbx";
import { useQuery } from "@apollo/client";

import { LIST_RATS_BUSINESS_UNIT } from "../../graphql/ratsBusinessUnit";

const RatsBusinessUnitSelect = ({
  value,
  name,
  label,
  size,
  onChange,
  disabled,
  getValue,
  setValue,
  required,
  showLabel,
  loading,
  showBlank,
  where,
}) => {
  const {
    data: ratsBusinessUnitData,
    loading: ratsBusinessUnitDataLoading,
  } = useQuery(LIST_RATS_BUSINESS_UNIT, {
    variables: {
      orderBy: { BUdescription: "asc" },
      where,
    },
  });

  const businessUnits = ratsBusinessUnitData?.businessunits;

  const handleChange = (e) => {
    onChange(e.target.name, setValue(e.target.value));
  };

  const isLoading =
    !Array.isArray(businessUnits) || loading || ratsBusinessUnitDataLoading;

  return (
    <Control expanded>
      {showLabel && <Label>{label}</Label>}
      <Select.Container
        fullwidth
        size={size}
        state={isLoading ? "loading" : ""}
      >
        <Select
          disabled={disabled}
          name={name}
          required={required}
          value={getValue(value)}
          onChange={handleChange}
        >
          {showBlank && <Select.Option value="" />}
          {Array.isArray(businessUnits) &&
            businessUnits.map((bu) => (
              <Select.Option
                key={`businessunit-${bu.PK_BUid}`}
                value={bu.BUrefCode}
              >
                {bu.BUdescription}
              </Select.Option>
            ))}
        </Select>
      </Select.Container>
    </Control>
  );
};

RatsBusinessUnitSelect.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.number,
    PropTypes.array,
  ]),
  name: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  getValue: PropTypes.func,
  setValue: PropTypes.func,
  showLabel: PropTypes.bool,
  loading: PropTypes.bool,
  where: PropTypes.object,
  showBlank: PropTypes.bool,
};

RatsBusinessUnitSelect.defaultProps = {
  value: "",
  name: "",
  label: "",
  size: "small",
  onChange: () => null,
  disabled: false,
  getValue: (x) => x,
  setValue: (x) => x,
  required: false,
  showLabel: true,
  loading: false,
  where: {},
  showBlank: false,
};

export default RatsBusinessUnitSelect;
