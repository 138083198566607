import React, { useState, useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import { Title, Button } from "rbx";
import { format, parse, isValid } from "date-fns";

import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { useHistory } from "react-router-dom";
import DoctorBlockTimeForm from "../DoctorBlockTimeForm";
import DoctorBlockTimeList from "../DoctorBlockTimeList";

import { useAuth, useModal } from "../../../../context";
import {
  convertTimeZoneDataBase,
  getDateEST,
  customToast as toast,
  convertTimeZone,
  initializeArrayWithRange,
} from "../../../../utils";

import {
  SINGLE_DOCTOR_QUERY,
  SINGLE_LOCATION_QUERY,
  CREATE_MANY_DOCTOR_SCHEDULES_QUERY,
  ALL_DOCTOR_SCHEDULE_QUERY,
  DELETE_DOCTOR_SCHEDULE_MUTATION,
} from "../../../../graphql";

import Confirmation from "../../../../components/Confirmation";

const INITIAL_STATE = {
  duration: 60,
  status: "Open",
  date: format(new Date(), "yyyy-MM-dd"),
  startHours: "07",
  startMinutes: "00",
  startMeridiem: "AM",
  endHours: "12",
  endMinutes: "00",
  endMeridiem: "PM",
};

const DoctorBlockTimeModal = ({
  doctorcode,
  locationcode,
  inputs: initialState,
  onComplete,
}) => {
  const { setModalOpen } = useModal();
  const history = useHistory();

  const { state: authState } = useAuth();

  const [blocks, setBlocks] = useState([]);
  const [inputs, setInputs] = useState({ ...INITIAL_STATE });

  useEffect(() => {
    if (initialState) {
      setInputs((prev) => ({ ...prev, ...initialState }));
    }
  }, [initialState]);

  const { data: DoctorData } = useQuery(SINGLE_DOCTOR_QUERY, {
    variables: { where: { doctorcode: parseInt(doctorcode, 10) } },
  });

  const { data: LocationData } = useQuery(SINGLE_LOCATION_QUERY, {
    variables: { where: { locationcode: parseInt(locationcode, 10) } },
  });

  const location = LocationData?.location || {};
  const doctor = DoctorData?.doctor || {};

  const [
    getDoctorSchedule,
    { data: doctorSchedulesData, refetch },
  ] = useLazyQuery(ALL_DOCTOR_SCHEDULE_QUERY, {
    fetchPolicy: "cache-and-network",
  });

  /**
   * Mutations
   */
  const [
    createManyDoctorSchedules,
    { loading: CreateManyDoctorSchedulesLoading },
  ] = useMutation(CREATE_MANY_DOCTOR_SCHEDULES_QUERY);

  const [deleteDoctorSchedule] = useMutation(DELETE_DOCTOR_SCHEDULE_MUTATION);

  const startDate = useMemo(
    () =>
      parse(
        `${inputs.startHours}:${inputs.startMinutes} ${inputs.startMeridiem}`,
        "hh:mm a",
        new Date(inputs.date.split("-").map((x) => parseInt(x, 10)))
      ),
    [inputs.date, inputs.startHours, inputs.startMeridiem, inputs.startMinutes]
  );

  const endDate = useMemo(
    () =>
      parse(
        `${inputs.endHours}:${inputs.endMinutes} ${inputs.endMeridiem}`,
        "hh:mm a",
        new Date(inputs.date.split("-").map((x) => parseInt(x, 10)))
      ),
    [inputs.date, inputs.endHours, inputs.endMeridiem, inputs.endMinutes]
  );

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const date = convertTimeZoneDataBase(getDateEST());

      const input = blocks
        .filter((block) => !!block.new)
        .map((block) => ({
          doctorcode: parseInt(doctorcode, 10),
          locationcode: parseInt(locationcode, 10),
          date,
          status: inputs.status,
          starttime: convertTimeZoneDataBase(block.starttime),
          duration: parseInt(inputs.duration, 10),
          casenbr1desc: block.casenbr1desc,
          casenbr2desc: block.casenbr2desc,
          CaseNbr3Desc: block.CaseNbr3Desc,
          useridadded: authState?.user?.userid,
          dateadded: date,
          useridedited: authState?.user?.userid,
          dateedited: date,
        }));

      await createManyDoctorSchedules({
        variables: {
          input,
        },
        refetchQueries: [
          {
            query: ALL_DOCTOR_SCHEDULE_QUERY,
            variables: {
              where: {
                doctorcode: { equals: parseInt(doctorcode, 10) },
                locationcode: { equals: parseInt(locationcode, 10) },
                starttime: {
                  gte: convertTimeZoneDataBase(startDate),
                  lte: convertTimeZoneDataBase(endDate),
                },
              },
            },
          },
        ],
      });
      toast.success("Doctor Schedule Block created successfully");
      onComplete(false);
    } catch (err) {
      toast.error(
        "Whoops! There was an error creating this Doctor Schedule Block. Please contact support."
      );
    }
  };

  useEffect(() => {
    if (isValid(startDate) && isValid(endDate)) {
      getDoctorSchedule({
        variables: {
          where: {
            doctorcode: { equals: parseInt(doctorcode, 10) },
            locationcode: { equals: parseInt(locationcode, 10) },
            starttime: {
              gte: convertTimeZoneDataBase(startDate),
              lte: convertTimeZoneDataBase(endDate),
            },
          },
        },
      });
    }
  }, [getDoctorSchedule, doctorcode, locationcode, startDate, endDate]);

  const doctorBlocks = useMemo(
    () =>
      doctorSchedulesData?.doctorSchedules?.length
        ? doctorSchedulesData?.doctorSchedules.map((schedule) => ({
            ...schedule,
            starttime: convertTimeZone(schedule.starttime),
            new: false,
          }))
        : [],
    [doctorSchedulesData?.doctorSchedules]
  );

  useEffect(() => {
    if (isValid(startDate) && isValid(endDate)) {
      const newBlocks = initializeArrayWithRange(
        endDate.getTime(),
        startDate.getTime(),
        parseInt(inputs.duration, 10) * 6e4
      ).reduce((acc, curr, i) => {
        const desc =
          inputs.status === "Hold" || inputs.status === "Off"
            ? inputs.status
            : "";
        return [
          ...acc,
          {
            schedcode: i + 1,
            status: inputs.status,
            starttime: new Date(curr),
            duration: inputs.duration,
            new: true,
            casenbr1desc: desc,
            casenbr2desc: desc,
            CaseNbr3Desc: desc,
          },
        ];
      }, []);

      setBlocks((prev) =>
        [...prev.filter((block) => !block.new), ...newBlocks]
          .sort((a, b) => new Date(b.starttime) - new Date(a.starttime))
          .reverse()
      );
    }
  }, [inputs.duration, inputs.status, startDate, endDate]);

  useEffect(() => {
    if (doctorBlocks) {
      setBlocks((prev) =>
        [...prev.filter((block) => block.new), ...doctorBlocks]
          .sort((a, b) => new Date(b.starttime) - new Date(a.starttime))
          .reverse()
      );
    }
  }, [doctorBlocks]);

  const isLoading = useMemo(() => CreateManyDoctorSchedulesLoading, [
    CreateManyDoctorSchedulesLoading,
  ]);

  const isDisabled = useMemo(
    () =>
      isLoading ||
      startDate > endDate ||
      !blocks.filter((block) => !!block.new).length,
    [blocks, endDate, isLoading, startDate]
  );

  /**
   * Change Handlers
   */

  const handleChange = (name, value) => {
    setInputs((prev) => ({ ...prev, [name]: value }));
  };

  const handleChangeBlock = (name, value, schedcode) => {
    setBlocks((prev) =>
      prev.map((item) =>
        item.schedcode === schedcode ? { ...item, [name]: value } : item
      )
    );
  };

  const handleRemoveBlock = ({
    schedcode,
    casenbr1,
    casenbr2,
    CaseNbr3,
    new: isNewBlock,
  }) => {
    if (!isNewBlock) {
      const performUpdate = async () => {
        try {
          await deleteDoctorSchedule({
            variables: {
              where: {
                schedcode_locationcode: {
                  schedcode: parseInt(schedcode, 10),
                  locationcode: parseInt(locationcode, 10),
                },
              },
            },
            refetchQueries: [
              {
                query: ALL_DOCTOR_SCHEDULE_QUERY,
                variables: {
                  where: {
                    doctorcode: { equals: parseInt(doctorcode, 10) },
                    locationcode: { equals: parseInt(locationcode, 10) },
                    starttime: {
                      gte: convertTimeZoneDataBase(startDate),
                      lte: convertTimeZoneDataBase(endDate),
                    },
                  },
                },
              },
            ],
          });
          toast.success("Block deleted successfully.");
        } catch (err) {
          toast.error("Error deleting this block. Please contact support.");
        }
      };

      if (!casenbr1 && !casenbr2 && !CaseNbr3) {
        setModalOpen(
          true,
          <Confirmation
            message="Are you sure you want to delete this block?"
            onCancel={() => {
              setModalOpen(
                true,
                <DoctorBlockTimeModal
                  doctorcode={doctorcode}
                  inputs={inputs}
                  locationcode={locationcode}
                  onComplete={onComplete}
                />
              );
            }}
            onConfirm={() => {
              performUpdate();
              setModalOpen(
                true,
                <DoctorBlockTimeModal
                  doctorcode={doctorcode}
                  inputs={inputs}
                  locationcode={locationcode}
                  onComplete={onComplete}
                />
              );
            }}
          />
        );
      } else {
        setModalOpen(
          true,
          <Confirmation
            message="This block as already been scheduled and cannot be removed until associated appointments are rescheduled. Would you like to reschedule now?"
            onCancel={() => {
              setModalOpen(
                true,
                <DoctorBlockTimeModal
                  doctorcode={doctorcode}
                  inputs={inputs}
                  locationcode={locationcode}
                  onComplete={onComplete}
                />
              );
            }}
            onConfirm={() => {
              const casenbrToReschedule = [casenbr1, casenbr2, CaseNbr3].find(
                (x) => !!x
              );
              history.push(`/cases/${casenbrToReschedule}/scheduling`);
              setModalOpen(false, "");
            }}
          />
        );
      }
    } else {
      setBlocks((prev) =>
        prev.filter((block) => block.schedcode !== schedcode)
      );
    }
  };

  const handleReset = () => {
    setInputs({ ...INITIAL_STATE });
    if (typeof refetch === "function") {
      refetch();
    }
  };

  return (
    <div className="block-time-modal-container">
      <form id="doctor-block-time-modal" onSubmit={handleSubmit}>
        <header className="page-head">
          <div className="page-head-start">
            <Title size={5}>
              {[doctor?.firstname, doctor?.lastname, "at", location?.location]
                .filter(Boolean)
                .join(" ")}
            </Title>
          </div>
          <div className="page-head-end">
            <Button.Group hasAddons>
              <Button
                disabled={isDisabled}
                size="small"
                type="button"
                onClick={() => onComplete(false)}
              >
                <span>Cancel</span>
              </Button>
              <Button
                color="primary"
                disabled={isDisabled}
                form="doctor-block-time-modal"
                size="small"
                state={isLoading ? "loading" : ""}
                type="submit"
              >
                <span>Submit</span>
              </Button>
            </Button.Group>
          </div>
        </header>
        <hr />
        <DoctorBlockTimeForm
          disabled={isLoading}
          inputs={inputs}
          onChange={handleChange}
          onReset={handleReset}
        />
      </form>
      <DoctorBlockTimeList
        blocks={blocks}
        disabled={isLoading}
        onChange={handleChangeBlock}
        onRemoveBlock={handleRemoveBlock}
      />
      <hr />
    </div>
  );
};

DoctorBlockTimeModal.propTypes = {
  onComplete: PropTypes.func.isRequired,
  locationcode: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  doctorcode: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  inputs: PropTypes.object,
};

DoctorBlockTimeModal.defaultProps = {
  inputs: undefined,
};

export default DoctorBlockTimeModal;
