import { TableCell } from "../../components/DataTable";

export const DEFAULT_COLUMNS = [
  {
    Header: "Cases",
    accessor: "caseCount",
    id: "caseCount",
    Cell: TableCell.Number,
  },
  {
    Header: "Rush",
    accessor: "rushCount",
    id: "rushCount",
    Cell: TableCell.Number,
  },
  {
    Header: "Status",
    accessor: "statusdesc",
    id: "status",
  },
];

export default DEFAULT_COLUMNS;
