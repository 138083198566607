import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";

import { useMutation, useQuery } from "@apollo/client";
import { Title, Generic, Button } from "rbx";
import { toast } from "react-toastify";

import { useAuth } from "../../../../context";
import RescheduleCaseForm from "../RescheduleCaseForm";
import { getDateEST, convertTimeZoneDataBase } from "../../../../utils";

import {
  SINGLE_CASE_QUERY,
  ALL_CASE_HISTORY_QUERY,
  RESCHEDULE_CASE_MUTATION,
} from "../../../../graphql";

const initialState = {
  reason: "",
};

const RescheduleCaseModal = ({ casenbr, isLateCancel, onComplete }) => {
  const { state: authState } = useAuth();

  const { data: CaseItemData } = useQuery(SINGLE_CASE_QUERY, {
    variables: { where: { casenbr: parseInt(casenbr, 10) } },
  });

  const [rescheduleCase, { loading: RescheduleCaseLoading }] = useMutation(
    RESCHEDULE_CASE_MUTATION
  );

  const [inputs, setInputs] = useState({
    ...initialState,
  });

  const handleSave = async (e) => {
    try {
      e.preventDefault();

      const date = convertTimeZoneDataBase(getDateEST());

      await rescheduleCase({
        variables: {
          input: {
            casenbr: parseInt(casenbr, 10),
            reason: inputs.reason,
            isLateCancel,
            userid: authState?.user?.userid,
            date,
          },
        },
        refetchQueries: [
          {
            query: SINGLE_CASE_QUERY,
            variables: { where: { casenbr: parseInt(casenbr, 10) } },
          },
          {
            query: ALL_CASE_HISTORY_QUERY,
            variables: {
              where: { casenbr: { equals: parseInt(casenbr, 10) } },
              orderBy: [{ eventdate: "desc" }],
            },
          },
        ],
      });
      onComplete();
      toast.success(`Case ready to be rescheduled.`);
    } catch (err) {
      toast.error(`Error rescheduling case.`);
    }
  };

  const handleChange = (name, value) => {
    setInputs((prev) => ({ ...prev, [name]: value }));
  };

  const isDisabled = useMemo(
    () =>
      Object.keys(inputs).some((key) => !inputs[key].length) ||
      !CaseItemData?.caseItem,
    [CaseItemData?.caseItem, inputs]
  );

  return (
    <form id="reschedule-case-form" onSubmit={handleSave}>
      <header className="page-head">
        <div className="page-head-start">
          <Title size={5}>Reschedule Case</Title>
        </div>
        <div className="page-head-end">
          <Button.Group hasAddons>
            <Button
              disabled={RescheduleCaseLoading}
              size="small"
              type="button"
              onClick={() => onComplete(false)}
            >
              <span>Cancel</span>
            </Button>
            <Button
              color="primary"
              disabled={isDisabled || RescheduleCaseLoading}
              form="reschedule-case-form"
              size="small"
              state={RescheduleCaseLoading ? "loading" : ""}
              type="submit"
            >
              <span>Submit</span>
            </Button>
          </Button.Group>
        </div>
      </header>
      <hr />
      <RescheduleCaseForm
        disabled={RescheduleCaseLoading}
        inputs={inputs}
        onChange={handleChange}
      />
      <hr />
      {isLateCancel && (
        <Generic textColor="danger" textSize={6}>
          This reschedule is a late reschedule and may be subject to a
          cancellation fee.
        </Generic>
      )}
    </form>
  );
};

RescheduleCaseModal.propTypes = {
  casenbr: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isLateCancel: PropTypes.bool,
  onComplete: PropTypes.func,
};

RescheduleCaseModal.defaultProps = {
  casenbr: "",
  isLateCancel: false,
  onComplete: () => null,
};
export default RescheduleCaseModal;
