import React, { useState } from "react";
import PropTypes from "prop-types";
import { Field, Control, Input, Button, Icon } from "rbx";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DataTable from "../../../components/DataTable";
import COLUMNS from "./columns";
import { debounce } from "../../../utils";
import { useModal } from "../../../context";
import { LIST_CLIENTS_QUERY } from "../../../graphql/clients";
import CompanyClientModal from "../components/CompanyClientModal";

const Clients = ({ companycode, companyIntName }) => {
  const { setModalOpen } = useModal();

  const [where, setWhere] = useState({
    companycode: { equals: parseInt(companycode, 10) },
  });
  const history = useHistory();

  const handleRowClick = ({ clientcode }) => {
    history.push(`/clients/${clientcode}/profile`);
  };

  return (
    <div>
      <header className="page-head">
        <div className="page-head-start">
          <Field kind="group">
            <Control>
              <Button
                color="primary"
                size="small"
                onClick={() =>
                  setModalOpen(
                    true,
                    <CompanyClientModal
                      companycode={companycode}
                      onComplete={() => {
                        setModalOpen(false, "");
                      }}
                    />
                  )
                }
              >
                <Icon>
                  <FontAwesomeIcon icon="plus" />
                </Icon>
                <span>Add</span>
              </Button>
            </Control>
            <Control>
              <Input
                autoComplete="new"
                placeholder="Search by Last Name"
                size="small"
                onChange={debounce(({ target: { value } }) => {
                  setWhere({ ...where, lastname: { startsWith: value } });
                }, 500)}
              />
            </Control>
          </Field>
        </div>
      </header>
      <DataTable
        aggregateKey="clientcode"
        aggregateName="aggregateClient"
        columns={COLUMNS}
        fetchPolicy="network-only"
        name="clients"
        orderBy={[
          { id: "lastname", desc: false },
          { id: "firstname", desc: false },
        ]}
        query={LIST_CLIENTS_QUERY}
        where={where}
        onRowClick={handleRowClick}
      />
    </div>
  );
};

Clients.propTypes = {
  companycode: PropTypes.string,
  companyIntName: PropTypes.string,
};

Clients.defaultProps = {
  companycode: "",
  companyIntName: "",
};

export default Clients;
