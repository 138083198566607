import React from "react";
import PropTypes from "prop-types";
import { Field, Control, Input, Label } from "rbx";
import ReportTypeSelect from "../../../../components/ReportTypeSelect";

const CaseReportForm = ({ disabled, inputs, onChange }) => (
  <div>
    <Field kind="group">
      <Control expanded>
        <Label>Name</Label>
        <Input
          autoComplete="new"
          disabled={disabled}
          name="sfilename"
          size="small"
          type="text"
          value={inputs.sfilename}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
      <Control expanded>
        <Label>Description</Label>
        <Input
          autoComplete="new"
          disabled={disabled}
          name="description"
          size="small"
          type="text"
          value={inputs.description}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
      <ReportTypeSelect
        disabled={disabled}
        name="reporttype"
        value={inputs.reporttype}
        onChange={onChange}
      />
    </Field>
  </div>
);

CaseReportForm.propTypes = {
  inputs: PropTypes.object,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};
CaseReportForm.defaultProps = {
  inputs: {},
  disabled: false,
  onChange: () => null,
};
export default CaseReportForm;
