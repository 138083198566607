import React from "react";
import PropTypes from "prop-types";
import { Icon, List } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from "../../context";

import "./UserActionButton.scss";

const UserActionButton = ({ onLogout, onChangePassword, onReleaseNotes }) => {
  const { state: authState } = useAuth();
  return (
    <div className="sidebar-menu-item bottom user-actions-container">
      <Icon size="large">
        <FontAwesomeIcon icon="sign-out-alt" />
      </Icon>
      <span>{authState?.user?.userid || "Logout"}</span>
      <div className="user-actions-list">
        <div className="user-actions-list-item">
          <List.Item onClick={onChangePassword}>Change Password</List.Item>
        </div>
        <div className="user-actions-list-item">
          <List.Item onClick={onLogout}>Logout</List.Item>
        </div>
        <div className="user-actions-list-item">
          <List.Item onClick={onReleaseNotes}>Release Notes</List.Item>
        </div>
      </div>
    </div>
  );
};

UserActionButton.propTypes = {
  onLogout: PropTypes.func,
  onChangePassword: PropTypes.func,
  onReleaseNotes: PropTypes.func,
};
UserActionButton.defaultProps = {
  onLogout: () => null,
  onChangePassword: () => null,
  onReleaseNotes: () => null,
};

export default UserActionButton;
