import React from "react";

import { Switch, Route } from "react-router-dom";
import CaseTrackerPage from "./CaseTrackerPage";

import "./CaseTracker.scss";

const CaseTrackerRouter = (props) => (
  <Switch>
    <Route path="/">
      <CaseTrackerPage />
    </Route>
  </Switch>
);

export default CaseTrackerRouter;
