import React from "react";
import PropTypes from "prop-types";
import { Column, Control, Input, Label, Button } from "rbx";

import StateSelect from "../../../components/StateSelect";
import PhoneNumberInput from "../../../components/PhoneNumberInput";

const BillingInformation = ({
  client,
  onChange,
  disabled,
  setInputs,
  onSubmit,
}) => {
  const chargeFromClient = () => {
    setInputs((prev) => ({
      ...prev,
      billattn: `${client.firstname} ${client.lastname}`,
      billaddr1: client.addr1,
      billaddr2: client.addr2,
      billcity: client.city,
      billstate: client.state,
      billzip: client.zip,
      billfax: client.fax,
    }));
  };
  return (
    <form id="edit-client-form" onSubmit={onSubmit}>
      <Column.Group style={{ justifyContent: "center" }}>
        <Column
          desktop={{ size: 6 }}
          mobile={{ size: 12 }}
          tablet={{ size: 12 }}
        >
          <div className="grid-2" style={{ marginBottom: 10 }}>
            <Control>
              <Label>Billing Attention</Label>
              <Input
                autoComplete="new"
                disabled={disabled}
                maxLength={70}
                name="billattn"
                size="small"
                type="text"
                value={client.billattn}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
            </Control>
            <Control style={{ paddingTop: 25 }}>
              <Button
                disabled={disabled}
                size="small"
                type="button"
                onClick={chargeFromClient}
              >
                Copy from Client
              </Button>
            </Control>
          </div>
          <div className="grid-2 sm-grid-4" style={{ marginBottom: 10 }}>
            <Control>
              <Label>Address 1</Label>
              <Input
                autoComplete="new"
                disabled={disabled}
                maxLength={50}
                name="billaddr1"
                size="small"
                type="text"
                value={client.billaddr1}
                onChange={(e) => onChange(e.target.name, e.target.value)}                
              />
            </Control>
            <Control>
              <Label>Address 2</Label>
              <Input
                autoComplete="new"
                disabled={disabled}
                maxLength={50}
                name="billaddr2"
                size="small"
                type="text"
                value={client.billaddr2}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
            </Control>
          </div>
          <div className="grid-4 sm-grid-4">
            <Control>
              <Label>City</Label>
              <Input
                autoComplete="new"
                disabled={disabled}
                maxLength={50}
                name="billcity"
                size="small"
                type="text"
                value={client.billcity}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
            </Control>
            <StateSelect
              required
              disabled={disabled}
              id="Statecode"
              label="State/Province"
              name="billstate"
              value={client.billstate}
              onChange={onChange}
            />
            <Control>
              <Label>Zip/Postal Code</Label>
              <Input
                autoComplete="new"
                disabled={disabled}
                maxLength={10}
                name="billzip"
                size="small"
                type="text"
                value={client.billzip}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
            </Control>
            <Control>
              <Label>Fax</Label>
              <PhoneNumberInput
                disabled={disabled}
                name="billfax"
                value={client.billfax}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
            </Control>
          </div>
        </Column>
        <Column>
          <Control>
            <Label>Accounts Receivable System Client Number</Label>
            <Input
              autoComplete="new"
              disabled={disabled}
              maxLength={10}
              name="clientnbrold"
              size="small"
              type="text"
              value={client.clientnbrold}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </Control>
        </Column>
      </Column.Group>
    </form>
  );
};

BillingInformation.propTypes = {
  client: PropTypes.object,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  setInputs: PropTypes.func,
  onSubmit: PropTypes.func,
};

BillingInformation.defaultProps = {
  client: {},
  onChange: (e) => e,
  disabled: false,
  setInputs: (e) => e,
  onSubmit: (e) => e,
};

export default BillingInformation;
