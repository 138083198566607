/* eslint-disable react/prop-types */
/* eslint-disable-next-line react/prop-types */
import React from "react";
import { format } from "date-fns";

import { convertTimeZone } from "../../../../utils";

const COLUMNS = [
  {
    Header: "Code",
    id: "issuecode",
    accessor: "issuecode",
  },
  {
    Header: "Description",
    id: "description",
    accessor: "description",
  },
  {
    Header: "Instruction",
    id: "instruction",
    accessor: "instruction",
    Cell: ({ cell }) =>
      cell?.value ? (
        <p
          style={{
            display: "inline",
            whiteSpace: "pre-wrap",
            position: "relative",
            lineHeight: 1,
          }}
        >
          {cell.value}
        </p>
      ) : (
        ""
      ),
  },
  {
    Header: "Status",
    id: "status",
    accessor: "status",
  },
  {
    Header: "Edited",
    id: "dateedited",
    accessor: "dateedited",
    Cell: ({ cell }) =>
      cell?.value
        ? format(convertTimeZone(cell.value), "MM/dd/yyyy hh:mm a")
        : "",
  },
  {
    Header: "By",
    id: "useridedited",
    accessor: "useridedited",
  },
];

export default COLUMNS;
