import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import ReactSelectAsync from "react-select/async";
import { Label, Control } from "rbx";
import { useQuery } from "@apollo/client";
import { ALL_OFFICES_QUERY } from "../../graphql";

const OfficeMultiSelect = ({
  name,
  value,
  label,
  onChange,
  fullWidth,
  disabled,
  readOnly,
  required,
  size,
  getValue,
  allowSelectAll,
  where,
}) => {
  const { data: officeData } = useQuery(ALL_OFFICES_QUERY, {
    variables: { where, orderBy: { description: "asc" } },
  });

  const offices = useMemo(
    () =>
      [
        allowSelectAll && {
          value: "*",
          name,
          label: "Select All",
        },
        ...(officeData?.offices?.map((item) => ({
          value: item.officecode,
          name,
          label: item.description,
        })) || []),
      ].filter(Boolean),
    [allowSelectAll, name, officeData?.offices]
  );

  const loadingOptions = useCallback(
    async (inputValue = "") =>
      new Promise((resolve, reject) => {
        try {
          if (Array.isArray(offices) && offices.length > 0) {
            const arrayFiltered = offices.filter((i) =>
              i.label.toLowerCase().includes(inputValue?.toLowerCase() || "")
            );
            resolve(arrayFiltered);
          }
        } catch (error) {
          reject(error);
        }
      }),
    [offices]
  );

  const interceptOnChange = (newSelectedOptions) => {
    const selectAllIsSelected = !!newSelectedOptions.find(
      (o) => o.value === "*"
    );

    onChange(
      name,
      selectAllIsSelected
        ? offices.filter((x) => x.value !== "*")
        : newSelectedOptions
    );
  };

  return (
    <Control>
      <Label>{label}</Label>
      <ReactSelectAsync
        cacheOptions
        isMulti
        defaultOptions={offices}
        isDisabled={disabled}
        isLoading={!Array.isArray(offices)}
        isSearchable={!readOnly}
        loadOptions={loadingOptions}
        menuIsOpen={readOnly ? false : undefined}
        menuPlacement="auto"
        menuPortalTarget={document.body}
        placeholder="Start typing..."
        required={required}
        styles={{
          menuPortal: (provided) => ({
            ...provided,
            zIndex: 9999,
          }),
          indicatorsContainer: (provided) => ({
            ...provided,
            height: "inherit",
          }),
          indicatorSeparator: (provided) => ({
            ...provided,
            display: "none",
          }),
          container: (provided) => ({
            fontSize: 13,
          }),
          singleValue: (provided) => ({
            ...provided,
            top: "38%",
            color: "black",
          }),
          control: (provided) => ({
            ...provided,
            width: "100%",
            minWidth: 180,
            minHeight: 30,
            height:
              Array.isArray(getValue(value)) && value.length > 0 ? "auto" : 30,
            border:
              required && Array.isArray(getValue(value)) && value.length === 0
                ? "1px #e63362 solid"
                : "",
            borderRadius: "4px",
            boxShadow:
              required && Array.isArray(getValue(value)) && value.length === 0
                ? "0 0 0 0.125em rgba(230, 51, 98, 0.25)"
                : "",
            "&:hover": {
              borderColor: "hsl(0, 0%, 90%)",
              borderRadius: 4,
              cursor: disabled ? "not-allowed" : "pointer",
            },
          }),
          placeholder: (provided) => ({ ...provided, top: "38%" }),
          menu: (provided) => ({
            ...provided,
            fontSize: 12,
            zIndex: 999,
          }),
        }}
        value={
          Array.isArray(offices) &&
          offices.filter((x) =>
            getValue(value).find((i) => i.value === x.value)
          )
        }
        onChange={
          (values) => {
            interceptOnChange(values);
          }
          // onChange
        }
      />
    </Control>
  );
};

OfficeMultiSelect.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  where: PropTypes.object,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.number,
    PropTypes.array,
  ]),
  onChange: PropTypes.func,
  size: PropTypes.string,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  getValue: PropTypes.func,
  allowSelectAll: PropTypes.bool,
};

OfficeMultiSelect.defaultProps = {
  label: "Offices",
  size: "small",
  where: {},
  name: "",
  value: "",
  readOnly: false,
  getValue: (x) => x,
  allowSelectAll: false,
  onChange: () => null,
  fullWidth: false,
  disabled: false,
  required: false,
};

export default OfficeMultiSelect;
