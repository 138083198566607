import { gql } from "@apollo/client";

export const CREATE_APOLLO_NOTE_MUTATION = gql`
  mutation CREATE_APOLLO_NOTE_MUTATION($data: ApolloNoteInput!) {
    createApolloNote(data: $data) {
      NoteID
      ClaimID
      NoteTypeID
    }
  }
`;

export const DELETE_APOLLO_NOTE_MUTATION = gql`
  mutation DELETE_APOLLO_NOTE_MUTATION($where: NotesWhereUniqueInput!) {
    deleteNotes(where: $where) {
      NoteID
    }
  }
`;
