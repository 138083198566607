import React from "react";
import PropTypes from "prop-types";
import { formatCurrency } from "../../../../utils";

const CurrencyCell = ({ value }) => (
  <div style={{ width: "100%", textAlign: "right" }}>
    {formatCurrency(value)}
  </div>
);

CurrencyCell.propTypes = {
  value: PropTypes.string.isRequired,
};

export default CurrencyCell;
