import React from "react";
import PropTypes from "prop-types";
import { Field, Control, Label, Input } from "rbx";

import JurisdictionSelect from "../../../../components/JurisdictionSelect";
import RatsTypeReferenceSelect from "../../../../components/RatsTypeReferenceSelect";
import RatsBusinessUnitSelect from "../../../../components/RatsBusinessUnitSelect";
import BooleanInput from "../../../../components/BooleanInput";
import ClaimNumberRecordsInput from "../ClaimNumberRecordsInput";

const NWForm = ({ handleChange, inputs, disabled }) => (
  <React.Fragment>
    <Field className="grid-override" kind="group">
      <Field className="grid-override" kind="group">
        <RatsTypeReferenceSelect
          required
          showBlank
          disabled={disabled}
          label="Nationwide Carrier"
          name="NWCarrier"
          value={inputs.NWCarrier}
          where={{
            TRentityAbbrev: {
              equals: "NW",
            },
          }}
          onChange={handleChange}
        />
        <RatsTypeReferenceSelect
          required
          showBlank
          disabled={disabled || !inputs.NWCarrier}
          label="Claim System"
          name="ClaimSystem"
          value={inputs.ClaimSystem}
          where={{
            TRappliesTo: {
              equals: inputs.NWCarrier,
            },
          }}
          onChange={handleChange}
        />
      </Field>
      <ClaimNumberRecordsInput
        disabled={disabled || !inputs.NWCarrier}
        inputs={inputs}
        onChange={handleChange}
      />
    </Field>
    <Field className="grid-override" kind="group">
      <RatsBusinessUnitSelect
        required
        showBlank
        disabled={disabled}
        label="Line of Business"
        name="BusinessUnit"
        value={inputs.BusinessUnit}
        where={{
          BUisRetired: {
            equals: false,
          },
          BUisNW: {
            equals: true,
          },
        }}
        onChange={(name, value) => handleChange(name, value)}
      />
      <JurisdictionSelect
        required
        disabled={disabled}
        label="Loss State"
        name="LossState"
        size="small"
        value={inputs.LossState}
        onChange={(name, value) => handleChange(name, value)}
      />
    </Field>
    <Field className="grid-override" kind="group">
      <Control>
        <Label>Is this case/claim a part of subrogation?</Label>
        <BooleanInput
          disabled={disabled}
          name="Subrogation"
          value={inputs.Subrogation}
          onChange={(name, value) => handleChange(name, value)}
        />
      </Control>
      <Control>
        <Label>Alleged Injuries of Interest</Label>
        <Input
          required
          autoComplete="new"
          disabled={disabled}
          maxLength={50}
          name="AllegedInjuries"
          size="small"
          value={inputs.AllegedInjuries}
          onChange={(e) => handleChange(e.target.name, e.target.value)}
        />
      </Control>
    </Field>
  </React.Fragment>
);

NWForm.propTypes = {
  handleChange: PropTypes.func.isRequired,
  inputs: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default NWForm;
