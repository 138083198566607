import React from "react";
import PropTypes from "prop-types";

import { Field, Control } from "rbx";

// internal-components
import PrioritySelect from "../../../components/PrioritySelect";

import UserSelect from "../../../components/UserSelect";
import CompanyDefaultDocuments from "../components/CompanyDefaultDocuments";

const Default = ({ onChange, inputs, disabled, companyCode, onSubmit }) => (
  <form id="add-company-form" onSubmit={onSubmit}>
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr",
        gridColumnGap: "1rem",
      }}
    >
      <Field>
        <Control expanded>
          <UserSelect
            disabled={disabled}
            label="Marketer"
            name="marketercode"
            value={inputs.marketercode}
            where={{
              fldActive: { equals: 1 },
              OR: [
                { usertype: { equals: "MK" } },
                { usertype: { equals: "*" } },
              ],
            }}
            onChange={onChange}
          />
        </Control>
      </Field>
      <Field>
        <Control expanded>
          <UserSelect
            required
            disabled={disabled}
            label="QA Rep"
            name="QARep"
            value={inputs.QARep}
            where={{
              fldActive: { equals: 1 },
              OR: [
                { usertype: { equals: "QA" } },
                { usertype: { equals: "*" } },
              ],
            }}
            onChange={onChange}
          />
        </Control>
      </Field>
      <Field>
        <Control expanded>
          <PrioritySelect
            showBlank
            disabled={disabled}
            name="priority"
            noValueLabel=""
            value={inputs.priority}
            onChange={onChange}
          />
        </Control>
      </Field>
    </div>
    <hr />
    <CompanyDefaultDocuments companycode={companyCode} />
  </form>
);

Default.propTypes = {
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  inputs: PropTypes.object.isRequired,
  companyCode: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default Default;
