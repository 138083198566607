import React from "react";
import PropTypes from "prop-types";
import { Field, Control, Input, Label } from "rbx";
import StatusSelect from "../../../../../components/StatusSelect";
import BooleanInput from "../../../../../components/BooleanInput";

const ServiceForm = ({ inputs, onChange }) => (
  <React.Fragment>
    <Field kind="group">
      <Control expanded>
        <Label>Name</Label>
        <Input
          autoComplete="new"
          maxLength={5}
          name="shortdesc"
          size="small"
          value={inputs.shortdesc}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
      <Control expanded>
        <Label>Description</Label>
        <Input
          autoComplete="new"
          name="description"
          size="small"
          value={inputs.description}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
      <StatusSelect
        fullWidth
        label="Status"
        name="status"
        options={[
          {
            id: "Active",
            value: "Active",
            label: "Active",
          },
          {
            id: "Inactive",
            value: "Inactive",
            label: "Inactive",
          },
        ]}
        size="small"
        value={inputs.status}
        onChange={onChange}
      />
      <Control expanded>
        <Label>Days to Commit Date</Label>
        <Input
          autoComplete="new"
          name="DaysToCommitDate"
          size="small"
          type="number"
          value={inputs.DaysToCommitDate}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
      <Control expanded>
        <Label>Calc From</Label>
        <Input
          name="CalcFrom"
          size="small"
          type="date"
          value={inputs.CalcFrom}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
      <Control>
        <Label>&nbsp;</Label>
        <BooleanInput
          label="Print Appt. Letters"
          name="PrintApptLetters"
          value={inputs.PrintApptLetters}
          onChange={onChange}
        />
      </Control>
    </Field>
  </React.Fragment>
);

ServiceForm.propTypes = {
  inputs: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ServiceForm;
