import React from "react";
import PropTypes from "prop-types";
import { Control, Label, Select } from "rbx";
import { useQuery } from "@apollo/client";

import { LIST_DOCUMENT_QUERY } from "../../graphql";

const DocumentSelect = ({
  value,
  name,
  label,
  size,
  onChange,
  documentType,
  disabled,
  getValue,
  setValue,
  required,
  accessor,
  showLabel,
}) => {
  const variables = { orderBy: { description: "asc" } };

  if (documentType) {
    variables.where = {
      type: { equals: documentType },
    };
  }
  const { data: DocumentData } = useQuery(LIST_DOCUMENT_QUERY, {
    variables,
  });
  const documents = DocumentData?.documents;

  const handleChange = (e) => {
    onChange(e.target.name, setValue(e.target.value));
  };

  const indicateRequired =
    required && (!value || value === "" || value === "0" || value === 0);

  const getOptionDisabled = (document) =>
    document.status !== "Active" || document.complete !== "Complete";

  return (
    <Control expanded>
      {showLabel && <Label>{label}</Label>}
      <Select.Container
        fullwidth
        size={size}
        state={Array.isArray(documents) ? "" : "loading"}
      >
        <Select
          disabled={disabled}
          name={name}
          style={{
            border: indicateRequired ? "1px #e63362 solid" : "",
            boxShadow: indicateRequired
              ? "0 0 0 0.125em rgba(230, 51, 98, 0.25)"
              : "",
          }}
          value={getValue(value)}
          onChange={handleChange}
        >
          <Select.Option value="" />
          {Array.isArray(documents) &&
            documents.map((document) => (
              <Select.Option
                disabled={getOptionDisabled(document)}
                key={document.SeqNo}
                value={document[accessor]}
              >
                {document.description}
              </Select.Option>
            ))}
        </Select>
      </Select.Container>
    </Control>
  );
};

DocumentSelect.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.number,
  ]),
  name: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.string,
  documentType: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  getValue: PropTypes.func,
  setValue: PropTypes.func,
  required: PropTypes.bool,
  accessor: PropTypes.string,
  showLabel: PropTypes.bool,
};

DocumentSelect.defaultProps = {
  value: "",
  name: "",
  label: "Document",
  documentType: "",
  size: "small",
  onChange: () => null,
  disabled: false,
  getValue: (x) => x,
  setValue: (x) => x,
  required: false,
  accessor: "SeqNo",
  showLabel: true,
};

export default DocumentSelect;
