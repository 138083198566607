import React from "react";

import { Switch, Route, Redirect } from "react-router-dom";
import { useIdleTimer } from "react-idle-timer";

import { Container, Title, Box } from "rbx";

import { Sidebar } from "../components/Navigation";

import CompanyRouter from "./Company";
import DoctorRouter from "./Doctor";
import ExamineeRouter from "./Examinee";
import ClientRouter from "./Client";
import CaseRouter from "./Case";
import CaseTrackerRouter from "./CaseTracker";
import DocumentRouter from "./Document";
import SetupRouter from "./Setup";
import UpdatePasswordModal from "../components/UpdatePasswordModal";
import ClaimRouter from "./Claim";
import ReferralRouter from "./Referral";
import InvestigatorsRouter from "./Investigator";
// import ResetPasswordRouter from "./ResetPassword";

import { useAuth } from "../context";
import { usePermissions } from "../hooks";

// import BugReporter from "../components/BugReporter";
import Unauthorized from "../components/Unauthorized";
import ApolloFooter from "../components/ApolloFooter";

import ConfirmationScreen from "./Referral/components/ConfirmationScreen";

const SIXTY_MINUTES = 1000 * 60 * 60;
// const FIFTEEN_SECONDS = 1000 * 15;

const pad = (number) => {
  if (number < 10) {
    return `0${number}`;
  }
  return number;
};

// eslint-disable-next-line
Date.prototype.toCorrectDateString = function () {
  return `${this.getFullYear()}-${pad(this.getMonth() + 1)}-${pad(
    this.getDate()
  )}T${pad(this.getHours())}:${pad(this.getMinutes())}`;
};

const Pages = (props) => {
  const { state: authState, handleLogout, handleUpdatePassword } = useAuth();

  useIdleTimer({
    timeout: SIXTY_MINUTES,
    debounce: 500,
    onIdle: handleLogout,
  });

  const needPasswordReset = authState.user?.resetPassword;

  const isAdmin = usePermissions(["AllAdminFunc"]);

  return authState.isLoggedIn ? (
    <div className="app-container">
      <Sidebar />
      <main className="main-container">
        <section className="section-container">
          <Container fluid>
            {needPasswordReset ? (
              <Box>
                <Title subtitle style={{ textAlign: "center" }}>
                  Please change your password before continue
                </Title>
                <UpdatePasswordModal onConfirm={handleUpdatePassword} />
              </Box>
            ) : (
              <Switch>
                <Route path="/referral">
                  <ReferralRouter />
                </Route>
                <Route
                  path="/setup"
                  render={() => (isAdmin ? <SetupRouter /> : <Unauthorized />)}
                />
                <Route path="/doctors">
                  <DoctorRouter />
                </Route>
                <Route path="/investigators">
                  <InvestigatorsRouter />
                </Route>
                <Route path="/companies">
                  <CompanyRouter />
                </Route>
                <Route path="/examinees">
                  <ExamineeRouter />
                </Route>
                <Route path="/clients">
                  <ClientRouter />
                </Route>
                <Route path="/cases">
                  <CaseRouter />
                </Route>
                <Route
                  path="/documents"
                  render={() =>
                    isAdmin ? <DocumentRouter /> : <Unauthorized />
                  }
                />
                <Route path="/claims">
                  <ClaimRouter />
                </Route>
                <Route exact path="/">
                  <CaseTrackerRouter />
                </Route>
                <Route path="*">
                  <Redirect to="/" />
                </Route>
              </Switch>
            )}
          </Container>
        </section>
        <ApolloFooter />
      </main>
    </div>
  ) : (
    <div className="app-container">
      <Switch>
        <Route path="/confirmation">
          <ConfirmationScreen />
        </Route>
        <Route path="/referral">
          <ReferralRouter />
        </Route>
        <Route path="*">
          <Redirect to="/referral" />
        </Route>
      </Switch>
    </div>
  );
};

Pages.propTypes = {};

export default Pages;
