import React from "react";
import PropTypes from "prop-types";

import { Box, Field, Control, Button, Label } from "rbx";

import FilterInput from "../../../../components/FilterInput";
import StatusSelect from "../../../../components/StatusSelect";
import CompleteSelect from "../../../../components/CompleteSelect";
import BusinessLineSelect from "../../../../components/BusinessLineSelect";
import DocumentFormatSelect from "../../../../components/DocumentFormatSelect";

const DocumentListFilters = ({ filters, onChange, onReset, onFilter }) => (
  <Box>
    <div className="list-filters">
      <Field>
        <BusinessLineSelect
          showAll
          getValue={(x) => x?.equals || ""}
          label="Line of Business"
          name="lob"
          setValue={(x) => (x ? { equals: parseInt(x, 10) } : "")}
          value={filters.lob}
          onChange={onChange}
        />
      </Field>
      <Field>
        <FilterInput
          getValue={(x) => (x && x?.startsWith ? x.startsWith : "")}
          label="Document Type"
          name="type"
          placeholder="Invoice, Voucher, Letter..."
          setValue={(x) => (x ? { startsWith: x } : "")}
          value={filters.type}
          onChange={onChange}
        />
      </Field>
      <Field>
        <DocumentFormatSelect
          fullWidth
          showBlank
          getValue={(x) => x?.equals || ""}
          id="outputFormat"
          label="Output Format"
          name="outputFormat"
          setValue={(x) => (x ? { equals: x } : "")}
          size="small"
          value={filters.outputFormat}
          onChange={onChange}
        />
      </Field>
      <Field>
        <CompleteSelect
          showBlank
          getValue={(x) => x?.equals || ""}
          label="Complete"
          name="complete"
          setValue={(x) => (x ? { equals: x } : "")}
          value={filters.complete}
          onChange={onChange}
        />
      </Field>

      <Field>
        <StatusSelect
          showBlank
          getValue={(x) => x?.equals || ""}
          label="Status"
          name="status"
          setValue={(x) => (x ? { equals: x } : "")}
          value={filters.status}
          onChange={onChange}
        />
      </Field>

      <Field kind="group">
        <Control>
          <Label>&nbsp;</Label>
          <Button color="danger" size="small" onClick={onReset}>
            Reset
          </Button>
        </Control>
      </Field>
    </div>
    {/* <hr />
    <pre>
      <code>{JSON.stringify(filters, null, 2)}</code>
    </pre> */}
  </Box>
);

DocumentListFilters.propTypes = {
  filters: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  onFilter: PropTypes.func,
};

DocumentListFilters.defaultProps = {
  onFilter: () => {},
};

export default DocumentListFilters;
