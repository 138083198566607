import React from "react";
import PropTypes from "prop-types";
import { Control, Field, Input, Box, Label } from "rbx";

import StateSelect from "../../../../components/StateSelect";
import PhoneNumberInput from "../../../../components/PhoneNumberInput";
import ZipInput from "../../../../components/ZipInput";
import ReferralStyledSubtitle from "../ReferralStyledSubtitle";

const TreatingPhysician = ({ onChange, inputs }) => (
  <Box>
    <ReferralStyledSubtitle>Treating Physician</ReferralStyledSubtitle>
    <Field kind="group">
      <Control expanded>
        <Label>Treating Physician</Label>
        <Input
          name="TreatingPhysician"
          size="small"
          value={inputs.TreatingPhysician}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
    </Field>
    <Field kind="group">
      <Control expanded>
        <Label>First Name</Label>
        <Input
          name="FirstName"
          size="small"
          value={inputs.FirstName}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
      <Control expanded>
        <Label>Last Name</Label>
        <Input
          name="LastName"
          size="small"
          value={inputs.LastName}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
    </Field>
    <Field kind="group">
      <Control expanded>
        <Label>Address 1</Label>
        <Input
          name="Address1"
          size="small"
          value={inputs.Address1}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
      <Control expanded>
        <Label>Address 2</Label>
        <Input
          name="Address2"
          size="small"
          value={inputs.Address2}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
    </Field>
    <Field className="grid-override is-three" kind="group">
      <Control expanded>
        <Label>City</Label>
        <Input
          name="City"
          size="small"
          value={inputs.City}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
      <StateSelect
        displayFullLabel
        expanded
        label="State"
        name="State"
        size="small"
        value={inputs.State}
        onChange={onChange}
      />
      <ZipInput
        label="Zip Code"
        name="Zip"
        state={inputs.State}
        value={inputs.Zip}
        onChange={onChange}
      />
    </Field>
    <Field kind="group">
      <Control expanded>
        <Label>Phone</Label>
        <PhoneNumberInput
          name="Phone"
          value={inputs.Phone}
          onChange={onChange}
        />
      </Control>
      <Control expanded>
        <Label>Extension</Label>
        <Input
          maxLength={14}
          name="PhoneExt"
          size="small"
          value={inputs.PhoneExt}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
    </Field>
  </Box>
);

TreatingPhysician.propTypes = {
  onChange: PropTypes.func.isRequired,
  inputs: PropTypes.object.isRequired,
};

export default TreatingPhysician;
