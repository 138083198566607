const DOCTOR_FIELDS = [
  {
    name: "Country",
    type: "String",
    entity: "doctor",
  },
  {
    name: "ImportSpecialty",
    type: "String",
    entity: "doctor",
  },
  {
    name: "NPINbr",
    type: "String",
    entity: "doctor",
  },
  {
    name: "OPSubType",
    type: "String",
    entity: "doctor",
  },
  {
    name: "OPType",
    type: "String",
    entity: "doctor",
  },
  {
    name: "ProvTypeCode",
    type: "Int",
    entity: "doctor",
  },
  {
    name: "PublishOnWeb",
    type: "Boolean",
    entity: "doctor",
  },
  {
    name: "SSNTaxID",
    type: "String",
    entity: "doctor",
  },
  {
    name: "UPIN",
    type: "String",
    entity: "doctor",
  },
  {
    name: "UnRegNbr",
    type: "String",
    entity: "doctor",
  },
  {
    name: "WCNbr",
    type: "String",
    entity: "doctor",
  },
  {
    name: "WebUserID",
    type: "Int",
    entity: "doctor",
  },
  {
    name: "addr1",
    type: "String",
    entity: "doctor",
  },
  {
    name: "addr2",
    type: "String",
    entity: "doctor",
  },
  {
    name: "cellphone",
    type: "String",
    entity: "doctor",
  },
  {
    name: "city",
    type: "String",
    entity: "doctor",
  },
  {
    name: "companyname",
    type: "String",
    entity: "doctor",
  },
  {
    name: "county",
    type: "String",
    entity: "doctor",
  },
  {
    name: "credentials",
    type: "String",
    entity: "doctor",
  },
  {
    name: "dateadded",
    type: "DateTime",
    entity: "doctor",
  },
  {
    name: "dateedited",
    type: "DateTime",
    entity: "doctor",
  },
  {
    name: "doctorcode",
    type: "Int",
    entity: "doctor",
  },
  {
    name: "doctoremail",
    type: "String",
    entity: "doctor",
  },
  {
    name: "emailAddr",
    type: "String",
    entity: "doctor",
  },
  {
    name: "faxNbr",
    type: "String",
    entity: "doctor",
  },
  {
    name: "feecode",
    type: "Int",
    entity: "doctor",
  },
  {
    name: "firstname",
    type: "String",
    entity: "doctor",
  },
  {
    name: "lastname",
    type: "String",
    entity: "doctor",
  },
  {
    name: "licensenbr",
    type: "String",
    entity: "doctor",
  },
  {
    name: "location",
    type: "DoctorLocation",
    entity: "doctor",
  },
  {
    name: "medicalrecordemail",
    type: "String",
    entity: "doctor",
  },
  {
    name: "middleinitial",
    type: "String",
    entity: "doctor",
  },
  {
    name: "notes",
    type: "String",
    entity: "doctor",
  },
  {
    name: "phone",
    type: "String",
    entity: "doctor",
  },
  {
    name: "phoneExt",
    type: "String",
    entity: "doctor",
  },
  {
    name: "prefix",
    type: "String",
    entity: "doctor",
  },
  {
    name: "FormalName",
    type: "String",
    entity: "doctor",
  },
  {
    name: "prepaid",
    type: "Boolean",
    entity: "doctor",
  },
  {
    name: "prodcode",
    type: "Int",
    entity: "doctor",
  },
  {
    name: "qualifications",
    type: "String",
    entity: "doctor",
  },
  {
    name: "schedulepriority",
    type: "Int",
    entity: "doctor",
  },
  {
    name: "state",
    type: "String",
    entity: "doctor",
  },
  {
    name: "status",
    type: "String",
    entity: "doctor",
  },
  {
    name: "usddate1",
    type: "DateTime",
    entity: "doctor",
  },
  {
    name: "usddate2",
    type: "DateTime",
    entity: "doctor",
  },
  {
    name: "usddate3",
    type: "DateTime",
    entity: "doctor",
  },
  {
    name: "usddate4",
    type: "DateTime",
    entity: "doctor",
  },
  {
    name: "usddate6",
    type: "DateTime",
    entity: "doctor",
  },
  {
    name: "usddate7",
    type: "DateTime",
    entity: "doctor",
  },
  {
    name: "usdint1",
    type: "Int",
    entity: "doctor",
  },
  {
    name: "usdint2",
    type: "Int",
    entity: "doctor",
  },
  {
    name: "usdmoney1",
    type: "Float",
    entity: "doctor",
  },
  {
    name: "usdmoney2",
    type: "Float",
    entity: "doctor",
  },
  {
    name: "usdtext1",
    type: "String",
    entity: "doctor",
  },
  {
    name: "usdtext2",
    type: "String",
    entity: "doctor",
  },
  {
    name: "usdvarchar1",
    type: "String",
    entity: "doctor",
  },
  {
    name: "usdvarchar2",
    type: "String",
    entity: "doctor",
  },
  {
    name: "usdvarchar3",
    type: "String",
    entity: "doctor",
  },
  {
    name: "useridadded",
    type: "String",
    entity: "doctor",
  },
  {
    name: "useridedited",
    type: "String",
    entity: "doctor",
  },
  {
    name: "zip",
    type: "String",
    entity: "doctor",
  },
];

export default DOCTOR_FIELDS;
