import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Control, Label, Select } from "rbx";
import { useLazyQuery } from "@apollo/client";

import { ALL_DOCTOR_SPECIALTIES_QUERY } from "../../graphql";

const DoctorSpecialtySelect = ({
  value,
  name,
  label,
  size,
  onChange,
  disabled,
  required,
  getValue,
  setValue,
  doctorcode,
}) => {
  const [
    getDoctorSpecialties,
    { data: DoctorSpecialtiesData, loading: DoctorSpecialtiesLoading },
  ] = useLazyQuery(ALL_DOCTOR_SPECIALTIES_QUERY);

  useEffect(() => {
    if (doctorcode) {
      getDoctorSpecialties({
        variables: {
          where: {
            doctorcode: { equals: parseInt(doctorcode, 10) },
          },
          orderBy: { specialtycode: "asc" },
        },
      });
    }
  }, [doctorcode, getDoctorSpecialties]);

  const indicateRequired =
    required && (!value || value === "" || value === "0" || value === 0);

  const doctorSpecialties = DoctorSpecialtiesData?.doctorSpecialties;

  const handleChange = (e) => {
    onChange(e.target.name, setValue(e.target.value));
  };
  return (
    <Control expanded>
      <Label>{label}</Label>
      <Select.Container
        fullwidth
        size={size}
        state={DoctorSpecialtiesLoading ? "loading" : ""}
      >
        <Select
          disabled={disabled}
          name={name}
          style={{
            border: indicateRequired ? "1px #e63362 solid" : "",
            borderRadius: "4px",
            boxShadow: indicateRequired
              ? "0 0 0 0.125em rgba(230, 51, 98, 0.25)"
              : "",
          }}
          value={getValue(value)}
          onChange={handleChange}
        >
          <Select.Option value="" />
          {Array.isArray(doctorSpecialties) &&
            doctorSpecialties.map((doctorSpecialty) => (
              <Select.Option
                key={doctorSpecialty.specialtycode}
                value={doctorSpecialty.specialtycode}
              >
                {doctorSpecialty?.specialty?.description ||
                  doctorSpecialty.specialtycode}
              </Select.Option>
            ))}
        </Select>
      </Select.Container>
    </Control>
  );
};

DoctorSpecialtySelect.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.number,
  ]),
  name: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  getValue: PropTypes.func,
  setValue: PropTypes.func,
  doctorcode: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

DoctorSpecialtySelect.defaultProps = {
  value: "",
  name: "",
  label: "DoctorLocation",
  size: "small",
  onChange: () => null,
  disabled: false,
  required: false,
  getValue: (x) => x,
  setValue: (x) => x,
  doctorcode: "",
};

export default DoctorSpecialtySelect;
