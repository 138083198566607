import { gql } from "@apollo/client";

// eslint-disable-next-line import/prefer-default-export
export const SINGLE_VWDOCUMENT_QUERY = gql`
  query SINGLE_VWDOCUMENT_QUERY($where: VwDocumentWhereUniqueInput!) {
    vwDocument(where: $where) {
      casenbr
      claimnbr
      examineeaddr1
      examineeaddr2
      examineecitystatezip
      SSN
      clientname
      company
      clientphone
      doctoraddr1
      doctoraddr2
      doctorcitystatezip
      ApptDate
      Appttime
      examineename
      examineephone
      sex
      DOB
      doctorphone
      clientaddr1
      clientaddr2
      clientcitystatezip
      clientfax
      clientemail
      scheduler
      marketer
      datecalledin
      DOI
      allegation
      notes
      casetype
      examineesalutation
      status
      calledinby
      chartnbr
      clientsalutation
      doctorsalutation
      insidedr
      doctoremail
      doctorfax
      faxdrschedule
      medrcdletter
      drletter
      reportverbal
      medsrecd
      Pattorneyname
      Pattorneysalutation
      Pattorneycompany
      Pattorneyaddr1
      Pattorneyaddr2
      Pattorneycitystatezip
      Pattorneyphone
      Pattorneyfax
      Pattorneyemail
      Dattorneyname
      Dattorneysalutation
      Dattorneycompany
      Dattorneyaddr1
      Dattorneyaddr2
      Dattorneycitystatezip
      Dattorneyphone
      Dattorneyfax
      Dattorneyemail
      fax
      DParaLegalname
      DParaLegalsalutation
      DParaLegalcompany
      DParaLegaladdr1
      DParaLegaladdr2
      DParaLegalcitystatezip
      DParaLegalphone
      DParaLegalemail
      DParaLegalfax
      typemedsrecd
      plaintiffattorneycode
      defenseattorneycode
      servicecode
      faxPattny
      faxdoctor
      faxclient
      emailclient
      emaildoctor
      emailPattny
      invoicedate
      invoiceamt
      commitdate
      WCBNbr
      specialinstructions
      priority
      servicedesc
      caseusdvarchar1
      caseusdvarchar2
      caseusddate1
      caseusddate2
      caseusdtext1
      caseusdtext2
      caseusdint1
      caseusdint2
      caseusdmoney1
      caseusdmoney2
      clienttitle
      clientprefix
      clientsuffix
      clientusdvarchar1
      clientusdvarchar2
      clientusddate1
      clientusddate2
      clientusdtext1
      clientusdtext2
      clientusdint1
      clientusdint2
      clientusdmoney1
      clientusdmoney2
      doctornotes
      doctorprefix
      doctorcorrespaddr1
      doctorcorrespaddr2
      doctorcorrespcitystatezip
      doctorcorrespphone
      doctorcorrespfax
      doctorcorrespemail
      qualifications
      prepaid
      doctorcorrespcounty
      doctorcounty
      doctorvicinity
      examineecounty
      examineeprefix
      examineeusdvarchar1
      examineeusdvarchar2
      examineeusddate1
      examineeusddate2
      examineeusdtext1
      examineeusdtext2
      examineeusdint1
      examineeusdint2
      examineeusdmoney1
      examineeusdmoney2
      doctorusdvarchar1
      doctorusdvarchar2
      doctorusddate1
      doctorusddate2
      doctorusdtext1
      doctorusdtext2
      doctorusdint1
      doctorusdint2
      doctorusdmoney1
      doctorusdmoney2
      schedulenotes
      requesteddoc
      companyusdvarchar1
      companyusdvarchar2
      companyusddate1
      companyusddate2
      companyusdtext1
      companyusdtext2
      companyusdint1
      companyusdint2
      companyusdmoney1
      companyusdmoney2
      doctorwcnbr
      casetypedesc
      location
      doctorlocation
      internalcasenbr
      doctordegree
      specialtydesc
      chartnotes
      examineefax
      examineeemail
      examineeinsured
      clientcode
      doctorcode
      feecode
      companycode
      clientnotes
      companynotes
      billaddr1
      billaddr2
      billcity
      billstate
      billzip
      billattn
      ARKey
      icd9code
      remitattn
      remitaddr1
      remitaddr2
      remitcity
      remitstate
      remitzip
      doctorspecialty
      shortdesc
      doctorlicense
      doctorlocationnotes
      doctorlocationcontact
      doctorlocationcontactsalutation
      medsstatus
      employer
      treatingphysician
      examineecity
      examineestate
      examineezip
      DoctorTaxID
      casebillclientcode
      casebilladdr1
      casebilladdr2
      casebillcity
      casebillstate
      casebillzip
      casebillarkey
      casebillcompany
      casebillcontact
      specialtycode
      doctorcorrespondence
      examineelastname
      examineefirstname
      casebillfax
      clientbillfax
      officecode
      doctorlastname
      doctorfirstname
      doctormiddleinitial
      doctorinitials
      QARep
      clientlastname
      clientfirstname
      dattorneyprefix
      dattorneylastname
      dattorneyfirstname
      pattorneyprefix
      pattorneylastname
      pattorneyfirstname
      doctorlocationcontactprefix
      doctorlocationcontactfirstname
      doctorlocationcontactlastname
      examineemiddleinitial
      ICD9Code2
      ICD9Code3
      ICD9Code4
      InsuredAddr1
      InsuredCity
      InsuredState
      InsuredZip
      InsuredPhone
      InsuredPhoneExt
      InsuredFax
      InsuredEmail
      TreatingPhysicianAddr1
      TreatingPhysicianCity
      TreatingPhysicianState
      TreatingPhysicianZip
      TreatingPhysicianPhone
      TreatingPhysicianPhoneExt
      TreatingPhysicianFax
      TreatingPhysicianEmail
      TreatingPhysicianLicenseNbr
      EmployerAddr1
      EmployerCity
      EmployerState
      EmployerZip
      EmployerPhone
      EmployerPhoneExt
      EmployerFax
      EmployerEmail
      TreatingPhysicianTaxID
      Country
      UPIN
      schedulepriority
      drfeecode
      PanelNbr
      Jurisdiction
      photoRqd
      CertMailNbr
      HearingDate
      DoctorName
      doctorstate
      clientstate
      doctorcorrespstate
      dattorneystate
      pattorneystate
      prevappt
      mastersubcase
      mastercasenbr
      doctorcity
      doctorzip
      clientcity
      clientzip
      policynumber
      pattorneycity
      pattorneyzip
      ApptDuration
      PracticeName
      OCF25Date
      DateForminDispute
      EmployerContactLastName
      EmployerContactFirstName
      DoctorNPINbr
      PublishOnWeb
      DoctorProviderType
      ProvTypeCode
      JurisdictionCode
      transcode
      transcompany
      DateReceived
      caseusddate3
      caseusddate4
      caseusdboolean1
      caseusdboolean2
      caseusddate5
      doctorusddate3
      doctorusddate4
      doctorusdvarchar3
      doctorusddate5
      doctorusddate6
      doctorusddate7
      officeusdvarchar1
      officeusdvarchar2      
      RequestedSpecialty
      statusdesc
      AttorneyNote
      CaseTypeShortDesc
      ExternalDueDate
      InternalDueDate
    }
  }
`;
