/* eslint-disable react/prop-types */
/* eslint-disable-next-line react/prop-types */
import { format } from "date-fns";
import { convertTimeZone } from "../../../../utils";

const COLUMNS = [
  {
    Header: "Type",
    id: "OPType",
    accessor: "OtherPartyType.type",
  },
  {
    Header: "Status",
    id: "status",
    accessor: "status",
  },
  {
    Header: "Company",
    id: "companyname",
    accessor: "companyname",
  },
  {
    Header: "Contact First Name",
    id: "firstname",
    accessor: "firstname",
  },
  {
    Header: "Contact Last Name",
    id: "lastname",
    accessor: "lastname",
  },
  {
    Header: "Address",
    id: "addr1",
    accessor: "addr1",
  },
  {
    Header: "City",
    id: "city",
    accessor: "city",
  },
  {
    Header: "State",
    id: "state",
    accessor: "state",
  },
  {
    Header: "Zip",
    id: "zip",
    accessor: "zip",
  },
  {
    Header: "Phone",
    id: "phone",
    accessor: "phone",
  },
  {
    Header: "Notes",
    id: "notes",
    accessor: "notes",
  },
  {
    Header: "Added",
    id: "dateadded",
    accessor: "dateadded",
    Cell: ({ cell }) =>
      cell?.value
        ? format(convertTimeZone(cell.value), "MM/dd/yyyy hh:mm a")
        : "",
  },
  {
    Header: "By",
    id: "useridadded",
    accessor: "useridadded",
  },
  {
    Header: "Edited",
    id: "dateedited",
    accessor: "dateedited",
    Cell: ({ cell }) =>
      cell?.value
        ? format(convertTimeZone(cell.value), "MM/dd/yyyy hh:mm a")
        : "",
  },
  {
    Header: "By",
    id: "useridedited",
    accessor: "useridedited",
  },
];

export default COLUMNS;
