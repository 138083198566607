import React from "react";
import PropTypes from "prop-types";
import { Field, Control, Input, Label } from "rbx";
import BooleanInput from "../../../../../../components/BooleanInput";

const BusinessLineForm = ({ disabled, inputs, onChange }) => (
  <div>
    <Field kind="group">
      <Control>
        <Label>Name</Label>
        <Input
          required
          autoComplete="new"
          disabled={disabled}
          maxLength="50"
          name="name"
          size="small"
          value={inputs.name}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
      <Control expanded>
        <Label>Description</Label>
        <Input
          required
          autoComplete="new"
          disabled={disabled}
          maxLength="200"
          name="description"
          size="small"
          value={inputs.description}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
      <Control>
        <Label>Don&apos;t Voucher</Label>
        <BooleanInput
          disabled={disabled}
          name="dontVoucher"
          value={inputs.dontVoucher}
          onChange={onChange}
        />
      </Control>
    </Field>
  </div>
);

BusinessLineForm.propTypes = {
  disabled: PropTypes.bool.isRequired,
  inputs: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default BusinessLineForm;
