import React from "react";
import PropTypes from "prop-types";
import { Control, Field, Textarea, Box, Label } from "rbx";

import ReferralStyledSubtitle from "../ReferralStyledSubtitle";
import BooleanInput from "../../../../components/BooleanInput";
import LanguageSelect from "../../../../components/LanguageSelect";

const SpecialServices = ({ onChange, inputs }) => (
  <Box>
    <ReferralStyledSubtitle>Special Services</ReferralStyledSubtitle>
    <Field>
      <Control expanded>
        <BooleanInput
          label="Taxi Service"
          name="TaxiService"
          value={inputs.TaxiService}
          onChange={(name, value) => onChange(name, value)}
        />
      </Control>
    </Field>
    <Field>
      <Control>
        <BooleanInput
          label="Interpreter Service"
          name="InterpreterService"
          value={inputs.InterpreterService}
          onChange={(name, value) => onChange(name, value)}
        />
      </Control>
    </Field>
    {inputs.InterpreterService && (
      <Field>
        <LanguageSelect
          label="Language"
          name="InterpreterLanguage"
          size="small"
          value={inputs.InterpreterLanguage}
          onChange={onChange}
        />
      </Field>
    )}
    <Field>
      <Control expanded>
        <BooleanInput
          label="Certified Mail"
          name="CertifiedMail"
          value={inputs.CertifiedMail}
          onChange={(name, value) => onChange(name, value)}
        />
      </Control>
    </Field>
    <Field>
      <Control>
        <Label>Medical Management Special Instructions</Label>
        <Textarea
          name="MedicalManagementSpecialInstructions"
          size="small"
          value={inputs.MedicalManagementSpecialInstructions}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
    </Field>
  </Box>
);

SpecialServices.propTypes = {
  onChange: PropTypes.func.isRequired,
  inputs: PropTypes.object.isRequired,
};

export default SpecialServices;
