import { gql } from "@apollo/client";

export const LIST_EXCEPTION_LIST_QUERY = gql`
  query LIST_EXCEPTION_LIST_QUERY(
    $orderBy: [ExceptionListOrderByInput!]
    $where: ExceptionListWhereInput
    $take: Int
    $skip: Int
  ) {
    exceptionLists(orderBy: $orderBy, where: $where, take: $take, skip: $skip) {
      DateAdded
      DateEdited
      Description
      ExceptionID
      Status
      UserIDAdded
      UserIDEdited
    }
    aggregateExceptionList(where: $where) {
      count {
        ExceptionID
      }
    }
  }
`;

export const ALL_EXCEPTION_LIST_QUERY = gql`
  query ALL_EXCEPTION_LIST_QUERY(
    $orderBy: [ExceptionListOrderByInput!]
    $where: ExceptionListWhereInput
  ) {
    exceptionLists(orderBy: $orderBy, where: $where) {
      DateAdded
      DateEdited
      Description
      ExceptionID
      Status
      UserIDAdded
      UserIDEdited
    }
  }
`;

export const SINGLE_EXCEPTION_LIST_QUERY = gql`
  query exceptionList($where: ExceptionListWhereUniqueInput!) {
    exceptionList(where: $where) {
      DateAdded
      DateEdited
      Description
      ExceptionID
      Status
      UserIDAdded
      UserIDEdited
    }
  }
`;

export const LIST_EXCEPTION_DEFINITION_QUERY = gql`
  query exceptionDefinitions(
    $take: Int
    $orderBy: [ExceptionDefinitionOrderByInput!]
    $skip: Int
    $where: ExceptionDefinitionWhereInput
  ) {
    exceptionDefinitions(
      take: $take
      orderBy: $orderBy
      skip: $skip
      where: $where
    ) {
      CaseTypeCode
      DateAdded
      DateEdited
      Description
      DisplayMessage
      Document1
      Document2
      EditEmail
      EmailMessage
      EmailOther
      EmailQA
      EmailScheduler
      EmailSubject
      EmailText
      Entity
      ExceptionDefID
      ExceptionID
      GenerateDocument
      IMECentricCode
      Message
      RequireComment
      ServiceCode
      Status
      StatusCode
      StatusCodeValue
      UserIDAdded
      UserIDEdited
    }
    aggregateExceptionDefinition(where: $where) {
      count {
        ExceptionDefID
      }
    }
  }
`;

export const FIND_FIRST_EXCEPTION_DEFINITION_QUERY = gql`
  query FIND_FIRST_EXCEPTION_DEFINITION_QUERY(
    $where: ExceptionDefinitionWhereInput
  ) {
    findFirstExceptionDefinition(where: $where) {
      CaseTypeCode
      DateAdded
      DateEdited
      Description
      DisplayMessage
      Document1
      Document2
      EditEmail
      EmailMessage
      EmailOther
      EmailQA
      EmailScheduler
      EmailSubject
      EmailText
      Entity
      ExceptionDefID
      ExceptionID
      GenerateDocument
      IMECentricCode
      Message
      RequireComment
      ServiceCode
      Status
      StatusCode
      StatusCodeValue
      UserIDAdded
      UserIDEdited
    }
  }
`;

export const SINGLE_EXCEPTION_DEFINITION_QUERY = gql`
  query exceptionDefinition($where: ExceptionDefinitionWhereUniqueInput!) {
    exceptionDefinition(where: $where) {
      CaseTypeCode
      DateAdded
      DateEdited
      Description
      DisplayMessage
      Document1
      Document2
      EditEmail
      EmailMessage
      EmailOther
      EmailQA
      EmailScheduler
      EmailSubject
      EmailText
      Entity
      ExceptionDefID
      ExceptionID
      GenerateDocument
      IMECentricCode
      Message
      RequireComment
      ServiceCode
      Status
      StatusCode
      StatusCodeValue
      UserIDAdded
      UserIDEdited
    }
  }
`;
