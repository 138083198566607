import React from "react";
import PropTypes from "prop-types";
import { Field, Box, Control, Label, Input } from "rbx";
import PhoneNumberInput from "../../../../components/PhoneNumberInput";
import AllstateAdjusterEmailSelect from "../AllstateAdjusterEmailSelect";

import ReferralStyledSubtitle from "../ReferralStyledSubtitle";

const AdjusterForm = ({
  mode,
  onChange,
  inputs,
  required,
  isGeicoInvestigationsRequirement,
  isAllstateOffice,
}) => (
  <Box>
    <ReferralStyledSubtitle>Adjuster Information</ReferralStyledSubtitle>
    <Field>
      <Control expanded>
        <Label>Email</Label>
        {isAllstateOffice ? (
          <AllstateAdjusterEmailSelect
            filterBy="email"
            inputs={inputs}
            name="Email"
            onChange={onChange}
          />
        ) : (
          <Input
            name="Email"
            size="small"
            value={inputs?.Email}
            onChange={(e) => onChange(e.target.name, e.target.value)}
          />
        )}
      </Control>
    </Field>
    <Field className="grid-override is-three" kind="group">
      <Control expanded>
        <Label>First Name</Label>
        {isAllstateOffice ? (
          <AllstateAdjusterEmailSelect
            filterBy="firstname"
            inputs={inputs}
            name="FirstName"
            onChange={onChange}
          />
        ) : (
          <Input
            name="FirstName"
            required={required}
            size="small"
            value={inputs?.FirstName}
            onChange={(e) => onChange(e.target.name, e.target.value)}
          />
        )}
      </Control>
      <Control expanded>
        <Label>Last Name</Label>
        {isAllstateOffice ? (
          <AllstateAdjusterEmailSelect
            filterBy="lastname"
            inputs={inputs}
            name="LastName"
            onChange={onChange}
          />
        ) : (
          <Input
            name="LastName"
            required={required}
            size="small"
            value={inputs?.LastName}
            onChange={(e) => onChange(e.target.name, e.target.value)}
          />
        )}
      </Control>
      <Control expanded>
        <Label>Phone #</Label>
        <PhoneNumberInput
          name="Phone"
          required={isGeicoInvestigationsRequirement}
          size="small"
          value={inputs.Phone}
          onChange={onChange}
        />
      </Control>
    </Field>
    <Field kind="group">
      <Control expanded>
        <Label>Company</Label>
        <Input
          name="Company"
          size="small"
          value={inputs?.Company}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
      <Control expanded>
        <Label>Office</Label>
        <Input
          name="Office"
          size="small"
          value={inputs?.Office}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
    </Field>
  </Box>
);
AdjusterForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  inputs: PropTypes.object.isRequired,
  isGeicoInvestigationsRequirement: PropTypes.bool,
  required: PropTypes.bool.isRequired,
  mode: PropTypes.string,
  isAllstateOffice: PropTypes.bool,
};

AdjusterForm.defaultProps = {
  mode: "nonrecords",
  isGeicoInvestigationsRequirement: false,
  isAllstateOffice: false,
};

export default AdjusterForm;
