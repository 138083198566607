import React from "react";
import PropTypes from "prop-types";
import { Control, Field, Label, Radio } from "rbx";

const RetainClaimAndClaimant = ({ value, handleChange }) => {
  const options = [
    {
      name: "Do Not Retain Information",
      handleChange: { claim: false, claimant: false },
      checked: !value.claim && !value.claimant,
    },
    {
      name: "Retain Claim & Claimant",
      handleChange: { claim: true, claimant: true },
      checked: value.claim && value.claimant,
    },
    {
      name: "Retain Claim",
      handleChange: { claim: true, claimant: false },
      checked: value.claim && !value.claimant,
    },
  ];

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        paddingLeft: "1rem",
        marginTop: "0.5rem",
      }}
    >
      {options.map((option) => (
        <Field className="radioWrapper">
          <Control>
            <Label style={{ display: "flex", gap: "0.5rem" }}>
              <Radio
                checked={option.checked}
                onChange={() => handleChange(option.handleChange)}
              />
              <span className="radioItem">{option.name}</span>
            </Label>
          </Control>
        </Field>
      ))}
    </div>
  );
};

RetainClaimAndClaimant.propTypes = {
  value: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default RetainClaimAndClaimant;
