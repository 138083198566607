import React, { useState } from "react";
import { Title, Field, Control, Button, Icon, Input } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import { useModal } from "../../../../context";
import DataTable from "../../../../components/DataTable";
import { LIST_EXCEPTION_LIST_QUERY } from "../../../../graphql";
import { ExceptionModal } from "../../components";
import { debounce } from "../../../../utils";
import COLUMNS from "./columns";

const DEFAULT_FILTER_STATE = {
  OR: [
    {
      Description: { contains: "" },
    },
    {
      Status: { contains: "" },
    },
  ],
};

const ExceptionsListPage = () => {
  const history = useHistory();
  const [where, setWhere] = useState(DEFAULT_FILTER_STATE);
  const { setModalOpen } = useModal();

  const handleRowClick = (row) => {
    history.push(`/setup/exceptions/${row.ExceptionID}`);
  };

  return (
    <div>
      <div className="section-header">
        <Title style={{ marginRight: 15 }}>Exceptions</Title>
        <Field kind="group">
          <Control>
            <Input
              autoComplete="off"
              id="search-input"
              placeholder="Search..."
              size="small"
              onChange={debounce(({ target: { value } }) => {
                if (value) {
                  setWhere({
                    OR: [
                      {
                        Description: { contains: value },
                      },
                      {
                        Status: { contains: value },
                      },
                    ],
                  });
                } else {
                  setWhere({
                    OR: [
                      {
                        Description: { contains: "" },
                      },
                      {
                        Status: { contains: "" },
                      },
                    ],
                  });
                }
              }, 500)}
            />
          </Control>
          <Control>
            <Button
              color="primary"
              size="small"
              onClick={() => {
                setModalOpen(
                  true,
                  <ExceptionModal
                    where={where}
                    onComplete={() => setModalOpen(false)}
                  />
                );
              }}
            >
              <Icon>
                <FontAwesomeIcon icon="plus" />
              </Icon>

              <span>Add</span>
            </Button>
          </Control>
        </Field>
      </div>
      <DataTable
        aggregateKey="ExceptionID"
        aggregateName="aggregateExceptionList"
        columns={COLUMNS}
        name="exceptionLists"
        orderBy={[{ id: "Description", desc: false }]}
        query={LIST_EXCEPTION_LIST_QUERY}
        where={where}
        onRowClick={handleRowClick}
      />
    </div>
  );
};

ExceptionsListPage.propTypes = {};

export default ExceptionsListPage;
