/* eslint-disable react/no-unescaped-entities */
import React from "react";
import PropTypes from "prop-types";
import { Control, Field, Input, Label, Box } from "rbx";

import styled from "styled-components";
import StateSelect from "../../../../components/StateSelect";
import CurrencyInput from "../../../../components/CurrencyInput";
import CourtSelect from "../CourtSelect";
import ClaimTypeSelect from "../../../../components/ClaimTypeSelect";

import ReferralStyledSubtitle from "../ReferralStyledSubtitle";
import RequestGenerator from "../RequestGenerator";
import WorkersCompensationForm from "../WorkersCompensationForm";

const StyledSubpoenaInformationFormContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1rem;
`;

const SubpoenaInformationForm = ({
  required,
  handleChange,
  inputs,
  disabled,
  requestMethod1,
  nonPartyRequestActive,
}) => (
  <Box className="col-span">
    <ReferralStyledSubtitle>
      Authorization / Subpoena Information
    </ReferralStyledSubtitle>
    <StyledSubpoenaInformationFormContainer id="subpoena-information-form">
      <div>
        <h2 className="sectionTitle">Plaintiff's Counsel Information</h2>
        <RequestGenerator
          disabled={disabled}
          handleChange={handleChange}
          inputs={inputs}
          label="Contact Name"
          required={required || requestMethod1 || nonPartyRequestActive}
          shouldFilter={false}
          type="ContactName"
        />
        <Field>
          <ClaimTypeSelect
            required
            disabled={disabled}
            label="Claim Type"
            name="ClaimType"
            value={inputs.ClaimType}
            onChange={handleChange}
          />
        </Field>
        <Field>
          <Control
            style={{
              marginTop: "3.2rem",
              padding: "1rem 0.2rem 3.2rem",
              boxShadow: "0 0.2rem 0.4rem 0 rgba(0, 80, 0, 0.26)",
              borderRadius: "0.6rem",
            }}
          >
            <Label size="medium" style={{ textAlign: "center" }}>
              User Reminders:
            </Label>
            <ul
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: "0.2rem",
                padding: "0 1rem",
                gap: "0.6rem",
                width: "100%",
              }}
            >
              <li>
                ◆ Enter Plaintiff's counsel field and legal case information if
                ISG will be preparing a subpoena.
              </li>
              <li>
                ◆ If ISG will have contact with opposing counsel (auth
                procurement or handling notice), please enter Plaintiff's
                Counsel field.
              </li>
              <li>
                ◆ If uploading authorization to this request, Plaintiff's
                Counsel and Legal Case Information are not required.
              </li>
              <li>
                ◆ N/A can be listed for any required fields that do not apply.
              </li>
            </ul>
          </Control>
        </Field>
      </div>
      <div>
        <h2 className="sectionTitle">Legal Case Information</h2>
        <Field>
          <Control>
            <Label>Caption</Label>
            <Input
              disabled={disabled}
              name="Caption"
              required={required || nonPartyRequestActive}
              size="small"
              value={inputs.Caption}
              onChange={(e) => handleChange(e.target.name, e.target.value)}
            />
          </Control>
        </Field>
        <Field>
          <Control>
            <Label>County</Label>
            <Input
              disabled={disabled}
              name="County"
              required={required || nonPartyRequestActive}
              size="small"
              value={inputs.County}
              onChange={(e) => handleChange(e.target.name, e.target.value)}
            />
          </Control>
        </Field>
        {inputs.ClaimType !== "TRCLWOR" && (
          <Field>
            <StateSelect
              displayFullLabel
              expanded
              disabled={disabled}
              label="State"
              name="SubpoenaState"
              required={required || nonPartyRequestActive}
              size="small"
              value={inputs.SubpoenaState || ""}
              onChange={handleChange}
            />
          </Field>
        )}
        <Field>
          <Control>
            <Label>Docket Number/ WC Bureau #</Label>
            <Input
              disabled={disabled}
              name="DocketNumber"
              required={required || nonPartyRequestActive}
              size="small"
              value={inputs.DocketNumber}
              onChange={(e) => handleChange(e.target.name, e.target.value)}
            />
          </Control>
        </Field>
        {inputs.ClaimType === "TRCLWOR" && (
          <WorkersCompensationForm
            disabled={disabled}
            handleChange={handleChange}
            inputs={inputs}
          />
        )}
        {inputs.ClaimType !== "TRCLWOR" && (
          <React.Fragment>
            <Field>
              <Control>
                <Label>Court</Label>
                <Input
                  disabled={disabled}
                  name="Court"
                  required={
                    (inputs.ClaimType !== "TRCLWOR" && required) ||
                    nonPartyRequestActive
                  }
                  size="small"
                  value={inputs.Court}
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                />
              </Control>
            </Field>
            <Field>
              <CourtSelect
                disabled={disabled}
                label="Court Address (VA/MA/DC/CA Only)"
                // required={required}
                name="CourtAddress"
                value={inputs.CourtAddress}
                onChange={(name, value) => handleChange(name, value)}
              />
            </Field>
            <Field>
              <Control>
                <Label>Branch (CA)</Label>
                <Input
                  disabled={disabled}
                  // required={required}
                  name="Branch"
                  size="small"
                  value={inputs.Branch}
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                />
              </Control>
            </Field>
            <Field>
              <Control>
                <Label>Division (FL/KY/LA Only)</Label>
                <Input
                  disabled={disabled}
                  // required={required}
                  name="Division"
                  size="small"
                  value={inputs.Division}
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                />
              </Control>
            </Field>
            <Field>
              <Control>
                <Label>Calendar Number (DC Only)</Label>
                <Input
                  disabled={disabled}
                  // required={required}
                  name="CalendarNumber"
                  size="small"
                  value={inputs.CalendarNumber}
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                />
              </Control>
            </Field>
            <Field>
              <CurrencyInput
                disabled={disabled}
                label="Court Fee (LA ONLY)"
                name="CourtFee"
                size="small"
                value={inputs.CourtFee}
                onChange={handleChange}
              />
            </Field>
            <Field>
              <CurrencyInput
                disabled={disabled}
                label="Sheriff Fee (LA ONLY)"
                name="SheriffFee"
                size="small"
                value={inputs.SheriffFee}
                onChange={handleChange}
              />
            </Field>
            <Field>
              <CurrencyInput
                disabled={disabled}
                label="Secretary of State Fee (LA ONLY)"
                name="SecretaryOfStateFee"
                size="small"
                value={inputs.SecretaryOfStateFee}
                onChange={handleChange}
              />
            </Field>
          </React.Fragment>
        )}
      </div>
    </StyledSubpoenaInformationFormContainer>
  </Box>
);

SubpoenaInformationForm.propTypes = {
  handleChange: PropTypes.func.isRequired,
  inputs: PropTypes.object.isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  requestMethod1: PropTypes.bool,
  nonPartyRequestActive: PropTypes.bool,
};

SubpoenaInformationForm.defaultProps = {
  required: false,
  disabled: false,
  requestMethod1: false,
  nonPartyRequestActive: false,
};

export default SubpoenaInformationForm;
