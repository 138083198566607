const activeProviderWithoutRecordType = (inputs) => {
  const activeProviders = inputs.Providers.filter((p) => !p.deleted);

  const recordTypeLengths = activeProviders.map(
    (each) => each?.RecordType.length
  );

  const doAnyEqualZero = recordTypeLengths.some((each) => each === 0);

  return doAnyEqualZero;
};

export default activeProviderWithoutRecordType;
