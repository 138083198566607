import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { getFilterState, saveFilterState } from "../../../utils";
import { useModal } from "../../../context";
import { ALL_CASE_HISTORY_QUERY } from "../../../graphql";
import FlatDataTable from "../../../components/FlatDataTable";
import CaseExportButton from "../../../components/CaseExportButton";
import { CASE_HISTORY_COLUMNS } from "../columns";
import CaseHistoryForm from "../components/CaseHistoryForm";
import CaseHistoryFilters from "../components/CaseHistoryFilters";

const CaseHistory = ({ casenbr }) => {
  const { setModalOpen } = useModal();
  const [showFilters, setShowFilters] = useState(false);
  const [where, setWhere] = useState(
    getFilterState("CASE_HISTORY_FILTERS", {})
  );

  const handleChange = (name, value) => {
    if (!value) {
      setWhere((prev) =>
        Object.keys(prev).reduce((acc, curr) => {
          if (curr !== name) acc[curr] = prev[curr];
          return acc;
        }, {})
      );
    } else {
      setWhere((prev) => ({ ...prev, [name]: value }));
    }
  };
  useEffect(() => {
    saveFilterState("CASE_HISTORY_FILTERS", where);
  }, [where]);

  const handleReset = () => {
    saveFilterState("CASE_HISTORY_FILTERS", {});
    setWhere({});
    if (document.getElementById("start-date")) {
      document.getElementById("start-date").value = "";
    }
    if (document.getElementById("end-date")) {
      document.getElementById("end-date").value = "";
    }
  };

  return (
    <div>
      <header className="page-head">
        <div className="page-head-start">
          <Button.Group>
            <Button
              color="primary"
              size="small"
              onClick={() =>
                setModalOpen(
                  true,
                  <CaseHistoryForm
                    casenbr={parseInt(casenbr, 10)}
                    onComplete={() => setModalOpen(false, "")}
                  />
                )
              }
            >
              <Icon>
                <FontAwesomeIcon icon="plus" />
              </Icon>
              <span>Add</span>
            </Button>
            <Button
              color="warning"
              size="small"
              onClick={() => setShowFilters((prev) => !prev)}
            >
              <Icon>
                <FontAwesomeIcon icon="filter" />
              </Icon>
            </Button>
            <CaseExportButton casenbr={casenbr} />
          </Button.Group>
        </div>
      </header>
      {showFilters && (
        <CaseHistoryFilters
          filters={where}
          onChange={handleChange}
          onReset={handleReset}
        />
      )}
      <FlatDataTable
        columns={CASE_HISTORY_COLUMNS}
        fetchPolicy="cache-and-network"
        name="caseHistories"
        orderBy={[
          { id: "eventdate", desc: true },
          { id: "id", desc: false },
        ]}
        query={ALL_CASE_HISTORY_QUERY}
        where={{
          ...where,
          casenbr: { equals: parseInt(casenbr, 10) },
        }}
        onCellClick={(item) =>
          setModalOpen(
            true,
            <CaseHistoryForm
              casenbr={parseInt(casenbr, 10)}
              data={item?.row?.original}
              onComplete={() => setModalOpen(false, "")}
            />
          )
        }
      />
    </div>
  );
};

CaseHistory.propTypes = {
  casenbr: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

CaseHistory.defaultProps = {
  casenbr: "",
};

export default CaseHistory;
