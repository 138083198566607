import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Title, Button } from "rbx";
import { useApolloClient, useMutation, useLazyQuery } from "@apollo/client";
import QueueForm from "../QueueForm";
import { useModal, useAuth } from "../../../../../context";
import {
  customToast as toast,
  getDateEST,
  convertTimeZoneDataBase,
} from "../../../../../utils";
import Confirmation from "../../../../../components/Confirmation";
import {
  FIND_FIRST_SERVICE_QUEUE_QUERY,
  CREATE_QUEUE_MUTATION,
  UPDATE_QUEUE_MUTATION,
  DELETE_QUEUE_MUTATION,
  // CREATE_AUDIT_TRAIL_MUTATION,
  LIST_QUEUES_QUERY,
  SINGLE_QUEUE_QUERY,
} from "../../../../../graphql";

const INITIAL_STATE = {
  shortdesc: "",
  statusdesc: "",
  status: "Active",
  type: "User",
  displayorder: 0,
};

const QueueModal = ({ onComplete, statuscode }) => {
  const client = useApolloClient();
  const { setModalOpen } = useModal();
  const { state: authState } = useAuth();
  const [inputs, setInputs] = useState(INITIAL_STATE);
  const [updated, setUpdated] = useState([]);
  const [loading, setLoading] = useState(false);

  const [getQueue, { data: queueData, loading: queryLoading }] = useLazyQuery(
    SINGLE_QUEUE_QUERY
  );
  const [createQueue] = useMutation(CREATE_QUEUE_MUTATION);
  const [updateQueue] = useMutation(UPDATE_QUEUE_MUTATION);
  const [deleteQueue] = useMutation(DELETE_QUEUE_MUTATION);
  // const [createAuditTrail] = useMutation(CREATE_AUDIT_TRAIL_MUTATION);

  useEffect(() => {
    if (statuscode) {
      getQueue({
        variables: {
          where: {
            statuscode,
          },
        },
      });
    }
  }, [getQueue, statuscode]);

  useEffect(() => {
    if (queueData?.queue) {
      setInputs({
        shortdesc: queueData.queue.shortdesc || "",
        statusdesc: queueData.queue.statusdesc || "",
        status: queueData.queue.status,
        type: queueData.queue.type || "User",
        displayorder: queueData.queue.displayorder || 0,
      });
    }
  }, [queueData]);

  const handleChange = (name, value) => {
    setInputs((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (statuscode) {
      setUpdated((prev) => (prev.includes(name) ? prev : [...prev, name]));
    }
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const date = convertTimeZoneDataBase(getDateEST());
      setLoading(true);
      if (!statuscode) {
        await createQueue({
          variables: {
            data: {
              ...inputs,
              displayorder: inputs.displayorder
                ? parseInt(inputs.displayorder, 10)
                : null,
              useridedited: authState.user.userid,
              dateedited: date,
              useridadded: authState.user.userid,
              dateadded: date,
            },
          },
          refetchQueries: [
            {
              query: LIST_QUEUES_QUERY,
            },
          ],
        });

        // await createAuditTrail({
        //   variables: {
        //     data: {
        //       tablename: "tblQueue",
        //       entityid: createQueueData.createQueue.statuscode.toString(),
        //       type: "New",
        //       description: "New Queue created",
        //       useridadded: authState.user.userid,
        //       dateadded: date,
        //     },
        //   },
        //   refetchQueries: [
        //     {
        //       query: SINGLE_QUEUE_QUERY,
        //       variables: {
        //         where: {
        //           statuscode: createQueueData.createQueue.statuscode,
        //         },
        //       },
        //     },
        //   ],
        // });
        toast.success("Queue created successfully.");
      } else {
        await updateQueue({
          variables: {
            data: {
              type: { set: inputs.type },
              shortdesc: { set: inputs.shortdesc },
              statusdesc: { set: inputs.statusdesc },
              status: { set: inputs.status },
              displayorder: {
                set: inputs.displayorder
                  ? parseInt(inputs.displayorder, 10)
                  : null,
              },
              useridedited: { set: authState.user.userid },
              dateedited: { set: date },
            },
            where: {
              statuscode,
            },
          },
          refetchQueries: [
            {
              query: LIST_QUEUES_QUERY,
            },
            {
              query: SINGLE_QUEUE_QUERY,
              variables: {
                where: {
                  statuscode,
                },
              },
            },
          ],
        });

        // await createAuditTrail({
        //   variables: {
        //     data: {
        //       tablename: "tblQueue",
        //       entityid: statuscode.toString(),
        //       type: "Update",
        //       description: `Updated fields: ${updated.join(", ")}`,
        //       useridadded: authState.user.userid,
        //       dateadded: date,
        //     },
        //   },
        // });
        toast.success("Queue updated successfully.");
      }
      onComplete();
    } catch (err) {
      toast.error("Error saving Queue");
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    const {
      data: { findFirstServiceQueue },
    } = await client.query({
      query: FIND_FIRST_SERVICE_QUEUE_QUERY,
      variables: {
        where: {
          statuscode: { equals: String(statuscode) },
        },
      },
    });

    if (findFirstServiceQueue) {
      toast.error("This queue is assigned a service and can't be removed.");
    } else {
      const performDelete = async () => {
        try {
          setLoading(true);
          await deleteQueue({
            variables: {
              where: {
                statuscode,
              },
            },
            refetchQueries: [
              {
                query: LIST_QUEUES_QUERY,
              },
              {
                query: SINGLE_QUEUE_QUERY,
                variables: {
                  where: {
                    statuscode,
                  },
                },
              },
            ],
          });
          toast.success("Queue deleted successfully");
          onComplete();
        } catch (err) {
          toast.error("Error trying to delete Queue");
        } finally {
          setLoading(false);
        }
      };
      setModalOpen(
        true,
        <Confirmation
          message="Are you sure you want to delete this Queue?"
          onCancel={() =>
            setModalOpen(
              true,
              <QueueModal statuscode={statuscode} onComplete={onComplete} />
            )
          }
          onConfirm={performDelete}
        />
      );
    }
  };

  const isDisabled =
    !inputs.shortdesc ||
    (statuscode && !updated?.length) ||
    queryLoading ||
    loading;

  return (
    <form id="queue-form" onSubmit={handleSubmit}>
      <header className="page-head">
        <div className="page-head-start">
          <Title size={5}>
            {`${!statuscode ? "Create" : "Edit"}`}
            &nbsp;Queue
          </Title>
        </div>
        <div className="page-head-end">
          <Button.Group hasAddons>
            <Button
              size="small"
              type="button"
              onClick={() => onComplete(false)}
            >
              <span>Cancel</span>
            </Button>

            <Button
              color="primary"
              disabled={isDisabled}
              form="queue-form"
              size="small"
              state={loading ? "loading" : ""}
              type="submit"
            >
              <span>Submit</span>
            </Button>
          </Button.Group>
        </div>
      </header>
      <hr />
      <QueueForm inputs={inputs} onChange={handleChange} />
      <hr />
      {statuscode && (
        <Button
          color="danger"
          size="small"
          type="button"
          onClick={handleDelete}
        >
          <span>Delete</span>
        </Button>
      )}
    </form>
  );
};

QueueModal.propTypes = {
  onComplete: PropTypes.func,
  statuscode: PropTypes.number,
};

QueueModal.defaultProps = {
  onComplete: () => null,
  statuscode: null,
};

export default QueueModal;
