import { format } from "date-fns";
import { convertTimeZone } from "../../../../utils";

const COLUMNS = [
  {
    Header: "Description",
    id: "NoteDescription",
    accessor: "NoteDescription",
  },
  {
    Header: "Text",
    id: "NoteText",
    accessor: "NoteText",
    maxWidth: "50%",
  },
  {
    Header: "Publish On Web",
    id: "PublishOnWeb",
    accessor: "PublishOnWeb",
    Cell: ({ cell }) => (cell?.value ? "Yes" : "No"),
  },
  {
    Header: "Status",
    id: "Status",
    accessor: "Status",
  },
  {
    Header: "Added",
    id: "DateAdded",
    accessor: "DateAdded",
    Cell: ({ cell }) =>
      cell?.value
        ? format(convertTimeZone(cell.value), "MM/dd/yyyy hh:mm a")
        : "",
  },
  {
    Header: "By",
    id: "UseridAdded",
    accessor: "UseridAdded",
  },
  {
    Header: "Edited",
    id: "DateEdited",
    accessor: "DateEdited",
    Cell: ({ cell }) =>
      cell?.value
        ? format(convertTimeZone(cell.value), "MM/dd/yyyy hh:mm a")
        : "",
  },
  {
    Header: "By",
    id: "UseridEdited",
    accessor: "UseridEdited",
  },
];

export default COLUMNS;
