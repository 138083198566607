import React from "react";
import PropTypes from "prop-types";

import DoctorSelect from "../DoctorSelect";
import { useDoctorsNotToUse } from "../../hooks";

const DrsDoNotUseDoctorSelect = ({
  casenbr,
  showLabel,
  name,
  value,
  label,
  onChange,
  disabled,
  required,
  getValue,
  setValue,
  showOnlyActives,
  where: initialWhere,
}) => {
  const doctorsNotToUse = useDoctorsNotToUse(casenbr);
  const where = {
    ...initialWhere,
    doctorcode: { notIn: doctorsNotToUse },
  };
  return (
    <DoctorSelect
      required
      showOnlyActives
      disabled={disabled}
      label={label}
      name="doctorcode"
      value={value}
      where={where}
      onChange={onChange}
    />
  );
};

DrsDoNotUseDoctorSelect.propTypes = {
  casenbr: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  showLabel: PropTypes.bool,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  getValue: PropTypes.func,
  setValue: PropTypes.func,
  showOnlyActives: PropTypes.bool,
  where: PropTypes.object,
};

DrsDoNotUseDoctorSelect.defaultProps = {
  casenbr: "",
  where: {},
  name: "",
  label: "",
  value: "",
  onChange: () => null,
  showLabel: true,
  disabled: false,
  required: false,
  getValue: (x) => x,
  setValue: (x) => x,
  showOnlyActives: false,
};

export default DrsDoNotUseDoctorSelect;
