import React, { useState } from "react";

import { Title, Field, Control, Button, Icon, Input } from "rbx";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { useModal } from "../../../../context";
import DataTable from "../../../../components/DataTable";
import {
  LIST_SERVICE_QUERY,
  DUPLICATE_SERVICE_MUTATION,
} from "../../../../graphql";
import { ServiceModal } from "../../components";
import { debounce, customToast as toast } from "../../../../utils";
import Loader from "../../../../components/Loader";
import { SERVICES_COLUMNS } from "./columns";

const DEFAULT_FILTER_STATE = {
  OR: [
    {
      description: { contains: "" },
    },
    {
      shortdesc: { contains: "" },
    },
  ],
};

const ServicesListPage = () => {
  const [where, setWhere] = useState(DEFAULT_FILTER_STATE);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { setModalOpen } = useModal();
  const [duplicateService] = useMutation(DUPLICATE_SERVICE_MUTATION);

  const handleRowClick = (row) => {
    history.push(`/setup/services/${row.servicecode}/main`);
  };

  const onDuplicateService = async (service) => {
    try {
      setLoading(true);
      await duplicateService({
        variables: {
          input: {
            servicecode: service.servicecode,
          },
        },
        refetchQueries: [
          {
            query: LIST_SERVICE_QUERY,
            variables: {
              where,
            },
          },
        ],
      });
    } catch (err) {
      toast.error("Error duplicating Service");
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <Loader />;

  return (
    <div>
      <div className="section-header">
        <Title style={{ marginRight: 15 }}>Services</Title>
        <Field kind="group">
          <Control>
            <Input
              autoComplete="off"
              id="search-input"
              placeholder="Search..."
              size="small"
              onChange={debounce(({ target: { value } }) => {
                if (value) {
                  setWhere({
                    OR: [
                      {
                        description: { contains: value },
                      },
                      {
                        shortdesc: { contains: value },
                      },
                    ],
                  });
                } else {
                  setWhere({
                    OR: [
                      {
                        description: { contains: "" },
                      },
                      {
                        shortdesc: { contains: "" },
                      },
                    ],
                  });
                }
              }, 500)}
            />
          </Control>
          <Control>
            <Button
              color="primary"
              size="small"
              onClick={() => {
                setModalOpen(
                  true,
                  <ServiceModal onComplete={(inputs) => setModalOpen(false)} />
                );
              }}
            >
              <Icon>
                <FontAwesomeIcon icon="plus" />
              </Icon>

              <span>Add</span>
            </Button>
          </Control>
        </Field>
      </div>
      <DataTable
        aggregateKey="servicecode"
        aggregateName="aggregateService"
        columns={SERVICES_COLUMNS(onDuplicateService)}
        name="services"
        orderBy={[{ id: "servicecode", desc: false }]}
        query={LIST_SERVICE_QUERY}
        where={where}
        onRowClick={handleRowClick}
      />
    </div>
  );
};

ServicesListPage.propTypes = {};

export default ServicesListPage;
