import React from "react";
import { Generic, Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const columns = [
  {
    Header: "Order",
    id: "queueorder",
    accessor: "queueorder",
    width: "5%",
  },

  {
    Header: "Status",
    id: "statusdesc",
    accessor: "queue.statusdesc",
    width: "45%",
  },
  {
    Header: "Next Status",
    id: "nextstatus",
    accessor: "NextStatus.statusdesc",
    width: "45%",
  },
];

export const generateColumns = (handleDelete) => [
  {
    Header: "",
    id: "delete",
    accessor: "statuscode",
    width: "5%",
    Cell: (item, index) => (
      <Generic
        style={{ textAlign: "center" }}
        type="button"
        onClick={(e) => {
          e.stopPropagation();
          handleDelete(item, index);
        }}
      >
        <Icon color="danger">
          <FontAwesomeIcon icon="times" />
        </Icon>
      </Generic>
    ),
  },
  ...columns,
];

export default columns;
