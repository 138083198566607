import React from "react";
import PropTypes from "prop-types";
import { Control, Label, Select } from "rbx";

const NativeSelect = ({
  label,
  size,
  name,
  onChange,
  disabled,
  value,
  required,
  id,
  fullWidth,
  options,
}) => (
  <Control
    expanded
    size={size}
    style={{
      border: required && !value ? "1px #e63362 solid" : "",
      borderRadius: "4px",
      boxShadow:
        required && !value ? "0 0 0 0.125em rgba(230, 51, 98, 0.25)" : "",
    }}
  >
    {label && <Label>{label}</Label>}
    <Select.Container fullwidth={fullWidth} size={size}>
      <Select
        disabled={disabled}
        id={id}
        name={name}
        required={required}
        value={value}
        onChange={onChange}
      >
        <Select.Option value="" />
        {options.map((item) => (
          <Select.Option key={item.value} value={item.value}>
            {item.label}
          </Select.Option>
        ))}
      </Select>
    </Select.Container>
  </Control>
);

NativeSelect.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  id: PropTypes.string,
  fullWidth: PropTypes.bool,
  options: PropTypes.array,
};
NativeSelect.defaultProps = {
  size: "small",
  fullWidth: false,
  disabled: false,
  value: "",
  name: "",
  label: null,
  required: false,
  onChange: () => {},
  id: null,
  options: [
    {
      name: "status",
      value: "Active",
      label: "Active",
    },
    {
      name: "status",
      value: "Inactive",
      label: "Inactive",
    },
  ],
};

export default NativeSelect;
