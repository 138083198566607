import Currency from "./Currency";
import Number from "./Number";
import Percent from "./Percent";
import Link from "./Link";
import Editable from "./Editable";
import Checkbox from "./Checkbox";

export const TableCell = {
  Currency,
  Number,
  Percent,
  Link,
  Editable,
  Checkbox,
};

export default TableCell;
