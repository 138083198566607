import { gql } from "@apollo/client";

export const ALL_LOCATIONS_QUERY = gql`
  query locations(
    $take: Int
    $where: LocationWhereInput
    $orderBy: [LocationOrderByInput!]
    $skip: Int
  ) {
    locations(take: $take, where: $where, orderBy: $orderBy, skip: $skip) {
      locationcode
      location
    }
  }
`;

export const SINGLE_LOCATION_QUERY = gql`
  query location($where: LocationWhereUniqueInput!) {
    location(where: $where) {
      locationcode
      location
      addr1
      addr2
      city
      state
      zip
      county
      Phone
      fax
      email
      contactprefix
      contactfirst
      contactlast
      status
      notes
    }
  }
`;
