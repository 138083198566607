/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from "react";
import PropTypes from "prop-types";

import { useTable, useSortBy, useExpanded } from "react-table";

import { Table as RbxTable, Title } from "rbx";

import "../../../../components/DataTable/DataTable.scss";

const ScheduleTableWithSubComponents = ({
  columns,
  data,
  onRowClick,
  initialSortBy,
  onChangeSort,
  onCellClick,
  getRowProps,
  renderRowSubComponent,
}) => {
  const tableState = {
    pageIndex: 0,
    sortBy: initialSortBy,
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    visibleColumns,
    state: { sortBy },
  } = useTable(
    {
      columns,
      data,
      initialState: { ...tableState },
    },
    useSortBy,
    useExpanded
  );

  const renderTableHeaderSort = (column) => {
    if (column.isSorted) {
      return column.isSortedDesc ? " ▼" : " ▲";
    }
    return "";
  };

  useEffect(() => {
    onChangeSort(sortBy);
  }, [onChangeSort, sortBy]);

  if (!data || (Array.isArray(data) && data.length === 0)) {
    return (
      <Title subtitle style={{ color: "gray" }}>
        Nothing here...
      </Title>
    );
  }

  const handleRowClick = (...args) => {
    const sel = window.getSelection().toString().length;
    if (sel) return;
    onRowClick(...args);
  };
  const handleCellClick = (...args) => {
    const sel = window.getSelection().toString().length;
    if (sel) return;
    onCellClick(...args);
  };

  return (
    <div className="data-table-container">
      <div className="table-container">
        <RbxTable {...getTableProps()} bordered fullwidth hoverable narrow>
          <RbxTable.Head>
            {headerGroups.map((headerGroup) => (
              <RbxTable.Row {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <RbxTable.Heading
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className={
                      ["Rush"].includes(column.render("Header"))
                        ? "right-align"
                        : ""
                    }
                    style={
                      typeof column.maxWidth === "string"
                        ? { width: column.maxWidth }
                        : { maxWidth: column.maxWidth || 400 }
                    }
                  >
                    {column.render("Header")}
                    <span>{renderTableHeaderSort(column)}</span>
                  </RbxTable.Heading>
                ))}
              </RbxTable.Row>
            ))}
          </RbxTable.Head>
          <RbxTable.Body {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              const { key, ...rowProps } = row.getRowProps();
              return (
                <React.Fragment key={key}>
                  <RbxTable.Row {...rowProps}>
                    {row.cells.map((cell) => (
                      <RbxTable.Cell
                        {...cell.getCellProps()}
                        onClick={(e) => {
                          if (cell.column.id !== "expander") {
                            handleRowClick(cell.row.original, cell.column.id);
                          }
                          handleCellClick(cell);
                        }}
                      >
                        {cell.render("Cell")}
                      </RbxTable.Cell>
                    ))}
                  </RbxTable.Row>

                  <RbxTable.Row {...rowProps}>
                    {row.isExpanded ? (
                      <RbxTable.Cell colSpan={visibleColumns.length}>
                        {renderRowSubComponent({ row })}
                      </RbxTable.Cell>
                    ) : null}
                  </RbxTable.Row>
                </React.Fragment>
              );
            })}
          </RbxTable.Body>
        </RbxTable>
      </div>
    </div>
  );
};

ScheduleTableWithSubComponents.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  onRowClick: PropTypes.func,
  initialSortBy: PropTypes.array,
  onChangeSort: PropTypes.func,
  onCellClick: PropTypes.func,
  getRowProps: PropTypes.func,
  renderRowSubComponent: PropTypes.func,
};

ScheduleTableWithSubComponents.defaultProps = {
  columns: [],
  data: [],
  initialSortBy: [],
  onRowClick: () => null,
  onChangeSort: () => null,
  onCellClick: () => null,
  getRowProps: () => {},
  renderRowSubComponent: () => {},
};

export default ScheduleTableWithSubComponents;
