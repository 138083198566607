const ACCT_TRANS_FIELDS = [
    {
      name: "#acctTrans",
      type: "String",
      entity: "acctTrans",
    },
    {
      name: "/acctTrans",
      type: "String",
      entity: "acctTrans",
    },
    {
      name: "type",
      type: "String",
      entity: "acctTrans",
    },
    {
      name: "documentnbr",
      type: "String",
      entity: "acctTrans",
    },
    {
      name: "documentdate",
      type: "DateTime",
      entity: "acctTrans",
    },
    {
      name: "otherinfo",
      type: "String",
      entity: "acctTrans",
    },
  ];
  
  export default ACCT_TRANS_FIELDS;
  