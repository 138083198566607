import React from "react";
import PropTypes from "prop-types";
import { Field, Input, Control, Label } from "rbx";
import QueueSelect from "../../../../../components/QueueSelect";
import BooleanInput from "../../../../../components/BooleanInput";

const ServiceQueueForm = ({ inputs, disabled, onChange }) => (
  <React.Fragment>
    <Field kind="group">
      <Control>
        <Label>Order</Label>
        <Input
          disabled
          autoComplete="new"
          name="queueorder"
          size="small"
          type="number"
          value={inputs.queueorder}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
      <QueueSelect
        disabled={disabled}
        label="Status"
        name="statuscode"
        value={inputs.statuscode}
        onChange={(name, value) => onChange(name, value)}
      />
      <Control>
        <Label>&nbsp;</Label>
        <BooleanInput
          disabled={disabled}
          label="Create Voucher"
          name="createvoucher"
          value={inputs.createvoucher}
          onChange={onChange}
        />
      </Control>
      <Control>
        <Label>&nbsp;</Label>
        <BooleanInput
          disabled={disabled}
          label="Create Invoice"
          name="createinvoice"
          value={inputs.createinvoice}
          onChange={onChange}
        />
      </Control>
    </Field>
  </React.Fragment>
);

ServiceQueueForm.propTypes = {
  inputs: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

ServiceQueueForm.defaultProps = {
  disabled: false,
};
export default ServiceQueueForm;
