const claim = {
  name: "claim",
  label: "Search",
  icon: "search",
  active: (location) => {
    const [, p] = location.pathname.split("/");
    return /claims/.test(p);
  },
  to: "/claims",
};

export default claim;
