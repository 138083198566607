import React from "react";
import PropTypes from "prop-types";
import { Control, Label, Select } from "rbx";
import { useQuery } from "@apollo/client";

import { LIST_PROVIDER_TYPES_QUERY } from "../../graphql";

const ProviderTypeSelect = ({
  value,
  name,
  label,
  size,
  onChange,
  disabled,
  required,
  getValue,
  setValue,
  showBlank,
}) => {
  const { data: providerTypeData } = useQuery(LIST_PROVIDER_TYPES_QUERY, {
    variables: { orderBy: { description: "asc" } },
  });

  const indicateRequired =
    required && (!value || value === "" || value === "0" || value === 0);

  const providerTypes = providerTypeData?.providerTypes;

  const handleChange = (e) => {
    onChange(e.target.name, setValue(e.target.value));
  };
  return (
    <Control expanded>
      <Label>{label}</Label>
      <Select.Container
        fullwidth
        size={size}
        state={Array.isArray(providerTypes) ? "" : "loading"}
      >
        <Select
          disabled={disabled}
          name={name}
          style={{
            border: indicateRequired ? "1px #e63362 solid" : "",
            borderRadius: "4px",
            boxShadow: indicateRequired
              ? "0 0 0 0.125em rgba(230, 51, 98, 0.25)"
              : "",
          }}
          value={getValue(value)}
          onChange={handleChange}
        >
          {showBlank && <Select.Option value="" />}
          {Array.isArray(providerTypes) &&
            providerTypes.map((providerType) => (
              <Select.Option
                key={providerType.ProvTypeCode}
                value={providerType.ProvTypeCode}
              >
                {providerType.description}
              </Select.Option>
            ))}
        </Select>
      </Select.Container>
    </Control>
  );
};

ProviderTypeSelect.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.number,
  ]),
  name: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  getValue: PropTypes.func,
  setValue: PropTypes.func,
  showBlank: PropTypes.bool,
};

ProviderTypeSelect.defaultProps = {
  value: "",
  name: "",
  label: "Provider Type",
  size: "small",
  onChange: () => null,
  disabled: false,
  required: false,
  getValue: (x) => x,
  setValue: (x) => x,
  showBlank: false,
};

export default ProviderTypeSelect;
