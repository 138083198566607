/* eslint-disable no-await-in-loop */
import { useMutation } from "@apollo/client";
import { useRef } from "react";
import { CALL_LINK_SERVICE } from "../graphql/linkService";

const handleProduct = (product) => {
  const prefix = product.slice(0, 2);
  if (prefix === "WR") return "RATS";
  if (product === "Records" || prefix === "RR") return "LSI";
  return "IMECentricDB";
};

const handleCaseId = (caseId) => {
  if (typeof caseId === "number") return caseId.toString();
  if (Array.isArray(caseId)) return caseId[0];
  if (typeof caseId === "string" && caseId.startsWith("WR")) return caseId; // Work request ids start with WR in the table
  const possiblePrefixes = ["MM", "RR", "IS"];
  const prefix = caseId.slice(0, 2);
  if (possiblePrefixes?.includes(prefix)) return caseId.slice(2);
  return caseId;
};

const handleRetain = (retain) => {
  if (typeof retain === "object") return retain.claim || retain.claimant;
  return retain;
};

const isDuplicate = (newId, currentQueue = []) =>
  currentQueue.some((entry) => entry?.id === newId);

const useLinkService = () => {
  const serviceQueue = useRef([]);

  const [submitLinkRequest] = useMutation(CALL_LINK_SERVICE);

  const addToLinkServiceQueue = async (caseIDs, retain, product) => {
    if (isDuplicate(handleCaseId(caseIDs), serviceQueue?.current)) return;

    const additionalObject = {
      id: handleCaseId(caseIDs),
      serviceCode: handleProduct(product),
      retaining: handleRetain(retain),
    };

    serviceQueue.current = [...serviceQueue.current, additionalObject];

    if (serviceQueue.current.length >= 2) {
      await callLinkService();
    }

    if (Array.isArray(caseIDs)) {
      let left = 0;
      let right = 1;
      while (right < caseIDs.length) {
        await submitLinkRequest({
          variables: {
            input: {
              linkFrom: {
                id: caseIDs[left],
                serviceCode: "IMECentricDB",
              },
              linkTo: {
                id: caseIDs[right],
                serviceCode: "IMECentricDB",
              },
            },
          },
        });

        left += 1;
        right += 1;
      }
    }
  };

  const callLinkService = async () => {
    const first = serviceQueue.current[serviceQueue.current.length - 2];
    const second = serviceQueue.current[serviceQueue.current.length - 1];

    if (first.retaining) {
      await submitLinkRequest({
        variables: {
          input: {
            linkFrom: { id: first.id, serviceCode: first.serviceCode },
            linkTo: { id: second.id, serviceCode: second.serviceCode },
          },
        },
      });
    }
  };

  return { addToLinkServiceQueue };
};

export default useLinkService;
