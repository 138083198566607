import React, { useState } from "react";
import PropTypes from "prop-types";

import { useParams } from "react-router-dom";

import { LIST_DOCTOR_LOCATIONS_QUERY } from "../../../../../graphql/doctorLocation";

import DoctorLocationModal from "../../../../Doctor/components/DoctorLocationModal";

import DataTable from "../../../../../components/DataTable";
import COLUMNS from "./columns";

import { useModal } from "../../../../../context";

const initialState = {};
const Offices = ({ onChange, inputs, disabled }) => {
  const [where] = useState(initialState);
  const { setModalOpen } = useModal();

  const { doctorcode } = useParams();

  const handleRowClick = ({ locationcode }) => {
    setModalOpen(
      true,
      <DoctorLocationModal
        doctorcode={doctorcode}
        locationcode={locationcode}
        refetchWhere={{
          doctorcode: { equals: parseInt(doctorcode, 10) },
          ...where,
        }}
        onComplete={() => setModalOpen(false, "")}
      />
    );
  };

  return (
    <div className="doctor-locations-page">
      <header className="page-head">
        <div className="page-head-end" />
      </header>
      <DataTable
        aggregateKey="doctorcode"
        aggregateName="aggregateDoctorLocation"
        columns={COLUMNS}
        fetchPolicy="cache-and-network"
        name="doctorLocations"
        query={LIST_DOCTOR_LOCATIONS_QUERY}
        where={{ doctorcode: { equals: parseInt(doctorcode, 10) }, ...where }}
        onRowClick={handleRowClick}
      />
    </div>
  );
};
Offices.propTypes = {
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  inputs: PropTypes.object.isRequired,
};

export default Offices;
