import React, { useState } from "react";
import PropTypes from "prop-types";
import { Control, Field, Label } from "rbx";

import RatsMedicalProviderSelect from "../../../../components/RatsMedicalProviderSelect";

import MedicalProviderActionButtons from "../MedicalProviderActionButtons";

const MedicalProvider = ({ handleArrChange, disabled, prov, index }) => {
  const [selectedAddress, setSelectedAddress] = useState([]);
  const [addressInputs, setAddressInputs] = useState({});

  return (
    <Field kind="group">
      <div style={{ flexGrow: 1 }}>
        <RatsMedicalProviderSelect
          required
          disabled={disabled && prov.fromDB}
          label="Provider"
          name="Provider"
          value={prov.Provider}
          onAddressInputsChange={(ai) => setAddressInputs(ai)}
          onChange={(name, value) => handleArrChange(index, name, value)}
          onSelectedAddressChange={(sa) => setSelectedAddress(sa)}
        />
      </div>
      <Control>
        <Label>&nbsp;</Label>
        <MedicalProviderActionButtons
          addressInputs={addressInputs}
          disabled={disabled}
          fromDB={!!prov.fromDB}
          selectedAddress={selectedAddress}
          onClearSelectionClick={() => handleArrChange(index, "Provider", "")}
          onMedicalProviderAdd={(medicalProvider) =>
            handleArrChange(index, "Provider", medicalProvider)
          }
          onRowClick={(row) => handleArrChange(index, "Provider", row.MPid)}
        />
      </Control>
    </Field>
  );
};

MedicalProvider.propTypes = {
  handleArrChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  prov: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};

export default MedicalProvider;
