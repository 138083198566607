import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { Button, Icon, Title } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  LIST_COMPANY_DEF_DOCUMENT_QUERY,
  DELETE_COMPANY_DEF_DOCUMENT_MUTATION,
} from "../../../../graphql";
import { useModal } from "../../../../context";
import FlatDataTable from "../../../../components/FlatDataTable";

import { generateColumns } from "./columns";
import Confirmation from "../../../../components/Confirmation";
import CompanyDefaultDocumentModal from "../CompanyDefaultDocumentModal";

const CompanyDefaultDocuments = ({ companycode }) => {
  const { setModalOpen } = useModal();
  const [deleteCompanyDefaultDocument] = useMutation(
    DELETE_COMPANY_DEF_DOCUMENT_MUTATION,
  );

  const COLUMNS = useMemo(() => {
    const handleDelete = (documentToRemove) => {
      const performDelete = async () => {
        try {
          setModalOpen(false, "");
          await deleteCompanyDefaultDocument({
            variables: {
              where: {
                companycode_documentcode_documentqueue: {
                  documentcode: documentToRemove.documentcode,
                  companycode: parseInt(documentToRemove.companycode, 10),
                  documentqueue: parseInt(documentToRemove.documentqueue, 10),
                },
              },
            },
            refetchQueries: [
              {
                query: LIST_COMPANY_DEF_DOCUMENT_QUERY,
                variables: {
                  where: {
                    companycode: { equals: parseInt(companycode, 10) },
                  },
                },
                fetchPolicy: "cache-first",
              },
            ],
          });
          toast.success("Document deleted successfully.");
        } catch (err) {
          toast.error(`Error deleting document.`);
        }
      };
      setModalOpen(
        true,
        <Confirmation
          message="Are you sure you want to delete this document?"
          onCancel={() => setModalOpen(false, "")}
          onConfirm={performDelete}
        />,
      );
    };
    return generateColumns(handleDelete);
  }, [companycode, deleteCompanyDefaultDocument, setModalOpen]);

  const handleAddClick = () => {
    setModalOpen(
      true,
      <CompanyDefaultDocumentModal
        companycode={companycode}
        onComplete={() => {
          setModalOpen(false, "");
        }}
      />,
    );
  };
  return (
    <div className="client-default-documents-page">
      <header className="page-head">
        <div className="page-head-start">
          <Button
            aria-label="Add Default Document"
            color="primary"
            size="small"
            type="button"
            onClick={handleAddClick}
          >
            <Icon>
              <FontAwesomeIcon icon="plus" />
            </Icon>
            <span>Add</span>
          </Button>
          <Title size={6}>Default Documents</Title>
        </div>
        <div className="page-head-end" />
      </header>
      <FlatDataTable
        columns={COLUMNS}
        fetchPolicy="cache-and-network"
        name="companyDefDocuments"
        orderBy={[
          { id: "order", desc: false },
          { id: "documentcode", desc: false },
        ]}
        query={LIST_COMPANY_DEF_DOCUMENT_QUERY}
        where={{ companycode: { equals: parseInt(companycode, 10) } }}
      />
    </div>
  );
};

CompanyDefaultDocuments.propTypes = {
  companycode: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
};

export default CompanyDefaultDocuments;
