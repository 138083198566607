import React, { useState } from "react";
import PropTypes from "prop-types";
import { useMutation, useApolloClient } from "@apollo/client";
import {
  Control,
  Label,
  Input,
  Title,
  Button,
  Field,
  Textarea,
  Help,
} from "rbx";
import { DateTime } from "luxon";
import {
  customToast as toast,
  getDateEST,
  convertTimeZoneDataBase,
} from "../../../utils";
import {
  CREATE_CASE_HISTORY_MUTATION,
  LIST_CASE_HISTORY_QUERY,
  UPDATE_CASE_HISTORY_MUTATION,
  DELETE_CASE_HISTORY_MUTATION,
  SINGLE_STANDARD_CASE_HISTORY_NOTE_QUERY,
} from "../../../graphql";
import { useModal, useAuth } from "../../../context";
import BooleanInput from "../../../components/BooleanInput";
import Confirmation from "../../../components/Confirmation";
import StandardNoteSelect from "../../../components/StandardNoteSelect";
import { usePermissions } from "../../../hooks";

const INITIAL_STATE = {
  eventdate: "",
  eventdesc: "",
  PublishOnWeb: false,
  otherinfo: "",
  NoteID: "",
};
const NOTE_LENGTH_LIMIT = 1000;

const CaseHistoryForm = ({ onComplete, casenbr, data }) => {
  const { state: authState } = useAuth();
  const client = useApolloClient();
  const isAdmin = usePermissions(["AllAdminFunc"]);
  const [createCaseHistory] = useMutation(CREATE_CASE_HISTORY_MUTATION);
  const [updateCaseHistory] = useMutation(UPDATE_CASE_HISTORY_MUTATION);
  const [deleteCaseHistory] = useMutation(DELETE_CASE_HISTORY_MUTATION);
  const [loading, setLoading] = useState(false);
  const { setModalOpen } = useModal();

  const [inputs, setInputs] = useState(
    data
      ? {
          ...data,
          eventdate: new Date(data.eventdate).toISOString().slice(0, 16),
        }
      : {
          ...INITIAL_STATE,
          eventdate: DateTime.now().toISO().slice(0, 16),
        }
  );

  const onChange = async (e, value) => {
    if (e === "NoteID") {
      if (value) {
        const { data: standardData } = await client.query({
          query: SINGLE_STANDARD_CASE_HISTORY_NOTE_QUERY,
          variables: {
            where: { NoteID: parseInt(value, 10) },
          },
        });
        const {
          findUniqueStandardCaseHistoryNotes: {
            NoteDescription,
            NoteText,
            PublishOnWeb,
          },
        } = standardData;
        setInputs((prev) => ({
          ...prev,
          eventdesc: NoteDescription,
          PublishOnWeb,
          otherinfo: NoteText,
          [e]: value,
        }));
      } else {
        setInputs((prev) => ({
          ...prev,
          eventdesc: "",
          PublishOnWeb: false,
          otherinfo: "",
          [e]: "",
        }));
      }
    } else if (e?.target?.type === "checkbox") {
      setInputs((prev) => ({
        ...prev,
        [e.target.name]: e.target.checked,
      }));
    } else {
      setInputs((prev) => ({
        ...prev,
        [e.target ? e.target.name : e]: e.target ? e.target.value : value,
      }));
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const date = convertTimeZoneDataBase(getDateEST());

      const eventDate = DateTime.fromFormat(
        String(inputs.eventdate).replace(/T/, " "),
        "yyyy-MM-dd HH:mm"
      ).toJSDate();

      if (data !== null) {
        await updateCaseHistory({
          variables: {
            data: {
              eventdate: { set: convertTimeZoneDataBase(eventDate) },
              eventdesc: { set: inputs.eventdesc },
              PublishOnWeb: { set: inputs.PublishOnWeb },
              otherinfo: { set: inputs.otherinfo },
              useridedited: { set: authState?.user?.userid },
              dateedited: { set: date },
              status: { set: inputs.status },
            },
            where: {
              id: data.id,
            },
          },
          refetchQueries: [
            {
              query: LIST_CASE_HISTORY_QUERY,
              variables: {
                where: {
                  casenbr: { equals: casenbr },
                },
              },
            },
          ],
        });
        toast.success("Case History updated successfully.");
      } else {
        await createCaseHistory({
          variables: {
            data: {
              casenbr,
              eventdate: convertTimeZoneDataBase(eventDate),
              eventdesc: inputs.eventdesc,
              PublishOnWeb: inputs.PublishOnWeb,
              otherinfo: inputs.otherinfo,
              userid: authState?.user?.userid,
              useridedited: authState?.user?.userid,
              dateedited: date,
              dateadded: date,
              status: inputs.status,
              type: inputs.status ? "StatChg" : null,
            },
          },
          refetchQueries: [
            {
              query: LIST_CASE_HISTORY_QUERY,
              variables: {
                where: {
                  casenbr: { equals: casenbr },
                },
              },
            },
          ],
        });
        toast.success("Case History created successfully.");
      }
      onComplete();
    } catch (err) {
      toast.error("Sorry, we had an error while saving. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    const performDelete = async () => {
      try {
        setLoading(true);
        await deleteCaseHistory({
          variables: {
            where: {
              id: data.id,
            },
          },
          refetchQueries: [
            {
              query: LIST_CASE_HISTORY_QUERY,
              variables: {
                where: {
                  casenbr: { equals: casenbr },
                },
              },
            },
          ],
        });
        toast.success("Case History entry deleted successfully");
        onComplete();
      } catch (err) {
        toast.error("Error trying to delete Case History entry");
      } finally {
        setLoading(false);
      }
    };
    setModalOpen(
      true,
      <Confirmation
        message="Are you sure you want to delete this Case History entry?"
        onCancel={() =>
          setModalOpen(
            true,
            <CaseHistoryForm
              casenbr={casenbr}
              data={data}
              onComplete={onComplete}
            />
          )
        }
        onConfirm={performDelete}
      />
    );
  };

  const isDisabled = loading || !inputs.eventdate || !inputs.eventdesc;

  const isAuthUser =
    data === null ||
    (data !== null && (isAdmin || authState?.user.userid === data?.userid));

  const note = inputs?.otherinfo || "";
  const isNoteTooLong = note.length >= NOTE_LENGTH_LIMIT;

  return (
    <form id="add-case-history-form" onSubmit={handleSave}>
      <header className="page-head">
        <Title size={5}>
          {`${data !== null ? "Edit" : "Create"}  Case History`}
        </Title>
        <div className="page-head-end">
          <Button.Group hasAddons>
            <Button size="small" type="button" onClick={onComplete}>
              <span>Cancel</span>
            </Button>
            <Button
              color="primary"
              disabled={isDisabled || !isAuthUser}
              form="add-case-history-form"
              size="small"
              state={loading ? "loading" : ""}
              type="submit"
            >
              <span>Submit</span>
            </Button>
          </Button.Group>
        </div>
      </header>
      <hr />

      <Field kind="group">
        <Control>
          <Label>Case</Label>
          <Input readOnly size="small" value={casenbr} />
        </Control>
        <Control>
          <Label>Event Date</Label>
          <Input
            required
            autoComplete="new"
            disabled={loading || !isAuthUser}
            name="eventdate"
            size="small"
            type="datetime-local"
            value={inputs.eventdate}
            onChange={onChange}
          />
        </Control>
        <Control>
          <Label>Publish to Web</Label>
          <BooleanInput
            disabled={loading || !isAuthUser}
            name="PublishOnWeb"
            value={inputs.PublishOnWeb}
            onChange={onChange}
          />
        </Control>

        {!data && (
          <StandardNoteSelect
            disabled={loading || !isAuthUser}
            name="NoteID"
            value={inputs.NoteID}
            onChange={onChange}
          />
        )}
      </Field>

      <Field kind="group">
        <Control expanded>
          <Label>Event Description</Label>
          <Input
            autoComplete="new"
            disabled={loading || !isAuthUser}
            maxLength={50}
            name="eventdesc"
            size="small"
            type="text"
            value={inputs.eventdesc}
            onChange={onChange}
          />
        </Control>
      </Field>

      <Field>
        <Control expanded>
          <Label>Other info</Label>
          <Textarea
            disabled={loading || !isAuthUser}
            maxLength={NOTE_LENGTH_LIMIT}
            name="otherinfo"
            rows="6"
            size="small"
            value={inputs.otherinfo}
            onChange={onChange}
          />
          <Help color={isNoteTooLong ? "danger" : "primary"}>
            {`${note.length} / ${NOTE_LENGTH_LIMIT} Characters`}
          </Help>
        </Control>
      </Field>
      <hr />
      {data && (
        <Button
          color="danger"
          disabled={loading || !isAuthUser}
          size="small"
          type="button"
          onClick={handleDelete}
        >
          <span>Delete</span>
        </Button>
      )}
    </form>
  );
};

CaseHistoryForm.propTypes = {
  onComplete: PropTypes.func,
  casenbr: PropTypes.number.isRequired,
  data: PropTypes.object,
};

CaseHistoryForm.defaultProps = {
  onComplete: (e) => e,
  data: null,
};

export default CaseHistoryForm;
