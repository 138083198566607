import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useLazyQuery, useMutation } from "@apollo/client";
import { Title, Button } from "rbx";
import IconLink from "../../../../components/IconLink";
import { usePermissions } from "../../../../hooks";
import { useAuth } from "../../../../context";
import {
  SINGLE_OTHER_PARTY_QUERY,
  UPDATE_DOCTOR_MUTATION,
  LIST_OTHER_PARTIES_QUERY,
} from "../../../../graphql";
import { OtherPartyTabs } from "../../components";
import {
  customToast as toast,
  getDateEST,
  convertTimeZoneDataBase,
} from "../../../../utils";

const INITIAL_STATE = {
  doctorcode: "",
  OPSubType: "",
  firstname: "",
  lastname: "",
  status: "Active",
  companyname: "",
  addr1: "",
  city: "",
  state: "",
  zip: "",
  phone: "",
  notes: "",
};

const OtherPartyEditPage = (props) => {
  const history = useHistory();
  const { doctorcode, tab } = useParams();
  const isAdmin = usePermissions(["AllAdminFunc"]);
  const [inputs, setInputs] = useState(INITIAL_STATE);
  const [disabled, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const { state: authState } = useAuth();

  const [getOtherParty, { data: otherPartyData }] = useLazyQuery(
    SINGLE_OTHER_PARTY_QUERY
  );
  const [updateOtherParty] = useMutation(UPDATE_DOCTOR_MUTATION);

  useEffect(() => {
    if (doctorcode) {
      getOtherParty({
        variables: {
          where: { doctorcode: parseInt(doctorcode, 10) },
        },
      });
    }
  }, [doctorcode, getOtherParty]);

  useEffect(() => {
    const doctor = otherPartyData?.doctor;
    if (doctor) {
      setInputs((prev) => ({
        ...prev,
        ...doctor,
      }));
    }
  }, [otherPartyData]);

  const handleSave = async (e) => {
    e.preventDefault();
    setLoading(true);
    const date = convertTimeZoneDataBase(getDateEST());
    try {
      await updateOtherParty({
        variables: {
          where: {
            doctorcode: parseInt(doctorcode, 10),
          },
          data: {
            OtherPartyType: {
              connect: {
                type: inputs.OPSubType,
              },
            },
            firstname: { set: inputs.firstname },
            lastname: { set: inputs.lastname },
            status: { set: inputs.status },
            companyname: { set: inputs.companyname },
            addr1: { set: inputs.addr1 },
            city: { set: inputs.city },
            state: { set: inputs.state },
            zip: { set: inputs.zip },
            phone: { set: inputs.phone },
            notes: { set: inputs.notes },
            useridedited: { set: authState.user.userid },
            dateedited: { set: date },
          },
        },
        refetchQueries: [
          {
            query: SINGLE_OTHER_PARTY_QUERY,
            variables: {
              where: { doctorcode: parseInt(doctorcode, 10) },
            },
          },
          {
            query: LIST_OTHER_PARTIES_QUERY,
            variables: {
              where: {
                OPType: {
                  equals: "OP",
                },
              },
            },
          },
        ],
      });
      toast.success("Other Party updated successfully");
    } catch (err) {
      toast.error("Error on update");
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (name, value) => {
    setInputs((prev) => ({ ...prev, [name]: value }));
  };

  const isDisabled =
    Object.keys(inputs)
      .map(
        (key) =>
          /${firstname|lastname|companyname|addr1|city|state|zip|phone|OPSubType}/.test(
            key
          ) && !inputs[key]
      )
      .includes(true) || loading;

  return (
    <React.Fragment>
      <form
        className="add-company-form"
        id="add-company-form"
        key="edit-company-page"
        onSubmit={handleSave}
      >
        <header className="page-head">
          <div className="page-head-start">
            <Title>
              {`${inputs?.companyname || ""} ${inputs?.firstname || ""} 
              ${inputs?.lastname || ""} ${inputs?.OPSubType || ""}`}
            </Title>
            <div style={{ marginLeft: "2rem" }}>
              <IconLink
                href={`tel:${inputs?.phone}`}
                icon="phone"
                label={inputs?.phone}
              />
            </div>
          </div>
          {isAdmin && (
            <div className="page-head-end">
              <Button.Group hasAddons style={{ justifyContent: "flex-end" }}>
                <Button
                  color="primary"
                  disabled={loading}
                  size="small"
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setEditMode(!disabled);
                  }}
                >
                  Edit
                </Button>
                <Button
                  color="success"
                  disabled={isDisabled}
                  size="small"
                  state={loading ? "loading" : ""}
                  type="submit"
                >
                  Save
                </Button>
              </Button.Group>
            </div>
          )}
        </header>
      </form>
      <OtherPartyTabs
        disabled={disabled}
        doctorcode={doctorcode}
        history={history}
        inputs={inputs}
        tab={tab}
        onChange={handleChange}
      />
    </React.Fragment>
  );
};

OtherPartyEditPage.propTypes = {};

export default OtherPartyEditPage;
