import React from "react";

import PropTypes from "prop-types";

import { Control, Label, Select } from "rbx";

import { useQuery } from "@apollo/client";
import { LIST_INSIGHT_CODES_QUERY } from "../../graphql";

const InsightCodeSelect = ({
  value,
  name,
  label,
  size,
  onChange,
  disabled,
  getValue,
  setValue,
  required,
  where,
}) => {
  const { data: insightCodeData } = useQuery(LIST_INSIGHT_CODES_QUERY, {
    variables: { where, orderBy: { Description: "asc" } },
  });

  const insightCodes = insightCodeData?.insightCodes;

  const handleChange = (e) => {
    onChange(e.target.name, setValue(e.target.value || ""));
  };

  return (
    <Control expanded>
      <Label>{label}</Label>
      <Select.Container
        fullwidth
        size={size}
        state={Array.isArray(insightCodes) ? "" : "loading"}
      >
        <Select
          disabled={disabled}
          name={name}
          required={required}
          value={getValue(value)}
          onChange={handleChange}
        >
          <Select.Option value="" />
          {Array.isArray(insightCodes) &&
            insightCodes.map((code) => (
              <Select.Option key={code.Code} value={code.Code}>
                {code.Description}
              </Select.Option>
            ))}
        </Select>
      </Select.Container>
    </Control>
  );
};

InsightCodeSelect.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  name: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  getValue: PropTypes.func,
  setValue: PropTypes.func,
  where: PropTypes.object,
};

InsightCodeSelect.defaultProps = {
  value: "",
  name: "",
  label: "Case Type",
  size: "small",
  onChange: () => null,
  disabled: false,
  required: false,
  getValue: (x) => x,
  setValue: (x) => x,
  where: {},
};

export default InsightCodeSelect;
