import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useLazyQuery } from "@apollo/client";
import { Content } from "rbx";
import { SINGLE_CC_ADDRESS_QUERY } from "../../graphql";

const CCAddressDisplay = ({ cccode }) => {
  const [getCCAddress, { data: CCAddressData }] = useLazyQuery(
    SINGLE_CC_ADDRESS_QUERY
  );
  useEffect(() => {
    if (cccode) {
      getCCAddress({
        variables: { where: { cccode: parseInt(cccode, 10) } },
      });
    }
  }, [cccode, getCCAddress]);

  const cCAddress = CCAddressData?.cCAddress;

  const showName = cCAddress?.firstname || cCAddress?.lastname;
  const showAddress = cCAddress?.address1 || cCAddress?.address2;
  const showCityStateZip =
    cCAddress?.city || cCAddress?.state || cCAddress?.zip;

  return cccode && cCAddress ? (
    <Content size="small">
      <address>
        {showName && (
          <React.Fragment>
            <span>
              {[cCAddress.prefix, cCAddress.firstname, cCAddress.lastname]
                .filter(Boolean)
                .join(" ")}
            </span>
            <br />
          </React.Fragment>
        )}

        {showAddress && (
          <React.Fragment>
            <span>
              {cCAddress.address1}
              &nbsp;
              {cCAddress.address2}
            </span>
            <br />
          </React.Fragment>
        )}
        {showCityStateZip && (
          <React.Fragment>
            <span>
              {cCAddress.city}
              ,&nbsp;
              {cCAddress.state}
              &nbsp;
              {cCAddress.zip}
            </span>
            <br />
          </React.Fragment>
        )}
        {cCAddress.phone && (
          <React.Fragment>
            <span>
              Phone:&nbsp;
              {cCAddress.phone}
            </span>
            <br />
          </React.Fragment>
        )}
        {cCAddress.fax && (
          <span>
            Fax:&nbsp;
            {cCAddress.fax}
          </span>
        )}
      </address>
    </Content>
  ) : null;
};

CCAddressDisplay.propTypes = {
  cccode: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default CCAddressDisplay;
