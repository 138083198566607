import { gql } from "@apollo/client";

export const SINGLE_USER_GROUP_QUERY = gql`
  query userGroup($where: UserGroupWhereUniqueInput!) {
    userGroup(where: $where) {
      groupcode
      groupdesc
    }
  }
`;

export const LIST_USER_GROUPS_QUERY = gql`
  query userGroups(
    $take: Int
    $skip: Int
    $orderBy: [UserGroupOrderByInput!]
    $where: UserGroupWhereInput
  ) {
    userGroups(take: $take, orderBy: $orderBy, skip: $skip, where: $where) {
      groupcode
      groupdesc
    }
    aggregateUserGroup(where: $where) {
      count {
        groupcode
      }
    }
  }
`;