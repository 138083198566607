import React from "react";
import { Generic, Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { format } from "date-fns";
import { convertTimeZone } from "../../../../utils";

import CaseDocumentDownloadLink from "../../components/CaseDocumentDownloadLink";

export const DEFAULT_COLUMNS = [
  // {
  //   id: "type",
  //   Header: "Type",
  //   accessor: "type",
  // },
  // {
  //   id: "document",
  //   Header: "Document",
  //   accessor: "document",
  // },
  {
    id: "apolloDocument.documentType.DocumentType",
    Header: "Type",
    accessor: "apolloDocument.documentType.DocumentType",
    Cell: ({ cell }) => cell.value || "--",
    disableSortBy: false,
  },
  {
    id: "description",
    Header: "Description",
    accessor: "description",
  },
  // {
  //   id: "reporttype",
  //   Header: "Report Type",
  //   accessor: "reporttype",
  //   Cell: ({ cell }) => cell.value || "--",
  // },
  {
    Header: "Filename",
    id: "sfilename",
    accessor: "sfilename",
    Cell: ({ cell }) => {
      const val = cell?.value;
      if (val) {
        if (/\\/.test(val)) {
          return cell.value.slice(cell.value.lastIndexOf("\\") + 1);
        }
        return cell.value.slice(cell.value.lastIndexOf("/") + 1);
      }
      return "--";
    },
  },
  // {
  //   id: "PublishedTo",
  //   Header: "Published To",
  //   accessor: "PublishedTo",
  // },
  // {
  //   id: "WebSynchDate",
  //   Header: "Web Sync Date",
  //   accessor: "WebSynchDate",
  //   Cell: ({ cell }) =>
  //     cell?.value
  //       ? format(convertTimeZone(cell.value), "MM/dd/yyyy hh:mm a")
  //       : "--",
  // },
  {
    id: "apolloDocument.documentLocation.DocumentLocation",
    Header: "Location",
    accessor: "apolloDocument.documentLocation.DocumentLocation",
    Cell: ({ cell }) => cell.value || "--",
    disableSortBy: false,
  },

  {
    id: "dateadded",
    Header: "Added",
    accessor: "dateadded",
    Cell: ({ cell }) =>
      cell?.value
        ? format(convertTimeZone(cell.value), "MM/dd/yyyy hh:mm a")
        : "--",
  },
  {
    id: "useridadded",
    Header: "By",
    accessor: "useridadded",
  },
  // {
  //   id: "dateedited",
  //   Header: "Date Edited",
  //   accessor: "dateedited",
  //   Cell: ({ cell }) =>
  //     cell?.value
  //       ? format(convertTimeZone(cell.value), "MM/dd/yyyy hh:mm a")
  //       : "--",
  // },
  // {
  //   id: "useridedited",
  //   Header: "User Edited",
  //   accessor: "useridedited",
  //   Cell: ({ cell }) => cell.value || "--",
  // },
];

export const generateColumns = (handleDelete, handlePublish, openDocument) => [
  {
    Header: "",
    id: "delete",
    Cell: (cell) => (
      <Generic
        style={{ textAlign: "center" }}
        title="Delete"
        type="button"
        onClick={(e) => {
          e.stopPropagation();
          handleDelete(cell.row.original);
        }}
      >
        <Icon color="danger">
          <FontAwesomeIcon icon="times" />
        </Icon>
      </Generic>
    ),
  },
  ...DEFAULT_COLUMNS,
  {
    id: "PublishOnWeb",
    Header: "Web",
    accessor: "PublishOnWeb",
    Cell: (cell) => (
      <Generic
        style={{ textAlign: "center" }}
        textColor={cell?.value ? "success" : "danger"}
        title="Publish On Web"
        type="button"
        onClick={(e) => {
          e.stopPropagation();
          handlePublish(cell.row.original);
        }}
      >
        <Icon color="inherit">
          <FontAwesomeIcon icon={cell.value ? "eye" : "eye-slash"} />
        </Icon>
      </Generic>
    ),
  },
  {
    Header: "Download",
    id: "download",
    Cell: (cell) => (
      <CaseDocumentDownloadLink caseDocument={cell.row.original} />
    ),
  },
  {
    Header: "Open",
    id: "generateShortcut",
    Cell: (cell) => (
      <Generic
        style={{ textAlign: "center" }}
        textColor="success"
        title="Open"
        type="button"
        onClick={(e) => {
          e.stopPropagation();
          const {
            casenbr,
            sfilename,
            CaseItem: { dateadded },
          } = cell.row.original;
          openDocument({ casenbr, sfilename, dateadded });
        }}
      >
        <Icon color="inherit">
          <FontAwesomeIcon icon="folder-open" />
        </Icon>
      </Generic>
    ),
  },
];

export default DEFAULT_COLUMNS;
