import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { Title, Button, Icon } from "rbx";
import {
  useLazyQuery,
  useMutation,
  useQuery,
  useApolloClient,
} from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  ALL_CLIENT_DEFAULT_DOCUMENTS_QUERY,
  ALL_COMPANY_DEFAULT_DOCUMENTS_QUERY,
  GENERATE_CASE_DOCUMENT_MUTATION,
  LIST_CASE_DOCUMENT_QUERY,
  SINGLE_CASE_QUERY,
  SINGLE_DOCUMENT_QUERY,
  FIND_FIRST_DOCUMENT_QUERY,
} from "../../../../graphql";
import {
  customToast as toast,
  runPromisesInSeries,
  sleep,
} from "../../../../utils";
import { useAuth } from "../../../../context/AuthContext";

import CaseGenerateDocumentForm from "../CaseGenerateDocumentForm";

const INITIAL_STATE = {
  description: "",
  document: "external",
  PublishOnWeb: false,
  reporttype: "document",
  SeqNo: 293,
  format: "",
};

const CaseGenerateDocumentModal = ({ onComplete, casenbr }) => {
  const client = useApolloClient();
  const { state: authState } = useAuth();
  const [inputs, setInputs] = useState(INITIAL_STATE);
  const [loading, setLoading] = useState(false);
  const [documents, setDocuments] = useState([]);

  const { data: CaseItemData } = useQuery(SINGLE_CASE_QUERY, {
    variables: { where: { casenbr: parseInt(casenbr, 10) } },
  });

  const [
    getClientDefDocuments,
    { data: GetClientDefDocumentsData },
  ] = useLazyQuery(ALL_CLIENT_DEFAULT_DOCUMENTS_QUERY, {
    fetchPolicy: "cache-and-network",
  });

  const [
    getCompanyDefDocuments,
    { data: GetCompanyDefDocumentsData },
  ] = useLazyQuery(ALL_COMPANY_DEFAULT_DOCUMENTS_QUERY, {
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    const clientcode = CaseItemData?.caseItem?.clientcode;
    const companycode = CaseItemData?.caseItem?.client?.companycode;
    const statuscode = CaseItemData?.caseItem?.status;

    if (clientcode && companycode && statuscode) {
      getClientDefDocuments({
        variables: {
          where: {
            clientcode: { equals: parseInt(clientcode, 10) },
            documentqueue: { equals: parseInt(statuscode, 10) },
          },
        },
      });
      getCompanyDefDocuments({
        variables: {
          where: {
            companycode: { equals: parseInt(companycode, 10) },
            documentqueue: { equals: parseInt(statuscode, 10) },
          },
        },
      });
    }
  }, [getClientDefDocuments, getCompanyDefDocuments, CaseItemData]);

  useEffect(() => {
    const clientDefDocuments = GetClientDefDocumentsData?.clientDefDocuments;
    const companyDefDocuments = GetCompanyDefDocumentsData?.companyDefDocuments;
    const userDefDocument = {
      id: new Date().valueOf(),
      source: "User",
      description: "",
      document: "",
      status: "",
      documentType: "",
      format: "",
    };
    if (
      Array.isArray(clientDefDocuments) &&
      Array.isArray(companyDefDocuments)
    ) {
      const documentsToGenerate = [
        ...clientDefDocuments,
        ...companyDefDocuments,
      ].map((document, i) => ({
        id: i,
        source:
          document?.__typename === "ClientDefDocument" ? "Client" : "Company",
        description: document?.document?.description || "",
        document: document?.documentcode,
        format: document?.outputFormat,
        status: "",
      }));
      setDocuments([...documentsToGenerate, userDefDocument]);
    } else {
      setDocuments([userDefDocument]);
    }
  }, [GetClientDefDocumentsData, GetCompanyDefDocumentsData]);

  const [getDocument, { data: DocumentData }] = useLazyQuery(
    SINGLE_DOCUMENT_QUERY
  );

  const [generateCaseDocument] = useMutation(GENERATE_CASE_DOCUMENT_MUTATION);

  const handleChange = (id) => async (name, value) => {
    setDocuments((prev) => {
      const currDocuments = [...prev];
      const idx = currDocuments.findIndex((doc) => doc.id === id);
      if (idx > -1) {
        currDocuments.splice(idx, 1, { ...currDocuments[idx], [name]: value });
      }
      return currDocuments;
    });

    if (name === "document" && value) {
      const { data: FindFirstDocumentData } = await client.query({
        query: FIND_FIRST_DOCUMENT_QUERY,
        variables: { where: { document: { equals: value } } },
      });

      const document = FindFirstDocumentData?.findFirstDocument;

      if (document) {
        handleChange(id)("description", document?.description);
        handleChange(id)("format", document?.outputFormat);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    await runPromisesInSeries(
      documents.map((document, i) => async () => {
        try {
          const options = {
            variables: {
              data: {
                casenbr: parseInt(casenbr, 10),
                document: document.document,
                description: document.description,
                userid: authState?.user?.userid,
                documentType: document.documentType,
                format: document.format,
              },
            },
          };
          if (i === documents.length - 1) {
            options.refetchQueries = [
              {
                query: LIST_CASE_DOCUMENT_QUERY,
                variables: {
                  where: { casenbr: { equals: parseInt(casenbr, 10) } },
                },
              },
            ];
          }
          handleChange(document.id)("status", "loading");
          await generateCaseDocument(options);
          handleChange(document.id)("status", "success");
        } catch (err) {
          handleChange(document.id)("status", "error");
        }
      })
    );

    toast.success(`Case Document generated successfully`);
    setLoading(false);
    await sleep(500);
    onComplete();
  };

  const isSubmitDisabled = useMemo(
    () =>
      documents.some(
        (doc) =>
          /loading|success|error/.test(doc.status) ||
          !doc.description.length ||
          !doc.document.length ||
          !doc.documentType
      ),
    [documents]
  );

  const isDisabled = useMemo(
    () => documents.some((doc) => /loading|success|error/.test(doc.status)),
    [documents]
  );

  useEffect(() => {
    if (inputs?.SeqNo) {
      getDocument({
        variables: { where: { SeqNo: parseInt(inputs.SeqNo, 10) } },
      });
    }
  }, [getDocument, inputs.SeqNo]);

  useEffect(() => {
    const description = DocumentData?.document?.description;
    if (description) {
      setInputs((prev) => ({ ...prev, description }));
    }
  }, [DocumentData]);

  const handleAddDocumentToGenerate = () => {
    setDocuments((prev) => [
      ...prev,
      {
        id: prev.length,
        source: "User",
        description: "",
        document: "",
        status: "",
        format: "",
      },
    ]);
  };

  const handleRemove = (id) => () => {
    setDocuments((prev) => {
      const currDocuments = [...prev];
      const idx = currDocuments.findIndex((doc) => doc.id === id);
      if (idx > -1) {
        currDocuments.splice(idx, 1);
      }
      return currDocuments;
    });
  };

  return (
    <form id="case-generate-document-form" onSubmit={handleSubmit}>
      <header className="page-head">
        <div className="page-head-start">
          <Title size={5}>Generate Documents</Title>
        </div>
        <div className="page-head-end">
          <Button.Group hasAddons>
            <Button
              size="small"
              type="button"
              onClick={() => onComplete(false)}
            >
              <span>Cancel</span>
            </Button>
            <Button
              color="primary"
              disabled={isSubmitDisabled}
              form="case-generate-document-form"
              size="small"
              state={loading ? "loading" : ""}
              type="submit"
            >
              <span>Submit</span>
            </Button>
          </Button.Group>
        </div>
      </header>
      <hr />
      {documents.map((document, i) => (
        <CaseGenerateDocumentForm
          disabled={isDisabled}
          inputs={document}
          key={document.id}
          showLabels={i === 0}
          onChange={handleChange(document.id)}
          onRemove={handleRemove(document.id)}
        />
      ))}
      <hr />
      <Button
        color="info"
        disabled={isDisabled}
        size="small"
        type="button"
        onClick={handleAddDocumentToGenerate}
      >
        <Icon>
          <FontAwesomeIcon icon="plus" />
        </Icon>
        <span>Add</span>
      </Button>
      {/* <pre>
        <code>{JSON.stringify(documents, null, 2)}</code>
      </pre> */}
    </form>
  );
};

CaseGenerateDocumentModal.propTypes = {
  onComplete: PropTypes.func.isRequired,
  casenbr: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default CaseGenerateDocumentModal;
