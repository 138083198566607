import React, { useMemo, useContext } from "react";

import PropTypes from "prop-types";
import { useMutation } from "@apollo/client";
import { Column, Title, Icon, Button } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";

import { ModalContext } from "../../../../../context/ModalContext";
import FlatDataTable from "../../../../../components/FlatDataTable";
import Confirmation from "../../../../../components/Confirmation";
import { customToast as toast } from "../../../../../utils";
import UserGroupFunctionForm from "../UserGroupFunctionForm";

import {
  LIST_USER_GROUP_FUNCTIONS_QUERY,
  DELETE_USER_GROUP_FUNCTION_MUTATION,
} from "../../../../../graphql";
import { COLUMNS_USER_FUNCTIONS as generateUserFunctionsColumns } from "./columns";

const UserGroupFunction = ({ inputs, disabled }) => {
  const { setModalOpen } = useContext(ModalContext);
  const history = useHistory();

  const [deleteUserGroupFunction] = useMutation(
    DELETE_USER_GROUP_FUNCTION_MUTATION
  );

  const COLUMNS_USER_FUNCTIONS = useMemo(() => {
    const handleDeleteUserFunction = ({ usergroupfunctionid, entityid }) => {
      const performDelete = async () => {
        try {
          await deleteUserGroupFunction({
            variables: {
              where: {
                usergroupfunctionid,
              },
            },
            refetchQueries: [
              {
                query: LIST_USER_GROUP_FUNCTIONS_QUERY,
                variables: {
                  where: {
                    entityid: { equals: entityid },
                  },
                },
              },
            ],
          });
          toast.success("User Group Function deleted successfully");
          setModalOpen(false);
          history.push(`/setup/user-groups/${inputs.entityid}`);
        } catch (err) {
          toast.error("Error on delete User Group Function");
        }
      };
      setModalOpen(
        true,
        <Confirmation
          message="Are you sure you want to delete this User Group Function?"
          onCancel={() => setModalOpen(false)}
          onConfirm={performDelete}
        />
      );
    };
    return generateUserFunctionsColumns(handleDeleteUserFunction, disabled);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setModalOpen, deleteUserGroupFunction, inputs?.entityid, disabled]);

  return (
    <Column.Group multiline>
      <Column>
        <div className="page-head">
          <div className="page-head-start">
            <Button
              color="primary"
              size="small"
              type="button"
              onClick={() => {
                setModalOpen(
                  true,
                  <UserGroupFunctionForm
                    entityid={inputs.entityid}
                    handleCancel={() => {
                      setModalOpen(false);
                      history.push(`/setup/user-groups/${inputs.entityid}`);
                    }}
                  />
                );
              }}
            >
              <Icon>
                <FontAwesomeIcon icon="plus" />
              </Icon>
            </Button>
            <Title size={6}>User Functions</Title>
          </div>
        </div>
        <div style={{ maxHeight: 400, minHeight: 100, overflowY: "auto" }}>
          <FlatDataTable
            columns={COLUMNS_USER_FUNCTIONS}
            name="userGroupFunctions"
            orderBy={[{ id: "functioncode", desc: false }]}
            query={LIST_USER_GROUP_FUNCTIONS_QUERY}
            where={{ entityid: { equals: inputs.entityid } }}
          />
        </div>
      </Column>
    </Column.Group>
  );
};

UserGroupFunction.propTypes = {
  inputs: PropTypes.object,
  disabled: PropTypes.bool,
};

UserGroupFunction.defaultProps = {
  inputs: {},
  disabled: false,
};

export default UserGroupFunction;
