/* eslint-disable import/prefer-default-export */
import { gql } from "@apollo/client";

export const CREATE_ISSUE_MUTATION = gql`
  mutation createIssue($data: IssueCreateInput!) {
    createIssue(data: $data) {
      description
      issuecode
      status
      useridadded
      useridedited
    }
  }
`;

export const UPDATE_ISSUE_MUTATION = gql`
  mutation updateIssue(
    $data: IssueUpdateInput!
    $where: IssueWhereUniqueInput!
  ) {
    updateIssue(data: $data, where: $where) {
      description
      issuecode
      status
      useridadded
      useridedited
    }
  }
`;

export const DELETE_ISSUE_MUTATION = gql`
  mutation DELETE_ISSUE_MUTATION($where: IssueWhereUniqueInput!) {
    deleteIssue(where: $where) {
      issuecode
    }
  }
`;
