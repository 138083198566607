import { gql } from "@apollo/client";

export const CREATE_CLIENT_MUTATION = gql`
  mutation createClient($data: ClientCreateInput!) {
    createClient(data: $data) {
      clientcode
      lastname
      firstname
      addr1
      addr2
      city
      lastappt
      phone1
    }
  }
`;

export const UPDATE_MANY_CLIENTS_MUTATION = gql`
  mutation UPDATE_MANY_CLIENTS_MUTATION(
    $data: ClientUpdateManyMutationInput!
    $where: ClientWhereInput!
  ) {
    updateManyClient(data: $data, where: $where) {
      count
    }
  }
`;

export const UPDATE_CLIENT_MUTATION = gql`
  mutation updateClient(
    $data: ClientUpdateInput!
    $where: ClientWhereUniqueInput!
  ) {
    updateClient(data: $data, where: $where) {
      clientcode
      lastname
      firstname
      addr1
      addr2
      city
      lastappt
      phone1
      TypeCode
    }
  }
`;

export const DELETE_CLIENT_MUTATION = gql`
  mutation deleteClient($where: ClientWhereUniqueInput!) {
    deleteClient(where: $where) {
      clientcode
    }
  }
`;
