import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { useMutation, useQuery } from "@apollo/client";
import { Title, Button } from "rbx";
import { useLocation } from "react-router-dom";
import { format } from "date-fns";
import {
  ALL_CASE_DOCUMENTS_QUERY,
  SINGLE_CASE_QUERY,
  CREATE_CASE_HISTORY_MUTATION,
  ALL_CASE_HISTORY_QUERY,
} from "../../../../graphql";
import {
  getDateEST,
  convertTimeZoneDataBase,
  runPromisesInSeries,
} from "../../../../utils";
import CasePrintDocumentForm from "../CasePrintDocumentForm";
import { useCaseDocumentPath, useCaseStatusChange } from "../../../../hooks";
import { useAuth } from "../../../../context";

const CasePrintDocumentModal = ({ onComplete, casenbr }) => {
  const getCaseVirtualDocumentPath = useCaseDocumentPath(casenbr);
  const executeCaseStatusChange = useCaseStatusChange(casenbr);
  const [documents, setDocuments] = useState([]);
  const location = useLocation();
  const { state: authState } = useAuth();
  const [createCaseHistory] = useMutation(CREATE_CASE_HISTORY_MUTATION);
  const { data: CaseData } = useQuery(SINGLE_CASE_QUERY, {
    variables: { where: { casenbr: parseInt(casenbr, 10) } },
  });
  const { data: GetCaseDocumentsData, loading } = useQuery(
    ALL_CASE_DOCUMENTS_QUERY,
    {
      variables: {
        where: {
          casenbr: { equals: parseInt(casenbr, 10) },
          OR: [
            {
              reporttype: { not: { equals: "Report" } },
            },
            {
              reporttype: { equals: null },
            },
          ],
        },
      },
      fetchPolicy: "cache-and-network",
    }
  );
  useEffect(() => {
    const docs = GetCaseDocumentsData?.caseDocuments;
    if (Array.isArray(docs)) {
      setDocuments(docs.map((d, i) => ({ ...d, id: i, numberOfCopies: 0 })));
    }
  }, [GetCaseDocumentsData?.caseDocuments]);
  const handleChange = (id) => (name, value) => {
    const currDocs = [...documents];
    const idx = currDocs.findIndex((d) => d.id === id);
    if (idx > -1) {
      currDocs.splice(idx, 1, { ...currDocs[idx], [name]: value });
      setDocuments(currDocs);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const documentsToPrint = documents.filter(
      (document) => document.printDocument
    );
    runPromisesInSeries(
      documentsToPrint.map((document, i) => {
        const loc = getCaseVirtualDocumentPath(document.sfilename, true);
        window.open(loc, "_blank");

        const date = convertTimeZoneDataBase(getDateEST());

        return createCaseHistory({
          variables: {
            data: {
              casenbr: parseInt(casenbr, 10),
              eventdesc: `Prntd - ${document.sfilename}`,
              eventdate: date,
              dateedited: date,
              dateadded: date,
              type: "Other",
              otherinfo: `Printed ${format(
                new Date(getDateEST()),
                "MM/dd/yyyy hh:mm a"
              )}`,
              userid: authState?.user?.userid,
              useridedited: authState?.user?.userid,
            },
          },
          refetchQueries: [
            i === documentsToPrint.length - 1 && {
              query: ALL_CASE_HISTORY_QUERY,
              variables: {
                where: { casenbr: { equals: parseInt(casenbr, 10) } },
              },
            },
          ].filter(Boolean),
        });
      })
    );

    // if status is Appointment Letters and we have dynamically triggered the generate modal
    if (
      location.state?.triggerGenerateModal &&
      CaseData?.caseItem?.status === 2
    ) {
      executeCaseStatusChange(77); // 77 = Awaiting Medical Records
    }

    onComplete();
  };
  const isDisabled = useMemo(
    () => !documents.some((doc) => doc.printDocument),
    [documents]
  );
  return (
    <form id="print-case-document-form" onSubmit={handleSubmit}>
      <header className="page-head">
        <div className="page-head-start">
          <Title size={5}>Print Documents</Title>
        </div>
        <div className="page-head-end">
          <Button.Group hasAddons>
            <Button
              size="small"
              type="button"
              onClick={() => onComplete(false)}
            >
              <span>Cancel</span>
            </Button>
            <Button
              color="primary"
              disabled={isDisabled}
              form="print-case-document-form"
              size="small"
              state={loading ? "loading" : ""}
              type="submit"
            >
              <span>Print</span>
            </Button>
          </Button.Group>
        </div>
      </header>
      <hr />
      {documents.map((document, i) => (
        <CasePrintDocumentForm
          inputs={document}
          key={document.id}
          showLabels={i === 0}
          onChange={handleChange(document.id)}
        />
      ))}
      <hr />
    </form>
  );
};
CasePrintDocumentModal.propTypes = {
  onComplete: PropTypes.func.isRequired,
  casenbr: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};
export default CasePrintDocumentModal;
