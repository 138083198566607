import React, { useState, useEffect } from "react";
import { Tab, Icon, Button, Control, Field } from "rbx";
import { useLazyQuery } from "@apollo/client";
import { Link, useParams, useLocation, Route, Switch } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useModal } from "../../context";
import { Documents, Notes, Services } from "./routes";

import { ClaimHeader, ClaimServiceModal } from "./components";

import { convertTimeZone } from "../../utils";

import { SINGLE_CE_CLAIM_QUERY } from "../../graphql";

import Loader from "../../components/Loader";

const INITIAL_STATE = {
  CentralEligibilityID: "",
  CompanyName: "",
  ExternalClaimID: "",
  ClaimNumber: "",
  DateOfLoss: "",
  SOJ: "",
  ExternalPatientID: "",
  ClaimantIPNumber: "",
  ClaimantFirstName: "",
  ClaimantMiddleName: "",
  ClaimantLastName: "",
  DateOfBirth: "",
  Address1: "",
  Address2: "",
  City: "",
  State: "",
  Zip5: "",
  Zip4: "",
  Phone1: "",
  Phone2: "",
  SSN: "",
  Sex: "",
  CoverageType: "",
  EligibilityStatus: "",
  HCP: "",
};

const ClaimPage = () => {
  const { setModalOpen } = useModal();
  const { claimcode } = useParams();
  const [inputs, setInputs] = useState(INITIAL_STATE);
  const [getClaimData, resultClaim] = useLazyQuery(SINGLE_CE_CLAIM_QUERY);

  const location = useLocation();

  useEffect(() => {
    if (claimcode && claimcode !== "add") {
      getClaimData({
        variables: {
          where: { CentralEligibilityID: parseInt(claimcode, 10) },
        },
        fetchPolicy: "network-only",
      });
    }
  }, [claimcode, getClaimData]);

  useEffect(() => {
    if (resultClaim?.data?.findUniqueClaims && !resultClaim?.loading) {
      const {
        data: { findUniqueClaims },
      } = resultClaim;
      if (findUniqueClaims) {
        // This is to prevent warning on changing state values from defined to undefined
        const clearClaim = Object.fromEntries(
          Object.entries(findUniqueClaims).filter(([_, v]) => v !== null)
        );
        setInputs((prev) => ({
          ...prev,
          ...clearClaim,
          DateOfBirth: convertTimeZone(clearClaim.DateOfBirth),
        }));
      }
    }
  }, [resultClaim]);

  if (resultClaim?.loading) return <Loader />;
  return (
    <div className="claim-page">
      <ClaimHeader claimcode={claimcode} />
      <div className="page-head">
        <div className="page-head-start">
          <Tab.Group
            kind="boxed"
            style={{
              width: location.pathname.includes("services") ? "95%" : "100%",
            }}
          >
            <Tab
              active={location.pathname.includes("services")}
              as={Link}
              to={`/claims/${claimcode}/services`}
            >
              Services
            </Tab>
            <Tab
              active={location.pathname.includes("documents")}
              as={Link}
              to={`/claims/${claimcode}/documents`}
            >
              Documents
            </Tab>
            <Tab
              active={location.pathname.includes("notes")}
              as={Link}
              to={`/claims/${claimcode}/notes`}
            >
              Notes
            </Tab>
          </Tab.Group>
        </div>
        {location.pathname.includes("services") ? (
          <div className="page-head-end">
            <Field kind="group">
              <Control>
                <Button
                  color="primary"
                  size="small"
                  onClick={() => {
                    setModalOpen(
                      true,
                      <ClaimServiceModal
                        ClaimID={parseInt(claimcode, 10)}
                        onComplete={() => setModalOpen(false)}
                      />
                    );
                  }}
                >
                  <Icon>
                    <FontAwesomeIcon icon="plus" />
                  </Icon>
                  <span>Add Service</span>
                </Button>
              </Control>
            </Field>
          </div>
        ) : null}
      </div>
      <Switch>
        <Route path="/claims/:claimcode/documents">
          <Documents claim={inputs} />
        </Route>
        <Route path="/claims/:claimcode/services/:serviceid?/:servicetype?/:workid?">
          <Services claim={inputs} />
        </Route>
        <Route path="/claims/:claimcode/notes">
          <Notes claim={inputs} />
        </Route>
      </Switch>
      {/* <pre>
        <code>{JSON.stringify(inputs, null, 2)}</code>
      </pre> */}
    </div>
  );
};

ClaimPage.propTypes = {};

export default ClaimPage;
