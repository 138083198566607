import React, { useState } from "react";
import PropTypes from "prop-types";
import { Title, Button, Field, Control, Label, Input } from "rbx";
import { useMutation } from "@apollo/client";
import BusinessCard from "../BusinessCard";
import DataTable from "../../../../components/DataTable";

import { RATS_CREATE_INSURANCE_CARRIER_MUTATION, RATS_INSURANCE_CARRIER_QUERY } from "../../../../graphql";

import {
  convertTimeZoneDataBase,
  customToast as toast,
  getDateEST,
} from "../../../../utils";

import { useReferrer } from "../../../../hooks";

const DEFAULT_STATE = {
  ICname: "",
};

const InsuranceCarrierEntryModal = ({
  onComplete,
  title,
  mode,
  label,
  onInsuranceCarrierAdd,
  selected,
  setSelected,
  onRowSelect
}) => {
  const [inputs, setInputs] = useState({ ...DEFAULT_STATE });
  const [loading, setLoading] = useState(false);
  const [where, setWhere] = useState({})

  const [createInsuranceCarrier] = useMutation(
    RATS_CREATE_INSURANCE_CARRIER_MUTATION
  );

  const referrer = useReferrer();

  const handleChange = (name, value) => {
    setInputs((prev) => ({ ...prev, [name]: value }));
  };

  const handleAddInsuranceCarrier = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);

      const data = {
        ICname: inputs.ICname,
        ICenteredBy: referrer?.ClientLogin,
        ICenteredDate: convertTimeZoneDataBase(getDateEST()),
      };

      const {
        data: { createInsurancecarrier },
      } = await createInsuranceCarrier({ variables: { data } });

      onInsuranceCarrierAdd(createInsurancecarrier.PK_ICid);
    } catch (err) {
      toast.error(
        "Something went wrong, please try again later or contact support"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleSelect = (rowData) => {
    setSelected({value: rowData.PK_ICid, label: rowData.ICname,})
    onRowSelect(rowData.PK_ICid)
  }

  const handleReset = () => {
    setInputs({...DEFAULT_STATE})
    setWhere({})
  }

  const disabled = mode === "add" && inputs.ICname === "";

  const searchButtons = (
    <Field kind="group">
      <Button.Group>
        <Button
          color="danger"
          disabled={!inputs.ICname}
          size="small"
          onClick={handleReset}
        >
          Reset
        </Button>
        <Button
          color="primary"
          disabled={!inputs.ICname}
          size="small"
          onClick={()=> setWhere({ICname: {contains: inputs.ICname}})}
        >
          Search
        </Button>
      </Button.Group>
    </Field>
  )

  const dataTable = (
    <React.Fragment>
      <hr />
      <DataTable
        aggregateKey="PK_ICid"
        aggregateName="aggregateInsuranceCarrier"
        columns={[
          {
            Header: "Info",
            id: "businessCard",
            disableSortBy: true,
            Cell: (cell) => (
              <BusinessCard
                isInTable
                data={[cell.row.values.ICname]}
                disabled={!cell.row.values}
                onSeeMoreClick={() => null}
              />
            )
          },
          {
            Header: "PK_ICid",
            id: "PK_ICid",
            accessor: "PK_ICid",
          },
          {
            Header: "ICname",
            id: "ICname",
            accessor: "ICname",
          },
        ]}
        loaderHeight="293px"
        name="insurancecarriers"
        orderBy={[
          { id: "ICname", desc: false },
        ]}
        pageSize={10}
        query={RATS_INSURANCE_CARRIER_QUERY}
        showPageSize={false}
        where={where}
        onCellClick={(cell, e) => {
          const { column: { id } } = cell;
          if (id === 'businessCard') {
            e.stopPropagation();
          }
        }}
        onRowClick={(rowData)=>handleSelect(rowData)}
      />
    </React.Fragment>
  )


  return (
    <React.Fragment>
      <form id="rg-form" onSubmit={handleAddInsuranceCarrier}>
        <header className="page-head">
          <div className="page-head-start">
            <Title size={5}>{title}</Title>
          </div>
          <div className="page-head-end">
            <Button.Group hasAddons>
              <Button
                disabled={loading}
                size="small"
                type="button"
                onClick={onComplete}
              >
                <span>{mode === 'view' ? 'Close' : 'Cancel'}</span>
              </Button>
              {mode === "add" && (
                <Button
                  color="primary"
                  disabled={disabled || loading}
                  form="rg-form"
                  size="small"
                  state={loading ? "loading" : ""}
                  type="submit"
                >
                  <span>Submit</span>
                </Button>
              )}
            </Button.Group>
          </div>
        </header>
        <hr />
        <Field>
          <Control>
            <Label>Insurance Carrier</Label>
            <Input
              required
              disabled={mode === 'view'}
              name="ICname"
              size="small"
              value={mode === 'view' ? selected.label : inputs.ICname}
              onChange={(e) => handleChange(e.target.name, e.target.value)}
            />
          </Control>
        </Field>
      </form>

      {!!Object.keys(where).length && dataTable}

      <hr />

      {mode === "search" && searchButtons}
    </React.Fragment>
  );
};

InsuranceCarrierEntryModal.propTypes = {
  onComplete: PropTypes.func,
  onInsuranceCarrierAdd: PropTypes.func,
  title: PropTypes.string,
  mode: PropTypes.string,
  label: PropTypes.string,
  selected: PropTypes.object,
  setSelected: PropTypes.func,
  onRowSelect: PropTypes.func,
};

InsuranceCarrierEntryModal.defaultProps = {
  onComplete: (e) => e,
  onInsuranceCarrierAdd: () => null,
  title: "",
  mode: "",
  label: "",
  selected: {},
  setSelected: ()=> null,
  onRowSelect: ()=> null,
};

export default InsuranceCarrierEntryModal;
