import { gql } from "@apollo/client";

export const RATS_REQUEST_GENERATOR_QUERY = gql`
  query Requestgenerators(
    $where: RequestgeneratorWhereInput
    $orderBy: [RequestgeneratorOrderByInput!]
    $take: Int
    $skip: Int
  ) {
    requestgenerators(
      where: $where
      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      RGid
      RGclientOfficeID
      RGnameLast
      RGnameFirst
      RGcourtID
      RGemail
      RGemail2
      clientoffice {
        COid
        COofficeName
        COaddress1
        COaddress2
        COcity
        COstate
        COzip
        COphone1
        COfax
        COclientMasterID
      }
    }
    aggregateRequestgenerator: aggregateRequestgenerator(where: $where) {
      count {
        RGid
      }
    }
  }
`;

export const RATS_SINGLE_REQUEST_GENERATOR_QUERY = gql`
  query Requestgenerator($where: RequestgeneratorWhereUniqueInput!) {
    requestgenerator(where: $where) {
      RGid
      RGclientOfficeID
      RGnameLast
      RGnameFirst
      RGcourtID
      RGemail
      RGemail2
      clientoffice {
        COid
        COofficeName
        COaddress1
        COaddress2
        COcity
        COstate
        COzip
        COphone1
        COfax
        CCOnErfEmail
        COclientMasterID
        clientmaster {
          shouldFilterContacts
        }
      }
    }
  }
`;

export const RATS_REQUEST_FOR_CLIENT_OFFICE_QUERY = gql`
  query Requestgenerators($where: RequestgeneratorWhereInput) {
    requestgenerators(where: $where) {
      RGid
      RGemail
      RGclientOfficeID
      clientoffice {
        COclientMasterID
        clientmaster {
          shouldFilterContacts
        }
      }
    }
  }
`;
