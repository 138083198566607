/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DraggableTableItem from "./DraggableTableItem";

const getBackgroundColor = (isDraggingOver, isDraggingFrom) => {
  if (isDraggingOver) {
    return "#d6d6d6";
  }
  if (isDraggingFrom) {
    return "#d6d6d6";
  }
  return "#f1f1f1";
};

const scrollContainerHeight = 250;

const DropZone = styled.div`
  min-height: ${scrollContainerHeight}px;
  height: 100%;
  background-color: ${(props) =>
    getBackgroundColor(props.isDraggingOver, props.isDraggingFrom)};
  border-bottom: solid 1px #f2f2f2;
  border-left: solid 1px #f2f2f2;
  border-right: solid 1px #f2f2f2;
`;

const ItemContainer = styled.div`
  height: 26px;
  border-bottom: solid 1px #f2f2f2;
  margin-bottom: 2px;
`;

// /////////////////////////////
// INNER ITEM LIST COMPONENT //
// ///////////////////////////
const InnerItemList = ({ items, name, columns, onRowClick, loading }) =>
  items.map((item, index) => (
    <ItemContainer key={item.id}>
      <Draggable
        draggableId={item.id}
        index={index}
        isDragDisabled={loading}
        key={item.id}
        shouldRespectForceTouch={false}
      >
        {(dragProvided, dragSnapshot) => (
          <DraggableTableItem
            columns={columns}
            index={index}
            isDragging={dragSnapshot.isDragging}
            isGroupedOver={Boolean(dragSnapshot.combineTargetFor)}
            item={item}
            key={item.id}
            loading={loading}
            name={name}
            provided={dragProvided}
            onRowClick={onRowClick}
          />
        )}
      </Draggable>
    </ItemContainer>
  ));

InnerItemList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  name: PropTypes.string.isRequired,
  columns: PropTypes.array.isRequired,
  onRowClick: PropTypes.func,
  loading: PropTypes.bool,
};

InnerItemList.defaultProps = {
  items: [],
  onRowClick: () => null,
  loading: false,
};

// ///////////////////////
// INNER LIST COMPONENT //
// //////////////////////
const InnerList = ({
  items,
  dropProvided,
  title,
  name,
  isDraggingOver,
  isDraggingFrom,
  columns,
  onRowClick,
  loading,
}) => (
  <DropZone
    isDraggingFrom={isDraggingFrom}
    isDraggingOver={isDraggingOver}
    ref={dropProvided.innerRef}
  >
    <InnerItemList
      columns={columns}
      items={Array.isArray(items) ? items : []}
      loading={loading}
      name={name}
      onRowClick={onRowClick}
    />
    {dropProvided.placeholder}
  </DropZone>
);
InnerList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  dropProvided: PropTypes.object,
  title: PropTypes.string,
  name: PropTypes.string.isRequired,
  isDraggingOver: PropTypes.bool,
  isDraggingFrom: PropTypes.bool,
  columns: PropTypes.array.isRequired,
  onRowClick: PropTypes.func,
  loading: PropTypes.bool,
};
InnerList.defaultProps = {
  items: [],
  dropProvided: {},
  title: "",
  loading: false,
  isDraggingOver: false,
  isDraggingFrom: false,
  onRowClick: () => null,
};

const DraggableTable = ({
  style,
  items,
  title,
  name,
  onDragEnd,
  columns,
  onRowClick,
  loading,
}) => (
  <DragDropContext onDragEnd={onDragEnd}>
    <Droppable
      isCombineEnabled
      droppableId="board"
      ignoreContainerClipping={false}
      isDropDisabled={false}
      type="ITEM"
    >
      {(dropProvided, dropSnapshot) => (
        <InnerList
          columns={columns}
          dropProvided={dropProvided}
          isDraggingFrom={Boolean(dropSnapshot.draggingFromThisWith)}
          isDraggingOver={dropSnapshot.isDraggingOver}
          items={items}
          loading={loading}
          name={name}
          title={title}
          onRowClick={onRowClick}
        />
      )}
    </Droppable>
  </DragDropContext>
);

DraggableTable.propTypes = {
  style: PropTypes.object,
  items: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  name: PropTypes.string.isRequired,
  onDragEnd: PropTypes.func,
  columns: PropTypes.array.isRequired,
  onRowClick: PropTypes.func,
  loading: PropTypes.bool,
};

DraggableTable.defaultProps = {
  style: {},
  items: [],
  title: "",
  loading: false,
  onDragEnd: () => null,
  onRowClick: () => null,
};

export default DraggableTable;
