import React from "react";
import { startOfDay, endOfDay, isValid } from "date-fns";
import PropTypes from "prop-types";
import { Box, Field, Control, Button, Label } from "rbx";
import FilterInput from "../../../components/FilterInput";
import DateInput from "../../../components/DateInput";

const CaseHistoryFilters = ({ filters, onChange, onReset }) => (
  <Box>
    <div className="list-filters">
      <Field kind="group">
        <DateInput
          getValue={(x) => {
            const date = x?.gte;
            return date ? date.substr(0, 10) : "";
          }}
          id="start-date"
          label="Start Event Date"
          name="eventdate"
          setValue={(x) => {
            if (
              x &&
              isValid(new Date(x.split("-").map((y) => parseInt(y, 10))))
            ) {
              return {
                lte:
                  filters.eventdate?.lte ||
                  endOfDay(
                    new Date(x.split("-").map((y) => parseInt(y, 10)))
                  ).toISOString(),
                gte: startOfDay(
                  new Date(x.split("-").map((y) => parseInt(y, 10)))
                ).toISOString(),
              };
            }
            return "";
          }}
          value={filters.eventdate?.gte || ""}
          onChange={onChange}
        />
        <DateInput
          getValue={(x) => {
            const date = x?.lte;
            return date ? date.substr(0, 10) : "";
          }}
          id="end-date"
          label="End Event Date"
          name="eventdate"
          setValue={(x) => {
            if (
              x &&
              isValid(new Date(x.split("-").map((y) => parseInt(y, 10))))
            ) {
              return {
                gte:
                  filters.eventdate?.gte ||
                  startOfDay(
                    new Date(x.split("-").map((y) => parseInt(y, 10)))
                  ).toISOString(),
                lte: endOfDay(
                  new Date(x.split("-").map((y) => parseInt(y, 10)))
                ).toISOString(),
              };
            }
            return "";
          }}
          value={filters.eventdate?.lte || ""}
          onChange={onChange}
        />
      </Field>
      <Field>
        <FilterInput
          getValue={(x) => x?.contains || ""}
          label="Event Description"
          maxLength={50}
          name="eventdesc"
          placeholder="Event Description"
          setValue={(x) => (x ? { contains: x } : "")}
          size="small"
          value={filters.eventdesc || ""}
          onChange={onChange}
        />
      </Field>
      <Field>
        <FilterInput
          getValue={(x) => x?.contains || ""}
          label="Other Info"
          maxLength={50}
          name="otherinfo"
          placeholder="Other Info"
          setValue={(x) => (x ? { contains: x } : "")}
          size="small"
          value={filters.otherinfo || ""}
          onChange={onChange}
        />
      </Field>
      <Field>
        <Control>
          <Label>&nbsp;</Label>
          <Button color="danger" size="small" onClick={onReset}>
            Reset
          </Button>
        </Control>
      </Field>
    </div>
  </Box>
);

CaseHistoryFilters.propTypes = {
  filters: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
};

export default CaseHistoryFilters;
