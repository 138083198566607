import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const StyledFooter = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3.5rem;
  box-shadow: 0 -5px 9px 0 rgba(0, 0, 0, 0.09);
  background: gainsboro;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  z-index: 999;
  padding: 0 1rem;
`;

const ReferralFooter = ({ children }) => (
  <StyledFooter>{children}</StyledFooter>
);

ReferralFooter.propTypes = {
  children: PropTypes.node,
};
ReferralFooter.defaultProps = {
  children: null,
};

export default ReferralFooter;
