import { gql } from "@apollo/client";

// eslint-disable-next-line import/prefer-default-export
export const LIST_TERMS_QUERY = gql`
  query terms(
    $take: Int = 20
    $orderBy: [TermOrderByInput!]
    $skip: Int
    $where: TermWhereInput
  ) {
    terms(take: $take, orderBy: $orderBy, skip: $skip, where: $where) {
      TermsCode
      Description
    }
  }
`;
