import { gql } from "@apollo/client";

export const SINGLE_RECORD_REQUEST = gql`
  query Recordrequest($where: RecordRequestInput!) {
    recordRequest(where: $where) {
      OK
      errorMessage
      RRid

      ClaimantLastName
      ClaimantFirstName
      ClaimantSSN
      ClaimantDateOfBirth
      ClaimantAddress1
      ClaimantAddress2
      ClaimantCity
      ClaimantState
      ClaimantZip

      ClaimNumber
      DateOfLoss

      Phone
      CaseType
      Insured
      Jurisdiction
      Venue
      ClaimantEmail
      ClaimantPhone
      ClaimantCellPhone
      ClaimantGender
      ClaimantMaritalStatus
      ClaimantSpecialPhysical

      SubService
      NWCarrier
      ClaimSystem
      BusinessUnit
      LossState
      Subrogation
      AllegedInjuries
      ServiceCenter
      BroadspireClient
      BroadspireCarrier
      InsuranceCarrier
      RRClaimantLetter
      RRUseCAWCRegs
      RRclaimOffice_us
      RRmemberNumber_us
      RRalisNumber_us
      RRclaimsLossEmail_us
      RRlossReportNumber_us
      RRLegalOffice_us

      RequestorOrAttorney
      RepresentedParty
      FileNumber
      ParalegalOrContact
      AdditionalContact
      GlobalInstructions
      DeadlineDate
      BillingContact

      ContactName
      ClaimType
      Caption
      County
      DocketNumber
      Court
      CourtAddress
      Branch
      Division
      CalendarNumber
      Submitted

      WCJudge
      WCInsuranceCarrier
      WCEmployer
      WCStateFileNumber
      WCStateFileYear
      WCRFANumber
      WCCarrierCode

      REState
      RECourtFee
      RESheriffFee
      RESecretaryOfStateFee

      Notes {
        NoteID
        Note
      }

      Counsels {
        CounselID
        Representing
        AdditionalCounsel
      }

      Providers {
        ProviderID
        Provider
        RequestDates
        RetrieveStartDate
        RetrieveEndDate
        RetrievePresent
        SpecialInstructions
        RequestMethod
        RushStatus
        OriginalDateEntered
        DateSubmitted
        RecordType
      }

      Uploads {
        id
        path
        link
        uploaded
        dateadded
        submitted
      }
    }
  }
`;

export const RECORD_REQUEST_GENERATE_PDF = gql`
  query GenerateRecordRequestPDF($where: RecordRequestInput!) {
    generateRecordRequestPDF(where: $where) {
      OK
      errorMessage
      documentPath
    }
  }
`;
