import React, { useState } from "react";
import PropTypes from "prop-types";
import { useMutation, useApolloClient } from "@apollo/client";
import { Control, Label, Title, Fieldset, Button } from "rbx";
import { customToast as toast, getDateEST } from "../../../utils";
import {
  CREATE_CASE_PROBLEM_MUTATION,
  LIST_CASE_PROBLEMS_QUERY,
  UPDATE_CASE_PROBLEM_MUTATION,
} from "../../../graphql";
import { useAuth } from "../../../context/AuthContext";
import ProblemSelect from "../../../components/ProblemSelect";
import ProblemMultiSelect from "../../../components/ProblemMultiSelect";

const CaseProblem = ({ handleCancel, casenbr, problemcode }) => {
  const { state: authState } = useAuth();
  const [createProblem] = useMutation(CREATE_CASE_PROBLEM_MUTATION);
  const [updateProblem] = useMutation(UPDATE_CASE_PROBLEM_MUTATION);
  const client = useApolloClient();

  const [inputs, setInputs] = useState({
    problemcode: problemcode || "",
    problemcodes: [],
  });

  const onChange = (e, value) => {
    setInputs((prev) => ({
      ...prev,
      [e.target ? e.target.name : e]: e.target ? e.target.value : value,
    }));
  };

  const handleSave = async (e) => {
    e.preventDefault();
    try {
      if (problemcode !== null) {
        await updateProblem({
          variables: {
            data: {
              problem: {
                connect: {
                  problemcode: Number(inputs.problemcode),
                },
              },
            },
            where: {
              casenbr_problemcode: {
                casenbr,
                problemcode,
              },
            },
          },
          refetchQueries: [
            {
              query: LIST_CASE_PROBLEMS_QUERY,
              variables: {
                where: {
                  casenbr: { equals: casenbr },
                },
                orderBy: [{ problemcode: "asc" }],
              },
              fetchPolicy: "cache-first",
            },
          ],
        });
        toast.success("Case Problem edited successfully.");
      } else {
        const { problemcodes } = inputs;
        await problemcodes.forEach(async (problem) => {
          const {
            data: { caseProblems },
          } = await client.query({
            query: LIST_CASE_PROBLEMS_QUERY,
            variables: {
              take: 1,
              where: {
                casenbr: { equals: casenbr },
                problemcode: { equals: parseInt(problem.value, 10) },
              },
            },
            fetchPolicy: "network-only",
          });
          if (caseProblems && !caseProblems.length) {
            await createProblem({
              variables: {
                data: {
                  casenbr,
                  problem: {
                    connect: {
                      problemcode: parseInt(problem.value, 10),
                    },
                  },
                  dateadded: getDateEST(),
                  useridadded: authState?.user?.userid,
                },
              },
              refetchQueries: [
                {
                  query: LIST_CASE_PROBLEMS_QUERY,
                  variables: {
                    where: {
                      casenbr: { equals: casenbr },
                    },
                    orderBy: [{ problemcode: "asc" }],
                  },
                  fetchPolicy: "cache-first",
                },
              ],
            });
          }
        });
        toast.success("Case Problem created successfully.");
      }
      handleCancel();
    } catch (err) {
      toast.error(
        "Error: Please, check if this Problem already exists on Case."
      );
    }
  };
  const isDisabled = !inputs.problemcodes.length;

  return (
    <form id="add-problem-form" style={{ height: 200 }} onSubmit={handleSave}>
      <header className="page-head">
        <div className="page-head-start">
          <Title size={5}>
            {`${problemcode !== null ? "Edit" : "Create"}  Case Problem`}
          </Title>
        </div>
        <div className="page-head-end">
          <Button.Group hasAddons>
            <Button size="small" type="button" onClick={handleCancel}>
              <span>Cancel</span>
            </Button>
            <Button
              color="primary"
              disabled={isDisabled}
              form="add-problem-form"
              size="small"
              type="submit"
            >
              <span>Submit</span>
            </Button>
          </Button.Group>
        </div>
      </header>
      <hr />
      <Fieldset>
        <Control>
          <Label>{problemcode !== null ? "Problems" : "Problem"}</Label>
          {problemcode !== null ? (
            <ProblemSelect
              fullWidth
              required
              name="problemcode"
              value={inputs.problemcode}
              onChange={onChange}
            />
          ) : (
            <ProblemMultiSelect
              fullWidth
              required
              name="problemcodes"
              value={inputs.problemcodes}
              onChange={(value) => onChange("problemcodes", value)}
            />
          )}
        </Control>
      </Fieldset>
      <hr />
    </form>
  );
};

CaseProblem.propTypes = {
  handleCancel: PropTypes.func,
  casenbr: PropTypes.number.isRequired,
  problemcode: PropTypes.number,
};

CaseProblem.defaultProps = {
  handleCancel: (e) => e,
  problemcode: null,
};

export default CaseProblem;
