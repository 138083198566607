import React, { useRef } from "react";
import { useQuery } from "@apollo/client";

import Confirmation from "../components/Confirmation";

import { useModal } from "../context";
import { FIND_FIRST_CLIENT_QUERY } from "../graphql";

export default function useWebReferral({
  clientcode,
  marketercode,
  QARep,
  priority,
  onConfirm,
}) {
  const triggeredRef = useRef(false);
  const { setModalOpen } = useModal();

  const { data: WebReferralClientData } = useQuery(FIND_FIRST_CLIENT_QUERY, {
    variables: {
      where: {
        firstname: { equals: "Web" },
        lastname: { equals: "Referral" },
      },
    },
  });
  const client = WebReferralClientData?.findFirstClient;

  const hasTriggered = !!triggeredRef.current;
  const isWebReferralClient = client?.clientcode === clientcode;
  const isDefaultMarketer =
    client?.marketercode === marketercode ||
    client?.company?.marketercode === marketercode;
  const isDefaultQARep =
    client?.QARep === QARep || client?.company?.QARep === QARep;
  const isDefaultPriority =
    client?.priority === priority || client?.company?.priority === priority;

  const handleConfirm = () => {
    onConfirm((prev) => {
      const updates = {
        marketercode: client?.marketercode,
        QARep: client?.QARep,
      };
      if (typeof client?.priority === "string") {
        updates.priority = client.priority.padEnd(10);
      }
      if (!updates.marketercode) {
        updates.marketercode = client?.company?.marketercode || "";
      }
      if (!updates.QARep) {
        updates.QARep = client?.company?.QARep || "";
      }
      if (!updates.priority) {
        updates.priority =
          typeof client?.company?.priority === "string"
            ? client.company.priority.padEnd(10)
            : "";
      }
      return { ...prev, ...updates };
    });
    setModalOpen(
      true,
      <Confirmation
        message="Defaults have been applied. Please save the case to capture your changes."
        negative="OK"
        showAffirmative={false}
      />
    );
  };

  if (
    isWebReferralClient &&
    !hasTriggered &&
    (!isDefaultMarketer || !isDefaultQARep || !isDefaultPriority)
  ) {
    triggeredRef.current = true;
    setModalOpen(
      true,
      <Confirmation
        message="This case is a Web Referral. Would you like to assign defaults?"
        onConfirm={handleConfirm}
      />
    );
  }
}
