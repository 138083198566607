import { format } from "date-fns";

import { convertTimeZone } from "../../../../utils";

const COLUMNS = [
  {
    Header: "Description",
    id: "Description",
    accessor: "Description",
    maxWidth: "50%",
  },
  {
    Header: "Status",
    id: "Status",
    accessor: "Status",
  },
  {
    Header: "Added",
    id: "DateAdded",
    accessor: "DateAdded",
    Cell: ({ cell }) =>
      cell?.value
        ? format(convertTimeZone(cell.value), "MM/dd/yyyy hh:mm a")
        : "",
  },
  {
    Header: "By",
    id: "UserIDAdded",
    accessor: "UserIDAdded",
  },
  {
    Header: "Edited",
    id: "DateEdited",
    accessor: "DateEdited",
    Cell: ({ cell }) =>
      cell?.value
        ? format(convertTimeZone(cell.value), "MM/dd/yyyy hh:mm a")
        : "",
  },
  {
    Header: "By",
    id: "UserIDEdited",
    accessor: "UserIDEdited",
  },
];

export default COLUMNS;
