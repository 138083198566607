import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";

import { AsyncPaginate } from "react-select-async-paginate";

import { Label, Control, Field } from "rbx";
import { useApolloClient, useLazyQuery } from "@apollo/client";
import { ALL_CC_ADDRESSES_QUERY, SINGLE_CC_ADDRESS_QUERY } from "../../graphql";

const QUERY_LIMIT = 50;

const CCAddressSelect = ({
  name,
  value: initialValue,
  label,
  onChange,
  disabled,
  getValue,
  setValue,
  required,
}) => {
  const client = useApolloClient();

  const [getCCAddress, { data: CCAddressData }] = useLazyQuery(
    SINGLE_CC_ADDRESS_QUERY
  );

  const [selected, setSelected] = useState(null);
  const [defaultOptions, setDefaultOptions] = useState([]);

  const getLabel = useCallback(
    (cCAddress) =>
      `${cCAddress.company || "N/A"} - ${
        cCAddress.firstname && cCAddress.lastname
          ? `${cCAddress.firstname} ${cCAddress.lastname}`
          : "N/A"
      } - ${cCAddress.address1 || "N/A"}`,
    []
  );

  const promiseOptions = async (inputValue, { length }) => {
    const where = {
      company: { startsWith: inputValue },
    };

    const { data: CCAddressesData } = await client.query({
      query: ALL_CC_ADDRESSES_QUERY,
      variables: {
        take: QUERY_LIMIT,
        where,
        orderBy: [{ company: "asc" }, { lastname: "asc" }],
        skip: length,
      },
    });

    const ccAddresses = CCAddressesData?.cCAddresses;

    const result = Array.isArray(ccAddresses)
      ? ccAddresses.map((cCAddress) => ({
          value: cCAddress.cccode,
          label: getLabel(cCAddress),
        }))
      : [];
    setDefaultOptions(result);

    return {
      options: result,
      hasMore: result.length === QUERY_LIMIT,
    };
  };

  const handleChange = ({ value: newValue }) => {
    onChange(name, setValue(newValue || ""));
  };

  useEffect(() => {
    if (initialValue) {
      getCCAddress({
        variables: {
          where: { cccode: parseInt(getValue(initialValue), 10) },
        },
      });
    } else {
      setSelected(null);
    }
  }, [initialValue, getValue, getCCAddress]);

  useEffect(() => {
    const cCAddress = CCAddressData?.cCAddress;
    if (cCAddress) {
      setSelected({
        value: cCAddress.cccode,
        label: getLabel(cCAddress),
      });
    }
  }, [CCAddressData?.cCAddress, getLabel]);

  useEffect(() => {
    if (selected) {
      const currOptions = [...defaultOptions];
      if (!currOptions.some((opt) => opt.value === selected.value)) {
        setDefaultOptions((prev) => [selected, ...prev]);
      }
    }
  }, [selected, defaultOptions]);

  const indicateRequired =
    required &&
    !disabled &&
    (!initialValue ||
      initialValue === "" ||
      initialValue === "0" ||
      initialValue === 0);

  return (
    <Field kind="group">
      <Control expanded>
        <Label>{label}</Label>
        <AsyncPaginate
          cacheOptions
          debounceTimeout={300}
          defaultOptions={defaultOptions}
          isDisabled={disabled}
          loadOptions={promiseOptions}
          menuPortalTarget={document.body}
          placeholder="Start typing..."
          required={required}
          styles={{
            menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
            indicatorsContainer: (provided) => ({
              ...provided,
              height: "inherit",
            }),
            indicatorSeparator: (provided) => ({
              ...provided,
              display: "none",
            }),
            container: (provided) => ({
              fontSize: 13,
            }),
            singleValue: (provided) => ({
              ...provided,
              top: "38%",
            }),
            control: (provided) => ({
              ...provided,
              minHeight: 31,
              height: 31,
              width: "100%",
              minWidth: 180,
              border: indicateRequired ? "1px #e63362 solid" : "",
              boxShadow: indicateRequired
                ? "0 0 0 0.125em rgba(230, 51, 98, 0.25)"
                : "",
              "&:hover": {
                borderColor: "hsl(0, 0%, 90%)",
                borderRadius: 4,
                cursor: disabled ? "not-allowed" : "pointer",
              },
            }),
            placeholder: (provided) => ({ ...provided, top: "38%" }),
            menu: (provided) => ({
              ...provided,
              fontSize: 12,
              zIndex: 999,
            }),
          }}
          value={selected}
          onChange={handleChange}
        />
      </Control>
    </Field>
  );
};

CCAddressSelect.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  getValue: PropTypes.func,
  setValue: PropTypes.func,
  required: PropTypes.bool,
};

CCAddressSelect.defaultProps = {
  name: "",
  label: "CC Address",
  value: "",
  onChange: () => null,
  disabled: false,
  getValue: (x) => x,
  setValue: (x) => x,
  required: false,
};

export default CCAddressSelect;
