import React from "react";
import PropTypes from "prop-types";
import { Title, Fieldset, Button, Field } from "rbx";

import RatsEmployerEntryForm from "../RatsEmployerEntryForm";

const RatsEmployerInfoModal = ({ onComplete, inputs, title }) => (
  <React.Fragment>
    <header className="page-head">
      <div className="page-head-start">
        <Title size={5}>{title}</Title>
      </div>
      <div className="page-head-end">
        <Button.Group hasAddons>
          <Button size="small" type="button" onClick={onComplete}>
            <span>Close</span>
          </Button>
        </Button.Group>
      </div>
    </header>
    <hr />
    <Field>
      <Fieldset>
        <RatsEmployerEntryForm
          disabled
          inputs={inputs}
          label={title}
          onChange={() => null}
        />
        <hr />
      </Fieldset>
    </Field>
  </React.Fragment>
);

RatsEmployerInfoModal.propTypes = {
  onComplete: PropTypes.func,
  inputs: PropTypes.object,
  title: PropTypes.string,
};

RatsEmployerInfoModal.defaultProps = {
  onComplete: (e) => e,
  inputs: {},
  title: "",
};

export default RatsEmployerInfoModal;
