import React from "react";
import PropTypes from "prop-types";

import CaseForm from "../../components/CaseForm";

const Profile = ({
  inputs,
  onChange,
  disabled,
  onSubmit,
  setInputs,
  casenbr,
}) => (
  <CaseForm
    casenbr={casenbr}
    disabled={disabled}
    inputs={inputs}
    setInputs={setInputs}
    onChange={onChange}
    onSubmit={onSubmit}
  />
);

Profile.propTypes = {
  inputs: PropTypes.object,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  setInputs: PropTypes.func.isRequired,
  casenbr: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

Profile.defaultProps = {
  inputs: {},
  onChange: (e) => e,
  disabled: false,
};

export default Profile;
