import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Title, Button } from "rbx";
import { useApolloClient, useMutation, useLazyQuery } from "@apollo/client";
import { useModal, useAuth } from "../../../../../context";
import {
  CREATE_SERVICE_CASE_TYPE_OFFICE_MUTATION,
  UPDATE_SERVICE_CASE_TYPE_MUTATION,
  DELETE_SERVICE_CASE_TYPE_OFFICE_MUTATION,
  LIST_CASE_TYPE_SERVICE_VIEW_QUERY,
  SINGLE_CASE_TYPE_DOCUMENT_QUERY,
} from "../../../../../graphql";
import Confirmation from "../../../../../components/Confirmation";
import {
  customToast as toast,
  getDateEST,
  convertTimeZoneDataBase,
} from "../../../../../utils";
import CaseTypeDocumentsForm from "../CaseTypeDocumentsForm";

const INITIAL_STATE = {
  casetype: "",
  servicecode: "",
  officecode: "",
  examineeaddrreqd: false,
  examineeSSNreqd: false,
  attorneyreqd: false,
  DOIRqd: false,
  ClaimNbrRqd: false,
  JurisdictionRqd: false,
  EmployerRqd: false,
  TreatingPhysicianRqd: false,
};

class CustomCaseTypeDocumentsError extends Error {}

const CaseTypeDocumentsModal = ({ onComplete, code, where }) => {
  const { setModalOpen } = useModal();
  const { state: authState } = useAuth();
  const [inputs, setInputs] = useState(INITIAL_STATE);
  const [loading, setLoading] = useState(false);

  const client = useApolloClient();

  const [
    getCaseType,
    { data: caseTypeData, loading: queryLoading },
  ] = useLazyQuery(SINGLE_CASE_TYPE_DOCUMENT_QUERY);
  const [createCaseTypeService] = useMutation(
    CREATE_SERVICE_CASE_TYPE_OFFICE_MUTATION
  );
  const [updateCaseTypeService] = useMutation(
    UPDATE_SERVICE_CASE_TYPE_MUTATION
  );
  const [deleteCaseTypeService] = useMutation(
    DELETE_SERVICE_CASE_TYPE_OFFICE_MUTATION
  );

  useEffect(() => {
    if (code) {
      getCaseType({
        variables: {
          where: { casetype_servicecode_officecode: code },
        },
      });
    }
  }, [code, getCaseType]);

  useEffect(() => {
    if (caseTypeData?.caseTypeService) {
      const {
        examineeaddrreqd,
        examineeSSNreqd,
        attorneyreqd,
        DOIRqd,
        ClaimNbrRqd,
        JurisdictionRqd,
        EmployerRqd,
        TreatingPhysicianRqd,
        casetype,
        servicecode,
        officecode,
      } = caseTypeData.caseTypeService;
      setInputs({
        examineeaddrreqd: examineeaddrreqd || false,
        examineeSSNreqd: examineeSSNreqd || false,
        attorneyreqd: attorneyreqd || false,
        DOIRqd: DOIRqd || false,
        ClaimNbrRqd: ClaimNbrRqd || false,
        JurisdictionRqd: JurisdictionRqd || false,
        EmployerRqd: EmployerRqd || false,
        TreatingPhysicianRqd: TreatingPhysicianRqd || false,
        casetype: casetype || "",
        servicecode: servicecode || "",
        officecode: officecode || "",
      });
    }
  }, [caseTypeData]);

  const handleChange = (name, value) => {
    setInputs((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      const date = convertTimeZoneDataBase(getDateEST());
      if (!code) {
        const c = {
          casetype: parseInt(inputs.casetype, 10),
          officecode: parseInt(inputs.officecode, 10),
          servicecode: parseInt(inputs.servicecode, 10),
        };
        const { data: ctsData } = await client.query({
          query: SINGLE_CASE_TYPE_DOCUMENT_QUERY,
          variables: {
            where: { casetype_servicecode_officecode: c },
          },
        });
        if (ctsData?.caseTypeService) {
          setModalOpen(
            true,
            <Confirmation
              message="This record has already been created. Do you want to edit it?"
              onCancel={() =>
                setModalOpen(
                  true,
                  <CaseTypeDocumentsModal
                    code={code}
                    where={where}
                    onComplete={onComplete}
                  />
                )
              }
              onConfirm={() =>
                setModalOpen(
                  true,
                  <CaseTypeDocumentsModal
                    code={c}
                    where={where}
                    onComplete={onComplete}
                  />
                )
              }
            />
          );
        } else {
          const data = {
            ...inputs,
            Service: {
              connect: { servicecode: parseInt(inputs.servicecode, 10) },
            },
            Office: {
              connect: { officecode: parseInt(inputs.officecode, 10) },
            },
            CaseType: { connect: { code: parseInt(inputs.casetype, 10) } },
            useridadded: authState.user.userid,
            dateadded: date,
          };
          delete data.servicecode;
          delete data.officecode;
          delete data.casetype;
          await createCaseTypeService({
            variables: {
              data,
            },
            refetchQueries: [
              {
                query: LIST_CASE_TYPE_SERVICE_VIEW_QUERY,
                variables: { where },
              },
              {
                query: SINGLE_CASE_TYPE_DOCUMENT_QUERY,
                variables: { where: { casetype_servicecode_officecode: c } },
              },
            ],
          });
          setModalOpen(false);
          toast.success("Case Type Document created successfully.");
          onComplete();
        }
      } else {
        await updateCaseTypeService({
          variables: {
            data: {
              examineeaddrreqd: { set: inputs.examineeaddrreqd },
              examineeSSNreqd: { set: inputs.examineeSSNreqd },
              attorneyreqd: { set: inputs.attorneyreqd },
              DOIRqd: { set: inputs.DOIRqd },
              ClaimNbrRqd: { set: inputs.ClaimNbrRqd },
              JurisdictionRqd: { set: inputs.JurisdictionRqd },
              EmployerRqd: { set: inputs.EmployerRqd },
              TreatingPhysicianRqd: { set: inputs.TreatingPhysicianRqd },
              useridedited: { set: authState.user.userid },
              dateedited: { set: date },
            },
            where: {
              casetype_servicecode_officecode: {
                casetype: parseInt(inputs.casetype, 10),
                officecode: parseInt(inputs.officecode, 10),
                servicecode: parseInt(inputs.servicecode, 10),
              },
            },
          },
          refetchQueries: [
            { query: LIST_CASE_TYPE_SERVICE_VIEW_QUERY, variables: { where } },
            {
              query: SINGLE_CASE_TYPE_DOCUMENT_QUERY,
              variables: { where: { casetype_servicecode_officecode: code } },
            },
          ],
        });
        setModalOpen(false);
        toast.success("Case Type Document updated successfully.");
        onComplete();
      }
    } catch (err) {
      const message =
        err instanceof CustomCaseTypeDocumentsError
          ? err.message
          : `Error trying to ${code ? "update" : "create"} Case Type Document`;
      toast.error(message);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (e) => {
    e.preventDefault();

    const performDelete = async () => {
      try {
        setLoading(true);
        await deleteCaseTypeService({
          variables: {
            where: {
              casetype_servicecode_officecode: {
                casetype: parseInt(inputs.casetype, 10),
                officecode: parseInt(inputs.officecode, 10),
                servicecode: parseInt(inputs.servicecode, 10),
              },
            },
          },
          refetchQueries: [
            { query: LIST_CASE_TYPE_SERVICE_VIEW_QUERY, variables: { where } },
            {
              query: SINGLE_CASE_TYPE_DOCUMENT_QUERY,
              variables: { where: { casetype_servicecode_officecode: code } },
            },
          ],
        });
        toast.success("Case Type Document deleted successfully");
        onComplete();
      } catch (err) {
        const message =
          err instanceof CustomCaseTypeDocumentsError
            ? err.message
            : "Error trying to delete Case Type Document";
        toast.error(message);
      } finally {
        setLoading(false);
      }
    };
    setModalOpen(
      true,
      <Confirmation
        message="Are you sure you want to delete this Case Type Document?"
        onCancel={() =>
          setModalOpen(
            true,
            <CaseTypeDocumentsModal
              code={code}
              where={where}
              onComplete={onComplete}
            />
          )
        }
        onConfirm={performDelete}
      />
    );
  };

  const isDisabled =
    !inputs.officecode ||
    !inputs.servicecode ||
    !inputs.casetype ||
    queryLoading ||
    loading;

  return (
    <form id="case-type-form" onSubmit={handleSubmit}>
      <header className="page-head">
        <div className="page-head-start">
          <Title size={5}>
            {`${!code ? "Create" : "Edit"}`}
            &nbsp;Case Type Document
          </Title>
        </div>
        <div className="page-head-end">
          <Button.Group hasAddons>
            <Button
              size="small"
              type="button"
              onClick={() => onComplete(false)}
            >
              <span>Cancel</span>
            </Button>
            <Button
              color="primary"
              disabled={isDisabled}
              form="case-type-form"
              size="small"
              state={loading ? "loading" : ""}
              type="submit"
            >
              <span>Submit</span>
            </Button>
          </Button.Group>
        </div>
      </header>
      <hr />
      <CaseTypeDocumentsForm
        editing={!!code}
        inputs={inputs}
        onChange={handleChange}
      />
      <hr />
      {code && (
        <Button
          color="danger"
          disabled={loading}
          size="small"
          type="button"
          onClick={handleDelete}
        >
          <span>Delete</span>
        </Button>
      )}
    </form>
  );
};

CaseTypeDocumentsModal.propTypes = {
  onComplete: PropTypes.func,
  code: PropTypes.object,
  where: PropTypes.object,
};

CaseTypeDocumentsModal.defaultProps = {
  onComplete: () => null,
  code: null,
  where: {},
};

export default CaseTypeDocumentsModal;
