import React, { useState } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Control, Delete, Field, Input, Panel, Icon } from "rbx";

import { matchSorter } from "match-sorter";

import {
  capitalize,
  generateUUID,
  secureContextNavigatorCopyText,
} from "../../../../utils";

import {
  CaseItemFields,
  ClientFields,
  DoctorFields,
  ExamineeFields,
  LocationFields,
  ServiceFields,
  ViewFields,
  IssueFields,
  ProblemFields,
  DoctorLocationFields,
  DoctorSpecialtyFields,
  CcFields,
  AcctTransFields,
} from "./docs";

const TABS = [
  "all",
  "acctTrans",
  "case",
  "ccField",
  "doctor",
  "examinee",
  "location",
  "client",
  "service",
  "view",
  "issue",
  "problem",
  "doctorLocation",
  "doctorSpecialty",
];

const sorter = (a, b) => {
  if (a.entity > b.entity) return 1;
  if (b.entity > a.entity) return -1;
  return 0;
};
const ALL_FIELDS = [
  ...AcctTransFields,
  ...CaseItemFields,
  ...CcFields,
  ...DoctorFields,
  ...ExamineeFields,
  ...LocationFields,
  ...ClientFields,
  ...ServiceFields,
  ...ViewFields,
  ...IssueFields,
  ...ProblemFields,
  ...DoctorLocationFields,
  ...DoctorSpecialtyFields,
]
  .sort(sorter)
  .map((x) => ({ ...x, id: generateUUID() }));

const Help = ({ onClose }) => {
  const [activeFields, setActiveFields] = useState([...ALL_FIELDS]);
  const [activeTab, setActiveTab] = useState("all");
  const [search, setSearch] = useState("");

  const handleSearchChange = (value) => {
    const items = matchSorter(
      activeTab === "all"
        ? [...ALL_FIELDS]
        : [...ALL_FIELDS].filter((x) => x.entity === activeTab),
      value,
      {
        keys: ["name"],
      }
    );
    setSearch(value);
    setActiveFields(items);
  };

  const handleChangeTab = (tab) => () => {
    setActiveTab(tab);
    const items = matchSorter(
      tab === "all"
        ? [...ALL_FIELDS]
        : [...ALL_FIELDS].filter((x) => x.entity === tab),
      search,
      { keys: ["name"] }
    );
    setActiveFields(items.sort(sorter));
  };

  const handleReset = () => {
    setSearch("");
    handleSearchChange("");
    setActiveTab("all");
    setActiveFields([...ALL_FIELDS]);
  };

  const copyCodeToClipboard = (e, text, key) => {
    e.preventDefault();
    e.stopPropagation();
    const textToCopy =
      [
        "doctorLocation",
        "doctorSpecialty",
        "issues",
        "problem",
        "ccField",
        "acctTrans",
      ].includes(key) || text === "CurrentDate"
        ? `{{${text}}}`
        : `{{${key}.${text}}}`;
    return secureContextNavigatorCopyText(textToCopy);
  };

  return (
    <div className="document-help">
      <Panel>
        <Panel.Heading>
          <div className="page-head" style={{ marginBottom: 0 }}>
            <div className="page-head-start">
              <Field>
                <Control>
                  <Delete onClick={onClose} />
                </Control>
              </Field>
            </div>
            <div className="page-head-end">Documentation</div>
          </div>
        </Panel.Heading>
        <Panel.Block>
          <Control iconLeft>
            <Input
              autoComplete="new"
              placeholder="search"
              size="small"
              type="text"
              value={search}
              onChange={(e) => handleSearchChange(e.target.value)}
            />
            <Icon align="left" size="small">
              <FontAwesomeIcon icon="search" />
            </Icon>
          </Control>
        </Panel.Block>
        <Panel.Tab.Group>
          {TABS.map((tab) => (
            <Panel.Tab
              active={activeTab === tab}
              key={tab}
              onClick={handleChangeTab(tab)}
            >
              <span>{capitalize(tab)}</span>
            </Panel.Tab>
          ))}
        </Panel.Tab.Group>

        <div className="field-list">
          {activeFields.map((x) => (
            <div key={x.id}>
              <Panel.Block>
                <div className="field-list-item">
                  <Button
                    role="button"
                    size="small"
                    onClick={(e) => copyCodeToClipboard(e, x.name, x.entity)}
                  >
                    <Icon>
                      <FontAwesomeIcon icon="copy" />
                    </Icon>
                  </Button>
                  <span className="name">{`${x.entity}.${x.name}`}</span>
                  <span className="type">{x.type}</span>
                </div>
              </Panel.Block>
            </div>
          ))}
        </div>
        <Panel.Block>
          <Button
            fullwidth
            outlined
            color="link"
            type="button"
            onClick={handleReset}
          >
            reset filters
          </Button>
        </Panel.Block>
      </Panel>
    </div>
  );
};

Help.propTypes = {
  onClose: PropTypes.func,
};

Help.defaultProps = {
  onClose: () => null,
};

export default Help;
