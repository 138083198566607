import React from "react";
import PropTypes from "prop-types";
import { Field, Input, Label, Control } from "rbx";
import { format } from "date-fns";

import ClientSelect from "../../../components/ClientSelect";
import PrioritySelect from "../../../components/PrioritySelect";
import CompanySelect from "../../../components/CompanySelect";
import CaseSpecialSelects from "../../../components/CaseSpecialSelects";
import DateInput from "../../../components/DateInput";
import JurisdictionSelect from "../../../components/JurisdictionSelect";
import UserSelectAsync from "../../../components/UserSelectAsync";
import SSNumberInput from "../../../components/SSNumberInput";
import CCAddressSelect from "../../../components/CCAddressSelect";
import ZipInput from "../../../components/ZipInput";
import StateSelect from "../../../components/StateSelect";

const AddCaseForm = ({
  inputs,
  examineeInputs,
  onChange,
  setInputs,
  disabled,
  requiredFields,
}) => (
  <div className="add-case-form">
    <Field style={{ gridColumn: "1 / 4" }}>
      <ClientSelect
        required
        showLabel
        disabled={disabled}
        label="Client"
        name="clientcode"
        value={inputs.clientcode}
        where={{ status: { equals: "Active" } }}
        onChange={onChange}
      />
    </Field>
    <Field style={{ gridColumn: "4 / 7" }}>
      <CompanySelect
        disabled
        fullWidth
        required
        showLabel
        label="Company"
        name="IMECompany"
        value={inputs.IMECompany}
        onChange={onChange}
      />
    </Field>

    <Field style={{ gridColumn: "1 / 4" }}>
      <PrioritySelect
        disabled={disabled}
        label="Priority"
        name="priority"
        noValueLabel=""
        value={inputs.priority}
        onChange={onChange}
      />
    </Field>
    <Field style={{ gridColumn: "4 / 7" }}>
      <DateInput
        disabled={disabled}
        label="Commit Date"
        max="9999-12-31"
        name="commitdate"
        value={inputs.commitdate}
        onChange={onChange}
      />
    </Field>
    <CaseSpecialSelects
      disabled={disabled}
      inputs={inputs}
      setInputs={setInputs}
    />

    <Field kind="group" style={{ gridColumn: "1 / 7" }}>
      <UserSelectAsync
        disabled={disabled}
        label="Marketer"
        name="marketercode"
        value={inputs.marketercode}
        where={{
          OR: [{ usertype: { equals: "MK" } }, { usertype: { equals: "*" } }],
          fldActive: { equals: 1 },
        }}
        onChange={onChange}
      />
      <UserSelectAsync
        disabled={disabled}
        label="QA Rep"
        name="QARep"
        value={inputs.QARep}
        where={{
          OR: [{ usertype: { equals: "QA" } }, { usertype: { equals: "*" } }],
          fldActive: { equals: 1 },
        }}
        onChange={onChange}
      />
      <UserSelectAsync
        disabled={disabled}
        label="Scheduler"
        name="schedulercode"
        value={inputs.schedulercode}
        where={{
          OR: [{ usertype: { equals: "SC" } }, { usertype: { equals: "*" } }],
          fldActive: { equals: 1 },
        }}
        onChange={onChange}
      />
      {inputs?.CompanyGroupID === 5010 && (
        <UserSelectAsync
          required
          disabled={disabled}
          label="Processor"
          name="SelectedAllstateProcessor"
          value={inputs.SelectedAllstateProcessor}
          where={{
            OR: [{ AllstateProcessor: { equals: 1 } }],
          }}
          onChange={onChange}
        />
      )}
    </Field>

    {(requiredFields.JurisdictionRqd ||
      requiredFields.ClaimNbrRqd ||
      requiredFields.DOIRqd) && (
      <Field kind="group" style={{ gridColumn: "1 / 7" }}>
        {requiredFields.JurisdictionRqd && (
          <JurisdictionSelect
            disabled={disabled}
            label="Jurisdiction"
            name="Jurisdiction"
            required={requiredFields.JurisdictionRqd}
            size="small"
            value={inputs.Jurisdiction}
            onChange={onChange}
          />
        )}
        {requiredFields.ClaimNbrRqd && (
          <Control expanded>
            <Label>Claim Nbr</Label>
            <Input
              autoComplete="new"
              disabled={disabled}
              maxLength={50}
              name="claimnbr"
              required={requiredFields.ClaimNbrRqd}
              size="small"
              type="text"
              value={inputs.claimnbr}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </Control>
        )}
        {requiredFields.DOIRqd && (
          <Control>
            <Label>Date of Injury</Label>
            <Input
              disabled={disabled}
              max={format(new Date(), "yyyy-MM-dd")}
              name="dateofinjury"
              required={requiredFields.DOIRqd}
              size="small"
              type="date"
              value={inputs.dateofinjury}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </Control>
        )}
      </Field>
    )}

    {(requiredFields.EmployerRqd || requiredFields.TreatingPhysicianRqd) && (
      <Field kind="group" style={{ gridColumn: "1 / 7" }}>
        {requiredFields.EmployerRqd && (
          <Control expanded>
            <Label>Employer Name</Label>
            <Input
              autoComplete="new"
              disabled={disabled}
              maxLength={70}
              name="employer"
              required={requiredFields.EmployerRqd}
              size="small"
              type="text"
              value={examineeInputs.employer}
              onChange={(e) => onChange(e.target.name, e.target.value, true)}
            />
          </Control>
        )}
        {requiredFields.TreatingPhysicianRqd && (
          <CCAddressSelect
            disabled={disabled}
            label="Treating Physician"
            name="treatingphysician"
            required={requiredFields.TreatingPhysicianRqd}
            value={examineeInputs.treatingphysician}
            onChange={(name, value) => onChange(name, value, true)}
          />
        )}
      </Field>
    )}

    <Field kind="group" style={{ gridColumn: "1 / 7" }}>
      <Control expanded>
        <Label>Examinee First Name</Label>
        <Input
          required
          autoComplete="new"
          disabled={disabled || examineeInputs.chartnbr}
          maxLength={50}
          name="firstname"
          size="small"
          value={examineeInputs.firstname}
          onChange={(e) => onChange(e.target.name, e.target.value, true)}
        />
      </Control>
      <Control expanded>
        <Label>Examinee Last Name</Label>
        <Input
          required
          autoComplete="new"
          disabled={disabled || examineeInputs.chartnbr}
          maxLength={50}
          name="lastname"
          size="small"
          value={examineeInputs.lastname}
          onChange={(e) => onChange(e.target.name, e.target.value, true)}
        />
      </Control>
      {requiredFields.examineeSSNreqd && (
        <Control expanded>
          <Label>Examinee Social Security</Label>
          <SSNumberInput
            disabled={disabled}
            maxLength={11}
            name="SSN"
            required={requiredFields.examineeSSNreqd}
            size="small"
            value={examineeInputs.SSN}
            onChange={(name, value) => onChange(name, value, true)}
          />
        </Control>
      )}
    </Field>

    {requiredFields.examineeaddrreqd && (
      <Field style={{ gridColumn: "1 / 7" }}>
        <Field kind="group">
          <Control expanded>
            <Label>Examinee Address 1</Label>
            <Input
              autoComplete="new"
              disabled={disabled}
              maxLength={50}
              name="addr1"
              required={requiredFields.examineeaddrreqd}
              size="small"
              value={examineeInputs.addr1}
              onChange={(e) => onChange(e.target.name, e.target.value, true)}
            />
          </Control>
          <Control expanded>
            <Label>Examinee Address 2</Label>
            <Input
              autoComplete="new"
              disabled={disabled}
              maxLength={50}
              name="addr2"
              size="small"
              value={examineeInputs.addr2}
              onChange={(e) => onChange(e.target.name, e.target.value, true)}
            />
          </Control>
        </Field>
        <Field kind="group">
          <Control expanded>
            <Label>City</Label>
            <Input
              autoComplete="new"
              disabled={disabled}
              maxLength={50}
              name="city"
              required={requiredFields.examineeaddrreqd}
              size="small"
              type="text"
              value={examineeInputs.city}
              onChange={(e) => onChange(e.target.name, e.target.value, true)}
            />
          </Control>
          <StateSelect
            disabled={disabled}
            label="State"
            name="state"
            required={requiredFields.examineeaddrreqd}
            size="small"
            value={examineeInputs.state}
            onChange={(name, value) => onChange(name, value, true)}
          />
          <ZipInput
            disabled={disabled}
            state={examineeInputs.state}
            value={examineeInputs.zip || ""}
            onChange={(name, value) => onChange(name, value, true)}
          />
          <Control expanded>
            <Label>County</Label>
            <Input
              autoComplete="new"
              disabled={disabled}
              maxLength={50}
              name="county"
              size="small"
              value={examineeInputs.county}
              onChange={(e) => onChange(e.target.name, e.target.value, true)}
            />
          </Control>
        </Field>
      </Field>
    )}
  </div>
);

AddCaseForm.propTypes = {
  disabled: PropTypes.bool,
  inputs: PropTypes.object.isRequired,
  examineeInputs: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  setInputs: PropTypes.func.isRequired,
  requiredFields: PropTypes.object,
};

AddCaseForm.defaultProps = {
  disabled: false,
  requiredFields: {},
};

export default AddCaseForm;
