import { format } from "date-fns";

const DEFAULT_COLUMNS = [
  {
    Header: "document",
    id: "document",
    accessor: "document",
    sortType: (rowA, rowB) => {
      if (rowA.original.document > rowB.original.document) return 1;
      if (rowB.original.document > rowA.original.document) return -1;
      return 0;
    },
  },
  {
    Header: "name",
    id: "documentname",
    accessor: "documentname",
  },
  {
    Header: "description",
    id: "description",
    accessor: "description",
    maxWidth: "35%",
  },
  {
    Header: "LOB",
    id: "businessLine:name",
    accessor: "businessLine.name",
    Cell: ({ cell }) => cell?.value || "All",
  },
  {
    Header: "Type",
    id: "type",
    accessor: "type",
  },
  {
    Header: "Format",
    id: "outputFormat",
    accessor: "outputFormat",
  },
  {
    Header: "complete",
    id: "complete",
    accessor: "complete",
  },
  {
    Header: "status",
    id: "status",
    accessor: "status",
  },

  {
    Header: "added",
    id: "dateadded",
    accessor: "dateadded",
    Cell: ({ cell }) =>
      cell?.value ? format(new Date(cell.value), "MM/dd/yyyy") : "--",
  },
  {
    Header: "added by",
    id: "useridadded",
    accessor: "useridadded",
  },
  {
    Header: "edited",
    id: "dateedited",
    accessor: "dateedited",
    Cell: ({ cell }) =>
      cell?.value ? format(new Date(cell.value), "MM/dd/yyyy") : "--",
  },
  {
    Header: "edited by",
    id: "useridedited",
    accessor: "useridedited",
  },
];

export default DEFAULT_COLUMNS;
