import { useCallback } from "react";

import { useApolloClient } from "@apollo/client";
import { PROCESS_EXCEPTION_QUERY, LIST_CASE_DOCUMENT_QUERY } from "../graphql";

import { useAuth, useExceptions } from "../context";

export default function useCaseExceptions(casenbr) {
  const client = useApolloClient();

  const { state: authState } = useAuth();
  const { onSetExceptions } = useExceptions();

  return useCallback(
    async (oldStatus, newStatus) => {
      const { data: exceptionsData } = await client.query({
        query: PROCESS_EXCEPTION_QUERY,
        variables: {
          where: {
            casenbr: parseInt(casenbr, 10),
            prevStatus: parseInt(oldStatus, 10),
            nextStatus: parseInt(newStatus, 10),
            userid: authState.user.userid,
          },
        },
        fetchPolicy: "network-only",
        refetchQueries: [
          {
            query: LIST_CASE_DOCUMENT_QUERY,
            variables: {
              where: {
                casenbr: { equals: parseInt(casenbr, 10) },
              },
            },
          },
        ],
      });

      const displayMessageExceptions = exceptionsData?.processException?.filter(
        (exception) => exception.DisplayMessage
      );

      if (displayMessageExceptions?.length) {
        onSetExceptions([
          {
            casenbr,
            exceptions: displayMessageExceptions,
          },
        ]);
      }
    },
    [authState?.user?.userid, casenbr, client, onSetExceptions]
  );
}
