const COLUMNS = [
  {
    Header: "Code",
    accessor: "degreecode",
  },
  {
    Header: "Description",
    accessor: "description",
  },
];
export default COLUMNS;