import React from "react";
import PropTypes from "prop-types";

// components
import { Switch, Route } from "react-router-dom";
import { Tab } from "rbx";

// internal components
import Default from "../../routes/Defaults";
import Clients from "../../routes/Clients";
import Cases from "../../routes/Cases";
import UserDefinedFields from "../../routes/UserDefinedFields";
import Notes from "../../routes/Notes";
import DrsNotToUse from "../../routes/DrsNotToUse";
import Exceptions from "../../routes/Exceptions";
import Statistics from "../../routes/Statistics";
import Profile from "../../routes/Profile";

const CompanyTabs = ({
  companyCode,
  tab,
  history,
  onChange,
  inputs,
  disabled,
  handleSave,
  isAdmin,
}) => (
  <React.Fragment key="company-tabs">
    <Tab.Group kind="boxed">
      <Tab
        active={tab === "profile"}
        onClick={() => history.push(`/companies/${companyCode}/profile`)}
      >
        Profile
      </Tab>
      <Tab
        active={tab === "defaults"}
        onClick={() => history.push(`/companies/${companyCode}/defaults`)}
      >
        Defaults
      </Tab>
      <Tab
        active={tab === "clients"}
        onClick={() => history.push(`/companies/${companyCode}/clients`)}
      >
        Clients
      </Tab>
      <Tab
        active={tab === "cases"}
        onClick={() => history.push(`/companies/${companyCode}/cases`)}
      >
        Cases
      </Tab>
      <Tab
        active={tab === "user-defined"}
        onClick={() => history.push(`/companies/${companyCode}/user-defined`)}
      >
        User-Defined Fields
      </Tab>
      <Tab
        active={tab === "notes"}
        onClick={() => history.push(`/companies/${companyCode}/notes`)}
      >
        Notes
      </Tab>
      <Tab
        active={tab === "drsntu"}
        onClick={() => history.push(`/companies/${companyCode}/drsntu`)}
      >
        Drs Not to Use
      </Tab>
      <Tab
        active={tab === "exceptions"}
        onClick={() => history.push(`/companies/${companyCode}/exceptions`)}
      >
        Exceptions
      </Tab>
      <Tab
        active={tab === "statistics"}
        onClick={() => history.push(`/companies/${companyCode}/statistics`)}
      >
        Statistics
      </Tab>
    </Tab.Group>
    <Switch>
      <Route path="/companies/:companycode/profile">
        <Profile
          companyCode={companyCode}
          disabled={disabled}
          inputs={inputs}
          onChange={onChange}
          onSubmit={handleSave}
        />
      </Route>
      <Route path="/companies/:companycode/defaults">
        <Default
          companyCode={companyCode}
          disabled={disabled}
          inputs={inputs}
          onChange={onChange}
          onSubmit={handleSave}
        />
      </Route>
      <Route path="/companies/:companycode/clients">
        <Clients
          companyIntName={inputs?.intname || ""}
          companycode={companyCode}
        />
      </Route>
      <Route path="/companies/:companycode/cases">
        <Cases company={companyCode} />
      </Route>
      <Route path="/companies/:companycode/user-defined">
        <UserDefinedFields
          disabled={disabled}
          inputs={inputs}
          onChange={onChange}
          onSubmit={handleSave}
        />
      </Route>
      <Route path="/companies/:companycode/notes">
        <Notes
          company={inputs}
          disabled={disabled}
          handleSave={handleSave}
          inputs={inputs}
          onChange={onChange}
        />
      </Route>
      <Route path="/companies/:companycode/drsntu">
        <DrsNotToUse />
      </Route>
      <Route path="/companies/:companycode/exceptions">
        <Exceptions company={companyCode} />
      </Route>
      <Route path="/companies/:companycode/statistics">
        <Statistics inputs={inputs} />
      </Route>
    </Switch>
  </React.Fragment>
);

CompanyTabs.propTypes = {
  companyCode: PropTypes.string.isRequired,
  tab: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  inputs: PropTypes.object.isRequired,
  handleSave: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool,
};

CompanyTabs.defaultProps = {
  isAdmin: false,
};

export default CompanyTabs;
