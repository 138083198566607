import { gql } from "@apollo/client";

export const CREATE_EXAMINEE_CC_MUTATION = gql`
  mutation CREATE_CC_ADDRESS_MUTATION($data: ExamineeCCCreateInput!) {
    createExamineeCC(data: $data) {
      cccode
      chartnbr
    }
  }
`;

export const DELETE_EXAMINEE_CC_MUTATION = gql`
  mutation DELETE_EXAMINEE_CC_MUTATION($where: ExamineeCCWhereUniqueInput!) {
    deleteExamineeCC(where: $where) {
      cccode
      chartnbr
    }
  }
`;
