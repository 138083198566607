import { gql } from "@apollo/client";

export const RATS_INSURANCE_CARRIER_QUERY = gql`
  query InsuranceCarriers(
    $where: InsurancecarrierWhereInput
    $orderBy: [InsurancecarrierOrderByInput!]
    $take: Int
    $skip: Int
  ) {
    insurancecarriers(
      where: $where
      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      PK_ICid
      ICname
    }
    aggregateInsuranceCarrier: aggregateInsurancecarrier(where: $where) {
      count {
        PK_ICid
      }
    }
  }
`;

export const RATS_SINGLE_INSURANCE_CARRIER_QUERY = gql`
  query InsuranceCarrier($where: InsurancecarrierWhereUniqueInput!) {
    insurancecarrier(where: $where) {
      PK_ICid
      ICname
    }
  }
`;
