// eslint-disable react-hooks/exhaustive-deps
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Title, Button, Field, Control } from "rbx";
import { useLazyQuery } from "@apollo/client";
import { useHistory } from "react-router-dom";

import { SINGLE_USER_GROUP_QUERY } from "../../../../../graphql";
import UserGroupForm from "../UserGroupForm";
import UserGroupFunction from "../UserGroupFunction";
import { useModal } from "../../../../../context";
import Confirmation from "../../../../../components/Confirmation";

const INITIAL_STATE = {
  groupcode: "",
  groupdesc: "",
};

const UserGroupModal = ({ adding, onComplete, values, onDelete }) => {
  const { setModalOpen } = useModal();
  const history = useHistory();

  const [inputs, setInputs] = useState(adding ? INITIAL_STATE : values);
  const [updated, setUpdated] = useState([]);
  const [loading, setLoading] = useState(false);

  const [getUserGroupData, { data: userGroupData }] = useLazyQuery(
    SINGLE_USER_GROUP_QUERY
  );

  useEffect(() => {
    if (values?.groupcode) {
      getUserGroupData({
        variables: {
          where: {
            groupcode: values.groupcode,
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userGroupData?.userGroup) {
      const { userGroup } = userGroupData;

      const newInputs = {};
      Object.keys(INITIAL_STATE).forEach((key) => {
        newInputs[key] = userGroup[key];
      });

      setInputs(newInputs);
    } else {
      setInputs({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userGroupData?.userGroup]);

  const navigateToList = () => {
    history.push("/setup/user-groups");
  };

  const handleChange = (name, value) => {
    setInputs((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (!adding) {
      setUpdated((prev) => (prev.includes(name) ? prev : [...prev, name]));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    navigateToList();
    setLoading(true);
    onComplete(adding, inputs, values?.groupcode, adding ? null : updated);
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    setModalOpen(
      true,
      <Confirmation
        message="Are you sure you want to delete this User Group?"
        onCancel={() =>
          setModalOpen(
            true,
            <UserGroupModal
              adding={adding}
              values={values}
              onComplete={onComplete}
              onDelete={onDelete}
            />
          )
        }
        onConfirm={() => {
          setModalOpen(false);
          onDelete(values.groupcode);
        }}
      />
    );
  };

  const isDisabled =
    !inputs.groupcode ||
    !inputs.groupdesc ||
    (!adding && !updated?.length) ||
    loading;

  return (
    <form id="problem-form" onSubmit={handleSubmit}>
      <header className="page-head">
        <div className="page-head-start">
          <Title size={5}>
            {[adding ? "Create" : "Edit", "User Group"].join(" ")}
          </Title>
        </div>
        <div className="page-head-end">
          <Button.Group hasAddons>
            <Button
              size="small"
              type="button"
              onClick={() => {
                navigateToList();
                onComplete(false);
              }}
            >
              <span>Cancel</span>
            </Button>

            <Button
              color="primary"
              disabled={isDisabled}
              form="problem-form"
              size="small"
              state={loading ? "loading" : ""}
              type="submit"
            >
              <span>Submit</span>
            </Button>
          </Button.Group>
        </div>
      </header>
      <hr />
      <UserGroupForm
        groupcode={values?.groupcode || ""}
        inputs={inputs}
        onChange={handleChange}
      />
      <hr />
      {!adding && (
        <React.Fragment>
          <UserGroupFunction inputs={{ entityid: values?.groupcode }} />
          <hr />
          <Field>
            <Control>
              <Button
                color="danger"
                size="small"
                type="button"
                onClick={handleDelete}
              >
                <span>Delete</span>
              </Button>
            </Control>
          </Field>
        </React.Fragment>
      )}
    </form>
  );
};

UserGroupModal.propTypes = {
  adding: PropTypes.bool,
  onComplete: PropTypes.func,
  values: PropTypes.object,
  onDelete: PropTypes.func,
};

UserGroupModal.defaultProps = {
  adding: false,
  onComplete: () => null,
  values: null,
  onDelete: () => null,
};

export default UserGroupModal;
