/* eslint-disable import/prefer-default-export */
import { gql } from "@apollo/client";

export const LSI_FIND_FIRST_RECORD_PROVIDER_QUERY = gql`
  query FindFirstRecordprovider(
    $where: RecordproviderWhereInput
    $orderBy: [RecordproviderOrderByInput!]
  ) {
    findFirstRecordprovider(where: $where, orderBy: $orderBy) {
      RPid
      RPproviderID
      RPrecordType
      RPinstructions
      RPrequestMethod
      RPrushStatus
      RPother
      RPbilling
      RPemployment
      RPpsych
      RPfilms
      RPscholastic
      RPmedical
      RPinsurance
      RPfirstparty
      RPthirdparty
      RPpharmacy
      RPworkerComp
      RPanimalcont
      RPcrimrecords
      RPpolicerecords
      RPlifeIns
      RPtype
      RPpip
      RPmotorvehicles
      RPerPhysician
      RPinsUnderwrite
      RPradiology
      RPorigEnteredDate
      RPpipPD
      RCprovider
      submitted
      RPems
      RPdateRange
      RPrecFrom
      RPrecTo
    }
  }
`;
