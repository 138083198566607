import React, { useState, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { Title, Field, Control, Input, Button, Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useApolloClient } from "@apollo/client";

import DataTable from "../../components/DataTable";
import COLUMNS from "./columns";
import { debounce, getFilterState, saveFilterState, omit } from "../../utils";
import { CUSTOM_LIST_DOCTORS_QUERY, FIND_FIRST_ZIP_QUERY } from "../../graphql";
import DoctorListFilters from "./components/DoctorListFilters";
import DoctorCreateModal from "./components/DoctorCreateModal";

import { useModal } from "../../context";

const DEFAULT_FILTER_STATE = {
  OR: [
    {
      usdint1: { equals: 2 },
    },
    {
      usdint1: { equals: null },
    },
  ],
  status: { equals: "Active" },
};

const DoctorListPage = (props) => {
  const history = useHistory();

  const client = useApolloClient();

  const { setModalOpen } = useModal();

  const [where, setWhere] = useState(
    getFilterState("DOCTOR_LIST_FILTERS", DEFAULT_FILTER_STATE)
  );

  const [showFilters, setShowFilters] = useState(
    Object.keys(getFilterState("DOCTOR_LIST_FILTERS")).length > 2
  );

  const handleRowClick = (doctor) => {
    history.push(`/doctors/${doctor.doctorcode}/profile`);
  };

  const handleReset = () => {
    setWhere({ ...DEFAULT_FILTER_STATE });
    if (document.getElementById("search-input")) {
      document.getElementById("search-input").value = "";
    }
  };

  const handleChange = (name, value) => {
    if (!value) {
      setWhere((prev) =>
        Object.keys(prev).reduce((acc, curr) => {
          if (curr !== name) acc[curr] = prev[curr];
          return acc;
        }, {})
      );
    } else {
      setWhere((prev) => ({ ...prev, [name]: value }));
    }
  };

  useEffect(() => {
    saveFilterState("DOCTOR_LIST_FILTERS", where);
  }, [where]);

  const handleAddClick = () => {
    setModalOpen(
      true,
      <DoctorCreateModal
        onComplete={() => {
          setModalOpen(false, "");
        }}
      />
    );
  };

  const handleBlur = async (name, value) => {
    const isCity = name === "city";
    const isState = name === "state";

    if ((isCity && where.state) || (isState && where.city)) {
      const zipWhere = {};
      zipWhere.sCity = { equals: isCity ? value : where.city.equals };
      zipWhere.sState = { equals: isState ? value : where.state.equals };

      const {
        data: { findFirstZipCode },
      } = await client.query({
        query: FIND_FIRST_ZIP_QUERY,
        variables: {
          where: zipWhere,
        },
      });
      const zipCode = findFirstZipCode?.sZip;
      const county = findFirstZipCode?.sCountyName;

      if (zipCode && county) {
        setWhere((prev) => ({
          ...prev,
          zip: { equals: zipCode },
          county: { equals: county },
        }));
      }
    }
  };

  const isDisabled = useMemo(
    () => !Object.keys(where).length || (!!where.proximity && !where.zip),
    [where]
  );

  return (
    <div>
      <div className="section-header">
        <Title style={{ marginRight: 15 }}>Doctors</Title>
        <Field kind="group" style={{ paddingTop: "0.3rem" }}>
          <Control>
            <Input
              autoComplete="off"
              id="search-input"
              placeholder="Search by Name"
              size="small"
              onChange={debounce(({ target: { value } }) => {
                if (value) {
                  const [lastname, firstname] = value
                    .split(/,/)
                    .map((x) => x.trim())
                    .filter(Boolean);
                  const newWhere = {
                    lastname: { startsWith: lastname },
                    firstname: { startsWith: firstname },
                    OR: [
                      {
                        usdint1: { equals: 2 },
                      },
                      {
                        usdint1: { equals: null },
                      },
                    ],
                  };
                  setWhere((prev) => ({ ...prev, ...newWhere }));
                } else {
                  setWhere((prev) => ({
                    ...omit({ ...prev }, ["lastname", "firstname"]),
                    ...DEFAULT_FILTER_STATE,
                  }));
                }
              }, 500)}
            />
          </Control>
          <Control>
            <Button
              color="warning"
              size="small"
              onClick={() => setShowFilters((prev) => !prev)}
            >
              <Icon>
                <FontAwesomeIcon icon="filter" />
              </Icon>
              <span>Filters</span>
            </Button>
          </Control>
          <Control>
            <Button color="primary" size="small" onClick={handleAddClick}>
              <Icon>
                <FontAwesomeIcon icon="plus" />
              </Icon>
              <span>Add</span>
            </Button>
          </Control>
        </Field>
      </div>
      {showFilters && (
        <DoctorListFilters
          filters={where}
          isDisabled={isDisabled}
          show={showFilters}
          onBlur={handleBlur}
          onChange={handleChange}
          onReset={handleReset}
        />
      )}
      <DataTable
        aggregateKey="doctorcode"
        aggregateName="aggregateDoctorItems"
        columns={COLUMNS}
        fetchPolicy="cache-and-network"
        name="doctorItems"
        orderBy={[
          { id: "lastname", desc: false },
          { id: "firstname", desc: false },
        ]}
        query={CUSTOM_LIST_DOCTORS_QUERY}
        where={omit(where, ["useProximity"])}
        onRowClick={handleRowClick}
      />
    </div>
  );
};

DoctorListPage.propTypes = {};

export default DoctorListPage;
