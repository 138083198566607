import { gql } from "@apollo/client";

const FIND_PERSON_BY_ID_QUERY = gql`
  query Person($where: PersonWhereUniqueInput!) {
    person(where: $where) {
      PersonID
      EmailAddress
      UserName
      FirstName
      LastName
      City
      StateCode
      Phone
      ClientCode
      IsBroadspire
      IsDeadlineDateRequired
      IsLowes
      IsNationwide
      IsNationwideRequired
      IsSentry
      IsUSAA
      IsUSAALegal
      RGID
    }
  }
`;

export default FIND_PERSON_BY_ID_QUERY;
