import React from "react";
import PropTypes from "prop-types";
import { Field, Control, Label, Input } from "rbx";
import RatsClientOfficeSelect from "../../../../components/RatsClientOfficeSelect";

const BroadspireForm = ({ handleChange, inputs, disabled }) => (
  <React.Fragment>
    <Field className="grid-override is-four-complete">
      <RatsClientOfficeSelect
        required
        showBlank
        disabled={disabled}
        label="Service Center"
        name="ServiceCenter"
        value={inputs.ServiceCenter}
        where={{
          COclientMasterID: {
            equals: "CM722",
          },
          COofficeName: {
            contains: "service center",
          },
        }}
        onChange={(name, value) => handleChange(name, value)}
      />
      <Control>
        <Label>Client</Label>
        <Input
          required
          autoComplete="new"
          disabled={disabled}
          maxLength={50}
          name="BroadspireClient"
          size="small"
          value={inputs.BroadspireClient}
          onChange={(e) => handleChange(e.target.name, e.target.value)}
        />
      </Control>
      <Control>
        <Label>Carrier</Label>
        <Input
          required
          autoComplete="new"
          disabled={disabled}
          maxLength={50}
          name="BroadspireCarrier"
          size="small"
          value={inputs.BroadspireCarrier}
          onChange={(e) => handleChange(e.target.name, e.target.value)}
        />
      </Control>
      <Control>
        <Label>Claim Number</Label>
        <Input
          disabled
          name="ClaimNumber"
          size="small"
          value={inputs.ClaimNumber}
        />
      </Control>
    </Field>
  </React.Fragment>
);

BroadspireForm.propTypes = {
  handleChange: PropTypes.func.isRequired,
  inputs: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default BroadspireForm;
