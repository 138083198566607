import { gql } from "@apollo/client";

export const LIST_INSIGHT_CODES_QUERY = gql`
  query insightCodes(
    $take: Int
    $orderBy: [InsightCodeOrderByInput!]
    $skip: Int
    $where: InsightCodeWhereInput
  ) {
    insightCodes(take: $take, orderBy: $orderBy, skip: $skip, where: $where) {
      Code
      Description
      Type
    }
  }
`;

export const SINGLE_INSIGHT_CODE_QUERY = gql`
  query insightCode($where: InsightCodeWhereUniqueInput!) {
    insightCode(where: $where) {
      Code
      Description
      Type
    }
  }
`;
