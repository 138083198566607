import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/client";
import { Title, Fieldset, Button } from "rbx";
import { customToast as toast, getDateEST } from "../../../../utils";
import ExamineeForm from "../ExamineeForm";
import {
  CREATE_EXAMINEE_MUTATION,
  LIST_EXAMINEE_QUERY,
} from "../../../../graphql";
import { useAuth } from "../../../../context";

const DEFAULT_STATE = {
  firstname: "",
  lastname: "",
  middleinitial: "",
  prefix: "",
  addr1: "",
  addr2: "",
  city: "",
  state: "",
  zip: "",
  county: "",
  Country: "",
  phone1: "",
  phone2: "",
  sex: "",
  DOB: "",
  SSN: "",
  fax: "",
  email: "",
};

const requiredKeys = ["firstname", "lastname", "addr1", "city", "state"];

const convertInputToVariables = (variables, keys, adding = false) =>
  keys.reduce((acc, curr) => {
    if (variables[curr]) {
      acc[curr] = adding ? variables[curr] : { set: variables[curr] };
    }
    return acc;
  }, {});

const ExamineeModal = ({ onComplete }) => {
  const { state: authState } = useAuth();
  const [createExaminee] = useMutation(CREATE_EXAMINEE_MUTATION);
  const [loading, setLoading] = useState(false);

  const [inputs, setInputs] = useState({
    ...DEFAULT_STATE,
  });

  const handleChange = (e, value) => {
    const newValue = e.target ? e.target.value : value;
    const newName = e.target ? e.target.name : e;
    setInputs((prev) => ({ ...prev, [newName]: newValue }));
  };

  const handleSave = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const date = getDateEST();
      const { data } = await createExaminee({
        variables: {
          data: {
            ...convertInputToVariables(
              inputs,
              Object.keys(DEFAULT_STATE),
              true
            ),
            dateadded: date,
            dateedited: date,
            useridadded: authState?.user?.userid,
            useridedited: authState?.user?.userid,
          },
        },
        refetchQueries: [
          {
            query: LIST_EXAMINEE_QUERY,
          },
        ],
      });
      const examineeCreated = data?.createExaminee;
      if (examineeCreated?.chartnbr) {
        onComplete(examineeCreated.chartnbr);
      }
      toast.success("Examinee created successfully.");
    } catch (err) {
      toast.error(`Error creating Examinee.`);
    } finally {
      setLoading(false);
    }
  };

  const isDisabled = useMemo(
    () => loading || requiredKeys.some((key) => !inputs[key]),
    [inputs, loading]
  );

  const handleCancel = () => {
    onComplete();
  };

  return (
    <form
      className="add-examinee-form"
      id="add-examinee-form"
      onSubmit={handleSave}
    >
      <header className="page-head">
        <div className="page-head-start">
          <Title size={5}>Create Examinee</Title>
        </div>
        <div className="page-head-end">
          <Button.Group hasAddons>
            <Button size="small" type="button" onClick={handleCancel}>
              <span>Cancel</span>
            </Button>
            <Button
              color="primary"
              disabled={isDisabled}
              form="add-examinee-form"
              size="small"
              state={loading && "loading"}
              type="submit"
            >
              <span>Submit</span>
            </Button>
          </Button.Group>
        </div>
      </header>
      <hr />
      <Fieldset>
        <ExamineeForm
          disabled={loading}
          inputs={inputs}
          onChange={handleChange}
        />
      </Fieldset>
      <hr />
      {/* <pre>
        <code>{JSON.stringify(inputs, null, 2)}</code>
      </pre> */}
    </form>
  );
};

ExamineeModal.propTypes = {
  onComplete: PropTypes.func,
};

ExamineeModal.defaultProps = {
  onComplete: () => {},
};

export default ExamineeModal;
