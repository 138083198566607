/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import PropTypes from "prop-types";

import { Checkbox } from "rbx";

const CheckboxCell = React.forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = React.useRef();
  const resolvedRef = ref || defaultRef;

  React.useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    <React.Fragment>
      <Checkbox ref={resolvedRef} {...rest} />
    </React.Fragment>
  );
});

CheckboxCell.propTypes = {
  indeterminate: PropTypes.bool,
};
CheckboxCell.defaultProps = {
  indeterminate: false,
};

export default CheckboxCell;
