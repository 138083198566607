import React, { useState } from "react";
import PropTypes from "prop-types";
import { useMutation, useApolloClient } from "@apollo/client";
import { Title, Fieldset, Button } from "rbx";
import { DateTime } from "luxon";

import { customToast as toast } from "../../../../utils";
import ClaimForm from "../ClaimForm";
import {
  CREATE_CE_CLAIM_MUTATION,
  CREATE_APOLLO_CLAIM_MUTATION,
  FIND_FIRST_CLAIM_QUERY,
} from "../../../../graphql";

const DEFAULT_STATE = {
  CompanyName: "",
  ExternalClaimID: "",
  ClaimNumber: "",
  DateOfLoss: "",
  SOJ: "",
  ExternalPatientID: "",
  ClaimantIPNumber: "",
  ClaimantFirstName: "",
  ClaimantMiddleName: "",
  ClaimantLastName: "",
  DateOfBirth: "",
  Address1: "",
  Address2: "",
  City: "",
  State: "",
  Zip5: "",
  Zip4: "",
  Phone1: "",
  Phone2: "",
  SSN: "",
  Sex: "",
  CoverageType: "",
  EligibilityStatus: "",
  HCP: false,
};

const convertInputToVariables = (variables, keys, adding = true) =>
  keys.reduce((acc, curr) => {
    if (variables[curr]) {
      acc[curr] = adding ? variables[curr] : { set: variables[curr] };
    } else {
      acc[curr] = adding ? null : { set: null };
    }
    return acc;
  }, {});

const ClaimModal = ({ onComplete, openDuplicateClaim }) => {
  const [createClaim] = useMutation(CREATE_CE_CLAIM_MUTATION);
  const [createApolloClaim] = useMutation(CREATE_APOLLO_CLAIM_MUTATION);

  const client = useApolloClient();

  const [loading, setLoading] = useState(false);

  const [inputs, setInputs] = useState({
    ...DEFAULT_STATE,
  });

  const handleChange = (name, value) => {
    setInputs((prev) => ({ ...prev, [name]: value }));
  };

  const handleSave = async (e) => {
    e.preventDefault();
    setLoading(true);
    const data = {
      ...convertInputToVariables(inputs, Object.keys(DEFAULT_STATE), true),
    };

    data.HCP = inputs.HCP;

    if (data.DateOfLoss && data.DateOfBirth) {
      let foundDuplicate;
      try {
        const dobArr = data.DateOfBirth.split("-");
        const dolArr = data.DateOfLoss.split("-");

        const startOfDOB = DateTime.local(
          +dobArr[0],
          +dobArr[1],
          +dobArr[2],
          5,
          30
        )
          .startOf("day")
          .toISO();
        const endOfDOB = DateTime.local(
          +dobArr[0],
          +dobArr[1],
          +dobArr[2],
          5,
          30
        )
          .endOf("day")
          .toISO();
        const startOfDOL = DateTime.local(
          +dolArr[0],
          +dolArr[1],
          +dolArr[2],
          5,
          30
        )
          .startOf("day")
          .toISO();
        const endOfDOL = DateTime.local(
          +dolArr[0],
          +dolArr[1],
          +dolArr[2],
          5,
          30
        )
          .endOf("day")
          .toISO();

        const { data: foundData } = await client.query({
          query: FIND_FIRST_CLAIM_QUERY,
          variables: {
            where: {
              AND: [
                {
                  ClaimantFirstName: {
                    equals: data.ClaimantFirstName,
                  },
                  ClaimantLastName: {
                    equals: data.ClaimantLastName,
                  },
                  DateOfBirth: {
                    gte: startOfDOB,
                    lte: endOfDOB,
                  },
                  DateOfLoss: {
                    gte: startOfDOL,
                    lte: endOfDOL,
                  },
                },
              ],
            },
          },
          fetchPolicy: "network-only",
        });

        if (foundData.findFirstClaims) {
          foundDuplicate = foundData.findFirstClaims;
          throw Error();
        }
      } catch (err) {
        const errorJSX = () => (
          <div style={{ textAlign: "center" }}>
            <h2>Another Claim with same DOL, DOB and Name already exists.</h2>
            <Button
              size="small"
              state={loading ? "loading" : ""}
              style={{
                backgroundColor: "#e74c3c",
                borderColor: "#e74c3c",
                color: "#fff",
              }}
              type="submit"
              onClick={(event) => {
                event.preventDefault();
                openDuplicateClaim(foundDuplicate);
              }}
            >
              <span>Click here to go to Claim</span>
            </Button>
          </div>
        );
        toast.error(errorJSX());
        onComplete();
        setLoading(false);
        return;
      }
    }

    ["DateOfBirth", "DateOfLoss"].forEach((dateField) => {
      if (data[dateField]) {
        const [year, month, day] = data[dateField]
          .split("-")
          .map((x) => parseInt(x, 10));

        const d = new Date(year, month - 1, day);

        d.setHours(13);

        data[dateField] = DateTime.fromJSDate(d)
          .setZone("Greenwich", { keepLocalTime: true })
          .toISO();
      }
    });

    try {
      const { data: createClaimData } = await createClaim({
        variables: {
          data,
        },
      });

      await createApolloClaim({
        variables: {
          data: {
            CentralEligibilityID:
              createClaimData?.createClaims?.CentralEligibilityID,
            CreatedDate: new Date(),
            Archive: false,
          },
        },
      });

      toast.success("Claimant created successfully.");
      onComplete();
    } catch (err) {
      toast.error("Error creating Claimant.");
    } finally {
      setLoading(false);
    }
  };

  const isDisabled = loading;

  return (
    <React.Fragment>
      <header className="page-head">
        <div className="page-head-start">
          <Title size={5}>Create Claim</Title>
        </div>
        <div className="page-head-end">
          <Button.Group hasAddons>
            <Button
              disabled={loading}
              size="small"
              type="button"
              onClick={onComplete}
            >
              <span>Cancel</span>
            </Button>
            <Button
              color="primary"
              disabled={loading}
              form="claim-form"
              size="small"
              state={loading ? "loading" : ""}
              type="submit"
            >
              <span>Submit</span>
            </Button>
          </Button.Group>
        </div>
      </header>
      <hr />
      <Fieldset>
        <ClaimForm
          disabled={isDisabled}
          formId="claim-form"
          inputs={inputs}
          onChange={handleChange}
          onSubmit={handleSave}
        />
      </Fieldset>
      <hr />
    </React.Fragment>
  );
};

ClaimModal.propTypes = {
  onComplete: PropTypes.func,
  openDuplicateClaim: PropTypes.func,
};

ClaimModal.defaultProps = {
  onComplete: (e) => e,
  openDuplicateClaim: (e) => e,
};

export default ClaimModal;
