import React from "react";
import PropTypes from "prop-types";
import { Field, Control, Input, Label } from "rbx";

const UserFunctionForm = ({ inputs, onChange, functioncode }) => (
  <div>
    <Field kind="group">
      <Control expanded>
        <Label>Function Code</Label>
        <Input
          autoComplete="new"
          disabled={!!functioncode.length}
          maxLength="15"
          name="functioncode"
          size="small"
          value={functioncode.length ? functioncode : inputs.functioncode}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
      <Control expanded>
        <Label>Description</Label>
        <Input
          autoComplete="new"
          maxLength="30"
          name="functiondesc"
          size="small"
          value={inputs.functiondesc}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
    </Field>
  </div>
);

UserFunctionForm.propTypes = {
  inputs: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  functioncode: PropTypes.string,
};

UserFunctionForm.defaultProps = {
  functioncode: "",
};

export default UserFunctionForm;
