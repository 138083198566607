/* eslint-disable react/prop-types */
import React from "react";

import { format } from "date-fns";
import { convertTimeZone, getDaysInQueue } from "../../utils";
import { TableCell } from "../../components/DataTable";
import DropdownButton from "../../components/DropdownButton";

export const CASE_CLIENT_COLUMNS = (duplicateCaseItem) => [
  {
    Header: "",
    id: "duplicate",
    Cell: ({ cell }) => (
      <DropdownButton
        options={[
          {
            title: "Duplicate",
            onClick: () => duplicateCaseItem(cell.row.original, false),
          },
          {
            title: "Add Sub Case",
            onClick: () => duplicateCaseItem(cell.row.original, true),
          },
        ]}
      />
    ),
  },
  {
    Header: "Case",
    id: "casenbr",
    accessor: "casenbr",
    Cell: ({ cell, row }) => (
      <span
        style={{
          color: row?.original?.priority?.includes("Normal")
            ? "#363636"
            : "Crimson",
        }}
      >
        {cell.value}
      </span>
    ),
  },
  {
    Header: "Priority",
    id: "priority",
    accessor: "priority",
    Cell: ({ cell }) => {
      const isNormalPriority = cell?.value?.includes("Normal");
      return cell.value ? (
        <span style={{ color: isNormalPriority ? "#363636" : "Crimson" }}>
          {cell.value}
        </span>
      ) : (
        ""
      );
    },
  },
  {
    Header: "Examinee",
    id: "examinee:lastname",
    accessor: "examinee",
    Cell: ({ cell, row }) => {
      if (!cell.value) return "--";
      const { mastersubcase } = row.original;
      const style = {};
      if (mastersubcase === "M") {
        style.color = "Crimson";
      }
      if (mastersubcase === "S") {
        style.color = "RoyalBlue";
      }
      return (
        <span style={style}>
          {[cell.value.lastname, cell.value.firstname]
            .filter(Boolean)
            .join(", ")}
        </span>
      );
    },
  },
  {
    Header: "Claim",
    id: "claimnbr",
    accessor: "claimnbr",
  },
  {
    Header: "Specialty",
    id: "sreqspecialty",
    accessor: "sreqspecialty",
  },
  {
    Header: "IQ",
    id: "laststatuschg",
    accessor: "laststatuschg",
    Cell: ({ cell }) => (
      <TableCell.Number
        value={cell?.value ? getDaysInQueue(cell.value) : "--"}
      />
    ),
  },
  {
    Header: "Date Added",
    id: "dateadded",
    accessor: "dateadded",
    Cell: ({ cell }) =>
      cell?.value
        ? format(convertTimeZone(cell.value), "MM/dd/yyyy hh:mm")
        : "--",
  },
  {
    Header: "Requested Doctor",
    id: "requesteddoc",
    accessor: "requesteddoc",
  },
  {
    Header: "Appt. Time",
    id: "Appttime",
    accessor: "Appttime",
    Cell: ({ cell }) =>
      cell?.value
        ? format(convertTimeZone(cell.value), "MM/dd/yyyy hh:mm")
        : "--",
  },
  {
    Header: "Company",
    id: "client:company:intname",
    accessor: "client.company.intname",
  },
  {
    Header: "Doctor",
    id: "doctor:lastname",
    accessor: "doctor",
    Cell: ({ cell }) =>
      `${cell?.value?.lastname || ""} ${cell?.value?.firstname || ""}`,
  },
  {
    Header: "Status",
    id: "status",
    accessor: "Status.statusdesc",
  },
  {
    Header: "Case Type",
    id: "casetype",
    accessor: "caseType.description",
  },
  {
    Header: "Service",
    id: "service:description",
    accessor: "service.description",
  },

  {
    Header: "Marketer",
    id: "marketercode",
    accessor: "marketercode",
  },
  {
    Header: "Scheduler",
    id: "schedulercode",
    accessor: "schedulercode",
  },
  {
    Header: "QA Rep",
    id: "QARep",
    accessor: "QARep",
  },
];

export const ASSOCIATED_CASE_COLUMNS = (maskAsMasterOrSub) => [
  {
    Header: "",
    id: "actions",
    accessor: "",
    Cell: ({ cell, row }) => (
      <DropdownButton
        options={[
          {
            title: "Mark as Subcase",
            onClick: () => maskAsMasterOrSub(cell.row.original, false),
          },
        ]}
      />
    ),
  },
  {
    Header: "Type",
    id: "mastersubcase",
    accessor: "mastersubcase",
  },
  {
    Header: "Case",
    id: "casenbr",
    accessor: "casenbr",
    Cell: ({ cell, row }) => {
      const isNormalPriority = row?.original?.priority?.includes("Normal");
      return (
        <span style={{ color: isNormalPriority ? "#363636" : "Crimson" }}>
          {cell.value}
        </span>
      );
    },
  },
  {
    Header: "Priority",
    id: "priority",
    accessor: "priority",
    Cell: ({ cell }) => {
      if (cell.value) {
        return (
          <span
            style={{
              color: cell.value.includes("Normal") ? "#363636" : "Crimson",
            }}
          >
            {cell.value}
          </span>
        );
      }
      return "";
    },
  },
  {
    Header: "Examinee",
    id: "examinee:lastname",
    accessor: "examinee",
    Cell: ({ cell }) =>
      cell.value
        ? `${cell.value.lastname || ""}, ${cell.value.firstname || ""}`
        : "--",
  },
  {
    Header: "Claim",
    id: "claimnbr",
    accessor: "claimnbr",
  },
  {
    Header: "Specialty",
    id: "sreqspecialty",
    accessor: "sreqspecialty",
  },
  {
    Header: "IQ",
    id: "laststatuschg",
    accessor: "laststatuschg",
    Cell: ({ cell }) => (
      <TableCell.Number
        value={cell?.value ? getDaysInQueue(cell.value) : "--"}
      />
    ),
  },
  {
    Header: "Date Added",
    id: "dateadded",
    accessor: "dateadded",
    Cell: ({ cell }) =>
      cell?.value
        ? format(convertTimeZone(cell.value), "MM/dd/yyyy hh:mm")
        : "--",
  },
  {
    Header: "Appt. Time",
    id: "Appttime",
    accessor: "Appttime",
    Cell: ({ cell }) =>
      cell?.value
        ? format(convertTimeZone(cell.value), "MM/dd/yyyy hh:mm")
        : "--",
  },
  {
    Header: "Company",
    id: "client:company:intname",
    accessor: "client.company.intname",
  },
  {
    Header: "Doctor",
    id: "doctor:lastname",
    accessor: "doctor",
    Cell: ({ cell }) =>
      `${cell?.value?.lastname || ""} ${cell?.value?.firstname || ""}`,
  },
  {
    Header: "Status",
    id: "status",
    accessor: "Status.statusdesc",
  },
  {
    Header: "Case Type",
    id: "casetype",
    accessor: "caseType.description",
  },
  {
    Header: "Service",
    id: "service:description",
    accessor: "service.description",
  },

  {
    Header: "Marketer",
    id: "marketercode",
    accessor: "marketercode",
  },
  {
    Header: "Scheduler",
    id: "schedulercode",
    accessor: "schedulercode",
  },
  {
    Header: "QA Rep",
    id: "QARep",
    accessor: "QARep",
  },
];

export const CASE_COMPANY_COLUMNS = [
  {
    Header: "Case",
    id: "casenbr",
    accessor: "casenbr",
    Cell: ({ cell, row }) => {
      const isNormalPriority = row?.original?.priority?.includes("Normal");
      return (
        <span style={{ color: isNormalPriority ? "#363636" : "red" }}>
          {cell.value}
        </span>
      );
    },
  },
  {
    Header: "Examinee",
    id: "examinee:lastname",
    accessor: "examinee",
    Cell: (cell) =>
      cell?.value ? `${cell.value.lastname}, ${cell.value.firstname}` : " ",
  },
  {
    Header: "Doctor",
    id: "DoctorName",
    accessor: "DoctorName",
  },
  {
    Header: "Appt. Date",
    id: "ApptDate",
    accessor: "ApptDate",
    Cell: ({ cell }) =>
      cell?.value ? format(convertTimeZone(cell.value), "MM/dd/yyyy") : "--",
  },
  {
    Header: "Client",
    id: "client:lastname",
    accessor: "client",
    Cell: (cell) =>
      cell?.value ? `${cell.value.lastname}, ${cell.value.firstname}` : " ",
  },
  {
    Header: "Case Type",
    id: "casetype",
    accessor: "caseType.description",
  },
  {
    Header: "Service",
    id: "service:description",
    accessor: "service.description",
  },
  {
    Header: "Status",
    id: "status",
    accessor: "Status.statusdesc",
  },
];

export const CASE_HISTORY_COLUMNS = [
  {
    Header: "Event Date",
    id: "eventdate",
    accessor: "eventdate",
    Cell: ({ cell }) =>
      cell?.value
        ? format(convertTimeZone(cell.value), "MM/dd/yyyy hh:mm a")
        : "--",
    maxWidth: "10%",
  },

  {
    Header: "Description",
    id: "eventdesc",
    accessor: "eventdesc",
    maxWidth: "10%",
  },
  {
    Header: "Other Info",
    id: "otherinfo",
    accessor: "otherinfo",
    Cell: ({ cell }) => (
      <p
        style={{
          color: cell.value?.includes("SHOW") ? "red" : "",
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          width: "auto",
          maxWidth: 700,
        }}
      >
        {cell.value}
      </p>
    ),
    maxWidth: "50%",
  },
  {
    Header: "IQ",
    id: "duration",
    accessor: "IQ",
    maxWidth: "10%",
  },
  {
    Header: "Web",
    id: "PublishOnWeb",
    accessor: "PublishOnWeb",
    Cell: ({ cell }) => (cell?.value ? "Yes" : "No"),
    maxWidth: "10%",
  },
  // {
  //   Header: "Edited",
  //   id: "dateedited",
  //   accessor: "dateedited",
  //   Cell: ({ cell }) =>
  //     cell?.value
  //       ? format(convertTimeZone(cell.value), "MM/dd/yyyy hh:mm a")
  //       : "--",
  //   maxWidth: "10%",
  // },
  // {
  //   Header: "By",
  //   id: "useridedited",
  //   accessor: "useridedited",
  //   maxWidth: "10%",
  // },
  // {
  //   Header: "Added",
  //   id: "dateadded",
  //   accessor: "dateadded",
  //   Cell: ({ cell }) =>
  //     cell?.value
  //       ? format(convertTimeZone(cell.value), "MM/dd/yyyy hh:mm a")
  //       : "--",
  //   maxWidth: "10%",
  // },
  {
    Header: "By",
    id: "userid",
    accessor: "userid",
    maxWidth: "10%",
  },
];

export const COLUMNS_APOLLO_NOTES = [
  {
    Header: "Note",
    accessor: "NoteText",
    id: "NoteText",
    maxWidth: "40%",
    Cell: ({ cell }) => <pre>{cell?.value || ""}</pre>,
  },
  {
    Header: "Type",
    accessor: "Type.NoteType",
    id: "NoteTypeID",
    maxWidth: "40%",
  },
  {
    Header: "Created By",
    accessor: "CreatedBy",
    id: "CreatedBy",
    maxWidth: "10%",
  },
  {
    Header: "Created At",
    id: "CreatedDate",
    accessor: "CreatedDate",
    maxWidth: "10%",
    Cell: ({ cell }) =>
      cell?.value
        ? format(convertTimeZone(cell.value), "MM/dd/yyyy hh:mm")
        : "--",
  },
];

export const CASE_ACCOUNTING_COLUMNS = [
  {
    Header: "Case",
    id: "casenbr",
    accessor: "casenbr",
    Cell: ({ cell, row }) => {
      const isNormalPriority = row?.original?.priority?.includes("Normal");
      return (
        <span style={{ color: isNormalPriority ? "#363636" : "red" }}>
          {cell.value}
        </span>
      );
    },
  },
  {
    Header: "Priority",
    id: "priority",
    accessor: "priority",
    Cell: ({ cell }) => {
      if (cell.value) {
        return (
          <span
            style={{ color: cell.value.includes("Normal") ? "#363636" : "red" }}
          >
            {cell.value}
          </span>
        );
      }
      return "";
    },
  },
  {
    Header: "IQ",
    id: "laststatuschg",
    accessor: "laststatuschg",
    Cell: ({ cell }) => (
      <TableCell.Number
        value={cell?.value ? getDaysInQueue(cell.value) : "--"}
      />
    ),
  },
  {
    Header: "Date",
    id: "ApptDate",
    accessor: "ApptDate",
    Cell: ({ cell }) =>
      cell?.value ? format(convertTimeZone(cell.value), "MM/dd/yyyy") : "--",
  },
  {
    Header: "Examinee",
    id: "examinee:lastname",
    accessor: "examinee",
    Cell: ({ cell }) =>
      cell.value ? `${cell.value.lastname}, ${cell.value.firstname}` : "--",
  },
  {
    Header: "Doctor",
    id: "DoctorName",
    accessor: "DoctorName",
  },
  {
    Header: "Company",
    id: "IMECompany",
    accessor: "client.company.intname",
  },
  {
    Header: "Service",
    id: "service:description",
    accessor: "service.description",
  },
  {
    Header: "Invoice Date",
    id: "InvoiceDate",
    accessor: "AcctHeader.documentdate",
    disableSortBy: true,
    Cell: ({ cell }) =>
      cell?.value ? format(convertTimeZone(cell.value), "MM/dd/yyyy") : "--",
  },
  {
    Header: "Invoice Amount",
    id: "InvoiceAmount",
    accessor: "AcctHeader.documenttotal",
    disableSortBy: true,
    Cell: TableCell.Currency,
  },
  {
    Header: "Voucher Date",
    id: "VoucherDate",
    accessor: "VoucherDate",
    disableSortBy: true,
    Cell: ({ cell }) =>
      cell?.value ? format(convertTimeZone(cell.value), "MM/dd/yyyy") : "--",
  },
  {
    Header: "Voucher Amount",
    id: "VoucherAmount",
    accessor: "VoucherAmount",
    disableSortBy: true,
    Cell: TableCell.Currency,
  },
];

export const CASE_IN_QA_COLUMNS = [
  {
    Header: "Case",
    id: "casenbr",
    accessor: "casenbr",
    Cell: ({ cell, row }) => {
      const isNormalPriority = row?.original?.priority?.includes("Normal");
      return (
        <span style={{ color: isNormalPriority ? "#363636" : "red" }}>
          {cell.value}
        </span>
      );
    },
  },
  {
    Header: "Priority",
    id: "priority",
    accessor: "priority",
    Cell: ({ cell }) => {
      if (cell.value) {
        return (
          <span
            style={{ color: cell.value.includes("Normal") ? "#363636" : "red" }}
          >
            {cell.value}
          </span>
        );
      }
      return "";
    },
  },
  {
    Header: "IQ",
    id: "laststatuschg",
    accessor: "laststatuschg",
    Cell: ({ cell }) => (
      <TableCell.Number
        value={cell?.value ? getDaysInQueue(cell.value) : "--"}
      />
    ),
  },
  {
    Header: "Date",
    id: "ApptDate",
    accessor: "ApptDate",
    Cell: ({ cell }) =>
      cell?.value ? format(convertTimeZone(cell.value), "MM/dd/yyyy") : "--",
  },
  {
    Header: "Examinee",
    id: "examinee:lastname",
    accessor: "examinee",
    Cell: ({ cell }) =>
      cell.value ? `${cell.value.lastname}, ${cell.value.firstname}` : "--",
  },
  {
    Header: "Doctor",
    id: "DoctorName",
    accessor: "DoctorName",
  },
  {
    Header: "Location",
    id: "location:location",
    accessor: "location.location",
  },
  {
    Header: "Company",
    id: "client:company:intname",
    accessor: "client.company.intname",
    disableSortBy: true,
  },
  {
    Header: "Service",
    id: "service:description",
    accessor: "service.description",
  },
  {
    Header: "Report Status",
    id: "rptstatus",
    accessor: "rptstatus",
  },
];
