import React from "react";
import PropTypes from "prop-types";

// components
import { Switch, Route } from "react-router-dom";
import { Tab } from "rbx";

// internal components
import MainInfo from "../MainInfo";
import Offices from "../Offices";
import Statistics from "../Statistics";

const OtherPartyTabs = ({
  doctorcode,
  tab,
  history,
  onChange,
  inputs,
  disabled,
}) => (
  <React.Fragment key="other-party-tabs">
    <Tab.Group kind="boxed">
      <Tab
        active={tab === "profile"}
        onClick={() => history.push(`/setup/other-party/${doctorcode}/profile`)}
      >
        Profile
      </Tab>
      {doctorcode !== "add" && (
        <React.Fragment>
          <Tab
            active={tab === "offices"}
            onClick={() =>
              history.push(`/setup/other-party/${doctorcode}/offices`)
            }
          >
            Locations
          </Tab>
          <Tab
            active={tab === "statistics"}
            onClick={() =>
              history.push(`/setup/other-party/${doctorcode}/statistics`)
            }
          >
            Statistics
          </Tab>
        </React.Fragment>
      )}
    </Tab.Group>
    <Switch>
      <Route path="/setup/other-party/:doctorcode/profile">
        <MainInfo disabled={disabled} inputs={inputs} onChange={onChange} />
      </Route>
      <Route path="/setup/other-party/:doctorcode/offices">
        <Offices disabled={disabled} inputs={inputs} onChange={onChange} />
      </Route>
      <Route path="/setup/other-party/:doctorcode/statistics">
        <Statistics />
      </Route>
    </Switch>
  </React.Fragment>
);

OtherPartyTabs.propTypes = {
  doctorcode: PropTypes.string.isRequired,
  tab: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  inputs: PropTypes.object.isRequired,
};

OtherPartyTabs.defaultProps = {};

export default OtherPartyTabs;
