import React, { useState, useEffect, useRef } from "react";
import { Title, Field, Control, Button, Icon, Input } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useParams } from "react-router-dom";
import { useModal } from "../../../../context";
import DataTable from "../../../../components/DataTable";
import { LIST_PRODUCTS_QUERY } from "../../../../graphql";
import { ProductModal } from "../../components";
import { debounce } from "../../../../utils";
import COLUMNS from "./columns";

const DEFAULT_FILTER_STATE = {
  OR: [
    {
      description: { contains: "" },
    },
    {
      longdesc: { contains: "" },
    },
    {
      cptcode: { contains: "" },
    },
    {
      status: { contains: "" },
    },
  ],
};

const ProductsListPage = () => {
  const { prodcode } = useParams();
  const [where, setWhere] = useState(DEFAULT_FILTER_STATE);
  const { setModalOpen } = useModal();
  const isModalOpen = useRef(false);

  useEffect(() => {
    if (prodcode && !isModalOpen.current) {
      isModalOpen.current = true;
      setModalOpen(
        true,
        <ProductModal
          prodcode={parseInt(prodcode, 10)}
          onComplete={(adding, inputs, previousCode, updated) =>
            setModalOpen(false)
          }
        />
      );
    }
  }, [prodcode, setModalOpen, where]);

  const handleRowClick = (row) => {
    setModalOpen(
      true,
      <ProductModal
        prodcode={row.prodcode}
        onComplete={(adding, inputs, previousCode, updated) =>
          setModalOpen(false)
        }
      />
    );
  };

  return (
    <div>
      <div className="section-header">
        <Title style={{ marginRight: 15 }}>Products</Title>
        <Field kind="group">
          <Control>
            <Input
              autoComplete="off"
              id="search-input"
              placeholder="Search..."
              size="small"
              onChange={debounce(({ target: { value } }) => {
                if (value) {
                  setWhere({
                    OR: [
                      {
                        description: { contains: value },
                      },
                      {
                        longdesc: { contains: value },
                      },
                      {
                        cptcode: { contains: value },
                      },
                      {
                        status: { contains: value },
                      },
                    ],
                  });
                } else {
                  setWhere({
                    OR: [
                      {
                        description: { contains: "" },
                      },
                      {
                        longdesc: { contains: "" },
                      },
                      {
                        cptcode: { contains: "" },
                      },
                      {
                        status: { contains: "" },
                      },
                    ],
                  });
                }
              }, 500)}
            />
          </Control>

          <Control>
            <Button
              color="primary"
              size="small"
              onClick={() => {
                setModalOpen(
                  true,
                  <ProductModal
                    where={where}
                    onComplete={() => setModalOpen(false)}
                  />
                );
              }}
            >
              <Icon>
                <FontAwesomeIcon icon="plus" />
              </Icon>
              <span>Add</span>
            </Button>
          </Control>
        </Field>
      </div>
      <DataTable
        aggregateKey="prodcode"
        aggregateName="aggregateProduct"
        columns={COLUMNS}
        name="products"
        orderBy={[{ id: "prodcode", desc: false }]}
        query={LIST_PRODUCTS_QUERY}
        where={where}
        onRowClick={handleRowClick}
      />
    </div>
  );
};

ProductsListPage.propTypes = {};

export default ProductsListPage;
