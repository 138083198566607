import { format } from "date-fns";
import { convertTimeZone } from "../../../../utils";

const COLUMNS = [
  {
    Header: "Name",
    id: "ShortDesc",
    accessor: "ShortDesc",
  },
  {
    Header: "Description",
    id: "description",
    accessor: "description",
    maxWidth: "50%",
  },
  {
    Header: "Office",
    id: "officecode",
    accessor: "Office.shortdesc",
    Cell: ({ cell }) => cell?.value || "All",
  },
  {
    Header: "Status",
    id: "status",
    accessor: "status",
  },
  {
    Header: "Added",
    id: "dateadded",
    accessor: "dateadded",
    Cell: ({ cell }) =>
      cell?.value
        ? format(convertTimeZone(cell.value), "MM/dd/yyyy hh:mm a")
        : "",
  },
  {
    Header: "By",
    id: "useridadded",
    accessor: "useridadded",
  },
  {
    Header: "Edited",
    id: "dateedited",
    accessor: "dateedited",
    Cell: ({ cell }) =>
      cell?.value
        ? format(convertTimeZone(cell.value), "MM/dd/yyyy hh:mm a")
        : "",
  },
  {
    Header: "By",
    id: "useridedited",
    accessor: "useridedited",
  },
];

export default COLUMNS;
