import React, { useState, useRef, useEffect } from "react";
import { Title, Field, Control, Button, Icon, Input } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useParams } from "react-router-dom";
import { useModal } from "../../../../context";
import DataTable from "../../../../components/DataTable";
import { ALL_DEGREES_QUERY } from "../../../../graphql";
import { DoctorDegreeModal } from "../../components";
import { debounce } from "../../../../utils";
import COLUMNS from "./columns";

const DEFAULT_FILTER_STATE = {
  OR: [
    {
      degreecode: { contains: "" },
    },
    {
      description: { contains: "" },
    },
  ],
};

const DoctorDegreesListPage = () => {
  const [where, setWhere] = useState(DEFAULT_FILTER_STATE);
  const [searchValue, setSearchValue] = useState("");
  const { degreecode } = useParams();
  const isModalOpen = useRef(false);

  const { setModalOpen } = useModal();

  useEffect(() => {
    if (degreecode && !isModalOpen.current) {
      isModalOpen.current = true;
      setModalOpen(
        true,
        <DoctorDegreeModal
          degreecode={degreecode}
          where={where}
          onComplete={() => setModalOpen(false)}
        />
      );
    }
  }, [degreecode, isModalOpen, setModalOpen, where]);

  const handleRowClick = (row) => {
    setModalOpen(
      true,
      <DoctorDegreeModal
        degreecode={row.degreecode}
        where={where}
        onComplete={() => setModalOpen(false)}
      />
    );
  };

  const handleSearchChange = debounce(({ target: { value } }) => {
    if (value) {
      setWhere({
        OR: [
          {
            degreecode: { contains: value },
          },
          {
            description: { contains: value },
          },
        ],
      });
    } else {
      setWhere({
        OR: [
          {
            degreecode: { contains: "" },
          },
          {
            description: { contains: "" },
          },
        ],
      });
    }
  }, 500);

  return (
    <div>
      <div className="section-header">
        <Title style={{ marginRight: 15 }}>Doctor Degrees</Title>
        <Field kind="group">
          <Control>
            <Input
              autoComplete="off"
              id="search-input"
              placeholder="Search..."
              size="small"
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value);
                handleSearchChange(e);
              }}
            />
          </Control>
          <Control>
            <Button
              color="primary"
              size="small"
              onClick={() => {
                setModalOpen(
                  true,
                  <DoctorDegreeModal
                    where={where}
                    onComplete={() => setModalOpen(false)}
                  />
                );
              }}
            >
              <Icon>
                <FontAwesomeIcon icon="plus" />
              </Icon>
              <span>Add</span>
            </Button>
          </Control>
        </Field>
      </div>
      <DataTable
        aggregateKey="_all"
        aggregateName="aggregateDegree"
        columns={COLUMNS}
        name="degrees"
        orderBy={[{ id: "degreecode", desc: false }]}
        query={ALL_DEGREES_QUERY}
        where={where}
        onRowClick={handleRowClick}
      />
    </div>
  );
};

DoctorDegreesListPage.propTypes = {};

export default DoctorDegreesListPage;
