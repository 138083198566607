/* eslint-disable import/prefer-default-export */
import { gql } from "@apollo/client";

export const RATS_UPDATE_TABLE_KEYS = gql`
  mutation UpdateTablekeys(
    $data: TablekeysUpdateInput!
    $where: TablekeysWhereUniqueInput!
  ) {
    updateTablekeys(data: $data, where: $where) {
      RG
    }
  }
`;
