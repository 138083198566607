import React from "react";
import { toast } from "react-toastify";
import { Generic, Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { format } from "date-fns";
import { convertTimeZone } from "../../../../utils";
import { TableCell } from "../../../../components/DataTable";

const { REACT_APP_DOCUMENT_HOST } = process.env;

const DEFAULT_COLUMNS = [
  {
    Header: "description",
    id: "description",
    accessor: "description",
  },
  {
    Header: "type",
    id: "type",
    accessor: "type",
  },
  {
    Header: "Expiration",
    id: "expiredate",
    accessor: "expiredate",
    Cell: ({ cell }) =>
      cell?.value ? format(convertTimeZone(cell.value), "MM/dd/yyyy") : "--",
  },
  {
    Header: "Filename",
    id: "pathfilename",
    accessor: "pathfilename",
    Cell: ({ cell }) => {
      const val = cell?.value;
      if (val) {
        if (/\\/.test(val)) {
          return cell.value.slice(cell.value.lastIndexOf("\\") + 1);
        }
        return cell.value.slice(cell.value.lastIndexOf("/") + 1);
      }
      return "--";
    },
  },
  {
    Header: "Edited",
    id: "DateEdited",
    accessor: "DateEdited",
    Cell: ({ cell }) =>
      cell?.value
        ? format(convertTimeZone(cell.value), "MM/dd/yyyy hh:mm a")
        : "--",
  },
  {
    Header: "By",
    id: "UserIDEdited",
    accessor: "UserIDEdited",
  },
  {
    Header: "Order",
    id: "order",
    accessor: "order",
    Cell: TableCell.Number,
  },
];

export const generateColumns = (handleDelete) => [
  {
    Header: "",
    id: "delete",
    Cell: (cell) => (
      <Generic
        style={{ textAlign: "center" }}
        type="button"
        onClick={(e) => {
          e.stopPropagation();
          handleDelete(cell.row.original);
        }}
      >
        <Icon color="danger">
          <FontAwesomeIcon icon="times" />
        </Icon>
      </Generic>
    ),
  },
  ...DEFAULT_COLUMNS,
  {
    Header: "",
    id: "download",
    Cell: (cell) => (
      <Generic
        style={{ textAlign: "center" }}
        type="button"
        onClick={(e) => {
          e.stopPropagation();
          try {
            window.open(
              `${REACT_APP_DOCUMENT_HOST}/${cell.row.original.pathfilename.replace(
                "T:\\",
                ""
              )}`
            );
          } catch (err) {
            toast.error("Error downloading document.");
          }
        }}
      >
        <Icon color="link">
          <FontAwesomeIcon icon="download" />
        </Icon>
      </Generic>
    ),
  },
];

export default DEFAULT_COLUMNS;
