import React from "react";
import { format } from "date-fns";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { Column, Control, Input, Label } from "rbx";

import { convertTimeZone } from "../../../utils";

import CompanyDeleteButton from "../components/CompanyDeleteButton";
import { usePermissions } from "../../../hooks";

const Statistics = ({ inputs }) => {
  const { companycode } = useParams();
  const isAdmin = usePermissions(["AllAdminFunc"]);
  return (
    <Column.Group>
      <Column size={6}>
        <div className="grid-2">
          <Control style={{ marginBottom: "1rem" }}>
            <Label>System Company Code</Label>
            <Input
              readOnly
              name="companycode"
              size="small"
              value={inputs.companycode || "--"}
            />
          </Control>
        </div>
        <div className="grid-2">
          <Control style={{ marginBottom: "1rem" }}>
            <Label>Date Added</Label>
            <Input
              readOnly
              name="dateadded"
              size="small"
              value={
                inputs.dateadded
                  ? format(
                      convertTimeZone(inputs.dateadded),
                      "MM/dd/yyyy hh:mm a"
                    )
                  : "--"
              }
            />
          </Control>
          <Control style={{ marginBottom: "1rem" }}>
            <Label>By</Label>
            <Input
              readOnly
              name="useridadded"
              size="small"
              value={inputs.useridadded || "--"}
            />
          </Control>
        </div>
        <div className="grid-2">
          <Control style={{ marginBottom: "1rem" }}>
            <Label>Last Edited</Label>
            <Input
              readOnly
              name="dateedited"
              size="small"
              value={
                inputs.dateedited
                  ? format(
                      convertTimeZone(inputs.dateedited),
                      "MM/dd/yyyy hh:mm a"
                    )
                  : "--"
              }
            />
          </Control>
          <Control style={{ marginBottom: "1rem" }}>
            <Label>By</Label>
            <Input
              readOnly
              name="useridedited"
              size="small"
              value={inputs.useridedited || "--"}
            />
          </Control>
        </div>
      </Column>
      <Column size={6}>
        {isAdmin && <CompanyDeleteButton companycode={companycode} />}
      </Column>
    </Column.Group>
  );
};

Statistics.propTypes = {
  inputs: PropTypes.object,
};

Statistics.defaultProps = {
  inputs: {},
};

export default Statistics;
