import React from "react";
import PropTypes from "prop-types";
import { Field, Control, Label, Box, Title } from "rbx";
import CaseTypeSelect from "../../../../../components/CaseTypeSelect";
import OfficeSelect from "../../../../../components/OfficeSelect";
import ServiceSelect from "../../../../../components/ServiceSelect";
import BooleanInput from "../../../../../components/BooleanInput";

const CaseTypeForm = ({ inputs, onChange, editing }) => (
  <div className="case-type-documents-form">
    <Field className="grid-override is-three" kind="group">
      <Control expanded>
        <OfficeSelect
          disabled={editing}
          label="Office"
          name="officecode"
          size="small"
          value={inputs.officecode}
          where={{ status: { equals: "Active" } }}
          onChange={onChange}
        />
      </Control>
      <Control expanded>
        <CaseTypeSelect
          disabled={!inputs.officecode || editing}
          name="casetype"
          value={inputs.casetype}
          onChange={onChange}
        />
      </Control>
      <Control expanded>
        <ServiceSelect
          disabled={!inputs.officecode || editing}
          label="Service"
          name="servicecode"
          value={inputs.servicecode}
          onChange={onChange}
        />
      </Control>
    </Field>
    <hr />
    <Title subtitle size={6}>
      Require These Fields When Adding Cases
    </Title>
    <Field className="inputs-wrapper" kind="group">
      <Box>
        <Title subtitle size={6} textAlign="centered">
          Examinee
        </Title>
        <Field kind="group">
          <Control>
            <Label>Addr</Label>
            <BooleanInput
              name="examineeaddrreqd"
              value={inputs.examineeaddrreqd}
              onChange={onChange}
            />
          </Control>
          <Control>
            <Label>SSN</Label>
            <BooleanInput
              name="examineeSSNreqd"
              value={inputs.examineeSSNreqd}
              onChange={onChange}
            />
          </Control>
        </Field>
      </Box>
      <Box>
        <Title subtitle size={6} textAlign="centered">
          Cases
        </Title>
        <Field kind="group">
          <Control>
            <Label>Attorney</Label>
            <BooleanInput
              name="attorneyreqd"
              value={inputs.attorneyreqd}
              onChange={onChange}
            />
          </Control>
          <Control>
            <Label>DOI</Label>
            <BooleanInput
              name="DOIRqd"
              value={inputs.DOIRqd}
              onChange={onChange}
            />
          </Control>
          <Control>
            <Label>Claim Nbr</Label>
            <BooleanInput
              name="ClaimNbrRqd"
              value={inputs.ClaimNbrRqd}
              onChange={onChange}
            />
          </Control>
          <Control>
            <Label>Juris</Label>
            <BooleanInput
              name="JurisdictionRqd"
              value={inputs.JurisdictionRqd}
              onChange={onChange}
            />
          </Control>
          <Control>
            <Label>Empl</Label>
            <BooleanInput
              name="EmployerRqd"
              value={inputs.EmployerRqd}
              onChange={onChange}
            />
          </Control>
          <Control>
            <Label>Treating Phy</Label>
            <BooleanInput
              name="TreatingPhysicianRqd"
              value={inputs.TreatingPhysicianRqd}
              onChange={onChange}
            />
          </Control>
        </Field>
      </Box>
    </Field>
  </div>
);

CaseTypeForm.propTypes = {
  inputs: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  editing: PropTypes.bool.isRequired,
};

export default CaseTypeForm;
