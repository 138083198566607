import React from "react";
import PropTypes from "prop-types";
import { Field } from "rbx";

import ClientSelect from "../../../../components/ClientSelect";
import PrioritySelect from "../../../../components/PrioritySelect";
import OfficeSelect from "../../../../components/OfficeSelect";
import CaseTypeSelect from "../../../../components/CaseTypeSelect";
import ServiceSelect from "../../../../components/ServiceSelect";
import CompanySelect from "../../../../components/CompanySelect";
import ServiceQueuesSelect from "../../../../components/ServiceQueuesSelect";
import DateInput from "../../../../components/DateInput";
import JurisdictionSelect from "../../../../components/JurisdictionSelect";
import UserSelectAsync from "../../../../components/UserSelectAsync";
// import StatusSelect from "../../../../components/StatusSelect";
import ApolloServiceTypeSelect from "../../../../components/ApolloServiceTypeSelect";
import "./ClaimServiceForm.scss";

const ClaimServiceForm = ({ inputs, onChange, disabled, onSubmit }) => (
  <form id="claim-service-form" onSubmit={onSubmit}>
    <Field style={{ gridColumn: "1 / 4" }}>
      <ClientSelect
        required
        showLabel
        disabled={disabled}
        label="Client"
        name="clientcode"
        value={inputs.clientcode}
        where={{ status: { equals: "Active" } }}
        onChange={onChange}
      />
    </Field>
    <Field style={{ gridColumn: "4 / 7" }}>
      <CompanySelect
        disabled
        fullWidth
        required
        showLabel
        label="Company"
        name="IMECompany"
        value={inputs.IMECompany}
        onChange={onChange}
      />
    </Field>
    <Field style={{ gridColumn: "1 / 3" }}>
      <OfficeSelect
        required
        disabled={disabled}
        label="Office"
        name="officecode"
        noValueLabel=""
        size="small"
        value={inputs.officecode}
        onChange={onChange}
      />
    </Field>
    <Field style={{ gridColumn: "3 / 5" }}>
      <PrioritySelect
        disabled={disabled}
        label="Priority"
        name="priority"
        noValueLabel=""
        value={inputs.priority}
        onChange={onChange}
      />
    </Field>
    <Field style={{ gridColumn: "5 / 7" }}>
      <DateInput
        disabled={disabled}
        label="Commit Date"
        name="commitdate"
        value={inputs.commitdate}
        onChange={onChange}
      />
    </Field>
    <Field style={{ gridColumn: "1 / 3" }}>
      <CaseTypeSelect
        required
        disabled={disabled}
        label="Case Type"
        name="caseType"
        value={inputs.caseType}
        onChange={onChange}
      />
    </Field>
    <Field style={{ gridColumn: "3 / 5" }}>
      <ServiceSelect
        disabled={disabled}
        label="Service"
        name="servicecode"
        size="small"
        value={inputs.servicecode}
        onChange={onChange}
      />
    </Field>
    <Field style={{ gridColumn: "5 / 7" }}>
      <ServiceQueuesSelect
        fullWidth
        disabled={disabled}
        label="Status"
        name="Status"
        serviceCode={inputs.servicecode}
        value={inputs.Status}
        onChange={onChange}
      />
    </Field>
    <Field style={{ gridColumn: "1 / 3" }}>
      <UserSelectAsync
        disabled={disabled}
        label="Marketer"
        name="marketercode"
        value={inputs.marketercode}
        where={{
          OR: [{ usertype: { equals: "MK" } }, { usertype: { equals: "*" } }],
          fldActive: { equals: 1 },
        }}
        onChange={onChange}
      />
    </Field>
    <Field style={{ gridColumn: "3 / 5" }}>
      <UserSelectAsync
        disabled={disabled}
        label="QA Rep"
        name="QARep"
        value={inputs.QARep}
        where={{
          OR: [{ usertype: { equals: "QA" } }, { usertype: { equals: "*" } }],
          fldActive: { equals: 1 },
        }}
        onChange={onChange}
      />
    </Field>
    <Field style={{ gridColumn: "5 / 7" }}>
      <UserSelectAsync
        disabled={disabled}
        label="Scheduler"
        name="schedulercode"
        value={inputs.schedulercode}
        where={{
          OR: [{ usertype: { equals: "SC" } }, { usertype: { equals: "*" } }],
          fldActive: { equals: 1 },
        }}
        onChange={onChange}
      />
    </Field>

    <Field style={{ gridColumn: "1 / 3" }}>
      <JurisdictionSelect
        required
        disabled={disabled}
        label="Jurisdiction"
        name="Jurisdiction"
        size="small"
        value={inputs.Jurisdiction}
        onChange={onChange}
      />
    </Field>

    <Field style={{ gridColumn: "3 / 7" }}>
      <ApolloServiceTypeSelect
        required
        disabled={disabled}
        label="Service Type"
        name="ServiceTypeID"
        size="small"
        value={inputs.ServiceTypeID}
        onChange={onChange}
      />
    </Field>
  </form>
);

ClaimServiceForm.propTypes = {
  inputs: PropTypes.object,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  disabled: PropTypes.bool,
};

ClaimServiceForm.defaultProps = {
  inputs: {},
  onChange: () => null,
  onSubmit: () => null,
  disabled: false,
};

export default ClaimServiceForm;
