import React, { useState } from "react";
import PropTypes from "prop-types";

import { useMutation, useApolloClient } from "@apollo/client";
import { Title, Button } from "rbx";
import { toast } from "react-toastify";
import {
  CREATE_COMPANY_DEF_DOCUMENT_MUTATION,
  LIST_COMPANY_DEF_DOCUMENT_QUERY,
  SINGLE_COMPANY_DEFAULT_DOCUMENT_QUERY,
} from "../../../../graphql";

import CompanyDefaultDocumentForm from "../CompanyDefaultDocumentForm";
import { useAuth } from "../../../../context";
import { getDateEST } from "../../../../utils";

const initialState = {
  documentcode: "",
  documentqueue: "",
  type: "",
  order: "",
};

const CompanyDefaultDocumentModal = ({ companycode, onComplete }) => {
  const client = useApolloClient();
  const { state: authState } = useAuth();
  const [inputs, setInputs] = useState({ ...initialState });

  const [createCompanyDefDocument] = useMutation(
    CREATE_COMPANY_DEF_DOCUMENT_MUTATION
  );

  const handleSave = async (e) => {
    try {
      e.preventDefault();

      const {
        data: { companyDefDocument },
      } = await client.query({
        query: SINGLE_COMPANY_DEFAULT_DOCUMENT_QUERY,
        variables: {
          where: {
            companycode_documentcode_documentqueue: {
              companycode: parseInt(companycode, 10),
              documentcode: inputs.documentcode,
              documentqueue: inputs.documentqueue,
            },
          },
        },
        fetchPolicy: "network-only",
      });
      if (!companyDefDocument) {
        const date = getDateEST();
        await createCompanyDefDocument({
          variables: {
            data: {
              companycode: parseInt(companycode, 10),
              dateadded: date,
              dateedited: date,
              documentcode: inputs.documentcode,
              documentqueue: parseInt(inputs.documentqueue, 10),
              order: inputs.order ? parseInt(inputs.order, 10) : null,
              useridadded: authState?.user?.userid,
              useridedited: authState.user.userid,
            },
          },
          refetchQueries: [
            {
              query: LIST_COMPANY_DEF_DOCUMENT_QUERY,
              variables: {
                where: { companycode: { equals: parseInt(companycode, 10) } },
              },
            },
          ],
        });
        toast.success(`Document created successfully.`);
        onComplete(true);
      } else {
        toast.warning(`This default document already exists.`);
      }
    } catch (err) {
      toast.error(`Error creating document.`);
    }
  };
  const handleChange = (name, value) => {
    setInputs((prev) => ({ ...prev, [name]: value }));
  };

  const isDisabled = !inputs.documentcode || !inputs.documentqueue;
  return (
    <form id="company-default-document-form" onSubmit={handleSave}>
      <header className="page-head">
        <div className="page-head-start">
          <Title size={5}>Create Company Default Document</Title>
        </div>
        <div className="page-head-end">
          <Button.Group hasAddons>
            <Button
              size="small"
              type="button"
              onClick={() => onComplete(false)}
            >
              <span>Cancel</span>
            </Button>
            <Button
              color="primary"
              disabled={isDisabled}
              form="company-default-document-form"
              size="small"
              type="submit"
            >
              <span>Submit</span>
            </Button>
          </Button.Group>
        </div>
      </header>
      <hr />
      <CompanyDefaultDocumentForm inputs={inputs} onChange={handleChange} />
      <hr />
    </form>
  );
};

CompanyDefaultDocumentModal.propTypes = {
  companycode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onComplete: PropTypes.func,
};

CompanyDefaultDocumentModal.defaultProps = {
  companycode: "",
  onComplete: () => null,
};
export default CompanyDefaultDocumentModal;
