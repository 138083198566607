import React from "react";
import PropTypes from "prop-types";
import { Control, Field, Input, Label } from "rbx";

import RequestGenerator from "../RequestGenerator";
import InsuranceCarrier from "../InsuranceCarrier";
import RatsEmployer from "../RatsEmployer";

const WorkersCompensationForm = ({
  required,
  handleChange,
  inputs,
  disabled,
}) => (
  <div className="col-span">
    <div>
      <RequestGenerator
        disabled={disabled}
        handleChange={handleChange}
        inputs={inputs}
        label="Judge"
        required={required}
        shouldFilter={false}
        type="WCJudge"
      />
      <InsuranceCarrier
        disabled={disabled}
        handleChange={handleChange}
        inputs={inputs}
        label="Insurance Carrier"
        required={required}
        type="WCInsuranceCarrier"
      />
      <RatsEmployer
        disabled={disabled}
        handleChange={handleChange}
        inputs={inputs}
        label="Employer"
        required={required}
        type="WCEmployer"
      />
      <Field>
        <Control>
          <Label>State File # (TN Workers Comp)</Label>
          <Input
            disabled={disabled}
            name="WCStateFileNumber"
            required={required}
            size="small"
            value={inputs.WCStateFileNumber}
            onChange={(e) => handleChange(e.target.name, e.target.value)}
          />
        </Control>
      </Field>
      <Field>
        <Control>
          <Label>State File Year (TN Workers Comp)</Label>
          <Input
            disabled={disabled}
            maxLength={4}
            name="WCStateFileYear"
            required={required}
            size="small"
            value={inputs.WCStateFileYear}
            onChange={(e) => handleChange(e.target.name, e.target.value)}
          />
        </Control>
      </Field>
      <Field>
        <Control>
          <Label>RFA # (TN Workers Comp)</Label>
          <Input
            disabled={disabled}
            name="WCRFANumber"
            required={required}
            size="small"
            value={inputs.WCRFANumber}
            onChange={(e) => handleChange(e.target.name, e.target.value)}
          />
        </Control>
      </Field>
      <Field>
        <Control>
          <Label>Carrier Code # (SC Workers Comp)</Label>
          <Input
            disabled={disabled}
            name="WCCarrierCode"
            required={required}
            size="small"
            value={inputs.WCCarrierCode}
            onChange={(e) => handleChange(e.target.name, e.target.value)}
          />
        </Control>
      </Field>
    </div>
  </div>
);

WorkersCompensationForm.propTypes = {
  handleChange: PropTypes.func.isRequired,
  inputs: PropTypes.object.isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
};

WorkersCompensationForm.defaultProps = {
  required: false,
  disabled: false,
};

export default WorkersCompensationForm;
