import React from "react";
import PropTypes from "prop-types";
import { format } from "date-fns";
import {
  Column,
  Input,
  Label,
  Field,
  Control,
  Select as RbxSelect,
  Textarea,
  Box,
  Title,
  Tag,
} from "rbx";
import { DateTime } from "luxon";
import StateSelect from "../../../components/StateSelect";
import PhoneNumberInput from "../../../components/PhoneNumberInput";
import ZipInput from "../../../components/ZipInput";
import PrefixSelect from "../../../components/PrefixSelect";
import SSNumberInput from "../../../components/SSNumberInput";
import CCAddressSelect from "../../../components/CCAddressSelect";
import CCAddressActionButtons from "./CCAddressActionButtons";
import CaseExamineeNoShow from "./CaseExamineeNoShow";
import { calculateAge } from "../../../utils";

const ExamineeForm = ({
  inputs,
  onChange,
  disabled,
  onSubmit,
  adding,
  initialExamineeState,
  handleTreatingChanged,
  treating,
  titleTag,
  casenbr,
  claimnbr,
  chartnbr,
}) => (
  <form id="edit-case-form" onSubmit={onSubmit}>
    <Column.Group>
      <Column mobile={{ size: 12 }} tablet={{ size: 6 }}>
        <Box>
          {titleTag === "" ? (
            <Title subtitle>Examinee</Title>
          ) : (
            <div className="case-examinee-header">
              <Title subtitle>Examinee</Title>
              <Tag rounded color="success">
                {titleTag}
              </Tag>
            </div>
          )}
          <div className="case-examinee-form-section-personal-info">
            <Field className="span-2">
              <PrefixSelect
                disabled={disabled}
                id="prefix"
                label="Prefix"
                name="prefix"
                size="small"
                value={inputs.prefix}
                onChange={onChange}
              />
            </Field>
            <Field className="span-4">
              <Control expanded>
                <Label>First Name</Label>
                <Input
                  required
                  autoComplete="new"
                  disabled={disabled}
                  maxLength={50}
                  name="firstname"
                  size="small"
                  value={inputs.firstname}
                  onChange={onChange}
                />
              </Control>
            </Field>
            <Field className="span-2">
              <Control expanded>
                <Label>MI</Label>
                <Input
                  autoComplete="new"
                  disabled={disabled}
                  maxLength={1}
                  name="middleinitial"
                  size="small"
                  value={inputs.middleinitial}
                  onChange={onChange}
                />
              </Control>
            </Field>
            <Field className="span-4">
              <Control expanded>
                <Label>Last Name</Label>
                <Input
                  required
                  autoComplete="new"
                  disabled={disabled}
                  maxLength={50}
                  name="lastname"
                  size="small"
                  value={inputs.lastname}
                  onChange={onChange}
                />
              </Control>
            </Field>

            <Field className="span-4">
              <Control expanded>
                <Label>
                  {`Birth Date (Age ${calculateAge(new Date(inputs.DOB))})`}
                </Label>
                <Input
                  defaultValue={
                    inputs.DOB
                      ? DateTime.fromISO(inputs.DOB)
                          .setZone("utc")
                          .toFormat("yyyy-MM-dd")
                      : ""
                  }
                  disabled={disabled}
                  key={inputs.DOB}
                  max={format(new Date(), "yyyy-MM-dd")}
                  name="DOB"
                  size="small"
                  type="date"
                  onBlur={onChange}
                />
              </Control>
            </Field>

            <Field className="span-2">
              <Control expanded>
                <Label>Sex</Label>
                <RbxSelect.Container fullwidth size="small">
                  <RbxSelect
                    disabled={disabled}
                    name="sex"
                    value={inputs.sex}
                    onChange={onChange}
                  >
                    <RbxSelect.Option value="" />
                    <RbxSelect.Option value="Female">Female</RbxSelect.Option>
                    <RbxSelect.Option value="Male">Male</RbxSelect.Option>
                  </RbxSelect>
                </RbxSelect.Container>
              </Control>
            </Field>
            <Field className="span-3">
              <Control expanded>
                <Label>Phone</Label>
                <PhoneNumberInput
                  disabled={disabled}
                  name="phone1"
                  value={inputs.phone1}
                  onChange={onChange}
                />
              </Control>
            </Field>
            <Field className="span-3">
              <Control expanded>
                <Label>Cell Phone</Label>
                <PhoneNumberInput
                  disabled={disabled}
                  name="phone2"
                  value={inputs.phone2}
                  onChange={onChange}
                />
              </Control>
            </Field>
            <Field className="span-4">
              <Control expanded>
                <Label>Email</Label>
                <Input
                  autoComplete="new"
                  disabled={disabled}
                  maxLength={50}
                  name="email"
                  size="small"
                  value={inputs.email}
                  onChange={onChange}
                />
              </Control>
            </Field>
            <Field className="span-4">
              <Control expanded>
                <Label>Social Security</Label>
                <SSNumberInput
                  disabled={disabled}
                  maxLength={11}
                  name="SSN"
                  size="small"
                  value={inputs.SSN}
                  onChange={onChange}
                />
              </Control>
            </Field>
            <Field className="span-8">
              <Control expanded>
                <Label>Address 1</Label>
                <Input
                  required
                  autoComplete="new"
                  disabled={disabled}
                  maxLength={50}
                  name="addr1"
                  size="small"
                  value={inputs.addr1}
                  onChange={onChange}
                />
              </Control>
            </Field>
            <Field className="span-8">
              <Control expanded>
                <Label>Address 2</Label>
                <Input
                  autoComplete="new"
                  disabled={disabled}
                  maxLength={50}
                  name="addr2"
                  size="small"
                  value={inputs.addr2}
                  onChange={onChange}
                />
              </Control>
            </Field>
            <Field className="span-4">
              <Control expanded>
                <Label>City</Label>
                <Input
                  required
                  autoComplete="new"
                  disabled={disabled}
                  maxLength={50}
                  name="city"
                  size="small"
                  type="text"
                  value={inputs.city}
                  onChange={onChange}
                />
              </Control>
            </Field>
            <Field className="span-4">
              <StateSelect
                required
                disabled={disabled}
                label="State"
                name="state"
                size="small"
                value={inputs.state}
                onChange={onChange}
              />
            </Field>
            <Field className="span-4">
              <ZipInput
                disabled={disabled}
                state={inputs.state}
                value={inputs.zip || ""}
                onChange={onChange}
              />
            </Field>
            <Field className="span-4">
              <Control expanded>
                <Label>County</Label>
                <Input
                  autoComplete="new"
                  disabled={disabled}
                  maxLength={50}
                  name="county"
                  size="small"
                  value={inputs.county}
                  onChange={onChange}
                />
              </Control>
            </Field>
            <Field className="span-16">
              <Control>
                <Label>Chart Notes</Label>
                <Textarea
                  disabled={disabled}
                  name="note"
                  size="small"
                  value={inputs.note}
                  onChange={onChange}
                />
              </Control>
            </Field>
          </div>
        </Box>
      </Column>
      <Column style={{ display: "flex", flexDirection: "column" }}>
        <CaseExamineeNoShow
          casenbr={casenbr}
          chartnbr={chartnbr}
          claimnbr={claimnbr}
        />
        <Box style={{ height: "100%" }}>
          <Title subtitle>Insured</Title>
          <div className="case-examinee-form-section">
            <Field className="span-3">
              <Control>
                <Label>Name</Label>
                <Input
                  autoComplete="new"
                  disabled={disabled}
                  maxLength={50}
                  name="insured"
                  size="small"
                  type="text"
                  value={inputs.insured}
                  onChange={onChange}
                />
              </Control>
            </Field>
            <Field className="span-2">
              <Control expanded>
                <Label>Phone</Label>
                <PhoneNumberInput
                  disabled={disabled}
                  name="InsuredPhone"
                  value={inputs.InsuredPhone}
                  onChange={onChange}
                />
              </Control>
            </Field>
            <Field>
              <Control expanded>
                <Label>Ext</Label>
                <Input
                  autoComplete="new"
                  disabled={disabled}
                  maxLength={10}
                  name="InsuredPhoneExt"
                  size="small"
                  type="text"
                  value={inputs.InsuredPhoneExt}
                  onChange={onChange}
                />
              </Control>
            </Field>
            <Field className="span-2">
              <Control expanded>
                <Label>Fax</Label>
                <PhoneNumberInput
                  disabled={disabled}
                  name="InsuredFax"
                  value={inputs.InsuredFax}
                  onChange={onChange}
                />
              </Control>
            </Field>
            <Field className="span-3">
              <Control>
                <Label>Address</Label>
                <Input
                  autoComplete="new"
                  disabled={disabled}
                  maxLength={70}
                  name="InsuredAddr1"
                  size="small"
                  type="text"
                  value={inputs.InsuredAddr1}
                  onChange={onChange}
                />
              </Control>
            </Field>
            <Field className="span-2">
              <Control>
                <Label>City</Label>
                <Input
                  autoComplete="new"
                  disabled={disabled}
                  maxLength={70}
                  name="InsuredCity"
                  size="small"
                  type="text"
                  value={inputs.InsuredCity}
                  onChange={onChange}
                />
              </Control>
            </Field>
            <Field>
              <StateSelect
                disabled={disabled}
                label="State"
                name="InsuredState"
                size="small"
                value={inputs.InsuredState}
                onChange={onChange}
              />
            </Field>
            <Field className="span-2">
              <Control>
                <Label>PC/Zip</Label>
                <Input
                  autoComplete="new"
                  disabled={disabled}
                  maxLength={10}
                  name="InsuredZip"
                  size="small"
                  type="text"
                  value={inputs.InsuredZip}
                  onChange={onChange}
                />
              </Control>
            </Field>

            <hr style={{ gridColumn: "span 8" }} />

            <Field className="span-3">
              <Control expanded>
                <Label>Email</Label>
                <Input
                  autoComplete="new"
                  disabled={disabled}
                  maxLength={70}
                  name="InsuredEmail"
                  size="small"
                  type="email"
                  value={inputs.InsuredEmail}
                  onChange={onChange}
                />
              </Control>
            </Field>
            <Field className="span-3">
              <Control expanded>
                <Label>Policy Number</Label>
                <Input
                  autoComplete="new"
                  disabled={disabled}
                  maxLength={70}
                  name="policynumber"
                  size="small"
                  type="text"
                  value={inputs.policynumber}
                  onChange={onChange}
                />
              </Control>
            </Field>
          </div>
        </Box>
      </Column>
    </Column.Group>
    <Column.Group>
      <Column mobile={{ size: 12 }} tablet={{ size: 6 }}>
        <Box style={{ height: "100%" }}>
          <Title subtitle>Employer</Title>
          <div className="case-examinee-form-section">
            <Field className="span-3">
              <Control expanded>
                <Label>Name</Label>
                <Input
                  autoComplete="new"
                  disabled={disabled}
                  maxLength={70}
                  name="employer"
                  size="small"
                  type="text"
                  value={inputs.employer}
                  onChange={onChange}
                />
              </Control>
            </Field>
            <Field className="span-2">
              <Control expanded>
                <Label>Phone</Label>
                <PhoneNumberInput
                  disabled={disabled}
                  name="EmployerPhone"
                  value={inputs.EmployerPhone}
                  onChange={onChange}
                />
              </Control>
            </Field>
            <Field>
              <Control expanded>
                <Label>Ext</Label>
                <Input
                  autoComplete="new"
                  disabled={disabled}
                  maxLength={10}
                  name="EmployerPhoneExt"
                  size="small"
                  type="text"
                  value={inputs.EmployerPhoneExt}
                  onChange={onChange}
                />
              </Control>
            </Field>
            <Field className="span-2">
              <Control expanded>
                <Label>Fax</Label>
                <PhoneNumberInput
                  disabled={disabled}
                  name="EmployerFax"
                  value={inputs.EmployerFax}
                  onChange={onChange}
                />
              </Control>
            </Field>
            <Field className="span-3">
              <Control expanded>
                <Label>Address</Label>
                <Input
                  autoComplete="new"
                  disabled={disabled}
                  maxLength={70}
                  name="EmployerAddr1"
                  size="small"
                  type="text"
                  value={inputs.EmployerAddr1}
                  onChange={onChange}
                />
              </Control>
            </Field>
            <Field className="span-2">
              <Control expanded>
                <Label>City</Label>
                <Input
                  autoComplete="new"
                  disabled={disabled}
                  maxLength={70}
                  name="EmployerCity"
                  size="small"
                  type="text"
                  value={inputs.EmployerCity}
                  onChange={onChange}
                />
              </Control>
            </Field>
            <Field>
              <StateSelect
                disabled={disabled}
                label="State"
                name="EmployerState"
                size="small"
                value={inputs.EmployerState}
                onChange={onChange}
              />
            </Field>
            <Field className="span-2">
              <Control expanded>
                <Label>PC/Zip</Label>
                <Input
                  autoComplete="new"
                  disabled={disabled}
                  maxLength={10}
                  name="EmployerZip"
                  size="small"
                  type="text"
                  value={inputs.EmployerZip}
                  onChange={onChange}
                />
              </Control>
            </Field>

            <hr style={{ gridColumn: "span 8" }} />

            <Field className="span-2">
              <Control expanded>
                <Label>Contact First Name</Label>
                <Input
                  autoComplete="new"
                  disabled={disabled}
                  maxLength={50}
                  name="EmployerContactFirstName"
                  size="small"
                  type="text"
                  value={inputs.EmployerContactFirstName}
                  onChange={onChange}
                />
              </Control>
            </Field>
            <Field className="span-2">
              <Control expanded>
                <Label>Contact Last Name</Label>
                <Input
                  autoComplete="new"
                  disabled={disabled}
                  maxLength={50}
                  name="EmployerContactLastName"
                  size="small"
                  type="text"
                  value={inputs.EmployerContactLastName}
                  onChange={onChange}
                />
              </Control>
            </Field>
            <Field className="span-3">
              <Control expanded>
                <Label>Email</Label>
                <Input
                  autoComplete="new"
                  disabled={disabled}
                  maxLength={70}
                  name="EmployerEmail"
                  size="small"
                  type="email"
                  value={inputs.EmployerEmail}
                  onChange={onChange}
                />
              </Control>
            </Field>
          </div>
        </Box>
      </Column>
      <Column>
        <Box style={{ height: "100%" }}>
          <Title subtitle>Treating Physician</Title>
          <div className="case-examinee-form-section">
            <Field kind="group" style={{ gridColumn: "span 8" }}>
              <CCAddressSelect
                label="Treating Physician"
                name="TreatingPhysician"
                value={treating}
                onChange={handleTreatingChanged}
              />
              <Control>
                <Label>&nbsp;</Label>
                <CCAddressActionButtons
                  cccode={treating}
                  name="TreatingPhysician"
                  onChange={handleTreatingChanged}
                />
              </Control>
            </Field>
            <Field className="span-3">
              <Control expanded>
                <Label>Name</Label>
                <Input
                  autoComplete="new"
                  disabled={disabled}
                  maxLength={70}
                  name="treatingphysician"
                  size="small"
                  type="text"
                  value={inputs.treatingphysician}
                  onChange={onChange}
                />
              </Control>
            </Field>
            <Field className="span-2">
              <Control expanded>
                <Label>Phone</Label>
                <PhoneNumberInput
                  disabled={disabled}
                  name="TreatingPhysicianPhone"
                  value={inputs.TreatingPhysicianPhone}
                  onChange={onChange}
                />
              </Control>
            </Field>
            <Field>
              <Control expanded>
                <Label>Ext</Label>
                <Input
                  autoComplete="new"
                  disabled={disabled}
                  maxLength={10}
                  name="TreatingPhysicianPhoneExt"
                  size="small"
                  type="text"
                  value={inputs.TreatingPhysicianPhoneExt}
                  onChange={onChange}
                />
              </Control>
            </Field>
            <Field className="span-2">
              <Control expanded>
                <Label>Fax</Label>
                <PhoneNumberInput
                  disabled={disabled}
                  name="TreatingPhysicianFax"
                  value={inputs.TreatingPhysicianFax}
                  onChange={onChange}
                />
              </Control>
            </Field>
            <Field className="span-3">
              <Control expanded>
                <Label>Address</Label>
                <Input
                  autoComplete="new"
                  disabled={disabled}
                  maxLength={70}
                  name="TreatingPhysicianAddr1"
                  size="small"
                  type="text"
                  value={inputs.TreatingPhysicianAddr1}
                  onChange={onChange}
                />
              </Control>
            </Field>
            <Field className="span-2">
              <Control expanded>
                <Label>City</Label>
                <Input
                  autoComplete="new"
                  disabled={disabled}
                  maxLength={70}
                  name="TreatingPhysicianCity"
                  size="small"
                  type="text"
                  value={inputs.TreatingPhysicianCity}
                  onChange={onChange}
                />
              </Control>
            </Field>
            <Field>
              <StateSelect
                disabled={disabled}
                label="State"
                name="TreatingPhysicianState"
                size="small"
                value={inputs.TreatingPhysicianState}
                onChange={onChange}
              />
            </Field>
            <Field className="span-2">
              <Control expanded>
                <Label>PC/Zip</Label>
                <Input
                  autoComplete="new"
                  disabled={disabled}
                  maxLength={10}
                  name="TreatingPhysicianZip"
                  size="small"
                  type="text"
                  value={inputs.TreatingPhysicianZip}
                  onChange={onChange}
                />
              </Control>
            </Field>
            <hr style={{ gridColumn: "span 8" }} />
            <Field className="span-3">
              <Control expanded>
                <Label>Email</Label>
                <Input
                  autoComplete="new"
                  disabled={disabled}
                  maxLength={70}
                  name="TreatingPhysicianEmail"
                  size="small"
                  type="email"
                  value={inputs.TreatingPhysicianEmail}
                  onChange={onChange}
                />
              </Control>
            </Field>
            <Field className="span-2">
              <Control expanded>
                <Label>License Number</Label>
                <Input
                  autoComplete="new"
                  disabled={disabled}
                  maxLength={50}
                  name="TreatingPhysicianLicenseNbr"
                  size="small"
                  type="text"
                  value={inputs.TreatingPhysicianLicenseNbr}
                  onChange={onChange}
                />
              </Control>
            </Field>
            <Field className="span-2">
              <Control expanded>
                <Label>Tax ID</Label>
                <Input
                  autoComplete="new"
                  disabled={disabled}
                  maxLength={50}
                  name="TreatingPhysicianTaxID"
                  size="small"
                  type="text"
                  value={inputs.TreatingPhysicianTaxID}
                  onChange={onChange}
                />
              </Control>
            </Field>
          </div>
        </Box>
      </Column>
    </Column.Group>
  </form>
);

ExamineeForm.propTypes = {
  inputs: PropTypes.object,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  adding: PropTypes.bool,
  initialExamineeState: PropTypes.object.isRequired,
  handleTreatingChanged: PropTypes.func.isRequired,
  treating: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  titleTag: PropTypes.string,
  casenbr: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  claimnbr: PropTypes.string,
  chartnbr: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ExamineeForm.defaultProps = {
  inputs: {},
  onChange: (e) => e,
  disabled: false,
  adding: false,
  treating: "",
  titleTag: "",
  casenbr: "",
  claimnbr: "",
  chartnbr: "",
};

export default ExamineeForm;
