const SERVICE_FIELDS = [
  { name: "CalcFrom", type: "String", entity: "service" },
  { name: "DaysToCommitDate", type: "Int", entity: "service" },
  { name: "ESendInsured", type: "Boolean", entity: "service" },
  { name: "ExcludeOnDash", type: "Boolean", entity: "service" },
  { name: "FinanceDescription", type: "String", entity: "service" },
  { name: "HideExamDateInfo", type: "Boolean", entity: "service" },
  { name: "IsReview", type: "Boolean", entity: "service" },
  { name: "IsSIU", type: "Int", entity: "service" },
  { name: "IsSurv", type: "Int", entity: "service" },
  { name: "LOB", type: "Int", entity: "service" },
  { name: "PhysicalAppt", type: "Int", entity: "service" },
  { name: "ProjRev", type: "Int", entity: "service" },
  { name: "PublishOnWeb", type: "Boolean", entity: "service" },
  { name: "ServShortName", type: "String", entity: "service" },
  { name: "ServiceType", type: "String", entity: "service" },
  { name: "ShowAsPendingSupp", type: "Int", entity: "service" },
  { name: "ShowLegalTabOnCase", type: "Boolean", entity: "service" },
  { name: "WebID", type: "Int", entity: "service" },
  { name: "WebSynchDate", type: "DateTime", entity: "service" },
  { name: "dateadded", type: "DateTime", entity: "service" },
  { name: "dateedited", type: "DateTime", entity: "service" },
  { name: "description", type: "String", entity: "service" },
  { name: "officecode", type: "Int", entity: "service" },
  { name: "servicecode", type: "Int", entity: "service" },
  { name: "shortdesc", type: "String", entity: "service" },
  { name: "status", type: "String", entity: "service" },
  { name: "useridadded", type: "String", entity: "service" },
  { name: "useridedited", type: "String", entity: "service" },
];

export default SERVICE_FIELDS;
