import React, { useState, createContext, useContext } from "react";
import PropTypes from "prop-types";

import { Modal, Box } from "rbx";

import styled from "styled-components";
import { ExceptionMessageModal } from "../pages/Case/components";

export const ExceptionContext = createContext(undefined);

const StyledModalContent = styled(Modal.Content)`
  position: absolute;
`;

export const ExceptionConsumer = () => (
  <ExceptionContext.Consumer>
    {({ exceptions, onComplete }) =>
      exceptions.length ? (
        <Modal active clipped>
          <Modal.Background />
          {exceptions.map((exception, i) => (
            <StyledModalContent key={exception.casenbr}>
              <Box>
                <ExceptionMessageModal
                  casenbr={exception.casenbr}
                  exceptions={exception.exceptions}
                  onComplete={() => {
                    onComplete(exception.casenbr);
                  }}
                />
              </Box>
            </StyledModalContent>
          ))}
        </Modal>
      ) : null
    }
  </ExceptionContext.Consumer>
);

ExceptionConsumer.propTypes = {};

export const ExceptionProvider = ({ children }) => {
  const [exceptions, setExceptions] = useState([]);

  const handleComplete = (casenbr) => {
    setExceptions((prev) => {
      const idx = prev.findIndex((exception) => exception.casenbr === casenbr);
      if (idx > -1) {
        prev.splice(idx, 1);
      }
      return [...prev];
    });
  };

  const handleSetExceptions = (exceptionsArr) => {
    // we only want exceptions here that have a message to display
    // some of these will require a response but not all
    const arr = exceptionsArr.reduce((acc, curr) => {
      const result = curr.exceptions?.filter((ex) => ex.DisplayMessage) || [];
      return result.length
        ? [...acc, { casenbr: curr.casenbr, exceptions: result }]
        : acc;
    }, []);

    setExceptions((prev) => [...prev, ...arr]);
  };

  return (
    <ExceptionContext.Provider
      value={{
        exceptions,
        onSetExceptions: handleSetExceptions,
        onComplete: handleComplete,
      }}
    >
      {children}
      <ExceptionConsumer />
    </ExceptionContext.Provider>
  );
};

ExceptionProvider.propTypes = {
  children: PropTypes.node,
};

ExceptionProvider.defaultProps = {
  children: null,
};

export const useExceptions = () => useContext(ExceptionContext);

export default ExceptionContext;
