/* eslint-disable import/prefer-default-export */
import { gql } from "@apollo/client";

export const RATS_SINGLE_TABLE_KEYS = gql`
  query FindUniqueTablekeys($where: TablekeysWhereUniqueInput!) {
    findUniqueTablekeys(where: $where) {
      RG
    }
  }
`;
