import React from "react";
import PropTypes from "prop-types";
import { useMutation, useQuery } from "@apollo/client";
import { Field, Control, Button, Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";

import {
  SINGLE_CLIENT_QUERY,
  SINGLE_COMPANY_QUERY,
  UPDATE_CLIENT_MUTATION,
} from "../../../../graphql";

import { useModal } from "../../../../context";

import Confirmation from "../../../../components/Confirmation";

const ClientInheritFromCompanyButton = ({ clientcode, companycode }) => {
  const { setModalOpen } = useModal();
  const { data: SingleCompanyData } = useQuery(SINGLE_COMPANY_QUERY, {
    variables: {
      where: {
        companycode: parseInt(companycode, 10),
      },
    },
  });
  const [updateClient] = useMutation(UPDATE_CLIENT_MUTATION);

  const handleInheritFromCompany = () => {
    const performInherit = async () => {
      try {
        const { addr1, addr2, city, state, zip, marketercode } = SingleCompanyData.company;

        await updateClient({
          variables: {
            data: {
              addr1: { set: addr1 },
              addr2: { set: addr2 },
              city: { set: city },
              state: { set: state },
              zip: { set: zip },
              marketercode: { set: marketercode },
            },
            where: {
              clientcode: parseInt(clientcode, 10),
            },
          },
          refetchQueries: [
            {
              query: SINGLE_CLIENT_QUERY,
              variables: {
                where: {
                  clientcode: parseInt(clientcode, 10),
                },
              },
            },
          ],
        });
        toast.success(`Company data inherited successfully.`);
        setModalOpen(false, "");
      } catch (err) {
        toast.error(`Error inheriting company data.`);
      }
    };
    setModalOpen(
      true,
      <Confirmation
        message="Are you sure you want to inherit from company?"
        onConfirm={performInherit}
      />,
    );
  };

  const isDisabled = !SingleCompanyData?.company;

  return (
    <Field>
      <Control>
        <Button
          color="danger"
          disabled={isDisabled}
          size="small"
          onClick={handleInheritFromCompany}
        >
          <Icon>
            <FontAwesomeIcon icon="copy" />
          </Icon>
          <span>Inherit from Company</span>
        </Button>
      </Control>
    </Field>
  );
};

ClientInheritFromCompanyButton.propTypes = {
  clientcode: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  companycode: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
};

export default ClientInheritFromCompanyButton;
