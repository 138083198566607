import React from "react";
import PropTypes from "prop-types";
import { Control, Field, Label, Select } from "rbx";

const BooleanSelect = ({ disabled, label, name, onChange, value }) => (
  <Field className="custom-select">
    {label && <Label>{label}</Label>}
    <Control size="small">
      <Select.Container fullwidth size="small">
        <Select
          disabled={disabled}
          id={name}
          name={name}
          value={value}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        >
          <Select.Option value="y">Yes</Select.Option>
          <Select.Option value="n">No</Select.Option>
        </Select>
      </Select.Container>
    </Control>
  </Field>
);

BooleanSelect.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

BooleanSelect.defaultProps = {
  disabled: false,
};

export default BooleanSelect;
