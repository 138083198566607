const CC_FIELDS = [
  {
    name: "#ccFields",
    type: "String",
    entity: "ccField",
  },
  {
    name: "/ccFields",
    type: "String",
    entity: "ccField",
  },
  {
    name: "company",
    type: "String",
    entity: "ccField",
  },
  {
    name: "prefix",
    type: "String",
    entity: "ccField",
  },
  {
    name: "firstname",
    type: "String",
    entity: "ccField",
  },
  {
    name: "lastname",
    type: "String",
    entity: "ccField",
  },
  {
    name: "address1",
    type: "String",
    entity: "ccField",
  },
  {
    name: "address2",
    type: "String",
    entity: "ccField",
  },
  {
    name: "city",
    type: "String",
    entity: "ccField",
  },
  {
    name: "state",
    type: "String",
    entity: "ccField",
  },
  {
    name: "zip",
    type: "String",
    entity: "ccField",
  },
  {
    name: "phone",
    type: "String",
    entity: "ccField",
  },
  {
    name: "email",
    type: "String",
    entity: "ccField",
  },
];

export default CC_FIELDS;
