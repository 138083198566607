import React from "react";
import PropTypes from "prop-types";
import { useQuery, useMutation } from "@apollo/client";
import { Field, Control, Button, Help, Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import {
  DELETE_CLIENT_MUTATION,
  FIND_FIRST_CASE_ITEM_QUERY,
} from "../../../../graphql";

import { useModal } from "../../../../context";

import Confirmation from "../../../../components/Confirmation";

const ClientDeleteButton = ({ clientcode }) => {
  const { setModalOpen } = useModal();
  const history = useHistory();
  const [deleteClient] = useMutation(DELETE_CLIENT_MUTATION);
  const { data: FindFirstCaseItemData } = useQuery(FIND_FIRST_CASE_ITEM_QUERY, {
    variables: {
      where: { clientcode: { equals: parseInt(clientcode, 10) } },
    },
    fetchPolicy: "cache-and-network",
  });

  const isDisabled =
    !FindFirstCaseItemData || FindFirstCaseItemData?.findFirstCaseItem;

  const handleDeleteCompany = () => {
    const performDelete = async () => {
      try {
        await deleteClient({
          variables: {
            where: {
              clientcode: parseInt(clientcode, 10),
            },
          },
        });
        toast.success(`Client deleted successfully.`);
        setModalOpen(false, "");
        history.push(`/clients`);
      } catch (err) {
        toast.error(`Error deleting client.`);
      }
    };
    setModalOpen(
      true,
      <Confirmation
        message="Are you sure you want to delete this client?"
        onConfirm={performDelete}
      />,
    );
  };
  return (
    <div>
      <Field>
        <Control>
          <Button
            color="danger"
            disabled={isDisabled}
            size="small"
            onClick={handleDeleteCompany}
          >
            <Icon>
              <FontAwesomeIcon icon="times" />
            </Icon>
            <span>Delete</span>
          </Button>
          {FindFirstCaseItemData?.findFirstCaseItem && (
            <Help>
              This client has cases associated with it and cannot be deleted.
            </Help>
          )}
        </Control>
      </Field>
    </div>
  );
};

ClientDeleteButton.propTypes = {
  clientcode: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
};

export default ClientDeleteButton;
