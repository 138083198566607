import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Control, Label } from "rbx";
import CreatableSelect from "react-select/creatable";

const DocumentTypeSelect = ({
  label,
  size,
  name,
  onChange,
  disabled,
  value,
  required,
  id,
  options: initialOptions,
}) => {
  const [options, setOptions] = useState(initialOptions);
  const handleChange = (e) => {
    onChange(name, e.value);
  };

  const getValue = (val) => ({
    value: val,
    label: val,
    name: "type",
  });

  useEffect(() => {
    const foundValue = options.find((option) => option.value === value);
    if (!foundValue && value) {
      setOptions((prev) => [...prev, getValue(value)]);
    }
  }, [options, value]);

  return (
    <Control expanded size={size}>
      {label && <Label>{label}</Label>}
      <CreatableSelect
        id={id}
        isDisabled={disabled}
        menuPortalTarget={document.body}
        name={name}
        options={options}
        required={required}
        styles={{
          menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
          indicatorsContainer: (provided) => ({
            ...provided,
            height: "inherit",
          }),
          indicatorSeparator: (provided) => ({
            ...provided,
            display: "none",
          }),
          container: (provided) => ({
            fontSize: 13,
          }),
          singleValue: (provided) => ({
            ...provided,
            top: "38%",
          }),
          control: (provided) => ({
            ...provided,
            minHeight: 31,
            height: 31,
            width: "100%",
            minWidth: 180,
            "&:hover": {
              borderColor: "hsl(0, 0%, 90%)",
              borderRadius: 4,
              cursor: "pointer",
            },
          }),
          placeholder: (provided) => ({ ...provided, top: "38%" }),
          menu: (provided) => ({
            ...provided,
            fontSize: 12,
            zIndex: 9999,
          }),
        }}
        value={getValue(value)}
        onChange={handleChange}
      />
    </Control>
  );
};

DocumentTypeSelect.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  id: PropTypes.string,
  options: PropTypes.array,
};

DocumentTypeSelect.defaultProps = {
  size: "small",
  disabled: false,
  value: "",
  name: "",
  label: null,
  required: false,
  id: null,
  options: [
    {
      name: "type",
      value: "CV",
      label: "CV",
    },
    {
      name: "type",
      value: "Elect Sig.",
      label: "Elect Sig.",
    },
    {
      name: "type",
      value: "Fees",
      label: "Fees",
    },
    {
      name: "type",
      value: "HCAI Form",
      label: "HCAI Form",
    },
    {
      name: "type",
      value: "Insurance",
      label: "Insurance",
    },
    {
      name: "type",
      value: "Licensure",
      label: "Licensure",
    },
    {
      name: "type",
      value: "Malpractice",
      label: "Malpractice",
    },
    {
      name: "type",
      value: "Other",
      label: "Other",
    },
    {
      name: "type",
      value: "Reg Prof Nbr",
      label: "Report",
    },
    {
      name: "type",
      value: "W-9",
      label: "W-9",
    },
    {
      name: "type",
      value: "Web Site",
      label: "Web Site",
    },
  ],
};

export default DocumentTypeSelect;
