/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Icon, Generic } from "rbx";
import { round } from "../../../../utils";
import { TableCell } from "../../../../components/DataTable";

export const DEFAULT_COLUMNS = [
  {
    // expander cell
    Header: "",
    id: "expander",
    Cell: ({ row }) => (
      <Icon size="small" {...row.getToggleRowExpandedProps()}>
        <FontAwesomeIcon icon={row.isExpanded ? "caret-down" : "caret-right"} />
      </Icon>
    ),
  },
  {
    Header: "",
    id: "block-time",
    accessor: "locationcode",
    Cell: ({ row }) => (
      <Generic style={{ textAlign: "center" }} title="Block Time" type="button">
        <Icon color="info" size="small">
          <FontAwesomeIcon icon="calendar" />
        </Icon>
      </Generic>
    ),
  },
  {
    id: "doctor",
    Header: "Doctor",
    accessor: "doctor",
    Cell: ({ cell }) => (
      <TableCell.Link
        to={`/doctors/${cell?.row?.original?.doctorcode}/profile`}
        value={
          cell?.value
            ? `${cell.value.lastname}, ${cell.value.firstname} ${
                cell.value?.credentials || ""
              }`
            : "--"
        }
      />
    ),
    disableSortBy: true,
  },
  {
    id: "proximity",
    Header: "Proximity",
    accessor: "proximity",
    Cell: ({ cell }) =>
      typeof cell.value === "number" ? round(cell.value, 1) : "--",
    sortType: (rowA, rowB) => {
      if (rowA.original.proximity > rowB.original.proximity) return 1;
      if (rowB.original.proximity > rowA.original.proximity) return -1;
      return 0;
    },
  },
  {
    id: "location:location",
    Header: "Location",
    accessor: "location.location",
  },
  {
    id: "location:Phone",
    Header: "Phone",
    accessor: "location.Phone",
  },
  {
    id: "specialty",
    Header: "Specialty",
    accessor: "doctor.DoctorSpecialty",
    Cell: ({ cell }) =>
      Array.isArray(cell?.value)
        ? cell.value.map((x) => x.specialtycode).join(", ")
        : "--",
    disableSortBy: true,
  },
  {
    id: "location:city",
    Header: "City",
    accessor: "location.city",
  },
  {
    id: "location:state",
    Header: "State",
    accessor: "location.state",
  },
  {
    id: "location:county",
    Header: "county",
    accessor: "location.county",
  },
  {
    id: "location:zip",
    Header: "zip",
    accessor: "location.zip",
  },
];

export default DEFAULT_COLUMNS;
