import React, { useEffect } from "react";

import PropTypes from "prop-types";
import { Field, Icon, Control, Button } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const formatter = new Intl.NumberFormat();

const SchedulePagination = ({
  page,
  pages,
  onClickPrev,
  onClickNext,
  canNextPage,
  canPreviousPage,
  pageSize,
  recordCount,
}) => {
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (canNextPage && e.keyCode === 39) {
        onClickNext();
      }
      if (canPreviousPage && e.keyCode === 37) {
        onClickPrev();
      }
    };
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [canNextPage, canPreviousPage, onClickNext, onClickPrev]);

  return (
    <div className="data-table-pagination">
      <Field align="right" kind="group" size="small">
        <Control>
          <Button
            className="prev"
            color="primary"
            disabled={!canPreviousPage}
            role="button"
            size="small"
            onClick={onClickPrev}
          >
            <Icon>
              <FontAwesomeIcon icon="arrow-left" />
            </Icon>
          </Button>
        </Control>
        <Control>
          <Button disabled size="small">
            {[page, "/", pages].join(" ")}
            &nbsp;
            {`(${formatter.format(recordCount)})`}
          </Button>
        </Control>
        <Control>
          <Button
            className="next"
            color="primary"
            disabled={!canNextPage}
            role="button"
            size="small"
            onClick={onClickNext}
          >
            <Icon>
              <FontAwesomeIcon icon="arrow-right" />
            </Icon>
          </Button>
        </Control>
      </Field>
    </div>
  );
};

SchedulePagination.propTypes = {
  pages: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  page: PropTypes.number.isRequired,
  onClickNext: PropTypes.func.isRequired,
  onClickPrev: PropTypes.func.isRequired,
  canNextPage: PropTypes.bool.isRequired,
  canPreviousPage: PropTypes.bool.isRequired,
  pageSize: PropTypes.number.isRequired,
  recordCount: PropTypes.number.isRequired,
};

export default SchedulePagination;
