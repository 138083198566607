/* eslint-disable no-nested-ternary */
import React, { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { Control, Label } from "rbx";
import { useApolloClient } from "@apollo/client";
import { AsyncPaginate } from "react-select-async-paginate";
import { useClientMaster } from "../../hooks";

import {
  RATS_REQUEST_GENERATOR_QUERY,
  RATS_SINGLE_REQUEST_GENERATOR_QUERY,
  RATS_SINGLE_TEMP_REQUESTOR,
} from "../../graphql";

const QUERY_LIMIT = 50;

const DEFAULT_STATE = {
  RGclientOfficeID: "",
  RGnameLast: "",
  RGnameFirst: "",
  RGcourtID: "",
  RGemail: "",
  RGemail2: "",
  COofficeName: "",
  COaddress1: "",
  COaddress2: "",
  COcity: "",
  COstate: "",
  COzip: "",
  COphone1: "",
  COfax: "",
};

const RatsRequestGeneratorSelect = ({
  name,
  value: initialValue,
  label,
  onChange,
  disabled,
  getValue,
  setValue,
  required,
  onSelectedAddressChange,
  onAddressInputsChange,
  shouldFilter,
}) => {
  const client = useApolloClient();

  const [selected, setSelected] = useState(null);
  const [defaultOptions, setDefaultOptions] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState([]);
  const [inputs, setInputs] = useState({ ...DEFAULT_STATE });
  const { filterDetails } = useClientMaster();
  const applyFilter = filterDetails?.shouldFilterContacts && shouldFilter;

  useEffect(() => {
    onSelectedAddressChange(selectedAddress);
  }, [onSelectedAddressChange, selectedAddress]);

  useEffect(() => {
    onAddressInputsChange(inputs);
  }, [onAddressInputsChange, inputs]);

  const getLabel = useCallback(
    (rg) =>
      `${
        rg.RGnameFirst && rg.RGnameLast
          ? `${rg.RGnameLast}, ${rg.RGnameFirst}`
          : rg.TRfirstName && rg.TRlastName
          ? `${rg.TRlastName}, ${rg.TRfirstName}`
          : "N/A"
      } ${rg.RGid ? `[${rg.RGid}]` : rg.TRid ? `[${rg.TRid}]` : ""}`,
    []
  );

  const promiseOptions = async (inputValue, { length }) => {
    const where = {
      OR: [
        {
          RGnameLast: {
            startsWith: inputValue,
          },
        },
        {
          RGnameFirst: {
            startsWith: inputValue,
          },
        },
        {
          RGcourtID: {
            startsWith: inputValue,
          },
        },
        {
          RGemail: {
            startsWith: inputValue,
          },
        },
        {
          RGemail2: {
            startsWith: inputValue,
          },
        },
      ],
      ...(applyFilter && {
        clientoffice: {
          is: {
            COclientMasterID: {
              equals: filterDetails?.clientMasterID,
            },
          },
        },
      }),
    };

    const { data: ratsRequestGeneratorData } = await client.query({
      query: RATS_REQUEST_GENERATOR_QUERY,
      variables: {
        take: QUERY_LIMIT,
        where,
        orderBy: [{ RGnameLast: "asc" }, { RGnameFirst: "asc" }],
        skip: length,
      },
      fetchPolicy: "network-only",
    });

    const requestGenerators = ratsRequestGeneratorData?.requestgenerators;

    const result = Array.isArray(requestGenerators)
      ? requestGenerators.map((rg) => ({
          value: rg.RGid,
          label: getLabel(rg),
        }))
      : [];
    setDefaultOptions(result);

    return {
      options: result,
      hasMore: result.length === QUERY_LIMIT,
    };
  };

  const handleChange = ({ value: newValue }) => {
    onChange(name, setValue(newValue || ""));
  };

  useEffect(() => {
    let address = [];
    const getRequestGenerator = async () => {
      if (initialValue) {
        const { data: requestGeneratorData } = await client.query({
          query: RATS_SINGLE_REQUEST_GENERATOR_QUERY,
          variables: {
            where: { RGid: getValue(initialValue) },
          },
        });

        const requestGenerator = requestGeneratorData?.requestgenerator;

        if (requestGenerator) {
          setSelected({
            value: requestGenerator.RGid,
            label: getLabel(requestGenerator),
          });

          setInputs((prev) => ({
            ...prev,
            RGnameLast: requestGenerator.RGnameLast,
            RGnameFirst: requestGenerator.RGnameFirst,
            RGcourtID: requestGenerator.RGcourtID,
            RGemail: requestGenerator.RGemail,
            RGemail2: requestGenerator.RGemail2,
          }));

          // if clientOffice is null, address wasn't being set at all. Just set with First and Last name if address is unavailable.
          address = [
            `${requestGenerator.RGnameLast}, ${requestGenerator.RGnameFirst}`,
          ];

          const clientOffice = requestGenerator.clientoffice;
          if (clientOffice) {
            address = [
              `${requestGenerator.RGnameLast}, ${requestGenerator.RGnameFirst}`,
              clientOffice.COofficeName,
              clientOffice.COaddress1,
              clientOffice.COaddress2,
              `${clientOffice.COcity}, ${clientOffice.COstate} ${clientOffice.COzip}`,
              clientOffice.COphone1,
              requestGenerator.RGemail,
            ];

            setInputs((prev) => ({
              ...prev,
              COofficeName: clientOffice.COofficeName,
              COaddress1: clientOffice.COaddress1,
              COaddress2: clientOffice.COaddress2,
              COcity: clientOffice.COcity,
              COstate: clientOffice.COstate,
              COzip: clientOffice.COzip,
              COphone1: clientOffice.COphone1,
              COfax: clientOffice.COfax,
            }));
          }
        } else {
          const { data: tempRequestorData } = await client.query({
            query: RATS_SINGLE_TEMP_REQUESTOR,
            variables: {
              where: { TRid: getValue(initialValue) },
            },
          });

          const tempRequestor = tempRequestorData?.temprequestor;

          if (tempRequestor) {
            setSelected({
              value: tempRequestor.TRid,
              label: getLabel(tempRequestor),
            });

            address = [
              `${tempRequestor.TRlastName}, ${tempRequestor.TRfirstName}`,
              tempRequestor.TRcompany,
              tempRequestor.TRaddress1,
              tempRequestor.TRaddress2,
              `${tempRequestor.TRcity}, ${tempRequestor.TRstate} ${tempRequestor.TRzip}`,
              tempRequestor.TRphone,
              tempRequestor.TRemail,
            ];

            setInputs((prev) => ({
              ...prev,
              RGnameLast: tempRequestor.TRlastName,
              RGnameFirst: tempRequestor.TRfirstName,
              RGcourtID: tempRequestor.TRcourtID,
              RGemail: tempRequestor.TRemail,
              RGemail2: tempRequestor.TRemail2,
              COofficeName: tempRequestor.TRcompany,
              COaddress1: tempRequestor.TRaddress1,
              COaddress2: tempRequestor.TRaddress2,
              COcity: tempRequestor.TRcity,
              COstate: tempRequestor.TRstate,
              COzip: tempRequestor.TRzip,
              COphone1: tempRequestor.TRphone,
              COfax: tempRequestor.TRfax,
            }));
          }
        }
        setSelectedAddress(address);
      } else {
        setSelected(null);
        setSelectedAddress([]);
        setInputs({ ...DEFAULT_STATE });
      }
    };
    getRequestGenerator();
  }, [initialValue, getValue, client, getLabel, label]);

  useEffect(() => {
    if (selected) {
      const currOptions = [...defaultOptions];
      if (!currOptions.some((opt) => opt.value === selected.value)) {
        setDefaultOptions((prev) => [selected, ...prev]);
      }
    }
  }, [selected, defaultOptions]);

  const indicateRequired =
    required &&
    !disabled &&
    (!initialValue ||
      initialValue === "" ||
      initialValue === "0" ||
      initialValue === 0);

  return (
    <Control expanded style={{ flexGrow: 1, marginRight: "0.313rem" }}>
      <Label>{label}</Label>
      <AsyncPaginate
        cacheOptions
        debounceTimeout={300}
        defaultOptions={defaultOptions}
        isDisabled={disabled}
        loadOptions={promiseOptions}
        menuPortalTarget={document.body}
        placeholder="Start typing..."
        required={required}
        styles={{
          menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
          indicatorsContainer: (provided) => ({
            ...provided,
            height: "inherit",
          }),
          indicatorSeparator: (provided) => ({
            ...provided,
            display: "none",
          }),
          container: (provided) => ({
            fontSize: 13,
          }),
          singleValue: (provided) => ({
            ...provided,
            top: "38%",
          }),
          control: (provided) => ({
            ...provided,
            minHeight: 31,
            height: 31,
            width: "100%",
            minWidth: 180,
            border: indicateRequired ? "1px #e63362 solid" : "",
            boxShadow: indicateRequired
              ? "0 0 0 0.125em rgba(230, 51, 98, 0.25)"
              : "",
            "&:hover": {
              borderColor: "hsl(0, 0%, 90%)",
              borderRadius: 4,
              cursor: disabled ? "not-allowed" : "pointer",
            },
          }),
          placeholder: (provided) => ({ ...provided, top: "38%" }),
          menu: (provided) => ({
            ...provided,
            fontSize: 12,
            zIndex: 999,
          }),
        }}
        value={selected}
        onChange={handleChange}
      />
    </Control>
  );
};

RatsRequestGeneratorSelect.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  getValue: PropTypes.func,
  setValue: PropTypes.func,
  required: PropTypes.bool,
  onSelectedAddressChange: PropTypes.func,
  onAddressInputsChange: PropTypes.func,
  shouldFilter: PropTypes.bool,
};

RatsRequestGeneratorSelect.defaultProps = {
  name: "",
  label: "",
  value: "",
  onChange: () => null,
  disabled: false,
  getValue: (x) => x,
  setValue: (x) => x,
  required: false,
  onSelectedAddressChange: () => null,
  onAddressInputsChange: () => null,
  shouldFilter: true,
};

export default RatsRequestGeneratorSelect;
