import React from "react";
import PropTypes from "prop-types";
import { Field, Control, Input, Label, Help } from "rbx";

const DocumentLocationForm = ({ inputs, onChange }) => (
  <div>
    <Field>
      <Control>
        <Label>Document Location</Label>
        <Input
          autoComplete="new"
          maxLength={100}
          name="DocumentLocation"
          size="small"
          value={inputs.DocumentLocation}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
        <Help color="danger">
          Document Location must be 100 characters or less
        </Help>
      </Control>
    </Field>
  </div>
);

DocumentLocationForm.propTypes = {
  inputs: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default DocumentLocationForm;
