import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Title, Button, Field, Control } from "rbx";
import { useMutation, useLazyQuery, useApolloClient } from "@apollo/client";
import OtherPartyTypeForm from "../OtherPartyTypeForm";
import { useModal, useAuth } from "../../../../../context";
import {
  convertTimeZoneDataBase,
  customToast as toast,
  getDateEST,
} from "../../../../../utils";
import Confirmation from "../../../../../components/Confirmation";
import {
  CREATE_OTHER_PARTY_TYPE_MUTATION,
  UPDATE_OTHER_PARTY_TYPE_MUTATION,
  DELETE_OTHER_PARTY_TYPE_MUTATION,
  // CREATE_AUDIT_TRAIL_MUTATION,
  FIND_FIRST_OTHER_PARTY_QUERY,
  LIST_OTHER_PARTY_TYPES_QUERY,
  SINGLE_OTHER_PARTY_TYPE_QUERY,
} from "../../../../../graphql";

const INITIAL_STATE = {
  type: "",
  description: "",
};

class CustomOtherPartyTypeError extends Error {}

const OtherPartyTypeModal = ({ onComplete, type }) => {
  const client = useApolloClient();
  const { setModalOpen } = useModal();
  const { state: authState } = useAuth();
  const [inputs, setInputs] = useState(INITIAL_STATE);
  const [updated, setUpdated] = useState([]);
  const [loading, setLoading] = useState(false);

  const [
    getOtherPartyType,
    { data: otherParyTypeData, loading: queryLoading },
  ] = useLazyQuery(SINGLE_OTHER_PARTY_TYPE_QUERY);
  const [createOtherPartyType] = useMutation(CREATE_OTHER_PARTY_TYPE_MUTATION);
  const [updateOtherPartyType] = useMutation(UPDATE_OTHER_PARTY_TYPE_MUTATION);
  const [deleteOtherPartyType] = useMutation(DELETE_OTHER_PARTY_TYPE_MUTATION);
  // const [createAuditTrail] = useMutation(CREATE_AUDIT_TRAIL_MUTATION);

  useEffect(() => {
    getOtherPartyType({
      variables: {
        where: {
          type,
        },
      },
    });
  }, [getOtherPartyType, type]);

  useEffect(() => {
    if (otherParyTypeData?.otherPartyType) {
      setInputs({
        type: otherParyTypeData.otherPartyType.type || "",
        description: otherParyTypeData.otherPartyType.description || "",
      });
    }
  }, [otherParyTypeData]);

  const handleChange = (name, value) => {
    setInputs((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (type) {
      setUpdated((prev) => (prev.includes(name) ? prev : [...prev, name]));
    }
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const date = convertTimeZoneDataBase(getDateEST());
      setLoading(true);
      if (!type) {
        await createOtherPartyType({
          variables: {
            data: {
              ...inputs,
              useridedited: authState.user.userid,
              dateedited: date,
              useridadded: authState.user.userid,
              dateadded: date,
            },
          },
          refetchQueries: [
            {
              query: LIST_OTHER_PARTY_TYPES_QUERY,
            },
          ],
        });

        // await createAuditTrail({
        //   variables: {
        //     data: {
        //       tablename: "tblOtherPartyType",
        //       entityid: createOtherPartyTypeData.createOtherPartyType.type.toString(),
        //       type: "New",
        //       description: "New Other Party Type created",
        //       useridadded: authState.user.userid,
        //       dateadded: date,
        //     },
        //   },
        //   refetchQueries: [
        //     {
        //       query: SINGLE_OTHER_PARTY_TYPE_QUERY,
        //       variables: {
        //         where: {
        //           type: createOtherPartyTypeData.createOtherPartyType.type,
        //         },
        //       },
        //     },
        //   ],
        // });
        toast.success("Other Party Type created successfully.");
      } else {
        await updateOtherPartyType({
          variables: {
            data: {
              type: { set: inputs.type },
              description: { set: inputs.description },
              useridedited: { set: authState.user.userid },
              dateedited: { set: date },
            },
            where: {
              type,
            },
          },
          refetchQueries: [
            {
              query: LIST_OTHER_PARTY_TYPES_QUERY,
            },
            {
              query: SINGLE_OTHER_PARTY_TYPE_QUERY,
              variables: {
                where: {
                  type,
                },
              },
            },
          ],
        });

        // await createAuditTrail({
        //   variables: {
        //     data: {
        //       tablename: "tblOtherPartyType",
        //       entityid: type.toString(),
        //       type: "Update",
        //       description: `Updated fields: ${updated.join(", ")}`,
        //       useridadded: authState.user.userid,
        //       dateadded: date,
        //     },
        //   },
        // });
        toast.success("Other Party Type updated successfully.");
      }
      onComplete();
    } catch (err) {
      if (err.message.includes("Unique constraint")) {
        toast.error("This Other Party Type already exists");
      } else {
        toast.error("Error saving Other Party Type");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (e) => {
    e.preventDefault();

    const performDelete = async () => {
      try {
        setLoading(true);
        const { data: OtherPartyData } = await client.query({
          query: FIND_FIRST_OTHER_PARTY_QUERY,
          variables: {
            where: {
              OPType: { equals: "OP" },
              OPSubType: { equals: type },
            },
            fetchPolicy: "network-only",
          },
        });
        if (OtherPartyData?.findFirstOtherParty) {
          throw new CustomOtherPartyTypeError(
            "This other party type is associated with an other party record and cannot be deleted."
          );
        }
        await deleteOtherPartyType({
          variables: {
            where: {
              type,
            },
          },
          refetchQueries: [
            {
              query: LIST_OTHER_PARTY_TYPES_QUERY,
            },
          ],
        });
        toast.success("Other Party Type deleted successfully");
        onComplete();
      } catch (err) {
        if (err instanceof CustomOtherPartyTypeError) {
          toast.error(err.message);
        } else {
          toast.error("Error trying to delete Other Party Type");
        }
      } finally {
        setLoading(false);
      }
    };
    setModalOpen(
      true,
      <Confirmation
        message="Are you sure you want to delete this Other Party Type?"
        onCancel={() =>
          setModalOpen(
            true,
            <OtherPartyTypeModal type={type} onComplete={onComplete} />
          )
        }
        onConfirm={performDelete}
      />
    );
  };

  const isDisabled =
    !inputs.type || (type && !updated?.length) || queryLoading || loading;

  return (
    <form id="other-party-type-form" onSubmit={handleSubmit}>
      <header className="page-head">
        <div className="page-head-start">
          <Title size={5}>
            {[!type ? "Create" : "Edit", "Other Party Type"].join(" ")}
          </Title>
        </div>
        <div className="page-head-end">
          <Button.Group hasAddons>
            <Button
              size="small"
              type="button"
              onClick={() => onComplete(false)}
            >
              <span>Cancel</span>
            </Button>
            <Button
              color="primary"
              disabled={isDisabled}
              form="other-party-type-form"
              size="small"
              state={loading ? "loading" : ""}
              type="submit"
            >
              <span>Submit</span>
            </Button>
          </Button.Group>
        </div>
      </header>
      <hr />
      <OtherPartyTypeForm inputs={inputs} onChange={handleChange} />
      <hr />

      {type && (
        <Field>
          <Control>
            <Button
              color="danger"
              size="small"
              type="button"
              onClick={handleDelete}
            >
              <span>Delete</span>
            </Button>
          </Control>
        </Field>
      )}
    </form>
  );
};

OtherPartyTypeModal.propTypes = {
  onComplete: PropTypes.func,
  type: PropTypes.string,
};

OtherPartyTypeModal.defaultProps = {
  onComplete: () => null,
  type: null,
};

export default OtherPartyTypeModal;
