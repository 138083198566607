/* eslint-disable import/prefer-default-export */
import { gql } from "@apollo/client";

export const CREATE_USER_GROUP_MUTATION = gql`
  mutation createUserGroup($data: UserGroupCreateInput!) {
    createUserGroup(data: $data) {
      groupcode
      groupdesc
    }
  }
`;

export const UPDATE_USER_GROUP_MUTATION = gql`
  mutation updateUserGroup(
    $data: UserGroupUpdateInput!
    $where: UserGroupWhereUniqueInput!
  ) {
    updateUserGroup(data: $data, where: $where) {
      groupcode
      groupdesc
    }
  }
`;

export const DELETE_USER_GROUP_MUTATION = gql`
  mutation deleteUserGroup($where: UserGroupWhereUniqueInput!) {
    deleteUserGroup(where: $where) {
      groupcode
    }
  }
`;