import { library } from "@fortawesome/fontawesome-svg-core";

import {
  faCheckCircle,
  faAsterisk,
  faCalendar,
  faRedo,
  faSquare,
  faSave,
  faParagraph,
  faEllipsisH,
  faClock,
  faCaretDown,
  faCaretRight,
  faChevronRight,
  faExchangeAlt,
  faBug,
  faLink,
  faBroom,
  faUser,
  faAngleDown,
  faSignOutAlt,
  faHome,
  faCogs,
  faBuilding,
  faFile,
  faList,
  faBriefcaseMedical,
  faChevronUp,
  faChevronDown,
  faArrowLeft,
  faArrowRight,
  faTimes,
  faUsers,
  faPlus,
  faPhone,
  faAt,
  faAddressBook,
  faSync,
  faSyncAlt,
  faFilter,
  faPrint,
  faDownload,
  faSearch,
  faCalendarTimes,
  faCopy,
  faEdit,
  faUpload,
  faExternalLinkAlt,
  faEye,
  faEyeSlash,
  faCheckDouble,
  faCheck,
  faUndo,
  faMailBulk,
  faBook,
  faCodeBranch,
  faFileExport,
  faFolderOpen,
  faExclamationTriangle,
  faBinoculars,
  faAngleDoubleLeft,
  faInfo,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faExclamationTriangle,
  faBroom,
  faSync,
  faUser,
  faAngleDown,
  faSignOutAlt,
  faSyncAlt,
  faHome,
  faCogs,
  faBuilding,
  faFile,
  faBriefcaseMedical,
  faList,
  faChevronUp,
  faBinoculars,
  faChevronDown,
  faArrowLeft,
  faArrowRight,
  faTimes,
  faUsers,
  faPlus,
  faPhone,
  faAt,
  faAddressBook,
  faFilter,
  faPrint,
  faSearch,
  faCalendarTimes,
  faCopy,
  faEdit,
  faUpload,
  faDownload,
  faExternalLinkAlt,
  faEye,
  faEyeSlash,
  faCheckDouble,
  faUndo,
  faCheck,
  faMailBulk,
  faBook,
  faCodeBranch,
  faFileExport,
  faFolderOpen,
  faBug,
  faChevronRight,
  faAsterisk,
  faLink,
  faAngleDoubleLeft,
  faExchangeAlt,
  faChevronRight,
  faCaretDown,
  faCaretRight,
  faClock,
  faEllipsisH,
  faParagraph,
  faSave,
  faInfo,
  faCheckCircle,
  faSquare,
  faRedo,
  faCalendar,
  faQuestionCircle
);
