import React from "react";
import PropTypes from "prop-types";
import { Field, Control } from "rbx";

import DoctorSelect from "../../../../components/DoctorSelect";

const ClientDrDoNotUseForm = ({ disabled, inputs, onChange }) => (
  <div className="client-dr-do-not-use-form">
    <Field>
      <Control>
        <DoctorSelect
          disabled={disabled}
          label="Doctor"
          name="doctorcode"
          size="small"
          type="text"
          value={inputs.doctorcode}
          onChange={onChange}
        />
      </Control>
    </Field>
  </div>
);

ClientDrDoNotUseForm.propTypes = {
  disabled: PropTypes.bool,
  inputs: PropTypes.object,
  onChange: PropTypes.func,
};
ClientDrDoNotUseForm.defaultProps = {
  inputs: {},
  disabled: false,
  onChange: () => null,
};
export default ClientDrDoNotUseForm;
