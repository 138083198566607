import React from "react";
import PropTypes from "prop-types";
import { Field, Control, Input, Label } from "rbx";

const ProviderTypeForm = ({ inputs, onChange }) => (
  <div>
    <Field kind="group">
      <Control expanded>
        <Label>Description</Label>
        <Input
          autoComplete="new"
          name="description"
          size="small"
          value={inputs.description}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
    </Field>
  </div>
);

ProviderTypeForm.propTypes = {
  inputs: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ProviderTypeForm;
