import React, { useState, useEffect } from "react";
import { useMutation, useQuery } from "@apollo/client";
import {
  useParams,
  useLocation,
  Switch,
  Route,
  Prompt,
} from "react-router-dom";

import { Button, Title } from "rbx";
import { toast } from "react-toastify";
import {
  ALL_DOCUMENTS_QUERY,
  SINGLE_DOCUMENT_QUERY,
  UPDATE_DOCUMENT_MUTATION,
} from "../../graphql";

import { Profile, Template, Test } from "./routes";

import Loader from "../../components/Loader";
import { useAuth } from "../../context";
import { Help, DocumentTabs } from "./components";

import { convertInputToVariables } from "../../utils";

const INITIAL_STATE = {
  document: "",
  documentname: "",
  description: "",
  type: "",
  template: "",
  status: "",
  lob: "",
  complete: "",
  outputFormat: "",
};

const DocumentPage = () => {
  const { id } = useParams();
  const { state: authState } = useAuth();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [editing, setEditing] = useState(false);
  const [showHelp, setShowHelp] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);

  const [inputs, setInputs] = useState({ ...INITIAL_STATE });
  const [updateDocument, { loading: UpdateDocumentLoading }] = useMutation(
    UPDATE_DOCUMENT_MUTATION
  );

  const { data: DocumentData } = useQuery(SINGLE_DOCUMENT_QUERY, {
    variables: {
      where: {
        SeqNo: parseInt(id, 10),
      },
    },
  });

  useEffect(() => {
    const document = DocumentData?.document;
    if (document) {
      setInputs((prev) => ({ ...prev, ...document }));
      setLoading(false);
    }
  }, [DocumentData?.document]);

  const handleChange = (name, value) => {
    if (!hasChanges) {
      setHasChanges(true);
    }
    setInputs((prev) => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    if (!/template/.test(location.pathname)) {
      const document = DocumentData?.document;
      setHasChanges(false);

      if (document) {
        setInputs((prev) => ({ ...prev, ...document }));
      }
    }
  }, [DocumentData, location]);

  const handleSave = async (e) => {
    if (e) e.preventDefault();
    try {
      setLoading(true);
      const data = {
        ...convertInputToVariables(
          inputs,
          Object.keys(INITIAL_STATE),
          false,
          // templates are big, we only update when necessary
          [!/template/.test(location.pathname) && "template", "lob"].filter(
            Boolean
          )
        ),
        dateedited: { set: new Date() },
        useridedited: { set: authState?.user?.userid },
      };

      if (!data.outputFormat) {
        data.outputFormat = { set: "PDF" };
      }

      if (inputs.lob) {
        data.businessLine = {
          connect: {
            businesslineid: parseInt(inputs.lob, 10),
          },
        };
      } else if (DocumentData?.document?.lob) {
        data.businessLine = {
          disconnect: true,
        };
      }

      await updateDocument({
        variables: {
          where: {
            SeqNo: parseInt(id, 10),
          },
          data,
        },
        refetchQueries: [
          {
            query: ALL_DOCUMENTS_QUERY,
          },
          {
            query: SINGLE_DOCUMENT_QUERY,
            variables: {
              where: { SeqNo: parseInt(id, 10) },
            },
          },
        ],
      });
      toast.success("Document updated successfully");
    } catch (err) {
      toast.error(`Error updating document`);
    } finally {
      setLoading(false);
      setHasChanges(false);
    }
  };
  if (DocumentData?.loading) return <Loader />;
  return (
    <form style={{ marginRight: showHelp ? "30vw" : "" }} onSubmit={handleSave}>
      <Prompt
        message={(loc) =>
          /template/.test(loc.pathname)
            ? true
            : "You have unsaved changes. Are you sure you want to leave this page?"
        }
        when={hasChanges}
      />
      <header className="page-head">
        <div className="document-page-header">
          <Title>{DocumentData?.document?.document}</Title>
        </div>
        <div className="page-head-end">
          <Button.Group hasAddons style={{ justifyContent: "flex-end" }}>
            {/template|test/.test(location.pathname) && (
              <Button
                color="warning"
                disabled={UpdateDocumentLoading}
                size="small"
                type="button"
                onClick={() => setShowHelp((prev) => !prev)}
              >
                Help
              </Button>
            )}
            <Button
              color="primary"
              disabled={UpdateDocumentLoading}
              size="small"
              type="button"
              onClick={() => setEditing((prev) => !prev)}
            >
              Edit
            </Button>
            <Button
              color="success"
              disabled={UpdateDocumentLoading}
              size="small"
              state={loading ? "loading" : ""}
              type="submit"
            >
              Save
            </Button>
          </Button.Group>
        </div>
      </header>
      <DocumentTabs id={id} />
      <Switch>
        <Route path="/documents/:id/profile">
          <Profile
            disabled={!editing || UpdateDocumentLoading}
            inputs={inputs}
            onChange={handleChange}
          />
        </Route>
        <Route path="/documents/:id/template">
          <Template
            name="template"
            value={inputs.template}
            onChange={handleChange}
          />
        </Route>
        <Route path="/documents/:id/test">
          <Test inputs={inputs} />
        </Route>
      </Switch>
      {showHelp && <Help onClose={() => setShowHelp(false)} />}
    </form>
  );
};

DocumentPage.propTypes = {};

export default DocumentPage;
