/* eslint-disable import/prefer-default-export */
import { gql } from "@apollo/client";

export const AGGREGATE_CASE_OTHER_PARTY = gql`
  query aggregateCaseOtherParty($where: CaseOtherPartyWhereInput) {
    aggregateCaseOtherParty(where: $where) {
      count {
        casenbr
      }
    }
  }
`;

export const LIST_CASE_OTHER_PARTIES = gql`
  query caseOtherParties($where: CaseOtherPartyWhereInput) {
    caseOtherParties(where: $where) {
      casenbr
      status
      description
    }
  }
`;

export const LIST_CASE_OTHER_PARTY_QUERY = gql`
  query caseOtherParties(
    $orderBy: [CaseOtherPartyOrderByInput!]
    $where: CaseOtherPartyWhereInput
  ) {
    caseOtherParties(orderBy: $orderBy, where: $where) {
      OPCode
      Type
      casenbr
      dateadded
      dateedited
      description
      duedate
      status
      useridadded
      useridedited
      useridresponsible
      otherParty {
        doctorcode
        firstname
        lastname
        companyname
      }
    }
    aggregateCaseOtherParty: aggregateCaseOtherParty(where: $where) {
      count {
        OPCode
        casenbr
      }
    }
  }
`;

export const FIND_FIRST_CASE_OTHER_PARTY = gql`
  query FindFirstCaseOtherParty($where: CaseOtherPartyWhereInput) {
    findFirstCaseOtherParty(where: $where) {
      casenbr
    }
  }
`;
