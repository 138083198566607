const INITIAL_SUBPOENA_INFO = {
  ContactName: "",
  ClaimType: "",
  Caption: "",
  County: "",
  DocketNumber: "",
  Court: "",
  CourtAddress: "",
  Branch: "",
  Division: "",
  CalendarNumber: "",
  WCJudge: "",
  WCInsuranceCarrier: "",
  WCEmployer: "",
  WCStateFileNumber: "",
  WCStateFileYear: "",
  WCRFANumber: "",
  WCCarrierCode: "",
  SubpoenaState: "",
  CourtFee: 0,
  SheriffFee: 0,
  SecretaryOfStateFee: 0,
};

const INITIAL_PROVIDER_MODEL = {
  ProviderID: "1",
  Provider: "",
  RequestDates: "allRecs",
  RequestMethod: 0,
  RushStatus: "TRRTNOR",
  SpecialInstructions: "",
  RetrieveStartDate: "",
  RetrieveEndDate: "",
  RetrievePresent: false,
  fromDB: false,
  RecordType: [],
};

const INITIAL_STATE = {
  // // Referrer
  // FirstName: "James",
  // LastName: "Jarvis",
  // CompanyName: "GraVoc",
  // City: "Harvard",
  // State: "MA",
  // Phone: "(508) 365-4281",
  // Email: "jjarvis@gravoc.com",

  // // Claim
  // CaseType: 24,
  // ClaimNumber: "1337",
  // Insured: "Mr. James Jarvis",
  // DateOfLoss: "2021-06-28",
  // Jurisdiction: "MA",
  // Venue: "Mt. Olympus",

  // // Claimant
  // Claimant: false,
  // ClaimantFirstName: "James",
  // ClaimantLastName: "Jarvis",
  // ClaimantAddress1: "53 Oak Hill Rd",
  // ClaimantAddress2: "Cupboard Under The Stairs",
  // ClaimantCity: "Harvard",
  // ClaimantState: "MA",
  // ClaimantZip: "01451",
  // ClaimantEmail: "jjarvis@gravoc.com",
  // ClaimantPhone: "(508) 365-4281",
  // ClaimantCellPhone: "(508) 365-4281",
  // ClaimantDateOfBirth: "1990-06-28",
  // ClaimantGender: "Male",
  // ClaimantMaritalStatus: "Married",
  // ClaimantSSN: "123-45-6789",
  // ClaimantSpecialPhysical: "Handsome",

  // Referrer
  FirstName: "",
  LastName: "",
  CompanyName: "",
  City: "",
  State: "MA",
  Phone: "",
  Email: "",
  clientcode: 19705, // default

  // Claim
  CaseType: "",
  ClaimNumber: "",
  Insured: "",
  DateOfLoss: "",
  Jurisdiction: "",
  Venue: "",
  AttSecFname: "",
  AttSecLname: "",
  GeicoClaimRepFname: "",
  GeicoClaimRepLname: "",

  // Claimant
  Claimant: false,
  ClaimantFirstName: "",
  ClaimantLastName: "",
  ClaimantAddress1: "",
  ClaimantAddress2: "",
  ClaimantCity: "",
  ClaimantState: "",
  ClaimantZip: "",
  ClaimantEmail: "",
  ClaimantPhone: "",
  ClaimantCellPhone: "",
  ClaimantDateOfBirth: "",
  ClaimantGender: "",
  ClaimantMaritalStatus: "",
  ClaimantSSN: "",
  ClaimantSpecialPhysical: "",

  // Services
  ServicesRequested: [],
  ServiceInstructions: "",

  RequestorOrAttorney: "",
  RepresentedParty: "",
  FileNumber: "",
  ParalegalOrContact: "",
  AdditionalContact: "",
  GlobalInstructions: "",
  DeadlineDate: "",

  // Billing
  IsBillingSameAsRequestor: false,
  BillingContact: "",
  InsuranceCarrier: "Other",
  // NW
  NWCarrier: "",
  ClaimSystem: "",
  BusinessUnit: "",
  LossState: "",
  Subrogation: false,
  AllegedInjuries: "",
  // Broadspire
  ServiceCenter: "",
  BroadspireClient: "",
  BroadspireCarrier: "",
  // Sentry
  RRClaimantLetter: "",
  RRUseCAWCRegs: false,
  // USAA
  RRclaimOffice_us: "",
  RRmemberNumber_us: "",
  RRalisNumber_us: "",
  RRclaimsLossEmail_us: "",
  RRlossReportNumber_us: "",
  RRLegalOffice_us: null,

  Providers: [],

  ContactName: "",
  ClaimType: "",
  doctorcode: "",
  Caption: "",
  County: "",
  DocketNumber: "",
  Court: "",
  Address: "",
  Branch: "",
  Division: "",
  CalendarNumber: "",
  PhysicalProblems: [],

  // Medical Service Instructions
  DateExamNeededBy: "",
  DiscoveryEndDate: "",
  NextDayReport: false,
  Reevaluation: false,
  Litigated: false,
  BoardDirected: false,
  ClaimantVideographerAttending: false,
  NYBoardNumber: "",
  NYDistrictOffice: "",
  RequestedSpecialty: "",

  TaxiService: false,
  InterpreterService: false,
  InterpreterLanguage: "",
  CertifiedMail: false,
  MedicalManagementSpecialInstructions: "",

  // Doctor Information
  DoctorFirstName: "",
  DoctorLastName: "",

  AMAImpairmentRatingLevel: "",

  // Product Selection
  product: "",

  // Additional Counsels
  Counsels: [],

  // SubService
  SubService: "RR",

  Uploads: [],

  marketeremail: "",

  // URL params
  mode: "",
  referrer: "",
  rrId: "",
};

const INITIAL_ADDITIONAL_INFORMATION_STATE = {
  PlaintiffAttorney: false,
  PartOfSubrogation: false,
  DefenseAttorney: false,
  CoDefendant: false,
  Employer: false,
  Insured: false,
  TreatingPhysician: false,
  Adjuster: false,
  Vehicle: false,
};

const INITIAL_EMPLOYER_STATE = {
  Employer: "",
  ContactFirstName: "",
  ContactLastName: "",
  Address1: "",
  Address2: "",
  Zip: "",
  City: "",
  State: "",
  Fax: "",
  Phone: "",
  PhoneExt: "",
  Email: "",
};

const INITIAL_TREATING_PHYSICIAN_STATE = {
  TreatingPhysician: "",
  FirstName: "",
  LastName: "",
  Address1: "",
  Address2: "",
  Zip: "",
  City: "",
  State: "",
  Phone: "",
  PhoneExt: "",
};

const INITIAL_ADJUSTER_INFORMATION_STATE = {
  FirstName: "",
  LastName: "",
  Phone: "",
  Email: "",
  Company: "",
  Office: "",
};

const INITIAL_CLAIM_QUESTIONS_STATE = {
  IWillBeSubmittingMyOwnCoverLetterForThisReferral: false,
  HistoryDiagnosisPrognosis: false,
  TreatmentReasonableAndNecessary: false,
  PresentWorkStatus: false,
  PhysicalCapacityFormFCE: false,
  WorkRestrictions: false,
  LengthOfDisability: false,
  CausalRelationship: false,
  DegreeOfDisability: false,
  PermanentAndStationaryStatus: false,
  Apportionment: false,
  MAndSStatement: false,
  PreexistingConditions: false,
  RadiologistToRead: false,
  EndResult: false,
  HasTheExamineeReachedMMI: false,
  MaximumMedicalImprovement: false,
  PermanentScarring: false,
  AMAImpairmentRating: false,
  ScheduledLossOfUse: false,
  NeedForTreatment: false,
  Permanency: false,
  NeedForSurgery: false,
  Transportation: false,
  AttendantCare: false,
  EssentialServices: false,
  DurableMedicalEquipment: false,
  Prescriptions: false,
};

const INITIAL_SURVEILLANCE_ACTIVITY_CHECK_STATE = {
  SurveillanceAmount: "",
  OtherAmount: "",
  Instructions: "",
  UpcomingAppointment: false,
  Type: "",
  AppointmentDate: "",
  AppointmentTime: "",
  PhysicianName: "",
  Address: "",
  City: "",
  State: "",
  Zip: "",
  TransportationProvided: false,
  SurveillanceInstructions: "",
};

const INITIAL_INVESTIGATIVE_SERVICE_INSTRUCTIONS_STATE = {
  ReceiveReportViaEmail: false,
  RushReferral: false,
  DateReportNeeded: "",
  DiscoveryEndDate: "",
  SoftTissueClaim: false,
  ReferralSentToSIUDept: false,
};

const INITIAL_PLAINTIFF_ATTORNEY_STATE = {
  Prefix: "",
  FirstName: "",
  LastName: "",
  Firm: "",
  Address1: "",
  Address2: "",
  City: "",
  State: "",
  ZipCode: "",
  Extension: "",
  Phone: "",
  Fax: "",
  Email: "",
};

const INITIAL_INSURED_INFORMATION_STATE = {
  address1: "",
  address2: "",
  city: "",
  state: "",
  zip: "",
};

const INITIAL_VEHICLE_INFORMATION_STATE = {
  year: "",
  make: "",
  plateNbr: "",
  color: "",
  model: "",
  state: "",
};

const INITIAL_DEFENSE_ATTORNEY_STATE = {
  Prefix: "",
  FirstName: "",
  LastName: "",
  Firm: "",
  Address1: "",
  Address2: "",
  City: "",
  State: "",
  ZipCode: "",
  Extension: "",
  Phone: "",
  Fax: "",
  Email: "",
};

const INITIAL_NOTES_STATE = [];

export {
  INITIAL_ADDITIONAL_INFORMATION_STATE,
  INITIAL_TREATING_PHYSICIAN_STATE,
  INITIAL_ADJUSTER_INFORMATION_STATE,
  INITIAL_EMPLOYER_STATE,
  INITIAL_CLAIM_QUESTIONS_STATE,
  INITIAL_SURVEILLANCE_ACTIVITY_CHECK_STATE,
  INITIAL_INVESTIGATIVE_SERVICE_INSTRUCTIONS_STATE,
  INITIAL_STATE,
  INITIAL_DEFENSE_ATTORNEY_STATE,
  INITIAL_VEHICLE_INFORMATION_STATE,
  INITIAL_INSURED_INFORMATION_STATE,
  INITIAL_PLAINTIFF_ATTORNEY_STATE,
  INITIAL_PROVIDER_MODEL,
  INITIAL_SUBPOENA_INFO,
  INITIAL_NOTES_STATE,
};
