/* eslint-disable react/no-unescaped-entities */
import React from "react";
import PropTypes from "prop-types";
import { Control, Field, Box, Label } from "rbx";

import { useLsiClient, usePortalPerson } from "../../../../hooks";
import BooleanInput from "../../../../components/BooleanInput";
import CarrierSelect from "../../../../components/CarrierSelect";

import ReferralStyledSubtitle from "../ReferralStyledSubtitle";
import NWForm from "../NWForm";
import BroadspireForm from "../BroadspireForm";
import SentryForm from "../SentryForm";
import USAAForm from "../USAAForm";
import RequestGenerator from "../RequestGenerator";

const BillingInformationForm = ({
  handleChange,
  inputs,
  handleBillingSameAsRequestorChange,
  disabled,
  isUSAA,
}) => {
  const { lsiClient } = useLsiClient();
  const { portalPerson } = usePortalPerson();

  return (
    <Box className="col-span">
      <ReferralStyledSubtitle>
        Billing Information (i.e. Claim Rep, Insurance Company)
      </ReferralStyledSubtitle>
      <Field>
        <CarrierSelect
          required
          disabled={disabled}
          isUSAA={isUSAA}
          label="Carrier"
          name="InsuranceCarrier"
          showBroadspire={
            lsiClient?.CLbsOption === "Y" || portalPerson?.CLbsOption
          }
          showNationwide={lsiClient?.CLnwOption || portalPerson?.CLnwOption}
          showSentry={lsiClient?.CLSentryOption || portalPerson?.CLSentryOption}
          size="small"
          value={inputs.InsuranceCarrier || ""}
          onChange={handleChange}
        />
      </Field>
      <Field>
        <Control>
          <Label>
            Check this box if the billing information is the same as requestor's
          </Label>
          <BooleanInput
            disabled={disabled}
            name="IsBillingSameAsRequestor"
            value={inputs.IsBillingSameAsRequestor}
            onChange={(name, value) => {
              handleBillingSameAsRequestorChange(name, value);
              handleChange(name, value);
            }}
          />
        </Control>
      </Field>
      {!inputs.IsBillingSameAsRequestor && (
        <RequestGenerator
          required
          disabled={disabled}
          handleChange={handleChange}
          inputs={inputs}
          label="Billing Contact"
          type="BillingContact"
        />
      )}
      {inputs.InsuranceCarrier === "Nationwide" && (
        <NWForm
          disabled={disabled}
          handleChange={handleChange}
          inputs={inputs}
        />
      )}
      {inputs.InsuranceCarrier === "Broadspire" && (
        <BroadspireForm
          disabled={disabled}
          handleChange={handleChange}
          inputs={inputs}
        />
      )}
      {inputs.InsuranceCarrier === "Sentry" && (
        <SentryForm
          disabled={disabled}
          handleChange={handleChange}
          inputs={inputs}
        />
      )}
      {inputs?.InsuranceCarrier?.includes("USAA") && (
        <USAAForm
          disabled={disabled}
          handleChange={handleChange}
          inputs={inputs}
        />
      )}
    </Box>
  );
};

BillingInformationForm.propTypes = {
  handleChange: PropTypes.func.isRequired,
  inputs: PropTypes.object.isRequired,
  handleBillingSameAsRequestorChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  isUSAA: PropTypes.object.isRequired,
};

export default BillingInformationForm;
