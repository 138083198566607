/* eslint-disable react/prop-types */
/* eslint-disable-next-line react/prop-types */
import { format } from "date-fns";
import { convertTimeZone } from "../../../../utils";

const COLUMNS = [
  {
    Header: "Code",
    id: "prodcode",
    accessor: "prodcode",
  },
  {
    Header: "Name",
    id: "description",
    accessor: "description",
  },
  {
    Header: "Description",
    id: "longdesc",
    accessor: "longdesc",
  },
  {
    Header: "Status",
    id: "status",
    accessor: "status",
  },
  {
    Header: "Revenue IN GL Account",
    id: "INglacct",
    accessor: "INglacct",
  },
  {
    Header: "Expense VO GL Account",
    id: "VOglacct",
    accessor: "VOglacct",
  },
  {
    Header: "Added",
    id: "dateadded",
    accessor: "dateadded",
    Cell: ({ cell }) =>
      cell?.value
        ? format(convertTimeZone(cell.value), "MM/dd/yyyy hh:mm a")
        : "",
  },
  {
    Header: "By",
    id: "useridadded",
    accessor: "useridadded",
  },
  {
    Header: "Edited",
    id: "dateedited",
    accessor: "dateedited",
    Cell: ({ cell }) =>
      cell?.value
        ? format(convertTimeZone(cell.value), "MM/dd/yyyy hh:mm a")
        : "",
  },
  {
    Header: "By",
    id: "useridedited",
    accessor: "useridedited",
  },
];

export default COLUMNS;
