import React from "react";
import PropTypes from "prop-types";
import { Field, Control, Input, Label } from "rbx";

const UserGroupForm = ({ inputs, onChange, groupcode }) => (
  <div>
    <Field kind="group">
      <Control expanded>
        <Label>Group Code</Label>
        <Input
          autoComplete="new"
          disabled={!!groupcode.length}
          maxLength="15"
          name="groupcode"
          size="small"
          value={groupcode.length ? groupcode : inputs.groupcode}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
      <Control expanded>
        <Label>Description</Label>
        <Input
          autoComplete="new"
          maxLength="50"
          name="groupdesc"
          size="small"
          value={inputs.groupdesc}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
    </Field>
  </div>
);

UserGroupForm.propTypes = {
  inputs: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  groupcode: PropTypes.string,
};

UserGroupForm.defaultProps = {
  groupcode: "",
};

export default UserGroupForm;
