import React, { useState } from "react";
import PropTypes from "prop-types";
import { Column, Control, Input, Label, Button, Field, Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BooleanInput from "../../../components/BooleanInput";

const WebPortal = ({ client, onChange, disabled, handleSave }) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <form id="edit-client-form" onSubmit={handleSave}>
      <Column.Group style={{ justifyContent: "center", marginTop: "1rem" }}>
        <Column
          desktop={{ size: 4 }}
          mobile={{ size: 12 }}
          tablet={{ size: 12 }}
        >
          <header className="page-head">
            <div className="page-head-start">
              <Control>
                <BooleanInput
                  disabled={disabled}
                  label="Allow Web Portal Access"
                  name="PublishOnWeb"
                  value={client.PublishOnWeb}
                  onChange={onChange}
                />
              </Control>
            </div>
            <div className="page-head-end">
              <Control>
                <Button
                  color="success"
                  disabled={disabled}
                  form="edit-client-form"
                  size="small"
                  type="submit"
                >
                  <span>Save</span>
                </Button>
              </Control>
            </div>
          </header>
          {client.PublishOnWeb && (
            <React.Fragment>
              <Field>
                <Control>
                  <Label>Web User Id</Label>
                  <Input
                    autoComplete="new"
                    disabled={disabled}
                    maxLength={100}
                    name="WebUserName"
                    size="small"
                    type="text"
                    value={client.WebUserName}
                    onChange={(e) => onChange(e.target.name, e.target.value)}
                  />
                </Control>
              </Field>
              <Field kind="addons">
                <Control expanded>
                  <Label>Web Password</Label>
                  <Input
                    autoComplete="new-password"
                    disabled={disabled}
                    maxLength={50}
                    name="Webpassword"
                    size="small"
                    type={showPassword ? "text" : "password"}
                    value={client.Webpassword}
                    onChange={(e) => onChange(e.target.name, e.target.value)}
                  />
                </Control>
                <Control>
                  <Label>&nbsp;</Label>
                  <Button
                    className="input"
                    color="info"
                    size="small"
                    tabIndex="-1"
                    type="button"
                    onClick={() => setShowPassword((prev) => !prev)}
                  >
                    <Icon>
                      <FontAwesomeIcon
                        icon={showPassword ? "eye" : "eye-slash"}
                      />
                    </Icon>
                  </Button>
                </Control>
              </Field>
            </React.Fragment>
          )}
        </Column>
      </Column.Group>
    </form>
  );
};

WebPortal.propTypes = {
  client: PropTypes.object,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  handleSave: PropTypes.func.isRequired,
};

WebPortal.defaultProps = {
  client: {},
  onChange: (e) => e,
  disabled: false,
};

export default WebPortal;
