import React, { useEffect } from "react";
import PropTypes from "prop-types";

import { Title, Field, Generic, Button, Control } from "rbx";
import { Link } from "react-router-dom";
import { format, addMinutes, isValid } from "date-fns";

import { useLazyQuery, useQuery } from "@apollo/client";
import { usePermissions, useCaseDoctorLabel } from "../../../../hooks";
import {
  convertTimeZone,
  debounce,
  secureContextNavigatorCopyText,
} from "../../../../utils";
import CaseStatusTag from "../CaseStatusTag";

import CaseStatusSelect from "../CaseStatusSelect";
import ToggleQueueButton from "../../../../components/ToggleQueueButton";

import { SINGLE_CASE_QUERY, SINGLE_EXAMINEE_QUERY } from "../../../../graphql";

const formatDate = (date) =>
  format(addMinutes(date, date.getTimezoneOffset()), "MM/dd/yyyy hh:mm a");

const handleCopyText = debounce(
  (text) => secureContextNavigatorCopyText(text),
  250
);

const CaseHeader = ({
  casenbr,
  inputs,
  loading,
  editing,
  toggleEditing,
  serviceCode,
  statusCode,
  caseTypeCode,
  officeCode,
  executeExceptions,
}) => {
  const { data: CaseData } = useQuery(SINGLE_CASE_QUERY, {
    variables: { where: { casenbr: parseInt(casenbr, 10) } },
    fetchPolicy: "cache-and-network",
  });
  const [getExaminee, { data: ExamineeData }] = useLazyQuery(
    SINGLE_EXAMINEE_QUERY
  );
  const hasPermission = usePermissions([
    "AllAdminFunc",
    "Accounting",
    "Cust Service",
    "QC",
  ]);

  const caseItem = CaseData?.caseItem;
  const chartnbr = caseItem?.chartnbr;

  useEffect(() => {
    if (chartnbr) {
      getExaminee({
        variables: {
          where: {
            chartnbr: parseInt(chartnbr, 10),
          },
        },
      });
    }
  }, [chartnbr, getExaminee]);

  const examinee = ExamineeData?.examinee;
  const doctorLabel = useCaseDoctorLabel(casenbr);

  const displayAppointment =
    caseItem?.Appttime && isValid(new Date(caseItem?.Appttime));

  return (
    <header className="case-head">
      <div className="case-header">
        <div>
          <Title size={4}>
            Case:&nbsp;
            <Generic
              className="copy-button"
              style={{ display: "inline" }}
              tooltip="Copy"
              tooltipPosition="top"
              onClick={() => handleCopyText(casenbr)}
            >
              {casenbr}
            </Generic>
          </Title>
          <CaseStatusTag casenbr={casenbr} />
        </div>

        {hasPermission && (
          <div>
            <Field className="case-header-field-group" kind="group">
              <Control>
                <Field kind="addons">
                  <CaseStatusSelect
                    caseTypeCode={caseTypeCode}
                    casenbr={casenbr}
                    executeExceptions={executeExceptions}
                    officeCode={officeCode}
                    serviceCode={serviceCode}
                    statusCode={statusCode}
                  />
                  <Control>
                    <ToggleQueueButton casenbr={casenbr} />
                  </Control>
                </Field>
              </Control>
              <Button.Group hasAddons>
                <Button
                  color="primary"
                  disabled={loading}
                  size="small"
                  type="button"
                  onClick={toggleEditing}
                >
                  <span>Edit</span>
                </Button>
                <Button
                  color="success"
                  disabled={!editing || loading}
                  form="edit-case-form"
                  size="small"
                  state={loading ? "loading" : ""}
                  type="submit"
                >
                  <span>Save</span>
                </Button>
              </Button.Group>
            </Field>
          </div>
        )}
      </div>
      <div className="case-info">
        {caseItem?.client && (
          <div>
            <p>
              <b>Client</b>
              &nbsp;
              <Link to={`/clients/${caseItem?.clientcode}/profile`}>
                {[caseItem?.client?.firstname, caseItem?.client?.lastname]
                  .filter(Boolean)
                  .join(" ")}
              </Link>
            </p>
          </div>
        )}
        {caseItem?.client?.company && (
          <div>
            <p>
              <b>Company</b>
              &nbsp;
              <Link
                to={`/companies/${caseItem?.client?.company?.companycode}/profile`}
              >
                {caseItem?.client?.company?.intname || ""}
              </Link>
            </p>
          </div>
        )}
        {displayAppointment && (
          <div>
            <p>
              <b>Appt</b>
              &nbsp;
              {formatDate(new Date(caseItem?.Appttime))}
            </p>
          </div>
        )}

        {caseItem?.doctor && (
          <React.Fragment>
            <div>
              <p>
                <b>{doctorLabel}</b>
                &nbsp;
                <Link to={`/doctors/${caseItem?.doctor?.doctorcode}/profile`}>
                  {[
                    caseItem?.doctor?.firstname,
                    caseItem?.doctor?.lastname,
                    caseItem?.doctor?.credentials,
                  ]
                    .filter(Boolean)
                    .join(" ")}
                </Link>
              </p>
            </div>
            <div>
              <p>
                <b>Specialty</b>
                &nbsp;
                {caseItem?.doctorspecialty || ""}
              </p>
            </div>
            <div>
              <p>
                <b>
                  {doctorLabel}
                  &nbsp;Phone
                </b>
                &nbsp;
                <a href={`tel: ${caseItem?.doctor?.phone || ""}`}>
                  {caseItem?.doctor?.phone || ""}
                </a>
              </p>
            </div>
            <div>
              <p>
                <b>Location</b>
                &nbsp;
                {caseItem?.location?.location || ""}
              </p>
            </div>
          </React.Fragment>
        )}
        {examinee?.firstname && (
          <div>
            <p>
              <b>Examinee</b>
              &nbsp;
              {[examinee?.firstname, examinee?.lastname]
                .filter(Boolean)
                .join(" ")}
            </p>
          </div>
        )}

        {caseItem?.scheduler?.firstname && (
          <div>
            <p>
              <b>Scheduler</b>
              &nbsp;
              <span>
                {[caseItem?.scheduler?.firstname, caseItem?.scheduler?.lastname]
                  .filter(Boolean)
                  .join(" ")}
              </span>
            </p>
          </div>
        )}
        {caseItem?.QAReporter && (
          <div>
            <p>
              <b>QA Rep</b>
              &nbsp;
              <span>
                {[
                  caseItem?.QAReporter?.firstname,
                  caseItem?.QAReporter?.lastname,
                ]
                  .filter(Boolean)
                  .join(" ")}
              </span>
            </p>
          </div>
        )}
        {caseItem?.caseType && (
          <div>
            <p>
              <b>Case Type</b>
              &nbsp;
              <span>{caseItem?.caseType?.description}</span>
            </p>
          </div>
        )}
        {caseItem?.service && (
          <div>
            <p>
              <b>Service</b>
              &nbsp;
              <span>{caseItem?.service?.description}</span>
            </p>
          </div>
        )}
        {inputs?.company?.companyToCompanyGroup?.CompanyGroupID === 5010 && (
          <div>
            <p>
              <b>Processor</b>
              &nbsp;
              <span>
                {[
                  caseItem?.AllstateProcessor?.firstname,
                  caseItem?.AllstateProcessor?.lastname,
                ]
                  .filter(Boolean)
                  .join(" ")}
              </span>
            </p>
          </div>
        )}
        {caseItem?.commitdate && (
          <div>
            <p>
              <b>Commit Date</b>
              &nbsp;
              <span>
                {format(convertTimeZone(caseItem?.commitdate), "MM/dd/yyyy")}
              </span>
            </p>
          </div>
        )}
      </div>
    </header>
  );
};

CaseHeader.propTypes = {
  casenbr: PropTypes.string.isRequired,
  inputs: PropTypes.object,
  loading: PropTypes.bool,
  editing: PropTypes.bool,
  toggleEditing: PropTypes.func,
  serviceCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  statusCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  caseTypeCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  officeCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  executeExceptions: PropTypes.func,
};

CaseHeader.defaultProps = {
  inputs: {},
  loading: false,
  editing: false,
  serviceCode: "",
  statusCode: "",
  caseTypeCode: "",
  officeCode: "",
  executeExceptions: () => {},
  toggleEditing: () => {},
};

export default CaseHeader;
