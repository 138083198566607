import React, { useState, useEffect } from "react";
import { format, isSameDay, differenceInCalendarDays } from "date-fns";
import { useLazyQuery, useMutation, useApolloClient } from "@apollo/client";
import {
  useParams,
  useLocation,
  Switch,
  Route,
  Prompt,
} from "react-router-dom";

import { useAuth, useModal } from "../../context";
import {
  UPDATE_EXAMINEE_MUTATION,
  SINGLE_EXAMINEE_QUERY,
  UPDATE_CASE_MUTATION,
  SINGLE_CASE_QUERY,
  CREATE_EXAMINEE_MUTATION,
  CREATE_CASE_HISTORY_MUTATION,
  ALL_CASE_HISTORY_QUERY,
  SINGLE_QUEUE_QUERY,
  FIND_FIRST_USER_QUERY,
  SINGLE_CLIENT_QUERY,
  SINGLE_COMPANY_QUERY,
  SINGLE_CC_ADDRESS_QUERY,
} from "../../graphql";
import {
  getDaysDiffBetweenDates,
  customToast as toast,
  formatSSNumber,
  getDateEST,
  convertTimeZoneDataBase,
  convertTimeZone,
  capitalizeEveryWord,
  runPromisesInSeries,
} from "../../utils";

import {
  Profile,
  Examinee,
  CaseParameters,
  CaseHistory,
  Accounting,
  Scheduling,
  Attorney,
  Documents,
  WebPortal,
  UserFields,
  Reports,
  ApolloNotes,
  AssociatedCases,
} from "./routes";

import { CaseTabs, CaseHeader, CaseCancellationModal } from "./components";
import Loader from "../../components/Loader";

import "./Case.scss";
import {
  useWebReferral,
  useSaveConflict,
  useCaseExceptions,
} from "../../hooks";
import Confirmation from "../../components/Confirmation";

const INITIAL_STATE = {
  editedExaminee: false,
  edited: false,
  casenbr: "",
  priority: "Normal",
  doctorspecialty: "",
  DoctorName: "",
  ApptDate: "",
  Appttime: "",
  commitdate: "",
  caseItem: "",
  chartnbr: "",
  marketercode: "",
  schedulercode: "",
  QARep: "",
  SelectedAllstateProcessor: "",
  caseTypeCode: "",
  serviceCode: "",
  statusCode: "",
  Jurisdiction: "",
  client: "",
  officeCode: "",
  IMECompany: "",
  doctor: "",
  serviceQueue: "",
  examinee: "",
  company: "",
  location: "",
  serviceQueues: "",
  QAReporter: "",
  AllstateProcessor: "",
  scheduler: "",

  // Accounting Third Party Form
  billclientcode: "",
  billcompany: "",
  billcontact: "",
  billaddr1: "",
  billaddr2: "",
  billcity: "",
  billstate: "",
  billzip: "",
  billfax: "",
  billARKey: "",

  marketer: "",
  TreatingPhysician: "",
  // Case Parameters
  ExternalDueDate: "",
  InternalDueDate: "",
  DateReceived: "",
  requesteddoc: "",
  calledinby: "",
  dateofinjury: "",
  datemedsrecd: "",
  typemedsrecd: "",
  reccode: "",
  claimnbr: "",
  WCBNbr: "",
  HearingDate: "",
  sreqspecialty: "",
  sreqdegree: "",
  sinternalcasenbr: "",
  icd9code: "",
  ICD9Code2: "",
  ICD9Code3: "",
  ICD9Code4: "",
  CertMailNbr: "",
  specialinstructions: "",
  notes: "",
  referraltype: "",
  referralmethod: "",
  IMEMDName: "",
  // Documents defaults
  emailclient: false,
  emaildoctor: false,
  emailPattny: false,
  faxclient: false,
  faxdoctor: false,
  faxPattny: false,
  reportverbal: false,
  bhanddelivery: false,
  CertifiedMail: false,

  // Attorney / CCs
  plaintiffattorneycode: "",
  defenseattorneycode: "",
  AttorneyNote: "",
  // User Fields
  usdvarchar1: "",
  usdvarchar2: "",
  bComplete: false,
  usdint1: "",
  usdint2: "",
  usdmoney1: "",
  usdmoney2: "",
  usddate1: "",
  usddate2: "",
  usddate3: "",
  usddate4: "",
  usdtext1: "",
  usdtext2: "",
  SalesCreditUserID: "",
  DoctorWasRequestedByClient: false,
  // Accounting
  BillingNote: "",
  // Reports
  rptstatus: "",
  schedulenotes: "",
};

const INITIAL_EXAMINEE_STATE = {
  chartnbr: "",
  firstname: "",
  lastname: "",
  middleinitial: "",
  prefix: "",
  addr1: "",
  addr2: "",
  city: "",
  state: "",
  zip: "",
  county: "",
  Country: "",
  phone1: "",
  phone2: "",
  sex: "",
  DOB: "",
  SSN: "",
  fax: "",
  email: "",
  note: "",
  insured: "",
  InsuredAddr1: "",
  InsuredCity: "",
  InsuredState: "",
  InsuredZip: "",
  InsuredPhone: "",
  InsuredPhoneExt: "",
  InsuredFax: "",
  InsuredEmail: "",
  policynumber: "",
  employer: "",
  EmployerAddr1: "",
  EmployerCity: "",
  EmployerState: "",
  EmployerZip: "",
  EmployerPhone: "",
  EmployerPhoneExt: "",
  EmployerFax: "",
  EmployerEmail: "",
  EmployerContactFirstName: "",
  EmployerContactLastName: "",
  treatingphysician: "",
  TreatingPhysicianAddr1: "",
  TreatingPhysicianCity: "",
  TreatingPhysicianState: "",
  TreatingPhysicianZip: "",
  TreatingPhysicianEmail: "",
  TreatingPhysicianFax: "",
  TreatingPhysicianLicenseNbr: "",
  TreatingPhysicianPhone: "",
  TreatingPhysicianPhoneExt: "",
  TreatingPhysicianTaxID: "",
};

class CaseItemError extends Error { }

const convertInputToVariables = (variables, keys, adding = false) =>
  keys.reduce((acc, curr) => {
    if (variables[curr]) {
      acc[curr] = adding ? variables[curr] : { set: variables[curr] };
    } else {
      acc[curr] = adding ? null : { set: null };
    }
    return acc;
  }, {});

const CasePage = () => {
  const [createCaseHistory] = useMutation(CREATE_CASE_HISTORY_MUTATION);
  const { casenbr: rrCaseNbr, workid } = useParams();
  const client = useApolloClient();
  const { state: authState } = useAuth();
  const { setModalOpen } = useModal();

  const [inputs, setInputs] = useState(INITIAL_STATE);
  const [examineeInputs, setExamineeInputs] = useState(INITIAL_EXAMINEE_STATE);
  const [editMode, setEditMode] = useState(false);
  const [originalRptStatus, setOriginalRptStatus] = useState("");

  const [getCaseData, resultCase] = useLazyQuery(SINGLE_CASE_QUERY);
  const [getExamineeData, resultExaminee] = useLazyQuery(SINGLE_EXAMINEE_QUERY);
  const [updateCase] = useMutation(UPDATE_CASE_MUTATION);
  const [updateExaminee] = useMutation(UPDATE_EXAMINEE_MUTATION);
  const [createExaminee] = useMutation(CREATE_EXAMINEE_MUTATION);

  const [loading, setLoading] = useState(false);
  const location = useLocation();

  const casenbr = rrCaseNbr || workid;

  const checkForSaveConflict = useSaveConflict({
    casenbr,
    lastuseridedited: inputs.useridedited,
    lastdateedited: inputs.dateedited,
  });

  const executeExceptions = useCaseExceptions(casenbr);

  const handleCreateCaseHistory = async () => {
    const date = convertTimeZoneDataBase(getDateEST());
    const {
      data: { caseItem },
    } = resultCase;
    const action = capitalizeEveryWord(
      location.pathname
        .substr(location.pathname.lastIndexOf("/") + 1)
        .replace(/-/gi, " ")
    );

    const refetchQueries = [
      {
        query: ALL_CASE_HISTORY_QUERY,
        variables: {
          where: { casenbr: { equals: parseInt(casenbr, 10) } },
        },
      },
      {
        query: SINGLE_CASE_QUERY,
        variables: {
          where: { casenbr: parseInt(casenbr, 10) },
        },
      },
    ];

    const caseHistoryUpdates = [
      (rfq) =>
        createCaseHistory({
          variables: {
            data: {
              casenbr: parseInt(casenbr, 10),
              eventdate: date,
              eventdesc: `${action} Updated`,
              otherinfo: `${action} updated from Apollo at ${format(
                convertTimeZone(date),
                "MM/dd/yyyy hh:mm a"
              )}`,
              PublishOnWeb: false,
              userid: authState?.user?.userid,
              useridedited: authState?.user?.userid,
              dateedited: date,
              dateadded: date,
            },
          },
          refetchQueries: rfq,
        }),
    ];

    if (
      caseItem?.status &&
      inputs.statusCode &&
      parseInt(caseItem?.status, 10) !== parseInt(inputs.statusCode, 10)
    ) {
      const { data: NewQueueData } = await client.query({
        query: SINGLE_QUEUE_QUERY,
        variables: {
          where: { statuscode: parseInt(inputs.statusCode, 10) },
        },
      });

      const { data: OriginalQueueData } = await client.query({
        query: SINGLE_QUEUE_QUERY,
        variables: {
          where: { statuscode: parseInt(caseItem?.status, 10) },
        },
      });

      await updateCase({
        variables: {
          where: {
            casenbr: parseInt(casenbr, 10),
          },
          data: {
            CaseCompletedDate: {
              set: NewQueueData?.queue?.statusdesc === "Complete" ? date : null,
            },
            laststatuschg: {
              set: date,
            },
          },
        },
        refetchQueries: [
          {
            query: SINGLE_CASE_QUERY,
            variables: {
              where: { casenbr: parseInt(casenbr, 10) },
            },
          },
        ],
      });

      caseHistoryUpdates.push((rfq) => {
        const duration = caseItem?.laststatuschg
          ? differenceInCalendarDays(
            date,
            convertTimeZone(new Date(caseItem.laststatuschg))
          )
          : null;

        return createCaseHistory({
          variables: {
            data: {
              casenbr: parseInt(casenbr, 10),
              eventdesc: `Status Chg New Status - ${NewQueueData.queue.shortdesc || ""
                }`,
              otherinfo: [
                OriginalQueueData?.queue?.statusdesc &&
                `Old - ${OriginalQueueData.queue.statusdesc}`,
                NewQueueData?.queue?.statusdesc &&
                `New - ${NewQueueData.queue.statusdesc}`,
              ]
                .filter(Boolean)
                .join(" "),
              eventdate: date,
              userid: authState?.user?.userid,
              useridedited: authState?.user?.userid,
              dateedited: date,
              dateadded: date,
              type: "StatChg",
              duration,
              status: parseInt(inputs.statusCode, 10),
            },
          },
          refetchQueries: rfq,
        });
      });

      if (NewQueueData?.queue?.statusdesc === "Cancelled") {
        const Appttime = caseItem?.Appttime;

        const daysDiff = Appttime
          ? getDaysDiffBetweenDates(new Date(), convertTimeZone(Appttime))
          : Number.MAX_SAFE_INTEGER;

        const isLateCancel = daysDiff < 1;

        setModalOpen(
          true,
          <CaseCancellationModal
            casenbr={casenbr}
            isLateCancel={isLateCancel}
            onComplete={async (shouldUpdate) => {
              setModalOpen(false, "");
            }}
          />
        );
      }
    }

    if ((inputs.QARep || caseItem.QARep) && inputs.QARep !== caseItem.QARep) {
      caseHistoryUpdates.push((rfq) =>
        createCaseHistory({
          variables: {
            data: {
              casenbr: parseInt(casenbr, 10),
              eventdesc: `QA Rep Change`,
              otherinfo: [
                caseItem.QARep && `Old - ${caseItem.QARep}`,
                inputs.QARep && `New - ${inputs.QARep}`,
              ]
                .filter(Boolean)
                .join(" "),
              eventdate: date,
              userid: authState?.user?.userid,
              dateadded: date,
              useridedited: authState?.user?.userid,
              dateedited: date,
              type: "QA Req Change",
            },
          },
          refetchQueries: rfq,
        })
      );
    }

    if (
      (inputs.AllstateProcessor || caseItem.AllstateProcessor) &&
      inputs.AllstateProcessor !== caseItem.AllstateProcessor
    ) {
      caseHistoryUpdates.push((rfq) =>
        createCaseHistory({
          variables: {
            data: {
              casenbr: parseInt(casenbr, 10),
              eventdesc: `Allstate Processor Change`,
              otherinfo: [
                caseItem.AllstateProcessor &&
                `Old - ${caseItem.AllstateProcessor}`,
                inputs.AllstateProcessor && `New - ${inputs.AllstateProcessor}`,
              ]
                .filter(Boolean)
                .join(" "),
              eventdate: date,
              userid: authState?.user?.userid,
              dateadded: date,
              useridedited: authState?.user?.userid,
              dateedited: date,
              type: "Allstate Processor Change",
            },
          },
          refetchQueries: rfq,
        })
      );
    }

    if (inputs.schedulercode !== caseItem?.schedulercode) {
      caseHistoryUpdates.push((rfq) =>
        createCaseHistory({
          variables: {
            data: {
              casenbr: parseInt(casenbr, 10),
              eventdesc: `Scheduler Change`,
              otherinfo: `Old Scheduler - ${caseItem.schedulercode || "Unassigned"
                } New - ${inputs.schedulercode || "Unassigned"}`,
              eventdate: date,
              userid: authState?.user?.userid,
              useridedited: authState?.user?.userid,
              dateedited: date,
              dateadded: date,
              type: "Scheduler Change",
            },
          },
          refetchQueries: rfq,
        })
      );
    }

    if (
      (caseItem?.commitdate || inputs?.commitdate) &&
      !isSameDay(new Date(inputs.commitdate), new Date(caseItem.commitdate))
    ) {
      caseHistoryUpdates.push((rfq) =>
        createCaseHistory({
          variables: {
            data: {
              casenbr: parseInt(casenbr, 10),
              eventdesc: `Commit Date Change to ${inputs?.commitdate
                  ? format(convertTimeZone(inputs.commitdate), "MM/dd/yyyy")
                  : "null"
                }`,
              otherinfo: `Old - ${caseItem?.commitdate
                  ? format(convertTimeZone(caseItem.commitdate), "MM/dd/yyyy")
                  : "null"
                }`,
              eventdate: date,
              userid: authState?.user?.userid,
              dateadded: date,
              useridedited: authState?.user?.userid,
              dateedited: date,
              type: "StatChg",
            },
          },
          refetchQueries: rfq,
        })
      );
    }
    if (
      inputs.sreqspecialty !== caseItem?.sreqspecialty &&
      inputs.sreqspecialty
    ) {
      caseHistoryUpdates.push((rfq) =>
        createCaseHistory({
          variables: {
            data: {
              casenbr: parseInt(casenbr, 10),
              eventdesc: `Req Spec Change to ${inputs.sreqspecialty}`,
              otherinfo: `Old - ${caseItem?.sreqspecialty}`,
              eventdate: date,
              userid: authState?.user?.userid,
              dateadded: date,
              useridedited: authState?.user?.userid,
              dateedited: date,
              type: "Req Spec Change",
            },
          },
          refetchQueries: rfq,
        })
      );
    }

    if (inputs.marketercode !== caseItem?.marketercode) {
      const {
        data: { findFirstUser },
      } = await client.query({
        query: FIND_FIRST_USER_QUERY,
        variables: {
          where: {
            userid: { equals: inputs.marketercode },
          },
        },
      });

      if (findFirstUser) {
        caseHistoryUpdates.push((rfq) =>
          createCaseHistory({
            variables: {
              data: {
                casenbr: parseInt(casenbr, 10),
                eventdesc: `Marketer Change`,
                otherinfo: `Old - ${caseItem.marketercode || "Unassigned"
                  } New - ${inputs.marketercode || "Unassigned"}`,
                eventdate: date,
                userid: authState?.user?.userid,
                dateadded: date,
                useridedited: authState?.user?.userid,
                dateedited: date,
                type: "Marketer Change",
              },
            },
            refetchQueries: rfq,
          })
        );
      }
    }

    if (
      inputs.client &&
      parseInt(caseItem?.client?.clientcode, 10) !== parseInt(inputs.client, 10)
    ) {
      const oldClientData =
        !!caseItem?.client?.clientcode &&
        (await client.query({
          query: SINGLE_CLIENT_QUERY,
          variables: {
            where: {
              clientcode: parseInt(caseItem?.client?.clientcode, 10),
            },
          },
        }));

      const {
        data: { client: newClientData },
      } = await client.query({
        query: SINGLE_CLIENT_QUERY,
        variables: {
          where: {
            clientcode: parseInt(inputs.client, 10),
          },
        },
      });

      caseHistoryUpdates.push((rfq) =>
        createCaseHistory({
          variables: {
            data: {
              casenbr: parseInt(casenbr, 10),
              eventdesc: `Client Change`,
              otherinfo: `Old - ${oldClientData && oldClientData?.data?.client
                  ? `${oldClientData?.data?.client.lastname || ""}, ${oldClientData?.data?.client.firstname || ""
                    }`.replace(/\s\s+/g, " ")
                  : "None"
                } New - ${newClientData.firstname && newClientData.lastname
                  ? `${newClientData.lastname}, ${newClientData.firstname}`
                  : "None"
                }`,
              eventdate: date,
              userid: authState?.user?.userid,
              dateadded: date,
              useridedited: authState?.user?.userid,
              dateedited: date,
              type: "Client Chg",
            },
          },
          refetchQueries: rfq,
        })
      );
    }

    const getCcAddressDescription = (cCAddress) =>
      `${cCAddress.company || "N/A"} - ${cCAddress.firstname && cCAddress.lastname
        ? `${cCAddress.firstname} ${cCAddress.lastname}`
        : "N/A"
      } - ${cCAddress.address1 || "N/A"}`;

    if (
      inputs.plaintiffattorneycode &&
      parseInt(caseItem?.plaintiffattorneycode, 10) !==
      parseInt(inputs.plaintiffattorneycode, 10)
    ) {
      const oldPlaintiffData =
        !!caseItem.plaintiffattorneycode &&
        (await client.query({
          query: SINGLE_CC_ADDRESS_QUERY,
          variables: {
            where: {
              cccode: parseInt(caseItem.plaintiffattorneycode, 10),
            },
          },
        }));

      const {
        data: { cCAddress: newPlaintiffData },
      } = await client.query({
        query: SINGLE_CC_ADDRESS_QUERY,
        variables: {
          where: {
            cccode: parseInt(inputs.plaintiffattorneycode, 10),
          },
        },
      });

      caseHistoryUpdates.push((rfq) =>
        createCaseHistory({
          variables: {
            data: {
              casenbr: parseInt(casenbr, 10),
              eventdesc: "Plaintiff Attorney Change",
              otherinfo: `Old - ${oldPlaintiffData
                  ? getCcAddressDescription(oldPlaintiffData.data.cCAddress)
                  : "None"
                } New - ${getCcAddressDescription(newPlaintiffData)}`.replace(
                  /\s\s+/g,
                  " "
                ),
              eventdate: date,
              userid: authState?.user?.userid,
              dateadded: date,
              useridedited: authState?.user?.userid,
              dateedited: date,
              type: "Atty Chg",
            },
          },
          refetchQueries: rfq,
        })
      );
    }

    if (
      inputs.defenseattorneycode &&
      parseInt(caseItem?.defenseattorneycode, 10) !==
      parseInt(inputs.defenseattorneycode, 10)
    ) {
      const oldDefenseData =
        !!caseItem.defenseattorneycode &&
        (await client.query({
          query: SINGLE_CC_ADDRESS_QUERY,
          variables: {
            where: {
              cccode: parseInt(caseItem.defenseattorneycode, 10),
            },
          },
        }));

      const {
        data: { cCAddress: newDefenseData },
      } = await client.query({
        query: SINGLE_CC_ADDRESS_QUERY,
        variables: {
          where: {
            cccode: parseInt(inputs.defenseattorneycode, 10),
          },
        },
      });

      caseHistoryUpdates.push((rfq) =>
        createCaseHistory({
          variables: {
            data: {
              casenbr: parseInt(casenbr, 10),
              eventdesc: "Defense Attorney Change",
              otherinfo: `Old - ${oldDefenseData
                  ? getCcAddressDescription(oldDefenseData.data.cCAddress)
                  : "None"
                } New - ${getCcAddressDescription(newDefenseData)}`.replace(
                  /\s\s+/g,
                  " "
                ),
              eventdate: date,
              userid: authState?.user?.userid,
              dateadded: date,
              useridedited: authState?.user?.userid,
              dateedited: date,
              type: "Atty Chg",
            },
          },
          refetchQueries: rfq,
        })
      );
    }

    await runPromisesInSeries(
      caseHistoryUpdates.map((x, i) =>
        x(i === caseHistoryUpdates.length - 1 ? refetchQueries : [])
      )
    );
  };

  const handleUpdate = async (e) => {
    try {
      if (location.pathname.includes("case-parameters")) {
        if (!inputs.sreqspecialty) {
          throw new CaseItemError("Requested Specialty is required.");
        }
      }
      // Set of inputs to ClientUpdateInput
      const obj = Object.keys(INITIAL_STATE).reduce((acc, curr) => {
        if (inputs[curr] !== null) {
          const value = Array.isArray(inputs[curr])
            ? inputs[curr][1]
            : inputs[curr];
          return {
            ...acc,
            [curr]: {
              set: typeof value === "object" ? value.value : value,
            },
          };
        }
        return acc;
      }, {});
      // Clear Object
      const cleanObject = Object.fromEntries(
        Object.entries(obj).filter(([_, v]) => v.set !== "")
      );
      // We don't need this properties to be updated, but we still need it on Initial State
      [
        "casenbr",
        "edited",
        "editedExaminee",
        "chartnbr",
        "status",
        "company",
        "serviceQueues",
        "marketer",
        "scheduler",
        "QAReporter",
        "AllstateProcessor",
        "serviceQueue",
        "defenseattorneycode",
        "plaintiffattorneycode",
        "statusCode",
        "caseTypeCode",
        "officeCode",
        "serviceCode",
      ].forEach((prop) => {
        delete cleanObject[prop];
      });

      const variablesConnect = {};
      if (inputs?.doctorcode) {
        variablesConnect.doctor = {
          connect: {
            doctorcode: parseInt(inputs.doctorcode, 10),
          },
        };
      }
      const prevStatus = resultCase?.data?.caseItem?.Status?.statuscode || null;
      const rptstatusObj = {};

      const date = convertTimeZoneDataBase(getDateEST());

      if (inputs.rptstatus !== originalRptStatus) {
        rptstatusObj.rptstatus = { set: inputs.rptstatus };
        if (inputs.rptstatus === "Final") {
          rptstatusObj.FinalizedRptDate = { set: date };
        }
      }

      await updateCase({
        variables: {
          where: {
            casenbr: parseInt(casenbr, 10),
          },
          data: {
            ...cleanObject,
            ...variablesConnect,
            ...rptstatusObj,
            claimnbr: {
              set:
                typeof inputs.claimnbr === "string"
                  ? inputs.claimnbr.trim()
                  : inputs.claim,
            },
            SelectedAllstateProcessor: {
              set: inputs.SelectedAllstateProcessor
                ? inputs.SelectedAllstateProcessor
                : null,
            },
            commitdate: { set: inputs.commitdate || null },
            priority: { set: inputs.priority },
            // these can be empty
            billclientcode: { set: inputs.billclientcode || null },
            billcompany: { set: inputs.billcompany || null },
            billcontact: { set: inputs.billcontact },
            billfax: { set: inputs.billfax },
            billaddr1: { set: inputs.billaddr1 },
            billaddr2: { set: inputs.billaddr2 },
            billcity: { set: inputs.billcity },
            billstate: { set: inputs.billstate },
            billzip: { set: inputs.billzip },
            billARKey: { set: inputs.billARKey },
            BillingNote: { set: inputs.BillingNote },
            // user defined fields
            usdtext1: { set: inputs.usdtext1 },
            usdtext2: { set: inputs.usdtext2 },
            usdvarchar1: { set: inputs.usdvarchar1 },
            usdvarchar2: { set: inputs.usdvarchar2 },
            SalesCreditUserID: { set: inputs.SalesCreditUserID || null },
            specialinstructions: {
              set:
                typeof inputs.specialinstructions === "string"
                  ? inputs.specialinstructions
                    .replace(/\r\n/g, "\n")
                    .replace(/\n/g, "\r\n")
                  : null,
            },
            notes: {
              set:
                typeof inputs.notes === "string"
                  ? inputs.notes.replace(/\r\n/g, "\n").replace(/\n/g, "\r\n")
                  : null,
            },
            DateReceived: { set: convertTimeZoneDataBase(inputs.DateReceived) },
            plaintiffattorneycode: {
              set: parseInt(inputs.plaintiffattorneycode, 10) || null,
            },
            defenseattorneycode: {
              set: parseInt(inputs.defenseattorneycode, 10) || null,
            },
            caseType: inputs?.caseTypeCode
              ? {
                connect: {
                  code: parseInt(inputs.caseTypeCode, 10),
                },
              }
              : null,
            service: inputs?.serviceCode
              ? {
                connect: {
                  servicecode: parseInt(inputs.serviceCode, 10),
                },
              }
              : undefined,
            Status: inputs?.statusCode
              ? {
                connect: {
                  statuscode: parseInt(inputs.statusCode, 10),
                },
              }
              : undefined,
            client: cleanObject?.client?.set
              ? {
                connect: {
                  clientcode: parseInt(cleanObject.client.set, 10),
                },
              }
              : null,
            office: inputs?.officeCode
              ? {
                connect: {
                  officecode: parseInt(inputs.officeCode, 10),
                },
              }
              : null,
            IMECompany: cleanObject?.IMECompany?.set
              ? { set: cleanObject.IMECompany.set.toString() }
              : null,
            referraltype: cleanObject?.referraltype?.set
              ? {
                set: parseInt(cleanObject.referraltype.set, 10),
              }
              : null,
            referralmethod: cleanObject?.referralmethod?.set
              ? {
                set: parseInt(cleanObject.referralmethod.set, 10),
              }
              : null,
            usdint1: cleanObject?.usdint1?.set
              ? {
                set: parseInt(cleanObject.usdint1.set, 10),
              }
              : null,
            usdint2: cleanObject?.usdint2?.set
              ? {
                set: parseFloat(
                  parseFloat(cleanObject.usdint2.set, 10).toFixed(2)
                ),
              }
              : null,
            usdmoney1: cleanObject?.usdmoney1?.set
              ? {
                set: parseFloat(cleanObject.usdmoney1.set, 10),
              }
              : null,
            usdmoney2: cleanObject?.usdmoney2?.set
              ? {
                set: parseFloat(cleanObject.usdmoney2.set, 10),
              }
              : null,
            useridedited: { set: authState?.user?.userid },
            dateedited: { set: date },
          },
        },
        refetchQueries: [
          {
            query: SINGLE_CASE_QUERY,
            variables: {
              where: { casenbr: parseInt(casenbr, 10) },
            },
          },
        ],
      });
      if (prevStatus) {
        executeExceptions(prevStatus, inputs.statusCode);
      }
      setInputs((prev) => ({
        ...prev,
        edited: false,
      }));
      toast.success("Case updated successfully.");
    } catch (err) {
      const message =
        err instanceof CaseItemError ? err.message : "Error updating Case.";
      toast.error(message);
    }
  };

  const handleExamineeSave = async () => {
    try {
      const obj = convertInputToVariables(
        examineeInputs,
        Object.keys(INITIAL_EXAMINEE_STATE),
        !inputs?.chartnbr
      );

      delete obj.edited;
      delete obj.editedExaminee;
      delete obj.chartnbr;
      delete obj.TreatingPhysician;
      if (inputs?.chartnbr) {
        if (obj.SSN?.set) {
          const [isValid] = formatSSNumber(obj.SSN.set);
          if (!isValid) {
            throw new CaseItemError("SSN Number is not valid");
          }
        }
        await updateCase({
          variables: {
            where: {
              casenbr: parseInt(casenbr, 10),
            },
            data: {
              examinee: {
                connect: {
                  chartnbr: parseInt(inputs.chartnbr, 10),
                },
              },
              TreatingPhysician: inputs?.TreatingPhysician
                ? { set: parseInt(inputs.TreatingPhysician, 10) }
                : null,
            },
          },
        });
        await updateExaminee({
          variables: {
            where: {
              chartnbr: examineeInputs.chartnbr,
            },
            data: obj,
          },
          refetchQueries: [
            {
              query: SINGLE_EXAMINEE_QUERY,
              variables: {
                where: { chartnbr: parseInt(inputs?.chartnbr, 10) },
              },
            },
          ],
        });
      } else {
        if (obj.SSN) {
          const [isValid] = formatSSNumber(obj.SSN);
          if (!isValid) {
            throw new CaseItemError("SSN Number is not valid");
          }
        }
        const { data: createExamineeData } = await createExaminee({
          variables: {
            data: obj,
          },
        });
        if (createExamineeData?.createExaminee?.chartnbr) {
          await updateCase({
            variables: {
              where: {
                casenbr: parseInt(inputs?.casenbr, 10),
              },
              data: {
                examinee: {
                  connect: {
                    chartnbr: parseInt(
                      createExamineeData?.createExaminee?.chartnbr,
                      10
                    ),
                  },
                },
              },
            },
            refetchQueries: [
              {
                query: SINGLE_CASE_QUERY,
                variables: {
                  where: { casenbr: parseInt(casenbr, 10) },
                },
              },
              {
                query: SINGLE_EXAMINEE_QUERY,
                variables: {
                  where: {
                    chartnbr: parseInt(
                      createExamineeData?.createExaminee?.chartnbr,
                      10
                    ),
                  },
                },
              },
            ],
          });
        }
      }
      setInputs((prev) => ({
        ...prev,
        editedExaminee: false,
      }));
    } catch (err) {
      const message =
        err instanceof CaseItemError ? err.message : "Error updating Case.";
      toast.error(message);
    }
  };

  useEffect(() => {
    if (casenbr && casenbr !== "add") {
      getCaseData({
        variables: {
          where: { casenbr: parseInt(casenbr, 10) },
        },
        fetchPolicy: "network-only",
      });
    } else {
      setEditMode(true);
    }
  }, [casenbr, getCaseData]);

  useEffect(() => {
    const caseItem = resultCase?.data?.caseItem;
    if (caseItem) {
      const cleanObject = Object.fromEntries(
        Object.entries(caseItem).filter(([_, v]) => v !== null)
      );
      setOriginalRptStatus(caseItem.rptstatus);
      setInputs((prev) => ({
        ...prev,
        ...cleanObject,
        company: caseItem?.client?.company,
        client: caseItem.client?.clientcode,
        priority: caseItem.priority,
        officeCode: caseItem.office?.officecode || "",
        commitdate: caseItem.commitdate
          ? new Date(caseItem.commitdate).toISOString().slice(0, 10)
          : "",
        marketercode: caseItem.marketercode || "",
        schedulercode: caseItem.schedulercode || "",
        QARep: caseItem.QARep || "",
        serviceCode: caseItem?.service?.servicecode || "",
        statusCode: caseItem?.Status?.statuscode || "",
        Jurisdiction: caseItem?.Jurisdiction || "",
        doctorcode: caseItem?.doctor?.doctorcode || "",
        caseTypeCode: caseItem.caseType?.code || "",
        DateReceived: caseItem.DateReceived
          ? new Date(caseItem.DateReceived).toISOString().slice(0, 16)
          : "",
        datemedsrecd: caseItem.datemedsrecd
          ? new Date(caseItem.datemedsrecd).toISOString().slice(0, 10)
          : "",
        ExternalDueDate: caseItem.ExternalDueDate
          ? new Date(caseItem.ExternalDueDate).toISOString().slice(0, 10)
          : "",
        InternalDueDate: cleanObject.InternalDueDate
          ? new Date(caseItem.InternalDueDate).toISOString().slice(0, 10)
          : "",
        dateofinjury: cleanObject.dateofinjury
          ? new Date(caseItem.dateofinjury).toISOString().slice(0, 10)
          : "",
        HearingDate: cleanObject.HearingDate
          ? new Date(caseItem.HearingDate).toISOString().slice(0, 10)
          : "",
        usddate1: cleanObject.usddate1
          ? new Date(caseItem.usddate1).toISOString().slice(0, 10)
          : "",
        usddate2: cleanObject.usddate2
          ? new Date(caseItem.usddate2).toISOString().slice(0, 10)
          : "",
        usddate3: cleanObject.usddate3
          ? new Date(caseItem.usddate3).toISOString().slice(0, 10)
          : "",
        usddate4: cleanObject.usddate4
          ? new Date(caseItem.usddate4).toISOString().slice(0, 10)
          : "",
      }));
    }
  }, [resultCase, setInputs]);

  // Getting Examinee Information
  useEffect(() => {
    if (inputs?.chartnbr) {
      getExamineeData({
        variables: {
          where: { chartnbr: parseInt(inputs.chartnbr, 10) },
        },
        fetchPolicy: "cache-first",
      });
    }
  }, [inputs?.chartnbr, getExamineeData]);

  useEffect(() => {
    if (resultExaminee?.data && !resultExaminee?.loading) {
      const {
        data: { examinee },
      } = resultExaminee;
      const cleanObject = Object.fromEntries(
        Object.entries(examinee).filter(([_, v]) => v)
      );
      setExamineeInputs({
        ...INITIAL_EXAMINEE_STATE,
        ...cleanObject,
      });
    }
  }, [resultExaminee, setExamineeInputs]);

  // company & marketer & qaRep should change with client
  const getClientCompanyDefaultsAndUpdate = async (clientcode) => {
    const { data: ClientData } = await client.query({
      query: SINGLE_CLIENT_QUERY,
      variables: { where: { clientcode: parseInt(clientcode, 10) } },
    });
    const data = {
      marketercode: ClientData?.client?.marketercode || "",
      QARep: ClientData?.client?.QARep || "",
      priority:
        typeof ClientData?.client?.priority === "string"
          ? ClientData.client.priority.padEnd(10)
          : "",
      IMECompany: ClientData?.client?.companycode || "",
      company: ClientData?.client?.company || "",
    };
    if (
      ClientData?.client?.companycode &&
      Object.keys(data).some((key) => !data[key])
    ) {
      const { data: CompanyData } = await client.query({
        query: SINGLE_COMPANY_QUERY,
        variables: {
          where: {
            companycode: parseInt(ClientData.client.companycode, 10),
          },
        },
      });
      if (CompanyData?.company) {
        if (!data.QARep) {
          data.QARep = CompanyData?.company?.QARep || "";
        }
        if (!data.marketercode) {
          data.marketercode = CompanyData?.company?.marketercode || "";
        }
        if (!data.priority) {
          data.priority =
            typeof CompanyData?.company?.priority === "string"
              ? CompanyData.company.priority.padEnd(10)
              : "";
        }
      }
    }
    setInputs((prev) => ({
      ...prev,
      ...data,
    }));
  };

  // Third parameter is for CompanyCode from selected Client
  const handleChange = (e, value, IMECompany, AllstateProcessor) => {
    if (e === "billclientcode") {
      setInputs((prev) => ({
        ...prev,
        ...value,
      }));
      return;
    }
    if (e?.target?.type === "checkbox") {
      setInputs((prev) => ({
        ...prev,
        [e.target.name]: e.target.checked,
      }));
    } else if (value && ["client", "Jurisdiction"].includes(e)) {
      if (IMECompany) {
        setInputs((prev) => ({
          ...prev,
          IMECompany,
          [e]: value,
        }));
      } else {
        setInputs((prev) => ({
          ...prev,
          [e]: value,
        }));
      }
      if (["client"].includes(e)) {
        getClientCompanyDefaultsAndUpdate(value);
      }
      if (!AllstateProcessor) {
        setInputs((prev) => ({
          ...prev,
          SelectedAllstateProcessor: "",
        }));
      }
    } else if (e.name && ["doctorcode"].includes(e.name)) {
      setInputs((prev) => ({
        ...prev,
        [e.name]: e.value,
      }));
    } else if (typeof e === "string") {
      setInputs((prev) => ({ ...prev, [e]: value }));
    } else {
      setInputs((prev) => ({
        ...prev,
        [e.target ? e.target.name : e.name]: e.target ? e.target.value : e,
      }));
    }
    if (!inputs.edited) {
      setInputs((prev) => ({
        ...prev,
        edited: true,
      }));
    }
  };

  const handleExamineeChange = (e, value) => {
    if (value) {
      setExamineeInputs((prev) => ({
        ...prev,
        [e]: value,
      }));
    } else if (e?.target?.type === "checkbox") {
      setExamineeInputs((prev) => ({
        ...prev,
        [e.target.name]: e.target.checked,
      }));
    } else if (typeof e === "string") {
      setExamineeInputs((prev) => ({ ...prev, [e]: value }));
    } else {
      setExamineeInputs((prev) => ({
        ...prev,
        [e.target ? e.target.name : e.name]: e.target ? e.target.value : e,
      }));
    }
    if (!inputs.editedExaminee) {
      setInputs((prev) => ({
        ...prev,
        editedExaminee: true,
      }));
    }
  };

  const resetExamineeInputs = () => {
    setInputs((prev) => ({
      ...prev,
      chartnbr: null,
    }));
    setExamineeInputs(INITIAL_EXAMINEE_STATE);
  };

  const saveChanges = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      setLoading(true);

      const {
        conflict,
        useridedited,
        dateedited,
      } = await checkForSaveConflict();
      if (!conflict) {
        await handleExamineeSave();
        await handleUpdate();
        handleCreateCaseHistory();
      } else {
        setModalOpen(
          true,
          <Confirmation
            affirmative="Refresh"
            message={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <React.Fragment>
                This record has been modified since you retrieved it.
                <br />
                Press refresh to load the latest changes or cancel to keep your
                existing changes.
                <br />
                <br />
                <small>
                  Last modified by&nbsp;
                  {useridedited}
                  &nbsp;at&nbsp;
                  {format(convertTimeZone(dateedited), "MM/dd/yyyy hh:mm a")}
                </small>
              </React.Fragment>
            }
            negative="Cancel"
            onCancel={() => setModalOpen(false, "")}
            onConfirm={async () => {
              setModalOpen(false, "");
              if (typeof resultCase.refetch === "function") {
                resultCase.refetch({
                  variables: { where: { casenbr: parseInt(casenbr, 10) } },
                });
              }
            }}
          />
        );
      }
    } catch (err) {
      const message =
        err instanceof CaseItemError ? err.message : "Error updating Case.";
      toast.error(message);
    } finally {
      setLoading(false);
    }
  };

  useWebReferral({
    clientcode: inputs?.client,
    marketercode: inputs?.marketercode,
    QARep: inputs?.QARep,
    priority: inputs?.priority,
    onConfirm: setInputs,
  });

  return (
    <React.Fragment>
      <Prompt
        message={(loc) =>
          /case/.test(loc.pathname)
            ? true
            : "You have unsaved changes. Are you sure you want to leave this page?"
        }
        when={inputs.editedExaminee || inputs.edited}
      />
      <CaseHeader
        caseTypeCode={inputs.caseTypeCode}
        casenbr={casenbr}
        editing={editMode}
        executeExceptions={executeExceptions}
        inputs={inputs}
        key={casenbr}
        loading={loading}
        officeCode={inputs.officeCode}
        serviceCode={inputs.serviceCode}
        statusCode={inputs.statusCode}
        toggleEditing={() => setEditMode((prev) => !prev)}
      />
      <CaseTabs casenbr={casenbr} />
      <Switch>
        <Route
          path={[
            "/cases/:casenbr/profile",
            "/claims/:claimcode/services/:serviceid/:servicetype/:workid/profile",
          ]}
        >
          {!resultCase?.loading ? (
            <Profile
              casenbr={casenbr}
              disabled={!editMode || loading}
              inputs={inputs}
              setInputs={setInputs}
              onChange={handleChange}
              onSubmit={saveChanges}
            />
          ) : (
            <Loader />
          )}
        </Route>
        <Route
          path={[
            "/cases/:casenbr/examinee",
            "/claims/:claimcode/services/:serviceid/:servicetype/:workid/examinee",
          ]}
        >
          <Examinee
            casenbr={casenbr}
            chartnbr={inputs?.chartnbr}
            claimnbr={inputs?.claimnbr}
            defenseattorneycode={inputs.defenseattorneycode}
            disabled={!editMode || loading}
            initialExamineeState={INITIAL_EXAMINEE_STATE}
            initialState={INITIAL_EXAMINEE_STATE}
            inputs={examineeInputs}
            physician={inputs?.TreatingPhysician}
            plaintiffattorneycode={inputs.plaintiffattorneycode}
            resetExamineeInputs={resetExamineeInputs}
            setInputs={setExamineeInputs}
            onChange={handleExamineeChange}
            onProfileChange={handleChange}
            onSubmit={saveChanges}
          />
        </Route>
        <Route
          path={[
            "/cases/:casenbr/case-parameters",
            "/claims/:claimcode/services/:serviceid/:servicetype/:workid/case-parameters",
          ]}
        >
          <CaseParameters
            disabled={!editMode || loading}
            inputs={inputs}
            onChange={handleChange}
            onSubmit={saveChanges}
          />
        </Route>
        <Route
          path={[
            "/cases/:casenbr/case-history",
            "/claims/:claimcode/services/:serviceid/:servicetype/:workid/case-history",
          ]}
        >
          <CaseHistory casenbr={casenbr} />
        </Route>
        <Route
          path={[
            "/cases/:casenbr/documents",
            "/claims/:claimcode/services/:serviceid/:servicetype/:workid/documents",
          ]}
        >
          <Documents inputs={inputs} />
        </Route>
        <Route
          path={[
            "/cases/:casenbr/scheduling",
            "/claims/:claimcode/services/:serviceid/:servicetype/:workid/scheduling",
          ]}
        >
          <Scheduling
            disabled={!editMode || loading}
            inputsCase={inputs}
            onChangeCase={handleChange}
            onSubmit={saveChanges}
          />
        </Route>
        <Route
          path={[
            "/cases/:casenbr/attorney",
            "/claims/:claimcode/services/:serviceid/:servicetype/:workid/attorney",
          ]}
        >
          <Attorney
            inputs={inputs}
            onChange={handleChange}
            onSubmit={saveChanges}
          />
        </Route>
        <Route
          path={[
            "/cases/:casenbr/accounting",
            "/claims/:claimcode/services/:serviceid/:servicetype/:workid/accounting",
          ]}
        >
          <Accounting
            casenbr={casenbr}
            disabled={!editMode || loading}
            inputs={inputs}
            onChange={handleChange}
            onSubmit={saveChanges}
          />
        </Route>
        <Route
          path={[
            "/cases/:casenbr/reports",
            "/claims/:claimcode/services/:serviceid/:servicetype/:workid/reports",
          ]}
        >
          <Reports
            disabled={!editMode || loading}
            inputs={inputs}
            onChange={handleChange}
            onSubmit={saveChanges}
          />
        </Route>
        <Route
          path={[
            "/cases/:casenbr/web-portal",
            "/claims/:claimcode/services/:serviceid/:servicetype/:workid/web-portal",
          ]}
        >
          <WebPortal
            disabled={!editMode || loading}
            inputs={inputs}
            onChange={handleChange}
            onSubmit={saveChanges}
          />
        </Route>
        <Route
          path={[
            "/cases/:casenbr/user-fields",
            "/claims/:claimcode/services/:serviceid/:servicetype/:workid/user-fields",
          ]}
        >
          <UserFields
            disabled={!editMode || loading}
            inputs={inputs}
            onChange={handleChange}
            onSubmit={saveChanges}
          />
        </Route>
        <Route
          path={[
            "/cases/:casenbr/apollo-notes",
            "/claims/:claimcode/services/:serviceid/:servicetype/:workid/apollo-notes",
          ]}
        >
          <ApolloNotes
            casenbr={casenbr}
            disabled={!editMode}
            inputs={inputs}
            onChange={handleChange}
            onSubmit={saveChanges}
          />
        </Route>
        <Route
          path={[
            "/cases/:casenbr/associated-cases",
            "/claims/:claimcode/services/:serviceid/:servicetype/:workid/associated-cases",
          ]}
        >
          <AssociatedCases
            casenbr={casenbr}
            chartnbr={inputs?.chartnbr}
            claimnbr={inputs?.claimnbr}
          />
        </Route>
      </Switch>
    </React.Fragment>
  );
};

export default CasePage;
