import React, { useState, useEffect } from "react";

import { Title, Button } from "rbx";

import { useParams, useHistory } from "react-router-dom";
import { useLazyQuery, useMutation } from "@apollo/client";

// componets
import { customToast, formatPhoneNumber, getDateEST } from "../../utils";
import Loader from "../../components/Loader";
import CompanyTabs from "./components/CompanyTabs";
import { useAuth } from "../../context/AuthContext";
import { usePermissions } from "../../hooks";

// graphql
import {
  COMPANY_QUERY,
  UPDATE_COMPANY_MUTATION,
  FIND_FIRST_COMPANY_GROUP_QUERY,
  DELETE_COMPANY_TO_COMPANY_GROUP_MUTATION,
  CREATE_COMPANY_TO_GROUP_MUTATION,
} from "../../graphql";

const InitialState = {
  companycode: null,
  extname: "",
  intname: "",
  addr1: "",
  addr2: "",
  city: "",
  status: "Active",
  state: "",
  zip: "",
  phone: "",
  notes: "",
  usddate1: "",
  usddate2: "",
  usdtext1: "",
  usdtext2: "",
  usdint1: "",
  usdmoney1: "",
  usdmoney2: "",
  marketercode: "",
  QARep: "",
  priority: "Normal",
  usdint2: "",
  usdvarchar1: "",
  usdvarchar2: "",
  CompanyGroupID: "",
  CompanyGroupEdited: false,
  CompanyEdited: false,
};

const CompanyPage = () => {
  const { state: authState } = useAuth();
  const isAdmin = usePermissions(["AllAdminFunc"]);
  const { companyCode, tab } = useParams();
  const history = useHistory();
  const [disabled, setEditMode] = useState(true);
  const [inputs, setInputs] = useState(InitialState);
  const [loading, setLoading] = useState(false);

  const [companyGroup, setCompanyGroup] = useState(null);

  const hasPermission = usePermissions([
    "AllAdminFunc",
    "Accounting",
    "Cust Service",
    "QC",
  ]);

  const [getCompany, { data: getCompanyData }] = useLazyQuery(COMPANY_QUERY);
  const [
    findFirstCompanyGroup,
    { data: findFirstCompanyGroupData },
  ] = useLazyQuery(FIND_FIRST_COMPANY_GROUP_QUERY);

  const [updateCompany] = useMutation(UPDATE_COMPANY_MUTATION);
  const [deleteCompanyToCompanyGroup] = useMutation(
    DELETE_COMPANY_TO_COMPANY_GROUP_MUTATION
  );
  const [createCompanyToCompanyGroup] = useMutation(
    CREATE_COMPANY_TO_GROUP_MUTATION
  );

  useEffect(() => {
    if (companyCode) {
      getCompany({
        variables: {
          where: { companycode: parseInt(companyCode, 10) },
        },
      });
    }
  }, [companyCode, getCompany]);

  useEffect(() => {
    const company = getCompanyData?.company;
    if (company) {
      setInputs((prev) => ({
        ...prev,
        ...company,
        marketercode: company.marketercode || "",
        QARep: company.QARep || "",
        priority: company.priority || "Normal",
        state: company.state,
        status: company.status || "",
        CompanyGroupID: prev.CompanyGroupID,
        usddate1: company.usddate1
          ? new Date(company.usddate1).toISOString().slice(0, 10)
          : "",
        usddate2: company.usddate2
          ? new Date(company.usddate2).toISOString().slice(0, 10)
          : "",
        usdint1: company.usdint1 || "",
        usdmoney1: company.usdmoney1 || "",
        usdmoney2: company.usdmoney2 || "",
        usdtext1: company.usdtext1 || "",
        usdtext2: company.usdtext2 || "",
        usdint2: company.usdint2 || "",
        usdvarchar1: company.usdvarchar1 || "",
        usdvarchar2: company.usdvarchar2 || "",
      }));
    }
  }, [getCompanyData]);

  useEffect(() => {
    if (companyCode) {
      findFirstCompanyGroup({
        variables: {
          where: {
            CompanyToCompanyGroup: {
              some: {
                CompanyID: { equals: parseInt(companyCode, 10) },
              },
            },
          },
        },
        fetchPolicy: "network-only",
      });
    }
  }, [companyCode, findFirstCompanyGroup]);

  useEffect(() => {
    const companyGroupResult = findFirstCompanyGroupData?.findFirstCompanyGroup;

    if (companyGroupResult && !companyGroup) {
      setCompanyGroup(companyGroupResult);
      setInputs((prev) => ({
        ...prev,
        CompanyGroupID: companyGroupResult.CompanyGroupID,
      }));
    }
  }, [findFirstCompanyGroupData, companyGroup]);

  const onChange = (e, selectedValue) => {
    if (
      typeof selectedValue !== "undefined" ||
      typeof selectedValue === "boolean"
    ) {
      setInputs((prev) => ({
        ...prev,
        CompanyEdited: true,
        CompanyGroupEdited: e === "CompanyGroupID",
        [e]: selectedValue,
      }));
    } else {
      const name = e.target ? e.target.name : e.name;
      const value = e.target ? e.target.value : e;
      if (e?.target?.type === "checkbox") {
        setInputs((prev) => ({
          ...prev,
          CompanyEdited: true,
          [name]: e.target.checked,
        }));
      } else {
        setInputs((prev) => ({
          ...prev,
          CompanyEdited: true,
          [name]: value,
        }));
      }
    }
  };

  const handleSave = async (e) => {
    if (e) e.preventDefault();
    try {
      setLoading(true);
      const [isValid] = formatPhoneNumber(inputs.phone);
      if (isValid) {
        let companyData = null;
        const methods = {
          company: "updateCompany",
        };
        const date = getDateEST();
        if (inputs.CompanyEdited) {
          const refetchQueries = [
            {
              query: COMPANY_QUERY,
              variables: {
                where: { companycode: parseInt(companyCode, 10) },
              },
            },
          ];
          const { data } = await updateCompany({
            variables: {
              data: {
                intname: { set: inputs.intname },
                extname: { set: inputs.extname },
                phone: {
                  set: inputs.phone,
                },
                addr1: { set: inputs.addr1 },
                addr2: { set: inputs.addr2 },
                city: { set: inputs.city },
                zip: { set: inputs.zip },
                state: { set: inputs.state },
                status: { set: inputs.status },
                marketercode: {
                  set: inputs.marketercode,
                },
                QARep: { set: inputs.QARep },
                priority: {
                  set: inputs.priority,
                },
                usdvarchar1: {
                  set: inputs.usdvarchar1,
                },
                usdvarchar2: {
                  set: inputs.usdvarchar2,
                },
                usddate1: { set: inputs.usddate1 },
                usddate2: { set: inputs.usddate2 },
                usdtext1: { set: inputs.usdtext1 },
                usdtext2: { set: inputs.usdtext2 },
                usdint1: { set: inputs.usdint1 ? 1 : 0 },
                usdint2: { set: inputs.usdint2 ? 1 : 0 },
                usdmoney1: {
                  set:
                    (inputs.usdmoney1 !== "" &&
                      parseInt(inputs.usdmoney1, 10)) ||
                    0,
                },
                usdmoney2: {
                  set:
                    (inputs.usdmoney2 !== "" &&
                      parseInt(inputs.usdmoney2, 10)) ||
                    0,
                },
                notes: { set: inputs.notes },
                dateedited: { set: date },
                useridedited: { set: authState?.user?.userid },
              },
              where: { companycode: parseInt(companyCode, 10) },
            },
            refetchQueries,
          });
          companyData = data;
        }
        const CompanyGroupID = parseInt(inputs?.CompanyGroupID, 10);
        const { companycode: resultCompanyCode } = companyData
          ? companyData[methods.company]
          : { companycode: parseInt(companyCode, 10) };

        if (inputs.CompanyGroupEdited) {
          if (CompanyGroupID) {
            if (companyGroup) {
              await deleteCompanyToCompanyGroup({
                variables: {
                  where: {
                    uq_CompanyToCompanyGroup: {
                      CompanyID: resultCompanyCode,
                      CompanyGroupID: companyGroup.CompanyGroupID,
                    },
                  },
                },
              });
            }
            await createCompanyToCompanyGroup({
              variables: {
                data: {
                  CompanyID: resultCompanyCode,
                  CompanyGroup: {
                    connect: {
                      CompanyGroupID,
                    },
                  },
                },
              },
            });

            setCompanyGroup({ CompanyGroupID });
          }
        }
        customToast.success("Company updated successfully");
      } else {
        customToast.warning("Invalid phone format.");
      }
    } catch (err) {
      customToast.warning("Error saving Company.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setInputs((prev) => ({
      ...prev,
      CompanyGroupID: companyGroup?.CompanyGroupID,
    }));
  }, [companyGroup]);

  if (loading || !getCompanyData) return <Loader />;

  return (
    <React.Fragment key="edit-company-page">
      <header className="page-head">
        <div className="company-page-header">
          <Title>{inputs?.intname}</Title>
        </div>
        <div className="page-head-end">
          {hasPermission && (
            <Button.Group hasAddons style={{ justifyContent: "flex-end" }}>
              <React.Fragment>
                <Button
                  color="primary"
                  size="small"
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setEditMode(!disabled);
                  }}
                >
                  Edit
                </Button>
              </React.Fragment>
              <Button
                color="success"
                disabled={!inputs.CompanyGroupEdited && !inputs.CompanyEdited}
                form="add-company-form"
                size="small"
                type="submit"
              >
                Save
              </Button>
            </Button.Group>
          )}
        </div>
      </header>
      <CompanyTabs
        companyCode={companyCode}
        disabled={disabled}
        handleSave={handleSave}
        history={history}
        inputs={inputs}
        isAdmin={isAdmin}
        tab={tab}
        onChange={onChange}
      />
    </React.Fragment>
  );
};

CompanyPage.propTypes = {};

export default CompanyPage;
