const checkProvReqMethod = (subpoenaInfo) => {
  const returnObj = {
    error: false,
    message: null,
    missingCount: 0,
  };

  const missingFields = [];

  if (!subpoenaInfo.ContactName) missingFields.push("Contact Name");
  if (!subpoenaInfo.Caption) missingFields.push("Caption");
  if (!subpoenaInfo.County) missingFields.push("County");
  if (!subpoenaInfo.DocketNumber)
    missingFields.push("Docket Number/ WC Bureau #");

  if (subpoenaInfo.ClaimType !== "TRCLWOR") {
    if (!subpoenaInfo.SubpoenaState) missingFields.push("State");
    if (!subpoenaInfo.Court) missingFields.push("Court");
  }

  if (missingFields.length) {
    returnObj.error = true;
    returnObj.message = missingFields.join(", ");
    returnObj.missingCount = missingFields.length;
  }

  return returnObj;
};

export default checkProvReqMethod;
