import React from "react";
import PropTypes from "prop-types";
import { Control, Field, Label, Input } from "rbx";
import { useApolloClient } from "@apollo/client";

import { SINGLE_CLIENT_QUERY } from "../../../../../../graphql";
import StateSelect from "../../../../../../components/StateSelect";
import ZipInput from "../../../../../../components/ZipInput";
import PhoneNumberInput from "../../../../../../components/PhoneNumberInput";
import ClientSelectWithOptions from "../../../../../../components/ClientSelectWithOptions";

const AccountingThirdPartyForm = ({ inputs, onChange, disabled }) => {
  const client = useApolloClient();

  const handleClientChange = async (name, value) => {
    if (value) {
      const { data: clientData } = await client.query({
        query: SINGLE_CLIENT_QUERY,
        variables: {
          where: { clientcode: parseInt(value, 10) },
        },
      });

      const newThirdPartyInputs = {
        [name]: value,
        billcompany: clientData?.client?.company?.intname || "",
        billcontact: clientData?.client?.billattn || "",
        billfax: clientData?.client?.billfax || clientData?.client?.fax || "",
        billaddr1:
          clientData?.client?.billaddr1 || clientData?.client?.addr1 || "",
        billaddr2:
          clientData?.client?.billaddr2 || clientData?.client?.addr2 || "",
        billcity:
          clientData?.client?.billcity || clientData?.client?.city || "",
        billstate:
          clientData?.client?.billstate || clientData?.client?.state || "",
        billzip: clientData?.client?.billzip || clientData?.client?.zip || "",
        billARKey: clientData?.client?.company?.intname || "",
      };

      onChange(name, newThirdPartyInputs);
    } else {
      onChange(name, {
        billclientcode: "",
        billcompany: "",
        billcontact: "",
        billfax: "",
        billaddr1: "",
        billaddr2: "",
        billcity: "",
        billstate: "",
        billzip: "",
        billARKey: "",
      });
    }
  };

  return (
    <div className="accounting-third-party">
      <Field className="grid-override is-two">
        <Control expanded>
          <ClientSelectWithOptions
            disabled={disabled}
            label="Billing Client"
            name="billclientcode"
            value={inputs.billclientcode}
            where={{ status: { equals: "Active" } }}
            onChange={handleClientChange}
          />
        </Control>
        <Control expanded>
          <Label>Company</Label>
          <Input
            autoComplete="new"
            disabled={disabled}
            name="billcompany"
            size="small"
            type="text"
            value={inputs.billcompany}
            onChange={(e) => onChange(e.target.name, e.target.value)}
          />
        </Control>
      </Field>
      <Field className="grid-override is-two">
        <Control expanded>
          <Label>Contact</Label>
          <Input
            autoComplete="new"
            disabled={disabled}
            name="billcontact"
            size="small"
            type="text"
            value={inputs.billcontact}
            onChange={(e) => onChange(e.target.name, e.target.value)}
          />
        </Control>
        <Control expanded>
          <Label>Fax</Label>
          <PhoneNumberInput
            disabled={disabled}
            name="billfax"
            placeholder="Fax"
            value={inputs.billfax}
            onChange={onChange}
          />
        </Control>
      </Field>
      <Field className="grid-override is-two">
        <Control expanded>
          <Label>Address 1</Label>
          <Input
            autoComplete="new"
            disabled={disabled}
            name="billaddr1"
            size="small"
            type="text"
            value={inputs.billaddr1}
            onChange={(e) => onChange(e.target.name, e.target.value)}
          />
        </Control>
        <Control expanded>
          <Label>Address 2</Label>
          <Input
            autoComplete="new"
            disabled={disabled}
            name="billaddr2"
            size="small"
            type="text"
            value={inputs.billaddr2}
            onChange={(e) => onChange(e.target.name, e.target.value)}
          />
        </Control>
      </Field>
      <Field className="grid-override is-three">
        <Control expanded>
          <Label>City</Label>
          <Input
            autoComplete="new"
            disabled={disabled}
            maxLength={50}
            name="billcity"
            size="small"
            type="text"
            value={inputs.billcity}
            onChange={onChange}
          />
        </Control>
        <StateSelect
          disabled={disabled}
          label="State"
          name="billstate"
          size="small"
          value={inputs.billstate}
          onChange={onChange}
        />
        <ZipInput
          disabled={disabled}
          name="billzip"
          state={inputs.billstate}
          value={inputs.billzip}
          onChange={onChange}
        />
      </Field>
      <Field>
        <Control expanded>
          <Label>AR Key</Label>
          <Input
            autoComplete="new"
            disabled={disabled}
            name="billARKey"
            size="small"
            type="text"
            value={inputs.billARKey}
            onChange={(e) => onChange(e.target.name, e.target.value)}
          />
        </Control>
      </Field>
    </div>
  );
};

AccountingThirdPartyForm.propTypes = {
  inputs: PropTypes.object,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

AccountingThirdPartyForm.defaultProps = {
  inputs: {},
  onChange: (e) => e,
  disabled: false,
};

export default AccountingThirdPartyForm;
