import React from "react";
import { format } from "date-fns";
import { useParams } from "react-router-dom";
import { Column, Control, Input, Label } from "rbx";
import { useQuery } from "@apollo/client";
import { SINGLE_DOCTOR_QUERY } from "../../../../graphql";
import { usePermissions } from "../../../../hooks";
import { convertTimeZone } from "../../../../utils";

import InvestigatorDeleteButton from "../../components/InvestigatorDeleteButton";

const Statistics = () => {
  const { doctorcode } = useParams();
  const isAdmin = usePermissions(["AllAdminFunc"]);

  const { data: doctorData } = useQuery(SINGLE_DOCTOR_QUERY, {
    variables: { where: { doctorcode: parseInt(doctorcode, 10) } },
  });

  const doctor = doctorData?.doctor;
  return (
    <Column.Group style={{ justifyContent: "center" }}>
      <Column desktop={{ size: 6 }} mobile={{ size: 12 }} tablet={{ size: 12 }}>
        <div className="grid-2">
          <Control style={{ marginBottom: "1rem" }}>
            <Label>System Investigator Code</Label>
            <Input
              readOnly
              name="doctorcode"
              size="small"
              value={doctor.doctorcode || "--"}
            />
          </Control>
        </div>
        <div className="grid-2">
          <Control style={{ marginBottom: "1rem" }}>
            <Label>Date Added</Label>
            <Input
              readOnly
              name="dateadded"
              size="small"
              value={
                doctor.dateadded
                  ? format(
                      convertTimeZone(doctor.dateadded),
                      "MM/dd/yyyy hh:mm a"
                    )
                  : "--"
              }
            />
          </Control>
          <Control style={{ marginBottom: "1rem" }}>
            <Label>By</Label>
            <Input
              readOnly
              name="useridadded"
              size="small"
              value={doctor.useridadded || "--"}
            />
          </Control>
        </div>
        <div className="grid-2">
          <Control style={{ marginBottom: "1rem" }}>
            <Label>Last Edited</Label>
            <Input
              readOnly
              name="dateedited"
              size="small"
              value={
                doctor.dateedited
                  ? format(
                      convertTimeZone(doctor.dateedited),
                      "MM/dd/yyyy hh:mm a"
                    )
                  : "--"
              }
            />
          </Control>
          <Control style={{ marginBottom: "1rem" }}>
            <Label>By</Label>
            <Input
              readOnly
              name="useridedited"
              size="small"
              value={doctor.useridedited || "--"}
            />
          </Control>
        </div>
      </Column>
      <Column>
        {isAdmin && <InvestigatorDeleteButton doctorcode={doctorcode} />}
      </Column>
    </Column.Group>
  );
};

Statistics.propTypes = {};

Statistics.defaultProps = {};

export default Statistics;
