import React, { useState } from "react";
import PropTypes from "prop-types";

import { Input } from "rbx";

const formatSSNumber = (SSN) => {
  const cleaned =
    typeof SSN === "string"
      ? SSN.replace(/\DxX/g, "")
      : (SSN && SSN[1]?.replace(/\DxX/g, "")) || "";
  const match = cleaned.match(
    /^(\d{3}|[x]{3}|[X]{3})(\d{2}|[x]{2}|[X]{2})(\d{4}|[x]{4}|[X]{4})$/
  );
  if (match) {
    return [true, [match[1], "-", match[2], "-", match[3]].join("")];
  }
  return [false, typeof SSN === "string" ? SSN : (SSN && SSN[1]) || ""];
};

const SSNumberInput = ({
  name,
  value,
  onChange,
  disabled,
  maxLength,
  required,
  placeholder,
  size,
  style,
}) => {
  const [valid, setValid] = useState(true);

  const handleChange = (e) => {
    const [isValid, SSN] = formatSSNumber(
      e.target.value.replace(/[^\d()xX\s-]/gi, "")
    );
    setValid(SSN.length ? isValid : true);
    onChange(name, SSN);
  };

  return (
    <Input
      autoComplete="new"
      disabled={disabled}
      maxLength={maxLength}
      name={name}
      placeholder={placeholder}
      required={required}
      size={size}
      state={valid ? "" : "warning"}
      style={style}
      type="text"
      value={value}
      onChange={handleChange}
    />
  );
};

SSNumberInput.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  maxLength: PropTypes.number,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  size: PropTypes.string,
  style: PropTypes.object,
};

SSNumberInput.defaultProps = {
  name: "",
  placeholder: "",
  value: "",
  maxLength: 11,
  disabled: false,
  required: false,
  onChange: () => null,
  size: "small",
  style: null,
};

export default SSNumberInput;
