import React from "react";
import PropTypes from "prop-types";
import { Control, Label, Select } from "rbx";
import { useQuery } from "@apollo/client";

import { LIST_CASE_DOCUMENT_QUERY } from "../../graphql";

const CaseDocumentSelect = ({
  casenbr,
  value,
  name,
  label,
  size,
  onChange,
  disabled,
  getValue,
  setValue,
  required,
  accessor,
  showLabel,
}) => {
  const { data: CaseDocumentData } = useQuery(LIST_CASE_DOCUMENT_QUERY, {
    variables: {
      where: {
        casenbr: {
          equals: parseInt(casenbr, 10),
        },
        OR: [
          {
            reporttype: { not: { equals: "Report" } },
          },
          {
            reporttype: { equals: null },
          },
        ],
      },
      orderBy: { description: "asc" },
    },
    fetchPolicy: "cache-and-network",
  });
  const documents = CaseDocumentData?.caseDocuments;
  const handleChange = (e) => {
    onChange(e.target.name, setValue(e.target.value));
  };

  const indicateRequired =
    required && (!value || value === "" || value === "0" || value === 0);

  return (
    <Control expanded>
      {showLabel && <Label>{label}</Label>}
      <Select.Container
        fullwidth
        size={size}
        state={Array.isArray(documents) ? "" : "loading"}
      >
        <Select
          disabled={disabled}
          name={name}
          style={{
            border: indicateRequired ? "1px #e63362 solid" : "",
            boxShadow: indicateRequired
              ? "0 0 0 0.125em rgba(230, 51, 98, 0.25)"
              : "",
          }}
          value={getValue(value)}
          onChange={handleChange}
        >
          <Select.Option value="" />
          {Array.isArray(documents) &&
            documents.map((document) => (
              <Select.Option key={document.seqno} value={document.seqno}>
                {document.description}
              </Select.Option>
            ))}
        </Select>
      </Select.Container>
    </Control>
  );
};

CaseDocumentSelect.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.number,
  ]),
  name: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  getValue: PropTypes.func,
  setValue: PropTypes.func,
  required: PropTypes.bool,
  accessor: PropTypes.string,
  showLabel: PropTypes.bool,
  casenbr: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

CaseDocumentSelect.defaultProps = {
  value: "",
  name: "",
  label: "Document",
  size: "small",
  onChange: () => null,
  disabled: false,
  getValue: (x) => x,
  setValue: (x) => x,
  required: false,
  accessor: "SeqNo",
  showLabel: true,
};

export default CaseDocumentSelect;
