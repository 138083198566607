import React from "react";
import PropTypes from "prop-types";
import { Field, Control, Label, Textarea } from "rbx";

import ApolloNoteTypeSelect from "../../../../components/ApolloNoteTypeSelect";

const ClaimNoteCreateForm = ({ inputs, onChange, disabled }) => (
  <React.Fragment>
    <Field>
      <ApolloNoteTypeSelect
        required
        disabled={disabled}
        label="Note Type"
        name="NoteTypeID"
        size="small"
        value={inputs.NoteTypeID}
        onChange={onChange}
      />
    </Field>
    <Field>
      <Control expanded>
        <Label>Note Description</Label>
        <Textarea
          autoComplete="new"
          disabled={disabled}
          name="NoteText"
          size="small"
          type="text"
          value={inputs.NoteText}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
    </Field>
  </React.Fragment>
);

ClaimNoteCreateForm.propTypes = {
  inputs: PropTypes.object,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

ClaimNoteCreateForm.defaultProps = {
  inputs: {},
  onChange: () => null,
  disabled: false,
};

export default ClaimNoteCreateForm;
