const CASE_ITEM_FIELDS = [
  { name: "ApptDate", type: "DateTime", entity: "case" },
  { name: "Appttime", type: "DateTime", entity: "case" },
  { name: "AttorneyNote", type: "String", entity: "case" },
  {
    name: "issuesWithInstructions",
    type: "String",
    entity: "Case",
  },
  { name: "BillingNote", type: "String", entity: "case" },
  { name: "CaseCompletedDate", type: "DateTime", entity: "case" },
  { name: "CertMailNbr", type: "String", entity: "case" },
  { name: "CertifiedMail", type: "Boolean", entity: "case" },
  { name: "DateForminDispute", type: "DateTime", entity: "case" },
  { name: "DateReceived", type: "DateTime", entity: "case" },
  { name: "DoctorName", type: "String", entity: "case" },
  { name: "ExternalDueDate", type: "DateTime", entity: "case" },
  { name: "HearingDate", type: "DateTime", entity: "case" },
  { name: "ICD9Code2", type: "String", entity: "case" },
  { name: "ICD9Code3", type: "String", entity: "case" },
  { name: "ICD9Code4", type: "String", entity: "case" },
  { name: "IMECompany", type: "String", entity: "case" },
  { name: "IMEMDName", type: "String", entity: "case" },
  { name: "CurrentDate", type: "String", entity: "case" },
  { name: "InternalDueDate", type: "DateTime", entity: "case" },
  { name: "Jurisdiction", type: "String", entity: "case" },
  { name: "LastScheduledBy", type: "String", entity: "case" },
  { name: "LastScheduledDate", type: "DateTime", entity: "case" },
  { name: "datemedsrecd", type: "DateTime", entity: "case" },
  { name: "OCF25Date", type: "DateTime", entity: "case" },
  { name: "PanelNbr", type: "Int", entity: "case" },
  { name: "PublishOnWeb", type: "Boolean", entity: "case" },
  { name: "QARep", type: "String", entity: "case" },
  { name: "QAReporter", type: "User", entity: "case" },
  { name: "Status", type: "Queue", entity: "case" },
  { name: "TreatingPhysician", type: "Int", entity: "case" },
  { name: "VoucherFeeOverride", type: "Float", entity: "case" },
  { name: "WCBNbr", type: "String", entity: "case" },
  { name: "WebGUID", type: "String", entity: "case" },
  { name: "WebNotifyEmail", type: "String", entity: "case" },
  { name: "allegation", type: "String", entity: "case" },
  { name: "bComplete", type: "Boolean", entity: "case" },
  { name: "bhanddelivery", type: "Boolean", entity: "case" },
  { name: "billARKey", type: "String", entity: "case" },
  { name: "billaddr1", type: "String", entity: "case" },
  { name: "billaddr2", type: "String", entity: "case" },
  { name: "billcity", type: "String", entity: "case" },
  { name: "billclientcode", type: "Int", entity: "case" },
  { name: "billcompany", type: "String", entity: "case" },
  { name: "billcontact", type: "String", entity: "case" },
  { name: "billedselect", type: "Boolean", entity: "case" },
  { name: "billfax", type: "String", entity: "case" },
  { name: "billstate", type: "String", entity: "case" },
  { name: "billzip", type: "String", entity: "case" },
  { name: "calledinby", type: "String", entity: "case" },
  { name: "casenbr", type: "Int", entity: "case" },
  { name: "casetype", type: "Int", entity: "case" },
  { name: "chartnbr", type: "Int", entity: "case" },
  { name: "claimnbr", type: "String", entity: "case" },
  { name: "client", type: "Client", entity: "case" },
  { name: "clientcode", type: "Int", entity: "case" },
  { name: "commitdate", type: "DateTime", entity: "case" },
  { name: "company", type: "Company", entity: "case" },
  { name: "dateadded", type: "DateTime", entity: "case" },
  { name: "datedrchart", type: "DateTime", entity: "case" },
  { name: "dateedited", type: "DateTime", entity: "case" },
  { name: "datemedsrecd", type: "DateTime", entity: "case" },
  { name: "dateofinjury", type: "DateTime", entity: "case" },
  { name: "defenseattorneycode", type: "Int", entity: "case" },
  { name: "doctor", type: "Doctor", entity: "case" },
  { name: "doctorcode", type: "Int", entity: "case" },
  { name: "ApptLocation", type: "String", entity: "case" },
  { name: "doctorspecialty", type: "String", entity: "case" },
  { name: "drchartselect", type: "Boolean", entity: "case" },
  { name: "emailPattny", type: "Boolean", entity: "case" },
  { name: "emailclient", type: "Boolean", entity: "case" },
  { name: "emaildoctor", type: "Boolean", entity: "case" },
  { name: "examinee", type: "Examinee", entity: "case" },
  { name: "faxPattny", type: "Boolean", entity: "case" },
  { name: "faxclient", type: "Boolean", entity: "case" },
  { name: "faxdoctor", type: "Boolean", entity: "case" },
  { name: "feecode", type: "Int", entity: "case" },
  { name: "icd9code", type: "String", entity: "case" },
  { name: "invoiceamt", type: "Float", entity: "case" },
  { name: "invoicedate", type: "DateTime", entity: "case" },
  { name: "issuecode", type: "Int", entity: "case" },
  { name: "laststatuschg", type: "DateTime", entity: "case" },
  { name: "location", type: "Location", entity: "case" },
  { name: "marketer", type: "User", entity: "case" },
  { name: "marketercode", type: "String", entity: "case" },
  { name: "mastercasenbr", type: "Int", entity: "case" },
  { name: "mastersubcase", type: "String", entity: "case" },
  { name: "miscselect", type: "Boolean", entity: "case" },
  { name: "notes", type: "String", entity: "case" },
  { name: "office", type: "Office", entity: "case" },
  { name: "officecode", type: "Int", entity: "case" },
  { name: "photoRqd", type: "Boolean", entity: "case" },
  { name: "plaintiffattorneycode", type: "Int", entity: "case" },
  { name: "prevappt", type: "DateTime", entity: "case" },
  { name: "priority", type: "String", entity: "case" },
  { name: "reccode", type: "Int", entity: "case" },
  { name: "referralmethod", type: "Int", entity: "case" },
  { name: "referraltype", type: "Int", entity: "case" },
  { name: "reportverbal", type: "Boolean", entity: "case" },
  { name: "requesteddoc", type: "String", entity: "case" },
  { name: "rptstatus", type: "String", entity: "case" },
  { name: "schedcode", type: "Int", entity: "case" },
  { name: "schedulenotes", type: "String", entity: "case" },
  { name: "scheduler", type: "User", entity: "case" },
  { name: "schedulercode", type: "String", entity: "case" },
  { name: "service", type: "Service", entity: "case" },
  { name: "serviceQueue", type: "ServiceQueue", entity: "case" },
  { name: "serviceQueues", type: "[ServiceQueue]", entity: "case" },
  { name: "servicecode", type: "Int", entity: "case" },
  { name: "sinternalcasenbr", type: "String", entity: "case" },
  { name: "specialinstructions", type: "String", entity: "case" },
  { name: "sreqdegree", type: "String", entity: "case" },
  { name: "sreqspecialty", type: "String", entity: "case" },
  { name: "status", type: "Int", entity: "case" },
  { name: "typemedsrecd", type: "String", entity: "case" },
  { name: "usddate1", type: "DateTime", entity: "case" },
  { name: "usddate2", type: "DateTime", entity: "case" },
  { name: "usddate3", type: "DateTime", entity: "case" },
  { name: "usddate4", type: "DateTime", entity: "case" },
  { name: "usdint1", type: "Int", entity: "case" },
  { name: "usdint2", type: "Float", entity: "case" },
  { name: "usdmoney1", type: "Float", entity: "case" },
  { name: "usdmoney2", type: "Float", entity: "case" },
  { name: "usdtext1", type: "String", entity: "case" },
  { name: "usdtext2", type: "String", entity: "case" },
  { name: "usdvarchar1", type: "String", entity: "case" },
  { name: "usdvarchar2", type: "String", entity: "case" },
  { name: "useridadded", type: "String", entity: "case" },
  { name: "useridedited", type: "String", entity: "case" },
  { name: "voucheramt", type: "Float", entity: "case" },
  { name: "voucherdate", type: "DateTime", entity: "case" },
  { name: "voucherselect", type: "Boolean", entity: "case" },
];

export default CASE_ITEM_FIELDS;
