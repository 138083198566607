import React, { useState, useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import { Title, Button, Field, Control, Input } from "rbx";
import { format, startOfDay, endOfDay, isValid } from "date-fns";

import { useQuery, useLazyQuery } from "@apollo/client";
import DoctorBlockTimeList from "../DoctorBlockTimeList";

import { useModal } from "../../../../context";

import { convertTimeZoneDataBase, convertTimeZone } from "../../../../utils";

import { ScheduleCaseModal } from "../../../Case/components";

import {
  SINGLE_DOCTOR_QUERY,
  SINGLE_LOCATION_QUERY,
  ALL_DOCTOR_SCHEDULE_QUERY,
} from "../../../../graphql";

const DoctorBlockTimeScheduleModal = ({
  casenbr,
  doctorcode,
  locationcode,
  doctorspecialty,
  onComplete,
}) => {
  const { setModalOpen } = useModal();
  const [blocks, setBlocks] = useState([]);

  const [dateStr, setDateStr] = useState(format(new Date(), "yyyy-MM-dd"));

  const { data: DoctorData } = useQuery(SINGLE_DOCTOR_QUERY, {
    variables: { where: { doctorcode: parseInt(doctorcode, 10) } },
  });

  const { data: LocationData } = useQuery(SINGLE_LOCATION_QUERY, {
    variables: { where: { locationcode: parseInt(locationcode, 10) } },
  });

  const location = LocationData?.location || {};
  const doctor = DoctorData?.doctor || {};

  const [
    getDoctorSchedule,
    { data: doctorSchedulesData, loading: GetDoctorScheduleLoading },
  ] = useLazyQuery(ALL_DOCTOR_SCHEDULE_QUERY, {
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    const date = new Date(dateStr.split("-").map((x) => parseInt(x, 10)));

    if (isValid(date)) {
      getDoctorSchedule({
        variables: {
          where: {
            doctorcode: { equals: parseInt(doctorcode, 10) },
            locationcode: { equals: parseInt(locationcode, 10) },
            starttime: {
              gte: convertTimeZoneDataBase(startOfDay(date)),
              lte: convertTimeZoneDataBase(endOfDay(date)),
            },
          },
        },
      });
    }
  }, [getDoctorSchedule, doctorcode, locationcode, dateStr]);

  const doctorBlocks = useMemo(
    () =>
      doctorSchedulesData?.doctorSchedules?.length
        ? doctorSchedulesData?.doctorSchedules.map((schedule) => ({
            ...schedule,
            starttime: convertTimeZone(schedule.starttime),
            new: false,
          }))
        : [],
    [doctorSchedulesData?.doctorSchedules]
  );

  useEffect(() => {
    if (doctorBlocks) {
      setBlocks((prev) =>
        [...prev.filter((block) => block.new), ...doctorBlocks]
          .sort((a, b) => new Date(b.starttime) - new Date(a.starttime))
          .reverse()
      );
    }
  }, [doctorBlocks]);

  const isLoading = useMemo(() => GetDoctorScheduleLoading, [
    GetDoctorScheduleLoading,
  ]);

  const handleScheduleBlock = ({ schedcode, starttime }) => {
    setModalOpen(
      true,
      <ScheduleCaseModal
        appttime={starttime}
        casenbr={casenbr}
        doctorcode={doctorcode}
        doctorspecialty={doctorspecialty}
        locationcode={locationcode}
        schedcode={schedcode}
        onComplete={() => {
          setModalOpen(false, "");
        }}
      />
    );
  };

  return (
    <div className="block-time-modal-container">
      <header className="page-head">
        <div className="page-head-start">
          <Title size={5}>
            {[doctor?.firstname, doctor?.lastname, "at", location?.location]
              .filter(Boolean)
              .join(" ")}
          </Title>
        </div>
        <div className="page-head-end">
          <Button size="small" type="button" onClick={() => onComplete(false)}>
            <span>Cancel</span>
          </Button>
        </div>
      </header>
      <hr />
      <Field className="grid-override is-six" kind="group">
        <Control>
          <Input
            autoComplete="new"
            name="dateStr"
            size="small"
            type="date"
            value={dateStr}
            onChange={(e) => setDateStr(e.target.value)}
          />
        </Control>
      </Field>
      <DoctorBlockTimeList
        isScheduling
        blocks={blocks}
        disabled={isLoading}
        onScheduleBlock={handleScheduleBlock}
      />
      <hr />
    </div>
  );
};

DoctorBlockTimeScheduleModal.propTypes = {
  locationcode: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  doctorcode: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  casenbr: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  doctorspecialty: PropTypes.string,
  onComplete: PropTypes.func.isRequired,
};

DoctorBlockTimeScheduleModal.defaultProps = {
  doctorspecialty: "",
};

export default DoctorBlockTimeScheduleModal;
