import { gql } from "@apollo/client";

export const LIST_CASE_TYPES_QUERY = gql`
  query LIST_CASE_TYPES_QUERY(
    $where: CaseTypeWhereInput
    $orderBy: [CaseTypeOrderByInput!]
    $take: Int
    $skip: Int
  ) {
    caseTypes(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
      code
      description
      dateadded
      useridadded
      dateedited
      useridedited
      officecode
      status
      ShortName
      ShortDesc
      Office {
        officecode
        description
        shortdesc
      }
    }
    aggregateCaseType {
      count {
        code
      }
    }
  }
`;

export const ALL_CASE_TYPES_QUERY = gql`
  query ALL_CASE_TYPES_QUERY(
    $where: CaseTypeWhereInput
    $orderBy: [CaseTypeOrderByInput!]
    $take: Int
    $skip: Int
  ) {
    caseTypes(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
      code
      description
      dateadded
      useridadded
      dateedited
      useridedited
      officecode
      status
      ShortName
      ShortDesc
    }
  }
`;

export const ALL_CASE_TYPES_SELECT_QUERY = gql`
  query caseTypes(
    $take: Int
    $orderBy: [CaseTypeOrderByInput!]
    $skip: Int
    $where: CaseTypeWhereInput
  ) {
    caseTypes(take: $take, orderBy: $orderBy, skip: $skip, where: $where) {
      code
      description
      ShortName
    }
  }
`;

export const ALL_CASE_TYPES_OFFICES_SELECT_QUERY = gql`
  query caseTypeOffices(
    $take: Int
    $orderBy: [CaseTypeOfficeOrderByInput!]
    $skip: Int
    $where: CaseTypeOfficeWhereInput
    $distinct: [CaseTypeOfficeScalarFieldEnum!]
  ) {
    caseTypeOffices(
      take: $take
      orderBy: $orderBy
      skip: $skip
      where: $where
      distinct: $distinct
    ) {
      casetype
      officecode
      CaseType {
        code
        description
      }
    }
  }
`;

export const SINGLE_CASE_TYPE_QUERY = gql`
  query SINGLE_CASE_TYPE_QUERY($where: CaseTypeWhereUniqueInput!) {
    caseType(where: $where) {
      code
      description
      status
      ShortDesc
      officecode
    }
  }
`;
