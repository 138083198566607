import React from "react";
import PropTypes from "prop-types";
import { useMutation, useQuery } from "@apollo/client";
import { Field, Control, Button, Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";

import {
  SINGLE_COMPANY_QUERY,
  UPDATE_MANY_CLIENTS_MUTATION,
} from "../../../../graphql";

import { useModal } from "../../../../context";

import Confirmation from "../../../../components/Confirmation";

const CompanyReplicateToClientsButton = ({ companycode, onSubmit }) => {
  const { setModalOpen } = useModal();
  const { data: SingleCompanyData } = useQuery(SINGLE_COMPANY_QUERY, {
    variables: {
      where: {
        companycode: parseInt(companycode, 10),
      },
    },
  });
  const [updateManyClients] = useMutation(UPDATE_MANY_CLIENTS_MUTATION);

  const handleReplicateToClients = async () => {
    const performReplication = async () => {
      try {
        const { addr1, addr2, city, state, zip } = SingleCompanyData.company;

        await updateManyClients({
          variables: {
            data: {
              addr1: { set: addr1 },
              addr2: { set: addr2 },
              city: { set: city },
              state: { set: state },
              zip: { set: zip },
            },
            where: {
              companycode: { equals: parseInt(companycode, 10) },
              OR: [
                { doNotInherit: { equals: false } },
                { doNotInherit: { equals: null } },
              ],
            },
          },
        });
        toast.success(`Company data replicated successfully.`);
        setModalOpen(false, "");
      } catch (err) {
        toast.error(`Error replicating company data.`);
      }
    };
    await onSubmit();
    setModalOpen(
      true,
      <Confirmation
        message="Are you sure you want to replicate to clients?"
        onConfirm={performReplication}
      />,
    );
  };

  const isDisabled = !SingleCompanyData?.company;

  return (
    <Field>
      <Control>
        <Button
          color="danger"
          disabled={isDisabled}
          size="small"
          onClick={handleReplicateToClients}
        >
          <Icon>
            <FontAwesomeIcon icon="copy" />
          </Icon>
          <span>Replicate to Clients</span>
        </Button>
      </Control>
    </Field>
  );
};

CompanyReplicateToClientsButton.propTypes = {
  companycode: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default CompanyReplicateToClientsButton;
