import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";

import { useMutation, useQuery } from "@apollo/client";

import { Title, Button, Icon } from "rbx";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import { useModal } from "../../../../context";
import {
  SINGLE_CASE_QUERY,
  ALL_EXAMINEE_CCS_QUERY,
  CREATE_EXAMINEE_CC_MUTATION,
  DELETE_EXAMINEE_CC_MUTATION,
} from "../../../../graphql";

import { CCAddressModal } from "../../../../components/CCAddress";
import Confirmation from "../../../../components/Confirmation";

import FlatDataTable from "../../../../components/FlatDataTable";

import { generateColumns } from "./columns";

const CaseExamineeContactList = ({ casenbr }) => {
  const { setModalOpen } = useModal();

  const [chartnbr, setChartNbr] = useState("");

  const [createExamineeCC] = useMutation(CREATE_EXAMINEE_CC_MUTATION);
  const [deleteExamineeCC] = useMutation(DELETE_EXAMINEE_CC_MUTATION);

  const { data: CaseItemData } = useQuery(SINGLE_CASE_QUERY, {
    variables: { where: { casenbr: parseInt(casenbr, 10) } },
  });

  useEffect(() => {
    if (CaseItemData?.caseItem?.chartnbr) {
      setChartNbr(CaseItemData.caseItem.chartnbr);
    }
  }, [CaseItemData?.caseItem?.chartnbr]);

  const handleAddClick = () => {
    setModalOpen(
      true,
      <CCAddressModal
        allowCCAddressSelect
        onComplete={async (cccode) => {
          try {
            setModalOpen(false, "");
            if (cccode && chartnbr) {
              await createExamineeCC({
                variables: {
                  data: {
                    chartnbr,
                    ccAddress: {
                      connect: {
                        cccode,
                      },
                    },
                  },
                },
                refetchQueries: [
                  {
                    query: ALL_EXAMINEE_CCS_QUERY,
                    variables: {
                      where: { chartnbr: { equals: parseInt(chartnbr, 10) } },
                    },
                  },
                ],
              });
              toast.success("Examinee CC relationship created.");
            }
          } catch (err) {
            toast.error("Examinee CC relationship already exists.");
          }
        }}
      />,
    );
  };

  const COLUMNS = useMemo(() => {
    const handleDelete = (examineeCC) => {
      const performDelete = async () => {
        await deleteExamineeCC({
          variables: {
            where: {
              chartnbr_cccode: {
                chartnbr: examineeCC.chartnbr,
                cccode: examineeCC.cccode,
              },
            },
          },
          refetchQueries: [
            {
              query: ALL_EXAMINEE_CCS_QUERY,
              variables: {
                where: {
                  chartnbr: { equals: parseInt(examineeCC.chartnbr, 10) },
                },
              },
            },
          ],
        });
        setModalOpen(false, "");
      };
      setModalOpen(
        true,
        <Confirmation
          message="Are you sure you want to delete this Examinee CC?"
          onCancel={() => setModalOpen(false, "")}
          onConfirm={performDelete}
        />,
      );
    };
    return generateColumns(handleDelete);
  }, [deleteExamineeCC, setModalOpen]);

  const handleRowClick = (item) => {
    setModalOpen(
      true,
      <CCAddressModal
        cccode={item.row.original.cccode}
        chartnbr={chartnbr}
        onComplete={() => {
          setModalOpen(false, "");
        }}
      />,
    );
  };

  return (
    <div>
      <div className="page-head">
        <div className="page-head-start">
          <Button
            color="primary"
            size="small"
            type="button"
            onClick={handleAddClick}
          >
            <Icon>
              <FontAwesomeIcon icon="plus" />
            </Icon>
            <span>Add</span>
          </Button>
          <Title size={6}>Case Examinee Contact List</Title>
        </div>
      </div>
      {chartnbr && (
        <FlatDataTable
          columns={COLUMNS}
          fetchPolicy="cache-and-network"
          name="examineeCCS"
          query={ALL_EXAMINEE_CCS_QUERY}
          where={{ chartnbr: { equals: parseInt(chartnbr, 10) } }}
          onCellClick={handleRowClick}
        />
      )}
      {/* <pre>
        <code>{JSON.stringify(CaseItemData, null, 2)}</code>
      </pre> */}
    </div>
  );
};

CaseExamineeContactList.propTypes = {
  casenbr: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default CaseExamineeContactList;
