import React from "react";
import PropTypes from "prop-types";
import { Control, Field, Input, Box, Label, Textarea } from "rbx";

import ReferralStyledSubtitle from "../ReferralStyledSubtitle";
import RequestGenerator from "../RequestGenerator";

const ClientContactInformationForm = ({
  handleChange,
  inputs,
  disabled,
  rpRequired,
}) => (
  <Box className="col-span">
    <ReferralStyledSubtitle>
      Client / Contact Information
    </ReferralStyledSubtitle>

    <Field
      className="grid-override is-three"
      id="client-contact-information-form"
      kind="group"
    >
      <RequestGenerator
        required
        disabled={disabled}
        handleChange={handleChange}
        inputs={inputs}
        label="Requestor Or Attorney"
        type="RequestorOrAttorney"
      />

      <RequestGenerator
        disabled={disabled}
        handleChange={handleChange}
        inputs={inputs}
        label="Paralegal Or Contact"
        required={false}
        type="ParalegalOrContact"
      />

      <RequestGenerator
        disabled={disabled}
        handleChange={handleChange}
        inputs={inputs}
        label="Additional Contact"
        required={false}
        type="AdditionalContact"
      />
    </Field>
    <Field className="grid-override" kind="group">
      <Control expanded>
        <Label>Represented Party</Label>
        <Textarea
          disabled={disabled}
          name="RepresentedParty"
          required={rpRequired}
          size="small"
          value={inputs.RepresentedParty}
          onChange={(e) => handleChange(e.target.name, e.target.value)}
        />
      </Control>
      <Control expanded>
        <Label>Global Instructions</Label>
        <Textarea
          disabled={disabled}
          name="GlobalInstructions"
          size="small"
          value={inputs.GlobalInstructions}
          onChange={(e) => handleChange(e.target.name, e.target.value)}
        />
      </Control>
    </Field>
    <Field className="grid-override" kind="group">
      <Control expanded>
        <Label>File #/Matter ID</Label>
        <Input
          required
          disabled={disabled}
          name="FileNumber"
          size="small"
          value={inputs.FileNumber}
          onChange={(e) => handleChange(e.target.name, e.target.value)}
        />
      </Control>
      <Control expanded>
        <Label>Deadline Date</Label>
        <Input
          disabled={disabled}
          name="DeadlineDate"
          size="small"
          type="date"
          value={inputs.DeadlineDate}
          onChange={(e) => handleChange(e.target.name, e.target.value)}
        />
      </Control>
    </Field>
  </Box>
);

ClientContactInformationForm.propTypes = {
  handleChange: PropTypes.func.isRequired,
  inputs: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  rpRequired: PropTypes.bool.isRequired,
};

export default ClientContactInformationForm;
