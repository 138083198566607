import React, { useMemo } from "react";
// import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { Field, Control, Button, Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMutation } from "@apollo/client";

import { toast } from "react-toastify";
import {
  LIST_DOCTOR_DOCUMENTS_QUERY,
  DELETE_DOCTOR_DOCUMENT_MUTATION,
} from "../../../../graphql";
import Confirmation from "../../../../components/Confirmation";
import DataTable from "../../../../components/DataTable";
import { generateColumns } from "./columns";

import { useModal } from "../../../../context";
import DoctorDocumentModal from "../../components/DoctorDocumentModal";

const Documents = (props) => {
  const { doctorcode } = useParams();
  const { setModalOpen } = useModal();
  const [deleteDoctorDocument] = useMutation(DELETE_DOCTOR_DOCUMENT_MUTATION);

  const handleAddClick = () => {
    setModalOpen(
      true,
      <DoctorDocumentModal
        doctorcode={doctorcode}
        onComplete={() => setModalOpen(false, "")}
      />
    );
  };

  const handleRowClick = ({ recid }) => {
    setModalOpen(
      true,
      <DoctorDocumentModal
        doctorcode={doctorcode}
        recid={recid}
        onComplete={() => setModalOpen(false, "")}
      />
    );
  };

  const COLUMNS = useMemo(() => {
    const handleDelete = (doctorDocument) => {
      const performDelete = async () => {
        try {
          setModalOpen(false, "");
          await deleteDoctorDocument({
            variables: {
              where: {
                doctorcode_recid: {
                  doctorcode: doctorDocument.doctorcode,
                  recid: doctorDocument.recid,
                },
              },
            },
            refetchQueries: [
              {
                query: LIST_DOCTOR_DOCUMENTS_QUERY,
                variables: {
                  where: {
                    doctorcode: {
                      equals: parseInt(doctorDocument.doctorcode, 10),
                    },
                  },
                },
              },
            ],
          });
          toast.success("Doctor Document deleted successfully.");
        } catch (err) {
          toast.error(`Error deleting Doctor Document.`);
        }
      };
      setModalOpen(
        true,
        <Confirmation
          message="Are you sure you want to delete this Doctor Document?"
          onCancel={() => setModalOpen(false, "")}
          onConfirm={performDelete}
        />
      );
    };
    return generateColumns(handleDelete);
  }, [deleteDoctorDocument, setModalOpen]);

  return (
    <div className="doctor-documents-page">
      <header className="page-head">
        <div className="page-head-start">
          <Field>
            <Control>
              <Button color="primary" size="small" onClick={handleAddClick}>
                <Icon>
                  <FontAwesomeIcon icon="plus" />
                </Icon>
                <span>Add</span>
              </Button>
            </Control>
          </Field>
          {/* <Title subtitle>Documents</Title> */}
        </div>
        <div className="page-head-end" />
      </header>
      <DataTable
        aggregateKey="_all"
        aggregateName="aggregateDoctorDocument"
        columns={COLUMNS}
        fetchPolicy="cache-and-network"
        name="doctorDocuments"
        orderBy={[{ id: "DateEdited", desc: false }]}
        query={LIST_DOCTOR_DOCUMENTS_QUERY}
        where={{ doctorcode: { equals: parseInt(doctorcode, 10) } }}
        onRowClick={handleRowClick}
      />
    </div>
  );
};

Documents.propTypes = {};

export default Documents;
