import { gql } from "@apollo/client";

export const CREATE_CC_ADDRESS_MUTATION = gql`
  mutation CREATE_CC_ADDRESS_MUTATION($data: CCAddressCreateInput!) {
    createCCAddress(data: $data) {
      cccode
    }
  }
`;

export const UPDATE_CC_ADDRESS_MUTATION = gql`
  mutation UPDATE_CC_ADDRESS_MUTATION(
    $data: CCAddressUpdateInput!
    $where: CCAddressWhereUniqueInput!
  ) {
    updateCCAddress(data: $data, where: $where) {
      cccode
    }
  }
`;
