import React, { useState, useEffect, useContext } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useParams, Switch, Route, Prompt, useHistory } from "react-router-dom";

import { UPDATE_EXAMINEE_MUTATION, SINGLE_EXAMINEE_QUERY } from "../../graphql";
import {
  customToast as toast,
  formatSSNumber,
  getDateEST,
  convertTimeZoneDataBase,
  convertInputToVariables,
} from "../../utils";
import { ModalContext } from "../../context/ModalContext";

import { Profile, Cases } from "./routes";

import { ExamineeTabs, ExamineeHeader } from "./components";
import Loader from "../../components/Loader";
import Confirmation from "../../components/Confirmation";
import AddCaseModal from "../Case/components/AddCaseModal";
import { useAuth } from "../../context/AuthContext";

import "./Examinee.scss";

const INITIAL_STATE = {
  chartnbr: "",
  firstname: "",
  lastname: "",
  middleinitial: "",
  prefix: "",
  addr1: "",
  addr2: "",
  city: "",
  state: "",
  zip: "",
  county: "",
  Country: "",
  phone1: "",
  phone2: "",
  sex: "",
  DOB: "",
  SSN: "",
  fax: "",
  email: "",
  edited: false,
};

class ExamineeError extends Error {}

const ExamineePage = (props) => {
  const { state: authState } = useAuth();
  const { chartnbr } = useParams();
  const history = useHistory();
  const { setModalOpen } = useContext(ModalContext);
  const [inputs, setInputs] = useState(INITIAL_STATE);
  const [editMode, setEditMode] = useState(false);

  const [getExamineeData, resultExaminee] = useLazyQuery(SINGLE_EXAMINEE_QUERY);
  const [updateExaminee] = useMutation(UPDATE_EXAMINEE_MUTATION);

  const [loading, setLoading] = useState(false);

  const handleExamineeSave = async () => {
    try {
      const obj = convertInputToVariables(
        inputs,
        Object.keys(INITIAL_STATE),
        false,
        ["edited", "chartnbr"]
      );

      const date = convertTimeZoneDataBase(getDateEST());

      if (obj.SSN?.set) {
        const [isValid] = formatSSNumber(obj.SSN.set);
        if (!isValid) {
          throw new ExamineeError("SSN Number is not valid");
        }
      }

      await updateExaminee({
        variables: {
          where: {
            chartnbr: inputs.chartnbr,
          },
          data: {
            ...obj,
            dateedited: { set: date },
            useridedited: { set: authState?.user?.userid },
          },
        },
        refetchQueries: [
          {
            query: SINGLE_EXAMINEE_QUERY,
            variables: {
              where: { chartnbr: parseInt(inputs?.chartnbr, 10) },
            },
          },
        ],
      });

      toast.success("Examinee updated successfully.");
      setInputs((prev) => ({
        ...prev,
        edited: false,
      }));
    } catch (err) {
      if (err instanceof ExamineeError) {
        toast.error(err.message);
      } else {
        toast.error("Error updating Examinee.");
      }
    }
  };

  useEffect(() => {
    if (chartnbr) {
      getExamineeData({
        variables: {
          where: { chartnbr: parseInt(chartnbr, 10) },
        },
        fetchPolicy: "network-only",
      });
    } else {
      setEditMode(true);
    }
  }, [chartnbr, getExamineeData]);

  useEffect(() => {
    const examineeResult = resultExaminee?.data?.examinee;
    if (examineeResult) {
      const cleanObject = Object.fromEntries(
        Object.entries(examineeResult).filter(([_, v]) => v !== null)
      );

      setInputs((prev) => ({
        ...prev,
        ...cleanObject,
        commitdate: cleanObject.DOB
          ? new Date(cleanObject.DOB).toISOString().slice(0, 10)
          : "",
      }));
    }
  }, [resultExaminee?.data?.examinee, setInputs]);

  const handleChange = (e, value) => {
    const newValue = e.target ? e.target.value : value;
    const newName = e.target ? e.target.name : e;
    setInputs((prev) => ({
      ...prev,
      [newName]: newValue,
      edited: true,
    }));
  };

  const saveChanges = async (e) => {
    try {
      setLoading(true);
      e.stopPropagation();
      e.preventDefault();
      await handleExamineeSave();
    } catch (err) {
      toast.error("Error updating Examinee.");
    } finally {
      setLoading(false);
    }
  };

  const createCase = async (e) => {
    try {
      setLoading(true);
      e.stopPropagation();
      e.preventDefault();
      setModalOpen(
        true,
        <AddCaseModal
          defaultExamineeValues={{
            chartnbr: inputs.chartnbr,
            firstname: inputs.firstname,
            lastname: inputs.lastname,
          }}
          onComplete={({ casenbr }) => {
            setModalOpen(false, "");
            if (casenbr) {
              setModalOpen(
                true,
                <Confirmation
                  message="Case created successfully. Would you like to open it now?"
                  onConfirm={() => {
                    setModalOpen(false);
                    history.push(`/cases/${casenbr}/profile`);
                  }}
                />
              );
            }
          }}
        />
      );
    } catch (err) {
      toast.error("Error updating Case.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      <Prompt
        message={(loc) =>
          /examinee/.test(loc.pathname)
            ? true
            : "You have unsaved changes. Are you sure you want to leave this page?"
        }
        when={inputs.edited}
      />
      <form
        onSubmit={
          /cases/.test(document.location.pathname) ? createCase : saveChanges
        }
      >
        <ExamineeHeader
          chartnbr={chartnbr}
          editing={editMode}
          loading={loading}
          toggleEditing={() => setEditMode((prev) => !prev)}
        />
      </form>
      <ExamineeTabs chartnbr={chartnbr} />
      <Switch>
        <Route path="/examinees/:chartnbr/profile">
          {!resultExaminee?.loading ? (
            <Profile
              disabled={!editMode || loading}
              inputs={inputs}
              onChange={handleChange}
            />
          ) : (
            <Loader />
          )}
        </Route>
        <Route path="/examinees/:chartnbr/cases">
          <Cases />
        </Route>
      </Switch>
    </React.Fragment>
  );
};
ExamineePage.propTypes = {};

export default ExamineePage;
