/* eslint-disable import/prefer-default-export */
import { gql } from "@apollo/client";

export const ALL_APOLLO_SERVICE_TYPES_QUERY = gql`
  query ALL_APOLLO_SERVICE_TYPES_QUERY(
    $take: Int = 100
    $orderBy: [ServiceTypesOrderByInput!]
    $skip: Int
    $where: ServiceTypesWhereInput
  ) {
    findManyServiceTypes(
      take: $take
      orderBy: $orderBy
      skip: $skip
      where: $where
    ) {
      ServiceTypeID
      ServiceType
      WorkServer
      WorkDatabase
      WorkSchema
      WorkTable
      WorkField
    }
  }
`;
