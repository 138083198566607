import React, { useState } from "react";

import { useHistory } from "react-router-dom";
import { Field, Control, Input, Button, Title, Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DataTable from "../../components/DataTable";

import COLUMNS from "./columns";

import { debounce, getFilterState, saveFilterState } from "../../utils";

import { CompanyListFilters } from "./components";

import { LIST_COMPANY_QUERY } from "../../graphql/company";

import { useModal } from "../../context";
import CompanyCreateModal from "./components/CompanyCreateModal";

const ListCompanyPage = (props) => {
  const [where, setWhere] = useState(
    getFilterState("COMPANY_LIST_FILTERS", { status: { equals: "Active" } })
  );
  const [showFilters, setShowFilters] = useState(
    Object.keys(getFilterState("COMPANY_LIST_FILTERS")).length > 1
  );
  const history = useHistory();
  const { setModalOpen } = useModal();
  const handleRowClick = (company) => {
    history.push(`/companies/${company.companycode}/profile`);
  };

  const handleReset = () => {
    saveFilterState("COMPANY_LIST_FILTERS", { status: { equals: "Active" } });
    setWhere({ status: { equals: "Active" } });
  };

  const handleChange = (name, value) => {
    if (!value) {
      setWhere((prev) =>
        Object.keys(prev).reduce((acc, curr) => {
          if (curr !== name) acc[curr] = prev[curr];
          return acc;
        }, {})
      );
    } else {
      setWhere((prev) => ({ ...prev, [name]: value }));
    }
    setWhere((prev) => {
      saveFilterState("COMPANY_LIST_FILTERS", prev);
      return prev;
    });
  };

  const handleAddClick = () => {
    setModalOpen(
      true,
      <CompanyCreateModal
        onComplete={() => {
          setModalOpen(false, "");
        }}
      />
    );
  };

  return (
    <div>
      <div className="section-header">
        <Title style={{ marginRight: 15 }}>Companies</Title>
        <Field kind="group" style={{ paddingTop: "0.3rem" }}>
          <Control>
            <Input
              autoComplete="new"
              placeholder="Search by Name"
              size="small"
              onChange={debounce(({ target: { value } }) => {
                setWhere(value ? { intname: { contains: value } } : {});
              }, 500)}
            />
          </Control>
          <Control>
            <Button
              color="warning"
              size="small"
              onClick={() => setShowFilters((prev) => !prev)}
            >
              <Icon>
                <FontAwesomeIcon icon="filter" />
              </Icon>
              <span>Filters</span>
            </Button>
          </Control>
          <Control>
            <Button color="primary" size="small" onClick={handleAddClick}>
              <Icon>
                <FontAwesomeIcon icon="plus" />
              </Icon>
              <span>Add</span>
            </Button>
          </Control>
        </Field>
      </div>
      {showFilters && (
        <CompanyListFilters
          filters={where}
          onChange={handleChange}
          onReset={handleReset}
        />
      )}
      <DataTable
        aggregateKey="companycode"
        aggregateName="aggregateCompany"
        columns={COLUMNS}
        fetchPolicy="cache-and-network"
        name="companies"
        orderBy={[{ id: "extname", desc: false }]}
        query={LIST_COMPANY_QUERY}
        where={where}
        onRowClick={handleRowClick}
      />
    </div>
  );
};

ListCompanyPage.propTypes = {};

export default ListCompanyPage;
