import React from "react";
import PropTypes from "prop-types";
import { Control, Label, Input } from "rbx";

import DocumentSelect from "../../../../components/DocumentSelect";
import QueueSelect from "../../../../components/QueueSelect";
import DocumentTypeSelect from "../../../../components/DocumentTypeSelect";

const ClientDefaultDocumentForm = ({ inputs, onChange }) => (
  <div
    style={{
      display: "grid",
      gridTemplateColumns: "1fr 2fr 2fr 1fr",
      gridColumnGap: "1rem",
    }}
  >
    <DocumentTypeSelect
      label="Type"
      name="documentType"
      size="small"
      value={inputs.documentType}
      onChange={onChange}
    />

    <DocumentSelect
      fullWidth
      accessor="document"
      documentType={inputs.documentType}
      label="Document"
      name="documentcode"
      size="small"
      value={inputs.documentcode}
      onChange={onChange}
    />

    <QueueSelect
      label="Where to generate?"
      name="documentqueue"
      size="small"
      type="text"
      value={inputs.documentqueue}
      onChange={onChange}
    />

    <Control>
      <Label>Order</Label>
      <Input
        autoComplete="new"
        name="order"
        size="small"
        type="number"
        value={inputs.order}
        onChange={(e) => onChange(e.target.name, e.target.value)}
      />
    </Control>
  </div>
);

ClientDefaultDocumentForm.propTypes = {
  inputs: PropTypes.object,
  onChange: PropTypes.func,
};
ClientDefaultDocumentForm.defaultProps = {
  inputs: {},
  onChange: () => null,
};
export default ClientDefaultDocumentForm;
