import { gql } from "@apollo/client";

export const CREATE_DR_DO_NOT_USE_MUTATION = gql`
  mutation createDrDoNotUse($data: DrDoNotUseCreateInput!) {
    createDrDoNotUse(data: $data) {
      code
      dateadded
      doctorcode
      type
      useridadded
    }
  }
`;

export const UPDATE_DR_DO_NOT_USE_MUTATION = gql`
  mutation updateDrDoNotUse(
    $data: DrDoNotUseUpdateInput!
    $where: DrDoNotUseWhereUniqueInput!
  ) {
    updateDrDoNotUse(data: $data, where: $where) {
      code
      dateadded
      doctorcode
      type
      useridadded
    }
  }
`;

export const DELETE_DR_DO_NOT_USE_MUTATION = gql`
  mutation deleteDrDoNotUse($where: DrDoNotUseWhereUniqueInput!) {
    deleteDrDoNotUse(where: $where) {
      code
      dateadded
      doctorcode
      type
      useridadded
    }
  }
`;

export const CREATE_DOCTOR_MUTATION = gql`
  mutation createDoctor($data: DoctorCreateInput!) {
    createDoctor(data: $data) {
      doctorcode
    }
  }
`;

export const UPDATE_DOCTOR_MUTATION = gql`
  mutation updateDoctor(
    $data: DoctorUpdateInput!
    $where: DoctorWhereUniqueInput!
  ) {
    updateDoctor(data: $data, where: $where) {
      doctorcode
    }
  }
`;

export const DELETE_DOCTOR_MUTATION = gql`
  mutation deleteDoctor($where: DoctorWhereUniqueInput!) {
    deleteDoctor(where: $where) {
      doctorcode
    }
  }
`;

export const CREATE_MANY_DOCTOR_SCHEDULES_QUERY = gql`
  mutation createManyDoctorSchedules($input: [DoctorScheduleCreateInput!]!) {
    createManyDoctorSchedules(input: $input) {
      schedcode
      locationcode
    }
  }
`;

export const UPDATE_DOCTOR_SCHEDULE_MUTATION = gql`
  mutation updateDoctorSchedule(
    $where: DoctorScheduleWhereUniqueInput!
    $data: DoctorScheduleUpdateInput!
  ) {
    updateDoctorSchedule(where: $where, data: $data) {
      schedcode
      locationcode
    }
  }
`;

export const DELETE_DOCTOR_SCHEDULE_MUTATION = gql`
  mutation deleteDoctorSchedule($where: DoctorScheduleWhereUniqueInput!) {
    deleteDoctorSchedule(where: $where) {
      schedcode
      locationcode
    }
  }
`;
