import { gql } from "@apollo/client";

// eslint-disable-next-line import/prefer-default-export
export const LIST_EXAMINEE_QUERY = gql`
  query examinees(
    $take: Int
    $orderBy: [ExamineeOrderByInput!]
    $skip: Int
    $where: ExamineeWhereInput
  ) {
    examinees(take: $take, orderBy: $orderBy, skip: $skip, where: $where) {
      firstname
      lastname
      note
      chartnbr
      addr1
      addr2
      city
      state
      DOB
      SSN
    }
    aggregateExaminee(where: $where) {
      count {
        chartnbr
      }
    }
  }
`;

export const ALL_EXAMINEES_QUERY = gql`
  query examinees(
    $take: Int
    $orderBy: [ExamineeOrderByInput!]
    $skip: Int
    $where: ExamineeWhereInput
  ) {
    examinees(take: $take, orderBy: $orderBy, skip: $skip, where: $where) {
      chartnbr
      firstname
      lastname
      DOB
      city
      state
      zip
    }
  }
`;

export const SINGLE_EXAMINEE_QUERY = gql`
  query examinee($where: ExamineeWhereUniqueInput!) {
    examinee(where: $where) {
      firstname
      lastname
      middleinitial
      note
      chartnbr
      addr1
      addr2
      phone1
      county
      phone2
      zip
      prefix
      city
      state
      sex
      DOB
      SSN
      email
      insured
      InsuredAddr1
      InsuredCity
      InsuredState
      InsuredZip
      InsuredPhone
      InsuredPhoneExt
      InsuredFax
      InsuredEmail
      policynumber
      employer
      EmployerAddr1
      EmployerCity
      EmployerState
      EmployerZip
      EmployerPhone
      EmployerPhoneExt
      EmployerFax
      EmployerEmail
      EmployerContactFirstName
      EmployerContactLastName
      treatingphysician
      TreatingPhysicianAddr1
      TreatingPhysicianCity
      TreatingPhysicianState
      TreatingPhysicianZip
      TreatingPhysicianEmail
      TreatingPhysicianFax
      TreatingPhysicianLicenseNbr
      TreatingPhysicianPhone
      TreatingPhysicianPhoneExt
      TreatingPhysicianTaxID
    }
  }
`;

export const EXAMINEE_NO_SHOW_QUERY = gql`
  query EXAMINEE_NO_SHOW_QUERY($where: NoShowWhereInput!) {
    examineeNoShow(where: $where) {
      currentCase
      claimNumber
      allCases
    }
  }
`;
