import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Control, Label } from "rbx";
import { useApolloClient } from "@apollo/client";
import { AsyncPaginate } from "react-select-async-paginate";

import {
  RATS_INSURANCE_CARRIER_QUERY,
  RATS_SINGLE_INSURANCE_CARRIER_QUERY,
} from "../../../../graphql";

const QUERY_LIMIT = 50;

const RatsInsuranceCarrierSelect = ({
  name,
  value: initialValue,
  label,
  onChange,
  disabled,
  getValue,
  setValue,
  required,
  selected,
  setSelected
}) => {
  const client = useApolloClient();

  const [defaultOptions, setDefaultOptions] = useState([]);

  const promiseOptions = async (inputValue, { length }) => {
    const where = {
      ICname: {
        startsWith: inputValue,
      },
    };

    const { data: ratsInsuranceCarrierData } = await client.query({
      query: RATS_INSURANCE_CARRIER_QUERY,
      variables: {
        take: QUERY_LIMIT,
        where,
        orderBy: [{ ICname: "asc" }],
        skip: length,
      },
    });

    const insuranceCarriers = ratsInsuranceCarrierData?.insurancecarriers;

    const result = Array.isArray(insuranceCarriers)
      ? insuranceCarriers.map((ic) => ({
          value: ic.PK_ICid,
          label: ic.ICname,
        }))
      : [];
    setDefaultOptions(result);

    return {
      options: result,
      hasMore: result.length === QUERY_LIMIT,
    };
  };

  const handleChange = ({ value: newValue }) => {
    onChange(name, setValue(newValue || ""));
  };

  useEffect(() => {
    const getInsuranceCarrier = async () => {
      if (initialValue) {
        const { data: insuranceCarrierData } = await client.query({
          query: RATS_SINGLE_INSURANCE_CARRIER_QUERY,
          variables: {
            where: { PK_ICid: getValue(initialValue) },
          },
        });

        const insuranceCarrier = insuranceCarrierData?.insurancecarrier;

        if (insuranceCarrier) {
          setSelected({
            value: insuranceCarrier.PK_ICid,
            label: insuranceCarrier.ICname,
          });
        }
      } else {
        setSelected(null);
      }
    };
    getInsuranceCarrier();
  }, [initialValue, getValue, client, setSelected]);

  useEffect(() => {
    if (selected) {
      const currOptions = [...defaultOptions];
      if (!currOptions.some((opt) => opt.value === selected.value)) {
        setDefaultOptions((prev) => [selected, ...prev]);
      }
    }
  }, [selected, defaultOptions]);

  const indicateRequired =
    required &&
    !disabled &&
    (!initialValue ||
      initialValue === "" ||
      initialValue === "0" ||
      initialValue === 0);

  return (
    <Control expanded style={{ flexGrow: 1, marginRight: "0.313rem" }}>
      <Label>{label}</Label>
      <AsyncPaginate
        cacheOptions
        debounceTimeout={300}
        defaultOptions={defaultOptions}
        isDisabled={disabled}
        loadOptions={promiseOptions}
        menuPortalTarget={document.body}
        placeholder="Start typing..."
        required={required}
        styles={{
          menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
          indicatorsContainer: (provided) => ({
            ...provided,
            height: "inherit",
          }),
          indicatorSeparator: (provided) => ({
            ...provided,
            display: "none",
          }),
          container: (provided) => ({
            fontSize: 13,
          }),
          singleValue: (provided) => ({
            ...provided,
            top: "38%",
          }),
          control: (provided) => ({
            ...provided,
            minHeight: 31,
            height: 31,
            width: "100%",
            minWidth: 180,
            border: indicateRequired ? "1px #e63362 solid" : "",
            boxShadow: indicateRequired
              ? "0 0 0 0.125em rgba(230, 51, 98, 0.25)"
              : "",
            "&:hover": {
              borderColor: "hsl(0, 0%, 90%)",
              borderRadius: 4,
              cursor: disabled ? "not-allowed" : "pointer",
            },
          }),
          placeholder: (provided) => ({ ...provided, top: "38%" }),
          menu: (provided) => ({
            ...provided,
            fontSize: 12,
            zIndex: 999,
          }),
        }}
        value={selected}
        onChange={handleChange}
      />
    </Control>
  );
};

RatsInsuranceCarrierSelect.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  getValue: PropTypes.func,
  setValue: PropTypes.func,
  required: PropTypes.bool,
  selected: PropTypes.object.isRequired,
  setSelected: PropTypes.func.isRequired,
};

RatsInsuranceCarrierSelect.defaultProps = {
  name: "",
  label: "",
  value: "",
  onChange: () => null,
  disabled: false,
  getValue: (x) => x,
  setValue: (x) => x,
  required: false,
};

export default RatsInsuranceCarrierSelect;
