import React from "react";
import PropTypes from "prop-types";
import { Button, Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BusinessCard from "../BusinessCard";
import CourtEntryForm from "../CourtEntryForm";

import { useModal } from "../../../../context";

const CourtSelectActionButtons = ({
  label,
  disabled,
  onSelect,
  selectedAddress,
  onClearSelectionClick,
}) => {
  const { setModalOpen } = useModal();

  const handleSelect = (data) => {
    onSelect(data);
    setModalOpen(false, "");
  };

  const handleAddClick = () => {
    setModalOpen(
      true,
      <CourtEntryForm
        disabled={disabled}
        label={label}
        mode='add'
        onClose={() => setModalOpen(false, '')}
        onSelect={handleSelect}
      />
    );
  };

  const handleSearchClick = () => {
    setModalOpen(
      true,
      <CourtEntryForm
        disabled={disabled}
        label={label}
        mode='search'
        onClose={() => setModalOpen(false, '')}
        onSelect={handleSelect}
      />
    );
  };

  const handleSeeMoreClick = () => {
    setModalOpen(
      true,
      <CourtEntryForm
        disabled={disabled}
        inputs={selectedAddress}
        label={label}
        mode='view'
        onClose={() => setModalOpen(false, '')}
      />
    );
  };

  return (
    <Button.Group hasAddons>
      <BusinessCard
        data={selectedAddress || {}}
        disabled={!selectedAddress}
        style={{ position: 'relative' }}
        onSeeMoreClick={handleSeeMoreClick}
      />
      <Button
        color="danger"
        disabled={disabled || !selectedAddress}
        size="small"
        title="Remove"
        type="button"
        onClick={onClearSelectionClick}
      >
        <Icon>
          <FontAwesomeIcon icon="times" />
        </Icon>
      </Button>
      <Button
        color="warning"
        disabled={disabled}
        size="small"
        title="Search"
        type="button"
        onClick={handleSearchClick}
      >
        <Icon>
          <FontAwesomeIcon icon="filter" />
        </Icon>
      </Button>
      <Button
        color="success"
        disabled={disabled}
        size="small"
        style={{ position: 'relative' }}
        title="Add"
        type="button"
        onClick={handleAddClick}
      >
        <Icon>
          <FontAwesomeIcon icon="plus" />
        </Icon>
      </Button>
    </Button.Group>
  );
};

CourtSelectActionButtons.propTypes = {
  onSelect: PropTypes.func,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  selectedAddress: PropTypes.object,
  onClearSelectionClick: PropTypes.func,
};

CourtSelectActionButtons.defaultProps = {
  onSelect: () => null,
  label: "",
  disabled: false,
  selectedAddress: {},
  onClearSelectionClick: () => null,
};

export default CourtSelectActionButtons;
