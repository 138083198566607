import React from "react";
import PropTypes from "prop-types";
import InvestigatorUserDefinedFieldsForm from "../../components/InvestigatorUserDefinedFieldsForm";

const UserDefinedFields = ({ inputs, onChange, disabled }) => (
  <InvestigatorUserDefinedFieldsForm
    disabled={disabled}
    inputs={inputs}
    onChange={onChange}
  />
);

UserDefinedFields.propTypes = {
  inputs: PropTypes.object,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

UserDefinedFields.defaultProps = {
  inputs: {},
  disabled: false,
  onChange: () => null,
};

export default UserDefinedFields;
