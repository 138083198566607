import React from "react";
import { render } from "react-dom";
import "./styles/index.scss";
import { ApolloProvider } from "@apollo/client";
import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ErrorBoundary } from "react-error-boundary";
import Pages from "./pages";
import ErrorFallback from "./components/ErrorFallback";

import ContextProvider from "./context";

// import reportWebVitals from "./reportWebVitals";

import { client } from "./graphql";

import "./icons";

render(
  <React.StrictMode>
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        window.location.reload();
      }}
    >
      <ApolloProvider client={client}>
        <Router>
          <ContextProvider>
            <Pages />
          </ContextProvider>
          <ToastContainer />
        </Router>
      </ApolloProvider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById("root")
);

// reportWebVitals(console.log);
