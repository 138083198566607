import React from "react";
import PropTypes from "prop-types";
import { Control, Box, Field } from "rbx";
import BooleanInput from "../../../../components/BooleanInput";
import ReferralStyledSubtitle from "../ReferralStyledSubtitle";

const AdditionalInformation = ({ inputs, onChange, teamConnectID }) => (
  <Box className="col-span" id="additional-information">
    <ReferralStyledSubtitle>Additional Information</ReferralStyledSubtitle>
    <Field>
      <Control expanded>
        <BooleanInput
          label="Plaintiff Attorney"
          name="PlaintiffAttorney"
          value={inputs.PlaintiffAttorney}
          onChange={onChange}
        />
      </Control>
      <Control expanded>
        <BooleanInput
          label="Subrogation"
          name="PartOfSubrogation"
          value={inputs.PartOfSubrogation}
          onChange={onChange}
        />
      </Control>
      <Control expanded>
        <BooleanInput
          disabled={teamConnectID && inputs.Adjuster}
          label="Defense Attorney"
          name="DefenseAttorney"
          value={inputs.DefenseAttorney}
          onChange={onChange}
        />
      </Control>
      <Control expanded>
        <BooleanInput
          label="Employer"
          name="Employer"
          value={inputs.Employer}
          onChange={onChange}
        />
      </Control>
      <Control expanded>
        <BooleanInput
          label="Co-Defendant"
          name="CoDefendant"
          value={inputs.CoDefendant}
          onChange={onChange}
        />
      </Control>
      <Control expanded>
        <BooleanInput
          label="Insured"
          name="Insured"
          value={inputs.Insured}
          onChange={onChange}
        />
      </Control>
      <Control expanded>
        <BooleanInput
          label="Treating Physician"
          name="TreatingPhysician"
          value={inputs.TreatingPhysician}
          onChange={onChange}
        />
      </Control>
      <Control expanded>
        <BooleanInput
          label="Vehicle"
          name="Vehicle"
          value={inputs.Vehicle}
          onChange={onChange}
        />
      </Control>
      <Control expanded>
        <BooleanInput
          disabled={teamConnectID && inputs.Adjuster}
          label="Adjuster"
          name="Adjuster"
          value={inputs.Adjuster}
          onChange={onChange}
        />
      </Control>
    </Field>
  </Box>
);
AdditionalInformation.propTypes = {
  inputs: PropTypes.object,
  onChange: PropTypes.func,
  teamConnectID: PropTypes.string,
};

AdditionalInformation.defaultProps = {
  teamConnectID: "",
  inputs: {
    PlaintiffAttorney: 0,
    PartOfSubrogation: 0,
    DefenseAttorney: 0,
    Employer: 0,
    Insured: 0,
    TreatingPhysician: 0,
    Vehicle: 0,
  },
  onChange: () => null,
};

export default AdditionalInformation;
