import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { format } from "date-fns";
import { convertTimeZone } from "../utils";
import { WORKREQUEST_QUERY } from "../graphql";
import { useQueryParams } from ".";

const useGetWorkRequest = () => {
  const [mainData, setMainData] = useState(null);
  const [subpoenaData, setSubpoenaData] = useState(null);

  const [previousReferral] = useQueryParams("ISGNumber", "", true);
  const WRID =
    previousReferral && previousReferral?.startsWith("WR")
      ? previousReferral
      : null;

  const { data: wrid } = useQuery(WORKREQUEST_QUERY, {
    variables: {
      where: { WRid: WRID },
    },
    fetchPolicy: "network-only",
    skip: !WRID,
  });

  const workRequest = wrid?.workrequest;

  useEffect(() => {
    if (workRequest) {
      setMainData({
        ClaimantLastName: workRequest?.requestsubject?.RSnameFirst,
        ClaimantFirstName: workRequest?.requestsubject?.RSnameLast,
        ClaimantSSN: workRequest?.requestsubject?.RSssnFull,
        ClaimantDateOfBirth: workRequest?.requestsubject?.RSdateOfBirth
          ? format(
              convertTimeZone(workRequest?.requestsubject?.RSdateOfBirth),
              "yyyy-MM-dd"
            )
          : "",
        ClaimantAddress1: workRequest?.requestsubject?.RSaddress1,
        ClaimantAddress2: workRequest?.requestsubject?.RSaddress2,
        ClaimantCity: workRequest?.requestsubject?.RScity,
        ClaimantState: workRequest?.requestsubject?.RSstate,
        ClaimantZip: workRequest?.requestsubject?.RSzip,
        ClaimantPhone: workRequest?.RSphone1,
        ClaimantCellPhone: workRequest?.RSphone2,
        ClaimNumber: workRequest?.WRattorneyFileID,
        RequestorOrAttorney: workRequest?.WRfileContactID,
        BillingContact: workRequest?.WRbillToID,
        DateOfLoss: workRequest?.WRdateOfAccident
          ? format(convertTimeZone(workRequest?.WRdateOfAccident), "yyyy-MM-dd")
          : "",
        Insured: workRequest?.WRinsuredName,
        carrier: workRequest?.WRcarrier,
        Jurisdiction: workRequest?.WRclaimState,
      });

      setSubpoenaData({
        ClaimType: workRequest?.WRclaimType,
      });
    }
  }, [workRequest]);

  return { mainData, subpoenaData };
};

export default useGetWorkRequest;
