import { gql } from "@apollo/client";

import { CORE_SERVICE_FRAGMENT } from "./apolloService.fragments";

export const SINGLE_APOLLO_SERVICE_QUERY = gql`
  ${CORE_SERVICE_FRAGMENT}
  query SINGLE_APOLLO_SERVICE_QUERY($where: ServicesWhereUniqueInput!) {
    findUniqueServices(where: $where) {
      ...CoreServiceFragment
    }
  }
`;
export const FIND_FIRST_APOLLO_SERVICE_QUERY = gql`
  ${CORE_SERVICE_FRAGMENT}
  query FIND_FIRST_APOLLO_SERVICE_QUERY($where: ServicesWhereInput!) {
    findFirstServices(where: $where) {
      ...CoreServiceFragment
    }
  }
`;

export const ALL_APOLLO_SERVICES_QUERY = gql`
  query ALL_APOLLO_NOTES_QUERY(
    $take: Int = 100
    $orderBy: [ServicesOrderByInput!]
    $skip: Int
    $where: ServicesWhereInput
  ) {
    findManyServices(
      take: $take
      orderBy: $orderBy
      skip: $skip
      where: $where
    ) {
      ServiceID
    }
  }
`;

export const LIST_APOLLO_SERVICES_QUERY = gql`
  ${CORE_SERVICE_FRAGMENT}

  query findManyServices(
    $take: Int = 100
    $orderBy: [ServicesOrderByInput!]
    $skip: Int
    $where: ServicesWhereInput
  ) {
    findManyServices(
      take: $take
      orderBy: $orderBy
      skip: $skip
      where: $where
    ) {
      ServiceID
      ClaimID
      ExternalServiceID
      ServiceTypeID
      ClientCode
      WorkID
      CreatedDate
      ParentServiceID
      Archive
      ClientName
      ServiceType {
        ServiceTypeID
        ServiceType
      }
      # 10 Levels
      ChildServices {
        ...CoreServiceFragment
        ChildServices {
          ...CoreServiceFragment
          ChildServices {
            ...CoreServiceFragment
            ChildServices {
              ...CoreServiceFragment
              ChildServices {
                ...CoreServiceFragment
                ChildServices {
                  ...CoreServiceFragment
                  ChildServices {
                    ...CoreServiceFragment
                    ChildServices {
                      ...CoreServiceFragment
                      ChildServices {
                        ...CoreServiceFragment
                        ChildServices {
                          ...CoreServiceFragment
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    aggregateServices(where: $where) {
      count {
        ServiceID
        ClientCode
      }
    }
  }
`;
