import React, { useState } from "react";
import PropTypes from "prop-types";
import { Control, Field, Label } from "rbx";

import RatsEmployerSelect from "../RatsEmployerSelect";

import RatsEmployerActionButtons from "../RatsEmployerActionButtons";

const RatsEmployer = ({
  handleChange,
  inputs,
  disabled,
  required,
  label,
  type,
}) => {
  const [selectedAddress, setSelectedAddress] = useState([]);
  const [addressInputs, setAddressInputs] = useState({});

  return (
    <Field kind="group">
      <div style={{ flexGrow: 1 }}>
        <RatsEmployerSelect
          disabled={disabled}
          label={label}
          name={type}
          required={required}
          value={inputs[type]}
          onAddressInputsChange={(ai) => setAddressInputs(ai)}
          onChange={(name, value) => handleChange(name, value)}
          onSelectedAddressChange={(sa) => setSelectedAddress(sa)}
        />
      </div>
      <Control>
        <Label>&nbsp;</Label>
        <RatsEmployerActionButtons
          addressInputs={addressInputs}
          disabled={disabled}
          label={label}
          selectedAddress={selectedAddress}
          onClearSelectionClick={() => handleChange(type, "")}
          onRatsEmployerAdd={(employer) => handleChange(type, employer)}
          onRowClick={(row) => handleChange(type, row.PK_EMid)}
        />
      </Control>
    </Field>
  );
};

RatsEmployer.propTypes = {
  handleChange: PropTypes.func.isRequired,
  inputs: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  required: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default RatsEmployer;
