import { gql } from "@apollo/client";

export const CREATE_APOLLO_DOCUMENT_MUTATION = gql`
  mutation CREATE_APOLLO_DOCUMENT_MUTATION(
    $data: DocumentsCreateInput!
  ) {
    createDocuments(data: $data) {
      DocumentID
    }
  }
`;

export const DELETE_APOLLO_DOCUMENT_MUTATION = gql`
  mutation DELETE_APOLLO_DOCUMENT_LOCATION_MUTATION(
    $where: DocumentsWhereUniqueInput!
  ) {
    deleteDocuments(where: $where) {
      DocumentID
    }
  }
`;