import { gql } from "@apollo/client";

// eslint-disable-next-line import/prefer-default-export
export const CREATE_DOCTOR_SPECIALTY_MUTATION = gql`
  mutation CREATE_DOCTOR_SPECIALTY_MUTATION(
    $data: DoctorSpecialtyCreateInput!
  ) {
    createDoctorSpecialty(data: $data) {
      specialtycode
      doctorcode
    }
  }
`;

export const DELETE_DOCTOR_SPECIALTY_MUTATION = gql`
  mutation DELETE_DOCTOR_SPECIALTY_MUTATION(
    $where: DoctorSpecialtyWhereUniqueInput!
  ) {
    deleteDoctorSpecialty(where: $where) {
      doctorcode
      specialtycode
    }
  }
`;

export const DELETE_MANY_DOCTOR_SPECIALTIES_MUTATION = gql`
  mutation DELETE_MANY_DOCTOR_SPECIALTIES_MUTATION(
    $where: DoctorSpecialtyWhereInput!
  ) {
    deleteManyDoctorSpecialty(where: $where) {
      count
    }
  }
`;
