import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { format } from "date-fns";
import { useLazyQuery } from "@apollo/client";

import { Title, Field, Icon, Button } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { SINGLE_EXAMINEE_QUERY } from "../../../../graphql";
import { calculateAge, convertTimeZone } from "../../../../utils";

import { usePermissions } from "../../../../hooks";
import "./ExamineeHeader.scss";

const ExamineeHeader = ({ chartnbr, loading, editing, toggleEditing }) => {
  const [getExaminee, { data: ExamineeData }] = useLazyQuery(
    SINGLE_EXAMINEE_QUERY
  );
  useEffect(() => {
    if (chartnbr) {
      getExaminee({
        variables: {
          where: {
            chartnbr: parseInt(chartnbr, 10),
          },
        },
      });
    }
  }, [chartnbr, getExaminee]);

  const examinee = useMemo(() => ExamineeData?.examinee || null, [
    ExamineeData,
  ]);
  const hasPermission = usePermissions([
    "AllAdminFunc",
    "Accounting",
    "Cust Service",
    "QC",
  ]);

  return (
    <header className="examinee-head">
      <div className="examinee-header">
        <div>
          <Title size={4}>
            {[examinee?.prefix, examinee?.firstname, examinee?.lastname]
              .filter(Boolean)
              .join(" ")}
          </Title>
        </div>

        {hasPermission && (
          <div>
            <Field className="case-header-field-group" kind="group">
              <Button.Group hasAddons>
                {/profile/.test(document.location.pathname) && (
                  <Button
                    color="primary"
                    disabled={loading}
                    size="small"
                    type="button"
                    onClick={toggleEditing}
                  >
                    <span>Edit</span>
                  </Button>
                )}
                {/profile/.test(document.location.pathname) && (
                  <Button
                    color="success"
                    disabled={!editing || loading}
                    size="small"
                    state={loading ? "loading" : ""}
                    type="submit"
                  >
                    <span>Save</span>
                  </Button>
                )}
                {/cases/.test(document.location.pathname) && (
                  <Button
                    color="primary"
                    disabled={loading}
                    size="small"
                    state={loading ? "loading" : ""}
                    type="submit"
                  >
                    <Icon>
                      <FontAwesomeIcon icon="plus" />
                    </Icon>
                    <span>Add New Case</span>
                  </Button>
                )}
              </Button.Group>
            </Field>
          </div>
        )}

        <div className="examinee-info">
          {examinee?.addr1 && (
            <div>
              <p>
                <b>Address 1</b>
                &nbsp;
                {examinee.addr1}
              </p>
            </div>
          )}
          {examinee?.addr2 && (
            <div>
              <p>
                <b>Address 2</b>
                &nbsp;
                {examinee.addr2}
              </p>
            </div>
          )}
          {examinee?.city && (
            <div>
              <p>
                <b>City</b>
                &nbsp;
                {examinee.city}
              </p>
            </div>
          )}
          {examinee?.state && (
            <div>
              <p>
                <b>State</b>
                &nbsp;
                {examinee.state}
              </p>
            </div>
          )}
          {examinee?.zip && (
            <div>
              <p>
                <b>Zip</b>
                &nbsp;
                {examinee.zip}
              </p>
            </div>
          )}
          {examinee?.county && (
            <div>
              <p>
                <b>County</b>
                &nbsp;
                {examinee.county}
              </p>
            </div>
          )}
          {examinee?.phone1 && (
            <div>
              <p>
                <b>Phone</b>
                &nbsp;
                {examinee.phone1}
              </p>
            </div>
          )}
          {examinee?.SSN && (
            <div>
              <p>
                <b>SSN</b>
                &nbsp;
                {examinee?.SSN}
              </p>
            </div>
          )}
          {examinee?.sex && (
            <div>
              <p>
                <b>Sex</b>
                &nbsp;
                {examinee?.sex}
              </p>
            </div>
          )}
          {examinee?.DOB && (
            <div>
              <p>
                <b>
                  {`Birth Date (Age ${calculateAge(new Date(examinee.DOB))})`}
                </b>
                &nbsp;
                {format(convertTimeZone(examinee?.DOB), "MM/dd/yyyy")}
              </p>
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

ExamineeHeader.propTypes = {
  chartnbr: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  loading: PropTypes.bool,
  editing: PropTypes.bool,
  toggleEditing: PropTypes.func,
};

ExamineeHeader.defaultProps = {
  loading: false,
  editing: false,
  toggleEditing: () => {},
};

export default ExamineeHeader;
