import { Container, Title } from "rbx";
import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useQueryParams } from "../../../../hooks";
import InformationHeader from "../InformationHeader/InformationHeader";

const ReferralHeader = ({ mode }) => {
  const [method] = useQueryParams("method");

  // console.log("PARAMS: ", method)
  return (
    <React.Fragment>
      <nav className="navigation-bar">
        <Container fluid style={{ paddingLeft: "1rem" }}>
          <div className="navigation">
            <div className="navigation-start">
              <div className="navigation-item" />
              <Link
                className="navigation-item navigation-item--brand"
                style={{ display: "flex", pointerEvents: "none" }}
                to="/"
              >
                <Title size={6} textColor="white">
                  Enterprise Referral Form
                </Title>
              </Link>
            </div>
            <div className="navigation-end">
              <a
                className="navigation-item"
                href="https://isgvalue.com/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <img alt="ISG" src="/isg-logo.png" style={{ height: 40 }} />
              </a>
            </div>
          </div>
        </Container>
      </nav>
      {!method && (
        <InformationHeader mode={mode} />
      )}
    </React.Fragment>
  )
}

ReferralHeader.propTypes = {
  mode: PropTypes.string.isRequired
};

export default ReferralHeader;
