import { useState, useEffect } from "react";

import { useApolloClient } from "@apollo/client";

import { ALL_DR_DO_NOT_USE_BY_CASE_QUERY } from "../graphql";

const useDoctorsNotToUse = (casenbr) => {
  const client = useApolloClient();
  const [doctorsNotToUse, setDoctorsNotToUse] = useState([]);

  useEffect(() => {
    (async () => {
      if (casenbr) {
        const { data: DoctorsNotToUseData } = await client.query({
          query: ALL_DR_DO_NOT_USE_BY_CASE_QUERY,
          variables: {
            where: { casenbr: parseInt(casenbr, 10) },
          },
          fetchPolicy: "network-only",
        });
        const doctorsNotToUseResult = DoctorsNotToUseData?.doctorNotToUseByCase;
        if (Array.isArray(doctorsNotToUseResult)) {
          setDoctorsNotToUse(doctorsNotToUseResult.map((dr) => dr.doctorcode));
        }
      }
    })();
  }, [casenbr, client]);

  return doctorsNotToUse;
};

export default useDoctorsNotToUse;
