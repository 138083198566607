import React from "react";
import { format } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Generic, Icon } from "rbx";

const COLUMNS = [
  {
    Header: "Name",
    accessor: "DocumentName",
    id: "DocumentName",
  },
  {
    Header: "Description",
    accessor: "DocumentDescription",
    id: "DocumentDescription",
  },
  {
    Header: "Publish On Web",
    accessor: "PublishOnWeb",
    id: "PublishOnWeb",
    Cell: ({ cell }) => (cell.value ? "Yes" : "No"),
    maxWidth: "10%",
  },
  {
    Header: "Created Date",
    accessor: "CreatedDate",
    id: "CreatedDate",
    Cell: ({ cell }) =>
      cell?.value ? format(new Date(cell.value), "MM/dd/yyyy hh:mm") : "--",
    maxWidth: "10%",
  },
  {
    Header: "Created By",
    accessor: "CreatedBy",
    id: "CreatedBy",
    maxWidth: "10%",
  },
  {
    Header: "Archive",
    accessor: "Archive",
    id: "Archive",
    Cell: ({ cell }) => (cell.value ? "Yes" : "No"),
    maxWidth: "10%",
  },
];

export const generateColumns = (handleDelete) => [
  {
    Header: "",
    id: "delete",
    Cell: (cell) => (
      <Generic
        style={{ textAlign: "center" }}
        type="button"
        onClick={(e) => {
          e.stopPropagation();
          handleDelete(cell.row.original);
        }}
      >
        <Icon color="danger" size="small">
          <FontAwesomeIcon icon="times" />
        </Icon>
      </Generic>
    ),
  },
  ...COLUMNS,
];

export default COLUMNS;
