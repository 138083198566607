import React from "react";
import PropTypes from "prop-types";
import { Field, Control, Label, Input } from "rbx";

import ApolloDocumentTypeSelect from "../../../../components/ApolloDocumentTypeSelect/ApolloDocumentTypeSelect";
import DropZoneFileUploader from "../../../../components/DropZoneFileUploader";

const CaseAttachExternalDocumentForm = ({ inputs, uploadPath, onChange }) => (
  <div className="case-attach-external-document-form">
    {inputs &&
      inputs.files &&
      inputs.files.map((file) => (
        <React.Fragment key={file.file}>
          <Field className="grid-override" kind="group">
            <Control expanded>
              <Label>Description</Label>
              <Input
                required
                autoComplete="new"
                name="files.description"
                size="small"
                type="text"
                value={file.description}
                onChange={(e) =>
                  onChange(e.target.name, e.target.value, file.id)
                }
              />
            </Control>
            <ApolloDocumentTypeSelect
              required
              name="documenttype"
              value={inputs.documenttype}
              onChange={onChange}
            />
          </Field>
          <Field>
            <Control expanded>
              <Label>File</Label>
              <Input
                disabled
                readOnly
                required
                autoComplete="new"
                name="file"
                size="small"
                type="text"
                value={file.file}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
            </Control>
          </Field>
        </React.Fragment>
      ))}
    <Field className="span-2">
      <DropZoneFileUploader
        multiple
        dirPath={uploadPath}
        name="files"
        value={inputs.files}
        onChange={onChange}
      />
    </Field>
  </div>
);

CaseAttachExternalDocumentForm.propTypes = {
  inputs: PropTypes.object.isRequired,
  uploadPath: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default CaseAttachExternalDocumentForm;
