import React from "react";
import PropTypes from "prop-types";
import { Column, Field, Control, Label, Input } from "rbx";
import CompanySelect from "../../../../components/CompanySelect";
import ClientTypeSelect from "../../../../components/ClientTypeSelect";
import StateSelect from "../../../../components/StateSelect";

import PhoneNumberInput from "../../../../components/PhoneNumberInput";
import ZipInput from "../../../../components/ZipInput";
import PrefixSelect from "../../../../components/PrefixSelect";
import StatusSelect from "../../../../components/StatusSelect";

const ClientForm = ({ companycode, inputs, onChange }) => (
  <Column.Group>
    <Column>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gridColumnGap: "0.75rem",
        }}
      >
        <Field>
          <Control expanded>
            <CompanySelect
              disabled
              fullWidth
              label="Company"
              name="companycode"
              value={companycode}
            />
          </Control>
        </Field>
        <Field>
          <ClientTypeSelect
            label="Client Type"
            name="TypeCode"
            size="small"
            value={inputs.TypeCode}
            onChange={onChange}
          />
        </Field>
      </div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "0.5fr 1fr 1fr 0.5fr",
          gridColumnGap: "0.75rem",
        }}
      >
        <Field>
          <PrefixSelect
            label="Prefix"
            name="prefix"
            value={inputs.prefix}
            onChange={onChange}
          />
        </Field>
        <Field>
          <Control>
            <Label>First Name</Label>
            <Input
              required
              autoComplete="new"
              maxLength={50}
              name="firstname"
              size="small"
              type="text"
              value={inputs.firstname}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </Control>
        </Field>
        <Field>
          <Control expanded>
            <Label>Last Name</Label>
            <Input
              required
              autoComplete="new"
              maxLength={50}
              name="lastname"
              size="small"
              type="text"
              value={inputs.lastname}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </Control>
        </Field>
        <Field>
          <Control>
            <Label>Suffix</Label>
            <Input
              autoComplete="new"
              maxLength={50}
              name="suffix"
              size="small"
              type="text"
              value={inputs.suffix}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </Control>
        </Field>
      </div>
      <Field kind="group">
        <Control expanded>
          <Label>Address 1</Label>
          <Input
            required
            autoComplete="new"
            maxLength={50}
            name="addr1"
            size="small"
            type="text"
            value={inputs.addr1}
            onChange={(e) => onChange(e.target.name, e.target.value)}
          />
        </Control>
        <Control expanded>
          <Label>Address 2</Label>
          <Input
            autoComplete="new"
            maxLength={50}
            name="addr2"
            size="small"
            type="text"
            value={inputs.addr2}
            onChange={(e) => onChange(e.target.name, e.target.value)}
          />
        </Control>
      </Field>

      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr 1fr",
          gridColumnGap: "0.75rem",
        }}
      >
        <Field>
          <Control>
            <Label>City</Label>
            <Input
              required
              autoComplete="new"
              maxLength={50}
              name="city"
              size="small"
              type="text"
              value={inputs.city}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </Control>
        </Field>
        <Field>
          <StateSelect
            required
            label="State"
            name="state"
            size="small"
            value={inputs.state}
            onChange={onChange}
          />
        </Field>
        <Field>
          <ZipInput
            required
            state={inputs.state}
            value={inputs.zip || ""}
            onChange={onChange}
          />
        </Field>
      </div>
    </Column>
    <Column>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gridColumnGap: "0.75rem",
        }}
      >
        <Field>
          <Control expanded>
            <Label>Title</Label>
            <Input
              autoComplete="new"
              maxLength={50}
              name="title"
              size="small"
              type="text"
              value={inputs.title}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </Control>
        </Field>
        <Field>
          <Control>
            <StatusSelect
              label="Status"
              name="status"
              value={inputs.status}
              onChange={onChange}
            />
          </Control>
        </Field>
      </div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gridColumnGap: "0.75rem",
        }}
      >
        <Field>
          <Control>
            <Label>Email</Label>
            <Input
              required
              autoComplete="new"
              maxLength={70}
              name="email"
              size="small"
              type="email"
              value={inputs.email}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </Control>
        </Field>
        <Field>
          <Control>
            <Label>Fax</Label>
            <PhoneNumberInput
              name="fax"
              value={inputs.fax}
              onChange={onChange}
            />
          </Control>
        </Field>
      </div>
      <Field kind="group" style={{ gridColumn: "1 / 3" }}>
        <Control expanded>
          <Label>Phone 1</Label>
          <PhoneNumberInput
            required
            name="phone1"
            value={inputs.phone1}
            onChange={onChange}
          />
        </Control>
        <Control>
          <Label>Ext</Label>
          <Input
            autoComplete="new"
            maxLength={50}
            name="phone1ext"
            size="small"
            type="text"
            value={inputs.phone1ext}
            onChange={(e) => onChange(e.target.name, e.target.value)}
          />
        </Control>
      </Field>
      <Field kind="group" style={{ gridColumn: "3 / 5" }}>
        <Control expanded>
          <Label>Phone 2</Label>
          <PhoneNumberInput
            name="phone2"
            value={inputs.phone2}
            onChange={onChange}
          />
        </Control>
        <Control>
          <Label>Ext</Label>
          <Input
            autoComplete="new"
            maxLength={14}
            name="phone2ext"
            size="small"
            type="text"
            value={inputs.phone2ext}
            onChange={(e) => onChange(e.target.name, e.target.value)}
          />
        </Control>
      </Field>
    </Column>
  </Column.Group>
);
ClientForm.propTypes = {
  companycode: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  inputs: PropTypes.object,
  onChange: PropTypes.func,
};

ClientForm.defaultProps = {
  inputs: {},
  onChange: (e) => e,
};
export default ClientForm;
