import { gql } from "@apollo/client";

// eslint-disable-next-line import/prefer-default-export
export const LIST_CASE_TRACKER_ITEMS_QUERY = gql`
  query caseTrackerItems($where: CaseItemWhereInput) {
    caseTrackerItems(where: $where) {
      caseCount
      rushCount
      statuscode
      statusdesc
    }
  }
`;
