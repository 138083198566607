import omit from "./omit";
import isNil from "./isNil";

const convertInputToVariables = (
  variables,
  keys,
  adding = false,
  connectFields = []
) =>
  omit(
    keys.reduce((acc, curr) => {
      if (!isNil(variables[curr])) {
        acc[curr] = adding ? variables[curr] : { set: variables[curr] };
      }
      return acc;
    }, {}),
    connectFields
  );

export default convertInputToVariables;
