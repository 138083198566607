import React from "react";
import { Switch, Route } from "react-router-dom";
import {
  DoctorSpecialtiesListPage,
  IssuesListPage,
  DoctorDegreesListPage,
  OfficesListPage,
  ProblemsListPage,
  OtherPartyTypes,
  Products,
  ProviderTypesListPage,
  QueuesList,
  ReportStatusCodesListPage,
  ServicesListPage,
  UsersListPage,
  UserGroupsListPage,
  UserFunctionsListPage,
  CaseTypesListPage,
  OtherPartyListPage,
  OtherPartyEditPage,
  DocumentTypesListPage,
  DocumentLocationsListPage,
  NoteTypesListPage,
  BucketsPage,
  StandardNotesListPage,
  ExceptionsListPage,
  ExceptionPage,
  CaseTypeDocumentsListPage,
  BusinessLineListPage,
  ProcessorsPage
} from "./routes";

const SetupRouter = () => (
  <Switch>
    {/* Offices */}
    <Route path="/setup/offices/:officecode">
      <OfficesListPage />
    </Route>
    <Route path="/setup/offices">
      <OfficesListPage />
    </Route>
    {/* Specialties */}
    <Route path="/setup/doctor-specialties/:specialtycode">
      <DoctorSpecialtiesListPage />
    </Route>
    <Route path="/setup/doctor-specialties">
      <DoctorSpecialtiesListPage />
    </Route>
    {/* Degrees */}
    <Route path="/setup/doctor-degrees/:degreecode">
      <DoctorDegreesListPage />
    </Route>
    <Route path="/setup/doctor-degrees">
      <DoctorDegreesListPage />
    </Route>
    {/* Issues */}
    <Route path="/setup/issues/:issuecode">
      <IssuesListPage />
    </Route>
    <Route path="/setup/issues">
      <IssuesListPage />
    </Route>
    {/* Provider Types */}
    <Route path="/setup/provider-types/:ProvTypeCode">
      <ProviderTypesListPage />
    </Route>
    <Route path="/setup/provider-types">
      <ProviderTypesListPage />
    </Route>
    {/* Problems */}
    <Route path="/setup/problems/:problemcode">
      <ProblemsListPage />
    </Route>
    <Route path="/setup/problems">
      <ProblemsListPage />
    </Route>
    {/* Other Party Types */}
    <Route path="/setup/other-party-types/:type?">
      <OtherPartyTypes />
    </Route>
    <Route path="/setup/document-locations/:location?">
      <DocumentLocationsListPage />
    </Route>

    {/* Report Status Codes */}
    <Route path="/setup/report-status-codes/:rptcode">
      <ReportStatusCodesListPage />
    </Route>
    <Route path="/setup/report-status-codes">
      <ReportStatusCodesListPage />
    </Route>
    {/* Products */}
    <Route path="/setup/products/:prodcode">
      <Products />
    </Route>
    <Route path="/setup/products">
      <Products />
    </Route>
    {/* Case Types */}
    <Route path="/setup/case-types/:code">
      <CaseTypesListPage />
    </Route>
    <Route path="/setup/users">
      <UsersListPage />
    </Route>
    <Route path="/setup/user-groups">
      <UserGroupsListPage />
    </Route>
    <Route path="/setup/user-functions">
      <UserFunctionsListPage />
    </Route>
    <Route path="/setup/processors">
      <ProcessorsPage /> 
    </Route>
    <Route path="/setup/case-types">
      <CaseTypesListPage />
    </Route>
    {/* Notes */}
    <Route path="/setup/standard-notes/:NoteID?">
      <StandardNotesListPage />
    </Route>
    {/* Queues */}
    <Route path="/setup/queues/:statuscode">
      <QueuesList />
    </Route>
    <Route path="/setup/queues">
      <QueuesList />
    </Route>
    {/* Other Party */}
    <Route path="/setup/other-party/:doctorcode/:tab">
      <OtherPartyEditPage />
    </Route>
    <Route path="/setup/other-party">
      <OtherPartyListPage />
    </Route>
    {/* Services */}
    <Route path="/setup/services">
      <ServicesListPage />
    </Route>
    {/* Document Type */}
    <Route path="/setup/document-types/:type">
      <DocumentTypesListPage />
    </Route>
    <Route path="/setup/document-types">
      <DocumentTypesListPage />
    </Route>
    {/* Note Type */}
    <Route path="/setup/note-types/:type">
      <NoteTypesListPage />
    </Route>
    <Route path="/setup/note-types">
      <NoteTypesListPage />
    </Route>
    {/* Exceptions */}
    <Route path="/setup/exceptions/:ExceptionID">
      <ExceptionPage />
    </Route>
    <Route path="/setup/exceptions">
      <ExceptionsListPage />
    </Route>
    {/* Buckets */}
    <Route path="/setup/buckets">
      <BucketsPage />
    </Route>
    {/* Case Type Documents */}
    <Route path="/setup/case-type-documents">
      <CaseTypeDocumentsListPage />
    </Route>
    {/* Business Lines */}
    <Route path="/setup/business-lines">
      <BusinessLineListPage />
    </Route>
  </Switch>
);

export default SetupRouter;
