import React, { useMemo } from "react";
import { Table, Box } from "rbx";
import { useQueryParams } from "../../../../hooks";

function DebugQueryParams() {
  const [mode] = useQueryParams("mode", "", true);
  const [email] = useQueryParams("email", "", true);
  const [referrer] = useQueryParams("referrer", "", true);
  const [claimId] = useQueryParams("claimId", "", true);
  const [claimantId] = useQueryParams("claimantId", "", true);
  const [sourceId] = useQueryParams("sourceId", "", true);
  const [rrId] = useQueryParams("rrId", "", true);
  const items = useMemo(
    () => [
      {
        name: "mode",
        value: mode,
      },
      {
        name: "email",
        value: email,
      },
      {
        name: "referrer",
        value: referrer,
      },
      {
        name: "claimId",
        value: claimId,
      },
      {
        name: "claimantId",
        value: claimantId,
      },
      {
        name: "sourceId",
        value: sourceId,
      },
      {
        name: "RRID",
        value: rrId,
      },
    ],
    [claimId, claimantId, email, mode, referrer, sourceId, rrId]
  );
  return (
    <Box
      className="data-table-container table-container"
      style={{
        position: "fixed",
        bottom: 0,
        left: 0,
        zIndex: 9999,
        padding: 0,
        margin: 15,
      }}
    >
      <Table>
        <Table.Head>
          <Table.Row>
            <Table.Heading>Name</Table.Heading>
            <Table.Heading>Value</Table.Heading>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {items.map(({ name, value }) => (
            <Table.Row key={name}>
              <Table.Cell>{name}</Table.Cell>
              <Table.Cell>{value || "None"}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </Box>
  );
}

export default DebugQueryParams;
