import { gql } from "@apollo/client";

export const CREATE_CE_CLAIM_MUTATION = gql`
  mutation CREATE_CE_CLAIM_MUTATION($data: ClaimsCreateInput!) {
    createClaims(data: $data) {
      CentralEligibilityID
      CompanyName
    }
  }
`;

export const UPDATE_CE_CLAIM_MUTATION = gql`
  mutation UPDATE_CE_CLAIM_MUTATION(
    $data: ClaimsUpdateInput!
    $where: ClaimsWhereUniqueInput!
  ) {
    updateClaims(data: $data, where: $where) {
      CentralEligibilityID
      CompanyName
    }
  }
`;

export const DELETE_CE_CLAIM_MUTATION = gql`
  mutation DELETE_CE_CLAIM_MUTATION($where: ClaimsWhereUniqueInput!) {
    deleteClaims(where: $where) {
      CentralEligibilityID
    }
  }
`;
