import React, { useEffect } from "react";
import PropTypes from "prop-types";

import { useLazyQuery } from "@apollo/client";
import { Box, Generic } from "rbx";
import { EXAMINEE_NO_SHOW_QUERY } from "../../../../graphql";
import { omit } from "../../../../utils";

const CaseExamineeNoShow = ({ casenbr, claimnbr, chartnbr }) => {
  const [getExamineeNoShow, { data: ExamineeNoShowData }] = useLazyQuery(
    EXAMINEE_NO_SHOW_QUERY
  );

  useEffect(() => {
    if (casenbr && claimnbr && chartnbr) {
      getExamineeNoShow({
        variables: {
          where: {
            casenbr: parseInt(casenbr, 10),
            claimnbr,
            chartnbr: parseInt(chartnbr, 10),
          },
        },
      });
    }
  }, [casenbr, chartnbr, claimnbr, getExamineeNoShow]);

  const examineeNoShow = ExamineeNoShowData?.examineeNoShow || {
    currentCase: 0,
    claimNumber: 0,
    allCases: 0,
  };

  if (
    !Object.keys(omit(examineeNoShow, ["__typename"])).some(
      (key) => examineeNoShow[key]
    )
  )
    return null;

  return (
    <Box>
      <Generic size={6} textColor="danger">
        {[
          "Examinee has no showed",
          examineeNoShow.currentCase,
          "time(s) on this case,",
          examineeNoShow.claimNumber,
          "time(s) on this claim number, and",
          examineeNoShow.allCases,
          "time(s) on all cases.",
        ].join(" ")}
      </Generic>
    </Box>
  );
};

CaseExamineeNoShow.propTypes = {
  casenbr: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  claimnbr: PropTypes.string.isRequired,
  chartnbr: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
};

export default CaseExamineeNoShow;
