import React from "react";
import PropTypes from "prop-types";
import { Tab } from "rbx";
import { Link, useLocation } from "react-router-dom";
import { useHasAssociatedCases } from "../../../../hooks";

const CaseTabs = ({ casenbr }) => {
  const location = useLocation();
  const hasAssociatedCases = useHasAssociatedCases(casenbr);

  return (
    <Tab.Group kind="boxed">
      <Tab
        active={location.pathname.includes("profile")}
        as={Link}
        to={`/cases/${casenbr}/profile`}
      >
        Profile
      </Tab>
      <Tab
        active={location.pathname.includes("examinee")}
        as={Link}
        to={`/cases/${casenbr}/examinee`}
      >
        Examinee
      </Tab>
      <Tab
        active={location.pathname.includes("case-parameters")}
        as={Link}
        to={`/cases/${casenbr}/case-parameters`}
      >
        Case Parameters
      </Tab>
      <Tab
        active={location.pathname.includes("scheduling")}
        as={Link}
        to={`/cases/${casenbr}/scheduling`}
      >
        Scheduling
      </Tab>
      <Tab
        active={location.pathname.includes("case-history")}
        as={Link}
        to={`/cases/${casenbr}/case-history`}
      >
        Case History
      </Tab>
      <Tab
        active={location.pathname.includes("attorney")}
        as={Link}
        to={`/cases/${casenbr}/attorney`}
      >
        Attorney/CC&apos;s
      </Tab>
      {/* <Tab
      active={location.pathname.includes("document-defaults")}
      as={Link}
      to={`/cases/${casenbr}/document-defaults`}
    >
      Document Defaults
    </Tab> */}
      <Tab
        active={location.pathname.includes("documents")}
        as={Link}
        to={`/cases/${casenbr}/documents`}
      >
        Documents
      </Tab>
      <Tab
        active={location.pathname.includes("reports")}
        as={Link}
        to={`/cases/${casenbr}/reports`}
      >
        Reports
      </Tab>
      <Tab
        active={location.pathname.includes("accounting")}
        as={Link}
        to={`/cases/${casenbr}/accounting`}
      >
        Accounting
      </Tab>
      <Tab
        active={location.pathname.includes("user-fields")}
        as={Link}
        to={`/cases/${casenbr}/user-fields`}
      >
        User Fields
      </Tab>
      <Tab
        active={location.pathname.includes("web-portal")}
        as={Link}
        to={`/cases/${casenbr}/web-portal`}
      >
        Web Portal
      </Tab>
      {hasAssociatedCases && (
        <Tab
          active={location.pathname.includes("associated-cases")}
          as={Link}
          to={`/cases/${casenbr}/associated-cases`}
        >
          Associated Cases
        </Tab>
      )}
    </Tab.Group>
  );
};

CaseTabs.propTypes = {
  casenbr: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default CaseTabs;
