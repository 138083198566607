const VIEW_FIELDS = [
  {
    name: "casenbr",
    type: "Int",
    entity: "view",
  },
  {
    name: "claimnbr",
    type: "String",
    entity: "view",
  },
  {
    name: "examineeaddr1",
    type: "String",
    entity: "view",
  },
  {
    name: "examineeaddr2",
    type: "String",
    entity: "view",
  },
  {
    name: "examineecitystatezip",
    type: "String",
    entity: "view",
  },
  {
    name: "SSN",
    type: "String",
    entity: "view",
  },
  {
    name: "clientname",
    type: "String",
    entity: "view",
  },
  {
    name: "company",
    type: "String",
    entity: "view",
  },
  {
    name: "clientphone",
    type: "String",
    entity: "view",
  },
  {
    name: "doctoraddr1",
    type: "String",
    entity: "view",
  },
  {
    name: "doctoraddr2",
    type: "String",
    entity: "view",
  },
  {
    name: "doctorcitystatezip",
    type: "String",
    entity: "view",
  },
  {
    name: "ApptDate",
    type: "DateTime",
    entity: "view",
  },
  {
    name: "Appttime",
    type: "DateTime",
    entity: "view",
  },
  {
    name: "examineename",
    type: "String",
    entity: "view",
  },
  {
    name: "examineephone",
    type: "String",
    entity: "view",
  },
  {
    name: "sex",
    type: "String",
    entity: "view",
  },
  {
    name: "DOB",
    type: "DateTime",
    entity: "view",
  },
  {
    name: "doctorphone",
    type: "String",
    entity: "view",
  },
  {
    name: "clientaddr1",
    type: "String",
    entity: "view",
  },
  {
    name: "clientaddr2",
    type: "String",
    entity: "view",
  },
  {
    name: "clientcitystatezip",
    type: "String",
    entity: "view",
  },
  {
    name: "clientfax",
    type: "String",
    entity: "view",
  },
  {
    name: "clientemail",
    type: "String",
    entity: "view",
  },
  {
    name: "scheduler",
    type: "String",
    entity: "view",
  },
  {
    name: "marketer",
    type: "String",
    entity: "view",
  },
  {
    name: "datecalledin",
    type: "DateTime",
    entity: "view",
  },
  {
    name: "DOI",
    type: "DateTime",
    entity: "view",
  },
  {
    name: "allegation",
    type: "String",
    entity: "view",
  },
  {
    name: "notes",
    type: "String",
    entity: "view",
  },
  {
    name: "casetype",
    type: "Int",
    entity: "view",
  },
  {
    name: "examineesalutation",
    type: "String",
    entity: "view",
  },
  {
    name: "status",
    type: "Int",
    entity: "view",
  },
  {
    name: "calledinby",
    type: "String",
    entity: "view",
  },
  {
    name: "chartnbr",
    type: "Int",
    entity: "view",
  },
  {
    name: "clientsalutation",
    type: "String",
    entity: "view",
  },
  {
    name: "doctorsalutation",
    type: "String",
    entity: "view",
  },
  {
    name: "insidedr",
    type: "Boolean",
    entity: "view",
  },
  {
    name: "doctoremail",
    type: "String",
    entity: "view",
  },
  {
    name: "doctorfax",
    type: "String",
    entity: "view",
  },
  {
    name: "faxdrschedule",
    type: "Boolean",
    entity: "view",
  },
  {
    name: "medrcdletter",
    type: "Boolean",
    entity: "view",
  },
  {
    name: "drletter",
    type: "Boolean",
    entity: "view",
  },
  {
    name: "reportverbal",
    type: "Boolean",
    entity: "view",
  },
  {
    name: "medsrecd",
    type: "DateTime",
    entity: "view",
  },
  {
    name: "Pattorneyname",
    type: "String",
    entity: "view",
  },
  {
    name: "Pattorneysalutation",
    type: "String",
    entity: "view",
  },
  {
    name: "Pattorneycompany",
    type: "String",
    entity: "view",
  },
  {
    name: "Pattorneyaddr1",
    type: "String",
    entity: "view",
  },
  {
    name: "Pattorneyaddr2",
    type: "String",
    entity: "view",
  },
  {
    name: "Pattorneycitystatezip",
    type: "String",
    entity: "view",
  },
  {
    name: "Pattorneyphone",
    type: "String",
    entity: "view",
  },
  {
    name: "Pattorneyfax",
    type: "String",
    entity: "view",
  },
  {
    name: "Pattorneyemail",
    type: "String",
    entity: "view",
  },
  {
    name: "Dattorneyname",
    type: "String",
    entity: "view",
  },
  {
    name: "Dattorneysalutation",
    type: "String",
    entity: "view",
  },
  {
    name: "Dattorneycompany",
    type: "String",
    entity: "view",
  },
  {
    name: "Dattorneyaddr1",
    type: "String",
    entity: "view",
  },
  {
    name: "Dattorneyaddr2",
    type: "String",
    entity: "view",
  },
  {
    name: "Dattorneycitystatezip",
    type: "String",
    entity: "view",
  },
  {
    name: "Dattorneyphone",
    type: "String",
    entity: "view",
  },
  {
    name: "Dattorneyfax",
    type: "String",
    entity: "view",
  },
  {
    name: "Dattorneyemail",
    type: "String",
    entity: "view",
  },
  {
    name: "fax",
    type: "String",
    entity: "view",
  },
  {
    name: "DParaLegalname",
    type: "String",
    entity: "view",
  },
  {
    name: "DParaLegalsalutation",
    type: "String",
    entity: "view",
  },
  {
    name: "DParaLegalcompany",
    type: "String",
    entity: "view",
  },
  {
    name: "DParaLegaladdr1",
    type: "String",
    entity: "view",
  },
  {
    name: "DParaLegaladdr2",
    type: "String",
    entity: "view",
  },
  {
    name: "DParaLegalcitystatezip",
    type: "String",
    entity: "view",
  },
  {
    name: "DParaLegalphone",
    type: "String",
    entity: "view",
  },
  {
    name: "DParaLegalemail",
    type: "String",
    entity: "view",
  },
  {
    name: "DParaLegalfax",
    type: "String",
    entity: "view",
  },
  {
    name: "typemedsrecd",
    type: "String",
    entity: "view",
  },
  {
    name: "plaintiffattorneycode",
    type: "Int",
    entity: "view",
  },
  {
    name: "defenseattorneycode",
    type: "Int",
    entity: "view",
  },
  {
    name: "servicecode",
    type: "Int",
    entity: "view",
  },
  {
    name: "faxPattny",
    type: "Boolean",
    entity: "view",
  },
  {
    name: "faxdoctor",
    type: "Boolean",
    entity: "view",
  },
  {
    name: "faxclient",
    type: "Boolean",
    entity: "view",
  },
  {
    name: "emailclient",
    type: "Boolean",
    entity: "view",
  },
  {
    name: "emaildoctor",
    type: "Boolean",
    entity: "view",
  },
  {
    name: "emailPattny",
    type: "Boolean",
    entity: "view",
  },
  {
    name: "invoicedate",
    type: "DateTime",
    entity: "view",
  },
  {
    name: "invoiceamt",
    type: "Float",
    entity: "view",
  },
  {
    name: "commitdate",
    type: "DateTime",
    entity: "view",
  },
  {
    name: "WCBNbr",
    type: "String",
    entity: "view",
  },
  {
    name: "specialinstructions",
    type: "String",
    entity: "view",
  },
  {
    name: "priority",
    type: "String",
    entity: "view",
  },
  {
    name: "servicedesc",
    type: "String",
    entity: "view",
  },
  {
    name: "caseusdvarchar1",
    type: "String",
    entity: "view",
  },
  {
    name: "caseusdvarchar2",
    type: "String",
    entity: "view",
  },
  {
    name: "caseusddate1",
    type: "DateTime",
    entity: "view",
  },
  {
    name: "caseusddate2",
    type: "DateTime",
    entity: "view",
  },
  {
    name: "caseusdtext1",
    type: "String",
    entity: "view",
  },
  {
    name: "caseusdtext2",
    type: "String",
    entity: "view",
  },
  {
    name: "caseusdint1",
    type: "Int",
    entity: "view",
  },
  {
    name: "caseusdint2",
    type: "Float",
    entity: "view",
  },
  {
    name: "caseusdmoney1",
    type: "Float",
    entity: "view",
  },
  {
    name: "caseusdmoney2",
    type: "Float",
    entity: "view",
  },
  {
    name: "clienttitle",
    type: "String",
    entity: "view",
  },
  {
    name: "clientprefix",
    type: "String",
    entity: "view",
  },
  {
    name: "clientsuffix",
    type: "String",
    entity: "view",
  },
  {
    name: "clientusdvarchar1",
    type: "String",
    entity: "view",
  },
  {
    name: "clientusdvarchar2",
    type: "String",
    entity: "view",
  },
  {
    name: "clientusddate1",
    type: "DateTime",
    entity: "view",
  },
  {
    name: "clientusddate2",
    type: "DateTime",
    entity: "view",
  },
  {
    name: "clientusdtext1",
    type: "String",
    entity: "view",
  },
  {
    name: "clientusdtext2",
    type: "String",
    entity: "view",
  },
  {
    name: "clientusdint1",
    type: "Int",
    entity: "view",
  },
  {
    name: "clientusdint2",
    type: "Int",
    entity: "view",
  },
  {
    name: "clientusdmoney1",
    type: "Float",
    entity: "view",
  },
  {
    name: "clientusdmoney2",
    type: "Float",
    entity: "view",
  },
  {
    name: "doctornotes",
    type: "String",
    entity: "view",
  },
  {
    name: "doctorprefix",
    type: "String",
    entity: "view",
  },
  {
    name: "doctorcorrespaddr1",
    type: "String",
    entity: "view",
  },
  {
    name: "doctorcorrespaddr2",
    type: "String",
    entity: "view",
  },
  {
    name: "doctorcorrespcitystatezip",
    type: "String",
    entity: "view",
  },
  {
    name: "doctorcorrespphone",
    type: "String",
    entity: "view",
  },
  {
    name: "doctorcorrespfax",
    type: "String",
    entity: "view",
  },
  {
    name: "doctorcorrespemail",
    type: "String",
    entity: "view",
  },
  {
    name: "qualifications",
    type: "String",
    entity: "view",
  },
  {
    name: "prepaid",
    type: "Boolean",
    entity: "view",
  },
  {
    name: "doctorcorrespcounty",
    type: "String",
    entity: "view",
  },
  {
    name: "doctorcounty",
    type: "String",
    entity: "view",
  },
  {
    name: "doctorvicinity",
    type: "String",
    entity: "view",
  },
  {
    name: "examineecounty",
    type: "String",
    entity: "view",
  },
  {
    name: "examineeprefix",
    type: "String",
    entity: "view",
  },
  {
    name: "examineeusdvarchar1",
    type: "String",
    entity: "view",
  },
  {
    name: "examineeusdvarchar2",
    type: "String",
    entity: "view",
  },
  {
    name: "examineeusddate1",
    type: "DateTime",
    entity: "view",
  },
  {
    name: "examineeusddate2",
    type: "DateTime",
    entity: "view",
  },
  {
    name: "examineeusdtext1",
    type: "String",
    entity: "view",
  },
  {
    name: "examineeusdtext2",
    type: "String",
    entity: "view",
  },
  {
    name: "examineeusdint1",
    type: "Int",
    entity: "view",
  },
  {
    name: "examineeusdint2",
    type: "Int",
    entity: "view",
  },
  {
    name: "examineeusdmoney1",
    type: "Float",
    entity: "view",
  },
  {
    name: "examineeusdmoney2",
    type: "Float",
    entity: "view",
  },
  {
    name: "doctorusdvarchar1",
    type: "String",
    entity: "view",
  },
  {
    name: "doctorusdvarchar2",
    type: "String",
    entity: "view",
  },
  {
    name: "doctorusddate1",
    type: "DateTime",
    entity: "view",
  },
  {
    name: "doctorusddate2",
    type: "DateTime",
    entity: "view",
  },
  {
    name: "doctorusdtext1",
    type: "String",
    entity: "view",
  },
  {
    name: "doctorusdtext2",
    type: "String",
    entity: "view",
  },
  {
    name: "doctorusdint1",
    type: "Int",
    entity: "view",
  },
  {
    name: "doctorusdint2",
    type: "Int",
    entity: "view",
  },
  {
    name: "doctorusdmoney1",
    type: "Float",
    entity: "view",
  },
  {
    name: "doctorusdmoney2",
    type: "Float",
    entity: "view",
  },
  {
    name: "schedulenotes",
    type: "String",
    entity: "view",
  },
  {
    name: "requesteddoc",
    type: "String",
    entity: "view",
  },
  {
    name: "companyusdvarchar1",
    type: "String",
    entity: "view",
  },
  {
    name: "companyusdvarchar2",
    type: "String",
    entity: "view",
  },
  {
    name: "companyusddate1",
    type: "DateTime",
    entity: "view",
  },
  {
    name: "companyusddate2",
    type: "DateTime",
    entity: "view",
  },
  {
    name: "companyusdtext1",
    type: "String",
    entity: "view",
  },
  {
    name: "companyusdtext2",
    type: "String",
    entity: "view",
  },
  {
    name: "companyusdint1",
    type: "Int",
    entity: "view",
  },
  {
    name: "companyusdint2",
    type: "Int",
    entity: "view",
  },
  {
    name: "companyusdmoney1",
    type: "Float",
    entity: "view",
  },
  {
    name: "companyusdmoney2",
    type: "Float",
    entity: "view",
  },
  {
    name: "doctorwcnbr",
    type: "String",
    entity: "view",
  },
  {
    name: "casetypedesc",
    type: "String",
    entity: "view",
  },
  {
    name: "location",
    type: "String",
    entity: "view",
  },
  {
    name: "doctorlocation",
    type: "String",
    entity: "view",
  },
  {
    name: "internalcasenbr",
    type: "String",
    entity: "view",
  },
  {
    name: "doctordegree",
    type: "String",
    entity: "view",
  },
  {
    name: "specialtydesc",
    type: "String",
    entity: "view",
  },
  {
    name: "chartnotes",
    type: "String",
    entity: "view",
  },
  {
    name: "examineefax",
    type: "String",
    entity: "view",
  },
  {
    name: "examineeemail",
    type: "String",
    entity: "view",
  },
  {
    name: "examineeinsured",
    type: "String",
    entity: "view",
  },
  {
    name: "clientcode",
    type: "Int",
    entity: "view",
  },
  {
    name: "doctorcode",
    type: "Int",
    entity: "view",
  },
  {
    name: "feecode",
    type: "Int",
    entity: "view",
  },
  {
    name: "companycode",
    type: "Int",
    entity: "view",
  },
  {
    name: "clientnotes",
    type: "String",
    entity: "view",
  },
  {
    name: "companynotes",
    type: "String",
    entity: "view",
  },
  {
    name: "billaddr1",
    type: "String",
    entity: "view",
  },
  {
    name: "billaddr2",
    type: "String",
    entity: "view",
  },
  {
    name: "billcity",
    type: "String",
    entity: "view",
  },
  {
    name: "billstate",
    type: "String",
    entity: "view",
  },
  {
    name: "billzip",
    type: "String",
    entity: "view",
  },
  {
    name: "billattn",
    type: "String",
    entity: "view",
  },
  {
    name: "ARKey",
    type: "String",
    entity: "view",
  },
  {
    name: "icd9code",
    type: "String",
    entity: "view",
  },
  {
    name: "remitattn",
    type: "String",
    entity: "view",
  },
  {
    name: "remitaddr1",
    type: "String",
    entity: "view",
  },
  {
    name: "remitaddr2",
    type: "String",
    entity: "view",
  },
  {
    name: "remitcity",
    type: "String",
    entity: "view",
  },
  {
    name: "remitstate",
    type: "String",
    entity: "view",
  },
  {
    name: "remitzip",
    type: "String",
    entity: "view",
  },
  {
    name: "doctorspecialty",
    type: "String",
    entity: "view",
  },
  {
    name: "shortdesc",
    type: "String",
    entity: "view",
  },
  {
    name: "doctorlicense",
    type: "String",
    entity: "view",
  },
  {
    name: "doctorlocationnotes",
    type: "String",
    entity: "view",
  },
  {
    name: "doctorlocationcontact",
    type: "String",
    entity: "view",
  },
  {
    name: "doctorlocationcontactsalutation",
    type: "String",
    entity: "view",
  },
  {
    name: "medsstatus",
    type: "String",
    entity: "view",
  },
  {
    name: "employer",
    type: "String",
    entity: "view",
  },
  {
    name: "treatingphysician",
    type: "String",
    entity: "view",
  },
  {
    name: "examineecity",
    type: "String",
    entity: "view",
  },
  {
    name: "examineestate",
    type: "String",
    entity: "view",
  },
  {
    name: "examineezip",
    type: "String",
    entity: "view",
  },
  {
    name: "DoctorTaxID",
    type: "String",
    entity: "view",
  },
  {
    name: "casebillclientcode",
    type: "Int",
    entity: "view",
  },
  {
    name: "casebilladdr1",
    type: "String",
    entity: "view",
  },
  {
    name: "casebilladdr2",
    type: "String",
    entity: "view",
  },
  {
    name: "casebillcity",
    type: "String",
    entity: "view",
  },
  {
    name: "casebillstate",
    type: "String",
    entity: "view",
  },
  {
    name: "casebillzip",
    type: "String",
    entity: "view",
  },
  {
    name: "casebillarkey",
    type: "String",
    entity: "view",
  },
  {
    name: "casebillcompany",
    type: "String",
    entity: "view",
  },
  {
    name: "casebillcontact",
    type: "String",
    entity: "view",
  },
  {
    name: "specialtycode",
    type: "String",
    entity: "view",
  },
  {
    name: "doctorcorrespondence",
    type: "String",
    entity: "view",
  },
  {
    name: "examineelastname",
    type: "String",
    entity: "view",
  },
  {
    name: "examineefirstname",
    type: "String",
    entity: "view",
  },
  {
    name: "casebillfax",
    type: "String",
    entity: "view",
  },
  {
    name: "clientbillfax",
    type: "String",
    entity: "view",
  },
  {
    name: "officecode",
    type: "Int",
    entity: "view",
  },
  {
    name: "doctorlastname",
    type: "String",
    entity: "view",
  },
  {
    name: "doctorfirstname",
    type: "String",
    entity: "view",
  },
  {
    name: "doctormiddleinitial",
    type: "String",
    entity: "view",
  },
  {
    name: "doctorinitials",
    type: "String",
    entity: "view",
  },
  {
    name: "QARep",
    type: "String",
    entity: "view",
  },
  {
    name: "clientlastname",
    type: "String",
    entity: "view",
  },
  {
    name: "clientfirstname",
    type: "String",
    entity: "view",
  },
  {
    name: "dattorneyprefix",
    type: "String",
    entity: "view",
  },
  {
    name: "dattorneylastname",
    type: "String",
    entity: "view",
  },
  {
    name: "dattorneyfirstname",
    type: "String",
    entity: "view",
  },
  {
    name: "pattorneyprefix",
    type: "String",
    entity: "view",
  },
  {
    name: "pattorneylastname",
    type: "String",
    entity: "view",
  },
  {
    name: "pattorneyfirstname",
    type: "String",
    entity: "view",
  },
  {
    name: "doctorlocationcontactprefix",
    type: "String",
    entity: "view",
  },
  {
    name: "doctorlocationcontactfirstname",
    type: "String",
    entity: "view",
  },
  {
    name: "doctorlocationcontactlastname",
    type: "String",
    entity: "view",
  },
  {
    name: "examineemiddleinitial",
    type: "String",
    entity: "view",
  },
  {
    name: "ICD9Code2",
    type: "String",
    entity: "view",
  },
  {
    name: "ICD9Code3",
    type: "String",
    entity: "view",
  },
  {
    name: "ICD9Code4",
    type: "String",
    entity: "view",
  },
  {
    name: "InsuredAddr1",
    type: "String",
    entity: "view",
  },
  {
    name: "InsuredCity",
    type: "String",
    entity: "view",
  },
  {
    name: "InsuredState",
    type: "String",
    entity: "view",
  },
  {
    name: "InsuredZip",
    type: "String",
    entity: "view",
  },
  {
    name: "InsuredPhone",
    type: "String",
    entity: "view",
  },
  {
    name: "InsuredPhoneExt",
    type: "String",
    entity: "view",
  },
  {
    name: "InsuredFax",
    type: "String",
    entity: "view",
  },
  {
    name: "InsuredEmail",
    type: "String",
    entity: "view",
  },
  {
    name: "TreatingPhysicianAddr1",
    type: "String",
    entity: "view",
  },
  {
    name: "TreatingPhysicianCity",
    type: "String",
    entity: "view",
  },
  {
    name: "TreatingPhysicianState",
    type: "String",
    entity: "view",
  },
  {
    name: "TreatingPhysicianZip",
    type: "String",
    entity: "view",
  },
  {
    name: "TreatingPhysicianPhone",
    type: "String",
    entity: "view",
  },
  {
    name: "TreatingPhysicianPhoneExt",
    type: "String",
    entity: "view",
  },
  {
    name: "TreatingPhysicianFax",
    type: "String",
    entity: "view",
  },
  {
    name: "TreatingPhysicianEmail",
    type: "String",
    entity: "view",
  },
  {
    name: "TreatingPhysicianLicenseNbr",
    type: "String",
    entity: "view",
  },
  {
    name: "EmployerAddr1",
    type: "String",
    entity: "view",
  },
  {
    name: "EmployerCity",
    type: "String",
    entity: "view",
  },
  {
    name: "EmployerState",
    type: "String",
    entity: "view",
  },
  {
    name: "EmployerZip",
    type: "String",
    entity: "view",
  },
  {
    name: "EmployerPhone",
    type: "String",
    entity: "view",
  },
  {
    name: "EmployerPhoneExt",
    type: "String",
    entity: "view",
  },
  {
    name: "EmployerFax",
    type: "String",
    entity: "view",
  },
  {
    name: "EmployerEmail",
    type: "String",
    entity: "view",
  },
  {
    name: "TreatingPhysicianTaxID",
    type: "String",
    entity: "view",
  },
  {
    name: "Country",
    type: "String",
    entity: "view",
  },
  {
    name: "UPIN",
    type: "String",
    entity: "view",
  },
  {
    name: "schedulepriority",
    type: "Int",
    entity: "view",
  },
  {
    name: "drfeecode",
    type: "Int",
    entity: "view",
  },
  {
    name: "PanelNbr",
    type: "Int",
    entity: "view",
  },
  {
    name: "Jurisdiction",
    type: "String",
    entity: "view",
  },
  {
    name: "photoRqd",
    type: "Boolean",
    entity: "view",
  },
  {
    name: "CertMailNbr",
    type: "String",
    entity: "view",
  },
  {
    name: "HearingDate",
    type: "DateTime",
    entity: "view",
  },
  {
    name: "DoctorName",
    type: "String",
    entity: "view",
  },
  {
    name: "doctorstate",
    type: "String",
    entity: "view",
  },
  {
    name: "clientstate",
    type: "String",
    entity: "view",
  },
  {
    name: "doctorcorrespstate",
    type: "String",
    entity: "view",
  },
  {
    name: "dattorneystate",
    type: "String",
    entity: "view",
  },
  {
    name: "pattorneystate",
    type: "String",
    entity: "view",
  },
  {
    name: "prevappt",
    type: "DateTime",
    entity: "view",
  },
  {
    name: "mastersubcase",
    type: "String",
    entity: "view",
  },
  {
    name: "mastercasenbr",
    type: "Int",
    entity: "view",
  },
  {
    name: "doctorcity",
    type: "String",
    entity: "view",
  },
  {
    name: "doctorzip",
    type: "String",
    entity: "view",
  },
  {
    name: "clientcity",
    type: "String",
    entity: "view",
  },
  {
    name: "clientzip",
    type: "String",
    entity: "view",
  },
  {
    name: "policynumber",
    type: "String",
    entity: "view",
  },
  {
    name: "pattorneycity",
    type: "String",
    entity: "view",
  },
  {
    name: "pattorneyzip",
    type: "String",
    entity: "view",
  },
  {
    name: "ApptDuration",
    type: "Int",
    entity: "view",
  },
  {
    name: "PracticeName",
    type: "String",
    entity: "view",
  },
  {
    name: "OCF25Date",
    type: "DateTime",
    entity: "view",
  },
  {
    name: "DateForminDispute",
    type: "DateTime",
    entity: "view",
  },
  {
    name: "EmployerContactLastName",
    type: "String",
    entity: "view",
  },
  {
    name: "EmployerContactFirstName",
    type: "String",
    entity: "view",
  },
  {
    name: "DoctorNPINbr",
    type: "String",
    entity: "view",
  },
  {
    name: "PublishOnWeb",
    type: "Boolean",
    entity: "view",
  },
  {
    name: "DoctorProviderType",
    type: "String",
    entity: "view",
  },
  {
    name: "ProvTypeCode",
    type: "Int",
    entity: "view",
  },
  {
    name: "JurisdictionCode",
    type: "String",
    entity: "view",
  },
  {
    name: "transcode",
    type: "Int",
    entity: "view",
  },
  {
    name: "transcompany",
    type: "String",
    entity: "view",
  },
  {
    name: "DateReceived",
    type: "DateTime",
    entity: "view",
  },
  {
    name: "caseusddate3",
    type: "DateTime",
    entity: "view",
  },
  {
    name: "caseusddate4",
    type: "DateTime",
    entity: "view",
  },
  {
    name: "caseusdboolean1",
    type: "Boolean",
    entity: "view",
  },
  {
    name: "caseusdboolean2",
    type: "Boolean",
    entity: "view",
  },
  {
    name: "caseusddate5",
    type: "DateTime",
    entity: "view",
  },
  {
    name: "doctorusddate3",
    type: "DateTime",
    entity: "view",
  },
  {
    name: "doctorusddate4",
    type: "DateTime",
    entity: "view",
  },
  {
    name: "doctorusdvarchar3",
    type: "String",
    entity: "view",
  },
  {
    name: "doctorusddate5",
    type: "DateTime",
    entity: "view",
  },
  {
    name: "doctorusddate6",
    type: "DateTime",
    entity: "view",
  },
  {
    name: "doctorusddate7",
    type: "DateTime",
    entity: "view",
  },
  {
    name: "officeusdvarchar1",
    type: "String",
    entity: "view",
  },
  {
    name: "officeusdvarchar2",
    type: "String",
    entity: "view",
  },
  {
    name: "RequestedSpecialty",
    type: "String",
    entity: "view",
  },
  {
    name: "statusdesc",
    type: "String",
    entity: "view",
  },
  {
    name: "AttorneyNote",
    type: "String",
    entity: "view",
  },
  {
    name: "CaseTypeShortDesc",
    type: "String",
    entity: "view",
  },
  {
    name: "ExternalDueDate",
    type: "DateTime",
    entity: "view",
  },
  {
    name: "InternalDueDate",
    type: "DateTime",
    entity: "view",
  },
];

export default VIEW_FIELDS;
