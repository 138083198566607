import { gql } from "@apollo/client";

export const ALL_OFFICES_QUERY = gql`
  query offices($orderBy: [OfficeOrderByInput!], $where: OfficeWhereInput) {
    offices(orderBy: $orderBy, where: $where) {
      officecode
      description
      shortdesc
      status
    }
  }
`;

export const LIST_OFFICES_QUERY = gql`
  query offices(
    $orderBy: [OfficeOrderByInput!]
    $where: OfficeWhereInput
    $take: Int
    $skip: Int
  ) {
    offices(orderBy: $orderBy, where: $where, take: $take, skip: $skip) {
      officecode
      description
      shortdesc
      class
      status
      dateadded
      dateedited
      useridadded
      useridedited
    }
    aggregateOffice(where: $where) {
      count {
        officecode
      }
    }
  }
`;

export const SINGLE_OFFICE_QUERY = gql`
  query SINGLE_OFFICE_QUERY($where: OfficeWhereUniqueInput!) {
    office(where: $where) {
      officecode
      description
      shortdesc
      class
      status
    }
  }
`;