import React, { useState, useContext, useEffect } from "react";

import { useHistory } from "react-router-dom";
import { Field, Control, Input, Button, Title, Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useApolloClient, useMutation } from "@apollo/client";

import {
  debounce,
  getFilterState,
  saveFilterState,
  customToast as toast,
  getDateEST,
  convertTimeZoneDataBase,
  openInNewTab,
  isWindows,
  isMacintosh,
} from "../../utils";

import { ModalContext } from "../../context/ModalContext";
import { useAuth } from "../../context/AuthContext";
import DataTable from "../../components/DataTable";
import { CaseListFilters, CaseTagStatus } from "./components";
import { CASE_CLIENT_COLUMNS } from "./columns";
import {
  LIST_CASE_CLIENT_QUERY,
  DUPLICATE_CASE_ITEM_MUTATION,
  SINGLE_CASE_QUERY,
  ALL_CASE_HISTORY_QUERY,
  FIND_FIRST_APOLLO_SERVICE_QUERY,
} from "../../graphql";
import { useLocalStorage } from "../../hooks";
import Confirmation from "../../components/Confirmation";
import AddCaseModal from "./components/AddCaseModal";

const INITIAL_FILTER_STATE = {
  // status: { not: { equals: 1 } },
};

const CaseListPage = (props) => {
  const { setModalOpen } = useContext(ModalContext);
  const client = useApolloClient();
  const { state: authState } = useAuth();
  const [where, setWhere] = useState(
    getFilterState("CASE_LIST_FILTERS", { ...INITIAL_FILTER_STATE })
  );
  const [showFilters, setShowFilters] = useState(false);

  const history = useHistory();
  const [duplicateCase] = useMutation(DUPLICATE_CASE_ITEM_MUTATION);
  const [defaultPageSize] = useLocalStorage("DEFAULT_PAGE_SIZE", 25);

  const handleRowClick = async (cell, event) => {
    if (cell.column.id !== "duplicate") {
      const { casenbr } = cell.row.original;
      try {
        const {
          data: { findFirstServices },
        } = await client.query({
          query: FIND_FIRST_APOLLO_SERVICE_QUERY,
          variables: {
            where: {
              WorkID: { equals: parseInt(casenbr, 10) },
            },
          },
        });
        const {
          ClaimID,
          ServiceType: { ServiceType },
          ServiceID,
          WorkID,
        } = findFirstServices;

        const newPath = `/claims/${ClaimID}/services/${ServiceID}/${ServiceType}/${WorkID}/profile`;
        // simulate normal browser behavior
        if (
          (isMacintosh() && event?.metaKey) ||
          (isWindows() && event?.ctrlKey)
        ) {
          return openInNewTab(newPath);
        }

        return history.push(newPath);
      } catch (err) {
        toast.error("Unable to determine service.");
        return history.push(`/cases/${casenbr}/profile`);
      }
    }
    return null;
  };

  const handleReset = () => {
    saveFilterState("CASE_LIST_FILTERS", { ...INITIAL_FILTER_STATE });
    setWhere({ ...INITIAL_FILTER_STATE });

    if (document.getElementById("search-input")) {
      document.getElementById("search-input").value = "";
    }
  };

  const handleChange = (name, value) => {
    setWhere((prev) =>
      value
        ? { ...prev, [name]: value }
        : Object.keys(prev).reduce((acc, curr) => {
            if (curr !== name) acc[curr] = prev[curr];
            return acc;
          }, {})
    );
  };

  useEffect(() => {
    saveFilterState("CASE_LIST_FILTERS", where);
  }, [where]);

  const duplicateCaseItem = (row, updateMasterCase) => {
    const performDuplicate = async () => {
      try {
        const date = convertTimeZoneDataBase(getDateEST());
        const {
          data: {
            duplicateCaseItem: { casenbr },
          },
        } = await duplicateCase({
          variables: {
            where: {
              casenbr: parseInt(row.casenbr, 10),
            },
            data: {
              dateadded: { set: date },
              useridadded: { set: authState?.user?.userid },
            },
            updateMasterCase,
          },
          refetchQueries: [
            {
              query: LIST_CASE_CLIENT_QUERY,
              variables: {
                where,
                orderBy: [{ casenbr: "desc" }],
                take: defaultPageSize,
                skip: 0,
              },
            },
            {
              query: ALL_CASE_HISTORY_QUERY,
              variables: {
                where: { casenbr: { equals: parseInt(row.casenbr, 10) } },
                orderBy: [{ eventdate: "desc" }],
              },
            },
            {
              query: SINGLE_CASE_QUERY,
              variables: { where: { casenbr: parseInt(row.casenbr, 10) } },
            },
          ],
        });
        toast.success("Case duplicated successfully.");
        setModalOpen(
          true,
          <Confirmation
            message="Case duplicated successfully. Would you like to open it now?"
            onConfirm={() => {
              setModalOpen(false);
              history.push(`/cases/${casenbr}/profile`);
            }}
          />
        );
      } catch (err) {
        toast.error("Error duplicating Case.");
      }
    };
    setModalOpen(
      true,
      <Confirmation
        message={`Are you sure you want to ${
          updateMasterCase ? "create a new Sub-Case based on" : "duplicate"
        } this Case?`}
        onCancel={() => setModalOpen(false)}
        onConfirm={performDuplicate}
      />
    );
  };

  const getInputValue = () =>
    where?.examinee?.is
      ? [
          where.examinee.is.lastname?.startsWith,
          where.examinee.is.firstname?.startsWith,
        ]
          .filter(Boolean)
          .join(", ")
      : where?.casenbr?.equals || "";

  return (
    <div>
      <div className="section-header">
        <Title style={{ marginRight: 15 }}>Cases</Title>
        <Field kind="group" style={{ paddingTop: "0.3rem" }}>
          <CaseTagStatus statuscode={where?.status?.equals || ""} />
          <Control>
            <Input
              autoComplete="off"
              defaultValue={getInputValue()}
              id="search-input"
              placeholder="Search..."
              size="small"
              onChange={debounce(({ target: { value } }) => {
                const variableToSend = {};
                if (parseInt(value, 10)) {
                  variableToSend.casenbr = { equals: parseInt(value, 10) };
                } else {
                  const [lastname, firstname] = value
                    .split(/,/)
                    .map((x) => x.trim())
                    .filter(Boolean);

                  variableToSend.examinee = {
                    is: {
                      lastname: {
                        startsWith: lastname,
                      },
                      firstname: {
                        startsWith: firstname,
                      },
                    },
                  };
                }
                setWhere((prev) => {
                  const { casenbr, examinee, ...prevCopy } = { ...prev };

                  if (variableToSend.casenbr) {
                    prevCopy.casenbr = variableToSend.casenbr;
                  } else if (variableToSend.examinee) {
                    prevCopy.examinee = variableToSend.examinee;
                  }

                  return value
                    ? prevCopy
                    : Object.keys(prev)
                        .filter((x) => x !== "casenbr" && x !== "examinee")
                        .reduce((a, c) => ({ ...a, [c]: prev[c] }), {});
                });
              }, 1000)}
            />
          </Control>
          <Control>
            <Button
              color="warning"
              size="small"
              onClick={() => setShowFilters((prev) => !prev)}
            >
              <Icon>
                <FontAwesomeIcon icon="filter" />
              </Icon>
              <span>Filters</span>
            </Button>
          </Control>
          <Control>
            <Button
              color="primary"
              size="small"
              onClick={() => {
                setModalOpen(
                  true,
                  <AddCaseModal
                    onComplete={({ casenbr }) => {
                      setModalOpen(false, "");
                      if (casenbr) {
                        history.push(`/cases/${casenbr}/examinee`);
                      }
                    }}
                  />
                );
              }}
            >
              <Icon>
                <FontAwesomeIcon icon="plus" />
              </Icon>
              <span>Add</span>
            </Button>
          </Control>
        </Field>
      </div>

      {showFilters && (
        <CaseListFilters
          filters={where}
          show={showFilters}
          onChange={handleChange}
          onReset={handleReset}
        />
      )}

      <DataTable
        aggregateKey="casenbr"
        aggregateName="aggregateCase"
        columns={CASE_CLIENT_COLUMNS(duplicateCaseItem)}
        fetchPolicy="cache-and-network"
        name="cases"
        orderBy={[{ id: "dateadded", desc: true }]}
        query={LIST_CASE_CLIENT_QUERY}
        where={where}
        onCellClick={handleRowClick}
      />
    </div>
  );
};

CaseListPage.propTypes = {};

export default CaseListPage;
