import React from "react";
import PropTypes from "prop-types";
import { Button, Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useModal } from "../../../../context";
import MedicalProviderInfoModal from "../MedicalProviderInfoModal";
import MedicalProviderEntryModal from "../MedicalProviderEntryModal";
import BusinessCard from "../BusinessCard";

const MedicalProviderActionButtons = ({
  disabled,
  selectedAddress,
  addressInputs,
  onClearSelectionClick,
  onRowClick,
  onMedicalProviderAdd,
  fromDB,
}) => {
  const { setModalOpen } = useModal();

  const handleRowClick = (row) => {
    onRowClick(row);
    setModalOpen(false, "");
  };

  const handleMedicalProviderAdd = (medicalProvider) => {
    onMedicalProviderAdd(medicalProvider);
    setModalOpen(false, "");
  };

  const handleAddClick = () => {
    setModalOpen(
      true,
      <MedicalProviderEntryModal
        mode="add"
        title="Add Provider"
        onComplete={() => {
          setModalOpen(false, "");
        }}
        onMedicalProviderAdd={handleMedicalProviderAdd}
      />
    );
  };

  const handleSearchClick = () => {
    setModalOpen(
      true,
      <MedicalProviderEntryModal
        mode="search"
        title="Search Provider"
        onComplete={() => {
          setModalOpen(false, "");
        }}
        onRowClick={handleRowClick}
      />
    );
  };

  const handleSeeMoreClick = () => {
    setModalOpen(
      true,
      <MedicalProviderInfoModal
        inputs={addressInputs}
        title="Provider"
        onComplete={() => {
          setModalOpen(false, "");
        }}
      />
    );
  };

  return (
    <Button.Group hasAddons>
      <BusinessCard
        data={selectedAddress}
        disabled={!selectedAddress.length}
        onSeeMoreClick={handleSeeMoreClick}
      />
      <Button
        className="medical-provider-action-buttons--delete"
        color="danger"
        disabled={fromDB && (disabled || !selectedAddress.length)}
        size="small"
        title="Remove"
        type="button"
        onClick={onClearSelectionClick}
      >
        <Icon>
          <FontAwesomeIcon icon="times" />
        </Icon>
      </Button>
      <Button
        className="medical-provider-action-buttons--search"
        color="warning"
        disabled={fromDB && disabled}
        size="small"
        title="Search"
        type="button"
        onClick={handleSearchClick}
      >
        <Icon>
          <FontAwesomeIcon icon="filter" />
        </Icon>
      </Button>
      <Button
        className="medical-provider-action-buttons--add"
        color="success"
        disabled={fromDB && disabled}
        size="small"
        title="Add"
        type="button"
        onClick={handleAddClick}
      >
        <Icon>
          <FontAwesomeIcon icon="plus" />
        </Icon>
      </Button>
    </Button.Group>
  );
};

MedicalProviderActionButtons.propTypes = {
  disabled: PropTypes.bool,
  selectedAddress: PropTypes.array,
  addressInputs: PropTypes.object,
  fromDB: PropTypes.bool,
  onClearSelectionClick: PropTypes.func,
  onRowClick: PropTypes.func,
  onMedicalProviderAdd: PropTypes.func,
};

MedicalProviderActionButtons.defaultProps = {
  disabled: false,
  fromDB: false,
  selectedAddress: [],
  addressInputs: {},
  onClearSelectionClick: () => null,
  onRowClick: () => null,
  onMedicalProviderAdd: () => null,
};

export default MedicalProviderActionButtons;
