/* eslint-disable import/prefer-default-export */
import { gql } from "@apollo/client";

export const RATS_CREATE_INSURANCE_CARRIER_MUTATION = gql`
  mutation CreateInsurancecarrier($data: InsurancecarrierCreateInput!) {
    createInsurancecarrier(data: $data) {
      PK_ICid
    }
  }
`;
