import { gql } from "@apollo/client";

export const LIST_DOCTOR_SPECIALTIES_QUERY = gql`
  query LIST_DOCTOR_SPECIALTIES_QUERY(
    $where: DoctorSpecialtyWhereInput
    $orderBy: [DoctorSpecialtyOrderByInput!]
    $take: Int
    $skip: Int
  ) {
    doctorSpecialties(
      where: $where
      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      specialtycode
      doctorcode
      specialty {
        specialtycode
        description
      }
    }
  }
`;

export const ALL_DOCTOR_SPECIALTIES_QUERY = gql`
  query ALL_DOCTOR_SPECIALTIES_QUERY(
    $where: DoctorSpecialtyWhereInput
    $orderBy: [DoctorSpecialtyOrderByInput!]
    $take: Int
    $skip: Int
  ) {
    doctorSpecialties(
      where: $where
      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      specialtycode
      doctorcode
      specialty {
        specialtycode
        description
      }
    }
  }
`;

export const FIND_FIRST_DOCTOR_SPECIALTY_QUERY = gql`
  query FIND_FIRST_DOCTOR_SPECIALTY_QUERY($where: DoctorSpecialtyWhereInput!) {
    findFirstDoctorSpecialty(where: $where) {
      doctorcode
      specialtycode
    }
  }
`;
