/* eslint-disable import/prefer-default-export */
import { gql } from "@apollo/client";

export const ALL_CLAIM_TYPES_QUERY = gql`
  query ALL_CLAIM_TYPES_QUERY(
    $where: TypereferenceWhereInput
    $orderBy: [TypereferenceOrderByInput!]
    $take: Int
    $skip: Int
  ) {
    typereferences(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
      TRdescription
      TRid
    }
  }
`;