import React, { useState, useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import { Title, Button } from "rbx";
import { format } from "date-fns";
import { useMutation } from "@apollo/client";

import {
  SEND_EMAIL_MUTATION,
  CREATE_CASE_HISTORY_MUTATION,
  ALL_CASE_HISTORY_QUERY,
  SINGLE_CASE_QUERY,
} from "../../../../graphql";
import {
  customToast as toast,
  getDateEST,
  convertTimeZone,
  convertTimeZoneDataBase,
} from "../../../../utils";
import { useCaseDocumentPath } from "../../../../hooks";
import { useAuth } from "../../../../context/AuthContext";

import CaseEmailDocumentForm from "../CaseEmailDocumentForm";
import CaseEmailDocumentAttachments from "../CaseEmailDocumentAttachments";

const INITIAL_STATE = {
  to: "",
  from: "",
  subject: "",
  text: "",
};

const CaseEmailDocumentModal = ({ onComplete, casenbr }) => {
  const { state: authState } = useAuth();
  const [createCaseHistory] = useMutation(CREATE_CASE_HISTORY_MUTATION);

  const [loading, setLoading] = useState(false);
  const [inputs, setInputs] = useState({ ...INITIAL_STATE });
  const [attachments, setAttachments] = useState([]);

  const getCaseVirtualDocumentPath = useCaseDocumentPath(casenbr);

  const [sendEmail] = useMutation(SEND_EMAIL_MUTATION);

  const handleChange = (name, value) => {
    setInputs((prev) => ({ ...prev, [name]: value }));
  };
  useEffect(() => {
    const email = authState?.user?.email;
    if (email) {
      setInputs((prev) => ({ ...prev, from: email }));
    }
  }, [authState]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const input = {
        to: inputs.to,
        from: inputs.from,
        subject: inputs.subject,
        text: inputs.text,
        attachments: attachments.map((att) => {
          const sfilename = att.substr(0, att.lastIndexOf("_"));
          return getCaseVirtualDocumentPath(sfilename, false);
        }),
      };

      const { data } = await sendEmail({
        variables: {
          input,
        },
      });

      if (!data) {
        throw new Error("Error emailing documents.");
      }
      const refetchQueries = [
        {
          query: ALL_CASE_HISTORY_QUERY,
          variables: {
            where: { casenbr: { equals: parseInt(casenbr, 10) } },
            orderBy: [{ eventdate: "desc" }],
          },
        },
        {
          query: SINGLE_CASE_QUERY,
          variables: {
            where: { casenbr: parseInt(casenbr, 10) },
          },
        },
      ];
      const date = convertTimeZoneDataBase(getDateEST());
      await createCaseHistory({
        variables: {
          data: {
            casenbr: parseInt(casenbr, 10),
            eventdate: date,
            eventdesc: "Case Documents Emailed",
            otherinfo: `Case Documents Emailed from Apollo at ${format(
              convertTimeZone(date),
              "MM/dd/yyyy hh:mm a"
            )} by ${authState?.user?.userid} to ${inputs.to}.`,
            userid: authState?.user?.userid,
            useridedited: authState?.user?.userid,
            dateedited: date,
            dateadded: date,
            type: "Email",
          },
        },
        refetchQueries,
      });
      toast.success(`Case Documents emailed successfully`);
      onComplete();
    } catch (err) {
      toast.error(`Error emailing documents.`);
    } finally {
      setLoading(false);
    }
  };

  const handleChangeAttachments = (arr) => {
    setAttachments(arr);
  };

  const isDisabled = useMemo(
    () =>
      !Object.keys(INITIAL_STATE).every((key) => inputs[key]?.length) ||
      !attachments.length,
    [attachments.length, inputs]
  );

  return (
    <form id="email-case-document-form" onSubmit={handleSubmit}>
      <header className="page-head">
        <div className="page-head-start">
          <Title size={5}>Email Documents</Title>
        </div>
        <div className="page-head-end">
          <Button.Group hasAddons>
            <Button
              disabled={loading}
              size="small"
              type="button"
              onClick={() => onComplete(false)}
            >
              <span>Cancel</span>
            </Button>
            <Button
              color="primary"
              disabled={isDisabled || loading}
              form="email-case-document-form"
              size="small"
              state={loading ? "loading" : ""}
              type="submit"
            >
              <span>Submit</span>
            </Button>
          </Button.Group>
        </div>
      </header>
      <hr />
      <div className="case-email-document-form-wrapper">
        <CaseEmailDocumentForm
          disabled={loading}
          inputs={inputs}
          onChange={handleChange}
        />
        <CaseEmailDocumentAttachments
          attachments={attachments}
          casenbr={casenbr}
          disabled={loading}
          onChange={handleChangeAttachments}
        />
      </div>
      <hr />
    </form>
  );
};

CaseEmailDocumentModal.propTypes = {
  onComplete: PropTypes.func.isRequired,
  casenbr: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default CaseEmailDocumentModal;
