import React, { useState } from "react";
import PropTypes from "prop-types";
import { useMutation, useApolloClient } from "@apollo/client";
import { Control, Label, Title, Fieldset, Button } from "rbx";

import { customToast as toast, getDateEST } from "../../../utils";
import {
  CREATE_CASE_ISSUE_MUTATION,
  LIST_CASE_ISSUES_QUERY,
  UPDATE_CASE_ISSUE_MUTATION,
} from "../../../graphql";
import { useAuth } from "../../../context/AuthContext";
import IssueSelect from "../../../components/IssueSelect";
import IssueMultiSelect from "../../../components/IssueMultiSelect";

const CaseIssue = ({ handleCancel, casenbr, issuecode }) => {
  const { state: authState } = useAuth();
  const [createIssue] = useMutation(CREATE_CASE_ISSUE_MUTATION);
  const [updateIssue] = useMutation(UPDATE_CASE_ISSUE_MUTATION);

  const client = useApolloClient();

  const [inputs, setInputs] = useState({
    issuecode: issuecode || "",
    issuecodes: [],
  });

  const onChange = (e, value) => {
    setInputs((prev) => ({
      ...prev,
      [e.target ? e.target.name : e]: e.target ? e.target.value : value,
    }));
  };

  const handleSave = async (e) => {
    e.preventDefault();
    try {
      const refetchQueries = [
        {
          query: LIST_CASE_ISSUES_QUERY,
          variables: {
            where: {
              casenbr: { equals: casenbr },
            },
            orderBy: [{ issuecode: "asc" }],
          },
          fetchPolicy: "cache-first",
        },
      ];
      if (issuecode !== null) {
        await updateIssue({
          variables: {
            data: {
              issue: {
                connect: {
                  issuecode: parseInt(inputs.issuecode, 10),
                },
              },
            },
            where: {
              casenbr_issuecode: {
                casenbr,
                issuecode,
              },
            },
          },
          refetchQueries,
        });
        toast.success("Case Issue updated successfully.");
      } else {
        const { issuecodes } = inputs;
        await issuecodes.forEach(async (issue) => {
          const {
            data: { caseIssues },
          } = await client.query({
            query: LIST_CASE_ISSUES_QUERY,
            variables: {
              take: 1,
              where: {
                casenbr: { equals: casenbr },
                issuecode: { equals: parseInt(issue.value, 10) },
              },
            },
            fetchPolicy: "network-only",
          });
          if (caseIssues && !caseIssues.length) {
            await createIssue({
              variables: {
                data: {
                  casenbr,
                  issue: {
                    connect: {
                      issuecode: parseInt(issue.value, 10),
                    },
                  },
                  dateadded: getDateEST(),
                  useridadded: authState?.user?.userid,
                },
              },
              refetchQueries,
            });
          }
        });
        toast.success("Case Issue created successfully.");
      }
      handleCancel();
    } catch (err) {
      toast.error("Error: Please, check if this Issue already exists on Case.");
    }
  };

  const isDisabled = !inputs.issuecodes.length;

  return (
    <form id="add-issue-form" style={{ height: 200 }} onSubmit={handleSave}>
      <header className="page-head">
        <div className="page-head-start">
          <Title size={5}>
            {`${issuecode !== null ? "Edit" : "Create"}  Case Issue`}
          </Title>
        </div>
        <div className="page-head-end">
          <Button.Group hasAddons>
            <Button size="small" type="button" onClick={handleCancel}>
              <span>Cancel</span>
            </Button>
            <Button
              color="primary"
              disabled={isDisabled}
              form="add-issue-form"
              size="small"
              type="submit"
            >
              <span>Submit</span>
            </Button>
          </Button.Group>
        </div>
      </header>
      <hr />
      <Fieldset>
        <Control>
          <Label>{issuecode ? "Issue" : "Issues"}</Label>
          {issuecode ? (
            <IssueSelect
              fullWidth
              required
              name="issuecode"
              value={inputs.issuecode}
              onChange={onChange}
            />
          ) : (
            <IssueMultiSelect
              fullWidth
              required
              name="issuecodes"
              value={inputs.issuecodes}
              onChange={(value) => onChange("issuecodes", value)}
            />
          )}
        </Control>
      </Fieldset>
      <hr />
    </form>
  );
};

CaseIssue.propTypes = {
  handleCancel: PropTypes.func,
  casenbr: PropTypes.number.isRequired,
  issuecode: PropTypes.number,
};

CaseIssue.defaultProps = {
  handleCancel: (e) => e,
  issuecode: null,
};

export default CaseIssue;
