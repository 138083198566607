import { format } from "date-fns";
import { convertTimeZone } from "../../../../utils";

const CLAIM_HEADER_COLUMNS = [
  {
    Header: "Client Name",
    accessor: "CompanyName",
  },
  {
    Header: "External Claim ID",
    accessor: "ExternalClaimID",
    Cell: (value) => parseInt(value, 10) || "--",
  },
  {
    Header: "Date of Loss",
    accessor: "DateOfLoss",
    Cell: (value) => format(convertTimeZone(value), "MM/dd/yyyy"),
  },
  {
    Header: "Claim Number",
    accessor: "ClaimNumber",
  },
  {
    Header: "SOJ",
    accessor: "SOJ",
  },
  {
    Header: "Phone 1",
    accessor: "Phone1",
  },
  {
    Header: "Phone 2",
    accessor: "Phone2",
  },
  {
    Header: "Date Of Birth",
    accessor: "DateOfBirth",
    Cell: (value) => format(convertTimeZone(value), "MM/dd/yyyy"),
  },
  {
    Header: "Address 1",
    accessor: "Address1",
  },
  {
    Header: "Address 2",
    accessor: "Address2",
  },
  {
    Header: "City",
    accessor: "City",
  },
  {
    Header: "State",
    accessor: "State",
  },
  {
    Header: "Zip",
    accessor: "Zip5",
  },
];

export default CLAIM_HEADER_COLUMNS;
