import React, { useState } from "react";
import PropTypes from "prop-types";

import { useMutation, useQuery } from "@apollo/client";
import { Title, Button, Field, Control } from "rbx";
import { toast } from "react-toastify";
import {
  LIST_DOCTOR_SPECIALTIES_QUERY,
  CREATE_DOCTOR_SPECIALTY_MUTATION,
} from "../../../../graphql";

import DoctorSelect from "../../../../components/DoctorSelect";

import SpecialtySelect from "../../../../components/SpecialtySelect";

import InvestigatorSpecialtyModalError from "./errors";

const InvestigatorSpecialtyModal = ({ doctorcode, onComplete }) => {
  const [specialtyCode, setSpecialtyCode] = useState("");

  const [createDoctorSpecialty] = useMutation(CREATE_DOCTOR_SPECIALTY_MUTATION);

  const { data: listDoctorSpecialtiesData } = useQuery(
    LIST_DOCTOR_SPECIALTIES_QUERY,
    {
      variables: {
        where: { doctorcode: { equals: parseInt(doctorcode, 10) } },
      },
    }
  );

  const handleSave = async (e) => {
    e.preventDefault();

    try {
      e.preventDefault();
      const refetchQueries = [
        {
          query: LIST_DOCTOR_SPECIALTIES_QUERY,
          variables: {
            where: { doctorcode: { equals: parseInt(doctorcode, 10) } },
          },
        },
      ];

      const currentSpecialties = listDoctorSpecialtiesData?.doctorSpecialties;

      if (
        Array.isArray(currentSpecialties) &&
        currentSpecialties.some((spec) => spec.specialtycode === specialtyCode)
      ) {
        throw new InvestigatorSpecialtyModalError(
          "This specialty is already associated with this investigator."
        );
      }

      await createDoctorSpecialty({
        variables: {
          data: {
            doctor: {
              connect: {
                doctorcode: parseInt(doctorcode, 10),
              },
            },
            specialty: {
              connect: {
                specialtycode: specialtyCode,
              },
            },
          },
        },
        refetchQueries,
      });

      toast.success(`Investigator Specialty created successfully.`);
      onComplete(true);
    } catch (err) {
      if (err instanceof InvestigatorSpecialtyModalError) {
        toast.error(err.message);
      } else {
        toast.error(`Error creating investigator specialty.`);
      }
    }
  };
  const handleChange = (name, value) => {
    setSpecialtyCode(value);
  };

  return (
    <form id="doctor-specialty-form" onSubmit={handleSave}>
      <header className="page-head">
        <div className="page-head-start">
          <Title size={5}>Investigator Specialty</Title>
        </div>
        <div className="page-head-end">
          <Button.Group hasAddons>
            <Button
              size="small"
              type="button"
              onClick={() => onComplete(false)}
            >
              <span>Cancel</span>
            </Button>
            <Button
              color="primary"
              form="doctor-specialty-form"
              size="small"
              type="submit"
            >
              <span>Submit</span>
            </Button>
          </Button.Group>
        </div>
      </header>
      <hr />
      <Field kind="group">
        <Control expanded>
          <DoctorSelect disabled label="Investigator" value={doctorcode} />
        </Control>
        <SpecialtySelect
          label="Specialty"
          name="specialtycode"
          value={specialtyCode}
          onChange={handleChange}
        />
      </Field>

      <hr />
    </form>
  );
};

InvestigatorSpecialtyModal.propTypes = {
  doctorcode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onComplete: PropTypes.func,
};

InvestigatorSpecialtyModal.defaultProps = {
  doctorcode: "",
  onComplete: () => null,
};
export default InvestigatorSpecialtyModal;
