import React, { useMemo, useContext } from "react";
import PropTypes from "prop-types";
import { useApolloClient } from "@apollo/client";
import { Column, Title, Icon, Button } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";

import GroupByDataTable from "../../../../../components/GroupByDataTable";
import { ModalContext } from "../../../../../context/ModalContext";

import Confirmation from "../../../../../components/Confirmation";
import { customToast as toast } from "../../../../../utils";
import UserSecurityForm from "../UserSecurityForm";

import {
  DELETE_USER_SECURITY_MUTATION,
  DELETE_MANY_USER_SECURITY_MUTATION,
  ALL_USER_SECURITIES_QUERY,
} from "../../../../../graphql";
import generateUserSecuritiesColumns from "./columns";

class CustomUserSecurityError extends Error {}

const UserSecurityModal = ({ inputs, disabled }) => {
  const { setModalOpen } = useContext(ModalContext);
  const history = useHistory();

  const client = useApolloClient();
  const COLUMNS = useMemo(() => {
    const handleDeleteUserSecuritiesByOffice = (userSecurity) => {
      const performDelete = async () => {
        try {
          if (!userSecurity.userid || !userSecurity.officecode) {
            throw new CustomUserSecurityError(
              "Missing user or office. Terminating unsafe operation. Please contact support."
            );
          }
          await client.mutate({
            mutation: DELETE_MANY_USER_SECURITY_MUTATION,
            variables: {
              where: {
                userid: { equals: userSecurity.userid },
                officecode: { equals: userSecurity.officecode },
              },
            },
            refetchQueries: [
              {
                query: ALL_USER_SECURITIES_QUERY,
                variables: {
                  where: {
                    userid: { equals: userSecurity.userid },
                  },
                },
              },
            ],
          });
          toast.success("User Security Group removed successfully.");
          history.push(`/setup/users/${inputs.userid}`);
        } catch (err) {
          const message =
            err instanceof CustomUserSecurityError
              ? err.message
              : "Whoops! We encountered an error deleting this security group. Please contact support.";
          toast.error(message);
        }
        return Promise.resolve();
      };

      setModalOpen(
        true,
        <Confirmation
          message={`Are you sure you want to delete office ${userSecurity.office.shortdesc}? Doing so will delete all of the existing security groups under this office for this user.`}
          onCancel={() => {
            setModalOpen(false);
            history.push(`/setup/users/${inputs.userid}`);
          }}
          onConfirm={performDelete}
        />
      );
    };
    const handleDeleteUserSecurityByGroup = (userSecurity) => {
      const performDelete = async () => {
        try {
          await client.mutate({
            mutation: DELETE_USER_SECURITY_MUTATION,
            variables: {
              where: {
                userid_groupcode_officecode: {
                  userid: userSecurity.userid,
                  groupcode: userSecurity.groupcode,
                  officecode: userSecurity.officecode,
                },
              },
            },
            refetchQueries: [
              {
                query: ALL_USER_SECURITIES_QUERY,
                variables: {
                  where: {
                    userid: { equals: userSecurity.userid },
                  },
                },
              },
            ],
          });
          toast.success("User Security Group removed successfully.");
          history.push(`/setup/users/${inputs.userid}`);
        } catch (err) {
          const message =
            err instanceof CustomUserSecurityError
              ? err.message
              : "Whoops! We encountered an error deleting this security group. Please contact support.";
          toast.error(message);
        }
        return Promise.resolve();
      };
      setModalOpen(
        true,
        <Confirmation
          message={`Are you sure you want to remove security group ${userSecurity.groupcode}?`}
          onCancel={() => {
            setModalOpen(false);
            history.push(`/setup/users/${inputs.userid}`);
          }}
          onConfirm={performDelete}
        />
      );
    };

    return generateUserSecuritiesColumns({
      handleDeleteUserSecurityByGroup,
      handleDeleteUserSecuritiesByOffice,
      disabled,
    });
  }, [client, disabled, history, inputs.userid, setModalOpen]);

  return (
    <Column.Group multiline>
      <Column size={12}>
        <div className="page-head">
          <div className="page-head-start">
            <Button
              color="success"
              size="small"
              type="button"
              onClick={() => {
                setModalOpen(
                  true,
                  <UserSecurityForm
                    userid={inputs.userid}
                    onComplete={() => {
                      setModalOpen(false);
                      history.push(`/setup/users/${inputs.userid}`);
                    }}
                  />
                );
              }}
            >
              <Icon>
                <FontAwesomeIcon icon="plus" />
              </Icon>
            </Button>
            <Title size={6}>User Security Groups</Title>
          </div>
        </div>
        <div
          style={{
            maxHeight: 400,
            minHeight: 100,
            overflowY: "auto",
            width: "100%",
          }}
        >
          <GroupByDataTable
            columns={COLUMNS}
            groupByFn={(x) => x?.officecode}
            hideCellFn={(x, i) => !!i}
            name="userSecurities"
            orderBy={[{ id: "office:description", desc: false }]}
            query={ALL_USER_SECURITIES_QUERY}
            sortFn={(a, b) => {
              const aVal = a?.office?.description;
              const bVal = b?.office?.description;
              if (aVal > bVal) return 1;
              if (bVal > aVal) return -1;
              return 0;
            }}
            where={{
              userid: { equals: inputs.userid },
            }}
          />
        </div>
      </Column>
    </Column.Group>
  );
};

UserSecurityModal.propTypes = {
  inputs: PropTypes.object,
  disabled: PropTypes.bool,
};

UserSecurityModal.defaultProps = {
  inputs: {},
  disabled: false,
};

export default UserSecurityModal;
