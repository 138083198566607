import React from "react";
import PropTypes from "prop-types";
import DoctorGeneralForm from "../../components/DoctorGeneralForm";

const General = ({ inputs, onChange, disabled }) => (
  <div>
    <DoctorGeneralForm
      disabled={disabled}
      inputs={inputs}
      onChange={onChange}
    />
  </div>
);

General.propTypes = {
  inputs: PropTypes.object,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};
General.defaultProps = {
  inputs: {},
  disabled: false,
  onChange: () => null,
};
export default General;
