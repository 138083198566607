import { gql } from "@apollo/client";

export const LIST_STANDARD_CASE_HISTORY_NOTES_QUERY = gql`
  query LIST_STANDARD_CASE_HISTORY_NOTES_QUERY(
    $where: StandardCaseHistoryNotesWhereInput
    $orderBy: [StandardCaseHistoryNotesOrderByInput!]
    $take: Int
    $skip: Int
  ) {
    findManyStandardCaseHistoryNotes(
      where: $where
      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      NoteID
      NoteDescription
      NoteText
      PublishOnWeb
      Status
      DateAdded
      UseridAdded
      DateEdited
      UseridEdited
    }
    aggregateStandardCaseHistoryNotes {
      count {
        NoteID
      }
    }
  }
`;

export const ALL_STANDARD_CASE_HISTORY_NOTES_QUERY = gql`
  query ALL_STANDARD_CASE_HISTORY_NOTES_QUERY(
    $where: StandardCaseHistoryNotesWhereInput
    $orderBy: [StandardCaseHistoryNotesOrderByInput!]
    $take: Int
    $skip: Int
  ) {
    findManyStandardCaseHistoryNotes(
      where: $where
      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      NoteID
      NoteDescription
      NoteText
      PublishOnWeb
      Status
      DateAdded
      UseridAdded
      DateEdited
      UseridEdited
    }
  }
`;

export const SINGLE_STANDARD_CASE_HISTORY_NOTE_QUERY = gql`
  query SINGLE_STANDARD_CASE_HISTORY_NOTE_QUERY(
    $where: StandardCaseHistoryNotesWhereUniqueInput!
  ) {
    findUniqueStandardCaseHistoryNotes(where: $where) {
      NoteID
      NoteDescription
      NoteText
      PublishOnWeb
      Status
      DateAdded
      UseridAdded
      DateEdited
      UseridEdited
    }
  }
`;
