import { gql } from "@apollo/client";

export const ALL_APOLLO_NOTES_QUERY = gql`
  query ALL_APOLLO_NOTES_QUERY(
    $take: Int = 100
    $orderBy: [NotesOrderByInput!]
    $skip: Int
    $where: NotesWhereInput
  ) {
    findManyNotes(take: $take, orderBy: $orderBy, skip: $skip, where: $where) {
      NoteID
      NoteText
      CreatedDate
      CreatedBy
    }
  }
`;

export const LIST_APOLLO_NOTES_QUERY = gql`
  query LIST_APOLLO_NOTES_QUERY(
    $take: Int = 100
    $orderBy: [NotesOrderByInput!]
    $skip: Int
    $where: NotesWhereInput
  ) {
    findManyNotes(take: $take, orderBy: $orderBy, skip: $skip, where: $where) {
      NoteID
      ClaimID
      ServiceID
      NoteText
      NoteTypeID
      CreatedDate
      CreatedBy
      Type {
        NoteTypeID
        NoteType
      }
    }
    aggregateNotes(where: $where) {
      count {
        NoteID
      }
    }
  }
`;

export const SINGLE_APOLLO_NOTE_QUERY = gql`
  query findUniqueDocumentTypes($where: DocumentTypesWhereUniqueInput!) {
    findUniqueDocumentTypes(where: $where) {
      DocumentTypeID
      DocumentType
    }
  }
`;
