import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useMutation, useLazyQuery } from "@apollo/client";
import { Title, Button } from "rbx";
import { toast } from "react-toastify";
import CCAddressForm from "./CCAddressForm";
import { useAuth } from "../../context";

import { getDateEST } from "../../utils";
import {
  CREATE_CC_ADDRESS_MUTATION,
  SINGLE_CC_ADDRESS_QUERY,
  UPDATE_CC_ADDRESS_MUTATION,
  ALL_EXAMINEE_CCS_QUERY,
  ALL_CC_ADDRESSES_QUERY,
} from "../../graphql";

const INITIAL_STATE = {
  lastname: "",
  firstname: "",
  address1: "",
  address2: "",
  city: "",
  company: "",
  state: "",
  prefix: "",
  zip: "",
  phone: "",
  phoneextension: "",
  fax: "",
  email: "",
  status: "Active",
  // lastname: "Jarvis",
  // firstname: "James",
  // address1: "51 Yale St",
  // address2: "13123213",
  // city: "Medford",
  // company: "Reconstructive Orthopaedic Associates II, PC",
  // state: "MA",
  // prefix: "Mr.",
  // zip: "01451",
  // phone: "(508) 265-4281",
  // phoneextension: "1",
  // fax: "",
  // email: "jamespjarvis@gmail.com",
  // status: "Active",
};

const convertInputToVariables = (variables, keys, adding = false) =>
  keys.reduce((acc, curr) => {
    if (variables[curr]) {
      acc[curr] = adding ? variables[curr] : { set: variables[curr] };
    }
    return acc;
  }, {});

const CCAddressModal = ({
  cccode,
  onComplete,
  allowCCAddressSelect,
  chartnbr,
}) => {
  const { state: authState } = useAuth();
  const [intCCCode, setIntCCCode] = useState(cccode);
  const [inputs, setInputs] = useState({ ...INITIAL_STATE });
  const [getCCAddress, { data: CCAddressData }] = useLazyQuery(
    SINGLE_CC_ADDRESS_QUERY
  );

  const [createCcAddress] = useMutation(CREATE_CC_ADDRESS_MUTATION);
  const [updateCcAddress] = useMutation(UPDATE_CC_ADDRESS_MUTATION);

  useEffect(() => {
    if (intCCCode) {
      getCCAddress({
        variables: {
          where: { cccode: parseInt(intCCCode, 10) },
        },
      });
    }
  }, [intCCCode, getCCAddress]);

  useEffect(() => {
    const cCAddress = CCAddressData?.cCAddress;
    if (cCAddress) {
      setInputs((prev) => ({
        ...Object.keys(prev).reduce((acc, curr) => {
          acc[curr] = cCAddress[curr] || "";
          return acc;
        }, prev),
      }));
    }
  }, [CCAddressData?.cCAddress]);

  const handleSave = async (e) => {
    try {
      e.preventDefault();

      const date = getDateEST();
      if (intCCCode) {
        const refetchQueries = [
          {
            query: SINGLE_CC_ADDRESS_QUERY,
            variables: {
              where: {
                cccode: parseInt(intCCCode, 10),
              },
            },
          },
          {
            query: ALL_CC_ADDRESSES_QUERY,
            variables: {
              take: 50,
              where: {
                company: { startsWith: inputs.company },
              },
              orderBy: [{ company: "asc" }, { lastname: "asc" }],
              skip: 0,
            },
          },
        ];
        const { data: UpdateCCAddressData } = await updateCcAddress({
          variables: {
            data: {
              ...convertInputToVariables(
                inputs,
                Object.keys(INITIAL_STATE),
                false
              ),
              dateedited: { set: date },
              useridedited: { set: authState?.user?.userid },
            },
            where: {
              cccode: parseInt(intCCCode, 10),
            },
          },
          refetchQueries: chartnbr
            ? [
                ...refetchQueries,
                {
                  query: ALL_EXAMINEE_CCS_QUERY,
                  variables: {
                    where: { chartnbr: { equals: chartnbr } },
                  },
                },
              ]
            : refetchQueries,
        });
        toast.success(`CC Address updated.`);
        onComplete(UpdateCCAddressData?.updateCCAddress?.cccode);
      } else {
        const { data: CreateCCAddressData } = await createCcAddress({
          variables: {
            data: {
              ...convertInputToVariables(
                inputs,
                Object.keys(INITIAL_STATE),
                true
              ),
              dateadded: date,
              dateedited: date,
              useridadded: authState?.user?.userid,
              useridedited: authState?.user?.userid,
            },
          },
          refetchQueries: [
            {
              query: ALL_CC_ADDRESSES_QUERY,
              variables: {
                take: 50,
                where: {
                  company: { startsWith: inputs.company },
                },
                orderBy: [{ company: "asc" }, { lastname: "asc" }],
                skip: 0,
              },
            },
          ],
        });
        toast.success(`CC Address created.`);
        onComplete(CreateCCAddressData?.createCCAddress?.cccode);
      }
    } catch (err) {
      toast.error(`Error creating CC AAddress`);
    }
  };

  const handleChange = (name, value) => {
    if (name === "cccode") {
      setIntCCCode(value);
    } else {
      setInputs((prev) => ({ ...prev, [name]: value }));
    }
  };
  return (
    <form id="cc-address-create-form" onSubmit={handleSave}>
      <header className="page-head">
        <div className="page-head-start">
          <Title size={5}>
            {intCCCode ? "Edit" : "Create"}
            &nbsp;CC
          </Title>
        </div>
        <div className="page-head-end">
          <Button.Group hasAddons>
            <Button
              size="small"
              type="button"
              onClick={() => onComplete(false)}
            >
              <span>Cancel</span>
            </Button>
            <Button
              color="primary"
              form="cc-address-create-form"
              size="small"
              type="submit"
            >
              <span>{intCCCode ? "Update and Add" : "Create and Add"}</span>
            </Button>
          </Button.Group>
        </div>
      </header>
      <hr />
      <CCAddressForm
        allowCCAddressSelect={allowCCAddressSelect}
        cccode={intCCCode}
        inputs={inputs}
        onChange={handleChange}
      />
      <hr />
      {/* <pre>
        <code>{JSON.stringify(CCAddressData, null, 2)}</code>
      </pre> */}
    </form>
  );
};

CCAddressModal.propTypes = {
  allowCCAddressSelect: PropTypes.bool,
  cccode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onComplete: PropTypes.func,
  chartnbr: PropTypes.number,
};

CCAddressModal.defaultProps = {
  cccode: "",
  chartnbr: null,
  onComplete: () => null,
  allowCCAddressSelect: false,
};

export default CCAddressModal;
