import React from "react";
import PropTypes from "prop-types";

import { Field, Control, Label } from "rbx";
import { useParams } from "react-router-dom";
import BooleanInput from "../../../components/BooleanInput";
import ClientForm from "../components/ClientForm";
import ClientInheritFromCompanyButton from "../components/ClientInheritFromCompanyButton";

const Profile = ({ client, onChange, disabled, adding, onSubmit }) => {
  const { clientcode } = useParams();

  return (
    <React.Fragment>
      <ClientForm
        adding={adding}
        disabled={disabled}
        inputs={client}
        onChange={onChange}
        onSubmit={onSubmit}
      />
      {client?.companycode && (
        <ClientInheritFromCompanyButton
          clientcode={clientcode}
          companycode={client.companycode}
        />
      )}
      <Field>
        <Control>
          <Label>Do Not Inherit (when replicating from company)</Label>
          <BooleanInput
            name="doNotInherit"
            value={client.doNotInherit}
            onChange={onChange}
          />
        </Control>
      </Field>
    </React.Fragment>
  );
};

Profile.propTypes = {
  client: PropTypes.object,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  adding: PropTypes.bool,
  onSubmit: PropTypes.func,
};

Profile.defaultProps = {
  client: {},
  onChange: (e) => e,
  disabled: false,
  adding: false,
  onSubmit: (e) => e,
};

export default Profile;
