import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";

import ReactSelectAsync from "react-select/async";

import { Label, Control } from "rbx";
import { useQuery } from "@apollo/client";
import { LIST_ISSUES_QUERY } from "../../graphql";

const IssueMultiSelect = ({
  name,
  value,
  label,
  onChange,
  fullWidth,
  disabled,
  readOnly,
  required,
  getValue,
}) => {
  const { data: issuesData } = useQuery(LIST_ISSUES_QUERY, {
    variables: {
      where: {
        status: { equals: "Active" },
      },
      orderBy: { description: "asc" },
    },
  });

  const issues = useMemo(
    () =>
      issuesData?.issues?.map((item) => ({
        value: item.issuecode,
        name,
        label: item.description,
      })) || [],
    [issuesData, name]
  );

  const loadingOptions = useCallback(
    async (inputValue = "") =>
      new Promise((resolve, reject) => {
        try {
          if (Array.isArray(issues) && issues.length > 0) {
            const arrayFiltered = issues.filter((i) =>
              i.label.toLowerCase().includes(inputValue?.toLowerCase() || "")
            );
            resolve(arrayFiltered);
          }
        } catch (error) {
          reject(error);
        }
      }),
    [issues]
  );

  return (
    <Control expanded>
      <Label>{label}</Label>
      <ReactSelectAsync
        cacheOptions
        isMulti
        defaultOptions={issues}
        isDisabled={disabled}
        isLoading={!Array.isArray(issues)}
        isSearchable={!readOnly}
        loadOptions={loadingOptions}
        menuIsOpen={readOnly ? false : undefined}
        menuPlacement="auto"
        menuPortalTarget={document.body}
        placeholder="Start typing..."
        styles={{
          menuPortal: (provided) => ({
            ...provided,
            zIndex: 9999,
          }),
          indicatorsContainer: (provided) => ({
            ...provided,
            height: "inherit",
          }),
          indicatorSeparator: (provided) => ({
            ...provided,
            display: "none",
          }),
          container: (provided) => ({
            fontSize: 13,
          }),
          singleValue: (provided) => ({
            ...provided,
            top: "38%",
          }),
          control: (provided) => ({
            ...provided,
            width: "100%",
            minWidth: 180,
            minHeight: 30,
            height:
              Array.isArray(getValue(value)) && value.length > 0 ? "auto" : 30,
            border:
              required && Array.isArray(getValue(value)) && value.length === 0
                ? "1px #e63362 solid"
                : "",
            borderRadius: "4px",
            boxShadow:
              required && Array.isArray(getValue(value)) && value.length === 0
                ? "0 0 0 0.125em rgba(230, 51, 98, 0.25)"
                : "",
            "&:hover": {
              borderColor: "hsl(0, 0%, 90%)",
              borderRadius: 4,
              cursor: disabled ? "not-allowed" : "pointer",
            },
          }),
          placeholder: (provided) => ({ ...provided, top: "38%" }),
          menu: (provided) => ({
            ...provided,
            fontSize: 12,
            zIndex: 999,
          }),
        }}
        value={
          Array.isArray(issues) &&
          issues.filter((x) => getValue(value).find((i) => i.value === x.value))
        }
        onChange={onChange}
      />
    </Control>
  );
};

IssueMultiSelect.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.number,
    PropTypes.array,
  ]),
  onChange: PropTypes.func,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  getValue: PropTypes.func,
};

IssueMultiSelect.defaultProps = {
  name: "",
  label: "",
  value: "",
  readOnly: false,
  getValue: (x) => x,
  onChange: () => null,
  fullWidth: false,
  disabled: false,
  required: false,
};

export default IssueMultiSelect;
