import React, { useMemo } from "react";
import PropTypes from "prop-types";

import { Label, Control, Input, Field, Help } from "rbx";

import {
  validateTRNWSCTTRCCSCT,
  validateTRNWNWTRCCNW,
  validateTRNWHARTRCCNWHAR,
  validateTRNWNWPCTRCCNWPC,
  validateTRNWOTHTRCCNWOTH,
} from "./helpers";

const ClaimNumberRecordsInput = ({
  inputs: { ClaimNumber, InsuranceCarrier, NWCarrier, ClaimSystem },
  onChange,
  disabled,
  size,
  name,
  label,
  required,
}) => {
  const handleChange = (v) => {
    if (InsuranceCarrier === "Nationwide" && NWCarrier && ClaimSystem) {
      // NWCarrier = Scottsdale/Western Heritage & ClaimSystem = E&S Claim Center
      if (NWCarrier === "TRNWSCT" && ClaimSystem === "TRCCSCT") {
        const { OK, value } = validateTRNWSCTTRCCSCT(v);
        if (OK) {
          onChange(name, value);
        } else if (!validateTRNWSCTTRCCSCT(v.slice(0, -1)).OK) { // this allows for a complete wipe on claim number if it's not valid in format by more than one character
          onChange(name, "")
        }
      }
      // NWCarrier = Nationwide (NW/AG/Alied/Titan) & ClaimSystem = Claim Center
      if (NWCarrier === "TRNWNW" && ClaimSystem === "TRCCNW") {
        const { OK, value } = validateTRNWNWTRCCNW(v, ClaimNumber);
        if (OK) {
          onChange(name, value);
        } else if (!validateTRNWNWTRCCNW(v.slice(0, -1), ClaimNumber).OK) { // this allows for a complete wipe on claim number if it's not valid in format by more than one character
          onChange(name, "")
        }
      }
      // NWCarrier = Harleysville & ClaimSystem = Harleysville
      if (NWCarrier === "TRNWHAR" && ClaimSystem === "TRCCNWHAR") {
        const { OK, value } = validateTRNWHARTRCCNWHAR(v, ClaimNumber);
        if (OK) {
          onChange(name, value);
        } else if (!validateTRNWHARTRCCNWHAR(v.slice(0, -1), ClaimNumber).OK) { // this allows for a complete wipe on claim number if it's not valid in format by more than one character
          onChange(name, "")
        }
      }
      // NWCarrier = NWPC & ClaimSystem = E&S Cliam Center
      if (NWCarrier === "TRNWNWPC" && ClaimSystem === "TRCCNWPC") {
        const { OK, value } = validateTRNWNWPCTRCCNWPC(v);
        if (OK) {
          onChange(name, value);
        } else if (!validateTRNWNWPCTRCCNWPC(v.slice(0, -1)).OK) { // this allows for a complete wipe on claim number if it's not valid in format by more than one character
          onChange(name, "")
        }
      }
      // NWCarrier = Other (K&K/Hagerty) & ClaimSystem = Claim Center
      if (NWCarrier === "TRNWOTH" && ClaimSystem === "TRCCNWOTH") {
        const { OK, value } = validateTRNWOTHTRCCNWOTH(v);
        if (OK) {
          onChange(name, value);
        } else if (!validateTRNWOTHTRCCNWOTH(v.slice(0, -1))) {
          onChange(name, "")
        }
      }
    } else {
      onChange(name, v);
    }
  };

  const feedback = useMemo(() => {
    if (NWCarrier === "TRNWSCT" && ClaimSystem === "TRCCSCT") {
      const { OK } = validateTRNWSCTTRCCSCT(ClaimNumber, true);
      return {
        valid: OK,
        message:
          "Claim number must be 8 characters long and only alphanumeric characters are allowed",
      };
    }
    if (NWCarrier === "TRNWNW" && ClaimSystem === "TRCCNW") {
      const { OK } = validateTRNWNWTRCCNW(ClaimNumber, ClaimNumber, true);
      return {
        valid: OK,
        message:
          "Claim number must be 9 characters long and must have the following format: ######-XX",
      };
    }
    if (NWCarrier === "TRNWHAR" && ClaimSystem === "TRCCNWHAR") {
      const { OK } = validateTRNWHARTRCCNWHAR(ClaimNumber, ClaimNumber, true);
      return {
        valid: OK,
        message:
          "Claim number must be 9 characters long and must have the following format: N#-######",
      };
    }
    if (NWCarrier === "TRNWNWPC" && ClaimSystem === "TRCCNWPC") {
      const { OK } = validateTRNWNWPCTRCCNWPC(ClaimNumber, true);

      return {
        valid: OK,
        message:
          "Claim Number must have one of the following formats: CLM-000##### | CLM##### | CLM000#####",
      };
    }
    if (NWCarrier === "TRNWOTH" && ClaimSystem === "TRCCNWOTH") {
      const { OK } = validateTRNWOTHTRCCNWOTH(ClaimNumber, true);
      return {
        valid: OK,
        message: "Claim Number must have the following format NW#######C",
      };
    }
    return {
      valid: true,
      message: <span>&nbsp;</span>,
    };
  }, [NWCarrier, ClaimSystem, ClaimNumber]);

  return (
    <Field kind="group">
      <Control expanded iconRight>
        <Label>{label}</Label>
        <Input
          disabled={disabled}
          name={name}
          required={required}
          size={size}
          value={ClaimNumber}
          onChange={(e) => handleChange(e.target.value)}
        />
        <Help
          color={ClaimNumber.length && feedback.valid ? "success" : "danger"}
        >
          {feedback.message}
        </Help>
      </Control>
    </Field>
  );
};

ClaimNumberRecordsInput.propTypes = {
  inputs: PropTypes.object,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  size: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
};

ClaimNumberRecordsInput.defaultProps = {
  inputs: {},
  onChange: () => null,
  disabled: false,
  size: "small",
  name: "ClaimNumber",
  label: "Claim Number",
  required: true,
};

export default ClaimNumberRecordsInput;
