import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useMutation, useLazyQuery } from "@apollo/client";
import { Title, Fieldset, Button, Field, Control, Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { customToast as toast, getDateEST } from "../../../utils";
import AddClientForm from "./AddClientForm";
import {
  CREATE_CLIENT_MUTATION,
  LIST_CLIENTS_QUERY,
  SINGLE_COMPANY_QUERY,
} from "../../../graphql";
import { useAuth } from "../../../context/AuthContext";

const DEFAULT_STATE = {
  firstname: "",
  lastname: "",
  addr1: "",
  addr2: "",
  city: "",
  state: "",
  zip: "",
  phone1: "",
  phone1ext: "",
  phone2: "",
  phone2ext: "",
  status: "Active",
  prefix: "",
  suffix: "",
  title: "",
  TypeCode: "",
  email: "",
  fax: "",
  marketercode: "",
  casetype: "",
  QARep: "",
  priority: "Normal",
};

const convertInputToVariables = (variables, keys, adding = true) =>
  keys.reduce((acc, curr) => {
    if (variables[curr]) {
      acc[curr] = adding ? variables[curr] : { set: variables[curr] };
    } else {
      acc[curr] = adding ? null : { set: null };
    }
    return acc;
  }, {});

const AddClientModal = ({ onComplete, companycode }) => {
  const { state: authState } = useAuth();
  const [inheritFromCompany, setInheritFromCompany] = useState(false);

  const [getCompany, { data: SingleCompanyData }] = useLazyQuery(
    SINGLE_COMPANY_QUERY
  );

  const [createClient] = useMutation(CREATE_CLIENT_MUTATION);

  const [inputs, setInputs] = useState({
    ...DEFAULT_STATE,
  });

  useEffect(() => {
    if (parseInt(inputs.companycode, 10) && inheritFromCompany) {
      getCompany({
        variables: {
          where: {
            companycode: parseInt(inputs.companycode, 10),
          },
        },
      });
    }
  }, [inputs.companycode, inheritFromCompany, getCompany]);

  useEffect(() => {
    if (SingleCompanyData?.company) {
      const {
        addr1,
        addr2,
        city,
        state,
        zip,
        phone,
        marketercode,
      } = SingleCompanyData.company;
      setInputs((prev) => ({
        ...prev,
        addr1,
        addr2,
        city,
        state,
        zip,
        phone1: phone,
        marketercode,
      }));
    }
  }, [SingleCompanyData]);

  const handleChange = (name, value) => {
    setInputs((prev) => ({ ...prev, [name]: value }));
  };

  const handleSave = async (e) => {
    e.preventDefault();
    try {
      const date = getDateEST();
      const { data: createClientData } = await createClient({
        variables: {
          data: {
            company: {
              connect: {
                companycode: parseInt(inputs.companycode, 10),
              },
            },
            ...convertInputToVariables(
              inputs,
              Object.keys(DEFAULT_STATE),
              true
            ),
            dateadded: date,
            dateedited: date,
            useridadded: authState?.user?.userid,
            useridedited: authState?.user?.userid,
          },
        },
        refetchQueries: [
          {
            query: LIST_CLIENTS_QUERY,
            variables: {
              where: companycode
                ? {
                    companycode: {
                      equals: parseInt(inputs.companycode, 10),
                    },
                  }
                : {},
              orderBy: [
                {
                  firstname: "desc",
                },
                { lastname: "desc" },
              ],
            },
          },
        ],
      });

      toast.success("Client created successfully.");
      onComplete({
        clientcode: createClientData.createClient.clientcode,
      });
    } catch (err) {
      toast.error("Error creating Client.");
    }
  };

  return (
    <React.Fragment>
      <header className="page-head">
        <div className="page-head-start">
          <Title size={5}>Create Client</Title>
        </div>
        <div className="page-head-end">
          <Button.Group hasAddons>
            <Button size="small" type="button" onClick={onComplete}>
              <span>Cancel</span>
            </Button>
            <Button
              color="primary"
              form="add-client-form"
              size="small"
              type="submit"
            >
              <span>Submit</span>
            </Button>
          </Button.Group>
        </div>
      </header>
      <hr />
      <Fieldset>
        <AddClientForm
          adding={!companycode}
          formId="add-client-form"
          inputs={inputs}
          onChange={handleChange}
          onSubmit={handleSave}
        />
      </Fieldset>
      <hr />
      {inputs?.companycode && (
        <Field>
          <Control>
            <Button
              color="danger"
              size="small"
              type="button"
              onClick={() => setInheritFromCompany(true)}
            >
              <Icon>
                <FontAwesomeIcon icon="copy" />
              </Icon>
              <span>Inherit from Company</span>
            </Button>
          </Control>
        </Field>
      )}
      {/* <pre>
        <code>{JSON.stringify(inputs, null, 2)}</code>
      </pre> */}
    </React.Fragment>
  );
};

AddClientModal.propTypes = {
  onComplete: PropTypes.func,
  companycode: PropTypes.string,
};

AddClientModal.defaultProps = {
  onComplete: (e) => e,
  companycode: null,
};

export default AddClientModal;
