import { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import {
  FIND_FIRST_LSI_CLIENT_QUERY,
  RATS_REQUEST_FOR_CLIENT_OFFICE_QUERY,
} from "../graphql";

import useQueryParams from "./useQueryParams";

const useLsiClient = () => {
  const [clidString] = useQueryParams("clid", "", true);
  const [lsiClient, setLsiClient] = useState(null);

  const { data } = useQuery(FIND_FIRST_LSI_CLIENT_QUERY, {
    variables: {
      where: { CLid: { equals: parseInt(clidString, 10) } },
    },
    fetchPolicy: "network-only",
    skip: !clidString,
  });

  const { data: ratsData } = useQuery(RATS_REQUEST_FOR_CLIENT_OFFICE_QUERY, {
    variables: {
      where: { RGemail: { equals: data?.findFirstLsiClient?.CLemail } },
    },
    fetchPolicy: "network-only",
    skip: !data?.findFirstLsiClient?.CLemail,
  });

  const foundRatsClient = ratsData?.requestgenerators[0];

  useEffect(() => {
    if (data) {
      setLsiClient({
        FirstName: data?.findFirstLsiClient?.CLfname,
        LastName: data?.findFirstLsiClient?.CLlname,
        CompanyName: data?.findFirstLsiClient?.CLcompany,
        City: data?.findFirstLsiClient?.CLcity,
        State: data?.findFirstLsiClient?.CLstate,
        Phone: data?.findFirstLsiClient?.CLphone,
        Email: data?.findFirstLsiClient?.CLemail,
        CLnwOption: data?.findFirstLsiClient?.CLnwOption,
        CLbsOption: data?.findFirstLsiClient?.CLbsOption,
        ClientLogin: data?.findFirstLsiClient?.CLlogin,
        ClientID: data?.findFirstLsiClient?.CLid,
        CLUSAARequired: data?.findFirstLsiClient?.CLUSAARequired,
        CLSentryOption: data?.findFirstLsiClient?.CLSentryOption,
        IsUSAALegal: data?.findFirstLsiClient?.CLUSAALegalRequired,
        RGID: foundRatsClient?.RGid,
        ClientMasterID: foundRatsClient?.clientoffice?.COclientMasterID,
        shouldFilterContacts:
          foundRatsClient?.clientoffice?.clientmaster?.shouldFilterContacts,
      });
    }
  }, [data, ratsData?.requestgenerators, foundRatsClient]);
  return { lsiClient };
};

export default useLsiClient;
