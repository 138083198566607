import React from "react";
import PropTypes from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Control, Label, File } from "rbx";

import axios from "axios";

const { REACT_APP_DOCUMENT_HOST } = process.env;

const FileUploader = ({ name, value, onChange, dirPath }) => {
  const handleChange = async (e) => {
    const file = e.target?.files?.[0];
    if (dirPath && file) {
      const formData = new FormData();
      formData.append("file", file);
      const { data } = await axios.post(
        `${REACT_APP_DOCUMENT_HOST}${dirPath}`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      const result = decodeURIComponent(data).substr(data.lastIndexOf("/") + 1);
      onChange(name, result);
    }
  };

  return (
    <Control>
      <Label>File</Label>
      <File fullwidth hasName color="primary" size="small">
        <File.Label>
          <File.Input name="file" onChange={handleChange} />
          <File.CTA>
            <File.Icon>
              <FontAwesomeIcon icon="upload" />
            </File.Icon>
            {/* <File.Label as="span">Choose a File</File.Label> */}
          </File.CTA>
          <File.Name>{value || "Choose a file"}</File.Name>
        </File.Label>
      </File>
    </Control>
  );
};
FileUploader.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  dirPath: PropTypes.string,
  onChange: PropTypes.func,
};

FileUploader.defaultProps = {
  name: "",
  value: "",
  dirPath: "",
  onChange: () => {},
};

export default FileUploader;
