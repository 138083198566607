import React, { useState, useEffect } from "react";

import { Title, Field, Control, Input, Button, Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import { useApolloClient } from "@apollo/client";

import DataTable from "../../components/DataTable";

import COLUMNS from "./columns";

import { useModal } from "../../context";
import {
  debounce,
  downloadCSV,
  customToast,
  getFilterState,
  saveFilterState,
  omit,
} from "../../utils";

import { ALL_DOCUMENTS_QUERY, LIST_DOCUMENT_QUERY } from "../../graphql";

import AddDocumentModal from "./components/AddDocumentModal";

import { DocumentListFilters } from "./components";

const INITIAL_FILTER_STATE = {
  // status: { not: { equals: 1 } },
  status: { equals: "Active" },
  complete: { equals: "Complete" },
};

const DocumentListPage = (props) => {
  const { setModalOpen } = useModal();
  const history = useHistory();
  const client = useApolloClient();
  const [where, setWhere] = useState(
    getFilterState("DOCUMENT_LIST_FILTERS", { ...INITIAL_FILTER_STATE })
  );
  const [loading, setLoading] = useState(false);
  const [showFilters, setShowFilters] = useState(false);

  const handleRowClick = ({ SeqNo }) => {
    history.push(`/documents/${SeqNo}/profile`);
  };

  const handleAddClick = () => {
    setModalOpen(
      true,
      <AddDocumentModal
        onComplete={() => {
          setModalOpen(false, "");
        }}
      />
    );
  };

  const exportCSV = async () => {
    try {
      setLoading(true);
      await downloadCSV(
        client,
        {
          query: LIST_DOCUMENT_QUERY,
          where,
          orderBy: [{ id: "document", desc: false }],
        },
        "documents",
        COLUMNS
      );
    } catch (err) {
      customToast.error("Error exporting CSV");
    } finally {
      setLoading(false);
    }
  };

  const handleReset = () => {
    saveFilterState("DOCUMENT_LIST_FILTERS", { ...INITIAL_FILTER_STATE });
    setWhere({ ...INITIAL_FILTER_STATE });

    if (document.getElementById("search-input")) {
      document.getElementById("search-input").value = "";
    }
  };

  const handleChange = (name, value) => {
    setWhere((prev) =>
      value
        ? { ...prev, [name]: value }
        : Object.keys(prev).reduce((acc, curr) => {
            if (curr !== name) acc[curr] = prev[curr];
            return acc;
          }, {})
    );
  };

  useEffect(() => {
    saveFilterState("DOCUMENT_LIST_FILTERS", where);
  }, [where]);

  const getInputValue = () => where?.OR?.[0]?.document?.contains || "";

  return (
    <div>
      <div className="section-header">
        <Title style={{ marginRight: 15 }}>Documents</Title>
        <Field kind="group" style={{ paddingTop: "0.3rem" }}>
          <Control>
            <Input
              autoComplete="new"
              defaultValue={getInputValue()}
              id="search-input"
              placeholder="Search by Document"
              size="small"
              onChange={debounce(({ target: { value } }) => {
                if (value) {
                  setWhere((prev) => ({
                    ...prev,
                    OR: [
                      {
                        document: { contains: value },
                      },
                      {
                        description: { contains: value },
                      },
                      {
                        documentname: { contains: value },
                      },
                    ],
                  }));
                } else {
                  setWhere((prev) => omit(prev, ["OR"]));
                }
              }, 500)}
            />
          </Control>
          <Control>
            <Button
              color="warning"
              size="small"
              onClick={() => setShowFilters((prev) => !prev)}
            >
              <Icon>
                <FontAwesomeIcon icon="filter" />
              </Icon>
              <span>Filters</span>
            </Button>
          </Control>
          <Control>
            <Button color="primary" size="small" onClick={handleAddClick}>
              <Icon>
                <FontAwesomeIcon icon="plus" />
              </Icon>
              <span>Add</span>
            </Button>
          </Control>
          <Control>
            <Button
              color="info"
              disabled={loading}
              size="small"
              state={loading ? "loading" : ""}
              onClick={exportCSV}
            >
              <Icon>
                <FontAwesomeIcon icon="file-export" />
              </Icon>
              <span>Export</span>
            </Button>
          </Control>
        </Field>
      </div>
      {showFilters && (
        <DocumentListFilters
          filters={where}
          show={showFilters}
          onChange={handleChange}
          onReset={handleReset}
        />
      )}
      <DataTable
        aggregateKey="_all"
        aggregateName="aggregateDocument"
        columns={COLUMNS}
        fetchPolicy="cache-and-network"
        name="documents"
        orderBy={[{ id: "document", desc: false }]}
        query={ALL_DOCUMENTS_QUERY}
        where={where}
        onRowClick={handleRowClick}
      />
      {/* <pre>
        <code>{JSON.stringify(where, null, 2)}</code>
      </pre> */}
    </div>
  );
};

DocumentListPage.propTypes = {};

export default DocumentListPage;
