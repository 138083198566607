import React from "react";
import PropTypes from "prop-types";
import { Control, Label, Select } from "rbx";

const GenderSelect = ({
  label,
  size,
  name,
  onChange,
  disabled,
  value,
  required,
  id,
  fullWidth,
  options,
  getValue,
  setValue,
  showBlank,
}) => {
  const handleChange = (e) => {
    onChange(e.target.name, setValue(e.target.value));
  };
  const status = showBlank
    ? options
    : options.filter((option) => option.value !== "");

  return (
    <Control expanded={fullWidth} size={size}>
      {label && <Label>{label}</Label>}
      <Select.Container fullwidth size={size}>
        <Select
          disabled={disabled}
          id={id}
          name={name}
          required={required}
          style={{
            border: required ? "1px #e63362 solid" : "",
            boxShadow: required ? "0 0 0 0.125em rgba(230, 51, 98, 0.25)" : "",
          }}
          value={getValue(value)}
          onChange={handleChange}
        >
          {status.map((item) => (
            <Select.Option key={item.id} value={item.value}>
              {item.label}
            </Select.Option>
          ))}
        </Select>
      </Select.Container>
    </Control>
  );
};
GenderSelect.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.number,
  ]),
  name: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  id: PropTypes.string,
  fullWidth: PropTypes.bool,
  options: PropTypes.array,
  getValue: PropTypes.func,
  setValue: PropTypes.func,
  showBlank: PropTypes.bool,
};
GenderSelect.defaultProps = {
  size: "small",
  fullWidth: false,
  disabled: false,
  value: "",
  name: "",
  label: null,
  required: false,
  id: null,
  showBlank: true,
  options: [
    {
      name: "gender",
      value: "",
      label: "",
      id: 0,
    },
    {
      name: "gender",
      value: "Female",
      label: "Female",
      id: 1,
    },
    {
      name: "gender",
      value: "Male",
      label: "Male",
      id: 2,
    },
  ],
  getValue: (x) => x,
  setValue: (x) => x,
  onChange: () => null,
};

export default GenderSelect;
