const COLUMNS = [
  {
    Header: "Group Code",
    id: "groupcode",
    accessor: "groupcode",
  },
  {
    Header: "Group Description",
    id: "groupdesc",
    accessor: "groupdesc",
  },
];

export default COLUMNS;
