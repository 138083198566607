const DEFAULT_COLUMNS = [
  {
    Header: "Last Name",
    id: "lastname",
    accessor: "lastname",
    sortType: () => 0,
  },
  {
    Header: "First Name",
    id: "firstname",
    accessor: "firstname",
    sortType: () => 0,
  },
  {
    Header: "Credentials",
    id: "credentials",
    accessor: "credentials",
    sortType: () => 0,
  },
  {
    Header: "Specialty",
    id: "doctorSpecialties",
    accessor: "doctorSpecialties",
    sortType: () => 0,
  },
  {
    Header: "City",
    id: "city",
    accessor: "city",
    sortType: () => 0,
  },
  {
    Header: "State",
    id: "state",
    accessor: "state",
    sortType: () => 0,
  },
  {
    Header: "County",
    id: "county",
    accessor: "county",
    sortType: () => 0,
  },
  {
    Header: "ZIP",
    id: "zip",
    accessor: "zip",
    sortType: () => 0,
  },
  {
    Header: "SSN / Tax ID",
    id: "SSNTaxID",
    accessor: "SSNTaxID",
    sortType: () => 0,
  },
  {
    Header: "Status",
    id: "status",
    accessor: "status",
    sortType: () => 0,
  },
  {
    Header: "Proximity",
    id: "proximity",
    accessor: "proximity",
    Cell: ({ cell }) => (cell?.value ? cell.value.toFixed(2) : "0.00"),
    sortType: () => 0,
  },
];

export default DEFAULT_COLUMNS;
