import React from "react";

import PropTypes from "prop-types";

import { Control, Label, Select } from "rbx";

import { useQuery } from "@apollo/client";
import { ALL_OTHER_PARTY_TYPES_QUERY } from "../../graphql";

const OtherPartyTypeSelect = ({
  value,
  name,
  label,
  size,
  onChange,
  disabled,
  getValue,
  setValue,
  required,
  showAllOption,
  showAll,
}) => {
  const { data: otherPartyTypesData } = useQuery(ALL_OTHER_PARTY_TYPES_QUERY, {
    variables: {
      orderBy: { type: "asc" },
    },
  });

  const otherPartyTypes = otherPartyTypesData?.otherPartyTypes;

  const handleChange = (e) => {
    onChange(e.target.name, setValue(e.target.value || ""));
  };

  return (
    <Control>
      <Label>{label}</Label>
      <Select.Container
        fullwidth
        size={size}
        state={Array.isArray(otherPartyTypes) ? "" : "loading"}
      >
        <Select
          disabled={disabled}
          name={name}
          required={required}
          value={getValue(value)}
          onChange={handleChange}
        >
          {showAllOption ? (
            <Select.Option value="">All</Select.Option>
          ) : (
            <Select.Option value="" />
          )}
          {Array.isArray(otherPartyTypes) &&
            otherPartyTypes.map(({ type, description }) => (
              <Select.Option key={type} value={type}>
                {description}
              </Select.Option>
            ))}
        </Select>
      </Select.Container>
    </Control>
  );
};

OtherPartyTypeSelect.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
    PropTypes.object,
  ]),
  name: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  getValue: PropTypes.func,
  setValue: PropTypes.func,
  showAllOption: PropTypes.bool,
  showAll: PropTypes.bool,
};

OtherPartyTypeSelect.defaultProps = {
  showAll: null,
  value: "",
  name: "",
  label: "Case Type",
  size: "small",
  showAllOption: false,
  onChange: () => null,
  disabled: false,
  required: false,
  getValue: (x) => x,
  setValue: (x) => x,
};

export default OtherPartyTypeSelect;
