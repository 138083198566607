import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { AsyncPaginate } from "react-select-async-paginate";

import { Label, Control } from "rbx";
import { useApolloClient, useLazyQuery } from "@apollo/client";
import { ALL_DOCTORS_QUERY, SINGLE_DOCTOR_QUERY } from "../../graphql/doctor";

const OtherPartySelect = ({
  showLabel,
  name,
  value: initialValue,
  label,
  onChange,
  disabled,
  required,
  getValue,
  setValue,
}) => {
  const client = useApolloClient();

  const [getDoctor, { data: getDoctorData }] = useLazyQuery(
    SINGLE_DOCTOR_QUERY,
    {
      fetchPolicy: "cache-and-network",
    }
  );

  const [value, setLocalValue] = useState(null);
  const [defaultOptions, setDefaultOptions] = useState([]);

  const promiseOptions = async (inputValue, loadedOptions) => {
    const where = {
      OR: [
        {
          companyname: { startsWith: inputValue },
        },
      ],
    };

    const { data: doctorsData } = await client.query({
      query: ALL_DOCTORS_QUERY,
      variables: {
        take: 50,
        where: {
          ...where,
          status: { equals: "Active" },
          OPType: { equals: "OP" },
        },
        orderBy: [{ companyname: "asc" }],
        skip: loadedOptions.length,
      },
      fetchPolicy: "network-only",
    });
    const doctors = doctorsData?.doctors;

    const result = Array.isArray(doctors)
      ? doctors.map((doctor) => ({
          value: doctor.doctorcode,
          label: `${doctor.companyname}${
            doctor.OPSubType ? ` - ${doctor.OPSubType}` : ""
          }`,
        }))
      : [];

    setDefaultOptions((prev) => [...prev, ...result]);

    return { options: result, hasMore: doctors.length === 50 };
  };

  const handleChange = ({ value: newValue }) => {
    onChange(name, setValue(newValue));
  };

  useEffect(() => {
    if (initialValue) {
      getDoctor({
        variables: {
          where: { doctorcode: parseInt(getValue(initialValue), 10) },
        },
      });
    } else {
      setLocalValue(null);
    }
  }, [initialValue, getDoctor, getValue]);

  useEffect(() => {
    const doctor = getDoctorData?.doctor;
    if (doctor) {
      setLocalValue({
        value: doctor.doctorcode,
        label: `${doctor.companyname}${
          doctor.OPSubType ? ` - ${doctor.OPSubType}` : ""
        }`,
      });
    }
  }, [getDoctorData]);

  useEffect(() => {
    if (value) {
      const currOptions = [...defaultOptions];
      if (!currOptions.some((opt) => opt.value === value.value)) {
        setDefaultOptions((prev) => [value, ...prev]);
      }
    }
  }, [value, defaultOptions]);

  const indicateRequired =
    required && (!value || value === "" || value === "0" || value === 0);

  return (
    <Control expanded>
      {showLabel && <Label size="small">{label}</Label>}
      <AsyncPaginate
        cacheOptions
        defaultOptions={defaultOptions}
        isDisabled={disabled}
        loadOptions={promiseOptions}
        menuPlacement="auto"
        menuPortalTarget={document.body}
        placeholder="Start typing..."
        styles={{
          menuPortal: (provided) => ({
            ...provided,
            zIndex: 9999,
          }),
          indicatorsContainer: (provided) => ({
            ...provided,
            height: "inherit",
          }),
          indicatorSeparator: (provided) => ({
            ...provided,
            display: "none",
          }),
          container: (provided) => ({
            fontSize: 13,
          }),
          singleValue: (provided) => ({
            ...provided,
            top: "38%",
          }),
          control: (provided) => ({
            ...provided,
            minHeight: 30,
            height: 30,
            width: "100%",
            minWidth: 180,
            border: indicateRequired ? "1px #e63362 solid" : "",
            borderRadius: "4px",
            boxShadow: indicateRequired
              ? "0 0 0 0.125em rgba(230, 51, 98, 0.25)"
              : "",
            "&:hover": {
              borderColor: "hsl(0, 0%, 90%)",
              borderRadius: 4,
              cursor: disabled ? "not-allowed" : "pointer",
            },
          }),
          placeholder: (provided) => ({ ...provided, top: "38%" }),
          menu: (provided) => ({
            ...provided,
            fontSize: 12,
            zIndex: 999,
          }),
        }}
        value={value}
        onChange={handleChange}
      />
    </Control>
  );
};

OtherPartySelect.propTypes = {
  showLabel: PropTypes.bool,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  getValue: PropTypes.func,
  setValue: PropTypes.func,
};

OtherPartySelect.defaultProps = {
  name: "",
  label: "",
  value: "",
  onChange: () => null,
  showLabel: true,
  disabled: false,
  required: false,
  getValue: (x) => x,
  setValue: (x) => x,
};

export default OtherPartySelect;
