import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Control, Label, Select } from "rbx";
import { useLazyQuery } from "@apollo/client";

import { ALL_DOCTOR_LOCATIONS_QUERY } from "../../graphql";

const DoctorLocationSelect = ({
  value,
  name,
  label,
  size,
  onChange,
  disabled,
  required,
  getValue,
  setValue,
  doctorcode,
  showOnlyActives,
}) => {
  const [getDoctorLocations, { data: DoctorLocationsData }] = useLazyQuery(
    ALL_DOCTOR_LOCATIONS_QUERY
  );
  useEffect(() => {
    if (doctorcode) {
      getDoctorLocations({
        variables: {
          where: {
            doctorcode: { equals: parseInt(doctorcode, 10) },
            status: showOnlyActives ? { equals: "active" } : {},
          },
          orderBy: { location: { location: "asc" } },
        },
      });
    }
  }, [doctorcode, getDoctorLocations, showOnlyActives]);

  const indicateRequired =
    required && (!value || value === "" || value === "0" || value === 0);

  const doctorLocations = DoctorLocationsData?.doctorLocations || [];

  const handleChange = (e) => {
    onChange(e.target.name, setValue(e.target.value));
  };
  return (
    <Control expanded>
      <Label>{label}</Label>
      <Select.Container
        fullwidth
        size={size}
        state={Array.isArray(doctorLocations) ? "" : "loading"}
      >
        <Select
          disabled={disabled}
          name={name}
          style={{
            border: indicateRequired ? "1px #e63362 solid" : "",
            borderRadius: "4px",
            boxShadow: indicateRequired
              ? "0 0 0 0.125em rgba(230, 51, 98, 0.25)"
              : "",
          }}
          value={getValue(value)}
          onChange={handleChange}
        >
          <Select.Option value="" />
          {Array.isArray(doctorLocations) &&
            doctorLocations.map((doctorLocation) => (
              <Select.Option
                key={doctorLocation.locationcode}
                value={doctorLocation.locationcode}
              >
                {doctorLocation.location.location}
              </Select.Option>
            ))}
        </Select>
      </Select.Container>
    </Control>
  );
};

DoctorLocationSelect.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.number,
  ]),
  name: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  showOnlyActives: PropTypes.bool,
  getValue: PropTypes.func,
  setValue: PropTypes.func,
  doctorcode: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

DoctorLocationSelect.defaultProps = {
  value: "",
  name: "",
  label: "DoctorLocation",
  size: "small",
  onChange: () => null,
  disabled: false,
  required: false,
  getValue: (x) => x,
  setValue: (x) => x,
  doctorcode: "",
  showOnlyActives: false,
};

export default DoctorLocationSelect;
