import React, { useState, useEffect } from "react";
import { Title, Field, Control, Button, Icon, Input } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";

// import { useAuth } from "../../../../context/AuthContext";
import { useModal } from "../../../../context";
import DataTable from "../../../../components/DataTable";
import {
  LIST_USER_GROUPS_QUERY,
  CREATE_USER_GROUP_MUTATION,
  UPDATE_USER_GROUP_MUTATION,
  DELETE_USER_GROUP_MUTATION,
  // CREATE_AUDIT_TRAIL_MUTATION,
} from "../../../../graphql";
import { UserGroupModal } from "../../components/UserGroups";
import { debounce, customToast as toast } from "../../../../utils";
import Loader from "../../../../components/Loader";
import COLUMNS from "./columns";

const DEFAULT_FILTER_STATE = {
  OR: [
    {
      groupdesc: { contains: "" },
    },
  ],
};

const UserGroupsListPage = () => {
  // const { state: authState } = useAuth();
  const history = useHistory();

  const [where, setWhere] = useState(DEFAULT_FILTER_STATE);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const { setModalOpen } = useModal();

  const [createUserGroup] = useMutation(CREATE_USER_GROUP_MUTATION);
  const [updateUserGroup] = useMutation(UPDATE_USER_GROUP_MUTATION);
  const [deleteUserGroup] = useMutation(DELETE_USER_GROUP_MUTATION);
  // const [createAuditTrail] = useMutation(CREATE_AUDIT_TRAIL_MUTATION);

  useEffect(() => {
    const listener = history.listen(() => {
      const route = history.location.pathname;
      if (route === "/setup/user-functions") {
        return;
      }

      if (route.length > 18) {
        const groupcode = route.slice(19, route.length);

        setModalOpen(
          true,
          <UserGroupModal
            values={{ groupcode }}
            onComplete={(adding, inputs, previousCode, updated) => {
              if (inputs) {
                handleSave(adding, inputs, previousCode, updated);
              } else {
                setModalOpen(false);
              }
            }}
            onDelete={handleDelete}
          />
        );
      }
    });
    return () => {
      listener();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  const handleDelete = async (groupcode) => {
    try {
      setLoading(true);
      await deleteUserGroup({
        variables: {
          where: {
            groupcode,
          },
        },
        refetchQueries: [
          {
            query: LIST_USER_GROUPS_QUERY,
          },
        ],
      });
      toast.success("User Group deleted successfully");
    } catch (err) {
      toast.error("Error trying to delete User Group");
    } finally {
      setLoading(false);
    }
  };

  const handleRowClick = (row) => {
    const values = {
      groupcode: row.groupcode || "",
      groupdesc: row.groupdesc || "",
    };
    setModalOpen(
      true,
      <UserGroupModal
        values={values}
        onComplete={(adding, inputs, previousCode, updated) => {
          if (inputs) {
            handleSave(adding, inputs, previousCode, updated);
          } else {
            setModalOpen(false);
          }
        }}
        onDelete={handleDelete}
      />
    );
  };

  const handleSave = async (adding, inputs, previousCode, updated = []) => {
    try {
      setLoading(true);
      if (adding) {
        await createUserGroup({
          variables: {
            data: {
              ...inputs,
            },
          },
          refetchQueries: [
            {
              query: LIST_USER_GROUPS_QUERY,
            },
          ],
        });
        // await createAuditTrail({
        //   variables: {
        //     data: {
        //       tablename: "tblUserGroup",
        //       entityid: String(createUserGroupData.createUserGroup.groupcode),
        //       type: "New",
        //       description: "New User Group created",
        //       useridadded: authState.user.userid,
        //       dateadded: getDateEST(),
        //     },
        //   },
        // });
        setModalOpen(false);
        toast.success("User Group created successfully.");
      } else {
        await updateUserGroup({
          variables: {
            data: {
              groupdesc: { set: inputs.groupdesc },
            },
            where: {
              groupcode: previousCode,
            },
          },
          refetchQueries: [
            {
              query: LIST_USER_GROUPS_QUERY,
            },
          ],
        });
        // await createAuditTrail({
        //   variables: {
        //     data: {
        //       tablename: "tblUserGroup",
        //       entityid: String(previousCode),
        //       type: "Update",
        //       description: `Updated fields: ${updated.join(", ")}`,
        //       useridadded: authState.user.userid,
        //       dateadded: getDateEST(),
        //     },
        //   },
        // });
        setModalOpen(false);
        toast.success("User Group updated successfully.");
      }
    } catch (err) {
      if (err.message.includes("Unique constraint")) {
        toast.error("This User Group already exists");
      } else {
        toast.error("Error saving User Group");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleSearchChange = debounce(({ target: { value } }) => {
    if (value) {
      setSearchValue(value);
      setWhere({
        OR: [
          {
            groupdesc: { contains: value },
          },
        ],
      });
    } else {
      setSearchValue("");
      setWhere({
        OR: [
          {
            groupdesc: { contains: "" },
          },
        ],
      });
    }
  }, 500);

  if (loading) return <Loader />;

  return (
    <div>
      <div className="section-header">
        <Title style={{ marginRight: 15 }}>User Groups</Title>
        <Field kind="group" style={{ paddingTop: "0.3rem" }}>
          <Control>
            <Input
              autoComplete="off"
              id="search-input"
              placeholder="Search..."
              size="small"
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value);
                handleSearchChange(e);
              }}
            />
          </Control>

          <Control>
            <Button
              color="primary"
              size="small"
              onClick={() => {
                setModalOpen(
                  true,
                  <UserGroupModal
                    adding
                    onComplete={(adding, inputs, updated) => {
                      if (inputs) {
                        handleSave(adding, inputs, updated);
                      } else {
                        setModalOpen(false);
                      }
                    }}
                  />
                );
              }}
            >
              <Icon>
                <FontAwesomeIcon icon="plus" />
              </Icon>
              <span>Add</span>
            </Button>
          </Control>
        </Field>
      </div>

      <DataTable
        aggregateKey="groupcode"
        aggregateName="aggregateUserGroup"
        columns={COLUMNS}
        fetchPolicy="network-only"
        name="userGroups"
        orderBy={[{ id: "groupcode", desc: false }]}
        query={LIST_USER_GROUPS_QUERY}
        where={where}
        onRowClick={handleRowClick}
      />
    </div>
  );
};

UserGroupsListPage.propTypes = {};

export default UserGroupsListPage;
