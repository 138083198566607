import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Title, Button, Field, Control } from "rbx";
import { useApolloClient, useMutation, useLazyQuery } from "@apollo/client";

import ReportStatusCodeForm from "../ReportStatusCodeForm";
import { useModal, useAuth } from "../../../../../context";
import {
  FIND_FIRST_CASE_ITEM_QUERY,
  CREATE_RPT_STATUS_MUTATION,
  UPDATE_RPT_STATUS_MUTATION,
  DELETE_RPT_STATUS_MUTATION,
  // CREATE_AUDIT_TRAIL_MUTATION,
  LIST_RPT_STATUSES_QUERY,
  SINGLE_RPT_STATUS_QUERY,
} from "../../../../../graphql";
import {
  customToast as toast,
  getDateEST,
  convertTimeZoneDataBase,
} from "../../../../../utils";
import Confirmation from "../../../../../components/Confirmation";

const INITIAL_STATE = {
  description: "",
};

class CustomReportStatusCodeError extends Error {}

const ReportStatusCodeModal = ({ onComplete, rptcode }) => {
  const client = useApolloClient();
  const { setModalOpen } = useModal();
  const { state: authState } = useAuth();
  const [inputs, setInputs] = useState(INITIAL_STATE);
  const [updated, setUpdated] = useState([]);
  const [loading, setLoading] = useState(false);

  const [getRptStatus, { data: rptStatusData }] = useLazyQuery(
    SINGLE_RPT_STATUS_QUERY
  );
  const [createRptCode] = useMutation(CREATE_RPT_STATUS_MUTATION);
  const [updateRtpCode] = useMutation(UPDATE_RPT_STATUS_MUTATION);
  const [deleteRptCode] = useMutation(DELETE_RPT_STATUS_MUTATION);
  // const [createAuditTrail] = useMutation(CREATE_AUDIT_TRAIL_MUTATION);

  useEffect(() => {
    if (rptcode) {
      getRptStatus({
        variables: {
          where: {
            rptcode,
          },
        },
      });
    }
  }, [getRptStatus, rptcode]);

  useEffect(() => {
    if (rptStatusData?.rptStatus) {
      setInputs({
        description: rptStatusData.rptStatus.description || "",
      });
    }
  }, [rptStatusData]);

  const handleChange = (name, value) => {
    setInputs((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (rptcode) {
      setUpdated((prev) => (prev.includes(name) ? prev : [...prev, name]));
    }
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const date = convertTimeZoneDataBase(getDateEST());
      setLoading(true);
      if (!rptcode) {
        await createRptCode({
          variables: {
            data: {
              ...inputs,
              useridedited: authState.user.userid,
              dateedited: date,
              useridadded: authState.user.userid,
              dateadded: date,
            },
          },
          refetchQueries: [
            {
              query: LIST_RPT_STATUSES_QUERY,
            },
          ],
        });
        // await createAuditTrail({
        //   variables: {
        //     data: {
        //       tablename: "tblRptStatus",
        //       entityid: String(createRptCodeData.createRptStatus.rptcode),
        //       type: "New",
        //       description: "New Report Status Code type created",
        //       useridadded: authState.user.userid,
        //       dateadded: getDateEST(),
        //     },
        //   },
        //   refetchQueries: [
        //     {
        //       query: SINGLE_RPT_STATUS_QUERY,
        //       variables: {
        //         where: {
        //           rptcode: createRptCodeData.createRptStatus.rptcode,
        //         },
        //       },
        //     },
        //   ],
        // });
        toast.success("Report Status Code created successfully.");
      } else {
        await updateRtpCode({
          variables: {
            data: {
              description: { set: inputs.description },
              useridedited: { set: authState.user.userid },
              dateedited: { set: date },
            },
            where: {
              rptcode,
            },
          },
          refetchQueries: [
            {
              query: LIST_RPT_STATUSES_QUERY,
            },
            {
              query: SINGLE_RPT_STATUS_QUERY,
              variables: {
                where: {
                  rptcode,
                },
              },
            },
          ],
        });
        // await createAuditTrail({
        //   variables: {
        //     data: {
        //       tablename: "tblRptStatus",
        //       entityid: String(rptcode),
        //       type: "Update",
        //       description: `Updated fields: ${updated.join(", ")}`,
        //       useridadded: authState.user.userid,
        //       dateadded: getDateEST(),
        //     },
        //   },
        // });
        toast.success("Report Status Code updated successfully.");
      }
      onComplete();
    } catch (err) {
      if (err.message.includes("Unique constraint")) {
        toast.error("This Report Status Code already exists");
      } else {
        toast.error("Error saving Report Status Code");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (e) => {
    e.preventDefault();

    const performDelete = async () => {
      try {
        setLoading(true);
        const { data: CaseItemData } = await client.query({
          query: FIND_FIRST_CASE_ITEM_QUERY,
          variables: {
            where: {
              rptstatus: { equals: String(rptcode) },
            },
          },
          fetchPolicy: "network-only",
        });
        if (CaseItemData?.findFirstCaseItem) {
          throw new CustomReportStatusCodeError(
            "This Report Status Code is associated with a Case and cannot be deleted."
          );
        }
        await deleteRptCode({
          variables: {
            where: {
              rptcode,
            },
          },
          refetchQueries: [
            {
              query: LIST_RPT_STATUSES_QUERY,
              options: {
                fetchPolicy: "network-only",
              },
            },
            {
              query: SINGLE_RPT_STATUS_QUERY,
              variables: {
                where: {
                  rptcode,
                },
              },
            },
          ],
        });
        toast.success("Report Status Code deleted successfully");
        onComplete();
      } catch (err) {
        if (err instanceof CustomReportStatusCodeError) {
          toast.error(err.message);
        } else {
          toast.error("Error trying to delete Report Status Code");
        }
      } finally {
        setLoading(false);
      }
    };
    setModalOpen(
      true,
      <Confirmation
        message="Are you sure you want to delete this Report Status Code?"
        onCancel={() =>
          setModalOpen(
            true,
            <ReportStatusCodeModal rptcode={rptcode} onComplete={onComplete} />
          )
        }
        onConfirm={performDelete}
      />
    );
  };

  const isDisabled =
    !inputs.description || (rptcode && !updated?.length) || loading;

  return (
    <form id="problem-form" onSubmit={handleSubmit}>
      <header className="page-head">
        <div className="page-head-start">
          <Title size={5}>
            {[rptcode ? "Edit" : "Create", "Report Status Code"]
              .filter(Boolean)
              .join(" ")}
          </Title>
        </div>
        <div className="page-head-end">
          <Button.Group hasAddons>
            <Button
              size="small"
              type="button"
              onClick={() => onComplete(false)}
            >
              <span>Cancel</span>
            </Button>

            <Button
              color="primary"
              disabled={isDisabled}
              form="problem-form"
              size="small"
              state={loading ? "loading" : ""}
              type="submit"
            >
              <span>Submit</span>
            </Button>
          </Button.Group>
        </div>
      </header>
      <hr />
      <ReportStatusCodeForm inputs={inputs} onChange={handleChange} />
      <hr />
      {rptcode && (
        <Field>
          <Control>
            <Button
              color="danger"
              size="small"
              type="button"
              onClick={handleDelete}
            >
              <span>Delete</span>
            </Button>
          </Control>
        </Field>
      )}
    </form>
  );
};

ReportStatusCodeModal.propTypes = {
  onComplete: PropTypes.func,
  rptcode: PropTypes.number,
};

ReportStatusCodeModal.defaultProps = {
  onComplete: () => null,
  rptcode: null,
};

export default ReportStatusCodeModal;
