import { format } from "date-fns";
import { convertTimeZone } from "../../../../utils";

export const DEFAULT_COLUMNS = [
  {
    Header: "Case #",
    id: "casenbr",
    accessor: "casenbr",
  },
  {
    Header: "Appt",
    id: "Appttime",
    accessor: "Appttime",
    Cell: ({ cell }) =>
      cell?.value
        ? format(convertTimeZone(cell.value), "MM/dd/yyyy hh:mm a")
        : "--",
  },
  {
    Header: "Examinee",
    id: "examinee:lastname",
    accessor: "examinee",
    Cell: ({ cell }) =>
      cell.value ? `${cell.value.lastname}, ${cell.value.firstname}` : "--",
  },
  {
    Header: "Doctor",
    id: "DoctorName",
    accessor: "DoctorName",
  },
  {
    Header: "Status",
    id: "status",
    accessor: "Status.statusdesc",
  },
  {
    Header: "Case Type",
    id: "casetype",
    accessor: "caseType.description",
  },
  {
    Header: "Service",
    id: "service:description",
    accessor: "service.description",
  },
];

export default DEFAULT_COLUMNS;
