import React from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";

import ClientDefaultDocuments from "../../components/ClientDefaultDocuments";

import ClientDefaultFrom from "../../components/ClientDefaultForm";

const Defaults = ({ client, onChange, disabled, onSubmit }) => {
  const { clientcode } = useParams();
  return (
    <div>
      <ClientDefaultFrom
        client={client}
        disabled={disabled}
        onChange={onChange}
        onSubmit={onSubmit}
      />
      <hr />
      <ClientDefaultDocuments clientcode={clientcode} />
    </div>
  );
};

Defaults.propTypes = {
  client: PropTypes.object,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  onSubmit: PropTypes.func,
};

Defaults.defaultProps = {
  client: {},
  onChange: (e) => e,
  disabled: false,
  onSubmit: (e) => e,
};

export default Defaults;
