const EXAMINEE_FIELDS = [
  { name: "Country", type: "String", entity: "examinee" },
  { name: "DOB", type: "DateTime", entity: "examinee" },
  { name: "EmployerAddr1", type: "String", entity: "examinee" },
  { name: "EmployerCity", type: "String", entity: "examinee" },
  { name: "EmployerContactFirstName", type: "String", entity: "examinee" },
  { name: "EmployerContactLastName", type: "String", entity: "examinee" },
  { name: "EmployerEmail", type: "String", entity: "examinee" },
  { name: "EmployerFax", type: "String", entity: "examinee" },
  { name: "EmployerPhone", type: "String", entity: "examinee" },
  { name: "EmployerPhoneExt", type: "String", entity: "examinee" },
  { name: "EmployerState", type: "String", entity: "examinee" },
  { name: "EmployerZip", type: "String", entity: "examinee" },
  { name: "InsuredAddr1", type: "String", entity: "examinee" },
  { name: "InsuredCity", type: "String", entity: "examinee" },
  { name: "InsuredEmail", type: "String", entity: "examinee" },
  { name: "InsuredFax", type: "String", entity: "examinee" },
  { name: "InsuredPhone", type: "String", entity: "examinee" },
  { name: "InsuredPhoneExt", type: "String", entity: "examinee" },
  { name: "InsuredState", type: "String", entity: "examinee" },
  { name: "InsuredZip", type: "String", entity: "examinee" },
  { name: "LastInitial", type: "String", entity: "examinee" },
  { name: "SSN", type: "String", entity: "examinee" },
  { name: "TreatingPhysicianAddr1", type: "String", entity: "examinee" },
  { name: "TreatingPhysicianCity", type: "String", entity: "examinee" },
  { name: "TreatingPhysicianEmail", type: "String", entity: "examinee" },
  { name: "TreatingPhysicianFax", type: "String", entity: "examinee" },
  { name: "TreatingPhysicianLicenseNbr", type: "String", entity: "examinee" },
  { name: "TreatingPhysicianPhone", type: "String", entity: "examinee" },
  { name: "TreatingPhysicianPhoneExt", type: "String", entity: "examinee" },
  { name: "TreatingPhysicianState", type: "String", entity: "examinee" },
  { name: "TreatingPhysicianTaxID", type: "String", entity: "examinee" },
  { name: "TreatingPhysicianZip", type: "String", entity: "examinee" },
  { name: "addr1", type: "String", entity: "examinee" },
  { name: "addr2", type: "String", entity: "examinee" },
  { name: "chartnbr", type: "Int", entity: "examinee" },
  { name: "city", type: "String", entity: "examinee" },
  { name: "county", type: "String", entity: "examinee" },
  { name: "dateadded", type: "DateTime", entity: "examinee" },
  { name: "dateedited", type: "DateTime", entity: "examinee" },
  { name: "email", type: "String", entity: "examinee" },
  { name: "employer", type: "String", entity: "examinee" },
  { name: "fax", type: "String", entity: "examinee" },
  { name: "firstname", type: "String", entity: "examinee" },
  { name: "insured", type: "String", entity: "examinee" },
  { name: "lastname", type: "String", entity: "examinee" },
  { name: "middleinitial", type: "String", entity: "examinee" },
  { name: "note", type: "String", entity: "examinee" },
  { name: "oldchartnbr", type: "String", entity: "examinee" },
  { name: "phone1", type: "String", entity: "examinee" },
  { name: "phone2", type: "String", entity: "examinee" },
  { name: "policynumber", type: "String", entity: "examinee" },
  { name: "prefix", type: "String", entity: "examinee" },
  { name: "sex", type: "String", entity: "examinee" },
  { name: "state", type: "String", entity: "examinee" },
  { name: "treatingphysician", type: "String", entity: "examinee" },
  { name: "usddate1", type: "DateTime", entity: "examinee" },
  { name: "usddate2", type: "DateTime", entity: "examinee" },
  { name: "usdint1", type: "Int", entity: "examinee" },
  { name: "usdint2", type: "Int", entity: "examinee" },
  { name: "usdmoney1", type: "Float", entity: "examinee" },
  { name: "usdmoney2", type: "Float", entity: "examinee" },
  { name: "usdtext1", type: "String", entity: "examinee" },
  { name: "usdtext2", type: "String", entity: "examinee" },
  { name: "usdvarchar1", type: "String", entity: "examinee" },
  { name: "usdvarchar2", type: "String", entity: "examinee" },
  { name: "useridadded", type: "String", entity: "examinee" },
  { name: "useridedited", type: "String", entity: "examinee" },
  { name: "zip", type: "String", entity: "examinee" },
];

export default EXAMINEE_FIELDS;
