import React from "react";
import PropTypes from "prop-types";

import { Content, Button, Control, Field } from "rbx";

import { toast } from "react-toastify";
import { useMutation } from "@apollo/client";

import { useModal, useAuth, useExceptions } from "../../../../context";

import {
  convertTimeZoneDataBase,
  getDateEST,
  getFilterState,
} from "../../../../utils";

import {
  BATCH_AWAITING_APPT_UPDATE_MUTATION,
  LIST_CASE_CLIENT_QUERY,
} from "../../../../graphql";

const CaseMarkAwaitingApptModal = ({ selected, onComplete, onCancel }) => {
  const { setModalOpen } = useModal();
  const { state: authState } = useAuth();
  const { onSetExceptions } = useExceptions();

  const [
    batchAwaitingApptUpdate,
    { loading: BatchUpdateLoading },
  ] = useMutation(BATCH_AWAITING_APPT_UPDATE_MUTATION, {
    refetchQueries: [
      {
        query: LIST_CASE_CLIENT_QUERY,
        variables: {
          where: getFilterState("CASE_LIST_AWAITING_APPT_FILTERS", {
            status: { equals: 1 },
          }),
        },
      },
    ],
  });

  const handleCancel = () => {
    setModalOpen(false, "");
    if (onCancel && typeof onCancel === "function") {
      onCancel();
    }
  };

  const handleConfirmClick = async () => {
    try {
      const date = convertTimeZoneDataBase(getDateEST());

      const { data: BatchAwaitingApptData } = await batchAwaitingApptUpdate({
        variables: {
          input: {
            selected,
            userid: authState?.user?.userid,
            date,
          },
        },
      });
      const batchExceptions = BatchAwaitingApptData?.batchAwaitingApptUpdate;

      onComplete();

      if (Array.isArray(batchExceptions)) {
        onSetExceptions(batchExceptions);
      }
      toast.success("Cases updated successfully.");
    } catch (err) {
      toast.error("Error Updating Cases");
    }
  };

  return (
    <div>
      <Content size="medium" textAlign="centered">
        <p>
          {`Are you sure you would like to update these ${selected.length} cases?`}
        </p>
      </Content>
      <Field
        kind="group"
        style={{ textAlign: "center", width: 200, margin: "0 auto" }}
      >
        <Control expanded>
          <Button
            fullwidth
            color="success"
            disabled={BatchUpdateLoading}
            size="small"
            state={BatchUpdateLoading ? "loading" : ""}
            onClick={handleConfirmClick}
          >
            Yes
          </Button>
        </Control>
        <Control expanded>
          <Button
            fullwidth
            color="danger"
            disabled={BatchUpdateLoading}
            size="small"
            onClick={handleCancel}
          >
            No
          </Button>
        </Control>
      </Field>
    </div>
  );
};

CaseMarkAwaitingApptModal.propTypes = {
  selected: PropTypes.arrayOf(
    PropTypes.shape({ casenbr: PropTypes.number, type: PropTypes.string })
  ).isRequired,
  onCancel: PropTypes.func,
  onComplete: PropTypes.func,
};

CaseMarkAwaitingApptModal.defaultProps = {
  onCancel: () => {},
  onComplete: () => {},
};

export default CaseMarkAwaitingApptModal;
