import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { Title, Button, Field, Control, Input, Icon, Label } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { format, endOfDay, startOfDay } from "date-fns";
import { useQuery } from "@apollo/client";

import { useHistory } from "react-router-dom";
import Table from "../../../../components/DataTable/Table";
import COLUMNS from "./columns";

import {
  SINGLE_DOCTOR_QUERY,
  SINGLE_LOCATION_QUERY,
  ALL_CASE_APPTS_QUERY,
} from "../../../../graphql";

const InvestigatorBlockTimeModal = ({
  doctorcode,
  locationcode,
  onComplete,
}) => {
  const history = useHistory();
  const dateRef = useRef();
  const [date, setDate] = useState(format(new Date(), "yyyy-MM-dd"));
  const endOfSelectedDay = endOfDay(
    new Date(date.split("-").map((x) => parseInt(x, 10)))
  );
  const startOfSelectedDay = startOfDay(
    new Date(date.split("-").map((x) => parseInt(x, 10)))
  );

  const { data: DoctorData } = useQuery(SINGLE_DOCTOR_QUERY, {
    variables: { where: { doctorcode: parseInt(doctorcode, 10) } },
  });

  const { data: LocationData } = useQuery(SINGLE_LOCATION_QUERY, {
    variables: { where: { locationcode: parseInt(locationcode, 10) } },
  });

  const { data: ApptsData, loading: ApptsDataLoading } = useQuery(
    ALL_CASE_APPTS_QUERY,
    {
      variables: {
        where: {
          doctorcode: { equals: parseInt(doctorcode, 10) },
          doctorlocation: { equals: parseInt(locationcode, 10) },
          Appttime: {
            gte: startOfSelectedDay,
            lte: endOfSelectedDay,
          },
        },
        orderBy: [
          {
            Appttime: "asc",
          },
        ],
      },
    }
  );

  const handleRowClick = ({ casenbr }) => {
    onComplete();
    history.push(`/cases/${casenbr}/profile`);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const value = dateRef?.current?.value;

    if (value) {
      setDate(value);
    }
  };
  const data = ApptsData?.cases || [];
  const doctor = DoctorData?.doctor || {};
  const location = LocationData?.location || {};

  return (
    <div id="doctor-block-time-modal">
      <header className="page-head">
        <div className="page-head-start">
          <Title size={5}>
            {[doctor?.firstname, doctor?.lastname, "at", location?.location]
              .filter(Boolean)
              .join(" ")}
          </Title>
        </div>
        <div className="page-head-end">
          <Button size="small" type="button" onClick={() => onComplete(false)}>
            <span>Close</span>
          </Button>
        </div>
      </header>
      <hr />
      <Field
        as="form"
        kind="group"
        style={{ marginBottom: "1.5rem" }}
        onSubmit={handleSubmit}
      >
        <Control>
          <Input
            required
            defaultValue={date}
            ref={dateRef}
            size="small"
            type="date"
          />
        </Control>
        <Control>
          <Button
            color="primary"
            disabled={ApptsDataLoading}
            size="small"
            state={ApptsDataLoading ? "loading" : ""}
            type="submit"
          >
            <Icon>
              <FontAwesomeIcon icon="sync-alt" />
            </Icon>
            <span>Submit</span>
          </Button>
        </Control>
      </Field>
      <div style={{ height: 200, overflowY: "auto", marginBottom: "1.5rem" }}>
        <Table
          columns={COLUMNS}
          data={data}
          initialSortBy={[{ id: "Appttime", desc: false }]}
          onRowClick={handleRowClick}
        />
      </div>
      {/* <hr /> */}
      <Label>Investigator Notes</Label>
      <pre
        style={{
          padding: "0.5rem",
          fontSize: "12px",
          color: "RoyalBlue",
          height: 150,
          overflowY: "auto",
        }}
      >
        <code>{doctor?.notes}</code>
      </pre>
      <hr />
    </div>
  );
};

InvestigatorBlockTimeModal.propTypes = {
  onComplete: PropTypes.func.isRequired,
  locationcode: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  doctorcode: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
};

export default InvestigatorBlockTimeModal;
