import React from "react";

import { Switch, Route } from "react-router-dom";

import ServiceEditPage from "./ServiceEditPage";
import ServicesListPage from "./ServicesListPage";

const ServicesRouter = (props) => (
  <Switch>
    <Route path="/setup/services/:servicecode">
      <ServiceEditPage />
    </Route>
    <Route path="/setup/services">
      <ServicesListPage />
    </Route>
  </Switch>
);

export default ServicesRouter;
