const doctor = {
  name: "doctors",
  label: "Doctors",
  icon: "briefcase-medical",

  active: (location) => {
    const [, p] = location.pathname.split("/");
    return /doctors/.test(p);
  },
  to: "/doctors",
};

export default doctor;
