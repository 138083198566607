import { format } from "date-fns";
import { convertTimeZone } from "../../utils";

const DEFAULT_COLUMNS = [
  {
    Header: "Last Name",
    accessor: "lastname",
    id: "lastname",
    Cell: ({ cell }) => cell?.value || "--",
  },
  {
    Header: "first name",
    accessor: "firstname",
    id: "firstname",
    Cell: ({ cell }) => cell?.value || "--",
  },
  {
    Header: "Address 1",
    accessor: "addr1",
    id: "addr1",
    Cell: ({ cell }) => cell?.value || "--",
  },
  {
    Header: "Address 2",
    accessor: "addr2",
    id: "addr2",
    Cell: ({ cell }) => cell?.value || "--",
  },
  {
    Header: "city",
    accessor: "city",
    id: "city",
    Cell: ({ cell }) => cell?.value || "--",
  },
  {
    Header: "state",
    accessor: "state",
    id: "state",
    Cell: ({ cell }) => cell?.value || "--",
  },
  {
    Header: "DOB",
    accessor: "DOB",
    id: "DOB",
    Cell: ({ cell }) =>
      cell.value ? format(convertTimeZone(cell.value), "MM/dd/yyyy") : "--",
  },
  {
    Header: "note",
    accessor: "note",
    id: "note",
    Cell: ({ cell }) => cell?.value || "--",
  },
];
export default DEFAULT_COLUMNS;
