import React from "react";
import PropTypes from "prop-types";

import { Title, Button } from "rbx";
import DataTable from "../../../../components/DataTable";
import { LIST_CASES_QUERY } from "../../../../graphql";
import COLUMNS from "./columns";

const CaseClaimNumberCheckModal = ({ claimnbr, onComplete }) => (
  <div className="claim-number-check-modal">
    <header className="page-head">
      <div className="page-head-start">
        <Title size={5}>Claim Number Check</Title>
      </div>
      <div className="page-head-end">
        <Button.Group hasAddons>
          <Button size="small" type="button" onClick={() => onComplete(false)}>
            <span>Cancel</span>
          </Button>
        </Button.Group>
      </div>
    </header>
    <hr />
    <DataTable
      aggregateKey="_all"
      aggregateName="aggregateCase"
      columns={COLUMNS}
      name="cases"
      query={LIST_CASES_QUERY}
      where={{ claimnbr: { equals: claimnbr } }}
    />
    <hr />
  </div>
);

CaseClaimNumberCheckModal.propTypes = {
  claimnbr: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  onComplete: PropTypes.func,
};

CaseClaimNumberCheckModal.defaultProps = {
  onComplete: () => null,
};

export default CaseClaimNumberCheckModal;
