import React from "react";

import { Switch, Route } from "react-router-dom";

import InvestigatorPage from "./InvestigatorPage";
import InvestigatorListPage from "./InvestigatorListPage";

const InvestigatorRouter = (props) => (
  <Switch>
    <Route path="/investigators/:doctorcode/:tab">
      <InvestigatorPage />
    </Route>
    <Route path="/investigators">
      <InvestigatorListPage />
    </Route>
  </Switch>
);

export default InvestigatorRouter;
