import React from "react";
import PropTypes from "prop-types";

import { isValid } from "date-fns";

import { Field, Textarea, Control, Input, Label, Column } from "rbx";
import DateInput from "../../../../components/DateInput";
import NativeSelect from "../../../../components/NativeSelect";
import BooleanInput from "../../../../components/BooleanInput";

const InvestigatorUserDefinedFieldsForm = ({ inputs, disabled, onChange }) => (
  <div className="doctor-form">
    <Column.Group>
      <Column size={4}>
        <Field>
          <Control>
            <Label>Open Field</Label>
            <Input
              autoComplete="new"
              name="usdvarchar1"
              size="small"
              type="text"
              value={inputs.usdvarchar1}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </Control>
        </Field>
        <Field kind="group">
          <Control expanded>
            <Label>Web Password</Label>
            <Input
              autoComplete="new"
              name="usdvarchar2"
              size="small"
              type="text"
              value={inputs.usdvarchar2}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </Control>
          <Control expanded>
            <Label>First Looker</Label>
            <Input
              autoComplete="new"
              name="usdvarchar3"
              size="small"
              type="text"
              value={inputs.usdvarchar3}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </Control>
        </Field>
        <NativeSelect
          fullWidth
          disabled={disabled}
          label="Type"
          name="usdint1"
          options={[
            {
              name: "usdint1",
              value: 1,
              label: "Investigator",
            },
            {
              name: "usdint1",
              value: 2,
              label: "Doctor",
            },
          ]}
          size="small"
          value={inputs.usdint1}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
        <Field>
          <Control>
            <Label>Signature on File</Label>
            <BooleanInput
              disabled={disabled}
              name="usdint2"
              size="small"
              type="text"
              value={inputs.usdint2 === 1}
              onChange={(name, value) => onChange(name, value ? 1 : 0)}
            />
          </Control>
        </Field>
      </Column>
      <Column size={4}>
        <Field>
          <DateInput
            getValue={(x) => (x ? x.substr(0, 10) : "")}
            label="Desktop Cred Date"
            name="usddate1"
            setValue={(x) => {
              const date = new Date(x.split("-").map((y) => parseInt(y, 10)));
              if (x && isValid(date)) {
                return date.toISOString();
              }
              return "";
            }}
            size="small"
            value={inputs.usddate1}
            onChange={onChange}
          />
        </Field>
        <Field>
          <DateInput
            getValue={(x) => (x ? x.substr(0, 10) : "")}
            label="Cred Request Date"
            name="usddate2"
            setValue={(x) => {
              const date = new Date(x.split("-").map((y) => parseInt(y, 10)));
              if (x && isValid(date)) {
                return date.toISOString();
              }
              return "";
            }}
            size="small"
            value={inputs.usddate2}
            onChange={onChange}
          />
        </Field>
        <Field>
          <DateInput
            getValue={(x) => (x ? x.substr(0, 10) : "")}
            label="Full Credential Date"
            name="usddate3"
            setValue={(x) => {
              const date = new Date(x.split("-").map((y) => parseInt(y, 10)));
              if (x && isValid(date)) {
                return date.toISOString();
              }
              return "";
            }}
            size="small"
            value={inputs.usddate3}
            onChange={onChange}
          />
        </Field>
        <Field>
          <DateInput
            getValue={(x) => (x ? x.substr(0, 10) : "")}
            label="Recredential Date"
            name="usddate4"
            setValue={(x) => {
              const date = new Date(x.split("-").map((y) => parseInt(y, 10)));
              if (x && isValid(date)) {
                return date.toISOString();
              }
              return "";
            }}
            size="small"
            value={inputs.usddate4}
            onChange={onChange}
          />
        </Field>
      </Column>
      <Column size={4}>
        <Field>
          <Control>
            <Label>Open Field</Label>
            <Textarea
              name="usdtext1"
              rows="5"
              size="small"
              value={inputs.usdtext1}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </Control>
        </Field>
        <Field>
          <Control>
            <Label>Open Field</Label>
            <Textarea
              name="usdtext2"
              rows="4"
              size="small"
              value={inputs.usdtext2}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </Control>
        </Field>
      </Column>
    </Column.Group>
  </div>
);
InvestigatorUserDefinedFieldsForm.propTypes = {
  inputs: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

InvestigatorUserDefinedFieldsForm.defaultProps = {};

export default InvestigatorUserDefinedFieldsForm;
