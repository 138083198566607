import React, { useMemo } from "react";
import PropTypes from "prop-types";

import { useParams } from "react-router-dom";
import { useMutation } from "@apollo/client";

import { toast } from "react-toastify";

import { Field, Control, Input, Title, Label, Column, Button, Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useModal } from "../../../../context";

import FlatDataTable from "../../../../components/FlatDataTable";
import BooleanInput from "../../../../components/BooleanInput";
import StatusSelect from "../../../../components/StatusSelect";
import SchedulePrioritySelect from "../../../../components/SchedulePrioritySelect";
import ProviderTypeSelect from "../../../../components/ProviderTypeSelect";
import DoctorSpecialtyModal from "../DoctorSpecialtyModal";
import Confirmation from "../../../../components/Confirmation";

import {
  LIST_DOCTOR_SPECIALTIES_QUERY,
  DELETE_DOCTOR_SPECIALTY_MUTATION,
} from "../../../../graphql";

import { generateColumns } from "./columns";

const DoctorGeneralForm = ({ inputs, onChange, disabled }) => {
  const { setModalOpen } = useModal();
  const { doctorcode } = useParams();
  const [deleteDoctorSpecialty] = useMutation(DELETE_DOCTOR_SPECIALTY_MUTATION);

  const handleAddClick = () => {
    setModalOpen(
      true,
      <DoctorSpecialtyModal
        doctorcode={doctorcode}
        onComplete={() => setModalOpen(false, "")}
      />
    );
  };

  const COLUMNS = useMemo(() => {
    const handleDelete = (doctorSpecialty) => {
      const performDelete = async () => {
        try {
          setModalOpen(false, "");
          await deleteDoctorSpecialty({
            variables: {
              where: {
                specialtycode_doctorcode: {
                  specialtycode: doctorSpecialty.specialtycode,
                  doctorcode: doctorSpecialty.doctorcode,
                },
              },
            },
            refetchQueries: [
              {
                query: LIST_DOCTOR_SPECIALTIES_QUERY,
                variables: {
                  where: {
                    doctorcode: {
                      equals: parseInt(doctorSpecialty.doctorcode, 10),
                    },
                  },
                },
              },
            ],
          });
          toast.success("Doctor Specialty deleted successfully.");
        } catch (err) {
          toast.error(`Error deleting Doctor Specialty.`);
        }
      };
      setModalOpen(
        true,
        <Confirmation
          message="Are you sure you want to delete this Doctor Specialty?"
          onCancel={() => setModalOpen(false, "")}
          onConfirm={performDelete}
        />
      );
    };
    return generateColumns(handleDelete);
  }, [deleteDoctorSpecialty, setModalOpen]);

  const handleSSNChange = (e) => {
    const v = e.target.value.replace(/[^\d()\s-]/gi, "");
    const c = v.replace(/\D-/g, "");
    onChange(e.target.name, c);
  };

  return (
    <div className="doctor-general-form">
      <Column.Group>
        <Column size={6}>
          <Field kind="group">
            <Control expanded>
              <Label>SSN / Tax ID</Label>
              <Input
                autoComplete="new"
                disabled={disabled}
                maxLength={11}
                name="SSNTaxID"
                size="small"
                value={inputs.SSNTaxID}
                onChange={(e) => handleSSNChange(e)}
              />
            </Control>
            <Control expanded>
              <Label>License #</Label>
              <Input
                autoComplete="new"
                disabled={disabled}
                maxLength={50}
                name="licensenbr"
                placeholder="License #"
                size="small"
                value={inputs.licensenbr}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
            </Control>
          </Field>

          <Field kind="group">
            <Control expanded>
              <Label>UPIN</Label>
              <Input
                autoComplete="new"
                disabled={disabled}
                maxLength={50}
                name="UPIN"
                placeholder="UPIN"
                size="small"
                value={inputs.UPIN}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
            </Control>
            <Control expanded>
              <Label>NPI #</Label>
              <Input
                autoComplete="new"
                disabled={disabled}
                maxLength={20}
                name="NPINbr"
                placeholder="NPI #"
                size="small"
                value={inputs.NPINbr}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
            </Control>
          </Field>
          <Field kind="group">
            <Control expanded>
              <Label>WC / Reg #</Label>
              <Input
                autoComplete="new"
                disabled={disabled}
                maxLength={50}
                name="WCNbr"
                placeholder="WC / Reg #"
                size="small"
                value={inputs.WCNbr}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
            </Control>
            <Control expanded>
              <Label>UnReg #</Label>
              <Input
                autoComplete="new"
                disabled={disabled}
                maxLength={50}
                name="UnRegNbr"
                placeholder="UnReg #"
                size="small"
                value={inputs.UnRegNbr}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
            </Control>
          </Field>
          <Field className="grid-override" kind="group">
            <SchedulePrioritySelect
              disabled={disabled}
              label="Schedule Priority"
              name="schedulepriority"
              setValue={(x) => (x ? parseInt(x, 10) : "")}
              size="small"
              value={inputs.schedulepriority}
              onChange={onChange}
            />
            <Field className="grid-override" kind="group">
              <ProviderTypeSelect
                showBlank
                disabled={disabled}
                label="Provider Type"
                name="ProvTypeCode"
                setValue={(x) => (x ? parseInt(x, 10) : "")}
                size="small"
                value={inputs.ProvTypeCode}
                onChange={onChange}
              />
              <StatusSelect
                disabled={disabled}
                id="state"
                label="Doctor Status"
                name="status"
                size="small"
                value={inputs.status}
                onChange={onChange}
              />
            </Field>
          </Field>
          <Field>
            <BooleanInput
              label="Require Prepayment?"
              name="prepaid"
              value={inputs.prepaid}
              onChange={onChange}
            />
          </Field>
        </Column>
        <Column>
          <div className="page-head">
            <div className="page-head-start">
              <Title size={5} style={{ flex: 1, margin: 0 }}>
                Specialties
              </Title>
            </div>
            <div className="page-head-end">
              <Button
                color="success"
                disabled={disabled}
                size="small"
                style={{ marginBottom: "0.5rem" }}
                type="button"
                onClick={handleAddClick}
              >
                <Icon>
                  <FontAwesomeIcon icon="plus" />
                </Icon>
                <span>Add</span>
              </Button>
            </div>
          </div>
          <div style={{ maxHeight: 400, minHeight: 100, overflowY: "auto" }}>
            <FlatDataTable
              columns={COLUMNS}
              fetchPolicy="cache-and-network"
              name="doctorSpecialties"
              query={LIST_DOCTOR_SPECIALTIES_QUERY}
              where={{ doctorcode: { equals: parseInt(doctorcode, 10) } }}
            />
          </div>
        </Column>
      </Column.Group>
    </div>
  );
};
DoctorGeneralForm.propTypes = {
  inputs: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

DoctorGeneralForm.defaultProps = {};

export default DoctorGeneralForm;
