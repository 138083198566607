import { gql } from "@apollo/client";

// eslint-disable-next-line import/prefer-default-export
export const ALL_PRIORITIES_QUERY = gql`
  query ALL_PRIORITIES_QUERY(
    $where: PriorityWhereInput
    $orderBy: [PriorityOrderByInput!]
    $take: Int
    $skip: Int
  ) {
    priorities(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
      prioritycode
      description
    }
  }
`;
