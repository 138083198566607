/* eslint-disable import/prefer-default-export */
import { gql } from "@apollo/client";

export const CREATE_PROVIDER_TYPE_MUTATION = gql`
  mutation createProviderType($data: ProviderTypeCreateInput!) {
    createProviderType(data: $data) {
      description
      ProvTypeCode
      useridadded
      useridedited
    }
  }
`;

export const UPDATE_PROVIDER_TYPE_MUTATION = gql`
  mutation updateProviderType(
    $data: ProviderTypeUpdateInput!
    $where: ProviderTypeWhereUniqueInput!
  ) {
    updateProviderType(data: $data, where: $where) {
      description
      ProvTypeCode
      useridadded
      useridedited
    }
  }
`;

export const DELETE_PROVIDER_TYPE_MUTATION = gql`
  mutation DELETE_PROVIDER_TYPE_MUTATION($where: ProviderTypeWhereUniqueInput!) {
    deleteProviderType(where: $where) {
      ProvTypeCode
    }
  }
`;