const cases = {
  name: "cases",
  label: "Cases",
  icon: "file",
  active: (location) => {
    const [, p] = location.pathname.split("/");
    return /cases/.test(p);
  },
  to: "/cases",
};

export default cases;
