import React from "react";
import PropTypes from "prop-types";
import { Box, Field, Control, Input, Label } from "rbx";
import ReferralStyledSubtitle from "../ReferralStyledSubtitle";
import StateSelect from "../../../../components/StateSelect";

const InsuredInformation = ({ inputs, onChange }) => (
  <Box>
    <ReferralStyledSubtitle>Insured</ReferralStyledSubtitle>
    <Field kind="group">
      <Control expanded>
        <Label>Address 1</Label>
        <Input
          name="address1"
          size="small"
          value={inputs.address1}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
      <Control expanded>
        <Label>Address 2</Label>
        <Input
          name="address2"
          size="small"
          value={inputs.address2}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
    </Field>
    <Field className="grid-override is-three" kind="group">
      <Control expanded>
        <Label>City</Label>
        <Input
          name="city"
          size="small"
          value={inputs.city}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
      <StateSelect
        displayFullLabel
        expanded
        label="State"
        name="state"
        size="small"
        value={inputs.state}
        onChange={onChange}
      />
      <Control expanded>
        <Label>Zip Code</Label>
        <Input
          name="zip"
          size="small"
          value={inputs.zip}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
    </Field>
  </Box>
);

InsuredInformation.propTypes = {
  inputs: PropTypes.object,
  onChange: PropTypes.func,
};
InsuredInformation.defaultProps = {
  inputs: {},
  onChange: () => null,
};

export default InsuredInformation;
