/* eslint-disable react/prop-types */
import React from "react";

import { Generic, Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const COLUMNS = [
  {
    Header: "Note",
    id: "note",
    accessor: "note",
    Cell: ({ cell }) => <pre>{cell.value}</pre>,
  },
  {
    Header: "User",
    id: "user",
    accessor: "user",
    maxWidth: "25%",
  },
];

export const generateColumns = ({ handleDelete }) => [
  {
    Header: "",
    id: "delete",
    accessor: "id",
    maxWidth: "1%",
    Cell: ({ cell }) => (
      <Generic
        style={{
          textAlign: "center",
          height: "100%",
        }}
        type="button"
        onClick={(e) => {
          e.stopPropagation();
          handleDelete(cell.value, cell.row?.original?.fromDB);
        }}
      >
        <Icon color="danger" size="small">
          <FontAwesomeIcon icon="times" />
        </Icon>
      </Generic>
    ),
  },
  ...COLUMNS,
];

export default COLUMNS;
