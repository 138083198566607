import React from "react";
import PropTypes from "prop-types";
import { Control, Label, Select } from "rbx";
import { useQuery } from "@apollo/client";

import { ALL_QUEUES_QUERY } from "../../graphql/queue";

const QueueSelect = ({
  value,
  name,
  label,
  size,
  onChange,
  disabled,
  getValue,
  setValue,
  required,
  showLabel,
  loading,
  where,
}) => {
  const { data: queueData, loading: QueueDataLoading } = useQuery(
    ALL_QUEUES_QUERY,
    {
      variables: {
        orderBy: { statusdesc: "asc" },
        where,
      },
    }
  );

  const queues = queueData?.queues;

  const handleChange = (e) => {
    onChange(
      e.target.name,
      setValue(e.target.value ? parseInt(e.target.value, 10) : "")
    );
  };

  const isLoading = !Array.isArray(queues) || loading || QueueDataLoading;
  return (
    <Control expanded>
      {showLabel && <Label>{label}</Label>}
      <Select.Container
        fullwidth
        size={size}
        state={isLoading ? "loading" : ""}
      >
        <Select
          disabled={disabled}
          name={name}
          required={required}
          value={getValue(value)}
          onChange={handleChange}
        >
          <Select.Option value="" />
          {Array.isArray(queues) &&
            queues.map((queue) => (
              <Select.Option
                key={`queue-${queue.statuscode}`}
                value={queue.statuscode}
              >
                {queue.statusdesc}
              </Select.Option>
            ))}
        </Select>
      </Select.Container>
    </Control>
  );
};

QueueSelect.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.number,
    PropTypes.array,
  ]),
  name: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  getValue: PropTypes.func,
  setValue: PropTypes.func,
  showLabel: PropTypes.bool,
  loading: PropTypes.bool,
  where: PropTypes.object,
};

QueueSelect.defaultProps = {
  value: "",
  name: "",
  label: "Queue",
  size: "small",
  onChange: () => null,
  disabled: false,
  getValue: (x) => x,
  setValue: (x) => x,
  required: false,
  showLabel: true,
  loading: false,
  where: {},
};

export default QueueSelect;
