import React from "react";
import PropTypes from "prop-types";

import { Editor } from "@tinymce/tinymce-react";

const Template = ({ name, value, onChange }) => (
  <div className="document-template-page">
    <Editor
      apiKey="1kgbjo3jtvqp585j2a6sbitmyybdrgp2j2hm6xtzk67dqf3m"
      init={{
        deprecation_warnings: false,
        height: "70vh",
        plugins: `
        export print preview importcss autolink
        code visualblocks visualchars
        fullscreen image media table
        hr pagebreak nonbreaking advlist lists
        wordcount imagetools noneditable quickbars 
        powerpaste checklist
        `,
        toolbar: `
        undo redo 
      | bold italic underline strikethrough 
      | fontselect fontsizeselect formatselect 
      | alignleft aligncenter alignright alignjustify 
      | outdent indent 
      | numlist bullist checklist horizontalCheckboxButton
      | verticalTextButton
      | forecolor backcolor removeformat 
      | hr pagebreak 
      | preview print export
      | image fullscreen
      `,
        content_style: `body p { padding: 0; margin: 0; line-height: 1.25; } .tox-checklist {
          display: block;
        } .tox-checklist-horizontal {
          display: inline-flex;
          padding-left: 0;
        } .tox-checklist-horizontal > li:not(.tox-checklist--hidden) { margin-left: 35px; } 
        .vertical-text { writing-mode: vertical-rl; transform: scale(-1); }
        `,
        setup: (editor) => {
          editor.ui.registry.addButton("verticalTextButton", {
            text: "Vertical Text",
            onAction(e) {
              editor.insertContent(
                `<span class="vertical-text">Text Here</span>`
              );
            },
          });
          editor.ui.registry.addButton("horizontalCheckboxButton", {
            text: "Horizontal Checkboxes",
            onAction(e) {
              editor.insertContent(
                `<ul class="tox-checklist tox-checklist-horizontal"><li>Text here</li></ul>`
              );
            },
          });
        },
      }}
      value={value}
      onEditorChange={(content) => onChange(name, content)}
    />
  </div>
);

Template.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

Template.defaultProps = {
  name: "",
  value: "",
  onChange: () => null,
};

export default Template;
