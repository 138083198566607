import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Title, Button } from "rbx";
import { useMutation, useLazyQuery, useApolloClient } from "@apollo/client";
import { useModal } from "../../../../../context";
import {
  LIST_APOLLO_DOCUMENT_LOCATIONS_QUERY,
  CREATE_APOLLO_DOCUMENT_LOCATION_MUTATION,
  UPDATE_APOLLO_DOCUMENT_LOCATION_MUTATION,
  DELETE_APOLLO_DOCUMENT_LOCATION_MUTATION,
  SINGLE_APOLLO_DOCUMENT_LOCATION_QUERY,
  FIND_FIRST_APOLLO_DOCUMENT_QUERY,
} from "../../../../../graphql";
import Confirmation from "../../../../../components/Confirmation";
import { customToast as toast } from "../../../../../utils";
import DocumentLocationForm from "../DocumentLocationForm";

const INITIAL_STATE = {
  DocumentLocation: "",
};

const DocumentLocationModal = ({ onComplete, DocumentLocationID, where }) => {
  const { setModalOpen } = useModal();
  const client = useApolloClient();
  const [inputs, setInputs] = useState(INITIAL_STATE);
  const [loading, setLoading] = useState(false);
  const [updated, setUpdated] = useState([]);

  const [
    getDocumentLocation,
    { data: documentLocationData, loading: queryLoading },
  ] = useLazyQuery(SINGLE_APOLLO_DOCUMENT_LOCATION_QUERY);
  const [createDocumentLocation] = useMutation(
    CREATE_APOLLO_DOCUMENT_LOCATION_MUTATION
  );
  const [updateDocumentLocation] = useMutation(
    UPDATE_APOLLO_DOCUMENT_LOCATION_MUTATION
  );
  const [deleteDocumentLocation] = useMutation(
    DELETE_APOLLO_DOCUMENT_LOCATION_MUTATION
  );

  useEffect(() => {
    if (DocumentLocationID) {
      getDocumentLocation({
        variables: {
          where: { DocumentLocationID },
        },
      });
    }
  }, [DocumentLocationID, getDocumentLocation]);

  useEffect(() => {
    if (documentLocationData?.findUniqueDocumentLocations) {
      setInputs({
        DocumentLocation:
          documentLocationData.findUniqueDocumentLocations.DocumentLocation ||
          "",
      });
    }
  }, [documentLocationData]);

  const handleChange = (name, value) => {
    setInputs((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (DocumentLocationID) {
      setUpdated((prev) => (prev.includes(name) ? prev : [...prev, name]));
    }
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);

      if (!DocumentLocationID) {
        await createDocumentLocation({
          variables: {
            data: inputs,
          },
          refetchQueries: [
            {
              query: LIST_APOLLO_DOCUMENT_LOCATIONS_QUERY,
              variables: {
                where,
                orderBy: [{ DocumentLocation: "asc" }],
                skip: 0,
                take: 25,
              },
            },
          ],
        });
        setModalOpen(false);
        toast.success("Document Location created successfully.");
      } else {
        await updateDocumentLocation({
          variables: {
            data: {
              DocumentLocation: {
                set: inputs.DocumentLocation,
              },
            },
            where: {
              DocumentLocationID,
            },
          },
          refetchQueries: [
            {
              query: LIST_APOLLO_DOCUMENT_LOCATIONS_QUERY,
              variables: {
                where,
                orderBy: [{ DocumentLocation: "asc" }],
                skip: 0,
                take: 25,
              },
            },
            {
              query: SINGLE_APOLLO_DOCUMENT_LOCATION_QUERY,
              variables: {
                where: { DocumentLocationID },
              },
            },
          ],
        });
        setModalOpen(false);
        toast.success("Document Location updated successfully.");
      }
      onComplete();
    } catch (err) {
      toast.error("Error saving Document Location");
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    const { data: documentsData } = await client.query({
      query: FIND_FIRST_APOLLO_DOCUMENT_QUERY,
      variables: {
        where: {
          DocumentLocationID: { equals: DocumentLocationID },
        },
        take: 1,
        skip: 0,
      },
    });

    if (documentsData?.findFirstDocuments) {
      toast.error(
        "This Document Location could not be removed because there's some Cases related"
      );
    } else {
      const performDelete = async () => {
        try {
          setLoading(true);
          await deleteDocumentLocation({
            variables: {
              where: {
                DocumentLocationID,
              },
            },
            refetchQueries: [
              {
                query: LIST_APOLLO_DOCUMENT_LOCATIONS_QUERY,
                variables: {
                  where,
                  orderBy: [{ DocumentLocation: "asc" }],
                  take: 25,
                  skip: 0,
                },
              },
              {
                query: SINGLE_APOLLO_DOCUMENT_LOCATION_QUERY,
                variables: {
                  where: { DocumentLocationID },
                },
              },
            ],
          });
          toast.success("Document Location deleted successfully");
          onComplete();
        } catch (err) {
          toast.error("Error trying to delete Document Location");
        } finally {
          setLoading(false);
        }
      };
      setModalOpen(
        true,
        <Confirmation
          message="Are you sure you want to delete this Document Location?"
          onCancel={() => setModalOpen(false)}
          onConfirm={performDelete}
        />
      );
    }
  };

  const isDisabled =
    !inputs.DocumentLocation ||
    (DocumentLocationID && !updated.length) ||
    queryLoading;

  return (
    <form id="document-location-form" onSubmit={handleSubmit}>
      <header className="page-head">
        <div className="page-head-start">
          <Title size={5}>
            {`${!DocumentLocationID ? "Create" : "Edit"}`}
            &nbsp;Document Location
          </Title>
        </div>
        <div className="page-head-end">
          <Button.Group hasAddons>
            <Button
              size="small"
              type="button"
              onClick={() => onComplete(false)}
            >
              <span>Cancel</span>
            </Button>
            <Button
              color="primary"
              disabled={isDisabled}
              form="document-location-form"
              size="small"
              state={loading ? "loading" : ""}
              type="submit"
            >
              <span>Submit</span>
            </Button>
          </Button.Group>
        </div>
      </header>
      <hr />
      <DocumentLocationForm inputs={inputs} onChange={handleChange} />
      <hr />
      {DocumentLocationID && (
        <Button
          color="danger"
          disabled={loading}
          size="small"
          type="button"
          onClick={handleDelete}
        >
          <span>Delete</span>
        </Button>
      )}
    </form>
  );
};

DocumentLocationModal.propTypes = {
  onComplete: PropTypes.func,
  DocumentLocationID: PropTypes.number,
  where: PropTypes.object,
};

DocumentLocationModal.defaultProps = {
  onComplete: () => null,
  DocumentLocationID: null,
  where: {},
};

export default DocumentLocationModal;
