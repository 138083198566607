import { gql } from "@apollo/client";

export const CREATE_BUSINESS_LINE_MUTATION = gql`
  mutation CREATE_BUSINESS_LINE_MUTATION($data: BusinessLineCreateInput!) {
    createBusinessLine(data: $data) {
      businesslineid
      description
    }
  }
`;

export const UPDATE_BUSINESS_LINE_MUTATION = gql`
  mutation UPDATE_BUSINESS_LINE_MUTATION(
    $data: BusinessLineUpdateInput!
    $where: BusinessLineWhereUniqueInput!
  ) {
    updateBusinessLine(data: $data, where: $where) {
      businesslineid
      description
    }
  }
`;

export const DELETE_BUSINESS_LINE_MUTATION = gql`
  mutation DELETE_BUSINESS_LINE_MUTATION(
    $where: BusinessLineWhereUniqueInput!
  ) {
    deleteBusinessLine(where: $where) {
      businesslineid
      description
    }
  }
`;
