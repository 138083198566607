/* eslint-disable import/prefer-default-export */
import { gql } from "@apollo/client";

export const RATS_CREATE_TEMP_REQUESTOR_MUTATION = gql`
  mutation CreateTempRequestor($input: RatsTempRequestorInput!) {
    createTempRequestor(input: $input) {
      OK
      TRid
    }
  }
`;
