import React from "react";
import PropTypes from "prop-types";
import { Field, Control, Input, Label } from "rbx";
import StatusSelect from "../../../../../components/StatusSelect";

const ExceptionForm = ({ inputs, onChange }) => (
  <div>
    <Field kind="group">
      <Control expanded>
        <Label>Description</Label>
        <Input
          autoComplete="new"
          name="Description"
          size="small"
          value={inputs.Description}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
      <StatusSelect
        label="Status"
        name="Status"
        options={[
          {
            id: "Active",
            value: "Active",
            label: "Active",
          },
          {
            id: "Inactive",
            value: "Inactive",
            label: "Inactive",
          },
        ]}
        size="small"
        value={inputs.Status}
        onChange={onChange}
      />
    </Field>
  </div>
);

ExceptionForm.propTypes = {
  inputs: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ExceptionForm;
