import React from "react";
import PropTypes from "prop-types";
import { Field, Control, Input, Label, Textarea } from "rbx";
import PrefixSelect from "../../../../components/PrefixSelect";
import StateSelect from "../../../../components/StateSelect";
import PhoneNumberInput from "../../../../components/PhoneNumberInput";
import ZipInput from "../../../../components/ZipInput";
// import StatusSelect from "../../../../components/StatusSelect";

const InvestigatorLocationForm = ({ inputs, onChange }) => (
  <div className="doctor-location-form">
    <Field style={{ gridColumn: "1 / 4" }}>
      <Control>
        <Label>Location Name</Label>
        <Input
          required
          autoComplete="new"
          name="location"
          size="small"
          type="text"
          value={inputs.location}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
    </Field>
    {/* <Field>
      <Control>
        <StatusSelect
          label="Status"
          name="status"
          size="small"
          value={inputs.status}
          onChange={onChange}
        />
      </Control>
    </Field> */}

    <Field>
      <Control>
        <Label>Address 1</Label>
        <Input
          required
          autoComplete="new"
          name="addr1"
          size="small"
          type="text"
          value={inputs.addr1}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
    </Field>
    <Field>
      <Control>
        <Label>Address 2</Label>
        <Input
          autoComplete="new"
          name="addr2"
          size="small"
          type="text"
          value={inputs.addr2}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
    </Field>
    <Field>
      <Control>
        <Label>City</Label>
        <Input
          required
          autoComplete="new"
          name="city"
          size="small"
          type="text"
          value={inputs.city}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
    </Field>
    <Field>
      <Control>
        <StateSelect
          required
          name="state"
          size="small"
          value={inputs.state}
          onChange={onChange}
        />
      </Control>
    </Field>
    <Field>
      <ZipInput
        required
        name="zip"
        state={inputs.state}
        value={inputs.zip || ""}
        onChange={onChange}
      />
    </Field>
    <Field>
      <Control>
        <Label>County</Label>
        <Input
          autoComplete="new"
          name="county"
          size="small"
          type="text"
          value={inputs.county}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
    </Field>
    <Field>
      <Control>
        <PrefixSelect
          label="Contact Prefix"
          name="contactprefix"
          size="small"
          value={inputs.contactprefix}
          onChange={onChange}
        />
      </Control>
    </Field>
    <Field>
      <Control>
        <Label>First Name</Label>
        <Input
          autoComplete="new"
          name="contactfirst"
          size="small"
          type="text"
          value={inputs.contactfirst}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
    </Field>
    <Field>
      <Control>
        <Label>Last Name</Label>
        <Input
          autoComplete="new"
          name="contactlast"
          size="small"
          type="text"
          value={inputs.contactlast}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
    </Field>
    <Field>
      <Control>
        <Label>Phone</Label>
        <PhoneNumberInput
          name="Phone"
          value={inputs.Phone}
          onChange={onChange}
        />
      </Control>
    </Field>
    <Field>
      <Control>
        <Label>Fax</Label>
        <PhoneNumberInput name="fax" value={inputs.fax} onChange={onChange} />
      </Control>
    </Field>
    <Field>
      <Control>
        <Label>Email</Label>
        <Input
          autoComplete="new"
          name="email"
          size="small"
          type="text"
          value={inputs.email}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
    </Field>

    <Field style={{ gridColumn: "1 / 4" }}>
      <Control>
        <Label>Notes</Label>
        <Textarea
          name="notes"
          rows="3"
          size="small"
          type="text"
          value={inputs.notes}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
    </Field>
  </div>
);

InvestigatorLocationForm.propTypes = {
  inputs: PropTypes.object,
  onChange: PropTypes.func,
};
InvestigatorLocationForm.defaultProps = {
  inputs: {},
  onChange: () => null,
};
export default InvestigatorLocationForm;
