import React from "react";
import PropTypes from "prop-types";
import { Field, Box } from "rbx";

import ProductSelect from "../../../../components/ProductSelect";
// import NativeSelect from "../../../../components/NativeSelect";

import ReferralStyledSubtitle from "../ReferralStyledSubtitle";

const ProductSelectionForm = ({ mode, onChange, reload, inputs }) => {
  const isRecords = /^records$/gi.test(mode);
  // const isNonRecordsDisabled = /Investigations|Medical Management/gi.test(mode);


  return (
    <Box className="col-span">
      <ReferralStyledSubtitle>Product Selection</ReferralStyledSubtitle>
      <Field>
        {/* {isRecords ? (
          <NativeSelect
            disabled
            fullWidth
            label="Product"
            options={[
              {
                name: "product",
                value: "Records",
                label: "Records",
              },
              {
                name: "product",
                value: "Investigations",
                label: "Investigations",
              },
              {
                name: "product",
                value: "Medical Management",
                label: "Medical Management",
              },
            ]}
            value="Records"
          />
        ) : ( */}
        <ProductSelect
          disabled={isRecords && inputs?.product === "Records"}
          inputs={inputs}
          isRecords={isRecords}
          label="Product"
          name="product"
          required={!inputs.product}
          value={inputs.product}
          onChange={onChange}
        />
        {/* )} */}
      </Field>
    </Box>
  );
};
ProductSelectionForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  inputs: PropTypes.object.isRequired,
  reload: PropTypes.bool,
  mode: PropTypes.string,
};

ProductSelectionForm.defaultProps = {
  mode: "nonrecords",
  reload: false,
};

export default ProductSelectionForm;
