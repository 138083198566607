import React from "react";
import PropTypes from "prop-types";
import { Field, Control, Input, Label } from "rbx";
import StatusSelect from "../../../../../components/StatusSelect";
import OfficeSelect from "../../../../../components/OfficeSelect";

const CaseTypeForm = ({ inputs, onChange }) => (
  <div>
    <Field kind="group">
      <Control>
        <Label>Name</Label>
        <Input
          autoComplete="new"
          maxLength={10}
          name="ShortDesc"
          size="small"
          value={inputs.ShortDesc}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
      <Control expanded>
        <Label>Description</Label>
        <Input
          autoComplete="new"
          name="description"
          size="small"
          value={inputs.description}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
    </Field>
    <Field kind="group">
      <OfficeSelect
        label="Office"
        name="officecode"
        size="small"
        value={inputs.officecode}
        where={{ status: { equals: "Active" } }}
        onChange={onChange}
      />
      <StatusSelect
        fullWidth
        label="Status"
        name="status"
        options={[
          {
            id: "Active",
            value: "Active",
            label: "Active",
          },
          {
            id: "Inactive",
            value: "Inactive",
            label: "Inactive",
          },
        ]}
        size="small"
        value={inputs.status}
        onChange={onChange}
      />
    </Field>
  </div>
);

CaseTypeForm.propTypes = {
  inputs: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default CaseTypeForm;
