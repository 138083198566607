import React from "react";
import PropTypes from "prop-types";
import { Field, Label, Button, Control, Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import AddClientModal from "../../pages/Client/components/AddClientModal";
import { useModal } from "../../context";
import ClientSelect from "../ClientSelect";
import "./ClientSelectWithOptions.scss";

const ClientSelectWithOptions = ({
  onChange,
  disabled,
  name,
  label,
  value,
  where,
}) => {
  const { setModalOpen } = useModal();

  const handleAddClick = () => {
    setModalOpen(
      true,
      <AddClientModal
        onComplete={({ clientcode }) => {
          setModalOpen(false, "");
          if (clientcode) {
            onChange(name, clientcode);
          }
        }}
      />
    );
  };

  const handleDeleteClick = () => {
    onChange(name, "");
  };

  return (
    <Field kind="group">
      <ClientSelect
        showLabel
        disabled={disabled}
        label={label}
        name={name}
        value={value}
        where={where}
        onChange={onChange}
      />
      <Control style={{ marginLeft: "0.75rem" }}>
        <Label>&nbsp;</Label>
        <Button.Group hasAddons>
          <Button
            color="success"
            disabled={disabled}
            size="small"
            type="button"
            onClick={handleAddClick}
          >
            <Icon>
              <FontAwesomeIcon icon="plus" />
            </Icon>
          </Button>
          <Button
            color="danger"
            disabled={disabled || !value}
            size="small"
            type="button"
            onClick={handleDeleteClick}
          >
            <Icon>
              <FontAwesomeIcon icon="times" />
            </Icon>
          </Button>
        </Button.Group>
      </Control>
    </Field>
  );
};

ClientSelectWithOptions.propTypes = {
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  where: PropTypes.object,
};

ClientSelectWithOptions.defaultProps = {
  onChange: (e) => e,
  disabled: false,
  name: "",
  label: "",
  value: "",
  where: {},
};

export default ClientSelectWithOptions;
