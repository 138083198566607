import React, { useState } from "react";
import PropTypes from "prop-types";

import { useMutation, useQuery } from "@apollo/client";
import { Title, Button, Field, Control } from "rbx";
import { toast } from "react-toastify";
import {
  LIST_DOCTOR_SPECIALTIES_QUERY,
  CREATE_DOCTOR_SPECIALTY_MUTATION,
} from "../../../../graphql";

import DoctorSelect from "../../../../components/DoctorSelect";

import SpecialtySelect from "../../../../components/SpecialtySelect";

import DoctorSpecialtyModalError from "./errors";

const DoctorSpecialtyModal = ({ doctorcode, onComplete }) => {
  const [specialtyCode, setSpecialtyCode] = useState("");

  const [
    createDoctorSpecialty,
    { loading: CreateDoctorSpecialtyLoading },
  ] = useMutation(CREATE_DOCTOR_SPECIALTY_MUTATION);

  const { data: listDoctorSpecialtiesData } = useQuery(
    LIST_DOCTOR_SPECIALTIES_QUERY,
    {
      variables: {
        where: { doctorcode: { equals: parseInt(doctorcode, 10) } },
      },
    }
  );

  const handleSave = async (e) => {
    e.preventDefault();

    try {
      e.preventDefault();
      const refetchQueries = [
        {
          query: LIST_DOCTOR_SPECIALTIES_QUERY,
          variables: {
            where: { doctorcode: { equals: parseInt(doctorcode, 10) } },
          },
        },
      ];

      const currentSpecialties = listDoctorSpecialtiesData?.doctorSpecialties;

      if (
        Array.isArray(currentSpecialties) &&
        currentSpecialties.some((spec) => spec.specialtycode === specialtyCode)
      ) {
        throw new DoctorSpecialtyModalError(
          "This specialty is already associated with this doctor."
        );
      }

      await createDoctorSpecialty({
        variables: {
          data: {
            doctor: {
              connect: {
                doctorcode: parseInt(doctorcode, 10),
              },
            },
            specialty: {
              connect: {
                specialtycode: specialtyCode,
              },
            },
          },
        },
        refetchQueries,
      });

      toast.success(`Doctor Specialty created successfully.`);
      onComplete(true);
    } catch (err) {
      if (err instanceof DoctorSpecialtyModalError) {
        toast.error(err.message);
      } else {
        toast.error(`Error creating doctor specialty.`);
      }
    }
  };
  const handleChange = (name, value) => {
    setSpecialtyCode(value);
  };

  const isDisabled = !specialtyCode;
  const isLoading = CreateDoctorSpecialtyLoading;

  return (
    <form id="doctor-specialty-form" onSubmit={handleSave}>
      <header className="page-head">
        <div className="page-head-start">
          <Title size={5}>Doctor Specialty</Title>
        </div>
        <div className="page-head-end">
          <Button.Group hasAddons>
            <Button
              disabled={isLoading}
              size="small"
              type="button"
              onClick={() => onComplete(false)}
            >
              <span>Cancel</span>
            </Button>
            <Button
              color="primary"
              disabled={isDisabled || isLoading}
              form="doctor-specialty-form"
              size="small"
              state={isLoading ? "loading" : ""}
              type="submit"
            >
              <span>Submit</span>
            </Button>
          </Button.Group>
        </div>
      </header>
      <hr />
      <Field className="grid-override" kind="group">
        <Control expanded>
          <DoctorSelect disabled label="Doctor" value={doctorcode} />
        </Control>
        <SpecialtySelect
          disabled={isLoading}
          label="Specialty"
          name="specialtycode"
          value={specialtyCode}
          onChange={handleChange}
        />
      </Field>

      <hr />
    </form>
  );
};

DoctorSpecialtyModal.propTypes = {
  doctorcode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onComplete: PropTypes.func,
};

DoctorSpecialtyModal.defaultProps = {
  doctorcode: "",
  onComplete: () => null,
};
export default DoctorSpecialtyModal;
