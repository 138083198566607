/* eslint-disable import/prefer-default-export */
import { gql } from "@apollo/client";

export const CREATE_RPT_STATUS_MUTATION = gql`
  mutation createRptStatus($data: RptStatusCreateInput!) {
    createRptStatus(data: $data) {
      rptcode
      description
      dateadded
      useridadded
      dateedited
      useridedited
    }
  }
`;

export const UPDATE_RPT_STATUS_MUTATION = gql`
  mutation updateRptStatus(
    $data: RptStatusUpdateInput!
    $where: RptStatusWhereUniqueInput!
  ) {
    updateRptStatus(data: $data, where: $where) {
      rptcode
      description
      dateadded
      useridadded
      dateedited
      useridedited
    }
  }
`;

export const DELETE_RPT_STATUS_MUTATION = gql`
  mutation deleteRptStatus($where: RptStatusWhereUniqueInput!) {
    deleteRptStatus(where: $where) {
      rptcode
    }
  }
`;