import React from "react";
import PropTypes from "prop-types";
import { Field, Control, Label } from "rbx";
import UserSelect from "../../../../../components/UserSelect/UserSelect"
import StatusSelect from "../../../../../components/StatusSelect/StatusSelect";

const AddProcessorsForm = ({ inputs, onChange, where, rowClick }) => (
  <div>
    <Field kind="group">
      <Control expanded>
        <Label>Processors</Label>
        <UserSelect
          autoComplete="new"
          name="user"
          size="small"
          value={inputs.user}
          // rowClick={rowClick}
          where={rowClick ? where : undefined}
          onChange={onChange}
        />
      </Control>
      <Control>
        <StatusSelect
          label="Status"
          name="status"
          value={inputs.status}
          onChange={onChange}
        />
      </Control>
    </Field>
  </div>
);

AddProcessorsForm.propTypes = {
  inputs: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  where: PropTypes.object.isRequired,
  rowClick: PropTypes.bool.isRequired
};

export default AddProcessorsForm;
