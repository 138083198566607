import React from "react";
import styled from "styled-components";
import { Title } from "rbx";

const StyledUnauthorizedWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const StyledUnauthorizedContainer = styled.div`
  width: 95%;
  max-width: 400px;
  text-align: center;
`;

const Unauthorized = () => (
  <StyledUnauthorizedWrapper>
    <StyledUnauthorizedContainer>
      <Title>You don&apos;t have permissions to see this content</Title>
    </StyledUnauthorizedContainer>
  </StyledUnauthorizedWrapper>
);

Unauthorized.propTypes = {};

Unauthorized.defaultProps = {};

export default Unauthorized;
