import React from "react";
import BusinessCard from "../BusinessCard"

const COLUMNS = [
    {
      Header: "Info",
      id: "businessCard",
      disableSortBy: true,
      Cell: (cell) => (
        <BusinessCard
          isInTable
          data={cell.row.values}
          disabled={!cell.row.values}
          onSeeMoreClick={() => null}
        />
      )
    },
    {
      Header: "CourtID",
      id: "CourtID",
      accessor: "CourtID",
    },
    {
      Header: "County",
      id: "CourtCounty",
      accessor: "CourtCounty",
    },
    {
      Header: "Type",
      id: "CourtType",
      accessor: "CourtType",
    },
    {
      Header: "Addr 1",
      id: "CourtAddress",
      accessor: "CourtAddress",
    },
    {
      Header: "Addr 2",
      id: "CourtAddress2",
      accessor: "CourtAddress2",
    },
    {
      Header: "City",
      id: "CourtCity",
      accessor: "CourtCity",
    },
    {
      Header: "State",
      id: "CourtState",
      accessor: "CourtState",
    },
    {
      Header: "Zip",
      id: "CourtZip",
      accessor: "CourtZip",
    },
    {
      Header: "Clerk Title",
      id: "CourtclerkTitle",
      accessor: "CourtclerkTitle",
    },
    {
      Header: "Clerk First Name",
      id: "CourtclerkFname",
      accessor: "CourtclerkFname",
    },
    {
      Header: "Clerk Last Name",
      id: "CourtclerkLname",
      accessor: "CourtclerkLname",
    }
  ]

  export default COLUMNS