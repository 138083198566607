import React from "react";
import PropTypes from "prop-types";

import { Box, Field, Control, Button, Label } from "rbx";
import { endOfDay, startOfDay, isValid } from "date-fns";

import FilterInput from "../../../../components/FilterInput";

import DateInput from "../../../../components/DateInput";
import StateSelect from "../../../../components/StateSelect";

import { convertTimeZoneDataBase } from "../../../../utils";

const ExamineeListFilters = ({
  filters,
  onChange,
  onReset,
  disableStatusFilter,
  onFilter,
}) => (
  <Box>
    <div className="list-filters">
      <Field>
        <FilterInput
          getValue={(x) => (x && x.startsWith ? x.startsWith : "")}
          label="Address 1"
          name="addr1"
          placeholder="Address 1"
          setValue={(x) => (x ? { startsWith: x } : "")}
          value={filters.addr1 || ""}
          onChange={onChange}
        />
      </Field>
      <Field>
        <FilterInput
          getValue={(x) => (x && x.startsWith ? x.startsWith : "")}
          label="Address 2"
          name="addr2"
          placeholder="Address 2"
          setValue={(x) => (x ? { startsWith: x } : "")}
          value={filters.addr2 || ""}
          onChange={onChange}
        />
      </Field>
      <Field>
        <FilterInput
          getValue={(x) => (x && x.startsWith ? x.startsWith : "")}
          label="City"
          name="city"
          placeholder="City"
          setValue={(x) => (x ? { startsWith: x } : "")}
          value={filters.city || ""}
          onChange={onChange}
        />
      </Field>
      <Field>
        <StateSelect
          getValue={(x) => (x && x.equals ? x.equals : "")}
          label="State"
          name="state"
          placeholder="State"
          setValue={(x) => (x ? { equals: x } : "")}
          value={filters.state || ""}
          onChange={onChange}
        />
      </Field>
      <Field>
        <DateInput
          getValue={(x) => {
            const date = x?.gte || x?.lte;
            return date ? date.substr(0, 10) : "";
          }}
          label="DOB"
          name="DOB"
          setValue={(x) => {
            if (
              x &&
              isValid(new Date(x.split("-").map((y, i) => parseInt(y, 10))))
            ) {
              return {
                gte: convertTimeZoneDataBase(
                  startOfDay(new Date(x.split("-").map((y) => parseInt(y, 10))))
                ).toISOString(),
                lte: convertTimeZoneDataBase(
                  endOfDay(new Date(x.split("-").map((y) => parseInt(y, 10))))
                ).toISOString(),
              };
            }
            return "";
          }}
          value={filters.DOB}
          onChange={onChange}
        />
      </Field>

      <Field kind="group">
        <Control>
          <Label>&nbsp;</Label>
          <Button color="danger" size="small" onClick={onReset}>
            Reset
          </Button>
        </Control>
        <Control>
          <Label>&nbsp;</Label>
          <Button color="primary" size="small" onClick={onFilter}>
            Filter
          </Button>
        </Control>
      </Field>
    </div>
    {/* <hr />
    <pre>
      <code>{JSON.stringify(filters, null, 2)}</code>
    </pre> */}
  </Box>
);

ExamineeListFilters.propTypes = {
  filters: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  onFilter: PropTypes.func,
  disableStatusFilter: PropTypes.bool,
};

ExamineeListFilters.defaultProps = {
  disableStatusFilter: false,
  onFilter: () => {},
};

export default ExamineeListFilters;
