import React, { useCallback, useContext, useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useMutation } from "@apollo/client";
import {
  customToast as toast,
  getDateEST,
  convertTimeZoneDataBase,
  isMacintosh,
  isWindows,
  openInNewTab,
} from "../../../../utils";
import { ModalContext } from "../../../../context/ModalContext";
import { useAuth } from "../../../../context/AuthContext";
import Confirmation from "../../../../components/Confirmation";
import DataTable from "../../../../components/DataTable";

import {
  LIST_EXAMINEE_CASES_QUERY,
  DUPLICATE_CASE_ITEM_MUTATION,
  SINGLE_CASE_QUERY,
  ALL_CASE_HISTORY_QUERY,
  ADD_EXAMINEE_SUB_CASE_MUTATION,
} from "../../../../graphql";
import { generateColumns } from "./columns";

const Cases = (props) => {
  const { setModalOpen } = useContext(ModalContext);
  const { state: authState } = useAuth();
  const { chartnbr } = useParams();
  const history = useHistory();
  const [duplicateExamineeCase] = useMutation(DUPLICATE_CASE_ITEM_MUTATION);
  const [addExamineeSubCase] = useMutation(ADD_EXAMINEE_SUB_CASE_MUTATION);

  const handleRowClick = ({ casenbr }, event) => {
    const newPath = `/cases/${casenbr}/profile`;
    // simulate normal browser behavior
    if ((isMacintosh() && event?.metaKey) || (isWindows() && event?.ctrlKey)) {
      return openInNewTab(newPath);
    }
    return history.push(`/cases/${casenbr}/profile`);
  };

  const duplicateCaseItem = useCallback(
    (row) => {
      const performDuplicate = async () => {
        try {
          const date = convertTimeZoneDataBase(getDateEST());
          const {
            data: {
              duplicateCaseItem: { casenbr },
            },
          } = await duplicateExamineeCase({
            variables: {
              where: {
                casenbr: parseInt(row.casenbr, 10),
              },
              data: {
                dateadded: { set: date },
                useridadded: { set: authState?.user?.userid },
              },
              updateMasterCase: false,
            },
            refetchQueries: [
              {
                query: SINGLE_CASE_QUERY,
                variables: { where: { casenbr: parseInt(row.casenbr, 10) } },
              },
              {
                query: LIST_EXAMINEE_CASES_QUERY,
                variables: {
                  where: { chartnbr: { equals: parseInt(chartnbr, 10) } },
                },
              },
              {
                query: ALL_CASE_HISTORY_QUERY,
                variables: {
                  where: { casenbr: { equals: parseInt(row.casenbr, 10) } },
                  orderBy: [{ eventdate: "desc" }],
                },
              },
            ],
          });
          toast.success("Case duplicated successfully.");
          setModalOpen(
            true,
            <Confirmation
              message="Case duplicated successfully. Would you like to open it now?"
              onConfirm={() => {
                setModalOpen(false);
                history.push(`/cases/${casenbr}/profile`);
              }}
            />
          );
        } catch (err) {
          toast.error("Error duplicating Case.");
        }
      };

      setModalOpen(
        true,
        <Confirmation
          message="Are you sure you want to duplicate this Case?"
          onCancel={() => setModalOpen(false)}
          onConfirm={performDuplicate}
        />
      );
    },
    [
      authState?.user?.userid,
      chartnbr,
      duplicateExamineeCase,
      history,
      setModalOpen,
    ]
  );

  const addSubCaseItem = useCallback(
    (row) => {
      const performAddSubCase = async () => {
        try {
          const date = convertTimeZoneDataBase(getDateEST());
          const {
            data: {
              addExamineeSubCaseItem: { casenbr },
            },
          } = await addExamineeSubCase({
            variables: {
              where: {
                casenbr: parseInt(row.casenbr, 10),
              },
              data: {
                dateadded: { set: date },
                useridadded: { set: authState?.user?.userid },
              },
            },
            refetchQueries: [
              {
                query: SINGLE_CASE_QUERY,
                variables: { where: { casenbr: parseInt(row.casenbr, 10) } },
              },
              {
                query: LIST_EXAMINEE_CASES_QUERY,
                variables: {
                  where: { chartnbr: { equals: parseInt(chartnbr, 10) } },
                },
              },
              {
                query: ALL_CASE_HISTORY_QUERY,
                variables: {
                  where: { casenbr: { equals: parseInt(row.casenbr, 10) } },
                  orderBy: [{ eventdate: "desc" }],
                },
              },
            ],
          });
          toast.success("Sub Case added successfully.");
          setModalOpen(
            true,
            <Confirmation
              message="Sub Case added successfully. Would you like to open it now?"
              onConfirm={() => {
                setModalOpen(false);
                history.push(`/cases/${casenbr}/profile`);
              }}
            />
          );
        } catch (err) {
          toast.error("Error adding Sub Case.");
        }
      };

      setModalOpen(
        true,
        <Confirmation
          message="Are you sure you want to add a subcase?"
          onCancel={() => setModalOpen(false)}
          onConfirm={performAddSubCase}
        />
      );
    },
    [
      authState?.user?.userid,
      chartnbr,
      addExamineeSubCase,
      history,
      setModalOpen,
    ]
  );

  const columns = useMemo(
    () => generateColumns({ duplicateCaseItem, addSubCaseItem }),
    [duplicateCaseItem, addSubCaseItem]
  );

  return (
    <div className="examinee-cases-page">
      <DataTable
        aggregateName="aggregateExamineeCase"
        columns={columns}
        fetchPolicy="cache-and-network"
        name="examineeCases"
        orderBy={[{ id: "Appttime", desc: true }]}
        query={LIST_EXAMINEE_CASES_QUERY}
        where={{ chartnbr: { equals: parseInt(chartnbr, 10) } }}
        onRowClick={handleRowClick}
      />
    </div>
  );
};

Cases.propTypes = {};

export default Cases;
