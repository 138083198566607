import React from "react";
import PropTypes from "prop-types";
import { Control, Field, Input, Box, Label } from "rbx";

import StateSelect from "../../../../components/StateSelect";
import PhoneNumberInput from "../../../../components/PhoneNumberInput";
import ZipInput from "../../../../components/ZipInput";
import ReferralStyledSubtitle from "../ReferralStyledSubtitle";

const Employer = ({ onChange, inputs }) => (
  <Box>
    <ReferralStyledSubtitle>Employer</ReferralStyledSubtitle>
    <Field kind="group">
      <Control expanded>
        <Label>Employer</Label>
        <Input
          name="Employer"
          size="small"
          value={inputs.Employer}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
    </Field>
    <Field kind="group">
      <Control expanded>
        <Label>Contact First Name</Label>
        <Input
          name="ContactFirstName"
          size="small"
          value={inputs.ContactFirstName}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
      <Control expanded>
        <Label>Contact Last Name</Label>
        <Input
          name="ContactLastName"
          size="small"
          value={inputs.ContactLastName}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
    </Field>
    <Field kind="group">
      <Control expanded>
        <Label>Address 1</Label>
        <Input
          name="Address1"
          size="small"
          value={inputs.Address1}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
      <Control expanded>
        <Label>Address 2</Label>
        <Input
          name="Address2"
          size="small"
          value={inputs.Address2}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
    </Field>
    <Field className="grid-override is-three" kind="group">
      <Control expanded>
        <Label>City</Label>
        <Input
          name="City"
          size="small"
          value={inputs.City}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
      <StateSelect
        displayFullLabel
        expanded
        label="State"
        name="State"
        size="small"
        value={inputs.State}
        onChange={onChange}
      />
      <ZipInput
        label="Zip Code"
        name="Zip"
        state={inputs.State}
        value={inputs.Zip}
        onChange={onChange}
      />
    </Field>
    <Field kind="group">
      <Control expanded>
        <Label>Phone</Label>
        <PhoneNumberInput
          name="Phone"
          value={inputs.Phone}
          onChange={onChange}
        />
      </Control>
      <Control expanded>
        <Label>Extension</Label>
        <Input
          maxLength={14}
          name="PhoneExt"
          size="small"
          value={inputs.PhoneExt}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
    </Field>
    <Field kind="group">
      <Control expanded>
        <Label>Fax</Label>
        <PhoneNumberInput name="Fax" value={inputs.Fax} onChange={onChange} />
      </Control>
      <Control expanded>
        <Label>Email</Label>
        <Input
          maxLength={70}
          name="Email"
          size="small"
          type="email"
          value={inputs.Email}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
    </Field>
  </Box>
);

Employer.propTypes = {
  onChange: PropTypes.func.isRequired,
  inputs: PropTypes.object.isRequired,
};

export default Employer;
