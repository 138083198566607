import { gql } from "@apollo/client";

export const UPDATE_DOCUMENT_MUTATION = gql`
  mutation updateDocument(
    $data: DocumentUpdateInput!
    $where: DocumentWhereUniqueInput!
  ) {
    updateDocument(where: $where, data: $data) {
      SeqNo
    }
  }
`;

export const CREATE_DOCUMENT_MUTATION = gql`
  mutation createDocument($data: DocumentCreateInput!) {
    createDocument(data: $data) {
      SeqNo
    }
  }
`;

export const CREATE_COMPANY_DEF_DOCUMENT_MUTATION = gql`
  mutation createCompanyDefDocument($data: CompanyDefDocumentCreateInput!) {
    createCompanyDefDocument(data: $data) {
      companycode
      documentcode
      documentqueue
    }
  }
`;

export const UPDATE_COMPANY_DEF_DOCUMENT_MUTATION = gql`
  mutation updateCompanyDefDocument(
    $data: CompanyDefDocumentUpdateInput!
    $where: CompanyDefDocumentWhereUniqueInput!
  ) {
    updateCompanyDefDocument(data: $data, where: $where) {
      companycode
      documentcode
      documentqueue
    }
  }
`;

export const DELETE_COMPANY_DEF_DOCUMENT_MUTATION = gql`
  mutation deleteCompanyDefDocument(
    $where: CompanyDefDocumentWhereUniqueInput!
  ) {
    deleteCompanyDefDocument(where: $where) {
      companycode
      documentcode
      documentqueue
    }
  }
`;

export const DELETE_CASE_DEF_DOCUMENT_MUTATION = gql`
  mutation deleteCaseDefDocument($where: CaseDefDocumentWhereUniqueInput!) {
    deleteCaseDefDocument(where: $where) {
      casenbr
      documentcode
      documentqueue
    }
  }
`;

export const CREATE_CASE_DOCUMENT_MUTATION = gql`
  mutation createCaseDocument($data: CaseDocumentCreateInput!) {
    createCaseDocument(data: $data) {
      casenbr
      document
      dateadded
      seqno
    }
  }
`;

export const UPDATE_CASE_DOCUMENT_MUTATION = gql`
  mutation updateCaseDocument(
    $data: CaseDocumentUpdateInput!
    $where: CaseDocumentWhereUniqueInput!
  ) {
    updateCaseDocuments(data: $data, where: $where) {
      casenbr
      document
      dateadded
      seqno
    }
  }
`;

export const DELETE_CASE_DOCUMENT_MUTATION = gql`
  mutation deleteCaseDocument($where: CaseDocumentWhereUniqueInput!) {
    deleteCaseDocuments(where: $where) {
      casenbr
      document
      dateadded
      seqno
    }
  }
`;

export const GENERATE_CASE_DOCUMENT_MUTATION = gql`
  mutation generateCaseDocument($data: GenerateCaseDocumentInput!) {
    generateCaseDocument(data: $data) {
      casenbr
      document
      dateadded
      seqno
    }
  }
`;

export const ATTACH_CASE_DOCUMENT_MUTATION = gql`
  mutation attachCaseDocument($data: AttachCaseDocumentInput!) {
    attachCaseDocument(data: $data) {
      casenbr
      document
      dateadded
      seqno
    }
  }
`;
