import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Control, Field, Input, Box, Label, Help, Dropdown } from "rbx";

import styled from "styled-components";
import { useLazyQuery } from "@apollo/client";
import { format } from "date-fns";
import { useReferrer } from "../../../../hooks";
import PhoneNumberInput from "../../../../components/PhoneNumberInput";
import ZipInput from "../../../../components/ZipInput";
import StateSelect from "../../../../components/StateSelect";
import GenderSelect from "../../../../components/GenderSelect";
import MaritalStatusSelect from "../../../../components/MaritalStatusSelect";
// import ClaimLastnameReferralInput from "../../../../components/ClaimLastnameReferralInput";

import SSNumberInput from "../SSNumberInput";
import ReferralStyledSubtitle from "../ReferralStyledSubtitle";
import ClickCapture from "../../../../components/ClickCapture";
import { LIST_CE_CLAIMS_QUERY } from "../../../../graphql";
import { convertTimeZone, debounce } from "../../../../utils";
import { KEY_CODES } from "../../../../constants";

const StyledClaimantInformationContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1rem;
`;

const ClaimantInformationForm = ({
  onChange,
  inputs,
  setInputs,
  mode,
  disabled,
  isGeicoInvestigationsRequirement,
  isGeicoMarltonOffice,
}) => {
  const isRecordsMode = /^records$/gi.test(mode);
  const claimNumber = inputs?.ClaimNumber;
  const { UP, DOWN, ENTER } = KEY_CODES;

  const referrer = useReferrer();
  const inputRef = useRef(null);
  const [cursor, setCursor] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false);

  const [getClaims, { data: ClaimsData }] = useLazyQuery(LIST_CE_CLAIMS_QUERY);

  const getClaimantVariables = useCallback(
    (search) => {
      const where = {
        ClaimantLastName: { startsWith: search },
      };
      if (referrer?.CompanyName) {
        where.CompanyName = { equals: referrer.CompanyName };
      }
      if (claimNumber) {
        where.ClaimNumber = { startsWith: claimNumber };
      }
      return {
        where,
        take: 12,
        orderBy: [
          { ClaimantLastName: "asc" },
          { ClaimantFirstName: "asc" },
          { ClaimNumber: "asc" },
        ],
      };
    },
    [referrer, claimNumber]
  );

  useEffect(() => {
    const el = inputRef.current;

    const handleFocus = (e) => {
      const {
        target: { value: newValue },
      } = e;
      if (referrer && (claimNumber || newValue.length >= 1)) {
        setShowDropdown(true);
        getClaims({
          variables: getClaimantVariables(newValue),
        });
      }
    };

    el?.addEventListener("focus", handleFocus);
    el?.addEventListener("click", handleFocus);

    return () => {
      el?.removeEventListener("focus", handleFocus);
      el?.removeEventListener("click", handleFocus);
    };
  }, [inputRef, referrer, claimNumber, getClaimantVariables, getClaims]);

  const handleSearchChange = debounce((e) => {
    e.preventDefault();
    e.stopPropagation();
    const {
      target: { value: newValue },
    } = e;
    if (referrer && (claimNumber || newValue.length >= 1)) {
      setShowDropdown(true);
      getClaims({
        variables: getClaimantVariables(newValue),
      });
    } else {
      setShowDropdown(false);
    }
  }, 500);

  const selectClaim = (claim) => {
    if (!claim) return;
    setInputs((prev) => ({
      ...prev,
      ClaimNumber: claim.ClaimNumber || "",
      CentralEligibilityID: claim.CentralEligibilityID || "",
      ClaimantFirstName: claim.ClaimantFirstName || "",
      ClaimantLastName: claim.ClaimantLastName || "",
      ClaimantAddress1: claim.Address1 || "",
      ClaimantAddress2: claim.Address2 || "",
      ClaimantPhone: claim.Phone1 || "",
      ClaimantCellPhone: claim.Phone2 || "",
      ClaimantCity: claim.City || "",
      ClaimantState: claim.State || "",
      ClaimantZip: claim.Zip5 || "",
      ClaimantGender: claim.Sex || "",
      ClaimantDateOfBirth: format(
        convertTimeZone(claim.DateOfBirth),
        "yyyy-MM-dd"
      ),
      DateOfLoss: format(convertTimeZone(claim.DateOfLoss), "yyyy-MM-dd"),
    }));
    setShowDropdown(false);
    setCursor(null);
  };

  const handleKeyDown = (e) => {
    const { keyCode } = e;
    if ([UP, DOWN, ENTER]?.includes(e.keyCode)) {
      e.preventDefault();
      e.stopPropagation();
    }
    if (keyCode === UP) {
      if (typeof cursor === "number" && cursor !== 0) {
        setCursor((prev) => prev - 1);
      }
    } else if (keyCode === DOWN) {
      if (typeof cursor !== "number") {
        setCursor(0);
      } else if (cursor < ClaimsData?.findManyClaims?.length - 1) {
        setCursor((prev) => prev + 1);
      }
    } else if (keyCode === ENTER) {
      const claim = ClaimsData?.findManyClaims?.[cursor];
      if (claim) {
        selectClaim(claim);
        setCursor(null);
      }
    }
  };

  const handleBlur = (e) => {
    onChange(e.target.name, e.target.value);
  };

  const isActive =
    ClaimsData &&
    Array.isArray(ClaimsData.findManyClaims) &&
    !!ClaimsData.findManyClaims.length;

  return (
    <Box className="col-span">
      <ReferralStyledSubtitle>Claimant Information</ReferralStyledSubtitle>
      <StyledClaimantInformationContainer id="claimant-information-form">
        <Field kind="group">
          <Control expanded>
            <Label>First Name</Label>
            <Input
              required
              disabled={disabled}
              name="ClaimantFirstName"
              size="small"
              value={inputs.ClaimantFirstName || ""}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </Control>

          {/* ClaimantLastName */}
          <Control expanded>
            <Label>Last Name</Label>
            <ClickCapture onOutsideClick={() => setShowDropdown(false)}>
              <Dropdown
                managed
                active={referrer && isActive}
                color="primary"
                style={{ width: "100%" }}
              >
                <Dropdown.Trigger style={{ flex: 1 }}>
                  <Input
                    required
                    autoComplete="new"
                    disabled={disabled}
                    name="ClaimantLastName"
                    ref={inputRef}
                    size="small"
                    tabIndex="0"
                    value={inputs?.ClaimantLastName}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      handleSearchChange(e);
                      onChange(e.target.name, e.target.value);
                    }}
                    onKeyDown={handleKeyDown}
                  />
                </Dropdown.Trigger>
                {showDropdown &&
                  ClaimsData?.findManyClaims?.length > 0 &&
                  (ClaimsData.findManyClaims[0]?.ClaimantLastName !==
                    inputs?.ClaimantLastName ||
                    !inputs?.ClaimantLastName) && (
                    <Dropdown.Menu style={{ flex: 1 }}>
                      {isActive && (
                        <Dropdown.Content>
                          {ClaimsData.findManyClaims.map((claim, i) => (
                            <Dropdown.Item
                              active={cursor === i}
                              as="a"
                              key={claim.CentralEligibilityID}
                              onClick={() => selectClaim(claim)}
                            >
                              {[
                                [
                                  claim.ClaimantLastName,
                                  claim.ClaimantFirstName,
                                ]
                                  .filter(Boolean)
                                  .join(", "),
                                claim.ClaimNumber,
                              ]
                                .filter(Boolean)
                                .join(" - ")}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Content>
                      )}
                    </Dropdown.Menu>
                  )}
              </Dropdown>
            </ClickCapture>
          </Control>
          {/* ClaimantLastName */}

          {/* <ClaimLastnameReferralInput
            required
            claimNumber={inputs.ClaimNumber}
            disabled={disabled}
            inputs={inputs}
            label="Last Name"
            name="ClaimantLastName"
            setInputs={setInputs}
            value={inputs.ClaimantLastName || ""}
            onChange={onChange}
          /> */}
        </Field>
        <Field kind="group">
          <Control expanded>
            <Label>Email</Label>
            <Input
              disabled={
                (disabled || isRecordsMode) &&
                !/^medical management|investigations$/gi.test(inputs?.product)
              }
              name="ClaimantEmail"
              size="small"
              value={inputs.ClaimantEmail || ""}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </Control>
        </Field>
        <Field kind="group">
          <Control expanded>
            <Label>Address 1</Label>
            <Input
              disabled={disabled}
              name="ClaimantAddress1"
              required={isGeicoInvestigationsRequirement}
              size="small"
              value={inputs.ClaimantAddress1 || ""}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </Control>
          <Control expanded>
            <Label>Address 2</Label>
            <Input
              disabled={disabled}
              name="ClaimantAddress2"
              size="small"
              value={inputs.ClaimantAddress2 || ""}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </Control>
        </Field>

        <Field kind="group">
          <Control expanded>
            <Label>Phone</Label>
            <PhoneNumberInput
              disabled={
                (disabled || isRecordsMode) &&
                !/^medical management|investigations$/gi.test(inputs?.product)
              }
              name="ClaimantPhone"
              value={inputs.ClaimantPhone || ""}
              onChange={onChange}
            />
          </Control>
          <Control expanded>
            <Label>Cell Phone</Label>
            <PhoneNumberInput
              disabled={
                (disabled || isRecordsMode) &&
                !/^medical management|investigations$/gi.test(inputs?.product)
              }
              name="ClaimantCellPhone"
              value={inputs.ClaimantCellPhone || ""}
              onChange={onChange}
            />
          </Control>
        </Field>
        <Field className="grid-override is-three" kind="group">
          <Control expanded>
            <Label>City</Label>
            <Input
              disabled={disabled}
              name="ClaimantCity"
              required={isGeicoInvestigationsRequirement}
              size="small"
              value={inputs.ClaimantCity || ""}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </Control>
          <StateSelect
            displayFullLabel
            expanded
            disabled={disabled}
            label="State"
            name="ClaimantState"
            required={isGeicoInvestigationsRequirement}
            size="small"
            value={inputs.ClaimantState || ""}
            onChange={onChange}
          />
          <Control expanded>
            <ZipInput
              disabled={disabled}
              label="Zip Code"
              name="ClaimantZip"
              required={isGeicoInvestigationsRequirement}
              size="small"
              value={inputs?.ClaimantZip?.trim() || ""}
              onChange={onChange}
            />
          </Control>
        </Field>
        <Field className="grid-override" kind="group">
          <Control expanded>
            <Label>Date Of Birth</Label>
            <Input
              disabled={disabled}
              name="ClaimantDateOfBirth"
              required={
                (/^medical management$/gi.test(inputs?.product) &&
                  isGeicoMarltonOffice) ||
                isGeicoInvestigationsRequirement
              }
              size="small"
              type="date"
              value={inputs.ClaimantDateOfBirth || ""}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </Control>
          <GenderSelect
            fullWidth
            disabled={
              (disabled || isRecordsMode) &&
              !/^medical management|investigations$/gi.test(inputs?.product)
            }
            label="Gender"
            name="ClaimantGender"
            value={inputs.ClaimantGender || ""}
            onChange={onChange}
          />
        </Field>
        <Field className="grid-override" kind="group">
          <MaritalStatusSelect
            disabled={
              (disabled || isRecordsMode) &&
              !/^medical management|investigations$/gi.test(inputs?.product)
            }
            label="Marital Status"
            name="ClaimantMaritalStatus"
            value={inputs.ClaimantMaritalStatus || ""}
            onChange={onChange}
          />

          <Control expanded>
            <Label>Social Security Number</Label>
            <SSNumberInput
              disabled={disabled}
              name="ClaimantSSN"
              required={
                isRecordsMode ||
                !/^medical management|investigations$/gi.test(inputs?.product)
              }
              value={inputs.ClaimantSSN || ""}
              onChange={onChange}
            />
          </Control>
        </Field>
        <Field>
          <Control>
            <Label>Special Physical Characteristics</Label>
            <Input
              disabled={
                (disabled || isRecordsMode) &&
                !/^medical management|investigations$/gi.test(inputs?.product)
              }
              name="ClaimantSpecialPhysical"
              size="small"
              value={inputs.ClaimantSpecialPhysical || ""}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
            <Help>
              For example: glasses, tattoos, facial hair, hand dominance
            </Help>
          </Control>
        </Field>
      </StyledClaimantInformationContainer>
    </Box>
  );
};

ClaimantInformationForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  inputs: PropTypes.object.isRequired,
  setInputs: PropTypes.func.isRequired,
  mode: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  isGeicoInvestigationsRequirement: PropTypes.bool.isRequired,
  isGeicoMarltonOffice: PropTypes.bool.isRequired,
};

export default ClaimantInformationForm;
