import { Control } from "rbx";
import PropTypes from "prop-types";

import React from "react";
import { useQuery } from "@apollo/client";
import { useConditionalRecordTypes } from "../../../../hooks";
import RetryFetch from "./RetryFetch";
import RECORD_PROVIDER_RECORD_TYPES_QUERY from "../../../../graphql/recordProviderRecordType/recordProviderRecordType.queries";
import BooleanInput from "../../../../components/BooleanInput";

const RecordProviderRecordTypes = ({
  disabled,
  provider,
  index,
  handleArrChange,
}) => {
  const { shouldDisable, handleChange, getIsSelected, getLabel } =
    useConditionalRecordTypes(disabled, provider, handleArrChange, index);

  const { data, refetch, error } = useQuery(
    RECORD_PROVIDER_RECORD_TYPES_QUERY,
    {
      variables: {
        where: {
          AND: [
            {
              RecordTypeID: {
                not: {
                  equals: 0,
                },
              },
              IsActive: {
                equals: true,
              },
              COID: {
                equals: null,
              },
            },
          ],
        },
        orderBy: [
          {
            RecordTypeName: "asc",
          },
        ],
      },
    }
  );

  if (!data?.dropdown_RecordTypes) return <div>Loading...</div>;

  if (error) return <RetryFetch handleRetry={refetch} />;

  const thirdLength = Math.ceil(data?.dropdown_RecordTypes.length / 3);

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(3, 1fr)",
        gridTemplateRows: `repeat(${thirdLength}, auto)`,
        gridAutoFlow: "column",
      }}
    >
      {data?.dropdown_RecordTypes.map((recordType) => (
        <Control>
          <BooleanInput
            disabled={shouldDisable(recordType?.RecordTypeCode)}
            key={recordType?.RecordTypeID}
            label={getLabel(
              recordType?.RecordTypeName,
              getIsSelected(recordType?.RecordTypeID)
            )}
            name={recordType?.RecordTypeID}
            value={getIsSelected(recordType?.RecordTypeID)}
            onChange={() =>
              handleChange(recordType?.RecordTypeID, recordType?.RecordTypeCode)
            }
          />
        </Control>
      ))}
    </div>
  );
};

RecordProviderRecordTypes.propTypes = {
  disabled: PropTypes.bool.isRequired,
  provider: PropTypes.object.isRequired,
  handleArrChange: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
};

export default RecordProviderRecordTypes;
