/* eslint-disable import/prefer-default-export */
import { gql } from "@apollo/client";

export const ALL_APOLLO_NOTE_TYPES_QUERY = gql`
  query ALL_APOLLO_NOTE_TYPES_QUERY(
    $take: Int = 100
    $orderBy: [NoteTypesOrderByInput!]
    $skip: Int
    $where: NoteTypesWhereInput
  ) {
    findManyNoteTypes(
      take: $take
      orderBy: $orderBy
      where: $where
      skip: $skip
    ) {
      NoteTypeID
      NoteType
    }
  }
`;

export const LIST_APOLLO_NOTE_TYPES_QUERY = gql`
  query findManyNoteTypes(
    $take: Int = 100
    $orderBy: [NoteTypesOrderByInput!]
    $skip: Int
    $where: NoteTypesWhereInput
  ) {
    findManyNoteTypes(take: $take, orderBy: $orderBy, skip: $skip, where: $where) {
      NoteTypeID
      NoteType
    }
    aggregateNoteTypes(where: $where) {
      count {
        NoteTypeID
      }
    }
  }
`;

export const SINGLE_APOLLO_NOTE_TYPE_QUERY = gql`
  query findUniqueNoteTypes($where: NoteTypesWhereUniqueInput!) {
    findUniqueNoteTypes(where: $where) {
      NoteTypeID
      NoteType
    }
  }
`;

export const FIND_FIRST_APOLLO_NOTE_TYPE_QUERY = gql`
  query FIND_FIRST_APOLLO_NOTE_TYPE_QUERY(
    $where: NoteTypesWhereInput
    $orderBy: [NoteTypesOrderByInput!]
  ) {
    findFirstNoteTypes(where: $where, orderBy: $orderBy) {
      NoteTypeID
      NoteType
    }
  }
`;

