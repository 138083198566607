/* eslint-disable import/prefer-default-export */
import { gql } from "@apollo/client";

export const RATS_CREATE_EMPLOYER_MUTATION = gql`
  mutation CreateEmployer($data: EmployerCreateInput!) {
    createEmployer(data: $data) {
      PK_EMid
    }
  }
`;
