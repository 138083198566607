import { gql } from "@apollo/client";

export const FIND_FIRST_LSI_CLIENT_QUERY = gql`
  query FIND_FIRST_LSI_CLIENT_QUERY(
    $where: LsiClientWhereInput!
    $orderBy: [LsiClientOrderByInput!]
  ) {
    findFirstLsiClient(where: $where, orderBy: $orderBy) {
      CLid
      CLfname
      CLlname
      CLcompany
      CLaddress1
      CLaddress2
      CLcity
      CLstate
      CLzip
      CLphone
      CLfax
      CLemail
      CLnwOption
      CLbsOption
      CLlogin
      CLUSAARequired
      CLUSAALegalRequired
      CLSentryOption
    }
  }
`;

export const FIND_MANY_LSI_CLIENTS_QUERY = gql`
  query FIND_MANY_LSI_CLIENTS_QUERY(
    $where: LsiClientWhereInput!
    $take: Int
    $limit: Int
    $orderBy: LsiClientOrderByInput
  ) {
    findManyLsiClients(
      where: $where
      take: $take
      limit: $limit
      orderBy: $orderBy
    ) {
      WebUserID
      UserID
      IMECentricCode
      UserType
    }
  }
`;
