import React, { useState } from "react";
import { Title, Field, Control, Button, Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useModal } from "../../../../context";
import DataTable from "../../../../components/DataTable";
import { LIST_CASE_TYPE_SERVICE_VIEW_QUERY } from "../../../../graphql";
import { getFilterState, saveFilterState } from "../../../../utils";

import {
  CaseTypeDocumentsModal,
  CaseTypeDocumentFilters,
} from "../../components";
import COLUMNS from "./columns";

const INITIAL_FILTER_STATE = {};

const CaseTypeDocuments = () => {
  const [where, setWhere] = useState(
    getFilterState("CASE_TYPE_DOCUMENTS_LIST_FILTERS", {
      ...INITIAL_FILTER_STATE,
    })
  );

  const [showFilters, setShowFilters] = useState(false);

  const { setModalOpen } = useModal();

  const handleRowClick = (row) => {
    const { casetypecode, officecode, servicecode } = row;
    const code = {
      casetype: casetypecode,
      servicecode,
      officecode,
    };
    setModalOpen(
      true,
      <CaseTypeDocumentsModal
        code={code}
        where={where}
        onComplete={() => setModalOpen(false)}
      />
    );
  };

  const handleFiltersChange = (name, value) => {
    if (value) {
      saveFilterState("CASE_TYPE_DOCUMENTS_LIST_FILTERS", {
        ...where,
        [name]: { equals: parseInt(value, 10) },
      });
      setWhere((prev) => ({
        ...prev,
        [name]: { equals: parseInt(value, 10) },
      }));
    } else {
      const current = getFilterState("CASE_TYPE_DOCUMENTS_LIST_FILTERS", {
        ...INITIAL_FILTER_STATE,
      });
      delete current[name];
      saveFilterState("CASE_TYPE_DOCUMENTS_LIST_FILTERS", current);
      setWhere(current);
    }
  };

  const handleReset = () => {
    saveFilterState("CASE_TYPE_DOCUMENTS_LIST_FILTERS", {
      ...INITIAL_FILTER_STATE,
    });
    setWhere({ ...INITIAL_FILTER_STATE });
  };

  return (
    <div>
      <div className="section-header">
        <Title style={{ marginRight: 15 }}>Case Type Documents</Title>
        <Field kind="group">
          <Control>
            <Button
              color="warning"
              size="small"
              onClick={() => setShowFilters((prev) => !prev)}
            >
              <Icon>
                <FontAwesomeIcon icon="filter" />
              </Icon>
              <span>Filters</span>
            </Button>
          </Control>
          <Control>
            <Button
              color="primary"
              size="small"
              onClick={() => {
                setModalOpen(
                  true,
                  <CaseTypeDocumentsModal
                    where={where}
                    onComplete={() => setModalOpen(false)}
                  />
                );
              }}
            >
              <Icon>
                <FontAwesomeIcon icon="plus" />
              </Icon>

              <span>Add</span>
            </Button>
          </Control>
        </Field>
      </div>

      {showFilters && (
        <CaseTypeDocumentFilters
          filters={where}
          show={showFilters}
          onChange={handleFiltersChange}
          onReset={handleReset}
        />
      )}

      <DataTable
        aggregateKey="_all"
        aggregateName="aggregateCaseTypeServiceView"
        columns={COLUMNS}
        name="caseTypeServiceViews"
        orderBy={[
          {
            id: "office",
            desc: false,
          },
          {
            id: "casetype",
            desc: false,
          },
          {
            id: "service",
            desc: false,
          },
        ]}
        query={LIST_CASE_TYPE_SERVICE_VIEW_QUERY}
        where={where}
        onRowClick={handleRowClick}
      />
    </div>
  );
};

CaseTypeDocuments.propTypes = {};

export default CaseTypeDocuments;
