import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";

import { useApolloClient, useLazyQuery, useQuery } from "@apollo/client";

import { useParams } from "react-router-dom";

import { Box, Field, Control, Input, Label, Button, Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import { useModal } from "../../../../context";
import ScheduleTable from "./ScheduleTable";

import {
  ALL_DOCTOR_LOCATIONS_QUERY,
  SINGLE_CASE_QUERY,
  ALL_ZIPS_QUERY,
  ALL_ZIP_CODES_BY_PROXIMITY_QUERY,
  FIND_FIRST_ZIP_QUERY,
} from "../../../../graphql";

import { clampNumber } from "../../../../utils";

import DoctorSelect from "../../../../components/DoctorSelect";

import DoctorLocationSelect from "../../../../components/DoctorLocationSelect";
import StateSelect from "../../../../components/StateSelect";
import DegreeSelect from "../../../../components/DegreeSelect";
import SpecialtySelect from "../../../../components/SpecialtySelect";
import BooleanInput from "../../../../components/BooleanInput";

import ScheduleCaseModal from "../../components/ScheduleCaseModal";
import { DoctorBlockTimeScheduleModal } from "../../../Doctor/components";

import ZipInput from "../../../../components/ZipInput";

import CustomToastWithLink from "../../../../components/CustomToastWithLink";
import {
  useCaseDoctorLabel,
  useDoctorsNotToUse,
  useThrottle,
} from "../../../../hooks";

const [MIN_PROXIMITY, MAX_PROXIMITY] = [1, 75];

const EXAMINEE_FIELDS = [
  {
    key: "city",
    fn: (x) => x,
  },
  {
    key: "state",
    fn: (x) => x?.equals,
  },
  {
    key: "zip",
    fn: (x) => x,
  },
  {
    key: "county",
    fn: (x) => x,
  },
];

const Schedule = ({ onChangeCase, inputsCase }) => {
  const { casenbr: pcasenbr, workid } = useParams();
  const casenbr = pcasenbr || workid;
  const client = useApolloClient();
  const { setModalOpen } = useModal();
  const [proximity, setProximity] = useState("");

  const [inputs, setInputs] = useState({});
  const [useProximity, setUseProximity] = useState(false);
  const [zips, setZips] = useState(new Map());

  const [doctorLocations, setDoctorLocations] = useState([]);

  const doctorsNotToUse = useDoctorsNotToUse(casenbr);

  const { data: CaseItemData } = useQuery(SINGLE_CASE_QUERY, {
    variables: { where: { casenbr: parseInt(casenbr, 10) } },
  });

  const [
    getDoctorLocations,
    { data: DoctorLocationsData, loading },
  ] = useLazyQuery(ALL_DOCTOR_LOCATIONS_QUERY);

  const [
    getZipCodes,
    { data: ZipCodesData, loading: zipsLoading },
  ] = useLazyQuery(ALL_ZIPS_QUERY);

  const [
    getZipCodesByProximity,
    { data: ZipCodesByProximityData },
  ] = useLazyQuery(ALL_ZIP_CODES_BY_PROXIMITY_QUERY);

  const handleChange = (name, value) => {
    if (!value) {
      setInputs((prev) =>
        Object.keys(prev).reduce((acc, curr) => {
          if (curr !== name) acc[curr] = prev[curr];
          return acc;
        }, {})
      );
    } else {
      setInputs((prev) => ({ ...prev, [name]: value }));
    }
  };

  useEffect(() => {
    const zipCode = inputs.zip;
    if (zipCode && zipCode.length === 5) {
      getZipCodes({
        variables: { where: { sZip: { equals: zipCode } } },
      });
    }
  }, [inputs.zip, getZipCodes]);

  useEffect(() => {
    const examinee = CaseItemData?.caseItem?.examinee;
    if (examinee) {
      setInputs((prev) => ({
        ...prev,
        city: examinee.city || "",
        state: examinee.state ? { equals: examinee.state } : "",
        zip: examinee.zip || "",
        county: examinee.county || "",
      }));
    }
  }, [CaseItemData]);

  useEffect(() => {
    const zipCodes = ZipCodesData?.zipCodes;
    if (Array.isArray(zipCodes) && zipCodes[0] && proximity) {
      const { fLatitude, fLongitude } = zipCodes[0];
      getZipCodesByProximity({
        variables: {
          input: {
            latitude: fLatitude,
            longitude: fLongitude,
            proximity,
          },
        },
      });
    }
  }, [ZipCodesData, getZipCodesByProximity, proximity]);

  useEffect(() => {
    const zipCodesByProximity = ZipCodesByProximityData?.zipCodesByProximity;
    if (Array.isArray(zipCodesByProximity)) {
      setZips(new Map(zipCodesByProximity.map((x) => [x.sZip, x.proximity])));
    }
  }, [ZipCodesByProximityData]);

  useEffect(() => {
    const docLocations = DoctorLocationsData?.doctorLocations;
    if (Array.isArray(docLocations)) {
      setDoctorLocations(
        docLocations
          .map((docLocation) => ({
            ...docLocation,
            proximity: zips.get(docLocation.location.zip),
          }))
          .sort((a, b) => {
            if (a.proximity > b.proximity) return 1;
            if (b.proximity > a.proximity) return -1;
            return 0;
          })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [DoctorLocationsData]);

  useEffect(() => {
    if (inputsCase?.sreqspecialty) {
      setInputs((prev) => ({
        ...prev,
        specialty: inputsCase?.sreqspecialty,
      }));
    }
  }, [inputsCase?.sreqspecialty]);

  const handleSearch = () => {
    const where = {
      status: { equals: "Active" },
      doctor: {
        is: {
          status: { equals: "Active" },
        },
      },
    };

    if (doctorsNotToUse.length) {
      where.doctorcode = { notIn: doctorsNotToUse };
    }

    if (proximity && useProximity && zips.size) {
      where.location = { is: { zip: { in: [...zips.keys()] } } };
    }

    if (inputs.specialty) {
      where.doctor = where?.doctor?.is
        ? {
            is: {
              ...where.doctor.is,
              DoctorSpecialty: {
                some: { specialtycode: { equals: inputs.specialty } },
              },
            },
          }
        : {
            is: {
              DoctorSpecialty: {
                some: { specialtycode: { equals: inputs.specialty } },
              },
            },
          };
    }

    if (inputs.credentials) {
      where.doctor = where?.doctor?.is
        ? {
            is: {
              ...where.doctor.is,
              credentials: { equals: inputs.credentials },
            },
          }
        : { is: { credentials: { equals: inputs.credentials } } };
    }

    if (inputs.doctorcode) {
      where.doctorcode = { equals: parseInt(inputs.doctorcode, 10) };
    }
    if (!useProximity) {
      if (inputs.state) {
        where.location = where?.location?.is
          ? { is: { ...where.location.is, state: inputs.state } }
          : { is: { state: inputs.state } };
      }

      if (inputs.city) {
        where.location = where?.location?.is
          ? { is: { ...where.location.is, city: { equals: inputs.city } } }
          : { is: { city: { equals: inputs.city } } };
      }

      if (inputs.county) {
        where.location = where?.location?.is
          ? { is: { ...where.location.is, county: { equals: inputs.county } } }
          : { is: { county: { equals: inputs.county } } };
      }

      if (inputs.zip) {
        where.location = where?.location?.is
          ? { is: { ...where.location.is, zip: { equals: inputs.zip } } }
          : { is: { zip: { equals: inputs.zip } } };
      }
    }
    getDoctorLocations({
      variables: {
        where,
      },
    });
  };

  const isDisabled = useMemo(
    () =>
      loading ||
      !Object.keys(inputs).length ||
      (proximity && !zips.size) ||
      !inputs.specialty ||
      (useProximity &&
        (!proximity ||
          !inputs.city ||
          !inputs.state ||
          !inputs.zip ||
          !inputs.county)),
    [inputs, loading, proximity, useProximity, zips.size]
  );

  const handleRowClick = ({ doctorcode, locationcode }, columnId) => {
    if (columnId === "block-time") {
      setModalOpen(
        true,
        <DoctorBlockTimeScheduleModal
          casenbr={casenbr}
          doctorcode={doctorcode}
          doctorspecialty={inputs.specialty}
          locationcode={locationcode}
          onComplete={() => setModalOpen(false, "")}
        />
      );
    } else {
      setModalOpen(
        true,
        <ScheduleCaseModal
          prefillDoctor
          casenbr={casenbr}
          doctorcode={doctorcode}
          doctorspecialty={inputs.specialty}
          inputsCase={inputsCase}
          locationcode={locationcode}
          onComplete={() => {
            setModalOpen(false, "");
          }}
        />
      );
    }
  };

  const handleBlur = async (name, value) => {
    const isCity = name === "city";
    const isState = name === "state";

    if ((isCity && inputs.state) || (isState && inputs.city)) {
      const where = {};
      where.sCity = { equals: isCity ? value : inputs.city };
      where.sState = isState ? value : inputs.state;

      const {
        data: { findFirstZipCode },
      } = await client.query({
        query: FIND_FIRST_ZIP_QUERY,
        variables: {
          where,
        },
      });
      const zipCode = findFirstZipCode?.sZip;
      const county = findFirstZipCode?.sCountyName;

      if (zipCode && county) {
        setInputs((prev) => ({ ...prev, zip: zipCode, county }));
      }
    }
  };

  const handleSchedule = () => {
    setModalOpen(
      true,
      <ScheduleCaseModal
        casenbr={casenbr}
        doctorspecialty={inputs.specialty}
        inputsCase={inputsCase}
        prefillDoctor={false}
        onComplete={() => {
          setModalOpen(false, "");
        }}
      />
    );
  };

  const handleProximityChange = ({ target: { value } }) => {
    const newValue = clampNumber(
      parseInt(value, 10),
      MIN_PROXIMITY,
      MAX_PROXIMITY
    );
    setUseProximity(!!newValue);
    setProximity(newValue || "");
  };

  const handleReset = () => {
    setProximity("");
    setUseProximity(false);
    setZips(new Map());
    setDoctorLocations([]);

    const examinee = CaseItemData?.caseItem?.examinee;

    if (examinee) {
      const inputsHaveChanged = EXAMINEE_FIELDS.some(({ key, fn }) => {
        const inputState = fn(inputs?.[key]) || "";
        const examineeState = examinee[key] || "";
        return inputState !== examineeState;
      });

      const examineeAddressIncomplete = EXAMINEE_FIELDS.some(
        ({ key }) => !examinee[key]
      );

      const messageArr = [];

      if (inputsHaveChanged) {
        messageArr.push("Search inputs have been reset");
      } else {
        messageArr.push("Search inputs are already reset");
      }

      if (examineeAddressIncomplete) {
        messageArr.push(
          " but examinee address information is incomplete. Click below to update"
        );
      }

      toast(
        <CustomToastWithLink
          actionLabel="Update"
          message={[...messageArr, "."].join("")}
          showAction={examineeAddressIncomplete}
          to={`/cases/${casenbr}/examinee`}
        />,
        {
          autoClose: 5000,
          hideProgressBar: examineeAddressIncomplete,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: true,
        }
      );

      setInputs((prev) => ({
        specialty: inputsCase?.sreqspecialty || "",
        city: examinee.city || "",
        state: examinee.state ? { equals: examinee.state } : "",
        zip: examinee.zip || "",
        county: examinee.county || "",
      }));
    }
  };

  const [throttledReset] = useThrottle(handleReset, 1000);

  const handleClear = () => {
    setProximity("");
    setUseProximity(false);
    setZips(new Map());
    setInputs({});
    setDoctorLocations([]);
  };

  const isClearable = !!CaseItemData?.caseItem?.examinee;
  const doctorLabel = useCaseDoctorLabel(casenbr);

  return (
    <div>
      <Box>
        <div className="list-filters" style={{ marginBottom: "1rem" }}>
          <Field
            kind="group"
            style={{ gridColumn: "5 / 6", justifySelf: "flex-end" }}
          >
            <Control>
              <Button
                color="primary"
                size="small"
                type="button"
                onClick={handleSchedule}
              >
                <Icon>
                  <FontAwesomeIcon icon={["fa", "calendar-times"]} />
                </Icon>
                <span>Schedule</span>
              </Button>
            </Control>
            {isClearable && (
              <Control>
                <Button
                  color="info"
                  size="small"
                  type="button"
                  onClick={handleClear}
                >
                  <Icon>
                    <FontAwesomeIcon icon="times" />
                  </Icon>
                  <span>Clear</span>
                </Button>
              </Control>
            )}
          </Field>
        </div>

        <form
          className="list-filters"
          onSubmit={(e) => {
            e.preventDefault();
            setDoctorLocations([]);
            handleSearch();
          }}
        >
          <Field>
            <Control>
              <SpecialtySelect
                required
                name="specialty"
                value={inputs.specialty}
                onChange={handleChange}
              />
            </Control>
          </Field>
          <Field>
            <Control>
              <Label>City</Label>
              <Input
                autoComplete="new"
                name="city"
                required={useProximity}
                size="small"
                type="text"
                value={inputs.city || ""}
                onBlur={(e) => handleBlur(e.target.name, e.target.value)}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
              />
            </Control>
          </Field>
          <Field>
            <StateSelect
              getValue={(x) => x?.equals || ""}
              label="State"
              name="state"
              required={useProximity}
              setValue={(x) => (x ? { equals: x } : "")}
              value={inputs.state}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </Field>
          <Field>
            <ZipInput
              name="zip"
              required={useProximity}
              value={inputs.zip || ""}
              onChange={handleChange}
            />
          </Field>
          <Field>
            <Control>
              <Label>County</Label>
              <Input
                autoComplete="new"
                name="county"
                required={useProximity}
                size="small"
                type="text"
                value={inputs.county || ""}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
              />
            </Control>
          </Field>
          <Field kind="group">
            <Control expanded>
              <Label>Proximity</Label>
              <Input
                autoComplete="new"
                name="proximity"
                required={useProximity}
                size="small"
                type="number"
                value={proximity}
                onChange={handleProximityChange}
                onWheel={(e) => e.preventDefault()}
              />
            </Control>
            <Control>
              <Label>Use Proximity</Label>
              <BooleanInput
                name="useProximity"
                value={useProximity}
                onChange={(name, value) => {
                  const checked = !!value;
                  setUseProximity(checked);
                  setProximity((prev) => (checked ? prev : ""));
                }}
              />
            </Control>
          </Field>
          <Field>
            <DoctorSelect
              showOnlyActives
              label={doctorLabel}
              name="doctorcode"
              value={inputs.doctorcode}
              onChange={handleChange}
            />
          </Field>
          <Field>
            <DoctorLocationSelect
              showOnlyActives
              doctorcode={inputs.doctorcode}
              label={`${doctorLabel} Location`}
              name="doctorlocation"
              value={inputs.doctorlocation}
              onChange={handleChange}
            />
          </Field>

          <Field>
            <DegreeSelect
              name="credentials"
              value={inputs.credentials}
              onChange={handleChange}
            />
          </Field>
          <Field kind="group">
            <Control expanded>
              <Label>&nbsp;</Label>
              <Button
                fullwidth
                color="success"
                disabled={isDisabled}
                size="small"
                state={loading || zipsLoading ? "loading" : ""}
                type="submit"
              >
                <Icon>
                  <FontAwesomeIcon icon="search" />
                </Icon>
                <span>Search</span>
              </Button>
            </Control>
            <Control expanded>
              <Label>&nbsp;</Label>
              <Button
                fullwidth
                color="danger"
                size="small"
                type="button"
                onClick={throttledReset}
              >
                <Icon>
                  <FontAwesomeIcon icon="times" />
                </Icon>
                <span>Reset</span>
              </Button>
            </Control>
          </Field>
        </form>
      </Box>
      <ScheduleTable data={doctorLocations} onRowClick={handleRowClick} />
    </div>
  );
};

Schedule.propTypes = {
  onChangeCase: PropTypes.func.isRequired,
  inputsCase: PropTypes.object.isRequired,
};

export default Schedule;
