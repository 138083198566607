import React, { useCallback, useMemo, useState } from "react";
import PropTypes from "prop-types";

import { useParams } from "react-router-dom";

// components
import { Field, Control, Icon, Button, Label } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { ALL_DOCTOR_LOCATIONS_QUERY } from "../../../../graphql/doctorLocation";

import DoctorLocationModal from "../../components/DoctorLocationModal";

import StatusSelect from "../../../../components/StatusSelect";
import StateSelect from "../../../../components/StateSelect";

import FilterInput from "../../../../components/FilterInput";
import { debounce } from "../../../../utils";
import DataTable from "../../../../components/DataTable";
import { DoctorDailyApptList, DoctorBlockTimeModal } from "../../components";

import { generateColumns } from "./columns";

import { useModal } from "../../../../context";

const initialState = {
  status: { equals: "Active" },
  location: {
    is: {
      location: { contains: "" },
      city: { contains: "" },
      state: { contains: "" },
    },
  },
};
const Locations = ({ onChange, inputs, disabled }) => {
  const [where, setWhere] = useState(initialState);
  const { setModalOpen } = useModal();

  const { doctorcode } = useParams();

  const handleRowClick = ({ locationcode }) => {
    setModalOpen(
      true,
      <DoctorLocationModal
        doctorcode={doctorcode}
        locationcode={locationcode}
        refetchWhere={{
          doctorcode: { equals: parseInt(doctorcode, 10) },
          ...where,
        }}
        onComplete={() => setModalOpen(false, "")}
      />
    );
  };
  const handleAddClick = () => {
    setModalOpen(
      true,
      <DoctorLocationModal
        doctorcode={doctorcode}
        refetchWhere={{
          doctorcode: { equals: parseInt(doctorcode, 10) },
          ...where,
        }}
        onComplete={() => setModalOpen(false, "")}
      />
    );
  };

  const handleChange = (name, value) => {
    if (!value) {
      setWhere((prev) =>
        Object.keys(prev).reduce((acc, curr) => {
          if (curr !== name) acc[curr] = prev[curr];
          return acc;
        }, {})
      );
    } else {
      setWhere((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleChangeLocation = debounce((name, value) => {
    setWhere((prev) => ({
      ...prev,
      location: { is: { ...prev.location.is, [name]: { contains: value } } },
    }));
  }, 500);

  const handleDailyApptListClick = useCallback(
    (locationcode) => {
      setModalOpen(
        true,
        <DoctorDailyApptList
          doctorcode={doctorcode}
          locationcode={locationcode}
          onComplete={() => setModalOpen(false, "")}
        />
      );
    },
    [doctorcode, setModalOpen]
  );

  const handleDoctorBlockTimeClick = useCallback(
    (locationcode) => {
      setModalOpen(
        true,
        <DoctorBlockTimeModal
          doctorcode={doctorcode}
          locationcode={locationcode}
          onComplete={() => setModalOpen(false, "")}
        />
      );
    },
    [doctorcode, setModalOpen]
  );

  const COLUMNS = useMemo(
    () =>
      generateColumns({ handleDailyApptListClick, handleDoctorBlockTimeClick }),
    [handleDailyApptListClick, handleDoctorBlockTimeClick]
  );

  return (
    <div className="doctor-locations-page">
      <header className="page-head">
        <div className="page-head-start">
          <Field kind="group">
            <Control expanded>
              <Button
                color="primary"
                size="small"
                style={{ marginTop: 25 }}
                type="button"
                onClick={handleAddClick}
              >
                <Icon>
                  <FontAwesomeIcon icon="plus" />
                </Icon>
                <span>Add</span>
              </Button>
            </Control>
            <Control expanded>
              <StatusSelect
                showBlank
                getValue={(x) => x?.equals || ""}
                label="Status"
                name="status"
                setValue={(x) => (x ? { equals: x } : "")}
                value={where.status}
                onChange={handleChange}
              />
            </Control>
            <Control expanded>
              <FilterInput
                label="Location"
                maxLength={50}
                name="location"
                placeholder="Location"
                size="small"
                value={where?.location?.is?.location?.contains || ""}
                onChange={handleChangeLocation}
              />
            </Control>
            <Control expanded>
              <FilterInput
                label="City"
                maxLength={50}
                name="city"
                placeholder="City"
                size="small"
                value={where?.location?.is?.city?.contains || ""}
                onChange={handleChangeLocation}
              />
            </Control>
            <Control expanded>
              <StateSelect
                id="Statecode"
                label="State"
                name="state"
                size="small"
                value={where?.location?.is?.state?.contains || ""}
                onChange={handleChangeLocation}
              />
            </Control>
            <Control expanded>
              <Label>&nbsp;</Label>
              <Button
                color="danger"
                size="small"
                onClick={() => setWhere(initialState)}
              >
                Reset
              </Button>
            </Control>
          </Field>
        </div>
        <div className="page-head-end" />
      </header>
      <DataTable
        aggregateKey="doctorcode"
        aggregateName="aggregateDoctorLocation"
        columns={COLUMNS}
        fetchPolicy="cache-and-network"
        name="doctorLocations"
        query={ALL_DOCTOR_LOCATIONS_QUERY}
        where={{ doctorcode: { equals: parseInt(doctorcode, 10) }, ...where }}
        onRowClick={handleRowClick}
      />
    </div>
  );
};
Locations.propTypes = {
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  inputs: PropTypes.object.isRequired,
};

export default Locations;
