import { gql } from "@apollo/client";

// eslint-disable-next-line import/prefer-default-export
export const FIND_FIRST_ACCT_DETAIL_QUERY = gql`
  query FIND_FIRST_ACCT_DETAIL_QUERY($where: AcctDetailWhereInput!) {
    findFirstAcctDetail: findFirstAcctDetail(where: $where) {
      documentnbr
    }
  }
`;
