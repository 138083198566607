import React from "react";
import PropTypes from "prop-types";
import { Field, Control, Label, Input } from "rbx";
import StateSelect from "../../../../components/StateSelect";

import USAAClaimOfficeSelect from "../USAAClaimOfficeSelect";

const USAAForm = ({ handleChange, inputs, disabled }) => {
  const legalSelected = inputs?.InsuranceCarrier?.includes("Legal");
  const claimsSelected = inputs?.InsuranceCarrier?.includes("Claims");

  return (
    <React.Fragment>
      <Field
        className={`grid-override ${claimsSelected ? "is-three" : "is two"}`}
        kind="group"
      >
        <Control>
          <Label>Claim Number</Label>
          <Input
            disabled
            name="ClaimNumber"
            size="small"
            value={inputs.ClaimNumber}
          />
        </Control>

        {legalSelected && (
          <StateSelect
            displayFullLabel
            expanded
            extendedOptionsUSAA
            required
            disabled={disabled}
            label="USAA Legal Office"
            name="RRLegalOffice_us"
            size="small"
            value={inputs.RRLegalOffice_us || ""}
            onChange={handleChange}
          />
        )}

        {claimsSelected && (
          <USAAClaimOfficeSelect
            fullWidth
            required
            showBlank
            disabled={disabled}
            id="RRclaimOffice_us"
            label="USAA Claim Office"
            name="RRclaimOffice_us"
            size="small"
            value={inputs.RRclaimOffice_us}
            onChange={handleChange}
          />
        )}

        {claimsSelected && (
          <Control expanded>
            <Label>USAA Member Number</Label>
            <Input
              required
              autoComplete="new"
              disabled={disabled}
              maxLength={50}
              name="RRmemberNumber_us"
              size="small"
              value={inputs.RRmemberNumber_us}
              onChange={(e) => handleChange(e.target.name, e.target.value)}
            />
          </Control>
        )}
      </Field>

      {claimsSelected && (
        <Field className="grid-override is-three" kind="group">
          <Control expanded>
            <Label>USAA Claims Loss Email Address</Label>
            <Input
              required
              autoComplete="new"
              disabled={disabled}
              maxLength={50}
              name="RRclaimsLossEmail_us"
              size="small"
              type="email"
              value={inputs.RRclaimsLossEmail_us}
              onChange={(e) => handleChange(e.target.name, e.target.value)}
            />
          </Control>
          <Control expanded>
            <Label>USAA Loss Report Number</Label>
            <Input
              required
              autoComplete="new"
              disabled={disabled}
              maxLength={50}
              name="RRlossReportNumber_us"
              size="small"
              value={inputs.RRlossReportNumber_us}
              onChange={(e) => handleChange(e.target.name, e.target.value)}
            />
          </Control>
          {/* <Control expanded>
            <Label>USAA ALIS Number</Label>
            <Input
              autoComplete="new"
              disabled={disabled}
              maxLength={50}
              name="RRalisNumber_us"
              size="small"
              value={inputs.RRalisNumber_us}
              onChange={(e) => handleChange(e.target.name, e.target.value)}
            />
          </Control> */}
        </Field>
      )}
    </React.Fragment>
  );
};

USAAForm.propTypes = {
  handleChange: PropTypes.func.isRequired,
  inputs: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default USAAForm;
