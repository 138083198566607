import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Field, Button, Control, Title } from "rbx";
import { useMutation } from "@apollo/client";
import {
  UPDATE_CASE_MUTATION,
  LIST_ASSOCIATED_CASES_QUERY,
  SINGLE_CASE_QUERY,
} from "../../../../graphql";
import { customToast as toast } from "../../../../utils";
import { useMasterSubCase } from "../../../../hooks";
import CaseSelectWithDemographics from "../../../../components/CaseSelectWithDemographics";
import BooleanInput from "../../../../components/BooleanInput";

class CustomSubCaseError extends Error {}

const CaseLinkToMasterModal = ({ casenbr, onComplete }) => {
  const [masterCasenbr, setMasterCasenbr] = useState("");
  const [inputs, setInputs] = useState({
    matchClaim: true,
    matchExaminee: false,
  });

  const { claimnbr, chartnbr, mastersubcase } = useMasterSubCase(casenbr);

  const [loading, setLoading] = useState(false);
  const [updateCase] = useMutation(UPDATE_CASE_MUTATION);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);

      if (mastersubcase === "M") {
        throw new CustomSubCaseError(
          `This case is a master case and cannot be made a subcase.`
        );
      }
      await updateCase({
        variables: {
          where: {
            casenbr: parseInt(casenbr, 10),
          },
          data: {
            mastersubcase: { set: "S" },
            mastercasenbr: { set: parseInt(masterCasenbr, 10) },
          },
        },
        refetchQueries: [
          {
            query: SINGLE_CASE_QUERY,
            variables: { where: { casenbr: parseInt(casenbr, 10) } },
          },
          { query: LIST_ASSOCIATED_CASES_QUERY },
        ],
      });

      toast.success(`Case Linked to Master successfully.`);
      onComplete();
    } catch (err) {
      const message =
        err instanceof CustomSubCaseError
          ? err.message
          : "Error adding to master case.";
      toast.error(message);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (name, value) => {
    setInputs((prev) => ({ ...prev, [name]: value }));
  };

  const where = useMemo(() => {
    const result = {
      casenbr: { not: { equals: parseInt(casenbr, 10) } },
      mastersubcase: { equals: "M" },
      OR: [
        {
          mastercasenbr: { not: { equals: parseInt(casenbr, 10) } },
        },
        {
          mastercasenbr: { equals: null },
        },
      ],
    };
    if (inputs.matchClaim) {
      result.claimnbr = { equals: claimnbr };
    }
    if (inputs.matchExaminee) {
      result.chartnbr = { equals: chartnbr };
    }
    return result;
  }, [chartnbr, claimnbr, casenbr, inputs.matchClaim, inputs.matchExaminee]);

  return (
    <form id="subcase-form" onSubmit={handleSubmit}>
      <header className="page-head">
        <div className="page-head-start">
          <Title size={5}>Link Case To Master</Title>
        </div>
        <div className="page-head-end">
          <Button.Group hasAddons>
            <Button
              size="small"
              type="button"
              onClick={() => onComplete(false)}
            >
              <span>Cancel</span>
            </Button>
            <Button
              color="primary"
              disabled={loading || !masterCasenbr}
              form="subcase-form"
              size="small"
              state={loading ? "loading" : ""}
              type="submit"
            >
              <span>Submit</span>
            </Button>
          </Button.Group>
        </div>
      </header>
      <hr />
      <Field kind="group">
        <Control>
          <BooleanInput
            disabled={loading}
            label="Match Claim"
            name="matchClaim"
            value={inputs.matchClaim}
            onChange={handleChange}
          />
        </Control>
        <Control>
          <BooleanInput
            disabled={loading}
            label="Match Examinee"
            name="matchExaminee"
            value={inputs.matchExaminee}
            onChange={handleChange}
          />
        </Control>
      </Field>
      <Field>
        <CaseSelectWithDemographics
          showMasterSubcase
          disabled={loading}
          label="Case"
          value={masterCasenbr}
          where={where}
          onChange={(name, value) => setMasterCasenbr(value)}
        />
      </Field>
      <hr />
    </form>
  );
};

CaseLinkToMasterModal.propTypes = {
  casenbr: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onComplete: PropTypes.func,
};
CaseLinkToMasterModal.defaultProps = {
  onComplete: () => {},
  casenbr: "",
};

export default CaseLinkToMasterModal;
