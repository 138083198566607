import React, { useState } from "react";
import PropTypes from "prop-types";
import { Title, Button, Field, Control } from "rbx";
import { useMutation } from "@apollo/client";

import {
  CREATE_APOLLO_NOTE_MUTATION,
  LIST_APOLLO_NOTES_QUERY,
  DELETE_APOLLO_NOTE_MUTATION,
} from "../../../../graphql";
import {
  customToast as toast,
  getDateEST,
  convertTimeZoneDataBase,
} from "../../../../utils";
import { useAuth, useModal } from "../../../../context";
import Confirmation from "../../../../components/Confirmation";
import ApolloNoteForm from "../ApolloNoteForm";

const INITIAL_STATE = {
  NoteText: "",
  NoteTypeID: 1,
};

const ApolloNoteModal = ({ onComplete, casenbr, inputs: initialInputs }) => {
  const { state: authState } = useAuth();
  const { setModalOpen } = useModal();
  const [createApolloNote] = useMutation(CREATE_APOLLO_NOTE_MUTATION);
  const [deleteApolloNote] = useMutation(DELETE_APOLLO_NOTE_MUTATION);

  const [loading, setLoading] = useState(false);
  const [inputs, setInputs] = useState(initialInputs || INITIAL_STATE);

  const handleChange = (name, value) => {
    setInputs((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);

      const refetchQueries = [
        {
          query: LIST_APOLLO_NOTES_QUERY,
          variables: {
            where: {
              Service: {
                is: {
                  WorkID: {
                    equals: parseInt(casenbr, 10),
                  },
                },
              },
            },
            orderBy: [{ CreatedDate: "desc" }],
          },
        },
      ];
      const date = convertTimeZoneDataBase(getDateEST());
      await createApolloNote({
        variables: {
          data: {
            NoteText: inputs.NoteText,
            casenbr: parseInt(casenbr, 10),
            CreatedBy: authState?.user?.userid,
            CreatedDate: date,
            NoteTypeID: inputs.NoteTypeID,
          },
        },
        refetchQueries,
      });
      toast.success(`Note created successfully`);
      onComplete();
    } catch (err) {
      toast.error(`Error creating note.`);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    const performDelete = async () => {
      try {
        setLoading(true);
        await deleteApolloNote({
          variables: {
            where: {
              NoteID: parseInt(initialInputs.NoteID, 10),
            },
          },
          refetchQueries: [
            {
              query: LIST_APOLLO_NOTES_QUERY,
              variables: {
                where: {
                  Service: {
                    is: {
                      WorkID: {
                        equals: parseInt(casenbr, 10),
                      },
                    },
                  },
                },
                orderBy: [{ CreatedDate: "desc" }],
              },
            },
          ],
        });
        toast.success("Note deleted successfully");
        onComplete();
      } catch (err) {
        toast.error("Error trying to delete Note");
      } finally {
        setLoading(false);
      }
    };
    setModalOpen(
      true,
      <Confirmation
        message="Are you sure you want to delete this Note?"
        onCancel={() => setModalOpen(false)}
        onConfirm={performDelete}
      />
    );
  };

  const isDisabled = !inputs.NoteText;

  return (
    <form id="note-form" onSubmit={handleSubmit}>
      <header className="page-head">
        <div className="page-head-start">
          <Title size={5}>Notes</Title>
        </div>
        <div className="page-head-end">
          <Button.Group hasAddons>
            <Button
              size="small"
              type="button"
              onClick={() => onComplete(false)}
            >
              <span>Cancel</span>
            </Button>
            <Button
              color="primary"
              disabled={isDisabled}
              form="note-form"
              size="small"
              state={loading ? "loading" : ""}
              type="submit"
            >
              <span>Submit</span>
            </Button>
          </Button.Group>
        </div>
      </header>
      <hr />
      <ApolloNoteForm
        disabled={!!initialInputs}
        inputs={inputs}
        onChange={handleChange}
      />
      <hr />
      {initialInputs && (
        <Field>
          <Control>
            <Button
              color="danger"
              size="small"
              type="button"
              onClick={handleDelete}
            >
              <span>Delete</span>
            </Button>
          </Control>
        </Field>
      )}
    </form>
  );
};

ApolloNoteModal.propTypes = {
  onComplete: PropTypes.func.isRequired,
  casenbr: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  inputs: PropTypes.object,
};

ApolloNoteModal.defaultProps = {
  inputs: null,
};

export default ApolloNoteModal;
