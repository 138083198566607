import styled from "styled-components";
import { Title } from "rbx";

const ReferralStyledSubtitle = styled(Title).attrs({ subtitle: true })`
  position: relative;
  color: #2a4734;
  font-size: 1.1rem;
  width: 100%;
  &:after {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 1px;
    transform: translate(0, 5px);
    background-color: #7e998d;
  }
`;

export default ReferralStyledSubtitle;
