/* eslint-disable no-console */
import {
  ApolloClient,
  from,
  HttpLink,
  InMemoryCache,
  ApolloLink,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";

import Cookies from "js-cookie";

const { NODE_ENV } = process.env;

const isProduction = NODE_ENV === "production";

// this will forward graphql + networking errors to the browser console
const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      graphQLErrors.map(({ message, locations, path }) =>
        console.error(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path} 😞`
        )
      );
    }
    if (networkError) {
      console.error(`[Network error]: ${networkError} 😞`);
    }
    return forward(operation);
  }
);

const httpLink = new HttpLink({
  uri: isProduction ? "/api" : "http://localhost:4000",
});

const authLink = new ApolloLink((operation, forward) => {
  const token = Cookies.get("token");
  operation.setContext(({ headers }) => ({
    headers: { authorization: token ? `Bearer ${token}` : "", ...headers },
  }));

  return forward(operation);
});

export default new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      CaseItem: {
        keyFields: ["casenbr"],
      },
      Company: {
        keyFields: ["companycode"],
      },
      Examinee: {
        keyFields: ["chartnbr"],
      },
      Location: {
        keyFields: ["locationcode"],
      },
      DoctorLocation: {
        keyFields: ["doctorcode", "locationcode"],
      },
      Client: {
        keyFields: ["clientcode"],
      },
      ClientDefDocument: {
        keyFields: ["clientcode", "documentcode", "documentqueue"],
      },
      CompanyDefDocument: {
        keyFields: ["companycode", "documentcode", "documentqueue"],
      },
      CompanyDefDocuments: {
        keyFields: ["companycode", "documentcode", "documentqueue"],
      },
      companyDefDocuments: {
        keyFields: ["companycode", "documentcode", "documentqueue"],
      },
      CaseDocument: {
        keyFields: ["seqno"],
      },
      CaseDocuments: {
        keyFields: ["seqno"],
      },
      ExamineeCC: {
        keyFields: ["cccode", "chartnbr"],
      },
      DoctorLocations: {
        keyFields: ["doctorcode", "locationcode"],
      },
      DoctorSpecialties: {
        keyFields: ["doctorcode", "specialtycode"],
      },
      DoctorSpecialty: {
        keyFields: ["doctorcode", "specialtycode"],
      },
      Doctor: {
        keyFields: ["doctorcode"],
      },
      Service: {
        keyFields: ["servicecode"],
      },
      clientDefDocuments: {
        keyFields: ["clientcode", "documentcode", "documentqueue"],
      },
      Queue: {
        keyFields: ["statuscode"],
      },
      DoctorSchedule: {
        keyFields: ["schedcode", "locationcode"],
      },
    },
  }),
  link: from([errorLink, authLink, httpLink]),
});
