import { format } from "date-fns";
import { convertTimeZone } from "../../utils";

const DEFAULT_COLUMNS = [
  {
    Header: "Last Name",
    id: "lastname",
    accessor: "lastname",
  },
  {
    Header: "First Name",
    id: "firstname",
    accessor: "firstname",
  },
  {
    Header: "Company",
    id: "company:intname",
    accessor: "company.intname",
  },
  {
    Header: "Address",
    id: "addr1",
    accessor: "addr1",
  },
  {
    Header: "City",
    id: "city",
    accessor: "city",
  },
  {
    Header: "State",
    id: "state",
    accessor: "state",
  },
  {
    Header: "Last Appt",
    id: "lastappt",
    accessor: "lastappt",
    Cell: ({ cell }) =>
      cell?.value ? format(convertTimeZone(cell.value), "MM/dd/yyyy") : "--",
  },
  {
    Header: "Phone",
    id: "phone1",
    accessor: "phone1",
  },
  {
    Header: "Status",
    accessor: "status",
    id: "status",
  },
];

export default DEFAULT_COLUMNS;
