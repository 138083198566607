import React from "react";
import { format } from "date-fns";
import PropTypes from "prop-types";
import { Column, Control, Input, Label } from "rbx";
import { useParams } from "react-router-dom";
import { convertTimeZone } from "../../../utils";
import ClientDeleteButton from "../components/ClientDeleteButton";
import { usePermissions } from "../../../hooks";

const Statistics = ({ client }) => {
  const { clientcode } = useParams();
  const isAdmin = usePermissions(["AllAdminFunc"]);
  return (
    <Column.Group>
      <Column size={6}>
        <div className="grid-2">
          <Control style={{ marginBottom: "1rem" }}>
            <Label>Last Case</Label>
            <Input
              readOnly
              name="lastappt"
              size="small"
              value={
                client.lastappt
                  ? format(convertTimeZone(client.lastappt), "MM/dd/yyyy")
                  : "--"
              }
            />
          </Control>
          <Control style={{ marginBottom: "1rem" }}>
            <Label>System Client Code</Label>
            <Input
              readOnly
              name="clientcode"
              size="small"
              value={client.clientcode || "--"}
            />
          </Control>
        </div>
        <div className="grid-2">
          <Control style={{ marginBottom: "1rem" }}>
            <Label>Date Added</Label>
            <Input
              readOnly
              name="dateadded"
              size="small"
              value={
                client.dateadded
                  ? format(
                      convertTimeZone(client.dateadded),
                      "MM/dd/yyyy hh:mm:ss a"
                    )
                  : "--"
              }
            />
          </Control>
          <Control style={{ marginBottom: "1rem" }}>
            <Label>By</Label>
            <Input
              readOnly
              name="useridadded"
              size="small"
              value={client.useridadded || "--"}
            />
          </Control>
        </div>
        <div className="grid-2">
          <Control style={{ marginBottom: "1rem" }}>
            <Label>Last Edited</Label>
            <Input
              readOnly
              name="dateedited"
              size="small"
              value={
                client.dateedited
                  ? format(
                      convertTimeZone(client.dateedited),
                      "MM/dd/yyyy hh:mm:ss a"
                    )
                  : "--"
              }
            />
          </Control>
          <Control style={{ marginBottom: "1rem" }}>
            <Label>By</Label>
            <Input
              readOnly
              name="useridedited"
              size="small"
              value={client.useridedited || "--"}
            />
          </Control>
        </div>
      </Column>
      <Column>
        {isAdmin && <ClientDeleteButton clientcode={clientcode} />}
      </Column>
    </Column.Group>
  );
};

Statistics.propTypes = {
  client: PropTypes.object,
};

Statistics.defaultProps = {
  client: {},
};

export default Statistics;
