export const minutes = [
  {
    value: "00",
    label: "00",
    id: 1,
  },
  {
    value: "15",
    label: "15",
    id: 2,
  },
  {
    value: "30",
    label: "30",
    id: 3,
  },
  {
    value: "45",
    label: "45",
    id: 4,
  },
];

export const hours = [
  {
    value: "01",
    label: "01",
    id: 1,
  },
  {
    value: "02",
    label: "02",
    id: 2,
  },
  {
    value: "03",
    label: "03",
    id: 3,
  },
  {
    value: "04",
    label: "04",
    id: 4,
  },
  {
    value: "05",
    label: "05",
    id: 5,
  },
  {
    value: "06",
    label: "06",
    id: 6,
  },
  {
    value: "07",
    label: "07",
    id: 7,
  },
  {
    value: "08",
    label: "08",
    id: 8,
  },
  {
    value: "09",
    label: "09",
    id: 9,
  },
  {
    value: "10",
    label: "10",
    id: 10,
  },
  {
    value: "11",
    label: "11",
    id: 11,
  },
  {
    value: "12",
    label: "12",
    id: 12,
  },
];

export const duration = [
  {
    value: "15",
    label: "15",
    id: 1,
  },
  {
    value: "30",
    label: "30",
    id: 2,
  },
  {
    value: "60",
    label: "60",
    id: 3,
  },
];

export const meridiem = [
  {
    id: 1,
    value: "AM",
    label: "AM",
  },
  {
    id: 2,
    value: "PM",
    label: "PM",
  },
];
