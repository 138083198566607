import React from "react";
import PropTypes from "prop-types";
import { Tab } from "rbx";
import { useLocation, Link } from "react-router-dom";

const DocumentTabs = ({ id }) => {
  const location = useLocation();

  return (
    <Tab.Group kind="boxed">
      <Tab
        active={location.pathname.includes("profile")}
        as={Link}
        to={`/documents/${id}/profile`}
      >
        Profile
      </Tab>
      <Tab
        active={location.pathname.includes("template")}
        as={Link}
        to={`/documents/${id}/template`}
      >
        Template
      </Tab>
      <Tab
        active={location.pathname.includes("test")}
        as={Link}
        to={`/documents/${id}/test`}
      >
        Test
      </Tab>
    </Tab.Group>
  );
};

DocumentTabs.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};
export default DocumentTabs;
