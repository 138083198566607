import React from "react";
import PropTypes from "prop-types";
import { Field, Input, Control, Label } from "rbx";
import NativeSelect from "../../../../../components/NativeSelect";
import StatusSelect from "../../../../../components/StatusSelect";

const QueueForm = ({ inputs, onChange }) => (
  <div>
    <Field kind="group">
      <Control>
        <Label>Name</Label>
        <Input
          required
          autoComplete="new"
          maxLength={10}
          name="shortdesc"
          size="small"
          value={inputs.shortdesc}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
      <Control expanded>
        <Label>Description</Label>
        <Input
          required
          autoComplete="new"
          name="statusdesc"
          size="small"
          value={inputs.statusdesc}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
      <Control expanded>
        <Label>Order</Label>
        <Input
          required
          autoComplete="new"
          name="displayorder"
          size="small"
          type="number"
          value={inputs.displayorder}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
      <Control>
        <NativeSelect
          fullWidth
          label="Type"
          name="type"
          options={[
            {
              name: "type",
              value: "User",
              label: "User",
            },
            {
              name: "type",
              value: "system",
              label: "system",
            },
          ]}
          size="small"
          value={inputs.type}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
      <Control>
        <StatusSelect
          label="Status"
          name="status"
          value={inputs.status}
          onChange={onChange}
        />
      </Control>
    </Field>
  </div>
);

QueueForm.propTypes = {
  inputs: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default QueueForm;
