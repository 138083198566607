import { gql } from "@apollo/client";

// eslint-disable-next-line import/prefer-default-export
export const CREATE_NOTE_MUTATION = gql`
  mutation CREATE_NOTE_MUTATION($data: NotesCreateInput!) {
    createNotes(data: $data) {
      NoteID
      ClaimID
      NoteTypeID
    }
  }
`;
