import React from "react";
import PropTypes from "prop-types";
import { Column, Control } from "rbx";

import { useParams } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import {
  UPDATE_DOCTOR_MUTATION,
  SINGLE_DOCTOR_QUERY,
} from "../../../../graphql";

import { usePermissions, useFunctions } from "../../../../hooks";
import NotesInput from "../../../../components/NotesInput";

const Notes = ({ inputs, onChange, disabled, handleSave }) => {
  const { doctorcode } = useParams();

  const isAdmin = usePermissions(["AllAdminFunc"]);
  const isAllowed = useFunctions(["EditDoctorNote"]);

  const canEdit = isAdmin || isAllowed;

  const [updateDoctor] = useMutation(UPDATE_DOCTOR_MUTATION);

  const handleSaveNote = async (note) => {
    updateDoctor({
      variables: {
        data: {
          notes: { set: note },
        },
        where: {
          doctorcode: parseInt(doctorcode, 10),
        },
      },
      refetchQueries: [
        {
          query: SINGLE_DOCTOR_QUERY,
          variables: {
            where: { doctorcode: parseInt(doctorcode, 10) },
          },
        },
      ],
    });
  };

  const handleChange = async (name, value) => {
    try {
      handleSaveNote(value);
      onChange(name, value);
      toast.success(`Note saved successfully.`);
    } catch (err) {
      toast.error(`Error saving note.`);
    }
  };

  return (
    <Column.Group>
      <Column>
        <Control style={{ marginBottom: "1rem" }}>
          <NotesInput
            contentEditable={canEdit}
            disabled={!canEdit && disabled}
            name="notes"
            size="small"
            value={inputs.notes}
            onChange={handleChange}
          />
        </Control>
      </Column>
    </Column.Group>
  );
};

Notes.propTypes = {
  inputs: PropTypes.object,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  handleSave: PropTypes.func,
};

Notes.defaultProps = {
  inputs: {},
  onChange: (e) => e,
  handleSave: () => null,
  disabled: false,
};

export default Notes;
