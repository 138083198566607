import React from "react";
import PropTypes from "prop-types";

import { useQuery } from "@apollo/client";
import { Tag } from "rbx";

import { SINGLE_CASE_QUERY } from "../../../../graphql";

const CaseStatusTag = ({ casenbr }) => {
  const { data: CaseItemData } = useQuery(SINGLE_CASE_QUERY, {
    variables: { where: { casenbr: parseInt(casenbr, 10) } },
  });
  const caseItemStatus = CaseItemData?.caseItem?.Status?.statusdesc;

  return caseItemStatus ? (
    <Tag rounded color="success">
      {caseItemStatus}
    </Tag>
  ) : null;
};

CaseStatusTag.propTypes = {
  casenbr: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default CaseStatusTag;
