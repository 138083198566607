import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Title, Field, Control, Button, Input, Label, Heading } from "rbx";
import { useMutation, useQuery } from "@apollo/client";

import {
  CREATE_CASE_HISTORY_MUTATION,
  ALL_CASE_HISTORY_QUERY,
  SINGLE_CASE_QUERY,
} from "../../../../graphql";

import { useAuth } from "../../../../context";

import {
  getDateEST,
  convertTimeZoneDataBase,
  customToast as toast,
  groupBy,
} from "../../../../utils";

const ExceptionMessageModal = ({ onComplete, exceptions, casenbr }) => {
  const [data, setData] = useState([]);

  const [loading, setLoading] = useState(false);

  const [createCaseHistory] = useMutation(CREATE_CASE_HISTORY_MUTATION);

  const { state: authState } = useAuth();

  const { data: CaseItemData } = useQuery(SINGLE_CASE_QUERY, {
    variables: { where: { casenbr: parseInt(casenbr, 10) } },
  });

  useEffect(() => {
    if (Array.isArray(exceptions)) {
      setData(
        exceptions.map((exception) => ({
          id: exception.ExceptionDefID,
          entity: exception.Entity,
          message: exception.Message,
          requireComment: exception.RequireComment,
          comment: "",
        }))
      );
    }
  }, [exceptions]);

  const onChange = (name, value) => {
    const ex = data.findIndex((item) => item.id === parseInt(name, 10));
    if (ex > -1) {
      const newData = [...data];
      newData[ex].comment = value;
      setData(newData);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const date = convertTimeZoneDataBase(getDateEST());
      await Promise.all(
        data.map((item) =>
          createCaseHistory({
            variables: {
              data: {
                casenbr: parseInt(casenbr, 10),
                eventdesc: `Viewed Exception`,
                otherinfo: [
                  item.message,
                  item.requireComment && `Comment: ${item.comment}`,
                ]
                  .filter(Boolean)
                  .join(" "),
                eventdate: date,
                userid: authState?.user?.userid,
                dateadded: date,
                useridedited: authState?.user?.userid,
                dateedited: date,
                type: "Except",
              },
            },
            refetchQueries: [
              {
                query: ALL_CASE_HISTORY_QUERY,
                variables: {
                  where: { casenbr: { equals: parseInt(casenbr, 10) } },
                },
              },
            ],
          })
        )
      );
      toast.success("Case History Updated");
      onComplete();
    } catch (err) {
      toast.error("Something went wrong, contact support for more information");
    } finally {
      setLoading(false);
    }
  };

  const isDisabled = data.some((item) => item.requireComment && !item.comment);

  const getExceptionFromLabel = (entity) => {
    const caseItem = CaseItemData?.caseItem;
    if (entity === "DR" && caseItem?.doctor)
      return [
        caseItem.doctor.prefix,
        caseItem.doctor.firstname,
        caseItem.doctor.lastname,
        caseItem.doctor.credentials,
        "(DR)",
      ]
        .filter(Boolean)
        .join(" ");
    if (entity === "CO" && caseItem?.company)
      return `${caseItem.company.intname} (CO)`;
    if (entity === "CL" && caseItem?.client)
      return [
        caseItem.client.firstname,
        caseItem.client.lastname,
        caseItem.client.company?.extname &&
          ["at", caseItem.client.company.extname].filter(Boolean).join(" "),
        "(CL)",
      ]
        .filter(Boolean)
        .join(" ");
    return `Case ${casenbr} (CS)`;
  };

  const grouped = groupBy(data, (x) => x.entity);

  return (
    <form id="exception-message-form" onSubmit={handleSubmit}>
      <header className="page-head">
        <div className="page-head-start">
          <Title size={5}>
            {`Exception${data.length > 1 ? "s" : ""} for Case ${casenbr}`}
          </Title>
        </div>
        <div className="page-head-end">
          <Button.Group hasAddons>
            <Button
              disabled={loading}
              size="small"
              type="button"
              onClick={onComplete}
            >
              <span>Cancel</span>
            </Button>
            <Button
              color="primary"
              disabled={isDisabled || loading}
              size="small"
              state={loading ? "loading" : ""}
              type="submit"
            >
              <span>Submit</span>
            </Button>
          </Button.Group>
        </div>
      </header>
      <hr />
      <div>
        {Object.keys(grouped).map((groupKey) => (
          <React.Fragment>
            <Heading>{getExceptionFromLabel(groupKey)}</Heading>
            {grouped[groupKey].map((item) => (
              <Field key={item.id}>
                <Control>
                  <Label>{item.message}</Label>
                  {item.requireComment && (
                    <Input
                      disabled={loading}
                      name={item.id}
                      size="small"
                      value={item.comment}
                      onChange={(e) => onChange(e.target.name, e.target.value)}
                    />
                  )}
                </Control>
              </Field>
            ))}
          </React.Fragment>
        ))}
      </div>
      <hr />
    </form>
  );
};

ExceptionMessageModal.propTypes = {
  onComplete: PropTypes.func,
  exceptions: PropTypes.array,
  casenbr: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
ExceptionMessageModal.defaultProps = {
  onComplete: () => {},
  exceptions: [],
  casenbr: "",
};
export default ExceptionMessageModal;
