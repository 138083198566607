import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Title, Button } from "rbx";
import { useApolloClient, useMutation } from "@apollo/client";
import { format } from "date-fns";
import axios from "axios";
import AddCaseForm from "./AddCaseForm";
import {
  CREATE_CASE_MUTATION,
  SINGLE_CLIENT_QUERY,
  SINGLE_COMPANY_QUERY,
  CREATE_EXAMINEE_MUTATION,
  CREATE_CASE_HISTORY_MUTATION,
  LIST_CASE_HISTORY_QUERY,
  LIST_CASE_CLIENT_QUERY,
  SIMPLE_QUEUES_QUERY,
  LIST_EXAMINEE_CASES_QUERY,
  SINGLE_CASE_TYPE_DOCUMENT_QUERY,
  SINGLE_CC_ADDRESS_QUERY,
} from "../../../graphql";
import {
  customToast as toast,
  getDateEST,
  convertTimeZoneDataBase,
  convertTimeZone,
  getCaseDocumentPath,
} from "../../../utils";
import { useAuth } from "../../../context/AuthContext";

const INITIAL_STATE = {
  allstateName: false,
  clientcode: "", // client
  CompanyGroupID: "",
  IMECompany: "", // company
  IMECompanyLabel: "", // company label
  SelectedAllstateProcessor: "", // Allstate Processor
  officeCode: "", // office
  priority: "Normal    ", // priority
  commitdate: "", // Commit Date
  caseTypeCode: "", // case type
  serviceCode: "", // service code
  Jurisdiction: "", // jurisdiction
  marketercode: "",
  QARep: "",
  schedulercode: "",
  statusCode: 3, // Status,
  claimnbr: "",
  dateofinjury: "",
};

const { REACT_APP_DOCUMENT_HOST } = process.env;

const AddCaseModal = ({ onComplete, defaultExamineeValues }) => {
  const client = useApolloClient();
  const { state: authState } = useAuth();
  const [inputs, setInputs] = useState(INITIAL_STATE);
  const [examineeInputs, setExamineeInputs] = useState({
    chartnbr: null,
    firstname: "",
    lastname: "",
    SSN: "",
    addr1: "",
    addr2: "",
    city: "",
    state: "",
    zip: "",
    county: "",
    employer: "",
    treatingphysician: "",
  });
  const [requiredFields, setRequiredFields] = useState({
    ClaimNbrRqd: false,
    DOIRqd: false,
    EmployerRqd: false,
    JurisdictionRqd: false,
    TreatingPhysicianRqd: false,
    attorneyreqd: false, // Skip
    examineeSSNreqd: false,
    examineeaddrreqd: false,
  });
  const [loading, setLoading] = useState(false);
  const [createCase] = useMutation(CREATE_CASE_MUTATION);
  const [createExaminee] = useMutation(CREATE_EXAMINEE_MUTATION);
  const [createCaseHistory] = useMutation(CREATE_CASE_HISTORY_MUTATION);

  const handleChange = (name, value, examinee = false, allstateName) => {
    if (examinee && typeof examinee === "boolean") {
      setExamineeInputs((prev) => ({ ...prev, [name]: value }));
    } else {
      setInputs((prev) => ({ ...prev, [name]: value }));
    }
  };

  useEffect(() => {
    setExamineeInputs((prev) => ({ ...prev, ...defaultExamineeValues }));
  }, [defaultExamineeValues]);

  // company & marketer & qaReq should change with client
  useEffect(() => {
    const fetchClient = async (clientcode) => {
      const { data: ClientData } = await client.query({
        query: SINGLE_CLIENT_QUERY,
        variables: { where: { clientcode: parseInt(clientcode, 10) } },
      });

      const data = {
        marketercode: ClientData?.client?.marketercode || "",
        QARep: ClientData?.client?.QARep || "",
        priority:
          typeof ClientData?.client?.priority === "string"
            ? ClientData.client.priority.padEnd(10)
            : "",
        IMECompany: ClientData?.client?.companycode || "",
        CompanyGroupID:
          ClientData?.client?.company?.companyToCompanyGroup?.CompanyGroupID ||
          "",
      };

      if (
        ClientData?.client?.companycode &&
        Object.keys(data).some((key) => !data[key])
      ) {
        const { data: CompanyData } = await client.query({
          query: SINGLE_COMPANY_QUERY,
          variables: {
            where: {
              companycode: parseInt(ClientData.client.companycode, 10),
            },
          },
        });

        if (CompanyData?.company) {
          if (!data.QARep) {
            data.QARep = CompanyData?.company?.QARep || "";
          }
          if (!data.marketercode) {
            data.marketercode = CompanyData?.company?.marketercode || "";
          }
          if (!data.priority) {
            data.priority =
              typeof CompanyData?.company?.priority === "string"
                ? CompanyData.company.priority.padEnd(10)
                : "";
          }
        }
      }
      setInputs((prev) => ({
        ...prev,
        ...data,
      }));
    };
    if (inputs.clientcode) {
      fetchClient(inputs.clientcode);
    }
  }, [client, authState, inputs.clientcode]);

  // scheduler should default to current logged in user
  useEffect(() => {
    if (authState?.user?.userid) {
      setInputs((prev) => ({
        ...prev,
        schedulercode: authState?.user?.userid || "",
      }));
    }
  }, [authState]);

  // Get Case Type Service once we get office, case type and service code
  useEffect(() => {
    const fetchCTS = async (cts) => {
      const { data: ctsData } = await client.query({
        query: SINGLE_CASE_TYPE_DOCUMENT_QUERY,
        variables: {
          where: { casetype_servicecode_officecode: cts },
        },
      });
      if (ctsData?.caseTypeService) {
        setRequiredFields({ ...ctsData.caseTypeService });
      }
    };

    if (inputs.officeCode && inputs.caseTypeCode && inputs.serviceCode) {
      const cts = {
        casetype: parseInt(inputs.caseTypeCode, 10),
        officecode: parseInt(inputs.officeCode, 10),
        servicecode: parseInt(inputs.serviceCode, 10),
      };
      fetchCTS(cts);
    }
  }, [client, inputs.officeCode, inputs.caseTypeCode, inputs.serviceCode]);

  const handleCreate = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const objectToCreate = {
        IMECompany: inputs.IMECompany.toString(),
        client: {
          connect: {
            clientcode: parseInt(inputs.clientcode, 10),
          },
        },
        caseType: inputs.caseTypeCode
          ? {
              connect: {
                code: parseInt(inputs.caseTypeCode, 10),
              },
            }
          : null,
        office: inputs.officeCode
          ? {
              connect: {
                officecode: parseInt(inputs.officeCode, 10),
              },
            }
          : null,
        service: inputs.serviceCode
          ? {
              connect: {
                servicecode: parseInt(inputs.serviceCode, 10),
              },
            }
          : null,
        Status: inputs.statusCode
          ? {
              connect: {
                statuscode: parseInt(inputs.statusCode, 10),
              },
            }
          : null,
        marketercode: inputs.marketercode,
        schedulercode: inputs.schedulercode,
        QARep: inputs.QARep,
        commitdate: inputs.commitdate,
        priority: inputs.priority,
        Jurisdiction: inputs.Jurisdiction,
        SelectedAllstateProcessor: inputs.SelectedAllstateProcessor,
        claimnbr:
          typeof inputs.claimnbr === "string" ? inputs.claimnbr.trim() : null,
        dateofinjury: inputs.dateofinjury,
      };
      // Clear Object
      const cleanObject = Object.fromEntries(
        Object.entries(objectToCreate).filter(([_, v]) => v)
      );

      const date = convertTimeZoneDataBase(getDateEST());
      let chartnbrToConnect = examineeInputs.chartnbr;
      if (!chartnbrToConnect) {
        const examineeData = {
          firstname: examineeInputs.firstname,
          lastname: examineeInputs.lastname,
          SSN: examineeInputs.SSN,
          addr1: examineeInputs.addr1,
          addr2: examineeInputs.addr2,
          city: examineeInputs.city,
          state: examineeInputs.state,
          zip: examineeInputs.zip,
          county: examineeInputs.county,
          employer: examineeInputs.employer,
          dateadded: date,
          dateedited: date,
          useridadded: authState?.user?.userid,
          useridedited: authState?.user?.userid,
        };

        if (examineeInputs.treatingphysician) {
          const {
            data: { cCAddress },
          } = await client.query({
            query: SINGLE_CC_ADDRESS_QUERY,
            variables: {
              where: { cccode: parseInt(examineeInputs.treatingphysician, 10) },
            },
          });
          if (cCAddress) {
            examineeData.treatingphysician = `${cCAddress.firstname || ""} ${
              cCAddress.lastname || ""
            }`;
            examineeData.TreatingPhysicianAddr1 = cCAddress.address1 || "";
            examineeData.TreatingPhysicianCity = cCAddress.city || "";
            examineeData.TreatingPhysicianState = cCAddress.state || "";
            examineeData.TreatingPhysicianZip = cCAddress.zip || "";
            examineeData.TreatingPhysicianPhone = cCAddress.phone || "";
            examineeData.TreatingPhysicianPhoneExt =
              cCAddress.phoneextension || "";
            examineeData.TreatingPhysicianEmail = cCAddress.email || "";
            examineeData.TreatingPhysicianFax = cCAddress.fax || "";
          }
        }

        const { data: createExamineeData } = await createExaminee({
          variables: { data: examineeData },
        });

        chartnbrToConnect = createExamineeData?.createExaminee?.chartnbr;
      }
      const data = {
        ...cleanObject,
        examinee: {
          connect: {
            chartnbr: parseInt(chartnbrToConnect, 10),
          },
        },
        TreatingPhysician: examineeInputs.treatingphysician
          ? parseInt(examineeInputs.treatingphysician, 10)
          : null,
        WebNotifyEmail: authState?.user?.email,
        dateadded: date,
        dateedited: date,
        DateReceived: date,
        laststatuschg: date,
        useridadded: authState?.user?.userid,
        useridedited: authState?.user?.userid,
      };
      if (inputs.Status) {
        const { data: queueData } = await client.query({
          query: SIMPLE_QUEUES_QUERY,
          variables: {
            where: { statuscode: { equals: parseInt(inputs.Status, 10) } },
            first: 1,
          },
          fetchPolicy: "network-only",
        });
        if (Array.isArray(queueData?.queues) && queueData?.queues[0]) {
          data.CaseCompletedDate =
            queueData?.queues[0]?.statusdesc === "Complete" ? date : null;
        }
      }
      if (chartnbrToConnect) {
        const { data: createCaseData } = await createCase({
          variables: {
            data,
          },
          refetchQueries: [
            {
              query: LIST_CASE_CLIENT_QUERY,
            },
            {
              query: LIST_EXAMINEE_CASES_QUERY,
              variables: {
                where: {
                  chartnbr: { equals: parseInt(chartnbrToConnect, 10) },
                },
              },
            },
          ],
        });
        await createCaseHistory({
          variables: {
            data: {
              casenbr: createCaseData.createCaseItem.casenbr,
              eventdate: date,
              eventdesc: "Case Created",
              PublishOnWeb: false,
              otherinfo: `Case created from Apollo at ${format(
                convertTimeZone(date),
                "MM/dd/yyyy hh:mm a"
              )} by ${authState?.user?.userid}`,
              userid: authState?.user?.userid,
              useridedited: authState?.user?.userid,
              dateedited: getDateEST(),
              status: parseInt(inputs.Status, 10),
              dateadded: getDateEST(),
            },
          },
          refetchQueries: [
            {
              query: LIST_CASE_HISTORY_QUERY,
              variables: {
                where: {
                  casenbr: { equals: createCaseData.createCaseItem.casenbr },
                },
                orderBy: [{ eventdate: "desc" }],
              },
              fetchPolicy: "cache-first",
            },
          ],
        });
        // This is okay since new cases will always go to IMECentric folder
        const documentPath = getCaseDocumentPath(
          {
            casenbr: createCaseData.createCaseItem.casenbr,
            dateadded: date,
          },
          false
        );

        // ensure path
        await axios.get(
          `${REACT_APP_DOCUMENT_HOST}/verify?path=${encodeURIComponent(
            `IMECentric/${documentPath}`
          )}`
        );

        toast.success("Case created successfully.");
        onComplete({
          casenbr: createCaseData.createCaseItem.casenbr,
        });
      } else {
        throw new Error("Error");
      }
    } catch (err) {
      toast.error("Error creating Case.");
    } finally {
      setLoading(false);
    }
  };
  return (
    <form id="add-case-form" onSubmit={handleCreate}>
      <header className="page-head">
        <div className="page-head-start">
          <Title size={5}>Create Case</Title>
        </div>
        <div className="page-head-end">
          <Button.Group hasAddons>
            <Button
              disabled={loading}
              size="small"
              type="button"
              onClick={onComplete}
            >
              <span>Cancel</span>
            </Button>
            <Button
              color="primary"
              disabled={loading}
              form="add-case-form"
              size="small"
              state={loading ? "loading" : ""}
              type="submit"
            >
              <span>Submit</span>
            </Button>
          </Button.Group>
        </div>
      </header>
      <hr />
      <AddCaseForm
        disabled={loading}
        examineeInputs={examineeInputs}
        inputs={inputs}
        requiredFields={requiredFields}
        setInputs={setInputs}
        onChange={handleChange}
      />
      <hr />
    </form>
  );
};

AddCaseModal.propTypes = {
  onComplete: PropTypes.func.isRequired,
  defaultExamineeValues: PropTypes.object,
};
AddCaseModal.defaultProps = {
  defaultExamineeValues: null,
};

export default AddCaseModal;
