/* eslint-disable import/prefer-default-export */
import { gql } from "@apollo/client";

export const FIND_FIRST_APOLLO_DOCUMENT_QUERY = gql`
  query FIND_FIRST_APOLLO_DOCUMENT_QUERY(
    $where: DocumentsWhereInput
    $orderBy: [DocumentsOrderByInput!]
  ) {
    findFirstDocuments(where: $where, orderBy: $orderBy) {
      DocumentID
      ExternalDocumentID
      ClaimID
      ServiceID
    }
  }
`;

export const LIST_APOLLO_DOCUMENTS_QUERY = gql`
  query findManyDocuments(
    $take: Int = 100
    $orderBy: [DocumentsOrderByInput!]
    $skip: Int
    $where: DocumentsWhereInput
  ) {
    findManyDocuments(
      take: $take
      orderBy: $orderBy
      skip: $skip
      where: $where
    ) {
      DocumentID
      ExternalDocumentID
      ClaimID
      ServiceID
      DocumentLocationID
      DocumentTypeID
      DocumentDescription
      DocumentName
      PublishOnWeb
      CreatedDate
      CreatedBy
      Archive
    }
    aggregateDocuments(where: $where) {
      count {
        DocumentID
      }
    }
  }
`;