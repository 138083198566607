import React from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/client";
import { Button, Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { SINGLE_DOCTOR_QUERY } from "../../../../graphql";

const DoctorLocationInheritFromDoctorButton = ({ doctorcode, setInputs }) => {
  const { data: SingleDoctorData } = useQuery(SINGLE_DOCTOR_QUERY, {
    variables: {
      where: {
        doctorcode: parseInt(doctorcode, 10),
      },
    },
  });

  const handleInheritFromDoctorClick = () => {
    const { addr1, addr2, city, state, zip } = SingleDoctorData.doctor;
    setInputs((prev) => ({ ...prev, addr1, addr2, city, state, zip }));
  };

  const isDisabled = !SingleDoctorData?.doctor;

  return (
    <Button
      color="danger"
      disabled={isDisabled}
      size="small"
      type="button"
      onClick={handleInheritFromDoctorClick}
    >
      <Icon>
        <FontAwesomeIcon icon="copy" />
      </Icon>
      <span>Inherit from Investigator</span>
    </Button>
  );
};

DoctorLocationInheritFromDoctorButton.propTypes = {
  doctorcode: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  setInputs: PropTypes.func.isRequired,
};

export default DoctorLocationInheritFromDoctorButton;
