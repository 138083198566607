import React, { createContext, useState, useContext, useCallback } from "react";
import PropTypes from "prop-types";
import Cookies from "js-cookie";

import { useHistory } from "react-router-dom";

import { useApolloClient, useMutation } from "@apollo/client";

import { toast } from "react-toastify";

import {
  LOGIN_MUTATION,
  CHECK_AUTH_QUERY,
  GET_USER_SECURITIES,
  UPDATE_PASSWORD_MUTATION,
  ALL_USER_GROUP_FUNCTIONS_QUERY,
  // FORGOT_PASSWORD_MUTATION,
  // COMPLETE_PASSWORD_RESET_MUTATION,
} from "../graphql";

import useLocalStorage from "../hooks/useLocalStorage";

export const AuthContext = createContext(null);

const initialState = {
  isLoggedIn: false,
  isLoginPending: false,
  loginError: null,
  user: { userid: null },
};

export const AuthProvider = ({ children }) => {
  const history = useHistory();
  const [state, setState] = useState(initialState);
  const [login] = useMutation(LOGIN_MUTATION);
  const [updatePassword] = useMutation(UPDATE_PASSWORD_MUTATION);
  // const [requestPasswordReset] = useMutation(FORGOT_PASSWORD_MUTATION);
  // const [completePasswordReset] = useMutation(COMPLETE_PASSWORD_RESET_MUTATION);

  const client = useApolloClient();

  const [, , clearRefreshEveryMinute] = useLocalStorage("REFRESH_EVERY_MINUTE");
  const [, , clearCaseTrackerFilters] = useLocalStorage("CASE_TRACKER_FILTERS");

  const handleLogin = async (userid, password) => {
    try {
      setState((prev) => ({ ...prev, isLoginPending: true }));

      const {
        data: { login: loginData = { token: "" } },
      } = await login({
        variables: { input: { userid, password } },
      });
      Cookies.set("token", loginData.token, { sameSite: true });
      const {
        data: { userSecurities },
      } = await client.query({
        query: GET_USER_SECURITIES,
        variables: {
          where: { userid: { equals: userid } },
          distinct: ["userid", "groupcode"],
        },
      });
      const {
        data: { userGroupFunctions },
      } = await client.query({
        query: ALL_USER_GROUP_FUNCTIONS_QUERY,
        variables: {
          where: {
            entityid: { equals: userid },
            entitytype: { equals: "User" },
          },
        },
      });
      setState((prev) => ({
        ...prev,
        user: { ...loginData.user, userSecurities, userGroupFunctions },
        isLoggedIn: true,
        isLoginPending: false,
      }));
    } catch (err) {
      toast.error(err.message);
      setState((prev) => ({
        ...prev,
        loginError: err.message,
        isLoginPending: false,
      }));
    }
  };

  // const handleForgotPassword = async (email) => {
  //   try {
  //     setState((prev) => ({ ...prev, isLoginPending: true }));

  //     await requestPasswordReset({
  //       variables: { input: { email, origin: window.origin } },
  //     });

  //     toast.success(`Password reset request sent. Please check your email.`);
  //   } catch (err) {
  //     toast.error(err.message);
  //     setState((prev) => ({ ...prev, loginError: err.message }));
  //   } finally {
  //     setState((prev) => ({ ...prev, isLoginPending: false }));
  //   }
  // };

  // const handleCompletePasswordReset = async (
  //   password,
  //   confirmPassword,
  //   resetPasswordToken
  // ) => {
  //   try {
  //     setState((prev) => ({ ...prev, isLoginPending: true }));
  //     if (password === confirmPassword) {
  //       await completePasswordReset({
  //         variables: { input: { password, resetPasswordToken } },
  //       });

  //       toast.success("Password reset completed. Please login.");
  //       window.location.href = window.origin;
  //     } else {
  //       throw new Error("Password and Confirm Password must be the same");
  //     }
  //   } catch (err) {
  //     toast.error(err.message);
  //     setState((prev) => ({ ...prev, loginError: err.message }));
  //   } finally {
  //     setState((prev) => ({ ...prev, isLoginPending: false }));
  //   }
  // };

  const handleLogout = useCallback(() => {
    const clearLocalStorage = () => {
      clearRefreshEveryMinute();
      clearCaseTrackerFilters();
    };

    Cookies.remove("token");
    clearLocalStorage();
    setState((prev) => ({ ...prev, ...initialState }));
    history.push("/");
  }, [history, clearCaseTrackerFilters, clearRefreshEveryMinute]);

  const handleCheckAuth = useCallback(async () => {
    try {
      const {
        data: { checkAuth = false },
      } = await client.query({
        query: CHECK_AUTH_QUERY,
        fetchPolicy: "network-only",
      });

      if (!checkAuth) {
        throw new Error(`Not authorized.`);
      }
      if (!!checkAuth.userid !== state.isLoggedIn) {
        const {
          data: { userSecurities },
        } = await client.query({
          query: GET_USER_SECURITIES,
          variables: {
            where: { userid: { equals: checkAuth.userid } },
            distinct: ["userid", "groupcode"],
          },
          fetchPolicy: "network-only",
        });
        const {
          data: { userGroupFunctions },
        } = await client.query({
          query: ALL_USER_GROUP_FUNCTIONS_QUERY,
          variables: {
            where: {
              entityid: { equals: checkAuth.userid },
              entitytype: { equals: "User" },
            },
          },
        });
        setState((prev) => ({
          ...prev,
          isLoggedIn: !!checkAuth,
          user: {
            userid: checkAuth.userid,
            email: checkAuth.email,
            resetPassword: checkAuth.resetPassword,
            usertype: checkAuth.usertype,
            userSecurities,
            userGroupFunctions,
          },
        }));
      }
    } catch (err) {
      if (state.isLoggedIn) {
        handleLogout();
      }
    }
  }, [client, handleLogout, state.isLoggedIn]);

  const handleUpdatePassword = useCallback(
    async (Password, ConfirmPassword, callback = () => null) => {
      try {
        await updatePassword({
          variables: {
            data: {
              Password,
              ConfirmPassword,
              resetPassword: false,
            },
            where: {
              userid_usertype: {
                userid: state.user.userid,
                usertype: state.user.usertype,
              },
            },
          },
        });
        setState((prev) => ({
          ...prev,
          user: {
            ...prev.user,
            resetPassword: false,
          },
        }));
        callback();
      } catch (err) {
        toast.error("Error updating password");
      }
    },
    [state, updatePassword]
  );

  return (
    <AuthContext.Provider
      value={{
        state,
        handleLogin,
        handleLogout,
        handleCheckAuth,
        handleUpdatePassword,
        // handleForgotPassword,
        // handleCompletePasswordReset,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useAuth = () => useContext(AuthContext);

export default AuthProvider;
