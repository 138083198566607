import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useLazyQuery } from "@apollo/client";
import { Tag } from "rbx";
import { SINGLE_QUEUE_QUERY } from "../../../../graphql";

const CaseTagStatus = ({ statuscode }) => {
  const [state, setState] = useState("");

  const [getQueue, { data: QueueData }] = useLazyQuery(SINGLE_QUEUE_QUERY);

  useEffect(() => {
    if (statuscode) {
      getQueue({
        variables: {
          where: {
            statuscode: parseInt(statuscode, 10),
          },
        },
      });
    } else {
      setState("");
    }
  }, [statuscode, getQueue]);

  const statusDesc = QueueData?.queue?.statusdesc;

  useEffect(() => {
    if (statusDesc) {
      setState(statusDesc);
    }
  }, [statusDesc]);

  return (
    state && (
      <Tag
        rounded
        color="success"
        style={{ alignSelf: "center", marginRight: "1rem" }}
      >
        {state}
      </Tag>
    )
  );
};

CaseTagStatus.propTypes = {
  statuscode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
CaseTagStatus.defaultProps = {
  statuscode: "",
};
export default CaseTagStatus;
