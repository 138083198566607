import { gql } from "@apollo/client";

export const ALL_QUEUES_QUERY = gql`
  query queues(
    $take: Int
    $orderBy: [QueueOrderByInput!]
    $skip: Int
    $where: QueueWhereInput
  ) {
    queues(take: $take, orderBy: $orderBy, skip: $skip, where: $where) {
      statuscode
      statusdesc
      type
      shortdesc
      displayorder
      dateadded
      dateedited
      useridadded
      useridedited
      status
    }
  }
`;

export const SINGLE_QUEUE_QUERY = gql`
  query SINGLE_QUEUE_QUERY($where: QueueWhereUniqueInput!) {
    queue(where: $where) {
      statuscode
      statusdesc
      type
      shortdesc
      displayorder
      status
    }
  }
`;

export const FIND_FIRST_QUEUE_QUERY = gql`
  query FIND_FIRST_QUEUE_QUERY($where: QueueWhereInput) {
    findFirstQueue(where: $where) {
      statuscode
      statusdesc
    }
  }
`;

export const LIST_QUEUES_QUERY = gql`
  query LIST_QUEUES_QUERY(
    $take: Int
    $orderBy: [QueueOrderByInput!]
    $skip: Int
    $where: QueueWhereInput
  ) {
    queues(take: $take, orderBy: $orderBy, skip: $skip, where: $where) {
      statuscode
      statusdesc
      type
      shortdesc
      displayorder
      dateadded
      dateedited
      useridadded
      useridedited
      status
      subtype
      functioncode
      WebGUID
      InvestigatorWebView
      IsCancel
      SurvQueue
    }
    aggregateQueue(where: $where) {
      count {
        statuscode
      }
    }
  }
`;

export const SIMPLE_QUEUES_QUERY = gql`
  query queues(
    $take: Int
    $orderBy: [QueueOrderByInput!]
    $skip: Int
    $where: QueueWhereInput
  ) {
    queues(take: $take, orderBy: $orderBy, skip: $skip, where: $where) {
      statuscode
      statusdesc
    }
  }
`;
