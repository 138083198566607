import React from "react";
import PropTypes from "prop-types";
import { Control, Label, Select } from "rbx";

const LanguageSelect = ({
  value,
  name,
  label,
  size,
  onChange,
  disabled,
  getValue,
  setValue,
  required,
  showAllOption,
  showAll,
}) => {
  const langData = [
    {
      lang: "English",
    },
    {
      lang: "French",
    },
    {
      lang: "Italian",
    },
    {
      lang: "German",
    },
    {
      lang: "Portuguese",
    },
    {
      lang: "Spanish",
    },
    {
      lang: "Arabic",
    },
    {
      lang: "Czech",
    },
    {
      lang: "Chinese (s)",
    },
    {
      lang: "Chinese (t)",
    },
    {
      lang: "Danish",
    },
    {
      lang: "Greek",
    },
    {
      lang: "Hebrew",
    },
    {
      lang: "Hindi",
    },
    {
      lang: "Hungarian",
    },
    {
      lang: "Persian",
    },
    {
      lang: "Japanese",
    },
    {
      lang: "Korean",
    },
    {
      lang: "Dutch",
    },
    {
      lang: "Norwegian",
    },
    {
      lang: "Polish",
    },
    {
      lang: "Romanian",
    },
    {
      lang: "Russian",
    },

    {
      lang: "Swedish",
    },
    {
      lang: "Turkish",
    },
    {
      lang: "Thai",
    },
    {
      lang: "Ukrainian",
    },
    {
      lang: "Urdu",
    },
  ];

  const handleChange = (e) => {
    onChange(e.target.name, setValue(e.target.value || ""));
  };

  return (
    <Control>
      <Label>{label}</Label>
      <Select.Container
        fullwidth
        size={size}
        state={Array.isArray(langData) ? "" : "loading"}
      >
        <Select
          disabled={disabled}
          name={name}
          required={required}
          value={getValue(value)}
          onChange={handleChange}
        >
          {showAllOption ? (
            <Select.Option value="">All</Select.Option>
          ) : (
            <Select.Option value="" />
          )}
          {Array.isArray(langData) &&
            langData.map((type) => (
              <Select.Option key={type.lang} value={type.lang}>
                {type.lang}
              </Select.Option>
            ))}
        </Select>
      </Select.Container>
    </Control>
  );
};

LanguageSelect.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
    PropTypes.object,
  ]),
  name: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  getValue: PropTypes.func,
  setValue: PropTypes.func,
  showAllOption: PropTypes.bool,
  showAll: PropTypes.bool,
};

LanguageSelect.defaultProps = {
  showAll: null,
  value: "",
  name: "",
  label: "Language",
  size: "small",
  showAllOption: false,
  onChange: () => null,
  disabled: false,
  required: false,
  getValue: (x) => x,
  setValue: (x) => x,
};

export default LanguageSelect;
