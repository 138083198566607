import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useLazyQuery, useMutation } from "@apollo/client";
import { Table, Button } from "rbx";
import DraggableTable from "../../../../components/DraggableTable";
import {
  ALL_SERVICE_QUEUES_QUERY,
  UPDATE_SERVICE_QUEUE_MUTATION,
  // CREATE_AUDIT_TRAIL_MUTATION,
} from "../../../../graphql";
import Loader from "../../../../components/Loader";
import { useModal, useAuth } from "../../../../context";
import { ServiceQueueModal } from "../../components";
import { SERVICE_QUEUES_COLUMNS } from "./columns";
import { getDateEST, convertTimeZoneDataBase } from "../../../../utils";

// eslint-disable-next-line no-extend-native
Array.prototype.move = function move(from, to) {
  this.splice(to, 0, this.splice(from, 1)[0]);
  return this;
};

const ServiceQueuesPage = ({ servicecode, onDragEnd, onRowClick }) => {
  const { setModalOpen } = useModal();
  const { state: authState } = useAuth();
  const [items, setItems] = useState([]);
  const [getServiceQueues, { data: serviceQueueData, loading }] = useLazyQuery(
    ALL_SERVICE_QUEUES_QUERY
  );
  const [updateServiceQueue] = useMutation(UPDATE_SERVICE_QUEUE_MUTATION);
  // const [createAuditTrail] = useMutation(CREATE_AUDIT_TRAIL_MUTATION);

  useEffect(() => {
    getServiceQueues({
      variables: {
        where: {
          servicecode: { equals: parseInt(servicecode, 10) },
        },
        orderBy: [{ queueorder: "asc" }, { nextstatus: "asc" }],
      },
    });
  }, [getServiceQueues, servicecode]);

  useEffect(() => {
    if (Array.isArray(serviceQueueData?.serviceQueues)) {
      const { serviceQueues } = serviceQueueData;
      setItems(
        serviceQueues.map((queue) => ({
          id: `${queue.servicecode}_${queue.statuscode}_${queue.queueorder}`,
          content: queue,
        }))
      );
    }
  }, [serviceQueueData]);

  const handleDragEnd = async (result) => {
    const { source, destination } = result;
    // const { content: itemToMoveContent } = items[source.index];
    if (source && destination) {
      const date = convertTimeZoneDataBase(getDateEST());
      const newItems = [...items];
      newItems.move(source.index, destination.index);
      setItems(
        newItems.map((item, index) => ({
          ...item,
          content: {
            ...item.content,
            nextstatus: newItems[index + 1]
              ? newItems[index + 1].content.statuscode
              : "0",
            NextStatus: newItems[index + 1]
              ? {
                  statuscode: newItems[index + 1].content.queue?.statuscode,
                  statusdesc: newItems[index + 1].content.queue?.statusdesc,
                }
              : null,
            queueorder: index + 1,
          },
        }))
      );
      const itemsToSave = newItems.map((item, index) => ({
        oldqueueorder: parseInt(item.content.queueorder, 10),
        nextstatus: newItems[index + 1]
          ? newItems[index + 1].content.statuscode
          : "0",
        servicecode: parseInt(item.content.servicecode, 10),
        queueorder: index + 1,
        statuscode: item.content.statuscode,
      }));
      await Promise.all(
        itemsToSave.map(async (serviceQueue) => {
          await updateServiceQueue({
            variables: {
              where: {
                servicecode_queueorder_statuscode: {
                  servicecode: serviceQueue.servicecode,
                  queueorder: serviceQueue.oldqueueorder,
                  statuscode: serviceQueue.statuscode,
                },
              },
              data: {
                queueorder: { set: serviceQueue.queueorder },
                nextstatus: { set: serviceQueue.nextstatus },
                dateedited: { set: date },
                useridedited: { set: authState?.user?.userid },
              },
            },
          });
        })
      );
      // await createAuditTrail({
      //   variables: {
      //     data: {
      //       tablename: "tblServiceQueue",
      //       entityid: `${itemToMoveContent.servicecode}_${itemToMoveContent.queueorder}_${itemToMoveContent.statuscode}`,
      //       type: "Update",
      //       description: `Updated Service Queue Order - Moved '${
      //         itemToMoveContent.statusdesc
      //       }' to '${destination.index + 1}'`,
      //       useridadded: authState.user.userid,
      //       dateadded: date,
      //     },
      //   },
      //   refetchQueries: [
      //     {
      //       query: ALL_SERVICE_QUEUES_QUERY,
      //       variables: {
      //         where: {
      //           servicecode: { equals: parseInt(servicecode, 10) },
      //         },
      //         orderBy: [{ queueorder: "asc" }, { nextstatus: "asc" }],
      //       },
      //     },
      //   ],
      // });
    }
  };

  const handleAddQueue = (e) => {
    e.preventDefault();
    const { content: lastItemContent } = items.length
      ? items[items.length - 1]
      : {};
    setModalOpen(
      true,
      <ServiceQueueModal
        lastItem={lastItemContent}
        queueorder={lastItemContent ? lastItemContent.queueorder + 1 : 1}
        servicecode={servicecode}
        onComplete={() => setModalOpen(false)}
      />
    );
  };

  const handleRowClick = (row, index) => {
    let lastItemContent = null;
    if (index > 0) {
      lastItemContent = items[index - 1].content;
    }
    setModalOpen(
      true,
      <ServiceQueueModal
        inputs={row.content}
        lastItem={lastItemContent}
        servicecode={servicecode}
        onComplete={() => setModalOpen(false)}
      />
    );
  };

  if (loading) return <Loader />;

  return (
    <div>
      <header className="page-head">
        <div className="page-head-start">
          <Button
            color="primary"
            size="small"
            type="button"
            onClick={handleAddQueue}
          >
            Add New Queue
          </Button>
        </div>
      </header>
      <div className="data-table-container" style={{ marginBottom: 0 }}>
        <Table bordered fullwidth hoverable narrow>
          <Table.Head>
            <Table.Row>
              {SERVICE_QUEUES_COLUMNS.map(({ Header, width, id }) => (
                <Table.Heading key={id} style={{ width: width || "20%" }}>
                  {Header}
                </Table.Heading>
              ))}
            </Table.Row>
          </Table.Head>
        </Table>
      </div>
      <DraggableTable
        columns={SERVICE_QUEUES_COLUMNS}
        items={items}
        name="serviceQueues"
        style={{}}
        title="Queues"
        onDragEnd={handleDragEnd}
        onRowClick={handleRowClick}
      />
    </div>
  );
};

ServiceQueuesPage.propTypes = {
  servicecode: PropTypes.string,
  onRowClick: PropTypes.func,
  onDragEnd: PropTypes.func,
};

ServiceQueuesPage.defaultProps = {
  servicecode: "",
  onRowClick: () => null,
  onDragEnd: () => null,
};

export default ServiceQueuesPage;
