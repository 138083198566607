import { gql } from "@apollo/client";

export const SINGLE_ACCT_HEADER_QUERY = gql`
  query acctHeader {
    acctHeader {
      documentnbr
      casenbr
      acctDetails {
        documentnbr
        date
        longdesc
        unit
        extendedamount
      }
    }
  }
`;

export const FIND_FIRST_ACCT_HEADER_QUERY = gql`
  query FIND_FIRST_ACCT_HEADER_QUERY($where: AcctHeaderWhereInput) {
    findFirstAcctHeader(where: $where) {
      documentnbr
      casenbr
      acctDetails(orderBy: { date: asc }) {
        documentnbr
        date
        longdesc
        unit
        extendedamount
      }
    }
  }
`;
