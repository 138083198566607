import React from "react";
import PropTypes from "prop-types";
import { Control, Label, Select } from "rbx";

const ReportTypeSelect = ({
  value,
  name,
  label,
  size,
  onChange,
  disabled,
  getValue,
  setValue,
  noValueLabel,
  showBlank,
}) => {
  const options = [
    { value: "Addendum", label: "Addendum" },
    { value: "File Review", label: "File Review" },
    { value: "Report", label: "Report" },
  ];

  const handleChange = (e) => {
    onChange(e.target.name, setValue(e.target.value));
  };

  return (
    <Control expanded>
      <Label>{label}</Label>
      <Select.Container
        fullwidth
        size={size}
        state={Array.isArray(options) ? "" : "loading"}
      >
        <Select
          disabled={disabled}
          name={name}
          value={getValue(value)}
          onChange={handleChange}
        >
          {Array.isArray(options) &&
            options.map((item) => (
              <Select.Option key={item.value} value={item.value}>
                {item.label}
              </Select.Option>
            ))}
        </Select>
      </Select.Container>
    </Control>
  );
};

ReportTypeSelect.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.number,
  ]),
  name: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  getValue: PropTypes.func,
  setValue: PropTypes.func,
  noValueLabel: PropTypes.string,
  showBlank: PropTypes.bool,
};

ReportTypeSelect.defaultProps = {
  value: "",
  name: "",
  label: "Type",
  size: "small",
  noValueLabel: "All",
  onChange: () => null,
  disabled: false,
  getValue: (x) => x,
  setValue: (x) => x,
  showBlank: true,
};

export default ReportTypeSelect;
