/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Control, Label } from "rbx";
import { useApolloClient } from "@apollo/client";
import { AsyncPaginate } from "react-select-async-paginate";

import {
  RATS_EMPLOYER_QUERY,
  RATS_SINGLE_EMPLOYER_QUERY,
} from "../../../../graphql";

const QUERY_LIMIT = 50;

const DEFAULT_STATE = {
  EMtaxidFEIN: "",
  EMname: "",
  EMaddress1: "",
  EMaddress2: "",
  EMcity: "",
  EMstate: "",
  EMzip: "",
  EMphone: "",
};

const RatsEmployerSelect = ({
  name,
  value: initialValue,
  label,
  onChange,
  disabled,
  getValue,
  setValue,
  required,
  onSelectedAddressChange,
  onAddressInputsChange,
}) => {
  const client = useApolloClient();

  const [selected, setSelected] = useState(null);
  const [defaultOptions, setDefaultOptions] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState([]);
  const [inputs, setInputs] = useState({ ...DEFAULT_STATE });

  useEffect(() => {
    onSelectedAddressChange(selectedAddress);
  }, [onSelectedAddressChange, selectedAddress]);

  useEffect(() => {
    onAddressInputsChange(inputs);
  }, [onAddressInputsChange, inputs]);

  const promiseOptions = async (inputValue, { length }) => {
    const where = {
      EMname: {
        startsWith: inputValue,
      },
    };

    const { data: ratsEmployerData } = await client.query({
      query: RATS_EMPLOYER_QUERY,
      variables: {
        take: QUERY_LIMIT,
        where,
        orderBy: [{ EMname: "asc" }],
        skip: length,
      },
    });

    const employers = ratsEmployerData?.employers;

    const result = Array.isArray(employers)
      ? employers.map((emp) => ({
          value: emp.PK_EMid,
          label: emp.EMname,
        }))
      : [];
    setDefaultOptions(result);

    return {
      options: result,
      hasMore: result.length === QUERY_LIMIT,
    };
  };

  const handleChange = ({ value: newValue }) => {
    onChange(name, setValue(newValue || ""));
  };

  useEffect(() => {
    const getEmployer = async () => {
      if (initialValue) {
        const { data: employerData } = await client.query({
          query: RATS_SINGLE_EMPLOYER_QUERY,
          variables: {
            where: { PK_EMid: getValue(initialValue) },
          },
        });

        const employer = employerData?.employer;

        if (employer) {
          setSelected({
            value: employer.PK_EMid,
            label: employer.EMname,
          });

          const address = [
            `${employer.EMname}`,
            "",
            employer.EMaddress1,
            employer.EMaddress2,
            `${employer.EMcity}, ${employer.EMstate} ${employer.EMzip}`,
            employer.EMphone,
          ];

          setSelectedAddress(address);

          setInputs((prev) => ({
            ...prev,
            EMtaxidFEIN: employer.EMtaxidFEIN,
            EMname: employer.EMname,
            EMaddress1: employer.EMaddress1,
            EMaddress2: employer.EMaddress2,
            EMcity: employer.EMcity,
            EMstate: employer.EMstate,
            EMzip: employer.EMzip,
            EMphone: employer.EMphone,
          }));
        }
      } else {
        setSelected(null);
        setSelectedAddress([]);
        setInputs({ ...DEFAULT_STATE });
      }
    };
    getEmployer();
  }, [initialValue, getValue, client]);

  useEffect(() => {
    if (selected) {
      const currOptions = [...defaultOptions];
      if (!currOptions.some((opt) => opt.value === selected.value)) {
        setDefaultOptions((prev) => [selected, ...prev]);
      }
    }
  }, [selected, defaultOptions]);

  const indicateRequired =
    required &&
    !disabled &&
    (!initialValue ||
      initialValue === "" ||
      initialValue === "0" ||
      initialValue === 0);

  return (
    <Control expanded style={{ flexGrow: 1, marginRight: "0.313rem" }}>
      <Label>{label}</Label>
      <AsyncPaginate
        cacheOptions
        debounceTimeout={300}
        defaultOptions={defaultOptions}
        isDisabled={disabled}
        loadOptions={promiseOptions}
        menuPortalTarget={document.body}
        placeholder="Start typing..."
        required={required}
        styles={{
          menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
          indicatorsContainer: (provided) => ({
            ...provided,
            height: "inherit",
          }),
          indicatorSeparator: (provided) => ({
            ...provided,
            display: "none",
          }),
          container: (provided) => ({
            fontSize: 13,
          }),
          singleValue: (provided) => ({
            ...provided,
            top: "38%",
          }),
          control: (provided) => ({
            ...provided,
            minHeight: 31,
            height: 31,
            width: "100%",
            minWidth: 180,
            border: indicateRequired ? "1px #e63362 solid" : "",
            boxShadow: indicateRequired
              ? "0 0 0 0.125em rgba(230, 51, 98, 0.25)"
              : "",
            "&:hover": {
              borderColor: "hsl(0, 0%, 90%)",
              borderRadius: 4,
              cursor: disabled ? "not-allowed" : "pointer",
            },
          }),
          placeholder: (provided) => ({ ...provided, top: "38%" }),
          menu: (provided) => ({
            ...provided,
            fontSize: 12,
            zIndex: 999,
          }),
        }}
        value={selected}
        onChange={handleChange}
      />
    </Control>
  );
};

RatsEmployerSelect.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  getValue: PropTypes.func,
  setValue: PropTypes.func,
  required: PropTypes.bool,
  onSelectedAddressChange: PropTypes.func,
  onAddressInputsChange: PropTypes.func,
};

RatsEmployerSelect.defaultProps = {
  name: "",
  label: "",
  value: "",
  onChange: () => null,
  disabled: false,
  getValue: (x) => x,
  setValue: (x) => x,
  required: false,
  onSelectedAddressChange: () => null,
  onAddressInputsChange: () => null,
};

export default RatsEmployerSelect;
