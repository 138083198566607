import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";

import ReactSelectAsync from "react-select/async";

import { Label, Control } from "rbx";
import { useQuery } from "@apollo/client";
import { LIST_PROBLEMS_QUERY } from "../../graphql";

const ProblemMultiSelect = ({
  name,
  inputs,
  problemsState,
  value,
  label,
  onChange,
  fullWidth,
  disabled,
  readOnly,
  required,
  getValue,
}) => {
  const { data: problemsData } = useQuery(LIST_PROBLEMS_QUERY, {
    variables: {
      where: {
        status: {
          equals: "Active",
        },
        AND: [
          {
            description: {
              not: {
                equals: "1st Party", // request from business to exclude
              },
            },
          },
          {
            description: {
              not: {
                equals: "3rd Party", // request from business to exclude
              },
            },
          },
        ],
      },
      orderBy: [
        {
          description: "asc",
        },
      ],
    },
  });

  const handleProblemsList = useCallback(() => {
    if (/^investigations$/gi.test(inputs.product)) {
      return problemsData?.problems
        .filter((problem) => problem.problemcode !== 176)
        .map((item) => ({
          value: item.problemcode,
          name,
          label: item.description,
        }));
    }

    return problemsData?.problems?.map((item) => ({
      value: item.problemcode,
      name,
      label: item.description,
    }));
  }, [problemsData, inputs.product, name]);

  const problems = useMemo(() => handleProblemsList(), [handleProblemsList]);

  const loadingOptions = useCallback(
    async (inputValue = "") =>
      new Promise((resolve, reject) => {
        try {
          if (Array.isArray(problems) && problems.length > 0) {
            const arrayFiltered = problems.filter((i) =>
              i.label.toLowerCase()?.includes(inputValue?.toLowerCase() || "")
            );
            resolve(arrayFiltered);
          }
        } catch (error) {
          reject(error);
        }
      }),
    [problems]
  );

  const filteredProblems = useCallback(() => {
    if (/^investigations$/gi.test(inputs.product) && problems?.length) {
      // Grabs the Unknown-TBD option and returns a { value, name, label } object to be passed in to the top of our Physical Problems list.
      const unknownTBD = problemsData?.problems
        .filter((problem) => problem.problemcode === 176)
        .map((item) => ({
          value: item.problemcode,
          name,
          label: item.description,
        }));
      return [...unknownTBD, ...problems];
    }

    return problems;
  }, [problems, inputs.product, name, problemsData]);

  const problemOptions = filteredProblems();

  return (
    <Control expanded>
      <Label>{label}</Label>
      <ReactSelectAsync
        cacheOptions
        isMulti
        defaultOptions={problemOptions}
        isDisabled={disabled}
        isLoading={!Array.isArray(problems)}
        isSearchable={!readOnly}
        loadOptions={loadingOptions}
        menuIsOpen={readOnly ? false : undefined}
        menuPlacement="auto"
        menuPortalTarget={document.body}
        placeholder="Start typing..."
        styles={{
          menuPortal: (provided) => ({
            ...provided,
            zIndex: 9999,
          }),
          indicatorsContainer: (provided) => ({
            ...provided,
            height: "inherit",
          }),
          indicatorSeparator: (provided) => ({
            ...provided,
            display: "none",
          }),
          container: (provided) => ({
            fontSize: 13,
          }),
          singleValue: (provided) => ({
            ...provided,
            top: "38%",
          }),
          control: (provided) => ({
            ...provided,
            width: "100%",
            minWidth: 180,
            minHeight: 30,
            height:
              Array.isArray(getValue(value)) && value.length > 0 ? "auto" : 30,
            border:
              required && Array.isArray(getValue(value)) && value.length === 0
                ? "1px #e63362 solid"
                : "",
            borderRadius: "4px",
            boxShadow:
              required && Array.isArray(getValue(value)) && value.length === 0
                ? "0 0 0 0.125em rgba(230, 51, 98, 0.25)"
                : "",
            "&:hover": {
              borderColor: "hsl(0, 0%, 90%)",
              borderRadius: 4,
              cursor: disabled ? "not-allowed" : "pointer",
            },
          }),
          placeholder: (provided) => ({ ...provided, top: "38%" }),
          menu: (provided) => ({
            ...provided,
            fontSize: 12,
            zIndex: 999,
          }),
        }}
        value={
          Array.isArray(problemOptions) &&
          problemOptions.filter((x) =>
            getValue(value).find((i) => i.value === x.value)
          )
        }
        onChange={onChange}
      />
    </Control>
  );
};

ProblemMultiSelect.propTypes = {
  name: PropTypes.string,
  inputs: PropTypes.object.isRequired,
  problemsState: PropTypes.object.isRequired,
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.number,
    PropTypes.array,
  ]),
  onChange: PropTypes.func,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  getValue: PropTypes.func,
};

ProblemMultiSelect.defaultProps = {
  name: "",
  label: "",
  value: "",
  readOnly: false,
  getValue: (x) => x,
  onChange: () => null,
  fullWidth: false,
  disabled: false,
  required: false,
};

export default ProblemMultiSelect;
