import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { Title } from "rbx";
import { useQuery } from "@apollo/client";

import Table from "./Table";
import "../DataTable/DataTable.scss";

const FlatDataTable = ({
  name,
  columns,
  query,
  where,
  orderBy,
  onCellClick,
  fetchPolicy,
  useOriginalOrderBy,
}) => {
  const [orderByMulti, setOrderByMulti] = useState(orderBy);

  const handleChangeSort = useCallback(async (sortBy) => {
    setOrderByMulti(sortBy);
  }, []);

  const { data } = useQuery(query, {
    variables: {
      where,
      orderBy: !useOriginalOrderBy
        ? orderByMulti.map((x) => ({ [x.id]: x.desc ? "desc" : "asc" }))
        : orderBy,
    },
    fetchPolicy,
  });

  if (!Array.isArray(data?.[name])) {
    return <h2>Loading...</h2>;
  }

  if (Array.isArray(data?.[name]) && data?.[name]?.length === 0) {
    return (
      <Title subtitle style={{ color: "gray" }}>
        Nothing here...
      </Title>
    );
  }

  return (
    <div>
      <React.Fragment>
        <Table
          columns={columns}
          data={data?.[name]}
          initialSortBy={orderByMulti}
          onCellClick={onCellClick}
          onChangeSort={handleChangeSort}
        />
      </React.Fragment>
    </div>
  );
};

FlatDataTable.propTypes = {
  name: PropTypes.string.isRequired,
  columns: PropTypes.array.isRequired,
  query: PropTypes.object.isRequired,
  where: PropTypes.object,
  orderBy: PropTypes.array,
  onCellClick: PropTypes.func,
  fetchPolicy: PropTypes.string,
  useOriginalOrderBy: PropTypes.bool,
};

FlatDataTable.defaultProps = {
  onCellClick: () => null,
  where: {},
  orderBy: [],
  fetchPolicy: "cache-and-network",
  useOriginalOrderBy: false,
};

export default FlatDataTable;
