import React from "react";
import PropTypes from "prop-types";

import { Box, Field, Control, Button, Label } from "rbx";
import CompanySelect from "../../../../components/CompanySelect";

import StateSelect from "../../../../components/StateSelect";
import FilterInput from "../../../../components/FilterInput";
// import StatusSelect from "../../../../components/StatusSelect";

const ClientListFilters = ({ filters, onChange, onReset }) => (
  <Box>
    <div className="list-filters">
      <Field>
        <CompanySelect
          getValue={(x) => x?.equals || ""}
          label="Company"
          name="companycode"
          setValue={(x) => (x ? { equals: parseInt(x, 10) } : "")}
          value={filters.companycode}
          onChange={onChange}
        />
      </Field>
      <Field>
        <Control>
          <Label size="xs">Address</Label>
          <FilterInput
            getValue={(x) => x?.contains || ""}
            maxLength={50}
            name="addr1"
            placeholder="Internal Name"
            setValue={(x) => (x ? { contains: x } : "")}
            size="small"
            value={filters.addr1 || ""}
            onChange={onChange}
          />
        </Control>
      </Field>
      <Field kind="group">
        <Control expanded>
          <Label size="xs">City</Label>
          <FilterInput
            getValue={(x) => x?.contains || ""}
            maxLength={50}
            name="city"
            placeholder="City"
            setValue={(x) => (x ? { contains: x } : "")}
            size="small"
            value={filters.city || ""}
            onChange={onChange}
          />
        </Control>
      </Field>
      <Field>
        <Control>
          <StateSelect
            getValue={(x) => x?.equals || ""}
            id="Statecode"
            label="State"
            name="state"
            setValue={(x) => (x ? { equals: x } : "")}
            size="small"
            value={filters.state}
            onChange={onChange}
          />
        </Control>
      </Field>
      <Field>
        <Control>
          <Label size="xs">PC/Zip</Label>
          <FilterInput
            getValue={(x) => x?.contains || ""}
            maxLength={10}
            name="zip"
            placeholder="PC/Zip"
            setValue={(x) => (x ? { contains: x } : "")}
            size="small"
            style={{ width: 90 }}
            value={filters.zip || ""}
            onChange={onChange}
          />
        </Control>
      </Field>
      {/* <Field>
        <Control>
          <StatusSelect
            showBlank
            getValue={(x) => x?.equals || ""}
            id="status"
            label="Status"
            name="status"
            setValue={(x) => (x ? { equals: x } : "")}
            size="small"
            value={filters.status}
            onChange={onChange}
          />
        </Control>
      </Field> */}
      {/* <Field>
        <Control>
          <Label size="xs">Client Code</Label>
          <FilterInput
            getValue={(x) => x?.contains || ""}
            maxLength={10}
            name="companycode"
            placeholder="Code"
            setValue={(x) => (x ? { contains: x } : "")}
            size="small"
            style={{ width: 90 }}
            value={filters.clientcode || ""}
            onChange={onChange}
          />
        </Control>
      </Field> */}
      <Field>
        <Control>
          <Label>&nbsp;</Label>
          <Button color="danger" size="small" onClick={onReset}>
            Reset
          </Button>
        </Control>
      </Field>
    </div>
  </Box>
);

ClientListFilters.propTypes = {
  filters: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
};

export default ClientListFilters;
