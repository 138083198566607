import { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { format } from "date-fns";
import useQueryParams from "./useQueryParams";
import { CLAIM_CLAIMANT_LOOKUP_QUERY } from "../graphql";
import { convertTimeZone } from "../utils";
import useReferrer from "./useReferrer";

export default function useClaim() {
  const [state, setState] = useState(null);
  const [errors, setErrors] = useState([]);
  const [claimId] = useQueryParams("claimId", "", true);
  const [claimantId] = useQueryParams("claimantId", "", true);
  const [sourceId] = useQueryParams("sourceId", "", true);
  const [rrId] = useQueryParams("rrId", "");

  const referrer = useReferrer();

  const [claimClaimantLookup, { data: ClaimClaimantLookupData }] = useLazyQuery(
    CLAIM_CLAIMANT_LOOKUP_QUERY
  );

  useEffect(() => {
    const clientName = referrer?.CompanyName;

    if (claimId && claimantId && sourceId && !rrId) {
      claimClaimantLookup({
        variables: { where: { claimId, claimantId, sourceId, clientName } },
      });
    }
  }, [claimantId, sourceId, claimId, referrer, claimClaimantLookup, rrId]);

  useEffect(() => {
    const lookupResult = ClaimClaimantLookupData?.claimClaimantLookup?.Result;
    const lookupErrors = ClaimClaimantLookupData?.claimClaimantLookup?.Errors;

    if (lookupResult) {
      setState({
        CentralEligibilityID: lookupResult.CentralEligibilityID,
        ClaimNumber: lookupResult.ClaimNumber,
        ClaimantFirstName: lookupResult.ClaimantFirstName,
        ClaimantIPNumber: lookupResult.ClaimantIPNumber,
        ClaimantLastName: lookupResult.ClaimantLastName,
        ClaimantMiddleName: lookupResult.ClaimantMiddleName,
        ClaimantAddress1: lookupResult.Address1,
        ClaimantAddress2: lookupResult.Address2,
        ClaimantCity: lookupResult.City,
        ClaimantState: lookupResult.State,
        ClaimantZip: lookupResult.Zip5,
        // CompanyName: lookupResult.CompanyName,
        ClaimantDateOfBirth: lookupResult.DateOfBirth
          ? format(
              convertTimeZone(new Date(lookupResult.DateOfBirth)),
              "yyyy-MM-dd"
            )
          : "",
        DateOfLoss: lookupResult.DateOfLoss
          ? format(
              convertTimeZone(new Date(lookupResult.DateOfLoss)),
              "yyyy-MM-dd"
            )
          : "",
        EligibilityStatus: lookupResult.EligibilityStatus,
        ExternalClaimID: lookupResult.ExternalClaimID,
        ExternalPatientID: lookupResult.ExternalPatientID,
        HCP: lookupResult.HCP,
        ClaimantPhone: lookupResult.Phone1,
        ClaimantCellPhone: lookupResult.Phone2,
        SOJ: lookupResult.SOJ,
        ClaimantGender: lookupResult.Sex,
        ClaimantSSN: lookupResult.SSN,
        Insured: lookupResult.InsuredParty,
        Venue: lookupResult.Venue,
        Jurisdiction: lookupResult.Jurisdiction,
        CellPhone: lookupResult.ClaimantCellPhone,
        ClaimantEmail: lookupResult.Email,
        ClaimantSpecialPhysical: lookupResult.SpecialPhysical,
      });
    } else if (Array.isArray(lookupErrors) && lookupErrors.length) {
      setErrors(lookupErrors);
    }
  }, [ClaimClaimantLookupData]);

  return [state, errors];
}
