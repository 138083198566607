/* eslint-disable import/prefer-default-export */
/* eslint-disable react/prop-types */
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Generic, Icon } from "rbx";

export default function generateUserSecuritiesColumns({
  handleDeleteUserSecurityByGroup,
  handleDeleteUserSecuritiesByOffice,
  disabled,
}) {
  return [
    {
      Header: "",
      id: "deleteUserSecurityOffice",
      maxWidth: "1%",
      Cell: ({ row }) =>
        !row.original.hide && (
          <React.Fragment>
            <Generic
              disabled={disabled}
              style={{ textAlign: "center" }}
              title="Delete All Securities For This Office"
              type="button"
              onClick={() =>
                !disabled && handleDeleteUserSecuritiesByOffice(row.original)
              }
            >
              <Icon color="danger">
                <FontAwesomeIcon icon="times" />
              </Icon>
            </Generic>
          </React.Fragment>
        ),
    },
    {
      Header: "Office",
      id: "office:description",
      accessor: "office.description",
      maxWidth: "40%",
      Cell: ({ cell, row }) => !row.original.hide && cell?.value,
    },
    {
      Header: "",
      id: "deleteUserSecurityGroup",
      accessor: "groupcode",
      maxWidth: "1%",
      Cell: ({ row }) => (
        <React.Fragment>
          <Generic
            disabled={disabled}
            style={{ textAlign: "center" }}
            title="Delete This Security Group"
            type="button"
            onClick={() =>
              !disabled && handleDeleteUserSecurityByGroup(row.original)
            }
          >
            <Icon color="danger">
              <FontAwesomeIcon icon="times" />
            </Icon>
          </Generic>
        </React.Fragment>
      ),
    },
    {
      Header: "groupcode",
      id: "groupcode",
      accessor: "groupcode",
      maxWidth: "40%",
    },
  ];
}
