/* eslint-disable import/prefer-default-export */
import { gql } from "@apollo/client";

export const CREATE_USER_FUNCTION_MUTATION = gql`
  mutation createUserFunction($data: UserFunctionCreateInput!) {
    createUserFunction(data: $data) {
      functioncode
      functiondesc
    }
  }
`;

export const UPDATE_USER_FUNCTION_MUTATION = gql`
  mutation updateUserFunction(
    $data: UserFunctionUpdateInput!
    $where: UserFunctionWhereUniqueInput!
  ) {
    updateUserFunction(data: $data, where: $where) {
      functioncode
      functiondesc
    }
  }
`;

export const DELETE_USER_FUNCTION_MUTATION = gql`
  mutation deleteUserFunction($where: UserFunctionWhereUniqueInput!) {
    deleteUserFunction(where: $where) {
      functioncode
    }
  }
`;