import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Title, Button, Field, Control } from "rbx";
import { useApolloClient, useMutation, useLazyQuery } from "@apollo/client";
import ProblemForm from "../ProblemForm";
import { useModal, useAuth } from "../../../../../context";
import {
  FIND_FIRST_CASE_PROBLEM_QUERY,
  LIST_PROBLEMS_QUERY,
  CREATE_PROBLEM_MUTATION,
  UPDATE_PROBLEM_MUTATION,
  // CREATE_AUDIT_TRAIL_MUTATION,
  DELETE_PROBLEM_MUTATION,
  SINGLE_PROBLEM_QUERY,
} from "../../../../../graphql";
import { customToast as toast, getDateEST } from "../../../../../utils";
import Confirmation from "../../../../../components/Confirmation";

const INITIAL_STATE = {
  description: "",
  status: "Active",
};

class CustomProblemError extends Error {}

const ProblemModal = ({ onComplete, problemcode }) => {
  const client = useApolloClient();
  const { setModalOpen } = useModal();
  const { state: authState } = useAuth();
  const [inputs, setInputs] = useState(INITIAL_STATE);
  const [updated, setUpdated] = useState([]);
  const [loading, setLoading] = useState(false);

  const [createProblem] = useMutation(CREATE_PROBLEM_MUTATION);
  const [updateProblem] = useMutation(UPDATE_PROBLEM_MUTATION);
  // const [createAuditTrail] = useMutation(CREATE_AUDIT_TRAIL_MUTATION);
  const [deleteProblem] = useMutation(DELETE_PROBLEM_MUTATION);
  const [
    getProblem,
    { data: problemData, loading: queryLoading },
  ] = useLazyQuery(SINGLE_PROBLEM_QUERY);

  useEffect(() => {
    if (problemcode) {
      getProblem({
        variables: {
          where: { problemcode },
        },
      });
    }
  }, [getProblem, problemcode]);

  useEffect(() => {
    if (problemData?.problem) {
      setInputs({
        description: problemData.problem.description || "",
        status: problemData.problem.status || "Active",
      });
    }
  }, [problemData]);

  const handleChange = (name, value) => {
    setInputs((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (problemcode) {
      setUpdated((prev) => (prev.includes(name) ? prev : [...prev, name]));
    }
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const date = getDateEST();
      setLoading(true);
      if (!problemcode) {
        await createProblem({
          variables: {
            data: {
              ...inputs,
              useridedited: authState.user.userid,
              dateedited: date,
              useridadded: authState.user.userid,
              dateadded: date,
            },
          },
          refetchQueries: [
            {
              query: LIST_PROBLEMS_QUERY,
            },
          ],
        });
        // await createAuditTrail({
        //   variables: {
        //     data: {
        //       tablename: "tblProblem",
        //       entityid: String(createProblemData.createProblem.problemcode),
        //       type: "New",
        //       description: "New problem created",
        //       useridadded: authState.user.userid,
        //       dateadded: getDateEST(),
        //     },
        //   },
        //   refetchQueries: [
        //     {
        //       query: SINGLE_PROBLEM_QUERY,
        //       variables: {
        //         where: {
        //           problemcode: createProblemData.createProblem.problemcode,
        //         },
        //       },
        //     },
        //   ],
        // });
        toast.success("Problem created successfully.");
      } else {
        await updateProblem({
          variables: {
            data: {
              description: { set: inputs.description },
              status: { set: inputs.status },
              useridedited: { set: authState.user.userid },
              dateedited: { set: date },
            },
            where: {
              problemcode,
            },
          },
          refetchQueries: [
            {
              query: LIST_PROBLEMS_QUERY,
            },
            {
              query: SINGLE_PROBLEM_QUERY,
              variables: {
                where: { problemcode },
              },
            },
          ],
        });
        // await createAuditTrail({
        //   variables: {
        //     data: {
        //       tablename: "tblProblem",
        //       entityid: String(problemcode),
        //       type: "Update",
        //       description: `Updated fields: ${updated.join(", ")}`,
        //       useridadded: authState.user.userid,
        //       dateadded: getDateEST(),
        //     },
        //   },
        // });
        toast.success("Problem updated successfully.");
      }
      onComplete();
    } catch (err) {
      if (err.message.includes("Unique constraint")) {
        toast.error("This code problem already exists");
      } else {
        toast.error("Error saving Problem");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (e) => {
    e.preventDefault();

    const performDelete = async () => {
      try {
        setLoading(true);
        const { data: CaseProblemData } = await client.query({
          query: FIND_FIRST_CASE_PROBLEM_QUERY,
          variables: {
            where: {
              problemcode: { equals: problemcode },
            },
          },
        });
        if (CaseProblemData?.findFirstCaseProblem) {
          throw new CustomProblemError(
            "This problem is associated with a case and cannot be deleted."
          );
        }

        await deleteProblem({
          variables: {
            where: {
              problemcode,
            },
          },
          refetchQueries: [
            {
              query: LIST_PROBLEMS_QUERY,
            },
          ],
        });
        toast.success("Problem deleted successfully");
        onComplete();
      } catch (err) {
        if (err instanceof CustomProblemError) {
          toast.error(err.message);
        } else {
          toast.error("Error trying to delete Problem");
        }
      } finally {
        setLoading(false);
      }
    };
    setModalOpen(
      true,
      <Confirmation
        message="Are you sure you want to delete this Problem?"
        onCancel={() =>
          setModalOpen(
            true,
            <ProblemModal problemcode={problemcode} onComplete={onComplete} />
          )
        }
        onConfirm={performDelete}
      />
    );
  };

  const isDisabled =
    !inputs.description ||
    (problemcode && !updated?.length) ||
    queryLoading ||
    loading;

  return (
    <form id="problem-form" onSubmit={handleSubmit}>
      <header className="page-head">
        <div className="page-head-start">
          <Title size={5}>
            {`${!problemcode ? "Create" : "Edit"}`}
            &nbsp;Problem
          </Title>
        </div>
        <div className="page-head-end">
          <Button.Group hasAddons>
            <Button
              size="small"
              type="button"
              onClick={() => onComplete(false)}
            >
              <span>Cancel</span>
            </Button>

            <Button
              color="primary"
              disabled={isDisabled}
              form="problem-form"
              size="small"
              state={loading ? "loading" : ""}
              type="submit"
            >
              <span>Submit</span>
            </Button>
          </Button.Group>
        </div>
      </header>
      <hr />
      <ProblemForm inputs={inputs} onChange={handleChange} />
      <hr />
      {problemcode && (
        <Field>
          <Control>
            <Button
              color="danger"
              size="small"
              type="button"
              onClick={handleDelete}
            >
              <span>Delete</span>
            </Button>
          </Control>
        </Field>
      )}
    </form>
  );
};

ProblemModal.propTypes = {
  onComplete: PropTypes.func,
  problemcode: PropTypes.number,
};

ProblemModal.defaultProps = {
  onComplete: () => null,
  problemcode: null,
};

export default ProblemModal;
