/* eslint-disable import/prefer-default-export */
import { gql } from "@apollo/client";

export const LIST_RATS_TYPE_REFERENCE = gql`
  query Typereferences(
    $where: TypereferenceWhereInput
    $orderBy: [TypereferenceOrderByInput!]
  ) {
    typereferences(where: $where, orderBy: $orderBy) {
      TRid
      TRentityAbbrev
      TRappliesTo
      TRdescription
      TRvalue
    }
  }
`;

export const PROVIDER_RUSH_STATUS_OPTIONS = gql`
  query Typereferences(
    $where: TypereferenceWhereInput
    $orderBy: [TypereferenceOrderByInput!]
  ) {
    typereferences(where: $where, orderBy: $orderBy) {
      TRid
      TRdescription
    }
  }
`;
