import { gql } from "@apollo/client";

export const UPDATE_SERVICE_QUEUE_MUTATION = gql`
  mutation UPDATE_SERVICE_QUEUE_MUTATION(
    $data: ServiceQueueUpdateInput!
    $where: ServiceQueueWhereUniqueInput!
  ) {
    updateServiceQueue(data: $data, where: $where) {
      servicecode
      queueorder
      statuscode
    }
  }
`;

export const CREATE_SERVICE_QUEUE_MUTATION = gql`
  mutation CREATE_SERVICE_QUEUE_MUTATION($data: ServiceQueueCreateInput!) {
    createServiceQueue(data: $data) {
      servicecode
      queueorder
      statuscode
    }
  }
`;

export const DELETE_STATUS_SERVICE_MUTATION = gql`
  mutation DELETE_STATUS_SERVICE_MUTATION(
    $where: ServiceQueueWhereUniqueInput!
  ) {
    deleteServiceQueue(where: $where) {
      servicecode
      queueorder
      statuscode
    }
  }
`;
