import React, { useState } from "react";
import PropTypes from "prop-types";
import { Title, Button } from "rbx";
import { useMutation, useQuery } from "@apollo/client";
import OfficeSelect from "../../../../../../components/OfficeSelect";
import CaseTypeOfficeSelect from "../../../../../../components/CaseTypeOfficeSelect";
import ServiceSelect from "../../../../../../components/ServiceSelect";
import { useAuth } from "../../../../../../context";
import {
  customToast as toast,
  getDateEST,
  convertTimeZoneDataBase,
} from "../../../../../../utils";
import {
  CREATE_SERVICE_CASE_TYPE_OFFICE_MUTATION,
  ALL_SERVICES_CASE_TYPES_SELECT_QUERY,
} from "../../../../../../graphql";

const INITIAL_STATE = {
  caseTypeCode: "",
  officeCode: "",
  serviceCode: "",
};

const AddServiceCaseTypeOfficeModal = ({
  onComplete,
  officeCode,
  caseTypeCode,
  onCancel,
}) => {
  const { state: authState } = useAuth();
  const [loading, setLoading] = useState(false);
  const [createServiceCaseTypeOffice] = useMutation(
    CREATE_SERVICE_CASE_TYPE_OFFICE_MUTATION
  );

  const { data: ServiceCaseTypesData, loading: loadingCaseTypes } = useQuery(
    ALL_SERVICES_CASE_TYPES_SELECT_QUERY,
    {
      variables: {
        orderBy: { Service: { description: "asc" } },
        where: {
          casetype: {
            equals: parseInt(caseTypeCode, 10),
          },
          officecode: {
            equals: parseInt(officeCode, 10),
          },
        },
      },
    }
  );

  const [inputs, setInputs] = useState({
    ...INITIAL_STATE,
    officeCode,
    caseTypeCode,
  });

  const handleChange = (name, value) => {
    setInputs((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const date = convertTimeZoneDataBase(getDateEST());
      setLoading(true);
      await createServiceCaseTypeOffice({
        variables: {
          data: {
            Office: {
              connect: {
                officecode: parseInt(inputs.officeCode, 10),
              },
            },
            CaseType: {
              connect: {
                code: parseInt(inputs.caseTypeCode, 10),
              },
            },
            Service: {
              connect: { servicecode: parseInt(inputs.serviceCode, 10) },
            },
            useridadded: authState.user.userid,
            dateadded: date,
            dateedited: date,
            useridedited: authState.user.userid,
          },
        },
        refetchQueries: [
          {
            query: ALL_SERVICES_CASE_TYPES_SELECT_QUERY,
            variables: {
              orderBy: { Service: { description: "asc" } },
              where: {
                casetype: {
                  equals: parseInt(caseTypeCode, 10),
                },
                officecode: {
                  equals: parseInt(officeCode, 10),
                },
              },
            },
          },
        ],
      });
      toast.success("Services to Case Type and Office created successfully.");
      onComplete(inputs.serviceCode);
    } catch (err) {
      toast.error("Error saving Services to Case Type and Office ");
    } finally {
      setLoading(false);
    }
  };

  const isDisabled = !inputs.serviceCode || loading || loadingCaseTypes;

  const caseTypeServices = ServiceCaseTypesData?.caseTypeServices || [];

  const serviceSelectWhere =
    Array.isArray(caseTypeServices) && caseTypeServices.length
      ? {
          servicecode: {
            notIn: caseTypeServices.map((cts) => cts.Service.servicecode),
          },
        }
      : {};

  return (
    <form id="service-form" onSubmit={handleSubmit}>
      <header className="page-head">
        <div className="page-head-start">
          <Title size={5}>Link Service to Case Type and Office</Title>
        </div>
        <div className="page-head-end">
          <Button.Group hasAddons>
            <Button size="small" type="button" onClick={onCancel}>
              <span>Cancel</span>
            </Button>
            <Button
              color="primary"
              disabled={isDisabled}
              form="service-form"
              size="small"
              state={loading ? "loading" : ""}
              type="submit"
            >
              <span>Submit</span>
            </Button>
          </Button.Group>
        </div>
      </header>
      <hr />
      <div className="service-case-type-office-form">
        <OfficeSelect
          disabled
          name="officeCode"
          noValueLabel=""
          value={inputs.officeCode}
          onChange={handleChange}
        />
        <CaseTypeOfficeSelect
          disabled
          name="caseTypeCode"
          noValueLabel=""
          officeCode={inputs.officeCode}
          value={inputs.caseTypeCode}
          onChange={handleChange}
        />
        <ServiceSelect
          disabled={loading}
          name="serviceCode"
          value={inputs.serviceCode}
          where={serviceSelectWhere}
          onChange={handleChange}
        />
      </div>
      <hr />
    </form>
  );
};

AddServiceCaseTypeOfficeModal.propTypes = {
  onComplete: PropTypes.func.isRequired,
  officeCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  caseTypeCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default AddServiceCaseTypeOfficeModal;
