import { gql } from "@apollo/client";

export const ALL_CASES_QUERY = gql`
  query cases(
    $where: CaseItemWhereInput
    $orderBy: [CaseItemOrderByInput!]
    $take: Int
    $skip: Int
  ) {
    cases: caseItems(
      where: $where
      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      casenbr
      status
      Status {
        statuscode
        statusdesc
      }
    }
  }
`;
export const ALL_CASE_APPTS_QUERY = gql`
  query cases(
    $where: CaseItemWhereInput
    $orderBy: [CaseItemOrderByInput!]
    $take: Int
    $skip: Int
  ) {
    cases: caseItems(
      where: $where
      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      casenbr
      location {
        locationcode
        location
      }
      Appttime
      examinee {
        chartnbr
        firstname
        lastname
      }
      client {
        clientcode
        company {
          companycode
          intname
        }
      }
    }
  }
`;

export const LIST_DOCTOR_CASES_QUERY = gql`
  query cases(
    $where: CaseItemWhereInput
    $orderBy: [CaseItemOrderByInput!]
    $take: Int
    $skip: Int
  ) {
    cases: caseItems(
      take: $take
      orderBy: $orderBy
      skip: $skip
      where: $where
    ) {
      casenbr
      ApptDate
      Appttime
      DoctorName
      status
      casetype
      examinee {
        chartnbr
        firstname
        lastname
      }
      client {
        clientcode
        firstname
        lastname
        company {
          companycode
          intname
          extname
        }
      }
      Status {
        statuscode
        statusdesc
      }
      service {
        description
        servicecode
      }
    }
    aggregateCase: aggregateCaseItem(where: $where) {
      count {
        casenbr
      }
    }
  }
`;

export const LIST_CASE_CLIENT_QUERY = gql`
  query cases(
    $take: Int = 20
    $orderBy: [CaseItemOrderByInput!]
    $skip: Int
    $where: CaseItemWhereInput
  ) {
    cases: caseItems(
      take: $take
      orderBy: $orderBy
      skip: $skip
      where: $where
    ) {
      casenbr
      # master / sub case
      mastercasenbr
      mastersubcase
      # appt
      ApptDate
      Appttime
      DoctorName
      status
      casetype
      QARep
      claimnbr
      clientcode
      doctorcode
      casetype
      priority
      commitdate
      servicecode
      Jurisdiction
      marketercode
      SelectedAllstateProcessor
      QARep
      laststatuschg
      schedulercode
      chartnbr
      officecode
      clientcode
      sreqspecialty
      IMECompany
      rptstatus
      requesteddoc
      examinee {
        chartnbr
        firstname
        lastname
        state
        zip
        city
        DOB
      }
      location {
        locationcode
        location
      }
      caseType {
        code
        description
      }
      Status {
        statuscode
        statusdesc
      }
      service {
        description
        servicecode
      }
      marketercode
      schedulercode
      dateadded
      company {
        companycode
        extname
        intname
      }
      client {
        clientcode
        clientnbrold
        company {
          companycode
          extname
          intname
        }
      }
      doctor {
        doctorcode
        firstname
        lastname
        specialty {
          doctorcode
          specialtycode
        }
      }
    }
    aggregateCase: aggregateCaseItem(where: $where) {
      count {
        casenbr
      }
    }
  }
`;

export const LIST_CASE_COMPANY_QUERY = gql`
  query cases(
    $take: Int = 20
    $orderBy: [CaseItemOrderByInput!]
    $skip: Int
    $where: CaseItemWhereInput
  ) {
    cases: caseItems(
      take: $take
      orderBy: $orderBy
      skip: $skip
      where: $where
    ) {
      casenbr
      ApptDate
      DoctorName
      status
      casetype
      caseType {
        code
        description
      }
      service {
        servicecode
        description
      }
      serviceQueue {
        statuscode
        statusdesc
      }
      examinee {
        chartnbr
        oldchartnbr
        firstname
        lastname
        addr1
        addr2
        city
        email
      }
      client {
        clientcode
        lastname
        firstname
        addr1
        addr2
        city
        state
        lastappt
        phone1
      }
    }
    aggregateCase: aggregateCaseItem(where: $where) {
      count {
        casenbr
      }
    }
  }
`;

export const AGGREGATE_CASE_QUERY = gql`
  query cases($where: CaseItemWhereInput) {
    aggregateCase: aggregateCaseItem(where: $where) {
      count {
        casenbr
      }
    }
  }
`;

export const SINGLE_CASE_QUERY = gql`
  query caseItem($where: CaseItemWhereUniqueInput!) {
    caseItem(where: $where) {
      # selects
      casetype
      officecode
      servicecode
      dateadded
      useridadded
      useridedited
      dateedited
      casenbr
      chartnbr
      ApptDate
      Appttime
      DoctorName
      status
      schedulenotes
      priority
      commitdate
      doctorspecialty
      IMECompany
      marketercode
      SelectedAllstateProcessor
      QARep
      schedulercode
      Jurisdiction
      TreatingPhysician
      #### Case Parameters ####
      ExternalDueDate
      InternalDueDate
      DateReceived
      calledinby
      dateofinjury
      datemedsrecd
      typemedsrecd
      reccode
      claimnbr
      WCBNbr
      HearingDate
      sreqspecialty
      sreqdegree
      sinternalcasenbr
      photoRqd
      icd9code
      ICD9Code2
      ICD9Code3
      ICD9Code4
      CertMailNbr
      specialinstructions
      notes
      referraltype
      referralmethod
      IMEMDName
      emailclient
      emaildoctor
      emailPattny
      faxclient
      faxdoctor
      faxPattny
      reportverbal
      bhanddelivery
      CertifiedMail
      rptstatus
      # Attorney / CCs
      plaintiffattorneycode
      defenseattorneycode
      AttorneyNote
      #User Fields
      usdvarchar1
      usdvarchar2
      bComplete
      usdint1
      DoctorWasRequestedByClient

      #Accounting Third Party Form
      billclientcode
      billcompany
      billcontact
      billaddr1
      billaddr2
      billcity
      billstate
      billzip
      billfax
      billARKey

      usdint2
      usdmoney1
      usdmoney2
      usddate1
      usddate2
      usddate3
      usddate4
      usdtext1
      usdtext2
      requesteddoc
      SalesCreditUserID
      # Accounting
      BillingNote
      laststatuschg
      mastercasenbr
      mastersubcase
      caseType {
        code
        description
      }
      serviceQueue {
        queueorder
        statuscode
        statusdesc
      }
      examinee {
        chartnbr
        oldchartnbr
        firstname
        lastname
        addr1
        addr2
        state
        county
        city
        email
        zip
      }
      clientcode
      client {
        clientcode
        companycode
        lastname
        firstname
        addr1
        addr2
        city
        state
        lastappt
        phone1
        company {
          companycode
          extname
          intname
          addr1
          addr2
          city
          state
          zip
          notes
          companyToCompanyGroup {
            CompanyGroupID
          }
        }
      }
      office {
        officecode
        description
        shortdesc
        status
      }

      marketer {
        userid
        firstname
        lastname
      }
      scheduler {
        userid
        firstname
        lastname
      }
      AllstateProcessor {
        userid
        firstname
        lastname
      }
      QAReporter {
        userid
        firstname
        lastname
        usertype
      }
      service {
        servicecode
        description
        LOB
        PrintApptLetters
      }
      Status {
        statuscode
        statusdesc
        type
        subtype
      }
      doctor {
        doctorcode
        prefix
        firstname
        lastname
        phone
        phoneExt
        credentials
        location {
          locationcode
          doctorcode
        }
        specialty {
          specialtycode
          doctorcode
        }
      }
      location {
        locationcode
        location
      }
      serviceQueues {
        servicecode
        queueorder
        statusdesc
        statuscode
        nextstatus
      }
      doctorLocation {
        locationcode
        doctorcode
      }
    }
  }
`;

export const LIST_QUEUE_QUERY = gql`
  query queues($orderBy: [QueueOrderByInput!], $where: QueueWhereInput) {
    queues(orderBy: $orderBy, where: $where) {
      statuscode
      statusdesc
      type
      subtype
    }
  }
`;

export const LIST_CASE_ISSUES_QUERY = gql`
  query caseIssues(
    $orderBy: [CaseIssueOrderByInput!]
    $where: CaseIssueWhereInput
    $take: Int
    $skip: Int
  ) {
    caseIssues(orderBy: $orderBy, where: $where, take: $take, skip: $skip) {
      casenbr
      dateadded
      issuecode
      useridadded
      issue {
        description
        issuecode
        status
      }
    }
    aggregateCaseIssue: aggregateCaseIssue(where: $where) {
      count {
        issuecode
        casenbr
      }
    }
  }
`;

export const LIST_CASE_PROBLEMS_QUERY = gql`
  query caseProblems(
    $orderBy: [CaseProblemOrderByInput!]
    $where: CaseProblemWhereInput
  ) {
    caseProblems(orderBy: $orderBy, where: $where) {
      casenbr
      dateadded
      problemcode
      useridadded
      problem {
        description
        problemcode
        status
      }
    }
    aggregateCaseProblem: aggregateCaseProblem(where: $where) {
      count {
        problemcode
        casenbr
      }
    }
  }
`;

export const FIND_FIRST_CASE_PROBLEM_QUERY = gql`
  query FIND_FIRST_CASE_PROBLEM_QUERY($where: CaseProblemWhereInput!) {
    findFirstCaseProblem(where: $where) {
      casenbr
      problemcode
    }
  }
`;

export const FIND_FIRST_CASE_ITEM_QUERY = gql`
  query FIND_FIRST_CASE_ITEM_QUERY($where: CaseItemWhereInput) {
    findFirstCaseItem(where: $where) {
      casenbr
    }
  }
`;

export const SINGLE_CASE_ITEM_WITH_EXAMINEE_CCS_QUERY = gql`
  query SINGLE_CASE_ITEM_WITH_EXAMINEE_CCS_QUERY(
    $where: CaseItemWhereUniqueInput!
  ) {
    case: caseItem(where: $where) {
      casenbr
      chartnbr
      examinee {
        chartnbr
        examineeCCs {
          chartnbr
          ccAddress {
            cccode
            company
            firstname
            lastname
            city
          }
        }
      }
    }
  }
`;

export const LIST_CASES_QUERY = gql`
  query cases(
    $take: Int = 20
    $orderBy: [CaseItemOrderByInput!]
    $skip: Int
    $where: CaseItemWhereInput
  ) {
    cases: caseItems(
      take: $take
      orderBy: $orderBy
      skip: $skip
      where: $where
    ) {
      casenbr
      claimnbr
      doctor {
        doctorcode
        firstname
        lastname
        fullname
      }
      doctorspecialty
      status
      Status {
        statuscode
        statusdesc
      }
      casetype
      caseType {
        code
        description
      }
      servicecode
      service {
        description
        servicecode
      }
    }
    aggregateCase: aggregateCaseItem(where: $where) {
      count {
        _all
      }
    }
  }
`;

export const LIST_CASE_ACCOUNTING_QUERY = gql`
  query LIST_CASE_ACCOUNTING_QUERY(
    $take: Int = 20
    $orderBy: [CaseItemOrderByInput!]
    $skip: Int
    $where: CaseItemWhereInput
  ) {
    cases: caseItems(
      take: $take
      orderBy: $orderBy
      skip: $skip
      where: $where
    ) {
      casenbr
      ApptDate
      Appttime
      DoctorName
      claimnbr
      clientcode
      doctorcode
      priority
      commitdate
      officecode
      clientcode
      IMECompany
      examinee {
        chartnbr
        firstname
        lastname
      }
      caseType {
        code
        description
      }
      Status {
        statuscode
        statusdesc
      }
      service {
        description
        servicecode
      }
      marketercode
      schedulercode
      dateadded
      company {
        companycode
        extname
        intname
      }
      doctor {
        doctorcode
        firstname
        lastname
        specialty {
          doctorcode
          specialtycode
        }
      }
      client {
        clientcode
        clientnbrold
        company {
          companycode
          extname
          intname
        }
      }
      AcctHeader {
        documentnbr
        documentdate
        documenttotal
      }
      VoucherAmount
      VoucherDate
      laststatuschg
    }
    aggregateCase: aggregateCaseItem(where: $where) {
      count {
        casenbr
      }
    }
  }
`;

export const LIST_ASSOCIATED_CASES_QUERY = gql`
  query cases(
    $take: Int = 20
    $orderBy: [CaseItemOrderByInput!]
    $skip: Int
    $where: CaseItemWhereInput
  ) {
    cases: caseItems(
      take: $take
      orderBy: $orderBy
      skip: $skip
      where: $where
    ) {
      casenbr
      ApptDate
      Appttime
      DoctorName
      status
      casetype
      QARep
      claimnbr
      clientcode
      doctorcode
      casetype
      priority
      commitdate
      servicecode
      Jurisdiction
      marketercode
      SelectedAllstateProcessor
      QARep
      laststatuschg
      schedulercode
      chartnbr
      officecode
      clientcode
      sreqspecialty
      IMECompany
      rptstatus
      mastersubcase
      mastercasenbr
      examinee {
        chartnbr
        firstname
        lastname
      }
      location {
        locationcode
        location
      }
      caseType {
        code
        description
      }
      Status {
        statuscode
        statusdesc
      }
      service {
        description
        servicecode
      }
      marketercode
      schedulercode
      dateadded
      company {
        companycode
        extname
        intname
      }
      client {
        clientcode
        clientnbrold
        company {
          companycode
          extname
          intname
        }
      }
      doctor {
        doctorcode
        firstname
        lastname
        specialty {
          doctorcode
          specialtycode
        }
      }
    }
    aggregateCase: aggregateCaseItem(where: $where) {
      count {
        casenbr
      }
    }
  }
`;

export const PROCESS_EXCEPTION_QUERY = gql`
  query PROCESS_EXCEPTION_QUERY($where: ProcessExceptionInput!) {
    processException(where: $where) {
      CaseTypeCode
      DateAdded
      DateEdited
      Description
      DisplayMessage
      Document1
      Document2
      EditEmail
      EmailMessage
      EmailOther
      EmailQA
      EmailScheduler
      EmailSubject
      EmailText
      Entity
      ExceptionDefID
      ExceptionID
      GenerateDocument
      IMECentricCode
      Message
      RequireComment
      ServiceCode
      Status
      StatusCode
      StatusCodeValue
      UserIDAdded
      UserIDEdited
    }
  }
`;
export const ALL_CASES_WITH_DEMOGRAPHICS_QUERY = gql`
  query cases(
    $where: CaseItemWhereInput
    $orderBy: [CaseItemOrderByInput!]
    $take: Int
    $skip: Int
  ) {
    cases: caseItems(
      where: $where
      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      casenbr
      status
      claimnbr
      mastersubcase
      mastercasenbr
      examinee {
        chartnbr
        lastname
        firstname
        addr1
        city
        state
        zip
      }
    }
  }
`;

export const SAVE_CONFLICT_QUERY = gql`
  query SAVE_CONFLICT_QUERY($where: CaseItemWhereUniqueInput!) {
    caseItem(where: $where) {
      casenbr
      useridedited
      dateedited
    }
  }
`;

export const GET_CASE_VIRTUAL_DOCUMENT_PATH = gql`
  query GET_CASE_VIRTUAL_DOCUMENT_PATH($where: CaseItemWhereUniqueInput!) {
    getDocVirtualPathByCase(where: $where)
  }
`;

export const GET_CASE_FOR_PORTAL2 = gql`
  query CaseItem($where: CaseItemWhereUniqueInput!) {
    caseItem(where: $where) {
      casenbr
      casetype
      claimnbr
      dateofinjury
      LegalFileNumber
      Jurisdiction
      examinee {
        chartnbr
        lastname
        firstname
        addr1
        addr2
        city
        state
        zip
        phone1
        phone2
        SSN
        sex
        DOB
        email
        insured
        note
      }
    }
  }
`;
