import React, { useState } from "react";
import PropTypes from "prop-types";

import { Title, Field, Control, Textarea, Help, Button } from "rbx";

const NOTE_LENGTH_LIMIT = 7987;

const NotesModal = ({ onComplete }) => {
  const [note, setNote] = useState("");

  const isNoteTooLong = note.length >= NOTE_LENGTH_LIMIT;
  return (
    <div id="referral-add-note-form">
      <header className="page-head">
        <div className="page-head-start">
          <Title size={5}>Add Note</Title>
        </div>
        <div className="page-head-end">
          <Button.Group hasAddons>
            <Button
              size="small"
              type="button"
              onClick={() => onComplete(false)}
            >
              <span>Cancel</span>
            </Button>
            <Button
              color="primary"
              disabled={!note.length || isNoteTooLong}
              size="small"
              type="button"
              onClick={() => onComplete(true, note)}
            >
              <span>Save</span>
            </Button>
          </Button.Group>
        </div>
      </header>
      <hr />
      <Field>
        <Control expanded>
          <Textarea
            maxLength={NOTE_LENGTH_LIMIT}
            name="note"
            size="small"
            value={note}
            onChange={(e) => setNote(e.target.value)}
          />
          <Help color={isNoteTooLong ? "danger" : "primary"}>
            {`${note.length} / ${NOTE_LENGTH_LIMIT} Characters`}
          </Help>
        </Control>
      </Field>
      <hr />
    </div>
  );
};

NotesModal.propTypes = {
  onComplete: PropTypes.func,
};

NotesModal.defaultProps = {
  onComplete: () => { },
};
export default NotesModal;
