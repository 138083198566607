import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Title, Field, Control, Input, Button, Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DataTable from "../../components/DataTable";
import { useModal } from "../../context";
import COLUMNS from "./columns";
import { debounce, getFilterState, saveFilterState } from "../../utils";
import AddClientModal from "./components/AddClientModal";
import ClientListFilters from "./components/ClientListFilters";

import { LIST_CLIENTS_QUERY } from "../../graphql/clients";

const DEFAULT_FILTER_STATE = {};

const ClientListPage = (props) => {
  const [where, setWhere] = useState(
    getFilterState("CLIENT_LIST_FILTERS", DEFAULT_FILTER_STATE)
  );
  const { setModalOpen } = useModal();
  const history = useHistory();

  const [showFilters, setShowFilters] = useState(false);

  const handleRowClick = (client) => {
    history.push(`/clients/${client.clientcode}/profile`);
  };

  const handleReset = () => {
    saveFilterState("CLIENT_LIST_FILTERS", DEFAULT_FILTER_STATE);
    setWhere(DEFAULT_FILTER_STATE);
  };

  const handleChange = (name, value) => {
    if (!value) {
      setWhere((prev) =>
        Object.keys(prev).reduce((acc, curr) => {
          if (curr !== name) acc[curr] = prev[curr];
          return acc;
        }, {})
      );
    } else {
      setWhere((prev) => ({ ...prev, [name]: value }));
    }
  };

  return (
    <div>
      <div className="section-header">
        <Title style={{ marginRight: 15 }}>Clients</Title>
        <Field kind="group" style={{ paddingTop: "0.3rem" }}>
          <Control>
            <Input
              autoComplete="new"
              placeholder="Search by Name"
              size="small"
              onChange={debounce(({ target: { value } }) => {
                if (value) {
                  const [lastname, firstname] = value
                    .split(/,/)
                    .map((x) => x.trim())
                    .filter(Boolean);

                  setWhere({
                    lastname: { startsWith: lastname },
                    firstname: { startsWith: firstname },
                  });
                } else {
                  setWhere({});
                }
              }, 500)}
            />
          </Control>
          <Control>
            <Button
              color="warning"
              size="small"
              onClick={() => setShowFilters((prev) => !prev)}
            >
              <Icon>
                <FontAwesomeIcon icon="filter" />
              </Icon>
              <span>Filters</span>
            </Button>
          </Control>
          <Control>
            <Button
              color="primary"
              size="small"
              onClick={() =>
                setModalOpen(
                  true,
                  <AddClientModal
                    onComplete={({ clientcode }) => {
                      setModalOpen(false, "");
                      if (clientcode) {
                        history.push(`/clients/${clientcode}/profile`);
                      }
                    }}
                  />
                )
              }
            >
              <Icon>
                <FontAwesomeIcon icon="plus" />
              </Icon>
              <span>Add</span>
            </Button>
          </Control>
        </Field>
      </div>
      {showFilters && (
        <ClientListFilters
          filters={where}
          show={showFilters}
          onChange={handleChange}
          onReset={handleReset}
        />
      )}
      <DataTable
        aggregateKey="clientcode"
        aggregateName="aggregateClient"
        columns={COLUMNS}
        fetchPolicy="cache-and-network"
        name="clients"
        orderBy={[
          { id: "lastname", desc: false },
          { id: "firstname", desc: false },
        ]}
        query={LIST_CLIENTS_QUERY}
        where={where}
        onRowClick={handleRowClick}
      />
    </div>
  );
};

ClientListPage.propTypes = {};

export default ClientListPage;
