import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Button, Control, Field, Title } from "rbx";

const StyledErrorFallbackWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const StyledErrorFallbackContainer = styled.div`
  width: 95%;
  max-width: 400px;
  text-align: center;
`;

const StyledErrorFallbackField = styled(Field).attrs({ kind: "group" })`
  display: flex;
  width: 80%;
  margin: 1rem auto;
  .control {
    flex: 1;
  }
`;

const ErrorFallback = ({ error, resetErrorBoundary }) => (
  <StyledErrorFallbackWrapper>
    <StyledErrorFallbackContainer>
      <Title>Something went wrong!</Title>
      <Title subtitle>Please try again.</Title>
      <StyledErrorFallbackField>
        <Control expanded>
          <Button
            fullwidth
            color="info"
            size="small"
            onClick={resetErrorBoundary}
          >
            Contact Support
          </Button>
        </Control>
        <Control expanded>
          <Button
            fullwidth
            color="primary"
            size="small"
            onClick={resetErrorBoundary}
          >
            Try Again
          </Button>
        </Control>
      </StyledErrorFallbackField>
    </StyledErrorFallbackContainer>
  </StyledErrorFallbackWrapper>
);

ErrorFallback.propTypes = {
  error: PropTypes.instanceOf(Error).isRequired,
  resetErrorBoundary: PropTypes.func,
};

ErrorFallback.defaultProps = {
  resetErrorBoundary: () => {},
};

export default ErrorFallback;
