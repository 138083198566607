/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
import { Control, Progress } from "rbx";
import axios from "axios";
import CircleLoader from "react-spinners/CircleLoader";

import { customToast as toast } from "../../utils";

import "./DropZoneFileUploader.scss";

const { REACT_APP_DOCUMENT_HOST } = process.env;

const TEN_GB = 1000000000 * 10;

class CustomDropZoneFileUploaderError extends Error {}

const DropZoneFileUploader = ({ name, value, onChange, dirPath, multiple }) => {
  const [loading, setLoading] = useState(false);
  const [uploadPercentage, setUploadPercentage] = useState(0);

  const onDropAccepted = useCallback(
    async (acceptedFiles) => {
      if (acceptedFiles.length <= 5) {
        setLoading(true);
        const arrayOfFiles = [];
        await Promise.all(
          acceptedFiles.map(async (file, i) => {
            try {
              const { data: alreadyExists } = await axios.get(
                `${REACT_APP_DOCUMENT_HOST}/exists?path=${dirPath}/${file.name}`
              );

              if (alreadyExists) {
                throw new CustomDropZoneFileUploaderError(
                  `${file.name} already exists on the drive. Please rename and try uploading again.`
                );
              }

              const formData = new FormData();
              formData.append("file", file);

              const { data } = await axios.post(
                `${REACT_APP_DOCUMENT_HOST}/${dirPath}`,
                formData,
                {
                  headers: { "Content-Type": "multipart/form-data" },
                  onUploadProgress: (progress) => {
                    const { total, loaded } = progress;
                    const totalSizeInMB = total / 1000000;
                    const loadedSizeInMB = loaded / 1000000;
                    const currentUploadPercentage =
                      (loadedSizeInMB / totalSizeInMB) * 100;
                    setUploadPercentage(currentUploadPercentage.toFixed(2));
                  },
                }
              );

              const result = decodeURIComponent(data).substr(
                data.lastIndexOf("/") + 1
              );
              arrayOfFiles.push({ file: result, description: "", id: i });
            } catch (err) {
              const message =
                err instanceof CustomDropZoneFileUploaderError
                  ? err.message
                  : "There was an error uploading this file. Please try again.";
              toast.error(message);
            } finally {
              setLoading(false);
              setUploadPercentage(0);
            }
          })
        );
        onChange(name, arrayOfFiles);
      } else {
        toast.warning("Please select 5 or less files to upload.");
      }
    },
    [dirPath, name, onChange]
  );

  const onDropRejected = () => {
    toast.error(
      "This file is too large to be uploaded. The current limit is 1 GB. Please contact your administrator if the issue persists."
    );
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDropAccepted,
    onDropRejected,
    multiple,
    maxSize: TEN_GB,
  });

  useEffect(() => {
    const handlePaste = (e) => {
      const files = e.clipboardData?.files;
      if (files.length) {
        onDropAccepted([...files]);
      }
    };
    window.addEventListener("paste", handlePaste);
    return () => window.removeEventListener("paste", handlePaste);
  }, [onDropAccepted]);

  return (
    <Control>
      {loading ? (
        <div className="drop-zone-loader">
          <CircleLoader loading size={75} />
          <h1>Uploading...</h1>
          <div className="progress-container">
            <Progress color="primary" value={uploadPercentage} />
            <p>
              {typeof uploadPercentage === "string"
                ? uploadPercentage.split(".")[0]
                : parseInt(uploadPercentage, 2)}
              %
            </p>
          </div>
        </div>
      ) : (
        <div {...getRootProps()} className="drap-drop-zone">
          <input {...getInputProps()} />
          {isDragActive ? (
            <p>Drop the file here ...</p>
          ) : (
            <p>
              Paste files into the window, drag and drop files here, or click to
              select files
            </p>
          )}
        </div>
      )}
    </Control>
  );
};

DropZoneFileUploader.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.number,
  ]),
  multiple: PropTypes.bool,
  dirPath: PropTypes.string,
  onChange: PropTypes.func,
};

DropZoneFileUploader.defaultProps = {
  name: "",
  value: "",
  dirPath: "",
  multiple: false,
  onChange: () => null,
};

export default DropZoneFileUploader;
