import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Title, Button } from "rbx";
import { useApolloClient, useMutation, useLazyQuery } from "@apollo/client";
import { useModal, useAuth } from "../../../../../context";
import {
  LIST_CASE_ISSUES_QUERY,
  CREATE_ISSUE_MUTATION,
  UPDATE_ISSUE_MUTATION,
  // CREATE_AUDIT_TRAIL_MUTATION,
  DELETE_ISSUE_MUTATION,
  LIST_ISSUES_QUERY,
  SINGLE_ISSUE_QUERY,
} from "../../../../../graphql";
import Confirmation from "../../../../../components/Confirmation";
import {
  convertTimeZoneDataBase,
  customToast as toast,
  getDateEST,
} from "../../../../../utils";
import IssueForm from "../IssueForm";

const INITIAL_STATE = {
  description: "",
  status: "Active",
  instruction: "",
};

const IssueModal = ({ onComplete, issuecode, where }) => {
  const client = useApolloClient();
  const { setModalOpen } = useModal();
  const { state: authState } = useAuth();
  const [inputs, setInputs] = useState(INITIAL_STATE);
  const [updated, setUpdated] = useState([]);
  const [loading, setLoading] = useState(false);

  const [getIssue, { data: issueData, loading: queryLoading }] = useLazyQuery(
    SINGLE_ISSUE_QUERY
  );
  const [createIssue] = useMutation(CREATE_ISSUE_MUTATION);
  const [updateIssue] = useMutation(UPDATE_ISSUE_MUTATION);
  // const [createAuditTrail] = useMutation(CREATE_AUDIT_TRAIL_MUTATION);
  const [deleteIssue] = useMutation(DELETE_ISSUE_MUTATION);

  useEffect(() => {
    if (issuecode) {
      getIssue({
        variables: {
          where: {
            issuecode: parseInt(issuecode, 10),
          },
        },
      });
    }
  }, [issuecode, getIssue]);

  useEffect(() => {
    if (issueData?.issue) {
      setInputs({
        description: issueData.issue.description || "",
        status: issueData.issue.status || "Active",
        instruction: issueData.issue.instruction || "",
      });
    }
  }, [issueData]);

  const handleChange = (name, value) => {
    setInputs((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (issuecode) {
      setUpdated((prev) => (prev.includes(name) ? prev : [...prev, name]));
    }
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);

      const date = convertTimeZoneDataBase(getDateEST());

      if (!issuecode) {
        await createIssue({
          variables: {
            data: {
              ...inputs,
              useridedited: authState.user.userid,
              dateedited: date,
              useridadded: authState.user.userid,
              dateadded: date,
            },
          },
          refetchQueries: [
            {
              query: LIST_ISSUES_QUERY,
            },
          ],
        });
        // await createAuditTrail({
        //   variables: {
        //     data: {
        //       tablename: "tblIssue",
        //       entityid: createIssueData.createIssue.issuecode.toString(),
        //       type: "New",
        //       description: "New issue created",
        //       useridadded: authState.user.userid,
        //       dateadded: date,
        //     },
        //   },
        //   refetchQueries: [
        //     {
        //       query: SINGLE_ISSUE_QUERY,
        //       variables: {
        //         where: {
        //           issuecode: createIssueData.createIssue.issuecode,
        //         },
        //       },
        //     },
        //   ],
        // });
        toast.success("Issue created successfully.");
      } else {
        await updateIssue({
          variables: {
            data: {
              description: { set: inputs.description },
              status: { set: inputs.status },
              instruction: { set: inputs.instruction },
              useridedited: { set: authState.user.userid },
              dateedited: { set: date },
            },
            where: {
              issuecode,
            },
          },
          refetchQueries: [
            {
              query: LIST_ISSUES_QUERY,
            },
            {
              query: SINGLE_ISSUE_QUERY,
              variables: {
                where: {
                  issuecode,
                },
              },
            },
          ],
        });
        // await createAuditTrail({
        //   variables: {
        //     data: {
        //       tablename: "tblIssue",
        //       entityid: issuecode.toString(),
        //       type: "Update",
        //       description: `Updated fields: ${updated.join(", ")}`,
        //       useridadded: authState.user.userid,
        //       dateadded: getDateEST(),
        //     },
        //   },
        // });
        toast.success("Issue updated successfully.");
      }
      onComplete();
    } catch (err) {
      toast.error("Error saving Issue");
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    const { data: caseIssuesData } = await client.query({
      query: LIST_CASE_ISSUES_QUERY,
      variables: {
        where: {
          issuecode: { equals: parseInt(issuecode, 10) },
        },
        take: 1,
        skip: 0,
      },
    });
    if (caseIssuesData?.caseIssues?.length > 0) {
      toast.error(
        "This issue could not be removed because there's some Cases related"
      );
    } else {
      const performDelete = async () => {
        try {
          setLoading(true);
          await deleteIssue({
            variables: {
              where: {
                issuecode,
              },
            },
            refetchQueries: [
              {
                query: LIST_ISSUES_QUERY,
                variables: {
                  where,
                  orderBy: [{ issuecode: "asc" }],
                },
                take: 25,
                skip: 0,
              },
              {
                query: SINGLE_ISSUE_QUERY,
                variables: {
                  where: {
                    issuecode,
                  },
                },
              },
            ],
          });
          toast.success("Issue deleted successfully");
          onComplete();
        } catch (err) {
          toast.error("Error trying to delete issue");
        } finally {
          setLoading(false);
        }
      };
      setModalOpen(
        true,
        <Confirmation
          message="Are you sure you want to delete this issue?"
          onCancel={() => setModalOpen(false)}
          onConfirm={performDelete}
        />
      );
    }
  };

  const isDisabled =
    !inputs.description ||
    (issuecode && !updated.length) ||
    queryLoading ||
    loading;

  return (
    <form id="issue-form" onSubmit={handleSubmit}>
      <header className="page-head">
        <div className="page-head-start">
          <Title size={5}>
            {`${!issuecode ? "Create" : "Edit"}`}
            &nbsp;Issue
          </Title>
        </div>
        <div className="page-head-end">
          <Button.Group hasAddons>
            <Button size="small" type="button" onClick={() => onComplete()}>
              <span>Cancel</span>
            </Button>
            <Button
              color="primary"
              disabled={isDisabled}
              form="issue-form"
              size="small"
              state={loading ? "loading" : ""}
              type="submit"
            >
              <span>Submit</span>
            </Button>
          </Button.Group>
        </div>
      </header>
      <hr />
      <IssueForm inputs={inputs} onChange={handleChange} />
      <hr />
      {issuecode && (
        <Button
          color="danger"
          size="small"
          type="button"
          onClick={handleDelete}
        >
          <span>Delete</span>
        </Button>
      )}
    </form>
  );
};

IssueModal.propTypes = {
  onComplete: PropTypes.func,
  issuecode: PropTypes.number,
  where: PropTypes.object,
};

IssueModal.defaultProps = {
  onComplete: () => null,
  issuecode: null,
  where: {},
};

export default IssueModal;
