import React, { useCallback } from "react";

import PropTypes from "prop-types";

import { Control, Label } from "rbx";
import ReactSelectAsync from "react-select/async";

import { useQuery } from "@apollo/client";
import { ALL_SERVICE_QUERY } from "../../graphql";

const ServiceMultiSelect = ({
  value,
  name,
  label,
  size,
  onChange,
  disabled,
  getValue,
  setValue,
  required,
  showAllOption,
  where,
}) => {
  const { data: serviceCodeData } = useQuery(ALL_SERVICE_QUERY, {
    variables: { orderBy: { description: "asc" }, where },
  });

  const services = serviceCodeData?.services?.map((item) => ({
    value: item.servicecode.toString(),
    name,
    label: item.description,
  }));

  const loadingOptions = useCallback(
    async (inputValue = "") =>
      new Promise((resolve, reject) => {
        try {
          if (services && Array.isArray(services) && services.length > 0) {
            const arrayFiltered = services.filter((i) =>
              i.label.toLowerCase().includes(inputValue.toLowerCase())
            );
            resolve(arrayFiltered);
          }
        } catch (error) {
          reject(error);
        }
      }),
    [services]
  );

  return (
    <Control expanded>
      <Label>{label}</Label>
      <ReactSelectAsync
        cacheOptions
        isMulti
        defaultOptions={services}
        isDisabled={disabled}
        isLoading={!Array.isArray(services)}
        loadOptions={loadingOptions}
        name={name}
        noOptionsMessage={
          ({ inputValue }) =>
            inputValue.length ? "No options" : "Start typing..."
          // eslint-disable-next-line react/jsx-curly-newline
        }
        styles={{
          indicatorsContainer: (provided) => ({
            ...provided,
            height: "inherit",
          }),
          indicatorSeparator: (provided) => ({
            ...provided,
            display: "none",
          }),
          container: (provided) => ({
            fontSize: 13,
          }),
          singleValue: (provided) => ({
            ...provided,
            top: "38%",
          }),
          control: (provided) => ({
            ...provided,
            width: "100%",
            minWidth: 180,
            border:
              required && Array.isArray(getValue(value)) && value.length === 0
                ? "1px #e63362 solid"
                : "",
            borderRadius: "4px",
            minHeight: 30,
            height:
              Array.isArray(getValue(value)) && value.length > 0 ? "auto" : 30,
            boxShadow:
              required && Array.isArray(getValue(value)) && value.length === 0
                ? "0 0 0 0.125em rgba(230, 51, 98, 0.25)"
                : "",
            "&:hover": {
              borderColor: "hsl(0, 0%, 90%)",
              borderRadius: 4,
              cursor: disabled ? "not-allowed" : "pointer",
            },
          }),
          placeholder: (provided) => ({ ...provided, top: "38%" }),
          menu: (provided) => ({
            ...provided,
            zIndex: 999,
          }),
        }}
        value={
          Array.isArray(services) &&
          services.filter((x) =>
            getValue(value).find((i) => i.value === x.value)
          )
        }
        onChange={onChange}
      />
    </Control>
  );
};

ServiceMultiSelect.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  name: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  getValue: PropTypes.func,
  setValue: PropTypes.func,
  showAllOption: PropTypes.bool,
  where: PropTypes.object,
};

ServiceMultiSelect.defaultProps = {
  value: [],
  name: "",
  label: "Case Type",
  size: "small",
  showAllOption: false,
  onChange: () => null,
  disabled: false,
  required: false,
  getValue: (x) => x,
  setValue: (x) => x,
  where: undefined,
};

export default ServiceMultiSelect;
