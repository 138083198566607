import React, { useState } from "react";
import { Title, Field, Control, Button, Icon, Input } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";

import { useModal } from "../../../../context";
import { LIST_OTHER_PARTIES_QUERY } from "../../../../graphql";
import { debounce } from "../../../../utils";
import DataTable from "../../../../components/DataTable";
import COLUMNS from "./columns";
import { OtherPartyModal } from "../../components";

const DEFAULT_FILTER_STATE = {
  OR: [
    {
      OtherPartyType: {
        is: {
          type: {
            contains: "",
          },
        },
      },
    },
    {
      companyname: { contains: "" },
    },
    {
      addr1: { contains: "" },
    },
    {
      city: { contains: "" },
    },
    {
      state: { contains: "" },
    },
    {
      zip: { contains: "" },
    },
    {
      lastname: { contains: "" },
    },
    {
      firstname: { contains: "" },
    },
  ],
  AND: [
    {
      OPType: {
        equals: "OP",
      },
    },
  ],
};

const OtherPartyListPage = () => {
  const history = useHistory();
  const { setModalOpen } = useModal();
  const [where, setWhere] = useState(DEFAULT_FILTER_STATE);

  const handleRowClick = (row) => {
    history.push(`/setup/other-party/${row.doctorcode}/profile`);
  };

  return (
    <div>
      <div className="section-header">
        <Title style={{ marginRight: 15 }}>Other Parties</Title>
        <Field kind="group" style={{ paddingTop: "0.3rem" }}>
          <Control>
            <Input
              autoComplete="off"
              id="search-input"
              placeholder="Search..."
              size="small"
              onChange={debounce(({ target: { value } }) => {
                if (value) {
                  setWhere({
                    OR: [
                      {
                        OtherPartyType: {
                          is: {
                            type: {
                              contains: value,
                            },
                          },
                        },
                      },
                      {
                        companyname: { contains: value },
                      },
                      {
                        addr1: { contains: value },
                      },
                      {
                        city: { contains: value },
                      },
                      {
                        state: { contains: value },
                      },
                      {
                        zip: { contains: value },
                      },
                      {
                        lastname: { contains: value },
                      },
                      {
                        firstname: { contains: value },
                      },
                    ],
                    AND: [
                      {
                        OPType: {
                          equals: "OP",
                        },
                      },
                    ],
                  });
                } else {
                  setWhere({
                    OR: [
                      {
                        OtherPartyType: {
                          is: {
                            type: {
                              contains: "",
                            },
                          },
                        },
                      },
                      {
                        companyname: { contains: "" },
                      },
                      {
                        addr1: { contains: "" },
                      },
                      {
                        city: { contains: "" },
                      },
                      {
                        state: { contains: "" },
                      },
                      {
                        zip: { contains: "" },
                      },
                      {
                        lastname: { contains: "" },
                      },
                      {
                        firstname: { contains: "" },
                      },
                    ],
                    AND: [
                      {
                        OPType: {
                          equals: "OP",
                        },
                      },
                    ],
                  });
                }
              }, 500)}
            />
          </Control>
          <Control>
            <Button
              color="primary"
              size="small"
              onClick={() => {
                setModalOpen(
                  true,
                  <OtherPartyModal onComplete={() => setModalOpen(false)} />
                );
              }}
            >
              <Icon>
                <FontAwesomeIcon icon="plus" />
              </Icon>
              <span>Add</span>
            </Button>
          </Control>
        </Field>
      </div>
      <DataTable
        aggregateKey="doctorcode"
        aggregateName="aggregateDoctor"
        columns={COLUMNS}
        name="doctors"
        orderBy={[{ id: "companyname", desc: false }]}
        query={LIST_OTHER_PARTIES_QUERY}
        where={where}
        onRowClick={handleRowClick}
      />
    </div>
  );
};

OtherPartyListPage.propTypes = {};

export default OtherPartyListPage;
