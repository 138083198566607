import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link, useParams } from "react-router-dom";
import { format } from "date-fns";
import { useApolloClient } from "@apollo/client";

import { Button, Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled, { css } from "styled-components";
import { ClaimServiceModal } from "../../components";
import { useModal } from "../../../../context";
import { convertTimeZone } from "../../../../utils";

import { SINGLE_APOLLO_SERVICE_QUERY } from "../../../../graphql";

const ServiceListItem = styled.div`
  border-radius: 2px;
  border: 1px solid black;
  margin-top: 3px;
  ${(props) =>
    props.depth &&
    css`
      background-color: ${props.depth % 2 ? "#e1e1e1" : "white"};
    `}
`;

const ServiceList = ({ services, depth }) => {
  const client = useApolloClient();

  const [expanded, setExpanded] = useState([]);

  const { claimcode, serviceid } = useParams();
  const { setModalOpen } = useModal();
  const handleExpand = (id) => {
    const idx = expanded.indexOf(id);
    const currExpanded = [...expanded];

    if (idx > -1) {
      currExpanded.splice(idx, 1);
    } else {
      currExpanded.push(id);
    }
    setExpanded(currExpanded);
  };

  useEffect(() => {
    const getService = async () => {
      try {
        const {
          data: { findUniqueServices },
        } = await client.query({
          query: SINGLE_APOLLO_SERVICE_QUERY,
          variables: {
            where: {
              ServiceID: parseInt(serviceid, 10),
            },
          },
        });
        const parentServiceId = findUniqueServices?.ParentServiceID;

        if (parentServiceId) {
          setExpanded((prev) => [...prev, parentServiceId]);
        }
      } catch {
        // do nothing
      }
    };
    if (serviceid) {
      getService();
    }
  }, [serviceid, client]);

  const handleCreateWithParent = (service) => {
    setModalOpen(
      true,
      <ClaimServiceModal
        ClaimID={parseInt(claimcode, 10)}
        ParentServiceID={service.ServiceID}
        onComplete={() => setModalOpen(false)}
      />
    );
  };
  return (
    <div className="service-list-container">
      <div className="service-list">
        {services.map((service) => {
          const hasChildren = !!(
            Array.isArray(service.ChildServices) && service.ChildServices.length
          );
          return (
            <ServiceListItem
              className="service-list-item"
              depth={depth}
              key={service.ServiceID}
            >
              <div
                className="service-list-item-header"
                // style={{ transform: `translateX(${depth}rem)` }}
              >
                <div>
                  {hasChildren ? (
                    <Button
                      size="small"
                      onClick={() => handleExpand(service.ServiceID)}
                    >
                      <Icon>
                        <FontAwesomeIcon
                          icon={
                            expanded.includes(service.ServiceID)
                              ? "chevron-down"
                              : "chevron-right"
                          }
                        />
                      </Icon>
                    </Button>
                  ) : (
                    <div className="blank" />
                  )}
                </div>
                <p style={{ paddingLeft: 5 }}>
                  <b>Client:&nbsp;</b>
                  {service.ClientName}
                </p>
                <p className="sl-text">
                  {!!(
                    Array.isArray(service.ChildServices) &&
                    service.ChildServices.length
                  ) && (
                    <React.Fragment>
                      <b>Services:&nbsp;</b>
                      {service.ChildServices.length}
                    </React.Fragment>
                  )}
                </p>
                <p className="sl-text">
                  <b>Service Type:&nbsp;</b>
                  {service.ServiceType.ServiceType}
                </p>
                <p className="sl-text">
                  <b>Created:&nbsp;</b>
                  {format(
                    convertTimeZone(new Date(service.CreatedDate)),
                    "MM/dd/yyyy hh:mm"
                  )}
                </p>
                <div>
                  <Button.Group hasAddons>
                    <Button
                      as={Link}
                      color="primary"
                      disabled={String(service.ServiceID) === serviceid}
                      size="small"
                      to={`/claims/${claimcode}/services/${service.ServiceID}/${service.ServiceType.ServiceType}/${service.WorkID}/profile`}
                    >
                      <Icon size="small">
                        <FontAwesomeIcon icon="eye" />
                      </Icon>
                    </Button>
                    <Button
                      color="danger"
                      size="small"
                      onClick={() => handleCreateWithParent(service)}
                    >
                      <Icon size="small">
                        <FontAwesomeIcon icon="plus" />
                      </Icon>
                    </Button>
                  </Button.Group>
                </div>
              </div>
              {hasChildren && expanded.includes(service.ServiceID) && (
                <div className="children">
                  <ServiceList
                    depth={depth + 1}
                    services={service.ChildServices}
                  />
                </div>
              )}

              {/* <pre>
                <code>{JSON.stringify(service, null, 2)}</code>
              </pre> */}
            </ServiceListItem>
          );
        })}
      </div>
    </div>
  );
};

ServiceList.propTypes = {
  services: PropTypes.array.isRequired,
  depth: PropTypes.number,
};

ServiceList.defaultProps = {
  depth: 0,
};
export default ServiceList;
