import React, {useState} from "react";
import PropTypes from "prop-types";
import { Control, Field, Label } from "rbx";

import InsuranceCarrierSelect from "../RatsInsuranceCarrierSelect";
import InsuranceCarrierActionButtons from "../InsuranceCarrierActionButtons";

const InsuranceCarrier = ({
  handleChange,
  inputs,
  disabled,
  required,
  label,
  type,
}) => {

  const [selected, setSelected] = useState(null);

  return (
    <Field kind="group">
      <div style={{ flexGrow: 1 }}>
        <InsuranceCarrierSelect
          disabled={disabled}
          label={label}
          name={type}
          required={required}
          selected={selected}
          setSelected={setSelected}
          value={inputs[type]}
          onChange={(name, value) => handleChange(name, value)}
        />
      </div>
      <Control>
        <Label>&nbsp;</Label>
        <InsuranceCarrierActionButtons
          disabled={disabled}
          label={label}
          selected={selected}
          setSelected={setSelected}
          value={inputs.WCInsuranceCarrier}
          onChange={(name, value) => handleChange(name, value)}
          onClearSelectionClick={() => handleChange(type, "")}
          onInsuranceCarrierAdd={(insuranceCarrier) =>
            handleChange(type, insuranceCarrier)
          }
        />
      </Control>
    </Field>
  );
};

InsuranceCarrier.propTypes = {
  handleChange: PropTypes.func.isRequired,
  inputs: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  required: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default InsuranceCarrier;
