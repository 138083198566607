import { useState, useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";

import qs from "query-string";

const setQueryStringValue = (key, value, history, location) => {
  const values = qs.parse(location.search);
  const newQsValue = value
    ? qs.stringify({ ...values, [key]: value })
    : qs.stringify(
        Object.keys(values).reduce((acc, curr) => {
          if (curr === key) return acc;
          return {
            ...acc,
            [key]: values[curr],
          };
        }, {})
      );
  history.replace(`?${newQsValue}`);
};
const decodeBase64 = (str) => {
  try {
    return atob(str);
  } catch (err) {
    // eslint-disable-next-line no-console
    // console.warn(err);
  }
  return str;
};
const getQueryStringValue = (key, location, isBase64Encoded) => {
  const values = qs.parse(location.search);
  return isBase64Encoded ? decodeBase64(values[key]) : values[key];
};

function useQueryParam(key, initialValue, isBase64Encoded = false) {
  const history = useHistory();
  const location = useLocation();

  const [value, setValue] = useState(
    getQueryStringValue(key, location, isBase64Encoded) || initialValue
  );

  const onSetValue = useCallback(
    (newValue) => {
      setValue(newValue);
      setQueryStringValue(key, newValue, history, location);
    },
    [key, history, location]
  );

  return [value, onSetValue];
}

export default useQueryParam;
