import React from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/client";
import { Title, Table } from "rbx";

import { SINGLE_CE_CLAIM_QUERY_BY_APOLLO_CLAIM_ID } from "../../../../graphql";
import CLAIM_HEADER_COLUMNS from "./columns";

const ClaimHeaderTableHeaderCell = ({ claim, column }) =>
  (column.type === "number" && parseInt(claim?.[column.accessor], 10)) ||
  (claim?.[column.accessor] && claim?.[column.accessor] !== "0") ? (
    <Table.Cell>{column.Header}</Table.Cell>
  ) : null;

ClaimHeaderTableHeaderCell.propTypes = {
  claim: PropTypes.object.isRequired,
  column: PropTypes.object.isRequired,
};

const ClaimHeaderTableBodyCell = ({ claim, column }) =>
  (column.type === "number" && parseInt(claim?.[column.accessor], 10)) ||
  (claim?.[column.accessor] && claim?.[column.accessor] !== "0") ? (
    <Table.Cell>
      {column.Cell
        ? column.Cell(claim[column.accessor])
        : claim[column.accessor]}
    </Table.Cell>
  ) : null;

ClaimHeaderTableBodyCell.propTypes = {
  claim: PropTypes.object.isRequired,
  column: PropTypes.object.isRequired,
};
const ClaimHeader = ({ claimcode }) => {
  const { data } = useQuery(SINGLE_CE_CLAIM_QUERY_BY_APOLLO_CLAIM_ID, {
    variables: { where: { ClaimID: parseInt(claimcode, 10) } },
  });
  const claim = data?.findCentralEligibilityClaimByApolloClaimId;
  if (!claim) return null;

  return (
    <div>
      <header className="page-head">
        <div className="page-head-start">
          <Title size={4}>
            {[claim.ClaimantFirstName, claim.ClaimantLastName]
              .filter(Boolean)
              .join(" ")}
          </Title>
          <div className="claim-info page-head-end">
            <Table>
              <Table.Head>
                <Table.Row style={{ fontWeight: "bold" }}>
                  {CLAIM_HEADER_COLUMNS.map((column) => (
                    <ClaimHeaderTableHeaderCell claim={claim} column={column} />
                  ))}
                </Table.Row>
              </Table.Head>
              <Table.Body>
                <Table.Row>
                  {CLAIM_HEADER_COLUMNS.map((column) => (
                    <ClaimHeaderTableBodyCell claim={claim} column={column} />
                  ))}
                </Table.Row>
              </Table.Body>
            </Table>
          </div>
        </div>
      </header>
    </div>
  );
};

ClaimHeader.propTypes = {
  claimcode: PropTypes.string.isRequired,
};

export default ClaimHeader;
