/* eslint-disable import/prefer-default-export */
import { gql } from "@apollo/client";

export const CREATE_OTHER_PARTY_TYPE_MUTATION = gql`
  mutation CREATE_OTHER_PARTY_TYPE_MUTATION($data: OtherPartyTypeCreateInput!) {
    createOtherPartyType(data: $data) {
      type
      description
      dateadded
      useridadded
      dateedited
      useridedited
    }
  }
`;

export const UPDATE_OTHER_PARTY_TYPE_MUTATION = gql`
  mutation UPDATE_OTHER_PARTY_TYPE_MUTATION(
    $data: OtherPartyTypeUpdateInput!
    $where: OtherPartyTypeWhereUniqueInput!
  ) {
    updateOtherPartyType(data: $data, where: $where) {
      type
      description
      dateadded
      useridadded
      dateedited
      useridedited
    }
  }
`;

export const DELETE_OTHER_PARTY_TYPE_MUTATION = gql`
  mutation DELETE_OTHER_PARTY_TYPE_MUTATION(
    $where: OtherPartyTypeWhereUniqueInput!
  ) {
    deleteOtherPartyType(where: $where) {
      type
    }
  }
`;
