import { gql } from "@apollo/client";

// eslint-disable-next-line import/prefer-default-export
export const ALL_CLIENT_DEFAULT_DOCUMENTS_QUERY = gql`
  query ALL_CLIENT_DEFAULT_DOCUMENTS_QUERY(
    $where: ClientDefDocumentWhereInput
    $orderBy: [ClientDefDocumentOrderByInput!]
    $take: Int
    $skip: Int
  ) {
    clientDefDocuments(
      where: $where
      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      clientcode
      documentcode
      documentqueue
      order
      document {
        document
        description
      }
      queue {
        statuscode
        statusdesc
      }
      dateadded
      dateedited
      useridadded
      useridedited
    }
  }
`;

export const SINGLE_CLIENT_DEFAULT_DOCUMENT_QUERY = gql`
  query SINGLE_CLIENT_DEFAULT_DOCUMENT_QUERY(
    $where: ClientDefDocumentWhereUniqueInput!
  ) {
    clientDefDocument(where: $where) {
      clientcode
      documentcode
      documentqueue
    }
  }
`;
