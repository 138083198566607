import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Title, Button, Field, Control, Input, Label, Help } from "rbx";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";

import UserForm from "../UserForm";
import { useModal } from "../../../../../context";
import Confirmation from "../../../../../components/Confirmation";
import {
  UPDATE_PASSWORD_MUTATION,
  LIST_USERS_QUERY,
} from "../../../../../graphql";
import UpdatePasswordModal from "../../../../../components/UpdatePasswordModal";
import { customToast as toast } from "../../../../../utils";
import Loader from "../../../../../components/Loader";
import UserSecurityModal from "../UserSecurityModal";
import UserFunctionModal from "../UserFunctionModal";
import "./UserModal.scss";

const INITIAL_STATE = {
  userid: "",
  firstname: "",
  lastname: "",
  email: "",
  usertype: "*",
};

const UserModal = ({ adding, onComplete, values, onDelete }) => {
  const { setModalOpen } = useModal();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [inputs, setInputs] = useState({});
  const [updated, setUpdated] = useState([]);
  const [originalUserType, setOriginalUserType] = useState("");

  const [updatePassword] = useMutation(UPDATE_PASSWORD_MUTATION);

  const [getUserData, { data: userData }] = useLazyQuery(LIST_USERS_QUERY, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (values?.userid) {
      getUserData({
        variables: {
          where: {
            userid: {
              equals: values.userid,
            },
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  useEffect(() => {
    if (userData?.users) {
      const user = userData.users[0];

      const newInputs = {};
      Object.keys(INITIAL_STATE).forEach((key) => {
        newInputs[key] = user[key];
      });

      setInputs(newInputs);
      setOriginalUserType(user.usertype);
    } else {
      setInputs(INITIAL_STATE);
      setOriginalUserType("*");
    }
  }, [userData?.users]);

  const handleChange = (name, value) => {
    setInputs((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (!adding) {
      setUpdated((prev) => (prev.includes(name) ? prev : [...prev, name]));
    }
  };

  const navigateToList = () => {
    history.push("/setup/users");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    navigateToList();
    onComplete(
      adding,
      originalUserType,
      inputs,
      values?.userid,
      adding ? null : updated
    );
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    setModalOpen(
      true,
      <Confirmation
        message="Are you sure you want to delete this User?"
        onCancel={() => {
          setModalOpen(false);
          history.push(`/setup/users/${values.userid}`);
        }}
        onConfirm={() => {
          setModalOpen(false);
          onDelete(values.userid, originalUserType);
        }}
      />
    );
  };

  const handleUpdatePassword = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setModalOpen(
      true,
      <UpdatePasswordModal
        onCancel={() => {
          setModalOpen(false);
          history.push(`/setup/users/${values.userid}`);
        }}
        onConfirm={async (Password, ConfirmPassword) => {
          try {
            setLoading(true);
            await updatePassword({
              variables: {
                data: {
                  Password,
                  ConfirmPassword,
                  resetPassword: true,
                },
                where: {
                  userid_usertype: {
                    userid: values.userid,
                    usertype: originalUserType,
                  },
                },
              },
              refetchQueries: [
                {
                  query: LIST_USERS_QUERY,
                },
              ],
            });
            setModalOpen(false);
            toast.success("Password updated successfully");
            history.push(`/setup/users/${values.userid}`);
          } catch (err) {
            toast.error("Error updating password");
          } finally {
            setLoading(false);
          }
        }}
      />
    );
  };

  const isDisabled =
    !inputs.firstname ||
    !inputs.lastname ||
    !inputs.usertype ||
    (!adding && !updated?.length);

  if (loading) return <Loader />;

  return (
    <form id="user-form" onSubmit={handleSubmit}>
      <header className="page-head">
        <div className="page-head-start">
          <Title size={5}>
            {[adding ? "Create" : "Edit", "User"].join(" ")}
          </Title>
        </div>
        <div className="page-head-end">
          <Button.Group hasAddons>
            <Button
              size="small"
              type="button"
              onClick={() => {
                navigateToList();
                onComplete(false);
              }}
            >
              <span>Cancel</span>
            </Button>
            <Button
              color="primary"
              disabled={isDisabled}
              form="user-form"
              size="small"
              state={loading ? "loading" : ""}
              type="submit"
            >
              <span>Submit</span>
            </Button>
          </Button.Group>
        </div>
      </header>
      <hr />
      <UserForm
        inputs={inputs}
        userid={values?.userid || ""}
        onChange={handleChange}
      />
      {adding && (
        <React.Fragment>
          <Field className="temp-pass" kind="group">
            <Control expanded>
              <Label>Temporary Password</Label>
              <Input
                name="password"
                size="small"
                value={inputs.password}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
              />
              <Help color="danger">
                User must change this password of first log in
              </Help>
            </Control>
          </Field>
          <hr />
        </React.Fragment>
      )}
      {!adding && (
        <React.Fragment>
          <hr />
          <div className="modalsWrapper">
            <div>
              <UserSecurityModal inputs={{ userid: values?.userid }} />
            </div>
            <div>
              <UserFunctionModal inputs={{ entityid: values?.userid }} />
            </div>
          </div>
          <hr />
          <div className="user-form-footer">
            <Field>
              <Control>
                <Button
                  color="danger"
                  size="small"
                  type="button"
                  onClick={handleDelete}
                >
                  <span>Delete</span>
                </Button>
              </Control>
            </Field>
            <Field>
              <Control>
                <Button
                  color="primary"
                  size="small"
                  type="button"
                  onClick={handleUpdatePassword}
                >
                  Update Password
                </Button>
              </Control>
            </Field>
          </div>
        </React.Fragment>
      )}
    </form>
  );
};

UserModal.propTypes = {
  adding: PropTypes.bool,
  onComplete: PropTypes.func,
  values: PropTypes.object,
  onDelete: PropTypes.func,
};

UserModal.defaultProps = {
  adding: false,
  onComplete: () => null,
  values: null,
  onDelete: () => null,
};

export default UserModal;
