import React from "react";
import PropTypes from "prop-types";
import { Control, Label, Select } from "rbx";
import { useQuery } from "@apollo/client";

import { ALL_PRIORITIES_QUERY } from "../../graphql";

const PrioritySelect = ({
  value,
  name,
  label,
  size,
  onChange,
  disabled,
  getValue,
  setValue,
  noValueLabel,
  showBlank,
}) => {
  const { data: PriorityData } = useQuery(ALL_PRIORITIES_QUERY, {
    variables: { orderBy: { prioritycode: "asc" } },
  });

  const priorities = PriorityData?.priorities;

  const handleChange = (e) => {
    onChange(e.target.name, setValue(e.target.value));
  };

  return (
    <Control expanded>
      <Label>{label}</Label>
      <Select.Container
        fullwidth
        size={size}
        state={Array.isArray(priorities) ? "" : "loading"}
      >
        <Select
          disabled={disabled}
          name={name}
          value={getValue(value)}
          onChange={handleChange}
        >
          {showBlank && <Select.Option value="">{noValueLabel}</Select.Option>}
          {Array.isArray(priorities) &&
            priorities.map((priority) => (
              <Select.Option
                key={`priority-${priority.prioritycode}`}
                value={priority.prioritycode}
              >
                {priority.description}
              </Select.Option>
            ))}
        </Select>
      </Select.Container>
    </Control>
  );
};

PrioritySelect.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.number,
  ]),
  name: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  getValue: PropTypes.func,
  setValue: PropTypes.func,
  noValueLabel: PropTypes.string,
  showBlank: PropTypes.bool,
};

PrioritySelect.defaultProps = {
  value: "",
  name: "",
  label: "Priority",
  size: "small",
  noValueLabel: "All",
  onChange: () => null,
  disabled: false,
  getValue: (x) => x,
  setValue: (x) => x,
  showBlank: true,
};

export default PrioritySelect;
