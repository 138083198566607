import { gql } from "@apollo/client";

export const ALL_APOLLO_DOCUMENT_LOCATIONS_QUERY = gql`
  query findManyDocumentLocations(
    $take: Int = 100
    $orderBy: [DocumentLocationsOrderByInput!]
    $skip: Int
    $where: DocumentLocationsWhereInput
  ) {
    findManyDocumentLocations(
      take: $take
      orderBy: $orderBy
      skip: $skip
      where: $where
    ) {
      DocumentLocationID
      DocumentLocation
    }
  }
`;

export const LIST_APOLLO_DOCUMENT_LOCATIONS_QUERY = gql`
  query findManyDocumentLocations(
    $take: Int = 100
    $orderBy: [DocumentLocationsOrderByInput!]
    $skip: Int
    $where: DocumentLocationsWhereInput
  ) {
    findManyDocumentLocations(
      take: $take
      orderBy: $orderBy
      skip: $skip
      where: $where
    ) {
      DocumentLocationID
      DocumentLocation
    }
    aggregateDocumentLocations(where: $where) {
      count {
        DocumentLocationID
      }
    }
  }
`;

export const SINGLE_APOLLO_DOCUMENT_LOCATION_QUERY = gql`
  query findUniqueDocumentLocations(
    $where: DocumentLocationsWhereUniqueInput!
  ) {
    findUniqueDocumentLocations(where: $where) {
      DocumentLocationID
      DocumentLocation
    }
  }
`;
