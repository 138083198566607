/* eslint-disable react/prop-types */
/* eslint-disable-next-line react/prop-types */
import React from "react";
import { Icon, Generic } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { format } from "date-fns";

import { convertTimeZone } from "../../../../utils";

export const SERVICES_COLUMNS = (onDuplicate) => [
  {
    Header: "",
    id: "duplicate",
    accessor: "dateadded",
    Cell: ({ cell }) => (
      <Generic
        style={{ textAlign: "center" }}
        type="button"
        onClick={(e) => {
          e.stopPropagation();
          onDuplicate(cell.row.original);
        }}
      >
        <Icon color="inherit">
          <FontAwesomeIcon icon="copy" />
        </Icon>
      </Generic>
    ),
  },
  {
    Header: "Code",
    id: "servicecode",
    accessor: "servicecode",
  },
  {
    Header: "Name",
    id: "shortdesc",
    accessor: "shortdesc",
  },
  {
    Header: "Description",
    id: "description",
    accessor: "description",
  },
  {
    Header: "Status",
    id: "status",
    accessor: "status",
  },
  {
    Header: "Calc From",
    id: "CalcFrom",
    accessor: "CalcFrom",
    Cell: ({ cell }) =>
      cell?.value ? format(convertTimeZone(cell.value), "MM/dd/yyyy") : "",
  },
  {
    Header: "Days To Commit",
    id: "DaysToCommitDate",
    accessor: "DaysToCommitDate",
    Cell: ({ cell }) => cell?.value || "",
  },
  {
    Header: "Print Letters",
    id: "PrintApptLetters",
    accessor: "PrintApptLetters",
    Cell: ({ cell }) => (cell?.value ? "Yes" : "No"),
  },
  {
    Header: "Edited",
    id: "dateedited",
    accessor: "dateedited",
    Cell: ({ cell }) =>
      cell?.value
        ? format(convertTimeZone(cell.value), "MM/dd/yyyy hh:mm a")
        : "",
  },
  {
    Header: "By",
    id: "useridedited",
    accessor: "useridedited",
  },
];

export const SERVICE_QUEUES_COLUMNS = [
  {
    Header: "Order",
    id: "queueorder",
    accessor: "queueorder",
    width: "5%",
  },

  {
    Header: "Status",
    id: "statusdesc",
    accessor: "queue.statusdesc",
    width: "20%",
  },
  {
    Header: "Next Status",
    id: "nextstatus",
    accessor: "NextStatus.statusdesc",
    width: "20%",
  },
];
