import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { format } from "date-fns";
import { AsyncPaginate } from "react-select-async-paginate";

import { Label, Control } from "rbx";
import { useApolloClient, useLazyQuery } from "@apollo/client";
import {
  ALL_EXAMINEES_QUERY,
  SINGLE_EXAMINEE_QUERY,
} from "../../graphql/examinee";

const getExamineeLabel = (examinee = {}) => {
  const { firstname, lastname, city, state, DOB, zip } = examinee;
  return [
    [lastname, firstname].filter(Boolean).join(", "),
    DOB && format(new Date(DOB), "MM/dd/yyyy"),
    [[city, state].filter(Boolean).join(", "), zip].filter(Boolean).join(" "),
  ]
    .filter(Boolean)
    .join(" - ");
};

const QUERY_LIMIT = 50;

const ExamineeSelect = ({
  showLabel,
  name,
  value: initialValue,
  label,
  onChange,
  disabled,
  getValue,
  setValue,
  required,
}) => {
  const client = useApolloClient();

  const [getExaminee, { data: getExamineeData }] = useLazyQuery(
    SINGLE_EXAMINEE_QUERY
  );

  const [selected, setSelected] = useState(null);
  const [defaultOptions, setDefaultOptions] = useState([]);

  const promiseOptions = async (inputValue, { length }) => {
    const [lastname, firstname] = inputValue
      ?.split(/,/)
      .map((x) => x.trim())
      .filter(Boolean);

    const where = {
      lastname: { startsWith: lastname },
      firstname: { startsWith: firstname },
    };

    const { data: examineesData } = await client.query({
      query: ALL_EXAMINEES_QUERY,
      variables: {
        take: QUERY_LIMIT,
        where,
        orderBy: [{ lastname: "asc" }, { firstname: "asc" }],
        skip: length,
      },
    });

    const examinees = examineesData?.examinees;

    const result = Array.isArray(examinees)
      ? examinees.map((examinee) => ({
          value: examinee.chartnbr,
          label: getExamineeLabel(examinee),
        }))
      : [];
    setDefaultOptions(result);
    return { options: result, hasMore: result.length === QUERY_LIMIT };
  };

  const handleChange = ({ value: newValue }) => {
    onChange(name, setValue(newValue || ""));
  };

  useEffect(() => {
    if (initialValue) {
      getExaminee({
        variables: {
          where: { chartnbr: parseInt(getValue(initialValue), 10) },
        },
      });
    } else {
      setSelected(null);
    }
  }, [initialValue, getValue, getExaminee]);

  useEffect(() => {
    const examinee = getExamineeData?.examinee;
    if (examinee && initialValue) {
      setSelected({
        value: examinee.chartnbr,
        label: getExamineeLabel(examinee),
      });
    }
  }, [getExamineeData, initialValue]);

  useEffect(() => {
    if (selected) {
      const currOptions = [...defaultOptions];
      if (!currOptions.some((opt) => opt.value === selected.value)) {
        setDefaultOptions((prev) => [selected, ...prev]);
      }
    }
  }, [selected, defaultOptions]);

  const indicateRequired =
    required &&
    (!initialValue ||
      initialValue === "" ||
      initialValue === "0" ||
      initialValue === 0);

  return (
    <Control expanded>
      {showLabel && <Label>{label}</Label>}
      <AsyncPaginate
        cacheOptions
        debounceTimeout={300}
        defaultOptions={defaultOptions}
        isDisabled={disabled}
        loadOptions={promiseOptions}
        menuPlacement="auto"
        menuPortalTarget={document.body}
        placeholder="Start typing..."
        styles={{
          menuPortal: (provided) => ({
            ...provided,
            zIndex: 9999,
          }),
          indicatorsContainer: (provided) => ({
            ...provided,
            height: "inherit",
          }),
          indicatorSeparator: (provided) => ({
            ...provided,
            display: "none",
          }),
          container: (provided) => ({
            fontSize: 13,
          }),
          singleValue: (provided) => ({
            ...provided,
            top: "38%",
          }),
          control: (provided) => ({
            ...provided,
            minHeight: 31,
            height: 31,
            width: "100%",
            minWidth: 180,
            border: indicateRequired ? "1px #e63362 solid" : "",
            borderRadius: "4px",
            boxShadow: indicateRequired
              ? "0 0 0 0.125em rgba(230, 51, 98, 0.25)"
              : "",
            "&:hover": {
              borderColor: "hsl(0, 0%, 90%)",
              borderRadius: 4,
              cursor: disabled ? "not-allowed" : "pointer",
            },
          }),
          placeholder: (provided) => ({ ...provided, top: "38%" }),
          menu: (provided) => ({
            ...provided,
            fontSize: 12,
            zIndex: 999,
          }),
        }}
        value={selected}
        onChange={handleChange}
      />
    </Control>
  );
};

ExamineeSelect.propTypes = {
  showLabel: PropTypes.bool,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  getValue: PropTypes.func,
  setValue: PropTypes.func,
  required: PropTypes.bool,
};

ExamineeSelect.defaultProps = {
  name: "",
  label: "",
  value: "",
  onChange: () => null,
  showLabel: true,
  disabled: false,
  getValue: (x) => x,
  setValue: (x) => x,
  required: false,
};

export default ExamineeSelect;
