import React, { useState } from "react";
import PropTypes from "prop-types";
import { Title, Button } from "rbx";
import { useMutation } from "@apollo/client";
import ServiceForm from "../ServiceForm";
import { useAuth } from "../../../../../context";
import {
  customToast as toast,
  getDateEST,
  convertTimeZoneDataBase,
} from "../../../../../utils";
import {
  CREATE_SERVICE_MUTATION,
  LIST_SERVICE_QUERY,
} from "../../../../../graphql";

const INITIAL_STATE = {
  description: "",
  status: "Active",
  shortdesc: "",
  CalcFrom: "",
  DaysToCommitDate: "",
};

const ServiceModal = ({ onComplete }) => {
  const [inputs, setInputs] = useState(INITIAL_STATE);
  const { state: authState } = useAuth();
  const [loading, setLoading] = useState(false);
  const [createService] = useMutation(CREATE_SERVICE_MUTATION);
  // const [createAuditTrail] = useMutation(CREATE_AUDIT_TRAIL_MUTATION);

  const handleChange = (name, value) => {
    setInputs((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const date = convertTimeZoneDataBase(getDateEST());
      setLoading(true);
      await createService({
        variables: {
          data: {
            ...inputs,
            DaysToCommitDate: parseInt(inputs.DaysToCommitDate, 10),
            useridedited: authState.user.userid,
            dateedited: date,
            useridadded: authState.user.userid,
            dateadded: date,
          },
        },
        refetchQueries: [
          {
            query: LIST_SERVICE_QUERY,
          },
        ],
      });
      // await createAuditTrail({
      //   variables: {
      //     data: {
      //       tablename: "tblService",
      //       entityid: createServiceData.createService.servicecode.toString(),
      //       type: "New",
      //       description: "New Service created",
      //       useridadded: authState.user.userid,
      //       dateadded: date,
      //     },
      //   },
      // });
      toast.success("Service created successfully.");
      onComplete();
    } catch (err) {
      toast.error("Error saving Service");
    } finally {
      setLoading(false);
    }
  };

  const isDisabled = !inputs.shortdesc || loading;

  return (
    <form id="service-form" onSubmit={handleSubmit}>
      <header className="page-head">
        <div className="page-head-start">
          <Title size={5}>Create Service</Title>
        </div>
        <div className="page-head-end">
          <Button.Group hasAddons>
            <Button
              size="small"
              type="button"
              onClick={() => onComplete(false)}
            >
              <span>Cancel</span>
            </Button>
            <Button
              color="primary"
              disabled={isDisabled}
              form="service-form"
              size="small"
              state={loading ? "loading" : ""}
              type="submit"
            >
              <span>Submit</span>
            </Button>
          </Button.Group>
        </div>
      </header>
      <hr />
      <ServiceForm inputs={inputs} onChange={handleChange} />
      <hr />
    </form>
  );
};

ServiceModal.propTypes = {
  onComplete: PropTypes.func,
};

ServiceModal.defaultProps = {
  onComplete: () => null,
};

export default ServiceModal;
