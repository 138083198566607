import { gql } from "@apollo/client";

// eslint-disable-next-line import/prefer-default-export
export const LIST_ACCT_TRANS = gql`
  query findManyAcctTrans(
    $orderBy: [AcctTransOrderByInput!]
    $take: Int
    $where: AcctTransWhereInput
  ) {
    findManyAcctTrans(where: $where, orderBy: $orderBy, take: $take) {
        casenbr
        documentdate
        otherinfo
        documentnbr
        type
    }
  }
`;
