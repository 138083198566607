/* eslint-disable import/prefer-default-export */
import { gql } from "@apollo/client";

export const SYNC_WEB_USER_INFO_MUTATION = gql`
  mutation SYNC_WEB_USER_INFO_MUTATION(
    $data: WebUserCreateInput!
    $where: ClientWhereUniqueInput!
  ) {
    syncWebUserInformation(data: $data, where: $where)
  }
`;
