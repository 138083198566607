import { gql } from "@apollo/client";

export const ALL_PRODUCTS_QUERY = gql`
  query ALL_PRODUCTS_QUERY(
    $orderBy: [ProductOrderByInput!]
    $where: ProductWhereInput
  ) {
    products(orderBy: $orderBy, where: $where) {
      prodcode
      description
      longdesc
      cptcode
      status
      taxable
    }
  }
`;

export const LIST_PRODUCTS_QUERY = gql`
  query LIST_PRODUCTS_QUERY(
    $orderBy: [ProductOrderByInput!]
    $where: ProductWhereInput
    $take: Int
    $skip: Int
  ) {
    products(orderBy: $orderBy, where: $where, take: $take, skip: $skip) {
      prodcode
      description
      longdesc
      cptcode
      status
      taxable
      INglacct
      VOglacct
      dateadded
      dateedited
      useridadded
      useridedited
      Location
      IncludeOnInvoiceReports
    }
    aggregateProduct(where: $where) {
      count {
        prodcode
      }
    }
  }
`;

export const SINGLE_PRODUCT_QUERY = gql`
  query SINGLE_PRODUCT_QUERY($where: ProductWhereUniqueInput!) {
    product(where: $where) {
      prodcode
      description
      longdesc
      status
      INglacct
      VOglacct
    }
  }
`;
