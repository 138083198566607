import React from "react";
import PropTypes from "prop-types";

import { Field, Control, Label, Input } from "rbx";

import PhoneNumberInput from "../../../../components/PhoneNumberInput";
import ZipInput from "../../../../components/ZipInput";
import StateSelect from "../../../../components/StateSelect";

const RatsEmployerEntryForm = ({ inputs, onChange, mode, label, disabled }) => (
  <Field>
    <Field style={{ marginBottom: "1.25rem" }}>
      <Field kind="group">
        <Control expanded>
          <Label>Name</Label>
          <Input
            disabled={disabled}
            name="EMname"
            placeholder="Name"
            required={mode === "add"}
            size="small"
            value={inputs.EMname}
            onChange={(e) => onChange(e.target.name, e.target.value)}
          />
        </Control>
        <Control expanded>
          <Label>FEIN Number</Label>
          <Input
            disabled={disabled}
            name="EMtaxidFEIN"
            placeholder="FEIN Number"
            size="small"
            value={inputs.EMtaxidFEIN}
            onChange={(e) => onChange(e.target.name, e.target.value)}
          />
        </Control>
        <Control expanded>
          <Label>Phone</Label>
          <PhoneNumberInput
            disabled={disabled}
            name="EMphone"
            placeholder="Phone"
            size="small"
            value={inputs.EMphone}
            onChange={onChange}
          />
        </Control>
      </Field>
      <Field kind="group">
        <Control expanded>
          <Label>Address 1</Label>
          <Input
            disabled={disabled}
            name="EMaddress1"
            placeholder="Address 1"
            required={mode === "add"}
            size="small"
            value={inputs.EMaddress1}
            onChange={(e) => onChange(e.target.name, e.target.value)}
          />
        </Control>
        <Control expanded>
          <Label>Address 2</Label>
          <Input
            disabled={disabled}
            name="EMaddress2"
            placeholder="Address 2"
            size="small"
            value={inputs.EMaddress2}
            onChange={(e) => onChange(e.target.name, e.target.value)}
          />
        </Control>
        <Control expanded>
          <Label>City</Label>
          <Input
            disabled={disabled}
            name="EMcity"
            placeholder="City"
            size="small"
            value={inputs.EMcity}
            onChange={(e) => onChange(e.target.name, e.target.value)}
          />
        </Control>
        <Control expanded>
          <StateSelect
            disabled={disabled}
            // id="state"
            label="State"
            name="EMstate"
            size="small"
            value={inputs.EMstate}
            onChange={onChange}
          />
        </Control>
        <Control expanded>
          <ZipInput
            disabled={disabled}
            label="Zip"
            name="EMzip"
            placeholder="Zip"
            size="small"
            value={inputs.EMzip}
            onChange={onChange}
          />
        </Control>
      </Field>
    </Field>
  </Field>
);

RatsEmployerEntryForm.propTypes = {
  inputs: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  mode: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
};

RatsEmployerEntryForm.defaultProps = {
  mode: "search",
  label: "",
  disabled: false,
};

export default RatsEmployerEntryForm;
