/* eslint-disable import/prefer-default-export */
import { gql } from "@apollo/client";

export const CREATE_PROBLEM_MUTATION = gql`
  mutation createProblem($data: ProblemCreateInput!) {
    createProblem(data: $data) {
      description
      problemcode
      status
      useridadded
      useridedited
    }
  }
`;

export const UPDATE_PROBLEM_MUTATION = gql`
  mutation updateProblem(
    $data: ProblemUpdateInput!
    $where: ProblemWhereUniqueInput!
  ) {
    updateProblem(data: $data, where: $where) {
      description
      problemcode
      status
      useridadded
      useridedited
    }
  }
`;

export const DELETE_PROBLEM_MUTATION = gql`
  mutation DELETE_PROBLEM_MUTATION($where: ProblemWhereUniqueInput!) {
    deleteProblem(where: $where) {
      problemcode
    }
  }
`;