import React from "react";
import { format } from "date-fns";
import { useParams } from "react-router-dom";
import { Column, Control, Input, Label, Field } from "rbx";
import { useQuery } from "@apollo/client";
import { SINGLE_DOCTOR_QUERY } from "../../../../../graphql";
import { usePermissions } from "../../../../../hooks";
import { convertTimeZone } from "../../../../../utils";

import DoctorDeleteButton from "../../../../Doctor/components/DoctorDeleteButton";

const Statistics = () => {
  const { doctorcode } = useParams();
  const isAdmin = usePermissions(["AllAdminFunc"]);

  const { data: doctorData } = useQuery(SINGLE_DOCTOR_QUERY, {
    variables: { where: { doctorcode: parseInt(doctorcode, 10) } },
  });

  const doctor = doctorData?.doctor;
  return (
    <Column.Group>
      <Column className="statistics-column" size={12}>
        <Field kind="group">
          <Control>
            <Label className="label">System Doctor Code</Label>
            <Input
              readOnly
              name="doctorcode"
              size="small"
              value={doctor?.doctorcode || ""}
            />
          </Control>
        </Field>
        <Field kind="group">
          <Control>
            <Label className="label">Added</Label>
            <Input
              readOnly
              name="dateadded"
              size="small"
              value={
                doctor?.dateadded
                  ? format(
                      convertTimeZone(doctor?.dateadded),
                      "MM/dd/yyyy hh:mm a"
                    )
                  : ""
              }
            />
          </Control>
          <Control>
            <Label className="label">By</Label>
            <Input
              readOnly
              name="useridadded"
              size="small"
              value={doctor?.useridadded || ""}
            />
          </Control>
        </Field>
        <Field kind="group">
          <Control>
            <Label className="label">Edited</Label>
            <Input
              readOnly
              name="dateedited"
              size="small"
              value={
                doctor?.dateedited
                  ? format(
                      convertTimeZone(doctor.dateedited),
                      "MM/dd/yyyy hh:mm a"
                    )
                  : ""
              }
            />
          </Control>
          <Control>
            <Label className="label">By</Label>
            <Input
              readOnly
              name="useridedited"
              size="small"
              value={doctor?.useridedited || ""}
            />
          </Control>
        </Field>
        <Field>
          {isAdmin && (
            <DoctorDeleteButton
              confirmationText="Are you sure you want to delete this Other Party?"
              doctorcode={doctorcode}
              errorText="Error deleting Other Party."
              helpText="This Other Party has cases associated with it and cannot be deleted."
              navigateTo="setup/other-party"
              source="otherparty"
              successText="Other Party deleted successfully."
            />
          )}
        </Field>
      </Column>
    </Column.Group>
  );
};

Statistics.propTypes = {};

Statistics.defaultProps = {};

export default Statistics;
