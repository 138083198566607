/* eslint-disable react/prop-types */
import React from "react";
import { Generic, Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const transformDocumentObjectToKey = (doc) =>
  `${doc.sfilename}_${doc.CaseItem?.dateadded}`;

const { REACT_APP_DOCUMENT_HOST } = process.env;
const DEFAULT_COLUMNS = [
  {
    id: "description",
    Header: "Description",
    accessor: "description",
  },
  {
    Header: "",
    id: "download",
    accessor: "sfilename",
    Cell: (cell) => (
      <Generic
        as="a"
        href={`http://${REACT_APP_DOCUMENT_HOST}${cell.value}`}
        rel="noopener noreferrer"
        style={{ textAlign: "center", display: "block" }}
        target="_blank"
        type="button"
      >
        <Icon color="link">
          <FontAwesomeIcon icon="download" />
        </Icon>
      </Generic>
    ),
  },
];

export const generateColumns = ({
  allSelected,
  selected,
  onSelect,
  onSelectAll,
  disabled,
}) => [
  {
    Header: (
      <div style={{ marginTop: 2, marginLeft: 5 }}>
        <input
          checked={allSelected}
          disabled={disabled}
          type="checkbox"
          onChange={() => onSelectAll(!allSelected)}
        />
      </div>
    ),
    id: "sfilename",
    accessor: "sfilename",
    defaultCanSort: false,
    disableSortBy: true,
    Cell: ({ row }) => (
      <div style={{ marginTop: 2, marginLeft: 5 }}>
        <input
          checked={selected.includes(
            transformDocumentObjectToKey(row.original)
          )}
          disabled={disabled}
          type="checkbox"
          onChange={() => onSelect(transformDocumentObjectToKey(row.original))}
        />
      </div>
    ),
  },
  ...DEFAULT_COLUMNS,
];

export default DEFAULT_COLUMNS;
