import React, { useState } from "react";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/client";
import { Title, Button } from "rbx";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import InvestigatorForm from "../InvestigatorForm";
import { useAuth } from "../../../../context";
import { getDateEST } from "../../../../utils";

import {
  CREATE_DOCTOR_MUTATION,
  CREATE_DOCTOR_SPECIALTY_MUTATION,
} from "../../../../graphql";

const INITIAL_STATE = {
  lastname: "",
  firstname: "",
  middleinitial: "",
  addr1: "",
  addr2: "",
  city: "",
  companyname: "",
  state: "",
  credentials: "",
  prefix: "",
  county: "",
  zip: "",
  phone: "",
  phoneExt: "",
  cellphone: "",
  faxNbr: "",
  emailAddr: "",
  status: "Active",
  prepaid: false,
  doctoremail: "",
  medicalrecordemail: "",
};

const convertInputToVariables = (variables, keys, adding = false) =>
  keys.reduce((acc, curr) => {
    if (variables[curr]) {
      acc[curr] = adding ? variables[curr] : { set: variables[curr] };
    }
    return acc;
  }, {});

const InvestigatorCreateModal = ({ onComplete }) => {
  const history = useHistory();
  const { state: authState } = useAuth();
  const [inputs, setInputs] = useState({ ...INITIAL_STATE });
  const [specialty, setSpecialty] = useState(null);
  const [createDoctor] = useMutation(CREATE_DOCTOR_MUTATION);
  const [createDoctorSpecialty] = useMutation(CREATE_DOCTOR_SPECIALTY_MUTATION);

  const handleSave = async (e) => {
    try {
      e.preventDefault();

      const date = getDateEST();
      const { data: CreateDoctorData } = await createDoctor({
        variables: {
          data: {
            ...convertInputToVariables(
              inputs,
              Object.keys(INITIAL_STATE),
              true
            ),
            OPType: "DR",
            schedulepriority: 3,
            usdint1: 1,
            ProvTypeCode: 2,
            dateadded: date,
            dateedited: date,
            useridadded: authState?.user?.userid,
            useridedited: authState?.user?.userid,
          },
        },
      });
      if (specialty) {
        await createDoctorSpecialty({
          variables: {
            data: {
              doctor: {
                connect: {
                  doctorcode: parseInt(
                    CreateDoctorData.createDoctor.doctorcode,
                    10
                  ),
                },
              },
              specialty: {
                connect: {
                  specialtycode: specialty,
                },
              },
            },
          },
        });
      }
      history.push(
        `/investigators/${CreateDoctorData.createDoctor.doctorcode}/profile`
      );
      toast.success(`Investigator created.`);
      onComplete();
    } catch (err) {
      toast.error(`Error creating investigator`);
    }
  };

  const handleChange = (name, value) => {
    setInputs((prev) => ({ ...prev, [name]: value }));
  };

  const handleSpecialtyChange = (name, value) => {
    setSpecialty(value);
  };

  return (
    <form id="doctor-create-form" onSubmit={handleSave}>
      <header className="page-head">
        <div className="page-head-start">
          <Title size={5}>Create Investigator</Title>
        </div>
        <div className="page-head-end">
          <Button.Group hasAddons>
            <Button
              size="small"
              type="button"
              onClick={() => onComplete(false)}
            >
              <span>Cancel</span>
            </Button>
            <Button
              color="primary"
              form="doctor-create-form"
              size="small"
              type="submit"
            >
              <span>Submit</span>
            </Button>
          </Button.Group>
        </div>
      </header>
      <hr />
      <InvestigatorForm
        adding
        handleSpecialtyChange={handleSpecialtyChange}
        inputs={inputs}
        specialty={specialty}
        onChange={handleChange}
      />
      <hr />
      {/* <pre>
        <code>{JSON.stringify(inputs, null, 2)}</code>
      </pre> */}
    </form>
  );
};

InvestigatorCreateModal.propTypes = {
  onComplete: PropTypes.func,
};

InvestigatorCreateModal.defaultProps = {
  onComplete: () => null,
};

export default InvestigatorCreateModal;
