import "./Claim.scss";

import React from "react";

import { Switch, Route } from "react-router-dom";

import ClaimPage from "./ClaimPage";
import ClaimListPage from "./ClaimListPage";

const ClaimRouter = (props) => (
  <Switch>
    <Route path="/claims/:claimcode">
      <ClaimPage />
    </Route>
    <Route path="/claims">
      <ClaimListPage />
    </Route>
  </Switch>
);

export default ClaimRouter;
