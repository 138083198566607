import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { useApolloClient, gql } from "@apollo/client";

import { Editor } from "@tinymce/tinymce-react";
import { DateTime } from "luxon";
import { Field, Control, Button, Label } from "rbx";

import Mustache from "mustache";

import { toast } from "react-toastify";
import {
  SINGLE_VWDOCUMENT_QUERY,
  ALL_EXAMINEE_CCS_QUERY,
  LIST_ACCT_TRANS,
  FIND_FIRST_ACCT_HEADER_QUERY,
} from "../../../../graphql";
import { buildDetailsWithoutCptCodeTable } from "../../../../utils";
import CaseSelect from "../../../../components/CaseSelect";

const Test = ({ inputs }) => {
  const client = useApolloClient();
  const [casenbr, setCasenbr] = useState(830513);
  const [template, setTemplate] = useState("");
  const [loading, setLoading] = useState(false);

  const handleChange = (n, v) => {
    setCasenbr(v);
  };

  const handleReset = () => {
    setTemplate(inputs?.template);
  };

  const handleTest = async () => {
    try {
      setLoading(true);
      const {
        data: { caseItem, caseIssues, caseProblems },
      } = await client.query({
        query: SINGLE_CASE_QUERY,
        variables: {
          caseItemWhere: { casenbr: parseInt(casenbr, 10) },
          caseIssueWhere: { casenbr: { equals: parseInt(casenbr, 10) } },
          caseProblemWhere: { casenbr: { equals: parseInt(casenbr, 10) } },
        },
      });
      const {
        data: { vwDocument },
      } = await client.query({
        query: SINGLE_VWDOCUMENT_QUERY,
        variables: { where: { casenbr: parseInt(casenbr, 10) } },
      });

      const {
        data: { examineeCCS },
      } = await client.query({
        query: ALL_EXAMINEE_CCS_QUERY,
        variables: {
          where: { chartnbr: { equals: parseInt(caseItem.chartnbr, 10) } },
        },
      });

      const {
        data: { findManyAcctTrans },
      } = await client.query({
        query: LIST_ACCT_TRANS,
        variables: {
          where: { casenbr: { equals: parseInt(casenbr, 10) } },
        },
      });

      const {
        data: { findFirstAcctHeader },
      } = await client.query({
        query: FIND_FIRST_ACCT_HEADER_QUERY,
        variables: {
          where: {
            documenttype: { equals: "IN" },
            casenbr: { equals: parseInt(casenbr, 10) },
          },
        },
      });

      const doctor = caseItem?.doctor;
      const service = caseItem?.service;
      const caseClient = caseItem?.client;
      const examinee = caseItem?.examinee;
      const location = caseItem?.location;

      const view = {
        acctTrans: findManyAcctTrans,
        problems: caseProblems.map((caseProblem) => caseProblem.problem),
        issues: caseIssues.map((caseIssue) => caseIssue.issue),
        doctor: {
          ...doctor,
          FormalName: `${doctor?.prefix || ""} ${doctor?.firstname || ""} ${
            doctor?.lastname || ""
          }, ${doctor?.credentials || ""}`,
        },
        doctorLocations: doctor?.DoctorLocation?.map(
          (doctorLocation) => doctorLocation.location
        ),
        doctorSpecialties: doctor?.DoctorSpecialty?.map(
          (doctorSpecialty) => doctorSpecialty.specialty
        ),
        ccFields: examineeCCS?.map((examineeCC) => ({
          ...examineeCC,
          ...examineeCC?.ccAddress,
        })),
        case: {
          ...caseItem,
          ApptLocation: caseItem?.doctorLocation?.location?.location,
          dateofinjury: caseItem.dateofinjury
            ? DateTime.fromISO(caseItem.dateofinjury)
                .setZone("utc")
                .toFormat("D")
            : "",
          CurrentDate: DateTime.now()
            .setZone("utc")
            .toFormat("LLL d, y")
            .toString(),
          issuesWithInstructions: caseIssues
            .map((caseIssue) =>
              [caseIssue?.issue?.description, caseIssue?.issue?.instruction]
                .filter(Boolean)
                .join(" - ")
            )
            .join(", "),
        },
        CurrentDate: DateTime.now()
          .setZone("utc")
          .toFormat("LLL d, y")
          .toString(),
        examinee,
        client: caseClient,
        location,
        service,
        view: {
          ...vwDocument,
          ApptDate: vwDocument.ApptDate
            ? DateTime.fromISO(vwDocument.ApptDate).setZone("utc").toFormat("D")
            : "",
          Appttime: vwDocument.Appttime
            ? DateTime.fromISO(vwDocument.Appttime).setZone("utc").toFormat("t")
            : "",
          DOB: vwDocument.DOB
            ? DateTime.fromISO(vwDocument.DOB).setZone("utc").toFormat("D")
            : "",
          datecalledin: vwDocument.datecalledin
            ? DateTime.fromISO(vwDocument.datecalledin)
                .setZone("utc")
                .toFormat("D")
            : "",
          DOI: vwDocument.DOI
            ? DateTime.fromISO(vwDocument.DOI).setZone("utc").toFormat("D")
            : "",
          medsrecd: vwDocument.medsrecd
            ? DateTime.fromISO(vwDocument.medsrecd).setZone("utc").toFormat("D")
            : "",
          invoicedate: vwDocument.invoicedate
            ? DateTime.fromISO(vwDocument.invoicedate)
                .setZone("utc")
                .toFormat("D")
            : "",
          commitdate: vwDocument.commitdate
            ? DateTime.fromISO(vwDocument.commitdate)
                .setZone("utc")
                .toFormat("D")
            : "",
          caseusddate1: vwDocument.caseusddate1
            ? DateTime.fromISO(vwDocument.caseusddate1)
                .setZone("utc")
                .toFormat("D")
            : "",
          caseusddate2: vwDocument.caseusddate2
            ? DateTime.fromISO(vwDocument.caseusddate2)
                .setZone("utc")
                .toFormat("D")
            : "",
          clientusddate1: vwDocument.clientusddate1
            ? DateTime.fromISO(vwDocument.clientusddate1)
                .setZone("utc")
                .toFormat("D")
            : "",
          clientusddate2: vwDocument.clientusddate2
            ? DateTime.fromISO(vwDocument.clientusddate2)
                .setZone("utc")
                .toFormat("D")
            : "",
          examineeusddate1: vwDocument.examineeusddate1
            ? DateTime.fromISO(vwDocument.examineeusddate1)
                .setZone("utc")
                .toFormat("D")
            : "",
          examineeusddate2: vwDocument.examineeusddate2
            ? DateTime.fromISO(vwDocument.examineeusddate2)
                .setZone("utc")
                .toFormat("D")
            : "",
          doctorusddate1: vwDocument.doctorusddate1
            ? DateTime.fromISO(vwDocument.doctorusddate1)
                .setZone("utc")
                .toFormat("D")
            : "",
          doctorusddate2: vwDocument.doctorusddate2
            ? DateTime.fromISO(vwDocument.doctorusddate2)
                .setZone("utc")
                .toFormat("D")
            : "",
          companyusddate1: vwDocument.companyusddate1
            ? DateTime.fromISO(vwDocument.companyusddate1)
                .setZone("utc")
                .toFormat("D")
            : "",
          companyusddate2: vwDocument.companyusddate2
            ? DateTime.fromISO(vwDocument.companyusddate2)
                .setZone("utc")
                .toFormat("D")
            : "",
          HearingDate: vwDocument.HearingDate
            ? DateTime.fromISO(vwDocument.HearingDate)
                .setZone("utc")
                .toFormat("D")
            : "",
          prevappt: vwDocument.prevappt
            ? DateTime.fromISO(vwDocument.prevappt).setZone("utc").toFormat("D")
            : "",
          OCF25Date: vwDocument.OCF25Date
            ? DateTime.fromISO(vwDocument.OCF25Date)
                .setZone("utc")
                .toFormat("D")
            : "",
          DateForminDispute: vwDocument.DateForminDispute
            ? DateTime.fromISO(vwDocument.DateForminDispute)
                .setZone("utc")
                .toFormat("D")
            : "",
          DateReceived: vwDocument.DateReceived
            ? DateTime.fromISO(vwDocument.DateReceived)
                .setZone("utc")
                .toFormat("D")
            : "",
          caseusddate3: vwDocument.caseusddate3
            ? DateTime.fromISO(vwDocument.caseusddate3)
                .setZone("utc")
                .toFormat("D")
            : "",
          caseusddate4: vwDocument.caseusddate4
            ? DateTime.fromISO(vwDocument.caseusddate4)
                .setZone("utc")
                .toFormat("D")
            : "",
          caseusddate5: vwDocument.caseusddate5
            ? DateTime.fromISO(vwDocument.caseusddate5)
                .setZone("utc")
                .toFormat("D")
            : "",
          doctorusddate3: vwDocument.doctorusddate3
            ? DateTime.fromISO(vwDocument.doctorusddate3)
                .setZone("utc")
                .toFormat("D")
            : "",
          doctorusddate4: vwDocument.doctorusddate4
            ? DateTime.fromISO(vwDocument.doctorusddate4)
                .setZone("utc")
                .toFormat("D")
            : "",
          doctorusddate5: vwDocument.doctorusddate5
            ? DateTime.fromISO(vwDocument.doctorusddate5)
                .setZone("utc")
                .toFormat("D")
            : "",
          doctorusddate6: vwDocument.doctorusddate6
            ? DateTime.fromISO(vwDocument.doctorusddate6)
                .setZone("utc")
                .toFormat("D")
            : "",
          doctorusddate7: vwDocument.doctorusddate7
            ? DateTime.fromISO(vwDocument.doctorusddate7)
                .setZone("utc")
                .toFormat("D")
            : "",
          ExternalDueDate: vwDocument.ExternalDueDate
            ? DateTime.fromISO(vwDocument.ExternalDueDate)
                .setZone("utc")
                .toFormat("D")
            : "",
          InternalDueDate: vwDocument.InternalDueDate
            ? DateTime.fromISO(vwDocument.InternalDueDate)
                .setZone("utc")
                .toFormat("D")
            : "",
        },
        detailsWithoutCptCode: Array.isArray(findFirstAcctHeader?.acctDetails)
          ? buildDetailsWithoutCptCodeTable(findFirstAcctHeader.acctDetails)
          : "",
      };
      const result = Mustache.render(template, view);

      setTemplate(result);
    } catch (err) {
      toast.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setTemplate(inputs.template);
  }, [inputs.template]);

  return (
    <div className="document-test-page">
      <Field kind="group">
        <CaseSelect label="Case" value={casenbr} onChange={handleChange} />
        <Control>
          <Label>&nbsp;</Label>
          <Button
            color="danger"
            size="small"
            type="button"
            onClick={handleReset}
          >
            Reset
          </Button>
        </Control>
        <Control>
          <Label>&nbsp;</Label>
          <Button
            color="warning"
            disabled={loading}
            size="small"
            type="button"
            onClick={handleTest}
          >
            {loading ? "Loading..." : "Test"}
          </Button>
        </Control>
      </Field>

      <Editor
        apiKey="1kgbjo3jtvqp585j2a6sbitmyybdrgp2j2hm6xtzk67dqf3m"
        init={{
          deprecation_warnings: false,
          height: "70vh",
          menubar: false,
          toolbar: false,
          content_style: `body p { padding: 0; margin: 0; line-height: 1.25; } .tox-checklist {
            display: block;
          } .tox-checklist-horizontal {
            display: inline-flex;
            padding-left: 0;
          } .tox-checklist-horizontal > li:not(.tox-checklist--hidden) { margin-left: 35px; } 
          .vertical-text { writing-mode: vertical-rl; transform: scale(-1); }
          `,
        }}
        value={template}
      />
    </div>
  );
};

Test.propTypes = {
  inputs: PropTypes.object,
};

Test.defaultProps = {
  inputs: {},
};

const SINGLE_CASE_QUERY = gql`
  query SINGLE_CASE_QUERY(
    $caseItemWhere: CaseItemWhereUniqueInput!
    $caseIssueWhere: CaseIssueWhereInput!
    $caseProblemWhere: CaseProblemWhereInput!
  ) {
    caseItem(where: $caseItemWhere) {
      ApptDate
      Appttime
      AttorneyNote
      BillingNote
      CaseCompletedDate
      CertMailNbr
      CertifiedMail
      DateForminDispute
      DateReceived
      DoctorName
      ExternalDueDate
      HearingDate
      ICD9Code2
      ICD9Code3
      ICD9Code4
      IMECompany
      IMEMDName
      InternalDueDate
      Jurisdiction
      LastScheduledBy
      LastScheduledDate
      MedsReceivedDate
      typemedsrecd
      OCF25Date
      PanelNbr
      PublishOnWeb
      QARep
      TreatingPhysician
      VoucherFeeOverride
      WCBNbr
      WebGUID
      WebNotifyEmail
      allegation
      bComplete
      bhanddelivery
      billARKey
      billaddr1
      billaddr2
      billcity
      billclientcode
      billcompany
      billcontact
      billedselect
      billfax
      billstate
      billzip
      calledinby
      casenbr
      casetype
      chartnbr
      claimnbr
      clientcode
      commitdate
      dateadded
      datedrchart
      dateedited
      datemedsrecd
      dateofinjury
      defenseattorneycode
      doctorcode
      doctorlocation
      doctorspecialty
      drchartselect
      emailPattny
      emailclient
      emaildoctor
      faxPattny
      faxclient
      faxdoctor
      feecode
      icd9code
      invoiceamt
      invoicedate
      issuecode
      laststatuschg
      location {
        locationcode
        location
        addr1
        addr2
        city
        state
        zip
        notes
      }
      marketercode
      mastercasenbr
      mastersubcase
      miscselect
      notes
      officecode
      photoRqd
      plaintiffattorneycode
      prevappt
      priority
      reccode
      referralmethod
      referraltype
      reportverbal
      requesteddoc
      rptstatus
      schedcode
      schedulenotes
      schedulercode
      servicecode
      sinternalcasenbr
      specialinstructions
      sreqdegree
      sreqspecialty
      status
      usddate1
      usddate2
      usddate3
      usddate4
      usdint1
      usdint2
      usdmoney1
      usdmoney2
      usdtext1
      usdtext2
      usdvarchar1
      usdvarchar2
      useridadded
      useridedited
      voucheramt
      voucherdate
      voucherselect
      doctor {
        Country
        ImportSpecialty
        NPINbr
        OPSubType
        OPType
        ProvTypeCode
        PublishOnWeb
        SSNTaxID
        UPIN
        UnRegNbr
        WCNbr
        WebUserID
        addr1
        addr2
        cellphone
        city
        companyname
        county
        credentials
        dateadded
        dateedited
        doctorcode
        doctoremail
        emailAddr
        faxNbr
        feecode
        firstname
        lastname
        licensenbr
        medicalrecordemail
        middleinitial
        notes
        phone
        phoneExt
        prefix
        prepaid
        prodcode
        qualifications
        schedulepriority
        state
        status
        usddate1
        usddate2
        usddate3
        usddate4
        usddate6
        usddate7
        usdint1
        usdint2
        usdmoney1
        usdmoney2
        usdtext1
        usdtext2
        usdvarchar1
        usdvarchar2
        usdvarchar3
        useridadded
        useridedited
        zip
        DoctorLocation {
          doctorcode
          locationcode
          location {
            locationcode
            location
            notes
            Phone
          }
        }
        DoctorSpecialty {
          doctorcode
          specialtycode
          specialty {
            specialtycode
            description
            dateadded
            useridadded
            dateedited
            useridedited
            PublishOnWeb
            WebSynchDate
            LOB
          }
        }
      }
      service {
        CalcFrom
        DaysToCommitDate
        ESendInsured
        ExcludeOnDash
        FinanceDescription
        HideExamDateInfo
        IsReview
        IsSIU
        IsSurv
        LOB
        PhysicalAppt
        ProjRev
        PublishOnWeb
        ServShortName
        ServiceType
        ShowAsPendingSupp
        ShowLegalTabOnCase
        WebID
        WebSynchDate
        dateadded
        dateedited
        description
        officecode
        servicecode
        shortdesc
        status
        useridadded
        useridedited
      }
      client {
        ARKey
        CertifiedMail
        Country
        DefOfficeCode
        DocumentPublish
        ProcessorEmail
        ProcessorFax
        ProcessorFirstName
        ProcessorLastName
        ProcessorPhone
        ProcessorPhoneExt
        PublishOnWeb
        QARep
        TypeCode
        WebGUID
        WebUserID
        WebUserName
        Webpassword
        addr1
        addr2
        billaddr1
        billaddr2
        billattn
        billcity
        billfax
        billstate
        billzip
        casetype
        city
        clientcode
        clientnbrold
        companycode
        dateadded
        dateedited
        doNotInherit
        documentemail
        documentfax
        documentmail
        email
        fax
        firstname
        lastappt
        lastname
        marketercode
        notes
        phone1
        phone1ext
        phone2
        phone2ext
        photoRqd
        prefix
        priority
        reportphone
        state
        status
        suffix
        title
        usddate1
        usddate2
        usdint1
        usdint2
        usdmoney1
        usdmoney2
        usdtext1
        usdtext2
        usdvarchar1
        usdvarchar2
        useridadded
        useridedited
        zip
      }
      examinee {
        Country
        DOB
        EmployerAddr1
        EmployerCity
        EmployerContactFirstName
        EmployerContactLastName
        EmployerEmail
        EmployerFax
        EmployerPhone
        EmployerPhoneExt
        EmployerState
        EmployerZip
        InsuredAddr1
        InsuredCity
        InsuredEmail
        InsuredFax
        InsuredPhone
        InsuredPhoneExt
        InsuredState
        InsuredZip
        LastInitial
        SSN
        TreatingPhysicianAddr1
        TreatingPhysicianCity
        TreatingPhysicianEmail
        TreatingPhysicianFax
        TreatingPhysicianLicenseNbr
        TreatingPhysicianPhone
        TreatingPhysicianPhoneExt
        TreatingPhysicianState
        TreatingPhysicianTaxID
        TreatingPhysicianZip
        addr1
        addr2
        chartnbr
        city
        county
        dateadded
        dateedited
        email
        employer
        fax
        firstname
        insured
        lastname
        middleinitial
        note
        oldchartnbr
        phone1
        phone2
        policynumber
        prefix
        sex
        state
        treatingphysician
        usddate1
        usddate2
        usdint1
        usdint2
        usdmoney1
        usdmoney2
        usdtext1
        usdtext2
        usdvarchar1
        usdvarchar2
        useridadded
        useridedited
        zip
      }
      doctorLocation {
        doctorcode
        locationcode
        location {
          locationcode
          location
          notes
          Phone
        }
      }
    }
    caseIssues(where: $caseIssueWhere) {
      issue {
        description
        PublishOnWeb
        WebID
        WebSynchDate
        dateadded
        dateedited
        instruction
        issuecode
        status
        useridadded
        useridedited
      }
    }
    caseProblems(where: $caseProblemWhere) {
      problem {
        problemcode
        description
        status
        dateadded
        useridadded
        dateedited
        useridedited
        PublishOnWeb
        WebSynchDate
      }
    }
  }
`;

export default Test;
