import React, { useState } from "react";
import PropTypes from "prop-types";
import { Title, Button, Field, Control } from "rbx";

import UserFunctionForm from "../UserFunctionForm";
import { useModal } from "../../../../../context";
import Confirmation from "../../../../../components/Confirmation";

const INITIAL_STATE = {
  functioncode: "",
  functiondesc: "",
};

const UserFunctionModal = ({ adding, onComplete, values, onDelete }) => {
  const { setModalOpen } = useModal();

  const [inputs, setInputs] = useState(adding ? INITIAL_STATE : values);
  const [updated, setUpdated] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleChange = (name, value) => {
    setInputs((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (!adding) {
      setUpdated((prev) => (prev.includes(name) ? prev : [...prev, name]));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    onComplete(adding, inputs, values?.functioncode, adding ? null : updated);
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    setModalOpen(
      true,
      <Confirmation
        message="Are you sure you want to delete this User Function?"
        onCancel={() =>
          setModalOpen(
            true,
            <UserFunctionModal
              adding={adding}
              values={values}
              onComplete={onComplete}
              onDelete={onDelete}
            />
          )
        }
        onConfirm={() => {
          setModalOpen(false);
          onDelete(values.functioncode);
        }}
      />
    );
  };

  const isDisabled =
    !inputs.functioncode ||
    !inputs.functiondesc ||
    (!adding && !updated?.length) ||
    loading;

  return (
    <form id="problem-form" onSubmit={handleSubmit}>
      <header className="page-head">
        <div className="page-head-start">
          <Title size={5}>
            {[adding ? "Create" : "Edit", "User Function"].join(" ")}
          </Title>
        </div>
        <div className="page-head-end">
          <Button.Group hasAddons>
            <Button
              size="small"
              type="button"
              onClick={() => onComplete(false)}
            >
              <span>Cancel</span>
            </Button>

            <Button
              color="primary"
              disabled={isDisabled}
              form="problem-form"
              size="small"
              state={loading ? "loading" : ""}
              type="submit"
            >
              <span>Submit</span>
            </Button>
          </Button.Group>
        </div>
      </header>
      <hr />
      <UserFunctionForm
        functioncode={values?.functioncode || ""}
        inputs={inputs}
        onChange={handleChange}
      />
      <hr />
      {!adding && (
        <Field>
          <Control>
            <Button
              color="danger"
              size="small"
              type="button"
              onClick={handleDelete}
            >
              <span>Delete</span>
            </Button>
          </Control>
        </Field>
      )}
    </form>
  );
};

UserFunctionModal.propTypes = {
  adding: PropTypes.bool,
  onComplete: PropTypes.func,
  values: PropTypes.object,
  onDelete: PropTypes.func,
};

UserFunctionModal.defaultProps = {
  adding: false,
  onComplete: () => null,
  values: null,
  onDelete: () => null,
};

export default UserFunctionModal;
