import React, { useState, useEffect } from "react";

import { Link, useLocation, useHistory } from "react-router-dom";
import { Icon, Container } from "rbx";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ClickCapture from "../ClickCapture";

import "./Sidebar.scss";

import { NestedMenu } from "./components";

import routes from "./routes";

import { useAuth, useModal } from "../../context";
import UserActionButton from "../UserActionButton";
import UpdatePasswordModal from "../UpdatePasswordModal";
import ReleaseNotesModal from "../ReleaseNotes";

function Sidebar() {
  const history = useHistory();
  const location = useLocation();
  const { setModalOpen } = useModal();
  const [menuActive, setMenuActive] = useState(false);
  const [activeMenus, setActiveMenus] = useState({});
  const [activeRoute, setActiveRoutes] = useState({});

  const [availableRoutes, setAvailableRoutes] = useState([]);

  const { handleLogout, handleUpdatePassword, state: authState } = useAuth();

  useEffect(() => {
    const userSecurities = authState?.user?.userSecurities;
    if (userSecurities && Array.isArray(userSecurities)) {
      setAvailableRoutes(
        routes.filter(
          (route) =>
            !route.permissions ||
            route.permissions.some((perm) =>
              userSecurities.some((x) => perm === x.groupcode)
            )
        )
      );
    }
  }, [authState?.user?.userSecurities, location.pathname]);

  useEffect(() => {
    setMenuActive(false);
  }, [location.pathname]);

  const toggleActiveMenus = (name) =>
    setActiveMenus((prev) => ({ ...prev, [name]: !activeMenus[name] }));

  const toggleActiveRoutes = (id) => {
    const route = routes[id];
    if (route.to) {
      history.push(route.to);
      setMenuActive(false);
    } else {
      setActiveRoutes(routes[id]);
      setMenuActive(true);
    }
  };

  // close sidebar menu when ESC key is pressed
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (menuActive && e.keyCode === 27) {
        setMenuActive(false);
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  });

  const handleChangePassword = (e) => {
    e.preventDefault();
    const closeModal = () => setModalOpen(false);
    setModalOpen(
      true,
      <UpdatePasswordModal
        onCancel={closeModal}
        onConfirm={async (Password, ConfirmPassword) => {
          handleUpdatePassword(Password, ConfirmPassword, closeModal);
        }}
      />
    );
  };

  const handleReleaseNotes = (e) => {
    e.preventDefault();
    const closeModal = () => setModalOpen(false);
    setModalOpen(true, <ReleaseNotesModal onCancel={closeModal} />);
  };

  return (
    <nav className="navigation-bar">
      <ClickCapture onOutsideClick={() => setMenuActive(false)}>
        <Container fluid style={{ paddingLeft: "1rem" }}>
          <div className="navigation">
            <div className="navigation-start">
              <div className="navigation-item">
                <NestedMenu
                  activeMenus={activeMenus}
                  isActive={menuActive}
                  links={activeRoute.links}
                  name={activeRoute.name}
                  toggleMenus={toggleActiveMenus}
                />
              </div>
              <Link
                className="navigation-item navigation-item--brand"
                style={{ display: "flex" }}
                to="/"
              >
                <img alt="APOLLO" src="/logo.png" style={{ height: 40 }} />
              </Link>
            </div>
            <div className="navigation-end">
              <div className="navigation-item">
                <img alt="ISG" src="/isg-logo.png" style={{ height: 40 }} />
              </div>
            </div>
          </div>
        </Container>
        <div className="sidebar">
          <div className="sidebar-container">
            <div className="sidebar-menu">
              <div className="sidebar-options">
                <Link
                  className={[
                    "sidebar-menu-item",
                    location.pathname === "/" && "is-current",
                  ]
                    .filter(Boolean)
                    .join(" ")}
                  to="/"
                >
                  <Icon size="large">
                    <FontAwesomeIcon icon="home" />
                  </Icon>
                  <span>Home</span>
                </Link>
                {availableRoutes.map((r, i) => (
                  <div
                    aria-label="Logout"
                    className={`sidebar-menu-item ${
                      activeRoute === routes[i] && menuActive ? "is-active" : ""
                    } ${r.active?.(location) ? "is-current" : ""}`}
                    key={r.name}
                    role="button"
                    tabIndex="0"
                    onClick={() => toggleActiveRoutes(i)}
                    onKeyDown={() => toggleActiveRoutes(i)}
                  >
                    <Icon size="large">
                      <FontAwesomeIcon icon={r.icon} />
                    </Icon>
                    <span>{r.label}</span>
                  </div>
                ))}
              </div>
              <UserActionButton
                onChangePassword={handleChangePassword}
                onLogout={handleLogout}
                onReleaseNotes={handleReleaseNotes}
              />

              {/* <div
                className="sidebar-menu-item bottom"
                role="button"
                tabIndex="-1"
                onClick={handleLogout}
                onKeyDown={() => null}
              >
                <Icon size="large">
                  <FontAwesomeIcon icon="sign-out-alt" />
                </Icon>
                <span>{authState?.user?.userid || "Logout"}</span>
              </div> */}
            </div>
          </div>
        </div>
      </ClickCapture>
    </nav>
  );
}

Sidebar.propTypes = {};

export default Sidebar;
