import React from "react";
import PropTypes from "prop-types";
import { Control, Field, Input, Box, Label } from "rbx";

import BooleanInput from "../../../../components/BooleanInput";
import ReferralStyledSubtitle from "../ReferralStyledSubtitle";

const MedicalServiceInstructions = ({ onChange, inputs }) => (
  <Box>
    <ReferralStyledSubtitle>
      Medical Service Instructions
    </ReferralStyledSubtitle>
    <Field kind="group">
      <Control expanded>
        <Label>Date Exam Needs To Be Completed By</Label>
        <Input
          name="DateExamNeededBy"
          size="small"
          type="date"
          value={inputs.DateExamNeededBy}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
      <Control expanded>
        <Label>Discovery End Date</Label>
        <Input
          name="DiscoveryEndDate"
          size="small"
          type="date"
          value={inputs.DiscoveryEndDate}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
    </Field>
    <div className="checkbox-wrapper">
      <Field kind="group">
        <Control expanded>
          <BooleanInput
            label="Next Day Report"
            name="NextDayReport"
            value={inputs.NextDayReport}
            onChange={(name, value) => onChange(name, value)}
          />
        </Control>
        <Control expanded>
          <BooleanInput
            label="Re-evaluation"
            name="Reevaluation"
            value={inputs.Reevaluation}
            onChange={(name, value) => onChange(name, value)}
          />
        </Control>
        <Control expanded>
          <BooleanInput
            label="Litigated"
            name="Litigated"
            value={inputs.Litigated}
            onChange={(name, value) => onChange(name, value)}
          />
        </Control>
        <Control expanded>
          <BooleanInput
            label="Board Directed"
            name="BoardDirected"
            value={inputs.BoardDirected}
            onChange={(name, value) => onChange(name, value)}
          />
        </Control>
        <Control expanded>
          <BooleanInput
            label="Claimant Videographer Attending"
            name="ClaimantVideographerAttending"
            value={inputs.ClaimantVideographerAttending}
            onChange={(name, value) => onChange(name, value)}
          />
        </Control>
      </Field>
    </div>
    <Field kind="group">
      <Control expanded>
        <Label>NY Board Number</Label>
        <Input
          name="NYBoardNumber"
          size="small"
          value={inputs.NYBoardNumber}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
      <Control expanded>
        <Label>NY District Office</Label>
        <Input
          name="NYDistrictOffice"
          size="small"
          value={inputs.NYDistrictOffice}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
    </Field>
    {/* <Field>
      <SpecialtyMultiSelect
        fullWidth
        label="Requested Specialty"
        name="RequestedSpecialty"
        value={inputs.RequestedSpecialty}
        onChange={(e) => onChange("RequestedSpecialty", e)}
      />
    </Field> */}
  </Box>
);

MedicalServiceInstructions.propTypes = {
  onChange: PropTypes.func.isRequired,
  inputs: PropTypes.object.isRequired,
};

export default MedicalServiceInstructions;
