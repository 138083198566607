/* eslint-disable react/no-array-index-key */
import React from "react";
import PropTypes from "prop-types";
import { Field, Box, Control, Textarea, Label, Select, Input } from "rbx";
import BooleanInput from "../../../../components/BooleanInput";
import StateSelect from "../../../../components/StateSelect";
import ReferralStyledSubtitle from "../ReferralStyledSubtitle";

const SurveillanceActivityCheck = ({ inputs, onChange, surveillancePicked }) => (
  <Box>
    <ReferralStyledSubtitle>
      Surveillance / Activity Check
    </ReferralStyledSubtitle>
    <Field kind="group">
      <Control expanded>
        <Label>Surveillance Amount</Label>
        <Select.Container fullwidth size="small">
          <Select
            name="SurveillanceAmount"
            required={surveillancePicked}
            value={inputs.SurveillanceAmount}
            onChange={(e) => onChange(e.target.name, e.target.value)}
          >
            <Select.Option value="" />
            {Array.from({ length: 5 }).map((_, i) => (
              <Select.Option key={i} value={(i + 1) * 8}>
                {(i + 1) * 8}
              </Select.Option>
            ))}
          </Select>
        </Select.Container>
      </Control>
      <Control expanded>
        <Label>Other Amount</Label>
        <Input
          name="OtherAmount"
          size="small"
          type="number"
          value={inputs.OtherAmount}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
    </Field>
    <Field>
      <Control>
        <Label>Surveillance Instructions / Objectives</Label>
        <Textarea
          name="SurveillanceInstructions"
          size="small"
          value={inputs.SurveillanceInstructions}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
    </Field>
    <Field>
      <Control>
        <BooleanInput
          label="Upcoming Appointment"
          name="UpcomingAppointment"
          value={inputs.UpcomingAppointment}
          onChange={(name, value) => onChange(name, value)}
        />
      </Control>
    </Field>
    {inputs.UpcomingAppointment && (
      <React.Fragment>
        <Field className="grid-override is-three" kind="group">
          <Control expanded>
            <Label>Type</Label>
            <Select.Container fullwidth size="small">
              <Select
                name="Type"
                value={inputs.Type}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              >
                <Select.Option value="" />
                <Select.Option value="Physician">Physician</Select.Option>
                <Select.Option value="IME">IME</Select.Option>
                <Select.Option value="Other">Other</Select.Option>
              </Select>
            </Select.Container>
          </Control>
          <Control expanded>
            <Label>Appointment Date</Label>
            <Input
              name="AppointmentDate"
              size="small"
              type="date"
              value={inputs.AppointmentDate}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </Control>
          <Control expanded>
            <Label>Appointment Time</Label>
            <Input
              name="AppointmentTime"
              size="small"
              type="time"
              value={inputs.AppointmentTime}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </Control>
        </Field>
        <Field className="grid-override is-three" kind="group">
          <Control expanded>
            <Label>Physician Name</Label>
            <Input
              name="PhysicianName"
              size="small"
              value={inputs.PhysicianName}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </Control>
          <Control expanded>
            <Label>Address</Label>
            <Input
              name="Address"
              size="small"
              value={inputs.Address}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </Control>
          <Control expanded>
            <Label>City</Label>
            <Input
              name="City"
              size="small"
              value={inputs.City}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </Control>
        </Field>
        <Field className="grid-override is-three" kind="group">
          <StateSelect
            displayFullLabel
            label="State"
            name="State"
            size="small"
            value={inputs.State}
            onChange={onChange}
          />
          <Control expanded>
            <Label>Zip Code</Label>
            <Input
              name="Zip"
              size="small"
              value={inputs.Zip}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </Control>
          <Control>
            <Label>&nbsp;</Label>
            <BooleanInput
              label="Transportation Provided"
              name="TransportationProvided"
              value={inputs.TransportationProvided}
              onChange={(name, value) => onChange(name, value)}
            />
          </Control>
        </Field>
      </React.Fragment>
    )}
  </Box>
);
SurveillanceActivityCheck.propTypes = {
  inputs: PropTypes.object,
  surveillancePicked: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
};

SurveillanceActivityCheck.defaultProps = {
  inputs: {},
  onChange: () => null,
};

export default SurveillanceActivityCheck;
