import React from "react";
import PropTypes from "prop-types";

import { ModalProvider } from "./ModalContext";
import { AuthProvider } from "./AuthContext";
import { ExceptionProvider } from "./ExceptionContext";

export { useAuth } from "./AuthContext";
export { useModal } from "./ModalContext";
export { useExceptions } from "./ExceptionContext";

const ContextProvider = ({ children }) => (
  <AuthProvider>
    <ExceptionProvider>
      <ModalProvider>{children}</ModalProvider>
    </ExceptionProvider>
  </AuthProvider>
);

ContextProvider.propTypes = {
  children: PropTypes.node,
};
ContextProvider.defaultProps = {
  children: null,
};
export default ContextProvider;
