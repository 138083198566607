import { gql } from "@apollo/client";

export const SINGLE_USER_SECURITY_QUERY = gql`
  query userSecurity($where: UserSecurityWhereUniqueInput!) {
    userSecurity(where: $where) {
      userid
      groupcode
    }
  }
`;

export const LIST_USER_SECURITIES_QUERY = gql`
  query userSecurities(
    $take: Int
    $skip: Int
    $orderBy: [UserSecurityOrderByInput!]
    $where: UserSecurityWhereInput
  ) {
    userSecurities(take: $take, orderBy: $orderBy, skip: $skip, where: $where) {
      userid
      groupcode
      officecode
    }
    aggregateUserSecurity(where: $where) {
      count {
        userid
      }
    }
  }
`;

export const ALL_USER_SECURITIES_QUERY = gql`
  query userSecurities(
    $orderBy: [UserSecurityOrderByInput!]
    $where: UserSecurityWhereInput
  ) {
    userSecurities(orderBy: $orderBy, where: $where, take: 1000) {
      userid
      groupcode
      officecode
      office {
        officecode
        description
        shortdesc
      }
    }
  }
`;
