/* eslint-disable import/prefer-default-export */
import { gql } from "@apollo/client";

export const LIST_RPT_STATUSES_QUERY = gql`
  query rptStatuses(
    $take: Int
    $orderBy: [RptStatusOrderByInput!]
    $skip: Int
    $where: RptStatusWhereInput
  ) {
    rptStatuses(take: $take, orderBy: $orderBy, skip: $skip, where: $where) {
      rptcode
      description
      dateadded
      useridadded
      dateedited
      useridedited
    }
    aggregateRptStatus: aggregateRptStatus(where: $where) {
      count {
        rptcode
      }
    }
  }
`;

export const SINGLE_RPT_STATUS_QUERY = gql`
  query rptStatus($where: RptStatusWhereUniqueInput!) {
    rptStatus(where: $where) {
      rptcode
      description
      dateadded
      useridadded
      dateedited
      useridedited
    }
  }
`;
