/* eslint-disable import/prefer-default-export */
import { gql } from "@apollo/client";

export const CREATE_USER_SECURITY_MUTATION = gql`
  mutation createUserSecurity($data: UserSecurityCreateInput!) {
    createUserSecurity(data: $data) {
      userid
      groupcode
      office {
        description
        shortdesc
        officecode
      }
    }
  }
`;

export const UPDATE_USER_SECURITY_MUTATION = gql`
  mutation updateUserSecurity(
    $data: UserSecurityUpdateInput!
    $where: UserSecurityWhereUniqueInput!
  ) {
    updateUserSecurity(data: $data, where: $where) {
      userid
      groupcode
      officecode
    }
  }
`;

export const DELETE_USER_SECURITY_MUTATION = gql`
  mutation deleteUserSecurity($where: UserSecurityWhereUniqueInput!) {
    deleteUserSecurity(where: $where) {
      userid
      groupcode
      officecode
    }
  }
`;

export const DELETE_MANY_USER_SECURITY_MUTATION = gql`
  mutation deleteManyUserSecurity($where: UserSecurityWhereInput!) {
    deleteManyUserSecurity(where: $where) {
      count
    }
  }
`;