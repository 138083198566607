import React from "react";
import PropTypes from "prop-types";
import { Generic, Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useCaseDocumentPath } from "../../../../hooks";

const CaseDocumentDownloadLink = ({ caseDocument }) => {
  const { casenbr, sfilename } = caseDocument;
  const getCaseVirtualDocumentPath = useCaseDocumentPath(casenbr);
  const path = getCaseVirtualDocumentPath(sfilename);

  return (
    <Generic
      as="a"
      href={path}
      rel="noopener noreferrer"
      style={{ textAlign: "center", display: "block" }}
      target="_blank"
      title="Download"
      type="button"
      onClick={(e) => e.stopPropagation()}
    >
      <Icon color="link">
        <FontAwesomeIcon icon="download" />
      </Icon>
    </Generic>
  );
};

CaseDocumentDownloadLink.propTypes = {
  caseDocument: PropTypes.object,
};
CaseDocumentDownloadLink.defaultProps = {
  caseDocument: {},
};
export default CaseDocumentDownloadLink;
