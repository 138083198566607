import companyRoutes from "./company";

import examineeRoutes from "./examinee";
import clientRoutes from "./client";
import doctorRoutes from "./doctor";
import caseRoutes from "./case";
import documentRoutes from "./document";
import setupRoutes from "./setup";
import claimRoutes from "./claim";
import investigatorRoutes from "./investigator";

const { REACT_APP_SHOW_SETUP } = process.env;

export const routesArr = [
  companyRoutes,
  clientRoutes,
  doctorRoutes,
  investigatorRoutes,
  caseRoutes,
  examineeRoutes,
  documentRoutes,
  claimRoutes,
  REACT_APP_SHOW_SETUP && setupRoutes,
].filter(Boolean);

export default routesArr;
