import React, { useState } from "react";
import { Title, Field, Control, Button, Icon, Input } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMutation } from "@apollo/client";

// import { useAuth } from "../../../../context/AuthContext";
import { useModal } from "../../../../context";
import DataTable from "../../../../components/DataTable";
import {
  LIST_USER_FUNCTIONS_QUERY,
  CREATE_USER_FUNCTION_MUTATION,
  UPDATE_USER_FUNCTION_MUTATION,
  DELETE_USER_FUNCTION_MUTATION,
  // CREATE_AUDIT_TRAIL_MUTATION,
} from "../../../../graphql";
import { UserFunctionModal } from "../../components/UserFunctions";
import { debounce, customToast as toast } from "../../../../utils";
import Loader from "../../../../components/Loader";
import COLUMNS from "./columns";

const DEFAULT_FILTER_STATE = {
  OR: [
    {
      functiondesc: { contains: "" },
    },
  ],
};

const UserFunctionsListPage = () => {
  // const { state: authState } = useAuth();

  const [where, setWhere] = useState(DEFAULT_FILTER_STATE);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const { setModalOpen } = useModal();

  const [createUserFunction] = useMutation(CREATE_USER_FUNCTION_MUTATION);
  const [updateUserFunction] = useMutation(UPDATE_USER_FUNCTION_MUTATION);
  const [deleteUserFunction] = useMutation(DELETE_USER_FUNCTION_MUTATION);
  // const [createAuditTrail] = useMutation(CREATE_AUDIT_TRAIL_MUTATION);

  const handleDelete = async (functioncode) => {
    try {
      setLoading(true);
      await deleteUserFunction({
        variables: {
          where: {
            functioncode,
          },
        },
        refetchQueries: [
          {
            query: LIST_USER_FUNCTIONS_QUERY,
          },
        ],
      });
      toast.success("User Function deleted successfully");
    } catch (err) {
      toast.error("Error trying to delete User Function");
    } finally {
      setLoading(false);
    }
  };

  const handleRowClick = (row) => {
    const values = {
      functioncode: row.functioncode || "",
      functiondesc: row.functiondesc || "",
    };
    setModalOpen(
      true,
      <UserFunctionModal
        values={values}
        onComplete={(adding, inputs, previousCode, updated) => {
          if (inputs) {
            handleSave(adding, inputs, previousCode, updated);
          } else {
            setModalOpen(false);
          }
        }}
        onDelete={handleDelete}
      />
    );
  };

  const handleSave = async (adding, inputs, previousCode, updated = []) => {
    try {
      setLoading(true);
      if (adding) {
        await createUserFunction({
          variables: {
            data: {
              ...inputs,
            },
          },
          refetchQueries: [
            {
              query: LIST_USER_FUNCTIONS_QUERY,
            },
          ],
        });
        // await createAuditTrail({
        //   variables: {
        //     data: {
        //       tablename: "tblUserFunction",
        //       entityid: String(
        //         createUserFunctionData.createUserFunction.functioncode
        //       ),
        //       type: "New",
        //       description: "New User Function created",
        //       useridadded: authState.user.userid,
        //       dateadded: getDateEST(),
        //     },
        //   },
        // });
        setModalOpen(false);
        toast.success("User Function created successfully.");
      } else {
        await updateUserFunction({
          variables: {
            data: {
              functiondesc: { set: inputs.functiondesc },
            },
            where: {
              functioncode: previousCode,
            },
          },
          refetchQueries: [
            {
              query: LIST_USER_FUNCTIONS_QUERY,
            },
          ],
        });
        // await createAuditTrail({
        //   variables: {
        //     data: {
        //       tablename: "tblUserFunction",
        //       entityid: String(previousCode),
        //       type: "Update",
        //       description: `Updated fields: ${updated.join(", ")}`,
        //       useridadded: authState.user.userid,
        //       dateadded: getDateEST(),
        //     },
        //   },
        // });
        setModalOpen(false);
        toast.success("User Function updated successfully.");
      }
    } catch (err) {
      if (err.message.includes("Unique constraint")) {
        toast.error("This User Function already exists");
      } else {
        toast.error("Error saving User Function");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleSearchChange = debounce(({ target: { value } }) => {
    if (value) {
      setSearchValue(value);
      setWhere({
        OR: [
          {
            functiondesc: { contains: value },
          },
        ],
      });
    } else {
      setSearchValue("");
      setWhere({
        OR: [
          {
            functiondesc: { contains: "" },
          },
        ],
      });
    }
  }, 500);

  if (loading) return <Loader />;

  return (
    <div>
      <div className="section-header">
        <Title style={{ marginRight: 15 }}>User Functions</Title>
        <Field kind="group" style={{ paddingTop: "0.3rem" }}>
          <Control>
            <Input
              autoComplete="off"
              id="search-input"
              placeholder="Search..."
              size="small"
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value);
                handleSearchChange(e);
              }}
            />
          </Control>

          <Control>
            <Button
              color="primary"
              size="small"
              onClick={() => {
                setModalOpen(
                  true,
                  <UserFunctionModal
                    adding
                    onComplete={(adding, inputs, updated) => {
                      if (inputs) {
                        handleSave(adding, inputs, updated);
                      } else {
                        setModalOpen(false);
                      }
                    }}
                  />
                );
              }}
            >
              <Icon>
                <FontAwesomeIcon icon="plus" />
              </Icon>
              <span>Add</span>
            </Button>
          </Control>
        </Field>
      </div>

      <DataTable
        aggregateKey="functioncode"
        aggregateName="aggregateUserFunction"
        columns={COLUMNS}
        fetchPolicy="network-only"
        name="userFunctions"
        orderBy={[{ id: "functioncode", desc: false }]}
        query={LIST_USER_FUNCTIONS_QUERY}
        where={where}
        onRowClick={handleRowClick}
      />
    </div>
  );
};

UserFunctionsListPage.propTypes = {};

export default UserFunctionsListPage;
