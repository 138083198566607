import React, { useState } from "react";
import PropTypes from "prop-types";
import { Title, Button } from "rbx";
import { useMutation, useQuery } from "@apollo/client";
import OfficeSelect from "../../../../../../components/OfficeSelect";
import CaseTypeSelect from "../../../../../../components/CaseTypeSelect";
import { useAuth } from "../../../../../../context";
import {
  customToast as toast,
  getDateEST,
  convertTimeZoneDataBase,
} from "../../../../../../utils";
import {
  CREATE_CASE_TYPE_OFFICE_MUTATION,
  ALL_CASE_TYPES_OFFICES_SELECT_QUERY,
} from "../../../../../../graphql";

const INITIAL_STATE = {
  caseTypeCode: "",
  officeCode: "",
};

const AddCaseTypeToOfficeModal = ({ onComplete, officeCode, onCancel }) => {
  const {
    data: OfficeCaseTypesData,
    loading: OfficeCaseTypesLoading,
  } = useQuery(ALL_CASE_TYPES_OFFICES_SELECT_QUERY, {
    variables: {
      orderBy: { CaseType: { description: "asc" } },
      where: {
        officecode: { equals: parseInt(officeCode, 10) },
      },
    },
  });

  const [inputs, setInputs] = useState({ ...INITIAL_STATE, officeCode });
  const { state: authState } = useAuth();
  const [loading, setLoading] = useState(false);
  const [createCaseTypeOffice] = useMutation(CREATE_CASE_TYPE_OFFICE_MUTATION);

  const handleChange = (name, value) => {
    setInputs((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const date = convertTimeZoneDataBase(getDateEST());
      setLoading(true);
      await createCaseTypeOffice({
        variables: {
          data: {
            officecode: parseInt(inputs.officeCode, 10),
            CaseType: { connect: { code: parseInt(inputs.caseTypeCode, 10) } },
            useridadded: authState.user.userid,
            dateadded: date,
          },
        },
        refetchQueries: [
          {
            query: ALL_CASE_TYPES_OFFICES_SELECT_QUERY,
            variables: {
              orderBy: { CaseType: { description: "asc" } },
              where: {
                officecode: { equals: parseInt(officeCode, 10) },
              },
            },
          },
        ],
      });
      toast.success("Case Type to Office created successfully.");
      onComplete(inputs.caseTypeCode);
    } catch (err) {
      toast.error("Error saving Case Type to Office ");
    } finally {
      setLoading(false);
    }
  };
  const isDisabled = !inputs.caseTypeCode || loading || OfficeCaseTypesLoading;

  const officeCaseTypes = OfficeCaseTypesData?.caseTypeOffices || [];

  const caseTypeSelectWhere =
    Array.isArray(officeCaseTypes) && officeCaseTypes.length
      ? {
          code: { notIn: officeCaseTypes.map((cto) => cto.CaseType.code) },
        }
      : {};

  return (
    <form id="service-form" onSubmit={handleSubmit}>
      <header className="page-head">
        <div className="page-head-start">
          <Title size={5}>Link Case Type to Office</Title>
        </div>
        <div className="page-head-end">
          <Button.Group hasAddons>
            <Button size="small" type="button" onClick={onCancel}>
              <span>Cancel</span>
            </Button>
            <Button
              color="primary"
              disabled={isDisabled}
              form="service-form"
              size="small"
              state={loading ? "loading" : ""}
              type="submit"
            >
              <span>Submit</span>
            </Button>
          </Button.Group>
        </div>
      </header>
      <hr />
      <div className="service-status-form" kind="group">
        <OfficeSelect
          disabled
          name="officeCode"
          noValueLabel=""
          value={inputs.officeCode}
          onChange={handleChange}
        />
        <CaseTypeSelect
          disabled={loading}
          name="caseTypeCode"
          noValueLabel=""
          value={inputs.caseTypeCode}
          where={caseTypeSelectWhere}
          onChange={handleChange}
        />
      </div>
      <hr />
    </form>
  );
};

AddCaseTypeToOfficeModal.propTypes = {
  onComplete: PropTypes.func.isRequired,
  officeCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default AddCaseTypeToOfficeModal;
