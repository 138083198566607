import React from "react";

import { Switch, Route } from "react-router-dom";

import ClientPage from "./ClientPage";
import ClientListPage from "./ClientListPage";

const ClientRouter = (props) => (
  <Switch>
    <Route path="/clients/:clientcode">
      <ClientPage />
    </Route>
    <Route path="/clients">
      <ClientListPage />
    </Route>
  </Switch>
);

export default ClientRouter;
