import React from "react";
import PropTypes from "prop-types";

import { format } from "date-fns";

import { Button, Icon, Input } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const getStatusColor = (status) =>
  ({ Open: "green", Off: "brown", Hold: "blue", Scheduled: "#2a4734" }[status]);

const DoctorBlockTimeListItem = ({
  block,
  onRemoveBlock,
  onScheduleBlock,
  onChange,
  disabled,
  readOnly,
  isScheduling,
}) => {
  const handleChangeBySchedCode = (schedcode) => (e) => {
    onChange(e.target.name, e.target.value, schedcode);
  };

  return (
    <div
      className={["schedule-grid", "schedule-item", block.new && "new"]
        .filter(Boolean)
        .join(" ")}
      key={block.schedcode}
    >
      {isScheduling ? (
        <Button
          outlined
          color="link"
          disabled={
            block.status === "Off" ||
            (block.casenbr1 && block.casenbr2 && block.CaseNbr3)
          }
          size="small"
          title="Schedule"
          type="button"
          onClick={() => onScheduleBlock(block)}
        >
          <Icon>
            <FontAwesomeIcon icon="plus" />
          </Icon>
        </Button>
      ) : (
        <Button
          outlined
          color="danger"
          size="small"
          title="Delete"
          type="button"
          onClick={() => onRemoveBlock(block)}
        >
          <Icon>
            <FontAwesomeIcon icon="times" />
          </Icon>
        </Button>
      )}
      <span>{format(block.starttime, "hh:mm a")}</span>
      <span>{block.duration}</span>
      <span>{block.casenbr1}</span>
      <Input
        disabled={disabled || !block.new}
        name="casenbr1desc"
        readOnly={readOnly}
        size="small"
        value={block.casenbr1desc}
        onChange={handleChangeBySchedCode(block.schedcode)}
      />
      <span>{block.casenbr2}</span>
      <Input
        disabled={disabled || !block.new}
        name="casenbr2desc"
        readOnly={readOnly}
        size="small"
        value={block.casenbr2desc}
        onChange={handleChangeBySchedCode(block.schedcode)}
      />
      <span>{block.CaseNbr3}</span>
      <Input
        disabled={disabled || !block.new}
        name="CaseNbr3Desc"
        readOnly={readOnly}
        size="small"
        value={block.CaseNbr3Desc}
        onChange={handleChangeBySchedCode(block.schedcode)}
      />
      <span style={{ color: getStatusColor(block.status) }}>
        {block.status}
      </span>
    </div>
  );
};

DoctorBlockTimeListItem.propTypes = {
  block: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  isScheduling: PropTypes.bool,
  onRemoveBlock: PropTypes.func,
  onScheduleBlock: PropTypes.func,
};

DoctorBlockTimeListItem.defaultProps = {
  disabled: false,
  readOnly: false,
  isScheduling: false,
  onRemoveBlock: () => {},
  onScheduleBlock: () => {},
};
export default DoctorBlockTimeListItem;
