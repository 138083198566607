import React from "react";
import PropTypes from "prop-types";
import { Field, Control, Label, Input, Icon, Button } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ApolloDocumentTypeSelect from "../../../../components/ApolloDocumentTypeSelect/ApolloDocumentTypeSelect";
import DocumentSelect from "../../../../components/DocumentSelect";

const StatusDisplay = ({ status, disabled, onClickRemove }) => {
  if (status === "loading") {
    return (
      <Button color="white" size="small" type="button">
        <Icon>
          <div className="loader is-loading" />
        </Icon>
      </Button>
    );
  }
  if (status === "success") {
    return (
      <Button color="white" size="small" type="button">
        <Icon color="success">
          <FontAwesomeIcon icon="check" />
        </Icon>
      </Button>
    );
  }
  if (status === "error") {
    return (
      <Button color="white" size="small" type="button">
        <Icon color="danger">
          <FontAwesomeIcon icon="times" />
        </Icon>
      </Button>
    );
  }
  return (
    <Button
      outlined
      color="danger"
      disabled={disabled}
      size="small"
      type="button"
      onClick={onClickRemove}
    >
      <Icon>
        <FontAwesomeIcon icon="times" />
      </Icon>
    </Button>
  );
};

StatusDisplay.propTypes = {
  status: PropTypes.string,
  onClickRemove: PropTypes.func,
  disabled: PropTypes.bool,
};

StatusDisplay.defaultProps = {
  status: "",
  disabled: false,
  onClickRemove: () => null,
};

const CaseGenerateDocumentForm = ({
  inputs,
  disabled,
  showLabels,
  onChange,
  onRemove,
}) => (
  <div className="case-generate-document-form">
    <Field>
      <Control>
        {showLabels && <Label>Source</Label>}
        <Input readOnly size="small" value={inputs.source} />
      </Control>
    </Field>
    <Field>
      <Control>
        {showLabels && <Label>Description</Label>}
        <Input
          required
          autoComplete="new"
          disabled={disabled}
          name="description"
          size="small"
          type="text"
          value={inputs.description}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>
    </Field>
    <Field>
      <DocumentSelect
        accessor="document"
        disabled={disabled}
        name="document"
        showLabel={showLabels}
        value={inputs.document}
        onChange={onChange}
      />
    </Field>
    <Field>
      {showLabels && <Label>Document Type</Label>}
      <ApolloDocumentTypeSelect
        required
        disabled={disabled}
        name="documentType"
        showLabel={false}
        value={inputs.documentType}
        onChange={onChange}
      />
    </Field>
    <Field>
      <Control>
        {showLabels && <Label>&nbsp;</Label>}
        <StatusDisplay
          disabled={disabled}
          status={inputs.status}
          onClickRemove={onRemove}
        />
      </Control>
    </Field>
  </div>
);

CaseGenerateDocumentForm.propTypes = {
  showLabels: PropTypes.bool.isRequired,
  inputs: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default CaseGenerateDocumentForm;
