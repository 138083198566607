import { gql } from "@apollo/client";

export const RATS_FIND_FIRST_TEMP_REQUESTOR = gql`
  query FindFirstTemprequestor(
    $take: Int
    $orderBy: [TemprequestorOrderByInput!]
    $where: TemprequestorWhereInput
  ) {
    findFirstTemprequestor(take: $take, orderBy: $orderBy, where: $where) {
      TRid
    }
  }
`;

export const RATS_SINGLE_TEMP_REQUESTOR = gql`
  query Temprequestor($where: TemprequestorWhereUniqueInput!) {
    temprequestor(where: $where) {
      TRid
      TRlastName
      TRfirstName
      TRcourtID
      TRcompany
      TRaddress2
      TRaddress1
      TRcity
      TRstate
      TRzip
      TRemail
      TRphone
      TRfax
      TRclinkUserID
      TRemail2
    }
  }
`;
