import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { AsyncPaginate } from "react-select-async-paginate";

import { Label, Control, Input } from "rbx";
import { useApolloClient, useLazyQuery } from "@apollo/client";
import {
  ALL_COMPANIES_QUERY,
  SINGLE_COMPANY_QUERY,
} from "../../graphql/company";

const CompanySelect = ({
  name,
  value: initialValue,
  label,
  onChange,
  fullWidth,
  disabled,
  readOnly,
  required,
  getValue,
  setValue,
  showLink,
}) => {
  const client = useApolloClient();

  const [getCompany, { data: getCompanyData }] = useLazyQuery(
    SINGLE_COMPANY_QUERY
  );

  const [value, setInnerValue] = useState(null);
  const [defaultOptions, setDefaultOptions] = useState([]);

  const indicateRequired =
    required && (!value || value === "" || value === "0" || value === 0);

  const promiseOptions = async (inputValue, loadedOptions) => {
    const { data: companiesData } = await client.query({
      query: ALL_COMPANIES_QUERY,
      variables: {
        take: 50,
        where: { intname: { contains: inputValue } },
        orderBy: { intname: "asc" },
        skip: loadedOptions.length,
      },
    });
    const companies = companiesData?.companies;

    const result = Array.isArray(companies)
      ? companies.map((company) => ({
          value: company.companycode,
          label: company.intname,
        }))
      : [];
    setDefaultOptions((prev) => [...prev, ...result]);

    return {
      options: result,
      hasMore: result.length === 50,
    };
  };

  const handleChange = ({ value: newValue }) => {
    onChange(name, setValue(String(newValue)));
  };

  useEffect(() => {
    if (initialValue) {
      getCompany({
        variables: {
          where: { companycode: parseInt(getValue(initialValue), 10) },
        },
      });
    } else {
      setInnerValue(null);
    }
  }, [initialValue, getCompany, getValue]);

  useEffect(() => {
    const company = getCompanyData?.company;
    if (company && initialValue) {
      setInnerValue({ value: company.companycode, label: company.intname });
    }
  }, [getCompanyData, initialValue]);

  useEffect(() => {
    if (value) {
      const currOptions = [...defaultOptions];
      if (!currOptions.some((opt) => opt.value === value.value)) {
        setDefaultOptions((prev) => [value, ...prev]);
      }
    }
  }, [value, defaultOptions]);

  return (
    <Control expanded>
      {showLink ? (
        <Label
          as={Link}
          className="label-link"
          tabIndex="-1"
          to={value?.value ? `/companies/${value.value}/profile` : "/clients"}
        >
          {label}
        </Label>
      ) : (
        <Label>{label}</Label>
      )}
      {readOnly ? (
        <Input
          readOnly
          autoComplete="new"
          size="small"
          type="text"
          value={value?.label || ""}
        />
      ) : (
        <AsyncPaginate
          cacheOptions
          debounceTimeout={300}
          defaultOptions={defaultOptions}
          isDisabled={disabled}
          isSearchable={!readOnly}
          loadOptions={promiseOptions}
          menuIsOpen={readOnly ? false : undefined}
          menuPlacement="auto"
          menuPortalTarget={document.body}
          placeholder="Start typing..."
          styles={{
            menuPortal: (provided) => ({
              ...provided,
              zIndex: 9999,
            }),
            indicatorsContainer: (provided) => ({
              ...provided,
              height: "inherit",
            }),
            indicatorSeparator: (provided) => ({
              ...provided,
              display: "none",
            }),
            container: (provided) => ({
              fontSize: 13,
            }),
            singleValue: (provided) => ({
              ...provided,
              top: "38%",
            }),
            control: (provided) => ({
              ...provided,
              minHeight: 31,
              height: 31,
              width: "100%",
              minWidth: 180,
              border: indicateRequired ? "1px #e63362 solid" : "",
              borderRadius: "4px",
              boxShadow: indicateRequired
                ? "0 0 0 0.125em rgba(230, 51, 98, 0.25)"
                : "",
              "&:hover": {
                borderColor: "hsl(0, 0%, 90%)",
                borderRadius: 4,
                cursor: disabled ? "not-allowed" : "pointer",
              },
            }),
            placeholder: (provided) => ({ ...provided, top: "38%" }),
            menu: (provided) => ({
              ...provided,
              fontSize: 12,
              zIndex: 999,
            }),
          }}
          value={value}
          onChange={handleChange}
        />
      )}
    </Control>
  );
};

CompanySelect.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
  showLink: PropTypes.bool,
  onChange: PropTypes.func,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  getValue: PropTypes.func,
  setValue: PropTypes.func,
};

CompanySelect.defaultProps = {
  showLink: false,
  name: "",
  label: "",
  value: "",
  readOnly: false,
  onChange: () => null,
  fullWidth: false,
  disabled: false,
  required: false,
  getValue: (x) => x,
  setValue: (x) => x,
};

export default CompanySelect;
