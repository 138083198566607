import { useMutation, useQuery, useApolloClient } from "@apollo/client";
import { differenceInCalendarDays } from "date-fns";
import { useCallback } from "react";
import {
  UPDATE_CASE_MUTATION,
  CREATE_CASE_HISTORY_MUTATION,
  SINGLE_CASE_QUERY,
  SINGLE_QUEUE_QUERY,
  LIST_CASES_QUERY,
  LIST_CASE_HISTORY_QUERY,
} from "../graphql";
import {
  getDateEST,
  convertTimeZone,
  convertTimeZoneDataBase,
  customToast as toast,
} from "../utils";
import useCaseExceptions from "./useCaseExceptions";

import { useAuth } from "../context";

export default function useCaseStatusChange(casenbr) {
  const client = useApolloClient();
  const { state: authState } = useAuth();
  const [createCaseHistory] = useMutation(CREATE_CASE_HISTORY_MUTATION);
  const [updateCase] = useMutation(UPDATE_CASE_MUTATION);

  const executeExceptions = useCaseExceptions(casenbr);

  const { data: CaseItemData } = useQuery(SINGLE_CASE_QUERY, {
    variables: { where: { casenbr: parseInt(casenbr, 10) } },
  });

  return useCallback(
    async (nextStatus) => {
      const date = convertTimeZoneDataBase(getDateEST());

      const prevStatus = CaseItemData?.caseItem?.status;

      try {
        const performAutoUpdate = async () => {
          const { data: OriginalQueueData } = await client.query({
            query: SINGLE_QUEUE_QUERY,
            variables: {
              where: { statuscode: parseInt(prevStatus, 10) },
            },
          });

          const { data: NewQueueData } = await client.query({
            query: SINGLE_QUEUE_QUERY,
            variables: {
              where: { statuscode: parseInt(nextStatus, 10) },
            },
          });
          const duration = CaseItemData?.caseItem?.laststatuschg
            ? differenceInCalendarDays(
                date,
                convertTimeZone(new Date(CaseItemData.caseItem.laststatuschg))
              )
            : null;

          await Promise.all([
            updateCase({
              variables: {
                where: { casenbr: parseInt(casenbr, 10) },
                data: {
                  Status: {
                    connect: { statuscode: parseInt(nextStatus, 10) },
                  },
                  laststatuschg: { set: date },
                  dateedited: { set: date },
                  useridedited: { set: authState?.user?.userid },
                },
              },
              refetchQueries: [
                {
                  query: SINGLE_CASE_QUERY,
                  variables: { where: { casenbr: parseInt(casenbr, 10) } },
                },
                {
                  query: LIST_CASES_QUERY,
                },
              ],
            }),
            createCaseHistory({
              variables: {
                data: {
                  casenbr: parseInt(casenbr, 10),
                  eventdate: date,
                  eventdesc: `Status Chg New Status - ${
                    NewQueueData.queue.shortdesc || ""
                  }`,
                  otherinfo: [
                    OriginalQueueData?.queue?.statusdesc &&
                      `Old - ${OriginalQueueData.queue.statusdesc}`,
                    NewQueueData?.queue?.statusdesc &&
                      `New - ${NewQueueData.queue.statusdesc}`,
                  ]
                    .filter(Boolean)
                    .join(" "),
                  userid: authState?.user?.userid,
                  dateadded: date,
                  useridedited: authState?.user?.userid,
                  dateedited: date,
                  duration,
                  type: "StatChg",
                  status: parseInt(nextStatus, 10),
                },
              },
              refetchQueries: [
                {
                  query: LIST_CASE_HISTORY_QUERY,
                  variables: {
                    where: { casenbr: { equals: parseInt(casenbr, 10) } },
                    orderBy: [{ eventdate: "desc" }],
                  },
                },
              ],
            }),
            executeExceptions(prevStatus, nextStatus),
          ]);
        };

        if (nextStatus && nextStatus !== prevStatus) {
          await performAutoUpdate();
          toast.success(`Case status updated successfully.`);
        }
      } catch {
        toast.error(`Error updating case status.`);
      }
    },
    [
      CaseItemData?.caseItem?.laststatuschg,
      CaseItemData?.caseItem?.status,
      authState?.user?.userid,
      casenbr,
      client,
      createCaseHistory,
      executeExceptions,
      updateCase,
    ]
  );
}
