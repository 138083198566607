import React from "react";

import { Switch, Route } from "react-router-dom";

import ExamineeListPage from "./ExamineeListPage";
import ExamineePage from "./ExamineePage";

const ExamineeRouter = (props) => (
  <Switch>
    <Route path="/examinees/:chartnbr/:tab">
      <ExamineePage />
    </Route>
    <Route path="/examinees">
      <ExamineeListPage />
    </Route>
  </Switch>
);

export default ExamineeRouter;
