import { gql } from "@apollo/client";

export const SINGLE_USER_GROUP_FUNCTION_QUERY = gql`
  query userGroupFunction($where: UserGroupFunctionWhereUniqueInput!) {
    userGroupFunction(where: $where) {
      usergroupfunctionid
      entitytype
      entityid
      functioncode
      dateadded
      useridadded
      dateedited
      useridedited
      UserFunction {
        functioncode
        functiondesc
      }
    }
  }
`;

export const FIND_FIRST_USER_GROUP_FUNCTION_QUERY = gql`
  query FIND_FIRST_USER_GROUP_FUNCTION_QUERY(
    $where: UserGroupFunctionWhereInput
  ) {
    findFirstUserGroupFunction(where: $where) {
      usergroupfunctionid
      entitytype
      entityid
      functioncode
      dateadded
      useridadded
      dateedited
      useridedited
      UserFunction {
        functioncode
        functiondesc
      }
    }
  }
`;

export const LIST_USER_GROUP_FUNCTIONS_QUERY = gql`
  query userGroupFunctions(
    $take: Int
    $skip: Int
    $orderBy: [UserGroupFunctionOrderByInput!]
    $where: UserGroupFunctionWhereInput
  ) {
    userGroupFunctions(
      take: $take
      orderBy: $orderBy
      skip: $skip
      where: $where
    ) {
      usergroupfunctionid
      entitytype
      entityid
      functioncode
      dateadded
      useridadded
      dateedited
      useridedited
      UserFunction {
        functioncode
        functiondesc
      }
    }
    aggregateUserGroupFunction(where: $where) {
      count {
        usergroupfunctionid
      }
    }
  }
`;
