import React, { useState, useEffect, useRef } from "react";
import { Title, Field, Control, Button, Icon, Input } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useParams } from "react-router-dom";
import { useModal } from "../../../../context";
import DataTable from "../../../../components/DataTable";
import { LIST_OFFICES_QUERY } from "../../../../graphql";
import { OfficeModal } from "../../components";
import { debounce } from "../../../../utils";
import COLUMNS from "./columns";

const DEFAULT_FILTER_STATE = {
  OR: [
    {
      description: { contains: "" },
    },
    {
      shortdesc: { contains: "" },
    },
    {
      class: { contains: "" },
    },
  ],
};

const OfficesListPage = () => {
  const { officecode } = useParams();
  const [where, setWhere] = useState(DEFAULT_FILTER_STATE);
  const isModalOpen = useRef(false);
  const { setModalOpen } = useModal();

  useEffect(() => {
    if (officecode && !isModalOpen.current) {
      isModalOpen.current = true;
      setModalOpen(
        true,
        <OfficeModal
          officecode={parseInt(officecode, 10)}
          where={where}
          onComplete={() => setModalOpen(false)}
        />
      );
    }
  }, [officecode, setModalOpen, where]);

  const handleRowClick = (row) => {
    setModalOpen(
      true,
      <OfficeModal
        officecode={parseInt(row.officecode, 10)}
        where={where}
        onComplete={() => setModalOpen(false)}
      />
    );
  };

  return (
    <div>
      <div className="section-header">
        <Title style={{ marginRight: 15 }}>Offices</Title>
        <Field kind="group">
          <Control>
            <Input
              autoComplete="off"
              id="search-input"
              placeholder="Search..."
              size="small"
              onChange={debounce(({ target: { value } }) => {
                if (value) {
                  setWhere({
                    OR: [
                      {
                        description: { contains: value },
                      },
                      {
                        shortdesc: { contains: value },
                      },
                      {
                        class: { contains: value },
                      },
                    ],
                  });
                } else {
                  setWhere({
                    OR: [
                      {
                        description: { contains: "" },
                      },
                      {
                        shortdesc: { contains: "" },
                      },
                      {
                        class: { contains: "" },
                      },
                    ],
                  });
                }
              }, 500)}
            />
          </Control>

          <Control>
            <Button
              color="primary"
              size="small"
              onClick={() => {
                setModalOpen(
                  true,
                  <OfficeModal
                    where={where}
                    onComplete={() => setModalOpen(false)}
                  />
                );
              }}
            >
              <Icon>
                <FontAwesomeIcon icon="plus" />
              </Icon>

              <span>Add</span>
            </Button>
          </Control>
        </Field>
      </div>
      <DataTable
        aggregateKey="officecode"
        aggregateName="aggregateOffice"
        columns={COLUMNS}
        name="offices"
        orderBy={[{ id: "officecode", desc: false }]}
        query={LIST_OFFICES_QUERY}
        where={where}
        onRowClick={handleRowClick}
      />
    </div>
  );
};

OfficesListPage.propTypes = {};

export default OfficesListPage;
