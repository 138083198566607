const investigator = {
  name: "investigators",
  label: "INVESTIGATIONS",
  icon: "binoculars",

  active: (location) => {
    const [, p] = location.pathname.split("/");
    return /investigators/.test(p);
  },
  to: "/investigators",
};

export default investigator;
