import React, { useState, useRef, useLayoutEffect, useEffect } from "react";
import PropTypes from "prop-types";
import { Card, Image, Content, Icon, Button, Dropdown } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ClickCapture from "../../../../components/ClickCapture";

const BusinessCard = ({ data, onSeeMoreClick, disabled, isInTable = false }) => {
  const ref = useRef(null);
  const [open, setOpen] = useState(false);
  const [coords, setCoords] = useState({ top: "-50%", left: "-50%" });

  const getAdjustedLeft = (rect, dropdownWidth, viewportWidth) => {
    const idealLeft = rect.left - dropdownWidth;

    if (idealLeft < 0) {
      return 0;
    }

    if (idealLeft + dropdownWidth > viewportWidth) {
      return viewportWidth - dropdownWidth;
    }

    return idealLeft;
  };

  const handlePositionDropdown = () => {
    const element = ref.current;
    if (element) {
      const rect = element.getBoundingClientRect();
      const viewportHeight = window.innerHeight;
      const viewportWidth = window.innerWidth;
      const dropdownHeight = 200;
      const dropdownWidth = 340;

      if (rect.bottom + dropdownHeight > viewportHeight) {
        setCoords({ top: rect.top - dropdownHeight, left: getAdjustedLeft(rect, dropdownWidth, viewportWidth) });
      } else {
        setCoords({ top: rect.bottom, left: getAdjustedLeft(rect, dropdownWidth, viewportWidth) });
      }
    }
  };

  useLayoutEffect(() => {
    handlePositionDropdown(ref.current);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", () => setOpen(false));
    return () => window.removeEventListener("scroll", () => setOpen(false));
  }, []);

  const handleOpen = () => {
    handlePositionDropdown();
    setOpen((prev) => !prev);
  };

  return (
    <ClickCapture onOutsideClick={(e) => setOpen(false)}>
      <Dropdown
        managed
        active={open}
        className="business-card-dropdown"
        ref={ref}
      >
        <Dropdown.Trigger>
          <Button
            className={!isInTable ? "request-generator-action-buttons--info" : ""}
            color="link"
            disabled={disabled}
            size="small"
            style={{
              borderBottomRightRadius: 0,
              borderTopRightRadius: 0,
              marginRight: -1,
            }}
            title="Inspect"
            type="button"
            onClick={handleOpen}
          >
            <Icon>
              <FontAwesomeIcon icon="info" />
            </Icon>
          </Button>
        </Dropdown.Trigger>
        <Dropdown.Menu style={{ position: "fixed", ...coords }}>
          <Dropdown.Content>
            <Card className="business-card-container">
              <Card.Image>
                <Image.Container size="48">
                  <Image src="./isg-logo.png" />
                </Image.Container>
                {!isInTable && (
                  <Icon onClick={onSeeMoreClick}>
                    <FontAwesomeIcon icon="eye" />
                  </Icon>
                )}
              </Card.Image>
              <Card.Content style={{ width: "100%" }}>
                <Content>
                  {/* For Employer Search */}
                  {(data?.EMname && (
                    <React.Fragment>
                      <span className="name" style={{ fontSize: "1.2rem" }}>{data?.EMname}</span>
                      <br />
                    </React.Fragment>
                  ))}
                  {(data?.EMtaxidFEIN && (
                    <React.Fragment>
                      <span style={{ color: "#555" }}>
                        FEIN:
                        {' '}
                        {data?.EMtaxidFEIN}
                        <br />
                      </span>
                    </React.Fragment>
                  ))}
                  {(data?.EMaddress1) && (
                    <React.Fragment>
                      <span>{data?.EMaddress1}</span>
                      <br />
                    </React.Fragment>
                  )}
                  {(data?.EMaddress2) && (
                    <React.Fragment>
                      <span>{data?.EMaddress2}</span>
                      <br />
                    </React.Fragment>
                  )}
                  {(data?.EMcity || data?.EMstate || data?.EMzip) && (
                    <React.Fragment>
                      <span>{`${data?.EMcity}, ${data?.EMstate} ${data?.EMzip}`}</span>
                      <br />
                    </React.Fragment>
                  )}
                  {(data?.EMphone && (
                    <React.Fragment>
                      <span>
                        <b>T. </b>
                        <a href={`tel:${data?.EMphone}`}>{data?.EMphone}</a>
                      </span>
                      <br />
                    </React.Fragment>
                  ))}
                  {/* For Employer Search */}

                  {/* For Court Address */}
                  {(data?.CourtclerkFname || data?.CourtclerkLname) && (
                    <React.Fragment>
                      <span className="name" style={{ fontSize: "1.2rem", whiteSpace: "normal", lineHeight: 1.1 }}>
                        {`${data?.CourtclerkFname || ''} ${data?.CourtclerkLname || ''}`}
                      </span>
                      <br />
                      <br />
                    </React.Fragment>
                  )}
                  {(data?.CourtCounty || data?.CourtType) && (
                    <React.Fragment>
                      <span className="name" style={{ whiteSpace: "normal", lineHeight: 1.1 }}>{`${data?.CourtCounty || ''} ${data?.CourtType || ''}`}</span>
                      <br />
                    </React.Fragment>
                  )}
                  {data?.CourtAddress && (
                    <React.Fragment>
                      <span>{data?.CourtAddress}</span>
                      <br />
                    </React.Fragment>
                  )}
                  {data?.CourtAddress2 && (
                    <React.Fragment>
                      <span>{data?.CourtAddress2}</span>
                      <br />
                    </React.Fragment>
                  )}
                  {(data?.CourtCity || data?.CourtState || data?.CourtZip) && (
                    <React.Fragment>
                      <span>{`${data?.CourtCity || ''}, ${data?.CourtState || ''} ${data?.CourtZip || ''}`}</span>
                      <br />
                    </React.Fragment>
                  )}
                  {/* For Court Address */}

                  {(data?.MPid && (
                    <React.Fragment>
                      <span style={{ color: "#555" }}>
                        MP ID:
                        {' '}
                        {data?.MPid}
                        <br />
                      </span>
                    </React.Fragment>
                  ))}

                  {(data[0] || data?.RGid || data?.MPid) && (
                    <React.Fragment>
                      <span className="name" style={{ fontSize: "1.2rem", whiteSpace: "normal", lineHeight: 1.1 }}>{data[0] ? data[0] : (data?.RGnameFirst && data?.RGnameLast ? data?.RGnameLast.concat(", ").concat(data?.RGnameFirst) : "") || (data?.MPnameFirst && data?.MPnameLast ? data?.MPnameLast.concat(", ").concat(data?.MPnameFirst) : "")}</span>
                      <br />
                      <br />
                    </React.Fragment>
                  )}
                  {(data?.MPgroupName && !data?.MPnameLast) && (
                    <React.Fragment>
                      <span className="name" style={{ fontSize: "1.2rem", whiteSpace: "normal", lineHeight: 1.1 }}>{data?.MPnameLast ? data?.MPnameLast : data?.MPgroupName}</span>
                      <br />
                      <br />
                    </React.Fragment>
                  )}
                  {(!data?.MPgroupName || data?.MPnameLast) && (
                    <React.Fragment>
                      <span className="name" style={{ fontSize: "1.2rem", whiteSpace: "normal", lineHeight: 1.1 }}>{data?.MPnameLast}</span>
                      <br />
                      <br />
                    </React.Fragment>
                  )}
                  {data[1] && (
                    <React.Fragment>
                      <span className="name">{data[1]}</span>
                      <br />
                    </React.Fragment>
                  )}
                  {(data[2] || data["clientoffice:COofficeName"] || data?.MPgroupName) && (
                    <React.Fragment>
                      <span className="name" style={{ whiteSpace: "normal", lineHeight: 1.1 }}>{data[2] ? data[2] : (data["clientoffice:COofficeName"] || data?.MPgroupName)}</span>
                      <br />
                    </React.Fragment>
                  )}
                  {(data[3] || data["clientoffice:COaddress1"] || data?.MPaddress1) && (
                    <React.Fragment>
                      <span>{data[3] ? data[3] : (data["clientoffice:COaddress1"] || data?.MPaddress1)}</span>
                      <br />
                    </React.Fragment>
                  )}
                  {(data[4] || data["clientoffice:COaddress2"] || data?.MPaddress2) && (
                    <React.Fragment>
                      <span>{data[4] ? data[4] : (data["clientoffice:COaddress2"] || data?.MPaddress2)}</span>
                      <br />
                    </React.Fragment>
                  )}
                  {(data["clientoffice:COcity"] || data["clientoffice:COstate"] || data["clientoffice:COzip"]) && (
                    <React.Fragment>
                      <span>{`${data["clientoffice:COcity"]}, ${data["clientoffice:COstate"]} ${data["clientoffice:COzip"]}`}</span>
                      <br />
                    </React.Fragment>
                  )}
                  {(data?.MPcity || data?.MPstate || data?.MPzip) && (
                    <React.Fragment>
                      <span>{`${data?.MPcity}, ${data?.MPstate} ${data?.MPzip}`}</span>
                      <br />
                    </React.Fragment>
                  )}
                  {(data[5] || data["clientoffice:COphone1"] || data?.MPphone1) && (
                    <React.Fragment>
                      <span>
                        <b>T. </b>
                        <a href={`tel:${data[5] ? data[5] : (data["clientoffice:COphone1"] || data?.MPphone1)}`}>{data[5] ? data[5] : (data["clientoffice:COphone1"] || data?.MPphone1)}</a>
                      </span>
                      <br />
                    </React.Fragment>
                  )}
                  {(data[6] || data?.RGemail) && (
                    <span>
                      <b>E. </b>
                      <a href={`mailto:${data[6] ? data[6] : data?.RGemail}`}>{data[6] ? data[6] : data?.RGemail}</a>
                    </span>
                  )}
                </Content>
              </Card.Content>
            </Card>
          </Dropdown.Content>
        </Dropdown.Menu>
      </Dropdown>
    </ClickCapture>
  );
};

BusinessCard.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]).isRequired,
  onSeeMoreClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  isInTable: PropTypes.bool.isRequired,
};

export default BusinessCard;
