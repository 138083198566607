const client = {
  name: "client",
  label: "Clients",
  icon: "address-book",

  active: (location) => {
    const [, p] = location.pathname.split("/");
    return /clients/.test(p);
  },
  to: "/clients",
};

export default client;
