/* eslint-disable react/prop-types */
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Generic, Icon } from "rbx";
import { format } from "date-fns";
import { convertTimeZone } from "../../../utils";

export const COLUMNS_ISSUES = (handleDelete, disabled) => [
  {
    Header: "",
    id: "Delete",
    Cell: ({ row }) => (
      <Generic
        disabled={disabled}
        style={{ textAlign: "center" }}
        type="button"
        onClick={() => !disabled && handleDelete(row.original)}
      >
        <Icon color="danger">
          <FontAwesomeIcon icon="times" />
        </Icon>
      </Generic>
    ),
  },
  {
    Header: "Issue",
    id: "issuecode",
    accessor: "issue.description",
  },
];

export const COLUMNS_PROBLEMS = (handleDelete, disabled) => [
  {
    Header: "",
    id: "Delete",
    Cell: ({ row }) => (
      <Generic
        disabled={disabled}
        style={{ textAlign: "center" }}
        type="button"
        onClick={() => !disabled && handleDelete(row.original)}
      >
        <Icon color="danger">
          <FontAwesomeIcon icon="times" />
        </Icon>
      </Generic>
    ),
  },
  {
    Header: "Problem",
    id: "problemcode",
    accessor: "problem.description",
  },
];

export const COLUMNS_OTHER_PARTY = (handleDelete, disabled) => [
  {
    Header: "",
    id: "Delete",
    Cell: ({ row }) => (
      <Generic
        style={{ textAlign: "center" }}
        type="button"
        onClick={() => !disabled && handleDelete(row.original)}
      >
        <Icon color="danger">
          <FontAwesomeIcon icon="times" />
        </Icon>
      </Generic>
    ),
  },
  {
    Header: "Company",
    id: "company",
    accessor: "otherParty.companyname",
  },
  {
    Header: "Resp",
    id: "useridresponsible",
    accessor: "useridresponsible",
  },
  {
    Header: "Due Date",
    id: "duedate",
    accessor: "duedate",
    Cell: ({ cell }) =>
      cell?.value ? format(convertTimeZone(cell.value), "MM/dd/yyyy") : "--",
  },
  {
    Header: "Status",
    id: "status",
    accessor: "status",
  },
];
