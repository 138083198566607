import React from "react";
import PropTypes from "prop-types";

import UserFieldsForm from "../../components/UserFieldsForm";

const UserFields = ({
  inputs,
  onChange,
  disabled,
  onSubmit,
}) => (
  <UserFieldsForm
    disabled={disabled}
    inputs={inputs}
    onChange={onChange}
    onSubmit={onSubmit}
  />
);

UserFields.propTypes = {
  inputs: PropTypes.object,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
};

UserFields.defaultProps = {
  inputs: {},
  onChange: (e) => e,
  disabled: false,
};

export default UserFields;
