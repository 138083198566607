import React from "react";
import PropTypes from "prop-types";

import { useParams } from "react-router-dom";
import CompanyForm from "../../components/CompanyForm";

import CompanyReplicateToClientsButton from "../../components/CompanyReplicateToClientsButton";

const Profile = ({ inputs, disabled, onChange, onSubmit }) => {
  const { companycode } = useParams();

  return (
    <React.Fragment>
      <CompanyForm
        companyCode={companycode}
        disabled={disabled}
        inputs={inputs}
        onChange={onChange}
        onSubmit={onSubmit}
      />
      <CompanyReplicateToClientsButton
        companycode={companycode}
        onSubmit={onSubmit}
      />
    </React.Fragment>
  );
};

Profile.propTypes = {
  inputs: PropTypes.object,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
};

Profile.defaultProps = {
  inputs: {},
  disabled: false,
  onSubmit: () => null,
  onChange: () => null,
};
export default Profile;
